<template>
  <div>
    <div class="d-flex flex-row align-items-center w-100 py-1 py-md-50">
      <span>{{ user.position }}</span>

      <b-avatar
        class="mx-50"
        size="34"
        variant="light-primary"
        :src="user.avatar"
        :text="avatarText(getUserName(user))"
      />

      <span
        class="d-flex flex-column w-100"
      >
        <span>
          <strong
            v-if="!isMe"
            class="font-small-4"
          >
            {{ `${user.firstName} ${user.lastName}` }}
          </strong>

          <strong
            v-else
            class="font-small-4 text-success"
          >
            {{ $t('Me') }}
          </strong>

          <b-badge v-if="user.userId"
                   v-b-tooltip
                   class="font-small-2"
                   variant="light-primary"
                   :title="$t('contact.userId')"
          >
            <feather-icon icon="ToolIcon" />
            {{ user.userId }}
          </b-badge>

          <b-badge
            v-b-tooltip
            variant="light-success"
            pill
            class="font-small-2 ml-25"
            :title="$t(type === 'marketing' ? 'SellProducts' : 'PointsScored')"
            style="min-width: 50px"
          >
            <feather-icon :icon="type === 'marketing' ? 'ShoppingCartIcon' : 'HexagonIcon'"
                          class="mr-50"
                          size="20"
            />
            {{ user.points }}
          </b-badge>
        </span>

        <span>{{ user.converter }}</span>
      </span>
    </div>

    <!-- Bar -->
    <b-progress
      :max="maxSale"
      height="1.5rem"
    >
      <b-progress-bar v-for="(product, productIndex) in user.products"
                      :key="`progress_${user.id}_${productIndex}`"
                      v-b-tooltip
                      :style="{ 'background-color': product.details ? product.details.color : '#7267f0' }"
                      :value="type === 'marketing' ? product.totalAmount : product.totalValue"
                      size="lg"
                      class="mr-25 progress-bar-hover py-25"
                      :title="product.details ? getTranslationsField(product.details, 'name') : ''"
      >
        <strong class="font-medium-1">
          {{ type === 'marketing' ? product.totalAmount : product.totalValue }}
        </strong>
      </b-progress-bar>
    </b-progress>
  </div>
</template>

<script>
import { VBTooltip, BProgressBar } from 'bootstrap-vue'
import { avatarText } from '../../../../../@core/utils/filter'

export default {
  components: {
    BProgressBar,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    maxSale: {
      type: Number,
      required: true,
      default: 0,
    },
    type: {
      type: String,
      required: true,
      default: 'marketing',
    },
  },
  computed: {
    isMe() {
      const currentUser = this.$store.getters['auth/getCurrentUser']
      return String(currentUser?.id) === String(this.user.id)
    },
  },
  methods: { avatarText },
}
</script>

<style lang="scss">
.progress-bar-hover {
  transition: all .25s;
  &:hover {
    opacity: .7;
  }
}
</style>
