var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.checkRequiredPermissions([_vm.$roles.SHOP_PRODUCT_VIEW_ALL, _vm.$roles.SHOP_PRODUCT_VIEW_GROUP]))?_c('app-collapse',{staticClass:"mb-1"},[_c('app-collapse-item',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ShoppingCartIcon"}}),_vm._v(" "+_vm._s(_vm.$t('ProductPointTable'))+" ")]},proxy:true}],null,false,160673370)},[_c('sw-select',{attrs:{"name":_vm.$t('SelectLoyaltyPlan')}},[_c('v-select',{attrs:{"options":_vm.items,"label":"name","reduce":function (plan) { return plan.id; }},on:{"input":_vm.loadProducts},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('NoOptions'))+" ")]},proxy:true}],null,false,2260934444),model:{value:(_vm.selectedPlan),callback:function ($$v) {_vm.selectedPlan=$$v},expression:"selectedPlan"}})],1),_c('sw-table',{attrs:{"table-id":"fc79a17638d24dfcaf6773d0bbbc337d","show-columns":false,"show-search":"","pagination":_vm.pagination},on:{"change-pagination":function($event){return Object.assign(_vm.pagination, $event)},"reload-content":_vm.loadProducts,"change-search-query":function($event){_vm.search = $event; _vm.loadProducts}},scopedSlots:_vm._u([{key:"table",fn:function(){return [_c('b-table',{style:({ minHeight: _vm.tableSize(_vm.items, 50) }),attrs:{"items":_vm.products,"fields":_vm.fields,"striped":"","responsive":"","busy":_vm.isTableLoading,"show-empty":""},scopedSlots:_vm._u([{key:"head()",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(("" + label)))+" ")]}},{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [(item.translations && item.translations.length)?_c('div',[_c('router-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",modifiers:{"hover":true,"left":true}}],attrs:{"to":{ name: 'productView', params: { productId: item.id } },"title":_vm.selectedTranslationObject(item).name || '-'}},[_c('span',{staticClass:"doubleLineText"},[_vm._v(" "+_vm._s(_vm._f("truncate")((_vm.selectedTranslationObject(item).name || '-'),60, '...'))+" ")])])],1):_c('feather-icon',{attrs:{"icon":"MinusIcon"}})]}},{key:"cell(reward)",fn:function(ref){
var item = ref.item;
return [(item.shopProductLoyaltyPlans && item.shopProductLoyaltyPlans.length)?_c('b-badge',{staticClass:"font-small-2",staticStyle:{"width":"100px"},attrs:{"variant":"light-primary"}},[_vm._v(" "+_vm._s(item.shopProductLoyaltyPlans[0].pointsReward)+" ")]):_c('feather-icon',{attrs:{"icon":"MinusIcon"}})]}},{key:"cell(price)",fn:function(ref){
var item = ref.item;
return [(item.shopProductLoyaltyPlans && item.shopProductLoyaltyPlans.length)?_c('b-badge',{staticClass:"font-small-2",staticStyle:{"width":"100px"},attrs:{"variant":"light-primary"}},[_vm._v(" "+_vm._s(item.shopProductLoyaltyPlans[0].pointsPrice)+" ")]):_c('feather-icon',{attrs:{"icon":"MinusIcon"}})]}},{key:"cell()",fn:function(row){return [_c('table-default-cell',{attrs:{"field":row.field,"value":row.value}})]}},{key:"empty",fn:function(){return [_c('empty-content')]},proxy:true},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[_c('b-spinner',{staticClass:"align-middle",attrs:{"variant":"primary"}})],1)]},proxy:true}],null,false,2281003939)})]},proxy:true}],null,false,3193872429)})],1)],1):_vm._e(),_c('b-alert',{staticClass:"d-flex align-items-center",attrs:{"variant":"primary","show":""}},[_c('div',{staticClass:"alert-body"},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"InfoIcon"}}),_c('span',{staticClass:"ml-25"},[_vm._v(" "+_vm._s(_vm.$t('HereWouWillCreateLoyaltyPlan'))+" ")])],1)]),_c('div',{staticClass:"my-50"},[(_vm.checkRequiredPermissions([_vm.$roles.LOYALTY_PLAN_MODIFY]))?_c('b-button',{attrs:{"variant":"primary","size":"sm","to":{ name: 'newLoyaltyPlan' }}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}}),_vm._v(" "+_vm._s(_vm.$t('Add'))+" ")],1):_vm._e()],1),_c('list',{attrs:{"is-loading":_vm.isLoading,"items":_vm.items}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }