<template>
  <div>
    <!--    <div-->
    <!--      class="mb-1 d-flex"-->
    <!--      style="gap: .74rem"-->
    <!--    >-->
    <!--      <b-button-->
    <!--        variant="primary"-->
    <!--        size="sm"-->
    <!--      >-->
    <!--        {{ $t('ExpandAll') }}-->

    <!--        <feather-icon icon="ChevronsDownIcon" />-->
    <!--      </b-button>-->

    <!--      <b-button-->
    <!--        variant="primary"-->
    <!--        size="sm"-->
    <!--      >-->
    <!--        {{ $t('CollapseAll') }}-->

    <!--        <feather-icon icon="ChevronsUpIcon" />-->
    <!--      </b-button>-->
    <!--    </div>-->

    <b-card v-if="!rootUserId"
            class="mb-1"
    >
      <b-row>
        <b-col sm="12">
          <div class="flex-grow-1 mr-1 order-2 mb-50">
            <b-input-group
              class="input-group-merge search-input-group mx-25 mb-25"
              style="min-width: 150px"
            >
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                v-model="search"
                :placeholder="$t('Search')"
                class="input-search"
                @input="isTyping = true"
                @blur="init"
              />
            </b-input-group>
          </div>
        </b-col>
        <b-col sm="12"
               md="6"
        >
          <sw-select :name="$t('SelectYear')">
            <v-select
              v-model="filters.year"
              :options="years"
              @input="filters.month = ''; init()"
            />
          </sw-select>
        </b-col>
        <b-col sm="12"
               md="6"
        >
          <sw-select :name="$t('SelectMonth')">
            <v-select
              v-model="filters.month"
              :disabled="!filters.year"
              :options="months"
              :reduce="d => d.value"
              :selectable="monthVal => filters.year !== currentYear || monthVal.value <= currentMonth"
              @input="init()"
            >
              <template #no-options>
                {{ $t('NoOptions') }}
              </template>

              <template #option="{ label }">
                {{ $t(`${label}`) }}
              </template>

              <template #selected-option="{ label }">
                {{ $t(`${label}`) }}
              </template>
            </v-select>
          </sw-select>
        </b-col>

        <b-col sm="12">
          <div
            v-if="checkRequiredModule('marketingModule') && checkRequiredPermissions([$roles.MARKETING_PLAN_VIEW_ALL])"
          >
            <sw-select :name="$t('SelectMarketingPlan')">
              <v-select
                v-model="filters.marketingPlan"
                :options="marketingPlans"
                label="name"
                :reduce="plan => plan.id"
                :clearable="false"
                @input="init()"
              >
                <template #no-options>
                  {{ $t('NoOptions') }}
                </template>
              </v-select>
            </sw-select>
          </div>
        </b-col>

        <b-col sm="12">
          <div class="d-flex">
            <span>{{ $t('userRole') }}:</span>
            <b-badge v-for="rank in ranks"
                     :key="`rank_${rank.id}`"
                     :variant="filters.ranks.includes(rank.id) ? 'primary' : 'light-primary'"
                     class="cursor-pointer ml-25"
                     @click="() => {
                       const existIndex = filters.ranks.findIndex(selectedRank => selectedRank === rank.id)
                       if (existIndex > -1) $delete(filters.ranks, existIndex)
                       else filters.ranks.push(rank.id)

                       init()
                     }"
            >
              {{ rank.name }}
            </b-badge>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <div v-if="!loading && !isTyping">
      <div v-if="items.length">
        <sw-multilevel-structure
          v-for="(item, index) in items"
          :key="index"
          :root-item="item"
          show-root
          :off="!item['children'].length"
          show-childs
          child-key="children"
        >
          <template #root-title="{ item }">
            <div class="d-flex align-items-center">
              <span class="font-small-3">
                <avatar v-if="item && item.user"
                        :user="item.user"
                />
                <feather-icon v-else
                              icon="MinusIcon"
                />
              </span>
              <b-badge
                variant="primary"
                class="ml-25 font-small-2"
              >
                <feather-icon icon="ToolIcon" />
                <span v-if="item.user && item.user.userId"
                      class="ml-25"
                >{{ item.user.userId }}</span>
                <feather-icon v-else
                              icon="MinusIcon"
                />
              </b-badge>
              <b-badge v-b-tooltip
                       variant="primary"
                       class="ml-25 font-small-2"
                       :title="$t('SellProducts')"
              >
                <feather-icon icon="ShoppingCartIcon" />
                {{ item.products || 0 }}
              </b-badge>
            </div>
          </template>
        </sw-multilevel-structure>
      </div>
      <div
        v-else
        class="text-center py-1 text-primary font-weight-bold"
      >
        <feather-icon
          size="17"
          icon="XCircleIcon"
        />
        {{ $t('NoData') }}
      </div>
    </div>
    <b-card v-else>
      <div class="d-flex justify-content-center align-items-center">
        <b-spinner variant="primary" />
      </div>
    </b-card>
  </div>
</template>

<script>
import axiosIns from '@/libs/axios'
import { VBTooltip } from 'bootstrap-vue'
import * as fields from '@/@constants/fields'
import vSelect from 'vue-select'
// import Item from './components/Item.vue'

export default {
  components: {
    // Item,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    rootUserId: {
      default: null,
      required: false,
      type: [String, Number],
    },
  },
  data: () => ({
    loading: true,
    isTyping: false,
    items: [],
    search: '',
    ranks: [],
    filters: {
      ranks: [],
      month: '',
      year: '',
      marketingPlan: null,
    },

    months: [
      { label: 'January', value: 1 },
      { label: 'February', value: 2 },
      { label: 'March', value: 3 },
      { label: 'April', value: 4 },
      { label: 'May', value: 5 },
      { label: 'June', value: 6 },
      { label: 'July', value: 7 },
      { label: 'August', value: 8 },
      { label: 'September', value: 9 },
      { label: 'October', value: 10 },
      { label: 'November', value: 11 },
      { label: 'December', value: 12 },
    ],
    years: [],

    currentMonth: null,
    currentYear: null,
    marketingPlans: [],
  }),
  async mounted() {
    const startYear = 2022
    const currentYear = (new Date()).getFullYear()

    this.currentYear = currentYear
    this.currentMonth = (new Date()).getMonth() + 1

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i <= currentYear - startYear; i++) {
      this.years.push(startYear + i)
    }
    await Promise.all([
      this.loadMarketingPlans(),
      this.loadRanks(),
    ])

    this.$nextTick(async () => {
      await this.init()
    })
  },
  methods: {
    async loadMarketingPlans() {
      try {
        if (this.checkRequiredModule('marketingModule') && this.checkRequiredPermissions([this.$roles.MARKETING_PLAN_VIEW_ALL])) {
          const resp = await axiosIns.get('1/marketingPlans', { params: { fields_load: this.$fields.MARKETING_PLANS } })

          const plans = (resp?.data?.data?.items || [])
          this.marketingPlans = plans

          if (plans?.length) {
            this.filters.marketingPlan = this.getObjectId(plans[0])
          }

          return plans
        }

        return []
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        return err
      }
    },
    async loadRanks() {
      try {
        const { data } = await axiosIns.get('1/ranks', { params: { fields_load: fields.RANKS } })

        this.ranks = data?.data?.items || []

        if (window.location.href.includes('hydro')) {
          this.filters.ranks = (data?.data?.items || []).filter(r => r.name === 'Przedstawiciel').mapKeys('id')
        } else {
          this.filters.ranks = (data?.data?.items || []).mapKeys('id')
        }
      } catch (err) {
        console.error(err)
      }
    },
    async init() {
      this.loading = true
      let params = {}
      if (this.search?.length) {
        const search = this.search.replaceAll(' ', '%')
        params = {
          search: `%${search}%`,
          search_fields: 'firstName,lastName,mailAddress-mail,mailAddressPrivate-mail,phoneNumber-phone,phoneNumberPrivate-phone',
        }
      }

      if (this.rootUserId) {
        params.user_rootUser = this.rootUserId
      } else if (this.filters.ranks?.length) params.user_eqArr_rank = JSON.stringify(this.filters.ranks.mapKeys('id'))

      if (this.filters.marketingPlan) {
        params['contactThreadCartBoxProduct_eq_shopProduct-shopProductMarketingPlans-marketingPlan-id'] = this.filters.marketingPlan
      }

      if (this.filters.year && !this.filters.month) {
        const startDate = `${this.filters.year}-01-01 00:00:00`
        const endDate = `${this.filters.year}-12-31 23:59:59`

        params['contactThreadCartBoxProduct_gte_contactThreadCartBox-contactThreadCartOrder-createdAt'] = startDate
        params['contactThreadCartBoxProduct_lte_contactThreadCartBox-contactThreadCartOrder-createdAt'] = endDate
      } else if (this.filters.year && this.filters.month) {
        const month = this.filters.month < 10 ? `0${this.filters.month}` : this.filters.month
        const { year } = this.filters
        const lastDay = new Date(year, month, 0).getDate()
        const startDate = `${year}-${month}-01 00:00:00`
        const endDate = `${year}-${month}-${lastDay} 23:59:59`

        params['contactThreadCartBoxProduct_gte_contactThreadCartBox-contactThreadCartOrder-createdAt'] = startDate
        params['contactThreadCartBoxProduct_lte_contactThreadCartBox-contactThreadCartOrder-createdAt'] = endDate
      }
      /* eslint-disable */
      const usersTree = await axiosIns.get('1/users/getStructure', { params })
      const data = usersTree.data.data.map(structureRecord => {
          if (!usersTree.data.data.find(e => e.user.id === structureRecord.user?.parentUser?.id)) {
              return { ...structureRecord, user: { ...structureRecord.user, parentUser: null } }
          }

          return { ...structureRecord }
      })
      this.items = await this.createNested(data || [], null) || []

      this.loading = false
      this.isTyping = false
    },
    // eslint-disable-next-line no-unused-vars
    async createNested(data, parentId = null) {
      const result = []

      // const roots = []
      // for (const item of data) {
      //   if (item.user.parentUser === null || data.find(({ user }) => user.parentUser?.id === item?.user?.parentUser.id)) {
      //       roots.push()
      //   }
      // }

        for (const item of data) {
          if (
              (parentId === null && item.user.parentUser === null) ||
              (item.user.parentUser && item.user.parentUser.id === parentId)
          )
          {
              const newItem = {
                  user: item.user,
                  products: item.products,
                  children: await this.createNested(data, item.user.id),
              }

              result.push(newItem)
          } else if (
              item.user.parentUser && !data.find(e => e.user?.id === item.user.parentUser?.id)
          ) {
              const newItem = {
                  user: item.user,
                  products: item.products,
                  children: await this.createNested(data.filter(e => item?.user?.id === e?.user?.id), item.user.id),
              }

              result.push(newItem)
          }
      }

        return result
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
