<!--eslint-disable-->

<template>
  <div>
    <!--    Button: New Type    -->
    <b-button
        variant="primary"
        size="sm"
        @click="openEditModal({ name: '', })"
    >
      <feather-icon
          icon="PlusIcon"
          class="mr-25"
      />
      {{ $t('Add') }}
    </b-button>

    <!--    Type list    -->
    <b-row class="d-flex flex-row-reverse">
      <b-col
          sm="12"
          md="12"
          lg="12"
      >
        <!--    Categories View    -->
        <div class="mt-1">
          <b-card class="myTableOffer">
            <b-table
                :busy="loading"
                :items="items"
                :fields="fields"
                striped
                :style="{ minHeight: tableSize(items, 100) }"
                show-empty
                responsive
                hover
                class="offerTable"
            >
              <!--    Table Head    -->
              <template #head()="item">
                <span class="text-nowrap">
                  {{ $tc(item.field.label) | truncate(34, '...') }}
                </span>
              </template>

              <!--    Name    -->
              <template #cell(name)="{ value }">
                {{ value }}
              </template>

              <!--    CreatedBy    -->
              <template #cell(createdBy)="{ value }">
                <avatar :user="value" />
              </template>
              <!--    Table Action    -->
              <template #cell(action)="{ item, index }">
                <b-dropdown
                    variant="link"
                    class="dropdown-icon-wrapper text-decoration-none"
                    no-caret
                >
                  <template
                      #button-content
                      class="sr-only"
                  >
                    <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="align-middle"
                    />
                  </template>

                  <!--    Action: Delete    -->
                  <b-dropdown-item @click="deleteProcess(item, index)">
                    {{ $t('Delete') }}
                  </b-dropdown-item>
                </b-dropdown>
              </template>

              <!--    Other    -->
                <!--    Other    -->
                <template #cell()="row">
                    <table-default-cell
                            :field="row.field"
                            :value="row.value"
                    />
                </template>

                <!--      Empty      -->
                <template #empty>
                    <empty-content />
                </template>

                <!--      Table Busy      -->
                <template #table-busy>
                    <div class="text-center text-danger my-2">
                        <b-spinner
                                class="align-middle"
                                variant="primary"
                        />
                    </div>
                </template>
            </b-table>
          </b-card>
        </div>
      </b-col>
    </b-row>

    <!--    Modal: Create/Edit Type     -->
    <b-modal
        :visible="!!editedField"
        :title="$t('SmsSender')"
        size="md"
        hide-footer
        no-close-on-backdrop
        @close="closeModal"
    >
      <div v-if="!!editedField">
        <b-form-group :label="$t('Name')">
          <b-form-input
              v-model="editedField.name"
              :placeholder="$t('Name')"
          />
        </b-form-group>
        <!--        <b-form-checkbox-->
        <!--          v-model="editedField.attachToElectronicSignature"-->
        <!--          class="my-50 custom-control-primary"-->
        <!--          variant="primary"-->
        <!--        >{{ $t('AttachToElectronicSignature') }}-->
        <!--        </b-form-checkbox>-->
      </div>
      <b-button
          variant="primary"
          size="sm"
          @click="pushToServer"
      >
        {{ $t('Save') }}
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import { BTable, VBTooltip } from 'bootstrap-vue'
import payloadKeysCutter from '@/helpers/keysCuter'
import { formatDate } from '@core/utils/filter'
import axiosIns from '@/libs/axios'

export default {
  components: {
    BTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data: () => ({
    loading: false,
    editedField: null,
    editedIndex: -1,
    fields: [
      { key: 'name', label: 'Name' },
      { key: 'createdAt', label: 'CreatedAt' },
      { key: 'createdBy', label: 'CreatedBy' },
      { key: 'action', label: 'Action' },
    ],
    items: [],
  }),
  mounted() {
    this.getFromServer()
  },
  methods: {
    formatDate,
    async getFromServer() {
      this.loading = true

      try {
        const resp = await axiosIns.get('1/settings/smsSender', { params: { fields_load: this.$fields.BASE_FIELDS } })

        if (resp?.data?.data?.items) this.items = resp.data.data.items || []
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.loading = false
      }
    },
    async pushToServer() {
      const items = JSON.parse(JSON.stringify(this.editedField))
      const preparedItems = payloadKeysCutter(items)

      try {
        await axiosIns.post('1/settings/smsSender', preparedItems)

        this.showToast('success', this.$i18n.t('success.contactUpdated'))
        this.closeModal()
        this.getFromServer()
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.loading = false
      }
    },

    /// Open Edit Modal ///
    openEditModal(item, index = -1) {
      this.editedField = JSON.parse(JSON.stringify(item))
      this.editedIndex = index
    },
    closeModal() {
      this.editedField = null
      this.editedIndex = -1
    },
    deleteProcess(item, index) {
      this.showAlert('error', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('DeleteWillCauseDeletingImages'))
        .then(() => {
          this.delete(item.id, index)
        })
    },
    async delete(id, index) {
      this.loading = true

      try {
        await axiosIns.delete('1/settings/smsSender', { data: JSON.stringify([id]) })

        this.$delete(this.items, index)
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
