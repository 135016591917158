<template>
  <span
    v-if="icon"
    @click="$emit('click')"
  >
    <feather-icon
      v-if="isFeatherIcon"
      :icon="icon"
      v-bind="{ ...$attrs }"
    />
    <font-awesome-icon
      v-else-if="isFontAwesomeIcon"
      :icon="icon"
      v-bind="{ ...$attrs }"
    />
    <v-icon
      v-else
      :name="icon"
      v-bind="{ ...$attrs }"
    />
  </span>
</template>

<script>
export default {
  name: 'Icon',
  props: {
    icon: {
      default: '',
      required: true,
      type: String,
    },
  },
  emits: ['click'],
  computed: {
    isFeatherIcon() {
      return this.icon.endsWith('Icon')
    },
    isFontAwesomeIcon() {
      return this.icon.startsWith('fa-')
    },
  },
}
</script>

<style scoped>

</style>
