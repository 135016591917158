<template>
  <div>
    <div class="d-flex flex-wrap flex-row justify-content-between align-content-center align-items-center">
      <div class="pr-1">
        <sw-select>
          <sw-select-fpr
            :is-empty="dateFilter"
            @clear="dateFilter = ''"
          >
            <flat-pickr
              v-model="dateFilter"
              style="min-width: 300px"
              class="form-control"
              :config="{ mode: 'range', locale: getCalendarLocale($i18n.locale) }"
              :placeholder="$t('DateOfReceiptOfApplication')"
              @input="ini()"
            />
          </sw-select-fpr>
        </sw-select>
      </div>

      <b-form-group>
        <b-form-radio-group
          v-model="activeFilter"
          :options="filterCreatedAtOptions"
          buttons
          button-variant="outline-primary"
          size="sm"
          class="flex-wrap"
          style="row-gap: .25rem"
          @input="setDate"
        />
      </b-form-group>
    </div>

    <sw-tabs>
      <b-tab
        :title="$t('GeneralIndicators')"
        title-item-class="btn btn-sm p-0"
        title-link-class="p-75"
      >
        <b-row>
          <b-col
            md="6"
            class="d-flex flex-column"
          >
            <label
              class="d-flex flex-row align-items-center"
              style="column-gap: .34rem"
            >
              {{ $t('ConversionRateOfAllSubmissions') }}

              <feather-icon
                v-b-tooltip.hover.v-primary
                icon="InfoIcon"
                :title="$t('ConversionAllTooltip')"
              />
            </label>

            <b-alert
              variant="primary"
              show
            >
              <div class="alert-body text-center px-25 py-25">
                {{ percentOfConvertedApplications }}%
              </div>
            </b-alert>
          </b-col>

          <b-col
            md="6"
            class="d-flex flex-column"
          >
            <label
              class="d-flex flex-row align-items-center"
              style="column-gap: .34rem"
            >
              {{ $t('AverageTurnaroundTimeForAllRequests') }}

              <feather-icon
                v-b-tooltip.hover.v-primary
                icon="InfoIcon"
                :title="$t('AverageTooltip')"
              />
            </label>

            <b-alert
              variant="primary"
              show
            >
              <div class="alert-body text-center px-25 py-25">
                {{ secondsToHours(averageTime).hours }}{{ $t('Hour')[0] }}
                {{ secondsToHours(averageTime).minutes }}{{ $t('Minute')[0] }}
              </div>
            </b-alert>
          </b-col>
        </b-row>
      </b-tab>

      <b-tab
        :title="$t('Details')"
        title-item-class="btn btn-sm p-0"
        title-link-class="px-50 py-75"
      >
        <!-- eslint-disable -->
            <b-table
              :items="items"
              :fields="fields"
              striped
              responsive
              :busy="isLoading"
              show-empty
            >
              <template #head()="item">
                <div
                  class="d-flex flex-row align-items-center"
                  style="column-gap: .34rem"
                >
                  {{ $t(item.label) }}

                  <feather-icon
                    v-if="item.field.tooltip"
                    v-b-tooltip.hover.v-primary
                    :title="item.field.tooltip ? $t(item.field.tooltip) : ''"
                    icon="InfoIcon"
                  />
                </div>
              </template>

              <template #cell(type)="{ value }">
                <b-badge variant="primary" class="text-capitalize">
                  {{ value.replaceAll('_', ' ').toLowerCase() }}
                </b-badge>
              </template>

                <template #cell(accepted)="{ item }">
                    <b-badge variant="light-primary">
                      {{ item.contactApplications.accepted }}
                    </b-badge>
                </template>

                <template #cell(total)="{ item }">
                    <b-badge variant="light-primary">
                      {{ item.contactApplications.all }}
                    </b-badge>
                </template>

                <template #cell(closedPositive)="{ item }">
                    <b-badge variant="light-success">
                        {{ item.contactApplications.closed_positive }}
                    </b-badge>
                </template>

              <template #cell(rejected)="{ item }">
                  <b-badge variant="light-primary">
                      {{ item.contactApplications.rejected }}
                  </b-badge>
              </template>

              <template #cell(closedNegative)="{ item }">
                <b-badge variant="light-danger">
                  {{ item.contactApplications.closed_negative }}
                </b-badge>
              </template>

              <template #cell(conversionRate)="{ item }">
                <b-badge variant="light-primary" style="min-width: fit-content" :style="{ width: Math.floor((item.contactApplications.closed_positive / (item.contactApplications.all || 1)) * 100) + '%' }">
                  {{ Math.floor((item.contactApplications.closed_positive / (item.contactApplications.all || 1)) * 100) || 0 }}%
                </b-badge>
              </template>

              <!--    Other    -->
                <!--    Other    -->
                <template #cell()="row">
                    <table-default-cell
                            :field="row.field"
                            :value="row.value"
                    />
                </template>

                <!--      Empty      -->
                <template #empty>
                    <empty-content />
                </template>

                <!--      Table Busy      -->
                <template #table-busy>
                    <div class="text-center text-danger my-2">
                        <b-spinner
                                class="align-middle"
                                variant="primary"
                        />
                    </div>
                </template>
            </b-table>
      </b-tab>
    </sw-tabs>
  </div>
</template>

<script>
import {
  BFormRadioGroup, BTab, BTable, VBPopover, VBTooltip,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import axiosIns from '@/libs/axios'
import moment from 'moment'
import { Polish as pl } from '@/libs/i18n/locales/flatpickr/pl'
import { english as en } from '@/libs/i18n/locales/flatpickr/en'

export default {
  components: {
    BTable,
    BTab,
    flatPickr,
    BFormRadioGroup,
  },

  directives: {
    'b-popover': VBPopover,
    'b-tooltip': VBTooltip,
  },

  data: () => ({
    isLoading: false,

    dateFilter: null,
    activeFilter: '',
    searchQuery: '',

    filterCreatedAtOptions: [],

    items: [],
    tableFields: [
      { key: 'type', label: 'ConnectionType', visible: true },
      { key: 'name', label: 'ConnectionSource', visible: true },
      { key: 'total', label: 'TotalNumberSubmissions', visible: true },
      {
        key: 'accepted', label: 'NumberTaken', visible: true,
      },
      { key: 'rejected', label: 'NumberOfRejected', visible: true },
      {
        key: 'closedPositive', label: 'NumberOfSuccesses', visible: true, tooltip: 'SuccessCountTooltip',
      },
      {
        key: 'closedNegative', label: 'NumberOfLosses', visible: true, tooltip: 'FailureCountTooltip',
      },
      {
        key: 'conversionRate', label: 'ConversionRate', visible: true, tooltip: 'ConversionTooltip',
      },
    ],

    percentOfConvertedApplications: 0,
    averageTime: 0,
  }),

  computed: {
    en() {
      return en
    },
    pl() {
      return pl
    },
    fields() {
      return this.tableFields.filter(field => field.visible)
    },
  },

  mounted() {
    this.ini()
  },

  methods: {
    setDate(dateValue) {
      if (dateValue === 'last7Days') {
        const startAt = moment().subtract(7, 'days').format('YYYY-MM-DD')

        this.dateFilter = [startAt]
      }

      if (dateValue === 'thisMonth') {
        const startAt = moment().startOf('month').format('YYYY-MM-DD')
        const endAt = moment().endOf('month').format('YYYY-MM-DD')

        this.dateFilter = [startAt, endAt]
      }

      if (dateValue === 'lastMonth') {
        const startAt = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD')
        const endAt = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')

        this.dateFilter = [startAt, endAt]
      }

      if (dateValue === 'last6Months') {
        const startAt = moment().subtract(6, 'months').startOf('month').format('YYYY-MM-DD')
        const endAt = moment().format('YYYY-MM-DD hh:mm')

        this.dateFilter = [startAt, endAt]
      }

      if (dateValue === null) this.dateFilter = ''
    },
    async ini() {
      this.filterCreatedAtOptions = [
        { text: this.$t('All'), value: null },
        { text: this.$t('Last7Days'), value: 'last7Days' },
        { text: this.$t('ThisMonth'), value: 'thisMonth' },
        { text: this.$t('LastMonth'), value: 'lastMonth' },
        { text: this.$t('Last6Months'), value: 'last6Months' },
      ]

      try {
        const params = {}

        if (this.dateFilter) {
          const [startAt, endAt] = this.dateFilter.dateRange()
          if (startAt) params.gte_createdAt = moment(startAt, 'YYYY-MM-DD').format('YYYY-MM-DD hh:mm')
          if (endAt) params.lt_createdAt = moment(endAt, 'YYYY-MM-DD').format('YYYY-MM-DD hh:mm')
        }

        const { data } = await axiosIns.get('1/reports/contactApplications/contactApplicationIntegrations', { params })
        const items = data.data?.contactApplications || {}
        this.loadData(items)
      } catch (err) {
        console.error(err)
      }
    },

    loadData({
      closed_positive, all, servedTimeAvg, contactApplicationIntegrations,
    }) {
      this.items = contactApplicationIntegrations
      this.averageTime = servedTimeAvg
      // eslint-disable-next-line camelcase
      this.percentOfConvertedApplications = Math.round((closed_positive / all) * 100) || 0
    },
  },
}
</script>
