var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-button',{staticClass:"mb-25",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){_vm.isModalOpen = true}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_vm._v(" "+_vm._s(_vm.$t('Add'))+" ")],1),_c('b-card',{staticClass:"mt-1",attrs:{"id":"docs"}},[_c('sw-table',{attrs:{"table-id":"a1d1ff0aa8394a68b443aec0aa8c27e7","pagination":_vm.pagination},on:{"change-pagination":function($event){return Object.assign(_vm.pagination, $event)},"reload-content":_vm.getPolls},scopedSlots:_vm._u([{key:"table",fn:function(){return [_c('b-table',{staticClass:"table-wrap-words rounded",attrs:{"items":_vm.polls,"fields":_vm.pollFields,"striped":"","responsive":"","busy":_vm.loading,"show-empty":""},scopedSlots:_vm._u([{key:"head()",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(("" + label)))+" ")]}},{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'polls', params: { pollId: item.id } }}},[_c('span',{staticClass:"text-primary"},[_vm._v(_vm._s(item.name))])])]}},{key:"cell(createdBy)",fn:function(ref){
var value = ref.value;
return [(value)?_c('span',{staticClass:"d-flex align-items-center text-nowrap"},[_c('b-avatar',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-25 pull-up",attrs:{"size":"24","src":value.avatar,"title":value.firstName + ' ' + value.lastName}}),_c('span',{staticClass:"ml-25"},[_vm._v(" "+_vm._s(value.firstName)+" "+_vm._s(value.lastName)+" ")])],1):_c('span',[_c('feather-icon',{attrs:{"icon":"MinusIcon"}})],1)]}},{key:"cell(action)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('b-dropdown',{attrs:{"id":"dropdown-dropleft","dropleft":"","no-caret":"","variant":"flat"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{attrs:{"icon":"MoreVerticalIcon"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.copyPoll(item, index)}}},[_c('dropdown-item-label',{attrs:{"icon":"CopyIcon","label":_vm.$t('Copy')}})],1),_c('b-dropdown-item',{on:{"click":function($event){return _vm.editPoll(item, index)}}},[_c('dropdown-item-label',{attrs:{"icon":"EditIcon","label":_vm.$t('Edit')}})],1),_c('b-dropdown-item',{on:{"click":function($event){return _vm.removePoll(item, index)}}},[_c('dropdown-item-label',{attrs:{"icon":"TrashIcon","label":_vm.$t('Delete')}})],1)],1),(false)?_c('feather-icon',{attrs:{"icon":"MinusIcon"}}):_vm._e()]}},{key:"cell()",fn:function(row){return [_c('table-default-cell',{attrs:{"field":row.field,"value":row.value}})]}},{key:"empty",fn:function(){return [_c('empty-content')]},proxy:true},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[_c('b-spinner',{staticClass:"align-middle",attrs:{"variant":"primary"}})],1)]},proxy:true}])})]},proxy:true}])})],1),_c('b-modal',{attrs:{"visible":_vm.isModalOpen,"title":_vm.$t('Poll'),"hide-footer":"","no-close-on-backdrop":""},on:{"hide":function($event){_vm.isModalOpen = false; _vm.editedPoll = { poll: null, index: -1 }}}},[_c('new-poll-modal',{attrs:{"edited-poll":_vm.editedPoll.poll},on:{"save-item":_vm.saveItem}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }