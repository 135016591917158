<template>
  <div>
    <div v-if="!isLoading">
      <app-collapse
        v-if="checklist.length > 0"
        class="mt-1"
        type="margin"
      >
        <app-collapse-item
          v-for="(category, i) in checklist"
          :key="i"
          :title="category.name | truncate(50)"
          is-visible
        >
          <template #title>
            <div
              class="d-flex align-items-center"
              style="gap: .25rem"
            >
              {{ category.name | truncate(50) }}
            </div>
          </template>

          <div
            v-for="(time, index) in category.list"
            :key="index"
          >
            <div class="d-flex flex-column flex-md-row justify-content-between align-items-md-center flex-wrap flex-md-nowrap">
              <div class="d-flex flex-wrap">
                <b-form-checkbox
                  v-model="time.checked"
                  variant="primary"
                  disabled
                  inline
                  class="mr-0"
                />

                <h5 class="text-nowrap ml-25">
                  {{ time.name | truncate(50) }}
                </h5>
              </div>

              <div
                v-if="time.checked"
                class="d-flex align-items-center"
              >
                <span>
                  <feather-icon icon="ClockIcon" />
                  {{ time.doneAt }}
                </span>

                <span class="ml-50">
                  <b-avatar
                    v-if="time.updatedBy"
                    v-b-tooltip.hover
                    :title="`${time.updatedBy.firstName} ${time.updatedBy.lastName}`"
                    class="pull-up"
                    :src="time.updatedBy.avatar"
                    :text="`${time.updatedBy.firstName[0]} ${time.updatedBy.lastName[0]}`"
                    variant="light-primary"
                    size="16"
                  />
                </span>
              </div>
            </div>
            <hr>
          </div>
        </app-collapse-item>
      </app-collapse>

      <div
        v-else
        class="text-center py-1 text-primary font-weight-bold"
      >
        {{ $t('NoData') }}
      </div>
    </div>

    <div
      v-else
      class="d-flex justify-content-center"
    >
      <b-spinner
        variant="primary"
        class="m-5"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axiosIns from '@/libs/axios'
import { VBTooltip } from 'bootstrap-vue'
import * as fields from '@/@constants/fields'

export default {
  data: () => ({
    isLoading: false,
    checklist: [],
  }),
  computed: {
    ...mapGetters({
      modalConfig: 'modal/getModalState',
    }),
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mounted() {
    this.ini()
  },
  methods: {
    ini() {
      const { id, contact, contactThreadChecklistTypes } = this.modalConfig.thread

      const checklist = [...contactThreadChecklistTypes.map(type => ({
        id: type.id,
        name: type.name,
        list: [],
      }))]

      this.isLoading = true

      contactThreadChecklistTypes.forEach(type => {
        const checklistId = type.id
        const threadId = id
        const contactId = contact.id
        const params = {
          fields_load: fields.CHECKLIST_POINT_DATUM,
        }

        axiosIns.get(`1/contacts/${contactId}/threads/${threadId}/checklistType/${checklistId}/pointDatum`, { params })
          .then(res => {
            type.contactThreadChecklistTypePoints.forEach(point => {
              // eslint-disable-next-line no-underscore-dangle
              const _point = { ...point }
              const datumItem = res.data.data.items.find(item => item.contactThreadChecklistTypePoint.id === point.id)
              if (datumItem) {
                _point.datumId = datumItem.id
                _point.checked = datumItem.checked
                _point.updatedBy = datumItem.updatedBy
                // eslint-disable-next-line prefer-destructuring
                _point.doneAt = datumItem.doneAt ? datumItem.doneAt.date.split('.')[0] : null
              } else {
                _point.datumId = null
                _point.checked = false
                _point.doneAt = null
              }

              checklist.find(el => el.id === type.id).list.push(_point)
            })
          })
          .catch(err => {
            console.error(err)
          })
      })

      this.checklist = checklist
      this.isLoading = false
    },
  },
}
</script>
