import {
  GET_AGREEMENTS,
  UPDATE_AGREEMENTS,
} from '@/@constants/mutations'
// eslint-disable-next-line import/no-cycle
import axiosIns from '@/libs/axios'
import axios from 'axios'
import * as fields from '@/@constants/fields'
import moment from 'moment'

// eslint-disable-next-line no-unused-vars
const resolveFilters = filters => {
  const params = {}

  if (filters?.agreementStatuses && Array.isArray(filters.agreementStatuses) && filters.agreementStatuses.length) {
    params.eqArr_status = JSON.stringify(filters.agreementStatuses)
  }

  if (filters?.offerStatuses && Array.isArray(filters.offerStatuses) && filters.offerStatuses.length) {
    params['eqArr_contactThreadOffer-status'] = JSON.stringify(filters.offerStatuses)
  }

  if (filters?.type) {
    // params['eq_1_agreementTemplate-agreementType'] = typeof filters.type === 'object' ? filters.type?.id : filters.type
    params.eq_agreementType = typeof filters.type === 'object' ? filters.type?.id : filters.type
  }

  if (filters?.createdAt) {
    const value = filters.createdAt.dateRange()
    if (value[0]) {
      // eslint-disable-next-line prefer-destructuring
      params.gte_createdAt = moment(value[0], 'DD-MM-YYYY hh:mm').format('YYYY-MM-DD hh:mm')
    }
    if (value[1]) {
      // eslint-disable-next-line prefer-destructuring
      params.lte_createdAt = moment(value[1], 'DD-MM-YYYY hh:mm').format('YYYY-MM-DD hh:mm')
    }
  }

  if (filters?.assignedUsers) {
    params['eqArr_contactThread-assignedUsers-id'] = JSON.stringify(
        filters?.assignedUsers.map(user => (typeof user === 'object' ? user.id : user)),
    )
  }

  if (filters?.signerUser) {
    params.eqArr_signerUser = JSON.stringify(
        filters?.signerUser.map(user => (typeof user === 'object' ? user.id : user)),
    )
  }

  if (filters?.createdBy) {
    params.eqArr_createdBy = JSON.stringify(
        filters?.createdBy.map(user => (typeof user === 'object' ? user.id : user)),
    )
  }

  if (filters?.contacts && filters.contacts.length) {
    params['eqArr_contactThread-contact-id'] = JSON.stringify(filters.contacts)
  }

  if (filters?.funnelPoint) {
    params['eq_contactThread-contactThreadFunnelProcesses-contactThreadFunnelTypePoint-id'] = filters.funnelPoint
  }
  if (filters?.funnel) {
    params['eq_contactThread-contactThreadFunnelProcesses-contactThreadFunnelTypePoint-contactThreadFunnelType-id'] = filters.funnel
  }
  if (filters?.thread) {
    params['eq_contactThread-id'] = filters.thread
  }

  if (filters?.showSentOffers) {
    params['eqArr_contactThreadOffer-contactThreadOfferMails-mail-status'] = JSON.stringify([
      'RECEIVED',
      'DELIVERED',
      'OPENED',
    ])
  }

  if (filters?.electronicSignatureType) params.eq_electronicSignatureType = filters.electronicSignatureType

  return params
}

let source
export default {
  namespaced: true,
  actions: {
    // eslint-disable-next-line no-unused-vars
    [GET_AGREEMENTS]: ({ commit }, {
      contactId = null, threadId = null, pagination, filters, searchQuery = '', sorter,
      // eslint-disable-next-line no-async-promise-executor
    }) => new Promise(async (resolve, reject) => {
      if (typeof source !== typeof undefined) source.cancel('Operation canceled due to new request.')

      source = axios.CancelToken.source()

      const params = {
        fields_load: fields.AGREEMENTS,
        orderBy: 'createdAt.desc',
        ...resolveFilters(filters),
      }

      if (pagination) {
        params.page = pagination.currentPage
        params.limit = pagination.perPage
      }

      if (sorter) {
        const sortDir = sorter?.sortDesc ? 'desc' : 'asc'
        const sortBy = sorter?.sortBy || 'id'
        params.orderBy = `${sortBy}.${sortDir}`
      }

      if (searchQuery && searchQuery.length) {
        // eslint-disable-next-line no-underscore-dangle
        const _search = searchQuery.replaceAll(' ', '%')
        params.search = `%${_search}%`
        params.search_fields = [
          'contactThread-contact-firstName',
          'contactThread-contact-lastName',
          'contactThread-contact-mailAddress-mail',
        ].join(',')
      }

      const url = threadId && contactId ? `1/contacts/${contactId}/threads/${threadId}/agreements` : '1/contacts/threads/agreements'

      axiosIns.get(url, { params })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [UPDATE_AGREEMENTS]: ({ commit }, {
      contactId, threadId, pollId, options,
    }) => new Promise((resolve, reject) => {
      /* eslint-disable */
      options.forEach(option => {
        delete option.updatedAt
        delete option.createdAt
        delete option.deletedAt
        delete option.updatedBy
        delete option.createdBy
        delete option.deletedBy
      })
      axiosIns.put(`1/contacts/${contactId}/threads/${threadId}/polls/${pollId}/fields`, options)
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
  },
}

// Test
