import axios from 'axios'
// eslint-disable-next-line import/no-cycle
import { localHistory } from '@/router'
import moment from 'moment/moment'

// eslint-disable-next-line import/prefer-default-export
export const discordWebhook = () => {
  const makeRequest = ({ color = 16711680, errorLog, message = '' }) => {
    const user = JSON.parse(localStorage.getItem('user') || '{}')
    if (user?.id) {
      axios.post('https://discord.com/api/webhooks/1299290776441847818/N30R5syWfMpi347ZSkCcImXCp93yJ_VuUeZUzCEJxCipNYZ-scmL9Pihhib9QbXsNzQG', {
        embeds: [
          {
            color,
            title: window.location.href,
            description: `
**${message ? 'Info' : 'Error'}**: \n\`\`\`${errorLog?.message || message || 'Unknown error'}\`\`\`
**Stack**: \n\`\`\`${errorLog?.stack || 'No stack trace available'}\`\`\`
**Router**: \n\`\`\`${localHistory.map(e => `[${e?.from?.fullPath}] -----> ${e?.to?.fullPath}`).join(',\n') || 'No stack trace available'}\`\`\`
`,
            fields: [
              {
                name: 'Created At',
                value: moment().format('YYYY-MM-DD HH:mm:ss'),
                inline: false,
              },
              {
                name: 'User',
                value: `${user.firstName} ${user.lastName}`,
                inline: true,
              },
              {
                name: 'Role',
                value: user?.rank?.name,
                inline: true,
              },
            ],
          },
        ],
      })
    }
  }

  const pushErrorMessage = log => {
    makeRequest({ errorLog: log })
  }

  const pushInfoMessage = message => {
    makeRequest({ color: 3447003, message })
  }

  const pushWarningMessage = message => {
    makeRequest({ color: 15105570, message })
  }

  return {
    pushErrorMessage,
    pushInfoMessage,
    pushWarningMessage,
  }
}
