<template>
  <div>
    <b-row v-if="!loading"
           style="width: 100%; max-width: 850px"
    >
      <b-col v-for="campaign in campaigns"
             :key="`campaign_${campaign.id}`"
             sm="12"
             md="6"
      >
        <b-card>{{ campaign.name }}
          <b-badge :variant="getStatusByValue(campaign.status).variant">
            {{ $t(getStatusByValue(campaign.status).label) }}
          </b-badge>
        </b-card>
      </b-col>
    </b-row>
    <div v-else
         class="text-center text-danger my-2"
         style="max-width: 850px"
    >
      <b-spinner
        class="align-middle"
        variant="primary"
      />
    </div>
  </div>
</template>

<script>
import axiosIns from '@/libs/axios'

export default {
  name: 'GoogleAdsCampaigns',
  props: {
    googleAdsAccount: {
      required: true,
      default: null,
    },
  },
  data: () => ({
    campaigns: [],
    loading: true,
  }),
  mounted() {
    this.loadCampaigns()
  },
  methods: {
    getStatusByValue(value) {
      const statuses = [
        { label: 'GoogleStatuses.Unknown', key: 'UNKNOWN', variant: 'light-secondary' },
        { label: 'GoogleStatuses.Unspecified', key: 'UNSPECIFIED', variant: 'light-secondary' },
        { label: 'GoogleStatuses.Enabled', key: 'ENABLED', variant: 'light-success' },
        { label: 'GoogleStatuses.Paused', key: 'PAUSED', variant: 'light-warning' },
        { label: 'GoogleStatuses.Removed', key: 'REMOVED', variant: 'light-danger' },
      ]

      if (statuses.findWhere('key', value)) return statuses.findWhere('key', value)

      return { label: '-', key: '-', variant: 'secondary' }
    },
    async loadCampaigns() {
      try {
        this.loading = true

        const response = await axiosIns.get(`1/integration/google/connections/googleAdsAccounts/${this.googleAdsAccount.integrationGoogleConnectionGoogleAdsAccount.id}/clients/${this.googleAdsAccount.googleAdsClientId}/campaigns`)
        this.campaigns = response.data?.data?.items || []
        // const response = await axiosIns.get(`1/integration/google/connections/${integrationGoogleConnection}/googleAdsAccounts/${googleAdsAccount}/clients/${googleAdsClientId}/campaigns`)
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>

</style>
