import { Pie } from 'vue-chartjs'

export default {
  extends: Pie,
  props: {
    data: {
      type: Object,
      default: () => ({
        labels: ['Wybrał PIPEDRIVE', 'Wybrał BITRIX', 'Zamknął firmę'],
        datasets: [
          {
            backgroundColor: [
              '#41B883',
              '#E46651',
              '#00D8FF',
              '#DD1B16',
            ],
            data: [1, 10, 1],
          },
        ],
      }),
    },
    options: {
      type: Object,
      default: () => ({
        responsive: true,
        maintainAspectRatio: false,
        cutoutPercentage: 50,
        legend: { display: false },
      }),
    },
  },
  watch: {
    data: {
      deep: true,
      handler(newData, oldData) {
        if (!Object.is(newData, oldData)) {
          this.renderChart(this.data, {
            ...this.options,
          })
        }
      },
    },
  },
  mounted() {
    this.renderChart(this.data, {
      ...this.options,
    })
  },
}
