<template>
  <b-row style="row-gap: .34rem">
    <!--    START:Status and Title    -->
    <b-col
      cols="12"
      md="6"
      lg="2"
    >
      <div
        class="d-flex flex-nowrap"
        style="row-gap: .14rem; column-gap: .5rem;"
      >
        <div
          v-b-tooltip.hover="{ variant: getEventVariant() }"
          :class="`bg-${getEventVariant()}`"
          style="min-width: 1rem; width: 1rem; height: 1rem; border-radius: .2rem; margin-top: .1rem;"
          :title="$t(`task.status.${data.extendedProps.status}`)"
        />

        <strong class="text-wrap">
          {{ data.title }}
        </strong>
      </div>
    </b-col>
    <!--    END:Status and Title    -->

    <!--    START:Thread    -->
    <b-col
      cols="12"
      md="6"
      lg="3"
    >
      <div
        class="d-flex flex-wrap"
        style="row-gap: .14rem; column-gap: .5rem;"
      >
        <strong class="text-wrap">{{ $t('Process') }}:</strong>

        <b-link
          v-if="data.extendedProps.contactThread && data.extendedProps.contactThread.contact"
          :href="`contact/${data.extendedProps.contactThread.contact.id}/thread/${data.extendedProps.contactThread.id}/timeline`"
          target="_blank"
          @click.stop
        >
          <b-badge
            v-if="data.extendedProps.contactThread"
            variant="light-primary"
            class="text-wrap d-flex flex-wrap align-items-center"
            style="column-gap: .24rem; row-gap: .14rem; text-align: start;"
          >
            <div
              class="d-flex align-items-center"
              style="column-gap: .24rem; row-gap: .14rem; text-align: start;"
            >
              <feather-icon
                icon="LinkIcon"
                style="min-width: max-content;"
              />

              {{ `${data.extendedProps.contactThread.contact.firstName} ${data.extendedProps.contactThread.contact.lastName}` }}
            </div>

            <div
              class="d-flex align-items-center"
              style="column-gap: .24rem; row-gap: .14rem; text-align: start;"
            >
              <feather-icon
                icon="ArrowRightIcon"
                style="min-width: max-content;"
              />

              {{ data.extendedProps.contactThread.name }}
            </div>
          </b-badge>
        </b-link>

        <feather-icon
          v-else
          icon="MinusIcon"
        />
      </div>
    </b-col>
    <!--    END:Thread    -->

    <!--    START:Content    -->
    <b-col class="overflow-hidden">
      <div
        class="d-flex flex-wrap"
        style="row-gap: .14rem; column-gap: .5rem;"
      >
        <strong class="text-wrap">{{ $t('Content') }}:</strong>

        {{ data.extendedProps.content | truncate(24) }}
      </div>
    </b-col>
    <!--    END:Content    -->

    <!--    START:Assigned User    -->
    <b-col
      cols="12"
      md="6"
      lg="2"
    >
      <div
        class="d-flex flex-wrap"
        style="row-gap: .14rem; column-gap: .5rem;"
      >
        <strong class="text-wrap">{{ $t('AssignedUser') }}:</strong>

        <avatar
          :user="data.extendedProps.assignedUser"
          show-tooltip
          text-variant="text-dark"
          size="1.24rem"
        />
      </div>
    </b-col>
    <!--    END:Assigned User    -->

    <!--    START:Created By    -->
    <b-col
      cols="12"
      md="6"
      lg="2"
    >
      <div
        class="d-flex flex-wrap"
        style="row-gap: .14rem; column-gap: .5rem;"
      >
        <strong class="text-wrap">{{ $t('CreatedBy') }}:</strong>

        <avatar
          :user="data.extendedProps.createdBy"
          show-tooltip
          text-variant="text-dark"
          size="1.24rem"
        />
      </div>
    </b-col>
    <!--    END:Created By    -->
  </b-row>
</template>

<script>
import { formatDate } from '@core/utils/filter'
import { VBTooltip } from 'bootstrap-vue'

export default {
  name: 'ListMonthComponent',
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    data: {
      default: null,
      type: Object,
      required: true,
    },
  },
  data: () => ({
    uid: Math.random().toString(16).slice(2),
  }),
  methods: {
    getEventVariant() {
      switch (this.data.extendedProps.status) {
        case 'O': return 'info'
        case 'D': return 'success'
        case 'ND': return 'danger'
        default: return 'primary'
      }
    },

    formatDate,
    taskTypeVariant(value) {
      let variant = 'light-primary'
      if (value === 'D') variant = 'light-success'
      else if (value === 'O') variant = 'light-info'
      else if (value === 'ND') variant = 'light-danger'
      return variant
    },
  },
}
</script>

<style scoped>

</style>
