<template>
  <div>
    <!-- eslint-disable -->
    <sw-table
      table-id="4b35dc8eb0414c1c927a8f779d5b8d1f"
      show-columns
      show-search
      :show-pagination="false"
      :fields.async="pointsFields"
      :pagination="pagination"
      @change-pagination="Object.assign(pagination, $event)"
      @change-search-query="search = $event"
      @reload-content="loadData"
      @set-fields="pointsFields = $event"
    >
      <template #table>
        <!--      Table      -->
        <b-table
          :items="points"
          :fields="fields"
          striped
          responsive
          :busy="loading"
          show-empty
        >
          <!--    Head    -->
          <template #head()="{ label }">
            {{ $t(`${label}`) }}
          </template>
            <template #cell(id)="{ index }">
                #{{ getOrderNo(index, pagination, sorter) }}
            </template>
          <!--    Status    -->
          <template #cell(status)="{ value }">
            <b-badge variant="light-primary">
              {{ $t(`LoyaltyPointsStatuses.${value}`) }}
            </b-badge>
          </template>          <!--    Assigned User    -->
          <template #cell(user)="{ value }">
            <avatar :user="value" />
          </template>
          <template #cell(createdBy)="{ value }">
            <avatar :user="value" />
          </template>
          <!--    Other    -->
            <!--    Other    -->
            <template #cell()="row">
                <table-default-cell
                        :field="row.field"
                        :value="row.value"
                />
            </template>

            <!--      Empty      -->
            <template #empty>
                <empty-content />
            </template>

            <!--      Table Busy      -->
            <template #table-busy>
                <div class="text-center text-danger my-2">
                    <b-spinner
                            class="align-middle"
                            variant="primary"
                    />
                </div>
            </template>
        </b-table>
      </template>
    </sw-table>
  </div>
</template>

<script>
import {
  BTable, VBTooltip,
} from 'bootstrap-vue'
import { formatDate } from '@core/utils/filter'
import axiosIns from '@/libs/axios'

export default {
  components: {
    BTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    filters: {
      type: Object,
      default: () => {},
    },
    threadId: {
      type: String,
      required: false,
      default: null,
    },
  },
  data: vm => ({
    loading: false,
    sorter: {
      sortBy: 'createdAt',
      sortDesc: true,
    },
    pagination: {
      perPage: 25,
      totalRecords: 0,
      currentPage: 1,
    },
    search: '',

    pointsFields: [
      {
        key: 'id', label: '#', visible: true, sortable: true,
      },
      { key: 'user', label: 'User', visible: true },
      // { key: 'loyaltyPlan', label: 'LoyaltyPlan', visible: true },
      {
        key: 'loyaltyPlansRealizedPoints', label: 'TotalPointsAmount', visible: true, requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_ACTUAL_POINTS_COLUMN_TOTAL_POINTS],
      },
      {
        key: 'loyaltyPlansSpentPoints', label: 'SpentPointsAmount', visible: true, requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_ACTUAL_POINTS_COLUMN_SPENT_POINTS],
      },
      {
        key: 'loyaltyPlansAvailablePoints', label: 'ActualPointsAmount', visible: true, requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_ACTUAL_POINTS_COLUMN_ACTUAL_POINTS],
      },
      {
        key: 'loyaltyPlansHypotheticalPoints', label: 'HypotheticalPoints', visible: true, requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_ACTUAL_POINTS_COLUMN_HYPOTHETICAL_POINTS],
      },
    ],
    points: [],
    agreementTypes: [],
  }),
  computed: {
    fields() {
      return this.pointsFields.filter(field => field.visible)
        .filter(e => !e?.requiredViewRanks || this.checkRequiredViewPermissions(e.requiredViewRanks))
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.loadData()
      },
    },
  },
  async mounted() {
    await this.loadData()
  },
  methods: {
    formatDate,
    async loadData() {
      this.loading = true
      try {
        const params = {
          orderBy: 'id.desc',
        }

        // if (this.pagination) {
        //   params.user_page = this.pagination.currentPage
        //   params.user_limit = this.pagination.perPage
        // }

        if (this.search && this.search.length) {
          const searchString = this.search.replaceAll(' ', '%')
          params.user_search = `%${searchString}%`
          // params.loyaltyPlanPoint_search = `%${searchString}%`
          params.user_search_fields = 'firstName,lastName,userId'
          // params.loyaltyPlanPoint_search_fields = 'contactThreadCartBox-contactThreadCartOrder-number'
        }

        if (this.threadId) {
          params['loyaltyPlanPoint_eq_contactThreadCartBox-contactThread-id'] = this.threadId
        }

        // if (this.filters?.type) params.eq_status = this.filters.type
        if (this.filters?.loyaltyPlan) params['loyaltyPlanPoint_eq_loyaltyPlan-id'] = this.getObjectId(this.filters.loyaltyPlan)
        if (this.filters?.assignedUsers?.length) params.user_eqArr_id = JSON.stringify(this.filters?.assignedUsers.map(user => this.getObjectId(user)))
        if (this.filters.assignedUsersStructure) params['user_eqArr_1_parentUser-id'] = JSON.stringify(this.filters?.assignedUsers.map(user => this.getObjectId(user)))
        // if (this.filters.createdAt)
        // if (this.filters.agreementDate)
        // if (this.filters.orderDate)

        const resp = await axiosIns.get('1/loyaltyPlans/points/getStatistics', { params })
        this.points = resp.data.data ?? []
        this.pagination.totalRecords = resp.data.data?.totalItemCount ?? 0
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss">
</style>
