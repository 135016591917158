<template>
  <component
    :is="$route.name === 'user' ? 'app-collapse' : 'div'"
  >
    <component
      :is="$route.name === 'user' ? 'app-collapse-item' : 'div'"
      :is-visible="false"
      :title="$t('contact.basicInformations')"
    >
      <b-row>
        <b-col
          v-for="(field, index) in getPersonalFields"
          :key="`${field}_${index}`"
          cols="12"
          sm="12"
          md="6"
        >
          <div
            v-if="((field !== 'phoneNumberPrivate' && field !== 'mailAddressPrivate') && !isUser) || ((field !== 'phoneNumbers' && field !== 'mailAddresses') && isUser)"
            class="border-bottom my-50 px-50 py-25 d-flex flex-wrap justify-content-between"
          >
            <b class="mb-25 mr-50">{{ $t(`contact.${field}`) }}</b>

            <span v-if="contact[field]">
              <a
                v-if="field === 'phone'"
                :href="`tel:${contact[field]}`"
              >
                {{ contact[field] | phoneFormat }}
              </a>
              <a
                v-else-if="field === 'phoneNumberPrivate' && contact[field] && isUser"
                :href="`tel:${contact[field].phone}`"
              >
                {{ contact[field].phone | phoneFormat }}
              </a>
              <a
                v-else-if="field === 'mailAddressPrivate' && contact[field] && isUser"
                :href="`mailto:${contact[field].mail}`"
              >
                {{ contact[field].mail }}
              </a>
              <span v-else-if="field === 'phoneNumbers' && contact[field] && contact[field].length && !isUser">
                <div
                  v-for="(item, index) in contact[field]"
                  :key="`phone_${index}`"
                >
                  <a
                    v-if="item.phone !== contact.phone"
                    :href="`tel:${item.phone}`"
                  >{{ item.phone | phoneFormat }}</a>
                </div>
              </span>
              <span v-else-if="field === 'mailAddresses' && contact[field] && contact[field].length && !isUser">
                <div
                  v-for="(item, index) in contact[field]"
                  :key="`mail_${index}`"
                >
                  <a
                    v-if="item.mail !== contact.email"
                    :href="`mailto:${item.mail}`"
                  >{{ item.mail }}</a>
                </div>
              </span>
              <span v-else-if="field === 'email' && contact.email && contact.email.length">
                <a :href="`mailto:${contact.email}`">
                  {{ contact.email }}
                </a>
              </span>
              <span v-else-if="field === 'position'">
                <span v-if="contact[field]">
                  {{ $t(contactPositionTypeByValue(contact[field])) }}
                </span>
                <span v-else>
                  <feather-icon icon="MinusIcon" />
                </span>
              </span>
              <span v-else-if="contact[field].length">
                {{ contact[field] }}
              </span>
              <span v-else>
                <feather-icon icon="MinusIcon" />
              </span>
            </span>

            <span v-else>
              <feather-icon icon="MinusIcon" />
            </span>
          </div>
        </b-col>
      </b-row>

      <!-- Contact agreement fields -->
      <b-row v-if="checkRequiredModule('agreementModule') && !isUser && checkRequiredViewPermissions([
        $viewRanks.SHOW_CONTACT_FORM_PERSONAL_ID,
        $viewRanks.SHOW_CONTACT_FORM_BIRTH_DATE,
        $viewRanks.SHOW_CONTACT_FORM_FAMILY_NAME,
        $viewRanks.SHOW_CONTACT_FORM_MATERIAL_STATUS,
        $viewRanks.SHOW_CONTACT_FORM_FINANCIAL_POSITION,
      ])"
      >
        <b-col
          sm="12"
          class="divider mt-2 mt-md-3 mb-1 mb-md-2 font-weight-bold text-primary"
        >
          <div class="divider-text">
            {{ $t('AdditionalAgreementInformation') }}
          </div>
        </b-col>
        <b-col
          v-for="(field, index) in getAgreementFields"
          :key="`${field.value}_${index}`"
          cols="12"
          sm="12"
          md="6"
        >
          <div
            class="border-bottom my-50 px-50 py-25 d-flex flex-wrap justify-content-between"
          >
            <b class="mb-25 mr-50">{{ $t(`contact.${field.value}`) }}</b>
            <span v-if="contact[field.value]">
              <span v-if="['maritalStatus', 'financialPosition'].includes(field.value)">
                {{ $t(`contact.${contact[field.value]}`) }}
              </span>
              <span v-else-if="contact[field.value].date">{{ formatDate(contact[field.value].date, { month: 'numeric', day: 'numeric', year: 'numeric' }, $i18n.locale) }}</span>
              <span v-else>{{ contact[field.value] }}</span>
            </span>
            <span v-else>
              <feather-icon icon="MinusIcon" />
            </span>
          </div>
        </b-col>
      </b-row>

      <!-- Contact agreement fields -->
      <b-row v-if="checkRequiredModule('agreementModule') && checkRequiredViewPermissionsByUser([$viewRanks.SHOW_CONTACT_FORM_PERSONAL_DOCUMENTS])">
        <b-col
          sm="12"
          class="divider mt-2 mt-md-3 mb-1 mb-md-2 font-weight-bold text-primary"
        >
          <div class="divider-text">
            {{ $t('contact.PersonIdentityDocuments') }}
          </div>
        </b-col>

        <b-col
          sm="12"
          class="px-0 overflow-scroll"
        >
          <b-table
            :fields="documentsFields"
            :items="contact.personIdentityDocuments"
            show-empty
          >
            <!--    Head    -->
            <template #head()="{ label }">
              {{ $t(`${label}`) }}
            </template>
            <!--    Head    -->
            <template #cell(type)="{ value }">
              {{ $t(`contact.${value}`) }}
            </template>
            <!--    Other    -->
            <!--    Other    -->
            <template #cell()="row">
              <table-default-cell
                :field="row.field"
                :value="row.value"
              />
            </template>

            <!--      Empty      -->
            <template #empty>
              <empty-content />
            </template>

            <!--      Table Busy      -->
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner
                  class="align-middle"
                  variant="primary"
                />
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>

      <!-- Contact organization -->
      <b-row v-if="contact.isOrganization && contact.organization">
        <b-col
          sm="12"
          class="divider mt-2 mt-md-3 mb-1 mb-md-2 font-weight-bold text-primary"
        >
          <div class="divider-text">
            {{ $t('Organization') }}
          </div>
        </b-col>
        <b-col
          v-for="(field, index) in organizationFields"
          :key="`${field}_${index}`"
          cols="12"
          sm="12"
          md="6"
        >
          <div
            class="border-bottom my-50 px-50 py-25 d-flex flex-wrap justify-content-between"
          >
            <b class="mb-25 mr-50">{{ $t(`OrganizationDetails.${field}`) }}</b>

            <span v-if="contact.organization[field]">{{ contact.organization[field] }}</span>
            <span v-else>
              <feather-icon icon="MinusIcon" />
            </span>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col sm="12">
          <b-col
            sm="12"
            class="divider mt-2 mt-md-3 mb-1 mb-md-2 font-weight-bold text-primary"
          >
            <div class="divider-text">
              {{ $t('DeliveryAddresses') }}
            </div>
          </b-col>
          <delivery-addresses
            ref="contact-delivery-addresses"
            :addresses="contact.deliveryAddresses"
            is-list
          />
        </b-col>
      </b-row>
      <!-- Contact Groups -->
      <b-row v-if="contact.userGroups && contact.userGroups.length">
        <b-col
          sm="12"
          class="divider mt-3 mb-50 font-weight-bold text-primary"
        >
          <div class="divider-text">
            {{ $t('Groups') }}
          </div>
        </b-col>
        <b-col sm="12">
          <b-badge
            v-for="group in contact.userGroups"
            :key="`${contact.id}_group_${group.id}`"
            variant="light-primary"
            class="mr-50"
          >
            <div class="d-flex align-items-center">
              <div
                class="color-dot"
                :style="{ backgroundColor: group.color }"
              />
              {{ group.name }}
            </div>
          </b-badge>
        </b-col>
      </b-row>

      <!-- Contact Marketing Plan -->
      <b-row>
        <b-col
          sm="12"
          class="divider mt-2 mt-md-3 font-weight-bold text-primary"
        >
          <div class="divider-text">
            {{ $t('MarketingPlan') }}
          </div>
        </b-col>

        <b-col cols="12">
          <div>
            <b-badge
              v-for="({ marketingPlan }, index) in contact.userMarketingPlans"
              :key="`marketing_plan_${index}`"
              variant="light-primary"
            >
              {{ marketingPlan.name }}
            </b-badge>
          </div>
        </b-col>
      </b-row>

      <!-- Contact Loyalty Plan -->
      <b-row>
        <b-col
          sm="12"
          class="divider mt-2 mt-md-3 font-weight-bold text-primary"
        >
          <div class="divider-text">
            {{ $t('LoyaltyPlan') }}
          </div>
        </b-col>

        <b-col cols="12">
          <div>
            <b-badge
              v-for="({ loyaltyPlan }, index) in contact.userLoyaltyPlans"
              :key="`loyalty_plan_${index}`"
              variant="light-primary"
            >
              {{ loyaltyPlan.name }}
            </b-badge>
          </div>
        </b-col>
      </b-row>

      <!-- Contact Note -->
    </component>
  </component>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { BTable } from 'bootstrap-vue'
import { formatDate } from '@core/utils/filter'
import DeliveryAddresses from '@/pages/contact.old/components/DeliveryAddresses.vue'
import { contactPositionTypeByValue } from '@/@types/contactPositionTypes'

export default {
  name: 'UserDetails',
  components: {
    AppCollapse,
    AppCollapseItem,
    BTable,
    DeliveryAddresses,
  },
  props: ['contact', 'additionalFields', 'isUser'],
  data: vm => ({
    fields: [
      { value: 'firstName' },
      { value: 'lastName' },
      { value: 'position', requiredViewPermissions: [vm.$viewRanks.SHOW_CONTACT_FORM_POSITION] },
      { value: 'phone' },
      { value: 'email' },
      { value: 'userId' },
      { value: 'phoneNumberPrivate' },
      { value: 'mailAddressPrivate' },
      { value: 'phoneNumbers' },
      { value: 'mailAddresses' },
    ],
    organizationFields: [
      'name',
      'vatId',
      'addressCountry',
      'addressCity',
      'addressPostalCode',
      'addressStreet',
      'addressNumber',
    ],
    agreementFields: [
      { value: 'personalId', requiredViewPermissions: [vm.$viewRanks.SHOW_CONTACT_FORM_PERSONAL_ID] },
      { value: 'birthDate', requiredViewPermissions: [vm.$viewRanks.SHOW_CONTACT_FORM_BIRTH_DATE] },
      { value: 'familyName', requiredViewPermissions: [vm.$viewRanks.SHOW_CONTACT_FORM_FAMILY_NAME] },
      { value: 'maritalStatus', requiredViewPermissions: [vm.$viewRanks.SHOW_CONTACT_FORM_MATERIAL_STATUS] },
      { value: 'financialPosition', requiredViewPermissions: [vm.$viewRanks.SHOW_CONTACT_FORM_FINANCIAL_POSITION] },
    ],
    documentsFields: [
      { key: 'type', label: 'task.Type' },
      { key: 'number', label: 'OrganizationDetails.addressNumber' },
      { key: 'authorityIssuing', label: 'contact.authorityIssuing' },
      { key: 'releaseDate', label: 'contact.releaseDate' },
      { key: 'expirationDate', label: 'contact.expirationDate' },
    ],
  }),
  computed: {
    getAgreementFields() {
      return this.agreementFields.filter(field => this.checkRequiredViewPermissionsByUser(field.requiredViewPermissions))
    },
    getPersonalFields() {
      return this.personalFields.filter(field => this.checkRequiredViewPermissionsByUser(field?.requiredViewPermissions)).map(p => p.value)
    },
    personalFields() {
      const contactFields = this.fields
      // if (this.contact.isOrganization) return contactFields
      contactFields.push(...[
        { value: 'addressCountry' },
        { value: 'addressCity' },
        { value: 'addressPostalCode' },
        { value: 'addressStreet' },
        { value: 'addressNumber' },
      ])
      return contactFields
    },
  },
  mounted() {
    if (this.additionalFields && this.additionalFields.length) {
      this.fields.push(...this.additionalFields.map(v => ({ value: v })))
    }
  },
  methods: {
    contactPositionTypeByValue,
    formatDate,
    checkRequiredViewPermissionsByUser(viewPermissions) {
      if (this.isUser) return true
      if (!viewPermissions) return true

      return this.checkRequiredViewPermissions(viewPermissions)
    },
  },
}
</script>

<style scoped>

</style>
