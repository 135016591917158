<template>
  <div :id="`input_${contactAddonFieldType.id}`"
       style="position: relative"
  >
    <validation-provider
      v-if="['DIGITAL', 'PERCENTAGE'].includes(contactAddonFieldType.type)"
      v-slot="{ errors }"
      :name="name"
    >
      <b-form-group>
        <b-form-input
          :value="value"
          :placeholder="$t('Value')"
          :disabled="!contactAddonFieldType || disabled"
          :state="errors.length > 0 ? false:null"
          type="number"
          @input="updateValue('set', 'value',$event)"
        />

        <small class="text-danger">{{ errors[0] }}</small>
      </b-form-group>
    </validation-provider>

    <!--      New Filed: Value -> DATE      -->
    <div v-if="['DATE'].includes(contactAddonFieldType.type)">
      <b-form-group style="position: relative">
        <validation-provider
          v-slot="{ errors }"
          :name="name"
          style="position: relative"
        >
          <b-input-group class="mb-0"
                         style="position: relative"
          >
            <cleave
              :value="value"
              :options="cleaveConfig"
              class="form-control rounded"
              :placeholder="$t('TypeDate')"
              @input="e => { updateValue('set', 'value', e) }"
              @blur="checkDate(value)"
              @on-blur="checkDate(value)"
            />
            <!--            <b-form-input-->
            <!--              :value="value"-->
            <!--              :disabled="disabled"-->
            <!--              type="text"-->
            <!--              placeholder="Select date"-->
            <!--            />-->
            <b-input-group-append>
              <b-button variant="outline-primary"
                        @click="openPicker"
              >
                <sw-icon icon="ClockIcon" />
              </b-button>
            </b-input-group-append>
          </b-input-group>

          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <sw-select-fpr style="display: none; position: relative"
                     @clear="updateValue('set', 'value', '')"
      >
        <flat-pickr
          :ref="`input_flatpicker_date_${contactAddonFieldType.id}`"
          :value="value && value.includes('-') ? value : getFormatValue(value)"
          style="visibility: hidden; width: 0; padding: 0; margin: 0; height: 0"
          class="form-control"
          :disabled="disabled"
          :config="{ locale: getCalendarLocale($i18n.locale), ...timeConfig('DATE') }"
          :placeholder="$t('SelectDate')"
          @on-close="updateValue('set', 'value', $event, 'DATE')"
        />
      </sw-select-fpr>
    </div>

    <!--      New Filed: Value -> TIME      -->
    <validation-provider
      v-if="['TIME'].includes(contactAddonFieldType.type)"
      v-slot="{ errors }"
      :name="name"
    >
      <b-form-group>
        <sw-select-fpr @clear="updateValue('set', 'value', '')">
          <flat-pickr
            :value="value"
            class="form-control"
            :disabled="disabled"
            :config="{ locale: getCalendarLocale($i18n.locale), ...timeConfig('TIME') }"
            :placeholder="$t('SelectTime')"
            @on-close="updateValue('set', 'value', $event, 'TIME')"
          />
        </sw-select-fpr>

        <small class="text-danger">{{ errors[0] }}</small>
      </b-form-group>
    </validation-provider>

    <!--      New Filed: Value -> CHECKBOX      -->
    <validation-provider
      v-if="['CHECKBOX'].includes(contactAddonFieldType.type)"
      v-slot="{ errors }"
      :name="name"
    >
      <b-form-group>
        <b-form-checkbox
          :checked="value"
          variant="primary"
          :disabled="!contactAddonFieldType || disabled"
          :placeholder="$t('Value')"
          @change="updateValue('set', 'value', $event)"
        />

        <small class="text-danger">{{ errors[0] }}</small>
      </b-form-group>
    </validation-provider>

    <!--      New Filed: Value -> EMAIL      -->
    <validation-provider
      v-if="['EMAIL'].includes(contactAddonFieldType.type)"
      v-slot="{ errors }"
      rules="email"
      :name="name"
    >
      <b-form-group>
        <b-form-input
          :value="value"
          :placeholder="$t('Value')"
          :disabled="!contactAddonFieldType || disabled"
          :state="errors.length > 0 ? false:null"
          @input="updateValue('set', 'value',$event)"
        />

        <small class="text-danger">{{ errors[0] }}</small>
      </b-form-group>
    </validation-provider>

    <!--      New Filed: Value -> TEXT_MULTIPLE_ARRAY      -->
    <validation-provider
      v-if="['TEXT_MULTIPLE_ARRAY'].includes(contactAddonFieldType.type)"
      v-slot="{ errors }"
      :name="name"
    >
      <b-form-group class="mb-0">
        <sw-select>
          <v-select
            :value="contactAddonFieldTypeOptions"
            :placeholder="$t('Select')"
            :options="contactAddonFieldType.pollFieldOptions"
            :filterable="false"
            label="name"
            item-value="id"
            :disabled="disabled"
            item-text="name"
            :state="errors.length > 0 ? false:null"
            :reduce="e => e.id"
            multiple
            @option:selected="updateValue('set', 'contactAddonFieldTypeOptions',$event)"
            @option:deselected="updateValue('set', 'contactAddonFieldTypeOptions', (() => {
              let a = [...(contactAddonFieldTypeOptions || [])];

              // Find the index of the item to be removed
              const index = a.findIndex(e => e.id === $event.id);

              // Remove the item if it exists in the array
              if (index > -1) {
                a.splice(index, 1);
              }

              return a
            })())"
          >
            <template #no-options>
              {{ $t('NoOptions') }}
            </template>
            <template #option="item">
              {{ item.translations[0].name }}
            </template>
            <template #selected-option="item">
              {{ item.translations[0].name }}
            </template>
          </v-select>
        </sw-select>

        <small class="text-danger">{{ errors[0] }}</small>
      </b-form-group>
    </validation-provider>

    <!--      New Filed: Value -> TEXT_ARRAY      -->
    <validation-provider
      v-if="['TEXT_ARRAY'].includes(contactAddonFieldType.type)"
      v-slot="{ errors }"
      :name="name"
    >
      <b-form-group class="mb-0">
        <sw-select>
          <v-select
            :value="contactAddonFieldTypeOptions"
            :placeholder="$t('Select')"
            :options="contactAddonFieldType.pollFieldOptions"
            :filterable="false"
            label="name"
            :disabled="disabled"
            item-value="id"
            item-text="name"
            :state="errors.length > 0 ? false:null"
            @option:selected="updateValue('set', 'contactAddonFieldTypeOptions',$event)"
            @option:deselected="updateValue('set', 'contactAddonFieldTypeOptions','')"
          >
            <template #no-options>
              {{ $t('NoOptions') }}
            </template>
            <template #option="item">
              {{ item.translations[0].name }}
            </template>
            <template #selected-option="item">
              {{ item.translations[0].name }}
            </template>
          </v-select>
        </sw-select>

        <small class="text-danger">{{ errors[0] }}</small>
      </b-form-group>
    </validation-provider>

    <!--      New Filed: Value -> RADIAL      -->
    <validation-provider
      v-if="['RADIAL'].includes(contactAddonFieldType.type)"
      :name="name"
    >
      <b-form-group
        v-slot="{ ariaDescribedby }"
      >
        <b-form-radio-group @change="updateValue('set', 'value',$event)">
          <b-form-radio
            v-for="(option, i) in contactAddonFieldType.pollFieldOptions"
            :key="i"
            :disabled="disabled"
            :aria-describedby="ariaDescribedby"
            :value="option"
          >
            <span v-if="option.translations">
              {{ option.translations[0].name }}
            </span>
          </b-form-radio>
        </b-form-radio-group>
      </b-form-group>
    </validation-provider>

    <!--      New Filed: Value -> TEXT      -->
    <!--    <validation-provider-->
    <!--      v-if="['TOOLTIP'].includes(contactAddonFieldType.type)"-->
    <!--      :name="$t('Value')"-->
    <!--    >-->
    <!--      <b-form-group>-->
    <!--        <b-input-group>-->
    <!--          <b-form-input-->
    <!--            :value="newValue"-->
    <!--            :placeholder="$t('Value')"-->
    <!--          />-->

    <!--          <b-input-group-append>-->
    <!--            <b-button-->
    <!--              variant="outline-primary"-->
    <!--              class="btn-icon"-->
    <!--              @click="() => {-->
    <!--                if (newValue.length) {-->
    <!--                  contactAddonFieldTextOptions.push(newValue)-->
    <!--                  newValue = ''-->
    <!--                }-->
    <!--              }"-->
    <!--            >-->
    <!--              <feather-icon icon="PlusIcon" />-->
    <!--            </b-button>-->
    <!--          </b-input-group-append>-->
    <!--        </b-input-group>-->
    <!--      </b-form-group>-->

    <!--      <b-form-group-->
    <!--        v-for="(option, i) in contactAddonFieldTextOptions"-->
    <!--        :key="i"-->
    <!--      >-->
    <!--        <b-input-group>-->
    <!--          <b-form-input-->
    <!--            :value="contactAddonFieldTextOptions[i]"-->
    <!--            :placeholder="$t('Value')"-->
    <!--          />-->

    <!--          <b-input-group-append>-->
    <!--            <b-button-->
    <!--              variant="outline-danger"-->
    <!--              class="btn-icon"-->
    <!--              @click="contactAddonFieldTextOptions.splice(i, 1)"-->
    <!--            >-->
    <!--              <feather-icon icon="MinusIcon" />-->
    <!--            </b-button>-->
    <!--          </b-input-group-append>-->
    <!--        </b-input-group>-->
    <!--      </b-form-group>-->
    <!--    </validation-provider>-->

    <!--      New Filed: Value -> TEXT      -->
    <validation-provider
      v-if="!contactAddonFieldType || ['TEXT'].includes(contactAddonFieldType.type)"
      v-slot="{ errors }"
      :name="name"
    >
      <b-form-group>
        <b-form-input
          :value="value"
          :disabled="!contactAddonFieldType || disabled"
          :state="errors.length > 0 ? false:null"
          :placeholder="$t('Value')"
          @input="updateValue('set', 'value', $event)"
        />

        <small class="text-danger">{{ errors[0] }}</small>
      </b-form-group>
    </validation-provider>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { ValidationProvider } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import { BFormRadioGroup, BInputGroupAppend } from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'

export default {
  name: 'AddonFieldType',
  components: {
    BInputGroupAppend,
    ValidationProvider,
    vSelect,
    flatPickr,
    BFormRadioGroup,
    Cleave,
  },
  props: {
    contactAddonFieldType: {
      default: null,
      required: true,
    },
    value: {
      required: true,
      default: null,
    },
    name: {
      required: true,
      default: '',
    },
    contactAddonFieldTypeOptions: {
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    cleaveConfig: {
      date: true,
      datePattern: ['Y', 'm', 'd'],
      delimiter: '-',
    },
  }),
  methods: {
    openPicker() {
      if (this.$refs[`input_flatpicker_date_${this.contactAddonFieldType.id}`]) this.$refs[`input_flatpicker_date_${this.contactAddonFieldType.id}`].fp.open()
    },
    getFormatValue(value) {
      const year = value.slice(0, 4)
      const month = value.length > 4 ? value.slice(4, 6).padStart(2, '0') : '01'
      const day = value.length > 6 ? value.slice(6, 8).padStart(2, '0') : '01'

      return `${year}-${month}-${day}`
    },
    checkDate(value) {
      if (!value) return

      this.$emit('update-value', { method: 'set', field: 'value', value: this.getFormatValue(value) })
    },
    // method = push, set
    updateValue(method, field, value, type) {
      // eslint-disable-next-line no-underscore-dangle
      let _value = value
      if (['DATE'].includes(type)) {
        // eslint-disable-next-line prefer-destructuring
        _value = moment(value[0]).format('YYYY-MM-DD')
      } else if (['TIME'].includes(type)) {
        _value = moment(value[0]).format('HH:mm')
      }
      this.$emit('update-value', { method, field, value: _value })
    },
    timeConfig(type) {
      return type === 'TIME' ? {
        enableTime: true, noCalendar: true, dateFormat: 'H:i', time_24hr: true,
      } : {}
    },
  },
}
</script>

<style scoped>

</style>
