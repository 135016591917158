<template>
  <div>
    <!-- eslint-disable -->
    <!--    Section: Filters    -->
    <sw-filters
      v-if="$route.name !== 'contact'"
      :shorts="[
        {
          title: $t('Seller'),
          type: 'avatar',
          value: () => filters.seller,
          clear: () => { filters.seller = []; loadStatistics(); reloadContent() }
        },
        {
          title: $t('CommissionFor'),
          type: 'avatar',
          value: () => filters.provisionFor,
          clear: () => { filters.provisionFor = []; loadStatistics(); reloadContent() }
        },
        {
          title: $t('DateOfConclusionOfContract'),
          value: () => filters.agreementDate,
          clear: () => { filters.agreementDate = ''; loadStatistics(); reloadContent() }
        },
        {
          title: $t('task.Type'),
          value: () => $t(filters.type),
          clear: () => { filters.type = ''; loadStatistics(); reloadContent() }
        },
        {
          title: $t('OrderDate'),
          value: () => filters.orderDate,
          clear: () => { filters.orderDate = ''; loadStatistics(); reloadContent() }
        },
      ]"
    >
      <filters
        :filters.async="filters"
      />
    </sw-filters>

    <!--    START::Items    -->
    <b-row class="mt-1">
      <b-col
        v-for="stat in stats"
        :key="stat.key"
        md="4"
        @click="status = stat.key"
      >
        <item
          :is-selected="status === stat.key"
          :is-loading="loading"
          :name="$t(stat.name)"
          :tip="$t(stat.info)"
          :val="stat.value"
          :val-group="stat.groupValue"
          :val-all="stat.allValue"
        />
      </b-col>
    </b-row>
    <!--    END::Items    -->

    <!--    START::Table    -->
    <table-commission
      ref="table"
      :contact-thread="contactThread"
      :status="status"
      @load-stats="e => {
        this.search = e
        loadStatistics()
      }"
      :filters="filters"
    />
    <!--    END::Table    -->
  </div>
</template>

<script>
import Filters from '@/pages/commissions/components/withdrawals/Filters.vue'
import Item from '@/pages/commissions/components/Item.vue'
import TableCommission from '@/pages/commissions/components/withdrawals/TableCommission.vue'
import axiosIns from '@/libs/axios'
import moment from 'moment'

export default {
  name: 'SwWithdrawals',
  components: {
    Filters,
    Item,
    TableCommission,
  },
  props: {
    contactThread: {
      default: null,
      required: false,
    },
  },
  data: () => ({
    loading: false,
    status: 'NEW',
    search: '',
    stats: [
      {
        key: 'NEW', name: 'PaymentStatuses.TO_PAY', allValue: 0, value: 0, groupValue: 0, info: 'CommissionsToPayInfo',
      },
      {
        key: 'TO_PAY', name: 'PaymentStatuses.IN_REALIZATION', allValue: 0, value: 0, groupValue: 0, info: 'CommissionsInRealizationInfo',
      },
      {
        key: 'PAID', name: 'PaidOut', allValue: 0, value: 0, groupValue: 0, info: 'CommissionsPaidInfo',
      },
    ],

    filters: {
      agreementDate: '',
      type: '',
      orderDate: '',
      seller: null,
      provisionFor: null,
    },

    isOpenBalanceChange: false,
    isOpenSettle: false,
  }),
  watch: {
    filters: {
      deep: true,
      handler() {
        if (!this.loading) {
          this.loadStatistics()
        }
      },
    },
  },
  methods: {
    reloadContent() {
      if (this.$refs.table) {
        this.$refs.table.loadData()
      }
    },
    async loadStatistics() {
      this.loading = true
      try {
        const params = {}

        // if (this.filters?.seller?.length) params['eqArr_marketingPlanPoint-contactThreadCartBox-contactThreadCartOrder-seller-id'] = JSON.stringify(this.filters.seller.mapKeys())
        // if (this.filters?.type) params.eq_type = this.filters?.type
        // if (this.filters?.provisionFor?.length) params['eqArr_marketingPlanPoint-user-id'] = JSON.stringify(this.filters.provisionFor.mapKeys())

        if (this.filters?.seller?.length) params['eqArr_marketingPlanPoint-contactThreadCartBox-contactThreadCartOrder-seller-id'] = JSON.stringify(this.filters.seller.mapKeys())
        if (this.filters?.type) params.eq_type = this.filters?.type
        if (this.filters?.provisionFor?.length) params['eqArr_marketingPlanPointUser-id'] = JSON.stringify(this.filters.provisionFor.mapKeys())

        if (this.filters?.orderDate) {
          const [startAt, endAt] = this.filters.orderDate.dateRange()
          if (startAt) params['gte_contactThreadCartOrder-orderDate'] = `${moment(startAt, 'YYYY-MM-DD').format('YYYY-MM-DD')} 00:00:00`
          if (endAt) params['lt_contactThreadCartOrder-orderDate'] = `${moment(endAt, 'YYYY-MM-DD').format('YYYY-MM-DD')} 23:59:59`
        }
        // if (this.filters?.seller?.length) params['eqArr_marketingPlanPointPayment-contactThreadCartOrder-seller-id'] = JSON.stringify(this.filters.seller.mapKeys())
        // if (this.filters?.provisionFor?.length) params['eqArr_marketingPlanPointPayment-user-id'] = JSON.stringify(this.filters.provisionFor.mapKeys())
        if (this.filters?.agreementDate) {
          const [startAt, endAt] = this.filters.agreementDate.dateRange()
          if (startAt) params['gte_marketingPlanPointPayment-contactThreadCartOrder-contactThreadCartBox-contactThreadAgreements-signedAt'] = `${moment(startAt, 'YYYY-MM-DD').format('YYYY-MM-DD')} 00:00:00`
          if (endAt) params['lt_marketingPlanPointPayment-contactThreadCartOrder-contactThreadCartBox-contactThreadAgreements-signedAt'] = `${moment(endAt, 'YYYY-MM-DD').format('YYYY-MM-DD')} 23:59:59`
        }

        if (this.contactThread) {
          params['eq_1_contactThread-id'] = this.getObjectId(this.contactThread)
          params['eq_2_marketingPlanPoint-contactThreadCartBox-contactThread-id'] = this.getObjectId(this.contactThread)
          params['eq_3_contactThreadCartOrder-contactThreadCartBox-contactThread-id'] = this.getObjectId(this.contactThread)
        }

        if (this.search && this.search.length) {
          const searchString = this.search.replaceAll(' ', '%')
          params.search = `%${searchString}%`
          params.search_fields = 'accountFileNumber,marketingPlanPoint-contactThreadCartBox-contactThreadCartOrder-number,contactThreadCartOrder-number'
        }

        const resp = await axiosIns.get('1/marketingPlans/pointPayments/collects/getStatistics', { params })

        if (resp.data?.data) {
          const types = {
            all: 'allValue',
            me: 'value',
            other: 'groupValue',
          }
          Object.keys(resp.data?.data).forEach(type => {
            // type => 'all', 'me', 'other'
            Object.keys(resp.data?.data[type]).forEach(key => {
              // key => 'REALIZED', 'NEW'
              const index = this.stats.findIndex(item => item.key === key)
              if (index > -1) this.stats[index][types[type]] = resp.data?.data[type][key]
            })
          })
        }
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
