<template>
  <div>
    <b-alert show
             variant="primary"
    >
      <div class="alert-body text-center">
        {{ $t('GenerateNewZapierKey') }}

        <div class="mt-25">
          <b-button variant="primary"
                    :disabled="loading"
                    @click="openEditModal"
          >
            <feather-icon icon="KeyIcon" />
            {{ $t('Generate') }}
          </b-button>
        </div>
      </div>
    </b-alert>

    <b-modal v-model="editItemModal.show"
             hide-footer
             :title="editItemModal.item.id ? $t('Edit') : $t('Generate')"
    >
      <b-form-group :label="$t('Name')">
        <b-form-input
          v-model="editItemModal.item.name"
          type="text"
          size="sm"
        />
      </b-form-group>
      <b-button variant="primary"
                :disabled="loading"
                size="sm"
                @click="generateNewApiKey"
      >
        <feather-icon v-if="editItemModal.item.id"
                      icon="KeyIcon"
        />
        {{ editItemModal.item.id ? $t('Save') : $t('Generate') }}
      </b-button>
    </b-modal>

    <b-card>
      <b-table v-if="items.length"
               :items="items"
               :fields="fields"
               :busy="loading"
               striped
      >
        <template #head()="{ label }">
          {{ $t(`${label}`) }}
        </template>

        <template #cell(createdBy)="{ value }">
          <avatar :user="value" />
        </template>

        <template #cell(apiKey)="{ value }">
          <b-badge variant="light-success"
                   class="cursor-pointer"
                   @click="copyValue(value)"
          >
            <feather-icon icon="CopyIcon" />
            {{ value }}
          </b-badge>
        </template>

        <template #cell(action)="{ item, index }">
          <b-dropdown
            id="dropdown-dropleft"
            dropleft
            no-caret
            variant="flat"
          >
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" />
            </template>
            <b-dropdown-item @click="openEditModal(item)">
              {{ $t('Edit') }}
            </b-dropdown-item>
            <b-dropdown-item @click="removeApiKey(item, index)">
              {{ $t('Delete') }}
            </b-dropdown-item>
          </b-dropdown>
        </template>

          <!--    Other    -->
          <template #cell()="row">
              <table-default-cell
                      :field="row.field"
                      :value="row.value"
              />
          </template>

          <!--      Empty      -->
          <template #empty>
              <empty-content />
          </template>

          <!--      Table Busy      -->
          <template #table-busy>
              <div class="text-center text-danger my-2">
                  <b-spinner
                          class="align-middle"
                          variant="primary"
                  />
              </div>
          </template>
      </b-table>

      <div
        v-else
        class="text-center py-25 font-weight-bold text-primary"
      >
        <feather-icon
          size="17"
          icon="XCircleIcon"
        />

        {{ $t('NoData') }}
      </div>
    </b-card>
  </div>
</template>

<script>
import axiosIns from '@/libs/axios'
import { formatDate } from '@core/utils/filter'
import { BModal, BTable } from 'bootstrap-vue'

const defaultItem = {
  name: '',
}

export default {
  name: 'Zapier',
  components: {
    BModal,
    BTable,
  },
  data: () => ({
    loading: true,
    items: [],

    editItemModal: {
      show: false,
      item: {
        name: JSON.parse(JSON.stringify(defaultItem)),
      },
    },

    fields: [
      { key: 'name', label: 'Name' },
      { key: 'apiKey', label: 'APIKey' },
      { key: 'createdBy', label: 'CreatedBy' },
      { key: 'createdAt', label: 'CreatedAt' },
      { key: 'action', label: 'Action' },
    ],
  }),
  mounted() {
    this.loadData()
  },
  methods: {
    formatDate,
    copyValue(value) {
      this.$copyText(value, this.$el).then(() => {
        this.showToast('primary', this.$i18n.t('CopiedToClipboard'))
      }, e => {
        this.showToast('error', e)
      })
    },
    openEditModal(item = defaultItem) {
      this.$set(this.editItemModal, 'show', true)
      this.$set(this.editItemModal, 'item', JSON.parse(JSON.stringify(item)))
    },
    async generateNewApiKey() {
      this.loading = true
      try {
        const payload = {
          name: this.editItemModal.item.name,
        }

        if (this.editItemModal.item.id) {
          payload.id = this.editItemModal.item.id
          await axiosIns.patch('1/plugins/zapier', payload)
        } else {
          await axiosIns.post('1/plugins/zapier', payload)
        }

        this.showToast('success', this.$i18n.t('success.contactUpdated'))
        this.$set(this.editItemModal, 'show', false)
        this.$set(this.editItemModal, 'item', JSON.parse(JSON.stringify(defaultItem)))
        await this.loadData()
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    async removeApiKey(item) {
      const confirmed = await this.showAlert('warning', this.$i18n.t('alerts.AreYouSure'))
      if (!confirmed) return

      await axiosIns.delete('1/plugins/zapier', { data: JSON.stringify([item.id]) })
      this.showToast('success', this.$i18n.t('success.contactUpdated'))

      await this.loadData()
    },
    async loadData() {
      this.loading = true

      try {
        const response = await axiosIns.get('1/plugins/zapier?fields_load=ZAPIER')

        if (response.data?.data?.items) {
          this.items = response.data.data.items || []
        }
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>

</style>
