// eslint-disable-next-line import/no-cycle
import axiosIns from '@/libs/axios'
import { MICRO_TASKS } from '@/@constants/fields'
import {
  CREATE_MICRO_TASK,
  GET_MICRO_TASK,
  GET_MICRO_TASK_FROM_TASK,
  GET_MICRO_TASKS,
  REMOVE_MICRO_TASK,
  TEST_MICRO_TASK,
  UPDATE_MICRO_TASK,
} from '@/@constants/mutations'
import moment from 'moment'

const getDate = date => date.dateRange()

export default {
  namespaced: true,
  actions: {
    [GET_MICRO_TASKS]: (_, filters) => new Promise((resolve, reject) => {
      const params = {}
      params.fields_load = MICRO_TASKS

      if (filters?.project) params['eq_projectTask-project-id'] = filters.project
      if (filters?.task) params['eq_projectTask-id'] = filters.task

      if (filters?.createdAt) {
        if (filters?.createdAt?.project) {
          const value = getDate(filters.createdAt.project)

          // eslint-disable-next-line prefer-destructuring
          if (value[0]) params['gte_projectTask-project-createdAt'] = value[0]
          // eslint-disable-next-line prefer-destructuring
          if (value[1]) params['lte_projectTask-project-createdAt'] = value[1]
        }

        if (filters?.createdAt?.task) {
          const value = getDate(filters.createdAt.task)

          // eslint-disable-next-line prefer-destructuring
          if (value[0]) params['gte_projectTask-createdAt'] = value[0]
          // eslint-disable-next-line prefer-destructuring
          if (value[1]) params['lte_projectTask-createdAt'] = value[1]
        }

        if (filters?.createdAt?.microTask) {
          const value = getDate(filters.createdAt.microTask)

          // eslint-disable-next-line prefer-destructuring
          if (value[0]) params.gte_createdAt = value[0]
          // eslint-disable-next-line prefer-destructuring
          if (value[1]) params.lte_createdAt = value[1]
        }
      }

      if (filters?.completionAt) {
        if (filters?.completionAt?.project) {
          const value = getDate(filters.completionAt.project)

          // eslint-disable-next-line prefer-destructuring
          if (value[0]) params['gte_projectTask-project-endAt'] = value[0]
          // eslint-disable-next-line prefer-destructuring
          if (value[1]) params['lte_projectTask-project-endAt'] = value[1]
        }

        if (filters?.completionAt?.task) {
          const value = getDate(filters.completionAt.task)

          // eslint-disable-next-line prefer-destructuring
          if (value[0]) params['gte_projectTask-endAt'] = value[0]
          // eslint-disable-next-line prefer-destructuring
          if (value[1]) params['lte_projectTask-endAt'] = value[1]
        }

        if (filters?.completionAt?.microTask) {
          const value = getDate(filters.completionAt.microTask)

          // eslint-disable-next-line prefer-destructuring
          if (value[0]) params.gte_endAt = value[0]
          // eslint-disable-next-line prefer-destructuring
          if (value[1]) params.lte_endAt = value[1]
        }
      }

      if (filters?.onDeadline) {
        params.gte_endAt = moment(new Date()).format('YYYY-MM-DD HH:mm')
        params.lte_endAt = moment(new Date()).add(1, 'days').format('YYYY-MM-DD HH:mm')

        params.doneType = null
      }

      if (filters?.contactThreads?.length) params['eqArr_projectTask-project-contactThreads-id'] = JSON.stringify(filters.contactThreads.map(contact => contact.id))

      if (filters?.assignedUsers?.length) params['eqArr_assignedUser-id'] = JSON.stringify(filters.assignedUsers.map(contact => contact.id))

      if (filters?.categories?.length) params['eqArr_projectTask-projectTaskCategories-id'] = JSON.stringify(filters.categories)

      if (filters?.expired) {
        params.lt_endAt = moment(new Date()).format('YYYY-MM-DD HH:mm')

        params.doneType = null
      }

      axiosIns.get('1/projects/tasks/microTasks', { params })
        .then(res => resolve(res.data.data.items))
        .catch(err => reject(err))
    }),

    [GET_MICRO_TASK_FROM_TASK]: (_, { project, task }) => new Promise((resolve, reject) => {
      const params = {}
      params.fields_load = MICRO_TASKS

      axiosIns.get(`1/projects/${project}/tasks/${task}/microTasks`, { params })
        .then(res => resolve(res.data.data.items))
        .catch(err => reject(err))
    }),

    [GET_MICRO_TASK]: (_, { project, task, microTask }) => new Promise((resolve, reject) => {
      const params = {}
      params.fields_load = MICRO_TASKS

      axiosIns.get(`1/projects/${project}/tasks/${task}/microTasks/${microTask}`, { params })
        .then(res => resolve(res.data.data.item))
        .catch(err => reject(err))
    }),

    [CREATE_MICRO_TASK]: (_, payload) => new Promise((resolve, reject) => {
      axiosIns.post(`1/projects/${payload.project}/tasks/${payload.projectTask}/microTasks`, payload)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    }),

    [UPDATE_MICRO_TASK]: (_, payload) => new Promise((resolve, reject) => {
      axiosIns.patch(`1/projects/${payload.project}/tasks/${payload.projectTask}/microTasks`, payload)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    }),

    [REMOVE_MICRO_TASK]: (_, { project, projectTask, id }) => new Promise((resolve, reject) => {
      axiosIns.delete(`1/projects/${project}/tasks/${projectTask}/microTasks`, { data: [id] })
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    }),

    [TEST_MICRO_TASK]: (_, level = 0) => new Promise((resolve, reject) => {
      const params = {}
      params.fields_info = level

      axiosIns.get('1/projects/tasks/microTasks', { params })
        .then(res => resolve(res.data.data))
        .catch(err => reject(err))
    }),
  },
}
