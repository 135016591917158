<template>
  <div>
    <!--      Section: Header      -->
    <div class="d-flex justify-content-between">
      <div>
        <b-button
          v-if="checkRequiredPermissions([$roles.PROJECT_MODIFY])"
          size="sm"
          variant="primary"
          :to="{ name: 'projects/tasks/new' }"
        >
          {{ $t('Add') }}

          <feather-icon icon="PlusIcon" />
        </b-button>
      </div>

      <b-button-group>
        <b-button
          variant="outline-primary"
          size="sm"
          class="btn-icon"
          @click="$store.commit('projects/setView', 'grid')"
        >
          <feather-icon icon="TrelloIcon" />
        </b-button>

        <b-button
          variant="outline-primary"
          size="sm"
          class="btn-icon"
          @click="$store.commit('projects/setView', 'extensible')"
        >
          <feather-icon icon="ListIcon" />
        </b-button>
      </b-button-group>
    </div>

    <!--      Section: categoryList      -->
    <views
      :is-loading="isLoading"
      :view="$store.getters['projects/getView']"
      is-task
      :project-id="Number($route.params.project)"
      :start-filters="startFilters"
    />
  </div>
</template>

<script>
import {
  BButtonGroup, VBModal, VBToggle, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { Polish as pl } from '@/libs/i18n/locales/flatpickr/pl'
import { english as en } from '@/libs/i18n/locales/flatpickr/en'
import Views from '../components/list/Views.vue'

export default {
  components: {
    BButtonGroup,
    Views,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    project: {
      type: Object,
      required: false,
      default: null,
    },
    startFilters: {
      type: Object,
      required: false,
      default: () => ({ assignedUsers: [JSON.parse(localStorage.getItem('user'))] }),
    },
  },
  data: () => ({
    isLoading: false,

    flatPickerConfig: {
      mode: 'range',
      locale: [pl, en],
    },
  }),
  computed: {
    locale() {
      return this.$i18n.locale
    },
  },
}
</script>

<style>
.project-comment {
  position: relative;
}

.project-comment::after {
  position: absolute;
  content: '\270E';

  top : -1rem;
  left: -.9rem;

  width : 1.8rem;
  height: 1.8rem;

  font-size: 1rem;

  background: var(--white);
  color: var(--gray);

  border: .1rem solid var(--gray);
  border-radius: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
