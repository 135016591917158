<template>
  <div class="email-app-details">

    <!-- Email Header -->
    <div class="email-detail-header">

      <!-- Header: Left -->
      <div class="email-header-left d-flex align-items-center">
        <span class="go-back mr-1">
          <feather-icon
            :icon="$store.state.appConfig.isRTL ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
            size="20"
            class="align-bottom"
            @click="$emit('close-email-view')"
          />
        </span>
        <h4 class="email-subject mb-0">
          {{ emailViewData.subject }}
        </h4>
      </div>

      <b-button
        v-if="threadId && checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_CARD_EMAILS]) && checkRequiredPermissions([$roles.CONTACT_THREAD_MAIL_VIEW_ALL], [$roles.CONTACT_THREAD_MAIL_ADD])"
        v-b-tooltip.hover.v-primary
        size="sm"
        variant="primary"
        class="mb-25"
        :title="$t('Answer')"
        @click="openModal('mail', $t('Email'), $t('Answer'))"
      >
        <feather-icon icon="MailIcon" />

        <span class="d-none d-lg-inline ml-25">
          {{ $t('Answer') }}
        </span>
      </b-button>
    </div>

    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="email-scroll-area scroll-area"
    >
      <b-row>
        <b-col cols="12">
          <email-single-message-card
            :message="emailViewData"
          />
        </b-col>
      </b-row>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { ref, watch } from '@vue/composition-api'
import EmailSingleMessageCard from '@/views/components/emails/EmailSingleMessageCard.vue'
import { OPEN_MODAL } from '@/@constants/mutations'

export default {
  components: {
    EmailSingleMessageCard,
    // 3rd Party
    VuePerfectScrollbar,
  },
  props: {
    emailViewData: {
      type: Object,
      required: true,
    },
    threadId: {
      type: [String, Number],
    },
  },
  setup(props) {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const showWholeThread = ref(false)

    watch(() => props.emailViewData.id, () => {
      showWholeThread.value = false
    })

    return {
      // UI
      perfectScrollbarSettings,
      showWholeThread,
    }
  },
  methods: {
    openModal(modalType = 'note', modalTitle = 'Nowa Notatka', okButtonTitle = 'Dodaj notatkę') {
      this.$store.commit(`modal/${OPEN_MODAL}`,
        {
          modalType,
          modalTitle,
          okButtonTitle,
          size: 'lg',
          threadFor: this.threadId,
          data: this.emailViewData,
        })
    },
  },
}
</script>

<style>

</style>
