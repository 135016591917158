<template>
  <div>

    <div v-if="!isMobile()">
      <div :id="`document-container_${id}`"
           class="document-container d-flex align-items-center"
      >
        <div v-if="show">
          <div
            v-for="(document, i) in displayedFiles"
            :key="`${document.name || document}_${i}`"
            class="d-flex flex-column align-items-start document-list-show"
          >
            <div v-if="document.preview"
                 class="d-flex flex-column align-items-start document-list-show"
            >
              <img
                :src="`data:image/webp;base64,${document.preview}`"
                style="max-width: 125px; max-height: 125px; object-fit: contain"
                class="rounded shadow"
              >
              <b-button
                variant="flat-secondary"
                size="sm"
                class="px-25"
                @click.stop="downloadFileTest(document.token, `${document.name}.${document.extension}`, true)"
              >
                <u>{{ $t('DownloadFile') }}</u>
              </b-button>
            </div>

            <b-badge v-else
                     variant="light-primary"
                     class="cursor-pointer"
                     @click.stop="downloadFileTest(document.token, `${document.name}.${document.extension}`, true)"
            >
              <feather-icon v-if="document.extension"
                            :icon="fileIcon(document.extension)"
              />
              {{ document.name }}
              <feather-icon class="ml-25"
                            icon="DownloadIcon"
              />
            </b-badge>
          </div>
        </div>
        <span v-else-if="!deleteMode">
          <!--        v-b-popover.hover.html="popoverMethod(document)"-->
          <span
            v-for="(document, index) in displayedFiles"
            :key="`${document.name || document}_${index}_${id}`"
          >
            <feather-icon
              :id="`${document.name || document}_${index}_${id}`"
              class="cursor-pointer"
              :class="classes"
              :icon="icon ? icon : getDocumentIcon(document.extension || document)"
              :title="(document.name + (document.extension || '')) || document"
              size="23"
              @click.stop="downloadFileTest(document.token, `${document.name}.${document.extension}`, true)"
            />
            <b-popover
              :target="`${document.name || document}_${index}_${id}`"
              :triggers="['hover']"
              container="parent"
            >
              <template #title>
                {{ (document.name + (document.extension ? `.${document.extension}` : '')) || document }}
              </template>
              <div
                v-if="allowImageRecognise && document.extension.includes('webp')"
                class="mb-50"
              >
                <b-button
                  v-if="!document.loading"
                  class="w-100"
                  variant="flat-primary"
                  size="sm"
                  @click="recogniseFile(document.token, index)"
                >
                  <feather-icon icon="SearchIcon" />
                  {{ $t('RecogniseImage') }}
                </b-button>
                <div
                  v-else
                  class="d-flex justify-content-center align-items-center w-100"
                >
                  <b-spinner
                    small
                    variant="primary"
                  />
                </div>
              </div>
              <div v-html="popoverMethod(document)" />

              <div
                v-if="allowImageRecognise && Array.isArray(document.imageRecognitionNames) && document.imageRecognitionNames.length"
                class="d-flex flex-wrap"
              >
                <b-badge
                  v-for="(name, nameIndex) in document.imageRecognitionNames"
                  :key="`image_popover_recognition_name_${nameIndex}_${index}_${id}`"
                  block
                  class="mr-25 flex-grow-1 mt-25"
                  :class="{ 'w-100': nameIndex === 0 }"
                  :variant="'light-primary'"
                >
                  <span v-if="nameIndex === 0">🥇</span>
                  <span v-else-if="nameIndex === 1">🥈</span>
                  <span v-else-if="nameIndex === 2">🥉</span>

                  {{ name }}
                </b-badge>
              </div>

              <div
                v-if="document.error"
                class="text-center"
              ><small>{{ $t(document.error) }}</small></div>
            </b-popover>
          </span>
        </span>
        <div
          v-else
          class="d-flex"
        >
          <div
            v-for="(document, index) in displayedFiles"
            :key="`${document.name || document}_${index}_${id}`"
            class="position-relative overflow-hidden text-nowrap"
          >
            <feather-icon
              :icon="getDocumentIcon(document.extension || document)"
              size="23"
              @mouseenter="showDeleteIcon(`delete_file_${document.name}_${index}`)"
            />
            <div
              :ref="`delete_file_${document.name}_${index}`"
              class="delete-file"
              @mouseleave="hideDeleteIcon(`delete_file_${document.name}_${index}`)"
            >
              <feather-icon
                v-b-popover.hover.html="popoverMethod(document)"
                icon="TrashIcon"
                :title="(document.name + '.' + document.extension) || document"
                @click="deleteFile(index)"
              />
            </div>
          </div>
        </div>

        <span
          v-if="hiddenFiles.length"
          class="pl-50 text-muted"
        >
          <span :id="`moreFiles_${index}_${id}`"
                class="text-nowrap"
          >
            <feather-icon icon="PlusIcon" />
            {{ hiddenFiles.length }}
            {{ $t('More') }}
          </span>
          <b-popover
            :target="`moreFiles_${index}_${id}`"
            :triggers="['hover', 'click', 'focus']"
            :container="attach || `document-container_${id}`"
          >
            <vue-perfect-scrollbar style="max-height: 350px">
              <b-badge
                block
                class="w-100 mb-50"
                variant="primary"
                @click.stop="downloadAll"
              >
                {{ $t('DownloadAll') }}
              </b-badge>
              <b-list-group flush>
                <b-list-group-item
                  v-for="(hiddenFile, hiddenFileIndex) in [...displayedFiles, ...hiddenFiles]"
                  :id="`hiddenFile_${hiddenFileIndex}_${id}`"
                  :key="`hiddenFile_${hiddenFileIndex}_${id}`"
                  class="mb-50 cursor-default"
                >
                  <b-popover
                    v-if="!['txt', 'pdf', 'docs'].includes(hiddenFile.extension)"
                    :target="`hiddenFile_${hiddenFileIndex}_${id}`"
                    :triggers="['hover']"
                    container="parent"
                  >
                    <template #title>
                      {{ (hiddenFile.name + '.' + hiddenFile.extension) }}
                    </template>
                    <div v-html="popoverMethod(hiddenFile)" />

                    <div
                      v-if="allowImageRecognise && Array.isArray(hiddenFile.imageRecognitionNames) && hiddenFile.imageRecognitionNames.length"
                      class="d-flex flex-wrap"
                    >
                      <b-badge
                        v-for="(name, nameIndex) in hiddenFile.imageRecognitionNames"
                        :key="`image_popover_hidden_recognition_name_${nameIndex}_${index}__${id}`"
                        block
                        class="mr-25 flex-grow-1 mt-25"
                        :class="{ 'w-100': nameIndex === 0 }"
                        :variant="'light-primary'"
                      >
                        <span v-if="nameIndex === 0">🥇</span>
                        <span v-else-if="nameIndex === 1">🥈</span>
                        <span v-else-if="nameIndex === 2">🥉</span>

                        {{ name }}
                      </b-badge>
                    </div>

                    <div
                      v-if="hiddenFile.error"
                      class="text-center"
                    ><small>{{ $t(hiddenFile.error) }}</small></div>
                  </b-popover>

                  <div class="d-flex justify-content-between">
                    <div class="d-flex align-items-center">
                      <feather-icon
                        class="mr-25"
                        size="23"
                        :icon="icon ? icon : getDocumentIcon(hiddenFile.extension || hiddenFile)"
                      />
                      <div>{{ hiddenFile.name | truncate(8) }}.{{ hiddenFile.extension || '' }}</div>
                    </div>
                    <div class="d-flex justify-content-center align-items-center">
                      <div
                        v-if="!hiddenFile.loading"
                        :key="`image_${hiddenFile.index}_${index}_${id}`"
                        style="width: 108px"
                      >
                        <b-button
                          v-if="allowImageRecognise && hiddenFile.extension.includes('webp')"
                          v-b-tooltip.left
                          variant="flat-primary"
                          :title="$t('RecogniseImage')"
                          size="sm"
                          @click="recogniseFile(hiddenFile.token, hiddenFileIndex)"
                        >
                          <feather-icon
                            size="23"
                            icon="SearchIcon"
                          />
                        </b-button>
                        <b-button
                          v-b-tooltip.right
                          variant="flat-primary"
                          :title="$t('Download')"
                          size="sm"
                          @click="downloadFileTest(hiddenFile.token, `${hiddenFile.name}.${hiddenFile.extension}`, true, hiddenFileIndex)"
                        >
                          <feather-icon
                            size="23"
                            icon="DownloadIcon"
                          />
                        </b-button>
                      </div>
                      <div
                        v-else
                        :key="`spinner_${hiddenFile.index}_${index}_${id}`"
                        style="width: 108px"
                        class="d-flex justify-content-center align-items-center"
                      >
                        <b-spinner
                          small
                          variant="primary"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="hiddenFile.error"
                    class="text-center"
                  ><small>{{ $t(hiddenFile.error) }}</small></div>
                  <div
                    v-if="Array.isArray(hiddenFile.imageRecognitionNames) && hiddenFile.imageRecognitionNames.length"
                    class="d-flex flex-wrap"
                  >
                    <b-badge
                      v-for="(name, nameIndex) in hiddenFile.imageRecognitionNames"
                      :key="`image_recognition_name_${nameIndex}_${index}_${id}`"
                      block
                      class="mr-25 flex-grow-1 mt-25"
                      :class="{ 'w-100': nameIndex === 0 }"
                      :variant="'light-primary'"
                    >
                      <span v-if="nameIndex === 0">🥇</span>
                      <span v-else-if="nameIndex === 1">🥈</span>
                      <span v-else-if="nameIndex === 2">🥉</span>

                      {{ name }}
                    </b-badge>
                  </div>

                </b-list-group-item>
              </b-list-group>
            <!--          <div-->
            <!--            v-for="(hiddenFile, hiddenFileIndex) in hiddenFiles"-->
            <!--            :key="`hiddenFile_${hiddenFileIndex}_${id}`"-->
            <!--            v-b-popover.hover.html="popoverMethod(hiddenFile)"-->
            <!--            class="mb-50 cursor-pointer"-->
            <!--            :title="(hiddenFile.name + '.' + hiddenFile.extension)"-->
            <!--            @click="downloadFileTest(hiddenFile.token, `${hiddenFile.name}.${hiddenFile.extension}`)"-->
            <!--          >-->
            <!--            <div class="d-flex align-items-center">-->
            <!--              <div>-->
            <!--                <feather-icon-->
            <!--                  size="23"-->
            <!--                  :icon="icon ? icon : getDocumentIcon(hiddenFile.extension || hiddenFile)"-->
            <!--                />-->
            <!--              </div>-->
            <!--              <div>{{ hiddenFile.name }}.{{ hiddenFile.extension || '' }}</div>-->
            <!--            </div>-->
            <!--          </div>-->
            </vue-perfect-scrollbar>
          </b-popover>
        </span>
      </div>
    </div>
    <div v-else
         class="d-flex flex-column"
    >
      <span
        v-for="(document, index) in displayedFiles"
        :ref="`ref_${document.name || document}_${index}_${id}`"
        :key="`key_${document.name || document}_${index}_${id}`"
        :class="isMobile() ? 'mb-25' : ''"
      >
        <feather-icon
          :id="`${document.name || document}_${index}_${id}`"
          :key="`test_${document.name || document}_${index}_${id}`"
          class="cursor-pointer"
          :class="classes"
          :icon="icon ? icon : getDocumentIcon(document.extension || document)"
          :title="(document.name + (document.extension || '')) || document"
          size="23"
        />
        <span v-if="isMobile()"
              :key="`test2_${document.name || document}_${index}_${id}`"
        >
          {{ document.name }}
        </span>
        <b-badge
          block
          class="w-100 mb-50"
          variant="primary"
          @click.stop="downloadAll"
        >
          {{ $t('DownloadAll') }}
        </b-badge>
        <b-popover
          :target="`${document.name || document}_${index}_${id}`"
          :triggers="['hover']"
          container="parent"
        >
          <template #title>
            {{ (document.name + (document.extension ? `.${document.extension}` : '')) || document }}
          </template>
          <div
            v-if="allowImageRecognise && document.extension.includes('webp')"
            class="mb-50"
          >
            <b-button
              v-if="!document.loading"
              class="w-100"
              variant="flat-primary"
              size="sm"
              @click="recogniseFile(document.token, index)"
            >
              <feather-icon icon="SearchIcon" />
              {{ $t('RecogniseImage') }}
            </b-button>
            <div
              v-else
              class="d-flex justify-content-center align-items-center w-100"
            >
              <b-spinner
                small
                variant="primary"
              />
            </div>
          </div>
          <div v-html="popoverMethod(document)" />

          <div
            v-if="allowImageRecognise && Array.isArray(document.imageRecognitionNames) && document.imageRecognitionNames.length"
            class="d-flex flex-wrap"
          >
            <b-badge
              v-for="(name, nameIndex) in document.imageRecognitionNames"
              :key="`image_popover_recognition_name_${nameIndex}_${index}_${id}`"
              block
              class="mr-25 flex-grow-1 mt-25"
              :class="{ 'w-100': nameIndex === 0 }"
              :variant="'light-primary'"
            >
              <span v-if="nameIndex === 0">🥇</span>
              <span v-else-if="nameIndex === 1">🥈</span>
              <span v-else-if="nameIndex === 2">🥉</span>

              {{ name }}
            </b-badge>
          </div>

          <div
            v-if="document.error"
            class="text-center"
          ><small>{{ $t(document.error) }}</small></div>
        </b-popover>
      </span>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import { BPopover, VBPopover, VBTooltip } from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { DOWNLOAD_FILE } from '@/@constants/mutations'
import fileIcon from '@/@data/fileIcons'
import axiosIns from '@/libs/axios'

export default {
  name: 'DocumentsList',
  components: {
    BPopover,
    VuePerfectScrollbar,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-popover': VBPopover,
  },
  props: {
    icon: {
      type: String,
      default: null,
    },
    documents: [Array, String],
    index: [Number, String],
    show: {
      type: Boolean,
      required: false,
      default: false,
    },
    listAll: {
      type: Boolean,
      required: false,
      default: false,
    },
    deleteMode: Boolean,
    classes: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: () => ['pull-up'],
    },
      attach: {
        default: null,
        required: false,
        type: String,
      }
  },
  data: () => ({
    displayedFiles: [],
    hiddenFiles: [],
    id: Math.random().toString(16).slice(2),
  }),
  computed: {
    allowImageRecognise() {
      return this.$store.getters['system/getPrivateSettings']?.imageRecognitionPlantNetEnabled && this.checkRequiredModule('imageRecognitionModule') && this.checkRequiredPermissions([this.$roles.IMAGE_RECOGNITION_USE])
    },
  },
  watch: {
    documents: {
      deep: true,
      handler(newVal) {
        this.documents = newVal || []

        const documentsCopy = this.documents ? [...this.documents] : []
          if (this.isMobile()) {
              this.displayedFiles = (documentsCopy || []).map(e => ({
                  loading: false, error: null, imageRecognitionNames: [], ...e,
              }))
          } else if (!this.listAll) {
            this.displayedFiles = (documentsCopy.splice(0, 1) || []).map(e => ({
                loading: false, error: null, imageRecognitionNames: [], ...e,
            }))
            if ((this.documents || []).length > 1) {
                this.hiddenFiles = [...(documentsCopy || []).map(f => ({
                    loading: false, error: null, imageRecognitionNames: [], ...f,
                }))]
            }
        } else {
            this.displayedFiles = (documentsCopy || []).map(e => ({
            loading: false, error: null, imageRecognitionNames: [], ...e,
        }))
        }
      },
    },
  },
  mounted() {
    const documentsCopy = this.documents ? [...this.documents] : []
      if (this.isMobile()) {
          this.displayedFiles = (documentsCopy || []).map(e => ({
              loading: false, error: null, imageRecognitionNames: [], ...e,
          }))
      } else if (!this.listAll) {
        this.displayedFiles = (documentsCopy.splice(0, 1) || []).map(e => ({
            loading: false, error: null, imageRecognitionNames: [], ...e,
        }))
        if ((this.documents || []).length > 1) {
            this.hiddenFiles = [...(documentsCopy || []).map(f => ({
                loading: false, error: null, imageRecognitionNames: [], ...f,
            }))]
        }
    } else {
        this.displayedFiles = (documentsCopy || []).map(e => ({
            loading: false, error: null, imageRecognitionNames: [], ...e,
        }))
    }

    this.id = Math.random().toString(16).slice(2)
  },
  methods: {
      isMobile() {
          return window.innerWidth <= 768
      },
    filename(file) {
      return `${file?.name || ''}.${file?.extension || ''}`
    },
    test() {
    },
    popoverMethod(document) {
      let image = null
      // console.log(document)
      if (document?.preview) image = document.preview.includes('http') ? `<img src="${document.preview}" style="width: 200px; height: 200px; object-fit: cover" />` : `<img src="data:image/webp;base64,${document.preview}" />`
      else if (document?.previewPath) image = `<img src="${document.previewPath}" style="width: 200px; height: 200px; object-fit: cover" />`
      return image
    },
    setImageField(token, value = '', field = 'loading') {
      const hiddenFileIndex = this.hiddenFiles.findIndex(f => f.token === token)
      if (hiddenFileIndex > -1) {
        this.hiddenFiles[hiddenFileIndex][field] = value
        return
      }

      const displayedFileIndex = this.displayedFiles.findIndex(f => f.token === token)
      if (displayedFileIndex > -1) {
        this.displayedFiles[displayedFileIndex][field] = value
      }
    },
    // setImageLoading(token, loading = false) {
    //   const hiddenFileIndex = this.hiddenFiles.findIndex(f => f.token === token)
    //   if (hiddenFileIndex > -1) {
    //     this.hiddenFiles[hiddenFileIndex].loading = loading
    //     return
    //   }
    //
    //   const displayedFileIndex = this.displayedFiles.findIndex(f => f.token === token)
    //   if (displayedFileIndex > -1) {
    //     this.displayedFiles[displayedFileIndex].loading = loading
    //   }
    // },
    fileIcon,
    async recogniseFile(token, index) {
      try {
        if (index >= 0) {
          this.setImageField(token, true, 'loading')
        }
        const resp = await axiosIns.post(`1/integration/imageRecognition/${token}`)

        if (resp.data?.data?.names) {
          this.setImageField(token, null, 'error')
          this.setImageField(token, resp.data.data.names, 'imageRecognitionNames')
        }
      } catch (err) {
        this.setImageField(token, [], 'imageRecognitionNames')
        this.setImageField(token, 'ImageNotRecognised', 'error')
      } finally {
        if (index >= 0) {
          this.setImageField(token, false, 'loading')
        }
      }
    },
    getDocumentIcon(documentName) {
      const splitedDoc = typeof documentName === 'string' ? documentName.split('.') : documentName?.type
      const type = splitedDoc.length > 0 ? splitedDoc[splitedDoc.length - 1] : null
      return this.fileIcon(type || 'pdf')
    },
    async downloadAll() {
      const bases = await Promise.all(this.documents.map(file => this.downloadFileTest(file.token, `${file.name}.${file.extension}`, false)))
      this.downloadZip(bases)
    },
    downloadFileTest(fileToken, filename_, download = true, index = null) {
      if (index >= 0) {
        this.setImageField(fileToken, true, 'loading')
      }

      return new Promise((resolve, reject) => {
        this.$store.dispatch(`uploader/${DOWNLOAD_FILE}`, { fileToken, filename: filename_ })
          .then(res => {
            const { data, filename } = res
            const base = `data:${data.type};base64,${data.file}`

            if (download) {
              const a = document.createElement('a')
              a.href = base
              a.id = filename
              a.setAttribute('target', '_top')
              a.setAttribute('ref', 'filename')
              a.download = filename
              document.body.appendChild(a)
              setTimeout(() => {
                a.click()
                document.body.removeChild(a);
              }, 50)

            }
            resolve({ base, filename, baseContent: data.file })
          })
          .catch(() => {
            reject()
          }).finally(() => {
            if (index >= 0) {
              this.setImageField(fileToken, false, 'loading')
            }
          })
      })
    },
    deleteFile(deleteFileIndex) {
      this.$emit('deleteFile', deleteFileIndex)
    },
    showDeleteIcon(ref) {
      this.$refs[ref][0].classList.add('delete-open')
    },
    hideDeleteIcon(ref) {
      this.$refs[ref][0].classList.remove('delete-open')
    },
  },
}
</script>

<style>
  .delete-file {
    overflow: hidden;
    position: relative;
  }
  .delete-open {
    transform: translateY(0) !important;
  }
  .delete-file {
    transition: all .15s;
    content: 'X';
    font-weight: bold;
    text-align: center;
    color: var(--danger);
    background-color: rgb(255, 255, 255);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .document-container {
      .popover {
          min-width: 375px !important;
          max-width: 375px !important;
          .popover-body {
              padding: 0.65rem 0 !important;
          }
      }
  }
</style>
