<template>
  <b-media
    vertical-align="top"
    class="d-flex flex-column flex-lg-row"
  >
    <template #aside>
      <span class="text-muted">
        {{ dateFormat }}
      </span>
    </template>

    <div
      v-if="newFutures.length"
      class="mb-n1 mb-md-0"
    >
      <h3 class="text-primary">
        {{ $t('News') }}
      </h3>
      <b-list-group>
        <b-list-group-item
          v-for="(log, index) in newFutures"
          :key="`future_${index}`"
          class="d-flex flex-wrap flex-column flex-md-row justify-content-between align-items-md-center"
        >
          <span>{{ log.label }}</span>
          <div v-if="log.places && log.places.length">
            <b-badge
              v-for="(place, placeIndex) in log.places"
              :key="`future_place_${placeIndex}`"
              variant="primary"
              pill
              class="badge-round mr-25 my-25"
            >
              {{ $t(`app.elements.${place}`) }}
            </b-badge>
          </div>
        </b-list-group-item>
      </b-list-group>
    </div>
    <!-- Bugs -->
    <div
      v-if="bugFixes.length"
      class="mb-n1 mb-md-0"
    >
      <h3 class="text-primary">
        {{ $t('Mistakes') }}
      </h3>
      <b-list-group>
        <b-list-group-item
          v-for="(log, index) in bugFixes"
          :key="`bug_${index}`"
          class="d-flex justify-content-between align-items-center"
        >
          <span>{{ log.label }}</span>
          <div v-if="log.places && log.places.length">
            <b-badge
              v-for="(place, placeIndex) in log.places"
              :key="`bug_place_${placeIndex}`"
              variant="primary"
              pill
              class="badge-round mr-25 my-25"
            >
              {{ $t(`app.elements.${place}`) }}
            </b-badge>
          </div>
        </b-list-group-item>
      </b-list-group>
    </div>
  </b-media>
</template>

<script>
import { BMedia } from 'bootstrap-vue'
import { formatDate } from '@core/utils/filter'
import moment from 'moment'

export default {
  name: 'LogCard',
  components: {
    BMedia,
  },
  props: {
    updateDate: {
      type: String,
      default: '',
    },
    newFutures: {
      type: Array,
      default: () => ([]),
    },
    bugFixes: {
      type: Array,
      default: () => ([]),
    },
  },
  computed: {
    dateFormat() {
      return formatDate(moment(this.updateDate, 'DD.MM.YYYY hh:mm') || new Date(), {
        month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric',
      }, this.$i18n.locale)
    },

    allowedBugs() {
      return this.bugFixes.filter(bug => {
        if (
          (!bug.requiredModule || this.checkRequiredModule(bug.requiredModule))
            && (!bug.requiredPermissions || this.checkRequiredPermissions(bug.requiredPermissions))
        ) return bug

        return false
      })
    },

    allowedFutures() {
      return this.newFutures.filter(future => {
        if (
          (!future.requiredModule || this.checkRequiredModule(future.requiredModule))
            && (!future.requiredPermissions || this.checkRequiredPermissions(future.requiredPermissions))
        ) return future

        return false
      })
    },
  },
}
</script>

<style scoped>

</style>
