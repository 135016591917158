<template>
  <div>
    <b-card no-body>
      <div
        class="d-flex flex-wrap flex-column w-100 justify-content-center align-items-center flex-md-row mb-1"
        style="row-gap: 1rem"
      >
        <slot name="legend" />

        <pie-chart
          v-if="chartData"
          :style="`height: ${isMobile ? 10 : 14}rem`"
          style="max-width: max-content"
          class="w-100"
          :data="chartData"
        />
      </div>

      <!-- eslint-disable -->
      <sw-table
        table-id="d2955a653b9e44f68693d8feda513891"
        show-columns
        :fields.async="fields"
        :pagination="pagination"
        @change-pagination="Object.assign(pagination, $event)"
        @set-fields="fields = $event"
        @reload-content="loadData"
      >
        <template #table>
          <b-table
            :items="items"
            :fields="fields"
            striped
            responsive
            :busy="loading"
            show-empty
          >
            <template #head()="item">
              <div
                class="d-flex flex-row align-items-center"
                style="column-gap: .34rem"
                v-b-tooltip.hover.v-primary
                :title="item.field.tooltip ? $t(item.field.tooltip) : ''"
              >
                {{ $t(item.label) }}

                <feather-icon
                  v-if="item.field.tooltip"
                  icon="InfoIcon"
                />
              </div>
            </template>

            <template #cell(contact)="{ item }">
                <div class="d-flex align-items-center" v-if="item.contactThread.contact">
                    <avatar :user="item.contactThread.contact" :show-name="false" show-tooltip />
                    <b-link :class="{ 'deleted-text': item.contacThread && item.contacThread.contact && item.contacThread.contact.deletedAt }" v-if="item.contactThread.contact"  :href="`/contact/${item.contactThread.contact.id}`" target="_blank">
                        {{ getUserName(item.contactThread.contact) | truncate(30) }}
                        <feather-icon icon="LinkIcon" class="text-success" />
                    </b-link>
                    <span v-else>{{ getUserName(item.contactThread.contact) | truncate(30) }}</span>
                </div>
            </template>

            <template #cell(name)="{ item }">
                <div class="d-flex align-items-center">
                    <avatar :user="item.contact" :show-name="false" show-tooltip />
                    <b-link :class="{ 'deleted-text': item.contact && item.contact.deletedAt }" v-if="item.contact" :href="`/contact/${item.contact.id}/thread/${item.id}/timeline`" target="_blank">
                        {{ item.name }}
                        <feather-icon icon="LinkIcon" class="text-success" />
                    </b-link>
                    <span v-else>{{ item.name }}</span>
                </div>
            </template>

            <template #cell(contactThreadStatusReason)="{ value }">
              <b-badge variant="light-primary">
                {{ value.name }}
              </b-badge>
            </template>

            <template #cell(statusClosedContactThreadFunnelTypePoint)="{ value }">
              <b-badge variant="light-primary">
                <span v-if="value.contactThreadFunnelType">{{ value.contactThreadFunnelType.name }} -</span>
                {{ value.name }}
              </b-badge>
            </template>

            <template #cell(statusNote)="{ value }">
              <div
                v-b-tooltip.hover.v-primary
                :title="value"
              >
                {{ value | truncate(34, '...') }}
              </div>
            </template>

            <template #cell(predictedValue)="{ value, item }">
              {{ value | priceFormat }} {{ item.predictedValueCurrency || 'PLN' }}
            </template>

            <template #cell(assignedUsers)="{ value }">
              <avatar-group :items="value" />
            </template>

            <template #cell(company)="{ item }">
                <div
                  v-if="item.contactThread.contact && item.contactThread.contact.isOrganization && item.contactThread.contact.organization"
                  v-b-tooltip
                  :title="item.contactThread.contact.organization.name"
                >
                  {{ item.contactThread.contact.organization.name | truncate(20) }}
                </div>
                <feather-icon
                  v-else
                  icon="MinusIcon"
                />
            </template>

            <!--    Other    -->
              <!--    Other    -->
              <template #cell()="row">
                  <table-default-cell
                          :field="row.field"
                          :value="row.value"
                  />
              </template>

              <!--      Empty      -->
              <template #empty>
                  <empty-content />
              </template>

              <!--      Table Busy      -->
              <template #table-busy>
                  <div class="text-center text-danger my-2">
                      <b-spinner
                              class="align-middle"
                              variant="primary"
                      />
                  </div>
              </template>
          </b-table>
        </template>
      </sw-table>
    </b-card>
  </div>
</template>

<script>
import { BLink, BTable, VBTooltip } from 'bootstrap-vue'
import PieChart from '@/pages/reports/tabs/components/PieChart'
import axiosIns from '@/libs/axios'
import moment from 'moment/moment'
import { formatDate } from '@core/utils/filter'
import useVerticalLayout from '@core/layouts/layout-vertical/useVerticalLayout'
import { onUnmounted } from '@vue/composition-api'

export default {
  components: {
    PieChart,
    BTable,
    BLink,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },

  props: {
    status: {
      type: String,
      default: '',
    },
    reasons: {
      type: Array,
      required: true,
      default: () => [],
    },
    chartData: {
      required: true,
      default: null,
    },
    title: {
      type: String,
      required: true,
      default: '',
    },
    funnel: {
      type: [String, Object],
      default: '',
    },
    dateFilter: {
      type: [String, Array],
      default: '',
    },
    disabledReasons: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const { isMobile, resizeHandler } = useVerticalLayout()

    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    return {
      isMobile,
    }
  },
  data: () => ({
    items: [],
    loading: false,

    filters: {
      createdAt: null,
      createdAtOptions: null,
    },
    sorter: {
      sortBy: 'createdAt',
      sortDesc: true,
    },
    pagination: {
      perPage: 25,
      totalRecords: 0,
      currentPage: 1,
    },

    fields: [
      { key: 'contact', label: 'Contact', visible: true },
      { key: 'name', label: 'Thread', visible: true },
      { key: 'company', label: 'Company', visible: true },
      { key: 'statusClosedAt', label: 'ClosingDate', visible: true },
      { key: 'statusClosedContactThreadFunnelTypePoint', label: 'Status', visible: true },
      { key: 'contactThreadStatusReason', label: 'Reason', visible: true },
      { key: 'statusNote', label: 'Note', visible: true },
      { key: 'predictedValue', label: 'CustomerValue', visible: true },
      { key: 'assignedUsers', label: 'AssignedUser', visible: true },
    ],
  }),
  watch: {
    funnel(newValue, oldValue) {
      if (newValue !== oldValue) this.loadData()
    },
    disabledReasons() {
      this.loadData()
    },
    dateFilter(newValue, oldValue) {
      if (newValue !== oldValue) this.loadData()
    },
  },

  mounted() {
    this.loadData()
  },
  methods: {
    formatDate,
    loadData() {
      this.loading = true

      const params = {
        fields_load: this.$fields.FUNNEL,
        limit: this.pagination.perPage,
        page: this.pagination.currentPage,
      }

      if (this.reasons && this.reasons.length) {
        params.eqArr_contactThreadStatusReason = JSON.stringify(this.reasons.filter(t => !this.disabledReasons.includes(t.name)).mapKeys('id'))
      }

      if (this.status) {
        params.eq_status = this.status
      }

      if (this.dateFilter) {
        const [startAt, endAt] = this.dateFilter.dateRange()
        if (startAt) params.gte_createdAt = moment(startAt, 'YYYY-MM-DD').format('YYYY-MM-DD hh:mm')
        if (endAt) params.lt_createdAt = moment(endAt, 'YYYY-MM-DD').format('YYYY-MM-DD hh:mm')
      }

      if (this.funnel) {
        params['eq_contactThreadFunnelType-id'] = this.getObjectId(this.funnel)
      }

      axiosIns.get('1/contacts/threads/funnelProcesses', { params })
        .then(res => {
          this.items = res.data.data.items.map(i => ({ ...i, ...i.contactThread }))
          this.pagination.totalRecords = res.data.data.totalItemCount
          this.loading = false
        }).catch(err => {
          console.error(err)
        })
    },
  },
}
</script>
