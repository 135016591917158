export default [
  {
    path: '/structure',
    component: () => import('@/pages/structure/Structure.vue'),
    name: 'structure',
    meta: {
      autorize: true,
      pageTitle: 'Structure',
    },
  },
]
