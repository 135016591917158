<script>
import axiosIns from '@/libs/axios'
import TrainingForm from '@/pages/trainings/components/TrainingForm.vue'
import TrainerForm from '@/pages/trainings/components/TrainerForm.vue'
import TomekAvatar from '@/assets/images/tomek.png'
import PatrykAvatar from '@/assets/images/patryk.png'
import PiotrAvatar from '@/assets/images/peter.png'
import TrainerListCard from '@/pages/trainings/components/TrainerListCard.vue'
import TrainingListCard from '@/pages/trainings/components/TrainingListCard.vue'
import { BFormTextarea } from 'bootstrap-vue'

const emptyTrainer = {
  name: '',
  avatar: '',
  websiteUrl: '',
  youtubeUrl: '',
  description: '',
}

const emptyTraining = {
  name: '',
  url: '',
  trainingTrainer: '',
}

const emptyContactForm = {
  message: '',
}

export default {
  name: 'Trainings',
  components: {
    TrainingListCard, TrainerListCard, TrainerForm, TrainingForm, BFormTextarea,
  },
  data: () => ({
    emptyTrainer,
    emptyTraining,
    emptyContactForm,

    search: '',
    selectedTrainerId: null,

    contactLoading: false,
    contactModal: {
      show: false,
      item: { ...emptyContactForm },
    },

    trainerModal: {
      show: false,
      item: null,
    },
    defaultTrainers: [
      {
        id: 'SW1',
        name: 'Patryk Jasiński',
        staticAvatar: PatrykAvatar,
        websiteUrl: 'https://patrykjasinski.pl/',
        youtubeUrl: 'https://www.youtube.com/@ASPatrykJasinski',
        description: 'Właściciel AS Akademia Sprzedaży. Doświadczony praktyk sprzedaży, trener i coach z pasją do procesu sprzedaży. Materiały wideo Patryka dotyczą zagadnień związanych ze skuteczną sprzedażą i zarządzania relacjami z klientami.',
      },
      {
        id: 'SW2',
        name: 'Tomasz Socha',
        staticAvatar: TomekAvatar,
        websiteUrl: 'https://4bsystems.pl/',
        youtubeUrl: 'https://www.youtube.com/@crmsaleswizard',
        description: 'CEO 4B Systems sp. z o.o. W swoich materiałach wideo omawia potrzeby firm związane z wdrożeniem oprogramowania CRM. Prowadzi szkolenia onboardingowe systemu SalesWizard dla firm z branż OZE, prawo, marketing.',
      },
      {
        id: 'SW3',
        name: 'Piotr Ignaczak',
        staticAvatar: PiotrAvatar,
        description: 'Szkoli i doradza m. in. w zakresie budowy, wdrażania standardów ekspozycji towarów. Prowadzi szkolenia szefowskie, w tym z zakresu pokonywania rutyny i wypalenia zawodowego. Pracował dla takich marek jak Costcutter Grupa Detalistów S.A., Heinz Polska sp. z o.o., Hochland Polska, Morliny S.A., Rossmann, Unilever Polska.',
      },
    ],
    trainers: [],
    trainerLoading: false,

    trainingModal: {
      show: false,
      item: null,
    },
    defaultTrainings: [
      {
        name: 'Jak ZARZĄDZAĆ LEJKIEM SPRZEDAŻOWYM (6 ETAPÓW)',
        url: 'https://www.youtube.com/embed/OQQZdNYKiR4',
        trainingTrainer: { id: 'SW1' },
      }, {
        name: 'Jak Zwiększyć Sprzedaż W Sezonie Martwym? (8 Praktycznych Rad)',
        url: 'https://www.youtube.com/embed/Lc7FFWtoQsg',
        trainingTrainer: { id: 'SW1' },
      }, {
        name: 'TECHNIKI SPRZEDAŻY | KONTRAKTACJA W SPRZEDAŻY',
        url: 'https://www.youtube.com/embed/xoYw7M_CHbE',
        trainingTrainer: { id: 'SW1' },
      }, {
        name: 'Jak SZYBKO ZDOBYĆ ZAUFANIE KLIENTA? (Sprzedaż telefoniczna)',
        url: 'https://www.youtube.com/embed/HSOEaoI9EiA',
        trainingTrainer: { id: 'SW1' },
      }, {
        name: 'Po co Twojej firmie CRM?',
        url: 'https://www.youtube.com/embed/cCwm-yv8NIs',
        trainingTrainer: { id: 'SW2' },
      }, {
        name: 'System CRM dla branży OZE',
        url: 'https://www.youtube.com/embed/UrAA4wRJ3fY',
        trainingTrainer: { id: 'SW2' },
      }, {
        name: 'Onboarding nowego pracownika',
        url: 'https://youtube.com/embed/QP1A7aIkRy4',
        trainingTrainer: { id: 'SW3' },
      },
    ],
    trainings: [],
    trainingLoading: false,
  }),
  computed: {
    trainersList() {
      return [...this.defaultTrainers, ...this.trainers]
    },
    trainingsList() {
      let items = [...this.trainings, ...this.defaultTrainings]
      if (this.selectedTrainerId) {
        items = items.filter(item => item.trainingTrainer.id === this.selectedTrainerId)
      }

      if (this.search) {
        items = items.filter(item => item.name.toLowerCase().includes(this.search.toLowerCase()))
      }

      return items
    },
  },
  async mounted() {
    this.init()
  },
  methods: {
    async init() {
      this.trainerLoading = true
      this.trainingLoading = true

      await this.loadTrainers()
      await this.loadTrainings()
    },
    async sendContactMessage() {
      this.contactLoading = true
      const user = this.$store.getters['auth/getCurrentUser']
      try {
        const payload = {
          ...this.contactModal.item,
          systemUrl: window.location.origin,
          userName: this.getUserName(user),
          userPhone: user.phone,
          userEmail: user.email,
        }
        await axiosIns.post('1/trainingTrainers/sendMail', payload)

        this.contactModal = {
          show: false,
          item: { ...this.emptyContactForm },
        }
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        setTimeout(() => {
          this.contactLoading = false
        }, 250)
      }
    },
    async loadTrainers() {
      try {
        const params = { fields_load: 'TRAINERS' }
        const response = await axiosIns.get('1/trainingTrainers', { params })

        if (response.data?.data?.items) this.trainers = response.data.data.items
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        setTimeout(() => {
          this.trainerLoading = false
        }, 250)
      }
    },
    async loadTrainings() {
      try {
        const params = { fields_load: 'TRAININGS_FULL', orderBy: 'id.desc' }
        const response = await axiosIns.get('1/trainings', { params })

        if (response.data?.data?.items) {
          this.trainings = response.data.data.items
          await this.assignTrainersToTrainings()
        }
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        setTimeout(() => {
          this.trainingLoading = false
        }, 250)
      }
    },
    async assignTrainersToTrainings() {
      this.trainings = this.trainings.map(training => ({
        ...training,
        trainingTrainer: training?.trainingTrainer?.name ? training.trainingTrainer : this.trainers.find(trainer => trainer.id === training.trainingTrainer.id),
      }))

      this.defaultTrainings = this.defaultTrainings.map(training => ({
        ...training,
        trainingTrainer: this.defaultTrainers.find(trainer => trainer.id === training.trainingTrainer.id),
      }))
      return true
    },

    async removeTrainer({ id }) {
      const accepted = await this.showAlert('error', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('alerts.WontbeAble'))
      if (!accepted) return

      try {
        const trainingsToDelete = this.trainings.filter(e => e.trainingTrainer?.id === id)
        if (trainingsToDelete.length) {
          await Promise.all(trainingsToDelete.map(t => this.removeTraining(t, false)))
        }

        // eslint-disable-next-line no-unreachable
        await axiosIns.delete('1/trainingTrainers', { data: JSON.stringify([id]) })
        const index = this.trainers.findIndex(e => e.id === id)
        if (index === -1) return

        this.$delete(this.trainers, index)
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    async removeTraining({ id }, showAlert = true) {
      if (showAlert) {
        const accepted = await this.showAlert('error', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('alerts.WontbeAble'))
        if (!accepted) return
      }

      try {
        await axiosIns.delete('1/trainings', { data: JSON.stringify([id]) })
        const index = this.trainings.findIndex(e => e.id === id)
        if (index === -1) return

        this.$delete(this.trainings, index)
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
  },
}
</script>

<template>
  <div>
    <div class="d-flex align-items-center m-50">
      <span class="font-medium-1 text-bolder mr-1">{{ $t('Trainers') }}</span>
      <b-button
        v-if="checkRequiredPermissions([$roles.FULL_ACCESS])"
        variant="primary"
        size="sm"
        @click="trainerModal = { show: true, item: JSON.parse(JSON.stringify(emptyTrainer)) }"
      >
        <feather-icon icon="PlusIcon" />
        {{ $t('Add') }}
      </b-button>
    </div>

    <b-card v-if="trainerLoading">
      <b-spinner variant="primary" />
    </b-card>
    <b-row v-else-if="trainersList.length">
      <b-col
        v-for="(trainer, index) in trainersList"
        :key="`trainer_${index}`"
        sm="12"
        md="6"
        lg="4"
      >
        <trainer-list-card
          :item="trainer"
          :is-selected="selectedTrainerId === trainer.id"
          @select="selectedTrainerId = $event"
          @edit="trainerModal = { show: true, item: JSON.parse(JSON.stringify(trainer)) }"
          @remove="removeTrainer(trainer, index)"
          @contact="contactModal = { show: true, item: JSON.parse(JSON.stringify({ ...emptyContactForm, trainerName: trainer.name })) }"
        />
      </b-col>
    </b-row>
    <b-card v-else>
      <div class="text-center py-1 font-weight-bold text-primary">
        <feather-icon
          size="17"
          icon="XCircleIcon"
        />
        {{ $t('NoData') }}
      </div>
    </b-card>

    <div class="d-flex align-items-center m-50">
      <span class="font-medium-1 text-bolder mr-1">{{ $t('Trainings') }}</span>
      <b-button
        v-if="checkRequiredPermissions([$roles.FULL_ACCESS])"
        variant="primary"
        size="sm"
        :disabled="!trainersList.length"
        @click="trainingModal = { show: true, item: JSON.parse(JSON.stringify(emptyTraining)) }"
      >
        <feather-icon icon="PlusIcon" />
        {{ $t('Add') }}
      </b-button>
    </div>

    <b-form-group>
      <b-form-input
        v-model="search"
        :placeholder="$t('TypeToSearch')"
      />
    </b-form-group>

    <b-card v-if="trainingLoading">
      <b-spinner variant="primary" />
    </b-card>
    <b-row v-else-if="trainingsList.length">
      <b-col
        v-for="(training, index) in trainingsList"
        :key="`training_${index}`"
        sm="12"
        lg="6"
      >
        <training-list-card
          :item="training"
          @edit="trainingModal = { show: true, item: JSON.parse(JSON.stringify(training)) }"
          @remove="removeTraining(training, index)"
        />
      </b-col>
    </b-row>
    <b-card v-else>
      <div class="text-center py-1 font-weight-bold text-primary">
        <feather-icon
          size="17"
          icon="XCircleIcon"
        />
        {{ $t('NoData') }}
      </div>
    </b-card>

    <!-- Trainer Form Modal -->
    <b-modal
      v-model="trainerModal.show"
      size="lg"
      :title="trainerModal.item && trainerModal.item.id ? $t('Edit') : $t('Add')"
      hide-footer
      no-close-on-backdrop
    >
      <div v-if="trainerModal.show">
        <trainer-form
          :edited-item="trainerModal.item"
          @close-modal="trainerModal.show = false; trainerModal.item = null; loadTrainers()"
        />
      </div>
    </b-modal>

    <!-- Training Form Modal -->
    <b-modal
      v-model="trainingModal.show"
      size="lg"
      :title="trainingModal.item && trainingModal.item.id ? $t('Edit') : $t('Add')"
      hide-footer
      no-close-on-backdrop
    >
      <div v-if="trainingModal.show">
        <training-form
          :edited-item="trainingModal.item"
          @close-modal="trainingModal.show = false; trainingModal.item = null; loadTrainings()"
        />
      </div>
    </b-modal>

    <!-- Trainer Contact Modal -->
    <b-modal
      v-model="contactModal.show"
      size="md"
      :title="$t('ContactUs')"
      hide-footer
      no-close-on-backdrop
    >
      <div v-if="contactModal.show">
        <b-form-group :label="$t('Content')">
          <b-form-textarea v-model="contactModal.item.message" />
        </b-form-group>

        <b-button
          :disabled="!contactModal.item.message || contactLoading"
          variant="primary"
          size="sm"
          @click="sendContactMessage"
        >
          <feather-icon icon="SendIcon"
                        class="mr-25"
          />
          {{ $t('Send') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<style scoped>

</style>
