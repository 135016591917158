// Total net price
export const netPriceByAmount = product => (product.shopProduct.offerPriceWithDiscount || 0) * (product.amount || 1)

// Total net price after cart discount
export const netPriceAfterCartDiscount = (product, sellerDiscount = false) => {
  let baseNetPrice = netPriceByAmount(product)

  if (sellerDiscount && product.sellerDiscount) {
    baseNetPrice -= product.sellerDiscount || 0
  }

  if (product?.discountType === 'NETTO_VALUE') return baseNetPrice - product.discount
  if (product?.discountType === 'NETTO_PERCENT') return baseNetPrice - ((product.discount / 100) * baseNetPrice)

  return baseNetPrice
}

// Total gross price after cart discount and vat overwrite
export const grossPrice = (product, sellerDiscount = false) => {
  const netPrice = netPriceAfterCartDiscount(product, sellerDiscount) || 0
  let { vat } = product.shopProduct

  if (product.overwriteVat !== null) vat = product.overwriteVat
  // if (this.isOnOverwriteVatInCart) vat = this.overwriteVatInCart

  return netPrice * ((Number(vat) / 100) + 1)
}

export const loyaltyPointsPrice = product => (product?.shopProductLoyaltyPlans?.length ? (product?.shopProductLoyaltyPlans.reduce((a, b) => +a + (+b.pointsPrice * product.amount || 1) || 0, 0)) : 0)
