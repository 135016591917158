<template>
  <b-row
    class="sw-button-breadcrumb d-flex"
    style="row-gap: .34rem"
  >
    <slot />
  </b-row>
</template>

<script>
export default {
  name: 'SwButtonBreadcrumb',
}
</script>
