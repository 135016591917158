<template>
  <div>
    <!--    Option: Title    -->
    <b-row class="mx-0">
      <b-col
        sm="12"
        md="6"
        lg="8"
        class="px-1"
      >
        <my-option
          :title="$t('Title')"
          :tip="$t('MaximumLength') + ': 50'"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="$t('Title')"
            rules="required"
            class="w-100"
          >
            <b-form-group>
              <b-form-input
                id="popover-QAndATitle"
                v-model.trim="newWebsite.QAndATitle"
                :placeholder="$t('Title')"
                :state="errors.length > 0 ? false:null"
                maxlength="50"
              />
              <small class="text-danger">{{ errors[0] }}</small>

              <!--    Popover    -->
              <b-popover
                target="popover-QAndATitle"
                triggers="focus"
                variant="primary"
              >
                <span>{{ $t('MaximumLength') + ': 50' }}</span>
              </b-popover>
            </b-form-group>
          </validation-provider>
        </my-option>
      </b-col>
    </b-row>

    <!--    Option: Description    -->
    <b-col
      sm="12"
      md="12"
      lg="12"
      class="px-1"
    >
      <my-option
        :title="$t('task.Description')"
        :tip="$t('MaximumLength') + ': 110'"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="$t('Title')"
          rules="required"
          class="w-100"
        >
          <b-form-group>
            <b-form-textarea
              v-model.trim="newWebsite.QAndADesc"
              :placeholder="$t('Title')"
              :state="errors.length > 0 ? false:null"
              maxlength="110"
            />
            <small class="text-danger">{{ errors[0] }}</small>

            <!--    Popover    -->
            <b-popover
              target="popover-bannerDesc"
              triggers="focus"
              variant="primary"
            >
              <span>{{ $t('MaximumLength') + ': 110' }}</span>
            </b-popover>
          </b-form-group>
        </validation-provider>
      </my-option>
    </b-col>

    <!--    Option: QAndA    -->
    <b-row class="mx-0">
      <b-col
        sm="12"
        md="12"
        lg="8"
        class="px-1"
      >
        <my-option :title="$t('offer.website.QAndA')">
          <template #action>
            <b-button
              class="ml-50"
              variant="primary"
              size="sm"
              @click="
                selectedOption = { id: -1, opt: JSON.parse(JSON.stringify({ question: '', answer: '' })) }
                showChangeQAndAOptionsModal = true
              "
            >
              <sw-icon icon="PlusIcon" /> {{ $t('Add') }}
            </b-button>
          </template>
          <!--    Form    -->
          <!--          <b-row class="w-100 mx-0 px-0">-->
          <!--            &lt;!&ndash;    Question    &ndash;&gt;-->
          <!--            <b-col-->
          <!--              sm="12"-->
          <!--              md="12"-->
          <!--              lg="12"-->
          <!--              class="d-flex mb-25 px-0"-->
          <!--            >-->
          <!--              <b-form-input-->
          <!--                id="popover-question"-->
          <!--                v-model.trim="newQAndA.question"-->
          <!--                :placeholder="$t('Question')"-->
          <!--                maxlength="120"-->
          <!--              />-->

          <!--              &lt;!&ndash;    Popover    &ndash;&gt;-->
          <!--              <b-popover-->
          <!--                target="popover-question"-->
          <!--                triggers="focus"-->
          <!--                variant="primary"-->
          <!--              >-->
          <!--                <span>{{ $t('MaximumLength') + ': 120' }}</span>-->
          <!--              </b-popover>-->

          <!--              &lt;!&ndash;    Add Button    &ndash;&gt;-->
          <!--              <b-button-->
          <!--                variant="primary"-->
          <!--                class="btn-icon ml-1 offerWebsiteAdd"-->
          <!--                @click="-->
          <!--                  () => {-->
          <!--                    if (newQAndA.question.length > 0 && newQAndA.answer.length > 0) {-->
          <!--                      newWebsite.QAndAOptions.push({-->
          <!--                        question: JSON.parse(JSON.stringify(newQAndA.question)),-->
          <!--                        answer: JSON.parse(JSON.stringify(newQAndA.answer)),-->
          <!--                      })-->

          <!--                      newQAndA.question = ''-->
          <!--                      newQAndA.answer = ''-->
          <!--                    }-->
          <!--                  }-->
          <!--                "-->
          <!--              >-->
          <!--                <feather-icon icon="PlusIcon" />-->
          <!--              </b-button>-->
          <!--            </b-col>-->

          <!--            &lt;!&ndash;    Answer    &ndash;&gt;-->
          <!--            <b-col-->
          <!--              sm="12"-->
          <!--              md="12"-->
          <!--              lg="12"-->
          <!--              class="d-flex mt-25 px-0"-->
          <!--            >-->
          <!--              <b-form-textarea-->
          <!--                id="popover-answer"-->
          <!--                v-model.trim="newQAndA.answer"-->
          <!--                :placeholder="$t('Answer')"-->
          <!--                maxlength="300"-->
          <!--              />-->

          <!--              &lt;!&ndash;    Popover    &ndash;&gt;-->
          <!--              <b-popover-->
          <!--                target="popover-answer"-->
          <!--                triggers="focus"-->
          <!--                variant="primary"-->
          <!--              >-->
          <!--                <span>{{ $t('MaximumLength') + ': 300' }}</span>-->
          <!--              </b-popover>-->
          <!--            </b-col>-->
          <!--          </b-row>-->

          <!--    Options    -->
        </my-option>
      </b-col>

      <b-col sm="12">
        <b-row>
          <b-col v-for="(item, index) in newWebsite.QAndAOptions"
                 :key="index"
                 sm="12"
                 md="6"
          >
            <b-row class="w-100 px-0 mx-0">
              <b-col sm="12"
                     class="d-flex justify-content-between align-items-center mt-25"
              >
                <div>{{ index + 1 }}. {{ $t('Question') }}</div>
                <div>
                  <!--    Remove Button    -->
                  <b-button
                    variant="flat-warning"
                    class="btn-icon"
                    size="sm"
                    @click="
                      selectedOption = { id: index, opt: JSON.parse(JSON.stringify(item)) }
                      showChangeQAndAOptionsModal = true
                    "
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>

                  <b-button
                    variant="flat-danger"
                    class="btn-icon ml-25"
                    size="sm"
                    @click="
                      newWebsite.QAndAOptions.splice(index, 1)
                      newWebsite.QAndAOptions.push()
                    "
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </div>
              </b-col>
              <!--    Question    -->
              <b-col
                sm="12"
                md="12"
                lg="12"
                class="d-flex mb-25 mt-25 px-0"
              >
                <b-form-input
                  v-model.trim="item.question"
                  :placeholder="$t('Question')"
                  readonly
                />
              </b-col>

              <!--    Answer    -->
              <b-col
                sm="12"
                md="12"
                lg="12"
                class="mt-25 d-flex align-items-end px-0"
              >
                <b-form-textarea
                  v-model.trim="item.answer"
                  :placeholder="$t('Answer')"
                  readonly
                />
              </b-col>
            </b-row>

            <hr class="mb-0">
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!--    Modal: Change QAndA options     -->
    <b-modal
      v-model="showChangeQAndAOptionsModal"
      :title="$t('offer.website.QAndA')"
      size="sm"
      hide-footer
      no-close-on-backdrop
    >
      <b-form>
        <validation-provider
          v-slot="{ errors }"
          :name="$t('Question')"
          rules="required"
          class="w-100"
        >
          <b-form-group :label="$t('Question')">
            <b-form-input
              v-model.trim="selectedOption.opt.question"
              :placeholder="$t('Question')"
              :state="errors.length > 0 ? false:null"
              maxlength="120"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <validation-provider
          v-slot="{ errors }"
          :name="$t('Answer')"
          rules="required"
          class="w-100"
        >
          <b-form-group :label="$t('Answer')">
            <b-form-input
              v-model.trim="selectedOption.opt.answer"
              :placeholder="$t('Answer')"
              :state="errors.length > 0 ? false:null"
              maxlength="300"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <!--    Button: Save    -->
        <b-button
          variant="success"
          class="ml-25"
          size="sm"
          @click="() => {
            if (selectedOption.id === -1) {
              newWebsite.QAndAOptions.push(selectedOption.opt)
            } else newWebsite.QAndAOptions[selectedOption.id] = selectedOption.opt
            $forceUpdate()

            showChangeQAndAOptionsModal = false
          }"
        >
          {{ $t('Save') }}
        </b-button>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  BForm, BFormTextarea, BPopover, VBTooltip,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import myOption from '@/views/offers/website/Option.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BForm,
    BFormTextarea,
    myOption,
    ValidationProvider,
    BPopover,
  },

  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  props: {
    value: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    newWebsite: { },

    newQAndA: {
      question: '',
      answer: '',
    },

    selectedOption: { },

    showChangeQAndAOptionsModal: false,
  }),

  mounted() {
    this.newWebsite = this.value
  },

  methods: {
  },
}
</script>
