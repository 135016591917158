<template>
  <div>
    <sw-filters-modern
      v-model="filters"
      class="mb-50"
      @refresh="loadProjects"
      @change="resolveFilters"
    />

    <template v-if="!loading && projects.length">
      <item-extensible
        v-for="(target, i) in projects"
        :key="i"
        :index="i"
        :project="target.project || null"
        :target="target"
        hide-collapse
        :is-task="false"
      />
    </template>

    <div
      v-else-if="!loading && !projects.length"
      class="text-center py-1 text-primary font-weight-bold"
    >
      <feather-icon
        size="17"
        icon="XCircleIcon"
      />
      {{ $t('NoData') }}
    </div>

    <div
      v-else
      class="d-flex justify-content-center"
    >
      <b-spinner
        variant="primary"
        size="sm"
      />
    </div>
  </div>
</template>

<script>
import {
  VBModal, VBToggle, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'
import { GET_PROJECT_STATUSES, GET_PROJECTS } from '@/@constants/mutations'
import ItemExtensible from '@/pages/projects/components/list/component/ItemExtensible.vue'
import i18n from '@/libs/i18n'

export default {
  components: { ItemExtensible },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    startFilters: {
      type: Object,
      required: false,
      default: () => ({ assignedUsers: [JSON.parse(localStorage.getItem('user'))] }),
    },
    thread: {
      type: Object,
      required: false,
      default: null,
    },
    showFilters: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data: () => ({
    loading: false,

    statuses: [],
    filters: [
      {
        type: 'refresh',
      },
      {
        type: 'radio',
        key: 'status',
        pending: true,
        active: '',
        options: [],
      },
    ],

    projects: [],
  }),
  computed: {
    ...mapGetters({
      currentThread: 'contact/thread',
    }),
  },
  async mounted() {
    try {
      this.loading = true

      const options = await this.$store.dispatch(`projectStatuses/${GET_PROJECT_STATUSES}`)

      this.filters[1].pending = true
      // eslint-disable-next-line prefer-destructuring
      this.filters[1].active = { label: i18n.t('All') }
      this.filters[1].options = [{ label: i18n.t('All') }, ...options.map(opt => ({ ...opt, label: opt.name }))]

      this.resolveFilters({
        filter: this.filters[1],
        option: { label: i18n.t('All') },
      })
    } catch (err) {
      this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
    } finally {
      this.loading = false
      this.filters[1].pending = false
    }
  },
  methods: {
    resolveFilters({ filter, option }) {
      if (filter.key === 'status') {
        this.status = option.id
        this.loadProjects()
      }
    },

    async loadProjects(statusId = this.status) {
      try {
        this.loading = true

        this.projects = await this.$store.dispatch(`projects/${GET_PROJECTS}`, {
          status: statusId,
          contactThreads: [this.currentThread],
        })
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
