<template>
  <!-- Error page-->
  <div
    class="misc-wrapper"
  >
    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          {{ $t('YourServiceIsBlocked') }}
          <feather-icon
            class="text-primary"
            size="20"
            icon="LockIcon"
          />
        </h2>
        <div>
          <p class="mb-2">
            {{ $t('YourServiceIsBlockedCheckSubscriptionPanel') }}
          </p>
          <b-button
            variant="outline-primary"
            class="btn-sm-block mr-25"
            :to="loggedIn ? $router.push('/') : $router.push('/login')"
          >
            {{ $t(loggedIn ? 'Back' : 'BackToLogin') }}
          </b-button>
          <b-button
            variant="primary"
            class="btn-sm-block"
            href="https://panel.4b-systems.com/"
          >
            {{ $t('GoToSubscriptionPanel') }}
          </b-button>
        </div>
        <!-- image -->
        <b-img
          fluid
          :src="imgUrl"
          alt="Error page"
        />
      </div>
    </div>
  </div>
  <!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BButton, BImg } from 'bootstrap-vue'
import store from '@/store/index'

export default {
  components: {
    BButton,
    BImg,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/not-authorized.svg'),
    }
  },
  computed: {
    loggedIn() {
      return Boolean(localStorage.getItem('token'))
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
    getLocale() {
      return navigator.language
    },
  },
  mounted() {
    const [lang] = navigator.language.split('_')
    this.$i18n.locale = lang
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
