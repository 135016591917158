<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content email-app-sidebar">
        <div class="email-app-menu">
          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="sidebar-menu-list scroll-area"
          >
            <sw-select style="margin-top: 2px"
                       class="mb-0"
            >
              <v-select
                :clearable="false"
                :options="[10, 25, 50, 100]"
                :value="perPage"
                @input="$emit('set-per-page', $event)"
              >
                <template #no-options>
                  <span>
                    {{ $t('NoData') }}
                  </span>
                </template>
              </v-select>
            </sw-select>

            <sw-select style="margin-top: 2px">
              <v-select
                label="name"
                :options="[{ id: null, name: $i18n.t('All') }, ...allEmails]"
                :placeholder="$t('gEmail.email')"
                :value="email"
                :clearable="false"
                @input="$emit('set-email', $event)"
              >
                <template #no-options>
                  <span>
                    {{ $t('NoData') }}
                  </span>
                </template>
              </v-select>
            </sw-select>

            <div class="pb-1 px-1 d-flex align-items-center">
              <b-button
                variant="primary"
                class="btn-icon text-center"
                style="min-width: 50px"
                @click="$emit('reload')"
              >
                <feather-icon icon="RefreshCwIcon" />
              </b-button>
              <b-button
                variant="outline-primary"
                block
                class="btn-icon flex-grow-1 mt-0 ml-25"
                @click="$emit('update:shall-show-email-compose-modal', true); $emit('close-left-sidebar')"
              >
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-25"
                />

                <span>{{ $t('Create') }}</span>
              </b-button>
            </div>

            <!-- Filters -->
            <b-list-group class="list-group-messages">
              <b-list-group-item
                class="cursor-pointer d-flex align-items-center justify-content-between font-small-4 py-75"
                :class="{ 'active': activeStatus === 'ALL' }"
                @click="$emit('close-left-sidebar'); $emit('set-message-status', 'ALL')"
              >
                <div class="d-flex align-items-center justify-content-between">
                  <feather-icon
                    icon="GridIcon"
                    class="text-primary mr-50"
                    size="18"
                  />

                  <span class="align-text-bottom line-height-1">{{ $t('All') }}</span>
                </div>

                <div>
                  <b-badge v-b-tooltip
                           pill
                           :variant="`light-primary` || 'light-dark'"
                           :title="$t('All')"
                  >
                    <span>{{ totalEmails.all }}</span>
                  </b-badge>
                  <b-badge v-b-tooltip
                           pill
                           class="ml-25"
                           :variant="`light-warning` || 'light-dark'"
                           :title="$t('Unread')"
                  >
                    <span>{{ totalEmails.unread }}</span>
                  </b-badge>
                </div>
              </b-list-group-item>
              <b-list-group-item
                v-for="filter in allFilteredKeys"
                :key="filter"
                class="cursor-pointer d-flex align-items-center justify-content-between font-small-4 py-75"
                :class="{ 'active': activeStatus === filter }"
                @click="$emit('close-left-sidebar'); $emit('set-message-status', statistics[filter].orginalKey)"
              >
                <div class="d-flex align-items-center justify-content-between">
                  <!--                  <feather-icon-->
                  <!--                    :icon="findStatus(filter) ? findStatus(filter).icon : 'CircleIcon' || 'MinusIcon'"-->
                  <!--                    :class="`text-${findStatus(filter) ? findStatus(filter).variant : 'primary'}`"-->
                  <!--                    class="mr-50"-->
                  <!--                    size="18"-->
                  <!--                  />-->

                  <sw-icon v-if="email.id === null && getFolderAvailableMails(statistics[filter].orginalKey).length"
                           :id="`imp_folder_${statistics[filter].orginalKey}`"
                           class="mr-25 text-primary"
                           icon="HelpCircleIcon"
                  />

                  <b-popover triggers="hover"
                             :target="`imp_folder_${statistics[filter].orginalKey}`"
                             :title="$t('FolderAvailableFor')"
                             custom-class="popover-folders p-0"
                  >
                    <b-list-group flush>
                      <b-list-group-item v-for="mailbox in getFolderAvailableMails(statistics[filter].orginalKey)"
                                         :key="`popover_${mailbox.id}_${mailbox.folder}`"
                      >
                        <div class="d-flex justify-content-between">
                          <div>{{ mailbox.name | truncate(20) }}</div>
                          <div v-b-tooltip
                               :title="$t('FolderName')"
                          >
                            {{ mailbox.folder }}
                          </div>
                        </div>
                      </b-list-group-item>
                    </b-list-group>

                  </b-popover>

                  <span v-b-tooltip
                        class="align-text-bottom line-height-1"
                        :title="(findStatus(filter) ? $t(findStatus(filter).title) : filter)"
                  >{{ (findStatus(filter) ? $t(findStatus(filter).title) : filter) | truncate(15) }}</span>
                </div>

                <div>
                  <b-badge
                    v-if="statistics[filter] && statistics[filter].all !== undefined"
                    v-b-tooltip
                    pill
                    :variant="`light-primary` || 'light-dark'"
                    :title="$t('All')"
                  >
                    <span>{{ statistics[filter].all }}</span>
                  </b-badge>
                  <b-badge
                    v-if="statistics[filter] && statistics[filter].unread !== undefined"
                    v-b-tooltip
                    pill
                    class="ml-25"
                    :variant="`light-warning` || 'light-dark'"
                    :title="$t('Unread')"
                  >
                    <span>{{ statistics[filter].unread }}</span>
                  </b-badge>

                </div>
              </b-list-group-item>
            </b-list-group>

            <!-- Labels -->
            <!--            <h6 class="section-label mt-3 mb-1 px-2">-->
            <!--              {{ $t('Labels') }}-->
            <!--            </h6>-->

            <!--            <b-list-group class="list-group-labels">-->
            <!--              <b-list-group-item-->
            <!--                v-for="label in emailLabel"-->
            <!--                :key="label.title"-->
            <!--                @click="$emit('close-left-sidebar')"-->
            <!--              >-->
            <!--                <span-->
            <!--                  class="bullet bullet-sm mr-1"-->
            <!--                  :class="`bullet-${label.color}`"-->
            <!--                />-->
            <!--                <span>{{ label.title }}</span>-->
            <!--              </b-list-group-item>-->
            <!--            </b-list-group>-->
          </vue-perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {
  BListGroup, BListGroupItem, BPopover, VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import emailFilters from '@/@types/emailFiltersGlobal'

export default {
  components: {
    // BSV
    BListGroup,
    BListGroupItem,
    vSelect,
    BPopover,
    // 3rd Party
    VuePerfectScrollbar,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    perPage: {
      type: Number,
      default: 25,
    },
    email: {
      default: null,
    },
    totalEmails: {
      default: 0,
    },
    activeStatus: {
      type: String,
      default: 'all',
    },
    statistics: {
      type: Object,
      default: () => ({}),
    },
    allEmails: {
      default: () => ([]),
    },
    allKeys: {
      default: () => ([]),
    },
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }

    const resolveFilterBadgeText = filter => {
      if (filter === 'INBOX') return 'text-primary'
      if (filter === 'INBOX.Sent') return 'text-success'
      if (filter === 'INBOX.Spam') return 'text-warning'
      if (filter === 'INBOX.Trash') return 'text-danger'
      if (filter === 'INBOX.Drafts') return 'text-info'
      return 'text-dark'
    }

    const resolveFilterBadgeVariant = filter => {
      if (filter === 'INBOX') return 'light-primary'
      if (filter === 'INBOX.Sent') return 'light-success'
      if (filter === 'INBOX.Spam') return 'light-warning'
      if (filter === 'INBOX.Trash') return 'light-danger'
      if (filter === 'INBOX.Drafts') return 'light-info'
      return 'dark'
    }

    const resolveFilterBadgeIcon = filter => {
      if (filter === 'INBOX') return 'EyeIcon'
      if (filter === 'INBOX.Sent') return 'SendIcon'
      if (filter === 'INBOX.Spam') return 'AlertCircleIcon'
      if (filter === 'INBOX.Trash') return 'TrashIcon'
      if (filter === 'INBOX.Drafts') return 'EditIcon'
      return 'MinusIcon'
    }

    const resolveFilterStatus = filter => {
      const text = resolveFilterBadgeText(filter)
      const variant = resolveFilterBadgeVariant(filter)
      const icon = resolveFilterBadgeIcon(filter)

      return { text, variant, icon }
    }

    return {
      // UI
      perfectScrollbarSettings,
      resolveFilterBadgeText,
      resolveFilterBadgeVariant,
      resolveFilterBadgeIcon,
      resolveFilterStatus,
      emailFilters,
    }
  },
  data: () => ({}),
  computed: {
    allFilteredKeys() {
      return (this.allKeys || []).filter(key => !this.statistics[key]?.hide)
    },
  },
  methods: {
    getFolderAvailableMails(fo) {
      return [
        ...this.allEmails
          .filter(mailbox => mailbox.userMailboxFolders?.find(mailboxFolder => mailboxFolder.folder === fo))
          .map(mailbox => ({ ...mailbox, folder: mailbox.userMailboxFolders?.find(mailboxFolder => mailboxFolder.folder === fo)?.name || '-' }))] || []
    },
    findStatus(status) {
      // if (status === 'INBOX.Sent') {
      //   return this.emailFilters[2]
      // }
      // if (status === 'INBOX') {
      //   return this.emailFilters[1]
      // }
      // if (status === 'INBOX.Draft') {
      //   return this.emailFilters[4]
      // }

      if (this.email?.userMailboxFolders?.length && this.email?.userMailboxFolders.find(e => e.folder === status)) {
        return {
          ...this.email?.userMailboxFolders.find(e => e.folder === status),
          variant: 'primary',
          icon: 'CircleIcon',
          title: this.email?.userMailboxFolders.find(e => e.folder === status).name,
        }
      }

      return this.emailFilters.find(e => ((this.email.sendFolder === status) || (this.email.draftFolder === status)) && e?.status?.toLowerCase()?.includes(status?.toLowerCase()))
    },
    getKeys() {
      return [...Object.keys(this.statistics).slice(0, 2), ...Object.keys(this.statistics).slice(3).sort((a, b) => {
        const aInArray = this.findStatus(a)
        const bInArray = this.findStatus(b)

        if (aInArray && !bInArray) return -1
        if (!aInArray && bInArray) return 1
        return a.localeCompare(b)
      })]
    },
  },
}
</script>

<style lang="scss">
.popover-folders {
  max-width: unset !important;
  & .popover-body {
    width: 350px;
  }
}
</style>
