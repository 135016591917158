<!--eslint-disable-->

<template>
  <div class="d-flex">
    <b-card class="checkout-product mb-0 p-0 flex-grow-1">
      <div
        v-if="isMobile"
        class="d-flex flex-column"
      >
        <div
          class="d-flex flex-wrap justify-content-between align-items-center px-1 py-75"
          style="row-gap: .34rem"
        >
          <!--    Cart Items Product title    -->
          <div class="d-flex align-items-center">
            <b-badge
              v-if="product.shopProduct.shopProductDiscountsActive.length"
              v-b-tooltip
              variant="light-success"
              :title="$t('ProductOnDiscount')"
            >
              <feather-icon
                icon="DollarSignIcon"
                size="20"
              />
            </b-badge>

            <div class="h4 mb-0 font-weight-bold">
              <router-link
                v-b-tooltip
                :to="{ name: 'productView', params: { productId: product.shopProduct.id } }"
                :title="product.shopProduct.translations[0].name"
              >
                {{ product.shopProduct.translations[0].name | textIsland(38) }}
              </router-link>

              <div class="d-flex align-items-center" style="gap: .5rem">
                <b-input-group size="sm" style="max-width: 150px">
                    <b-input-group-prepend>
                        <b-button @click="product.amount--" variant="outline-secondary" :disabled="product.amount <= 1">
                            <sw-icon icon="MinusIcon" />
                        </b-button>
                    </b-input-group-prepend>

                    <b-form-input
                            :value="product.amount"
                            @change="e => product.amount = Number(e)"
                            type="number"
                            style="width: 50px"
                            :state="product.amount < 0 || product.amount > product.shopProduct.shopProductStoragesSum ? false : null"
                            @blur="() => {
                              if (product.amount > product.shopProduct.shopProductStoragesSum) {
                                  product.amount = product.shopProduct.shopProductStoragesSum
                              } else if (product.amount <= 0) {
                                  product.amount = 1
                              }
                          }"
                    />

                    <b-input-group-append>
                        <b-button @click="product.amount++" variant="outline-secondary" :disabled="product.amount >= product.shopProduct.shopProductStoragesSum">
                            <sw-icon icon="PlusIcon" />
                        </b-button>
                    </b-input-group-append>
                </b-input-group>

                <template v-if="product.shopProduct && product.shopProduct.amountUnitType">
                  {{ $t(`unit.${product.shopProduct.amountUnitType}`).toLowerCase() }}
                </template>
              </div>
            </div>
          </div>

          <div>
            <b-button
              size="sm"
              variant="outline-danger"
              class="btn-wishlist btn-icon rounded-circle"
              @click="$emit('remove', product)"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>

            <b-button
              v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CART_PRODUCT_VAT, $viewRanks.SHOW_CART_PRODUCT_DISCOUNT])"
              size="sm"
              variant="outline-primary"
              class="btn-wishlist btn-icon rounded-circle mx-50"
              @click="showDiscount = !showDiscount"
            >
              <feather-icon icon="MoreVerticalIcon" />
            </b-button>

            <template v-if="checkRequiredModule('loyaltyModule')">
              <b-button
                v-if="product.shopProduct.shopProductLoyaltyPlans && product.shopProduct.shopProductLoyaltyPlans.length"
                :id="`product_trigger_${product.id}`"
                size="sm"
                variant="outline-warning"
                class="btn-wishlist btn-icon rounded-circle mr-50"
              >
                <feather-icon icon="StarIcon" />
              </b-button>

              <b-popover
                v-if="product.shopProduct.shopProductLoyaltyPlans && product.shopProduct.shopProductLoyaltyPlans.length"
                :target="`product_trigger_${product.id}`"
                :triggers="['click', 'hover']"
                custom-class="loyaltyPlanPricePopover"
              >
                <div
                  v-for="(plan, planIndex) in product.shopProduct.shopProductLoyaltyPlans"
                  :key="`product_${product.id}_plan_popover_${planIndex}`"
                >
                  <b-badge
                    variant="light-primary"
                    class="mb-25"
                    style="white-space: break-spaces;"
                  >
                    {{ plan.loyaltyPlan.name }}
                  </b-badge>
                  <div class="d-flex justify-content-between">
                    <div>{{ $t('PointAwardedForSellingProduct') }}</div>
                    <strong>{{ plan.pointsReward }}</strong>
                  </div>
                  <div class="d-flex justify-content-between">
                    <div>{{ $t('PointOfTheProductInPurchase') }}</div>
                    <strong>{{ plan.pointsPrice }}</strong>
                  </div>
                </div>
              </b-popover>
            </template>
          </div>
        </div>

        <div
          v-b-tooltip.html="{ customClass: 'product-tooltip' }"
          class="d-flex flex-column align-items-center justify-content-center productContainer w-100 px-1 py-75 bg-primary text-nowrap"
          style="border-radius: 0 0 5px 5px"
          :title="pricesTooltip"
        >
          <span class=" productLabel text-light">
            {{ $t('offer.product.priceGross') }}
          </span>

          <!--    Cart Items Product price    -->
          <h6 class="mb-0 text-light font-weight-bolder">
            {{ grossPrice(product) | priceGrossFormat }} {{ $store.getters['system/getSettings'] ? ($store.getters['system/getSettings'].defaultCurrency || 'PLN') : 'PLN' }}
          </h6>
        </div>
      </div>

      <div
        v-else
        class="position-relative d-flex align-content-center justify-content-between productBody flex-wrap flex-sm-nowrap ml-75"
        style="height: 71px"
      >
        <!--    Cart Items Product title    -->
        <div class="d-flex align-items-center productContainer w-100 text-nowrap">
          <b-badge
            v-if="product.shopProduct.shopProductDiscountsActive.length"
            v-b-tooltip
            variant="light-success"
            class="ml-25"
            :title="$t('ProductOnDiscount')"
          >
            <feather-icon
              icon="DollarSignIcon"
              size="20"
            />
          </b-badge>
          <div class="font-weight-bold ml-50">
            <span>
              <router-link
                v-b-tooltip
                :to="{ name: 'productView', params: { productId: product.shopProduct.id } }"
                :title="product.shopProduct.translations[0].name"
              >
                {{ product.shopProduct.translations[0].name | textIsland(38) }}
              </router-link>
            </span>

            <div class="d-flex align-items-center" style="gap: .5rem">
              <b-input-group size="sm" style="max-width: 150px">
                  <b-input-group-prepend>
                      <b-button @click="product.amount--" variant="outline-secondary" :disabled="product.amount <= 1">
                          <sw-icon icon="MinusIcon" />
                      </b-button>
                  </b-input-group-prepend>

                  <b-form-input
                          :value="product.amount"
                          @change="e => product.amount = Number(e)"
                          type="number"
                          style="width: 50px"
                          :state="product.amount < 0 || product.amount > product.shopProduct.shopProductStoragesSum ? false : null"
                          @blur="() => {
                              if (product.amount > product.shopProduct.shopProductStoragesSum) {
                                  product.amount = product.shopProduct.shopProductStoragesSum
                              } else if (product.amount <= 0) {
                                  product.amount = 1
                              }
                          }"
                  />

                  <b-input-group-append>
                      <b-button @click="product.amount++" variant="outline-secondary" :disabled="product.amount >= product.shopProduct.shopProductStoragesSum">
                          <sw-icon icon="PlusIcon" />
                      </b-button>
                  </b-input-group-append>
              </b-input-group>

              <template v-if="product.shopProduct && product.shopProduct.amountUnitType">
                {{ $t(`unit.${product.shopProduct.amountUnitType}`).toLowerCase() }}
              </template>
            </div>
          </div>
        </div>

        <!--    Cart Items Amount Selected    -->
        <div class="d-flex position-absolute position-top-0 position-right-0 h-100">
          <div class="d-flex flex-column align-items-end justify-content-center productContainer pl-50 fade-left bg-white">
            <!--    Cart Items Product Delete    -->
            <div class="d-flex">
              <b-button
                size="sm"
                variant="outline-danger"
                class="btn-wishlist btn-icon rounded-circle"
                @click="$emit('remove', product)"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>

              <b-button
                v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CART_PRODUCT_VAT, $viewRanks.SHOW_CART_PRODUCT_DISCOUNT])"
                size="sm"
                variant="outline-primary"
                class="btn-wishlist btn-icon rounded-circle mx-50"
                @click="showDiscount = !showDiscount"
              >
                <feather-icon icon="MoreVerticalIcon" />
              </b-button>

              <span v-if="checkRequiredModule('loyaltyModule')">
                <b-button
                  v-if="product.shopProduct.shopProductLoyaltyPlans && product.shopProduct.shopProductLoyaltyPlans.length"
                  :id="`product_trigger_${product.id}`"
                  size="sm"
                  variant="outline-warning"
                  class="btn-wishlist btn-icon rounded-circle mr-50"
                >
                  <feather-icon icon="StarIcon" />
                </b-button>

                <b-popover
                  v-if="product.shopProduct.shopProductLoyaltyPlans && product.shopProduct.shopProductLoyaltyPlans.length"
                  :target="`product_trigger_${product.id}`"
                  :triggers="['click', 'hover']"
                  custom-class="loyaltyPlanPricePopover"
                >
                  <div
                    v-for="(plan, planIndex) in product.shopProduct.shopProductLoyaltyPlans"
                    :key="`product_${product.id}_plan_popover_${planIndex}`"
                  >
                    <b-badge
                      variant="light-primary"
                      class="mb-25"
                      style="white-space: break-spaces;"
                    >
                      {{ plan.loyaltyPlan.name }}
                    </b-badge>
                    <div class="d-flex justify-content-between">
                      <div>{{ $t('PointAwardedForSellingProduct') }}</div>
                      <strong>{{ plan.pointsReward }}</strong>
                    </div>
                    <div class="d-flex justify-content-between">
                      <div>{{ $t('PointOfTheProductInPurchase') }}</div>
                      <strong>{{ plan.pointsPrice }}</strong>
                    </div>
                  </div>
                </b-popover>
              </span>
            </div>
          </div>

          <div
            v-b-tooltip.html="{ customClass: 'product-tooltip' }"
            class="d-flex flex-column align-items-center justify-content-center productContainer w-100 px-1 bg-primary text-nowrap"
            style="min-width: 200px; border-radius: 5px"
            :title="pricesTooltip"
          >
            <span class=" productLabel text-light">{{ $t('offer.product.priceGross') }}</span>
            <!--    Cart Items Product price    -->
            <h5 class="text-light mb-0 font-weight-bolder">
              {{ grossPrice(product) | priceGrossFormat }} {{ $store.getters['system/getSettings'] ? ($store.getters['system/getSettings'].defaultCurrency || 'PLN') : 'PLN' }}
            </h5>
          </div>
        </div>
      </div>

      <!-- discounts -->
      <transition name="fade">
        <div
          v-show="showDiscount"
          class="px-1 mb-1"
        >
          <div v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CART_PRODUCT_VAT])">
            <div
              class="divider mb-1 mt-2 font-weight-bold text-primary"
            >
              <div class="divider-text px-1 text-capitalize">
                {{ $t('VatSettings') }}
              </div>
            </div>

            <div class="d-flex flex-column flex-lg-row align-items-start align-items-lg-center justify-content-lg-between">
              <div
                style="min-width: 275px"
                class="mb-50 mb-lg-0"
              >
                {{ $t('SelectVatValueOfProduct') }}
              </div>

              <sw-select>
                <v-select
                  v-model="productVat"
                  v-b-tooltip.hover
                  :options="['0', '8', '23']"
                  :disabled="isOnOverwriteVatInCart"
                  :clearable="false"
                  :title="$t(isOnOverwriteVatInCart ? 'VatValueIsSetGlobally' : 'VatValueOfProduct')"
                />
              </sw-select>
            </div>
          </div>

          <div v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CART_PRODUCT_DISCOUNT])">
            <div
              class="divider mb-1 mt-md-50 font-weight-bold text-primary"
            >
              <div class="divider-text px-1 text-capitalize">
                {{ $t('Offer.Discount') }}
              </div>
            </div>

            <!-- Product Global Discount -->
            <div
              v-if="product.shopProduct.shopProductDiscounts && product.shopProduct.shopProductDiscounts.length"
              class="d-flex align-items-center flex-wrap my-50"
            >
              <div style="min-width: 275px">
                {{ $t('Offer.CatalogDiscount') }}
              </div>
              <div>
                <b-form-checkbox
                  :checked="product.shopProduct.shopProductDiscounts[0].type !== 'NETTO_PERCENT'"
                  class="ml-25"
                  name="check-button"
                  variant="primary"
                  disabled
                  switch
                  inline
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="DollarSignIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="PercentIcon" />
                  </span>
                </b-form-checkbox>
              </div>
              <div class="flex-grow-1">
                <cleave
                  v-model="product.shopProduct.shopProductDiscounts[0].value"
                  :options="cleaveOptions.number"
                  disabled
                  class="form-control form-control-sm"
                />
              </div>
            </div>
            <!-- Product Additional Discount -->
            <div
              v-if="checkRequiredPermissions([$roles.CONTACT_THREAD_CART_DISCOUNT_EDIT])"
            >
              <b-alert
                show
                variant="primary"
                class="p-25"
              >
                <div class="alert-body">
                  {{ $t('DiscountInfoBox') }}
                </div>
              </b-alert>
              <div class="d-flex align-items-center justify-content-between flex-wrap my-50">
                <div style="min-width: 275px">
                  {{ $t('Offer.AdditionalDiscount') }}
                </div>
                <div class="d-inline-flex flex-wrap align-items-center">
                  <b-form-checkbox
                    v-model="discount.discountType"
                    value="NETTO_VALUE"
                    unchecked-value="NETTO_PERCENT"
                    class="ml-25"
                    name="check-button"
                    switch
                    inline
                    @change="changeSwitch"
                  >
                    <span class="switch-icon-left">
                      <feather-icon icon="DollarSignIcon" />
                    </span>
                    <span class="switch-icon-right">
                      <feather-icon icon="PercentIcon" />
                    </span>
                  </b-form-checkbox>

                  <div class="flex-grow-1">
                    <cleave
                      v-model="discount.discount"
                      :options="cleaveOptions.number"
                      class="form-control form-control-sm my-50 my-md-25"
                      @blur="$emit('update-discount', discount)"
                      @input="changePrice"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </b-card>

    <b-modal
      :visible="!!editedProduct"
      hide-footer
      :title="$t('Edit')"
      @hide="editedProduct = null"
    >
      <!-- eslint-disable -->
      <section-checkout-edit-product :product.async="editedProduct" />
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */

import { BFormSpinbutton, BPopover, VBTooltip, BInputGroupAppend, BInputGroupPrepend } from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import vSelect from 'vue-select'
import { grossPrice, netPriceAfterCartDiscount } from '@/helpers/cartProductPrices'
import SectionCheckoutEditProduct from '@/views/offers/checkout/SectionCheckoutEditProduct.vue'
import useVerticalLayout from '@core/layouts/layout-vertical/useVerticalLayout'
import { onUnmounted } from '@vue/composition-api'

export default {
  components: {
    // BImg,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormSpinbutton,
    Cleave,
    vSelect,
    BPopover,
    SectionCheckoutEditProduct,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    priceViewType: {
      type: String,
      default: 'PRICE',
    },
    gross: {
      type: Number,
      default: 0,
    },
    product: {
      type: Object,
      required: true,
    },
    isOnOverwriteVatInCart: {
      type: Boolean,
      required: false,
      default: false,
    },
    overwriteVatInCart: {
      required: false,
      default: 0,
    },
    editedProduct: null,
  },
  emits: ['remove', 'changeQuantity', 'updateVat'],
  setup() {
    const { isMobile, resizeHandler } = useVerticalLayout()

    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    const cleaveOptions = {
      number: {
        delimiter: ' ',
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
      },
    }

    return {
      cleaveOptions,
      isMobile,
    }
  },
  data: () => ({
    showDiscount: false,
    discount: {
      discountType: '',
      discount: 0,
    },
    productVat: 0,
  }),
  watch: {
    overwriteVatInCart(newValue) {
      this.assignOverwriteVatInCart(newValue)
    },
    productVat(newValue, oldValue) {
      // eslint-disable-next-line
      if (newValue != oldValue) {
        this.productVat = newValue
        this.$emit('update-vat', newValue)
      }
    },
  },
  mounted() {
    this.discount = {
      discount: this.product.discount || 0,
      discountType: this.product.discountType,
    }
    this.assignOverwriteVatInCart()
  },
  methods: {
    netPriceAfterCartDiscount,
    grossPrice,
    assignOverwriteVatInCart(value = this.overwriteVatInCart) {
      if (value !== null) this.productVat = value
      else if (this.product.overwriteVat !== null) this.productVat = this.product.overwriteVat
      else this.productVat = this.product.shopProduct.vat ?? 23
    },
    changePrice(value) {
      this.$set(this.discount, 'discount', parseFloat(value))
      this.$emit('update-discount', this.discount)
    },
    changeSwitch() {
      this.$nextTick(() => {
        this.$emit('update-discount', this.discount)
      })
    },
    priceFormat(value) {
      return this.$options.filters.priceFormat(value)
    },
    priceGrossFormat(value) {
      return this.$options.filters.priceGrossFormat(value)
    },
    pricesTooltip() {
      let html = '<div>'
      html += `
          <div>
          <div class="text-nowrap my-25">
            ${this.$i18n.t('offer.GrossPrice')}
            <div class="font-weight-bolder"> ${this.priceGrossFormat(this.grossPrice(this.product))} ${this.$store.getters['system/getSettings'] ? (this.$store.getters['system/getSettings'].defaultCurrency || 'PLN') : 'PLN'}</div>
          </div>
          <div class="text-nowrap my-25">
            ${this.$i18n.t('offer.NettoPrice')}
            <div class="font-weight-bolder"> ${this.priceFormat(this.netPriceAfterCartDiscount(this.product))} ${this.$store.getters['system/getSettings'] ? (this.$store.getters['system/getSettings'].defaultCurrency || 'PLN') : 'PLN'}</div>
          </div>
        </div>
        </div>
      `
      return html
    },
    openEditProduct(product) {
      this.editedProduct = {
        overwriteCode: product.overwriteCode || this.getTranslationsField(product.shopProduct, 'name'),
        overwriteContactThreadCartDraftProductAttributes: [],
        overwriteOfferPrice: product.overwriteOfferPrice ?? product.shopProduct.offerPrice,
      }
    },
  },
}
</script>

<style lang="scss">
.productBody { min-height: 4rem; overflow: hidden !important }
.shopCartItem {
  overflow: hidden !important;
}
.productLabel { margin-bottom: .14rem }

.loyaltyPlanPricePopover {
  width: 250px !important;
  max-width: 250px !important;
  .popover-body {
    width: 250px !important;
    max-width: 250px !important;
  }
}
</style>
