<template>
  <div>
    <b-card
      v-if="!loading"
      class="pt-1"
    >
      <div>
        <!-- Row Loop -->
        <b-row
          v-for="(group, index) in groups"
          :id="`group_${group.id}_${index}`"
          :key="`group_${group.id}_${index}`"
          ref="row"
        >
          <!-- Item Name -->
          <b-col md="5">
            <b-form-group
              :label="$t('Name')"
              label-for="status-name"
            >
              <b-form-input
                id="status-name"
                v-model="group.name"
                size="sm"
                type="text"
              />
            </b-form-group>
          </b-col>

          <b-col
            md="1"
            class="d-flex justify-content-center align-items-center"
          >
            <b-form-input
              v-model="group.color"
              type="color"
            />
          </b-col>

          <!-- Remove Button -->
          <b-col
            lg="2"
            md="3"
            class="mt-1 mt-md-0"
          >
            <b-button
              variant="outline-danger"
              class="mt-0 mt-md-2"
              size="sm"
              @click="removeGroupItem(group.id, index, groupsToDelete.includes(group.id))"
            >
              <feather-icon
                icon="XIcon"
                class="mr-25"
              />
              <span>{{ groupsToDelete.includes(group.id) ? $t('Cancel') : $t('Delete') }}</span>
            </b-button>
          </b-col>
          <b-col cols="12">
            <hr>
          </b-col>
        </b-row>
      </div>
      <b-button
        variant="primary"
        class="mr-1"
        size="sm"
        @click="saveFieldsProcess"
      >
        <span>{{ $t('Save') }}</span>
      </b-button>
      <b-button
        variant="outline-primary"
        size="sm"
        @click="addEmptyGroup"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-25"
        />
        <span>{{ $t('Add') }}</span>
      </b-button>
    </b-card>
    <div
      v-else
      class="d-flex justify-content-center"
    >
      <b-spinner
        variant="primary"
        class="m-5"
      />
    </div>
  </div>
</template>

<script>
import {
  BFormGroup,
} from 'bootstrap-vue'
import { DELETE_GROUPS, GET_GROUPS, SAVE_GROUPS } from '@/@constants/mutations'

const emptyGroup = {
  id: null,
  name: '',
  color: '#7367f0',
}

export default {
  name: 'FunnelStatuses',
  components: {
    BFormGroup,
  },
  data: () => ({
    loading: false,
    groups: [],
    groupsToDelete: [],
  }),
  mounted() {
    this.loadGroups()
  },
  methods: {
    loadGroups() {
      this.loading = true
      this.$store.dispatch(`groups/${GET_GROUPS}`)
        .then(res => {
          this.groups = res.data.items
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
        .finally(() => {
          this.loading = false
        })
    },
    addEmptyGroup() {
      this.groups.push(JSON.parse(JSON.stringify(emptyGroup)))
    },
    removeGroupItem(id, index, isDeleted = false) {
      if (isDeleted) {
        this.groupsToDelete.splice(this.groupsToDelete.indexOf(id), 1)
      } else if (id) this.groupsToDelete.push(id)
      else this.groups.splice(index, 1)
    },
    saveFieldsProcess() {
      if (this.groupsToDelete.length) {
        this.$store.dispatch(`groups/${DELETE_GROUPS}`, this.groupsToDelete)
          .then(() => {
            this.updateFields()
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          })
      } else this.updateFields()
    },
    updateFields() {
      const preparedGroups = (this.groups.filter(group => !this.groupsToDelete.includes(group.id))).map(group => {
        const groupCopy = { ...group }
        if (!groupCopy.id) delete groupCopy.id
        return groupCopy
      })

      if (preparedGroups.length) {
        this.$store.dispatch(`groups/${SAVE_GROUPS}`, preparedGroups)
          .then(() => {
            this.showToast('success', this.$i18n.t('success.contactUpdated'))
            this.groupsToDelete = []
            this.loadGroups()
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          })
      } else {
        this.groupsToDelete = []
        this.loadGroups()
      }
    },
  },

}
</script>
