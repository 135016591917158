// eslint-disable-next-line import/no-cycle
import axiosIns from '@/libs/axios'
import {
  GET_ORGANIZATION,
  GET_ORGANIZATIONS, GET_ORGANIZATIONS_ALL,
} from '@/@constants/mutations'

import * as fields from '@/@constants/fields'

export default {
  namespaced: true,
  actions: {
    // eslint-disable-next-line no-unused-vars
    [GET_ORGANIZATIONS]: ({ commit }, search) => new Promise((resolve, reject) => {
      const params = {
        search: `%${search}%`,
        search_fields: 'name,vatId',
      }

      params.fields_load = fields.BUILDING_ORGANIZATIONS

      axiosIns.get('1/organizations', { params })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [GET_ORGANIZATION]: ({ commit }, id) => new Promise((resolve, reject) => {
      const params = {
        fields_load: fields.BUILDING_ORGANIZATIONS,
      }

      axiosIns.get(`1/organizations/${id}`, { params })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    [GET_ORGANIZATIONS_ALL]: (_, payload) => new Promise((resolve, reject) => {
      const {
        filters,
        pagination,
        search,
        sorter,
      } = payload

      const params = {
        fields_load: fields.BUILDING_ORGANIZATIONS,
        orderBy: 'id.desc',
      }

      if (pagination) {
        params.page = pagination.currentPage
        params.limit = pagination.perPage
      }

      if (sorter) {
        const sortDir = sorter?.sortDesc ? 'desc' : 'asc'
        const sortBy = sorter?.sortBy || 'id'
        params.orderBy = `${sortBy}.${sortDir}`
      }

      if (search) {
        const searchQuery = search.replaceAll(' ', '%')
        params.search = `%${searchQuery}%`
        params.search_fields = 'name,addressCountry,addressCity,addressPostalCode,users-firstName,users-lastName,vatId,organizationClassificationCodes-code'
      }

      if (filters) {
        if (filters.assignedContacts && filters.assignedContacts.length) {
          params['eqArr_contacts-id'] = JSON.stringify(
            filters.assignedContacts.map(
              contact => (typeof contact === 'object' ? contact.id : contact),
            ),
          )
        }

        if (filters.assignedUsers && filters.assignedUsers.length) {
          params['eqArr_users-id'] = JSON.stringify(
            filters.assignedUsers.map(
              user => (typeof user === 'object' ? user.id : user),
            ),
          )
        }
      }

      axiosIns.get('1/organizations', { params })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
  },
}

// Test
