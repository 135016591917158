<template>
  <div>
    <b-button
      v-if="items.length"
      :id="uuid"
      v-b-tooltip.hover.v-primary
      variant="none"
      class="p-0"
      :title="title"
    >
      <b-avatar
        v-if="items.length === 1 || maxLength === 1"
        :src="items[0].avatar"
        :text="getAvatarText(items[0])"
        variant="primary"
        size="1.34rem"
      />

      <b-avatar-group
        v-else
        :show-tooltip="false"
        size="1.24rem"
      >
        <b-avatar
          v-for="item in items.slice(0, maxLength)"
          :key="item.id"
          :src="item.avatar"
          :text="getAvatarText(item)"
          variant="primary"
        />

        <b-avatar
          v-if="showMore"
          variant="primary"
        >
          <div class="d-flex align-items-center justify-content-center">
            <sw-icon
              icon="PlusIcon"
              size="10rem"
              style="margin-top: -.2rem"
            />

            <small class="font-weight-light">3</small>
          </div>
        </b-avatar>
      </b-avatar-group>
    </b-button>

    <b-button
      v-else
      :id="uuid"
      v-b-tooltip.hover.v-primary
      :variant="allowAdd ? 'outline-primary' : 'flat-primary'"
      class="btn-icon p-25 rounded-circle"
      size="sm"
      :title="title"
    >
      <sw-icon icon="UsersIcon" />
    </b-button>

    <b-popover
      :target="uuid"
      triggers="focus"
      placement="bottom"
      :disabled="!showMore && !allowRemove && !allowAdd"
      :title="title"
    >
      <ul
        class="p-0 m-0 d-flex flex-wrap"
        style="gap: .34rem; list-style: none;"
      >
        <li
          v-for="item in items"
          :key="item.id"
          :class="{ 'cursor-pointer': allowRemove }"
          @click="allowRemove && $emit('remove')"
        >
          <b-avatar
            :key="item.id"
            :src="item.avatar"
            :text="getAvatarText(item)"
            variant="light-primary"
            :class="{ 'removable-avatar': allowRemove }"
          />
        </li>

        <li
          v-if="allowAdd && (limitAdd ? items.length < limitAdd : true)"
          @click="allowAdd && $emit('add')"
        >
          <b-avatar
            variant="success"
            button
          >
            <sw-icon icon="PlusIcon" />
          </b-avatar>
        </li>
      </ul>
    </b-popover>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import { BPopover, VBTooltip } from 'bootstrap-vue'

export default {
  components: {
    BPopover,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    items: {
      type: Array,
      required: false,
      default: () => [],
    },
    title: {
      type: [String, Object],
      required: true,
    },
    allowRemove: {
      type: Boolean,
      required: false,
      default: false,
    },
    allowAdd: {
      type: Boolean,
      required: false,
      default: false,
    },
    maxLength: {
      type: [String, Number],
      required: false,
      default: 2,
    },
    limitAdd: {
      type: [String, Number],
      required: false,
      default: null,
    },
  },
  emits: ['add', 'remove'],
  data: () => ({
    uuid: uuidv4(),
  }),
  computed: {
    showMore() {
      return Boolean(this.items.length > this.maxLength)
    },
  },
  methods: {
    getAvatarText(item) {
      return (item?.firstName ? item.firstName[0].toUpperCase() : '') + (item?.lastName ? item.lastName[0].toUpperCase() : '')
    },
  },
}
</script>
