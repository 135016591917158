<!--eslint-disable-->

<template>
  <b-table
    :items="attributes"
    :fields="constAttributeFields"
    striped
    responsive
    hover
    class="constTable"
    :tbody-tr-class="hideNotBase"
  >
    <!--    Table Head    -->
    <template #head()="item">
      <span class="text-nowrap">
        {{ $tc(item.field.label) | truncate(34, '...') }}
      </span>
    </template>

    <!-- Table position -->
    <template #cell(position)="{ value, index }">
      <div class="d-flex align-items-center">
        <b-button-group class="btn-group-vertical mr-25">
          <b-button
            variant="flat-primary"
            size="sm"
            class="btn-icon p-md-0"
            :disabled="isEdited || value === 0"
            @click="moveAttr(index, true)"
          >
            <feather-icon icon="ArrowUpIcon" />
          </b-button>

          <b-button
            variant="flat-primary"
            size="sm"
            class="btn-icon p-0"
            :disabled="isEdited || value === attributes.length -1"
            @click="moveAttr(index, false)"
          >
            <feather-icon icon="ArrowDownIcon" />
          </b-button>
        </b-button-group>

        {{ value }}
      </div>
    </template>

    <!-- Table Name -->
    <template #cell(name)="{ item }">
      {{ item.shopAttribute.translations[0].name }}
    </template>

    <!-- Table Value -->
    <template #cell(value)="{ item, index }">
      <div v-if="['TEXT_ARRAY', 'TEXT_MULTIPLE_ARRAY'].includes(item.shopAttribute.type)">
        <div v-if="Array.isArray(item.valueShopAttributeOptions)">
          <b-badge
            v-for="(value, itemIndex) in item.valueShopAttributeOptions"
            :key="`${index}_attributeItem_${itemIndex}_${value}`"
            variant="primary"
            class="ml-25"
          >
            {{ value.translations[0].name }}
          </b-badge>
        </div>
        <div v-else>
          <b-badge
            variant="primary"
            class="ml-25"
          >
            {{ item.valueShopAttributeOptions.translations[0].name }}
          </b-badge>
        </div>
      </div>
      <div v-else>
        {{ item.value }}
      </div>
    </template>

    <!--    Table Type    -->
    <template #cell(type)="{ item }">
      {{ $t(`offer.attribute.types.${item.shopAttribute.type}`) }}
    </template>

    <!--    Table Website    -->
    <template #cell(visibleOfferWWW)="{ value }">
      <span class="d-flex">
        <b-badge
          v-if="value"
          v-b-tooltip.hover.v-success
          variant="light-success"
          pill
          :title="$t('Active')"
        >
          <feather-icon icon="CheckIcon" />
        </b-badge>
        <b-badge
          v-else
          v-b-tooltip.hover.v-danger
          variant="light-danger"
          pill
          :title="$t('InActive')"
        >
          <feather-icon icon="MinusIcon" />
        </b-badge>
      </span>
    </template>

    <!--    Table Status    -->
    <template #cell(active)="{ value }">
      <span class="d-flex">
        <b-badge
          v-if="value"
          v-b-tooltip.hover.v-success
          variant="light-success"
          pill
          :title="$t('Active')"
        >
          <feather-icon icon="CheckIcon" />
        </b-badge>
        <b-badge
          v-else
          v-b-tooltip.hover.v-danger
          variant="light-danger"
          pill
          :title="$t('InActive')"
        >
          <feather-icon icon="MinusIcon" />
        </b-badge>
      </span>
    </template>

    <!--    Table Action    -->
    <template #cell(action)="{ item, index }">
      <b-dropdown
        variant="link"
        class="dropdown-icon-wrapper text-decoration-none"
        no-caret
        :disabled="isEdited"
      >
        <template
          #button-content
          class="sr-only"
        >
          <feather-icon
            icon="MoreVerticalIcon"
            size="16"
            class="align-middle"
          />
        </template>

        <!--    Action: Edit    -->
        <b-dropdown-item @click="editAttribute(item, index)">
          {{ $t('Edit') }}
        </b-dropdown-item>

        <!--    Action: Delete    -->
        <b-dropdown-item @click="remove(index)">
          {{ $t('Delete') }}
        </b-dropdown-item>
      </b-dropdown>
    </template>

    <!--    Other    -->
      <!--    Other    -->
      <template #cell()="row">
          <table-default-cell
                  :field="row.field"
                  :value="row.value"
          />
      </template>

      <!--      Empty      -->
      <template #empty>
          <empty-content />
      </template>

      <!--      Table Busy      -->
      <template #table-busy>
          <div class="text-center text-danger my-2">
              <b-spinner
                      class="align-middle"
                      variant="primary"
              />
          </div>
      </template>
  </b-table>
</template>

<script>
/* eslint-disable */

import { BButtonGroup, BTable, VBTooltip } from 'bootstrap-vue'
import Vue from 'vue'

export default {
  components: {
    BTable,
    BButtonGroup,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    showBase: {
      default: false,
      type: Boolean,
    },
    isEdited: {
      default: false,
      type: Boolean,
    },
    attributes: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
      required: true,
    },
  },
  emits: ['editAttribute', 'cloneAttribute', 'removeAttribute'],
  setup() {
    const constAttributes = [{
      name: 'Opis',
      value: '30,4m2',
      type: 'text',
      visibleOfferWWW: true,
      active: 'active',
      valueShopAttributeOptions: [''],
    }]

    return { constAttributes }
  },
  data: () => ({
    constAttributeFields: [
      { key: 'position', label: '#' },
      { key: 'name', label: 'Name' },
      { key: 'value', label: 'Value' },
      { key: 'type', label: 'FieldType' },
      { key: 'visibleOfferWWW', label: 'OfferWebsite' },
      { key: 'action', label: 'Action' },
    ],
  }),
  methods: {
    // eslint-disable-next-line no-unused-vars
    hideNotBase(item) {
      return !item.shopAttribute.base && this.showBase ? 'd-none' : ''
    },
    editAttribute(constAttribute, index = -1) {
      this.$emit('editAttribute', constAttribute, index)
    },

    moveAttr(index, direction) {
      if (direction && this.attributes[index].position > 0) {
        this.attributes[index].position -= 1
        this.attributes[index - 1].position += 1

        const oldAttr = this.attributes[index]

        Vue.set(this.attributes, index, this.attributes[index - 1])
        Vue.set(this.attributes, index - 1, oldAttr)
      } else if (this.attributes[index].position < this.attributes.length - 1) {
        this.attributes[index].position += 1
        this.attributes[index + 1].position -= 1

        const oldAttr = this.attributes[index]

        Vue.set(this.attributes, index, this.attributes[index + 1])
        Vue.set(this.attributes, index + 1, oldAttr)
      }
    },

    /*    Duplicate duplicates const attributes    */
    duplicate(constAttribute) {
      const copy = JSON.parse(JSON.stringify(constAttribute))
      if (copy.id) delete copy.id
      this.$emit('cloneAttribute', copy)
    },

    /*    Remove removes const attributes    */
    remove(id) {
      this.$emit('remove-attribute', id)
    },
  },
}
</script>

<style lang="scss">
.constTable table { min-height: 10rem }
</style>
