<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <b-row>
        <b-col
          md="5"
          lg="3"
        >
          <client-info />
        </b-col>

        <b-col
          md="7"
          lg="9"
        >
          <calendar-info />
        </b-col>
      </b-row>

      <p class="w-100 mt-50 d-flex flex-wrap align-items-center justify-content-center">
        <span class="mt-25">Powered by</span>

        <a
          :href="`http://saleswizard.${ swHrefList.pl }`"
          class="ml-50"
        >
          <b-img
            :src="swLogo"
            style="max-width: 8rem;"
          />
        </a>
      </p>
    </div>
  </div>
</template>

<script>
import swLogo from '@/assets/images/core/logo_universal.png'
import ClientInfo from './components/ClientInfo.vue'
import CalendarInfo from './components/CalendarInfo.vue'

export default {
  components: {
    ClientInfo,
    CalendarInfo,
  },
  data: () => ({
    swHrefList: {
      pl: 'pl',
      en: 'pl',
    },
    swLogo,
  }),
}
</script>
