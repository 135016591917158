<template>
  <component
    :is="card ? 'b-card' : 'div'"
    no-body
    class="rounded-lg overflow-hidden"
  >
    <b-tabs
      class="tabs-scroll"
      style="row-gap: .5rem"
      nav-class="btn-group btn-group-row-gap-0.34"
      :nav-wrapper-class="{
        'bg-white before-bg-tonal-primary border-bottom-light-primary position-relative': card,
        'bg-white shadow rounded-lg': !card,
        'sticky-to-top': sticky
      }"
      content-class="px-1 pt-1 mb-0 b-card-body"
      pills
      :lazy="lazy"
      no-nav-style
      :align="align"
      active-nav-item-class="opacity-disabled"
      @input="$emit('input', $event)"
    >
      <template v-if="tabs && tabs.length && !$slots.default">
        <b-tab
          v-for="(tab, i) in tabs"
          :key="i"
          :title="$t(tab.title)"
          :active="(activeTabKey && activeTabKey === tab.key) || tab.active"
          :disabled="tab.disabled"
          title-item-class="btn btn-sm p-0"
          title-link-class="px-75 py-75 h-100 d-flex align-items-center justify-content-center"
        >
          <template
            v-if="tab.icon"
            #title
          >
            <div
              class="d-flex flex-row align-items-center"
              style="column-gap: .5rem"
            >
              <feather-icon :icon="tab.icon" />

              <span
                v-if="
                  !isMobile
                    && typeof tab.showTitle === 'undefined' ? true : tab.showTitle
                "
              >
                {{ $t(tab.title) }}
              </span>
            </div>
          </template>

          <component
            :is="tab.component"
            v-if="tab.component"
            :update="update"
          />

          <div
            v-else
            class="p-2 text-center text-primary"
          >
            <feather-icon icon="XCircleIcon" />

            {{ $t('NoData') }}
          </div>
        </b-tab>
      </template>

      <slot />

      <template
        v-if="$slots['tabs-end']"
        #tabs-end
      >
        <slot name="tabs-end" />
      </template>
    </b-tabs>
  </component>
</template>

<script>
import { BTab, BTabs } from 'bootstrap-vue'
import useVerticalLayout from '@core/layouts/layout-vertical/useVerticalLayout'
import { onUnmounted } from '@vue/composition-api'

export default {
  components: { BTab, BTabs },

  props: {
    tabs: {
      type: Array,
      required: false,
      default: () => [],
    },
    activeTabKey: {
      type: String,
      required: false,
      default: '',
    },
    card: {
      type: Boolean,
      required: false,
      default: true,
    },
    lazy: {
      type: Boolean,
      required: false,
      default: false,
    },
    align: {
      type: String,
      required: false,
      default: 'center',
    },
    sticky: {
      type: Boolean,
      required: false,
      default: false,
    },
    update: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ['input'],

  setup() {
    const { isMobile, resizeHandler } = useVerticalLayout()

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    return {
      perfectScrollbarSettings,
      isMobile,
    }
  },
}
</script>
