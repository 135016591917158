<template>
  <sw-filters
    :shorts="[
      {
        title: $t('ShowThreadsAssignedToUsers'),
        type: 'avatar',
        value: () => filters.assignedUsers,
        clear: () => {
          filters.assignedUsers = []
          changeAssignedUsers()
        }
      },
      {
        title: $t('ChooseTags'),
        value: () => filters.tags ? filters.tags.map(({ name }) => name).join(', ') : '',
        clear: () => { filters.tags = [] }
      },
      {
        title: $t('funnel.SelectStatus'),
        variant: 'primary',
        value: () => filters.status ? statuses.find(({ id }) => id === filters.status).name : null,
        clear: () => { filters.status = '' }
      },
      {
        variant: 'primary',
        value: () => filters.contactStructure ? $t('funnel.ShowStructureContacts') : null,
        clear: () => { filters.contactStructure = false }
      },
      {
        variant: 'light-primary',
        value: () => filters.contactCloseStatus ? $t(closeStatuses[filters.contactCloseStatus]) : $t('ShowProcesses'),
        clear: () => { filters.contactCloseStatus = '' }
      },
      {
        variant: 'primary',
        value: () => filters.delayedContactStatuses ? $t('funnel.ShowOverdueStatuses') : null,
        clear: () => { filters.delayedContactStatuses = false }
      },
      {
        variant: 'primary',
        value: () => filters.delayedContactThreadStatuses ? $t('funnel.ShowThreadOverdueStatuses') : null,
        clear: () => { filters.delayedContactThreadStatuses = false }
      },
      {
        variant: 'primary',
        value: () => filters.withOrganization ? $t('ShowThreadsAssignedToOrganization') : null,
        clear: () => { filters.withOrganization = false }
      },
      {
        variant: 'primary',
        value: () => filters.contactsOverdueTasks ? $t('funnel.ShowOverdueTasks') : null,
        clear: () => { filters.contactsOverdueTasks = false }
      },
      {
        variant: 'primary',
        value: () => filters.agreeMarketing ? $t('contact.agreeMarketing') : null,
        clear: () => { filters.agreeMarketing = false }
      },
    ]"
  >
    <b-row class="pb-1">
      <b-col cols="12">
        <b-row>
          <b-col sm="12"
                 md="4"
          >
            <!--  Table Filters - Users -->
            <sw-select :name="$t('ShowThreadsAssignedToUsers')">
              <!--              <assigned-users-->
              <!--                :value="filters.assignedUsers"-->
              <!--                :is-multiple="true"-->
              <!--                class="mb-25"-->
              <!--                fields="USERS_SEARCH"-->
              <!--                @input="changeAssignedUsers"-->
              <!--              />-->
              <v-select
                :value="filters.assignedUsers"
                :options="userList"
                label="firstName"
                :filterable="false"
                multiple
                :placeholder="$t('ShareTo')"
                @search="loadUsers"
                @option:selected="changeAssignedUsers"
                @option:deselected="changeAssignedUsers"
              >
                <template #no-options="{ search }">
                  <span v-if="search.length">
                    {{ $t('NoData') }}
                  </span>
                  <span v-else>
                    {{ $t('TypeToSearch') }}
                  </span>
                </template>

                <template #option="{ firstName, lastName, avatar }">
                  <div class="d-flex align-items-center">
                    <avatar
                      :user="{ firstName, lastName, avatar }"
                      class="mr-25"
                    />
                  </div>
                </template>

                <template #selected-option="{ firstName, lastName, avatar }">
                  <div class="d-flex">
                    <avatar
                      :user="{ firstName, lastName, avatar }"
                      class="mr-25"
                      text-variant="text-white"
                    />
                  </div>
                </template>
              </v-select>
            </sw-select>
          </b-col>
          <b-col
            v-if="!isFunnel"
            sm="12"
            md="4"
            class="w-50"
          >
            <sw-select :name="$t('funnel.SelectStatus')">
              <v-select
                v-model="filters.status"
                :options="statuses"
                label="name"
                :reduce="status => status.id.toString()"
              >
                <template #no-options>
                  {{ $t('NoOptions') }}
                </template>
              </v-select>
            </sw-select>
          </b-col>
          <b-col
            v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_TAGS])"
            sm="12"
            md="4"
          >
            <sw-select :name="$t('ChooseTags')"
                       class="mb-25"
            >
              <v-select
                v-model="filters.tags"
                multiple
                :options="tags"
                label="name"
              >
                <template #no-options>
                  {{ $t('NoOptions') }}
                </template>
                <template #option="{ name, color }">
                  <div
                    class="color-dot"
                    style="border: 1px solid white"
                    :style="{ backgroundColor: color }"
                  />
                  {{ name }}
                </template>
                <template #selected-option="{ name, color }">
                  <div
                    class="color-dot"
                    style="border: 1px solid white"
                    :style="{ backgroundColor: color }"
                  />
                  {{ name }}
                </template>
              </v-select>
            </sw-select>

            <b-form-checkbox
              v-model="filters.tagsSum"
              variant="primary"
              switch
            >
              {{ filters.tagsSum ? $t('InterceptSum') : $t('InterceptSeparately') }}
              <feather-icon
                v-b-tooltip
                class="text-primary"
                icon="AlertCircleIcon"
                :title="!filters.tagsSum ? $t('ContactMustIncludeMinimumOne') : $t('ContactMustIncludeEach')"
              />
            </b-form-checkbox>
          </b-col>
          <b-col
            sm="12"
            md="6"
            lg="4"
          >
            <sw-select :name="$t('CreatedTime')">
              <sw-select-fpr
                :is-empty="filters.createdAt"
                @clear="filters.createdAt = ''"
              >
                <flat-pickr
                  v-model="filters.createdAt"
                  class="form-control"
                  :placeholder="$t('CreatedTime')"
                  :config="{ mode: 'range', locale: getCalendarLocale($i18n.locale) }"
                />
              </sw-select-fpr>
            </sw-select>
          </b-col>
          <!--          <b-col-->
          <!--            sm="12"-->
          <!--            md="6"-->
          <!--          >-->
          <!--            <sw-select :name="$t('SelectCloseStatus')">-->
          <!--              <v-select-->
          <!--                v-model="filters.closeStatuses"-->
          <!--                multiple-->
          <!--                :reduce="t => t.value"-->
          <!--                :options="[-->
          <!--                  { label: $t('funnel.status.Success'), value: 'CLOSED_POSITIVE' },-->
          <!--                  { label: $t('funnel.status.Failure'), value: 'CLOSED_NEGATIVE' },-->
          <!--                ]"-->
          <!--              >-->
          <!--                <template #no-options>-->
          <!--                  {{ $t('NoOptions') }}-->
          <!--                </template>-->
          <!--              </v-select>-->
          <!--            </sw-select>-->
          <!--          </b-col>-->
          <!--  Table Filters - Users -->
        </b-row>
      </b-col>
      <b-col
        cols="12"
        md="6"
        sm="12"
      >
        <!--  Table Filters - Users - client -->
        <div class="d-flex flex-wrap">
          <div class="mr-1">
            <b-form-checkbox
              v-model="filters.contactStructure"
              class="my-1"
              variant="primary"
            >
              {{ $t('funnel.ShowStructureContacts') }}
            </b-form-checkbox>
            <!--  Table Filters - Users - client -->
            <b-form-checkbox
              v-model="filters.delayedContactThreadStatuses"
              class="my-1"
              variant="primary"
            >{{ $t('funnel.ShowThreadOverdueStatuses') }}
            </b-form-checkbox>
            <b-form-checkbox
              v-model="filters.delayedContactStatuses"
              class="my-1"
              variant="primary"
            >{{ $t('funnel.ShowOverdueStatuses') }}
            </b-form-checkbox>

          </div>
          <div>
            <b-form-checkbox
              v-model="filters.withOrganization"
              class="my-1"
              variant="primary"
            >{{ $t('ShowThreadsAssignedToOrganization') }}
            </b-form-checkbox>
            <b-form-checkbox
              v-model="filters.contactsOverdueTasks"
              class="my-1"
              variant="primary"
            >{{ $t('funnel.ShowOverdueTasks') }}
            </b-form-checkbox>
            <b-form-checkbox
              v-model="filters.agreeMarketing"
              class="my-1"
              variant="primary"
            >{{ $t('ShowContactsWithAcceptAgreement') }}
            </b-form-checkbox>
          </div>
        </div>
        <!--        <b-form-checkbox-->
        <!--          class="my-1"-->
        <!--          variant="primary"-->
        <!--        >{{ $t('funnel.ShowAcceptedOffers') }}-->
        <!--        </b-form-checkbox>-->
      </b-col>

      <b-col
        cols="12"
        md="6"
        sm="12"
      >
        <b-form-radio-group v-model="filters.contactCloseStatus">
          <b-form-radio
            class="my-50"
            value=""
            variant="primary"
          >
            {{ $t('ShowProcesses') }}
          </b-form-radio>
          <b-form-radio
            class="my-50"
            value="OPEN"
            variant="primary"
          >{{ $t('ShowOpenProcesses') }}
          </b-form-radio>
          <b-form-radio
            class="my-50"
            value="PAUSED"
            variant="primary"
          >{{ $t('ShowPausedProcesses') }}
          </b-form-radio>
          <b-form-radio
            class="my-50"
            value="CLOSED_POSITIVE"
            variant="primary"
          >{{ $t('ShowClosedPositiveProcesses') }}
          </b-form-radio>
          <b-form-radio
            class="my-50"
            value="CLOSED_NEGATIVE"
            variant="primary"
          >{{ $t('ShowClosedNegativeProcesses') }}
          </b-form-radio>
        </b-form-radio-group>
      </b-col>
    </b-row>
  </sw-filters>
</template>

<script>
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
// import AssignedUsers from '@/views/components/AssignedUsers.vue'
import { saveToLocalStorage } from '@/helpers/localStorage'
import { GET_THREAD_TAGS } from '@/@constants/mutations'
import { VBTooltip, BFormRadioGroup } from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { USERS_SEARCH } from '@/@constants/fields'

export default {
  name: 'Filters',
  components: {
    vSelect,
    // AssignedUsers,
    BFormRadioGroup,
    flatPickr,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    isFunnel: {
      default: false,
      type: Boolean,
    },
  },
  data: () => ({
    statuses: [],
    tags: [],
    userList: [],
    closeStatuses: {
      OPEN: 'ShowOpenProcesses',
      PAUSED: 'ShowPausedProcesses',
      CLOSED_POSITIVE: 'ShowClosedPositiveProcesses',
      CLOSED_NEGATIVE: 'ShowClosedNegativeProcesses',
    },
  }),
  watch: {
    $route() {
      this.getStatuses()
      this.onGetContactTags()
    },
  },
  mounted() {
    this.getStatuses()
    this.onGetContactTags()

    // if (this.currentUser.configTables && this.currentUser.configTables['sales-funnel-filters']) {
    //   const filters = JSON.parse(this.currentUser.configTables['sales-funnel-filters'])
    //   Object.assign(this.filters, filters)
    //
    //   console.log('filters ', filters)
    // }
  },
  computed: {
    ...mapGetters({
      filters: 'funnelMain/getFilters',
      currentUser: 'auth/getCurrentUser',
    }),
  },
  methods: {
    onGetContactTags() {
      this.$store.dispatch(`typeSettings/${GET_THREAD_TAGS}`)
        .then(res => {
          this.tags = res.data.items
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err.response.data.message}`))
        })
    },
    getStatuses() {
      const { id } = this.$route.params
      // eslint-disable-next-line no-unused-vars
      this.$store.dispatch('funnelMain/GET_FUNNEL_POINTS', { funnelId: id })
        .then(funnelPoints => {
          this.statuses = funnelPoints
        })
    },
    async loadUsers(search, isLoading) {
      const filters = {}

      this.userList = await this.selectSearch(search, isLoading, USERS_SEARCH, 'firstName,lastName', '1/users', 25, filters)
    },
    changeAssignedUsers(payload) {
      if (Array.isArray(payload)) {
        this.$store.commit('funnelMain/SET_FILTER', { field: 'assignedUsers', value: payload })
        saveToLocalStorage('funnel_assigned_filter', payload)
      } else {
        this.$store.commit('funnelMain/SET_FILTER', { field: 'assignedUsers', value: this.filters.assignedUsers.filter(a => a.id !== payload.id) })
        saveToLocalStorage('funnel_assigned_filter', this.filters.assignedUsers.filter(a => a.id !== payload.id))
      }
    },
  },
}
</script>
