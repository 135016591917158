export default [
  {
    path: '/orders',
    component: () => import('@/pages/orders/List.vue'),
    name: 'orders',
    meta: {
      autorize: true,
      pageTitle: 'Orders',
    },
  },
  {
    path: '/orders/new',
    component: () => import('@/pages/orders/New.vue'),
    name: 'ordersNew',
    meta: {
      autorize: true,
      pageTitle: 'OrdersNew',
    },
  },
]
