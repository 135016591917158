<template>
  <app-collapse
    class="rounded overflow-hidden tree-container"
    :class="{ 'border': deepLevel === 0 }"
  >
    <app-collapse-item
      :off="!rootItem[childKey].length"
      title="test"
      :is-visible="!($route.name === 'user' && deepLevel === 1)"
      class="pl-2 pr-0 tree-item"
      arrow-left
    >
      <template #title>
        <slot
          name="root-title"
          :deep-level="deepLevel"
          :item="rootItem"
        />
      </template>

      <!-- Substructure Items -->
      <span v-if="Array.isArray(rootItem[childKey]) && rootItem[childKey].length">
        <sw-multilevel-structure
          v-for="(xxx, index) in rootItem[childKey]"
          :key="Math.random() + index + ' .' + deepLevel"
          :child-key="childKey"
          :off="!rootItem[childKey].length"
          :root-item="xxx"
          :deep-level="deepLevel + 1"
          :max-deep-level="maxDeepLevel"
          class="ml-2"
        >
          <template #root-title="row">
            <slot
              name="root-title"
              :deep-level="row.deepLevel"
              :item="row.item"
            />
          </template>
        </sw-multilevel-structure>
      </span>
    </app-collapse-item>
  </app-collapse>
</template>

<script>
/* eslint-disable */

export default {
  name: 'Structure',
  props: {
    off: {
      required: false,
      default: false,
      type: Boolean,
    },
    rootItem: {
      required: true,
      default: null,
      type: [Array, Object],
    },
    childKey: {
      required: true,
      default: 'user',
      type: String,
    },
    maxDeepLevel: {
      default: null,
      required: false,
      type: Number,
    },
    deepLevel: {
      required: false,
      default: 0,
      type: Number,
    },
  },
}
</script>

<style scoped>

</style>
