<template>
  <div class="d-flex">
    <b-button
      v-if="user.contactThreadFunnelTypePointDelayed"
      v-b-tooltip.hover.html="tooltipContent($t('funnel.ThreadAboveNorm'), user.contactThreadFunnelTypePointDelayedAt)"
      variant="flat-warning"
      class="btn-icon rounded-circle mr-25"
      style="padding: 5px"
    >
      <feather-icon
        icon="AlertCircleIcon"
        size="17"
      />
    </b-button>

    <b-button
      v-if="user.contact.statusDelayed"
      v-b-tooltip.hover.html="tooltipContent($t('funnel.ContactAboveNorm'), user.contact.statusUpdatedAt)"
      variant="flat-info"
      class="btn-icon rounded-circle mr-25"
      style="padding: 5px"
    >
      <feather-icon
        icon="AlertTriangleIcon"
        size="17"
      />
    </b-button>

    <b-button
      :id="`user_arrears_${user.id}`"
      :ref="`user_arrears_${user.id}`"
      v-b-popover:user_arrears_popover
      :variant="user.contactThreadTasksOpened && user.contactThreadTasksOpened.length && outdatedTasks ? 'danger' : 'flat-danger'"
      class="btn-icon rounded-circle"
      style="padding: 5px"
      @mouseover="changeTarget(user)"
    >
      <feather-icon
        icon="ListIcon"
        size="17"
      />
    </b-button>
  </div>
</template>

<script>
import { VBPopover, VBTooltip } from 'bootstrap-vue'

export default {
  name: 'UserArrears',
  directives: {
    'b-popover': VBPopover,
    'b-tooltip': VBTooltip,
  },
  props: ['user'],
  computed: {
    outdatedTasks() {
      return this.user.contactThreadTasksOpened.filter(task => task.outdated).length
    },
  },
  methods: {
    changeTarget(user) {
      this.$emit('changeTarget', user)
    },
    tooltipContent(title, date) {
      return `
        <span>${title}</span>
        <small>${this.$i18n.t('From').toLowerCase()}: ${date.date.split('.')[0]}</small>
      `
    },
  },
}
</script>

<style scoped>

</style>
