var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h4',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$t('UserCommissionForOwnSales'))+" ")]),_c('b-row',{staticStyle:{"row-gap":"1rem"}},[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('SelectCommissionType'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('sw-select',{staticClass:"mb-0",attrs:{"state":errors.length > 0 ? false:null,"name":_vm.$t('SelectCommissionType'),"tip":_vm.$t('YouWillSpecifyValueOfCommissionForSaleInEachProduct')}},[_c('v-select',{attrs:{"options":_vm.commissionTypes,"label":"label","reduce":function (i) { return i.value; }},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var label = ref.label;
return [_c('span',[_vm._v(_vm._s(_vm.$t(("" + label))))])]}},{key:"option",fn:function(ref){
var label = ref.label;
return [_c('span',[_vm._v(_vm._s(_vm.$t(("" + label))))])]}},{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('NoOptions'))+" ")]},proxy:true}],null,true),model:{value:(_vm.target.commissionType),callback:function ($$v) {_vm.$set(_vm.target, "commissionType", $$v)},expression:"target.commissionType"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('ChooseMethodOfDeterminingAmountOfCommission'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('sw-select',{staticClass:"mb-0",attrs:{"state":errors.length > 0 ? false:null,"name":_vm.$t('ChooseMethodOfDeterminingAmountOfCommission'),"tip":_vm.$t('YouWillSpecifyAmountOfCommissionDependingOnNumber')}},[_c('v-select',{attrs:{"options":_vm.determineCommissionAmountTypes,"label":"label","reduce":function (i) { return i.value; }},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var label = ref.label;
return [_c('span',[_vm._v(_vm._s(_vm.$t(("" + label))))])]}},{key:"option",fn:function(ref){
var label = ref.label;
return [_c('span',[_vm._v(_vm._s(_vm.$t(("" + label))))])]}},{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('NoOptions'))+" ")]},proxy:true}],null,true),model:{value:(_vm.target.determineCommissionAmountType),callback:function ($$v) {_vm.$set(_vm.target, "determineCommissionAmountType", $$v)},expression:"target.determineCommissionAmountType"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('EnableUserDiscount')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex"},[_c('b-form-checkbox',{attrs:{"variant":"primary","switch":""},model:{value:(_vm.target.enableUserDiscount),callback:function ($$v) {_vm.$set(_vm.target, "enableUserDiscount", $$v)},expression:"target.enableUserDiscount"}},[_vm._v(" "+_vm._s(_vm.$t('EnableUserDiscount'))+" ")]),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.focus.v-primary",modifiers:{"focus":true,"v-primary":true}}],staticClass:"btn-icon p-25 mt-n50 ml-25",attrs:{"variant":"flat-primary","title":_vm.$t('ItEnablesUserToGrantDiscountToCounterpartyUpToAmountOfHisOwnCommission'),"size":"sm"}},[_c('feather-icon',{attrs:{"icon":"InfoIcon"}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-overlay',{attrs:{"show":_vm.isLoading,"no-wrap":"","spinner-variant":"primary"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }