import * as fields from '@/@constants/fields'

const userListBreadcrumb = {
  text: 'Users',
  active: false,
  to: '/users',
}

// eslint-disable-next-line import/prefer-default-export
export const user = [
  // CREATE
  {
    path: '/user/new',
    name: 'new-user',
    component: () => import('@/pages/user/Create.vue'),
    meta: {
      autorize: true,
      pageTitle: 'NewUser',
      breadcrumb: [
        userListBreadcrumb,
        {
          text: 'NewUser',
          active: true,
        },
      ],
    },
  },
  // EDIT
  {
    path: '/user/edit/:userId',
    name: 'edit-user',
    component: () => import('@/pages/user/Create.vue'),
    meta: {
      autorize: true,
      pageTitle: 'EditUser',
      breadcrumb: [
        userListBreadcrumb,
        {
          active: false,
          request: {
            to: '/user/{userId}', routeParam: 'userId', endpoint: '/users/{userId}', fields: ['firstName', 'lastName'], fields_load: fields.USERS_SEARCH,
          },
        },
      ],
    },
  },
  // DETAILS
  {
    path: '/user-new/:userId',
    name: 'user-new',
    component: () => import('@/pages/user/UserNew.vue'),
    meta: {
      autorize: true,
      pageTitle: 'User',
      breadcrumb: [
        userListBreadcrumb,
        {
          active: true,
          request: {
            routeParam: 'userId', endpoint: '/users/{userId}', fields: ['firstName', 'lastName'], fields_load: fields.USERS_SEARCH,
          },
        },
      ],
    },
  },
  {
    path: '/user/:userId',
    name: 'user',
    component: () => import('@/pages/user/User.vue'),
    meta: {
      autorize: true,
      pageTitle: 'User',
      breadcrumb: [
        userListBreadcrumb,
        {
          active: true,
          request: {
            routeParam: 'userId', endpoint: '/users/{userId}', fields: ['firstName', 'lastName'], fields_load: fields.USERS_SEARCH,
          },
        },
      ],
    },
  },
  {
    path: '/profile-settings/:tab?',
    name: 'user-profile',
    component: () => import('@/pages/user/current/Profile.vue'),
    meta: {
      autorize: true,
      pageTitle: 'ProfileSettings',
    },
  },
  {
    path: '/profile-settings-new',
    name: 'user-profile-new',
    component: () => import('@/pages/user/current/ProfileNew.vue'),
    meta: {
      autorize: true,
      pageTitle: 'ProfileSettings',
    },
  },
  {
    path: '/sessions',
    name: 'user-sessions',
    component: () => import('@/pages/user/current/Sessions.vue'),
    meta: {
      autorize: true,
      pageTitle: 'AuthHistory',
    },
  },
]
