import {
  DOWNLOAD_FILE,
  SET_DEFAULT_STATE,
  SET_TOAST_ITEM_PROGRESS, SHOW_UPLOAD_TOAST,
  UPLOAD_FILES,
} from '@/@constants/mutations'
// eslint-disable-next-line import/no-cycle
import axiosIns from '@/libs/axios'

const defaultState = {
  showUploadToast: false,
  uploadedFiles: [],
  uploadProgress: null,
}

export default {
  namespaced: true,
  state: { ...defaultState },
  getters: {
    getShowUploadToast: state => state.showUploadToast,
    getUploadedFiles: state => state.uploadedFiles,
  },
  mutations: {
    [SET_DEFAULT_STATE](state) {
      Object.assign(state, defaultState)
    },
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    [SHOW_UPLOAD_TOAST]: ({ commit, state }, payload) => new Promise((resolve, reject) => {
      const { files, controlIndex } = payload
      const item = {
        controlIndex, type: 'email', uploadedFiles: files, progress: 0,
      }
      state.uploadedFiles.push(item)
      state.showUploadToast = true
    }),
    // eslint-disable-next-line no-unused-vars
    [SET_TOAST_ITEM_PROGRESS]: ({ commit, state }, payload) => new Promise((resolve, reject) => {
      const { progress, controlIndex } = payload
      state.uploadedFiles[state.uploadedFiles.findIndex(tableFile => tableFile.controlIndex === controlIndex)].progress = progress

      if (progress > 99) {
        state.uploadedFiles.splice(state.uploadedFiles.findIndex(tableFile => tableFile.controlIndex === controlIndex), 1)
        if (!state.uploadedFiles.length) commit(SET_DEFAULT_STATE)
      }
    }),
    // eslint-disable-next-line no-unused-vars
    [UPLOAD_FILES]: ({ commit, state }, payload) => new Promise((resolve, reject) => {
      const {
        uploadedFiles, type, threadId, data, compress = true,
      } = payload

      if (!uploadedFiles || !uploadedFiles.length) {
        resolve({ files: [], threadId, data })
        return
      }

      const controlIndex = `${uploadedFiles.length}_${uploadedFiles[0].size}_${threadId || '00'}_${Math.round(Math.random() * 10)}`
      const item = {
        controlIndex, type, uploadedFiles, progress: 0,
      }
      state.uploadedFiles.push(item)
      state.showUploadToast = true

      const formData = new FormData()
      uploadedFiles.forEach(file => {
        formData.append('files[]', file, file.name)
      })

      if (!compress) {
        formData.append('imageCompress', 'false')
      }

      const config = {
        onUploadProgress(progressEvent) {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          state.uploadedFiles[state.uploadedFiles.findIndex(tableFile => tableFile.controlIndex === controlIndex)].progress = percentCompleted
        },
      }

      axiosIns.post('storage/1/upload', formData, { noApi: true, ...config })
        .then(res => {
          setTimeout(() => {
            resolve({
              files: res.data.data.files,
              threadId,
              data,
            })
            state.uploadedFiles.splice(state.uploadedFiles.findIndex(tableFile => tableFile.controlIndex === controlIndex), 1)
            if (!state.uploadedFiles.length) commit(SET_DEFAULT_STATE)
          }, 500)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [DOWNLOAD_FILE]: ({ commit }, payload) => new Promise((resolve, reject) => {
      const { fileToken, filename } = payload
      axiosIns.get(`storage/1/download/${fileToken}`)
        .then(res => {
          resolve({ data: res.data.data, filename })
        })
        .catch(err => {
          reject(err)
        })
    }),
  },
}
