<template>
  <div
    style="padding-bottom: .6rem; z-index: 10"
    class="sticky-top bg-light"
  >
    <b-card
      body-class="p-1 d-flex align-items-center justify-content-between gap-1"
      class="mb-0"
    >
      <template v-if="!loading">
        <sw-user-info
          :name="contactName"
          :loading="loading"
          :content="contact.isOrganization && contact.organization ? contact.organization.name : ''"
          :icon="contact.isOrganization && contact.organization ? 'BriefcaseIcon' : 'UserIcon'"
          :tip="`${contactName}${contact.isOrganization && contact.organization ? ` ${$t('projects.comments.From')} ${contact.organization && contact.organization.name ? contact.organization.name : ''}` : ''}`"
          size="sm"
        />

        <!--    START::Actions    -->
        <b-dropdown
          v-if="protectActions"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          dropright
          variant="primary"
          :text="$t('Actions')"
          size="sm"
        >
          <template
            v-if="
              protectSendSms
                || protectSendEmail
                || protectAddTask
                || protectAddNote
                || protectActiveCart
            "
          >
            <template
              v-if="
                protectSendSms
                  || protectSendEmail
                  || protectAddTask
                  || protectAddNote
              "
            >
              <b-dropdown-item
                v-if="protectSendSms"
                @click="openModal('sms', $t('Sms'), $t('Send'))"
              >
                <dropdown-item-label
                  icon="MessageCircleIcon"
                  :label="$t('SendSms')"
                />
              </b-dropdown-item>

              <b-dropdown-item
                v-if="protectSendEmail"
                @click="openModal('mail', $t('Email'), $t('Send'))"
              >
                <dropdown-item-label
                  icon="MailIcon"
                  :label="$t('SendEmail')"
                />
              </b-dropdown-item>

              <b-dropdown-item
                v-if="protectAddTask"
                @click="openModal('task', $t('NewTask'), $t('AddTask'))"
              >
                <dropdown-item-label
                  icon="ListIcon"
                  :label="$t('AddTask')"
                />
              </b-dropdown-item>

              <b-dropdown-item
                v-if="protectAddNote"
                @click="openModal('note', $t('NewNote'), $t('AddNote'))"
              >
                <dropdown-item-label
                  icon="FileTextIcon"
                  :label="$t('AddNote')"
                />
              </b-dropdown-item>

              <b-dropdown-divider />
            </template>

            <template v-if="protectActiveCart && thread">
              <b-dropdown-item
                :disabled="cart.contactThread && thread.id === cart.contactThread.id"
                @click="activateCheckout()"
              >
                <dropdown-item-label
                  icon="ShoppingCartIcon"
                  :label="$t(cart.contactThread && thread.id === cart.contactThread.id ? 'offer.ActiveCheckout' : 'offer.ActivateCheckout')"
                />
              </b-dropdown-item>
            </template>

            <b-dropdown-divider v-if="protectEditContact || protectRemoveContact" />
          </template>

          <b-dropdown-item
            v-if="protectEditContact"
            :to="`/contacts/edit/${contact.id}`"
          >
            <dropdown-item-label
              icon="EditIcon"
              :label="$t('edit')"
            />
          </b-dropdown-item>

          <b-dropdown-item
            v-if="protectRemoveContact"
            variant="danger"
            @click="removeCurrentContact(contact.id)"
          >
            <dropdown-item-label
              icon="TrashIcon"
              :label="$t('Delete')"
            />
          </b-dropdown-item>
        </b-dropdown>
      <!--    END::Actions    -->
      </template>

      <b-spinner
        v-else
        type="grow"
        small
        class="my-50 mx-auto"
        variant="primary"
      />
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { OPEN_MODAL, REMOVE_CURRENT_CONTACT, UPDATE_CART } from '@/@constants/mutations'
import * as $viewRanks from '@/helpers/viewPermissions'
import * as $roles from '@/helpers/permissions'
import router from '@/router'

export default {
  computed: {
    ...mapGetters({
      cart: 'cart/getCart',
      system: 'system/getSettings',
      user: 'auth/getCurrentUser',

      contact: 'contact/contact',
      thread: 'contact/thread',
      contactName: 'contact/contactName',
    }),

    loading() { return !this.contact || (!this.thread && this.protectGetThreads && router.currentRoute.params.threadId) },
    protectActions() {
      return this.protectSendSms
        || this.protectSendEmail
        || this.protectAddTask
        || this.protectAddNote
        || this.protectActiveCart
        || this.protectEditContact
        || this.protectRemoveContact
    },
    protectGetThreads() {
      return this.checkRequiredPermissions([$roles.CONTACT_THREAD_VIEW_ALL]) && this.checkRequiredPermissions([$roles.CONTACT_THREAD_VIEW_ASSIGNED])
    },
    protectSendSms() {
      return this.checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_CARD_SMS])
        && this.checkRequiredPermissions([$roles.CONTACT_THREAD_SMS_VIEW_ALL, $roles.CONTACT_THREAD_SMS_VIEW_SHARED, $roles.CONTACT_THREAD_SMS_VIEW_SELF], [$roles.CONTACT_THREAD_SMS_ADD])
    },
    protectSendEmail() {
      return this.checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_CARD_EMAILS])
        && this.checkRequiredPermissions([$roles.CONTACT_THREAD_MAIL_VIEW_ALL], [$roles.CONTACT_THREAD_MAIL_ADD])
    },
    protectAddTask() {
      return this.checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_CARD_TASKS])
        && this.checkRequiredPermissions([$roles.CONTACT_THREAD_TASK_VIEW_ALL, $roles.CONTACT_THREAD_TASK_VIEW_ASSIGNED, $roles.CONTACT_THREAD_TASK_VIEW_STRUCTURE], [
          $roles.CONTACT_THREAD_TASK_ADD,
          {
            role: $roles.CONTACT_THREAD_ASSIGNED_TASK_ADD, data: { thread: this.thread }, userId: this.user.id, field: 'assignedUsers',
          },
        ])
    },
    protectAddNote() {
      return this.checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_CARD_NOTES])
        && this.checkRequiredPermissions([$roles.CONTACT_THREAD_NOTE_VIEW_ALL, $roles.CONTACT_THREAD_NOTE_VIEW_SELF, $roles.CONTACT_THREAD_NOTE_VIEW_SHARED], [
          $roles.CONTACT_THREAD_NOTE_ADD,
          {
            role: $roles.CONTACT_THREAD_ASSIGNED_NOTE_ADD, data: this.thread, userId: this.user.id, field: 'assignedUsers',
          },
        ])
    },
    protectActiveCart() {
      return this.checkRequiredModule('offerModule')
        && this.checkRequiredPermissions([$roles.SHOP_PRODUCT_VIEW_ALL, $roles.SHOP_PRODUCT_VIEW_GROUP])
        && this.checkRequiredPermissions([$roles.CONTACT_THREAD_OFFER_ADD])
    },
    protectEditContact() {
      return this.checkRequiredPermissions([$roles.CONTACT_EDIT_DETAILS])
    },
    protectRemoveContact() {
      return this.checkRequiredPermissions([$roles.CONTACT_DELETE])
    },
  },
  methods: {
    ...mapActions({
      removeCurrentContact: `contact/${REMOVE_CURRENT_CONTACT}`,
    }),

    changeSettings(value, place) {
      this.$set(this.cart, place, value)

      this.$store.dispatch(`cart/${UPDATE_CART}`, { cart: this.cart })
        .then(() => this.showToast('success', this.$i18n.t('CartUpdated')))
        .catch(err => this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err))
    },
    activateCheckout() {
      const {
        id, name, contact, offersTotal,
      } = this.thread
      const thread = {
        id, name, contact,
      }

      if (offersTotal >= this.system.contactThreadOffersLimit) {
        this.showAlert('warning', '', this.$i18n.tc('ThreadOfferCountIsToBig', this.system.contactThreadOffersLimit, { limit: this.system.contactThreadOffersLimit }))

        return
      }

      if (this.thread?.id) {
        const text = this.$i18n.tc('offer.CartAlreadyActivated', 1, { thread: this.thread.name, contact: `${this.contact.firstName} ${this.contact.lastName}` })

        this.showAlert('error', this.$i18n.t('alerts.AreYouSure'), text)
          .then(() => this.changeSettings(thread, 'contactThread'))
          .catch(() => {})
      } else {
        this.changeSettings(thread, 'contactThread')
      }
    },
    openModal(modalType = 'note', modalTitle = 'Nowa Notatka', okButtonTitle = 'Dodaj notatkę') {
      this.$store.commit(`modal/${OPEN_MODAL}`,
        {
          modalType,
          modalTitle,
          okButtonTitle,
          size: 'lg',
          threadFor: this.thread?.id,
        })
    },
  },
}
</script>
