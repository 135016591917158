var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.isAssignedContactsEdit)?_c('div',[(_vm.allowedContacts.length)?_c('sw-select',{attrs:{"name":_vm.$t('SelectContact')}},[_c('v-select',{attrs:{"id":"select_funnel","options":_vm.allowedContacts,"label":"name","reduce":function (c) { return c.id; }},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('NoOptions'))+" ")]},proxy:true},{key:"selected-option",fn:function(ref){
var firstName = ref.firstName;
var lastName = ref.lastName;
return [_vm._v(" "+_vm._s(firstName)+" "+_vm._s(lastName)+" ")]}},{key:"option",fn:function(ref){
var firstName = ref.firstName;
var lastName = ref.lastName;
return [_vm._v(" "+_vm._s(firstName)+" "+_vm._s(lastName)+" ")]}}],null,false,2715861960),model:{value:(_vm.selectedContact),callback:function ($$v) {_vm.selectedContact=$$v},expression:"selectedContact"}})],1):_vm._e(),_c('b-form-group',[_c('b-form-checkbox',{model:{value:(_vm.thread.isPrimary),callback:function ($$v) {_vm.$set(_vm.thread, "isPrimary", $$v)},expression:"thread.isPrimary"}},[_vm._v(_vm._s(_vm.$t('SetAsPrimaryThread')))])],1),(!_vm.modalConfig.editedData)?_c('sw-select',{attrs:{"name":_vm.$t('SelectFunnel')}},[_c('v-select',{attrs:{"id":"select_funnel","options":_vm.funnels,"label":"name","reduce":function (funnel) { return funnel.contactThreadFunnelTypePoints[0].id; }},on:{"option:selected":_vm.selectFunnel},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('NoOptions'))+" ")]},proxy:true},{key:"selected-option",fn:function(ref){
var name = ref.name;
return [_vm._v(" "+_vm._s(_vm._f("truncate")(name,50, '...'))+" ")]}},{key:"option",fn:function(ref){
var name = ref.name;
return [_vm._v(" "+_vm._s(_vm._f("truncate")(name,50, '...'))+" ")]}}],null,false,2020463016),model:{value:(_vm.thread.newThreadFunnel),callback:function ($$v) {_vm.$set(_vm.thread, "newThreadFunnel", $$v)},expression:"thread.newThreadFunnel"}})],1):_vm._e(),_c('b-form-group',{attrs:{"label":_vm.$t('ThreadName')}},[_c('b-form-input',{ref:"input1",attrs:{"id":"popover-input-1"},model:{value:(_vm.thread.newThreadName),callback:function ($$v) {_vm.$set(_vm.thread, "newThreadName", $$v)},expression:"thread.newThreadName"}})],1),(!_vm.modalConfig.editedData)?_c('sw-select',{attrs:{"name":_vm.$t('SelectChecklist')}},[_c('v-select',{attrs:{"id":"select_checklist","options":_vm.checklists,"label":"name","multiple":"","reduce":function (checklist) { return checklist.id.toString(); }},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('NoOptions'))+" ")]},proxy:true},{key:"selected-option",fn:function(ref){
var name = ref.name;
return [_vm._v(" "+_vm._s(name)+" ")]}},{key:"option",fn:function(ref){
var name = ref.name;
return [_vm._v(" "+_vm._s(name)+" ")]}}],null,false,2986950120),model:{value:(_vm.thread.newThreadChecklist),callback:function ($$v) {_vm.$set(_vm.thread, "newThreadChecklist", $$v)},expression:"thread.newThreadChecklist"}})],1):_vm._e(),_c('div',{staticClass:"mt-1 mb-1"},[_c('label',[_vm._v(_vm._s(_vm.$t('AssignedUsers')))]),_c('assigned-users',{attrs:{"value":_vm.thread.assignedUsers,"is-multiple":true},on:{"input":_vm.changeAssignedUsers}})],1),_c('sw-select',{attrs:{"name":_vm.$t('PrimaryUser'),"tip":_vm.$t('PrimaryUserIsUsedInShortcodes')}},[_c('v-select',{attrs:{"options":_vm.thread.assignedUsers,"label":"firstName","clearable":false},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.$t('NoData'))+" ")])]},proxy:true},{key:"option",fn:function(ref){
var firstName = ref.firstName;
var lastName = ref.lastName;
var avatar = ref.avatar;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('avatar',{staticClass:"mr-25",attrs:{"user":{ firstName: firstName, lastName: lastName, avatar: avatar },"size":"sm"}})],1)]}},{key:"selected-option",fn:function(ref){
var firstName = ref.firstName;
var lastName = ref.lastName;
var avatar = ref.avatar;
return [_c('div',{staticClass:"d-flex"},[_c('avatar',{staticClass:"mr-25",attrs:{"user":{ firstName: firstName, lastName: lastName, avatar: avatar },"size":"sm"}})],1)]}}],null,false,2146094675),model:{value:(_vm.thread.assignedLeadUser),callback:function ($$v) {_vm.$set(_vm.thread, "assignedLeadUser", $$v)},expression:"thread.assignedLeadUser"}})],1),_c('b-form-group',{staticClass:"mb-1"},[_c('label',[_vm._v(_vm._s(_vm.$t('CustomerValue')))]),_c('b-row',[_c('b-col',{attrs:{"sm":"9","md":"9","lg":"9"}},[_c('cleave',{staticClass:"form-control",attrs:{"options":_vm.cleaveOptions.number},model:{value:(_vm.thread.predictedValue),callback:function ($$v) {_vm.$set(_vm.thread, "predictedValue", $$v)},expression:"thread.predictedValue"}})],1),_c('b-col',{attrs:{"sm":"3","md":"3","lg":"3"}},[_c('sw-select',[_c('v-select',{attrs:{"options":_vm.currencyList},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('NoOptions'))+" ")]},proxy:true}],null,false,2260934444),model:{value:(_vm.thread.predictedValueCurrency),callback:function ($$v) {_vm.$set(_vm.thread, "predictedValueCurrency", $$v)},expression:"thread.predictedValueCurrency"}})],1)],1)],1)],1)],1):_vm._e(),_c('div',{staticClass:"mt-1"},[_c('label',[_vm._v(_vm._s(_vm.$t('AssignedContact')))]),_vm._l((_vm.thread.contactThreadAssignedContacts),function(contact,index){return _c('div',{key:("thread_assigned_user_" + index),staticClass:"d-flex flex-wrap flex-md-nowrap mt-50 align-items-center"},[_c('sw-select',{staticClass:"flex-grow-1 mb-0"},[_c('v-select',{staticClass:"W-100 mr-50 select-size-sm",staticStyle:{"min-width":"255px"},attrs:{"item-value":"id","options":_vm.contacts,"label":"firstName","filterable":false,"selectable":function (option) { return _vm.usedContacts(option); }},on:{"search":_vm.onSearch},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var id = ref.id;
var avatar = ref.avatar;
var firstName = ref.firstName;
var lastName = ref.lastName;
return [_c('b-avatar',{attrs:{"size":"sm","src":avatar,"text":firstName.slice(0,1).toUpperCase() + lastName.slice(0,1).toUpperCase()}}),_c('span',{staticClass:"ml-50 pt-25"},[_vm._v(" "+_vm._s(firstName)+" "+_vm._s(lastName)+" "+_vm._s(id === '0' ? ("- " + (_vm.$t('Contact'))) : ''))])]}},{key:"selected-option",fn:function(ref){
var avatar = ref.avatar;
var firstName = ref.firstName;
var lastName = ref.lastName;
return [_c('b-avatar',{staticClass:"border border-white",attrs:{"size":"sm","src":avatar,"text":firstName.slice(0,1).toUpperCase() + lastName.slice(0,1).toUpperCase()}}),_c('span',{staticClass:"ml-50 pt-25"},[_vm._v(" "+_vm._s(firstName)+" "+_vm._s(lastName))])]}}],null,true),model:{value:(contact.contact),callback:function ($$v) {_vm.$set(contact, "contact", $$v)},expression:"contact.contact"}},[_c('template',{slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('typeToSearchContact'))+" ")])],2)],1),(false)?_c('sw-select',[_c('v-select',{staticClass:"w-50 select-size-sm",staticStyle:{"min-width":"250px"},attrs:{"options":_vm.threadTags,"placeholder":_vm.$t('ContactTag'),"label":"name","selectable":function (option) { return _vm.usedTags(option); }},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('NoOptions'))+" ")]},proxy:true},{key:"option",fn:function(ref){
var name = ref.name;
var color = ref.color;
return [_c('div',{staticClass:"pt-25"},[_c('div',{staticClass:"color-dot",staticStyle:{"border":"1px solid white"},style:({ backgroundColor: color })}),_vm._v(" "+_vm._s(_vm._f("truncate")(name,30))+" ")])]}},{key:"selected-option",fn:function(ref){
var name = ref.name;
var color = ref.color;
return [_c('div',{staticClass:"pt-25 position-relative"},[_c('div',{staticClass:"color-dot",staticStyle:{"border":"1px solid white"},style:({ backgroundColor: color })}),_vm._v(" "+_vm._s(_vm._f("truncate")(name,30))+" ")])]}}],null,true),model:{value:(contact.contactType),callback:function ($$v) {_vm.$set(contact, "contactType", $$v)},expression:"contact.contactType"}})],1):_vm._e(),_c('b-input-group',{staticClass:"input-group-merge ml-25",staticStyle:{"min-width":"100px"},attrs:{"size":"sm"}},[_c('b-form-input',{attrs:{"type":"number","placeholder":_vm.$t('Part') + ' %'},model:{value:(contact.part),callback:function ($$v) {_vm.$set(contact, "part", $$v)},expression:"contact.part"}})],1),_c('b-button',{staticClass:"ml-50",attrs:{"variant":"flat-danger","size":"sm"},on:{"click":function($event){return _vm.deleteContact(index)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}})],1)],1)}),_c('div',{staticClass:"mt-50"},[_c('b-button',{attrs:{"size":"sm","variant":"flat-success"},on:{"click":_vm.addContact}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)],1)],2),_c('div',{staticClass:"d-flex justify-content-end pt-1 mt-1 border-top"},[_c('b-button',{staticClass:"mr-50",attrs:{"variant":"secondary","size":"sm"},on:{"click":_vm.onModalClose}},[_vm._v(" "+_vm._s(_vm.$t('Cancel'))+" ")]),_c('b-button',{attrs:{"variant":"primary","size":"sm","disabled":_vm.thread.newThreadName === '' || !_vm.checkAssignedContacts || _vm.loading},on:{"click":_vm.onCreateThread}},[_vm._v(" "+_vm._s(_vm.okBtn)+" ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }