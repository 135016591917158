const projects = [
  {
    path: '/booking/:id',
    name: 'Booking',
    component: () => import('@/pages/booking/View/index.vue'),
    meta: {
      pageTitle: 'Booking',
      layout: 'full',
    },
  },
]

export default projects
