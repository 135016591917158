<template>
  <div>
    <div v-if="!loading && user">
      <b-row>
        <b-col
          xl="12"
          md="12"
        >
          <b-card>
            <b-row>
              <!-- User Info: Left col -->
              <b-col
                cols="12"
                xl="6"
                class="d-flex justify-content-between flex-column"
              >
                <!-- User Avatar & Action Buttons -->
                <div class="d-flex justify-content-start">
                  <div class="d-flex flex-column ml-1">
                    <div class="mb-50 d-flex">
                      <b-avatar
                        variant="primary"
                        :src="user.avatar"
                        :text="user.firstName.slice(0,1).toUpperCase() + user.lastName.slice(0,1).toUpperCase()"
                        size="35px"
                        circle
                        class="mr-50"
                      />
                      <div>
                        <h5 class="mb-0">
                          {{ user.firstName }} {{ user.lastName }}
                        </h5>

                        <div
                          v-if="user.email"
                          class="d-flex align-items-center"
                          style="gap: .34rem"
                        >
                          <a
                            :href="`mailto:${user.email}`"
                            class="card-text font-small-2"
                          >
                            {{ user.email }}
                          </a>

                          <b-button
                            v-b-tooltip.hover.v-success
                            variant="flat-success"
                            size="sm"
                            class="btn-icon p-25"
                            :title="$t('Copy')"
                            @click="copy(user.email)"
                          >
                            <feather-icon icon="CopyIcon" />
                          </b-button>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex flex-wrap mt-50">
                      <!--  edit btn -->
                      <b-button
                        v-if="checkRequiredPermissions([$roles.USER_EDIT])"
                        size="sm"
                        variant="warning"
                        class="mb-25 mr-25"
                        :to="`/user/edit/${user.id}`"
                      >
                        <feather-icon
                          icon="EditIcon"
                          size="14"
                        />
                        {{ $t("Edit") }}
                      </b-button>

                      <b-button
                        v-if="checkRequiredPermissions([$roles.USER_EDIT]) && user.canLogin"
                        size="sm"
                        :variant="user.blocked ? 'success' : 'danger'"
                        class="mb-25 mr-25"
                        @click="changeUserStatus(user.id)"
                      >
                        <feather-icon
                          :icon="user.blocked ? 'UnlockIcon' : 'LockIcon'"
                          size="14"
                        />
                        {{ user.blocked ? $t('Unblock') : $t('Block') }}
                      </b-button>

                      <b-button
                        v-if="user.activated && checkRequiredPermissions([$roles.USER_RESET_PASSWORD]) && user.canLogin"
                        variant="primary"
                        size="sm"
                        class="mb-25 mr-25"
                        @click="resetUserPassword(user.id)"
                      >
                        <feather-icon
                          icon="EditIcon"
                          size="14"
                        />
                        {{ $t('login.ResetPassword') }}
                      </b-button>
                    </div>
                  </div>
                </div>
              </b-col>

              <!-- Right Col: Table -->
              <b-col
                cols="12"
                xl="5"
                class="mt-50 mt-md-0"
              >
                <!-- User Info -->
                <user-info :user="user" />
              </b-col>
            </b-row>

            <b-col
              v-if="checkRequiredViewPermissions([$viewRanks.SHOW_USERS_NOTE]) && user.note"
              sm="12"
              class="mt-50"
            >
              <b-alert
                show
                variant="primary"
              >
                <h4 class="alert-heading">
                  {{ $t('Note') }}
                </h4>
                <div class="alert-body">
                  <div v-html="user.note" />
                </div>
              </b-alert>
            </b-col>

            <b-row class="mt-1 mt-md-0">
              <b-col sm="12">
                <user-details
                  :is-user="true"
                  :contact="user"
                  :additional-fields="['bankAccountName', 'bankAccountNumber']"
                />
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <b-tabs>
        <b-tab :title="$t('RanksSettings')">
          <rank-details
            v-if="user"
            view-mode
            :rank-id="user.rank.id"
          />
        </b-tab>
        <b-tab v-if="checkRequiredPermissions([
                 $roles.USER_STRUCTURE_VIEW_ALL,
                 $roles.USER_STRUCTURE_VIEW_STRUCTURE,
                 $roles.USER_STRUCTURE_VIEW_STRUCTURE_FIRST,
               ])"
               :title="$t('Structure')"
        >
          <structure :root-user-id="user.id" />
        </b-tab>
      </b-tabs>

    </div>
    <div v-else>
      <b-overlay
        :show="loading"
        no-wrap
        spinner-variant="primary"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as roles from '@/helpers/permissions'
import UserDetails from '@/views/core/singleContact/parts/UserDetails.vue'
import axiosIns from '@/libs/axios'
import RankDetails from '@/views/settings/ranks/RankDetails.vue'
import Structure from '@/pages/structure/Structure.vue'
import UserInfo from '../../views/core/user/UserInfo.vue'
import { CHANGE_USER_STATUS, GET_SINGLE_USER, REMOVE_USERS } from '../../@constants/mutations'

export default {
  name: 'SingleUser',
  components: {
    Structure,
    UserDetails,
    RankDetails,
    'user-info': UserInfo,
  },
  data: () => ({
    roles,
    loading: true,
    ranks: [],
  }),
  computed: {
    ...mapGetters({
      user: 'users/getSingleUser',
      currentUser: 'auth/getCurrentUser',
    }),
  },
  async mounted() {
    const { userId } = this.$route.params
    this.loading = true

    try {
      // eslint-disable-next-line no-unused-vars
      const [_, ranksListResponse] = await Promise.all([
        this.$store.dispatch(`users/${GET_SINGLE_USER}`, userId),
        axiosIns.get('1/ranks', { params: { fields_load: this.$fields.RANKS } }),
      ])

      this.ranks = ranksListResponse.data.data.items
    } catch (err) {
      this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
    } finally {
      this.loading = false
    }

    // this.$store.dispatch(`users/${GET_SINGLE_USER}`, userId)
    //   .then(() => {
    //     this.loading = false
    //   })
    //   .catch(err => {
    //     this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
    //   })
  },
  methods: {
    copy(txt) {
      this.showToast('success', this.$i18n.t('CopiedToClipboard'))

      navigator.clipboard.writeText(txt)
    },
    resetUserPassword(userId) {
      this.$store.dispatch('auth/RESET_USER_PASSWORD', userId)
        .then(() => {
          this.showAlert('success', '', this.$i18n.t('UserRestartInfo'), false)
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
    changeUserStatus(userId) {
      this.$store.dispatch(`users/${CHANGE_USER_STATUS}`, { status: !this.user.blocked, userId })
        .then(() => {
          this.user.blocked = !this.user.blocked
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
    removeUser(userId) {
      this.$store.dispatch('globalTasks/GET_USER_TASKS', { userIds: [userId.toString()] })
        .then(res => {
          if (!res.data.totalItemCount) {
            this.showAlert('error', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('alerts.WontbeAble'))
              .then(() => {
                this.$store.dispatch(`users/${REMOVE_USERS}`, [userId.toString()])
                  .then(() => {
                    this.$router.push('/users')
                  })
                  .catch(err => {
                    this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
                  })
              })
          } else {
            this.showAlert('error', this.$i18n.t('ProblemOccured'), 'Użytkownik z przypisanymi zadaniami nie może zostać usunięty')
          }
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
  },
}
</script>

<style scoped>

</style>
