<template>
  <div v-if="product">
    <b-overlay
      no-wrap
      :show="isLoading"
      spinner-variant="primary"
    />

    <!--  Remove "&& loading" on production -->
    <b-alert
      variant="primary"
      show
      class-name="shadow border border-secondary"
      style="border-color: #DDD !important;"
    >
      <div class="alert-body text-left position-relative">
        <div
          class="d-flex align-items-center justify-content-center w-100 position-relative zindex-1"
          style="min-height: 140px"
        >
          <div
            class="d-flex flex-column justify-content-center align-items-center"
            style="min-height: 140px"
          >
            <!--      START->Announcement: No Service      -->
            <h3
              class="text-primary text-md-center font-weight-bolder mb-2"
              style="max-width: 680px; line-height: 2rem"
            >
              {{ !product.shopProductLoyaltyPlans.length ? $t('LoyaltyPlanNotSelectedToChargeUsersRewardsForSellingThisProduct') : $t('LoyaltyPlanSelectedToChargeUsersRewardsForSellingThisProduct') }}
            </h3>
            <!--      END->Announcement: No Service      -->

            <!--      START->Button: Add Service      -->
            <sw-select
              style="max-width: 340px"
              :disabled="isEdited"
            >
              <v-select
                :value="selectedPlan"
                class="bg-white"
                :disabled="isEdited"
                :options="plans"
                label="name"
                @input="selectPlan"
              >
                <template #no-options>
                  {{ $t('NoOptions') }}
                </template>
              </v-select>
            </sw-select>
            <!--      END->Button: Add Service      -->
          </div>
        </div>

        <!--      START->Illustration      -->
        <!--          <div class="text-center w-100 mt-2 position-absolute position-top-0">-->
        <!--            <img-->
        <!--              src="@/assets/images/pricing-Illustration.svg"-->
        <!--              alt="Illustration"-->
        <!--              width="950px"-->
        <!--              style="opacity: .14; z-index: 0;"-->
        <!--            >-->
        <!--          </div>-->
        <!--      END->Illustration      -->
      </div>
    </b-alert>    <!--  Widok przed wybraniem   -->

    <app-collapse
      class="mt-1"
      type="margin"
    >
      <app-collapse-item
        v-for="(shopProductPlan, i) in product.shopProductLoyaltyPlans"
        :key="i"
        :title="shopProductPlan.loyaltyPlan.name"
        is-visible
      >
        <b-row style="row-gap: 1rem">
          <b-col md="6">
            <p class="mb-25">
              {{ $t('PointValueAwardedForSellingProduct') }}
            </p>

            <cleave
              v-model="shopProductPlan.pointsReward"
              :options="cleaveOptions.numberPositive"
              class="form-control"
              :disabled="isEdited"
            />
          </b-col>

          <b-col md="6">
            <p class="mb-25">
              {{ $t('PointValueOfTheProductInPurchase') }}
            </p>

            <cleave
              v-model="shopProductPlan.pointsPrice"
              :options="cleaveOptions.numberPositive"
              class="form-control"
              :disabled="isEdited"
            />
          </b-col>
        </b-row>
      </app-collapse-item>
    </app-collapse>    <!--  Widok po wyborze   -->
  </div>
</template>

<script>
import Cleave from 'vue-cleave-component'
import vSelect from 'vue-select'
import axiosIns from '@/libs/axios'

export default {
  name: 'LoyaltyPlan',
  components: {
    vSelect,
    Cleave,
  },
  props: {
    product: {
      required: true,
      default: null,
      type: Object,
    },
    isEdited: {
      required: true,
      default: false,
      type: Boolean,
    },
  },
  data: () => ({
    plans: [],
    isLoading: false,

    cleaveOptions: {
      numberPositive: {
        delimiter: ' ',
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
        numeralPositiveOnly: true,
      },
    },
  }),
  computed: {
    selectedPlan() {
      return this.product?.shopProductLoyaltyPlans?.length ? this.product.shopProductLoyaltyPlans[0]?.loyaltyPlan : null
    },
  },
  async mounted() {
    await this.loadPlans()
  },
  methods: {
    async loadPlans() {
      this.isLoading = true

      try {
        const resp = await axiosIns.get('1/loyaltyPlans', { params: { fields_load: this.$fields.LOYALTY_PLANS } })

        this.plans = resp?.data?.data?.items || []
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.isLoading = false
      }
    },
    async selectPlan(event) {
      if (!event) {
        this.$set(this.product, 'shopProductLoyaltyPlans', [])
        return
      }

      this.$set(this.product, 'shopProductLoyaltyPlans', [{
        pointsReward: 0,
        pointsPrice: 0,
        loyaltyPlan: event,
      }])
    },
  },
}
</script>

<style scoped>

</style>
