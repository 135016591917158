<template>
  <!--    Category list    -->
  <category-list />
</template>

<script>
import categoryList from '../../views/offers/category/List.vue'

export default {
  components: {
    categoryList,
  },
}
</script>
