var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.thread && _vm.thread.contactThreadAssignedContacts)?_c('div',[_c('app-collapse',{staticClass:"mt-50",attrs:{"accordion":"","type":_vm.$route.name !== 'shop/checkout' ? 'margin' : 'border'}},_vm._l((_vm.thread.contactThreadAssignedContacts),function(assignedContact,index){return _c('app-collapse-item',{key:("contact_" + index),attrs:{"title":assignedContact.contact.firstName,"sub-title":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('avatar',{attrs:{"user":assignedContact.contact}})]},proxy:true},{key:"subTitle",fn:function(){return [_c('div',{staticClass:"d-flex flex-wrap justify-content-between w-100 mt-50"},[_c('div',{staticStyle:{"width":"100px"}},[_vm._v(" "+_vm._s(_vm.$t('Part'))+" "),_c('b-badge',{attrs:{"variant":'light-primary'}},[_vm._v(" "+_vm._s(assignedContact.part || _vm.getMainContactPart || 0)+"% ")])],1),_c('div',[_vm._v(" "+_vm._s(_vm.$t('Filled'))+" "),_c('b-badge',{attrs:{"variant":_vm.getFilledFieldsCount[index].total !== _vm.getFilledFieldsCount[index].filled ? 'light-danger' : 'light-success'}},[_vm._v(" "+_vm._s(_vm.getFilledFieldsCount[index].filled)+" / "+_vm._s(_vm.getFilledFieldsCount[index].total)+" ")])],1)])]},proxy:true}],null,true)},[_c('validation-observer',{ref:("contacts_validator_" + (assignedContact.contact.id)),refInFor:true},[_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('ContactPartInAgreement'),"rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('ContactPartInAgreement')}},[_c('b-input-group',{staticClass:"input-group-merge",attrs:{"append":"%"}},[(index !== 0)?_c('b-form-input',{attrs:{"id":"basicInput"},model:{value:(assignedContact.part),callback:function ($$v) {_vm.$set(assignedContact, "part", $$v)},expression:"assignedContact.part"}}):_c('b-form-input',{attrs:{"id":"basicInput","value":_vm.getMainContactPart,"disabled":""}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"sm":"12","md":"6","lg":"4"}},[_c('validation-provider',{attrs:{"name":_vm.$t('contact.name'),"rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('contact.name')}},[_c('b-form-input',{attrs:{"id":"basicInput"},model:{value:(assignedContact.contact.firstName),callback:function ($$v) {_vm.$set(assignedContact.contact, "firstName", $$v)},expression:"assignedContact.contact.firstName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"sm":"12","md":"6","lg":"4"}},[_c('validation-provider',{attrs:{"name":_vm.$t('contact.lastName'),"rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('contact.lastName')}},[_c('b-form-input',{attrs:{"id":"basicInput"},model:{value:(assignedContact.contact.lastName),callback:function ($$v) {_vm.$set(assignedContact.contact, "lastName", $$v)},expression:"assignedContact.contact.lastName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"sm":"12","md":"6","lg":"4"}},[_c('validation-provider',{attrs:{"name":_vm.$t('contact.email'),"rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('contact.email')}},[_c('b-form-input',{attrs:{"id":"basicInput","state":!Boolean(assignedContact.contact && assignedContact.contact.emailExist)},on:{"blur":function($event){return _vm.checkExistedEmailsNew(_vm.thread.contactThreadAssignedContacts)}},model:{value:(assignedContact.contact.email),callback:function ($$v) {_vm.$set(assignedContact.contact, "email", $$v)},expression:"assignedContact.contact.email"}}),(Boolean(assignedContact.contact && assignedContact.contact.emailExist))?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.$t('EmailExist'))+" ")]):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"sm":"12","md":"6","lg":"4"}},[_c('div',{staticClass:"d-flex"},[_c('sw-select',{attrs:{"name":_vm.$t('contact.phone')}},[_c('v-select',{staticClass:"mr-50",staticStyle:{"min-width":"90px"},attrs:{"options":_vm.countryCodes,"label":"dial_code","clearable":false},model:{value:(assignedContact.contact.phone.countryCode),callback:function ($$v) {_vm.$set(assignedContact.contact.phone, "countryCode", $$v)},expression:"assignedContact.contact.phone.countryCode"}})],1),_c('validation-provider',{attrs:{"name":_vm.$t('contact.phone'),"rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",class:[
                    !Boolean(assignedContact.contact && assignedContact.contact.phoneExist) ? 'is-valid' : 'is-invalid'
                  ],attrs:{"id":("contact_phone_" + (assignedContact.id)),"options":_vm.cleaveOptions.number},on:{"input":function($event){return _vm.checkExistedPhones(_vm.thread.contactThreadAssignedContacts)}},model:{value:(assignedContact.contact.phone.value),callback:function ($$v) {_vm.$set(assignedContact.contact.phone, "value", $$v)},expression:"assignedContact.contact.phone.value"}}),(Boolean(assignedContact.contact && assignedContact.contact.phoneExist))?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.$t('PhoneExist'))+" ")]):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('b-col',{attrs:{"sm":"12","md":"6","lg":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('contact.birthDate')}},[_c('flat-pickr',{staticClass:"mr-50 mt-25 form-control",attrs:{"id":"releaseDate","value":_vm.getBirthDate(assignedContact.contact),"config":{ locale: _vm.getCalendarLocale(_vm.$i18n.locale) }},on:{"input":function($event){return _vm.changeField(assignedContact.contact, 'birthDate', $event)}}})],1)],1),_c('b-col',{attrs:{"sm":"12","md":"6","lg":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('contact.familyName')}},[_c('b-form-input',{attrs:{"id":"basicInput"},model:{value:(assignedContact.contact.familyName),callback:function ($$v) {_vm.$set(assignedContact.contact, "familyName", $$v)},expression:"assignedContact.contact.familyName"}})],1)],1),_c('b-col',{attrs:{"sm":"12","md":"6","lg":"4"}},[_c('sw-select',{attrs:{"name":_vm.$t('contact.maritalStatus')}},[_c('v-select',{attrs:{"options":_vm.civilStatus},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
                  var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(("contact." + label)))+" ")]}},{key:"option",fn:function(ref){
                  var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(("contact." + label)))+" ")]}}],null,true),model:{value:(assignedContact.contact.maritalStatus),callback:function ($$v) {_vm.$set(assignedContact.contact, "maritalStatus", $$v)},expression:"assignedContact.contact.maritalStatus"}})],1)],1),_c('b-col',{attrs:{"sm":"12","md":"6","lg":"4"}},[_c('sw-select',{attrs:{"name":_vm.$t('contact.financialPosition')}},[_c('v-select',{attrs:{"options":_vm.propertyStatus},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
                  var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(("contact." + label)))+" ")]}},{key:"option",fn:function(ref){
                  var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(("contact." + label)))+" ")]}}],null,true),model:{value:(assignedContact.contact.financialPosition),callback:function ($$v) {_vm.$set(assignedContact.contact, "financialPosition", $$v)},expression:"assignedContact.contact.financialPosition"}})],1)],1),(assignedContact.contact.isOrganization)?_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-items-center flex-wrap",staticStyle:{"max-width":"500px"}},[_c('div',{staticClass:"d-flex align-items-center mb-25"},[_vm._v(" "+_vm._s(_vm.$t('Organization'))+" "),_c('b-button',{staticClass:"ml-50",attrs:{"variant":"success","size":"sm"},on:{"click":function($event){return _vm.addOrganization(false, assignedContact.contact)}}},[_vm._v(" "+_vm._s(_vm.$t('Add'))+" ")]),(assignedContact.contact.organization)?_c('b-button',{staticClass:"ml-50",attrs:{"variant":"warning","size":"sm"},on:{"click":function($event){return _vm.addOrganization(true, assignedContact.contact)}}},[_vm._v(" "+_vm._s(_vm.$t('Edit'))+" ")]):_vm._e()],1),_c('sw-select',[_c('v-select',{staticClass:"w-100",attrs:{"options":_vm.organizations,"label":"name","filterable":false},on:{"search":_vm.reloadOrganizations,"input":function($event){return _vm.changeOrganization($event, assignedContact.contact)}},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
                  var search = ref.search;
return [(search.length)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('NoData'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('TypeToSearch'))+" ")])]}}],null,true),model:{value:(assignedContact.contact.organization),callback:function ($$v) {_vm.$set(assignedContact.contact, "organization", $$v)},expression:"assignedContact.contact.organization"}})],1)],1)]):_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":_vm.$t('Country'),"rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Country')}},[_c('b-form-input',{attrs:{"id":"basicInput","placeholder":_vm.$t('Country')},model:{value:(assignedContact.contact.addressCountry),callback:function ($$v) {_vm.$set(assignedContact.contact, "addressCountry", $$v)},expression:"assignedContact.contact.addressCountry"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',[_c('validation-provider',{attrs:{"name":_vm.$t('City'),"rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('City')}},[_c('b-form-input',{attrs:{"id":"basicInput","placeholder":_vm.$t('City')},model:{value:(assignedContact.contact.addressCity),callback:function ($$v) {_vm.$set(assignedContact.contact, "addressCity", $$v)},expression:"assignedContact.contact.addressCity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',[_c('validation-provider',{attrs:{"name":_vm.$t('contact.postalCode'),"rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('contact.postalCode')}},[_c('b-form-input',{attrs:{"id":"basicInput","placeholder":_vm.$t('contact.postalCode')},model:{value:(assignedContact.contact.addressPostalCode),callback:function ($$v) {_vm.$set(assignedContact.contact, "addressPostalCode", $$v)},expression:"assignedContact.contact.addressPostalCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":_vm.$t('Street'),"rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Street')}},[_c('b-form-input',{attrs:{"id":"basicInput","placeholder":_vm.$t('Street')},model:{value:(assignedContact.contact.addressStreet),callback:function ($$v) {_vm.$set(assignedContact.contact, "addressStreet", $$v)},expression:"assignedContact.contact.addressStreet"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',[_c('validation-provider',{attrs:{"name":_vm.$t('BuildingNumber'),"rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('BuildingNumber')}},[_c('b-form-input',{attrs:{"id":"basicInput","placeholder":_vm.$t('BuildingNumber')},model:{value:(assignedContact.contact.addressNumber),callback:function ($$v) {_vm.$set(assignedContact.contact, "addressNumber", $$v)},expression:"assignedContact.contact.addressNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',[_c('validation-provider',{attrs:{"name":_vm.$t('Pesel'),"rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Pesel')}},[_c('b-form-input',{attrs:{"id":"basicInput","type":"number"},model:{value:(assignedContact.contact.personalId),callback:function ($$v) {_vm.$set(assignedContact.contact, "personalId", $$v)},expression:"assignedContact.contact.personalId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('div',[_vm._v(_vm._s(_vm.$t('contact.PersonIdentityDocuments'))+" "),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"ml-50",attrs:{"icon":"AlertCircleIcon","title":_vm.$t('OnlyFirstDocument')}})],1),(assignedContact.contact.personIdentityDocuments)?_c('div',_vm._l((assignedContact.contact.personIdentityDocuments),function(doc,documentIndex){return _c('div',{key:("doc_" + index + "_" + documentIndex),staticClass:"d-flex flex-lg-nowrap flex-wrap mt-25"},[_c('validation-provider',{attrs:{"name":_vm.$t('task.Type'),"rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',{staticClass:"mr-50 mt-25",staticStyle:{"min-width":"200px"},attrs:{"options":_vm.documentTypes,"placeholder":_vm.$t('task.Type')},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
                  var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(("contact." + label)))+" ")]}},{key:"option",fn:function(ref){
                  var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(("contact." + label)))+" ")]}}],null,true),model:{value:(doc.type),callback:function ($$v) {_vm.$set(doc, "type", $$v)},expression:"doc.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{staticClass:"mr-50",attrs:{"name":' ',"rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mr-50 mt-25",attrs:{"id":"basicInput","placeholder":_vm.$t('OrganizationDetails.addressNumber')},model:{value:(doc.number),callback:function ($$v) {_vm.$set(doc, "number", $$v)},expression:"doc.number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{staticClass:"mr-50",attrs:{"name":_vm.$t('contact.authorityIssuing'),"rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mr-50 mt-25",attrs:{"id":"basicInput","placeholder":_vm.$t('contact.authorityIssuing')},model:{value:(doc.authorityIssuing),callback:function ($$v) {_vm.$set(doc, "authorityIssuing", $$v)},expression:"doc.authorityIssuing"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('b-form-group',{staticClass:"mr-50"},[_c('flat-pickr',{staticClass:"mr-50 mt-25 form-control",attrs:{"id":"releaseDate","value":typeof doc.releaseDate === 'object' ? (doc.releaseDate && doc.releaseDate.date ? doc.releaseDate.date : '') : doc.releaseDate,"config":{ locale: _vm.getCalendarLocale(_vm.$i18n.locale) },"placeholder":_vm.$t('contact.releaseDate')},on:{"input":function($event){return _vm.changeField(doc, 'releaseDate', $event)}}})],1),_c('b-form-group',{staticClass:"mr-50"},[_c('flat-pickr',{staticClass:"mt-25 form-control",attrs:{"id":"expirationDate","value":typeof doc.expirationDate === 'object' ? (doc.expirationDate && doc.expirationDate.date ? doc.expirationDate.date : '') : doc.expirationDate,"config":{ locale: _vm.getCalendarLocale(_vm.$i18n.locale) },"placeholder":_vm.$t('contact.expirationDate')},on:{"input":function($event){return _vm.changeField(doc, 'expirationDate', $event)}}})],1),_c('b-button',{staticClass:"mr-50 mt-25",attrs:{"variant":"flat-danger"},on:{"click":function($event){return _vm.$delete(assignedContact.contact.personIdentityDocuments, index)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}})],1)],1)}),0):_vm._e(),_c('b-button',{staticClass:"mt-50",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return assignedContact.contact.personIdentityDocuments.push({ type: '', number: '', authorityIssuing: '', releaseDate: '', expirationDate: ''})}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_vm._v(" "+_vm._s(_vm.$t('Add'))+" ")],1)],1)],1)],1)],1)}),1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }