<template>
  <div>
    <!-- eslint-disable -->
    <sw-table
      table-id="559b8bea1ca44087a7c7a55608b83d10"
      show-columns
      show-search
      :fields.async="pointsFields"
      :pagination="pagination"
      @change-pagination="Object.assign(pagination, $event)"
      @change-search-query="search = $event"
      @reload-content="loadData"
      @set-fields="pointsFields = $event"
    >
      <template #table>
        <!--      Table      -->
        <b-table
          :items="points"
          :fields="fields"
          striped
          responsive
          :busy="loading"
          show-empty
          :sort-by.sync="sorter.sortBy"
          :no-local-sorting="true"
          :sort-desc.sync="sorter.sortDesc"
          @sort-changed="Object.assign(sorter, $event); loadData()"
        >
          <!--    Head    -->
          <template #head()="{ label }">
            {{ $t(`${label}`) }}
          </template>
            <template #cell(description)="{ value }">
                <span v-if="value" v-b-tooltip :title="value">
                    <span class="text-primary mr-25">
                        <feather-icon icon="EyeIcon" />
                    </span>
                    {{ value | truncate(100) }}
                </span>
                <feather-icon
                        v-else
                        icon="MinusIcon"
                />
            </template>
            <template #cell(id)="{ index }">
                #{{ getOrderNo(index, pagination, sorter) }}
            </template>
          <!--    Status    -->
          <template #cell(status)="{ value }">
            <b-badge :variant="value !== 'ORDER_CANCELLED' ? 'light-primary' : 'light-danger'">
              {{ $t(`LoyaltyPointsStatuses.${value}`) }}
            </b-badge>
          </template>          <!--    Assigned User    -->
            <template #cell(orderId)="{ item }">
                <b-badge variant="light-primary" v-if="item.contactThreadCartBox && item.contactThreadCartBox.contactThreadCartOrder">
                    {{ item.contactThreadCartBox.contactThreadCartOrder.number }}
                </b-badge>
                <feather-icon
                  v-else
                  icon="MinusIcon"
                />
            </template>

            <template #cell(contact)="{ item }">
            <span v-if="item.contactThreadCartBox && item.contactThreadCartBox.contactThread">
              <router-link :to="`/contact/${item.contactThreadCartBox.contactThread.contact.id}`">
                {{ item.contactThreadCartBox.contactThread.contact.firstName }} {{ item.contactThreadCartBox.contactThread.contact.lastName }}
              </router-link>
            </span>
                <span v-else>
              <feather-icon icon="MinusIcon" />
            </span>
            </template>

          <template #cell(user)="{ item }">
            <avatar
              v-if="item && item.user"
              :user="item.user"
            />
            <feather-icon
              v-else
              icon="MinusIcon"
            />
          </template>

            <template #cell(orderDate)="{ item }">
                <span v-if="item.contactThreadCartBox && item.contactThreadCartBox.contactThreadCartOrder && item.contactThreadCartBox.contactThreadCartOrder.orderDate">{{ formatDate(item.contactThreadCartBox.contactThreadCartOrder.orderDate.date, { month: 'numeric', day: 'numeric', year: 'numeric' }, $i18n.locale) }}</span>
                <feather-icon
                        v-else
                        icon="MinusIcon"
                />
            </template>

          <template #cell(loyaltyPlan)="{ item }">
            <span v-if="item && item.loyaltyPlan">{{ item.loyaltyPlan.name }}</span>
            <feather-icon
              v-else
              icon="MinusIcon"
            />
          </template>
          <template #cell(createdBy)="{ value }">
            <avatar :user="value" />
          </template>
          <!--    Other    -->
            <!--    Other    -->
            <template #cell()="row">
                <table-default-cell
                        :field="row.field"
                        :value="row.value"
                />
            </template>

            <!--      Empty      -->
            <template #empty>
                <empty-content />
            </template>

            <!--      Table Busy      -->
            <template #table-busy>
                <div class="text-center text-danger my-2">
                    <b-spinner
                            class="align-middle"
                            variant="primary"
                    />
                </div>
            </template>
        </b-table>
      </template>
    </sw-table>
  </div>
</template>

<script>
import {
  BTable, VBTooltip,
} from 'bootstrap-vue'
import { formatDate } from '@core/utils/filter'
import axiosIns from '@/libs/axios'
import moment from 'moment/moment'

export default {
  components: {
    BTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    filters: {
      type: Object,
      default: () => {},
    },
    threadId: {
      type: String,
      required: false,
      default: null,
    },
  },
  data: vm => ({
    loading: false,
    sorter: {
      sortBy: 'id',
      sortDesc: true,
    },
    pagination: {
      perPage: 25,
      totalRecords: 0,
      currentPage: 1,
    },
    search: '',

    pointsFields: [
      {
        key: 'id', label: '#', visible: true, sortable: true,
      },
      {
        key: 'orderId', label: 'OrderID', visible: true, requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_AWARDED_POINTS_COLUMN_NUMBER],
      },
      {
        key: 'orderDate', label: 'OrderDate', visible: true, requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_AWARDED_POINTS_COLUMN_NUMBER],
      },
      {
        key: 'user', label: 'User', visible: true, requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_AWARDED_POINTS_COLUMN_USER],
      },
      {
        key: 'contact', label: 'Contact', visible: true, requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_AWARDED_POINTS_COLUMN_CONTACT],
      },
      {
        key: 'status', label: 'task.Status', visible: true, requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_AWARDED_POINTS_COLUMN_STATUS],
      },
      {
        key: 'loyaltyPlan', label: 'LoyaltyPlan', visible: true, requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_AWARDED_POINTS_COLUMN_LOYALTY_PLAN],
      },
      {
        key: 'value', label: 'NumberOfPoints', visible: true, requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_AWARDED_POINTS_COLUMN_NUMBER_OF_POINTS],
      },
      {
        key: 'description', label: 'Description', visible: true, requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_AWARDED_POINTS_COLUMN_DESCRIPTION],
      },
      {
        key: 'createdAt', label: 'CreationTime', visible: true, requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_AWARDED_POINTS_COLUMN_CREATED_AT],
      },
      {
        key: 'createdBy', label: 'CreatedBy', visible: true, requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_AWARDED_POINTS_COLUMN_CREATED_BY],
      },
    ],
    points: [],
    agreementTypes: [],
  }),
  computed: {
    fields() {
      return this.pointsFields.filter(field => field.visible)
        .filter(e => !e?.requiredViewRanks || this.checkRequiredViewPermissions(e.requiredViewRanks))
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.loadData()
      },
    },
  },
  async mounted() {
    await this.loadData()
  },
  methods: {
    formatDate,
    async loadData() {
      this.loading = true
      try {
        const params = {
          fields_load: this.$fields.LOYALTY_PLANS_POINTS,
          orderBy: 'id.desc',
        }

        if (this.pagination) {
          params.page = this.pagination.currentPage
          params.limit = this.pagination.perPage
        }

        if (this.search && this.search.length) {
          const searchString = this.search.replaceAll(' ', '%')
          params.search = `%${searchString}%`
          params.search_fields = 'contactThreadCartBox-contactThreadCartOrder-id,contactThreadCartBox-contactThreadCartOrder-number,user-firstName,user-lastName,user-userId'
        }

        if (this.sorter) {
          const sortDir = this.sorter?.sortDesc ? 'desc' : 'asc'
          const sortBy = this.sorter?.sortBy || 'id'
          params.orderBy = `${sortBy}.${sortDir}`
        }

        if (this.threadId) {
          params['eq_contactThreadCartBox-contactThread-id'] = this.threadId
        }
        if (this.filters?.funnel) {
          params['eq_contactThreadCartBox-contactThread-contactThreadFunnelProcesses-contactThreadFunnelTypePoint-contactThreadFunnelType-id'] = this.getObjectId(this.filters.funnel)
        }
        if (this.filters?.funnelPoint) {
          params['eq_contactThreadCartBox-contactThread-contactThreadFunnelProcesses-contactThreadFunnelTypePoint-id'] = this.getObjectId(this.filters.funnelPoint)
        }
        if (this.filters?.thread) {
          params['eq_contactThreadCartBox-contactThread-id'] = this.getObjectId(this.filters.thread)
        }
        if (this.filters?.contacts?.length) {
          params['eqArr_contactThreadCartBox-contactThread-contact-id'] = JSON.stringify((this.filters.contacts || []).mapKeys('id'))
        }

        if (this.filters?.type) {
          params.eq_status = this.filters.type
        } else {
          params.neqArr_status = JSON.stringify(['ORDER_CANCELLED', 'NOT_REALIZED'])
        }

        if (this.filters?.orderDate) {
          const [startAt, endAt] = this.filters.orderDate.dateRange()
          if (startAt) params['gte_contactThreadCartBox-contactThreadCartOrder-orderDate'] = `${moment(startAt, 'YYYY-MM-DD').format('YYYY-MM-DD')} 00:00:00`
          if (endAt) params['lt_contactThreadCartBox-contactThreadCartOrder-orderDate'] = `${moment(endAt, 'YYYY-MM-DD').format('YYYY-MM-DD')} 23:59:59`
        }

        if (this.filters?.loyaltyPlan) params['eq_loyaltyPlan-id'] = this.getObjectId(this.filters.loyaltyPlan)
        if (this.filters?.assignedUsers?.length) params['eqArr_user-id'] = JSON.stringify(this.filters?.assignedUsers.map(user => this.getObjectId(user)))
        if (this.filters.assignedUsersStructure) params['eqArr_user-parentUser-id'] = JSON.stringify(this.filters?.assignedUsers.map(user => this.getObjectId(user)))

        const resp = await axiosIns.get('1/loyaltyPlans/points', { params })
        this.points = resp.data.data.items ?? []
        this.pagination.totalRecords = resp.data.data.totalItemCount ?? 0
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss">
</style>
