<template>
  <b-row>
    <!--     Filters: Structure     -->
    <b-col
      sm="12"
      md="6"
      :lg="constructionFilter ? lgSize : 6"
    >
      <sw-select>
        <v-select
          v-model="structure"
          :options="groups"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="name"
          :placeholder="$t('ChooseGroup')"
          @option:selected="loadInvestments()"
          @input="clearField('group', $event)"
        >
          <template #no-options>
            {{ $t('NoOptions') }}
          </template>
          <template #option="{ name, color }">
            <div
              class="color-dot"
              style="border: 1px solid white"
              :style="{ backgroundColor: color }"
            />
            {{ name }}
          </template>
          <template #selected-option="{ name, color }">
            <div
              class="color-dot"
              style="border: 1px solid white"
              :style="{ backgroundColor: color }"
            />
            {{ name }}
          </template>
        </v-select>
      </sw-select>
    </b-col>

    <!--     Filters: Investment     -->
    <b-col
      sm="12"
      md="6"
      :lg="constructionFilter ? lgSize : 6"
    >
      <sw-select>
        <v-select
          v-model="investment"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="name"
          :options="investments"
          :placeholder="$t('Offer.SelectInvestment')"
          :disabled="!structure"
          @option:selected="loadBuildings()"
          @input="clearField('investment', $event)"
        >
          <template #no-options>
            {{ $t('Offer.SelectInvestment') }}
          </template>
        </v-select>
      </sw-select>
    </b-col>

    <!--     Filters: Construction     -->
    <b-col
      v-if="constructionFilter"
      sm="12"
      md="6"
      :lg="lgSize"
    >
      <sw-select>
        <v-select
          v-model="building"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :disabled="!structure || !investment"
          label="name"
          :options="buildings"
          :placeholder="$t('Contracts.ChooseConstruction')"
          @input="clearField('building', $event)"
        >
          <template #no-options>
            {{ $t('Contracts.ChooseConstruction') }}
          </template>
        </v-select>
      </sw-select>
    </b-col>
  </b-row>
</template>

<script>
import vSelect from 'vue-select'
import { GET_CONSTRUCTIONS, GET_GROUPS } from '@/@constants/mutations'
import axiosIns from '@/libs/axios'

export default {
  name: 'BuildingFilters',
  components: {
    vSelect,
  },
  props: {
    constructionFilter: {
      type: Boolean,
      default: true,
    },
    lgSize: {
      type: Number,
      default: 3,
    },
  },
  data: () => ({
    structure: '',
    investment: '',
    building: '',

    groups: [],
    investments: [],
    buildings: [],
  }),
  mounted() {
    this.loadGroups()
  },
  methods: {
    reloadContent() {
      const filters = {
        structure: this.getId(this.structure),
        investment: this.getId(this.investment),
        building: this.getId(this.building),
      }
      this.$emit('reloadContent', filters)
    },
    clearField(field, value) {
      if (field === 'group' && !value) {
        this.investment = ''
        this.building = ''
      }

      if (field === 'investment' && !value) {
        this.building = ''
      }

      this.reloadContent()
    },
    loadGroups() {
      this.$store.dispatch(`groups/${GET_GROUPS}`)
        .then(res => {
          this.groups = res.data.items
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err.response.data.message}`))
        })
    },
    loadInvestments() {
      if (!this.structure) {
        this.investment = ''
        return
      }
      const params = {
        fields_load: this.$fields.BASE_NAME,
      }
      params['eq_userGroups-id'] = this.getId(this.structure)
      axiosIns.get('1/investments', { params })
        .then(res => {
          this.investments = res.data.data.items
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err.response.data.message}`))
        })
    },
    loadBuildings() {
      if (!this.investment) {
        this.building = ''
        return
      }
      const payload = {
        customFields: this.$fields.BASE_NAME,
        filters: { investment: this.getId(this.investment) },
      }
      this.$store.dispatch(`constructions/${GET_CONSTRUCTIONS}`, payload)
        .then(res => {
          this.buildings = res.data.items
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err.response.data.message}`))
        })
    },
    getId(field) {
      // eslint-disable-next-line no-nested-ternary
      return typeof field === 'object' ? (field?.id ? field.id : '') : field
    },
  },
}
</script>

<style scoped>

</style>
