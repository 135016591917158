// eslint-disable-next-line import/no-cycle
import axiosIns from '@/libs/axios'
import { PROJECT_TASK_CATEGORIES } from '@/@constants/fields'
import {
  CREATE_PROJECT_TASK_CATEGORY,
  GET_PROJECT_TASK_CATEGORIES,
  GET_PROJECT_TASK_CATEGORY,
  REMOVE_PROJECT_TASK_CATEGORY,
  TEST_PROJECT_TASK_CATEGORY,
  UPDATE_PROJECT_TASK_CATEGORY,
} from '@/@constants/mutations'

export default {
  namespaced: true,
  actions: {
    [GET_PROJECT_TASK_CATEGORIES]: () => new Promise((resolve, reject) => {
      const params = {}
      params.fields_load = PROJECT_TASK_CATEGORIES

      axiosIns.get('1/settings/projectTaskCategories', { params })
        .then(res => resolve(res.data.data.items))
        .catch(err => reject(err))
    }),

    [GET_PROJECT_TASK_CATEGORY]: (_, categoryId) => new Promise((resolve, reject) => {
      const params = {}
      params.fields_load = PROJECT_TASK_CATEGORIES

      axiosIns.get(`1/settings/projectTaskCategories/${categoryId}`, { params })
        .then(res => resolve(res.data.data.item))
        .catch(err => reject(err))
    }),

    [CREATE_PROJECT_TASK_CATEGORY]: (_, payload) => new Promise((resolve, reject) => {
      axiosIns.post('1/settings/projectTaskCategories', payload)
        .then(res => resolve(res.data.data.items[0].id))
        .catch(err => reject(err))
    }),

    [UPDATE_PROJECT_TASK_CATEGORY]: (_, payload) => new Promise((resolve, reject) => {
      axiosIns.patch('1/settings/projectTaskCategories', payload)
        .then(res => resolve(res.data.data.items[0].id))
        .catch(err => reject(err))
    }),

    [REMOVE_PROJECT_TASK_CATEGORY]: (_, id) => new Promise((resolve, reject) => {
      axiosIns.delete('1/settings/projectTaskCategories', { data: [id] })
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    }),

    [TEST_PROJECT_TASK_CATEGORY]: (_, level = 0) => new Promise((resolve, reject) => {
      const params = {}
      params.fields_info = level

      axiosIns.get('1/settings/projectTaskCategories', { params })
        .then(res => resolve(res.data.data))
        .catch(err => reject(err))
    }),
  },
}
