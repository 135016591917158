var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"newDocumentForm",staticClass:"d-flex flex-column",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('Name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Name')}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('Name'),"state":errors.length > 0 ? false:null},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('Offer.Project')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('sw-select',{attrs:{"name":_vm.$t('Offer.Project')}},[_c('v-select',{attrs:{"options":_vm.projectList,"placeholder":_vm.$t('Offer.Project'),"state":errors.length > 0 ? false:null,"filterable":false,"label":"name","reduce":function (item) { return item.id.toString(); },"item-value":"id","item-text":"name"},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('NoOptions'))+" ")]},proxy:true}],null,true),model:{value:(_vm.project),callback:function ($$v) {_vm.project=$$v},expression:"project"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('timeline.Task')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('sw-select',{attrs:{"name":_vm.$t('timeline.Task')}},[_c('v-select',{attrs:{"options":_vm.taskList,"placeholder":_vm.$t('timeline.Task'),"state":errors.length > 0 ? false:null,"filterable":false,"label":"name","reduce":function (item) { return item.id.toString(); },"item-value":"id","item-text":"name"},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('NoOptions'))+" ")]},proxy:true}],null,true),model:{value:(_vm.projectTask),callback:function ($$v) {_vm.projectTask=$$v},expression:"projectTask"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('MicroTask')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('sw-select',{attrs:{"name":_vm.$t('MicroTask')}},[_c('v-select',{attrs:{"options":_vm.microTaskList,"placeholder":_vm.$t('MicroTask'),"state":errors.length > 0 ? false:null,"filterable":false,"label":"name","reduce":function (item) { return item.id.toString(); },"item-value":"id","item-text":"name"},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('NoOptions'))+" ")]},proxy:true}],null,true),model:{value:(_vm.projectTaskMicroTask),callback:function ($$v) {_vm.projectTaskMicroTask=$$v},expression:"projectTaskMicroTask"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('Description')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Description')}},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.description),expression:"description"}],staticClass:"form-control",attrs:{"type":"text","placeholder":_vm.$t('Description'),"state":errors.length > 0 ? false:null},domProps:{"value":(_vm.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.description=$event.target.value}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('Files')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Files')}},[_c('drag-drop-upload',{staticClass:"mt-n1",attrs:{"disabled":_vm.isLoading,"multiple":"","state":errors.length > 0 ? false:null},on:{"onChangeFiles":_vm.changeFiles}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('b-button',{staticClass:"w-100 mt-1",attrs:{"variant":"primary","size":"sm","disabled":invalid || !_vm.files.length},on:{"click":function($event){return _vm.save()}}},[_vm._v(" "+_vm._s(_vm.$t('Add'))+" ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }