import {
  DELETE_ATTRIBUTE, GET_ATTRIBUTE, SAVE_ATTRIBUTE,
} from '@/@constants/mutations'
// eslint-disable-next-line import/no-cycle
import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  actions: {
    // eslint-disable-next-line no-unused-vars
    [GET_ATTRIBUTE]: ({ commit }) => new Promise((resolve, reject) => {
      //  , orderBy: 'position.asc'
      axiosIns.get('1/shops/attributes', { params: { fields_load: 'SHOP_ATTRIBUTES' } })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [DELETE_ATTRIBUTE]: ({ commit }, id) => new Promise((resolve, reject) => {
      axiosIns.delete('1/shops/attributes', { data: [id] })
        .then(() => {
          resolve()
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [SAVE_ATTRIBUTE]: ({ commit }, attributes) => new Promise((resolve, reject) => {
      axiosIns.put('1/shops/attributes', attributes)
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
  },
}
