<template>
  <component
    :is="isGlobalRoute || isNewContact ? 'div' : 'app-collapse-item'"
    id="application-container"
    key="applications"
    is-template
    :title="$t('Applications')"
    class="app-aplications"
    show-collapse
    :is-title="false"
  >
    <template
      v-if="!isGlobalRoute"
      #subTitle
    >
      <div
        class="font-weight-bold d-flex flex-wrap align-items-center"
        style="gap: .5rem"
      >
        {{ $t('Applications') }}

        <b-badge variant="light-primary">
          <span v-if="loading">
            <b-spinner small />
          </span>
          <span v-else>{{ pagination.totalRecords }}</span>
        </b-badge>
      </div>
    </template>

    <!--    Section: Filters    -->
    <sw-filters
      v-if="isGlobalRoute"
      :shorts="[
        {
          title: $t('task.Type'),
          value: () => filters.type
            ? $t(types.find(t => t.key === filters.type).label) +
              (types.find(t => t.key === filters.type)
                ? (types.find(t => t.key === filters.type).prefix ? (` - ${$t(types.find(t => t.key === filters.type).prefix)}`) : '')
                : '')
            : '',
          clear: () => { filters.type = ''; loadData() }
        },
        {
          title: $t('ContactSource'),
          value: () => {
            let toReturn = ''

            if (filters.source) {
              const element = filtredSources.find(f => f.id === filters.source)

              if (filters.type === 'FACEBOOK') return `${$t('Connection')} - ${element.name}`
              if (filters.type === 'LEAD_GENERATOR_WIDGET') return `${$t('Widget')} - ${element.name}`
              if (filters.type === 'LEAD_GENERATOR_WHATSAPP') return `${$t('Whatsapp')} - ${element.name}`
              if (filters.type === 'LEAD_GENERATOR_MOBILE') return `${$t('MobileClients')} - ${element.name}`
              if (filters.type === 'ZAPIER') return `${element.name}`
              if (filters.type === 'MAKE') return `${element.name}`

              const name = element.name
              const type = types.find(({key}) => key === element.type).label

              toReturn = `${type} – ${name}`
            }

            return toReturn
          },
          clear: () => { filters.source = ''; loadData() }
        },
        {
          title: $t('task.Type'),
          value: () => filters.status ? $t(applicationStatuses.find(t => t.key === filters.status).label) : '',
          clear: () => { filters.status = ''; loadData() }
        },
        {
          title: $t('DateReceipt'),
          value: () => filters.createdAt,
          clear: () => { filters.createdAt = ''; loadData() }
        },
      ]"
      :res="[
        { title: $t('TotalNumberSubmissions'), value: () => getStats.totalNumberSubmissions },
        { title: $t('NewSubmissions'), value: () => getStats.newSubmissions },
        { title: $t('RejectedSubmissions'), value: () => getStats.rejectedSubmissions },
        { title: $t('SubmissionsTaken'), value: () => getStats.submissionsTaken },
      ]"
    >
      <b-row>
        <b-col
          sm="12"
          md="6"
          lg="4"
        >
          <sw-select :name="$t('task.Type')">
            <v-select
              v-model="filters.type"
              :options="filteredTypes"
              :reduce="i => i.key"
            >
              <template #option="{ label, prefix }">
                <span>{{ $t(label) }}<span v-if="prefix"> - {{ $t(prefix) }}</span></span>
              </template>
              <template #selected-option="{ label, prefix }">
                <span>{{ $t(label) }}<span v-if="prefix"> - {{ $t(prefix) }}</span></span>
              </template>
              <template #no-options>
                {{ $t('NoOptions') }}
              </template>
            </v-select>
          </sw-select>
        </b-col>

        <!--    Filter by Source    -->
        <b-col
          sm="12"
          md="6"
          lg="4"
        >
          <sw-select
            :name="$t('ContactSource')"
            :disabled="!filters.type"
          >
            <v-select
              v-model="filters.source"
              :options="filtredSources"
              :disabled="!filters.type || filters.type === 'LEAD_GENERATOR_MOBILE'"
              :reduce="i => i.id"
            >
              <template #option="{ name, type }">
                <span v-if="filters.type === 'FACEBOOK'">{{ $t('Connection') }} - {{ name }}</span>
                <span v-else-if="filters.type === 'LEAD_GENERATOR_WIDGET'">{{ $t('Widget') }} - {{ name }}</span>
                <span v-else-if="filters.type === 'LEAD_GENERATOR_WHATSAPP'">{{ $t('Whatsapp') }} - {{ name }}</span>
                <span v-else-if="filters.type === 'LEAD_GENERATOR_MOBILE'">{{ $t('MobileClients') }} - {{ name }}</span>
                <span v-else-if="filters.type === 'MAKE'">{{ name }}</span>
                <span v-else-if="filters.type === 'ZAPIER'">{{ name }}</span>
                <span
                  v-else
                  class="d-flex"
                  style="gap: .24rem"
                >
                  <span>{{ types.find(({key}) => key === type).label }}</span>
                  <span>–</span>
                  <span>{{ name }}</span>
                </span>
              </template>
              <template #selected-option="{ name, type }">
                <span v-if="filters.type === 'FACEBOOK'">{{ $t('Connection') }} - {{ name }}</span>
                <span v-else-if="filters.type === 'LEAD_GENERATOR_WIDGET'">{{ $t('Widget') }} - {{ name }}</span>
                <span v-else-if="filters.type === 'LEAD_GENERATOR_WHATSAPP'">{{ $t('Whatsapp') }} - {{ name }}</span>
                <span v-else-if="filters.type === 'LEAD_GENERATOR_MOBILE'">{{ $t('MobileClients') }} - {{ name }}</span>
                <span v-else-if="filters.type === 'MAKE'">{{ name }}</span>
                <span v-else-if="filters.type === 'ZAPIER'">{{ name }}</span>
                <span
                  v-else
                  class="d-flex"
                  style="gap: .24rem"
                >
                  <span>{{ types.find(({key}) => key === type).label }}</span>
                  <span>–</span>
                  <span>{{ name }}</span>
                </span>
              </template>
              <template #no-options>
                {{ $t('NoOptions') }}
              </template>
            </v-select>
          </sw-select>
        </b-col>

        <!--    Filter by Assigned User    -->
        <b-col
          sm="12"
          md="6"
          lg="4"
        >
          <sw-select :name="$t('task.Status')">
            <v-select
              v-model="filters.status"
              :options="applicationStatuses"
              :reduce="i => i.key"
            >
              <template #option="{ label }">
                {{ $t(`${label}`) }}
              </template>
              <template #selected-option="{ label }">
                {{ $t(`${label}`) }}
              </template>
              <template #no-options>
                {{ $t('NoOptions') }}
              </template>
            </v-select>
          </sw-select>
        </b-col>

        <!--    Filter by Created    -->
        <b-col
          sm="12"
          md="6"
          lg="4"
        >
          <sw-select :name="$t('DateReceipt')">
            <sw-select-fpr
              :is-empty="filters.createdAt"
              @clear="filters.createdAt = ''"
            >
              <flat-pickr
                v-model="filters.createdAt"
                class="form-control"
                style="min-width: 17rem"
                :config="{ mode: 'range', dateFormat: 'd-m-Y H:i', enableTime: true, time_24hr: true, locale: getCalendarLocale($i18n.locale) }"
              />
            </sw-select-fpr>
          </sw-select>
        </b-col>
      </b-row>
    </sw-filters>

    <b-row
      v-if="!contact"
      class="mb-50"
    >
      <b-col
        md="6"
        lg="3"
        xl="3"
      >
        <b-alert
          show
          variant="primary"
          class="px-50 py-1 d-flex align-items-center justify-content-start"
        >
          <!--          <b-avatar variant="primary">-->
          <!--            <feather-icon icon="HelpCircleIcon" />-->
          <!--          </b-avatar>-->

          <div class="d-flex flex-column ml-75">
            <h4 class="mb-0 text-primary">
              {{ getStats.totalNumberSubmissions || 0 }}
            </h4>

            <p class="text-dark">
              {{ $t('TotalNumberSubmissions') }}
            </p>
          </div>
        </b-alert>
      </b-col>

      <b-col
        md="6"
        lg="3"
        xl="3"
      >
        <b-alert
          show
          variant="primary"
          class="px-50 py-1 d-flex align-items-center justify-content-start"
        >
          <!--          <b-avatar variant="primary">-->
          <!--            <feather-icon icon="HelpCircleIcon" />-->
          <!--          </b-avatar>-->

          <div class="d-flex flex-column ml-75">
            <h4 class="mb-0 text-primary">
              {{ getStats.newSubmissions || 0 }}
            </h4>

            <p class="text-dark">
              {{ $t('NewSubmissions') }}
            </p>
          </div>
        </b-alert>
      </b-col>

      <b-col
        md="6"
        lg="3"
        xl="3"
      >
        <b-alert
          show
          variant="primary"
          class="px-50 py-1 d-flex align-items-center justify-content-start"
        >
          <!--          <b-avatar variant="primary">-->
          <!--            <feather-icon icon="HelpCircleIcon" />-->
          <!--          </b-avatar>-->

          <div class="d-flex flex-column ml-75">
            <h4 class="mb-0 text-primary">
              {{ getStats.rejectedSubmissions || 0 }}
            </h4>

            <p class="text-dark">
              {{ $t('RejectedSubmissions') }}
            </p>
          </div>
        </b-alert>
      </b-col>

      <b-col
        md="6"
        lg="3"
        xl="3"
      >
        <b-alert
          show
          variant="primary"
          class="px-50 py-1 d-flex align-items-center justify-content-start"
        >
          <!--          <b-avatar variant="primary">-->
          <!--            <feather-icon icon="HelpCircleIcon" />-->
          <!--          </b-avatar>-->

          <div class="d-flex flex-column ml-75">
            <h4 class="mb-0 text-primary">
              {{ getStats.submissionsTaken || 0 }}
            </h4>

            <p class="text-dark">
              {{ $t('SubmissionsTaken') }}
            </p>
          </div>
        </b-alert>
      </b-col>
    </b-row>

    <sw-table
      table-id="7452427b6c2e4a6dbadb4a47f03c0cb4"
      :pagination="pagination"
      @change-pagination="Object.assign(pagination, $event)"
      @reload-content="loadData"
    >
      <template #empty>
        <div
          v-if="!loading && items.length === 0"
          class="p-2 text-center text-primary"
        >
          <feather-icon icon="XCircleIcon" />
          {{ $t('NoData') }}
        </div>
      </template>
      <template #loading>
        <div
          v-if="loading"
          class="text-center text-danger my-2"
        >
          <b-spinner
            class="align-middle"
            variant="primary"
          />
        </div>
      </template>
      <template #table>
        <div
          v-if="!isMobile"
          class="d-flex justify-content-between px-1 py-1 mt-0 position-top-0"
          style="top: 0;"
          :class="isGlobalRoute ? 'application-sticker' : ''"
        >
          <h5
            style="width: 175px"
            class="flex-grow-1"
          >
            {{ $t('Action') }}
          </h5>
          <h5
            style="width: 200px"
            class="flex-grow-1"
          >
            {{ $t('ApplicationType') }}
          </h5>
          <h5
            style="width: 250px"
            class="flex-grow-1"
          >
            {{ $t('BasicInformation') }}
          </h5>
          <h5
            style="width: 400px"
            class="flex-grow-1"
          >
            {{ $t('SuggestedMaintainer') }}
          </h5>
        </div>

        <b-row>
          <!--          <applications-card-->
          <!--            v-if="initApplication"-->
          <!--            :application="initApplication"-->
          <!--            :is-phone-exists="initApplication.phoneNumber ? existedPhone(application.phoneNumber.phone) : null"-->
          <!--            :is-mail-exists="initApplication.mailAddress ? existedMail(application.mailAddress.mail) : null"-->
          <!--            @accept-application="selectApplication($event, index)"-->
          <!--            @reject-application="reject(application, index)"-->
          <!--            @delete-application="deleteApplication(application, index)"-->
          <!--          />-->

          <applications-card
            v-for="(application, index) in items"
            :key="`application_${index}`"
            :application="application"
            :is-phone-exists="application.phoneNumber ? existedPhone(application.phoneNumber.phone) : null"
            :is-mail-exists="application.mailAddress ? existedMail(application.mailAddress.mail) : null"
            @accept-application="selectApplication($event, index)"
            @reject-application="reject(application, index)"
            @delete-application="deleteApplication(application, index)"
          />
        </b-row>
      </template>
    </sw-table>

    <!-- Accept Application Modal -->
    <b-modal
      :visible="!!selectedApplications"
      :title="$t('Accept')"
      hide-footer
      size="xl"
      no-close-on-backdrop
      modal-class="pr-0"
      @hide="closeModal"
      @hidden="closeModal"
    >
      <div class="divider text-primary">
        <div class="divider-text">
          {{ $t('Contact') }}
        </div>
      </div>
      <b-form-group class="mt-n2">
        <b-form-radio-group
          v-model="contactOption"
          :options="[
            { text: $t('New'), value: 'new' },
            { text: $t('Existed'), value: 'existed' }
          ]"
          class="demo-inline-spacing"
          name="radio-inline"
        />
      </b-form-group>
      <!-- CONTACT -->
      <div v-if="contactOption === 'new'">
        <b-row v-if="!$route.name.includes('organization')">
          <b-col sm="6">
            <b-card :class="{ 'border-primary': !isCompany }"
                    style="height: 60px"
                    no-body
                    class="border cursor-pointer"
                    @click="isCompany = false"
            >
              <div class="d-flex justify-content-between px-1 align-items-center h-100">
                <div class="d-flex align-items-center">
                  <b-form-radio v-model="isCompany"
                                :value="false"
                  />
                  <sw-icon icon="UserIcon"
                           class="mr-25"
                  />
                  {{ $t('Person') }}
                </div>
              </div>
            </b-card>

          </b-col>
          <b-col sm="6">
            <b-card :class="{ 'border-primary': isCompany }"
                    style="height: 60px"
                    no-body
                    class="border cursor-pointer"
                    @click="isCompany = true"
            >
              <div class="d-flex justify-content-between px-1 align-items-center h-100">
                <div class="d-flex align-items-center">
                  <b-form-radio v-model="isCompany"
                                :value="true"
                  />
                  <sw-icon icon="GlobeIcon"
                           class="mr-25"
                  />
                  {{ $t('Company') }}
                </div>

                <div class="d-flex align-items-center">
                  <sw-select class="mb-0 pb-0">
                    <v-select
                      v-model="newContact.organization"
                      :options="organizations"
                      :placeholder="$t('Select')"
                      label="name"
                      class="mb-0 pb-0 select-size-sm mr-25"
                      style="width: 250px"
                      :filterable="false"
                      @search="reloadOrganizations"
                      @input="changeOrganization"
                    >
                      <template #no-options="{ search }">
                        <span v-if="search.length">
                          {{ $t('NoData') }}
                        </span>
                        <span v-else>
                          {{ $t('TypeToSearch') }}
                        </span>
                      </template>
                    </v-select>
                  </sw-select>
                  <b-button
                    variant="primary"
                    size="sm"
                    @click="addOrganization"
                  >
                    <sw-icon icon="PlusIcon" />
                  </b-button>
                </div>
              </div>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group :label="$t('FirstName')">
              <b-form-input
                v-model="newContact.firstName"
                type="text"
                size="sm"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group :label="$t('LastName')">
              <b-form-input
                v-model="newContact.lastName"
                type="text"
                size="sm"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group :label="$t('contact.email')">
              <b-input-group size="sm">
                <b-form-input
                  v-model.trim="newContact.email"
                  type="email"
                  @blur="checkExistedEmails"
                />
                <b-input-group-append v-if="existedMail(newContact.email)">
                  <!-- Email Exist Buttons -->
                  <b-button
                    v-if="existedMail(newContact.email)"
                    :id="`email_exist_button`"
                    variant="outline-warning"
                  >
                    <feather-icon
                      icon="AlertCircleIcon"
                      size="12"
                    />
                    <b-popover
                      :target="`email_exist_button`"
                      triggers="hover"
                    >
                      <template #title>
                        <span>{{ $t('TypedEmailExist') }}</span>
                      </template>
                      <div
                        v-for="existedContact in existedMail(newContact.email).contacts"
                        :key="`contact_email_${existedContact.id}`"
                      >
                        <router-link :to="{ name: 'contact', params: { contactId: existedContact.id } }">
                          {{ existedContact.firstName }} {{ existedContact.lastName }}
                        </router-link>
                      </div>
                      <!--                        test@mail-saleswizardapp.com-->
                    </b-popover>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group :label="$t('Phone')">
              <b-input-group>
                <div class="d-flex w-100 align-items-center">
                  <sw-select :style="'max-width: 6rem'"
                             class="mb-0"
                  >
                    <v-select
                      v-model="countryCode"
                      :options="countryCodes"
                      label="dial_code"
                      class="mr-50 select-size-sm rounded"
                      :clearable="false"
                      @option:selected="checkExistedPhones"
                    />
                  </sw-select>

                  <cleave
                    v-model="newContact.phone"
                    :options="cleaveOptions.number"
                    class="form-control form-control-sm w-100"
                    size="sm"
                    style="border-bottom-right-radius: 0 !important; border-top-right-radius: 0 !important"
                    @blur="checkExistedPhones"
                  />

                  <b-input-group-append v-if="existedPhone(newContact.phone, 'to 1')"
                                        class="flex-grow-1"
                                        style="border-bottom-left-radius: 0 !important; border-top-left-radius: 0 !important; padding-top: 8px; padding-bottom: 8px"
                  >
                    <!-- Email Exist Buttons -->
                    <b-button
                      v-if="existedPhone(newContact.phone, 'to')"
                      :id="`phone_exist_button`"
                      variant="outline-warning"
                      style="border-bottom-left-radius: 0 !important; border-top-left-radius: 0 !important; padding-top: 8px; padding-bottom: 8px"
                      size="sm"
                    >
                      <feather-icon
                        icon="AlertCircleIcon"
                        size="12"
                      />
                      <b-popover
                        :target="`phone_exist_button`"
                        triggers="hover"
                      >
                        <template #title>
                          <span>{{ $t('TypedPhoneExist') }}</span>
                        </template>
                        <div
                          v-for="existedContact in existedPhone(newContact.phone, 'ha?').contacts"
                          :key="`contact_phone_${existedContact.id}`"
                        >
                          <router-link :to="{ name: 'contact', params: { contactId: existedContact.id } }">
                            {{ existedContact.firstName }} {{ existedContact.lastName }}
                          </router-link>
                        </div>
                        <!--                        test@mail-saleswizardapp.com-->
                      </b-popover>
                    </b-button>
                  </b-input-group-append>
                </div>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col sm="12"
                 md="6"
          >
            <sw-select :name="$t('contact.contactStatus')">
              <v-select
                v-model="newContact.status"
                :options="statuses"
                label="name"
                class="select-size-sm"
                :reduce="status => status.id"
              >
                <template #no-options>
                  {{ $t('NoOptions') }}
                </template>
                <template #option="{ name, color }">
                  <div
                    class="color-dot"
                    :style="{ backgroundColor: color }"
                  />
                  {{ name }}
                </template>
                <template #selected-option="{ name, color }">
                  <div
                    class="color-dot"
                    :style="{ backgroundColor: color }"
                  />
                  {{ name }}
                </template>
              </v-select>
            </sw-select>
          </b-col>

          <b-col sm="12"
                 md="6"
          >
            <sw-select :name="$t('contact.contactTypes')">
              <v-select
                v-model="newContact.contactTypes"
                multiple
                class="select-size-sm"
                :options="tags"
                label="name"
              >
                <template #no-options>
                  {{ $t('NoOptions') }}
                </template>
                <template #option="{ name, color }">
                  <div
                    class="color-dot"
                    style="border: 1px solid white"
                    :style="{ backgroundColor: color }"
                  />
                  {{ name }}
                </template>
                <template #selected-option="{ name, color }">
                  <div
                    class="color-dot"
                    style="border: 1px solid white"
                    :style="{ backgroundColor: color }"
                  />
                  {{ name }}
                </template>
              </v-select>
            </sw-select>
          </b-col>
        </b-row>
      </div>
      <div v-else-if="contactOption === 'existed'">
        <sw-select>
          <v-select
            v-model="selectedContact"
            item-value="id"
            :options="contacts"
            label="firstName"
            class="select-size-sm"
            @input="selectContact"
            @search="onSearch"
          >
            <template slot="no-options">
              {{ $t('typeToSearchContact') }}
            </template>
            <template #option="{ id, firstName, lastName }">
              <avatar :user="{id, firstName, lastName}" />
            </template>

            <template #selected-option="{ id, firstName, lastName }">
              <avatar :user="{id, firstName, lastName}" />
            </template>
          </v-select>
        </sw-select>

        <b-row class="mt-50">
          <b-col
            md="12"
          >
            <b-alert
              show
              class="p-25"
              variant="primary"
            >
              <div class="alert-body p-25">
                {{ $t('EmailAndSmsAreAdditionalFields') }}
              </div>
            </b-alert>
          </b-col>
          <b-col md="6">
            <b-form-group :label="$t('contact.email')">
              <b-input-group size="sm">
                <b-form-input
                  v-model.trim="newContact.email"
                  type="email"
                  @blur="checkExistedEmails"
                />
                <b-input-group-append v-if="existedMail(newContact.email)">
                  <!-- Email Exist Buttons -->
                  <b-button
                    v-if="existedMail(newContact.email)"
                    :id="`email_exist_button`"
                    variant="outline-warning"
                  >
                    <feather-icon
                      icon="AlertCircleIcon"
                      size="12"
                    />
                    <b-popover
                      :target="`email_exist_button`"
                      triggers="hover"
                    >
                      <template #title>
                        <span>{{ $t('TypedEmailExist') }}</span>
                      </template>
                      <div
                        v-for="existedContact in existedMail(newContact.email).contacts"
                        :key="`contact_email_${existedContact.id}`"
                      >
                        <router-link :to="{ name: 'contact', params: { contactId: existedContact.id } }">
                          {{ existedContact.firstName }} {{ existedContact.lastName }}
                        </router-link>
                      </div>
                      <!--                        test@mail-saleswizardapp.com-->
                    </b-popover>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <!--            <b-form-group :label="$t('Phone')"-->
            <!--                          class="w-100"-->
            <!--            >-->
            <!--              <b-input-group class="w-100">-->
            <!--                <div class="d-flex w-100">-->
            <!--                  <sw-select :style="'max-width: 6rem'"-->
            <!--                             class="mb-0 flex-grow-1"-->
            <!--                             classes="mb-0"-->
            <!--                  >-->
            <!--                    <v-select-->
            <!--                      v-model="countryCode"-->
            <!--                      :options="countryCodes"-->
            <!--                      label="dial_code"-->
            <!--                      class="mr-50 select-size-sm mb-0"-->
            <!--                      :clearable="false"-->
            <!--                      @option:selected="checkExistedPhones"-->
            <!--                    />-->
            <!--                  </sw-select>-->
            <!--                  <div class="d-flex w-100 flex-grow-1">-->
            <!--                    <cleave-->
            <!--                      v-model="newContact.phone"-->
            <!--                      :options="cleaveOptions.number"-->
            <!--                      class="form-control form-control-sm flex-grow-1"-->
            <!--                      size="sm"-->
            <!--                      @blur="checkExistedPhones"-->
            <!--                    />-->
            <!--                    <b-input-group-append v-if="existedPhone(countryCode.dial_code + newContact.phone)">-->
            <!--                      &lt;!&ndash; Email Exist Buttons &ndash;&gt;-->
            <!--                      <b-button-->
            <!--                        v-if="existedPhone(countryCode.dial_code + newContact.phone)"-->
            <!--                        :id="`phone_exist_button`"-->
            <!--                        variant="outline-warning"-->
            <!--                        size="sm"-->
            <!--                      >-->
            <!--                        <feather-icon-->
            <!--                          icon="AlertCircleIcon"-->
            <!--                          size="12"-->
            <!--                        />-->
            <!--                        <b-popover-->
            <!--                          :target="`phone_exist_button`"-->
            <!--                          triggers="hover"-->
            <!--                        >-->
            <!--                          <template #title>-->
            <!--                            <span>{{ $t('TypedPhoneExist') }}</span>-->
            <!--                          </template>-->
            <!--                          <div-->
            <!--                            v-for="existedContact in existedPhone(newContact.phone).contacts"-->
            <!--                            :key="`contact_phone_${existedContact.id}`"-->
            <!--                          >-->
            <!--                            <router-link :to="{ name: 'contact', params: { contactId: existedContact.id } }">-->
            <!--                              {{ existedContact.firstName }} {{ existedContact.lastName }}-->
            <!--                            </router-link>-->
            <!--                          </div>-->
            <!--                          &lt;!&ndash;                        test@mail-saleswizardapp.com&ndash;&gt;-->
            <!--                        </b-popover>-->
            <!--                      </b-button>-->
            <!--                    </b-input-group-append>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </b-input-group>-->
            <!--            </b-form-group>-->
            <b-form-group :label="$t('Phone')">
              <b-input-group>
                <div class="d-flex w-100 align-items-center">
                  <sw-select :style="'max-width: 6rem'"
                             class="mb-0"
                  >
                    <v-select
                      v-model="countryCode"
                      :options="countryCodes"
                      label="dial_code"
                      class="mr-50 select-size-sm rounded"
                      :clearable="false"
                      @option:selected="checkExistedPhones"
                    />
                  </sw-select>

                  <cleave
                    v-model="newContact.phone"
                    :options="cleaveOptions.number"
                    class="form-control form-control-sm w-100"
                    size="sm"
                    style="border-bottom-right-radius: 0 !important; border-top-right-radius: 0 !important"
                    @blur="checkExistedPhones"
                  />

                  <b-input-group-append v-if="existedPhone(newContact.phone, 'to 1')">
                    <!-- Email Exist Buttons -->
                    <b-button
                      v-if="existedPhone(newContact.phone, 'to')"
                      :id="`phone_exist_button`"
                      variant="outline-warning"
                      style="border-bottom-left-radius: 0 !important; border-top-left-radius: 0 !important; padding-top: 8px; padding-bottom: 8px"
                      size="sm"
                    >
                      <feather-icon
                        icon="AlertCircleIcon"
                        size="12"
                      />
                      <b-popover
                        :target="`phone_exist_button`"
                        triggers="hover"
                      >
                        <template #title>
                          <span>{{ $t('TypedPhoneExist') }}</span>
                        </template>
                        <div
                          v-for="existedContact in existedPhone(newContact.phone, 'ha?').contacts"
                          :key="`contact_phone_${existedContact.id}`"
                        >
                          <router-link :to="{ name: 'contact', params: { contactId: existedContact.id } }">
                            {{ existedContact.firstName }} {{ existedContact.lastName }}
                          </router-link>
                        </div>
                        <!--                        test@mail-saleswizardapp.com-->
                      </b-popover>
                    </b-button>
                  </b-input-group-append>
                </div>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <!-- THREAD -->
      <div class="divider text-primary">
        <div class="divider-text">
          {{ $t('Thread') }}
        </div>
      </div>
      <b-form-group class="mt-n2">
        <b-form-radio-group
          v-model="threadOption"
          :options="[
            { text: $t('New'), value: 'new' },
            { text: $t('Existed'), value: 'existed', disabled: contactOption === 'new' }
          ]"
          class="demo-inline-spacing"
          name="radio-thread"
        />
      </b-form-group>
      <div v-if="threadOption === 'new'">
        <b-row>
          <b-col md="6">
            <sw-select :name="$t('SelectFunnel')">
              <v-select
                id="select_funnel"
                :value="newThread.newThreadFunnel"
                class="select-size-sm"
                :options="funnels"
                label="name"
                @option:selected="(option) => {
                  if (option) {
                    newThread.name = option.name
                    newThread.newThreadFunnel = option
                  }
                }"
                @option:deselected="() => {
                  newThread.newThreadFunnel = null
                }"
              >
                <template #no-options>
                  {{ $t('NoOptions') }}
                </template>
                <template #selected-option="{ name }">
                  {{ name | truncate(15, '...') }}
                </template>
                <template #option="{ name }">
                  {{ name | truncate(15, '...') }}
                </template>
              </v-select>
            </sw-select>
          </b-col>
          <b-col md="6">
            <sw-select :name="$t('SelectChecklist')">
              <v-select
                id="select_checklist"
                v-model="newThread.newThreadChecklist"
                class="select-size-sm"
                :options="checklists"
                label="name"
                :reduce="checklist => checklist.id.toString()"
              >
                <template #no-options>
                  {{ $t('NoOptions') }}
                </template>
                <template #selected-option="{ name }">
                  {{ name | truncate(45, '...') }}
                </template>
                <template #option="{ name }">
                  {{ name | truncate(45, '...') }}
                </template>
              </v-select>
            </sw-select>
          </b-col>
        </b-row>

        <b-form-group :label="$t('ThreadName')">
          <b-form-input
            id="popover-input-1"
            ref="input1"
            v-model="newThread.name"
            size="sm"
          />
        </b-form-group>

        <b-form-group :label="$t('AssignedUsers')">
          <assigned-users
            :classes="['select-size-sm']"
            :value="newThread.assignedUsers"
            close-on-select
            :is-multiple="true"
            @input="changeAssignedUsers"
          />
        </b-form-group>
      </div>
      <div v-else-if="threadOption === 'existed'">
        <sw-select>
          <v-select
            v-model="selectedThread"
            :options="threads"
            label="name"
            class="select-size-sm"
          >
            <template #no-options>
              {{ $t('NoOptions') }}
            </template>
            <template #option="{ name }">
              {{ name }}
            </template>

            <template #selected-option="{ name }">
              {{ name }}
            </template>
          </v-select>
        </sw-select>
      </div>

      <!-- THREAD -->
      <div class="divider text-primary">
        <div class="divider-text">
          {{ $t('Note') }}
        </div>
      </div>

      <b-form-group>
        <note-form
          :show-contact="false"
          no-btns
          :is-get-note="false"
          :thread-id="threadId"
        >
          <template #actions="{ item, filesToUpload, disabled }">
            <b-button
              variant="primary"
              :disabled="!allowSave || disabled"
              @click="acceptProcess(item, filesToUpload)"
            >
              {{ $t('Save') }}
            </b-button>
          </template>
        </note-form>
      </b-form-group>
    </b-modal>

    <!-- Reject Modal -->
    <b-modal
      v-model="showRejectModal"
      :title="$t('FinalNote')"
      hide-footer
      size="md"
      no-close-on-backdrop
      modal-class="pr-0"
      @close="() => {
        rejectModalData = ''
        finalNote = ''
        reason = ''
      }"
    >
      <!--      Description      -->
      <b-form-group :label="$t('FinalNote')">
        <b-form-textarea
          v-model="finalNote"
          style="height: 10rem"
        />
      </b-form-group>

      <sw-select :name="$t('CloseReason')">
        <v-select
          v-model="reason"
          :options="reasons"
          label="name"
          :reduce="reason => reason.id"
        >
          <template #no-options>
            {{ $t('NoOptions') }}
          </template>
        </v-select>
      </sw-select>

      <div class="text-right">
        <b-button
          variant="success"
          @click="addFinalNote"
        >
          {{ $t('Save') }}
        </b-button>
      </div>
    </b-modal>
  </component>
</template>

<script>
import AssignedUsers from '@/views/components/AssignedUsers.vue'
import flatPickr from 'vue-flatpickr-component'
import {
  BFormRadioGroup,
  BFormTextarea,
  BInputGroupAppend,
  BPopover,
  BTab,
  BTable,
  BTableSimple,
  BTabs,
  BTbody,
  BTd,
  BThead,
  BTr,
  VBTooltip,
} from 'bootstrap-vue'
import Facebook from '@core/components/@sw/Facebook.vue'
import { formatDate } from '@core/utils/filter'
import {
  CREATE_CONTACT,
  CREATE_NEW_THREAD,
  CREATE_NOTE,
  DELETE_APPLICATION,
  EDIT_CONTACT,
  GET_APPLICATIONS,
  GET_CONTACT_STATUSES,
  GET_CONTACT_THREADS,
  GET_FUNNELS, GET_ORGANIZATIONS,
  GET_THREAD_TAGS, GET_WIDGET_CHATS, OPEN_MODAL,
  UPDATE_APPLICATION,
  UPLOAD_FILES,
} from '@/@constants/mutations'
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import axiosIns from '@/libs/axios'
import { countryCodes } from '@/@data/countryCodes'
import Cleave from 'vue-cleave-component'
import ApplicationsCard from '@/pages/applications/components/Card.vue'
import useVerticalLayout from '@core/layouts/layout-vertical/useVerticalLayout'
import { onUnmounted } from '@vue/composition-api'
import * as roles from '@/helpers/permissions'
import noteForm from '@/views/modals/contact/NoteModal.vue'
import moment from 'moment'
import * as fields from '@/@constants/fields'

export default {
  name: 'SwApplications',
  components: {
    Facebook,
    ApplicationsCard,
    Cleave,
    AssignedUsers,
    flatPickr,
    noteForm,
    vSelect,
    BTable,
    BTab,
    BTabs,
    BPopover,
    BFormRadioGroup,
    BInputGroupAppend,
    BTableSimple,
    BTr,
    BTd,
    BThead,
    BTbody,
    BFormTextarea,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    contact: {
      default: null,
      type: Object,
    },
    thread: {
      default: null,
    },
  },
  setup() {
    const { isMobile, resizeHandler } = useVerticalLayout()

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    return {
      perfectScrollbarSettings,
      isMobile,
    }
  },
  data: () => ({
    showRejectModal: false,
    rejectModalData: null,
    roles,
    test: '',
    threadId: null,
    loading: false,
    selectedApplications: null,
    sendNote: false,
    isCompany: false,
    organizations: [],
    newContact: {
      organization: null,
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      status: '',
      contactTypes: [],
    },
    tags: [],
    status: [],
    countryCode: {
      code: 'PL',
      dial_code: '+48 ',
      name: 'Poland',
    },
    countryCodes,
    cleaveOptions: {
      number: {
        delimiter: ' ',
        numeral: true,
        blocks: [3, 3, 3],
      },
    },
    newThread: {
      assignedUsers: [],
      name: '',
      newThreadFunnel: '',
      newThreadChecklist: '',
    },
    sorter: {
      sortBy: 'createdAt',
      sortDesc: true,
    },
    pagination: {
      perPage: 25,
      currentPage: 1,
      totalRecords: 0,
    },
    searchQuery: '',
    sources: [],
    type: '',
    types: [
      { key: 'GOOGLE_ADS', label: 'Google' },
      { key: 'HTML', label: 'HTML' },
      { key: 'WORDPRESS', label: 'Wordpress' },
      { key: 'FACEBOOK', label: 'Facebook' },
      {
        key: 'LEAD_GENERATOR_WIDGET', label: 'LeadGenerator', prefix: 'Widget', requiredModule: 'clientBrowserModule',
      },
      {
        key: 'LEAD_GENERATOR_WHATSAPP', label: 'LeadGenerator', prefix: 'Whatsapp', requiredModule: 'whatsappModule',
      },
      {
        key: 'LEAD_GENERATOR_MOBILE', label: 'LeadGenerator', prefix: 'MobileClients', requiredModule: 'clientMobileModule',
      },
      {
        key: 'ZAPIER', label: 'Zapier',
      },
      {
        key: 'MAKE', label: 'Make',
      },
    ],
    applicationStatuses: [
      { key: 'NEW', label: 'AgreementStatuses.NEW' },
      { key: 'REJECTED', label: 'AgreementStatuses.REJECTED' },
      { key: 'ACCEPTED', label: 'WasTaken' },
    ],
    filters: {
      type: '',
      source: '',
      status: '',
      createdAt: '',
    },
    fields: [
      { key: 'selected', label: 'Details', isVisible: true },
      { key: 'details', label: 'Details', isVisible: true },
      { key: 'createdAt', label: 'CreatedAt', isVisible: true },
      { key: 'mailAddress', label: 'Email', isVisible: true },
      { key: 'phoneNumber', label: 'Phone', isVisible: true },
      { key: 'name', label: 'FirstNameAndLastName', isVisible: true },
      { key: 'source', label: 'ContactSource', isVisible: true },
      { key: 'days', label: 'DaysCount', isVisible: true },
      { key: 'message', label: 'Message', isVisible: true },
      { key: 'status', label: 'task.Status', isVisible: true },
      { key: 'contactThread', label: 'SingleAssignedContact', isVisible: true },
      { key: 'action', label: 'Action', isVisible: true },
    ],
    items: [],
    existedPhones: [],
    existedEmails: [],
    contacts: [],
    widgets: [],
    funnels: [],
    threads: [],
    checklists: [],
    contactOption: 'new',
    selectedContact: '',
    threadOption: 'new',
    selectedThread: '',
    zapierIntegrations: [],
    makeIntegrations: [],
    selectedRows: [],
    phoneNumbers: [],
    finalNote: '',
    noteItem: null,
    initApplication: null,
    filesToUpload: [],
    connections: [],
    reason: '',
    reasons: [],
    stats: {
      NEW: 0,
      REJECTED: 0,
      ACCEPTED: 0,
      TOTAL: 0,
    },
  }),
  watch: {
    reloadContent(newValue) {
      // console.log(newValue, this.$store.getters['modal/getModalData'])
      if (newValue) {
        this.newContact.organization = this.$store.getters['modal/getModalData']
      }
    },
    contactOption(newValue) {
      if (newValue === 'new') {
        this.threadOption = 'new'
      }
    },
    'filters.type': function () {
      this.filters.source = ''
    },
    '$route.params.id': {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) this.loadInitRecord()
      },
    },
    filters: {
      deep: true,
      handler() {
        this.loadData()
      },
    },
    contact: {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue?.id && newValue?.id !== oldValue?.id) {
          this.loadData()
        }
      },
    },
  },
  computed: {
    phoneInList() {
      return this.newContact.phone
    },
    locale() {
      return this.$i18n.locale
    },
    filteredTypes() {
      return this.types.filter(type => !type.requiredModule || (type.requiredModule && this.checkRequiredModule(type.requiredModule)))
    },
    filtredSources() {
      if (this.filters.type === 'FACEBOOK') return this.connections
      if (this.filters.type === 'LEAD_GENERATOR_WIDGET') return this.widgets
      if (this.filters.type === 'LEAD_GENERATOR_WHATSAPP') return this.phoneNumbers
      if (this.filters.type === 'ZAPIER') return this.zapierIntegrations
      if (this.filters.type === 'MAKE') return this.makeIntegrations

      return this.sources.filter(({ type }) => type === this.filters.type)
    },
    isGlobalRoute() {
      return this.$route.name === 'applications'
    },
    isNewContact() {
      return this.$route.path.includes('new_contact')
    },
    allowSave() {
      let isValid = true

      if (this.contactOption === 'new') {
        isValid = isValid && !!this.newContact.firstName && !!this.newContact.status
      }

      if (this.threadOption === 'new') {
        isValid = isValid && !!this.newThread.name && !!this.newThread.assignedUsers && !!this.newThread.newThreadFunnel
      }

      if (this.contactOption === 'existed') {
        isValid = isValid && !!this.selectedContact
      }

      if (this.threadOption === 'existed') {
        isValid = isValid && !!this.selectedThread
      }

      return isValid
    },
    ...mapGetters({
      statuses: 'typeSettings/geContactStatuses',
      user: 'auth/getCurrentUser',
      reloadContent: 'modal/getReloadContent',
    }),
    getStats() {
      return {
        totalNumberSubmissions: this.stats.TOTAL,
        newSubmissions: this.stats.NEW,
        rejectedSubmissions: this.stats.REJECTED,
        submissionsTaken: this.stats.ACCEPTED,
      }
    },
  },
  async mounted() {
    await Promise.all([
      this.getStatuses(),
      this.getApplicationTypes(),
      this.loadWhatsappNumbers(),
      this.getContactTags(),
      this.loadWebhooks(),
      this.loadWidgets(),
      this.loadZapierIntegrations(),
      this.loadMakeIntegrations(),
    ])

    this.loadInitRecord()
    this.loadData()

    try {
      const params = { fields_load: this.$fields.CONTACT_APPLICATION_CLOSE_REASONS, orderBy: 'position.asc' }
      const resp = await axiosIns.get('1/settings/contactApplicationStatusReasons', { params })

      if (resp?.data?.data?.items) {
        const items = resp.data?.data?.items || []
        this.reasons = items
      }
    } catch (err) {
      this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
    }

    try {
      const checklists = await axiosIns.get('1/settings/contactThreadChecklistTypes', { params: { fields_load: this.$fields.SETTINGS_FUNNEL_TYPE, orderBy: 'position.asc' } })
      const funnels = await this.$store.dispatch(`funnelMain/${GET_FUNNELS}`)

      if (checklists) this.checklists = checklists.data.data.items
      if (funnels) this.funnels = funnels
    } catch (err) {
      this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
    }
  },
  methods: {
    addOrganization() {
      const payload = {
        modalType: 'organization',
        modalTitle: this.$i18n.t('NewOrganization'),
        okButtonTitle: this.$i18n.t('Save'),
        size: 'lg',
      }
      this.$store.commit(`modal/${OPEN_MODAL}`, payload)
    },
    changeOrganization(event) {
      this.$set(this.newContact, 'organization', event)
    },
    reloadOrganizations(search, loading) {
      if (search.length) {
        loading(true)
        this.$store.dispatch(`organizations/${GET_ORGANIZATIONS}`, search)
          .then(res => {
            this.organizations = res.data.items
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          })
          .finally(() => {
            loading(false)
          })
      }
    },
    async loadInitRecord() {
      if (!this.$route?.params?.id || this.$route.name !== 'applications') {
        this.initApplication = null
        return null
      }

      try {
        const params = {
          fields_load: fields.APPLICATIONS,
          eq_id: this.$route?.params?.id,
        }
        const response = await axiosIns.get('1/contacts/applications', { params })
        const [item] = (response.data.data?.items || [])

        const { phoneNumber } = item

        if (phoneNumber?.phone?.includes('+')) {
          const code = countryCodes.find(({ dial_code }) => phoneNumber.phone.includes(dial_code.trim()))
          const newPhone = phoneNumber.phone.replace(code.dial_code.trim(), '')

          phoneNumber.phone = `${code.dial_code} ${newPhone}`.replaceAll('  ', ' ')
        }

        this.initApplication = { ...item, phoneNumber, isFromParam: true }

        if (this.items[0]?.isFromParam) {
          this.$set(this.items, 0, { ...item, phoneNumber, isFromParam: true })
        }

        return { ...item, phoneNumber, isFromParam: true }
      } catch (err) {
        console.error(err)
      }

      return null
    },
    async loadWidgets() {
      this.widgets = (await this.$store.dispatch(`chatWidgets/${GET_WIDGET_CHATS}`)).map(e => ({ ...e, label: e.name }))
    },
    closeModal() {
      this.contacts = []
      this.threads = []
      this.selectedApplications = null
      this.newContact = {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        status: '',
        contactTypes: [],
      }
      this.countryCode = {
        code: 'PL',
        dial_code: '+48 ',
        name: 'Poland',
      }
      this.newThread = {
        assignedUsers: [],
        name: '',
        newThreadFunnel: '',
        newThreadChecklist: '',
      }
    },
    async loadWhatsappNumbers() {
      if (!this.checkRequiredModule('whatsappModule')) {
        return true
      }

      try {
        const res = await axiosIns.get('1/integration/whatsapp/connections/subscriptionPhoneNumbers', { params: { fields_load: 'WHATSAPP_SUBSCRIPTION' } })
        const phoneNumbers = res?.data?.data?.items?.sort((a, b) => new Date(b.createdAt.date) - new Date(a.createdAt.date)).map(e => ({ ...e, label: e.whatsAppPhoneNumberDisplay, name: e.whatsAppPhoneNumberDisplay }))

        this.phoneNumbers = phoneNumbers || []
        return phoneNumbers
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        return err
      }
    },
    async loadWebhooks() {
      this.loading = true

      const res = await axiosIns.get('1/integration/facebook/connection/webhook', { params: { fields_load: this.$fields.FACEBOOK_WEBHOOKS } })
      const connections = res?.data?.data?.items?.sort((a, b) => new Date(b.createdAt.date) - new Date(a.createdAt.date))

      this.connections = connections || []

      // this.webhooks = webhooks.filter(wh => wh.integrationFacebookConnection.status === this.CONNECTION_STATUS.ACTIVE)

      this.loading = false
    },
    async getContactTags() {
      const res = await this.$store.dispatch(`typeSettings/${GET_THREAD_TAGS}`)
      this.tags = res.data.items
    },
    getStatus(name) {
      const stat = this?.status.filter(el => el.status === name)[0] ?? 0

      return stat?.totalGrossPrice ?? 0
    },
    existedPhone(phone) {
      if (!phone) return

      // eslint-disable-next-line consistent-return
      return phone.includes('+')
        ? this.existedPhones.find(contact => contact.phone === phone?.replaceAll('  ', ' '))
        : this.existedPhones.find(contact => contact.phone === ((this.countryCode?.dial_code || '') + phone)?.replaceAll('  ', ' '))
    },
    existedMail(email) {
      return this.existedEmails.find(item => item.mail === email)
    },
    async getApplicationTypes() {
      try {
        const resp = await axiosIns.get('1/settings/contactsApplicationsIntegrations', { params: { fields_load: this.$fields.SETTINGS_CONTACTS_APPLICATIONS_INTEGRATIONS } })
        if (resp?.data?.data?.items) this.sources = resp.data.data.items
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    async loadZapierIntegrations() {
      try {
        const response = await axiosIns.get('1/plugins/zapier?fields_load=ZAPIER')
        if (response?.data?.data?.items) this.zapierIntegrations = response.data.data.items.map(e => ({ ...e, label: e.name }))
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    async loadMakeIntegrations() {
      try {
        const response = await axiosIns.get('1/plugins/make?fields_load=MAKE')
        if (response?.data?.data?.items) this.makeIntegrations = response.data.data.items.map(e => ({ ...e, label: e.name }))
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    selectContact(contact) {
      if (contact) {
        this.$store.dispatch(`singleContact/${GET_CONTACT_THREADS}`, contact?.id)
          .then(res => {
            this.threads = res
          })
      } else this.threads = []
    },
    changeAssignedUsers(payload) {
      this.newThread.assignedUsers = payload
    },
    formatDate,
    loadData() {
      this.loading = true
      this.$nextTick(() => {
        const payload = {
          pagination: this.pagination,
          filters: this.filters,
          contact: this.isGlobalRoute ? null : this.contact,
          thread: this.isGlobalRoute ? null : this.thread,
        }

        // payload.filters.createdAt = Array.isArray(this.filters.createdAt) ? this.filters.createdAt.join(' do ') : this.filters.createdAt

        if (this.isGlobalRoute || (!this.isGlobalRoute && this.contact?.contactThreads?.length)) {
          this.$store.dispatch(`applications/${GET_APPLICATIONS}`, payload)
            .then(res => {
              Object.keys(this.stats).forEach(key => {
                if (key !== 'TOTAL') this.$set(this.stats, key, res.stats[key] || 0)
                else this.$set(this.stats, key, res.data.totalItemCount)
              })
              this.items = res.data.items.map(app => {
                const { phoneNumber } = app

                if (phoneNumber?.phone?.includes('+')) {
                  const code = countryCodes.find(({ dial_code }) => phoneNumber.phone.includes(dial_code.trim()))
                  const newPhone = phoneNumber.phone.replace(code.dial_code.trim(), '')

                  phoneNumber.phone = `${code.dial_code} ${newPhone}`.replaceAll('  ', ' ')
                }

                // console.log(phoneNumber.phone.includes('+') ? phoneNumber.phone?.replaceAll('  ', ' ') : `+48 ${phoneNumber.phone?.replaceAll(' ', '')}`)

                return { ...app, phoneNumber }
              })

              if (this.$route.params.id && this.$route.name === 'applications' && this.initApplication) {
                this.items.unshift(this.initApplication)
              }

              this.pagination.totalRecords = res.data.totalItemCount
              this.loading = false

              this.checkExistedEmails()
              this.checkExistedPhones()
            })
            .catch(err => {
              this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
              this.loading = false
            })
        } else this.loading = false
      })
    },
    async getStatuses() {
      await this.$store.dispatch(`typeSettings/${GET_CONTACT_STATUSES}`)
      // eslint-disable-next-line prefer-destructuring
      this.newContact.status = this.statuses[0]
    },
    reject({ id }, index) {
      this.showAlert('warning', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('alerts.WontbeAble'))
        .then(() => {
          this.rejectModalData = { id, index, status: 'REJECTED' }
          this.showRejectModal = true
        })
    },
    deleteApplication({ id }, index) {
      this.showAlert('error', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('alerts.WontbeAble'))
        .then(() => {
          this.$store.dispatch(`applications/${DELETE_APPLICATION}`, id)
            .then(() => {
              this.showToast('success', this.$i18n.t('RemovedSuccessfully'))
              this.items.splice(index, 1)
            })
            .catch(err => {
              this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            })
        })
    },
    async selectApplication(application, index) {
      Object.assign(this.newContact, {
        firstName: application.firstName,
        lastName: application.lastName,
        email: application.mailAddress?.mail || '',
        phone: application.phoneNumber?.phone,
      })

      this.$set(this.newThread, 'assignedUsers', [this.$store.getters['auth/getCurrentUser']])

      if (this.newContact.phone) {
        const code = countryCodes.find(({ dial_code }) => this.newContact.phone.includes(dial_code.trim()))
        if (code) {
          const newPhone = this.newContact.phone.replace(code.dial_code.trim(), '')
          this.$set(this.newContact, 'phone', newPhone)
          this.countryCode = code
        }
      } else {
        this.$set(this.newContact, 'phone', '')
        this.countryCode = {
          dial_code: '+48 ',
        }
      }

      const emails = this.existedMail(application.mailAddress?.mail)
      const phones = this.existedPhone(application.phoneNumber?.phone)
      if (emails || phones) {
        this.contactOption = 'existed'
        if (emails) this.contacts.push(...emails.contacts)
        if (phones) this.contacts.push(...phones.contacts)

        this.contacts = this.contacts.removeDuplicates()

        if (this.contacts.length === 1) {
          [this.selectedContact] = this.contacts

          if (this.selectedContact?.id) {
            const a = await axiosIns.get('1/contacts', { params: { fields_load: 'BASIC_CONTACT', eq_id: this.selectedContact.id } })
            if (a.data?.data?.items) {
              [this.selectedContact] = a.data.data.items
            }
          }

          this.selectContact(this.selectedContact)
        }
      }

      this.selectedApplications = {
        application,
        index,
      }
    },
    async checkExistedPhones() {
      this.existedPhones = []
      const phones = this.items.map(({ phoneNumber }) => {
        // if (phoneNumber.phone.includes('+')) {
        //   const code = countryCodes.find(({ dial_code }) => phoneNumber.phone.includes(dial_code.trim()))
        //   const newPhone = phoneNumber.phone.replace(code.dial_code.trim(), '')
        //   return `${code.dial_code} ${newPhone}`
        // }
        if (phoneNumber?.phone) return phoneNumber.phone

        return null
      }).filter(Boolean).map(phone => (phone.includes('+') ? phone?.replaceAll('  ', ' ') : `+48 ${phone?.replaceAll(' ', '')}`))

      if (!phones?.length) return

      try {
        const resp = await axiosIns.post('1/contacts/getExistsByPhones', phones)

        if (resp?.data?.data?.exists) this.existedPhones = resp.data.data?.exists || []
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    async checkExistedEmails() {
      let emails = this.items.map(({ mailAddress }) => {
        if (mailAddress?.mail) return mailAddress.mail

        return null
      }).filter(Boolean)
      emails = emails?.filter(Boolean)
      if (!emails?.length) return

      try {
        const resp = await axiosIns.post('1/contacts/getExistsByEmails', emails)

        if (resp?.data?.data?.exists) this.existedEmails = resp.data.data.exists || []
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    // getDaysToNow(createdAt) {
    //   if (!createdAt?.date) return 0
    //
    //   const createdAtDate = moment(createdAt.date).set({ hour: 0, minute: 0, second: 0 })
    //   const diff = moment(new Date()).set({ hour: 0, minute: 0, second: 0 }).diff(createdAtDate, 'days')
    //   return diff
    // },
    getContactsLength(item) {
      return item?.contacts?.length || 0
    },
    async acceptProcess(note, filesToUpload) {
      this.noteItem = { ...note }
      this.filesToUpload = filesToUpload
      try {
        if (this.contactOption === 'new' && this.threadOption === 'new') {
          // Create new Contact
          await this.createNewContactAndThread()
        }
        if (this.contactOption === 'existed' && this.threadOption === 'new') {
          await this.assignToContactAndCreateThread()
          // Assign email, phone to contact
        }
        if (this.contactOption === 'existed' && this.threadOption === 'existed') {
          // Assign contact and thread
          await this.assignToContactAndThread()
        }

        this.showToast('success', this.$i18n.t('ApplicationUpdated'))
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    async assignToContactAndThread() {
      const it = await this.updateContact(this.getId(this.selectedContact), this.newContact)

      if (it && this.selectedThread) {
        this.assignThreadToApplication(this.selectedThread.id)
      }
    },
    async createNewContactAndThread() {
      const contactId = await this.createContact(this.newContact)

      if (contactId) {
        await this.createThread(contactId, this.newThread)
      }
    },
    async assignToContactAndCreateThread() {
      const it = await this.updateContact(this.getId(this.selectedContact), this.newContact)

      if (it) {
        await this.createThread(this.getId(this.selectedContact), this.newThread)
      }
    },
    updateContact(contactId, contact) {
      // Matko jaki syf ...
      return new Promise((resolve, reject) => {
        let emails = []
        if (this.selectedContact.mailAddresses?.length) {
          emails = this.selectedContact.mailAddresses.filter(email => email.mail !== contact.email).map(email => email.mail)
          emails.push(contact.email)
        } else emails.push(contact.email)

        let phones = []
        const code = countryCodes.find(({ dial_code }) => contact.phone.includes(dial_code.trim()))
        let phoneNumberItem = ''
        if (!code) {
          phoneNumberItem = (this.countryCode?.dial_code || '') + contact.phone
        }

        if (this.selectedContact.phoneNumbers?.length) {
          phones = this.selectedContact.phoneNumbers.filter(phone => phone.phone !== phoneNumberItem).map(phone => phone.phone)
          phones.push(phoneNumberItem)
        } else phones.push(phoneNumberItem)

        const contactPayload = {
          id: contactId,
          mailAddresses: [...new Set(emails)],
          phoneNumbers: [...new Set(phones.filter(e => e.length > 4))],
        }

        // console.log(contactPayload, this.selectedContact.mailAddresses, contact)
        // return false
        /* eslint-disable */

        if (!phoneNumberItem) {
          delete contactPayload.phoneNumbers
        }

        if (this.selectedContact.email) {
          // eslint-disable-next-line prefer-destructuring
          contactPayload.mailAddress = this.selectedContact.email
        } else if (emails.length) {
          // eslint-disable-next-line prefer-destructuring
          contactPayload.mailAddress = emails[0]
        }

        if (phoneNumberItem && this.selectedContact.phone) {
          // eslint-disable-next-line prefer-destructuring
          contactPayload.phoneNumber = this.selectedContact.phone
        } else if (phones.length) {
          // eslint-disable-next-line prefer-destructuring
          contactPayload.phoneNumber = phones[0]
        }

        this.$store.dispatch(`contacts/${EDIT_CONTACT}`, contactPayload)
          .then(res => {
            this.showToast('success', this.$i18n.t('success.contactUpdated'))
            resolve(res)
            return res
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            reject(err)
          })
      })
    },
    createContact(contact) {
      let { phone } = contact
      if (phone && !phone?.includes('+')) {
        phone = `${this.countryCode.dial_code}${phone}`
      }
      /* eslint-disable */
      contact.status = this.getId(contact.status)
      contact.isOrganization = this.isCompany
      if (this.isCompany && contact.organization) {
        contact.organization = this.getObjectId(contact.organization)
      } else {
        contact.organization = null
        contact.isOrganization = false
      }
      contact.mailAddresses = [contact.email]
      contact.mailAddress = contact.email
      contact.phoneNumbers = phone ? [phone] : []
      contact.phoneNumber = phone ? phone : ''
      contact.contactTypes = contact.contactTypes.map(type => (typeof type === 'string' ? type : type?.id))

      return new Promise((resolve, reject) => {
        this.$store.dispatch(`contacts/${CREATE_CONTACT}`, contact)
            .then(contactId => {
              this.showToast('success', this.$i18n.t('success.contactCreated'))
              resolve(contactId)
              return contactId
            })
            .catch(err => {
                if (err?.response?.data?.message === 'LIMIT_REACHED') {
                    this.showAlert('error', '', this.$i18n.t('PlanLimitReached'), false)
                    reject(err)
                    return
                }

              this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
              reject(err)
            })
      })
    },
    // eslint-disable-next-line no-unused-vars
    createThread(contactId, thread) {
      return new Promise((resolve, reject) => {
        let pointId = ''
        if (typeof thread.newThreadFunnel === 'object') {
          const point = thread.newThreadFunnel.contactThreadFunnelTypePoints[0]
          pointId = point && typeof point === 'object' ? point.id : point
        } else pointId = thread.newThreadFunnel

        const payload = {
          contactId,
          threadName: thread.name,
          threadFunnelPoint: pointId,
          assignedUsers: thread.assignedUsers.map(user => this.getId(user)),
          contactThreadAssignedContacts: [],
          predictedValue: 0,
          predictedValueCurrency: this.$store.getters['system/getSettings'].defaultCurrency || 'PLN',
        }

        if (thread.newThreadChecklist) {
            payload.checklistId = [thread.newThreadChecklist]
        }

        this.$store.dispatch(`singleContact/${CREATE_NEW_THREAD}`, payload)
            .then(res => {
              this.assignThreadToApplication(res)
              this.showToast('success', this.$i18n.t('ThreadCreated'))
              resolve(res)
            })
            .catch(err => {
              this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
              reject(err)
            })
      })
    },
    assignThreadToApplication(threadId) {
      this.$store.dispatch(`applications/${UPDATE_APPLICATION}`, { id: this.selectedApplications.application.id, contactThread: parseInt(threadId) })
          .then(() => {
            this.contactOption = 'new'
            this.selectedContact = ''
            this.threadOption = 'new'
            this.selectedThread = ''
            this.selectedApplications = null

            this.threadId = parseInt(threadId)

            this.createNote(threadId)
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          })
    },
    async createNote(threadId) {
      const { noteItem } = this

        if (noteItem.type && noteItem.content) {
            const formatedContent = noteItem.content.replaceAll('\n', '<br />')
            const noteData = {
                name: '',
                contactThreadNoteType: this.getObjectId(noteItem.type),
                content: formatedContent,
                sharedRanks: [],
            }

            noteData.sharedRanks = noteItem.share.map(rank => this.getObjectId(rank))

            this.$store.dispatch(`uploader/${UPLOAD_FILES}`, { uploadedFiles: this.filesToUpload, type: 'note', threadId })
                .then(async res => {
                    noteData.files = [...res.files, ...noteItem.files.map(file => file.token)]

                    await this.$store.dispatch(`modal/${CREATE_NOTE}`, { noteData, threadId })

                    this.showToast('success', this.$i18n.t('ApplicationAssignedToThread'))
                    this.filesToUpload = []
                    this.noteItem = null
                    await this.loadData()
                })
                .catch(err => {
                    this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
                })
        } else {
            this.showToast('success', this.$i18n.t('ApplicationAssignedToThread'))
            this.filesToUpload = []
            this.noteItem = null
            await this.loadData()
        }
    },
    onSearch(search, loading) {
      if (search.length) {
        loading(true)
        this.$store.dispatch('contacts/GET_SELECT_CONTACTS', { search })
            .then(res => {
              this.contacts = res
              loading(false)
            })
      }
    },
    getId(item) {
      return item?.id ? item.id : item
    },
    addFinalNote() {
      const { rejectModalData, finalNote } = this

      const payload = {
        id: rejectModalData.id,
        status: rejectModalData.status,
        note: finalNote,
        contactApplicationStatusReason: this.reason
      }

      this.$store.dispatch(`applications/${UPDATE_APPLICATION}`, payload)
        .then(() => {
          this.showToast('success', this.$i18n.t('success.contactUpdated'))
          this.$set(this.items[rejectModalData.index], 'status', rejectModalData.status)
          this.$set(this.items[rejectModalData.index], 'note', finalNote)
          this.$set(this.items[rejectModalData.index], 'servedBy', this.$store.getters['auth/getCurrentUser'])
          this.$set(this.items[rejectModalData.index], 'servedAt', { date: moment().format("YYYY-MM-DD HH:mm:ss") })
          this.$set(this.items[rejectModalData.index], 'contactApplicationStatusReason', this.reasons.find(r => r.id === payload.contactApplicationStatusReason))

          this.finalNote = ''
          this.showRejectModal = false
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
  },
}
</script>

<style lang="scss">
  .application-sticker {
    z-index: 9;
    position:sticky;
    top: 0px;
    background-color: rgb(var(--theme-color-background)) !important
  }
</style>
