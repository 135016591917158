<template>
  <div>
    <validation-observer
      class="d-flex flex-column"
    >
      <validation-provider
        v-slot="{ errors }"
        :name="$t('SelectUser')"
        rules="required"
      >
        <sw-select :name="$t('task.Type')">
          <v-select
            v-model="item.type"
            :options="types"
          >
            <template #no-options>
              {{ $t('NoOptions') }}
            </template>
            <template #option="{ label }">
              {{ $t(label) }}
            </template>
            <template #selected-option="{ label }">
              {{ $t(label) }}
            </template>
          </v-select>
        </sw-select>

        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>

      <validation-provider
        v-slot="{ errors }"
        :name="$t('SelectUser')"
        rules="required"
      >
        <sw-select class="mb-0"
                   :name="$t('SelectUser')"
        >
          <assigned-users
            :is-multiple="false"
            mode="users"
            :value="item.marketingPlanPointUser"
            @input="setUser"
          />
        </sw-select>

        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>

      <validation-provider
        v-slot="{ errors }"
        :name="$t('SelectOrder')"
      >
        <sw-select :name="$t('SelectThreadOrOrder')">
          <v-select
            v-model="item.contactThreadCartOrder"
            :options="orders"
            label="number"
            :disabled="contactThread"
            @option:selected="item.contactThread = ''"
          >
            <template #no-options>
              {{ $t('NoOptions') }}
            </template>
          </v-select>
        </sw-select>

        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>

      <sw-select :name="$t('Process')">
        <v-select
          v-model="item.contactThread"
          :options="contactList"
          :filterable="false"
          :disabled="contactThread"
          @search="loadContacts"
          @option:selected="item.contactThreadCartOrder = ''"
        >
          <template #no-options="{ search }">
            <span v-if="search.length">
              {{ $t('NoData') }}
            </span>

            <span v-else>
              {{ $t('TypeToSearch') }}
            </span>
          </template>

          <template #option="{ name, contact }">
            <div class="d-flex align-items-center">
              <avatar
                :user="contact"
                class="mr-25"
              />  - {{ name }}
            </div>
          </template>

          <template #selected-option="{ name, contact }">
            <div class="d-flex">
              <avatar
                :user="contact"
                class="mr-25"
              /> – {{ name }}
            </div>
          </template>
        </v-select>
      </sw-select>

      <b-row>
        <b-col
          sm="6"
          class="d-flex align-items-center"
        >
          <b-form-checkbox
            v-model="isDecreaseValue"
            variant="primary"
            switch
          >
            <span class="switch-icon-right">
              <feather-icon icon="PlusIcon" />
            </span>
            <span class="switch-icon-left">
              <feather-icon icon="MinusIcon" />
            </span>
          </b-form-checkbox>
          <span v-if="isDecreaseValue">{{ $t('Decrease') }}</span>
          <span v-else>{{ $t('Increase') }}</span>
        </b-col>
      </b-row>
      <b-form-group
        :label="$t('ChangeValue')"
        class="mt-50"
      >
        <b-input-group class="input-group-merge">
          <b-input-group-prepend is-text>
            <feather-icon :icon="isDecreaseValue ? 'MinusIcon' : 'PlusIcon'" />
          </b-input-group-prepend>
          <cleave
            v-model="item.toPaid"
            :options="cleaveOptions.number"
            class="form-control"
          />
        </b-input-group>
      </b-form-group>

      <validation-provider
        v-slot="{ errors }"
        :name="$t('SpecifyTypeOfCommission')"
      >
        <label for="description">
          {{ $t('Description') }}
        </label>

        <textarea
          id="description"
          v-model="item.description"
          class="form-control"
          name="description"
          type="text"
        />

        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>

    </validation-observer>
    <b-button
      :disabled="loading || !item.type"
      variant="primary"
      class="mt-1"
      size="sm"
      @click="save"
    >
      {{ $t('Save') }}
    </b-button>
  </div>
</template>

<script>
import AssignedUsers from '@/views/components/AssignedUsers.vue'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Cleave from 'vue-cleave-component'
import axiosIns from '@/libs/axios'
import { THREADS_SEARCH } from '@/@constants/fields'

const defaultItem = {
  toPaid: 0,
  description: '',
  contactThreadCartOrder: '',
  marketingPlanPointUser: '',
  type: '',
  contactThread: '',
}
export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    AssignedUsers,
    vSelect,
    Cleave,
  },
  props: {
    contactThread: {
      default: null,
      required: false,
    },
  },
  data: () => ({
    orders: [],
    types: [
      'COMMISSION',
      'WEBSITE_TAKEOVER',
      'COMMISSION_CORRECTION',
    ],
    loading: false,
    isDecreaseValue: false,
    item: { ...defaultItem },
    contactList: [],
    cleaveOptions: {
      number: {
        delimiter: ' ',
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
        numeralPositiveOnly: true,
      },
    },
  }),
  mounted() {
    if (this.contactThread) {
      this.item.contactThread = this.contactThread
    }
  },
  methods: {
    setUser(event) {
      this.item.marketingPlanPointUser = event

      this.loadOrders()
    },
    async loadOrders() {
      const params = {
        fields_load: this.$fields.ORDERS,
        'eq_seller-id': this.getObjectId(this.item.marketingPlanPointUser),
      }
      const resp = await axiosIns.get('1/contacts/threads/carts/orders', { params })

      this.orders = resp?.data?.data?.items || []
    },
    async save() {
      this.loading = true
      try {
        const payload = { ...this.item, status: 'NEW' }
        payload.toPaid = this.isDecreaseValue ? payload.toPaid * -1 : payload.toPaid * 1
        payload.contactThreadCartOrder = this.getObjectId(payload.contactThreadCartOrder) || null
        payload.marketingPlanPointUser = this.getObjectId(payload.marketingPlanPointUser)
        payload.contactThread = this.getObjectId(payload.contactThread) || null
        payload.type = (payload.type) || null

        const resp = await axiosIns.post('1/marketingPlans/pointPayments/collects', payload)

        if (resp) {
          this.item = { ...defaultItem }
          this.$emit('reload')
        }
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    async loadContacts(search, isLoading) {
      this.contactList = await this.selectSearch(search, isLoading, THREADS_SEARCH, 'name,contact-firstName,contact-lastName', '1/contacts/threads', 25)
    },
  },
}
</script>
