// eslint-disable-next-line import/no-cycle
import axiosIns from '@/libs/axios'

import * as fields from '@/@constants/fields'

function getCurrentDate(full = false) {
  const date = new Date()
  const month = (date.getMonth() + 1) > 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
  const day = date.getDate() > 10 ? `0${date.getDate()}` : date.getDate()
  if (full) {
    const hour = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
    const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
    const seconds = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds()
    return `${date.getFullYear()}-${month}-${day} ${hour}:${minutes}:${seconds}`
  }
  return `${date.getFullYear()}-${month}-${day}`
}

export default {
  namespaced: true,
  actions: {
    // eslint-disable-next-line no-unused-vars
    GET_OUR_DELAYED_THREADS: ({ commit }, userId) => new Promise((resolve, reject) => {
      const params = {
        limit: 5,
        fields: 'id,name,createdAt,assignedUsers.id,assignedUsers.firstName,assignedUsers.lastName,assignedUsers.avatar,contact.id,contact.firstName,contact.lastName',
        orderBy: 'createdAt.desc',
        lt_contactThreadFunnelTypePointDelayedAt: getCurrentDate(true),
      }
      axiosIns.get('1/contacts/threads', { params })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    GET_All_DELAYED_THREADS: ({ commit }, payload) => new Promise((resolve, reject) => {
      const params = {
        limit: 5,
        fields: 'id,name,createdAt,assignedUsers.id,assignedUsers.firstName,assignedUsers.lastName,assignedUsers.avatar,contact.id,contact.firstName,contact.lastName',
        orderBy: 'createdAt.desc',
        lt_contactThreadFunnelTypePointDelayedAt: getCurrentDate(true),
      }
      axiosIns.get('1/contacts/threads', { params })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    GET_ASSIGNED_CONTACTS: ({ commit }, payload) => new Promise((resolve, reject) => {
      const params = {
        limit: 5,
        fields: 'id,contactAssignedUsers.user.id,contactAssignedUsers.user.firstName,contactAssignedUsers.user.lastName,contactAssignedUsers.user.avatar,firstName,lastName,email,createdAt',
        'isNotNull_contactAssignedUsers-id': '',
        orderBy: 'createdAt.desc',
      }
      axiosIns.get('1/contacts', { params })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    GET_NOT_ASSIGNED_CONTACTS: ({ commit }, payload) => new Promise((resolve, reject) => {
      const params = {
        limit: 5,
        fields: 'id,contactAssignedUsers.user.id,contactAssignedUsers.user.firstName,contactAssignedUsers.user.lastName,contactAssignedUsers.user.avatar,firstName,lastName,email,createdAt',
        eq_contactAssignedUsers: null,
        orderBy: 'createdAt.desc',
      }
      axiosIns.get('1/contacts', { params })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // Get Assigned Statistics
    // eslint-disable-next-line no-unused-vars
    GET_STATISTIC_ASSIGNED_CONTACTS: ({ commit }, payload) => new Promise((resolve, reject) => {
      const params = {
        limit: 5,
      }
      axiosIns.get('1/contacts/getStatistics', { params })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // Sms
    // eslint-disable-next-line no-unused-vars
    GET_ALL_SMSES: ({ commit }, payload) => new Promise((resolve, reject) => {
      const params = {
        limit: 5,
        fields: 'createdAt,createdBy.id,createdBy.firstName,createdBy.lastName,createdBy.avatar,content,sentTo.id,sentTo.firstName,sentTo.lastName,sentTo.avatar,sharedRanks.id,sharedRanks.name,contactThread.id,contactThread.name,contactThread.contact.id,contactThread.contact.firstName,contactThread.contact.lastName',
        orderBy: 'createdAt.desc',
      }
      axiosIns.get('1/contacts/threads/smses', { params })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    GET_OUR_SMSES: ({ commit }, payload) => new Promise((resolve, reject) => {
      const { userId } = payload
      const params = {
        limit: 5,
        fields: 'createdAt,createdBy.id,createdBy.firstName,createdBy.lastName,createdBy.avatar,content,sentTo.id,sentTo.firstName,sentTo.lastName,sentTo.avatar,sharedRanks.id,sharedRanks.name,contactThread.id,contactThread.name,contactThread.contact.id,contactThread.contact.firstName,contactThread.contact.lastName',
        orderBy: 'createdAt.desc',
        'in_contactThread-assignedUsers': userId.toString(),
      }
      axiosIns.get('1/contacts/threads/smses', { params })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // Tasks
    // eslint-disable-next-line no-unused-vars
    GET_ALL_TASKS: ({ commit }) => new Promise((resolve, reject) => {
      const params = {
        limit: 5,
      }

      params.orderBy = 'createdAt.desc'
      params.eq_status = 'O'
      params.lte_startAt = `${getCurrentDate()} 23:59:59`
      params.fields_load = fields.TASKS

      axiosIns.get('1/contacts/threads/tasks', { params })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    GET_DELAYED_TASKS: ({ commit }, payload) => new Promise((resolve, reject) => {
      const { userId } = payload
      const params = {
        limit: 5,
      }

      params.orderBy = 'createdAt.desc'
      params.eq_status = 'O'
      params.eq_assignedUser = userId.toString()
      params.lte_startAt = `${getCurrentDate()} 23:59:59`
      params.fields_load = fields.TASKS

      axiosIns.get('1/contacts/threads/tasks', { params })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    GET_OUR_NOTES: ({ commit }, payload) => new Promise((resolve, reject) => {
      const { userId } = payload
      const params = {
        limit: 5,
      }

      params.orderBy = 'createdAt.desc'
      params['in_contactThread-assignedUsers'] = userId.toString()
      params.fields = 'id,files,content,contactThreadNoteType.id,contactThreadNoteType.icon,contactThreadNoteType.name,contactThread.id,contactThread.name,contactThread.contact.id,contactThread.contact.firstName,contactThread.contact.lastName,contactThread.assignedUsers.id,contactThread.assignedUsers.firstName,contactThread.assignedUsers.lastName,contactThread.assignedUsers.avatar,contactThreadTaskReport.id,contactThreadTaskReport.content,contactThreadTaskReport.createdAt,contactThreadTaskReport.createdBy.id,contactThreadTaskReport.createdBy.firstName,contactThreadTaskReport.createdBy.lastName,createdAt,createdBy.id,createdBy.firstName,createdBy.lastName,createdBy.avatar'

      axiosIns.get('1/contacts/threads/notes', { params })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    GET_ALL_NOTES: ({ commit }, payload) => new Promise((resolve, reject) => {
      const params = {
        limit: 5,
        orderBy: 'createdAt.desc',
        fields: 'id,files,content,contactThreadNoteType.id,contactThreadNoteType.icon,contactThreadNoteType.name,contactThread.id,contactThread.name,contactThread.contact.id,contactThread.contact.firstName,contactThread.contact.lastName,contactThread.assignedUsers.id,contactThread.assignedUsers.firstName,contactThread.assignedUsers.lastName,contactThread.assignedUsers.avatar,createdAt,createdBy.id,createdBy.firstName,createdBy.lastName,createdBy.avatar,files',
      }

      axiosIns.get('1/contacts/threads/notes', { params })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
  },
}
