<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content email-app-sidebar">
        <div class="email-app-menu">
          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="sidebar-menu-list scroll-area"
          >
            <sw-select>
              <v-select
                :clearable="false"
                :options="[10, 25, 50, 100]"
                :value="perPage"
                @input="$emit('set-per-page', $event)"
              />
            </sw-select>

            <!-- Filters -->
            <b-list-group class="list-group-messages">
              <b-list-group-item
                v-for="filter in emailFilters"
                :key="filter.title + $route.path"
                class="cursor-pointer"
                :class="{ 'active': activeStatus === filter.status }"
                @click="$emit('close-left-sidebar'); $emit('set-message-status', filter.status)"
              >
                <!--                <span-->
                <!--                  v-if="filter.status !== 'all'"-->
                <!--                  class="mr-75 bullet bullet-sm"-->
                <!--                  :class="resolveFilterBadgeColor(filter.status).color"-->
                <!--                />-->
                <feather-icon
                  :icon="resolveFilterBadgeColor(filter.status).icon || 'MailIcon'"
                  :class="resolveFilterBadgeColor(filter.status).color"
                  class="mr-50"
                />

                <span class="align-text-bottom line-height-1">{{ $t(`${filter.title}`) }}</span>
              </b-list-group-item>
            </b-list-group>

          </vue-perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BListGroup, BListGroupItem } from 'bootstrap-vue'
import vSelect from 'vue-select'
import * as emailStatuses from '@/@constants/emailStatuses'

export default {
  components: {
    // BSV
    BListGroup,
    BListGroupItem,
    vSelect,
    // 3rd Party
    VuePerfectScrollbar,
  },
  props: {
    perPage: {
      type: Number,
      default: 25,
    },
    activeStatus: {
      type: String,
      default: 'all',
    },
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }

    const emailFilters = [
      { title: 'All', icon: 'GridIcon', status: 'all' },
      { title: 'Inbox', icon: 'MailIcon', status: emailStatuses.RECEIVED },
      { title: 'Sent', icon: 'SendIcon', status: emailStatuses.QUEUE },
      { title: 'Delivered', icon: 'MailIcon', status: emailStatuses.DELIVERED },
      { title: 'Failed', icon: 'SendIcon', status: emailStatuses.FAILED },
      { title: 'Opened', icon: 'SendIcon', status: emailStatuses.OPENED },
    ]

    const resolveFilterBadgeColor = filter => {
      if (filter === emailStatuses.RECEIVED) return { icon: 'EyeIcon', color: 'text-primary' }
      if (filter === emailStatuses.QUEUE) return { icon: 'SendIcon', color: 'text-warning' }
      if (filter === emailStatuses.DELIVERED) return { icon: 'AnchorIcon', color: 'text-success' }
      if (filter === emailStatuses.FAILED) return { icon: 'AlertTriangleIcon', color: 'text-danger' }
      if (filter === emailStatuses.OPENED) return { icon: 'BookOpenIcon', color: 'text-info' }
      return 'bullet-primary'
    }

    return {
      // UI
      perfectScrollbarSettings,
      resolveFilterBadgeColor,

      // Filter & Labels
      emailFilters,
    }
  },
}
</script>

<style lang="scss">
.email-app-hidden-sidebar {

  .content-area-wrapper {
    .sidebar-left {
      position: relative;
      .email-app-sidebar {
        transform: translateX(-110%);
        transition: all 0.3s ease-in-out;
        position: absolute;
        left: 0;
      }
      &.show {
        .email-app-sidebar {
          transform: translateX(0%);
          transition: all 0.3s ease;
        }
      }
    }

    .content-right {
      width: 100% !important;
      border-left: 0;
      .email-app-details {
        width: 100%;
      }
    }
  }
}
</style>
