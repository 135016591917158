<template>
  <div
    role="article"
    aria-roledescription="email"
    aria-label="Default email title"
    lang="en"
  >
    <table
      style="font-family: Montserrat, -apple-system, 'Segoe UI', sans-serif; width: 100%;"
      width="100%"
      cellpadding="0"
      cellspacing="0"
      role="presentation"
    >
      <tr>
        <td
          align="center"
          style="padding-bottom: 20px; --bg-opacity: 1; background-color: #eceff1; background-color: rgba(236, 239, 241, var(--bg-opacity)); font-family: Montserrat, -apple-system, 'Segoe UI', sans-serif;"
          bgcolor="rgba(236, 239, 241, var(--bg-opacity))"
        >
          <table
            class="sm-w-full"
            style="font-family: 'Montserrat',Arial,sans-serif;"
            cellpadding="0"
            cellspacing="0"
            role="presentation"
          >
            <tr>
              <td
                class="sm-py-32 sm-px-24"
                style="font-family: Montserrat, -apple-system, 'Segoe UI', sans-serif; padding: 20px; text-align: center;"
                align="center"
              >
                <img
                  :src="system.logo"
                  width="155"
                  alt=""
                  style="border: 0; max-width: 100%; line-height: 100%; vertical-align: middle;"
                >
              </td>
            </tr>
            <tr>
              <td
                align="center"
                class="sm-px-24"
                style="font-family: 'Montserrat',Arial,sans-serif;"
              >
                <table
                  style="font-family: 'Montserrat',Arial,sans-serif; width: 100%;"
                  width="100%"
                  cellpadding="0"
                  cellspacing="0"
                  role="presentation"
                >
                  <tr>
                    <td
                      class="sm-px-24"
                      style="--bg-opacity: 1; background-color: #ffffff; border-radius: 4px; font-family: Montserrat, -apple-system, 'Segoe UI', sans-serif; font-size: 14px; line-height: 24px; padding: 48px; text-align: left; --text-opacity: 1; color: #626262; color: rgba(98, 98, 98, var(--text-opacity));"
                      align="left"
                    >
                      <!-- Content -->
                      <div class="text-wrap" v-html="templateShortcodesPreview" />
                      <table
                        style="font-family: 'Montserrat',Arial,sans-serif; width: 100%;"
                        width="100%"
                        cellpadding="0"
                        cellspacing="0"
                        role="presentation"
                      >
                        <tr>
                          <td style="font-family: 'Montserrat',Arial,sans-serif; padding-top: 32px; padding-bottom: 32px;">
                            <div style="--bg-opacity: 1; background-color: #eceff1; background-color: rgba(236, 239, 241, var(--bg-opacity)); height: 1px; line-height: 1px;">
                              &zwnj;
                            </div>
                          </td>
                        </tr>
                      </table>
                      <table
                        style="font-family: 'Montserrat',Arial,sans-serif; width: 100%;"
                        width="100%"
                        cellpadding="0"
                        cellspacing="0"
                        role="presentation"
                      >
                        <tr>
                          <td style="font-family: 'Montserrat',Arial,sans-serif; padding-top: 12px; padding-bottom: 12px;">
                            <div v-html="footerShortcodesPreview" />
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { shortcodes } from '@/@data/shortcodes'
import { GET_FOOTER } from '@/@constants/mutations'

export default {
  name: 'EmailPreviewModal',
  props: {
    template: {
      type: String,
      default: '',
      required: true,
    },
  },
  data: () => ({
    shortcodes,
    footerContent: '',
  }),
  computed: {
    ...mapGetters({
      system: 'system/getSettings',
    }),
    templateShortcodesPreview() {
      return this.decodeShortcodes(this.template)
    },
    footerShortcodesPreview() {
      return this.decodeShortcodes(this.footerContent)
    },
  },
  mounted() {
    this.$store.dispatch(`templates/${GET_FOOTER}`)
      .then(res => {
        if (res.data.item.emailFooter) this.footerContent = res.data.item.emailFooter
        else this.footerContent = '<p>Pozdrawiam {% loggedUser.lastName %} {% loggedUser.firstName %},</p><p>Telefon: {% loggedUser.phone %}, Email: {% email %}</p>'
      })
      .catch(err => {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      })
  },
  methods: {
    decodeShortcodes(text) {
      let textCopy = text
      this.shortcodes.forEach(shortcodesContainer => {
        shortcodesContainer.shortcodes.forEach(({ code, sample }) => {
          if (!code.includes('system')) {
            textCopy = textCopy.replaceAll(`{% ${code} %}`, sample)
          } else textCopy = textCopy.replaceAll(`{% ${code} %}`, this.system[code])
        })
      })

      return textCopy
    },
  },
}
</script>

<style scoped>

</style>
