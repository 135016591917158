<template>
  <div>
    <!--    Option: Link    -->
    <b-col
      sm="12"
      md="12"
      lg="8"
      class="px-1"
    >
      <my-option
        :title="$t('LinkYT')"
        :tip="$t('VideoYT')"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="$t('LinkYT')"
          rules="required"
          class="w-100"
        >
          <b-form-group>
            <b-form-input
              v-model.trim="newWebsite.videoLink"
              :placeholder="$t('LinkYT')"
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </my-option>
    </b-col>

    <!--    Option: Title    -->
    <b-row class="mx-0">
      <b-col
        sm="12"
        md="6"
        lg="8"
        class="px-1"
      >
        <my-option
          :title="$t('Title')"
          :tip="$t('MaximumLength') + ': 50'"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="$t('Title')"
            rules="required"
            class="w-100"
          >
            <b-form-group>
              <b-form-input
                id="popover-QAndATitle"
                v-model.trim="newWebsite.videoTitle"
                :placeholder="$t('Title')"
                maxlength="50"
              />
              <small class="text-danger">{{ errors[0] }}</small>

              <!--    Popover    -->
              <b-popover
                target="popover-QAndATitle"
                triggers="focus"
                variant="primary"
              >
                <span>{{ $t('MaximumLength') + ': 50' }}</span>
              </b-popover>
            </b-form-group>
          </validation-provider>
        </my-option>
      </b-col>
    </b-row>

    <!--    Option: Description    -->
    <b-row class="mx-0">
      <b-col
        sm="12"
        md="6"
        lg="8"
        class="px-1"
      >
        <my-option
          :title="$t('offer.product.description')"
          :tip="$t('MaximumLength') + ': 100'"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="$t('offer.product.description')"
            rules="required"
            class="w-100"
          >
            <b-form-group>
              <b-form-textarea
                id="popover-videoDesc"
                v-model.trim="newWebsite.videoDesc"
                :placeholder="$t('offer.product.description')"
                :state="errors.length > 0 ? false:null"
                maxlength="100"
              />
              <small class="text-danger">{{ errors[0] }}</small>

              <!--    Popover    -->
              <b-popover
                target="popover-videoDesc"
                triggers="focus"
                variant="primary"
              >
                <span>{{ $t('MaximumLength') + ': 100' }}</span>
              </b-popover>
            </b-form-group>
          </validation-provider>
        </my-option>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BFormTextarea, BPopover, VBTooltip } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import myOption from '@/views/offers/website/Option.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BFormTextarea,
    myOption,
    ValidationProvider,
    BPopover,
  },

  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  props: {
    value: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    newWebsite: { },
  }),

  mounted() {
    this.newWebsite = this.value
  },

  methods: {
  },
}
</script>
