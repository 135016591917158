<template>
  <div>
    <b-card>
      <h5 class="mb-1">
        {{ $t('templates.OfferTemplate') }}
      </h5>
      <b-row>
        <b-col sm="12">
          <div>
            <b-form-group :label="$t('Title')">
              <b-form-input v-model="translations[0].emailOfferSubject" />
            </b-form-group>

            <quill-editor
              ref="editor"
              v-model="translations[0].emailOfferContent"
              style="min-height: 150px"
              :options="editorOption"
            >
              <div
                id="toolbar"
                slot="toolbar"
              >
                <!-- Add a bold button -->
                <span class="ql-formats">
                  <button class="ql-clean" />
                  <button class="ql-bold" />
                  <button class="ql-italic" />
                  <button class="ql-link" />
                </span>
                <span class="ql-formats">
                  <button
                    class="ql-script"
                    value="sub"
                  />
                  <button
                    class="ql-script"
                    value="super"
                  />
                </span>
                <span class="ql-formats">
                  <button
                    class="ql-list"
                    value="ordered"
                  />
                  <button
                    class="ql-list"
                    value="bullet"
                  />
                </span>
                <span class="ql-formats">
                  <button
                    class="ql-indent"
                    value="-1"
                  />
                  <button
                    class="ql-indent"
                    value="+1"
                  />
                </span>
                <span class="ql-formats">
                  <select class="ql-align" />
                  <select class="ql-color" />
                </span>
                <span class="ql-formats">
                  <select class="ql-size" />
                  <select class="ql-header" />
                  <select class="ql-font" />
                </span>

                <!-- You can also add your own -->
                <span class="ql-formats">
                  <b-button
                    id="custom-button"
                    variant="success"
                    class="w-auto"
                    @click="isModalOpen = true"
                  >
                    <span class="text-white">
                      <feather-icon
                        icon="CodeIcon"
                        class="mr-25"
                      />
                    </span>

                    <span class="text-white">
                      {{ $t('ShortCodes') }}
                    </span>
                  </b-button>
                  <b-button
                    variant="primary"
                    size="sm"
                    class="preview_swiper mr-50 mb-25 ml-50"
                    @click="templatePreview = !templatePreview; previewContent = translations[0].emailOfferContent"
                  >
                    <feather-icon :icon="!templatePreview ? 'EyeIcon' : 'EyeOffIcon'" />
                  </b-button>
                </span>
              </div>
            </quill-editor>
          </div>
        </b-col>
      </b-row>
      <b-overlay
        no-wrap
        :show="loading || getLoading"
        spinner-variant="primary"
      />
    </b-card>
    <b-button
      variant="primary"
      :disabled="!translations[0].emailOfferContent"
      @click="save"
    >
      {{ $t('Save') }}
    </b-button>

    <b-modal
      :visible="isModalOpen"
      :title="$t('Codes')"

      size="lg"
      hide-footer
      no-close-on-backdrop
      @hide="isModalOpen = false"
    >
      <shortcodes-modal
        @close-modal="isModalOpen = false"
      />
    </b-modal>

    <transition name="fade">
      <div
        v-if="scrollPos && scrollPos.y.value > 200"
        class="btn position-fixed mr-25"
        style="z-index: 999; bottom: 4.4rem; right: 0; transition-delay: .14s;"
      >
        <b-button
          variant="success"
          class="btn-icon rounded-circle"
          @click.prevent.stop="isModalOpen = true"
        >
          <feather-icon
            icon="CodeIcon"
            size="16"
          />
        </b-button>
      </div>
    </transition>

    <b-modal
      v-model="templatePreview"
      hide-footer
      :title="$t('Preview')"
      size="lg"
    >
      <div style="overflow: auto">
        <email-preview-modal :template="previewContent" />
      </div>
    </b-modal>
  </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'
import { mapGetters } from 'vuex'
import { GET_OFFER_EMAIL } from '@/@constants/mutations'
import EmailPreviewModal from '@/views/modals/EmailPreviewModal.vue'
import ShortcodesModal from '@/views/modals/ShortcodesModal.vue'
import { useWindowScroll } from '@vueuse/core'

export default {
  components: {
    quillEditor,
    ShortcodesModal,
    EmailPreviewModal,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    scrollPos: 0,
    isModalOpen: false,
    getLoading: false,
    selectPosition: 0,
    editorOption: {
      modules: {
        toolbar: '#toolbar',
      },
    },
    defaultContent: '<p>Pozdrawiam {% loggedUser.lastName %} {% loggedUser.firstName %},</p><p>Telefon: {% loggedUser.phone %}, Email: {% email %}</p>',
    translations: [
      { locale: 'pl_pl', emailOfferContent: '', emailOfferSubject: '' },
    ],
    previewContent: '',
    templatePreview: false,
  }),
  computed: {
    ...mapGetters({
      shortcodes: 'templates/getShortcodes',
    }),
  },
  mounted() {
    this.getLoading = true

    this.$store.dispatch(`templates/${GET_OFFER_EMAIL}`)
      .then(res => {
        if (res.data.item?.translations?.length) {
          this.$set(this.translations[0], 'emailOfferContent', res.data.item.translations[0].emailOfferContent)
          this.$set(this.translations[0], 'emailOfferSubject', res.data.item.translations[0].emailOfferSubject)
        } else this.translations[0].emailOfferContent = this.defaultContent
        this.getLoading = false
      })
      .catch(err => {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        this.getLoading = false
      })

    this.scrollPos = useWindowScroll()
  },
  methods: {
    onShow() {
      const range = this.$refs.editor.quill.getSelection()
      if (range && range.index) this.selectPosition = range.index
    },
    onHidden() {
      this.selectPosition = 0
    },
    save() {
      this.$emit('save', this.translations)
    },
  },
}
</script>

<style lang="scss">
.ql-container {
  min-height: 200px;
}
</style>
