<template>
  <b-card>
    <b-tabs pills>
      <b-tab
        v-for="(tab, index) in tabs"
        :key="`setting_tab_${index}`"
        @click="tw"
      >
        <template #title>
          <span>{{ $t(`${tab.title}`) }}</span>
          <div
            v-if="!tab.isSaved"
            v-b-tooltip.hover.v-warning
            :title="$t('UnsavedChanges')"
          >
            <feather-icon
              class="ml-50"
              icon="AlertTriangleIcon"
            />
          </div>
        </template>

        <component
          :is="tab.component"
          :ref="tab.ref"
        />
      </b-tab>

      <b-tab>
        <template #title>
          <span>{{ $t('AdditionalInformation') }}</span>
        </template>

        <additional-information />
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>

import { BTab, BTabs, VBTooltip } from 'bootstrap-vue'
import ThreadTags from '@/views/core/admin/adminSettingsTabs/ThreadTags.vue'
import ContactStatus from '../../views/core/admin/adminSettingsTabs/ContactStatus.vue'
import AdditionalInformation from './contacts/AdditionalInformation.vue'

export default {
  name: 'ContactSettings',
  components: {
    ContactStatus,
    ThreadTags,
    BTabs,
    BTab,
    AdditionalInformation,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data: () => ({
    tabs: [
      {
        title: 'ContactStatuses', ref: 'setting_contact_status', component: 'contact-status', isSaved: true,
      },
      {
        title: 'ThreadTags', ref: 'setting_thread_tags', component: 'thread-tags', isSaved: true,
      },
    ],
  }),
  methods: {
    tw() {
      this.tabs.forEach((tab, index) => {
        this.tabs[index].isSaved = this.$refs[tab.ref][0].areChangesSaved
      })
    },
  },
}
</script>

<style scoped>

</style>
