<template>
  <div v-if="item">
    <b-row>
      <b-col
        sm="12"
        md="6"
      >
        <sw-select
          v-if="checkRequiredModule('orderModule')"
          :name="$t('Order')"
        >
          <v-select
            v-model="item.contactThreadCartOrder"
            :options="orders"
            :filterable="false"
            @search="searchOrders"
            @option:selected="selectOption"
            @option:deselecting="selectOption(null)"
          >
            <template slot="no-options">
              {{ $t('typeToSearchContact') }}
            </template>
            <template #option="{ number }">
              {{ number }}
            </template>

            <template #selected-option="{ number }">
              {{ number }}
            </template>
          </v-select>
        </sw-select>
      </b-col>
      <b-col
        sm="12"
        md="6"
      >
        <sw-select :name="$t('ContactThread')">
          <v-select
            id="offer-thread-1"
            v-model="item.contactThread"
            :options="threads"
            label="name"
            :filterable="false"
            @search="getThreads"
            @option:selected="selectThreadOption"
            @option:deselecting="selectThreadOption(null)"
          >
            <template #no-options="{ search }">
              <span v-if="search.length">
                {{ $t('NoData') }}
              </span>
              <span v-else>
                {{ $t('TypeToSearch') }}
              </span>
            </template>
            <template #option="{ name, contact }">
              <div class="d-flex align-items-center">
                <avatar
                  :user="contact"
                  class="mr-25"
                />  - {{ name }}
              </div>
            </template>

            <template #selected-option="{ name, contact }">
              <div class="d-flex">
                <avatar
                  :user="contact"
                  class="mr-25"
                />  - {{ name }}
              </div>
            </template>
          </v-select>
        </sw-select>
      </b-col>
    </b-row>

    <div class="divider text-primary">
      <div class="divider-text">
        {{ $t('BasicInformation') }}
      </div>
    </div>

    <b-row>
      <b-col sm="6">
        <b-form-group :label="$t('OrderNumber')">
          <b-form-input v-model="item.orderNumber" />
        </b-form-group>
      </b-col>

      <b-col sm="6">
        <sw-select :name="$t('OrderDate')">
          <sw-select-fpr
            :is-empty="item.orderDate"
            @clear="item.orderDate = ''"
          >
            <flat-pickr
              v-model="item.orderDate"
              :config="{ locale: getCalendarLocale($i18n.locale) }"
              class="form-control"
            />
          </sw-select-fpr>
        </sw-select>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        sm="12"
        md="6"
      >
        <b-form-group :label="$t('FirstName')">
          <b-form-input v-model="item.firstName" />
        </b-form-group>
      </b-col>
      <b-col
        sm="12"
        md="6"
      >
        <b-form-group :label="$t('LastName')">
          <b-form-input v-model="item.lastName" />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        sm="12"
        md="6"
      >
        <b-form-group :label="$t('Email')">
          <b-form-input v-model="item.email" />
        </b-form-group>
      </b-col>
      <b-col
        sm="12"
        md="6"
      >
        <b-form-group class="mb-0"
                      :label="$t('Phone')"
        >
          <b-input-group>
            <b-input-group-prepend>
              <sw-select>
                <v-select
                  v-model="item.countryCode"
                  :options="countryCodes"
                  label="dial_code"
                  style="min-width: 125px"
                  :clearable="false"
                />
              </sw-select>
            </b-input-group-prepend>
            <cleave
              v-model="item.phone"
              :options="{
                delimiter: ' ',
                numeral: true,
                blocks: [3, 3, 3],
              }"
              class="form-control rounded"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        md="6"
        lg="4"
      >
        <b-form-group :label="$t('Country')">
          <b-form-input
            id="basicInput"
            v-model="item.addressCountry"
          />
        </b-form-group>
      </b-col>

      <b-col
        md="6"
        lg="4"
      >
        <b-form-group :label="$t('City')">
          <b-form-input
            id="basicInput"
            v-model="item.addressCity"
          />
        </b-form-group>
      </b-col>

      <b-col
        md="6"
        lg="4"
      >
        <b-form-group :label="$t('contact.postalCode')">
          <b-form-input
            id="basicInput"
            v-model="item.addressPostalCode"
            class="form-control"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="6">
        <b-form-group :label="$t('Street')">
          <b-form-input
            id="basicInput"
            v-model="item.addressStreet"
          />
        </b-form-group>
      </b-col>

      <b-col md="6">
        <b-form-group :label="$t('BuildingNumber')">
          <b-form-input
            id="basicInput"
            v-model="item.addressNumber"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-form-checkbox
      v-if="!isEditMode"
      v-model="addNote"
      switch
      variant="primary"
    >
      {{ $t('AddNote') }}
    </b-form-checkbox>

    <transition name="fade">
      <b-form-group
        v-if="addNote || isEditMode"
        :label="$t('Note')"
      >
        <b-form-textarea v-model="item.note">
          {{ item.note }}
        </b-form-textarea>
      </b-form-group>
    </transition>

    <b-button
      variant="primary"
      size="sm"
      :disabled="loading"
      @click="saveItem"
    >
      {{ $t('Save') }}
    </b-button>
  </div>
  <div
    v-else
    class="d-flex justify-content-center"
  >
    <b-spinner
      variant="primary"
      class="m-2"
    />
  </div>
</template>

<script>
import axiosIns from '@/libs/axios'
import vSelect from 'vue-select'
import { BFormTextarea } from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import keysCuter from '@/helpers/keysCuter'
import { countryCodes } from '@/@data/countryCodes'
import Cleave from 'vue-cleave-component'

export default {
  name: 'ComplainForm',
  components: {
    vSelect,
    BFormTextarea,
    flatPickr,
    Cleave,
  },
  props: {
    editedItem: {
      default: null,
      required: true,
      type: Object,
    },
  },
  data: () => ({
    item: null,
    loading: false,

    orders: [],
    threads: [],

    addNote: false,
    countryCodes,
  }),
  computed: {
    isEditMode() {
      return Boolean(this.item?.id)
    },
  },
  async mounted() {
    this.loading = true
    if (this.editedItem?.id) {
      await this.loadItem()
    } else {
      this.item = this.editedItem
    }
    this.loading = false
  },
  methods: {
    async loadItem() {
      const { id } = this.editedItem

      try {
        const params = {
          fields_load: 'COMPLAINT_FULL',
          eq_id: id,
        }
        const resp = await axiosIns.get('1/contacts/threads/complaint', { params })
        if (resp.data.data.items) {
          [this.item] = resp.data.data.items

          if (this.item?.orderDate) {
            [this.item.orderDate] = this.item.orderDate.date.split(' ')
          }

          if (this.item.phoneNumber?.phone) {
            const { countryCode, phone } = this.getFormattedPhone(this.item.phoneNumber.phone)
            this.item.countryCode = countryCode
            this.item.phone = phone
          }

          if (this.item.mailAddress?.mail) {
            this.item.email = this.item.mailAddress.mail
          }
        }
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },

    closeModal(toReturn) {
      this.$emit('close-modal', toReturn)
      this.item = null
      this.addNote = false
      this.orders = []
    },

    async saveItem() {
      this.loading = true
      try {
        let payload = {
          ...this.item,
          contactThread: this.getObjectId(this.item.contactThread),
        }

        if (this.checkRequiredModule('orderModule')) {
          payload.contactThreadCartOrder = this.getObjectId(payload.contactThreadCartOrder)
        }

        if (!payload.status) {
          payload.status = 'NEW'
        }

        if (payload.phone) {
          payload.phoneNumber = `${payload.countryCode?.dial_code || payload.countryCode} ${payload.phone.replaceAll(' ', '')}`.replaceAll('  ', ' ')
        } else {
          payload.phoneNumber = ''
        }

        if (payload.email) {
          payload.mailAddress = payload.email
        } else {
          payload.mailAddress = ''
        }

        payload = keysCuter(payload, ['phone', 'email', 'mail'])

        // eslint-disable-next-line no-unreachable
        const resp = await axiosIns({
          url: '1/contacts/threads/complaint',
          method: payload.id ? 'PATCH' : 'POST',
          data: payload,
        })

        this.closeModal(resp)
        this.showToast('success', this.$i18n.t('success.contactUpdated'))
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.loading = false
      }
    },

    async searchOrders(search, isLoading) {
      this.orders = await this.selectSearch(search, isLoading, this.$fields.ORDERS, 'number,contactThreadCartBox-contactThread-contact-lastName,contactThreadCartBox-contactThread-contact-firstName,contactThreadCartBox-name', '1/contacts/threads/carts/orders', 20)
    },

    async getThreads(search, loading) {
      this.threads = await this.selectSearch(search, loading, this.$fields.CONTACT_SINGLE_THREAD, 'name,contact-firstName,contact-lastName', '1/contacts/threads', 20)
    },

    selectOption(event) {
      if (!event) return

      this.item.contactThread = event.contactThreadCartBox?.contactThread
      this.item.addressCity = event.deliveryAddressCity
      this.item.addressCountry = event.deliveryAddressCountry
      this.item.addressNumber = event.deliveryAddressNumber
      this.item.addressPostalCode = event.deliveryAddressPostalCode
      this.item.addressStreet = event.deliveryAddressStreet
      this.item.firstName = event.contactThreadCartBox?.contactThread?.contact?.firstName || ''
      this.item.lastName = event.contactThreadCartBox?.contactThread?.contact?.lastName || ''
      this.item.email = event.contactThreadCartBox?.contactThread?.contact?.email || ''
      this.item.orderNumber = event.number
      this.item.orderDate = event.deliveryAddressStreet

      const { countryCode, phone } = this.getFormattedPhone(event.contactThreadCartBox?.contactThread?.contact?.phone || '')
      this.item.countryCode = countryCode
      this.item.phone = phone
    },
    selectThreadOption(event) {
      if (!event) return

      this.item.addressCity = event.contact?.addressCity
      this.item.addressCountry = event.contact?.addressCountry
      this.item.addressNumber = event.contact?.addressNumber
      this.item.addressPostalCode = event.contact?.addressPostalCode
      this.item.addressStreet = event.contact?.addressStreet
      this.item.firstName = event.contact?.firstName || ''
      this.item.lastName = event.contact?.lastName || ''
      this.item.email = event.contact?.email || ''
      this.item.orderNumber = ''
      this.item.orderDate = ''
      this.item.contactThreadCartOrder = ''

      const { countryCode, phone } = this.getFormattedPhone(event.contact?.phone || '')
      this.item.countryCode = countryCode
      this.item.phone = phone
    },
    getFormattedPhone(phone = '') {
      let countryCode = '+48 '
      let phoneNumber = ''

      if (phone) {
        if (phone.includes('+')) {
          const [countryCodeFragment, phoneFragment] = phone.split(' ')
          if (countryCodeFragment?.includes('+')) {
            countryCode = countryCodeFragment
          }

          if (phoneFragment) {
            phoneNumber = phoneFragment
          }
        }
      }

      return { countryCode, phone: phoneNumber }
    },
  },
}
</script>

<style scoped>

</style>
