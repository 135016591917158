import Vue from 'vue'
import {
  CLOSE_FOLDER, CREATE_FOLDER, GET_CONTACT_DOCUMENTS, OPEN_FOLDER,
} from '@/@constants/mutations'
// eslint-disable-next-line import/no-cycle
import axiosIns from '@/libs/axios'
import * as roles from '@/helpers/permissions'
import * as fields from '@/@constants/fields'

export default {
  namespaced: true,
  state: {
    // Db
    contactFolders: [],
    userFolders: [],
    currentUserFolders: [],
    openedFolderDocuments: [],
    // Db
    folders: [
      {
        id: 1,
        name: 'Tasks',
        loading: false,
        requiredPermissions: [
          roles.CONTACT_THREAD_TASK_VIEW_ASSIGNED,
          roles.CONTACT_THREAD_TASK_VIEW_ALL,
          roles.CONTACT_THREAD_TASK_VIEW_STRUCTURE,
        ],
      },
      {
        id: 2,
        name: 'Notes',
        loading: false,
        requiredPermissions: [
          roles.CONTACT_THREAD_NOTE_VIEW_SHARED,
          roles.CONTACT_THREAD_NOTE_VIEW_SELF,
          roles.CONTACT_THREAD_NOTE_VIEW_ALL,
        ],
      },
      {
        id: 3,
        name: 'TasksReports',
        loading: false,
        requiredPermissions: [
          roles.CONTACT_THREAD_TASK_VIEW_ASSIGNED,
          roles.CONTACT_THREAD_TASK_VIEW_ALL,
          roles.CONTACT_THREAD_TASK_VIEW_STRUCTURE,
        ],
      },
      {
        id: 4,
        name: 'Email',
        loading: false,
        requiredPermissions: [
          roles.CONTACT_THREAD_MAIL_VIEW_ALL,
        ],
      },
    ],
    selectedActionFolder: null,
    openedFolder: null,

    // Documents
    folderDocuments: [],
  },
  getters: {
    getFolders: state => state.folders,
    getOpenedFolder: state => state.openedFolder,
    getDocuments: state => state.folderDocuments,
  },
  mutations: {
    [OPEN_FOLDER](state, payload) {
      Vue.set(state, 'openedFolder', payload.folder)
      Vue.set(state, 'folderDocuments', payload.files)
      // Api request - get folder files by payload folder id
    },
    [CLOSE_FOLDER](state) {
      state.openedFolder = null
      state.folderDocuments = null
    },
    [CREATE_FOLDER]() {
      // console.log(state, payload)
    },
    // DB
    // eslint-disable-next-line no-unused-vars
    [GET_CONTACT_DOCUMENTS](state, payload) {
      // console.log(state, payload)
    },
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    [OPEN_FOLDER]: ({ commit }, payload) => new Promise((resolve, reject) => {
      // eslint-disable-next-line no-unused-vars
      const { threadId, folder, contactId } = payload

      const params = {
        fields_load: fields.FOLDER_FILES,
      }

      let path = ''
      if (threadId) {
        if (folder.id === 1) path = `1/contacts/threads/${threadId}/tasks`
        if (folder.id === 2) path = `1/contacts/threads/${threadId}/notes`
        if (folder.id === 3) path = `1/contacts/${contactId}/threads/${threadId}/tasks/reports`
        if (folder.id === 4) path = `1/contacts/threads/${threadId}/conversations/mails`
      } else {
        if (folder.id === 1) path = '1/contacts/threads/tasks'
        if (folder.id === 2) path = '1/contacts/threads/notes'
        if (folder.id === 3) path = '1/contacts/threads/tasks/reports'
        if (folder.id === 4) path = '1/contacts/threads/conversations/mails'
      }

      params['isNotNull_files-id'] = ''

      const preparedFiles = []
      axiosIns.get(path, { params })
        .then(res => {
          const files = res.data.data.items.map(item => ({
            createdBy: item.createdBy,
            createdAt: item.createdAt,
            files: item.files,
          }))
          files.forEach(file => {
            file.files.forEach(f => {
              preparedFiles.push({ file: f, createdAt: file.createdAt, createdBy: file.createdBy })
            })
          })
          resolve(preparedFiles)
        })
        .catch(err => {
          reject(err)
        })
        .finally(() => {
          commit(OPEN_FOLDER, { folder, files: preparedFiles })
        })
    }),
    [GET_CONTACT_DOCUMENTS]: ({ commit }) => new Promise(() => {
      commit(GET_CONTACT_DOCUMENTS)
    }),
  },
}
