<template>
  <div>
    <div class="pb-50 w-100 d-flex align-items-center justify-content-between">
      <div class="flex-grow-1">
        <b-button
          size="sm"
          class="mr-50"
          @click="closeFolder"
        >
          <feather-icon icon="ChevronLeftIcon" />{{ $t('Return') }}
        </b-button>
        <span class="font-medium-1 font-weight-bold">{{ $t('SelectedFolder') }}: {{ $t(`${folder.name}`) }}</span>
      </div>

      <div>
        <sw-select>
          <v-select
            v-model="pagination.perPage"
            style="min-width: 100px;"
            :options="[10, 25, 50, 100, 250]"
            :clearable="false"
            class="per-page-selector d-inline-block"
          />
        </sw-select>
      </div>
    </div>
    <b-table
      ref="selectableTable"
      selectable
      select-mode="multi"
      :items="documents"
      :fields="fields"
      :per-page="pagination.perPage"
      :current-page="pagination.currentPage"
      responsive
      striped
      show-empty
      @row-selected="onRowSelected"
    >
      <template #head(selected)>
        <b-form-checkbox
          variant="primary"
          :checked="documents.length === selectedFiles.length"
          @change="changeAllSelection"
        />
      </template>
      <template #cell(selected)="rowSelected">
        <b-form-checkbox
          v-model="selectedFiles"
          variant="primary"
          :value="rowSelected.item"
          @change="changeSelection(rowSelected.rowSelected, rowSelected.index)"
        />
      </template>
      <template #cell(file)="data">
        <div class="d-flex align-items-center">
          <documents-list :documents="[data.value]" />
          <!--          <feather-icon-->
          <!--            class="pull-up mr-25"-->
          <!--            :icon="data.value.extension === 'pdf' ? 'FileIcon': 'ImageIcon'"-->
          <!--            size="23"-->
          <!--          />-->
          <span class="ml-25">{{ data.value.name }}</span>
        </div>
      </template>
      <template #cell(createdAt)="data">
        {{ data.value.date.split('.')[0] }}
      </template>

      <template #cell(imageRecognise)="{ item }">
        <div v-if="item.file.extension.includes('webp')">
          <div
            v-if="Array.isArray(item.file.imageRecognitionNames) && item.file.imageRecognitionNames.length"
            class="d-flex flex-wrap"
            style="max-width: 200px"
          >
            <b-badge
              v-for="(name, nameIndex) in item.file.imageRecognitionNames"
              :key="`image_recognition_name_${nameIndex}`"
              block
              class="mr-25 flex-grow-1 mt-25"
              :class="{ 'w-100': nameIndex === 0 }"
              :variant="'light-primary'"
            >
              <span v-if="nameIndex === 0">🥇</span>
              <span v-else-if="nameIndex === 1">🥈</span>
              <span v-else-if="nameIndex === 2">🥉</span>

              {{ name }}
            </b-badge>
          </div>
          <span v-else>{{ $t('ImageNotRecognised') }}</span>
        </div>
        <feather-icon
          v-else
          icon="MinusIcon"
        />
      </template>

      <template #cell(createdBy)="data">
        <div
          v-if="data.value"
          class="d-flex align-items-center"
        >
          <b-avatar
            v-b-tooltip
            class="pull-up"
            :title="data.value.firstName + ' ' + data.value.lastName"
            :src="data.value.avatar"
            :text="data.value.firstName.slice(0,1).toUpperCase() + data.value.lastName.slice(0,1).toUpperCase()"
          />
        </div>
        <div v-else>
          <feather-icon icon="MinusIcon" />
        </div>
      </template>
      <template
        #cell(action)="data"
      >
        <div
          :id="data.id"
          class="text-center"
        >
          <b-dropdown
            id="dropdown-grouped"
            variant="flat-primary"
            class="dropdown-icon-wrapper"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="20"
                class="align-middle"
              />
            </template>
            <b-dropdown-item>
              {{ $t('Delete') }}
            </b-dropdown-item>
            <!--            <b-dropdown-item>-->
            <!--              Przenieś-->
            <!--            </b-dropdown-item>-->
            <!--            <b-dropdown-item>-->
            <!--              Udostępnianie-->
            <!--            </b-dropdown-item>-->
          </b-dropdown>
        </div>
      </template>
      <template #empty>
        <div
          class="text-center py-1 text-primary font-weight-bold"
        >
          <feather-icon
            size="17"
            icon="XCircleIcon"
          />
          {{ $t('NoData') }}
        </div>
      </template>
    </b-table>
    <!-- Pagination -->
    <b-pagination
      v-model="pagination.currentPage"
      class="pt-1"
      :total-rows="rows"
      :per-page="pagination.perPage"
      align="center"
    />
    <!-- navigation files -->
    <transition name="fade">
      <div
        v-if="selectedFiles.length"
        style="position: fixed; right: 60px; bottom: 24px"
        class="d-flex"
      >
        <b-button
          variant="success"
          class="d-flex align-items-center mr-1"
          @click="downloadAll"
        >
          <feather-icon icon="DownloadIcon" />
          <span class="pl-25 d-sm-flex d-none align-items-center">
            {{ $t('Download') }}
          </span>
        </b-button>
        <!--        <b-button-->
        <!--          variant="primary"-->
        <!--          class="d-flex align-items-center mr-1"-->
        <!--        >-->
        <!--          <feather-icon icon="Share2Icon" />-->
        <!--          <span class="pl-25 d-sm-flex d-none align-items-center">-->
        <!--            {{ $t('Share') }}-->
        <!--          </span>-->
        <!--        </b-button>-->
        <!--        <b-button-->
        <!--          variant="danger"-->
        <!--          class="d-flex align-items-center mr-1"-->
        <!--        >-->
        <!--          <feather-icon icon="TrashIcon" />-->
        <!--          <span class="pl-25 d-sm-flex d-none align-items-center">-->
        <!--            {{ $t('Delete') }}-->
        <!--          </span>-->
        <!--        </b-button>-->
      </div>
    </transition>
    <!--    <b-modal-->
    <!--      id="modal-share-folder"-->
    <!--      :title="'Udostępnij folder'"-->
    <!--      ok-only-->
    <!--      :ok-title="$t('Share')"-->
    <!--      ok-variant="info"-->
    <!--    >-->
    <!--      <span>Wybierz użytkowników</span>-->
    <!--      <v-select-->
    <!--        v-model="shareForUsers"-->
    <!--        multiple-->
    <!--        :close-on-select="false"-->
    <!--        :options="options"-->
    <!--      >-->

    <!--        <template #option="{ avatar, name }">-->
    <!--          <b-avatar-->
    <!--            size="sm"-->
    <!--            :src="avatar"-->
    <!--          />-->
    <!--          <span class="ml-50"> {{ name }}</span>-->
    <!--        </template>-->

    <!--        <template #selected-option="{ avatar, name }">-->
    <!--          <b-avatar-->
    <!--            size="sm"-->
    <!--            class="border border-white"-->
    <!--            :src="avatar"-->
    <!--          />-->
    <!--          <span class="ml-50"> {{ name }}</span>-->
    <!--        </template>-->
    <!--      </v-select>-->
    <!--    </b-modal>-->
    <!--  Add Document Modal  -->
    <!--    <b-modal-->
    <!--      id="modal-add-document"-->
    <!--      title="Dodaj dokuemnt"-->
    <!--      ok-only-->
    <!--      :ok-title="$t('Add')"-->
    <!--      ok-variant="success"-->
    <!--      @ok="createNewDocument"-->
    <!--    >-->
    <!--      <b-form-file-->
    <!--        v-model="newDocument"-->
    <!--        :browse-text="$t('Search')"-->
    <!--        placeholder="Wybierz lub przeciągnij plik"-->
    <!--        drop-placeholder="Upuść plik..."-->
    <!--      />-->
    <!--      <div-->
    <!--        v-if="newDocument"-->
    <!--        class="mt-1"-->
    <!--      >-->
    <!--        <span>Nazwa pliku</span>-->
    <!--        <b-form-input v-model="newDocument.name" />-->
    <!--      </div>-->
    <!--    </b-modal>-->
    <!--  Add Document Modal  -->
    <!-- folder nav -->
    <!--    <transition name="fade">-->
    <!--      <div-->
    <!--        v-if="selectedFiles.length"-->
    <!--        style="position: fixed; right: 20px; bottom: 20px"-->
    <!--        class="d-flex"-->
    <!--      >-->
    <!--        <b-button-->
    <!--          variant="success"-->
    <!--          class="d-flex align-items-center mr-1"-->
    <!--        >-->
    <!--          <feather-icon icon="DownloadIcon" />-->
    <!--          <span class="pl-25 d-sm-flex d-none align-items-center">-->
    <!--            {{ $t("Download") }}-->
    <!--          </span>-->
    <!--        </b-button>-->
    <!--        <b-button-->
    <!--          v-b-modal.modal-share-folder-->
    <!--          variant="primary"-->
    <!--          class="d-flex align-items-center mr-1"-->
    <!--        >-->
    <!--          <feather-icon icon="Share2Icon" />-->
    <!--          <span class="pl-25 d-sm-flex d-none align-items-center">-->
    <!--            {{ $t("Share") }}-->
    <!--          </span>-->
    <!--        </b-button>-->
    <!--        <b-button-->
    <!--          variant="danger"-->
    <!--          class="d-flex align-items-center mr-1"-->
    <!--        >-->
    <!--          <feather-icon icon="TrashIcon" />-->
    <!--          <span class="pl-25 d-sm-flex d-none align-items-center">-->
    <!--            {{ $t("Delete") }}-->
    <!--          </span>-->
    <!--        </b-button>-->
    <!--      </div>-->
    <!--    </transition>-->
    <!-- share folder modal -->
  </div>
</template>

<script>
import {
  BAvatar, BPagination, BTable, VBTooltip,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import { CLOSE_FOLDER, DOWNLOAD_FILE } from '@/@constants/mutations'
import DocumentsList from '@/views/components/DocumentsList.vue'

export default {
  name: 'FolderDocuments',
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    DocumentsList,
    BTable,
    BAvatar,
    BPagination,
    vSelect,
  },
  data: vm => ({
    loading: false,
    selectedFiles: [],
    newFolderName: '',
    newDocument: [],
    shareForUsers: [],
    sorter: {
      sortBy: 'createdAt',
      sortDesc: true,
    },
    pagination: {
      perPage: 10,
      currentPage: 1,
    },
    documentFields: [
      {
        key: 'selected',
      },
      {
        key: 'imageRecognise',
        label: vm.$i18n.t('Action'),
        imageRecognise: true,
      },
      {
        key: 'file',
        label: vm.$i18n.t('documents.Name'),
        sortable: true,
      },
      {
        key: 'createdAt',
        label: vm.$i18n.t('documents.CreatedTime'),
        sortable: true,
      },
      {
        key: 'createdBy',
        label: vm.$i18n.t('documents.Creator'),
      },
    ],
  }),
  computed: {
    ...mapGetters({
      documents: 'documents/getDocuments',
      folder: 'documents/getOpenedFolder',
    }),
    rows() {
      return this.documents.length
    },
    allowImageRecognise() {
      return this.$store.getters['system/getPrivateSettings'].imageRecognitionPlantNetEnabled && this.checkRequiredModule('imageRecognitionModule') && this.checkRequiredPermissions([this.$roles.IMAGE_RECOGNITION_USE])
    },
    fields() {
      return this.documentFields.filter(f => !f.imageRecognise || (f.imageRecognise && this.allowImageRecognise))
    },
  },
  methods: {
    downloadFile(fileToken, filename_, download = true) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(`uploader/${DOWNLOAD_FILE}`, { fileToken, filename: filename_ })
          .then(res => {
            const { data, filename } = res
            const base = `data:${data.type};base64,${data.file}`

            if (download) {
              const a = document.createElement('a')
              a.href = base
              a.setAttribute('target', '_top')
              a.download = filename
              setTimeout(() => {
                a.click()
              }, 50)
            }

            resolve({ base, filename, baseContent: data.file })
          })
          .catch(() => {
            reject()
          })
      })
    },
    async downloadAll() {
      if (this.selectedFiles.length === 1) {
        const fileItem = this.selectedFiles[0]
        await this.downloadFile(fileItem.file.token, `${fileItem.file.name}.${fileItem.file.extension}`)
        return
      }

      const bases = await Promise.all(this.selectedFiles.map(({ file }) => this.downloadFile(file.token, `${file.name}.${file.extension}`, false)))
      this.downloadZip(bases)
    },
    // downloadDocuments() {
    //   this.selectedFiles.forEach(({ file }) => {
    //     this.$store.dispatch(`uploader/${DOWNLOAD_FILE}`, { fileToken: file.token, filename: file.name })
    //       .then(res => {
    //         const { data, filename } = res
    //         const a = document.createElement('a')
    //         a.href = `data:${data.type};base64,${data.file}`
    //         a.download = filename
    //         a.click()
    //       })
    //   })
    // },
    closeFolder() {
      this.$store.commit(`documents/${CLOSE_FOLDER}`)
    },
    changeSelection(e, index) {
      if (!e) this.$refs.selectableTable.selectRow(index)
      else this.$refs.selectableTable.unselectRow(index)
    },
    changeAllSelection() {
      if (this.documents.length === this.selectedFiles.length) this.$refs.selectableTable.clearSelected()
      else this.$refs.selectableTable.selectAllRows()
    },
    onRowSelected(items) {
      this.selectedFiles = items
    },
  },
}
</script>

<style scoped>

</style>
