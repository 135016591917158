<template>
  <validation-observer tag="form">
    <b-row>
      <!--    Form: Validity     -->
      <b-col
        sm="12"
        md="6"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="$t('contact.validityOfferTerm')"
        >
          <b-form-group
            v-b-tooltip.hover="$t('offer.blockedProduct')"
            :label="$t('contact.validityOfferTerm') + ` (${$tc('Days', 2)})`"
          >
            <cleave
              v-model="cart.offerValidityDays"
              :options="cleaveOptions.number"
              :placeholder="$t('contact.validityOfferTerm') + ` (${$tc('Days', 2)})`"
              :state="errors.length > 0 ? false:null"
              class="form-control"
              @blur="changeSettings($event, 'offerValidityDays')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>

      <b-col sm="12">
        <div class="mb-50">
          <b-form-checkbox
            v-model="selectOfferWebsite"
            inline
            disabled
            variant="primary"
            @change="changeOfferWebsite"
          >
            {{ $t('Offer.CreateListingWebsite') }}
          </b-form-checkbox>
        </div>

        <sw-select>
          <v-select
            v-if="selectOfferWebsite"
            id="tester"
            key="wtf"
            v-model="selectedOfferWebsite"
            input-id="offer-thread-2"
            :options="websites"
            label="name"
            @input="changeSettings($event, 'offerWebTemplate')"
          >
            <template #no-options>
              {{ $t('NoData') }}
            </template>
            <template #option="item">
              {{ item.name }}
            </template>
            <template #selected-option="item">
              {{ item.name }}
            </template>
          </v-select>
        </sw-select>

        <b-form-checkbox
          v-if="selectedOfferWebsite"
          v-model="hideOfferProductPrice"
          checked="true"
          name="check-button"
          inline
          variant="primary"
          class="w-100 mt-50"
          :disabled="!checkRequiredPermissions([$roles.CONTACT_THREAD_CART_HIDE_PRICE_MODIFY])"
          @change="changeSettings(hideOfferProductPrice, 'hideOfferProductPrice')"
        >
          {{ $t('offer.product.HidePrices') }}
        </b-form-checkbox>

        <b-form-checkbox
          v-if="selectedOfferWebsite"
          v-model="hideOfferTotalPrice"
          checked="false"
          name="check-button"
          inline
          variant="primary"
          class="mt-50"
          :disabled="!checkRequiredPermissions([$roles.CONTACT_THREAD_CART_HIDE_PRICE_MODIFY])"
          @change="changeSettings(hideOfferTotalPrice, 'hideOfferTotalPrice')"
        >
          {{ $t('offer.product.HideTotalPrice') }}
        </b-form-checkbox>
      </b-col>
      <!-- Fiels uploader -->

      <b-col
        sm="12"
        class="mt-75"
      >
        <b-form-checkbox
          v-model="cart.sendEmail"
          variant="primary"
          :disabled="$store.state.cart.contactThread && $store.state.cart.contactThread.contact && !$store.state.cart.contactThread.contact.email"
          @change="changeSettings($event, 'sendEmail')"
        >
          <div class="d-flex">
            {{ $t('Offer.SendToClient') }}
            <div
              v-b-tooltip.hover.bottom
              class="ml-50"
              :title="$t('Offer.Tips.SendToClient')"
            >
              <feather-icon icon="InfoIcon" />
            </div>
          </div>
        </b-form-checkbox>

        <b-alert
          v-if="$store.state.cart.contactThread && $store.state.cart.contactThread.contact && !$store.state.cart.contactThread.contact.email"
          class="mt-50"
          show
          variant="primary"
        >
          <div class="alert-body">
            {{ $t('ContactDontHaveAssignedEmailToSendOffer') }}
          </div>
        </b-alert>

        <div
          v-if="allowEditingOfferEmail && cart.sendEmail"
          class="mt-50"
        >
          <b-form-group :label="$t('EditEmailContent')">
            <quill-editor
              ref="editor"
              v-model="$store.state.cart.offerEmailContent"
              :options="editorOption"
            >
              <div
                id="toolbar"
                slot="toolbar"
              >
                <!-- Add a bold button -->
                <span class="ql-formats">
                  <button class="ql-clean" />
                  <button class="ql-bold" />
                  <button class="ql-italic" />
                  <button class="ql-link" />
                </span>
                <span class="ql-formats">
                  <button
                    class="ql-script"
                    value="sub"
                  />
                  <button
                    class="ql-script"
                    value="super"
                  />
                </span>
                <span class="ql-formats">
                  <button
                    class="ql-list"
                    value="ordered"
                  />
                  <button
                    class="ql-list"
                    value="bullet"
                  />
                </span>
                <span class="ql-formats">
                  <button
                    class="ql-indent"
                    value="-1"
                  />
                  <button
                    class="ql-indent"
                    value="+1"
                  />
                </span>
                <span class="ql-formats">
                  <select class="ql-align" />
                  <select class="ql-color" />
                </span>
                <span class="ql-formats">
                  <select class="ql-size" />
                  <select class="ql-header" />
                  <select class="ql-font" />
                </span>

                <!-- You can also add your own -->
                <span class="ql-formats">
                  <b-button
                    id="custom-button"
                    variant="success"
                    class="w-auto"
                    @click="isModalOpen = true"
                  >
                    <span class="text-white">
                      <feather-icon
                        icon="CodeIcon"
                        class="mr-25"
                      />
                    </span>

                    <span class="text-white">
                      {{ $t('ShortCodes') }}
                    </span>
                  </b-button>
                  <b-button
                    variant="primary"
                    size="sm"
                    class="preview_swiper mr-50 mb-25 ml-50"
                    @click="templatePreview = !templatePreview; previewContent = $store.state.cart.offerEmailContent"
                  >
                    <feather-icon :icon="!templatePreview ? 'EyeIcon' : 'EyeOffIcon'" />
                  </b-button>
                </span>
              </div>
            </quill-editor>
          </b-form-group>
          <b-modal
            :visible="isModalOpen"
            :title="$t('Codes')"

            size="lg"
            hide-footer
            no-close-on-backdrop
            @hide="isModalOpen = false"
          >
            <shortcodes-modal
              @close-modal="isModalOpen = false"
            />
          </b-modal>
          <b-modal
            v-model="templatePreview"
            hide-footer
            :title="$t('Preview')"
            size="lg"
          >
            <div style="overflow: auto">
              <email-preview-modal :template="$store.state.cart.offerEmailContent" />
            </div>
          </b-modal>
        </div>
      </b-col>

      <!-- Fiels uploader -->
      <b-col
        v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CART_ADDITIONAL_FILES])"
        sm="12"
        class="mt-75"
      >
        <b-form-group :label="$t('OfferFiles')">
          <drag-drop-upload
            :key="`files`"
            :default-value="cart.files"
            :mt="false"
            multiple
            @onChangeFiles="changeFiles"
          />
        </b-form-group>
      </b-col>

      <!--      <b-col-->
      <!--        v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CART_ADDITIONAL_FILES])"-->
      <!--        sm="12"-->
      <!--        class="mt-75"-->
      <!--      >-->
      <!--        <b-form-group :label="$t('PresentationFiles')">-->
      <!--          <drag-drop-upload-->
      <!--            :key="`presentation_files`"-->
      <!--            :tname="'presentation'"-->
      <!--            :default-value="cart.presentationFiles"-->
      <!--            :mt="false"-->
      <!--            :accept="'application/pdf'"-->
      <!--            multiple-->
      <!--            @onChangeFiles="changePresentationFiles"-->
      <!--          />-->
      <!--        </b-form-group>-->
      <!--      </b-col>-->
    </b-row>
  </validation-observer>
</template>

<script>
import {
  GET_OFFER_EMAIL, GET_PRIVATE_SETTINGS, GET_WEBSITE, GET_WEBSITES, UPDATE_CART,
} from '@/@constants/mutations'
import { quillEditor } from 'vue-quill-editor'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import DragDropUpload from '@/views/components/DragDropUpload.vue'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import { mapGetters } from 'vuex'
import { VBTooltip } from 'bootstrap-vue'
import EmailPreviewModal from '@/views/modals/EmailPreviewModal.vue'
import ShortcodesModal from '@/views/modals/ShortcodesModal.vue'

export default {
  name: 'SectionGeneratingOffer',
  components: {
    quillEditor,
    EmailPreviewModal,
    ShortcodesModal,
    ValidationObserver,
    ValidationProvider,
    DragDropUpload,
    vSelect,
    Cleave,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data: () => ({
    editorOption: {
      modules: {
        toolbar: '#toolbar',
      },
    },
    selectOfferWebsite: true,
    selectedOfferWebsite: null,
    hideOfferProductPrice: false,
    hideOfferTotalPrice: false,
    cleaveOptions: {
      number: {
        delimiter: ' ',
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
      },
    },
    templatePreview: false,
    isModalOpen: false,
    websites: [],
    allowEditingOfferEmail: false,
  }),
  watch: {
    cart: {
      deep: true,
      handler(newValue) {
        if (newValue.offerWebTemplate?.id) {
          this.selectOfferWebsite = true
          this.selectedOfferWebsite = newValue.offerWebTemplate

          this.hideOfferProductPrice = newValue.hideOfferProductPrice
          this.hideOfferTotalPrice = newValue.hideOfferTotalPrice
        } else {
          this.selectedOfferWebsite = null
        }
      },
    },
  },
  async mounted() {
    try {
      const resp = await this.$store.dispatch(`system/${GET_PRIVATE_SETTINGS}`)

      this.allowEditingOfferEmail = resp.data?.item?.allowEditingOfferEmail || false
    } catch (err) {
      this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
    }

    this.$store.dispatch(`templates/${GET_OFFER_EMAIL}`)
      .then(res => {
        if (res.data.item?.translations?.length) {
          this.$set(this.$store.state.cart, 'offerEmailContent', res.data.item.translations[0].emailOfferContent)
        } else this.$store.state.cart.offerEmailContent = ''
      })
      .catch(err => {
        this.showToast('danger', this.$i18n.t(`errors.${err.response.data.message}`))
      })

    if (this.$store.state.cart.contactThread?.contact && !this.$store.state.cart.contactThread.contact.email) {
      this.$store.state.cart.sendEmail = false
    }

    await this.getFromServer()
  },
  computed: {
    ...mapGetters({
      cart: 'cart/getCart',
    }),
  },
  methods: {
    async getFromServer() {
      if (this.cart.offerWebTemplate && this.cart.offerWebTemplate?.id) {
        this.selectOfferWebsite = true
        this.selectedOfferWebsite = this.cart.offerWebTemplate

        const websiteResp = await this.$store.dispatch(`websites/${GET_WEBSITE}`, this.cart.offerWebTemplate?.id)
        if (this.cart.hideOfferProductPrice !== websiteResp.data?.item?.productPrices) await this.changeSettings(websiteResp.data?.item?.productPrices, 'hideOfferProductPrice')
        if (this.cart.hideOfferTotalPrice !== websiteResp.data?.item?.hideOfferPrice) await this.changeSettings(websiteResp.data?.item?.hideOfferPrice, 'hideOfferTotalPrice')
      }

      try {
        const resp = await this.$store.dispatch(`websites/${GET_WEBSITES}`, { customFields: this.$fields.BASE_NAME, filters: { active: true } })

        this.$set(this, 'websites', resp?.data?.items || [])
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err.response?.data?.message}`), err)
      }
    },
    changeFiles(files) {
      this.cart.files.push(...files)
    },
    changePresentationFiles(files) {
      this.cart.presentationFiles.push(...files)
    },
    changeOfferWebsite(event) {
      if (!event) {
        this.changeSettings(null, 'offerWebTemplate')
      }
    },
    async changeSettings(value, place) {
      this.loading = true
      this.$set(this.cart, place, value)

      if (place === 'offerWebTemplate' && this.cart.offerWebTemplate?.id) {
        await this.$store.dispatch(`websites/${GET_WEBSITE}`, this.cart.offerWebTemplate.id)
          .then(({ data }) => data.item)
          .then(({ productPrices, hideOfferPrice }) => {
            this.$set(this.cart, 'hideOfferProductPrice', productPrices)
            this.$set(this.cart, 'hideOfferTotalPrice', hideOfferPrice)
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err.response?.data?.message}`), err)
          })
      }

      this.$nextTick(() => {
        this.$store.dispatch(`cart/${UPDATE_CART}`, { cart: this.cart, updateFiles: false })
      })
      setTimeout(() => {
        this.loading = false
      }, 1000)
    },
  },
}
</script>

<style scoped>

</style>
