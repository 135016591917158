const smsStatus = status => {
  const errors = ['NOT_FOUND', 'EXPIRED', 'UNDELIVERED', 'FAILED', 'REJECTED', 'RENEWAL', 'STOP', 'CANCELLED']
  const warnings = ['UNKNOWN', 'QUEUE', 'ACCEPTED']
  const success = ['DELIVERED']
  let color = 'bullet-primary'
  if (errors.includes(status)) color = 'bullet-danger'
  if (warnings.includes(status)) color = 'bullet-warning'
  if (success.includes(status)) color = 'bullet-success'

  return color
}

export default smsStatus
