<template>
  <div>
    <portal to="full-screen-button">
      <b-button
        id="full-window-button"
        v-b-tooltip.d500
        variant="outline-secondary"
        class="btn-icon ml-25"
        :title="$t('FullWindow')"
        @click="showModal = true"
      >
        <sw-icon icon="MaximizeIcon" />

        <!--        {{ title }}-->
      </b-button>
    </portal>

    <slot />

    <b-modal
      v-model="showModal"
      :title="title"
      hide-footer
      size="xl"
      modal-class="modal-fullscreen"
      header-class="my-0 py-50"
      scrollable
    >
      <template #modal-header>
        <div class="d-flex align-items-center justify-content-between w-100 my-0">
          <div />
          <b-button class="my-0"
                    size="sm"
                    variant="flat-secondary"
                    @click="showModal = false"
          >
            <sw-icon icon="XIcon" />
            {{ $t('Close') }}
          </b-button>
        </div>
      </template>

      <slot />
    </b-modal>
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
  },
  data: () => ({
    showModal: false,
  }),
}
</script>

<style lang="scss">
</style>
