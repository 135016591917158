<template>
  <div class="app-calendar overflow-hidden border">
    <!--    Section: Filters    -->
    <sw-filters
      :shorts="[
        {
          title: $t('TasksAssignedTo'),
          type: 'avatar',
          value: () => filters.assignedTo,
          clear: () => { filters.assignedTo = []; refetchEvents() }
        },
        {
          title: $t('TaskTypes'),
          value: () => {
            const valueList = []

            filters.taskTypes.forEach(status =>
              valueList.push(taskTypes.find(({ id }) => id === status).name)
            )

            return valueList.join(', ')
          },
          clear: () => { filters.taskTypes = []; refetchEvents() }
        },
        {
          value: () => filters.showUnsignedTasks ? $t('ShowUnsignedTasks') : null,
          clear: () => { filters.showUnsignedTasks = false; refetchEvents() }
        },
        {
          title: $t('TaskStatus'),
          value: () => filters.taskStatus && filters.taskStatus.length ? $t(`task.status.${filters.taskStatus}`) : '',
          clear: () => { filters.taskStatus = '' }
        },
      ]"
    >
      <b-row>
        <b-col
          sm="12"
          md="6"
        >
          <sw-select :name="$t('TasksAssignedTo')">
            <assigned-users
              :value="[...filters.assignedTo]"
              @input="changeAssignedUsers"
            />
          </sw-select>
        </b-col>

        <b-col
          sm="12"
          md="6"
        >
          <sw-select :name="$t('TaskTypes')">
            <v-select
              v-model="filters.taskTypes"
              label="name"
              item-value="id"
              item-text="name"
              :reduce="taskItem => taskItem.id.toString()"
              multiple
              :close-on-select="false"
              :options="taskTypes"
            >
              <template #no-options>
                {{ $t('NoOptions') }}
              </template>
              <template #option="{ name }">
                <span class="ml-50">{{ name }}</span>
              </template>

              <template #selected-option="{ name }">
                <span class="ml-50">{{ name }}</span>
              </template>
            </v-select>
          </sw-select>
        </b-col>

        <b-col
          sm="12"
          md="6"
        >
          <sw-select :name="$t('TaskStatus')">
            <v-select
              v-model="filters.taskStatus"
              :options="taskStatus"
            >
              <template #no-options>
                {{ $t('NoOptions') }}
              </template>
              <template #option="{ label }">
                <span class="ml-50">{{ $t(`task.status.${label}`) }}</span>
              </template>

              <template #selected-option="{ label }">
                <span class="ml-50">{{ $t(`task.status.${label}`) }}</span>
              </template>
            </v-select>
          </sw-select>
        </b-col>

        <b-col md="4">
          <b-form-checkbox
            v-model="filters.showUnsignedTasks"
            variant="primary"
            @change="reloadTasks(pagination.currentPage, pagination.perPage)"
          >
            {{ $t('ShowUnsignedTasks') }}
          </b-form-checkbox>
        </b-col>
      </b-row>
    </sw-filters>

    <div class="row no-gutters">
      <!-- Calendar -->
      <div class="col position-relative">
        <div class="card shadow-none border-0 mb-0 rounded-0">
          <div class="card-body pb-0 pt-1">
            <full-calendar
              ref="refCalendar"
              :options="{ ...calendarOptions, locales: items, locale: $i18n.locale }"
              class="full-calendar calendar-custom"
            >
              <template #eventContent="{ event }">
                <component
                  :is="getViewByCalendar(event._context.viewApi.type)"
                  style="width: 100%; height: 100%"
                  :data="event"
                />
              </template>
            </full-calendar>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BPopover } from 'bootstrap-vue'
import FullCalendar from '@fullcalendar/vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import allLocales from '@fullcalendar/core/locales-all'
import { GET_TASKS } from '@/@constants/mutations'
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import AssignedUsers from '@/views/components/AssignedUsers.vue'
import DefaultView from '@/views/calendar/components/DefaultView.vue'
import ListMonthView from '@/views/calendar/components/ListMonthView.vue'
import calendarStoreModule from './calendarStoreModule'
import useCalendar from './useCalendar'

export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    BPopover,
    vSelect,
    AssignedUsers,
    DefaultView,
    ListMonthView,
  },
  data: () => ({
    items: allLocales,
    taskTypes: [],
    taskStatus: ['O', 'D', 'ND'],
    isMounted: false,
  }),
  computed: {
    ...mapGetters({
      filters: 'globalCalendar/getFilters',
      user: 'auth/getCurrentUser',
      modalState: 'modal/getModalState',
    }),
  },
  watch: {
    filters: {
      deep: true,
      // eslint-disable-next-line no-unused-vars
      handler(nw, ow) {
        // this.$nextTick(() => {
        // if (this.isMounted) {
        const t = this.$refs.refCalendar.getApi()
        t.refetchEvents()
        // }
        // })
      },
    },
    modalState: {
      deep: true,
      handler(n) {
        if (n.reloadContent && this.$refs.refCalendar) {
          const t = this.$refs.refCalendar.getApi()
          t.refetchEvents()
        }
      },
    },
  },
  async mounted() {
    this.isMounted = false
    await this.changeAssignedUsers([this.user])

    await this.loadTaskTypes()

    this.isMounted = true
  },
  methods: {
    getViewByCalendar(calendarView) {
      if (calendarView === 'listMonth') return 'list-month-view'
      return 'default-view'
    },
    changeAssignedUsers(event) {
      this.filters.assignedTo = event
    },
    loadTaskTypes() {
      this.$store.dispatch(`typeSettings/${GET_TASKS}`)
        .then(res => {
          this.taskTypes = res
        })
    },
    t(f) {
      console.log(f)
    },
  },
  setup() {
    const CALENDAR_APP_STORE_MODULE_NAME = 'calendar'

    // Register module
    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME)
    })

    const {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      fetchEvents,
      refetchEvents,
      calendarOptions,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
    } = useCalendar()

    // fetchEvents()

    return {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      refetchEvents,
      fetchEvents,
      calendarOptions,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
.fc-timegrid-axis,
.fc-scrollgrid-shrink {
  white-space: pre-wrap !important;
}
</style>
