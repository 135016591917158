<template>
  <div>
    <GoogleAds />
  </div>
</template>

<script>
import GoogleAds from '@/pages/contact/tabs/GoogleAds.vue'

export default {
  name: 'List',
  components: { GoogleAds },
}
</script>

<style scoped>

</style>
