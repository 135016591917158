<template>
  <app-collapse-item v-if="contact && contact.note || !contact"
                     :is-visible="checkRequiredHostnameFragments(['hydro'])"
                     :force-show="checkRequiredHostnameFragments(['hydro'])"
  >
    <template #header>
      <div
        class="d-flex align-items-center"
        style="gap: .34rem"
      >
        <sw-icon
          icon="AlignLeftIcon"
          style="margin-top: -.2rem"
        />

        <h6 class="mb-0">
          {{ $t('Note') }}
        </h6>

        <b-spinner
          v-if="!contact"
          type="grow"
          small
          class="mx-auto"
          variant="primary"
        />
      </div>
    </template>

    <p
      v-if="contact"
      class="mb-0"
      v-html="formattedNote"
    />
  </app-collapse-item>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      contact: 'contact/contact',
    }),
    formattedNote() {
      return this.sanitizeNote(this.contact?.note || '')
    },
  },
  methods: {
    sanitizeNote(note) {
      if (!note) return ''

      const regex = /<(?!br\s*\/?>)/gi
      const sanitized = note.replace(regex, '&lt;')
      return sanitized
    },
  },
}
</script>
