<template>
  <b-card>
    <template #header>
      <span class="h4 mb-0">
        Wybierz datę i godzinę
      </span>
    </template>

    <div
      class="w-100 d-flex flex-column align-items-center"
      style="row-gap: 2.5rem"
    >
      <div class="d-flex flex-column align-items-center">
        <p class="h4">
          27 Grudzień, 2023
        </p>

        <sw-select>
          <sw-select-fpr
            :is-empty="day"
            @clear="day = ''"
          >
            <flat-pickr
              v-model="day"
              class="form-control"
              :config="{ altInput: true, locale: getCalendarLocale($i18n.locale) }"
            />
          </sw-select-fpr>
        </sw-select>
      </div>

      <div class="d-flex flex-column align-items-center">
        <span
          class="h6 d-flex justify-content-center align-items-center"
          style="column-gap: .5rem"
        >
          <feather-icon
            icon="CalendarIcon"
            size="18"
            class="mt-n25"
          />

          Spotkanie o godzinie 21:31
        </span>

        <sw-time-picker />
      </div>
    </div>

    <template #footer>
      <div
        class="w-100 d-flex flex-row align-items-center justify-content-between"
        style="row-gap: 1rem"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="flat-primary"
          size="sm"
        >
          Wyczyść
        </b-button>

        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
        >
          Potwierdź
        </b-button>
      </div>
    </template>
  </b-card>
</template>

<script>

import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import SwTimePicker from './SwTimePicker.vue'

export default {
  components: {
    flatPickr,
    SwTimePicker,
  },

  directives: {
    Ripple,
  },

  data: () => ({
    day: '',
    time: '',
  }),
}
</script>
