<template>
  <div>
    <div
      v-if="!isLoading"
    >
      <sw-select :name="$t('AddChecklist')">
        <v-select
          v-model="checklists"
          :options="checklistList"
          :reduce="i => i.id"
          label="name"
        >
          <template #no-options>
            {{ $t('NoOptions') }}
          </template>
        </v-select>
      </sw-select>

      <b-button
        size="sm"
        variant="success"
        class="mt-1 align-self-right float-right"
        :disabled="!checklists.length > 0"
        @click="addChecklist"
      >
        {{ $t('Add') }}
      </b-button>
    </div>

    <div
      v-else
      class="d-flex justify-content-center"
    >
      <b-spinner
        variant="primary"
        class="m-5"
      />
    </div>
  </div>
</template>

<script>
import axiosIns from '@/libs/axios'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'

export default {
  components: {
    vSelect,
  },
  data: () => ({
    isLoading: false,
    checklists: '',
    checklistList: [],
  }),
  async mounted() {
    this.checklistList = await this.loadChecklistList()
  },
  computed: {
    ...mapGetters({
      modalConfig: 'modal/getModalState',
    }),
  },
  methods: {
    async loadChecklistList() {
      this.isLoading = true

      return axiosIns.get('1/settings/contactThreadChecklistTypes', { params: { fields_load: this.$fields.BASE_NAME, orderBy: 'position.asc' } })
        .then(({ data }) => data.data)
        .then(({ items }) => items.filter(el => !this.modalConfig.thread.contactThreadChecklistTypes.some(i => i.id === el.id)))
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    onModalClose() {
      this.$emit('close')
    },
    addChecklist() {
      const { contactId, thread } = this.modalConfig

      const newChecklist = [...thread.contactThreadChecklistTypes.map(el => Number(el.id)), this.getObjectId(this.checklists)]

      axiosIns.patch(`1/contacts/${contactId}/threads`, {
        id: thread.id.toString(),
        contact: contactId,
        contactThreadChecklistTypes: newChecklist,
      })
        .then(() => {
          this.showToast('success', this.$i18n.t('success.contactUpdated'))

          this.modalConfig.callback()

          this.onModalClose()
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
  },
}
</script>
