<template>
  <div class="d-flex flex-wrap">
    <!--    Phone    -->
    <div class="d-flex align-items-center justify-content-between flex-wrap w-100 mt-50 mt-md-25">
      <div>
        <feather-icon
          icon="PhoneIcon"
          class="mr-75"
        />
        <span class="font-weight-bold">{{ $t('Phone') }}</span>
      </div>

      <a
        v-if="user.phone"
        :href="`tel:${user.phone}`"
      >
        {{ user.phone | phoneFormat }}
      </a>

      <feather-icon
        v-else
        icon="MinusIcon"
      />
    </div>

    <!--    User    -->
    <div class="d-flex align-items-center justify-content-between flex-wrap w-100 mt-50 mt-md-25">
      <div>
        <feather-icon
          icon="BriefcaseIcon"
          class="mr-75"
        />
        <span class="font-weight-bold">{{ $t('contact.parentUser') }}</span>
      </div>

      <span
        v-if="user.parentUser"
        class="my-50 pl-50"
      >
        <b-avatar
          size="25"
          class="mr-50"
          :src="user.parentUser.avatar"
        />

        {{ user.parentUser.firstName }} {{ user.parentUser.lastName }}
      </span>
      <span
        v-else
      >
        -
      </span>
    </div>

    <!--    Account Type    -->
    <div class="d-flex align-items-center justify-content-between flex-wrap w-100 mt-50 mt-md-25">
      <div>
        <feather-icon
          icon="UserCheckIcon"
          class="mr-75"
        />
        <span class="font-weight-bold">{{ $t('AccountType') }}</span>
      </div>

      <span class="text-capitalize my-50 pl-50">
        {{ !user.isOrganization ? $t('privatePerson') : $t('company') }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: ['user'],
}
</script>

<style scoped>
    tr {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
</style>
