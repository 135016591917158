import Vue from 'vue'
import { ModalPlugin, ToastPlugin, BVConfigPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import * as roles from '@/helpers/permissions'
import * as viewRanks from '@/helpers/viewPermissions'
import * as fields from '@/@constants/fields'

// Libs
import i18n from '@/libs/i18n'
import sweetAlert from '@/libs/sweet-alerts'

import PortalVue from 'portal-vue'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'
import './global-utils'
import './global-modals'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/oh-vue-icons'
import '@/libs/toastification'
import '@/libs/clipboard'

import '@/@core/mixins'
import '@/@core/filters'
import './registerServiceWorker'

Vue.use(VueCompositionAPI)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(PortalVue)
Vue.use(ModalPlugin)

Vue.use(BVConfigPlugin, {
  BTooltip: {
    delay: {
      show: 500,
      hide: 0,
    },
  },
  BPopover: {
    delay: 0,
    hide: 0,
  },
})

// Composition API

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')
require('@core/assets/fonts/feather/iconfont.css')

Vue.config.productionTip = false

Vue.prototype.$roles = roles
Vue.prototype.$viewRanks = viewRanks
Vue.prototype.$fields = fields

new Vue({
  router,
  store,
  i18n,
  sweetAlert,
  render: h => h(App),
}).$mount('#app')
