<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar = false"
    />

    <!-- Main Area -->
    <section class="chat-app-window flex-grow-1">
      <!-- Start Chat Logo -->
      <header class="chat-header bg-white d-block d-lg-none" v-if="!selectedConversation.user">

        <!-- Avatar & Name -->
        <div
          class="d-flex align-items-center p-1"
        >

          <!-- Toggle Icon -->
          <div class="sidebar-toggle d-block d-lg-none mr-1">
            <feather-icon
              icon="ListIcon"
              class="cursor-pointer"
              size="21"
              @click="mqShallShowLeftSidebar = true"
            />
          </div>
        </div>
      </header>

      <div
        v-if="!selectedConversation.user"
        class="start-chat-area"
      >
        <div class="mb-1 start-chat-icon">
          <feather-icon
            icon="MessageSquareIcon"
            size="56"
          />
        </div>

        <b-button
          size="lg"
          variant="gradient-primary"
          class="mt-25 d-lg-none"
          @click="startConversation"
        >
          {{ $t('StartConversation') }}
        </b-button>
      </div>

      <!-- Chat Content -->
      <div
        v-else
        class="active-chat d-flex flex-column justify-content-between"
      >
        <!-- Chat Navbar -->
        <div class="chat-navbar">
          <header class="chat-header">

            <!-- Avatar & Name -->
            <div
              class="d-flex align-items-center py-1"
            >

              <!-- Toggle Icon -->
              <div class="sidebar-toggle d-block d-lg-none mr-1">
                <feather-icon
                  icon="ListIcon"
                  class="cursor-pointer"
                  size="21"
                  @click="mqShallShowLeftSidebar = true"
                />
              </div>

              <b-avatar
                v-if="selectedConversation.user"
                size="36"
                class="mr-1 badge-minimal"
                badge
                badge-variant="primary"
                :text="(selectedConversation.user.firstName && selectedConversation.user.firstName[0].toUpperCase()) + (selectedConversation.user.lastName && selectedConversation.user.lastName[0].toUpperCase())"
                :src="selectedConversation.user.avatar"
              />

              <h6
                v-if="selectedConversation.user"
                class="mb-0"
              >
                {{ selectedConversation.user.firstName }} {{ selectedConversation.user.lastName }}
              </h6>
            </div>
          </header>
        </div>

        <!-- User Chat Area -->
        <vue-perfect-scrollbar
          ref="refChatLogPS"
          :settings="perfectScrollbarSettings"
          class="user-chats scroll-area"
          @ps-y-reach-start="loadOlderMessages"
        >
          <div
            v-if="firedLoading"
            class="position-absolute position-left-0 position-right-0 position-top-0 position-bottom-0 w-100 h-100 d-flex align-items-center justify-content-center bg-light-secondary"
          >
            <b-spinner
              variant="primary"
              class="m-1"
            />
          </div>

          <chat-messages :messages="selectedConversation.messages" />
        </vue-perfect-scrollbar>

        <!-- Message Input -->
        <div
          v-if="!allowWriteToUser"
          class="chat-app-form align-items-center justify-content-center py-1 text-center text-primary"
        >
          <feather-icon
            icon="LockIcon"
            class="mr-25"
          />
          {{ $t('NoChatPermission') }}
        </div>
        <b-form
          v-else
          class="chat-app-form d-flex flex-column align-items-end align-items-md-center align-items-md-start flex-md-row py-1"
          @submit.prevent="sendConversationMessage"
        >
          <b-input-group class="input-group-merge form-send-message mr-md-50">
            <b-form-input
              v-model="chatInputMessage"
              :placeholder="$t('MessageContent')"
              class="py-2"
            />
            <b-input-group-append is-text>
              <small
                :class="chatInputMessage.length >= 2000 ? 'bg-light-danger' : 'bg-light-primary'"
                class="text-light py-25 px-50 rounded"
              >
                <span class="char-count">{{ chatInputMessage.length }}</span> / 2000
              </small>
            </b-input-group-append>
          </b-input-group>

          <div class="d-flex flex-row align-items-center justify-content-center mt-50 mt-md-0">
            <!-- Attachments -->
            <label
              for="message_attachments"
              class="mr-25 mr-md-0"
            >
              <b-avatar
                variant="light-primary"
                class="mr-50 cursor-pointer"
                :disabled="uploadProgress !== 0"
                type="file"
                :badge="String(chatAttachments.length)"
                badge-variant="primary"
              >
                <feather-icon icon="PaperclipIcon" />
              </b-avatar>
              <input
                id="message_attachments"
                ref="message_attachments"
                class="d-none"
                type="file"
                multiple
                @input="selectFiles"
              >
            </label>

            <!-- Button->Send Message -->
            <b-button
              variant="primary"
              :disabled="!chatInputMessage.trim().length || uploadProgress !== 0 || chatInputMessage.length >= 2000"
              type="submit"
              class="btn-icon px-1"
            >
              <feather-icon :icon="uploadProgress !== 0 ? 'LoadingIcon' : 'SendIcon'" />
            </b-button>
          </div>
        </b-form>
      </div>
    </section>

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <chat-users-sidebar
        :mq-shall-show-left-sidebar="mqShallShowLeftSidebar"
        :available-users="users"
        :conversation-users="conversationUsers"
        :selected-conversation-id="selectedConversation.id"
        @open-conversation="openConversation"
        @close-mq-shall-show-left-sidebar="mqShallShowLeftSidebar = false"
      />
    </portal>
  </div>
</template>

<script>
import store from '@/store'
import { ref } from '@vue/composition-api'
import {
  BForm, BFormInput, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { $themeBreakpoints } from '@themeConfig'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import {
  CREATE_CHAT_CONVERSATION,
  CREATE_CHAT_CONVERSATION_MESSAGE,
  GET_CHAT_CONVERSATION_MESSAGES,
  GET_CHAT_CONVERSATIONS,
  GET_USERS,
} from '@/@constants/mutations'
import { mapGetters } from 'vuex'
// eslint-disable-next-line import/no-cycle
import axiosIns from '@/libs/axios'
import { CHAT_VIEW_ALL, FULL_ACCESS } from '@/helpers/permissions'
import ChatUsersSidebar from '../views/core/chat/ChatUsersSidebar.vue'
import ChatMessages from '../views/core/chat/ChatMessages.vue'

const emptyConversation = {
  id: null,
  user: null,
  messages: [],
}

export default {
  name: 'SwChat',
  components: {

    // BSV
    BForm,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,

    // 3rd Party
    VuePerfectScrollbar,

    // SFC
    ChatUsersSidebar,
    ChatMessages,
  },
  data: () => ({
    chatInputMessage: '',
    chatAttachments: [],
    users: [],
    conversationUsers: [],
    selectedConversation: { ...emptyConversation },
    loadInterval: null,
    loadConversationsInterval: null,
    firedLoading: false,
    uploadProgress: 0,
  }),
  computed: {
    ...mapGetters({
      currentUser: 'auth/getCurrentUser',
    }),
    allowWriteToUser() {
      const selectedConversationUserRank = this.selectedConversation.user?.rank?.id || this.selectedConversation.rank?.id || null
      if (!selectedConversationUserRank) return false

      const currentUser = JSON.parse(localStorage.getItem('user'))
      if (currentUser.rank?.permissions.includes(CHAT_VIEW_ALL) || currentUser.rank?.permissions.includes(FULL_ACCESS)) return true

      return !!currentUser?.rank?.chatViewRanks?.find(item => item.id === selectedConversationUserRank)
    },
  },
  mounted() {
    this.loadConversationsAndUsers()
    this.loadConversationsInterval = setInterval(() => {
      this.getConversationUsers()
    }, 15000)
  },
  beforeDestroy() {
    clearInterval(this.loadInterval)
    clearInterval(this.loadConversationsInterval)
  },
  methods: {
    loadConversationsAndUsers() {
      this.getUsers()
      this.getConversationUsers()
    },
    getUsers() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(`chat/${GET_USERS}`)
          .then(res => {
            this.users = res.data.items
            resolve(res.data.items)
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            reject()
          })
      })
    },
    getConversationUsers() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(`chat/${GET_CHAT_CONVERSATIONS}`)
          .then(res => {
            this.conversationUsers = res.data.items
            resolve(res.data.items)
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            reject()
          })
      })
    },
    loadOlderMessages(e) {
      if (!this.firedLoading && this.allowLoading) {
        this.firedLoading = true

        const payload = {
          conversationId: this.selectedConversation.id,
          loadFromFirstId: this.selectedConversation.messages[0].id,
        }
        setTimeout(() => {
          this.$store.dispatch(`chat/${GET_CHAT_CONVERSATION_MESSAGES}`, payload)
            .then(res => {
              if (res.data.items.length === 20) {
                this.firedLoading = false
                this.selectedConversation.messages.unshift(...res.data.items.reverse())
                this.$nextTick(() => {
                  if (this.$refs.refChatLogPS.$el) this.$refs.refChatLogPS.$el.scrollTop = e.target.scrollWidth
                })
              } else {
                if (res.data.items.length) {
                  this.selectedConversation.messages.unshift(...res.data.items.reverse())
                  this.$nextTick(() => {
                    if (this.$refs.refChatLogPS.$el) this.$refs.refChatLogPS.$el.scrollTop = e.target.scrollWidth
                  })
                }
                this.allowLoading = false
                this.firedLoading = false
              }
            })
            .catch(err => {
              this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            })
        }, 1000)
      }
    },

    // Load Messages First Time
    openConversation(conversationId = null, user = null) {
      clearInterval(this.loadInterval)
      this.allowLoading = true
      this.selectedConversation = {
        id: conversationId,
        user,
        messages: [],
      }

      if (conversationId) {
        this.clearUnreadMessages(conversationId)
        const payload = {
          conversationId,
        }
        this.$store.dispatch(`chat/${GET_CHAT_CONVERSATION_MESSAGES}`, payload)
          .then(res => {
            if (res.data.items.length < 20) this.allowLoading = false
            this.$set(this.selectedConversation, 'messages', res.data.items.reverse())
            this.$nextTick(() => {
              this.scrollToBottomInChatLog()
            })
            clearInterval(this.loadInterval)
            this.loadInterval = setInterval(() => {
              this.loadNewMessages(conversationId)
            }, 1000)
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          })
      } else this.scrollToBottomInChatLog()
    },

    clearUnreadMessages(conversationId) {
      const item = this.conversationUsers.find(conversation => conversation.id === conversationId)
      if (item) item.chatConversationMessagesUnreadAmount = 0
    },

    // Load Newly Sended Messaages
    loadNewMessages(conversationId) {
      const payload = {
        conversationId,
        loadFromLastId: this.selectedConversation.messages.at(-1).id,
      }
      this.$store.dispatch(`chat/${GET_CHAT_CONVERSATION_MESSAGES}`, payload)
        .then(res => {
          if (res.data.items.length) {
            if (res.data.items[0].id === this.selectedConversation.messages.at(-1).id) {
              this.selectedConversation.messages.splice(this.selectedConversation.messages.findIndex(item => item.id === res.data.items[0].id), 1)
            }
            this.selectedConversation.messages.push(...res.data.items)
            this.setConversationLastMessage(res.data.items.at(-1), conversationId)
            this.$nextTick(() => {
              this.scrollToBottomInChatLog()
            })
          }
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
    sendConversationMessage() {
      if (this.selectedConversation.id) {
        this.pushConversationMessage()
      } else {
        this.pushConversation()
      }
    },
    pushConversation() {
      const users = [this.selectedConversation.user.id, this.currentUser.id]
      this.$store.dispatch(`chat/${CREATE_CHAT_CONVERSATION}`, { users })
        .then(res => {
          this.selectedConversation.id = res.data.items[0].id
          this.$nextTick(() => {
            this.pushConversationMessage()
            this.loadConversationsAndUsers()
          })
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
    pushConversationMessage() {
      const conversationId = this.selectedConversation.id
      const message = this.chatInputMessage
      this.uploadMessageFiles()
        .then(files => {
          this.$store.dispatch(`chat/${CREATE_CHAT_CONVERSATION_MESSAGE}`, { conversationId, message, files })
            .then(res => {
              let fileItems = []
              try {
                fileItems = [
                  ...(Array.from(this.chatAttachments)).map((attachment, index) => ({
                    previewPath: URL.createObjectURL(attachment),
                    token: files[index],
                    name: attachment.name.split('.')[0],
                    extension: attachment.name.split('.')[1],
                  })),
                ]
              } catch (e) {
                this.showToast('danger', e.message)
              }

              const chatItem = {
                message,
                id: res.data.items[0].id,
                createdBy: {
                  ...this.currentUser,
                },
                createdAt: { date: new Date() },
                files: fileItems,
              }
              // eslint-disable-next-line no-unreachable
              this.selectedConversation.messages.push(chatItem)
              this.setConversationLastMessage(chatItem, conversationId)
              this.chatInputMessage = ''
              this.$nextTick(() => {
                this.scrollToBottomInChatLog()
                this.selectFiles(null)
              })
            })
            .catch(err => {
              this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            })
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
    selectFiles(editedList = null) {
      if (editedList === null) {
        this.$refs.message_attachments.value = ''
        this.chatAttachments = []
        return
      }
      const files = editedList.target.files || [...this.$refs.message_attachments.files]
      const filesToUpload = [...files]
      const filesToRemove = []
      files.forEach((file, index) => {
        if (file.size > 5000000) {
          filesToUpload.splice(index, 1)
          filesToRemove.push(index)
        }
      })

      if (filesToRemove.length) {
        this.showAlert('error', this.$i18n.tc('MoreThanFewFilesMbWontBeUploaded', 2, { size: 5 }))
      }

      this.chatAttachments = files
    },
    uploadMessageFiles() {
      const vm = this
      return new Promise((resolve, reject) => {
        if (this.chatAttachments.length) {
          const formData = new FormData()
          this.chatAttachments.forEach(file => {
            formData.append('files[]', file, file.name)
          })

          const config = {
            onUploadProgress(progressEvent) {
              vm.$set(this, 'uploadProgress', Math.round((progressEvent.loaded * 100) / progressEvent.total))
            },
          }

          axiosIns.post('storage/1/upload', formData, config)
            .then(res => {
              resolve(res.data.data.files)
            })
            .catch(err => {
              reject(err)
            })
        } else {
          resolve([])
        }
      })
    },
    setConversationLastMessage(message, conversationId) {
      const conversation = this.conversationUsers.find(item => item.id === conversationId)
      if (conversation) conversation.chatConversationMessagesLast = message
    },
  },
  setup() {
    // Scroll to Bottom ChatLog
    const refChatLogPS = ref(null)
    const scrollToBottomInChatLog = () => {
      const scrollEl = refChatLogPS.value.$el || refChatLogPS.value
      scrollEl.scrollTop = scrollEl.scrollHeight
    }

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    // Active Chat Contact Details
    const shallShowActiveChatContactSidebar = ref(false)

    // UI + SM Devices
    // Left Sidebar Responsiveness
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()
    const startConversation = () => {
      if (store.state.app.windowWidth < $themeBreakpoints.lg) {
        mqShallShowLeftSidebar.value = true
      }
    }

    return {
      // useChat
      refChatLogPS,
      // Active Chat Contact Details
      shallShowActiveChatContactSidebar,

      // UI
      perfectScrollbarSettings,
      scrollToBottomInChatLog,

      // UI + SM Devices
      startConversation,
      mqShallShowLeftSidebar,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-chat.scss";
@import "~@core/scss/base/pages/app-chat-list.scss";
.chat-application {
  .sidebar-content {
    padding: 0 !important;
  }
}
</style>
