<template>
  <b-card v-if="user">
    <b-card-title
      title-tag="h5"
      class="d-flex flex-wrap align-items-center"
    >
      {{ $t('userEdit.ChangeAvatar') }}

      <small class="ml-50">({{ $t('userEdit.CurrentAvatar') }})</small>
    </b-card-title>
    <b-row class="d-flex">
      <b-col class="text-center">
        <div
          class="position-relative mt-50"
          style="width: 90px; margin: 0 auto"
        >
          <b-avatar
            variant="light-primary"
            size="90"
            :src="user.avatar"
          />
          <b-avatar
            style="position:absolute; bottom: -5px; right: 0"
            variant="primary"
            size="30"
            class="cursor-pointer"
            button
            @click="onTargetAvatarInput"
          >
            <feather-icon icon="EditIcon" />
          </b-avatar>
          <input
            ref="newAvatar"
            type="file"
            style="display: none"
            accept="image/*"
            @change="onChangeAvatar"
          >
        </div>
      </b-col>
      <b-col
        v-if="imagePreview"
        class="text-center"
      >
        {{ $t('userEdit.NewAvatarPreview') }}
        <div
          class="position-relative mt-50"
          style="width: 90px; margin: 0 auto"
        >
          <b-avatar
            size="90"
            :src="imagePreview"
          />
        </div>
      </b-col>
    </b-row>
    <b-button
      class="mt-2"
      variant="primary"
      size="sm"
      :disabled="!imagePreview"
      @click="onSaveAvatar"
    >
      {{ $t('Save') }}
    </b-button>
    <b-overlay
      no-wrap
      :show="loading"
      spinner-variant="primary"
    />
  </b-card>
</template>

<script>
import { BCardTitle } from 'bootstrap-vue'
import { CHANGE_USER_AVATAR } from '@/@constants/mutations'

export default {
  name: 'AvatarUpload',
  components: {
    BCardTitle,
  },
  props: {
    user: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    loading: false,
    imagePreview: null,
  }),
  methods: {
    onTargetAvatarInput() {
      this.$refs.newAvatar.click()
    },
    onChangeAvatar(event) {
      const fr = new FileReader()
      if (event.target.files[0].size > 25 * 1000) {
        this.showToast('danger', this.$i18n.tc('MoreThanKB', 1, { type: this.$i18n.t('SystemMoreThan.avatar'), size: 25 }))
        return
      }
      fr.onload = () => {
        this.imagePreview = fr.result
      }
      fr.readAsDataURL(event.target.files[0])
    },
    onSaveAvatar() {
      this.loading = true

      const payload = {
        avatar: this.imagePreview,
        userId: this.user.id,
      }

      this.$store.dispatch(`profileSettings/${CHANGE_USER_AVATAR}`, payload)
        .then(() => {
          this.loading = false
          this.showToast('success', this.$i18n.t('userEdit.AvatarUpdated'))
          this.$store.commit('auth/SET_USER_FIELD', { value: payload.avatar, field: 'avatar' })
        })
        .catch(err => {
          this.loading = false
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
  },
}
</script>

<style scoped>

</style>
