<!--eslint-disable-->

<template>
  <div v-if="filters">
    <b-row>
      <b-col
        md="6"
        lg="4"
      >
        <sw-select
          :name="$t('Seller')"
          class="mb-0"
        >
          <assigned-users
            :value="filters.seller"
            @input="payload => filters.seller = payload"
          />
        </sw-select>
      </b-col>

      <b-col
        md="6"
        lg="4"
      >
        <sw-select
          :name="$t('CommissionFor')"
          class="mb-0"
        >
          <assigned-users
            :value="filters.provisionFor"
            @input="payload => filters.provisionFor = payload"
          />
        </sw-select>
      </b-col>

      <b-col
        md="6"
        lg="4"
      >
        <sw-select :name="$t('PaymentMethod')">
          <v-select
            v-model="filters.paymentMethod"
            :options="[
              'INSTALLMENT',
              'BANK_INSTALLMENT',
              'TRANSFER',
              'CASH',
            ]"
          >
            <template #no-options>
              {{ $t('NoOptions') }}
            </template>

            <template #option="{ label }">
              {{ $t(label.toLowerCase()) }}
            </template>

            <template #selected-option="{ label }">
              {{ $t(label.toLowerCase()) }}
            </template>
          </v-select>
        </sw-select>
      </b-col>

      <b-col
        md="6"
        lg="4"
      >
        <sw-select :name="$t('StatusOfOrder')">
          <v-select
            v-model="filters.orderStatus"
            :options="[
              'NEW',
              'REALIZED',
              // 'POINTS_PURCHASE',
              'CANCELLED',
            ]"
          >
            <template #no-options>
              {{ $t('NoOptions') }}
            </template>

            <template #option="option">
              {{ $tc(`offer.status.${option.label}`, 2) }}
            </template>
            <template #selected-option="option">
              {{ $tc(`offer.status.${option.label}`, 2) }}
            </template>
          </v-select>
        </sw-select>
      </b-col>

      <b-col
        md="6"
        lg="4"
      >
        <sw-select :name="$t('OrderCreationDate')">
          <sw-select-fpr
            :is-empty="filters.orderCreatedAt"
            @clear="filters.orderCreatedAt = ''"
          >
            <flat-pickr
              ref="timeRange"
              v-model="filters.orderCreatedAt"
              class="form-control"
              :config="{ ...flatPickerConfig, locale: getCalendarLocale($i18n.locale) }"
            />
          </sw-select-fpr>
        </sw-select>
      </b-col>
        <b-col
        md="6"
        lg="4"
      >
        <sw-select :name="$t('OrderDate')">
          <sw-select-fpr
            :is-empty="filters.orderDate"
            @clear="filters.orderDate = ''"
          >
            <flat-pickr
              ref="timeRange"
              v-model="filters.orderDate"
              class="form-control"
              :config="{ ...flatPickerConfig, locale: getCalendarLocale($i18n.locale) }"
            />
          </sw-select-fpr>
        </sw-select>
      </b-col>

      <!--      <b-col-->
      <!--        md="6"-->
      <!--        lg="4"-->
      <!--      >-->
      <!--        <sw-select :name="$t('DateOfConclusionOfContract')">-->
      <!--          <sw-select-fpr>-->
      <!--            <flat-pickr-->
      <!--              ref="timeRange"-->
      <!--              value=""-->
      <!--              class="form-control"-->
      <!--              :config="{ ...flatPickerConfig, locale: getCalendarLocale($i18n.locale) }"-->
      <!--            />-->
      <!--          </sw-select-fpr>-->
      <!--        </sw-select>-->
      <!--      </b-col>-->

    </b-row>
  </div>
</template>

<script>
import AssignedUsers from '@/views/components/AssignedUsers.vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { Polish as pl } from '@/libs/i18n/locales/flatpickr/pl'
import { english as en } from '@/libs/i18n/locales/flatpickr/en'

export default {
  name: 'CommissionsFilters',
  components: {
    AssignedUsers,
    vSelect,
    flatPickr,
  },
  props: {
    filters: {
      required: true,
      default: null,
      type: Object,
    },
  },
  data: () => ({
    flatPickerConfig: {
      mode: 'range',
      locale: [pl, en],
    },
  }),
  computed: {
    locale() {
      return this.$i18n.locale
    },
  },
}
</script>
