<template>
  <div>
    <!--      Thread      -->
    <sw-select
      v-if="showContact"
      :name="$t('Thread')"
    >
      <v-select
        v-model="contactThread"
        :options="modalConfig.allowThreads && modalConfig.allowThreads.length ? modalConfig.allowThreads :contactThreadList"
        label="name"
        :filterable="false"
        @search="(e, is) => {
          if (modalConfig.allowThreads && modalConfig.allowThreads.length) return null
          else loadThreads(e, is)
        }"
      >
        <template #no-options="{ search }">
          <span v-if="search.length">
            {{ $t('NoData') }}
          </span>
          <span v-else>
            {{ $t('TypeToSearch') }}
          </span>
        </template>
        <template #option="{ name, contact, offersTotal }">
          <div class="d-flex align-items-center">
            <avatar
              :user="contact"
              class="mr-25"
            />  - {{ name }}
            <feather-icon
              v-if="offersTotal >= system.contactThreadOffersLimit"
              v-b-tooltip
              icon="AlertCircleIcon"
              class="ml-50"
              :title="$tc('ThreadOfferCountIsToBig', system.contactThreadOffersLimit, { limit: system.contactThreadOffersLimit })"
            />
          </div>
        </template>

        <template #selected-option="{ name, contact }">
          <div class="d-flex">
            <avatar
              :user="contact"
              class="mr-25"
            /> – {{ name }}
          </div>
        </template>
      </v-select>
    </sw-select>

    <!--      Type      -->
    <sw-select
      :name="$t('SelectNoteType')"
    >
      <v-select
        v-model="note.type"
        :options="noteTypes"
        :reduce="nType => nType.id.toString()"
        item-value="id"
        item-text="name"
        label="name"
        :clearable="false"
      >
        <template #no-options>
          {{ $t('NoOptions') }}
        </template>
        <template #option="{ name, icon }">
          <feather-icon
            :icon="icon"
            size="16"
            class="align-middle mr-50"
          />
          <span>{{ name }}</span>
        </template>
        <template #selected-option="{ name, icon }">
            <feather-icon
                    :icon="icon"
                    size="16"
                    class="align-middle mr-50"
            />
            <span>{{ name }}</span>
        </template>
      </v-select>
    </sw-select>

    <b-form-group :label="$t('NoteContent')">
      <b-form-textarea
        v-model="note.content"
        rows="5"
      />
    </b-form-group>

    <sw-select :name="$t('ShareNote')">
      <v-select
        v-model="note.share"
        multiple
        :close-on-select="false"
        :options="ranks"
        label="name"
        :reduce="rank => rank.id.toString()"
        item-value="id"
        item-text="name"
      >
        <template #no-options>
          {{ $t('NoOptions') }}
        </template>
      </v-select>
    </sw-select>
    <div
      v-if="note.files.length"
      class="mt-1"
    >
      <div class="mb-50">
        {{ $t('FilesAssignedToNote') }}
      </div>
      <documents-list
        :key="`task_${note.id}_${note.files.length}`"
        :delete-mode="true"
        :documents="note.files"
        @deleteFile="deleteFile"
      />
    </div>
    <!-- Ipload files -->
    <DragDropUpload
      ref="dragdropupload"
      :is-refresh="false"
      @onChangeFiles="changeFiles"
      @onDeleteFiles="filesToUpload = []"
    />
    <!--  Footer  -->
    <div
      v-if="!noBtns"
      class="d-flex justify-content-end pt-1 mt-1 border-top"
    >
      <b-button
        size="sm"
        class="mr-50"
        variant="secondary"
        @click="onModalClose"
      >
        {{ $t('Cancel') }}
      </b-button>
      <b-button
        size="sm"
        variant="primary"
        :disabled="loading || !note.type || !note.content || !contactThread || !contactThread.id"
        @click="onCreateNote"
      >
        {{ $t(okBtn ? okBtn : 'Save') }}
      </b-button>
    </div>
    <slot
      v-else
      name="actions"
      :item="note"
      :files-to-upload="filesToUpload"
    />
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { BButton, BFormTextarea } from 'bootstrap-vue'
import {
  CLOSE_MODAL, CREATE_NOTE, GET_NOTES, HIDE_MODAL, UPLOAD_FILES,
} from '@/@constants/mutations'
import { mapGetters } from 'vuex'
// import axiosIns from '@/libs/axios'
import DocumentsList from '@/views/components/DocumentsList.vue'
import { THREADS_SEARCH } from '@/@constants/fields'
import axiosIns from '@/libs/axios'
import DragDropUpload from '../../components/DragDropUpload.vue'

export default {
  name: 'NoteModal',
  components: {
    DocumentsList,
    DragDropUpload,
    vSelect,
    BFormTextarea,
    BButton,
  },
  props: {
    okBtn: {
      type: String,
      required: false,
      default: '',
    },
    noBtns: {
      type: Boolean,
      required: false,
      default: false,
    },
    showContact: {
      type: Boolean,
      required: false,
      default: true,
    },
    threadId: {
      type: Number,
      required: false,
      default: null,
    },
    isGetNote: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data: () => ({
    note: {
      type: '',
      content: '',
      share: [],
      files: [],
    },
    filesToUpload: [],
    loading: false,

    contactThreadList: [],
    contactThread: null,
  }),
  computed: {
    ...mapGetters({
      noteTypes: 'typeSettings/getNotes',
      ranks: 'ranks/getRanksList',
      modalConfig: 'modal/getModalState',
      system: 'system/getSettings',
    }),
  },
  mounted() {
    this.$store.dispatch(`typeSettings/${GET_NOTES}`)
    this.$store.dispatch('ranks/GET_RANKS_LIST')

    if (this.modalConfig.allowThreads?.length) {
      // eslint-disable-next-line prefer-destructuring
      this.contactThread = this.modalConfig.allowThreads[0]
    }

    if (this.modalConfig.editedData) {
      const editedField = this.modalConfig.editedData
      const content = editedField.content.replaceAll('<br />', '\n')
      this.note = {
        content,
        share: editedField.sharedRanks,
        type: editedField.contactThreadNoteType,
        files: editedField.files,
        id: editedField.id,
      }
    }

    if (!this.threadId && !this.modalConfig.threadId) {
      this.loadThreads('', loading => { this.isMeLoading = loading })
    } else {
      this.loadThread(this.threadId || this.modalConfig.threadId, loading => { this.isMeLoading = loading })
    }
  },
  methods: {
    deleteFile(fileIndex) {
      this.note.files.splice(fileIndex, 1)
    },
    changeFiles(files) {
      this.filesToUpload.push(...files)
    },
    onModalClose() {
      this.$emit('close')
    },
    onCreateNote() {
      if (this.$slots.actions || this.noBtns) return

      this.loading = true
      const { note, contactThread } = this

      const threadId = contactThread?.id

      if (note.type === '' || note.content === '' || !threadId) return

      const formatedContent = note.content.replaceAll('\n', '<br />')
      const noteData = {
        name: '',
        contactThreadNoteType: typeof note.type === 'object' ? note.type.id : note.type,
        content: formatedContent,
        sharedRanks: [],
      }

      note.share.forEach(rank => {
        if (typeof rank === 'object') noteData.sharedRanks.push(rank.id.toString())
        else noteData.sharedRanks.push(rank)
      })

      if (note.id) {
        noteData.id = note.id.toString()
      }

      this.$store.commit(`modal/${HIDE_MODAL}`)
      this.$store.dispatch(`uploader/${UPLOAD_FILES}`, { uploadedFiles: this.filesToUpload, type: 'note', threadId })
        .then(res => {
          noteData.files = [...res.files, ...note.files.map(file => file.token)]
          this.$store.dispatch(`modal/${CREATE_NOTE}`, { noteData, threadId: res.threadId })
            .then(() => {
              this.loading = false
              this.showToast('success', noteData.id ? this.$i18n.t('NoteUpdated') : this.$i18n.t('NoteCreated'))

              this.$nextTick(() => this.close())
            })
            .catch(err => {
              this.loading = false
              this.showToast('danger', this.$i18n.t('errors.15_MINUTES_AFTER_CREATION'), err)
            })
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },

    close() { this.$store.commit(`modal/${CLOSE_MODAL}`) },

    async loadThreads(search, isLoading) {
      this.contactThreadList = await this.selectSearch(search, isLoading, THREADS_SEARCH, 'name,contact-firstName,contact-lastName', '1/contacts/threads')
    },

    async loadThread(id) {
      const thread = await axiosIns(`1/contacts/threads/${id}`, { params: { fields_load: THREADS_SEARCH } })
      if (thread.data.data.item) {
        this.contactThread = thread.data.data.item
      }
    },
  },
}
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>
