const checkNotification = (_type, _action) => {
  let notification = {
    title: _action,
    icon: _type,
  }
  const types = {
    integrationGoogleConnection: {
      failed: {
        title: 'GoogleFailed',
        icon: 'AlertTriangleIcon',
      },
    },
    integrationWhatsappConnection: {
      failed: {
        title: 'WhatsappFailed',
        icon: 'AlertTriangleIcon',
      },
    },
    clientChat: {
      disableAi: {
        title: 'DisableAi',
        icon: 'AlertTriangleIcon',
      },
    },
    mail: {
      add: {
        title: 'AddedNewMail',
        icon: 'SendIcon',
      },
      receive: {
        title: 'MailReceive',
        icon: 'BellIcon',
      },
      failed: {
        title: 'MailFailed',
        icon: 'AlertTriangleIcon',
      },
    },
    contactThreadSms: {
      add: {
        title: 'AddedNewSms',
        icon: 'SendIcon',
      },
      failed: {
        title: 'FAILED',
        icon: 'PhoneOffIcon',
      },
    },
    contactThreadNote: {
      add: {
        title: 'AddedNewNote',
        icon: 'PlusIcon',
      },
    },
    contactThreadTask: {
      addAssignedUser: {
        title: 'AddedNewTask',
        icon: 'UserPlusIcon',
      },
      title: 'ChangedTaskStatus',
      icon: 'EditIcon',
    },
    contactThreadChecklist: {
      changePoint: {
        title: 'ChangedChecklistPoint',
        icon: 'EditIcon',
      },
    },
    contact: {
      addAssignedUser: {
        title: 'AssignYouToContact',
        icon: 'UserPlusIcon',
      },
      title: 'ChangedContactStatus',
      icon: 'EditIcon',
    },
    contactThreadCartOffer: {
      open: {
        title: 'ContactOpenOffer',
        icon: 'ExternalLinkIcon',
      },
      add: {
        title: 'OfferAdded',
        icon: 'PlusIcon',
      },
      cancel: {
        title: 'OfferCanceled',
        icon: 'XIcon',
      },
      accept: {
        title: 'OfferAccepted',
        icon: 'CheckIcon',
      },
      title: _action,
      icon: 'XIcon',
    },
    contactThreadOfferMessage: {
      add: {
        title: 'OfferMessageAdded',
        icon: 'PlusIcon',
      },
    },
    contactThread: {
      addAssignedUser: {
        title: 'AssignYouToThread',
        icon: 'UserPlusIcon',
      },
      changeStatus: {
        title: 'ThreadChangeCloseStatus',
        icon: 'UserXIcon',
      },
      title: 'ChangedThreadStatus',
      icon: 'EditIcon',
    },
    contactThreadPayment: {
      changeStatus: {
        icon: 'EditIcon',
        title: 'PaymentChangeStatus',
      },
      add: {
        icon: 'PlusIcon',
        title: 'PaymentAdded',
      },
    },
    contactThreadCartOrder: {
      changeStatus: {
        icon: 'EditIcon',
        title: 'OrderChangeStatus',
      },
      add: {
        icon: 'PlusIcon',
        title: 'OrderAdded',
      },
    },
    contactThreadAgreement: {
      add: {
        icon: 'PlusIcon',
        title: 'AgreementAdded',
      },
      changeStatus: {
        icon: 'EditIcon',
        title: 'AgreementChangeStatus',
      },
    },
    integrationFacebookConnection: {
      title: _action,
      icon: 'FacebookIcon',
    },
    contactThreadComplaint: {
      changeStatus: {
        icon: 'EditIcon',
        title: 'ComplaintChangeStatus',
      },
    },
    automation: {
      contact: {
        icon: 'UserIcon',
        title: 'AutomationContactAction',
      },
      contactThread: {
        icon: 'UsersIcon',
        title: 'AutomationContactThreadAction',
      },
      automationContactThreadChecklistPointDatum: {
        icon: 'CheckIcon',
        title: 'AutomationContactThreadPointDatumAction',
      },
    },
  }

  /* eslint-disable */
  if (types[_type] && types[_type][_action]) notification = { ...types[_type][_action] }
  else if (types[_type]?.icon) {
    notification.icon = types[_type]?.icon
    notification.title = types[_type]?.title
  }

  return notification
}

export const getNotificationActionTitle = (_type, _action) => checkNotification(_type, _action).title

export const getNotificationActionIcon = (_type, _action) => checkNotification(_type, _action).icon
