import {
  CHANGE_USER_STATUS,
  CREATE_USER, EDIT_USER, EDIT_USER_LANG,
  GET_SELECT_USERS,
  GET_SINGLE_USER,
  GET_USERS, REMOVE_USERS,
} from '@/@constants/mutations'
// eslint-disable-next-line import/no-cycle
import axiosIns from '@/libs/axios'

import * as fields from '@/@constants/fields'

const users = []

export default {
  namespaced: true,
  state: {
    users,
    singleUser: null,
    totalRecords: 10,
  },
  getters: {
    getUsers: state => state.users,
    getSingleUser: state => state.singleUser,
    getTotalRecords: state => state.totalRecords,
  },
  mutations: {
    [GET_USERS](state, payload) {
      state.users = payload.users
      state.totalRecords = payload.total
    },
    [GET_SINGLE_USER](state, payload) {
      state.singleUser = payload
    },
  },
  actions: {
    // Create single user
    // eslint-disable-next-line no-unused-vars
    [CREATE_USER]: ({ commit }, userData) => new Promise((resolve, reject) => {
      const user = userData
      user.canLogin = true
      axiosIns.post('1/users', user)
        .then(() => {
          resolve()
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [EDIT_USER]: ({ commit }, userData) => new Promise((resolve, reject) => {
      const user = userData
      user.canLogin = true
      axiosIns.patch('1/users', user)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [EDIT_USER_LANG]: ({ commit }, payload) => new Promise((resolve, reject) => {
      axiosIns.patch(`1/users/${payload.id}/profile`, payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    }),
    [GET_USERS]: ({ commit }, payload) => new Promise((resolve, reject) => {
      let params = {
        ignore_canLogin: 1,
      }

      if (payload.filters) {
        if (payload.filters.role) params.eq_rank = payload.filters.role.toString()

        if (payload.filters.timeRange) {
          const splitedDate = payload.filters.timeRange.dateRange()
          params.gte_createdAt = `${splitedDate[0]} 00:00:00`
          if (splitedDate[1]) {
            params.lte_createdAt = `${splitedDate[1]} 23:59:59`
          }
        }

        if (payload.filters.status) {
          const statusData = payload.filters.status
          if (statusData.blocked) {
            params.eq_blocked = 1
          } else {
            params.eq_activated = statusData.activated ? 1 : 0
          }
        }

        if (payload.filters.accountType === 0 || payload.filters.accountType === 1) params.eq_isOrganization = payload.filters.accountType

        if (payload.filters.loyaltyPlan) params['eq_userLoyaltyPlans-loyaltyPlan-id'] = payload.filters.loyaltyPlan
        if (payload.filters.marketingPlan) params['eq_userMarketingPlans-marketingPlan-id'] = payload.filters.marketingPlan
        if (payload.filters.superSeller) params['eq_userMarketingPlans-superSeller'] = 1

        if (payload.filters.assignedUser && payload.filters.assignedUser.length) {
          params.eqArr_parentUser = JSON.stringify(payload.filters.assignedUser.map(user => user?.id))
        }
      }

      if (payload.search) {
        const search = payload.search.replaceAll(' ', '%')
        params = {
          search: `%${search}%`,
          search_fields: 'userId,firstName,lastName,mailAddress-mail,mailAddressPrivate-mail,phoneNumber-phone,phoneNumberPrivate-phone',
        }
      }

      if (payload.sorter) {
        const sortDir = payload.sorter?.sortDesc ? 'desc' : 'asc'
        const sortBy = payload.sorter?.sortBy || 'id'
        params.orderBy = `${sortBy}.${sortDir}`
      }

      if (payload.pagination) {
        // eslint-disable-next-line no-shadow
        const { pagination } = payload

        params.page = pagination.currentPage
        params.limit = pagination.perPage
      }

      params.fields_load = fields.USERS

      axiosIns.get('1/users?ignore_canLogin=1', { params })
        .then(res => {
          commit(GET_USERS, { users: res.data.data.items, total: res.data.data.totalItemCount })
          resolve(res.data)
        })
        .catch(err => {
          commit(GET_USERS, users)
          reject(err)
        })
    }),
    [GET_SINGLE_USER]: ({ commit }, id) => new Promise((resolve, reject) => {
      const params = {
        fields_load: fields.SINGLE_USER,
      }

      axiosIns.get(`1/users/${id}`, { params })
        .then(res => {
          commit(GET_SINGLE_USER, res.data.data.item)
          resolve(res.data.data.item)
        })
        .catch(err => {
          commit(GET_SINGLE_USER, users[0])
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [GET_SELECT_USERS]: ({ commit }, search) => new Promise((resolve, reject) => {
      // eslint-disable-next-line no-underscore-dangle
      const _search = search.replaceAll(' ', '%')
      const params = {
        search: `%${_search}%`,
        search_fields: 'userId,firstName,lastName,mailAddress-mail,mailAddressPrivate-mail,phoneNumber-phone,phoneNumberPrivate-phone',
        fields_load: fields.USERS_SEARCH,
      }
      axiosIns.get('1/users', { params })
        .then(res => {
          resolve(res.data.data.items)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [REMOVE_USERS]: ({ commit }, { userIds, status }) => new Promise((resolve, reject) => {
      axiosIns.patch('1/users?ignore_canLogin=1', { canLogin: status, id: userIds[0] })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [CHANGE_USER_STATUS]: ({ commit }, payload) => new Promise((resolve, reject) => {
      const data = {
        id: payload.userId.toString(),
        blocked: payload.status,
      }

      axiosIns.patch('1/users', data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    }),
  },
}
