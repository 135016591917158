export default [
  {
    path: '/marketing',
    component: () => import('@/pages/plans/marketing/Container.vue'),
    name: 'marketingPlans',
    children: [
      {
        component: () => import('@/pages/commissions/List.vue'),
        path: 'commissions',
        name: 'marketingPlansCommissions',
      },
      {
        component: () => import('@/pages/plans/marketing/List.vue'),
        path: 'plans',
        name: 'marketingPlansPlans',
      },
    ],
    meta: {
      autorize: true,
      pageTitle: 'MarketingPlans',
    },
  },
  {
    path: '/marketing-plans/:marketingPlanId?',
    component: () => import('@/pages/plans/marketing/New.vue'),
    name: 'newMarketingPlan',
    meta: {
      autorize: true,
      pageTitle: 'NewMarketingPlan',
    },
  },
  // Loyalty
  {
    path: '/loyalty',
    component: () => import('@/pages/plans/loyalty/Container.vue'),
    name: 'loyaltyPlans',
    children: [
      {
        component: () => import('@/pages/loyaltyPoints/List.vue'),
        path: 'points',
        name: 'loyaltyPlansLoyaltyPoints',
      },
      {
        component: () => import('@/pages/plans/loyalty/List.vue'),
        path: 'plans',
        name: 'loyaltyPlansLoyaltyPlans',
      },
    ],
    meta: {
      autorize: true,
      pageTitle: 'LoyaltyPlans',
    },
  },
  {
    path: '/loyalty-points',
    component: () => import('@/pages/loyaltyPoints/List.vue'),
    name: 'loyaltyPoints',
    meta: {
      autorize: true,
      pageTitle: 'LoyaltyPoints',
    },
  },
  {
    path: '/loyalty-plans/:loyaltyPlanId?',
    component: () => import('@/pages/plans/loyalty/New.vue'),
    name: 'newLoyaltyPlan',
    meta: {
      autorize: true,
      pageTitle: 'NewLoyaltyPlan',
    },
  },
]
