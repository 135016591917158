<!--eslint-disable-->

<template>
  <div>
    <!--    Button: New Type    -->
    <b-button
      variant="primary"
      size="sm"
      @click="openEditModal({ name: '', visibleOfferWWW: false, attachToElectronicSignature: false })"
    >
      <feather-icon
        icon="PlusIcon"
        class="mr-25"
      />
      {{ $t('Add') }}
    </b-button>

    <!--    Type list    -->
    <b-row class="d-flex flex-row-reverse">
      <b-col
        sm="12"
        md="12"
        lg="12"
      >
        <!--    Categories View    -->
        <div class="mt-1">
          <b-card class="myTableOffer">
            <b-table
              :style="{ minHeight: tableSize(types, 100) }"
              :busy="loading"
              :items="types"
              :fields="fields"
              striped
              show-empty
              responsive
              hover
              class="offerTable"
            >
              <!--    Table Head    -->
              <template #head()="item">
                <span class="text-nowrap">
                  {{ $tc(item.field.label) | truncate(34, '...') }}
                </span>
              </template>

              <!--    Name    -->
              <template #cell(name)="{ value }">
                {{ value }}
              </template>

              <!--    CreatedBy    -->
              <template #cell(createdBy)="{ value }">
                <avatar :user="value" />
              </template>

              <!--    AccessIn    -->
              <template #cell(visibleOfferWWW)="{ value }">
                <b-form-checkbox
                  :disabled="true"
                  :checked="value"
                  class="custom-control-primary"
                  variant="primary"
                /></template>
              <!--    AccessIn    -->
              <template #cell(attachToElectronicSignature)="{ value }">
                <b-form-checkbox
                  :disabled="true"
                  :checked="value"
                  class="custom-control-primary"
                  variant="primary"
                /></template>
              <!--    Table Action    -->
              <template #cell(action)="{ item, index }">
                <b-dropdown
                  variant="link"
                  class="dropdown-icon-wrapper text-decoration-none"
                  no-caret
                >
                  <template
                    #button-content
                    class="sr-only"
                  >
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle"
                    />
                  </template>

                  <!--    Action: Delete    -->
                  <b-dropdown-item @click="deleteProcess(item, index)">
                    {{ $t('Delete') }}
                  </b-dropdown-item>

                  <!-- Action: Edit -->
                  <b-dropdown-item @click="openEditModal({ ...item }, index)">
                    {{ $t('Edit') }}
                  </b-dropdown-item>
                </b-dropdown>
              </template>

              <!--    Other    -->
                <!--    Other    -->
                <template #cell()="row">
                    <table-default-cell
                            :field="row.field"
                            :value="row.value"
                    />
                </template>

                <!--      Empty      -->
                <template #empty>
                    <empty-content />
                </template>

                <!--      Table Busy      -->
                <template #table-busy>
                    <div class="text-center text-danger my-2">
                        <b-spinner
                                class="align-middle"
                                variant="primary"
                        />
                    </div>
                </template>
            </b-table>
          </b-card>
        </div>
      </b-col>
    </b-row>

    <!--    Modal: Create/Edit Type     -->
    <b-modal
      :visible="!!editedField"
      :title="$t('task.Type')"
      size="md"
      hide-footer
      no-close-on-backdrop
      @close="closeModal"
    >
      <div v-if="!!editedField">
        <b-form-group :label="$t('Name')">
          <b-form-input
            v-model="editedField.name"
            :placeholder="$t('Name')"
          />
        </b-form-group>
        <b-form-checkbox
          v-model="editedField.visibleOfferWWW"
          class="my-50 custom-control-primary"
          variant="primary"
        >{{ $t('ViewInOfferWWW') }}
        </b-form-checkbox>
        <!--        <b-form-checkbox-->
        <!--          v-model="editedField.attachToElectronicSignature"-->
        <!--          class="my-50 custom-control-primary"-->
        <!--          variant="primary"-->
        <!--        >{{ $t('AttachToElectronicSignature') }}-->
        <!--        </b-form-checkbox>-->
      </div>
      <b-button
        variant="primary"
        size="sm"
        @click="saveItem"
      >
        {{ $t('Save') }}
      </b-button>
    </b-modal>

    <!-- Push to server btn -->
    <b-button
      variant="primary"
      @click="pushToServer"
    >
      {{ $t('Save') }}
    </b-button>
  </div>
</template>

<script>
import { BTable, VBTooltip } from 'bootstrap-vue'
import { DELETE_PICTURE_TYPE, GET_PICTURE_TYPE, SAVE_PICTURE_TYPE } from '@/@constants/mutations'
import payloadKeysCutter from '@/helpers/keysCuter'
import { formatDate } from '@core/utils/filter'

export default {
  components: {
    BTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data: () => ({
    loading: false,
    editedField: null,
    editedIndex: -1,
    fields: [
      { key: 'name', label: 'Name' },
      // { key: 'attachToElectronicSignature', label: 'AttachToElectronicSignature' },
      { key: 'visibleOfferWWW', label: 'ViewInOfferWWW' },
      { key: 'createdAt', label: 'CreatedAt' },
      { key: 'createdBy', label: 'CreatedBy' },
      { key: 'action', label: 'Action' },
    ],
    types: [],
  }),
  mounted() {
    this.getFromServer()
  },
  methods: {
    formatDate,
    getFromServer() {
      this.loading = true
      this.$store.dispatch(`productPictures/${GET_PICTURE_TYPE}`)
        .then(res => {
          this.types = res.data.items
          this.loading = false
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          this.loading = false
        })
    },
    pushToServer() {
      const items = JSON.parse(JSON.stringify(this.types))
      const preparedItems = payloadKeysCutter(items)
      this.$store.dispatch(`productPictures/${SAVE_PICTURE_TYPE}`, preparedItems)
        .then(() => {
          this.showToast('success', this.$i18n.t('success.contactUpdated'))
          this.getFromServer()
        })
        .catch(err => {
          this.loading = false
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },

    /// Open Edit Modal ///
    openEditModal(item, index = -1) {
      this.editedField = JSON.parse(JSON.stringify(item))
      this.editedIndex = index
    },
    closeModal() {
      this.editedField = null
      this.editedIndex = -1
    },
    saveItem() {
      if (this.editedIndex === -1) {
        const item = this.editedField
        item.createdAt = { date: new Date() }
        item.createdBy = this.$store.getters['auth/getCurrentUser']
        this.types.push(item)
      } else {
        this.$set(this.types, this.editedIndex, { ...this.editedField })
      }

      this.closeModal()
    },
    deleteProcess(item, index) {
      if (item?.id) {
        this.showAlert('error', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('DeleteWillCauseDeletingImages'))
          .then(() => {
            this.delete(item.id, index)
          })
      } else {
        this.types.splice(index, 1)
      }
    },
    delete(id, index) {
      this.$store.dispatch(`productPictures/${DELETE_PICTURE_TYPE}`, id)
        .then(() => {
          this.types.splice(index, 1)
          this.showToast('success', this.$i18n.t('RemovedSuccessfully'))
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
  },
}
</script>
