<template>
  <!-- eslint-disable -->
  <div class="d-flex flex-column">
    <!--    START::Buttons    -->
    <div class="mt-25">
      <b-button
        variant="dark"
        size="sm"
        class="mr-50"
        @click="$router.push({ name: 'marketingPlans' })"
      >
        <feather-icon icon="ArrowLeftIcon" />
        {{ $t('Return') }}
      </b-button>

      <b-button
        type="submit"
        variant="primary"
        size="sm"
        @click="saveItem"
      >
        {{ $t('Save') }}
      </b-button>
    </div>
    <!--    END::Buttons    -->

    <validation-observer ref="planForm">
      <!--    START::Info    -->
      <b-card class="mt-2">
        <info
            :is-loading="isLoading"
            :target.async="target"
        />
      </b-card>
      <!--    END::Info    -->

      <!--    START::Own Sale    -->
      <b-card>
        <own-sale
            :is-loading="isLoading"
            :target.async="target"
        />
      </b-card>
      <!--    END::Own Sale    -->

      <!--    START::Sale Users    -->
      <b-card>
        <sale-users
            :is-loading="isLoading"
            :target.async="target"
        />
      </b-card>
      <!--    END::Sale Users    -->

      <!--    START::User Commission    -->
      <b-card>
        <user-commission
            :is-loading="isLoading"
            :target.async="target"
        />
      </b-card>
      <!--    END::User Commission    -->

      <!--    START::Commission Scheme    -->
      <b-card>
        <commission-scheme
            :is-loading="isLoading"
            :target.async="target"
        />


        <!--    START::Checkbox Neko    -->
        <b-form-checkbox
                v-model="target.chargeCommissionForSales"
                variant="primary"
                switch
        >
            <span v-html="$t('DoNotChargeCommissionForSalesMadeFromLoyaltyPlanPoints')" />
        </b-form-checkbox>
        <!--    END::Checkbox Neko    -->
      </b-card>
      <!--    END::Commission Scheme    -->
    </validation-observer>

    <div class="mb-2">
      <b-button
        type="submit"
        variant="primary"
        @click="saveItem"
        :disabled="isLoading"
      >
        {{ $t('Save') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import axiosIns from '@/libs/axios'
import payloadKeysCutter from '@/helpers/keysCuter'
import { ValidationObserver } from 'vee-validate'
import info from './components/new/Info.vue'
import ownSale from './components/new/OwnSale.vue'
import saleUsers from './components/new/SaleUsers.vue'
import userCommission from './components/new/UserCommission.vue'
import commissionScheme from './components/new/CommissionScheme.vue'

const defaultItem = {
  // info
  name: '',
  qualifiedStatus: '',
  contractDaysAmount: 0,
  qualifiedStatusEndDaysAmount: 0,
  // own sales
  commissionType: 'ABSOLUTE_VALUE_IN_PRODUCT',
  determineCommissionAmountType: 'COMMISSION_BY_PRODUCT',
  enableUserDiscount: false,
  maxUserDiscount: 0,
  // sales users
  chargeCommissionForSales: false,
  structureDeepLevel: 0,
  basisCalculatingUserCommission: 'PERCENT_BY_PLAN',
  marketingPlanStructureLevels: [],
  // user commissions
  commissionAccrualTime: '',
  enableMinimumNetValue: false,
  minimumNetValue: 0,
  enableMinimumProductSoldAmount: false,
  minimumProductsSoldAmount: 0,
  // commission scheme
  includeMinimumProductCosts: false,
  calculationOrderType: '',
}

export default {
  components: {
    info,
    ownSale,
    saleUsers,
    userCommission,
    commissionScheme,
    ValidationObserver,
  },
  data: () => ({
    isLoading: false,
    target: { ...defaultItem },
  }),
  async mounted() {
    const id = this.$route.params?.marketingPlanId || ''
    if (Boolean(id) && id !== 'new') {
      await this.init(id)
    } else {
      Object.assign(this.target, { ...defaultItem })
    }
  },
  methods: {
    async init(id) {
      this.isLoading = true

      try {
        const resp = await axiosIns.get(`1/marketingPlans/${id}`, { params: { fields_load: this.$fields.MARKETING_PLANS } })

        // eslint-disable-next-line
        const item = resp?.data?.data?.item
        if (item) {
          Object.assign(this.target, {
            ...item,
            // qualifiedStatusEndDate: item.qualifiedStatusEndDate?.date,
            // contractDate: item.contractDate?.date,
          })
        }
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.isLoading = false
      }
    },
    /* eslint-disable */
    async saveItem() {
      const isValid = await this.$refs.planForm.validate()
      if (!isValid) return false

      const itemCopy = payloadKeysCutter({
        ...this.target,
        maxUserDiscount: 0,
        contractDaysAmount: parseFloat(this.target.contractDaysAmount),
        qualifiedStatusEndDaysAmount: parseFloat(this.target.qualifiedStatusEndDaysAmount),
        structureDeepLevel: parseFloat(this.target.structureDeepLevel),
        minimumNetValue: parseFloat(this.target.minimumNetValue),
        minimumProductsSoldAmount: parseFloat(this.target.minimumProductsSoldAmount),
        marketingPlanStructureLevels: this.target.marketingPlanStructureLevels.map(level => ({ ...level, value: parseFloat(level.value || 0) }))
      })

      delete itemCopy.marketingPlanCalculations

      try {
        const resp = itemCopy?.id
          ? await axiosIns.patch('1/marketingPlans', itemCopy)
          : await axiosIns.post('1/marketingPlans', itemCopy)

        if (resp?.data?.data) await this.$router.push({ name: 'marketingPlans' })
        this.showToast('success', this.$i18n.t('success.contactUpdated'))
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
  },
}
</script>
