<template>
  <div>
    <!--    Button: New Product    -->
    <b-button
      class="mb-1"
      variant="primary"
      size="sm"
      :to="{ name: 'productEdit' }"
    >
      {{ $t('Add') }}
    </b-button>

    <!--    Filters View    -->
    <b-card>
      <filters @reloadData="reloadContent" />
    </b-card>

    <!--    Products View    -->
    <div class="mt-1">
      <b-card
        class="myTableOffer"
        style="min-height: 70vh"
      >
        <product-table
          :busy="loading"
          :is-edited="isEdited"
          :products="products"
          :total="totalRecords"
          @reloadData="getProducts"
          @removeProduct="deleteItem"
        />
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  VBTooltip,
} from 'bootstrap-vue'
import { DELETE_PRODUCT, GET_PRODUCTS } from '@/@constants/mutations'
import Filters from '@/views/filters/OfferFilters.vue'
import productTable from '../../../views/offers/product/ProductTable.vue'

export default {
  components: {
    Filters,
    productTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    isEdited: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loading: false,
    products: [],

    filters: null,

    filterOptions: {
      category: [],
      project: [],
      subcategory: [],
    },
    totalRecords: 100,
    sorter: {
      sortBy: 'createdAt',
      sortDesc: true,
    },
    pagination: {
      perPage: 25,
      currentPage: 1,
    },
    search: '',
  }),
  mounted() {
    this.getProduct()
  },
  methods: {
    reloadContent(filters) {
      this.$set(this, 'filters', filters)

      this.getProduct()
    },
    getProducts(pagination, search) {
      this.$set(this, 'pagination', pagination)
      this.$set(this, 'search', search)

      this.getProduct()
    },
    getProduct() {
      const payload = {
        pagination: this.pagination,
        search: this.search,
        filters: this.filters,
      }
      this.loading = true
      this.$store.dispatch(`products/${GET_PRODUCTS}`, payload)
        .then(res => {
          this.totalRecords = res.data.totalItemCount
          this.products = res.data.items
          this.loading = false
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          this.loading = false
        })
    },
    deleteItem(item) {
      if (item?.id) {
        this.showAlert('warning', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('alerts.WontbeAble'))
          .then(() => {
            this.deleteItemFromDatabase(item.id)
            this.getProducts(this.pagination)
          })
      }
    },
    deleteItemFromDatabase(id) {
      this.$store.dispatch(`products/${DELETE_PRODUCT}`, id)
        .then(() => {
          this.showToast('success', this.$i18n.t('RemovedSuccessfully'))
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
    pushToServer() {},

    selectCategory() {},

    selectProject() {},

    selectSubcategory() {},
  },
}
</script>

<style lang="scss" scoped>
//.form-group { margin: 0 }

.stickyFilterBar {
  position: sticky;

  top: 7rem;
}

.myTableOffer {
  overflow: hidden;

  .card-body {
    .table-responsive {
      margin: unset;
    }
  }
}
</style>
