var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-table',{staticStyle:{"min-height":"150px"},attrs:{"busy":_vm.busy,"items":_vm.products,"fields":_vm.fields,"sort-by":_vm.sorter.sortBy,"sort-desc":_vm.sorter.sortDesc,"no-local-sorting":true,"striped":"","responsive":"","hover":"","show-empty":""},on:{"update:sortBy":function($event){return _vm.$set(_vm.sorter, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.sorter, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.sorter, "sortDesc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.sorter, "sortDesc", $event)},"sort-changed":function($event){return _vm.$emit('change-sort', $event)}},scopedSlots:_vm._u([{key:"head()",fn:function(item){return [_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm._f("truncate")(_vm.$t(item.field.label),34, '...'))+" ")])]}},{key:"cell(id)",fn:function(ref){
var index = ref.index;
return [_vm._v(" #"+_vm._s(_vm.getOrderNo(index, _vm.pagination, _vm.sorter))+" ")]}},{key:"cell(addProduct)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.checkRequiredPermissions([_vm.$roles.CONTACT_THREAD_OFFER_ADD]))?_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],attrs:{"title":!Boolean(item.shopProductStoragesSum) ? _vm.$t('ProductNotAvailableInStorage') : ''}},[(!_vm.productsInCart.includes(item.id))?_c('b-button',{staticClass:"btn-icon round",attrs:{"variant":"outline-success","disabled":!Boolean(item.shopProductStoragesSum) || !item.active},on:{"click":function($event){return _vm.$emit('addProductToCart', item)}}},[_c('feather-icon',{attrs:{"icon":"ShoppingCartIcon"}})],1):_c('b-button',{staticClass:"btn-icon round",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.$emit('remove-cart-product', item.id, index)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}})],1)],1):_c('feather-icon',{attrs:{"icon":"MinusIcon"}})]}},{key:"cell(pictures)",fn:function(ref){
var value = ref.value;
return [_c('documents-list',{attrs:{"documents":value}})]}},{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [(item.translations && item.translations.length)?_c('div',[_c('router-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",modifiers:{"hover":true,"left":true}}],attrs:{"to":{ name: 'productView', params: { productId: item.id } },"title":_vm.selectedTranslationObject(item).name}},[_c('span',{staticClass:"doubleLineText"},[_vm._v(" "+_vm._s(_vm._f("truncate")(_vm.selectedTranslationObject(item).name,26, '...'))+" ")])])],1):_vm._e()]}},{key:"cell(userGroups)",fn:function(ref){
var item = ref.item;
return [(item.shopCategories && item.shopCategories.length && item.shopCategories[0] && item.shopCategories[0].userGroups && item.shopCategories[0].userGroups[0] && item.shopCategories[0].userGroups[0].name)?_c('div',[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",modifiers:{"hover":true,"left":true}}],staticClass:"doubleLineText",attrs:{"title":item.shopCategories[0].userGroups[0].name}},[_vm._v(" "+_vm._s(_vm._f("truncate")(item.shopCategories[0].userGroups[0].name,26, '...'))+" ")])]):_c('feather-icon',{attrs:{"icon":"MinusIcon"}})]}},{key:"cell(category)",fn:function(ref){
var item = ref.item;
return [(item.shopCategories && item.shopCategories.length && item.shopCategories[0] && item.shopCategories[0].translations)?_c('div',[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",modifiers:{"hover":true,"left":true}}],staticClass:"doubleLineText",attrs:{"title":item.shopCategories[0].translations[0].name}},[_vm._v(" "+_vm._s(_vm._f("truncate")(item.shopCategories[0].translations[0].name,26, '...'))+" ")])]):_vm._e()]}},{key:"cell(subcategory)",fn:function(ref){
var item = ref.item;
return [(item.shopCategories && item.shopCategories.length && item.shopCategories[1] && item.shopCategories[1].translations)?_c('div',[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",modifiers:{"hover":true,"left":true}}],staticClass:"doubleLineText",attrs:{"title":item.shopCategories[1].translations[0].name}},[_vm._v(" "+_vm._s(_vm._f("truncate")(item.shopCategories[1].translations[0].name,26, '...'))+" ")])]):_vm._e()]}},{key:"cell(description)",fn:function(ref){
var item = ref.item;
return [(item.translations && item.translations.length && item.translations[0])?_c('div',[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",modifiers:{"hover":true,"right":true}}],attrs:{"title":_vm._f("truncate")(_vm.selectedTranslationObject(item).description,600, '...')},domProps:{"innerHTML":_vm._f("truncate")((_vm.selectedTranslationObject(item).description || ''),34, '...')}})]):_vm._e()]}},{key:"cell(vat)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.vat)+"% ")]}},{key:"cell(amount)",fn:function(ref){
var value = ref.value;
return [_c('b-badge',{attrs:{"variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('Left') + ': ' + value.max)+" ")])]}},{key:"cell(offerPriceGross)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("priceGrossFormat")(item.offerPrice * ((item.vat / 100) + 1) || 0))+" "+_vm._s(_vm.$store.getters['system/getSettings'] ? (_vm.$store.getters['system/getSettings'].defaultCurrency || 'PLN') : 'PLN')+" ")]}},{key:"cell(offerPrice)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("priceFormat")(value || 0))+" "+_vm._s(_vm.$store.getters['system/getSettings'] ? (_vm.$store.getters['system/getSettings'].defaultCurrency || 'PLN') : 'PLN')+" ")]}},{key:"cell(numberReservations)",fn:function(ref){
var value = ref.value;
return [_c('b-badge',{attrs:{"variant":"warning"}},[_vm._v(" "+_vm._s(_vm.$t('Offer.Together') + ': ' + value.max)+" ")])]}},{key:"cell(quantitySold)",fn:function(ref){
var value = ref.value;
return [_c('b-badge',{attrs:{"variant":"warning"}},[_vm._v(" "+_vm._s(_vm.$t('Offer.Together') + ': ' + value.max)+" ")])]}},{key:"cell(webUrl)",fn:function(ref){
var value = ref.value;
return [(value)?_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],attrs:{"title":value,"variant":"primary"},on:{"click":function($event){return _vm.redirectTo(value)}}},[_c('feather-icon',{attrs:{"icon":"LinkIcon"}})],1):_c('feather-icon',{attrs:{"icon":"MinusIcon"}})]}},{key:"cell(loyaltyPlan)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [((item.shopProductLoyaltyPlans || []).length)?_c('span',_vm._l((item.shopProductLoyaltyPlans),function(plan,planIndex){return _c('div',{key:("product_" + index + "_plan_" + planIndex)},[(plan.loyaltyPlan)?_c('b-badge',{attrs:{"variant":"light-primary"}},[_vm._v(" "+_vm._s(plan.loyaltyPlan.name)+" ")]):_vm._e()],1)}),0):_c('feather-icon',{attrs:{"icon":"MinusIcon"}})]}},{key:"cell(marketingPlan)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [((item.shopProductMarketingPlans || []).length)?_c('span',_vm._l((item.shopProductMarketingPlans),function(plan,planIndex){return _c('div',{key:("product_marketing_" + index + "_plan_" + planIndex)},[(plan.marketingPlan)?_c('b-badge',{attrs:{"variant":"light-primary"}},[_vm._v(" "+_vm._s(plan.marketingPlan.name)+" ")]):_vm._e()],1)}),0):_c('feather-icon',{attrs:{"icon":"MinusIcon"}})]}},{key:"cell(active)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-flex justify-content-center"},[(Boolean(item.shopProductStoragesSum) && item.active)?_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-success",modifiers:{"hover":true,"v-success":true}}],attrs:{"variant":"light-success","pill":"","title":_vm.$t('Active')}},[_c('feather-icon',{attrs:{"icon":"CheckIcon"}})],1):(!Boolean(item.shopProductStoragesSum) && item.active)?_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-warning",modifiers:{"hover":true,"v-warning":true}}],attrs:{"variant":"light-warning","pill":"","title":_vm.$t('ProductNotAvailableInStorage')}},[_c('feather-icon',{attrs:{"icon":"MinusIcon"}})],1):_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-danger",modifiers:{"hover":true,"v-danger":true}}],attrs:{"variant":"light-danger","pill":"","title":_vm.$t('InActive')}},[_c('feather-icon',{attrs:{"icon":"MinusIcon"}})],1)],1)]}},{key:"cell(action)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.checkRequiredPermissions([_vm.$roles.SHOP_PRODUCT_MODIFY]))?_c('b-dropdown',{staticClass:"dropdown-icon-wrapper text-decoration-none",attrs:{"dropleft":"","no-caret":"","variant":"flat"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{attrs:{"icon":"MoreVerticalIcon"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.$emit('toggleActivity', item)}}},[_c('dropdown-item-label',{attrs:{"icon":!item.active ? 'CheckIcon' : 'XIcon',"label":!item.active ? _vm.$t('Activate') : _vm.$t('Deactivate')}})],1),_c('b-dropdown-divider'),_c('b-dropdown-item',{on:{"click":function($event){return _vm.copy(item.id)}}},[_c('dropdown-item-label',{attrs:{"icon":"CopyIcon","label":_vm.$t('Copy')}})],1),_c('b-dropdown-item',{on:{"click":function($event){return _vm.$router.push({ name: 'productView', params: { productId: item.id } })}}},[_c('dropdown-item-label',{attrs:{"icon":"EditIcon","label":_vm.$t('Edit')}})],1),_c('b-dropdown-item',{on:{"click":function($event){return _vm.remove(item, index)}}},[_c('dropdown-item-label',{attrs:{"icon":"TrashIcon","label":_vm.$t('Delete')}})],1)],1):_c('feather-icon',{attrs:{"icon":"MinusIcon"}})]}},{key:"cell()",fn:function(row){return [_c('table-default-cell',{attrs:{"field":row.field,"value":row.value}})]}},{key:"empty",fn:function(){return [_c('empty-content')]},proxy:true},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[_c('b-spinner',{staticClass:"align-middle",attrs:{"variant":"primary"}})],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }