// eslint-disable-next-line import/no-cycle
import axiosIns from '@/libs/axios'
import { CONTACT_ADDON_FIELD_TYPES } from '@/@constants/fields'
import {
  CREATE_CONTACT_ADDON_FIELD_TYPE,
  DELETE_CONTACT_ADDON_FIELD_TYPE,
  GET_CONTACT_ADDON_FIELD_TYPE,
  GET_CONTACT_ADDON_FIELD_TYPES,
  TEST_CONTACT_ADDON_FIELD_TYPE,
  UPDATE_CONTACT_ADDON_FIELD_TYPE,
} from '@/@constants/mutations'

export default {
  namespaced: true,
  actions: {
    [GET_CONTACT_ADDON_FIELD_TYPES]: () => new Promise((resolve, reject) => {
      const params = {
        orderBy: 'position.asc',
      }
      params.fields_load = CONTACT_ADDON_FIELD_TYPES

      axiosIns.get('1/settings/contactAddonFieldsTypes', { params })
        .then(res => resolve(res.data.data.items))
        .catch(err => reject(err))
    }),

    [GET_CONTACT_ADDON_FIELD_TYPE]: (_, projectId) => new Promise((resolve, reject) => {
      const params = {}
      params.fields_load = CONTACT_ADDON_FIELD_TYPES

      axiosIns.get(`1/settings/contactAddonFieldsTypes/${projectId}`, { params })
        .then(res => resolve(res.data.data.item))
        .catch(err => reject(err))
    }),

    [CREATE_CONTACT_ADDON_FIELD_TYPE]: (_, payload) => new Promise((resolve, reject) => {
      axiosIns.post('1/settings/contactAddonFieldsTypes', payload)
        .then(res => resolve(res.data.data.items[0].id))
        .catch(err => reject(err))
    }),

    [UPDATE_CONTACT_ADDON_FIELD_TYPE]: (_, payload) => new Promise((resolve, reject) => {
      axiosIns.patch('1/settings/contactAddonFieldsTypes', payload)
        .then(res => resolve(res.data.data.items[0].id))
        .catch(err => reject(err))
    }),

    [DELETE_CONTACT_ADDON_FIELD_TYPE]: (_, id) => new Promise((resolve, reject) => {
      axiosIns.delete('1/settings/contactAddonFieldsTypes', { data: [id] })
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    }),

    [TEST_CONTACT_ADDON_FIELD_TYPE]: (_, level = 0) => new Promise((resolve, reject) => {
      const params = {}
      params.fields_info = level

      axiosIns.get('1/settings/contactAddonFieldsTypes', { params })
        .then(res => resolve(res.data.data))
        .catch(err => reject(err))
    }),
  },
}
