import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)
export function loadLocaleMessages() {
  /* eslint-disable */
  const defaultLocales = require.context('./locales/default', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const hydroPureLocales = require.context('./locales/hydropure', true, /[A-Za-z0-9-_,\s]+\.json$/i)

  const messages = {}
  defaultLocales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)

    if (matched && matched.length > 1) {
      const locale = matched[1]
      let messageItems = defaultLocales(key)
      if (origin.includes('hydropure') || origin.includes('192')) messageItems = {
          ...messageItems,
          ...hydroPureLocales(key)
        }

      messages[locale] = messageItems
    }
  })
  return messages
}

export default new VueI18n({
  locale: 'pl',
  fallbackLocale: 'en',
  messages: loadLocaleMessages({}),
})
