<template>
  <div
    v-if="date"
    v-b-tooltip.hover.v-primary.auto
    class="d-inline-flex align-items-center"
    data-bs-placement="bottom"
    :title="title"
    style="gap: .5rem"
  >
    <div
      class="d-inline-flex align-items-center"
      style="gap: .24rem"
    >
      <feather-icon icon="CalendarIcon" />

      {{ moment(date.slice(0, 10)).format('DD.MM.YYYY') }}
    </div>

    <div
      v-if="!hideTime"
      class="d-inline-flex align-items-center"
      style="gap: .24rem"
    >
      <feather-icon icon="ClockIcon" />

      {{ date.slice(11, 16) }}
    </div>
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'
import moment from 'moment'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    date: {
      type: [String, null],
      required: false,
      default: null,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    hideTime: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({ moment }),
}
</script>
