<template>
  <div class="oversize-lg-modal">
    <b-modal
      :visible="show"
      size="xl"
      hide-footer
      @hidden="closeModal"
    >
      <b-row>
        <b-col sm="12">
          <b-alert
            show
            variant="primary"
          >
            <div class="alert-body">
              <div>
                <feather-icon icon="AlertCircleIcon" />
                {{ $t('FirstImportRow') }}
              </div>

              <div class="mt-25">
                <feather-icon icon="AlertCircleIcon" />
                <span v-html="$t('RequiredPhoneFormat')" />
              </div>

              <div class="mt-25">
                <feather-icon icon="AlertCircleIcon" />
                <span v-html="$t('StatusFormat')" />
              </div>

              <div class="mt-25">
                <feather-icon icon="AlertCircleIcon" />
                <span v-html="$t('AdditionalContactFieldsFormat')" />
              </div>

              <div class="mt-25">
                <feather-icon icon="AlertCircleIcon" />
                <span v-html="$t('RequiredAssignedUsersField')" />
              </div>

              <div class="my-25">
                <feather-icon icon="AlertCircleIcon" />
                <span v-html="$t('ImportTags')" />
              </div>

              <b-button
                variant="primary"
                size="sm"
                class="mt-50"
                download
                :href="require('@/assets/pdf/ContactExport.xlsx')"
              >
                <feather-icon
                  icon="DownloadIcon"
                  class="mr-25"
                />

                {{ $t('DownloadSampleFile') }}
              </b-button>
            </div>
          </b-alert>

        </b-col>
        <b-col
          sm="12"
          lg="6"
        >
          <div>{{ $t('AvailableFields') }}</div>
          <draggable
            :list="fields"
            tag="div"
            draggable=".draggable-item"
            :group="{ name: 'fields', pull: 'clone', put:false }"
            class="d-flex flex-wrap mt-25"
          >
            <b-badge
              v-for="(listItem, index) in fields"
              :key="index"
              class="mr-25 mt-50 flex-grow-1 font-small-3"
              :class="!fieldsOrder.includes(listItem) ? ['draggable-item', 'cursor-pointer'] : ''"
              :variant="!fieldsOrder.includes(listItem) ? 'primary' : 'light-primary'"
              @click="pushItem(listItem)"
            >
              {{ $t(`contact.${listItem}`) }}
            </b-badge>
          </draggable>
        </b-col>
        <!-- Selected Fields -->
        <b-col
          sm="12"
          lg="6"
          class="mt-1 mt-md-0"
        >
          <div>
            {{ $t('SelectedFields') }}
          </div>
          <draggable
            :list="fieldsOrder"
            tag="div"
            :group="{ name: 'fields' }"
            class="d-flex flex-wrap mt-25"
          >
            <b-badge
              v-for="(listItem, index) in fieldsOrder"
              :key="index"
              class="mr-25 mt-50 badge-item flex-grow-1 font-small-3 cursor-move"
            >
              <div class="d-flex justify-content-between align-items-center">
                <div class="bg-light p-25 text-secondary rounded">
                  {{ index + 1 }}
                </div>
                <div class="mx-25">
                  {{ $t(`contact.${listItem}`) }}
                </div>
                <feather-icon
                  icon="XIcon"
                  class="cursor-pointer font-small-3"
                  @click="fieldsOrder.splice(index, 1)"
                />
              </div>
            </b-badge>
          </draggable>
        </b-col>

        <b-col
          sm="12"
          md="6"
          class="mt-50"
        >
          <sw-select :name="$t('ThreadTags')">
            <v-select
              v-model="defaultTags"
              :options="contactTags"
              multiple
            >
              <template #no-options>
                {{ $t('NoOptions') }}
              </template>
              <template #option="{ name }">
                {{ name }}
              </template>
              <template #selected-option="{ name }">
                {{ name }}
              </template>
            </v-select>
          </sw-select>
        </b-col>

        <b-col
          sm="12"
          md="6"
          class="mt-50"
        >
          <sw-select :name="$t('AutomationSection.ContactStatus')">
            <v-select
              v-model="defaultContactStatus"
              :options="contactStatuses"
            >
              <template #no-options>
                {{ $t('NoOptions') }}
              </template>
              <template #option="{ name }">
                {{ name }}
              </template>
              <template #selected-option="{ name }">
                {{ name }}
              </template>
            </v-select>
          </sw-select>
        </b-col>

        <!-- Uploader -->
        <b-col sm="12">
          <drag-drop-upload
            :disabled="loading"
            :show-files="false"
            @onChangeFiles="changeFiles"
          />
        </b-col>
      </b-row>

      <b-row
        v-if="contactsCount"
        class="mt-1"
      >
        <b-col sm="12">
          <div class="d-flex justify-content-between align-items-center mb-1">
            <div>
              {{ $t('ContactsFromFile') }}
              <b-badge>{{ contactsCount }}</b-badge>
            </div>
          </div>
        </b-col>
        <b-col
          sm="12"
          class="table-padding-x-0"
        >
          <div class="d-flex justify-content-between">
            <sw-select class="ml-50">
              <v-select
                v-model="pagination.perPage"
                :options="[25, 50, 100, 250]"
                style="max-width: 100px"
                :clearable="false"
                class="per-page-selector w-100"
              />
            </sw-select>
            <!-- Pagination -->
            <b-pagination
              v-model="pagination.currentPage"
              :total-rows="pagination.totalRecords"
              :per-page="pagination.perPage"
              align="center"
            />
          </div>
          <validation-observer
            ref="validator"
            tag="div"
          >
            <b-table
              :items="importedContacts"
              :fields="contactFields"
              responsive
              fixed
              :tbody-tr-class="getTrClasses"
              hover
              show-empty
              :total="pagination.totalRecords"
              :current-page="pagination.currentPage"
              :per-page="pagination.perPage"
            >
              <template #table-colgroup>
                <col style="width: 5rem">
                <col style="width: 10rem">
                <col style="width: 10rem">
                <col style="width: 10rem">
                <col style="width: 18em">
                <col style="width: 17rem">
                <col style="width: 17rem">
                <col style="width: 10rem">
                <col style="width: 10rem">
                <col style="width: 10rem">
                <col style="width: 10rem">
                <col style="width: 10rem">
                <col style="width: 13rem">
                <col style="width: 13rem">
                <col style="width: 10rem">

                <!-- add as many <col> elements as needed -->
              </template>

              <template #empty>
                <div class="text-center py-1 text-primary font-weight-bold">
                  <feather-icon
                    size="17"
                    icon="XCircleIcon"
                  />
                  {{ $t('NoData') }}
                </div>
              </template>
              <template #head()="{ column }">
                {{ column !== 'Action' ? $t(`contact.${column}`) : $t('Action') }}
              </template>

              <template #cell(Action)="{ index }">
                <b-button
                  variant="flat-danger"
                  size="sm"
                  @click="$delete(importedContacts, index)"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </template>

              <template #cell(AdditionalOptions)="row">
                <b-button
                  size="sm"
                  class="mr-2 btn-icon"
                  variant="outline-primary"
                  :disabled="!row.item.addonFields.length"
                  @click="row.toggleDetails"
                >
                  <feather-icon :icon="row.detailsShowing ? 'MinusIcon' : 'PlusIcon'" />
                </b-button>
              </template>

              <!-- Subcategory Section -->
              <template #row-details="{ item, index }">
                <b-list-group flush>
                  <b-list-group-item
                    v-for="(attribute, key) in item.addonFields"
                    :key="`${key}_${index}`"
                  >
                    <b-badge
                      variant="light-primary"
                      class="mr-25"
                    >
                      {{ $t(`offer.attribute.types.${attribute.type.type}`) }}
                    </b-badge>
                    {{ attribute.name }} (<strong>{{ attribute.value }}</strong>)
                  </b-list-group-item>
                </b-list-group>
              </template>

              <template #cell()="{ value, field, index }">
                <b-form-input
                  :value="value"
                  size="sm"
                  @input="changeContactFieldValue($event, field, index)"
                />
              </template>

              <template #cell(phone)="{ value, field, index, item }">
                <b-input-group
                  class="mr-2"
                >
                  <b-form-input
                    :state="value ? !Boolean((item && item.phoneExist) || existedPhones.includes(value)) : null"
                    :value="value"
                    size="sm"
                    @blur="changeContactFieldValue($event.target.value, field, index)"
                  />
                </b-input-group>
              </template>

              <template #cell(email)="{ value, field, index, item }">
                <b-input-group
                  class="mr-2"
                >
                  <b-form-input
                    :state="value ? !Boolean(((item && item.emailExist) || existedEmails.includes(value))) : null"
                    :value="value"
                    size="sm"
                    @blur="changeContactFieldValue($event.target.value, field, index)"
                  />
                </b-input-group>
              </template>

              <template #cell(assignedUsers)="{ value, field, index }">
                <div style="width: 200px">
                  <assigned-users
                    :value="value"
                    :is-multiple="true"
                    :classes="['select-size-sm']"
                    @input="changeContactFieldValue($event, field, index)"
                  />
                </div>
              </template>

              <template #cell(status)="{ value, field, index }">
                <validation-provider
                  rules="required"
                >
                  <div style="width: 12rem">
                    <sw-select>
                      <v-select
                        :value="value"
                        :options="contactStatuses"
                        class="select-size-sm"
                        label="name"
                        :clearable="false"
                        @option:selected="changeContactFieldValue($event, field, index)"
                      >
                        <template #no-options>
                          {{ $t('NoOptions') }}
                        </template>
                        <template #option="{ name }">
                          {{ name }}
                        </template>
                        <template #selected-option="{ name }">
                          {{ name }}
                        </template>
                      </v-select>
                    </sw-select>
                  </div>
                </validation-provider>
              </template>

              <template #cell(tags)="{ value, field, index }">
                <validation-provider
                  rules="required"
                >
                  <div style="width: 12rem">
                    <sw-select>
                      <v-select
                        :value="value"
                        :options="contactTags"
                        class="select-size-sm"
                        label="name"
                        multiple
                        :clearable="false"
                        @option:selected="changeContactFieldValue($event, field, index)"
                      >
                        <template #no-options>
                          {{ $t('NoOptions') }}
                        </template>
                        <template #option="{ name }">
                          {{ name }}
                        </template>
                        <template #selected-option="{ name }">
                          {{ name }}
                        </template>
                      </v-select>
                    </sw-select>
                  </div>
                </validation-provider>
              </template>
            </b-table>
          </validation-observer>

          <div class="d-flex justify-content-between">
            <sw-select class="ml-50">
              <v-select
                v-model="pagination.perPage"
                :options="[25, 50, 100, 250]"
                style="max-width: 100px"
                :clearable="false"
                class="per-page-selector w-100"
              />
            </sw-select>
            <!-- Pagination -->
            <b-pagination
              v-model="pagination.currentPage"
              :total-rows="pagination.totalRecords"
              :per-page="pagination.perPage"
              align="center"
            />
          </div>
        </b-col>
        <b-col
          v-if="existedEmails.length"
          sm="12"
        >
          <b-alert
            show
            variant="danger"
          >
            <h4 class="alert-heading">
              {{ $t('TypedEmailAddressesAlreadyExistsInDatabase') }}
            </h4>
            <div class="alert-body">
              <b-row>
                <b-col
                  v-for="item in existedEmails.filter(Boolean)"
                  :key="`existedEmails_${item}`"
                  sm="6"
                  md="4"
                >
                  <feather-icon icon="CircleIcon" />
                  {{ item }}
                </b-col>
              </b-row>
            </div>
          </b-alert>
        </b-col>
        <b-col
          v-if="invalidEmails.length"
          sm="12"
        >
          <b-alert
            show
            variant="danger"
          >
            <h4 class="alert-heading">
              {{ $t('TypedEmailAddressesAreInvalid') }}
            </h4>
            <div class="alert-body">
              <b-row>
                <b-col
                  v-for="item in invalidEmails.filter(Boolean)"
                  :key="`invalidEmails_${item}`"
                  sm="6"
                  md="4"
                >
                  <feather-icon icon="CircleIcon" />
                  {{ item }}
                </b-col>
              </b-row>
            </div>
          </b-alert>
        </b-col>

        <b-col
          v-if="existedPhones.length"
          sm="12"
        >
          <b-alert
            show
            variant="danger"
          >
            <h4 class="alert-heading">
              {{ $t('TypedPhonesAlreadyExistsInDatabase') }}
            </h4>
            <div class="alert-body">
              <b-row>
                <b-col
                  v-for="item in existedPhones.filter(Boolean)"
                  :key="`existedPhones_${item}`"
                  sm="6"
                  md="4"
                >
                  <feather-icon icon="CircleIcon" />
                  {{ item }}
                </b-col>
              </b-row>
            </div>
          </b-alert>
        </b-col>
        <b-col
          v-if="invalidPhones.length"
          sm="12"
        >
          <b-alert
            show
            variant="danger"
          >
            <h4 class="alert-heading">
              {{ $t('TypedPhoneAreInvalid') }}
            </h4>
            <div class="alert-body">
              <b-row>
                <b-col
                  v-for="item in invalidPhones.filter(Boolean)"
                  :key="`invalidPhones_${item}`"
                  sm="6"
                  md="4"
                >
                  <feather-icon icon="CircleIcon" />
                  {{ item }}
                </b-col>
              </b-row>
            </div>
          </b-alert>
        </b-col>
        <b-col sm="12">
          <b-button
            :ok-disabled="!importedContacts.length"
            size="sm"
            class="mr-25"
            variant="primary"

            @click="saveImportedContacts"
          >
            <!--              :disabled="
              Boolean(existedEmails.length) ||
                Boolean(invalidEmails.length) ||
                Boolean(existedPhones.length) ||
                Boolean(invalidPhones.length)
            "-->
            {{ $t('ImportContacts') }}
          </b-button>
          <b-button
            size="sm"
            variant="outline-secondary"
            @click="closeModal"
          >
            {{ $t('Cancel') }}
          </b-button>
        </b-col>
      </b-row>
      <b-overlay
        no-wrap
        :show="loading"
        spinner-variant="primary"
      />
    </b-modal>
  </div>
</template>

<script>
import { BAlert, BModal, BTable } from 'bootstrap-vue'
import draggable from 'vuedraggable'

import XLSX from 'xlsx'
import {
  GET_CONTACT_ADDON_FIELD_TYPES,
  GET_CONTACT_STATUSES,
  GET_SINGLE_USER,
  GET_THREAD_TAGS,
} from '@/@constants/mutations'
import AssignedUsers from '@/views/components/AssignedUsers.vue'
import vSelect from 'vue-select'
import axiosIns from '@/libs/axios'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import { mapGetters } from 'vuex'
import moment from 'moment'
import DragDropUpload from '../components/DragDropUpload.vue'

const fields = [
  'firstName',
  'lastName',
  'phone',
  'email',
  'assignedUsers',
  'addressCountry',
  'addressCity',
  'addressPostalCode',
  'addressStreet',
  'addressNumber',
  'status',
  'tags',
  'note',
  'birthDate',
]

export default {
  name: 'ImportContactsModal',
  components: {
    BAlert,
    BTable,
    BModal,
    vSelect,
    draggable,
    DragDropUpload,
    AssignedUsers,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    show: {
      default: true,
      type: Boolean,
    },
  },
  data: () => ({
    required,
    fields: [...fields],
    // fieldsOrder: [...fields],
    fieldsOrder: [
      'firstName',
      'lastName',
      'phone',
      'email',
      'status',
      'birthDate',
      'addressCountry',
      'addressCity',
      'addressPostalCode',
      'addressStreet',
      'addressNumber',
      'tags',
      'note',
      'assignedUsers',
    ],
    importedContacts: [],
    preview: false,
    contactsCount: 0,

    defaultTags: [],
    defaultContactStatus: '',
    contactStatuses: [],
    contactTags: [],
    additionalFieldsList: [],

    loading: false,
    sorter: {
      sortBy: 'createdAt',
      sortDesc: true,
    },
    pagination: {
      perPage: 100,
      currentPage: 1,
      totalRecords: 100,
    },
  }),
  computed: {
    ...mapGetters({
      user: 'auth/getCurrentUser',
    }),
    contactFields() {
      return ['Action', 'AdditionalOptions', ...this.fields]
    },
    invalidEmails() {
      return [...new Set((this.importedContacts.map(contact => contact.email).filter(email => !this.isValidEmail(email)) || []))].filter(Boolean)
    },
    existedEmails() {
      return [...new Set((this.importedContacts.filter(contact => contact.emailExist).map(contact => contact.email) || []))].filter(Boolean)
    },
    existedPhones() {
      return [...new Set((this.importedContacts.filter(contact => contact.phoneExist).map(contact => contact.phone) || []))].filter(Boolean)
    },
    invalidPhones() {
      return [...new Set((this.importedContacts.map(contact => contact.phone).filter(phone => !this.isValidPhone(phone)) || []))].filter(Boolean)
    },
  },
  watch: {
    importedContacts: {
      deep: true,
      handler(newValue, oldValue) {
        if (!newValue.length && oldValue.length) {
          this.importedContacts = oldValue
        }
      },
    },
  },
  async mounted() {
    await this.loadTypes()
    await this.loadContactStatuses()
    await this.loadContactTags()
  },
  methods: {
    closeModal() {
      this.importedContacts = []
      this.preview = false
      this.contactsCount = 0
      this.$emit('close-modal')
    },
    pushItem(field) {
      if (!this.isUsed(field)) this.fieldsOrder.push(field)
    },
    isUsed(field) {
      return this.fieldsOrder.includes(field)
    },
    changeFiles(files) {
      if (files.length) this.readFile(files[0])
    },
    async donwloadSampleXlSX() {
      try {
        const currentUser = await this.$store.dispatch(`users/${GET_SINGLE_USER}`, this.user.id)

        const wb = XLSX.utils.book_new()
        wb.Props = {
          Title: 'SampleXlsx',
        }

        wb.SheetNames.push('Sample')
        // const headers = this.fieldsOrder.map(fieldName => this.$i18n.t(`contact.${fieldName}`))
        const data = this.fieldsOrder.map(fieldName => {
          if (fieldName === 'assignedUsers') return currentUser.email

          return currentUser[fieldName]
        })

        const ws = XLSX.utils.aoa_to_sheet([[], data])
        wb.Sheets.Sample = ws

        XLSX.writeFile(wb, 'Sample.xlsx')
      } catch (err) {
        console.error(err)
      }
    },
    readFile(file) {
      const reader = new FileReader()
      reader.onload = e => {
        const ab = e.target.result
        const wb = XLSX.read(new Uint8Array(ab), { type: 'array' })
        const wsname = wb.SheetNames[0]
        const ws = wb.Sheets[wsname]
        const data = XLSX.utils.sheet_to_json(ws, {
          blankrows: false,
          header: 1,
          raw: true,
          cellDates: true,
          dateNF: 'yyyy-mm-dd',
          defval: '',
          range: 0,
          rawDates: true,
          cellNF: false,
        })

        this.prepareContactsFromXLS(data)
      }
      reader.readAsArrayBuffer(file)
    },
    async prepareContactsFromXLS(rows = []) {
      if (rows[0].includes(this.$i18n.t('contact.firstName')) || rows[0].includes(this.$i18n.t('contact.email'))) rows.shift()
      const contacts = []

      try {
        rows.forEach(contactRow => {
          if (Array.isArray(contactRow) && !contactRow.length) return

          const contact = {
            assignedUsers: [
              { ...this.$store.getters['auth/getCurrentUser'] },
            ],
            status: this.contactStatuses[0],
          }

          fields.forEach(contactField => {
            const index = this.fieldsOrder.indexOf(contactField)
            contact.addressCorrespondenceCountry = ''
            contact.addressCorrespondenceCity = ''
            contact.addressCorrespondencePostalCode = ''
            contact.addressCorrespondenceStreet = ''
            contact.addressCorrespondenceNumber = ''
            contact.agreeMarketing = false
            contact.company = ''
            contact.note = ''
            contact.addonFields = []

            if (contactField === 'status') {
              contact[contactField] = this.resolveStatus(contactRow[index])
            } else if (contactField === 'tags') {
              contact[contactField] = this.resolveTags(contactRow[index])
            } else if (contactField === 'email') {
              contact[contactField] = contactRow[index] || ''

              // eslint-disable-next-line prefer-destructuring
              contact[contactField] = contact[contactField].split('/')[0].trim()
            } else if (contactField !== 'assignedUsers') {
              contact[contactField] = contactRow[index] || ''
            } else {
              contact[contactField] = contactRow[index] ? contactRow[index].replaceAll(' ', '').split(',') : []
            }
          })

          // resove addon fields
          const indexFrom = this.fieldsOrder.length
          const addonFieldsRows = (contactRow.slice(indexFrom) || []).splitIntoChunks(2)
          addonFieldsRows.forEach(addonField => {
            const [name, value] = addonField
            const settingsField = this.additionalFieldsList.find(e => e.name === String(name).trim())

            let formatedValue = value

            if (settingsField?.type === 'DATE' && typeof formatedValue === 'number') {
              formatedValue = moment(new Date(Math.round((Number(formatedValue) - 25569) * 86400 * 1000))).format('YYYY-MM-DD')
            }

            if (settingsField) {
              contact.addonFields.push({
                name: String(name),
                value: formatedValue,
                type: settingsField,
              })
            }
          })

          contacts.push(contact)
        })
      } catch (err) {
        console.error(err)
      }
      // this.$set(this, 'importedContacts', contacts)
      Promise.all([this.checkExistedEmailsNew(contacts), this.checkExistedPhones(contacts)])
        .then(async () => {
          if (contacts.length) {
            this.contactsCount = contacts.length
            this.pagination.totalRecords = contacts.length
            if (this.fieldsOrder.includes('assignedUsers')) {
              await this.resolveAssignedUsers(contacts)
                .then(res => {
                  this.$set(this, 'importedContacts', res)
                })
            } else this.$set(this, 'importedContacts', contacts)
          }
        })
        .catch(err => {
          console.error(err)
        })
    },
    loadTypes() {
      this.isLoading = true

      this.$store.dispatch(`addonFieldTypes/${GET_CONTACT_ADDON_FIELD_TYPES}`)
        .then(res => {
          this.additionalFieldsList = (res || [])
          return true
        }).catch(err => err)
    },
    resolveTags(tagsString = '') {
      const tagsList = tagsString.split(',').map(tag => String(tag).trim()).filter(Boolean)
      if (tagsList.length) {
        return tagsList.map(tagItem => {
          const tag = this.contactTags.find(t => t.name.toLowerCase() === tagItem.toLowerCase().trim())
          if (!tag) return null

          return tag
        }).filter(Boolean)
      }

      if (!tagsString && this.defaultTags?.length) return [this.defaultTags]
      if (!tagsString && !this.defaultTags?.length) return []

      return [this.contactTags[0]]
    },
    resolveStatus(status) {
      if (status) {
        const existedStatus = this.contactStatuses.find(e => e.name.toLowerCase() === status.toLowerCase().trim())
        if (existedStatus) return existedStatus
      }

      if (!status && this.defaultContactStatus) return [this.defaultContactStatus]
      if (!status && !this.defaultContactStatus) return this.defaultContactStatus

      return this.defaultContactStatus
    },
    // New
    // eslint-disable-next-line no-unused-vars
    checkExistedEmailsNew(contacts = [], value = null) {
      const allEmails = contacts.map(user => user?.email).filter(Boolean)
      const duplicateElementa = allEmails.filter((e, i, a) => a.indexOf(e) !== i)

      return new Promise((resolve, reject) => {
        const emails = Array.isArray(contacts) ? contacts.map(contact => contact.email) : [typeof contacts === 'object' ? contacts.email : contacts]
        axiosIns.post('1/contacts/checkMailAddresses', value ? [value, ...emails] : emails)
          .then(res => {
            const duplicates = [...res.data.data.exists, ...duplicateElementa].filter(Boolean)

            this.changeExistedEmails(duplicates, contacts)
            resolve()
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            reject(err)
          })
      })
    },
    isValidEmail(email) {
      const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      return validRegex.test(email)
    },
    isValidPhone(phone) {
      // eslint-disable-next-line no-underscore-dangle
      const _phone = phone ? String(phone) : null
      if (!_phone) return true

      // Test no country code
      let validRegex = /^\+\d{1,3}\s\d{1,14}$/
      if (_phone && _phone.includes('+') && validRegex) return validRegex.test(_phone)

      validRegex = /^\d{1,14}$/
      if (_phone) return validRegex.test(_phone)

      return true
    },
    changeExistedEmails(duplicates, contacts) {
      contacts.forEach(contact => {
        if (contact.email && (duplicates.includes(contact.email) || !this.isValidEmail(contact.email))) {
          this.$set(contact, 'emailExist', true)
        } else {
          this.$set(contact, 'emailExist', false)
        }
      })
    },
    // Existed phones
    // eslint-disable-next-line no-unused-vars
    checkExistedPhones(contacts = [], value = null) {
      const allPhones = contacts.map(user => user?.phone).filter(Boolean).map(String)
      const duplicateElementa = allPhones.filter((e, i, a) => a.indexOf(e) !== i)

      return new Promise((resolve, reject) => {
        let phones = Array.isArray(contacts) ? contacts.map(contact => String(contact.phone)) : [typeof contacts === 'object' ? String(contacts.phone) : contacts]
        phones = phones.map(phone => {
          if (phone && phone.includes('+')) {
            return phone
          } if (phone && !phone.includes('+')) {
            return `+48 ${phone}`
          }

          return null
        }).filter(Boolean)
        // eslint-disable-next-line no-underscore-dangle
        const _value = value && value.includes('+') ? value : `+48 ${value}`
        axiosIns.post('1/contacts/getExistsByPhones', _value ? [_value, ...phones] : phones)
          .then(res => {
            const duplicates = [...res.data.data.exists.map(c => c.phone), ...duplicateElementa].filter(Boolean)
            this.changeExistedPhones(duplicates, contacts)
            resolve()
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            reject(err)
          })
      })
    },
    changeExistedPhones(duplicates, contacts) {
      contacts.forEach(contact => {
        const phone = String(contact.phone)
        let value = ''
        if (phone && phone?.includes('+')) {
          value = contact.phone
        } if (phone && !phone?.includes('+')) {
          value = `+48 ${contact.phone}`
        }

        if (contact.phone && (duplicates.includes(String(value)) || !this.isValidPhone(value))) {
          this.$set(contact, 'phoneExist', true)
        } else {
          this.$set(contact, 'phoneExist', false)
        }
      })
    },

    resolveAssignedUsers(contacts) {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        let emails = []
        contacts.forEach(contact => {
          emails.push(...contact.assignedUsers)
        })
        emails = [...new Set(emails)]

        const params = {
          fields_load: this.$fields.USERS_SEARCH,
          'eqArr_mailAddress-mail': JSON.stringify(emails),
        }
        axiosIns.get('1/users', { params })
          .then(res => {
            const users = res.data.data.items
            contacts.forEach(contact => {
              const usersToAssign = []
              contact.assignedUsers.forEach(user => {
                const userToAssign = users.find(dbUser => dbUser.email === user)
                if (userToAssign) usersToAssign.push(userToAssign)
              })
              if (!usersToAssign.length) {
                if (!usersToAssign.some(user => user?.id === this.$store.getters['auth/getCurrentUser'].id)) usersToAssign.push({ ...this.$store.getters['auth/getCurrentUser'] })
              }
              // eslint-disable-next-line
              contact.assignedUsers = usersToAssign
            })
            resolve(contacts)
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            reject()
          })
      })
    },
    emailExist(item) {
      return !item?.emailExist ? { icon: 'CheckIcon', classes: 'text-success' } : { icon: 'AlertCircleIcon', classes: 'text-danger' }
    },
    phoneExist(item) {
      return !item?.phoneExist ? { icon: 'CheckIcon', classes: 'text-success' } : { icon: 'AlertCircleIcon', classes: 'text-danger' }
    },
    async loadContactStatuses() {
      this.loading = true

      try {
        const { data } = await this.$store.dispatch(`typeSettings/${GET_CONTACT_STATUSES}`)
        this.contactStatuses = data.items
        return data.items
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        return err
      } finally {
        this.loading = false
      }
    },
    async loadContactTags() {
      this.loading = true
      try {
        const { data } = await this.$store.dispatch(`typeSettings/${GET_THREAD_TAGS}`)
        this.contactTags = data.items
        return data.items
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        return err
      } finally {
        this.loading = false
      }
    },
    /* eslint-disable */
    changeContactFieldValue(value, { key }, index) {
      this.$set(this.importedContacts[index], key, value)

      if (key === 'email') {
        this.$nextTick(() => {
          this.checkExistedEmailsNew(this.importedContacts, value)
        })
      }

      if (key === 'phone') {
        this.$nextTick(() => {
          this.checkExistedPhones(this.importedContacts, value)
        })
      }
    },
      getTrClasses(item, type) {
        if (item?.emailExist || item?.phoneExist) return ['border border-danger border-3']
        return []
      },
    /* eslint-disable */
    async saveImportedContacts() {
        await this.loadContactStatuses()
      // const isValid = await this.$refs.validator.validate()
      // if (!isValid) return


      const newOptions = {}
      let contacts = JSON.parse(JSON.stringify(this.importedContacts))
      contacts.forEach(contact => {
        contact.phone = contact.phone.toString().trim()
        if (contact.phone && !contact.phone.includes('+')) contact.phone = `+48 ${contact.phone.trim().replaceAll(' ', '')}`

        contact.status = typeof contact.status === 'object' ? contact.status.id : contact.status
        contact.phoneNumber = contact.phone.toString()
        contact.assignedUsers = contact.assignedUsers.map(user => (typeof user === 'object' ? user.id : user))
        contact.phoneNumbers = [contact.phone.toString()]
        contact.mailAddress = contact.email?.trim()?.replaceAll(' ', '')?.replaceAll('\n', '')
        contact.mailAddresses = [contact.email?.trim()?.replaceAll(' ', '')?.replaceAll('\n', '')]
        contact.isOrganization = false
        contact.note = String(contact.note)
        contact.firstName = String(contact?.firstName || '')
        contact.lastName = String(contact?.lastName || '')
        contact.addressNumber = contact.addressNumber ? String(contact.addressNumber) : null

          if (!contact.status && this.contactStatuses[0]) {
              contact.status = this.getObjectId(this.contactStatuses[0])
          }

          if (contact.tags?.length) {
            contact.contactTypes = (contact.tags || []).mapKeys('id')
          }

          if (contact?.addonFields?.length) {
            contact.addonFields.forEach(addonField => {
              // znajdz mi typ
              const existAttribute = this.additionalFieldsList.find(createdAddonField => createdAddonField.name === addonField.name)

              if (existAttribute) {
                if (existAttribute.type.includes('ARRAY') || existAttribute.type.includes('RADIAL')) {
                  const values = String(addonField.value).replaceAll(', ', ',').split(',')

                  values.forEach(value => {
                    const trimedValue = String(value).toLowerCase().trim()
                    if (!newOptions[existAttribute.id]) {
                      newOptions[existAttribute.id] = [trimedValue]
                    }

                    const valueExist = existAttribute.contactAddonFieldTypeOptions.find(option => option.name?.toLowerCase() === trimedValue)

                    if (valueExist && !newOptions[existAttribute.id].includes(trimedValue)) {
                      newOptions[existAttribute.id].push(trimedValue)
                    } else if (!valueExist && !newOptions[existAttribute.id].includes(trimedValue)) {
                        newOptions[existAttribute.id].push(trimedValue)
                    }
                  })
                }
              }
            })
          }
      })

      if (Object.keys(newOptions).length && this.checkRequiredPermissions([this.$roles.SETTINGS])) {
        const payload = Object.keys(newOptions).map(id => {
          return { id, contactAddonFieldTypeOptions: newOptions[id].map(name => ({ name })) }
        })
        const [resp, err] = await this.saveAddonFieldsOptions(payload)
        if (err) return

        if (resp) await this.loadTypes()
      }

      contacts.forEach(contact => {
       if (this.checkRequiredPermissions([this.$roles.CONTACT_ADDON_FIELD_MODIFY])) {
         contact.contactAddonFields = contact.addonFields.map(addonField => {
           const existAttribute = this.additionalFieldsList.find(createdAddonField => createdAddonField.name === addonField.name)
           if (!existAttribute) return

           const contactAddonFieldType = existAttribute.id;
           let contactAddonFieldTypeOptions = [];
           let value = null;

           if (existAttribute.type.includes('ARRAY')) {
             const values = String(addonField.value).replaceAll(', ', ',').split(',')

             contactAddonFieldTypeOptions = values.map(value => {
               const trimedValue = String(value).toLowerCase().trim()
               const valueExist = existAttribute.contactAddonFieldTypeOptions.find(option => option.name?.toLowerCase() === trimedValue)
               if (!valueExist) return null

               return valueExist.id
             }).filter(Boolean)
           } else value = String(addonField.value || '')

           return { contactAddonFieldTypeOptions, contactAddonFieldType, value }
         }).filter(Boolean).filter(e => e.value)
       }

        delete contact.addonFields
        delete contact.tags
      })
      contacts = contacts.filter(contact => !this.fieldsOrder.includes('email') || !contact.emailExist || !contact.mailAddress).map(contact => contact)
      contacts = contacts.filter(contact => !this.fieldsOrder.includes('phone') || !contact.phoneExist || !contact.phoneNumber).map(contact => contact)

      if (!contacts.length) return

      await this.$store.dispatch('importer/UPLOAD_IMPORTED_CONTACTS', { data: contacts, uploadUrl: '1/contacts', importerLabel: 'Contacts' })
      this.closeModal()
      // this.$store.dispatch(`contacts/${CREATE_CONTACT}`, contacts)
      //   .then(() => {
      //     this.showToast('success', this.$i18n.t('success.contactUpdated'))
      //     this.$emit('reload-content')
      //   })
      //   .catch(err => {
      //     this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      //   })
    },
    async saveAddonFieldsOptions(payload) {
      try {
        const resp = await axiosIns.patch('1/settings/contactAddonFieldsTypes', payload)

        return [resp, null]
      } catch (err) {
        return [null, err]
      }
    },
  },
}
</script>

<style lang="scss">
.badge-item {
  transition: all 1s
}

.table-padding-x-0 {
  td, th {
    padding: 0.6rem 0.5rem !important;
  }
}

[dir=ltr] .modal-xl {
  margin-left: 15% !important;
  margin-right: 15% !important;
}
</style>
