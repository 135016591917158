const campaigns = [
  {
    path: '/campaigns',
    name: 'campaigns',
    component: () => import('@/pages/campaigns/Campaigns.vue'),
    meta: {
      autorize: true,
      pageTitle: 'Campaigns',
    },
  },
  // {
  //   path: '/campaigns/sms',
  //   name: 'sms',
  //   component: () => import('@/pages/campaigns/sms/Sms.vue'),
  //   meta: {
  //     autorize: true,
  //     pageTitle: 'Sms',
  //   },
  // },
  // {
  //   path: '/campaigns/mailing',
  //   name: 'mailing',
  //   component: () => import('@/pages/campaigns/mailing/Mailing.vue'),
  //   meta: {
  //     autorize: true,
  //     pageTitle: 'Mailing',
  //   },
  // },
]

export default campaigns
