<template>
  <validation-observer
    ref="newMicroTaskForm"
    v-slot="{ invalid }"
    tag="form"
  >
    <!--      New Task: Title      -->
    <validation-provider
      v-slot="{ errors }"
      rules="required|max-input"
      :name="$t('Title')"
    >
      <b-form-group :label="$t('Title')">
        <b-form-textarea
          v-model="name"
          :disabled="!isEdit"
          :placeholder="$t('Title')"
          :state="errors.length > 0 ? false:null"
          @input="e => $emit('getTitle', e)"
        />

        <small class="text-danger">{{ errors[0] }}</small>
      </b-form-group>
    </validation-provider>

    <!--      Project      -->
    <validation-provider
      v-slot="{ errors }"
      rules="required"
      :name="$t('Offer.Project')"
    >
      <sw-select
        :name="$t('Offer.Project')"
        :disabled="!isEdit"
      >
        <v-select
          v-model="project"
          :placeholder="$t('Offer.Project')"
          :options="projects"
          :disabled="!isEdit"
          :filterable="false"
          label="name"
          :reduce="item => item.id.toString()"
          item-value="id"
          item-text="name"
          :state="errors.length > 0 ? false:null"
          @input="loadTasks"
          @clear="loadTasks()"
        >
          <template #no-options>
            {{ $t('NoOptions') }}
          </template>
        </v-select>
      </sw-select>

      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>

    <!--      Task      -->
    <validation-provider
      v-slot="{ errors }"
      rules="required"
      :name="$t('timeline.Task')"
    >
      <sw-select
        :name="$t('timeline.Task')"
        :disabled="!isEdit"
      >
        <v-select
          v-model="projectTask"
          :placeholder="$t('timeline.Task')"
          :options="tasks"
          :disabled="!isEdit"
          :filterable="false"
          label="name"
          :reduce="item => item.id.toString()"
          item-value="id"
          item-text="name"
          :state="errors.length > 0 ? false:null"
        >
          <template #no-options>
            {{ $t('NoOptions') }}
          </template>
        </v-select>
      </sw-select>

      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>

    <!--      User      -->
    <validation-provider
      v-slot="{ errors }"
      :name="$t('SelectUsers')"
    >
      <sw-select
        :name="$t('SelectUsers')"
        :disabled="!isEdit"
      >
        <v-select
          id="offer-thread-1"
          v-model="assignedUser"
          :options="userList"
          label="firstName"
          :filterable="false"
          :state="errors.length > 0 ? false:null"
          :placeholder="$t('SelectUsers')"
          :disabled="!isEdit"
          @search="loadUsers"
          @select="save"
        >
          <template #no-options="{ search }">
            <span v-if="search.length">
              {{ $t('NoData') }}
            </span>
            <span v-else>
              {{ $t('TypeToSearch') }}
            </span>
          </template>

          <template #option="{ firstName, lastName, avatar }">
            <div class="d-flex align-items-center">
              <avatar
                :user="{ firstName, lastName, avatar }"
                class="mr-25"
              />
            </div>
          </template>

          <template #selected-option="{ firstName, lastName, avatar }">
            <div class="d-flex">
              <avatar
                :user="{ firstName, lastName, avatar }"
                class="mr-25"
              />
            </div>
          </template>
        </v-select>
      </sw-select>

      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>

    <!--      Time      -->
    <div>
      <!--    Task time -->
      <b-form-checkbox
        v-model="isWholeDay"
        variant="primary"
        :disabled="!isEdit"
        class="custom-control-primary"
      >
        {{ $t('WholeDay') }}
      </b-form-checkbox>

      <b-row>
        <b-col :cols="isWholeDay ? 12 : 6">
          <sw-select>
            <sw-select-fpr
              :is-empty="startAt"
              @clear="() => {
                startAt = ''

                if (isWholeDay) endAt = ''
              }"
            >
              <flat-pickr
                :key="`time_${isWholeDay}`"
                v-model="startAt"
                :disabled="!isEdit"
                :placeholder="isWholeDay ? $t('TaskTerm') : $t('TaskStartTerm')"
                class="form-control mt-50"
                :config="{ ...flatPickerConfig, enableTime: !isWholeDay, locale: getCalendarLocale($i18n.locale), maxDate: endAt && !isWholeDay ? endAt : '', dateFormat: isWholeDay ? 'Y-m-d' : 'Y-m-d H:i' }"
              />
            </sw-select-fpr>
          </sw-select>
        </b-col>

        <b-col
          v-if="!isWholeDay"
          cols="6"
        >
          <sw-select>
            <sw-select-fpr
              :is-empty="endAt"
              @clear="endAt = ''"
            >
              <flat-pickr
                :key="`time_end_${isWholeDay}`"
                v-model="endAt"
                :disabled="!isEdit"
                :placeholder="isWholeDay ? $t('TaskTerm') : $t('TaskEndTerm')"
                class="form-control mt-50"
                :config="{ ...flatPickerConfig, enableTime: !isWholeDay, locale: getCalendarLocale($i18n.locale), dateFormat: isWholeDay ? 'Y-m-d' : 'Y-m-d H:i', minDate: startAt ? startAt : '' }"
              />
            </sw-select-fpr>
          </sw-select>
        </b-col>
      </b-row>
    </div>

    <!--      Files      -->
    <drag-drop-upload
      :disabled="Boolean(id)"
      :default-value="files"
      @onChangeFiles="changeFiles"
    />

    <!--      Button: Save      -->
    <b-button
      v-if="isEdit"
      size="sm"
      variant="primary"
      :disabled="invalid || isLoading"
      class="mt-1"
      @click="save()"
    >
      {{ $t('Save') }}
    </b-button>

    <b-overlay
      no-wrap
      :show="isLoading"
      spinner-variant="primary"
    />
  </validation-observer>
</template>

<script>
import vSelect from 'vue-select'
import { Polish as pl } from '@/libs/i18n/locales/flatpickr/pl'
import { english as en } from '@/libs/i18n/locales/flatpickr/en'
import { mapGetters } from 'vuex'
import flatPickr from 'vue-flatpickr-component'
import DragDropUpload from '@/views/components/DragDropUpload.vue'
import {
  CLOSE_MODAL,
  CREATE_MICRO_TASK,
  CREATE_PROJECT_DOCUMENT,
  GET_PROJECT_DOCUMENTS,
  GET_PROJECT_TASKS_FROM_PROJECT,
  GET_PROJECTS,
  RELOAD_CONTENT,
  UPDATE_MICRO_TASK,
  UPLOAD_FILES,
} from '@/@constants/mutations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import moment from 'moment/moment'
import { BFormTextarea } from 'bootstrap-vue'
import { USERS_SEARCH } from '@/@constants/fields'

export default {
  components: {
    vSelect,
    flatPickr,
    DragDropUpload,
    ValidationObserver,
    ValidationProvider,
    BFormTextarea,
  },
  data: () => ({
    isLoading: false,

    isEdit: true,

    isWholeDay: false,

    startAt: '',
    endAt: '',

    flatPickerConfig: {
      enableTime: true,
      dateFormat: 'Y-m-d H:i',
      time_24hr: true,
      locale: [pl, en],
    },

    id: null,
    name: '',

    projects: [],
    project: '',

    tasks: [],
    projectTask: '',

    userList: [],
    assignedUser: [],

    files: [],
  }),
  computed: {
    locale() {
      return this.$i18n.locale
    },
    ...mapGetters({
      modalConfig: 'modal/getModalState',
      system: 'system/getSettings',
    }),
  },
  watch: {
    project(n) {
      this.loadTasks(n)
    },

    projectTask() {
      this.userList = []
      this.assignedUser = []
    },

    isWholeDay: {
      deep: true,
      handler(n) {
        if (n) {
          this.endAt = this.startAt
            ? moment(this.startAt, 'YYYY-MM-DD HH:mm').add('hours', 23.99).format('YYYY-MM-DD HH:mm')
            : ''
        }
      },
    },

    startAt(n) { if (n) this.setDate() },
    endAt(n) { if (n) this.setDate() },
  },
  mounted() {
    this.ini()
  },
  methods: {
    ini() {
      if (this.modalConfig?.data?.microTask?.id) {
        this.loadMicroTask(this.modalConfig?.data.microTask)
      } else if (this.modalConfig?.data?.task?.id) {
        this.loadTask(this.modalConfig?.data.task)
      } else {
        this.loadProjects()
      }

      this.$nextTick(() => {
        this.assignedUser = this.modalConfig?.data?.microTask?.assignedUser || []

        if (this.project && this.projectTask) this.getDocument(this.project, this.projectTask)
      })
    },

    loadProjects() {
      this.isLoading = true

      this.$store.dispatch(`projects/${GET_PROJECTS}`)
        .then(res => {
          this.projects = res

          if (!res.find(el => el.id === this.project)) this.project = ''
        })

        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err.response.data.message}`))
        })

        .finally(() => { this.isLoading = false })
    },

    loadTasks(project) {
      if (project > 0) {
        this.isLoading = true

        this.$store.dispatch(`tasks/${GET_PROJECT_TASKS_FROM_PROJECT}`, project)
          .then(res => {
            this.tasks = res

            if (!res.find(el => el.id === this.projectTask)) this.projectTask = ''
          })

          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err.response.data.message}`))
          })

          .finally(() => { this.isLoading = false })
      } else {
        this.tasks = []
        this.projectTask = ''
      }
    },

    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.newMicroTaskForm.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },

    save() {
      this.isLoading = true

      if (this.validationForm()) {
        const payload = this.getPayload()

        this.send(payload)
      } else {
        this.isLoading = false
      }
    },

    getPayload() {
      const {
        id,
        name,
        startAt,
        endAt,
        project,
        projectTask,
        assignedUser,
        isWholeDay,
      } = this

      const payload = {
        name,
        startAt,
        endAt,
        project,
        projectTask,
        assignedUser: assignedUser.id || '',
        doneType: null,
      }

      if (isWholeDay) {
        if (startAt) {
          payload.startAt = moment(payload.startAt).format('YYYY-MM-DD HH:mm')
          payload.endAt = moment(payload.startAt, 'YYYY-MM-DD HH:mm').add('hours', 23.99).format('YYYY-MM-DD HH:mm')
        } else {
          payload.endAt = ''
        }
      }

      if (id) payload.id = this.id

      return payload
    },

    send(payload) {
      const action = payload?.id ? UPDATE_MICRO_TASK : CREATE_MICRO_TASK

      this.$store.dispatch(`microTasks/${action}`, payload)
        .then(res => { this.sendDocuments(payload.id, res.data.items[0].id) })

        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })

        .finally(() => { this.isLoading = false })
    },

    getDocument(project, task) {
      const payload = {
        projectId: project,
        taskId: task,
      }

      this.$store.dispatch(`projectDocuments/${GET_PROJECT_DOCUMENTS}`, payload)
      // eslint-disable-next-line no-unused-vars
        .then(res => {
          // this.files = res.map(doc => doc.files)
          //
          // console.log(this.files)
        })

        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })

        .finally(() => { this.isLoading = false })
    },

    async sendDocuments(isNew, projectTaskMicroTask) {
      const {
        name,
        project,
        projectTask,
        files,
      } = this

      if (files.length) {
        const fileList = files.filter(file => file?.token)

        const filePayload = { uploadedFiles: files.filter(file => !file?.token) }
        const newFiles = await this.$store.dispatch(`uploader/${UPLOAD_FILES}`, filePayload)

        fileList.push(...newFiles.files)

        const payload = {
          name: `${name} ${project}/${projectTask}/${projectTaskMicroTask}`,
          project,
          projectTask,
          projectTaskMicroTask,
          files: fileList,
        }

        this.$store.dispatch(`projectDocuments/${CREATE_PROJECT_DOCUMENT}`, payload)
          .then(() => {
            this.showToast('success', this.$i18n.t(isNew ? 'MicroTaskUpdated' : 'MicroTaskAdded'))

            this.$store.commit(`modal/${RELOAD_CONTENT}`, projectTaskMicroTask)

            this.$nextTick(() => this.close())
          })

          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          })

          .finally(() => { this.isLoading = false })
      } else {
        this.showToast('success', this.$i18n.t(isNew ? 'MicroTaskUpdated' : 'MicroTaskAdded'))

        this.$store.commit(`modal/${RELOAD_CONTENT}`, projectTaskMicroTask)

        this.$nextTick(() => this.close())

        this.isLoading = false
      }
    },

    loadMicroTask(microTask) {
      if (microTask?.id) {
        this.isLoading = true

        this.id = microTask.id
        this.name = microTask.name
        this.project = microTask.projectTask.project.id
        this.projectTask = microTask.projectTask.id
        this.assignedUser = microTask.assignedUser
        this.startAt = (microTask.startAt?.date || microTask.startAt || '').slice(0, 16)
        this.endAt = (microTask.endAt?.date || microTask.endAt || '').slice(0, 16)

        this.isWholeDay = moment(this.endAt).diff(moment(this.startAt), 'days', true) === 1

        this.loadProjects()

        this.isLoading = false
      }
    },

    loadTask(task) {
      if (task?.id) {
        this.isLoading = true

        this.project = task.project.id
        this.projectTask = task.id

        this.loadProjects()

        this.isLoading = false
      }
    },

    close() { this.$store.commit(`modal/${CLOSE_MODAL}`) },

    changeFiles(files) { this.files.push(...files) },

    async loadUsers(search, isLoading) {
      const { projectTask, tasks } = this

      const filters = {}

      if (projectTask) {
        filters.allowedUsers = tasks.find(p => p.id === (projectTask?.id || projectTask)).assignedUsers.map(u => u.id)

        this.userList = await this.selectSearch(search, isLoading, USERS_SEARCH, 'firstName,lastName', '1/users', 25, filters)
      }
    },

    setDate() {
      if (this.isWholeDay) {
        this.startAt = moment(this.startAt).format('YYYY-MM-DD HH:mm')
        this.endAt = moment(this.startAt, 'YYYY-MM-DD HH:mm').add('hours', 23.99).format('YYYY-MM-DD HH:mm')
      }

      this.isWholeDay = moment(this.endAt).diff(moment(this.startAt), 'days', true) === 1
        || Math.round(moment(this.endAt).diff(moment(this.startAt), 'hours', true) * 10) / 10 === 24
    },
  },
}
</script>
