<template>
  <validation-observer
    ref="form"
    class="d-flex flex-column"
    style="row-gap: 1.34rem"
  >
    <!--    START:Header    -->
    <div
      class="d-flex flex-column flex-md-row align-md-items-center justify-md-content-between"
      style="column-gap: 1.5rem"
    >
      <!--    START:Name & Hint    -->
      <b-row
        class="d-flex flex-grow-1"
        style="row-gap: .34rem"
      >
        <!--    START:Name    -->
        <b-col
          cols="12"
          md="6"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="$t('EnterFieldName')"
            rules="required"
          >
            <b-input-group>
              <b-form-input
                v-model="form.name"
                :label="$t('EnterFieldName')"
                :placeholder="$t('EnterFieldName')"
              />

              <b-input-group-append>
                <b-button
                  v-b-tooltip.hover.v-primary
                  variant="outline-primary"
                  :title="$t('calculators.sectionTypes.SingleSelectionList')"
                  class="btn-icon px-75"
                  style="cursor: default !important"
                >
                  <sw-icon icon="InfoIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <!--    END:Name    -->

        <!--    START:Hint    -->
        <b-col
          cols="12"
          md="6"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="$t('AddHint')"
          >
            <b-form-input
              v-model="form.hint"
              :label="$t('AddHint')"
              :placeholder="$t('AddHint')"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <!--    END:Hint    -->
      </b-row>
      <!--    END:Name & Hint    -->

      <!--    START:Toolbar MD    -->
      <div
        class="d-none d-md-flex align-items-md-start mt-md-50"
        style="row-gap: .5rem"
      >
        <b-form-checkbox
          v-model="form.show"
          v-b-tooltip.hover.v-success
          checked="true"
          :title="$t('FieldHiddenVisible')"
          class="custom-control-success"
          name="show"
          switch
        >
          <span class="switch-icon-left">
            <feather-icon icon="EyeIcon" />
          </span>
          <span class="switch-icon-right">
            <feather-icon icon="EyeOffIcon" />
          </span>
        </b-form-checkbox>

        <b-button
          v-b-tooltip.hover.v-danger
          variant="flat-danger"
          size="sm"
          class="btn-icon mt-md-n25"
          :title="$t('Delete')"
          @click="$emit('rm')"
        >
          <feather-icon icon="TrashIcon" />
        </b-button>
      </div>
      <!--    END:Toolbar MD    -->
    </div>
    <!--    END:Header    -->

    <!--    START:Content    -->
    <div>
      <h5>
        {{ $t('DefineFieldValues') }}
      </h5>

      <!--    START:Fields    -->
      <b-row
        class="mb-50 d-flex"
        style="row-gap: 1rem;"
      >
        <b-col
          v-for="(field, i) in form.options.fieldList"
          :key="i"
          cols="12"
          lg="6"
        >
          <div
            class="px-1 py-1 d-flex flex-column flex-md-row align-items-md-center"
            style="background: var(--bg-shopItem-lighten-3); border: 0; border-radius: 6px; column-gap: 1.34rem; row-gap: .64rem;"
          >
            <!--    START:Field Name & Value    -->
            <b-row
              class="flex-grow-1"
              style="row-gap: .5rem"
            >
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('Name')"
                  rules="required"
                >
                  <b-form-input
                    v-model="field.name"
                    :label="$t('Name')"
                    :placeholder="$t('Name')"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>

              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('Value')"
                  rules="required"
                >
                  <b-form-input
                    v-model="field.value"
                    :label="$t('Value')"
                    :placeholder="$t('Value')"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
            <!--    END:Field Name & Value    -->

            <!--    START:Delete Field    -->
            <div class="d-flex justify-content-end">
              <b-button
                variant="danger"
                size="sm"
                class="btn-icon"
                @click="form.options.fieldList.splice(i, 1)"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </div>
            <!--    END:Delete Field    -->
          </div>
        </b-col>

        <!--    START:Add New Field    -->
        <b-col
          cols="12"
          lg="6"
          class="d-flex"
        >
          <b-button
            variant="outline-primary"
            class="flex-grow-1 py-2"
            @click="form.options.fieldList.push({ })"
          >
            <feather-icon icon="PlusIcon" />

            {{ $t('Add') }}
          </b-button>
        </b-col>
        <!--    END:Add New Field    -->
      </b-row>
      <!--    END:Fields    -->
    </div>
    <!--    END:Content    -->

    <!--    START:Options    -->
    <b-form-group
      :label="$t('Options')"
      class="mb-0"
    >
      <template #label>
        <h5 class="mb-25">
          {{ $t('Options') }}
        </h5>
      </template>

      <b-form-checkbox
        v-model="form.required"
        class="custom-control-primary"
      >
        {{ $t('MandatoryChoice') }}
      </b-form-checkbox>
    </b-form-group>
    <!--    END:Options    -->

    <!--    START:Toolbar SM    -->
    <div
      class="d-flex align-items-center justify-content-end mt-n1 d-md-none"
      style="row-gap: .5rem"
    >
      <b-form-checkbox
        v-model="form.show"
        v-b-tooltip.hover.v-success
        checked="true"
        :title="$t('FieldHiddenVisible')"
        class="custom-control-success"
        name="show"
        switch
      >
        <span class="switch-icon-left">
          <feather-icon icon="EyeIcon" />
        </span>
        <span class="switch-icon-right">
          <feather-icon icon="EyeOffIcon" />
        </span>
      </b-form-checkbox>

      <b-button
        v-b-tooltip.hover.v-danger
        variant="flat-danger"
        size="sm"
        class="btn-icon"
        :title="$t('Delete')"
        @click="$emit('rm')"
      >
        <feather-icon icon="TrashIcon" />
      </b-button>
    </div>
    <!--    END:Toolbar SM    -->
  </validation-observer>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { BInputGroupAppend, VBTooltip } from 'bootstrap-vue'

export default {
  components: {
    BInputGroupAppend,
    ValidationObserver,
    ValidationProvider,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },

  emits: ['isValid', 'rm'],

  data: () => ({
    form: {
      options: {
        fieldList: [],
      },
      show: true,
    },
  }),

  watch: {
    form: {
      deep: true,
      async handler() {
        this.$emit('isValid', await this.$refs.form.validate({ silent: true }))
      },
    },
  },
}
</script>
