var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.user)?_c('b-card',[_c('validation-observer',{ref:"loginForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form-group',{attrs:{"label":_vm.$t('userEdit.OldPassword'),"label-for":"oldPassword"}},[_c('validation-provider',{attrs:{"name":_vm.$t('login.Password'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"oldPassword","state":errors.length > 0 ? false:null,"type":"password"},model:{value:(_vm.oldPassword),callback:function ($$v) {_vm.oldPassword=$$v},expression:"oldPassword"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('userEdit.NewPassword'),"label-for":"newPassword"}},[_c('validation-provider',{attrs:{"name":_vm.$t('userEdit.NewPassword'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var dirty = ref.dirty;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"newPassword","state":(errors.length > 0) || (dirty && !_vm.passwordMatch) ? false:null,"type":"password"},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer text-primary",attrs:{"id":"popover-password-requirements","icon":"HelpCircleIcon"}}),_c('b-popover',{ref:"popover-show",attrs:{"target":"popover-password-requirements","triggers":"hover"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('PasswordRequirements.PasswordRequirements'))+" ")]},proxy:true}],null,true)},_vm._l((_vm.passwordRequirements),function(req){return _c('p',{key:req,staticClass:"text-muted"},[_vm._v(" "+_vm._s(_vm.$t(("PasswordRequirements." + req)))+" ")])}),0)],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(!_vm.passwordMatch && dirty)?_c('div',[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t('ThePasswordDoesntMatchRequirements')))])]):_vm._e()]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('RepeatPassword'),"label-for":"newPasswordRepeat"}},[_c('validation-provider',{attrs:{"name":_vm.$t('RepeatPassword'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var dirty = ref.dirty;
return [_c('b-form-input',{attrs:{"id":"newPasswordRepeat","state":errors.length > 0 || _vm.newPassword !== _vm.repeatedPassword ? false:null,"type":"password"},model:{value:(_vm.repeatedPassword),callback:function ($$v) {_vm.repeatedPassword=$$v},expression:"repeatedPassword"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(dirty && _vm.newPassword !== _vm.repeatedPassword)?_c('div',[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t('PasswordsArentTheSame')))])]):_vm._e()]}}],null,true)})],1),_c('b-button',{attrs:{"variant":"primary","size":"sm","disabled":invalid || !_vm.passwordMatch},on:{"click":_vm.onSavePassword}},[_vm._v(" "+_vm._s(_vm.$t('Save'))+" ")])]}}],null,false,3477677896)}),_c('b-overlay',{attrs:{"no-wrap":"","show":_vm.loading,"spinner-variant":"primary"}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }