<template>
  <sw-top-page-bar
    v-model="activeTab"
    is-tab-view
    :tab-view-list="tabList"
  >
    <template v-if="activeTab">
      <div
        v-if="activeTab.title === tabList[0].title"
        :key="0"
      >
        <list />
      </div>

      <div
        v-if="activeTab.title === tabList[1].title"
        :key="1"
      >
        <structure />
      </div>
    </template>
  </sw-top-page-bar>
</template>

<script>
import List from './List.vue'
import Structure from './Structure.vue'

export default {
  name: 'Container',
  components: {
    List,
    Structure,
  },
  data: () => ({
    activeTab: null,
    tabList: [
      {
        title: 'List',
        icon: 'GridIcon',
      },
      {
        title: 'Structure',
        icon: 'HexagonIcon',
      },
    ],
  }),
}
</script>

<style scoped>

</style>
