<template>
  <div>
    <validation-observer
      ref="newProjectForm"
      v-slot="{ invalid }"
      tag="form"
    >
      <!--    START::Toolbar    -->
      <div
        class="d-flex flex-wrap align-items-center justify-content-between mb-1"
        style="column-gap: .5rem; row-gap: .34rem;"
      >
        <div
          class="d-flex flex-wrap align-items-center justify-content-between"
          style="column-gap: .5rem; row-gap: .34rem;"
        >
          <b-button
            variant="warning"
            size="sm"
            :to="{ name: 'newsList' }"
          >
            <feather-icon icon="ArrowLeftIcon" />

            {{ $t('Return') }}
          </b-button>

          <b-button
            :disabled="invalid || !edit.author || !edit.subject || isLoading"
            variant="primary"
            size="sm"
            @click="save(false)"
          >

            {{ $t('Save') }}
          </b-button>

          <b-button
            v-if="checkRequiredPermissions([$roles.NEWS_PO])"
            :disabled="invalid || !edit.id || isLoading"
            variant="danger"
            size="sm"
            @click="remove()"
          >
            <feather-icon icon="TrashIcon" />

            {{ $t('Delete') }}
          </b-button>
        </div>

        <div
          class="d-flex flex-wrap align-items-center justify-content-between"
          style="column-gap: .5rem; row-gap: .34rem;"
        >
          <b-button
            :disabled="invalid || !edit.author || !edit.subject || isLoading"
            variant="primary"
            size="sm"
            @click="openPreview"
          >
            {{ $t('Preview') }}

            <feather-icon icon="EyeIcon" />
          </b-button>

          <b-button
            :disabled="invalid || !edit.author || !edit.subject || isLoading"
            :variant="!edit.id || edit.status !== STATUS.PUBLISHED ? 'primary' : 'danger'"
            size="sm"
            @click="edit.id ? changeStatus(STATUS[edit.status !== STATUS.PUBLISHED ? 'PUBLISHED' : 'SKETCH']) : save(true)"
          >
            {{ $t(edit.id ? edit.status !== STATUS.PUBLISHED ? 'Publish' : 'Withdraw' : 'SaveAndPublish') }}

            <feather-icon :icon="!edit.id || edit.status !== STATUS.PUBLISHED ? 'SendIcon' : 'AlertOctagonIcon'" />
          </b-button>
        </div>
      </div>
      <!--    END::Toolbar    -->

      <!--    START::Top Info    -->
      <b-card>
        <top-info
          :subject="edit.subject ? edit.subject : $t('BlogNew')"
          :introduction="edit.introduction ? edit.introduction : $t('Admission')"
          :author="edit.author ? edit.author : {}"
          :created-at="edit.createdAt"
          :status="edit.status"
          class="px-md-1 px-lg-2 py-lg-1"
        />
      </b-card>
      <!--    END::Top Info    -->

      <!--    START::Options    -->
      <my-section :title="$t('offer.website.Identity')">
        <b-row style="row-gap: .5rem">
          <!--      START:Option -> Creator      -->
          <validation-provider
            class="col-12"
            rules="required"
            :name="$t('Creator')"
          >
            <my-option :title="$t('Creator')">
              <sw-select>
                <v-select
                  v-model="edit.author"
                  :options="userList"
                  label="firstName"
                  :filterable="false"
                  :placeholder="$t('Creator')"
                  class="text-primary"
                  @search="loadUsers"
                >
                  <template #no-options="{ search }">
                    <span v-if="search.length">
                      {{ $t('NoData') }}
                    </span>
                    <span v-else>
                      {{ $t('TypeToSearch') }}
                    </span>
                  </template>

                  <template #option="{ firstName, lastName, avatar }">
                    <div class="d-flex align-items-center">
                      <avatar
                        :user="{ firstName, lastName, avatar }"
                        class="mr-25"
                      />
                    </div>
                  </template>

                  <template #selected-option="{ firstName, lastName, avatar }">
                    <div class="d-flex">
                      <avatar
                        :user="{ firstName, lastName, avatar }"
                        class="mr-25"
                      />
                    </div>
                  </template>
                </v-select>
              </sw-select>
            </my-option>
          </validation-provider>
          <!--      END:Option -> Creator      -->

          <!--      START:Option -> Title      -->
          <validation-provider
            v-slot="{ errors }"
            class="col-12"
            rules="required"
            :name="$t('Title')"
          >
            <my-option
              :title="$t('Title')"
              :tip="$t('MaximumLength') + ': 64'"
            >
              <b-form-group class="w-100">
                <b-form-input
                  v-model="edit.subject"
                  :placeholder="$t('Title')"
                  :state="errors.length > 0 || edit.subject.length > 64 ? false:null"
                  trim
                  maxlength="64"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </my-option>
          </validation-provider>
          <!--      END:Option -> Title      -->

          <!--      START:Option -> Admission      -->
          <validation-provider
            v-slot="{ errors }"
            class="col-12"
            :name="$t('Admission')"
          >
            <my-option
              :title="$t('Admission')"
              :tip="$t('MaximumLength') + ': 80'"
            >
              <b-form-group class="w-100">
                <b-form-textarea
                  v-model="edit.introduction"
                  :placeholder="$t('Admission')"
                  :state="errors.length > 0 || edit.subject.length > 80 ? false:null"
                  trim
                  maxlength="80"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </my-option>
          </validation-provider>
          <!--      END:Option -> Admission      -->

          <!--      START:Option -> MainPhoto      -->
          <validation-provider
            class="col-12"
            rules="required"
            :name="$t('MainPhoto')"
          >
            <my-option
              :title="$t('MainPhoto')"
              :tip="`${$t('PreferredFile')}: (150x300)px/1.25GB`"
              my-class="dragDrop"
            >
              <div>

                <div
                  class="p-relative overflow-hidden d-flex justify-content-center align-items-center rounded-lg"
                  style="height: 380px"
                  :style="!edit.picture || !edit.picture.preview ? 'background: rgba(var(--theme-color-primary), .14);' : ''"
                >
                  <b-img
                    class="img-fluid"
                    :style="
                      edit.picture ? `
                        width: 100%;
                        height: 100%;

                        object-fit: cover;
                      ` : ''
                    "
                    :class="{ 'p-2': !edit.picture }"
                    :src="edit.picture ? !edit.picture.token ? getPictureBlob(edit.picture) : `data:image/webp;base64,${edit.picture.preview}` : system.logo || system.signet"
                  />
                </div>

                <b-button
                  v-b-tooltip.hover.v-primary
                  variant="primary"
                  :title="$t('DragDropFile')"
                >
                  <feather-icon icon="PlusIcon" />
                  <input
                    type="file"
                    @change="onChangePicture($event, 'logo', 1250)"
                  >
                </b-button>
              </div>
            </my-option>
          </validation-provider>
        <!--      END:Option -> MainPhoto      -->
        </b-row>
      </my-section>
      <!--    END::Options    -->

      <!--    START::Content    -->
      <b-card>
        <quill-editor
          v-model="edit.content"
          :options="editorOption"
          class="w-quill"
        />
      </b-card>
      <!--    END::Content    -->

      <!--    START::Toolbar    -->
      <div
        class="d-flex flex-wrap align-items-center mb-2"
        style="column-gap: .5rem; row-gap: .34rem;"
      >
        <b-button
          variant="warning"
          size="sm"
          :to="{ name: 'newsList' }"
        >
          <feather-icon icon="ArrowLeftIcon" />

          {{ $t('Return') }}
        </b-button>

        <b-button
          :disabled="invalid || !edit.author || !edit.subject || isLoading"
          variant="primary"
          size="sm"
          @click="save(false)"
        >

          {{ $t('Save') }}
        </b-button>

        <b-button
          v-if="checkRequiredPermissions([$roles.NEWS_POST_MODIFY])"
          :disabled="invalid || !edit.id || isLoading"
          variant="danger"
          size="sm"
          @click="remove()"
        >
          <feather-icon icon="TrashIcon" />

          {{ $t('Delete') }}
        </b-button>
      </div>
    <!--    END::Toolbar    -->
    </validation-observer>

    <b-overlay
      :show="isLoading"
      no-wrap
      spinner-variant="primary"
    />

    <b-modal
      v-model="showPreview"
      :title="$t('Preview')"
      hide-footer
      tabindex="none"
      size="lg"
    >
      <post
        :preview="edit"
        class="py-2"
      />
    </b-modal>
  </div>
</template>

<script>
import TopInfo from '@/pages/news/components/TopInfo.vue'
import { mapGetters } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { BFormTextarea, VBTooltip } from 'bootstrap-vue'
import myOption from '@/pages/news/components/Option.vue'
import vSelect from 'vue-select'
import { USERS_SEARCH } from '@/@constants/fields'
import mySection from '@/pages/news/components/Section.vue'
import { quillEditor } from 'vue-quill-editor'
import {
  CREATE_NEWS, GET_NEWS, REMOVE_NEWS, UPDATE_NEWS, UPLOAD_FILES,
} from '@/@constants/mutations'
import Post from '@/pages/news/Post.vue'

export default {
  components: {
    Post,
    mySection,
    vSelect,
    myOption,
    TopInfo,
    ValidationObserver,
    ValidationProvider,
    BFormTextarea,
    quillEditor,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },

  data: () => ({
    isLoading: false,

    editorOption: { },

    userList: [],

    edit: {
      id: null,
      subject: '',
      introduction: '',
      content: '',
      author: '',
      picture: null,
      status: 'SKETCH',
      createdAt: '',
    },

    STATUS: {
      SKETCH: 'SKETCH',
      PUBLISHED: 'PUBLISHED',
    },

    showPreview: false,
  }),

  computed: {
    ...mapGetters({
      system: 'system/getSettings',
      currentUser: 'auth/getCurrentUser',
    }),
  },

  watch: {
    $route: {
      immediate: true,
      handler(newRoute, oldRoute) {
        if (!oldRoute || newRoute.params.id !== oldRoute.params.id) this.ini(Number(newRoute.params.id) || null)
      },
    },
  },

  mounted() {
    const id = Number(this.$route.params?.id) || null

    this.ini(id)
  },

  methods: {
    async ini(id) {
      this.isLoading = true

      await this.loadDefault()

      this.edit.id = id

      if (this.edit.id !== null) {
        await this.loadPost(this.edit.id)
      } else {
        this.isLoading = false
      }
    },

    async loadPost(id) {
      this.isLoading = true

      await this.$store.dispatch(`news/${GET_NEWS}`, { id })
        .then(res => this.$set(this, 'edit', res))
        .finally(() => {
          this.isLoading = false
        })
    },

    async loadDefault() {
      this.isLoading = true

      this.$set(this, 'edit', {
        id: null,
        subject: '',
        introduction: '',
        content: '',
        author: '',
        picture: null,
        status: 'SKETCH',
        createdAt: '',
      })

      this.edit.author = this.currentUser

      this.$forceUpdate()

      this.isLoading = false
    },

    async loadUsers(search, isLoading) {
      this.userList = await this.selectSearch(search, isLoading, USERS_SEARCH, 'firstName,lastName', '1/users')
    },

    async save(isPublish) {
      this.isLoading = true

      const payload = this.getPayload()

      if (isPublish) payload.status = this.STATUS.PUBLISHED

      if (payload) {
        if (isPublish) {
          this.showAlert('info', this.$i18n.t('alerts.AreYouSure'))
            .then(() => this.send(payload))
            .finally(() => { this.isLoading = false })
        } else {
          await this.send(payload)
        }
      } else {
        this.isLoading = false
      }
    },

    async changeStatus(status) {
      const { edit } = this

      if (edit.id && status) {
        const payload = {
          id: edit.id,
          status,
        }

        this.showAlert('info', this.$i18n.t('alerts.AreYouSure'))
          .then(() => this.$store.dispatch(`news/${UPDATE_NEWS}`, payload)
            .then(() => {
              this.edit.status = status
            })
            .finally(() => {
              this.isLoading = false
            }))
          .finally(() => { this.isLoading = false })
      }
    },

    async send(item) {
      this.isLoading = true

      const payload = JSON.parse(JSON.stringify(item))

      const type = !payload.id ? CREATE_NEWS : UPDATE_NEWS

      if (item.picture) {
        if (!item.picture.token) {
          const filesPayload = {
            uploadedFiles: [item.picture],
            type: 'building',
          }
          const { files } = await this.$store.dispatch(`uploader/${UPLOAD_FILES}`, filesPayload)

          // eslint-disable-next-line prefer-destructuring
          payload.picture = files[0]
        } else {
          payload.picture = payload.picture.token
        }
      }

      await this.$store.dispatch(`news/${type}`, payload)
        .then(id => {
          if (payload.id) {
            this.showToast('success', this.$i18n.t('ChangesSaved'))
          } else {
            this.showToast('success', this.$i18n.t('success.postSuccessfullyCreated'))

            this.$router.push(`/news/edit/${id}`)
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    async remove() {
      this.isLoading = true

      const { edit } = this

      if (edit.id) {
        this.showAlert('error', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('alerts.WontbeAble'))
          .then(() => this.removePost(edit.id))
          .finally(() => { this.isLoading = false })
      } else {
        this.isLoading = false
      }
    },

    async removePost(id) {
      this.isLoading = true

      await this.$store.dispatch(`news/${REMOVE_NEWS}`, id)
        .then(() => this.$router.push({ name: 'newsList' }))
        .finally(() => {
          this.isLoading = false
        })
    },

    getPayload() {
      const { edit } = this

      const payload = JSON.parse(JSON.stringify(edit))

      payload.picture = edit.picture
      payload.author = edit.author.id

      if (!payload.id) delete payload.id

      if (payload.id) {
        delete payload.createdAt
        delete payload.createdBy
      }

      return payload
    },

    onChangePicture(event, option, maxSize) {
      if (event.target.files[0].size > maxSize * 1000) {
        this.showToast('danger', this.$i18n.tc('MoreThanKB', 1, {
          type: this.$i18n.t(`SystemMoreThan.${option}`),
          size: maxSize,
        }))
        return
      }

      const [first] = event.target.files

      this.edit.picture = first
    },

    getPictureBlob(picture) {
      return URL.createObjectURL(picture)
    },

    async openPreview() {
      const { edit } = this

      if (edit.author || edit.subject) this.showPreview = true
    },
  },
}
</script>

<style>
.w-quill .ql-container {
  height: 80vh;
}
</style>
