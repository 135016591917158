<template>
  <target-view
    :key="`new-task-${project}`"
    :target-id="id"
    :project-id="project"
    :is-return-to-project="isReturnToProject"
    is-task
  />
</template>

<script>
import TargetView from '@/pages/projects/components/new/View.vue'

export default {
  components: {
    TargetView,
  },
  computed: {
    id() { return Number(this.$route.params?.id || null) },
    project() { return Number(this.$route.params?.projectId || null) },
    isReturnToProject() { return this.$route.params?.returnToProject || false },
  },
}
</script>
