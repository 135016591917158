import { render, staticRenderFns } from "./ContactGeneratorList.vue?vue&type=template&id=8182776c&scoped=true"
import script from "./ContactGeneratorList.vue?vue&type=script&lang=js"
export * from "./ContactGeneratorList.vue?vue&type=script&lang=js"
import style0 from "./ContactGeneratorList.vue?vue&type=style&index=0&id=8182776c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8182776c",
  null
  
)

export default component.exports