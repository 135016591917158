import { render, staticRenderFns } from "./TimelineStats.vue?vue&type=template&id=5ab4ef34&scoped=true"
import script from "./TimelineStats.vue?vue&type=script&lang=js"
export * from "./TimelineStats.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ab4ef34",
  null
  
)

export default component.exports