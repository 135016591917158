<template>
  <b-row
    class="d-flex flex-row-reverse"
    :class="{ 'justify-content-center': !allowAgreementInCart }"
  >
    <!--    Views: Order    -->
    <b-col
      :sm="allowAgreementInCart ? '12' : '12'"
      :md="allowAgreementInCart ? '12' : '12'"
      :lg="allowAgreementInCart ? '4' : '5'"
      class="pl-lg-25 pl-md-1 mb-1 mb-lg-0 position-relative"
    >
      <!-- -->
      <checkout-summary />
      <!--    Order Content    -->
      <div
        class="d-flex"
        :class="{ 'justify-content-end': !allowAgreementInCart }"
      >
        <!--    Order End Button    -->
        <b-button
          variant="primary"
          class="mb-50 mr-50"
          :class="{ 'flex-grow-1': allowAgreementInCart }"
          :disabled="loading"
          @click="save"
        >
          {{ allowOffer ? (cart.type === 'OFFER' ? $t('Offer.CreateAnOffer') : $t('Offer.FinishYourOrder')) : $t('CreateContracts') }}
        </b-button>
        <!--    Order Cancel Button    -->
        <b-button
          variant="danger"
          class="mb-50"
          :to="{ name: 'shop' }"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>
    </b-col>

    <!--    Views: Products    -->
    <b-col
      sm="12"
      md="12"
      lg="8"
      class="position-relative"
    >
      <slot name="overlay" />

      <!--    Products Content    -->
      <div class="shopCart d-flex flex-column">
        <!--    Cart Items    -->
        <div v-if="allowContract && allowAgreementInCart">
          <div v-if="allowSystemAgreementInCart">
            <b-form-checkbox
              v-model="systemAgreementRecord"
              variant="primary"
              class="mb-50"
            >
              {{ $t('AddSystemAgreementRecord') }}
            </b-form-checkbox>

            <validation-observer
              ref="validator"
              class="position-relative"
            >
              <div
                v-for="(agreement, index) in cart.cartAgreements"
                :key="`agreement_${agreement.id}`"
              >
                <section-agreement-item
                  v-if="index === 0"
                  :ref="`agreement-item-${agreement.id}`"
                  is-cart
                  :disabled="!systemAgreementRecord"
                  :agreement="agreement"
                  :agreement-index="index"
                  :agreement-types="agreementTypes.filter(e => e.agreementTemplates && e.agreementTemplates.length)"
                  :autenti-enabled="autentiEnable"
                  @remove="removeAgreement($event)"
                />
              </div>

              <b-overlay
                no-wrap
                :show="!systemAgreementRecord && allowAgreement"
                variant="transparent"
                :opacity="0.95"
                :blur="'10px'"
                rounded="sm"
              >
                <template #overlay>
                  <div class="d-flex align-items-center flex-column">
                    <h4>{{ $t('UnavailableContent') }}</h4>
                  </div>
                </template>
              </b-overlay>
            </validation-observer>
          </div>

          <!--- System Record -->
          <b-form-checkbox
            v-model="fileAgreementRecord"
            variant="primary"
            class="mb-1"
          >
            <p class="h5 mb-0">
              {{ $t('AddAgreementFile') }}
            </p>
          </b-form-checkbox>

          <b-card class="position-relative">
            <sw-select :name="$t('Contracts.AgreementType')">
              <v-select
                v-model="agreementSignManually.agreementType"
                :options="agreementTypes"
                :reduce="item => item.id"
                label="name"
              />
            </sw-select>
            <sw-select :name="$t('Contracts.DateOfSigningContract')">
              <sw-select-fpr>
                <flat-pickr
                  ref="timeRange"
                  v-model="agreementSignManually.signedAt"
                  :disabled="!fileAgreementRecord"
                  class="form-control"
                  :config="{ dateFormat: 'd-m-Y', locale: getCalendarLocale($i18n.locale) }"
                />
              </sw-select-fpr>
            </sw-select>

            <drag-drop-upload
              tname="agreement"
              :disabled="!fileAgreementRecord"
              @onChangeFiles="(e) => { agreementSignManually.filesSigned = e }"
            />

            <b-overlay
              no-wrap
              :show="!fileAgreementRecord && allowAgreement"
              variant="transparent"
              :opacity="0.95"
              :blur="'10px'"
              rounded="sm"
            >
              <template #overlay>
                <div class="d-flex align-items-center flex-column">
                  <h4>{{ $t('UnavailableContent') }}</h4>
                </div>
              </template>
            </b-overlay>
          </b-card>
          <!--          <new-contract-modal-->
          <!--              :key="contactKey"-->
          <!--              ref="contract-modal"-->
          <!--              :total-gross="totalGross"-->
          <!--              :payment-schedule="cartProducts[0].shopProduct.shopCategories[1].paymentSchedule || null"-->
          <!--              :thread="cart.contactThread"-->
          <!--              :cart="cart.id"-->
          <!--              hide-footer-->
          <!--              tag="b-card"-->
          <!--              :disabled="allowAgreementInCart"-->
          <!--          />-->
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
/* eslint-disable */
import { mapGetters } from 'vuex'
import axiosIns from '@/libs/axios'
import SectionAgreementItem from '@/views/offers/checkout/SectionAgreementItem.vue'
import {
  EDIT_CONTACT, GET_PRIVATE_SETTINGS, GET_USER_CART, UPDATE_CART, UPLOAD_FILES,
} from '@/@constants/mutations'
import { ValidationObserver } from 'vee-validate'
import CheckoutSummary from '@/views/offers/checkout/CheckoutSummary.vue'
import flatPickr from 'vue-flatpickr-component'
import DragDropUpload from '@/views/components/DragDropUpload.vue'
import { grossPrice } from '@/helpers/cartProductPrices'
import vSelect from 'vue-select'

export default {
  name: 'SectionAgreementNew',
  components: {
    vSelect,
    SectionAgreementItem,
    ValidationObserver,
    CheckoutSummary,
    flatPickr,
    DragDropUpload,
  },
  data: () => ({
    flag: 1,
    loading: false,
    autentiEnable: false,
    agreementSignManually: {
      agreementTypes: '',
      signedAt: '',
      filesSigned: [],
    },
    fileAgreementRecord: false,
    systemAgreementRecord: true,

    agreementTypes: [],
  }),
  computed: {
    ...mapGetters({
      cart: 'cart/getCart',
      cartProducts: 'cart/getCartProducts',
    }),
    allowContract() {
      return (this.allowOffer && this.cart?.contactThread?.id && this.cartProducts.length && this.cartProducts[0]?.shopProduct?.shopCategories) || !this.allowOffer
    },
    totalProductsGrossPriceAfterDiscount() {
      return this.cartProducts.length ? this.cartProducts.reduce((a, b) => +a + (this.grossPrice(b, true)), 0) : 0
    },
  },
  props: {
    totalGross: Number,
    allowAgreementInCart: {
      type: Boolean,
      default: true,
    },
    allowSystemAgreementInCart: {
      type: Boolean,
      default: false,
    },
    allowAgreement: {
      default: true,
      type: Boolean,
    },
    allowOffer: {
      default: true,
      type: Boolean,
    },
  },
  async mounted() {
    await this.loadSettings()
    await this.loadAgreementTypes()

    if (this.allowSystemAgreementInCart) {
      this.$nextTick(async () => {
        if (this.cart.cartAgreements?.length === 0 && this.cart.id) {
          await this.addAgreement()
        }
      })
    } else {
      this.fileAgreementRecord = true
    }

    if (this.checkRequiredHostnameFragments(['hydro'])) {
        this.fileAgreementRecord = true
    }
  },
  methods: {
    grossPrice,
    async loadSettings() {
      try {
        const resp = await this.$store.dispatch(`system/${GET_PRIVATE_SETTINGS}`)
        this.autentiEnable = resp?.data?.item?.autentiEnabled || false
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    /* eslint-disable */
    async loadAgreementTypes() {
      try {
        const resp = await axiosIns.get('1/settings/agreementTypes', { params: { fields_load: this.$fields.SETTINGS_AGREEMENTS_TYPES } })
        this.agreementTypes = resp.data.data?.items || []
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    async addAgreement() {
      const emptyAgreement = {
          agreementTemplate: '',
          allowElectronicSignature: false,
          electronicSignatureType: null,
          anonymised: false,
          signerUser: '',
          contactThreadCartDraftAgreementAssignedContacts: [],
      }

      try {
        if (this.$store.state.cart.cartAgreements.length === 0) {
          const resp = await axiosIns.post('1/contacts/threads/cartDrafts/me/agreements', emptyAgreement)

          if (resp?.data?.data?.items?.length) {
            this.cart.cartAgreements.push({
              ...emptyAgreement,
              id: resp.data.data.items[0].id
            })
          }
        }
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    async removeAgreement(agreementId, showToast = true) {
      const agreement = this.cart.cartAgreements.find(agreement => agreement.id === agreementId)

      if (agreement?.id) {
        try {
          if (this.$store.state.cart.name) {
            await axiosIns.delete('1/contacts/threads/cartDrafts/me/agreements', { data: JSON.stringify([agreement?.id]) })

            if (showToast) this.showToast('success')
            return true
          }
        } catch (err) {
          if (showToast) this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          return false
        }
      }
    },
    async save() {
      if (this.allowSystemAgreementInCart) {
          const a = await this.validateItems()
          if (a.includes(false)) return
      }

      this.loading = true
      //  Save Payments
      if (this.cart.files && this.cart.files.length) {
        const payload = { uploadedFiles: this.cart.files.filter(file => !file?.token), type: 'building' }
        const { files } = await this.$store.dispatch(`uploader/${UPLOAD_FILES}`, payload)

        this.$set(this.cart, 'files', [
          ...files,
          ...this.cart.files.filter(file => file?.token).map(file => file.token),
        ])
      }

        if (this.cart.presentationFiles && this.cart.presentationFiles.length) {
            const payload = { uploadedFiles: this.cart.presentationFiles.filter(file => !file?.token), type: 'building' }
            const { files } = await this.$store.dispatch(`uploader/${UPLOAD_FILES}`, payload)

            this.$set(this.cart, 'presentationFiles', [
                ...files,
                ...this.cart.presentationFiles.filter(file => file?.token).map(file => file.token),
            ])
        }

      if (this.checkRequiredModule('paymentModule')) {
        try {
          const requests = []
          let restPayment = null

          const payments = this.cart.cartPayments.filter(payment => {
            if (payment.type === 'TOTAL_PAYMENT') {
              restPayment = payment
              return false
            }
            if (payment.type === 'ADVANCE' && !payment?.isUsed) {
              return false
            }

            return payment
          }).map(payment => {
              if (this.$store.state.cart.paymentType === 'LOYALTY_POINTS') {
                  return { ...payment, type: 'LOYALTY_POINTS' }
              }

              return payment
          })
          const toUpdate = payments.filter(payment => payment.id).map(p => ({ ...p, value: parseFloat(p?.value || 0) }))
          const toCreate = payments.filter(payment => !payment.id && payment.date).map(p => ({ ...p, value: parseFloat(p?.value || 0) }))

          let totalScheduleItemsPrice = 0
          toCreate.forEach(payment => { totalScheduleItemsPrice += payment.value })
          toUpdate.forEach(payment => { totalScheduleItemsPrice += payment.value })

          const restToPaid = parseFloat((this.totalProductsGrossPriceAfterDiscount - totalScheduleItemsPrice).toFixed(2))
          if (restPayment) {
            toCreate.push({
              ...restPayment,
              value: restToPaid
            })
          }

          if (toUpdate.length && this.$store.state.cart.paymentType !== 'LOYALTY_POINTS') requests.push(axiosIns.patch('1/contacts/threads/cartDrafts/me/paymentScheduleItems', toUpdate))
          if (toCreate.length && this.$store.state.cart.paymentType !== 'LOYALTY_POINTS') requests.push(axiosIns.post('1/contacts/threads/cartDrafts/me/paymentScheduleItems', toCreate))

          const resp = await Promise.all(requests)
        } catch (err) {
          this.loading = false
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        }
      }

      // Addresses
      if (this.cart.deliveryAddressType === 'OTHER_ADDRESS') {
        const response = await axiosIns.post('1/addresses', this.cart.otherAddress)

        if (response?.data?.data?.items.length) {
          const id = response?.data?.data?.items[0].id
          this.cart.deliveryAddress = id
          const ids = (this.cart.contactThread.contact?.deliveryAddresses?.map(i => this.getObjectId(i)).filter(Boolean) || [])
          ids.push(id)

          const contactPayload = {
            id: this.cart.contactThread.contact.id,
            deliveryAddresses: ids,
          }
          await this.$store.dispatch(`contacts/${EDIT_CONTACT}`, contactPayload)
        }
      }

      if (this.cart.cartAgreements?.length > 1 && this.cart.cartAgreements[1] && this.cart.cartAgreements[1]?.id) {
        await this.removeAgreement(this.cart.cartAgreements[1].id)
      }

      if (!this.systemAgreementRecord) {
        const idsToDelete = this.cart.cartAgreements.map(i => i?.id).filter(Boolean)
        await axiosIns.delete('1/contacts/threads/cartDrafts/me/agreements', { data: JSON.stringify(idsToDelete) })
      }

      if (this.fileAgreementRecord && this.agreementSignManually.signedAt && this.agreementSignManually.filesSigned?.length) {
        try {
            const filesPayload = { uploadedFiles: this.agreementSignManually.filesSigned, type: 'building' }
            const { files } = await this.$store.dispatch(`uploader/${UPLOAD_FILES}`, filesPayload)

            const payload = { ...this.agreementSignManually, filesSigned: files, status: 'SIGNED_MANUALLY', agreementTemplate: this.agreementSignManually.agreementType || null }

            await axiosIns.post('1/contacts/threads/cartDrafts/me/agreements', payload)
        } catch (err) {}
      }
      const cartCp = JSON.parse(JSON.stringify(this.cart))
      delete cartCp.cartAgreements
      delete cartCp.cartPayments
      await this.$store.dispatch(`cart/${UPDATE_CART}`, { cart: { ...this.cart, orderNumber: Boolean(this.cart.orderNumber) ? this.cart.orderNumber : null }, updateFiles: true })

      try {
        const finalizeCart = await axiosIns.post('1/contacts/threads/cartDrafts/me/doFinalize')
        let path = ''
        if (this.checkRequiredModule('offerModule') && this.cart.type === 'OFFER') path = `/contact/${this.cart.contactThread.contact.id}/thread/${this.cart.contactThread.id}/offers`
        else if (this.checkRequiredModule('orderModule') && this.cart.type !== 'OFFER') path = `/contact/${this.cart.contactThread.contact.id}/thread/${this.cart.contactThread.id}/orders`
        else if (this.checkRequiredModule('paymentModule')) path = `/contact/${this.cart.contactThread.contact.id}/thread/${this.cart.contactThread.id}/payments`
        else path = `/contact/${this.cart.contactThread.contact.id}/thread/${this.cart.contactThread.id}/timeline`

        if (this.checkRequiredModule('offerModule')) {
          const cart = await this.$store.dispatch(`cart/${GET_USER_CART}`)
        }
        await this.$router.push(path)

        this.showToast('success', this.$i18n.t('success.contactUpdated'))
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.loading = false
      }
    },
    async validateItems() {
      if (!this.allowAgreement) return [true]
      if (!this.systemAgreementRecord) return [true]
      const items = []
      // eslint-disable-next-line
      for (const agreement of [this.cart.cartAgreements[0]]) {
        const ref = `agreement-item-${agreement.id}`
        // eslint-disable-next-line no-await-in-loop
        const response = await this.$refs[ref][0].save()

        if (response) {
          items.push(response)
        }
      }

      if (items.length) {
        return items
      }

      this.showToast('error', this.$i18n.t('SomeFieldsAreNotFilled'))
      return [false]
    },
  },
}
</script>

<style scoped>

</style>
