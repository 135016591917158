<!--eslint-disable-->

<template>
  <div>
    <h4 class="mb-1">
      {{ $t('TermsOfCalculatingUserCommission') }}
    </h4>

    <!--    START::Top    -->
    <!--    <b-row-->
    <!--      style="row-gap: 1rem"-->
    <!--      class="mb-2"-->
    <!--    >-->
    <!--    START::Commission accrual time    -->
    <!--      <b-col lg="4">-->
    <!--        <validation-provider-->
    <!--          #default="{ errors }"-->
    <!--          :name="$t('CommissionAccrualTime')"-->
    <!--        >-->
    <!--          <sw-select :name="$t('CommissionAccrualTime')">-->
    <!--            <v-select-->
    <!--              v-model="target.commissionAccrualTime"-->
    <!--              :options="commissionAccrualTimes"-->
    <!--              :reduce="i => i.value"-->
    <!--              :create-option="num => Math.abs(Number(num) || 0)"-->
    <!--            >-->
    <!--              <template #no-options>-->
    <!--                {{ $t('NoOptions') }}-->
    <!--              </template>-->

    <!--              <template #option="{ label }">-->
    <!--                {{ $t(label) }}-->
    <!--              </template>-->

    <!--              <template #selected-option="{ label }">-->
    <!--                {{ $t(label) }}-->
    <!--              </template>-->
    <!--            </v-select>-->
    <!--          </sw-select>-->
    <!--          <small class="text-danger">{{ errors[0] }}</small>-->
    <!--        </validation-provider>-->
    <!--      </b-col>-->
    <!--    END::Commission accrual time    -->
    <!--    </b-row>-->
    <!--    END::Top    -->

    <!--    START::Bottom    -->
    <h6>{{ $t('SpecifyConditionsForGrantingCommission') }}</h6>

    <b-row
      style="row-gap: 1rem"
      class="pt-50"
    >
      <!--    START::Minimum net value of own sales    -->
      <!--      <b-col md="6">-->
      <!--        <validation-provider-->
      <!--          #default="{ errors }"-->
      <!--          :name="$t('MinimumNetValueOfOwnSales')"-->
      <!--        >-->
      <!--          <label class="mb-50">-->
      <!--            <b-form-checkbox-->
      <!--              v-model="target.enableMinimumNetValue"-->
      <!--              variant="primary"-->
      <!--              switch-->
      <!--            >-->
      <!--              {{ $t('MinimumNetValueOfOwnSales') }}-->
      <!--            </b-form-checkbox>-->
      <!--          </label>-->
      <!--          <cleave-->
      <!--            v-model="target.minimumNetValue"-->
      <!--            :options="cleaveOptions.number"-->
      <!--            :state="errors.length > 0 ? false:null"-->
      <!--            class="form-control"-->
      <!--            :disabled="!target.enableMinimumNetValue"-->
      <!--          />-->
      <!--          <small class="text-danger">{{ errors[0] }}</small>-->
      <!--        </validation-provider>-->
      <!--      </b-col>-->
      <!--    END::Minimum net value of own sales    -->

      <!--    START::Minimum amount of own "selling" products    -->
      <b-col md="6">
        <validation-provider
          v-slot="{ errors }"
          :name="$t('MinimumAmountOfOwnProducts')"
        >
          <label class="mb-50">
            <b-form-checkbox
              v-model="target.enableMinimumProductSoldAmount"
              variant="primary"
              switch
            >
              {{ $t('MinimumAmountOfOwnProducts') }}
            </b-form-checkbox>
          </label>

          <cleave
            v-model="target.minimumProductsSoldAmount"
            :options="cleaveOptions.number"
            :state="errors.length > 0 ? false:null"
            class="form-control"
            :disabled="!target.enableMinimumProductSoldAmount"
          />

          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col>
      <!--    END::Minimum amount of own "selling" products    -->
    </b-row>
    <!--    END::Bottom    -->

    <b-overlay
      :show="isLoading"
      no-wrap
      spinner-variant="prFimary"
    />
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
// import vSelect from 'vue-select'
import { VBTooltip } from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'

export default {
  components: {
    ValidationProvider,
    // vSelect,
    Cleave,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    target: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    cleaveOptions: {
      number: {
        delimiter: ' ',
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
        numeralPositiveOnly: true,
      },
    },
    commissionAccrualTimes: [
      { value: 'MONTH', label: 'OnceAMonth' },
      { value: 'WEEK', label: 'OnceAWeek' },
      { value: 'DAY', label: 'OnceADay' },
    ],
  }),
}
</script>
