export const SHOW_CONTACTS_ASSIGNED = 'SHOW_CONTACTS_ASSIGNED'
export const SHOW_CONTACTS_DELAYED = 'SHOW_CONTACTS_DELAYED'
export const SHOW_CONTACTS_STATUSES = 'SHOW_CONTACTS_STATUSES'

export const SHOW_SYSTEM_MENU_RANKING = 'SHOW_SYSTEM_MENU_RANKING'
export const SHOW_USERS_NOTE = 'SHOW_USERS_NOTE'
export const SHOW_THREADS_ASSIGNED = 'SHOW_THREADS_ASSIGNED'
export const SHOW_THREADS_DELAYED = 'SHOW_THREADS_DELAYED'
export const SHOW_THREADS_STATUSES = 'SHOW_THREADS_STATUSES'
export const SHOW_OFFERS_SENT = 'SHOW_OFFERS_SENT'
export const SHOW_OFFERS_ACCEPTED = 'SHOW_OFFERS_ACCEPTED'
export const SHOW_OFFERS_MESSAGES = 'SHOW_OFFERS_MESSAGES'
export const SHOW_AGREEMENTS_SENT = 'SHOW_AGREEMENTS_SENT'
export const SHOW_AGREEMENTS_SIGNED = 'SHOW_AGREEMENTS_SIGNED'
export const SHOW_TASKS_DELAYED = 'SHOW_TASKS_DELAYED'
export const SHOW_TASKS_TODAY = 'SHOW_TASKS_TODAY'
export const SHOW_TASKS_IN_THREADS = 'SHOW_TASKS_IN_THREADS'
export const SHOW_CONTACT_FORM_POSITION = 'SHOW_CONTACT_FORM_POSITION'
export const SHOW_CONTACT_FORM_TAG = 'SHOW_CONTACT_FORM_TAG'
export const SHOW_CONTACT_FORM_GROUP = 'SHOW_CONTACT_FORM_GROUP'
export const SHOW_CONTACT_FORM_ASSIGNED_USERS = 'SHOW_CONTACT_FORM_ASSIGNED_USERS'
export const SHOW_CONTACT_FORM_DELIVERY_ADDRESSES = 'SHOW_CONTACT_FORM_DELIVERY_ADDRESSES'
export const SHOW_CONTACT_FORM_PERSONAL_ID = 'SHOW_CONTACT_FORM_PERSONAL_ID'
export const SHOW_CONTACT_FORM_BIRTH_DATE = 'SHOW_CONTACT_FORM_BIRTH_DATE'
export const SHOW_CONTACT_FORM_FAMILY_NAME = 'SHOW_CONTACT_FORM_FAMILY_NAME'
export const SHOW_CONTACT_FORM_MATERIAL_STATUS = 'SHOW_CONTACT_FORM_MATERIAL_STATUS'
export const SHOW_CONTACT_FORM_FINANCIAL_POSITION = 'SHOW_CONTACT_FORM_FINANCIAL_POSITION'
export const SHOW_CONTACT_FORM_PERSONAL_DOCUMENTS = 'SHOW_CONTACT_FORM_PERSONAL_DOCUMENTS'
export const SHOW_CONTACT_FORM_LANGUAGE = 'SHOW_CONTACT_FORM_LANGUAGE'
export const SHOW_CONTACT_FORM_NOTE = 'SHOW_CONTACT_FORM_NOTE'

export const SHOW_CONTACT_CARD_THREAD_ACTIONS = 'SHOW_CONTACT_CARD_THREAD_ACTIONS'
export const SHOW_CONTACT_CARD_THREAD_FUNNEL_PROGRESS = 'SHOW_CONTACT_CARD_THREAD_FUNNEL_PROGRESS'
export const SHOW_CONTACT_CARD_CHECKLISTS = 'SHOW_CONTACT_CARD_CHECKLISTS'
export const SHOW_CONTACT_CARD_TASKS = 'SHOW_CONTACT_CARD_TASKS'
export const SHOW_CONTACT_CARD_DOCUMENTS = 'SHOW_CONTACT_CARD_DOCUMENTS'
export const SHOW_CONTACT_CARD_POLLS = 'SHOW_CONTACT_CARD_POLLS'
export const SHOW_CONTACT_CARD_OFFERS = 'SHOW_CONTACT_CARD_OFFERS'
export const SHOW_CONTACT_CARD_EMAILS = 'SHOW_CONTACT_CARD_EMAILS'
export const SHOW_CONTACT_CARD_BASIC_INFORMATION = 'SHOW_CONTACT_CARD_BASIC_INFORMATION'
export const SHOW_CONTACT_CARD_APPLICATIONS = 'SHOW_CONTACT_CARD_APPLICATIONS'
export const SHOW_CONTACT_CARD_ADDITIONAL_FIELDS = 'SHOW_CONTACT_CARD_ADDITIONAL_FIELDS'
export const SHOW_CONTACT_CARD_PREDICTED_VALUE = 'SHOW_CONTACT_CARD_PREDICTED_VALUE'
export const SHOW_CONTACT_CARD_SMS = 'SHOW_CONTACT_CARD_SMS'
export const SHOW_CONTACT_CARD_NOTES = 'SHOW_CONTACT_CARD_NOTES'
export const SHOW_CONTACT_CARD_PROJECTS = 'SHOW_CONTACT_CARD_PROJECTS'
export const SHOW_CONTACT_CARD_PAYMENTS = 'SHOW_CONTACT_CARD_PAYMENTS'
export const SHOW_CONTACT_CARD_ORDERS = 'SHOW_CONTACT_CARD_ORDERS'
export const SHOW_CONTACT_CARD_LOYALTY_POINTS = 'SHOW_CONTACT_CARD_LOYALTY_POINTS'
export const SHOW_CONTACT_CARD_AGREEMENTS = 'SHOW_CONTACT_CARD_AGREEMENTS'
export const SHOW_CONTACT_CARD_AUTOMATIONS = 'SHOW_CONTACT_CARD_AUTOMATIONS'

export const SHOW_SYSTEM_MENU_DASHBOARD = 'SHOW_SYSTEM_MENU_DASHBOARD'
export const SHOW_SYSTEM_MENU_APPLICATIONS = 'SHOW_SYSTEM_MENU_APPLICATIONS'
export const SHOW_SYSTEM_MENU_TASKS = 'SHOW_SYSTEM_MENU_TASKS'
export const SHOW_SYSTEM_MENU_CALENDAR = 'SHOW_SYSTEM_MENU_CALENDAR'
export const SHOW_SYSTEM_MENU_CONTACTS = 'SHOW_SYSTEM_MENU_CONTACTS'
export const SHOW_SYSTEM_MENU_ORGANIZATIONS = 'SHOW_SYSTEM_MENU_ORGANIZATIONS'
export const SHOW_SYSTEM_MENU_NEW_CONTACT = 'SHOW_SYSTEM_MENU_NEW_CONTACT'
export const SHOW_SYSTEM_MENU_FUNNELS = 'SHOW_SYSTEM_MENU_FUNNELS'
export const SHOW_SYSTEM_MENU_OFFERS = 'SHOW_SYSTEM_MENU_OFFERS'
export const SHOW_SYSTEM_MENU_ORDERS = 'SHOW_SYSTEM_MENU_ORDERS'
export const SHOW_SYSTEM_MENU_AGREEMENTS = 'SHOW_SYSTEM_MENU_AGREEMENTS'
export const SHOW_SYSTEM_MENU_PAYMENTS = 'SHOW_SYSTEM_MENU_PAYMENTS'
export const SHOW_SYSTEM_MENU_TIMELINE = 'SHOW_SYSTEM_MENU_TIMELINE'
export const SHOW_SYSTEM_MENU_MARKETING_PLANS = 'SHOW_SYSTEM_MENU_MARKETING_PLANS'
export const SHOW_SYSTEM_MENU_LOYALTY_PLANS = 'SHOW_SYSTEM_MENU_LOYALTY_PLANS'
export const SHOW_SYSTEM_MENU_SHOP = 'SHOW_SYSTEM_MENU_SHOP'
export const SHOW_SYSTEM_MENU_PROJECTS = 'SHOW_SYSTEM_MENU_PROJECTS'
export const SHOW_SYSTEM_MENU_CHAT = 'SHOW_SYSTEM_MENU_CHAT'
export const SHOW_SYSTEM_MENU_MATERIALS = 'SHOW_SYSTEM_MENU_MATERIALS'
export const SHOW_SYSTEM_MENU_USERS = 'SHOW_SYSTEM_MENU_USERS'
export const SHOW_SYSTEM_MENU_SETTINGS = 'SHOW_SYSTEM_MENU_SETTINGS'

export const SHOW_NOTIFICATION_SYSTEM = 'SHOW_NOTIFICATION_SYSTEM'
export const SHOW_NOTIFICATION_CONTACT = 'SHOW_NOTIFICATION_CONTACT'
export const SHOW_NOTIFICATION_THREAD = 'SHOW_NOTIFICATION_THREAD'
export const SHOW_NOTIFICATION_EMAIL = 'SHOW_NOTIFICATION_EMAIL'
export const SHOW_NOTIFICATION_SMS = 'SHOW_NOTIFICATION_SMS'
export const SHOW_NOTIFICATION_TASK = 'SHOW_NOTIFICATION_TASK'
export const SHOW_NOTIFICATION_NOTE = 'SHOW_NOTIFICATION_NOTE'
export const SHOW_NOTIFICATION_CHECKLIST = 'SHOW_NOTIFICATION_CHECKLIST'
export const SHOW_NOTIFICATION_OFFER = 'SHOW_NOTIFICATION_OFFER'
export const SHOW_NOTIFICATION_ORDER = 'SHOW_NOTIFICATION_ORDER'
export const SHOW_NOTIFICATION_PAYMENT = 'SHOW_NOTIFICATION_PAYMENT'
export const SHOW_NOTIFICATION_AGREEMENT = 'SHOW_NOTIFICATION_AGREEMENT'
export const SHOW_NOTIFICATION_AUTOMATION = 'SHOW_NOTIFICATION_AUTOMATION'

export const SHOW_CART_PRODUCT_VAT = 'SHOW_CART_PRODUCT_VAT'
export const SHOW_CART_PRODUCT_DISCOUNT = 'SHOW_CART_PRODUCT_DISCOUNT'
export const SHOW_CONTACT_TAGS = 'SHOW_CONTACT_TAGS'
export const SHOW_ORDER_EDIT = 'SHOW_ORDER_EDIT'
export const SHOW_CONTACT_FORM_ADDRESS_COUNTRY = 'SHOW_CONTACT_FORM_ADDRESS_COUNTRY'
export const SHOW_CONTACT_FORM_ADDRESS_CITY = 'SHOW_CONTACT_FORM_ADDRESS_CITY'
export const SHOW_CONTACT_FORM_ADDRESS_STREET = 'SHOW_CONTACT_FORM_ADDRESS_STREET'
export const SHOW_CONTACT_FORM_ADDRESS_POSTAL_CODE = 'SHOW_CONTACT_FORM_ADDRESS_POSTAL_CODE'
export const SHOW_CONTACT_FORM_ADDRESS_NUMBER = 'SHOW_CONTACT_FORM_ADDRESS_NUMBER'
export const SHOW_UPPER_HEADER_CONTACT_ADD = 'SHOW_UPPER_HEADER_CONTACT_ADD'
export const SHOW_UPPER_HEADER_CONTACT_NOTE = 'SHOW_UPPER_HEADER_CONTACT_NOTE'
export const SHOW_UPPER_HEADER_CONTACT_TASK = 'SHOW_UPPER_HEADER_CONTACT_TASK'
export const SHOW_UPPER_HEADER_CONTACT_EMAIL = 'SHOW_UPPER_HEADER_CONTACT_EMAIL'
export const SHOW_UPPER_HEADER_CONTACT_SMS = 'SHOW_UPPER_HEADER_CONTACT_SMS'

export const SHOW_CONTACT_END_STATUS = 'SHOW_CONTACT_END_STATUS'
export const SHOW_CONTACT_STATUS_LIST = 'SHOW_CONTACT_STATUS_LIST'
export const SHOW_CONTACT_STATUS_EDIT = 'SHOW_CONTACT_STATUS_EDIT'

export const SHOW_CART_ADDITIONAL_FILES = 'SHOW_CART_ADDITIONAL_FILES'
export const SHOW_LOYALTY_PLANS = 'SHOW_LOYALTY_PLANS'
export const SHOW_MARKETING_PLANS = 'SHOW_MARKETING_PLANS'

export const SHOW_MARKETING_POINTS_EXPORT = 'SHOW_MARKETING_POINTS_EXPORT'
export const SHOW_MARKETING_POINT_PAYMENTS_EXPORT = 'SHOW_MARKETING_POINT_PAYMENTS_EXPORT'
export const SHOW_ORDERS_EXPORT = 'SHOW_ORDERS_EXPORT'
// Hydropure 25.02
export const SHOW_TABLE_ORDER_COLUMN_NUMBER = 'SHOW_TABLE_ORDER_COLUMN_NUMBER'
export const SHOW_TABLE_ORDER_COLUMN_ORDER_DATE = 'SHOW_TABLE_ORDER_COLUMN_ORDER_DATE'
export const SHOW_TABLE_ORDER_COLUMN_CONTACT = 'SHOW_TABLE_ORDER_COLUMN_CONTACT'
export const SHOW_TABLE_ORDER_COLUMN_THREAD = 'SHOW_TABLE_ORDER_COLUMN_THREAD'
export const SHOW_TABLE_ORDER_COLUMN_PRODUCTS = 'SHOW_TABLE_ORDER_COLUMN_PRODUCTS'
export const SHOW_TABLE_ORDER_COLUMN_PRICE_GROSS = 'SHOW_TABLE_ORDER_COLUMN_PRICE_GROSS'
export const SHOW_TABLE_ORDER_COLUMN_TOTAL_SELLER_DISCOUNT = 'SHOW_TABLE_ORDER_COLUMN_TOTAL_SELLER_DISCOUNT'
export const SHOW_TABLE_ORDER_COLUMN_STATUS = 'SHOW_TABLE_ORDER_COLUMN_STATUS'
export const SHOW_TABLE_ORDER_COLUMN_PAYMENT_TYPE = 'SHOW_TABLE_ORDER_COLUMN_PAYMENT_TYPE'
export const SHOW_TABLE_ORDER_COLUMN_SELLER = 'SHOW_TABLE_ORDER_COLUMN_SELLER'
export const SHOW_TABLE_ORDER_COLUMN_AGREEMENTS = 'SHOW_TABLE_ORDER_COLUMN_AGREEMENTS'
export const SHOW_TABLE_ORDER_COLUMN_CREATED_BY = 'SHOW_TABLE_ORDER_COLUMN_CREATED_BY'
export const SHOW_TABLE_ORDER_COLUMN_CREATED_AT = 'SHOW_TABLE_ORDER_COLUMN_CREATED_AT'
export const SHOW_TABLE_ORDER_COLUMN_ACTION = 'SHOW_TABLE_ORDER_COLUMN_ACTION'
// Payments Table
export const SHOW_TABLE_PAYMENT_COLUMN_NUMBER = 'SHOW_TABLE_PAYMENT_COLUMN_NUMBER'
export const SHOW_TABLE_PAYMENT_COLUMN_DAYS_LEFT = 'SHOW_TABLE_PAYMENT_COLUMN_DAYS_LEFT'
export const SHOW_TABLE_PAYMENT_COLUMN_PAYMENT = 'SHOW_TABLE_PAYMENT_COLUMN_PAYMENT'
export const SHOW_TABLE_PAYMENT_COLUMN_ORDER_DATE = 'SHOW_TABLE_PAYMENT_COLUMN_ORDER_DATE'
export const SHOW_TABLE_PAYMENT_COLUMN_CREATED_AT = 'SHOW_TABLE_PAYMENT_COLUMN_CREATED_AT'
export const SHOW_TABLE_PAYMENT_COLUMN_CONTACT = 'SHOW_TABLE_PAYMENT_COLUMN_CONTACT'
export const SHOW_TABLE_PAYMENT_COLUMN_THREAD = 'SHOW_TABLE_PAYMENT_COLUMN_THREAD'
export const SHOW_TABLE_PAYMENT_COLUMN_AMOUNT_TO_PAY = 'SHOW_TABLE_PAYMENT_COLUMN_AMOUNT_TO_PAY'
export const SHOW_TABLE_PAYMENT_COLUMN_PAID = 'SHOW_TABLE_PAYMENT_COLUMN_PAID'
export const SHOW_TABLE_PAYMENT_COLUMN_LEFT_TO_PAID = 'SHOW_TABLE_PAYMENT_COLUMN_LEFT_TO_PAID'
export const SHOW_TABLE_PAYMENT_COLUMN_TYPE = 'SHOW_TABLE_PAYMENT_COLUMN_TYPE'
export const SHOW_TABLE_PAYMENT_COLUMN_LAST_PAYMENT_DATE = 'SHOW_TABLE_PAYMENT_COLUMN_LAST_PAYMENT_DATE'
export const SHOW_TABLE_PAYMENT_COLUMN_PAYMENT_STATUS = 'SHOW_TABLE_PAYMENT_COLUMN_PAYMENT_STATUS'
export const SHOW_TABLE_PAYMENT_COLUMN_PAYMENT_METHOD = 'SHOW_TABLE_PAYMENT_COLUMN_PAYMENT_METHOD'
export const SHOW_TABLE_PAYMENT_COLUMN_ACTION = 'SHOW_TABLE_PAYMENT_COLUMN_ACTION'
export const SHOW_TABLE_ACTUAL_POINTS_COLUMN_TOTAL_POINTS = 'SHOW_TABLE_ACTUAL_POINTS_COLUMN_TOTAL_POINTS'
export const SHOW_TABLE_ACTUAL_POINTS_COLUMN_SPENT_POINTS = 'SHOW_TABLE_ACTUAL_POINTS_COLUMN_SPENT_POINTS'
export const SHOW_TABLE_ACTUAL_POINTS_COLUMN_ACTUAL_POINTS = 'SHOW_TABLE_ACTUAL_POINTS_COLUMN_ACTUAL_POINTS'
export const SHOW_TABLE_ACTUAL_POINTS_COLUMN_HYPOTHETICAL_POINTS = 'SHOW_TABLE_ACTUAL_POINTS_COLUMN_HYPOTHETICAL_POINTS'
export const SHOW_TABLE_AWARDED_POINTS_COLUMN_NUMBER = 'SHOW_TABLE_AWARDED_POINTS_COLUMN_NUMBER'
export const SHOW_TABLE_AWARDED_POINTS_COLUMN_USER = 'SHOW_TABLE_AWARDED_POINTS_COLUMN_USER'
export const SHOW_TABLE_AWARDED_POINTS_COLUMN_CONTACT = 'SHOW_TABLE_AWARDED_POINTS_COLUMN_CONTACT'
export const SHOW_TABLE_AWARDED_POINTS_COLUMN_STATUS = 'SHOW_TABLE_AWARDED_POINTS_COLUMN_STATUS'
export const SHOW_TABLE_AWARDED_POINTS_COLUMN_LOYALTY_PLAN = 'SHOW_TABLE_AWARDED_POINTS_COLUMN_LOYALTY_PLAN'
export const SHOW_TABLE_AWARDED_POINTS_COLUMN_NUMBER_OF_POINTS = 'SHOW_TABLE_AWARDED_POINTS_COLUMN_NUMBER_OF_POINTS'
export const SHOW_TABLE_AWARDED_POINTS_COLUMN_DESCRIPTION = 'SHOW_TABLE_AWARDED_POINTS_COLUMN_DESCRIPTION'
export const SHOW_TABLE_AWARDED_POINTS_COLUMN_CREATED_AT = 'SHOW_TABLE_AWARDED_POINTS_COLUMN_CREATED_AT'
export const SHOW_TABLE_AWARDED_POINTS_COLUMN_CREATED_BY = 'SHOW_TABLE_AWARDED_POINTS_COLUMN_CREATED_BY'
export const SHOW_TABLE_MARKETING_COMMISSION_COLUMN_NUMBER = 'SHOW_TABLE_MARKETING_COMMISSION_COLUMN_NUMBER'
export const SHOW_TABLE_MARKETING_COMMISSION_COLUMN_ORDER_DATE = 'SHOW_TABLE_MARKETING_COMMISSION_COLUMN_ORDER_DATE'
export const SHOW_TABLE_MARKETING_COMMISSION_COLUMN_CONTACT = 'SHOW_TABLE_MARKETING_COMMISSION_COLUMN_CONTACT'
export const SHOW_TABLE_MARKETING_COMMISSION_COLUMN_THREAD = 'SHOW_TABLE_MARKETING_COMMISSION_COLUMN_THREAD'
export const SHOW_TABLE_MARKETING_COMMISSION_COLUMN_PRODUCTS = 'SHOW_TABLE_MARKETING_COMMISSION_COLUMN_PRODUCTS'
export const SHOW_TABLE_MARKETING_COMMISSION_COLUMN_PRODUCT = 'SHOW_TABLE_MARKETING_COMMISSION_COLUMN_PRODUCT'
export const SHOW_TABLE_MARKETING_COMMISSION_COLUMN_USER_DISCOUNT = 'SHOW_TABLE_MARKETING_COMMISSION_COLUMN_USER_DISCOUNT'
export const SHOW_TABLE_MARKETING_COMMISSION_COLUMN_NETTO_PRICE = 'SHOW_TABLE_MARKETING_COMMISSION_COLUMN_NETTO_PRICE'
export const SHOW_TABLE_MARKETING_COMMISSION_COLUMN_GROSS_PRICE = 'SHOW_TABLE_MARKETING_COMMISSION_COLUMN_GROSS_PRICE'
export const SHOW_TABLE_MARKETING_COMMISSION_COLUMN_COMMISSION_AMOUNT = 'SHOW_TABLE_MARKETING_COMMISSION_COLUMN_COMMISSION_AMOUNT'
export const SHOW_TABLE_MARKETING_COMMISSION_COLUMN_ORDER_STATUS = 'SHOW_TABLE_MARKETING_COMMISSION_COLUMN_ORDER_STATUS'
export const SHOW_TABLE_MARKETING_COMMISSION_COLUMN_PAYMENT_STATUS = 'SHOW_TABLE_MARKETING_COMMISSION_COLUMN_PAYMENT_STATUS'
export const SHOW_TABLE_MARKETING_COMMISSION_COLUMN_PAYMENT_METHOD = 'SHOW_TABLE_MARKETING_COMMISSION_COLUMN_PAYMENT_METHOD'
export const SHOW_TABLE_MARKETING_COMMISSION_COLUMN_SELLER = 'SHOW_TABLE_MARKETING_COMMISSION_COLUMN_SELLER'
export const SHOW_TABLE_MARKETING_COMMISSION_COLUMN_COMMISSION_FOR = 'SHOW_TABLE_MARKETING_COMMISSION_COLUMN_COMMISSION_FOR'
export const SHOW_TABLE_MARKETING_COMMISSION_COLUMN_ACTION = 'SHOW_TABLE_MARKETING_COMMISSION_COLUMN_ACTION'
export const SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_TYPE = 'SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_TYPE'
export const SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_NUMBER = 'SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_NUMBER'
export const SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_ORDER_DATE = 'SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_ORDER_DATE'
export const SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_CONTACT = 'SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_CONTACT'
export const SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_THREAD = 'SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_THREAD'
export const SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_PRODUCTS = 'SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_PRODUCTS'
export const SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_NETTO_PRICE = 'SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_NETTO_PRICE'
export const SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_GROSS_PRICE = 'SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_GROSS_PRICE'
export const SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_PAYMENT_METHOD = 'SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_PAYMENT_METHOD'
export const SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_TO_PAY = 'SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_TO_PAY'
export const SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_PAID_OUT = 'SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_PAID_OUT'
export const SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_SELLER = 'SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_SELLER'
export const SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_COMMISSION_FOR = 'SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_COMMISSION_FOR'
export const SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_COMMISSION_STATUS = 'SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_COMMISSION_STATUS'
export const SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_DESCRIPTION = 'SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_DESCRIPTION'
export const SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_DOCUMENT_NUMBER = 'SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_DOCUMENT_NUMBER'
export const SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_DOCUMENT = 'SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_DOCUMENT'
export const SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_ACTION = 'SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_ACTION'

export const viewPermissionsList = [
  {
    //  DASHBOARD
    section: 'Dashboard',
    subsections: [
      {
        section: 'Contacts',
        ranks: [
          { key: SHOW_CONTACTS_ASSIGNED, requiredRanks: [] },
          { key: SHOW_CONTACTS_DELAYED, requiredRanks: [] },
          { key: SHOW_CONTACTS_STATUSES, requiredRanks: [] },
        ],
      },
      {
        section: 'Threads',
        ranks: [
          { key: SHOW_THREADS_ASSIGNED, requiredRanks: [] },
          { key: SHOW_THREADS_DELAYED, requiredRanks: [] },
          { key: SHOW_THREADS_STATUSES, requiredRanks: [] },
        ],
      },
      {
        section: 'dashboard.sections.YourStats',
        ranks: [
          { key: SHOW_OFFERS_SENT, requiredRanks: [] },
          { key: SHOW_OFFERS_ACCEPTED, requiredRanks: [] },
          { key: SHOW_OFFERS_MESSAGES, requiredRanks: [] },
          { key: SHOW_AGREEMENTS_SENT, requiredRanks: [] },
          { key: SHOW_AGREEMENTS_SIGNED, requiredRanks: [] },
        ],
      },
      {
        section: 'dashboard.sections.YourTasksOnTheSystem',
        ranks: [
          { key: SHOW_TASKS_DELAYED, requiredRanks: [] },
          { key: SHOW_TASKS_TODAY, requiredRanks: [] },
          { key: SHOW_TASKS_IN_THREADS, requiredRanks: [] },
        ],
      },
    ],
    meta: {
      // requiredRanks: [CONTACT_VIEW_ALL, CONTACT_VIEW_STRUCTURE, CONTACT_VIEW_ASSIGNED],
      requiredModules: [],
    },
  },
  {
    //  DASHBOARD
    section: 'Contacts',
    form: [
      { key: SHOW_CONTACT_FORM_POSITION },
      { key: SHOW_CONTACT_FORM_TAG },
      { key: SHOW_CONTACT_FORM_GROUP },
      { key: SHOW_CONTACT_FORM_ASSIGNED_USERS },
      { key: SHOW_CONTACT_FORM_DELIVERY_ADDRESSES },
      { key: SHOW_CONTACT_FORM_PERSONAL_ID },
      { key: SHOW_CONTACT_FORM_BIRTH_DATE },
      { key: SHOW_CONTACT_FORM_FAMILY_NAME },
      { key: SHOW_CONTACT_FORM_MATERIAL_STATUS },
      { key: SHOW_CONTACT_FORM_FINANCIAL_POSITION },
      { key: SHOW_CONTACT_FORM_PERSONAL_DOCUMENTS },
      { key: SHOW_CONTACT_FORM_LANGUAGE },
      { key: SHOW_CONTACT_FORM_NOTE },
      { key: SHOW_CONTACT_FORM_ADDRESS_COUNTRY },
      { key: SHOW_CONTACT_FORM_ADDRESS_CITY },
      { key: SHOW_CONTACT_FORM_ADDRESS_STREET },
      { key: SHOW_CONTACT_FORM_ADDRESS_POSTAL_CODE },
      { key: SHOW_CONTACT_FORM_ADDRESS_NUMBER },
    ],
    subsections: [
      {
        section: 'ContactCard',
        ranks: [
          { key: SHOW_CONTACT_END_STATUS, requiredRanks: [] },
          { key: SHOW_CONTACT_STATUS_LIST, requiredRanks: [] },
          { key: SHOW_CONTACT_STATUS_EDIT, requiredRanks: [] },
          { key: SHOW_CONTACT_CARD_BASIC_INFORMATION, requiredRanks: [] },
          { key: SHOW_CONTACT_CARD_APPLICATIONS, requiredRanks: [] },
          { key: SHOW_CONTACT_CARD_ADDITIONAL_FIELDS, requiredRanks: [] },
          { key: SHOW_CONTACT_CARD_PREDICTED_VALUE, requiredRanks: [] },
          { key: SHOW_CONTACT_CARD_THREAD_ACTIONS, requiredRanks: [] },
          { key: SHOW_CONTACT_CARD_THREAD_FUNNEL_PROGRESS, requiredRanks: [] },
          { key: SHOW_CONTACT_CARD_SMS, requiredRanks: [] },
          { key: SHOW_CONTACT_CARD_NOTES, requiredRanks: [] },
          { key: SHOW_CONTACT_CARD_CHECKLISTS, requiredRanks: [] },
          { key: SHOW_CONTACT_CARD_AGREEMENTS, requiredRanks: [] },
          { key: SHOW_CONTACT_CARD_TASKS, requiredRanks: [] },
          { key: SHOW_CONTACT_CARD_DOCUMENTS, requiredRanks: [] },
          { key: SHOW_CONTACT_CARD_POLLS, requiredRanks: [] },
          { key: SHOW_CONTACT_CARD_OFFERS, requiredRanks: [] },
          { key: SHOW_CONTACT_CARD_EMAILS, requiredRanks: [] },
          { key: SHOW_CONTACT_CARD_PROJECTS, requiredRanks: [] },
          { key: SHOW_CONTACT_CARD_PAYMENTS, requiredRanks: [] },
          { key: SHOW_CONTACT_CARD_ORDERS, requiredRanks: [] },
          { key: SHOW_CONTACT_CARD_LOYALTY_POINTS, requiredRanks: [] },
          { key: SHOW_CONTACT_CARD_AUTOMATIONS, requiredRanks: [] },
        ],
      },
    ],
    meta: {
      // requiredRanks: [CONTACT_VIEW_ALL, CONTACT_VIEW_STRUCTURE, CONTACT_VIEW_ASSIGNED],
      requiredModules: [],
    },
  },
  {
    //  MENU
    section: 'Offer.Cart',
    ranks: [
      { key: SHOW_CART_PRODUCT_VAT, requiredRanks: [] },
      { key: SHOW_CART_PRODUCT_DISCOUNT, requiredRanks: [] },
      { key: SHOW_CART_ADDITIONAL_FILES, requiredRanks: [] },
    ],
    meta: {
      // requiredRanks: [CONTACT_VIEW_ALL, CONTACT_VIEW_STRUCTURE, CONTACT_VIEW_ASSIGNED],
      requiredModules: ['offerModule'],
    },
  },
  {
    //  MENU
    section: 'Users',
    ranks: [
      { key: SHOW_USERS_NOTE, requiredRanks: [] },
    ],
    meta: {},
  },
  {
    //  MENU
    section: 'Export',
    ranks: [
      { key: SHOW_MARKETING_POINTS_EXPORT, requiredRanks: [] },
      { key: SHOW_MARKETING_POINT_PAYMENTS_EXPORT, requiredRanks: [] },
      { key: SHOW_ORDERS_EXPORT, requiredRanks: [] },
    ],
    meta: {},
  },
  {
    //  MENU
    section: 'Tables',
    subsections: [
      {
        section: 'Orders',
        ranks: [
          { coreLabel: 'ShowColumn', label: 'OrderID', key: SHOW_TABLE_ORDER_COLUMN_NUMBER },
          { coreLabel: 'ShowColumn', label: 'OrderDate', key: SHOW_TABLE_ORDER_COLUMN_ORDER_DATE },
          { coreLabel: 'ShowColumn', label: 'Contact', key: SHOW_TABLE_ORDER_COLUMN_CONTACT },
          { coreLabel: 'ShowColumn', label: 'contact.contactThread', key: SHOW_TABLE_ORDER_COLUMN_THREAD },
          { coreLabel: 'ShowColumn', label: 'Products', key: SHOW_TABLE_ORDER_COLUMN_PRODUCTS },
          { coreLabel: 'ShowColumn', label: 'offer.GrossPrice', key: SHOW_TABLE_ORDER_COLUMN_PRICE_GROSS },
          { coreLabel: 'ShowColumn', label: 'UserDiscount', key: SHOW_TABLE_ORDER_COLUMN_TOTAL_SELLER_DISCOUNT },
          { coreLabel: 'ShowColumn', label: 'StatusOfOrder', key: SHOW_TABLE_ORDER_COLUMN_STATUS },
          { coreLabel: 'ShowColumn', label: 'PaymentMethod', key: SHOW_TABLE_ORDER_COLUMN_PAYMENT_TYPE },
          { coreLabel: 'ShowColumn', label: 'Seller', key: SHOW_TABLE_ORDER_COLUMN_SELLER },
          { coreLabel: 'ShowColumn', label: 'CreateContract', key: SHOW_TABLE_ORDER_COLUMN_AGREEMENTS },
          { coreLabel: 'ShowColumn', label: 'contact.creator', key: SHOW_TABLE_ORDER_COLUMN_CREATED_BY },
          { coreLabel: 'ShowColumn', label: 'CreationTime', key: SHOW_TABLE_ORDER_COLUMN_CREATED_AT },
          { coreLabel: 'ShowColumn', label: 'Action', key: SHOW_TABLE_ORDER_COLUMN_ACTION },
        ],
      },
      {
        section: 'Schedules',
        ranks: [
          { coreLabel: 'ShowColumn', label: 'OrderID', key: SHOW_TABLE_PAYMENT_COLUMN_NUMBER },
          { coreLabel: 'ShowColumn', label: 'DaysLeftToBePaid', key: SHOW_TABLE_PAYMENT_COLUMN_DAYS_LEFT },
          { coreLabel: 'ShowColumn', label: 'Order', key: SHOW_TABLE_PAYMENT_COLUMN_PAYMENT },
          { coreLabel: 'ShowColumn', label: 'OrderDate', key: SHOW_TABLE_PAYMENT_COLUMN_ORDER_DATE },
          { coreLabel: 'ShowColumn', label: 'CreatedAt', key: SHOW_TABLE_PAYMENT_COLUMN_CREATED_AT },
          { coreLabel: 'ShowColumn', label: 'Contact', key: SHOW_TABLE_PAYMENT_COLUMN_CONTACT },
          { coreLabel: 'ShowColumn', label: 'Thread', key: SHOW_TABLE_PAYMENT_COLUMN_THREAD },
          { coreLabel: 'ShowColumn', label: 'Costs.AmountToPay', key: SHOW_TABLE_PAYMENT_COLUMN_AMOUNT_TO_PAY },
          { coreLabel: 'ShowColumn', label: 'Costs.Paid', key: SHOW_TABLE_PAYMENT_COLUMN_PAID },
          { coreLabel: 'ShowColumn', label: 'LeftToPay', key: SHOW_TABLE_PAYMENT_COLUMN_LEFT_TO_PAID },
          { coreLabel: 'ShowColumn', label: 'TypeOfPayment', key: SHOW_TABLE_PAYMENT_COLUMN_TYPE },
          { coreLabel: 'ShowColumn', label: 'DateOfLastPayment', key: SHOW_TABLE_PAYMENT_COLUMN_LAST_PAYMENT_DATE },
          { coreLabel: 'ShowColumn', label: 'PaymentStatus', key: SHOW_TABLE_PAYMENT_COLUMN_PAYMENT_STATUS },
          { coreLabel: 'ShowColumn', label: 'PaymentMethod', key: SHOW_TABLE_PAYMENT_COLUMN_PAYMENT_METHOD },
          { coreLabel: 'ShowColumn', label: 'Action', key: SHOW_TABLE_PAYMENT_COLUMN_ACTION },
        ],
      },
      {
        section: 'LoyaltyPoints',
        addonLabel: 'ActualBalance',
        ranks: [
          { coreLabel: 'ShowColumn', label: 'TotalPointsAmount', key: SHOW_TABLE_ACTUAL_POINTS_COLUMN_TOTAL_POINTS },
          { coreLabel: 'ShowColumn', label: 'SpentPointsAmount', key: SHOW_TABLE_ACTUAL_POINTS_COLUMN_SPENT_POINTS },
          { coreLabel: 'ShowColumn', label: 'ActualPointsAmount', key: SHOW_TABLE_ACTUAL_POINTS_COLUMN_ACTUAL_POINTS },
          { coreLabel: 'ShowColumn', label: 'HypotheticalPoints', key: SHOW_TABLE_ACTUAL_POINTS_COLUMN_HYPOTHETICAL_POINTS },
        ],
      },
      {
        section: 'LoyaltyPoints',
        addonLabel: 'AwardedPoints',
        ranks: [
          { coreLabel: 'ShowColumn', label: 'OrderID', key: SHOW_TABLE_AWARDED_POINTS_COLUMN_NUMBER },
          { coreLabel: 'ShowColumn', label: 'User', key: SHOW_TABLE_AWARDED_POINTS_COLUMN_USER },
          { coreLabel: 'ShowColumn', label: 'Contact', key: SHOW_TABLE_AWARDED_POINTS_COLUMN_CONTACT },
          { coreLabel: 'ShowColumn', label: 'Status', key: SHOW_TABLE_AWARDED_POINTS_COLUMN_STATUS },
          { coreLabel: 'ShowColumn', label: 'LoyaltyPlan', key: SHOW_TABLE_AWARDED_POINTS_COLUMN_LOYALTY_PLAN },
          { coreLabel: 'ShowColumn', label: 'NumberOfPoints', key: SHOW_TABLE_AWARDED_POINTS_COLUMN_NUMBER_OF_POINTS },
          { coreLabel: 'ShowColumn', label: 'Description', key: SHOW_TABLE_AWARDED_POINTS_COLUMN_DESCRIPTION },
          { coreLabel: 'ShowColumn', label: 'CreationTime', key: SHOW_TABLE_AWARDED_POINTS_COLUMN_CREATED_AT },
          { coreLabel: 'ShowColumn', label: 'CreatedBy', key: SHOW_TABLE_AWARDED_POINTS_COLUMN_CREATED_BY },
        ],
      },
      {
        section: 'MarketingPlans',
        addonLabel: 'Commissions',
        ranks: [
          { coreLabel: 'ShowColumn', label: 'OrderID', key: SHOW_TABLE_MARKETING_COMMISSION_COLUMN_NUMBER },
          { coreLabel: 'ShowColumn', label: 'OrderDate', key: SHOW_TABLE_MARKETING_COMMISSION_COLUMN_ORDER_DATE },
          { coreLabel: 'ShowColumn', label: 'Contact/Client', key: SHOW_TABLE_MARKETING_COMMISSION_COLUMN_CONTACT },
          { coreLabel: 'ShowColumn', label: 'Thread', key: SHOW_TABLE_MARKETING_COMMISSION_COLUMN_THREAD },
          { coreLabel: 'ShowColumn', label: 'Products', key: SHOW_TABLE_MARKETING_COMMISSION_COLUMN_PRODUCTS },
          { coreLabel: 'ShowColumn', label: 'ProductEdit', key: SHOW_TABLE_MARKETING_COMMISSION_COLUMN_PRODUCT },
          { coreLabel: 'ShowColumn', label: 'UserDiscount', key: SHOW_TABLE_MARKETING_COMMISSION_COLUMN_USER_DISCOUNT },
          { coreLabel: 'ShowColumn', label: 'offer.NettoPrice', key: SHOW_TABLE_MARKETING_COMMISSION_COLUMN_NETTO_PRICE },
          { coreLabel: 'ShowColumn', label: 'offer.GrossPrice', key: SHOW_TABLE_MARKETING_COMMISSION_COLUMN_GROSS_PRICE },
          { coreLabel: 'ShowColumn', label: 'NetCommissionAmount', key: SHOW_TABLE_MARKETING_COMMISSION_COLUMN_COMMISSION_AMOUNT },
          { coreLabel: 'ShowColumn', label: 'StatusOfOrder', key: SHOW_TABLE_MARKETING_COMMISSION_COLUMN_ORDER_STATUS },
          { coreLabel: 'ShowColumn', label: 'PaymentStatus', key: SHOW_TABLE_MARKETING_COMMISSION_COLUMN_PAYMENT_STATUS },
          { coreLabel: 'ShowColumn', label: 'PaymentMethod', key: SHOW_TABLE_MARKETING_COMMISSION_COLUMN_PAYMENT_METHOD },
          { coreLabel: 'ShowColumn', label: 'Seller', key: SHOW_TABLE_MARKETING_COMMISSION_COLUMN_SELLER },
          { coreLabel: 'ShowColumn', label: 'CommissionFor', key: SHOW_TABLE_MARKETING_COMMISSION_COLUMN_COMMISSION_FOR },
          { coreLabel: 'ShowColumn', label: 'Action', key: SHOW_TABLE_MARKETING_COMMISSION_COLUMN_ACTION },
        ],
      },
      {
        section: 'CommissionPayments',
        addonLabel: 'CommissionPayments',
        ranks: [
          { coreLabel: 'ShowColumn', label: 'task.Type', key: SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_TYPE },
          { coreLabel: 'ShowColumn', label: 'OrderID', key: SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_NUMBER },
          { coreLabel: 'ShowColumn', label: 'OrderDate', key: SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_ORDER_DATE },
          { coreLabel: 'ShowColumn', label: 'Contact/Client', key: SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_CONTACT },
          { coreLabel: 'ShowColumn', label: 'Thread', key: SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_THREAD },
          { coreLabel: 'ShowColumn', label: 'Products', key: SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_PRODUCTS },
          { coreLabel: 'ShowColumn', label: 'offer.NettoPrice', key: SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_NETTO_PRICE },
          { coreLabel: 'ShowColumn', label: 'offer.GrossPrice', key: SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_GROSS_PRICE },
          { coreLabel: 'ShowColumn', label: 'PaymentMethod', key: SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_PAYMENT_METHOD },
          { coreLabel: 'ShowColumn', label: 'ToPay', key: SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_TO_PAY },
          { coreLabel: 'ShowColumn', label: 'PaidOut', key: SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_PAID_OUT },
          { coreLabel: 'ShowColumn', label: 'Seller', key: SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_SELLER },
          { coreLabel: 'ShowColumn', label: 'CommissionFor', key: SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_COMMISSION_FOR },
          { coreLabel: 'ShowColumn', label: 'CommissionPaymentStatuses', key: SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_COMMISSION_STATUS },
          { coreLabel: 'ShowColumn', label: 'Description', key: SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_DESCRIPTION },
          { coreLabel: 'ShowColumn', label: 'DocumentNumber', key: SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_DOCUMENT_NUMBER },
          { coreLabel: 'ShowColumn', label: 'Documents', key: SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_DOCUMENT },
          { coreLabel: 'ShowColumn', label: 'Action', key: SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_ACTION },
        ],
      },
    ],
    ranks: [],
    meta: {},
  },
  {
    //  MENU
    section: 'System',
    subsections: [
      {
        section: 'Menu',
        ranks: [
          { key: SHOW_SYSTEM_MENU_DASHBOARD, requiredRanks: [] },
          { key: SHOW_SYSTEM_MENU_APPLICATIONS, requiredRanks: [] },
          { key: SHOW_SYSTEM_MENU_RANKING, requiredRanks: [] },
          { key: SHOW_SYSTEM_MENU_TASKS, requiredRanks: [] },
          { key: SHOW_SYSTEM_MENU_CALENDAR, requiredRanks: [] },
          { key: SHOW_SYSTEM_MENU_CONTACTS, requiredRanks: [] },
          { key: SHOW_SYSTEM_MENU_ORGANIZATIONS, requiredRanks: [] },
          { key: SHOW_SYSTEM_MENU_NEW_CONTACT, requiredRanks: [] },
          { key: SHOW_SYSTEM_MENU_FUNNELS, requiredRanks: [] },
          { key: SHOW_SYSTEM_MENU_OFFERS, requiredRanks: [] },
          { key: SHOW_SYSTEM_MENU_ORDERS, requiredRanks: [] },
          { key: SHOW_SYSTEM_MENU_AGREEMENTS, requiredRanks: [] },
          { key: SHOW_SYSTEM_MENU_PAYMENTS, requiredRanks: [] },
          { key: SHOW_SYSTEM_MENU_TIMELINE, requiredRanks: [] },
          { key: SHOW_SYSTEM_MENU_MARKETING_PLANS, requiredRanks: [] },
          { key: SHOW_SYSTEM_MENU_LOYALTY_PLANS, requiredRanks: [] },
          { key: SHOW_SYSTEM_MENU_SHOP, requiredRanks: [] },
          { key: SHOW_SYSTEM_MENU_PROJECTS, requiredRanks: [] },
          { key: SHOW_SYSTEM_MENU_CHAT, requiredRanks: [] },
          { key: SHOW_SYSTEM_MENU_MATERIALS, requiredRanks: [] },
          { key: SHOW_SYSTEM_MENU_USERS, requiredRanks: [] },
          { key: SHOW_SYSTEM_MENU_SETTINGS, requiredRanks: [] },
        ],
      },
      {
        section: 'Notifications',
        ranks: [
          { key: SHOW_NOTIFICATION_SYSTEM, requiredRanks: [] },
          { key: SHOW_NOTIFICATION_CONTACT, requiredRanks: [] },
          { key: SHOW_NOTIFICATION_THREAD, requiredRanks: [] },
          { key: SHOW_NOTIFICATION_EMAIL, requiredRanks: [] },
          { key: SHOW_NOTIFICATION_SMS, requiredRanks: [] },
          { key: SHOW_NOTIFICATION_TASK, requiredRanks: [] },
          { key: SHOW_NOTIFICATION_NOTE, requiredRanks: [] },
          { key: SHOW_NOTIFICATION_CHECKLIST, requiredRanks: [] },
          { key: SHOW_NOTIFICATION_OFFER, requiredRanks: [] },
          { key: SHOW_NOTIFICATION_ORDER, requiredRanks: [] },
          { key: SHOW_NOTIFICATION_PAYMENT, requiredRanks: [] },
          { key: SHOW_NOTIFICATION_AGREEMENT, requiredRanks: [] },
          { key: SHOW_NOTIFICATION_AUTOMATION, requiredRanks: [] },
        ],
      },
      {
        section: 'UpperHeader',
        ranks: [
          { key: SHOW_UPPER_HEADER_CONTACT_ADD, requiredRanks: [] },
          { key: SHOW_UPPER_HEADER_CONTACT_NOTE, requiredRanks: [] },
          { key: SHOW_UPPER_HEADER_CONTACT_TASK, requiredRanks: [] },
          { key: SHOW_UPPER_HEADER_CONTACT_EMAIL, requiredRanks: [] },
          { key: SHOW_UPPER_HEADER_CONTACT_SMS, requiredRanks: [] },
        ],
      },
      {
        section: 'General',
        ranks: [
          { key: SHOW_CONTACT_TAGS, requiredRanks: [] },
          { key: SHOW_ORDER_EDIT, requiredRanks: [] },
          { key: SHOW_MARKETING_PLANS, requiredRanks: [] },
          { key: SHOW_LOYALTY_PLANS, requiredRanks: [] },
        ],
      },
    ],
    meta: {
      // requiredRanks: [CONTACT_VIEW_ALL, CONTACT_VIEW_STRUCTURE, CONTACT_VIEW_ASSIGNED],
      requiredModules: [],
    },
  },
  //  CONTACT DETAILS
]
