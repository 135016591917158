<template>
  <div class="d-flex align-items-center justify-content-between">
    <b-button
      variant="flat-primary"
      size="sm"
      @click="$emit('previous')"
    >
      <feather-icon
        icon="ArrowLeftIcon"
        class="mr-50"
      />

      {{ $t('previous') }}
    </b-button>

    <span class="font-medium-1">
      {{ text }}
    </span>

    <b-button
      variant="flat-primary"
      size="sm"
      @click="$emit('next')"
      :disabled="disableNext"
    >
      {{ $t('next') }}

      <feather-icon
        icon="ArrowRightIcon"
        class="ml-50"
      />
    </b-button>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      tye: String,
      required: true,
    },
    disableNext: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['previous', 'next'],
}
</script>
