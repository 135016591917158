<!--eslint-disable-->

<template>
  <div class="position-relative p-25 m-25">
    <div class="d-flex justify-content-between align-items-center pb-25">
      <avatar
        :user="contactObject"
        :truncate="25"
      >
        <template #subtitle>
          {{ $t('Filled') }}
          <b-badge :variant="filledFields === requiredFields.length ? 'light-success' : 'light-danger'">
            {{ filledFields }} / {{ requiredFields.length }}
          </b-badge>
        </template>
      </avatar>
      <b-button
        size="sm"
        variant="warning"
        @click="editedContactId = contactObject.id; isContactEdited = true"
      >
        {{ $t('Edit') }}
      </b-button>
    </div>
    <b-form-group>
      <b-input-group>
        <b-form-input
          v-model="contact.part"
          @blur="saveContact"
        />
        <b-input-group-append is-text>
          {{ $t('Part') }}
        </b-input-group-append>
      </b-input-group>
    </b-form-group>

    <b-modal
      v-if="isContactEdited && editedContactId"
      :visible="isContactEdited"
      size="xl"
      hide-footer
      @hidden="editedContactId = 0; isContactEdited = false"
    >
      <create-contact
        :id="editedContactId"
        is-modal-view
        :required-fields="requiredFields"
        @close="e => {isContactEdited = false; editedContactId = 0; refreshContactData()}"
      />
    </b-modal>

    <b-overlay
      :show="loading"
      no-wrap
      spinner-variant="primary"
    />
  </div>
</template>

<script>
/* eslint-disable */

import { BInputGroupAppend } from 'bootstrap-vue'
import CreateContact from '@/pages/contact.old/Create.vue'
import axiosIns from '@/libs/axios'

export default {
  name: 'SectionAgreementItemContact',
  components: { CreateContact, BInputGroupAppend },
  props: {
    contact: {
      type: Object,
      default: null,
      required: true,
    },
    requiredFields: {
      type: Array,
      default: () => [],
    },
    agreementRequiredContactFields: {
      type: Array,
      default: () => [],
    },
    agreementRequiredPollFields: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    isContactEdited: false,
    editedContactId: 0,
    loading: false,
  }),
  computed: {
    contactObject: {
      get() {
        return this.contact?.contact || this.contact || null
      },
      set(value) {
        if (this.contact?.contact) this.contact.contact = value
        else this.contact = value
      },
    },
    filledFields() {
      const addresses = [
        'addressCity',
        'addressNumber',
        'addressPostalCode',
        'addressStreet',
        'addressCountry',
        'addressCorrespondenceCountry',
        'addressCorrespondenceCity',
        'addressCorrespondencePostalCode',
        'addressCorrespondenceStreet',
        'addressCorrespondenceNumber',
      ]
      let total = 0
      /* eslint-disable */
      this.requiredFields.forEach(field => {
        if (field === 'organizationName') {
          if (this.contactObject?.isOrganization && this.contactObject?.organization?.name) {
            total++
          }
        } else if (['number', 'authorityIssuing', 'releaseDate', 'expirationDate', 'type'].includes(field) && this.contactObject?.personIdentityDocuments?.length && this.contactObject.personIdentityDocuments[0][field]) {
          total++
        } else if (addresses.includes(field)) {
          if (this.contactObject?.isOrganization && this.contactObject?.organization && this.contactObject.organization[field]) {
            total++
          } else if (!this.contactObject?.isOrganization && this.contactObject[field]) {
            total++
          }
        } else if (field === 'part' && Boolean(String(this.contact.part))) {
          total++
        } else if (this.getObjectId(this.contactObject[field])) {
          total++
        }
      })

      return total
    },
  },
  async mounted() {
    await this.refreshContactData()
  },
  methods: {
    saveContact() {
      this.$emit('save')
    },
    async refreshContactData() {
      this.loading = true
      try {
        const resp = await axiosIns.get(`1/contacts/${this.contactObject.id}`, { params: { fields_load: this.$fields.SINGLE_CONTACT } })
        this.contactObject = resp.data.data.item
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>

</style>
