<template>
  <div>
    <!--    Picture    -->
    <b-row class="mx-0 mb-1">
      <b-col
        sm="12"
        md="6"
        lg="4"
      >
        <my-option
          :title="$t('offer.website.SelectBannerPicture')"
          :tip="`${$t('PreferredFile')}: (400x400)px, PNG`"
          :my-class="'dragDrop rec'"
        >
          <div>
            <b-img
              id="img-aboutBanner"
              class="px-0 py-0 center"
              thumbnail
              fluid
              :src="!!newWebsite.aboutBanner && newWebsite.aboutBanner.preview ? `data:image/webp;base64,${newWebsite.aboutBanner.preview}`: require('@/assets/icons/user/user.svg')"
            />

            <b-button
              v-b-tooltip.hover.v-primary
              variant="primary"
              :title="$t('DragDropFile')"
            >
              <feather-icon icon="PlusIcon" />
              <input
                type="file"
                @change="onChange($event, 'aboutBanner', 1250)"
              >
            </b-button>
          </div>
        </my-option>
      </b-col>
    </b-row>

    <!--    Option: Title    -->
    <b-row class="mx-0">
      <b-col
        sm="12"
        md="6"
        lg="8"
        class="px-1"
      >
        <my-option
          :title="$t('Title')"
          :tip="$t('MaximumLength') + ': 60'"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="$t('Title')"
            rules="required"
            class="w-100"
          >
            <b-form-group>
              <b-form-input
                id="popover-aboutTitle"
                v-model.trim="newWebsite.aboutTitle"
                :placeholder="$t('Title')"
                :state="errors.length > 0 ? false:null"
                maxlength="60"
              />
              <small class="text-danger">{{ errors[0] }}</small>

              <!--    Popover    -->
              <b-popover
                target="popover-aboutTitle"
                triggers="focus"
                variant="primary"
              >
                <span>{{ $t('MaximumLength') + ': 110' }}</span>
              </b-popover>
            </b-form-group>
          </validation-provider>
        </my-option>
      </b-col>
    </b-row>

    <!--    Option: Description    -->
    <b-row class="mx-0">
      <b-col
        sm="12"
        md="6"
        lg="8"
        class="px-1"
      >
        <my-option
          :title="$t('task.Description')"
          :tip="$t('MaximumLength') + ': 110'"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="$t('Title')"
            rules="required"
            class="w-100"
          >
            <b-form-group>
              <b-form-textarea
                id="popover-aboutDesc"
                v-model.trim="newWebsite.aboutDesc"
                :placeholder="$t('Title')"
                :state="errors.length > 0 ? false:null"
                maxlength="110"
              />
              <small class="text-danger">{{ errors[0] }}</small>

              <!--    Popover    -->
              <b-popover
                target="popover-aboutDesc"
                triggers="focus"
                variant="primary"
              >
                <span>{{ $t('MaximumLength') + ': 110' }}</span>
              </b-popover>
            </b-form-group>
          </validation-provider>
        </my-option>
      </b-col>
    </b-row>

    <!--    Option: Options    -->
    <b-row class="mx-0">
      <b-col
        sm="12"
        md="6"
        lg="8"
        class="px-1"
      >
        <!--    New Option    -->
        <my-option
          :title="$t('Options')"
          :tip="$t('MaximumLength') + ': 75'"
        >
          <b-row class="w-100 mx-0 px-0">
            <b-col
              sm="12"
              md="12"
              lg="12"
              class="d-flex px-0"
            >
              <b-form-input
                id="popover-aboutNewAbout"
                v-model.trim="aboutNewOption"
                :placeholder="$t('Title')"
                maxlength="75"
              />

              <!--    Popover    -->
              <b-popover
                target="popover-aboutNewAbout"
                triggers="focus"
                variant="primary"
              >
                <span>{{ $t('MaximumLength') + ': 75' }}</span>
              </b-popover>

              <!--    Add Button    -->
              <b-button
                variant="primary"
                class="btn-icon ml-1 offerWebsiteAdd"
                @click="
                  () => {
                    if (aboutNewOption.length > 0) {
                      newWebsite.aboutOptions.push(JSON.parse(JSON.stringify(aboutNewOption)))

                      aboutNewOption = ''
                    }
                  }
                "
              >
                <feather-icon icon="PlusIcon" />
              </b-button>
            </b-col>
          </b-row>

          <!--    Options    -->
          <b-row
            v-if="newWebsite.aboutOptions && newWebsite.aboutOptions.length > 0"
            class="w-100 mx-0 px-0"
          >
            <b-col
              v-for="(item, index) in newWebsite.aboutOptions"
              :key="index"
              sm="12"
              md="12"
              lg="12"
              class="mt-1 px-0 d-flex"
            >
              <b-form-input
                :value="item"
                readonly

                class="cursor-pointer"

                @click="
                  selectedOption = { id: index, opt: JSON.parse(JSON.stringify(item)) }
                  showChangeAboutOptionsModal = true
                "
              />
              <b-button
                variant="outline-danger"
                class="btn-icon ml-1 offerWebsiteAdd"
                @click="
                  newWebsite.aboutOptions.splice(index, 1)

                  newWebsite.aboutOptions.push()
                "
              >
                <feather-icon icon="MinusIcon" />
              </b-button>
            </b-col>
          </b-row>
        </my-option>
      </b-col>
    </b-row>

    <!--    Modal: Change about options     -->
    <b-modal
      v-model="showChangeAboutOptionsModal"
      :title="$t('offer.website.AboutCompany')"

      size="sm"
      hide-footer
      no-close-on-backdrop
    >
      <b-form>
        <validation-provider
          v-slot="{ errors }"
          :name="$t('Title')"
          rules="required"
          class="w-100"
        >
          <b-form-group :label="$t('Title')">
            <b-form-input
              v-model.trim="selectedOption.opt"
              :placeholder="$t('Title')"
              :state="errors.length > 0 ? false:null"
              maxlength="75"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <!--    Button: Save    -->
        <b-button
          variant="success"
          class="ml-25"
          size="sm"
          @click="
            newWebsite.aboutOptions[selectedOption.id] = selectedOption.opt
            $forceUpdate()

            showChangeAboutOptionsModal = false
          "
        >
          {{ $t('Save') }}
        </b-button>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  BForm, BFormTextarea, BPopover, VBTooltip,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import myOption from '@/views/offers/website/Option.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BForm,
    BFormTextarea,
    myOption,
    ValidationProvider,
    BPopover,
  },

  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  props: {
    value: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    newWebsite: { },

    selectedOption: {},
    aboutNewOption: '',

    showChangeAboutOptionsModal: false,
  }),

  mounted() {
    this.newWebsite = this.value
  },

  methods: {
    onChange(event, option, maxSize) {
      if (event.target.files[0].size > maxSize * 1000) {
        this.showToast('danger', this.$i18n.tc('MoreThanKB', 1, {
          type: this.$i18n.t(`SystemMoreThan.${option}`),
          size: maxSize,
        }))
        return
      }

      const [first] = event.target.files

      if (typeof this.newWebsite[option] !== 'undefined') {
        this.newWebsite[option] = first
      } else {
        // eslint-disable-next-line default-case
        switch (option) {
          case 'newOption-avatar':
            this.newOption.avatar = first
            break
        }
      }

      document.querySelector(`#img-${option}`).src = URL.createObjectURL(first)

      // eslint-disable-next-line consistent-return
      return first
    },
  },
}
</script>
