import Vue from 'vue'
import { truncate } from '@core/filters/parts/truncate'
import textIsland from '@core/filters/parts/textIsland'
import { shortcodePreview } from '@core/filters/parts/shortcodePreview'
import {
  htmlFormat, htmlToText, phoneFormat, priceFormat, priceGrossFormat, dateFormat, dateFormatShort,
} from '@core/filters/parts/valueFormat'

Vue.filter('truncate', truncate)
Vue.filter('priceGrossFormat', priceGrossFormat)
Vue.filter('textIsland', textIsland)
Vue.filter('shortcodePreview', shortcodePreview)
Vue.filter('htmlFormat', htmlFormat)
Vue.filter('htmlToText', htmlToText)
Vue.filter('priceFormat', priceFormat)
Vue.filter('phoneFormat', phoneFormat)
Vue.filter('dateFormat', dateFormat)
Vue.filter('dateFormatShort', dateFormatShort)
