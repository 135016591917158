var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-button',{staticClass:"mb-1",attrs:{"variant":"success","size":"sm"},on:{"click":function($event){return _vm.$router.push('/calculator/new')}}},[_vm._v(" "+_vm._s(_vm.$t('calculators.CreateNew'))+" "),_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1),_c('b-card',[_c('sw-table',{attrs:{"table-id":"6fa925f9793342ca985631b207630796","show-columns":"","show-search":"","pagination":_vm.pagination,"fields":_vm.tableFields},on:{"change-search-query":function($event){_vm.searchQuery = $event},"change-pagination":function($event){return Object.assign(_vm.pagination, $event)},"reload-content":_vm.loadData,"set-fields":function($event){_vm.tableFields = $event}},scopedSlots:_vm._u([{key:"table",fn:function(){return [_c('b-table',{ref:"selectableTable",staticClass:"table-wrap-words mx-0 px-0",attrs:{"items":_vm.items,"fields":_vm.fields,"striped":"","responsive":"","busy":_vm.loading,"show-empty":"","sort-by":_vm.sorter.sortBy,"sort-desc":_vm.sorter.sortDesc,"no-local-sorting":true},on:{"update:sortBy":function($event){return _vm.$set(_vm.sorter, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.sorter, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.sorter, "sortDesc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.sorter, "sortDesc", $event)},"sort-changed":function($event){Object.assign(_vm.sorter, $event); _vm.loadData()}},scopedSlots:_vm._u([{key:"head()",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(("" + label)))+" ")]}},{key:"cell(id)",fn:function(ref){
var index = ref.index;
return [_vm._v(" #"+_vm._s(_vm.getOrderNo(index, _vm.pagination, _vm.sorter))+" ")]}},{key:"cell(numberOfCalculationsPerformed)",fn:function(ref){
var value = ref.value;
return [_c('b-badge',{attrs:{"variant":"primary"}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"cell(status)",fn:function(ref){
var value = ref.value;
return [_c('b-badge',{attrs:{"variant":value === 'active' ? 'success' : 'danger'}},[_vm._v(" "+_vm._s(_vm.$t(("calculators." + value)))+" ")])]}},{key:"cell(createdBy)",fn:function(ref){
var value = ref.value;
return [(value)?_c('avatar',{attrs:{"user":value}}):_c('feather-icon',{attrs:{"icon":"MinusIcon"}})]}},{key:"cell(files)",fn:function(ref){
var value = ref.value;
return [(Array.isArray(value) && value.length)?_c('documents-list',{attrs:{"documents":value}}):_c('feather-icon',{attrs:{"icon":"MinusIcon"}})]}},{key:"cell(action)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('b-dropdown',{attrs:{"id":"dropdown-dropleft","dropleft":"","no-caret":"","variant":"flat"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{attrs:{"icon":"MoreVerticalIcon"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',[_c('dropdown-item-label',{attrs:{"icon":"EditIcon","label":_vm.$t('Edit')}})],1),_c('b-dropdown-item',[_c('dropdown-item-label',{attrs:{"icon":item.status === 'active' ? 'XSquareIcon' : 'CheckSquareIcon',"label":_vm.$t(item.status === 'active' ? 'Deactivate' : 'Activate')}})],1)],1)]}},{key:"cell()",fn:function(row){return [_c('table-default-cell',{attrs:{"field":row.field,"value":row.value}})]}},{key:"empty",fn:function(){return [_c('empty-content')]},proxy:true},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[_c('b-spinner',{staticClass:"align-middle",attrs:{"variant":"primary"}})],1)]},proxy:true}])})]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }