<template>
  <validation-observer
    ref="newStatusForm"
    v-slot="{ invalid }"
    tag="form"
  >
    <!--      New Task: Name      -->
    <validation-provider
      v-slot="{ errors }"
      rules="required"
      :name="$t('Name')"
    >
      <b-form-group
        :label="$t('Name')"
      >
        <b-form-input
          v-model="name"
          :placeholder="$t('Name')"
          :state="errors.length > 0 ? false:null"
        />

        <small class="text-danger">{{ errors[0] }}</small>
      </b-form-group>
    </validation-provider>

    <!--      Button: Save      -->
    <b-button
      variant="primary"
      class="w-100"
      :disabled="invalid"
      @click="save()"
    >
      {{ $t(id ? 'Save' : 'Add') }}
    </b-button>

    <b-overlay
      :show="isLoading"
      no-wrap
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
    />
  </validation-observer>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  CLOSE_MODAL,
  CREATE_PROJECT_STATUS,
  CREATE_PROJECT_TASK_STATUS,
  RELOAD_CONTENT,
  UPDATE_PROJECT_STATUS,
  UPDATE_PROJECT_TASK_STATUS,
} from '@/@constants/mutations'
import { mapGetters } from 'vuex'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data: () => ({
    isLoading: false,

    id: null,
    name: '',
  }),
  computed: {
    ...mapGetters({
      modalConfig: 'modal/getModalState',
    }),
  },
  mounted() {
    this.ini()
  },
  methods: {
    ini() {
      if (this.modalConfig.data?.status) this.loadStatus(this.modalConfig.data.status)
    },

    loadStatus(status) {
      if (status) {
        const { id, name } = status

        this.id = id || null
        this.name = name || ''
      }
    },

    save() {
      if (this.validationForm()) {
        const payload = this.getPayload()

        this.send(payload)
      }
    },

    getPayload() {
      const { id, name } = this

      const payload = { name }

      if (id) payload.id = id

      if (!id && this.modalConfig.data.newPosition) payload.position = this.modalConfig.data.newPosition

      return payload
    },

    send(payload) {
      this.isLoading = true

      const isTask = this.modalConfig.data.isTask || false
      let type

      if (!payload?.id > 0) {
        type = !isTask ? `projectStatuses/${CREATE_PROJECT_STATUS}` : `taskStatuses/${CREATE_PROJECT_TASK_STATUS}`
      } else {
        type = !isTask ? `projectStatuses/${UPDATE_PROJECT_STATUS}` : `taskStatuses/${UPDATE_PROJECT_TASK_STATUS}`
      }

      this.$store.dispatch(type, payload)
        .then(res => {
          this.$store.commit(`modal/${RELOAD_CONTENT}`, { id: res, ...payload })

          this.$nextTick(() => this.close())
        })

        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })

        .finally(() => { this.isLoading = false })
    },

    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.newStatusForm.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },

    close() { this.$store.commit(`modal/${CLOSE_MODAL}`) },
  },
}
</script>
