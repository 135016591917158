<template>
  <transition
    name="slide-fade"
    mode="out-in"
  >
    <automations-list
      v-if="!selectedAutomation"
      key="automations-list"
    />

    <automations-details
      v-else
      key="automations-details"
    />
  </transition>
</template>

<script>
import AutomationsList from '@/views/settings/automations/AutomationsList.vue'
import AutomationsDetails from '@/views/settings/automations/AutomationsDetails.vue'

export default {
  name: 'Automations',
  components: {
    AutomationsList,
    AutomationsDetails,
  },
  computed: {
    selectedAutomation() {
      return this.$route.params?.automationId
    },
  },
}
</script>

<style scoped>

</style>
