<script>
export default {
  name: 'TrainerListCard',
  props: {
    item: {
      default: null,
      required: true,
    },
    isSelected: {
      default: false,
      required: true,
    },
  },
  emites: ['edit', 'remove', 'select', 'contact'],
  computed: {
    avatar() {
      if (this.item?.avatar?.preview) return `data:image/webp;base64,${this.item?.avatar?.preview}`

      return this.item?.staticAvatar
    },
  },
  methods: {
    redirectTo(url) {
      window.open(url, '_blank')
    },
    selectCard() {
      this.$emit('select', this.isSelected ? null : this.item?.id)
    },
  },
}
</script>

<template>
  <b-card
    v-if="item"
    class="cursor-pointer"
    :class="{ 'border-primary': isSelected, 'border': !isSelected }"
    @click="selectCard"
  >
    <div class="d-flex align-items-center flex-column">
      <b-avatar
        class="shadow mb-50"
        size="90"
        :src="avatar"
      />
      <div class="ml-1 w-100">
        <div class="d-flex justify-content-between w-100">
          <div>
            <h5>
              {{ item.name }}

              <feather-icon
                v-if="item.youtubeUrl"
                class="text-primary mx-50 cursor-pointer"
                icon="YoutubeIcon"
                @click="redirectTo(item.youtubeUrl)"
              />
              <feather-icon
                v-if="item.websiteUrl"
                class="text-primary mx-50 cursor-pointer"
                icon="GlobeIcon"
                @click="redirectTo(item.websiteUrl)"
              />
            </h5>

            <div v-if="checkRequiredPermissions([$roles.FULL_ACCESS])">
              <feather-icon
                v-if="!item.id.includes('SW')"
                class="text-primary mx-50 cursor-pointer"
                icon="SettingsIcon"
                @click.stop="$emit('edit')"
              />
              <feather-icon
                v-if="!item.id.includes('SW')"
                class="text-primary mx-50 cursor-pointer"
                icon="TrashIcon"
                @click.stop="$emit('remove')"
              />
            </div>
          </div>

          <div v-if="item.id.includes('SW')"
               class="text-primary mx-50 cursor-pointer"
               @click.stop="$emit('contact')"
          >
            <feather-icon icon="SendIcon" />
            {{ $t('ContactUs') }}
          </div>
        </div>
        <span class="text-muted">{{ item.description }}</span>
      </div>
    </div>
  </b-card>
</template>

  <style scoped>

  </style>
