<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link
          class="brand-logo"
          style="margin-bottom: .5rem; margin-top: .5rem"
        >
          <b-img
            :src="system.logo"
            style="max-width: 225px"
          />
        </b-link>

        <!-- form -->
        <validation-observer
          ref="loginForm"
          v-slot="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="login"
          >

            <!-- email -->
            <b-form-group
              label-for="email"
              label="Email"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="userEmail"
                  name="login-email"
                  :state="errors.length > 0 ? false:null"
                  placeholder="john@example.com"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group :label="$t('Password')">
              <!--              <div class="d-flex justify-content-between">-->
              <!--                <label for="password">{{ $t('login.Password') }}</label>-->
              <!--                <b-link :to="{name:'auth-forgot-password-v1'}">-->
              <!--                  <small>{{ $t('login.ForgotPassword') }}?</small>-->
              <!--                </b-link>-->
              <!--              </div>-->
              <validation-provider
                v-slot="{ errors }"
                :name="$t('login.Password')"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    placeholder="********"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordType"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <!--            <b-form-group>-->
            <!--              <b-form-checkbox-->
            <!--                id="remember-me"-->
            <!--                v-model="status"-->
            <!--                name="checkbox-1"-->
            <!--              >-->
            <!--                {{ $t('login.RememberMe') }}-->
            <!--              </b-form-checkbox>-->
            <!--            </b-form-group>-->

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid || loading"
            >
              <b-spinner
                v-if="loading"
                small
              />
              <span v-else>{{ $t('login.Login') }}</span>
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
      <!-- /Login v1 -->

      <span class="w-100 mt-50 d-flex flex-wrap align-items-center justify-content-center">
        Powered by

        <a
          :href="`http://saleswizard.${ swHrefList.pl }`"
          class="ml-50"
        >
          <b-img
            :src="swLogo"
            style="max-width: 8rem;"
          />
        </a>
      </span>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { localize, ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  BButton,
  BCard,
  BForm,
  BFormGroup,
  BFormInput,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BLink,
} from 'bootstrap-vue'
import pl from 'vee-validate/dist/locale/pl.json'
import en from 'vee-validate/dist/locale/en.json'
import { email, required } from '@validations'
import axiosIns from '@/libs/axios'
import Logo from '@/assets/images/core/logo.png'
import { mapGetters } from 'vuex'
import swLogo from '@/assets/images/core/logo_universal.png'
import ClientWebsocketService from '@/helpers/websocket'
import { AUTH_REQUEST } from '../../@constants/mutations'

export default {
  name: 'SwLogin',
  components: {
    // BSV
    BImg,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BLink,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      passwordFieldType: 'password',
      loading: false,
      appLogo: Logo,
      userEmail: '',
      password: '',
      status: '',
      swHrefList: {
        pl: 'pl',
        en: 'pl',
      },
      // validation rules
      required,
      email,
      swLogo,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    ...mapGetters({
      system: 'system/getSettings',
    }),
  },
  beforeMount() {
    // eslint-disable-next-line no-plusplus
    for (let i = 1; i < 99999; i++) window.clearInterval(i)

    this.$store.dispatch('app/UPDATE_TITLE', {
      label: '',
      title: '',
    })
  },
  mounted() {
    ClientWebsocketService.instances.forEach(e => {
      ClientWebsocketService.instances.delete(e.name)
    })
    if (this.$route.query?.userEmail) {
      this.userEmail = this.$route.query.userEmail
    }

    const locales = {
      pl,
      en,
    }
    let language = window.navigator.userLanguage || window.navigator.language
    if (!language) language = 'pl'
    // eslint-disable-next-line prefer-destructuring
    if (language.includes('-')) language = language.split('-')[0]
    // eslint-disable-next-line prefer-destructuring
    if (language.includes('_')) language = language.split('_')[0]

    localize(language, locales[language])
    this.$i18n.locale = language
  },
  methods: {
    togglePasswordType() {
      if (this.passwordFieldType === 'password') this.passwordFieldType = 'text'
      else this.passwordFieldType = 'password'
    },
    login() {
      this.loading = true
      const { userEmail, password } = this
      this.$store.dispatch(`auth/${AUTH_REQUEST}`, { userEmail, password })
        .then(token => {
          axiosIns.defaults.headers.common.Authorization = `Bearer ${token}`
          this.showToast('success', this.$i18n.t('SoonYouWillBeRedirected'), this.$i18n.t('alert.Success'))
          setTimeout(() => {
            const router = this.$router
            const route = this.$route
            if (route.query && route.query.nextUrl) {
              router.push(route.query.nextUrl.toString() || '/')
            } else router.push('/')
            this.loading = false
          }, 1500)
        })
        .catch(err => {
          this.loading = false
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/page-auth.scss';
</style>
