var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('sw-table',{attrs:{"table-id":"559b8bea1ca44087a7c7a55608b83d10","show-columns":"","show-search":"","fields":_vm.pointsFields,"pagination":_vm.pagination},on:{"change-pagination":function($event){return Object.assign(_vm.pagination, $event)},"change-search-query":function($event){_vm.search = $event},"reload-content":_vm.loadData,"set-fields":function($event){_vm.pointsFields = $event}},scopedSlots:_vm._u([{key:"table",fn:function(){return [_c('b-table',{attrs:{"items":_vm.points,"fields":_vm.fields,"striped":"","responsive":"","busy":_vm.loading,"show-empty":"","sort-by":_vm.sorter.sortBy,"no-local-sorting":true,"sort-desc":_vm.sorter.sortDesc},on:{"update:sortBy":function($event){return _vm.$set(_vm.sorter, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.sorter, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.sorter, "sortDesc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.sorter, "sortDesc", $event)},"sort-changed":function($event){Object.assign(_vm.sorter, $event); _vm.loadData()}},scopedSlots:_vm._u([{key:"head()",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(("" + label)))+" ")]}},{key:"cell(description)",fn:function(ref){
var value = ref.value;
return [(value)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],attrs:{"title":value}},[_c('span',{staticClass:"text-primary mr-25"},[_c('feather-icon',{attrs:{"icon":"EyeIcon"}})],1),_vm._v(" "+_vm._s(_vm._f("truncate")(value,100))+" ")]):_c('feather-icon',{attrs:{"icon":"MinusIcon"}})]}},{key:"cell(id)",fn:function(ref){
var index = ref.index;
return [_vm._v(" #"+_vm._s(_vm.getOrderNo(index, _vm.pagination, _vm.sorter))+" ")]}},{key:"cell(status)",fn:function(ref){
var value = ref.value;
return [_c('b-badge',{attrs:{"variant":value !== 'ORDER_CANCELLED' ? 'light-primary' : 'light-danger'}},[_vm._v(" "+_vm._s(_vm.$t(("LoyaltyPointsStatuses." + value)))+" ")])]}},{key:"cell(orderId)",fn:function(ref){
var item = ref.item;
return [(item.contactThreadCartBox && item.contactThreadCartBox.contactThreadCartOrder)?_c('b-badge',{attrs:{"variant":"light-primary"}},[_vm._v(" "+_vm._s(item.contactThreadCartBox.contactThreadCartOrder.number)+" ")]):_c('feather-icon',{attrs:{"icon":"MinusIcon"}})]}},{key:"cell(contact)",fn:function(ref){
var item = ref.item;
return [(item.contactThreadCartBox && item.contactThreadCartBox.contactThread)?_c('span',[_c('router-link',{attrs:{"to":("/contact/" + (item.contactThreadCartBox.contactThread.contact.id))}},[_vm._v(" "+_vm._s(item.contactThreadCartBox.contactThread.contact.firstName)+" "+_vm._s(item.contactThreadCartBox.contactThread.contact.lastName)+" ")])],1):_c('span',[_c('feather-icon',{attrs:{"icon":"MinusIcon"}})],1)]}},{key:"cell(user)",fn:function(ref){
var item = ref.item;
return [(item && item.user)?_c('avatar',{attrs:{"user":item.user}}):_c('feather-icon',{attrs:{"icon":"MinusIcon"}})]}},{key:"cell(orderDate)",fn:function(ref){
var item = ref.item;
return [(item.contactThreadCartBox && item.contactThreadCartBox.contactThreadCartOrder && item.contactThreadCartBox.contactThreadCartOrder.orderDate)?_c('span',[_vm._v(_vm._s(_vm.formatDate(item.contactThreadCartBox.contactThreadCartOrder.orderDate.date, { month: 'numeric', day: 'numeric', year: 'numeric' }, _vm.$i18n.locale)))]):_c('feather-icon',{attrs:{"icon":"MinusIcon"}})]}},{key:"cell(loyaltyPlan)",fn:function(ref){
var item = ref.item;
return [(item && item.loyaltyPlan)?_c('span',[_vm._v(_vm._s(item.loyaltyPlan.name))]):_c('feather-icon',{attrs:{"icon":"MinusIcon"}})]}},{key:"cell(createdBy)",fn:function(ref){
var value = ref.value;
return [_c('avatar',{attrs:{"user":value}})]}},{key:"cell()",fn:function(row){return [_c('table-default-cell',{attrs:{"field":row.field,"value":row.value}})]}},{key:"empty",fn:function(){return [_c('empty-content')]},proxy:true},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[_c('b-spinner',{staticClass:"align-middle",attrs:{"variant":"primary"}})],1)]},proxy:true}])})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }