<template>
  <div>
    <div
      v-if="!loading"
      class="pt-1"
    >
      <div>
        <b-form
          ref="form"
          class="repeater-form"
        >
          <draggable v-model="notes">
            <!-- Row Loop -->
            <b-row
              v-for="(note, index) in notes"
              :id="note.id"
              :key="note.id"
              ref="row"
            >
              <!-- Item Name -->
              <b-col md="6">
                <div class="d-flex align-items-center">
                  <feather-icon
                    icon="MoveIcon"
                    class="text-primary mr-75"
                    size="18"
                  />
                  <b-form-group
                    :label="$t('Name')"
                    label-for="status-name"
                    class="w-100 flex-grow-1"
                  >
                    <b-form-input
                      id="status-name"
                      v-model="note.name"
                      type="text"
                      size="sm"
                    />
                  </b-form-group>

                  <b-button
                    variant="flat-primary"
                    size="sm"
                    class="ml-50"
                    @click="openSidebar(note, index)"
                  >
                    <feather-icon
                      :key="`status_icon_${index}_${note.icon}`"
                      :icon="note.icon"
                      size="20"
                    />
                  </b-button>
                </div>
              </b-col>

              <!-- Remove Button -->
              <b-col
                md="4"
                lg="2"
                class="mb-50"
              >
                <b-button
                  variant="outline-danger"
                  class="mt-0 mt-md-2 d-flex align-items-center"
                  style="column-gap: .24rem"
                  size="sm"
                  @click="removeItem(note.id, index)"
                >
                  <feather-icon icon="XIcon" />

                  <span>
                    {{ deleteNotes.includes(note.id ? note.id.toString() : note.id) ? $t('Cancel') : $t('Delete') }}
                  </span>
                </b-button>
              </b-col>

              <b-col cols="12">
                <hr>
              </b-col>
            </b-row>
          </draggable>
        </b-form>
      </div>

      <b-button
        variant="primary"
        class="mr-1"
        size="sm"
        @click="onSaveForm"
      >
        <span>{{ $t('Save') }}</span>
      </b-button>

      <b-button
        variant="outline-primary"
        size="sm"
        @click="addField"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-25"
        />
        <span>{{ $t('Add') }}</span>
      </b-button>

      <!-- icons sidebar -->
      <b-sidebar
        v-model="isSidebarOpen"
        class="icons-sidebar"
        shadow
        no-header
        :right="true"
      >
        <template #default="{ hide }">
          <div class="p-2 d-flex justify-content-between align-items-center bg-light">
            <div>
              <h4 class="text-capitalize mb-0">
                {{ $t('ChangeIcon') }}
              </h4>
            </div>

            <feather-icon
              icon="XIcon"
              size="18"
              class="cursor-pointer"
              @click="hide"
            />
          </div>

          <vue-perfect-scrollbar
            class="perfect-scroll"
            :settings="{ maxScrollbarLength: 60 }"
          >
            <div
              v-if="currentNote"
              class="sidebar-content"
            >
              <b-row>
                <b-col
                  cols="12"
                  sm="12"
                >
                  <b-card class="text-center">
                    <div class="pb-25">
                      {{ $t('CurrentIcon') }}
                    </div>
                    <feather-icon
                      class="text-primary"
                      size="30"
                      :icon="currentNote.icon"
                    />
                  </b-card>
                </b-col>

                <b-col
                  v-for="(icon, index) in iconsList"
                  :key="`icon_${index}`"
                  cols="12"
                  md="4"
                  sm="6"
                  @click="changeIcon(icon)"
                >
                  <b-card class="text-center">
                    <feather-icon
                      :icon="icon"
                      size="25"
                    />
                  </b-card>
                </b-col>
              </b-row>
            </div>
          </vue-perfect-scrollbar>
        </template>
      </b-sidebar>
    </div>

    <div
      v-else
      class="d-flex justify-content-center"
    >
      <b-spinner
        variant="primary"
        class="m-5"
      />
    </div>
  </div>
</template>

<script>
import {
  BButton, BCol, BForm, BFormGroup, BFormInput, BRow, BSidebar,
} from 'bootstrap-vue'
import icons from '@helpers/icons'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { mapGetters } from 'vuex'
import draggable from 'vuedraggable'
import { DELETE_NOTES, GET_NOTES, SAVE_NOTES } from '../../../../@constants/mutations'

export default {
  name: 'FunnelStatuses',
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BSidebar,
    VuePerfectScrollbar,
    draggable,
  },
  data: () => ({
    loading: false,
    areChangesSaved: true,
    iconsList: null,
    isSidebarOpen: false,
    currentNote: null,
    currentNoteIndex: null,
    notes: [],
  }),
  computed: {
    ...mapGetters({
      deleteNotes: 'typeSettings/getDeleteNotes',
    }),
  },
  mounted() {
    this.iconsList = icons()
    this.loadData()
  },
  methods: {
    loadData() {
      this.loading = true
      this.$store.dispatch(`typeSettings/${GET_NOTES}`)
        .then(res => {
          this.notes = res
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
        .finally(() => {
          this.loading = false
        })
    },
    addField() {
      const fieldData = { id: null, name: '', icon: 'UserIcon' }
      this.notes.push(fieldData)
      this.areChangesSaved = false
    },
    openSidebar(note, index) {
      this.isSidebarOpen = true
      this.currentNote = note
      this.currentNoteIndex = index
    },
    changeIcon(icon) {
      const note = { ...this.currentNote, icon }
      this.notes[this.currentNoteIndex] = note
      this.currentNote = note
      this.$forceUpdate()
      this.areChangesSaved = false
    },
    removeItem(id, index) {
      if (id) this.$store.commit('typeSettings/ADD_DELETE_NOTES', id)
      else this.notes.splice(index, 1)
      this.areChangesSaved = false
    },
    onSaveForm() {
      this.loading = true
      this.areChangesSaved = true
      const notes = []
      this.notes.forEach((note, index) => {
        const { icon, id, name } = note
        if (!this.deleteNotes.includes(note.id ? note.id.toString() : note.id)) {
          if (id && name) {
            notes.push({
              icon, id: id.toString(), name, position: index,
            })
          } else if (name) notes.push({ icon, name, position: index })
        }
      })

      if (this.deleteNotes.length) {
        this.$store.dispatch(`typeSettings/${DELETE_NOTES}`, this.deleteNotes)
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          })
      }

      this.$store.dispatch(`typeSettings/${SAVE_NOTES}`, notes)
        .then(() => {
          this.loadData()
          this.showToast('success', this.$i18n.t('success.contactUpdated'))
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },

}
</script>

<style lang="scss">
  .perfect-scroll {
    height: calc(100% - 85px);
  }
  .icons-sidebar {
    .card {
      box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
      &:hover {
        cursor: pointer;
        box-shadow: 0 4px 24px 0 rgb(34 41 47 / 20%);
      }
    }
  }
</style>
