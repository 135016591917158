<template>
  <!-- eslint-disable -->
  <b-tabs>
    <b-tab :title="$t('Commissions')">
      <commissions :contact-thread="contactThread || thread" />
    </b-tab>

    <b-tab :title="$t('CommissionPayments')">
      <withdrawals :contact-thread="contactThread || thread" />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTab, BTabs } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import Commissions from './components/Commissions.vue'
import Withdrawals from './components/Withdrawals.vue'

export default {
  /* eslint-disable */
  name: 'SwCommissionList',
  components: {
    BTabs,
    BTab,
    Commissions,
    Withdrawals,
  },
  props: {
    contactThread: {
      default: null,
      required: false,
    }
  },
  computed: {
    ...mapGetters({
      thread: 'contact/thread',
    }),
  },
}
</script>
