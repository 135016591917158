// eslint-disable-next-line import/no-cycle
import axiosIns from '@/libs/axios'
import {
  ADD_CAMPAIGNS,
  DELETE_CAMPAIGNS,
  GET_CAMPAIGNS,
  TEST_CAMPAIGNS,
  UPDATE_CAMPAIGNS,
} from '@/@constants/mutations'
import { CAMPAIGNS } from '@/@constants/fields'

export default {
  namespaced: true,

  actions: {
    // Query to get GET_CAMPAIGNS
    [GET_CAMPAIGNS]: async (_, filters) => axiosIns.get('1/campaigns', { params: { ...filters, fields_load: CAMPAIGNS, orderBy: 'id.desc' } })
      .then(({ data }) => data.data),

    // Query to get ADD_CAMPAIGNS
    [ADD_CAMPAIGNS]: async (_, payload) => axiosIns.post('1/campaigns', payload),

    // Query to get UPDATE_CAMPAIGNS
    [UPDATE_CAMPAIGNS]: async (_, payload) => axiosIns.patch('1/campaigns', payload),

    // Query to get DELETE_CAMPAIGNS
    [DELETE_CAMPAIGNS]: async (_, id) => axiosIns.delete('1/campaigns', { data: JSON.stringify([id]) }),

    // Query to get TEST_CAMPAIGNS
    [TEST_CAMPAIGNS]: (_, level = 0) => new Promise((resolve, reject) => {
      const params = {}
      params.fields_info = level

      axiosIns.get('1/campaigns', { params })
        .then(res => resolve(res.data.data))
        .catch(err => reject(err))
    }),
  },
}
