<template>
  <div>
    <b-tabs
      v-if="activeTab !== null"
      v-model="activeTab"
      :fill="fill"
      :justified="justified"
      :align="align"
      :pills="pills"
      nav-class="gap-0.34"
      @activate-tab="$emit('change', $event); changeUrl(showTabs.filter(({ show }) => show)[$event].key, $event)"
    >
      <template v-for="(tab, i) in showTabs.filter(({ show }) => show)">
        <b-tab
          :key="`${i}_tab_${$route.params.threadId}`"
          :title="$t(tab.title)"
          :lazy="tab.lazy || lazy"
          :disabled="tab.disabled"
          :title-link-class="
            pills
              ? activeTab !== null && showTabs.filter(({ show }) => show)[activeTab] && showTabs.filter(({ show }) => show)[activeTab].key === tab.key
                ? 'btn btn-primary btn-sm font-small-2 px-75 py-50'
                : 'btn btn-outline-primary btn-sm font-small-2 px-75 py-50'
              : ''"
          class="position-relative"
        >
          <template #title>
            <sw-icon
              :icon="tab.icon"
              :class="{
                'mr-n25': pills,
                'mt-n25': !tab.icon.startsWith('hi-')
              }"
              :size="pills ? '12' : ''"
              :scale="pills ? '0.6' : ''"
            />

            <span>{{ $t(tab.title) }}</span>

            <b-spinner
              v-if="tab.loading"
              type="border"
              small
              class="ml-50"
            />
          </template>

          <component
            :is="card ? 'card' : 'div'"
            v-if="tab.component"
            class="h-100"
            :key="`${i}_tab_${tab.title}_${$route.params.threadId}`"
            style="overflow-x: hidden"
            :no-body="noBody"
          >
            <component
              :is="tab.component"
              :key="`${i}_1_tab_${tab.title}_${$route.params.threadId}`"
              v-bind="{ thread, ...(tab.meta.componentProps || {}) }"
            />
          </component>

          <h4
            v-else
            class="py-2 text-center text-primary"
          >
            {{ $t('NoData') }} 😔
          </h4>
        </b-tab>
      </template>

      <template #tabs-end>
        <b-nav-item
          id="addView"
          ref="popoverTarget"
          role="presentation"
          :link-classes="pills && 'btn btn-flat-primary btn-sm font-small-2 px-75 py-50'"
          style="max-width: 150px"
        >
          <sw-icon
            icon="PlusIcon"
            class="mt-n25"
            :class="pills && 'mr-n25'"
            :size="pills && '12rem'"
          />

          <span>{{ $t('View') }}</span>
        </b-nav-item>
      </template>

      <template #empty>
        <h4 class="text-center text-secondary font-weight-normal">
          {{ $t('NoTabs') }}
        </h4>
      </template>
    </b-tabs>

    <b-popover
      ref="addViewPopover"
      target="addView"
      triggers="hover"
      placement="bottom"
      variant="flat-primary"
      custom-class="max-w-max no-arrow mt-0"
    >
      <b-row
        ref="addViewPopoverContent"
        style="width: 40rem; max-width: 84vw; max-height: 60vh; row-gap: .1rem;"
        class="overflow-auto"
      >
        <b-col
          v-for="(tab, j) in showTabs"
          :key="j"
          cols="12"
          md="6"
          class="d-flex"
        >
          <b-button
            :variant="tab.show ? 'flat-primary' : 'flat-secondary'"
            class="d-flex flex-row align-items-center flex-grow-1"
            :class="size === 'sm' ? 'px-75 py-50' : 'px-75 py-1'"
            :style="size === 'sm' ? 'column-gap: .75rem' : 'column-gap: .84rem'"
            @click="$emit('changeShow', { tab: tab, value: !tab.show })"
          >
            <sw-icon
              v-if="size === 'sm'"
              :icon="tab.icon"
            />

            <b-avatar
              v-else
              :variant="tab.show ? 'light-primary' : 'light-secondary'"
              rounded="sm"
            >
              <sw-icon :icon="tab.icon" />
            </b-avatar>

            <div
              class="d-flex flex-column align-items-start text-nowrap"
              style="row-gap: .34rem"
            >
              <span>
                {{ $t(tab.title) }}
              </span>

              <small
                v-if="size === 'md' || size === 'lg'"
                class="text-secondary"
              >
                {{ $t(tab.description) }}
              </small>
            </div>
          </b-button>
        </b-col>
      </b-row>
    </b-popover>
  </div>
</template>

<script>

import { BNavItem, BPopover } from 'bootstrap-vue'
import router from '@/router'

export default {
  components: {
    BNavItem,
    BPopover,
  },
  props: {
    tabs: {
      type: Array,
      required: false,
      default: () => [],
    },
    thread: {
      required: false,
      default: null,
    },
    lazy: {
      type: Boolean,
      required: false,
      default: false,
    },
    fill: {
      type: Boolean,
      required: false,
      default: false,
    },
    justified: {
      type: Boolean,
      required: false,
      default: false,
    },
    pills: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: 'md',
    },
    align: {
      type: String,
      required: false,
      default: 'start',
    },
    card: {
      type: Boolean,
      required: false,
      default: false,
    },
    noBody: {
      type: Boolean,
      required: false,
      default: false,
    },
    url: {
      type: Boolean,
      required: false,
      default: false,
    },
    baseUrl: {
      type: String,
      required: false,
      default: '',
    },
  },
  emits: ['change'],
  data: () => ({
    activeTab: null,
    showTabs: [],
  }),
  watch: {
    '$route.params.tab': {
      deep: true,
      handler(nw) {
        const index = this.tabs.filter(({ show }) => show).findIndex(e => e.key === nw)
        if (index > -1) {
          this.$set(this, 'activeTab', index)
        } else {
          this.$emit('changeShow', { tab: nw, value: true })
          this.$nextTick(() => {
            this.$set(this, 'activeTab', this.tabs.filter(({ show }) => show).findIndex(e => e.key === nw))
          })
        }
      },
    },
    tabs: {
      deep: true,
      async handler(n) {
        this.showTabs = n

        if (this.url) {
          const tab = String(router.currentRoute.params?.tab)

          // eslint-disable-next-line eqeqeq
          if (tab != undefined) {
            this.$set(this, 'activeTab', this.tabs.filter(({ show }) => show).findIndex(e => e.key === tab))
          }
          // this.activeTab = this.tabs.filter(({ show }) => show).findIndex(e => e.key === tab)

          // if (!tab) await this.changeUrl(tab)
        }
      },
    },
  },
  async mounted() {
    this.showTabs = this.tabs

    if (this.url) {
      const tab = String(router.currentRoute.params?.tab)

      const index = this.tabs.filter(({ show }) => show).findIndex(e => e.key === tab)
      if (index > -1) {
        this.$set(this, 'activeTab', index)
        if (!tab) await this.changeUrl(tab)
      } else {
        this.$emit('changeShow', { tab, value: true })

        this.$nextTick(async () => {
          const newIndex = this.tabs.filter(({ show }) => show).findIndex(e => e.key === tab)
          this.$set(this, 'activeTab', newIndex)
          if (!tab) await this.changeUrl(tab)
        })
      }
    } else {
      this.activeTab = 0
    }

    // Add the document click event listener when component is mounted
    document.addEventListener('click', this.handleOutsideClick)
    if (document.getElementById('right-side')) document.getElementById('right-side').addEventListener('scroll', this.handleClosePopover)
  },
  beforeDestroy() {
    // Remove the event listener when component is destroyed
    document.removeEventListener('click', this.handleOutsideClick)
    if (document.getElementById('right-side')) document.getElementById('right-side').removeEventListener('scroll', this.handleClosePopover)
  },
  methods: {
    handleClosePopover() {
      this.$refs.addViewPopover.$emit('close')
    },
    async changeUrl(i) {
      // if (this.url) await this.$router.push(this.baseUrl ? { path: `${this.baseUrl}/tab/${i}`, replace: true } : { query: { tab: i }, replace: true })
      const targetPath = this.baseUrl ? `${this.baseUrl}/${i}` : null
      const targetQuery = this.baseUrl ? null : { tab: i }

      // Get current path and query
      const currentPath = this.$route.path
      const currentQuery = this.$route.query

      // Check if the path or query is different before navigating
      if (this.thread) {
        if ((targetPath && (currentPath !== targetPath)) || (targetQuery && currentQuery.tab !== i.toString())) {
          if (targetPath) {
            await this.$router.push({ path: targetPath, replace: true })
          } else {
            await this.$router.push({ query: targetQuery, replace: true })
          }
        }
      }
    },
    togglePopover(event) {
      event.preventDefault()
      event.stopPropagation()

      const popover = this.$refs.addViewPopover

      // Toggle popover visibility manually
      if (popover.localShow) {
        this.$root.$emit('bv::hide::popover', this.$refs.popoverTarget.$el)
      } else {
        popover.doOpen()
      }
    },
    // eslint-disable-next-line no-unused-vars
    handleOutsideClick(event) {
      // eslint-disable-next-line no-unused-vars
      const { popoverTarget, addViewPopoverContent } = this.$refs

      // Check if the click was outside both the button (target) and the popover content
      // if (!popoverTarget?.contains(event.target) && !addViewPopoverContent?.contains(event.target)) this.$root.$emit('bv::hide::popover', popoverTarget.$el)
    },
  },
}
</script>
