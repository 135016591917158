import { PROJECT_TASKS_STATUSES } from '@/@constants/fields'
// eslint-disable-next-line import/no-cycle
import axiosIns from '@/libs/axios'
import {
  CREATE_PROJECT_TASK_STATUS,
  DELETE_PROJECT_TASK_STATUS,
  GET_PROJECT_TASK_STATUS,
  GET_PROJECT_TASK_STATUSES,
  TEST_PROJECT_TASK_STATUS,
  UPDATE_PROJECT_TASK_STATUS,
} from '@/@constants/mutations'
import moment from 'moment/moment'

const getDate = date => date.dateRange()

export default {
  namespaced: true,
  actions: {
    [GET_PROJECT_TASK_STATUSES]: (_, filters) => new Promise((resolve, reject) => {
      const params = {}
      params.fields_load = PROJECT_TASKS_STATUSES

      if (filters?.project) params['eq_projectTasks-project-id'] = filters.project

      if (filters?.createdAt) {
        if (filters?.createdAt?.project) {
          const value = getDate(filters.createdAt.project)

          // eslint-disable-next-line prefer-destructuring
          if (value[0]) params['gte_projectTasks-project-createdAt'] = value[0]
          // eslint-disable-next-line prefer-destructuring
          if (value[1]) params['lte_projectTasks-project-createdAt'] = value[1]
        }

        if (filters?.createdAt?.task) {
          const value = getDate(filters.createdAt.task)

          // eslint-disable-next-line prefer-destructuring
          if (value[0]) params['gte_projectTasks-createdAt'] = value[0]
          // eslint-disable-next-line prefer-destructuring
          if (value[1]) params['lte_projectTasks-createdAt'] = value[1]
        }
      }

      if (filters?.completionAt) {
        if (filters?.completionAt?.project) {
          const value = getDate(filters.completionAt.project)

          // eslint-disable-next-line prefer-destructuring
          if (value[0]) params['gte_projectTasks-project-endAt'] = value[0]
          // eslint-disable-next-line prefer-destructuring
          if (value[1]) params['lte_projectTasks-project-endAt'] = value[1]
        }

        if (filters?.completionAt?.task) {
          const value = getDate(filters.completionAt.task)

          // eslint-disable-next-line prefer-destructuring
          if (value[0]) params['gte_projectTasks-endAt'] = value[0]
          // eslint-disable-next-line prefer-destructuring
          if (value[1]) params['lte_projectTasks-endAt'] = value[1]
        }
      }

      if (filters?.tags.length) {
        const tags = filters.tags.map(tag => (typeof tag === 'string' ? tag : tag.id))

        params['eqArr_projectTasks-projectTaskTag-id'] = JSON.stringify(tags)
      }

      if (filters?.onDeadline) {
        params['gte_projectTasks-endAt'] = moment(new Date()).format('YYYY-MM-DD HH:mm')
        params['lte_projectTasks-endAt'] = moment(new Date()).add(1, 'days').format('YYYY-MM-DD HH:mm')
      }

      if (filters?.contactThreads.length) params['eqArr_projectTasks-project-contactThreads-id'] = JSON.stringify(filters.contactThreads.map(contact => contact.id))

      if (filters?.assignedUsers.length) params['eqArr_projectTasks-assignedUsers-id'] = JSON.stringify(filters.assignedUsers.map(contact => contact.id))

      if (filters?.categories.length) params['eqArr_projectTasks-projectTaskCategories-id'] = JSON.stringify(filters.categories)

      if (filters?.expired) params['lt_projectTasks-endAt'] = moment(new Date()).format('YYYY-MM-DD HH:mm')

      axiosIns.get('1/settings/projectTaskStatuses', { params })
        .then(res => {
          let list = res.data.data.items

          list = list.sort((a, b) => {
            if (a.position === null) { return 1 }

            if (b.position === null) { return -1 }

            return a.position - b.position
          })

          list.forEach(status => {
            if (filters?.project) {
              // eslint-disable-next-line
              status.projectTasks = status.projectTasks.filter(t => t?.project?.id === filters.project)
            }

            if (filters?.completionAt) {
              if (filters?.completionAt?.task) {
                const value = getDate(filters.completionAt.task)

                // eslint-disable-next-line
                status.projectTasks = status.projectTasks.filter(p => (p?.endAt?.date ? moment(p.endAt.date).isBetween(moment(value[0]), moment(value[1])) : false))
              }
            }

            if (filters?.tags.length) {
              const tags = filters.tags.map(tag => (typeof tag === 'string' ? tag : tag.id))

              // eslint-disable-next-line
              status.projectTasks = status.projectTasks.filter(p => (p?.projectTaskTag ? p.projectTaskTag.filter(t => tags.includes(t.id)).length : false))
            }

            if (filters?.contactThreads.length) {
              const threadList = filters.contactThreads.map(contact => contact.id)

              // eslint-disable-next-line
              status.projectTasks = status.projectTasks.filter(pt => (pt?.project?.contactThreads ? pt.project.contactThreads.filter(t => threadList.includes(t.id)).length : false))
            }

            if (filters?.assignedUsers.length) {
              const userList = filters.assignedUsers.map(contact => contact.id)

              // eslint-disable-next-line
              status.projectTasks = status.projectTasks.filter(p => (p?.assignedUsers ? p.assignedUsers.filter(t => userList.includes(t.id)).length : false))
            }

            if (filters?.categories.length) {
              // eslint-disable-next-line
              status.projectTasks = status.projectTasks.filter(pt => (pt?.projectTaskCategories ? pt.projectTaskCategories.filter(c => filters.categories.includes(c.id)).length : false))
            }

            if (filters?.createdAt) {
              if (filters?.createdAt?.task) {
                const value = getDate(filters.createdAt.task)

                // eslint-disable-next-line prefer-destructuring
                if (value[0]) params['gte_projectTasks-createdAt'] = value[0]
                // eslint-disable-next-line prefer-destructuring
                if (value[1]) params['lte_projectTasks-createdAt'] = value[1]

                // eslint-disable-next-line
                status.projectTasks = status.projectTasks.filter(p => moment(p.createdAt.date).isBetween(moment(value[0]), moment(value[1])))
              }
            }

            if (filters?.completionAt) {
              if (filters?.completionAt?.task) {
                const value = getDate(filters.completionAt.task)

                // eslint-disable-next-line prefer-destructuring
                if (value[0]) params['gte_projectTasks-endAt'] = value[0]
                // eslint-disable-next-line prefer-destructuring
                if (value[1]) params['lte_projectTasks-endAt'] = value[1]

                // eslint-disable-next-line
                status.projectTasks = status.projectTasks.filter(p => (p?.endAt?.date ? moment(p.endAt.date).isBetween(moment(value[0]), moment(value[1])) : false))
              }
            }

            // eslint-disable-next-line
            status.projectTasks = status.projectTasks.sort((a, b) => {
              if (a.position === null) {
                return 1
              }

              if (b.position === null) {
                return -1
              }

              return a.position - b.position
            })
          })

          resolve(list)
        })
        .catch(err => reject(err))
    }),

    [GET_PROJECT_TASK_STATUS]: (_, status) => new Promise((resolve, reject) => {
      const params = {}
      params.fields_load = PROJECT_TASKS_STATUSES

      axiosIns.get(`1/settings/projectTaskStatuses/${status}`, { params })
        .then(res => resolve(res.data.data.item))
        .catch(err => reject(err))
    }),

    [CREATE_PROJECT_TASK_STATUS]: (_, payload) => new Promise((resolve, reject) => {
      axiosIns.post('1/settings/projectTaskStatuses', payload)
        .then(res => resolve(res.data.data.items[0].id))
        .catch(err => reject(err))
    }),

    [UPDATE_PROJECT_TASK_STATUS]: (_, payload) => new Promise((resolve, reject) => {
      axiosIns.patch('1/settings/projectTaskStatuses', payload)
        .then(res => resolve(res.data.data.items[0].id))
        .catch(err => reject(err))
    }),

    [DELETE_PROJECT_TASK_STATUS]: (_, id) => new Promise((resolve, reject) => {
      axiosIns.delete('1/settings/projectTaskStatuses', { data: [id] })
        .then(() => { resolve() })
        .catch(err => { reject(err) })
    }),

    [TEST_PROJECT_TASK_STATUS]: (_, level = 0) => new Promise((resolve, reject) => {
      const params = {}
      params.fields_info = level

      axiosIns.get('1/settings/projectTaskStatuses', { params })
        .then(res => resolve(res.data.data))
        .catch(err => reject(err))
    }),
  },
}
