<template>
  <div>
    <!--    Option: Name     -->
    <b-form-group :label="$t('Name')">
      <b-form-input
        v-model="item.name"
        :placeholder="$t('Name')"
      />
    </b-form-group>
    <b-button
      variant="success"
      size="sm"
      :disabled="!item.name"
      @click="$emit('save-item', item)"
    >
      {{ $t('Save') }}
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'NewPollModal',
  props: {
    editedPoll: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    item: {
      name: '',
    },
  }),
  mounted() {
    if (this.editedPoll) {
      Object.keys(this.editedPoll).forEach(key => {
        if (key === 'id') this.item.id = this.editedPoll.id
        // eslint-disable-next-line no-prototype-builtins
        else if (this.item.hasOwnProperty(key)) this.item[key] = this.editedPoll[key]
      })
    }
  },
}
</script>

<style scoped>

</style>
