<template>
  <div>
    <b-button
      size="sm"
      class="mb-50"
      variant="primary"
      @click="showModal = true; editedItem = null"
    >
      {{ $t('Add') }}

      <feather-icon icon="PlusIcon" />
    </b-button>

<!--    <b-alert-->
<!--      show-->
<!--      variant="primary"-->
<!--      class="py-1 px-75 mt-1 d-flex flex-wrap align-items-center justify-content-between"-->
<!--      style="row-gap: .5rem"-->
<!--    >-->
<!--      <div class="d-flex align-items-center">-->
<!--        <b-avatar variant="primary">-->
<!--          <feather-icon icon="HexagonIcon" />-->
<!--        </b-avatar>-->

<!--        <div class="d-flex flex-column ml-75">-->
<!--          <strong>-->
<!--            {{ $t('ai.reports.Tokens') }}-->
<!--          </strong>-->

<!--          <div>-->
<!--            <span>-->
<!--              {{-->
<!--                $tc('UsedUpX', 2, {-->
<!--                  number: 0,-->
<!--                  number1: 0-->
<!--                })-->
<!--              }}-->
<!--            </span>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </b-alert>-->

    <!--      Section: Table      -->
    <sw-tabs
      :tabs="tabList"
      lazy
      :update="update"
    />

    <add-modal
      v-model="showModal"
      v-if="showModal"
      :show-modal="v => { showModal = v }"
      @update="() => { update = !update }"
    />
  </div>
</template>

<script>
import Sketches from './tabs/Sketches.vue'
import Planned from './tabs/Planned.vue'
import Delivering from './tabs/Delivering.vue'
import Sent from './tabs/Sent.vue'
import Failed from './tabs/Failed.vue'
import AddModal from './Add.vue'

export default {
  name: 'SwMAIL',
  components: {
    AddModal,
  },
  data: () => ({
    showModal: false,

    tabList: [
      {
        title: 'Sketches',
        component: Sketches,
      },
      {
        title: 'Planned',
        component: Planned,
      },
      {
        title: 'Delivering',
        component: Delivering,
      },
      {
        title: 'Sent',
        component: Sent,
      },
      {
        title: 'Failed',
        component: Failed,
      },
    ],

    update: false,
  }),
}
</script>
