<template>
  <b-modal
    :visible="showModal"
    :title="$t('LoyaltyPointsStatuses.BALANCE_CHANGE')"
    hide-footer
    @hide="closeModal"
  >
    <b-row>
      <b-col
        sm="12"
      >
        <assigned-users
          :label="$t('SelectUser')"
          :value="assignedUser"
          :disable-current-user="true"
          :is-multiple="false"
          @input="selectUser"
        />
      </b-col>
      <b-col
        sm="12"
        class="mb-50"
      >
        <div v-if="assignedUser && assignedUser.userLoyaltyPlans && assignedUser.userLoyaltyPlans.length">
          <label for="">{{ $t('SelectLoyaltyPlan') }}</label>
          <transition-group name="fade">
            <div
              v-for="plan in assignedUser.userLoyaltyPlans"
              :key="`assigned_plan_${plan.id}`"
              class="border p-1 mb-50 rounded cursor-pointer d-flex justify-content-between"
              :class="{ 'border-primary': loyaltyPlan === plan.loyaltyPlan.id }"
              @click="loyaltyPlan = plan.loyaltyPlan.id"
            >
              <div class="d-flex align-items-center">
                <feather-icon
                  icon="StarIcon"
                  :class="{ 'text-primary': loyaltyPlan === plan.loyaltyPlan.id }"
                  class="mr-25"
                />
                <div>
                  {{ plan.loyaltyPlan.name }}
                  <b-badge
                    v-b-tooltip.top.primary
                    variant="primary"
                    class="ml-25"
                    :title="$t('NumberOfPoints')"
                  >
                    {{ plan.loyaltyPlansRealizedPoints || 0 }}
                  </b-badge>
                </div>
              </div>
              <span :class="{ 'text-primary': loyaltyPlan === plan.loyaltyPlan.id }">{{ loyaltyPlan === plan.loyaltyPlan.id ? $t('Selected') : $t('Change') }}</span>
            </div>
          </transition-group>
        </div>
        <b-alert
          v-if="assignedUser && assignedUser.userLoyaltyPlans && assignedUser.userLoyaltyPlans.length === 0"
          show
        >
          <div class="alert-body">
            {{ $t('NoPlansForSelectedUser') }}
          </div>
        </b-alert>
      </b-col>
      <b-col sm="12">
        <b-form-group :label="$t('note.Description')">
          <b-form-textarea
            v-model="description"
            style="height: 10rem"
          />
        </b-form-group>
      </b-col>

      <b-col
        sm="12"
        class="d-flex align-items-center"
      >
        <b-form-checkbox
          v-model="isDecreaseValue"
          variant="primary"
          switch
        >
          <span class="switch-icon-right">
            <feather-icon icon="PlusIcon" />
          </span>
          <span class="switch-icon-left">
            <feather-icon icon="MinusIcon" />
          </span>
        </b-form-checkbox>
        <span v-if="isDecreaseValue">{{ $t('Decrease') }}</span>
        <span v-else>{{ $t('Increase') }}</span>
      </b-col>
      <b-col sm="12">
        <b-form-group
          :label="$t('ChangeValue')"
          class="mt-50"
        >
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon :icon="isDecreaseValue ? 'MinusIcon' : 'PlusIcon'" />
            </b-input-group-prepend>
            <b-form-input
              v-model="value"
              type="number"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-button
      variant="primary"
      size="sm"
      :disabled="isDisabled"
      @click="savePoint"
    >
      {{ $t('Save') }}
    </b-button>
  </b-modal>
</template>

<script>
import AssignedUsers from '@/views/components/AssignedUsers.vue'
import { VBTooltip, BFormTextarea } from 'bootstrap-vue'
import axiosIns from '@/libs/axios'

export default {
  name: 'LoyaltyPointModal',
  components: { AssignedUsers, BFormTextarea },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isDecreaseValue: false,
    assignedUser: '',
    loyaltyPlan: '',
    value: 0,
    description: '',
    loading: false,
  }),
  computed: {
    isDisabled() {
      if (this.loading) return true
      if (!this.assignedUser) return true
      if (!this.loyaltyPlan) return true

      return false
    },
  },
  methods: {
    closeModal() {
      this.isDecreaseValue = false
      this.assignedUser = ''
      this.loyaltyPlan = ''
      this.value = 0
      this.description = ''
      this.$emit('close-modal')
    },
    async savePoint() {
      this.loading = true
      const payload = {
        // user: this.getObjectId(this.assignedUser),
        user: this.getObjectId(this.assignedUser),
        loyaltyPlan: this.getObjectId(this.loyaltyPlan),
        // eslint-disable-next-line radix
        value: this.isDecreaseValue ? -parseInt(this.value) : parseInt(this.value),
        status: 'BALANCE_CHANGE',
        description: this.description,
      }
      // eslint-disable-next-line no-unreachable
      try {
        const resp = await axiosIns.post('1/loyaltyPlans/points', payload)
        if (resp) this.closeModal()
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.loading = false
      }
    },
    selectUser(event) {
      if (!event) {
        this.assignedUser = ''
        this.loyaltyPlan = ''
        return
      }

      this.assignedUser = event
      if (event?.userLoyaltyPlans?.length) {
        this.loyaltyPlan = event.userLoyaltyPlans[0]?.loyaltyPlan?.id
      }
    },
  },
}
</script>

<style scoped>

</style>
