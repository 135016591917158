<template>
  <div
    class="p-relative overflow-hidden d-flex justify-content-center align-items-center rounded-lg"
    style="height: 380px"
    :style="!post.picture || !post.picture.preview ? 'background: rgba(var(--theme-color-primary), .14);' : ''"
  >
    <img
      :src="getSRC"
      class="img-fluid"
      :style="
        post.picture ? `
          width: 100%;
          height: 100%;

          object-fit: cover;
        ` : ''
      "
    >
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    post: {
      type: Object,
      required: true,
    },
    isPreview: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      system: 'system/getSettings',
    }),

    getSRC() {
      const { post, isPreview } = this

      if (!isPreview) return post.picture ? `data:image/webp;base64,${post.picture.preview ? post.picture.preview : post.picture}` : this.system.logo || this.system.signet

      // eslint-disable-next-line no-nested-ternary
      return post.picture ? !post.picture.token ? this.getPictureBlob(post.picture) : `data:image/webp;base64,${post.picture.preview}` : this.system.logo || this.system.signet
    },
  },

  methods: {
    getPictureBlob(picture) {
      return URL.createObjectURL(picture)
    },
  },
}
</script>
