<template>
  <b-col
    sm="12"
    :md="md"
    :lg="lg"
  >
    <b-card class="offer-tile position-relative mb-1 mb-md-2">
      <div class="d-flex flex-column w-100">
        <span class="d-lg-none mb-50 h5 w-100">{{ title }}</span>

        <div class="d-flex justify-content-between align-items-center w-100">
          <div class="h5 mt-n1">
            <b-img
              v-if="image"
              :src="image"
              style="max-width: 25px; height: 25px; width: 25px"
              class="mr-50 text-primary"
            />

            <div v-if="svgIcon"
                 class="svg-icon d-inline-block mr-50"
                 v-html="svgIcon"
            />

            <feather-icon
              v-if="tip"
              v-b-tooltip.v-primary
              size="18"
              :title="tip"
              icon="InfoIcon"
              class="text-primary cursor-pointer mx-25 d-md-none"
            />

            <span class="mx-25">
              <span class="d-none d-lg-inline-block">{{ title }}</span>
            </span>

            <feather-icon
              v-if="tip"
              v-b-tooltip.v-primary
              size="18"
              :title="tip"
              icon="InfoIcon"
              class="text-primary cursor-pointer ml-25 d-none d-md-inline-block"
            />
          </div>

          <div class="d-flex">
            <div class="d-flex justify-content-end align-items-center flex-grow-1">
              <b-alert
                variant="primary"
                show
                class="my-0 mb-1 text-center"
              >
                <div class="alert-body">
                  <strong>{{ val }}</strong>
                </div>
              </b-alert>

              <b-button
                v-if="!hideAll"
                style="position:absolute;bottom: 0;left: 0;right: 0"
                class="w-100"
                size="sm"
                variant="flat-primary"
                @click="$emit('redirect', to)"
              >
                {{ $t('ShowAll') }}
              </b-button>
            </div>
          </div>
        </div>
      </div>

      <b-overlay
        no-wrap
        :show="val === -1"
        spinner-variant="primary"
      />
    </b-card>
  </b-col>
</template>

<script>
import { BAlert, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    BAlert,
  },
  props: {
    image: {
      default: null,
    },
    svgIcon: {
      default: null,
    },
    hideAll: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    tip: {
      type: String,
      required: false,
      default: '',
    },
    val: {
      type: Number,
      required: false,
      default: -1,
    },
    to: {
      type: String,
      required: false,
      default: '',
    },
    md: {
      type: Number,
      required: false,
      default: 6,
    },
    lg: {
      type: Number,
      required: false,
      default: 12,
    },
  },
}
</script>

<style>
.offer-tile {
  transition: all .16s ease-out;
}

.offer-tile:hover {
  transform: translateY(-.3rem);
  box-shadow: 0 14px 34px 0 rgb(34 41 47 / 14%);
}

.svg-icon {
    svg {
        height: 25px;
        width: 25px;
        max-width: 25px;
        line-height: 25px;
    }
}
</style>
