<template>
  <div class="d-flex align-items-center">
    <feather-icon :icon="icon"
                  class="mr-50"
    />
    {{ label }}
  </div>
</template>

<script>
export default {
  name: 'DropdownItemLabel',
  props: {
    label: {
      type: String,
      required: true,
      default: '',
    },
    icon: {
      type: String,
      required: true,
      default: 'CircleIcon',
    },
  },
}
</script>

<style scoped>

</style>
