<template>
  <draggable
    :id="`group_${index}`"
    :list="statusList"
    tag="ul"
    group="people"
    class="list-group list-group-flush overflow-y-scroll mb-2"
    :class="{ 'cursor-move': checkRequiredPermissions([$roles.PROJECT_MODIFY]) }"
    ghost-class="ghost-item"
    :disabled="!checkRequiredPermissions([$roles.PROJECT_MODIFY])"
    style="height: 80vh"
    @end="$emit('update')"
  >
    <!--      Target Body      -->
    <b-card
      v-for="(target, indexTask) in statusList"
      :id="`item_${target.project ? target.project.id : ''}`"
      :key="indexTask"
      :data-thread="target.id"
      class="border mb-1"
    >
      <div
        class="d-flex flex-row align-items-start"
        style="gap: .5rem"
      >
        <move-button
          v-if="checkRequiredPermissions([$roles.PROJECT_MODIFY])"
          :target-id="Number(target.id)"
          :is-task="isTask"
          :status-id="Number((target.projectStatus || target.projectTaskStatus).id)"
          :project-id="!isTask ? null : Number(target.project.id)"
          small
          @update="$emit('reload')"
        />

        <div class="mb-50 mt-n25 w-100">
          <span class="d-flex align-items-center">
            <!--      Task Title      -->
            <router-link :to="{ path: !isTask ? `/projects/edit/${target.id}` : `/projects/${target.project.id}/tasks/edit/${target.id}`, params: target.id }">
              <strong
                v-b-tooltip.v-primary.hover
                class="mb-0 text-dark"
                :title="target.name"
              >
                {{ target.name | truncate(64, '…') }}
              </strong>
            </router-link>

            <!--            <b-button-->
            <!--              v-b-tooltip.v-primary.hover-->
            <!--              size="sm"-->
            <!--              variant="flat-primary"-->
            <!--              class="p-25 text-nowrap"-->
            <!--              style="margin-left: auto;"-->
            <!--              :to="{ name: !isTask ? 'projects/tasks' : `projects/tasks/micro`, params: {-->
            <!--                project: !isTask ? target.id : target.project.id,-->
            <!--                task: !isTask ? null : target.id-->
            <!--              } }"-->
            <!--              :title="$t(!isTask ? 'ShowProjectTasks' : 'ShowMicroTaskTasks')"-->
            <!--            >-->
            <!--              <feather-icon icon="CheckSquareIcon" />-->
            <!--            </b-button>-->
          </span>

          <!--      Task Header      -->
          <div class="d-flex justify-content-between align-items-center">
            <!--      Time      -->
            <date
              :date="target.createdAt.date"
              :title="$t( !isTask ? 'ProjectCompletionDate' : 'TaskCompletionDate')"
              hide-time
            />
          </div>
        </div>
      </div>

      <b-badge
        v-if="isTask"
        v-b-tooltip.hover.v-primary
        :title="$t('offer.product.project')"
        variant="light-primary"
        style="font-size: .7rem"
        class="w-100 mb-25"
      >
        {{ target.project.name | textIsland(34) }}
      </b-badge>

      <template v-if="isTask">
        <template v-if="target.projectTaskCategories.length">
          <b-badge
            v-for="category in target.projectTaskCategories"
            :key="category.id"
            v-b-tooltip.hover.v-warning
            :title="$t('Category')"
            variant="light-warning"
            style="font-size: .7rem"
            class="w-100 mb-25"
          >
            {{ category.name | textIsland(34) }}
          </b-badge>
        </template>

        <b-badge
          v-else
          v-b-tooltip.hover.v-warning
          :title="$t('Category')"
          variant="light-warning"
          style="font-size: .7rem"
          class="w-100 mb-25"
        >
          <feather-icon
            icon="MinusIcon"
          />
        </b-badge>
      </template>

      <!--      Target Info      -->
      <target-info
        :is-loading="isLoading || isMeLoading"
        :infos="{
          documents: getDocumentLength(target.projectDocuments || target.projectTasks),
          completeTasks: (
            (
              typeof target.projectTaskCompletedCount !== 'undefined'
                ? target.projectTaskCompletedCount || 0
                : target.projectTaskCompletedMicroTasksCount || 0
            )
            + (
              typeof target.projectTaskRejected !== 'undefined'
                ? target.projectTaskRejected || 0
                : target.projectTaskRejectedMicroTasksCount || 0
            )
          ),
          tasks: target.projectTasksCount || 0,
          microTasks: (target.projectTaskMicroTasksCount || 0),
          endangeredTasks: (target.projectTaskMicroTasksCloseToDeadlineCount || 0),
          endangered: (target.projectTaskCloseToDeadlineCount || 0),
          assignedUsers: target.assignedUsers || [],
        }"
        class="mb-25"
        :is-task="isTask"
        :small="false"
      />

      <!--      Target Description      -->
      <span style="font-size: .8rem">{{ target.description | truncate(120, '…') }}</span>

      <!--      Target Categories Progres      -->
      <b-alert
        v-if="!isTask"
        variant="secondary"
        show
        class="my-50"
      >
        <div class="alert-body w-100 px-0">
          <progress-project
            :is-loading="isLoading || isMeLoading"
            :project="target"
            small
            :max-length="3"
          />
        </div>
      </b-alert>

      <!--      Target Tags      -->
      <target-tags
        :is-loading="isLoading"
        :tags="!isTask ? target.projectTasksTags || [] : target.projectTaskTag || []"
        :is-task="isTask"
      />
    </b-card>

    <b-overlay
      :show="isLoading"
      no-wrap
      spinner-variant="warning"
    />
  </draggable>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'
import draggable from 'vuedraggable'
import ProgressProject from '@/pages/projects/components/new/ProgressProject.vue'
import TargetInfo from '@/pages/projects/components/new/components/Info.vue'
import TargetTags from '@/pages/projects/components/new/components/Tags.vue'
import MoveButton from '@/pages/projects/components/new/components/MoveButton.vue'
import Date from '@/pages/projects/components/new/components/Date.vue'
import { UPDATE_PROJECT, UPDATE_PROJECT_TASK } from '@/@constants/mutations'

export default {
  components: {
    Date,
    draggable,
    ProgressProject,
    TargetInfo,
    // eslint-disable-next-line vue/no-unused-components
    TargetTags,
    MoveButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    index: {
      type: Number,
      required: false,
      default: -1,
    },
    statusList: {
      type: Array,
      required: true,
    },
    isTask: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    isMeLoading: false,

    mTags: false,
  }),
  methods: {
    getPayload(status, target, project) {
      const payload = {
        id: target,
      }

      if (!this.isTask) {
        payload.projectStatus = status
      } else {
        payload.project = project
        payload.projectTaskStatus = status
      }

      return payload
    },

    send(payload) {
      this.isMeLoading = true

      const type = !this.isTask ? `projects/${UPDATE_PROJECT}` : `tasks/${UPDATE_PROJECT_TASK}`

      this.$store.dispatch(type, payload)
        .then(() => {
          this.showToast('success', this.$i18n.t(!this.isTask ? 'ProjectUpdated' : 'TaskUpdated'))

          this.$emit('update')
        })

        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })

        .finally(() => { this.isMeLoading = false })
    },

    getDocumentLength(list) {
      if (!list.length) return 0

      return list.map(doc => doc.files.length).reduce((a, b) => a + b)
    },
  },
}
</script>
