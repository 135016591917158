var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-wrap align-items-center mt-25",class:_vm.small ? 'font-small-1' : 'font-small-2',staticStyle:{"gap":".5rem"}},[(_vm.tags.length)?[_vm._l((_vm.tags.slice(0, _vm.length || _vm.tags.length).filter(
          function (obj, index) { return _vm.tags.slice(0, _vm.length || _vm.tags.length).findIndex(function (item) { return item.name === obj.name; }) === index; }
        )),function(tag,indexTag){return _c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-danger",modifiers:{"hover":true,"v-danger":true}}],key:indexTag,class:_vm.isEdit ? 'cursor-pointer' : 'cursor-default',attrs:{"variant":!_vm.isEdit ? 'light-primary' : 'light-danger',"title":!_vm.isEdit ? tag : _vm.$t('Delete'),"disabled":!_vm.isEdit},on:{"click":function($event){_vm.isEdit ? _vm.deleteTag(tag.id) : null}}},[_c('span',{staticClass:"d-none d-md-inline-block"},[_vm._v(_vm._s('#' + tag.name))]),_c('span',{staticClass:"d-md-none"},[_vm._v(_vm._s(_vm._f("textIsland")('#' + tag.name,14, '…')))])])}),(_vm.tags.length >= 8)?_c('b-badge',{staticClass:"cursor-pointer",attrs:{"variant":"flat-primary"},on:{"click":function($event){_vm.mTags = _vm.tags}}},[_c('span',{staticClass:"text-primary",staticStyle:{"text-decoration":"underline","font-size":".7rem"}},[_vm._v(" "+_vm._s(_vm.$t('More') + '…')+" ")])]):_vm._e()]:(!_vm.isEdit)?_c('div',{staticClass:"text-center text-primary font-weight-bold mt-25"},[_c('feather-icon',{attrs:{"size":"17","icon":"XCircleIcon"}}),_vm._v(" "+_vm._s(_vm.$t('NoTags'))+" ")],1):_vm._e(),(_vm.isEdit)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary",modifiers:{"hover":true,"v-primary":true}}],staticClass:"p-25",attrs:{"size":"sm","variant":"flat-primary","title":_vm.$t('AddTag')},on:{"click":function($event){_vm.mAddTag = true}}},[_vm._v(" "+_vm._s(_vm.$t('AddTag'))+" "),_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1):_vm._e(),_c('b-modal',{attrs:{"title":_vm.$t('AddTag'),"hide-footer":"","no-close-on-backdrop":"","tabindex":"none","size":"sm"},model:{value:(_vm.mAddTag),callback:function ($$v) {_vm.mAddTag=$$v},expression:"mAddTag"}},[_c('validation-observer',{ref:"loginForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var invalid = ref.invalid;
return [_c('validation-provider',{attrs:{"name":"ChooseCategory","rules":"required"}},[_c('sw-select',{attrs:{"name":_vm.$t('ChooseTag')}},[_c('v-select',{attrs:{"placeholder":_vm.$t('ChooseTag'),"options":_vm.tagList,"disabled":!_vm.isEdit,"filterable":false,"label":"name","reduce":function (item) { return ({ id: item.id.toString(), name: item.name }); },"item-value":"id","item-text":"name"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
        var name = ref.name;
return [_vm._v(" "+_vm._s(_vm._f("textIsland")(name,28))+" ")]}},{key:"selected-option",fn:function(ref){
        var name = ref.name;
return [_vm._v(" "+_vm._s(_vm._f("textIsland")(name,28))+" ")]}},{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('NoOptions'))+" ")]},proxy:true}],null,true),model:{value:(_vm.tag),callback:function ($$v) {_vm.tag=$$v},expression:"tag"}})],1)],1),_c('b-button',{staticClass:"w-100",attrs:{"variant":"primary","size":"sm","disabled":invalid},on:{"click":function($event){return _vm.addTag()}}},[_vm._v(" "+_vm._s(_vm.$t('Add'))+" ")])]}}])}),_c('b-overlay',{attrs:{"no-wrap":"","show":_vm.isLoading || _vm.isMeLoading,"spinner-variant":"primary"}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }