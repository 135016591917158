<template>
  <div>
    <!--    Section: Filters    -->
    <sw-filters
      :shorts="[
        {
          title: $t('ShowAssignedTo'),
          type: 'avatar',
          value: () => filters.assignedUser,
          clear: () => {
            filters.assignedUser = []
            changeAssignedUsers()
            reloadUsers()
          }
        },
        {
          title: $t('accountStatus'),
          value: () => filters.status ? $t(accountStatuses.find(({ value }) => value === filters.status).label) : null,
          clear: () => {
            filters.status = ''
            reloadUsers()
          },
        },
        {
          title: $t('userRole'),
          value: () => filters.role ? ranksList.find(({ id }) => id === filters.role).name : null,
          clear: () => {
            filters.role = ''
            reloadUsers()
          },
        },
        {
          title: $t('accountType'),
          value: () => filters.accountType ? $t(accountTypes.find(({ value }) => value === filters.accountType).label) : null,
          clear: () => {
            filters.accountType = ''
            reloadUsers()
          },
        },
        {
          title: $t('DateRangeUser'),
          value: () => filters.timeRange,
          clear: () => {
            filters.timeRange = ''
            reloadUsers()
          }
        },
        {
          title: $t('LoyaltyPlan'),
          value: () => filters.loyaltyPlan ? $t(loyaltyPlans.find(({ id }) => id === filters.loyaltyPlan).name) : null,
          clear: () => {
            filters.loyaltyPlan = ''
            reloadUsers()
          },
        },
        {
          title: $t('MarketingPlan'),
          value: () => filters.marketingPlan ? $t(marketingPlans.find(({ id }) => id === filters.marketingPlan).name) : null,
          clear: () => {
            filters.marketingPlan = ''
            reloadUsers()
          },
        },
      ]"

      :res="[
        {
          title: $t('All'),
          value: () => users.length,
        }
      ]"
      mt
    >
      <b-row class="client_filters">
        <b-col
          sm="12"
        >
          <!--  Table Filters - Users -->
          <span>{{ $t('ShowAssignedTo') }}: </span>
          <sw-select>
            <assigned-users
              :value="filters.assignedUser"
              :is-multiple="true"
              @input="changeAssignedUsers"
            />
          </sw-select>
          <!--  Table Filters - Users -->
        </b-col>

        <b-col
          cols="12"
          md="4"
          class="mt-1"
        >
          <!--  Table Filters - Users - status -->
          <sw-select :name="$t('accountStatus')">
            <v-select
              v-model="filters.status"
              :options="accountStatuses"
              :reduce="status => status.value"
              @input="reloadUsers"
            >
              <template #no-options>
                {{ $t('NoOptions') }}
              </template>
              <template #option="{ label }">
                {{ $t(`${label}`) }}
              </template>
              <template #selected-option="{ label }">
                {{ $t(`${label}`) }}
              </template>
            </v-select>
          </sw-select>
          <!--  Table Filters - Users - status -->
        </b-col>

        <b-col
          cols="12"
          md="4"
          class="mt-1"
        >
          <!--  Table Filters - Users - status -->
          <sw-select :name="$t('userRole')">
            <v-select
              v-model="filters.role"
              :reduce="role => role.id.toString()"
              :options="ranksList"
              label="name"
              @open="getRanks"
              @input="reloadUsers"
            >
              <template #no-options>
                {{ $t('NoOptions') }}
              </template>
            </v-select>
          </sw-select>
          <!--  Table Filters - Users - status -->
        </b-col>

        <b-col
          cols="12"
          md="4"
          class="mt-1"
        >
          <!--  Table Filters - Users - status -->
          <sw-select :name="$t('accountType')">
            <v-select
              v-model="filters.accountType"
              :reduce="type => type.value"
              :options="accountTypes"
              @input="reloadUsers"
            >
              <template #no-options>
                {{ $t('NoOptions') }}
              </template>
              <template #option="{ label }">
                {{ $t(`${label}`) }}
              </template>
              <template #selected-option="{ label }">
                {{ $t(`${label}`) }}
              </template>
            </v-select>
          </sw-select>
          <!--  Table Filters - Users - status -->
        </b-col>

        <b-col
          cols="12"
          lg="4"
          class="mt-1"
        >
          <sw-select :name="$t('DateRangeUser')">
            <sw-select-fpr
              :is-empty="filters.timeRange"
              @clear="clearTimeRange()"
            >
              <flat-pickr
                v-model="filters.timeRange"
                class="form-control"
                :config="{ ...flatPickerConfig, locale: getCalendarLocale($i18n.locale) }"
                @on-close="reloadUsers"
              />
            </sw-select-fpr>
          </sw-select>
        </b-col>

        <b-col
          v-if="checkRequiredModule('loyaltyModule') && checkRequiredPermissions([$roles.LOYALTY_PLAN_VIEW_ALL])"
          cols="12"
          md="4"
          class="mt-1"
        >
          <!--  Table Filters - Users - Loyalty Plan -->
          <sw-select :name="$t('LoyaltyPlan')">
            <v-select
              v-model="filters.loyaltyPlan"
              :options="loyaltyPlans"
              label="name"
              :reduce="plan => plan.id"
              @input="reloadUsers"
            >
              <template #no-options>
                {{ $t('NoOptions') }}
              </template>
            </v-select>
          </sw-select>
          <!--  Table Filters - Users - Loyalty Plan -->
        </b-col>

        <b-col
          v-if="checkRequiredModule('marketingModule') && checkRequiredPermissions([$roles.MARKETING_PLAN_VIEW_ALL])"
          cols="12"
          md="4"
          class="mt-1"
        >
          <!--  Table Filters - Users - Marketing Plan -->
          <sw-select :name="$t('MarketingPlan')">
            <v-select
              v-model="filters.marketingPlan"
              label="name"
              :options="marketingPlans"
              :reduce="plan => plan.id"
              @input="reloadUsers"
            >
              <template #no-options>
                {{ $t('NoOptions') }}
              </template>
            </v-select>
          </sw-select>

          <b-form-checkbox v-model="filters.superSeller"
                           variant="primary"
                           @change="reloadUsers"
          >
            {{ $t('SuperSeller') }}
          </b-form-checkbox>
          <!--  Table Filters - Users - Marketing Plan -->
        </b-col>
      </b-row>
    </sw-filters>

    <div class="my-50">
      <b-button
        v-if="checkRequiredPermissions([$roles.USER_ADD])"
        variant="primary"
        to="/user/new"
        size="sm"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-50"
        />

        <span class="align-middle">{{ $t('Add') }}</span>
      </b-button>
    </div>

    <b-row>
      <b-col cols="12">
        <b-card>
          <!-- eslint-disable -->
          <!-- New Table -->
          <sw-table
            table-id="0c44cb8d5b3b45f380aeaa77c9cd93d2"
            show-columns
            show-search
            :fields.async="fields"
            :pagination="pagination"
            @change-pagination="Object.assign(pagination, $event)"
            @change-search-query="searchQuery = $event"
            @reload-content="getUsers"
            @set-fields="fields = $event"
          >
            <template #table>
              <b-table
                :tbody-tr-class="deletedClasses"
                :sort-by.sync="sorter.sortBy"
                :sort-desc.sync="sorter.sortDesc"
                :no-local-sorting="true"
                @sort-changed="Object.assign(sorter, $event); getUsers()"
                ref="selectableTable"
                :items="users"
                :fields="userFields"
                responsive
                :busy="loading"
                show-empty
              >
                <template #head()="{ label }">
                  {{ $t(`${label}`) }}
                </template>
                  <template #cell(id)="{ index }">
                      #{{ getOrderNo(index, pagination, sorter) }}
                  </template>
                <template #cell(userId)="{ value }">
                  <b-badge
                    v-if="value"
                    variant="light-primary"
                    @click="copyValue(value)"
                  >
                    <feather-icon icon="ToolIcon" />
                    {{ value }}
                  </b-badge>
                  <feather-icon
                    v-else
                    icon="MinusIcon"
                  />
                </template>
                <!-- Name -->
                <template #cell(name)="{ item }">
                  <router-link
                    v-if="item.canLogin"
                    :to="{ name: 'user', params: { userId: item.id } }"
                  >
                    <avatar :user="item" />
                  </router-link>
                  <avatar
                    v-else
                    text-variant="secondary"
                    :user="item"
                  />

                </template>
                <!-- Parent user -->
                <template #cell(parentUser)="{ value }">
                  <avatar :user="value">
                    <template #subtitle v-if="value && value.userId">
                      <feather-icon icon="ToolIcon" /> {{ value.userId }}
                    </template>
                  </avatar>
                </template>

                <template #cell(phone)="{ value }">
                  <a :href="`tel:${value}`" class="text-nowrap" v-if="value">
                    {{ value | phoneFormat }}
                  </a>

                  <feather-icon
                      v-else
                      icon="MinusIcon"
                  />
                </template>

                <template #cell(email)="{ value }">
                  <a :href="`mailto:${value}`" class="text-nowrap" v-if="value">
                    {{ value }}
                  </a>

                  <feather-icon
                      v-else
                      icon="MinusIcon"
                  />
                </template>
                <!-- Is organization -->
                <template #cell(isOrganization)="{ value }">
                  <b-badge variant="light-primary">
                    {{ value ? $t('company') : $t('privatePerson') }}
                  </b-badge>
                </template>

                <template #cell(marketingPlans)="{ item, index }">
                  <div v-if="Array.isArray(item.userMarketingPlans) && item.userMarketingPlans.length">
                    <b-badge variant="light-primary" v-for="({ marketingPlan, superSeller }, planIndex) in item.userMarketingPlans" :key="`marketingPlans_${index}_${planIndex}`">
                    {{ marketingPlan.name }}
                      <feather-icon icon="StarIcon" v-b-tooltip :title="$t('SuperSeller')" v-if="superSeller" />
                  </b-badge>
                  </div>
                  <feather-icon
                      v-else
                      icon="MinusIcon"
                  />
                </template>

                <template #cell(loyaltyPlans)="{ item, index }">
                  <div v-if="Array.isArray(item.userLoyaltyPlans) && item.userLoyaltyPlans.length">
                    <b-badge variant="light-primary" class="mr-25" v-for="({ loyaltyPlan }, planIndex) in item.userLoyaltyPlans" :key="`loyaltyPlans_${index}_${planIndex}`">
                    {{ loyaltyPlan.name }}
                  </b-badge>
                  </div>
                  <feather-icon
                      v-else
                      icon="MinusIcon"
                  />
                </template>
                <!-- Status -->
                <template #cell(accountStatus)="{ item }">
                  <b-badge
                      v-if="item.canLogin"
                    :variant="item.blocked ? 'light-danger' : (!item.activated ? 'light-secondary' : 'light-success')"
                  >
                    {{ item.blocked ? $t('Blocked') : (!item.activated ? $t('NotActive') : $t('Active')) }}
                  </b-badge>
                  <b-badge
                          v-else
                          :variant="'danger'"
                  >
                      {{ $t('offer.product.constAttribute.status.deleted') }}
                      <span v-if="item.canLoginUpdateAt"> {{ formatDate(item.canLoginUpdateAt.date, { month: 'short', day: 'numeric', year: 'numeric' }, $i18n.locale) }}
                      </span>
                  </b-badge>
                </template>
                <!-- Action -->
                <template #cell(action)="{ index, item }">
                  <b-dropdown
                    class="dropdown-icon-wrapper text-decoration-none"
                    dropleft
                    no-caret
                    variant="flat"
                  >
                    <template #button-content>
                      <feather-icon icon="MoreVerticalIcon" />
                    </template>
                    <b-dropdown-item
                      v-if="currentUser.id !== item.id && checkRequiredPermissions([$roles.USER_EDIT]) && item.canLogin"
                      @click.stop="changeUserStatus(true, item.id, rowIndex = index)"
                    >
                        <dropdown-item-label icon="LockIcon" :label="$t('Block')" />
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="currentUser.id !== item.id && checkRequiredPermissions([$roles.USER_EDIT]) && item.canLogin"
                      @click.stop="changeUserStatus(false, item.id, rowIndex = index)"
                    >
                        <dropdown-item-label icon="UnlockIcon" :label="$t('Unblock')" />
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="item.activated && checkRequiredPermissions([$roles.USER_RESET_PASSWORD]) && item.canLogin"
                      @click.stop="resetUserPassword(item.id)"
                    >
                      <dropdown-item-label icon="KeyIcon" :label="$t('login.ResetPassword')" />
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="!item.activated && checkRequiredPermissions([$roles.USER_EDIT])"
                      @click.stop="resendActivation(item.id)"
                    >
                      <dropdown-item-label icon="SendIcon" :label="$t('ResendActivationLink')" />
                    </b-dropdown-item>

                    <b-dropdown-divider />

                      <b-dropdown-item
                              v-if="checkRequiredPermissions([$roles.USER_EDIT]) && item.canLogin"
                              @click.stop="onDeleteUsers([String(item.id)], item.canLogin)"
                      >
                          <dropdown-item-label icon="TrashIcon" :label="$t('Delete')" />
                      </b-dropdown-item>

                      <b-dropdown-item
                              v-else-if="checkRequiredPermissions([$roles.USER_EDIT]) && !item.canLogin"
                              @click.stop="onDeleteUsers([String(item.id)], item.canLogin)"
                              class="position-relative"
                              :disabled="blockRestore(item)"
                      >
                          <dropdown-item-label icon="RepeatIcon" :label="$t('Restore')" />
                          <b-overlay
                                  no-wrap
                                  variant="white"
                                  opacity=".75"
                                  show
                                  v-if="item.canLoginUpdateAt && blockRestore(item)"
                          >
                              <template #overlay>
                                  <feather-icon icon="LockIcon" />
                                  {{ formatDate(moment(item.canLoginUpdateAt.date).add(7, 'days').format('YYYY-MM-DD'), { month: 'short', day: 'numeric', year: 'numeric' }, $i18n.locale) }}
                              </template>
                          </b-overlay>
                      </b-dropdown-item>
                  </b-dropdown>
                </template>
                  <!--    Other    -->
                  <template #cell()="row">
                      <table-default-cell
                              :field="row.field"
                              :value="row.value"
                      />
                  </template>

                  <!--      Empty      -->
                  <template #empty>
                      <empty-content />
                  </template>

                  <!--      Table Busy      -->
                  <template #table-busy>
                      <div class="text-center text-danger my-2">
                          <b-spinner
                                  class="align-middle"
                                  variant="primary"
                          />
                      </div>
                  </template>
              </b-table>
            </template>
          </sw-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { loadFromLocalStorage, saveToLocalStorage } from '@/helpers/localStorage'
import { BTable, VBTooltip } from 'bootstrap-vue'
import { formatDate } from '@core/utils/filter'
import axiosIns from '@/libs/axios'
import moment from 'moment'
import { CHANGE_USER_STATUS, GET_USERS, REMOVE_USERS } from '../../@constants/mutations'
import AssignedUsers from '../../views/components/AssignedUsers.vue'

export default {
  components: {
    vSelect,
    flatPickr,
    AssignedUsers,
    BTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data: () => ({
    filters: {
      assignedUser: [],
      role: '',
      status: '',
      timeRange: '',
      accountType: '',
      loyaltyPlan: '',
      marketingPlan: '',
    },
    loading: true,
    fields: [
      {
        key: 'id', label: '#', visible: true, sortable: true,
      },
      { key: 'userId', label: 'ID', visible: true },
      { key: 'name', label: 'FirstNameAndLastName', visible: true },
      { key: 'parentUser', label: 'contact.parentUser', visible: true },
      { key: 'accountStatus', label: 'contact.accountStatus', visible: true },
      { key: 'phone', label: 'contact.phone', visible: true },
      { key: 'email', label: 'contact.email', visible: true },
      { key: 'createdAt', label: 'CreatedAt', visible: true },
      { key: 'lastLoginAt', label: 'contact.lastLoginAt', visible: true },
      { key: 'rank', label: 'contact.rank', visible: true },
      {
        key: 'marketingPlans', label: 'MarketingPlans', visible: true, requiredModule: 'marketingModule',
      },
      {
        key: 'loyaltyPlans', label: 'LoyaltyPlans', visible: true, requiredModule: 'loyaltyModule',
      },
      { key: 'isOrganization', label: 'contact.isOrganization', visible: true },
      { key: 'action', label: 'Action', visible: true },
    ],
    searchQuery: '',
    // Pagination
    sorter: {
      sortBy: 'id',
      sortDesc: true,
    },
    pagination: {
      perPage: 25,
      currentPage: 1,
      totalRecords: 1,
    },
    marketingPlans: [],
    loyaltyPlans: [],
    // Pagination
    accountTypes: [{ value: 0, label: 'privatePerson' }, { value: 1, label: 'company' }],
    accountStatuses: [
      { value: { blocked: true }, label: 'Blocked' },
      { value: { blocked: false, activated: true }, label: 'Active' },
      { value: { blocked: false, activated: false }, label: 'NotActive' },
    ],
    flatPickerConfig: {
      mode: 'range',
    },
    users: [],
  }),
  computed: {
    moment() {
      return moment
    },
    permitedFields() {
      return this.fields.filter(field => (!field?.requiredModule || this.checkRequiredModule(field?.requiredModule)))
    },
    userFields() {
      return this.permitedFields.filter(field => field.visible)
    },
    ...mapGetters({
      ranksList: 'ranks/getRanksList',
      currentUser: 'auth/getCurrentUser',
    }),
    locale() {
      return this.$i18n.locale
    },
  },
  async mounted() {
    await this.loadMarketingPlans()
    await this.loadLoyaltyPlans()

    this.filters.assignedUser = [this.currentUser]
    if (loadFromLocalStorage('users_assigned_filter')) {
      this.filters.assignedUser = loadFromLocalStorage('users_assigned_filter')
    }
    this.getUsers()
  },
  methods: {
    copyValue(value) {
      this.$copyText(value, this.$el).then(() => {
        this.showToast('primary', this.$i18n.t('CopiedToClipboard'))
      }, e => {
        this.showToast('success', e)
      })
    },
    formatDate,
    deletedClasses(userRow) {
      if (userRow && !userRow.canLogin) {
        return 'bg-light-primary text-dark'
      }
      return ''
    },
    clearTimeRange() {
      this.filters.timeRange = ''
      this.getUsers()
    },
    getRanks() {
      this.$store.dispatch('ranks/GET_RANKS_LIST')
    },
    reloadUsers() {
      this.getUsers()
    },
    resetUserPassword(userId) {
      this.$store.dispatch('auth/RESET_USER_PASSWORD', userId)
        .then(() => {
          this.showAlert('success', '', this.$i18n.t('UserRestartInfo'), false, 'Ok')
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
    // eslint-disable-next-line no-unused-vars
    onDeleteUsers(users, status) {
      if (status) {
        this.$store.dispatch('globalTasks/GET_USER_TASKS', { userIds: users, status: 'O' })
          .then(res => {
            if (!res.data.totalItemCount) {
              this.showAlert('error', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('UserCanBeRestoredAfter7Days'))
                .then(() => {
                  this.$store.dispatch(`users/${REMOVE_USERS}`, { userIds: users, status: false })
                    .then(() => {
                      this.getUsers()
                      this.showToast('success', this.$i18n.t('success.contactUpdated'))
                    })
                    .catch(err => {
                      this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
                    })
                })
            } else {
              this.showAlert('error', this.$i18n.t('ProblemOccured'), 'Użytkownik z przypisanymi zadaniami nie może zostać usunięty')
            }
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          })
      } else {
        this.$store.dispatch(`users/${REMOVE_USERS}`, { userIds: users, status: true })
          .then(() => {
            this.getUsers()
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          })
      }
    },
    // Pagination
    onChangePage(event) {
      this.pagination.currentPage = event
      this.getUsers()
    },
    onChangeRecordsCount(event) {
      this.pagination.perPage = event
    },
    // Pagination
    getUsers() {
      this.loading = true

      this.$nextTick(() => {
        const { pagination } = this

        this.$store.dispatch(`users/${GET_USERS}`, {
          filters: this.filters, pagination, search: this.searchQuery, sorter: this.sorter,
        })
          .then(res => {
            this.users = res.data.items
            this.pagination.totalRecords = res.data.totalItemCount
            this.loading = false
          })
          .catch(err => {
            this.loading = false
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          })
      })
    },
    changeUserStatus(status, userId, userIndex) {
      this.$store.dispatch(`users/${CHANGE_USER_STATUS}`, { status, userId })
        .then(() => {
          this.users[userIndex].blocked = status
          this.showToast('success', this.$i18n.t('success.contactUpdated'))
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
    changeAssignedUsers(payload) {
      if (payload) {
        saveToLocalStorage('users_assigned_filter', payload)
        this.filters.assignedUser = payload
      } else {
        saveToLocalStorage('users_assigned_filter', [])
        this.filters.assignedUser = []
      }

      this.reloadUsers()
    },
    async resendActivation(userId) {
      try {
        await axiosIns.post(`1/users/${userId}/resendActivation`)

        this.showToast('success', this.$i18n.t('success.contactUpdated'))
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },

    async loadMarketingPlans() {
      if (this.checkRequiredModule('marketingModule') && this.checkRequiredPermissions([this.$roles.MARKETING_PLAN_VIEW_ALL])) {
        try {
          const resp = await axiosIns.get('1/marketingPlans', { params: { fields_load: this.$fields.MARKETING_PLANS } })

          this.marketingPlans = resp?.data?.data?.items || []
        } catch (err) {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        }
      }
    },
    // eslint-disable-next-line consistent-return
    async loadLoyaltyPlans() {
      if (this.checkRequiredModule('loyaltyModule') && this.checkRequiredPermissions([this.$roles.LOYALTY_PLAN_VIEW_ALL])) {
        try {
          const resp = await axiosIns.get('1/loyaltyPlans', { params: { fields_load: this.$fields.LOYALTY_PLANS } })

          this.loyaltyPlans = resp?.data?.data?.items || []
        } catch (err) {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        }
      }
    },
    blockRestore(user) {
      // eslint-disable-next-line eqeqeq
      if (this.currentUser.id == 1) return false

      if (user.canLoginUpdateAt) {
        const now = moment()
        const deletedAt = moment(user.canLoginUpdateAt.date)

        const daysCount = now.diff(deletedAt, 'days')

        return daysCount <= 7
      }

      return false
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';

  .bg-light-primary.text-dark {
    *:not(.b-avatar > *):not(.b-avatar-text *):not(.badge):not(.badge *) { color: #444 }
  }
</style>
