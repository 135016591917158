// eslint-disable-next-line import/prefer-default-export
export const reports = [
  {
    path: '/reports',
    name: 'reports',
    component: () => import('@/pages/reports/Panel.vue'),
    meta: {
      autorize: true,
      pageTitle: 'Reports1',
    },
  },
]
