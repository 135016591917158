// eslint-disable-next-line import/no-cycle
import axiosIns from '@/libs/axios'
import { PROJECT_DOCUMENTS } from '@/@constants/fields'
import {
  CREATE_PROJECT_DOCUMENT,
  DELETE_PROJECT_DOCUMENT,
  GET_PROJECT_DOCUMENT,
  GET_PROJECT_DOCUMENTS,
  TEST_PROJECT_DOCUMENT,
  UPDATE_PROJECT_DOCUMENT,
} from '@/@constants/mutations'

export default {
  namespaced: true,
  actions: {
    [GET_PROJECT_DOCUMENTS]: (_, { projectId, taskId }) => new Promise((resolve, reject) => {
      const params = {}
      params.fields_load = PROJECT_DOCUMENTS

      if (taskId) params['eq_projectTask-id'] = taskId

      axiosIns.get(`1/projects/${projectId}/documents`, { params })
        .then(res => resolve(res.data.data.items))
        .catch(err => reject(err))
    }),

    [GET_PROJECT_DOCUMENT]: (_, { projectId, documentId }) => new Promise((resolve, reject) => {
      const params = {}
      params.fields_load = PROJECT_DOCUMENTS

      axiosIns.get(`1/projects/${projectId}/documents/${documentId}`, { params })
        .then(res => resolve(res.data.data.item))
        .catch(err => reject(err))
    }),

    [DELETE_PROJECT_DOCUMENT]: (_, { projectId, documentId }) => new Promise((resolve, reject) => {
      axiosIns.delete(`1/projects/${projectId}/documents`, { data: [documentId] })
        .then(() => resolve())
        .catch(err => reject(err))
    }),

    [CREATE_PROJECT_DOCUMENT]: (_, payload) => new Promise((resolve, reject) => {
      axiosIns.post(`1/projects/${payload.project}/documents`, payload)
        .then(res => resolve(res.data.data.items[0].id))
        .catch(err => reject(err))
    }),

    [UPDATE_PROJECT_DOCUMENT]: (_, payload) => new Promise((resolve, reject) => {
      axiosIns.patch(`1/projects/${payload.project}/documents`, payload)
        .then(res => resolve(res.data.data.items[0].id))
        .catch(err => reject(err))
    }),

    [TEST_PROJECT_DOCUMENT]: (_, level = 0) => new Promise((resolve, reject) => {
      const params = {}
      params.fields_info = level

      axiosIns.get('1/projects/1/documents', { params })
        .then(res => resolve(res.data.data))
        .catch(err => reject(err))
    }),
  },
}
