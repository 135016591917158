var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex flex-column",staticStyle:{"gap":".5rem"}},[_c('h3',{staticClass:"text-primary",style:(!_vm.form.name && 'opacity: .5')},[_vm._v(" "+_vm._s(_vm.form.name || _vm.$t('calculators.New'))+" ")]),_c('div',{staticClass:"d-flex mb-1",staticStyle:{"column-gap":".5rem"}},[_c('b-button',{attrs:{"variant":"warning","size":"sm"},on:{"click":function($event){return _vm.$router.push('/calculator')}}},[_c('feather-icon',{attrs:{"icon":"ArrowLeftIcon"}}),_vm._v(" "+_vm._s(_vm.$t('Return'))+" ")],1),_c('b-button',{attrs:{"disabled":!_vm.form.name || !_vm.isSectionsValid || _vm.loading,"variant":"success","size":"sm"}},[_vm._v(" "+_vm._s(_vm.$t('Save'))+" ")])],1),_c('b-form-group',{attrs:{"label":_vm.$t('Name')}},[_c('validation-provider',{attrs:{"name":_vm.$t('Name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"label":_vm.$t('Name'),"placeholder":_vm.$t('calculators.New')},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('h4',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('Sections'))+" ")]),(_vm.form.sections.length)?_c('draggable',{staticClass:"list-group list-group-flat collapse-draggable mt-75",attrs:{"list":_vm.form.sections,"tag":"app-collapse","group":"sections"}},_vm._l((_vm.form.sections),function(section,i){return _c('app-collapse-item',{key:("section-" + (section.id)),attrs:{"title":section.name || ((_vm.$t('Section')) + " " + (i + 1))},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('b-form-group',{staticClass:"flex-grow-1 mb-0"},[_c('validation-provider',{attrs:{"name":_vm.$t('Name'),"rules":"required"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"label":_vm.$t('Name'),"placeholder":((_vm.$t('Section')) + " " + (i + 1))},model:{value:(section.name),callback:function ($$v) {_vm.$set(section, "name", $$v)},expression:"section.name"}}),_c('b-input-group-append',[_c('b-button',{staticClass:"btn-icon px-1",attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();section.options.length
                        ? _vm.showAlert('warning', _vm.$i18n.t('alerts.AreYouSure'), _vm.$i18n.t('alerts.WontbeAble'))
                          .then(function () { return _vm.form.sections.splice(i, 1); })
                          .catch(function () {})
                        : _vm.form.sections.splice(i, 1)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}})],1)],1)],1)],1)],1)]},proxy:true}],null,true)},[(section.options.length)?_c('draggable',{staticClass:"list-group list-group-flat mt-n25",attrs:{"list":section.options,"tag":"ul","group":("section-" + (section.id) + "-fields")}},_vm._l((section.options),function(option,j){return _c('b-list-group-item',{key:("section-" + (section.id) + "-field-" + (option.id)),staticClass:"cursor-move",attrs:{"tag":"li"}},[_c(option.type,{tag:"component",on:{"isValid":function($event){section.isValid = $event; _vm.sectionsValidation()},"rm":function($event){return section.options.splice(j, 1)}}})],1)}),1):_c('empty-content'),_c('div',{staticClass:"container mt-1"},[_c('sw-select',{scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('b-button',{staticClass:"btn-icon",attrs:{"variant":"success","disabled":!section.sectionType},on:{"click":function($event){section.options.push({ id: section.options.length, type: section.sectionType }); section.sectionType = ''}}},[_vm._v(" "+_vm._s(_vm.$t('AddField'))+" "),_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)]},proxy:true}],null,true)},[_c('v-select',{attrs:{"options":_vm.sectionTypes,"label":"name","get-option-label":function (type) { return _vm.$t(("calculators.sectionTypes." + type)); },"placeholder":_vm.$t('SelectFieldType')},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
                        var search = ref.search;
return [(search.length)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('NoData'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('TypeToSearch'))+" ")])]}}],null,true),model:{value:(section.sectionType),callback:function ($$v) {_vm.$set(section, "sectionType", $$v)},expression:"section.sectionType"}})],1)],1)],1)}),1):_c('empty-content'),_c('div',{staticClass:"w-100 px-3 py-2 border-primary text-primary rounded-lg d-flex align-items-center justify-content-center cursor-pointer mt-50",staticStyle:{"gap":".34rem"},on:{"click":function($event){_vm.form.sections.push(JSON.parse(JSON.stringify(Object.assign({}, {id: _vm.form.sections.length},
        _vm.emptySection))))}}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}}),_vm._v(" "+_vm._s(_vm.$t('AddNewSection'))+" ")],1),_c('sum',{staticClass:"mt-2"}),_c('div',{staticClass:"d-flex pb-2",staticStyle:{"column-gap":".5rem"}},[_c('b-button',{attrs:{"variant":"warning"},on:{"click":function($event){return _vm.$router.push('/calculator')}}},[_c('feather-icon',{attrs:{"icon":"ArrowLeftIcon"}}),_vm._v(" "+_vm._s(_vm.$t('Return'))+" ")],1),_c('b-button',{attrs:{"disabled":!_vm.form.name || !_vm.isSectionsValid || _vm.loading,"variant":"success"}},[_vm._v(" "+_vm._s(_vm.$t('Save'))+" ")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }