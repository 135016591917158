import Vue from 'vue'
import i18n from '@/libs/i18n'

export const showAlert = (
  icon = 'error',
  title = '',
  text = '',
  showCancel = true,
  okLabel = 'Confirm',
  cancelLabel = 'Cancel',
  denyButtonText = '',
) => new Promise((resolve, reject) => {
  Vue.swal({
    title,
    html: text,
    icon,
    showCancelButton: showCancel,
    showDenyButton: denyButtonText.length,
    confirmButtonText: i18n.t(okLabel),
    cancelButtonText: i18n.t(cancelLabel),
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-outline-danger ml-1',
      denyButton: 'btn btn-outline-warning ml-1',
    },

    buttonsStyling: false,
    denyButtonText: i18n.t(denyButtonText),
  }).then(result => {
    if (!result.isDismissed) resolve(result)
    else reject()
  }).catch(err => {
    reject(err)
  })
})

export const _ = null
