<template>
  <b-row>
    <b-col sm="12"
           md="4"
           lg="3"
    >
      <b-card v-if="user">
        <div class="d-flex justify-content-center align-items-center flex-column">
          <div class="position-relative mb-2">
            <b-avatar
              variant="light-primary"
              class="box-shadow-2"
              size="100"
              :src="user.avatar"
            />
            <b-avatar
              style="position:absolute; bottom: -5px; right: 0"
              variant="primary"
              size="30"
              class="cursor-pointer"
              button
              @click="onTargetAvatarInput"
            >
              <feather-icon icon="EditIcon" />
            </b-avatar>
            <input
              ref="newAvatar"
              type="file"
              style="display: none"
              accept="image/*"
              @change="onChangeAvatar"
            >
          </div>
          <h4>
            {{ getUserName(user) }}
          </h4>
        </div>

        <div class="divider text-primary divider-left-center">
          <div class="divider-text">
            {{ $t('BasicInformation') }}
          </div>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from 'vuex'
import { GET_SINGLE_USER } from '@/@constants/mutations'

export default {
  name: 'ProfileNew',
  computed: {
    ...mapGetters({
      user: 'users/getSingleUser',
      currentUser: 'auth/getCurrentUser',
    }),
  },
  mounted() {
    this.loading = true
    this.$store.dispatch(`users/${GET_SINGLE_USER}`, this.currentUser.id)
      .then(() => {
        this.loading = false
      })
      .catch(err => {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      })
  },
  methods: {
    onTargetAvatarInput() {
      this.$refs.newAvatar.click()
    },
    onChangeAvatar(event) {
      const fr = new FileReader()
      if (event.target.files[0].size > 25 * 1000) {
        this.showToast('danger', this.$i18n.tc('MoreThanKB', 1, { type: this.$i18n.t('SystemMoreThan.avatar'), size: 25 }))
        return
      }
      fr.onload = () => {
        this.imagePreview = fr.result
      }
      fr.readAsDataURL(event.target.files[0])
    },
  },
}
</script>

<style scoped>

</style>
