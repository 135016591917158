<template>
  <div
    class="text-start d-flex flex-column align-items-start"
    style="row-gap: 1.5rem"
  >
    <h1 class="mb-0 font-weight-bolder text-primary post-top-info-title">
      {{ subject || '–' }}
    </h1>

    <div
      class="d-flex align-items-center"
      style="column-gap: .5rem"
    >
      <avatar
        v-if="author"
        variant="light-primary"
        :user="author"
        :show-name="false"
        size="28"
      />

      <span
        v-if="createdAt.date"
        class="text-secondary"
        style="font-size: 1rem"
      >
        {{ $t(`news.status.${status}`) }}

        {{ createdAt.date.split(' ')[0] || '–' }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    subject: {
      type: [String, null],
      required: true,
    },
    createdAt: {
      type: [Object, String, null],
      required: true,
    },
    author: {
      type: [Object, null],
      required: true,
    },
    status: {
      type: String,
      required: false,
      default: 'SKETCH',
    },
  },

  data: () => ({
    STATUS: {
      SKETCH: 'SKETCH',
      PUBLISHED: 'PUBLISHED',
    },
  }),
}
</script>

<style lang="css">
.post-top-info-title {
  font-size: 1.5rem;

  line-height: 2rem;
}

@media only screen and (min-width: 768px) {
  .post-top-info-title {
    font-size: 1.5rem;

    line-height: 2.34rem;
  }
}
</style>
