<template>
  <div>
    <div
      class="d-flex flex-row align-items-center"
      style="column-gap: .5rem"
    >
      <avatar
        variant="light-primary"
        :user="author"
        :show-name="false"
        size="50"
      />

      <div
        class="d-flex flex-column"
        style="row-gap: .34rem"
      >
        <span class="h4 mb-0">
          {{ `${author.firstName} ${author.lastName}` }}
        </span>

        <span class="text-secondary h6 mb-0 font-weight-normal">
          {{ $t(`news.status.${status}`) }}

          {{ createdAt.date }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    createdAt: {
      type: Object,
      required: true,
    },
    author: {
      type: Object,
      required: true,
    },
    status: {
      type: String,
      required: false,
      default: 'SKETCH',
    },
  },
}
</script>
