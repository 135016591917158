var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.$route.path.includes('new_contact') ? 'div' : 'app-collapse-item',{tag:"component",attrs:{"title":_vm.$t('Campaigns'),"is-title":false},scopedSlots:_vm._u([{key:"subTitle",fn:function(){return [_c('div',{staticClass:"d-flex font-weight-bold flex-wrap align-items-center",staticStyle:{"gap":".5rem"}},[_vm._v(" "+_vm._s(_vm.$t('CampaignsSmsEmail'))+" "),_c('b-badge',{attrs:{"variant":"light-primary"}},[(_vm.isLoading)?_c('span',[_c('b-spinner',{attrs:{"small":""}})],1):_c('span',[_vm._v(_vm._s(_vm.pagination.totalRecords))])])],1)]},proxy:true}])},[_c('sw-table',{attrs:{"table-id":"14bc7e3a9c94449cbb95aecae517f560","show-columns":false,"show-search":false,"busy":_vm.isLoading,"fields":_vm.fields,"pagination":_vm.pagination},on:{"change-pagination":function($event){return Object.assign(_vm.pagination, $event)},"change-search-query":function($event){_vm.searchQuery = $event},"reload-content":_vm.loadData},scopedSlots:_vm._u([{key:"table",fn:function(){return [_c('b-table',{staticClass:"offer-table",attrs:{"items":_vm.items,"fields":_vm.fields,"striped":"","responsive":"","busy":_vm.isLoading,"show-empty":""},scopedSlots:_vm._u([{key:"head()",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(("" + label)))+" ")]}},{key:"cell(campaign)",fn:function(ref){
var item = ref.item;
return [(item.campaign)?_c('b-badge',{attrs:{"variant":"light-primary"}},[_vm._v(" "+_vm._s(item.campaign.name)+" ")]):_c('feather-icon',{attrs:{"icon":"MinusIcon"}})]}},{key:"cell(email)",fn:function(ref){
var item = ref.item;
return [(item.mail)?_c('b-button',{attrs:{"size":"sm","variant":"light-primary"},on:{"click":function($event){_vm.modalPreview = item.mail; _vm.modalShow = true}}},[_c('feather-icon',{attrs:{"icon":"EyeIcon"}})],1):_c('feather-icon',{attrs:{"icon":"MinusIcon"}})]}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [(item.status)?_c('b-badge',{attrs:{"variant":"light-primary"}},[_vm._v(" "+_vm._s(item.status === 'QUEUE' ? _vm.$t('InQueue') : _vm.$tc(("gEmail.status." + (item.status)), 1))+" ")]):_c('feather-icon',{attrs:{"icon":"MinusIcon"}})]}},{key:"cell(startAt)",fn:function(ref){
var item = ref.item;
return [(item.campaign && item.campaign.startAt)?_c('span',[_vm._v(" "+_vm._s(_vm.formatDate(item.campaign.startAt.date, { month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' }, _vm.$i18n.locale))+" ")]):_c('feather-icon',{attrs:{"icon":"MinusIcon"}})]}},{key:"cell()",fn:function(row){return [_c('table-default-cell',{attrs:{"field":row.field,"value":row.value}})]}},{key:"empty",fn:function(){return [_c('empty-content')]},proxy:true},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[_c('b-spinner',{staticClass:"align-middle",attrs:{"variant":"primary"}})],1)]},proxy:true}])})]},proxy:true}])}),_c('b-modal',{attrs:{"title":_vm.$t('Details'),"size":"lg","static":"","hide-footer":""},on:{"hide":function($event){_vm.modalShow = false; _vm.modalPreview = null}},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('div',{staticClass:"d-flex flex-wrap align-items-center",staticStyle:{"column-gap":".5rem"}},[_vm._v(" "+_vm._s(_vm.$t('Details'))+" ")])]},proxy:true}]),model:{value:(_vm.modalShow),callback:function ($$v) {_vm.modalShow=$$v},expression:"modalShow"}},[(_vm.modalPreview)?_c('div',[_c('p',{staticClass:"d-flex flex-column"},[_c('strong',{staticClass:"text-primary"},[_vm._v(_vm._s(_vm.modalPreview.subject))]),_c('span',{domProps:{"innerHTML":_vm._s(_vm.htmlFormat(_vm.modalPreview.contentHtml))}})]),(_vm.modalPreview.footer)?_c('p',{staticClass:"d-flex flex-column"},[_c('strong',[_vm._v(_vm._s(_vm.$t('Footer')))]),_c('span',{domProps:{"innerHTML":_vm._s((_vm.modalPreview.footer || '').replaceAll('\n', '<br>'))}})]):_vm._e()]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }