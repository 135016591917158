// eslint-disable-next-line import/no-cycle
import axiosIns from '@/libs/axios'
import * as fields from '@/@constants/fields'
import axios from 'axios'

// eslint-disable-next-line no-unused-vars
const prepareTasks = (tasks = []) => tasks.map(task => {
  const isWholeDay = (task.endAt && task.endAt.date.includes('23:59:59')) && (task.startAt && task.startAt.date.includes('00:00:00'))
  const item = {
    id: task.id,
    title: task.contactThreadTaskType?.name || '-',
    start: task.startAt.date,
    end: task.endAt.date,
    allDay: isWholeDay,
    extendedProps: {
      calendar: 'task',
      ...task,
    },
  }
  return item
})

// address: null
// assignedUser: {avatar: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4w…2MS03LjIzQS41LjUsMCwwLDEsNjQsNjMuMTJaIi8+PC9zdmc+', firstName: 'Wiktor', lastName: 'Kowalski', id: '1'}
// contactThread: {contact: {…}, name: 'AdamTest1NN_W', assignedUsers: Array(2), id: '3'}
// contactThreadTaskReport: null
// contactThreadTaskType: {name: 'E-mail', icon: 'MailIcon', isContentRequired: true, isAddressRequired: false, isReportRequired: false, …}
// content: "test"
// createdAt: {date: '2022-08-09 10:16:08.000000', timezone_type: 3, timezone: 'Europe/Warsaw'}
// createdBy: {avatar: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4w…2MS03LjIzQS41LjUsMCwwLDEsNjQsNjMuMTJaIi8+PC9zdmc+', firstName: 'Wiktor', lastName: 'Kowalski', id: '1'}
// endAt: {date: '2022-08-13 12:00:00.000000', timezone_type: 3, timezone: 'Europe/Warsaw'}
// files: []
// id: "8"
// outdated: false
// startAt: {date: '2022-08-10 12:00:00.000000', timezone_type: 3, timezone: 'Europe/Warsaw'}
// status: "O"

let source
const defaultState = {
  filters: {
    showUnsignedTasks: false,
    assignedTo: [],
    taskTypes: [],
    taskStatus: [],
  },
}

export default {
  namespaced: true,
  state: { ...defaultState },
  getters: {
    getFilters: state => state?.filters,
    getState: state => state,
  },
  actions: {
    // eslint-disable-next-line no-unused-vars,no-async-promise-executor,consistent-return
    GET_CALENDAR_TASKS: ({ commit, state = defaultState }, payload) => new Promise(async (resolve, reject) => {
      if (typeof source !== typeof undefined) source.cancel('Operation canceled due to new request.')

      source = axios.CancelToken.source()
      // eslint-disable-next-line no-unused-vars
      const { info } = payload
      const params = {}

      if (info) {
        params.gte_startAt = info.startStr
        params.lte_startAt = info.endStr
      }

      if (Array.isArray(state?.filters?.assignedTo) && state.filters.assignedTo.length) {
        params.eqArr_assignedUser = JSON.stringify(
          state.filters.assignedTo.map(
            user => (typeof user === 'object' ? user.id : user),
          ),
        )
      }

      if (state.filters && state.filters.showUnsignedTasks) {
        params.isNull_contactThread = 1
      }

      if (Array.isArray(state?.filters?.taskTypes) && state.filters.taskTypes.length) {
        params.eqArr_contactThreadTaskType = JSON.stringify(state.filters.taskTypes)
      }

      if (state.filters.taskStatus && state.filters.taskStatus.length) params.eq_status = state.filters.taskStatus

      params.orderBy = 'startAt.asc'
      params.fields_load = fields.TASKS

      const checkResponse = await axiosIns.get('1/contacts/threads/tasks', { params: { ...params, limit: 1 } })
      const { totalItemCount } = checkResponse.data.data

      const chunksCountToLoad = Math.ceil(totalItemCount / 400)
      const requests = new Array(chunksCountToLoad).fill(null)
      const chunksResponse = await Promise.all(requests.map((_, index) => axiosIns.get('1/contacts/threads/tasks', { params: { ...params, limit: 400, page: index + 1 } })))

      const allItems = chunksResponse.flatMap(p => p?.data?.data?.items) || []
      resolve(prepareTasks(allItems))

      // axiosIns.get('1/contacts/threads/tasks', { params, cancelToken: source.token })
      //   .then(res => {
      //     resolve(prepareTasks(res.data.data.items))
      //   })
      //   .catch(err => {
      //     reject(err)
      //   })
      //   .finally(() => {
      //     isInProgress = false
      //   })
    }),
  },
}
