<template>
  <div>
    <import-companies-modal
      :show="showImport"
      @close-modal="showImport = false; reloadContent()"
      @reload-content="reloadContent"
    />
    <!--    Section: Filters    -->
    <sw-filters
      :shorts="[
        {
          title: $tc('dashboard.tiles.YourAssignedContacts', 2),
          type: 'avatar',
          value: () => filters.assignedContacts,
          clear: () => { filters.assignedContacts = []; reloadContent() }
        },
        {
          title: $t('AssignedUsers'),
          type: 'avatar',
          value: () => filters.assignedUsers,
          clear: () => { filters.assignedUsers = []; reloadContent() }
        },
      ]"
      mt
    >
      <my-filters
        @filters="filterList => filters = filterList"
      />
    </sw-filters>

    <div class="my-50">
      <b-button
        variant="primary"
        size="sm"
        class="mr-50"
        @click="$router.push({ name: 'organizationsNew' })"
      >
        <feather-icon icon="PlusIcon" />
        {{ $t('Add') }}
      </b-button>

      <b-button
        v-if="checkRequiredPermissions([$roles.ORGANIZATION_DELETE])"
        variant="primary"
        size="sm"
        class="mr-50"
        :disabled="!selectedCompanies.length"
        @click="deleteOrganizations(null)"
      >
        <feather-icon icon="TrashIcon" />
        {{ $t('Delete') }} <span v-if="selectedCompanies.length">({{ selectedCompanies.length }})</span>
      </b-button>

      <b-button
        v-if="checkRequiredPermissions([$roles.CONTACT_ADD_IMPORT])"
        variant="primary"
        class="mr-50"
        size="sm"
        @click="showImport = true"
      >
        <feather-icon icon="UploadCloudIcon" />
        {{ $t('Import') }}
      </b-button>
    </div>

    <b-card>
      <my-table
        ref="table"
        :filters="filters"
        @select-items="selectedCompanies = $event"
        @delete-item="deleteOrganizations"
      />
    </b-card>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import ImportCompaniesModal from '@/views/modals/ImportCompaniesModal.vue'
import axiosIns from '@/libs/axios'
import MyFilters from './components/Filters.vue'
import MyTable from './components/Table.vue'

export default {
  components: {
    ImportCompaniesModal,
    MyFilters,
    MyTable,
  },
  setup() {
    const filters = ref([])

    return { filters }
  },
  data: () => ({
    showImport: false,
    selectedCompanies: [],

    deleteModal: {
      show: false,
      deleteWithAssignedContacts: false,
      deleteWithBaseContact: false,
    },
  }),
  methods: {
    async deleteOrganizations(organizationId = null) {
      const accepted = await this.showAlert('error', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('alerts.WontbeAble'))
      if (!accepted) return

      try {
        const payload = JSON.stringify(organizationId ? [organizationId] : this.selectedCompanies)
        await axiosIns.delete('1/organizations', { data: payload })

        this.showToast('success', this.$i18n.t('RemovedSuccessfully'))

        this.reloadContent()
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    allow() {
      return window.location.href.includes('kogen') || window.location.href.includes('192') || window.location.href.includes('d1')
    },
    reloadContent() {
      if (this.$refs.table) {
        this.$refs.table.loadData()
      }
    },
  },
}
</script>
