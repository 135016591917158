import * as fields from '@/@constants/fields'

// eslint-disable-next-line import/prefer-default-export
export const offers = [
  // OFFERS
  // pages/offer
  {
    path: '/offers',
    name: 'offr/offerList',
    component: () => import('@/pages/offer/List.vue'),
    meta: {
      autorize: true,
      pageTitle: 'OfferList',
    },
  },
  // OFFER-AGREEMENTS
  // pages/offer
  {
    path: '/agreements',
    name: 'agreements',
    component: () => import('@/pages/offer/Agreements.vue'),
    meta: {
      autorize: true,
      pageTitle: 'OfferAgreements',
    },
  },
  // SCHEDULES
  // pages/
  {
    path: '/schedules',
    name: 'schedules',
    component: () => import('@/pages/Schedules.vue'),
    meta: {
      autorize: true,
      pageTitle: 'Schedules',
    },
  },
  // SHOP
  // pages/shop
  {
    path: '/shop/products',
    name: 'shop/productList',
    component: () => import('@/pages/shop/product/List.vue'),
    meta: {
      autorize: true,
      pageTitle: 'ProductList',
    },
  },
  {
    path: '/shop/product',
    name: 'productEdit',
    props: true,
    component: () => import('@/pages/shop/product/Edit.vue'),
    meta: {
      autorize: true,
      pageTitle: 'ProductEdit',
      breadcrumb: [
        { active: false, to: '/shop', text: 'Shop' },
      ],
    },
  },
  {
    path: '/shop/product/:productId',
    name: 'productView',
    props: true,
    component: () => import('@/pages/shop/product/Edit.vue'),
    meta: {
      autorize: true,
      pageTitle: 'ProductEdit',
      breadcrumb: [
        { active: false, to: '/shop', text: 'Shop' },
        {
          active: true,
          request: {
            routeParam: 'productId', endpoint: '/shops/products/{productId}', fields: ['translations.name'], fields_load: fields.BASE_TRANSLATION_NAME,
          },
        },
      ],
    },
  },
  {
    path: '/shop/categories',
    name: 'shop/categoryList',
    component: () => import('@/pages/shop/CategoryList.vue'),
    meta: {
      autorize: true,
      pageTitle: 'CategoryList',
    },
  },
  {
    path: '/shop/attributes',
    name: 'shop/attributeList',
    component: () => import('@/pages/shop/AttributeList.vue'),
    meta: {
      autorize: true,
      pageTitle: 'AttributeList',
    },
  },
  {
    path: '/shop/checkout',
    name: 'shop/checkout',
    component: () => import('@/pages/shop/Checkout.vue'),
    meta: {
      autorize: true,
      pageTitle: 'Offer.Cart',
      breadcrumb: [
        { active: false, to: '/shop', text: 'Shop' },
      ],
    },
  },
]
