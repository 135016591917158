const extensions = [
  {
    title: 'TextFiles',
    icon: 'FileTextIcon',
    extensions: [
      'DOC', 'DOCX', 'LOG', 'ODT', 'MSG', 'PAGES', 'RTF', 'TEX', 'TXT', 'WPD', 'WPS', 'PDF',
    ],
  },
  {
    title: 'ImageFiles',
    icon: 'ImageIcon',
    extensions: [
      'BMP', 'DDS', 'GIF', 'JPG', 'PNG', 'PSD', 'PSPIMAGE', 'TGA', 'TIF', 'TIFF', 'YUV', 'AI', 'EPS', 'SVG', 'JPEG', 'WEBP',
    ],
  },
  {
    title: 'VideoFiles',
    icon: 'FilmIcon',
    extensions: [
      '3G2', '3GP', 'ASF', 'AVI', 'FLV', 'M4V', 'MOV', 'MP4', 'MPG', 'RM', 'SRT', 'SWF', 'VOB', 'WMV',
    ],
  },
  {
    title: 'CompressedFiles',
    icon: 'FolderIcon',
    extensions: [
      '7Z', 'CBR', 'RAR', 'GZ', 'ZIP', 'ZIPX', 'RPM',
    ],
  },
  {
    title: 'AudioFiles',
    icon: 'MusicIcon',
    extensions: [
      'AIF', 'IFF', 'M3U', 'M4A', 'MID', 'MP3', 'MPA', 'WAV', 'WMA',
    ],
  },
]

const fileIcon = extension => {
  // eslint-disable-next-line no-underscore-dangle
  const _extension = extension.toUpperCase()
  const type = extensions.filter(e => e.extensions.includes(_extension))
  return type[0]?.icon || 'FileIcon'
}

export default fileIcon
