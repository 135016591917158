<template>
  <div class="oversize-lg-modal">
    <b-modal
      :visible="show"
      size="lg"
      hide-footer
      @hidden="closeModal"
    >
      <b-row>
        <b-col sm="12">
          <b-alert
            show
            variant="primary"
          >
            <div class="alert-body">
              <div>
                <feather-icon icon="AlertCircleIcon" />
                {{ $t('FirstImportRow') }}
              </div>

              <div class="mt-25">
                <feather-icon icon="AlertCircleIcon" />
                <span v-html="$t('FunnelStatusFormat')" />
              </div>

              <div class="mt-25">
                <feather-icon icon="AlertCircleIcon" />
                <span v-html="$t('ContactImportFormat')" />
              </div>

              <div class="mt-25">
                <feather-icon icon="AlertCircleIcon" />
                <span v-html="$t('RequiredAssignedUsersFormat')" />
              </div>
            </div>
          </b-alert>

        </b-col>
        <b-col
          sm="12"
          lg="6"
        >
          <div>{{ $t('AvailableFields') }}</div>
          <draggable
            :list="fields"
            tag="div"
            draggable=".draggable-item"
            :group="{ name: 'fields', pull: 'clone', put:false }"
            class="d-flex flex-wrap mt-25"
          >
            <b-badge
              v-for="(listItem, index) in fields"
              :key="index"
              class="mr-25 mt-50 flex-grow-1 font-small-3"
              :class="!fieldsOrder.find(e => e.key === listItem.key) ? ['draggable-item', 'cursor-pointer'] : ''"
              :variant="!fieldsOrder.find(e => e.key === listItem.key) ? 'primary' : 'light-primary'"
              @click="pushItem(listItem)"
            >
              {{ $t(`${listItem.label}`) }}
            </b-badge>
          </draggable>
        </b-col>
        <!-- Selected Fields -->
        <b-col
          sm="12"
          lg="6"
          class="mt-1 mt-md-0"
        >
          <div>
            {{ $t('SelectedFields') }}
          </div>
          <draggable
            :list="fieldsOrder"
            tag="div"
            :group="{ name: 'fields' }"
            class="d-flex flex-wrap mt-25"
          >
            <b-badge
              v-for="(listItem, index) in fieldsOrder"
              :key="index"
              class="mr-25 mt-50 badge-item flex-grow-1 font-small-3 cursor-move"
            >
              <div class="d-flex justify-content-between align-items-center">
                <div class="bg-light p-25 text-secondary rounded">
                  {{ index + 1 }}
                </div>
                <div class="mx-25">
                  {{ $t(`${listItem.label}`) }}
                </div>
                <feather-icon
                  icon="XIcon"
                  class="cursor-pointer font-small-3"
                  @click="fieldsOrder.splice(index, 1)"
                />
              </div>
            </b-badge>
          </draggable>
        </b-col>
        <!-- Uploader -->
        <b-col sm="12">
          <drag-drop-upload
            :disabled="loading"
            :show-files="false"
            @onChangeFiles="changeFiles"
          />
        </b-col>
      </b-row>

      <b-row
        v-if="contactsCount"
        class="mt-1"
      >
        <b-col sm="12">
          <div class="d-flex justify-content-between align-items-center mb-1">
            <div>
              {{ $t('ContactsFromFile') }}
              <b-badge>{{ contactsCount }}</b-badge>
            </div>
          </div>
        </b-col>
        <b-col
          sm="12"
          class="table-padding-x-0"
        >
          <div class="d-flex justify-content-between">
            <sw-select class="ml-50">
              <v-select
                v-model="pagination.perPage"
                :options="[25, 50, 100, 250]"
                style="max-width: 100px"
                :clearable="false"
                class="per-page-selector w-100"
              />
            </sw-select>
            <!-- Pagination -->
            <b-pagination
              v-model="pagination.currentPage"
              :total-rows="pagination.totalRecords"
              :per-page="pagination.perPage"
              align="center"
            />
          </div>
          <validation-observer
            ref="validator"
            tag="div"
          >
            <b-table
              :items="importedContacts"
              :fields="contactFields"
              responsive
              :tbody-tr-class="getTrClasses"
              hover
              show-empty
              :total="pagination.totalRecords"
              :current-page="pagination.currentPage"
              :per-page="pagination.perPage"
            >
              <template #empty>
                <div class="text-center py-1 text-primary font-weight-bold">
                  <feather-icon
                    size="17"
                    icon="XCircleIcon"
                  />
                  {{ $t('NoData') }}
                </div>
              </template>
              <template #head()="{ label }">
                {{ label !== 'Action' ? $t(`${label}`) : $t('Action') }}
              </template>

              <template #cell(Action)="{ index }">
                <b-button
                  variant="flat-danger"
                  size="sm"
                  @click="$delete(importedContacts, index)"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </template>

              <template #cell()="{ value, field, index }">
                <b-form-input
                  :value="value"
                  size="sm"
                  @input="changeContactFieldValue($event, field, index)"
                />
              </template>
              <template #cell(funnelPoint)="{ value, field, index }">
                <div style="width: 300px">
                  <v-select
                    label="fullName"
                    :value="value"
                    class="select-size-sm"
                    :options="contactThreadFunnelTypesPoints"
                    @option:selected="changeContactFieldValue($event, field, index)"
                  >
                    <template #no-options>
                      {{ $t('NoOptions') }}
                    </template>
                  </v-select>
                </div>
              </template>
              <template #cell(name)="{ value, field, index }">
                <div style="width: 150px">
                  <b-form-input
                    :value="value"
                    size="sm"
                    @input="changeContactFieldValue($event, field, index)"
                  />
                </div>
              </template>
              <template #cell(contact)="{ value, field, index }">
                <div style="width: 200px">
                  <v-select
                    :value="value"
                    item-value="id"
                    :options="contactList"
                    label="firstName"
                    class="select-size-sm"
                    :filterable="false"
                    @input="changeContactFieldValue($event, field, index)"
                    @search="loadContacts"
                  >
                    <template slot="no-options">
                      {{ $t('typeToSearchContact') }}
                    </template>
                    <template slot="no-options">
                      {{ $t('typeToSearchContact') }}
                    </template>
                    <template #option="{ id, firstName, lastName }">
                      <avatar :user="{id, firstName, lastName}" />
                    </template>

                    <template #selected-option="{ id, firstName, lastName }">
                      <avatar
                        text-variant="light"
                        :user="{id, firstName, lastName}"
                      />
                    </template>
                  </v-select>
                </div>
              </template>
              <template #cell(assignedUsers)="{ value, field, index }">
                <div style="width: 200px">
                  <assigned-users
                    tag="div"
                    :value="value"
                    :is-multiple="true"
                    :classes="['select-size-sm']"
                    @input="changeContactFieldValue($event, field, index)"
                  />
                </div>
              </template>
            </b-table>
          </validation-observer>

          <div class="d-flex justify-content-between">
            <sw-select class="ml-50">
              <v-select
                v-model="pagination.perPage"
                :options="[25, 50, 100, 250]"
                style="max-width: 100px"
                :clearable="false"
                class="per-page-selector w-100"
              />
            </sw-select>
            <!-- Pagination -->
            <b-pagination
              v-model="pagination.currentPage"
              :total-rows="pagination.totalRecords"
              :per-page="pagination.perPage"
              align="center"
            />
          </div>
        </b-col>
        <b-col sm="12">
          <b-button
            :disabled="!importedContacts.length || importedContacts.some(e => getTrClasses(e).length)"
            size="sm"
            class="mr-25"
            variant="primary"

            @click="saveImportedContacts"
          >
            {{ $t('Import') }}
          </b-button>
          <b-button
            size="sm"
            variant="outline-secondary"
            @click="closeModal"
          >
            {{ $t('Cancel') }}
          </b-button>
        </b-col>
      </b-row>
      <b-overlay
        no-wrap
        :show="loading"
        spinner-variant="primary"
      />
    </b-modal>
  </div>
</template>

<script>
import { BAlert, BModal, BTable } from 'bootstrap-vue'
import draggable from 'vuedraggable'

import XLSX from 'xlsx'
import {
  GET_CONTACT_STATUSES,
  GET_SINGLE_USER,
  GET_THREAD_TAGS,
} from '@/@constants/mutations'
import AssignedUsers from '@/views/components/AssignedUsers.vue'
import vSelect from 'vue-select'
import axiosIns from '@/libs/axios'
import { ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { mapGetters } from 'vuex'
import { CONTACTS_SEARCH } from '@/@constants/fields'
import DragDropUpload from '../components/DragDropUpload.vue'

const fields = [
  { key: 'name', label: 'Name' },
  { key: 'funnelPoint', label: 'FunnelStatus' },
  { key: 'predictedValue', label: 'CustomerValue' },
  { key: 'assignedUsers', label: 'AssignedContactUsers' },
  { key: 'contact', label: 'Contact' },
]

export default {
  name: 'ImportContactsModal',
  components: {
    BAlert,
    BTable,
    BModal,
    vSelect,
    draggable,
    DragDropUpload,
    AssignedUsers,
    ValidationObserver,
  },
  props: {
    show: {
      default: true,
      type: Boolean,
    },
  },
  data: () => ({
    required,
    fields: [...fields],
    // fieldsOrder: [...fields],
    fieldsOrder: [
      { key: 'name', label: 'Name' },
      { key: 'funnelPoint', label: 'FunnelStatus' },
      { key: 'predictedValue', label: 'CustomerValue' },
      { key: 'assignedUsers', label: 'AssignedContactUsers' },
      { key: 'contact', label: 'Contact' },
    ],
    importedContacts: [],
    preview: false,
    contactsCount: 0,

    defaultTags: [],
    defaultContactStatus: '',
    contactStatuses: [],
    contactTags: [],
    additionalFieldsList: [],

    loading: false,
    sorter: {
      sortBy: 'createdAt',
      sortDesc: true,
    },
    pagination: {
      perPage: 100,
      currentPage: 1,
      totalRecords: 100,
    },
    contactList: [],
    contactThreadFunnelTypesPoints: [],
  }),
  computed: {
    ...mapGetters({
      user: 'auth/getCurrentUser',
    }),
    contactFields() {
      return [{ key: 'Action', label: 'Action' }, ...this.fields]
    },
  },
  watch: {
    importedContacts: {
      deep: true,
      handler(newValue, oldValue) {
        if (!newValue.length && oldValue.length) {
          this.importedContacts = oldValue
        }
      },
    },
  },
  // eslint-disable-next-line no-empty-function
  async mounted() {
    await this.loadThreadStatuses()
  },
  methods: {
    async loadThreadStatuses() {
      try {
        const resp = await axiosIns.get('1/settings/contactThreadFunnelTypes', { params: { fields_load: this.$fields.SETTINGS_FUNNEL_TYPE, orderBy: 'position.asc' } })

        if (resp?.data?.data?.items) {
          this.contactThreadFunnelTypesPoints = (resp.data.data.items || []).flatMap(type => type.contactThreadFunnelTypePoints.map(point => ({ ...point, typeId: type.id, fullName: `${type.name} - ${point.name}` })))
        }
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    closeModal() {
      this.importedContacts = []
      this.preview = false
      this.contactsCount = 0
      this.$emit('close-modal')
    },
    pushItem(field) {
      if (!this.isUsed(field)) this.fieldsOrder.push(field)
    },
    isUsed(field) {
      return this.fieldsOrder.includes(field)
    },
    changeFiles(files) {
      if (files.length) this.readFile(files[0])
    },
    async donwloadSampleXlSX() {
      try {
        const currentUser = await this.$store.dispatch(`users/${GET_SINGLE_USER}`, this.user.id)

        const wb = XLSX.utils.book_new()
        wb.Props = {
          Title: 'SampleXlsx',
        }

        wb.SheetNames.push('Sample')
        // const headers = this.fieldsOrder.map(fieldName => this.$i18n.t(`contact.${fieldName}`))
        const data = this.fieldsOrder.map(fieldName => {
          if (fieldName === 'assignedUsers') return currentUser.email

          return currentUser[fieldName]
        })

        const ws = XLSX.utils.aoa_to_sheet([[], data])
        wb.Sheets.Sample = ws

        XLSX.writeFile(wb, 'Sample.xlsx')
      } catch (err) {
        console.error(err)
      }
    },
    readFile(file) {
      const reader = new FileReader()
      reader.onload = e => {
        const ab = e.target.result
        const wb = XLSX.read(new Uint8Array(ab), { type: 'array' })
        const wsname = wb.SheetNames[0]
        const ws = wb.Sheets[wsname]
        const data = XLSX.utils.sheet_to_json(ws, {
          blankrows: false,
          header: 1,
          raw: true,
          cellDates: true,
          dateNF: 'yyyy-mm-dd',
          defval: '',
          range: 0,
          rawDates: true,
          cellNF: false,
        })

        this.prepareContactsFromXLS(data)
      }
      reader.readAsArrayBuffer(file)
    },
    async prepareContactsFromXLS(rows = []) {
      if (rows[0].includes(this.$i18n.t('contact.firstName')) || rows[0].includes(this.$i18n.t('contact.email'))) rows.shift()
      const contacts = []

      try {
        rows.forEach(contactRow => {
          if (Array.isArray(contactRow) && !contactRow.length) return

          const contact = {
            assignedUsers: [
              { ...this.$store.getters['auth/getCurrentUser'] },
            ],
          }

          fields.forEach(({ key }) => {
            const index = this.fieldsOrder.findIndex(e => e.key === key)

            if (key === 'funnelPoint') {
              contact[key] = this.resolveFunnelPoint(contactRow[index])
            } else if (key !== 'assignedUsers') {
              contact[key] = contactRow[index] || ''
            } else {
              contact[key] = contactRow[index] ? contactRow[index].replaceAll(' ', '').split(',') : []
            }
          })

          contacts.push(contact)
        })
      } catch (err) {
        console.error(err)
      }

      const assignedContacts = await this.resolveContacts(contacts)

      this.contactsCount = assignedContacts.length

      // this.$set(this, 'importedContacts', contacts)
      if (this.fieldsOrder.find(e => e.key === 'assignedUsers')) {
        await this.resolveAssignedUsers(assignedContacts)
          .then(res => {
            this.$set(this, 'importedContacts', res)
          })
      } else {
        this.$set(this, 'importedContacts', assignedContacts)
      }
    },
    resolveFunnelPoint(point) {
      if (point) {
        const existedStatus = this.contactThreadFunnelTypesPoints.find(e => e.name.toLowerCase() === point.toLowerCase().trim())
        if (existedStatus) return existedStatus
      }

      return null
    },
    // New
    resolveAssignedUsers(contacts) {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        let emails = []
        contacts.forEach(contact => {
          emails.push(...contact.assignedUsers)
        })
        emails = [...new Set(emails)]

        const params = {
          fields_load: this.$fields.USERS_SEARCH,
          'eqArr_mailAddress-mail': JSON.stringify(emails),
        }
        axiosIns.get('1/users', { params })
          .then(res => {
            const users = res.data.data.items
            contacts.forEach(contact => {
              const usersToAssign = []
              contact.assignedUsers.forEach(user => {
                const userToAssign = users.find(dbUser => dbUser.email === user)
                if (userToAssign) usersToAssign.push(userToAssign)
              })
              if (!usersToAssign.length) {
                if (!usersToAssign.some(user => user?.id === this.$store.getters['auth/getCurrentUser'].id)) usersToAssign.push({ ...this.$store.getters['auth/getCurrentUser'] })
              }
              // eslint-disable-next-line
              contact.assignedUsers = usersToAssign
            })
            resolve(contacts)
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            reject()
          })
      })
    },
    resolveContacts(contacts) {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        let emails = []
        contacts.forEach(contact => {
          emails.push(contact.contact)
        })
        emails = [...new Set(emails)]

        const params = {
          fields_load: this.$fields.CONTACTS_SEARCH,
          'eqArr_mailAddresses-mail': JSON.stringify(emails),
        }
        axiosIns.get('1/contacts', { params })
          .then(res => {
            const users = res.data.data.items
            contacts.forEach(contact => {
              // eslint-disable-next-line
              contact.contact = users.find(e => e.email === contact.contact) || null
            })
            resolve(contacts)
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            reject()
          })
      })
    },
    async loadContacts(search = '', isLoading = false) {
      this.contactList = await this.selectSearch(search, isLoading, CONTACTS_SEARCH, 'firstName,lastName', '1/contacts')
    },
    async loadContactStatuses() {
      this.loading = true

      try {
        const { data } = await this.$store.dispatch(`typeSettings/${GET_CONTACT_STATUSES}`)
        this.contactStatuses = data.items
        return data.items
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        return err
      } finally {
        this.loading = false
      }
    },
    async loadContactTags() {
      this.loading = true
      try {
        const { data } = await this.$store.dispatch(`typeSettings/${GET_THREAD_TAGS}`)
        this.contactTags = data.items
        return data.items
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        return err
      } finally {
        this.loading = false
      }
    },
    /* eslint-disable */
    changeContactFieldValue(value, { key }, index) {
      this.$set(this.importedContacts[index], key, value)
    },
      getTrClasses(item) {
        if (!item?.contact || !item?.funnelPoint || !item.name) return ['border-left border-danger border-5']
        return []
      },
    /* eslint-disable */
    async saveImportedContacts() {

      const newOptions = {}
      let contacts = JSON.parse(JSON.stringify(this.importedContacts))
      const payload = []
      const payloadThreads = []

      contacts.forEach(contact => {
        // {
        //   "contactThreadFunnelTypePoint": "1007",
        //     "status": "OPEN"
        // }

        payload.push({
          name: contact.name,
          predictedValue: Number(contact.predictedValue || 0) || 0,
          predictedValueCurrency: 'PLN',
          assignedLeadUser: this.getObjectId(contact.assignedUsers[0]),
          assignedUsers: (contact.assignedUsers || []).map(user => this.getObjectId(user)),
          contact: this.getObjectId(contact.contact),
        })

        payloadThreads.push({
          contactThreadFunnelTypePoint: this.getObjectId(contact.funnelPoint),
          status: 'OPEN',
        })
      })

      if (!payload.length) return

      const resp = await this.$store.dispatch('importer/UPLOAD_IMPORTED_CONTACTS', { data: payload, uploadUrl: '1/contacts/threads', importerLabel: 'Contacts' })
      const items = resp.map(e => e.data.data.items.map(a => a.id)).flat()

      await this.$store.dispatch('importer/UPLOAD_IMPORTED_CONTACTS', { data: items.map((id, index) => ({ contactThread: id, ...payloadThreads[index] })), uploadUrl: '1/contacts/threads/funnelProcesses', importerLabel: 'Contacts' })

      this.showToast('success', this.$i18n.tc('ImportedLines', items.length, { linesCount: items.length }))
      this.closeModal()
      // this.$store.dispatch(`contacts/${CREATE_CONTACT}`, contacts)
      //   .then(() => {
      //     this.showToast('success', this.$i18n.t('success.contactUpdated'))
      //     this.$emit('reload-content')
      //   })
      //   .catch(err => {
      //     this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      //   })
    },
  },
}
</script>

<style lang="scss">
.badge-item {
  transition: all 1s
}

.table-padding-x-0 {
  td, th {
    padding: 0.6rem 0.5rem !important;
  }
}

[dir=ltr] .modal-xl {
  margin-left: 15% !important;
  margin-right: 15% !important;
}
</style>
