<template>
  <div>
    <b-button
      variant="primary"
      size="sm"
      class="mb-1"
      @click="onSaveSettings"
    >
      {{ $t('Save') }}
    </b-button>

    <!-- INTEGRATIONS - Autenti -->
    <b-card>
      <template #header>
        <h4 class="d-flex align-items-center">
          Autenti

          <b-form-checkbox
            v-model="autentiEnabled"
            class="ml-1"
            checked="true"
            name="check-button"
            variant="primary"
            switch
            inline
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </h4>
      </template>

      <b-row>
        <b-col
          lg="6"
        >
          <b-alert
            variant="primary"
            show
            class="p-1"
          >
            <div>
              {{ $t('SignDocumentsOnline') }}
            </div>

            <div class="mt-50 d-flex flex-column">
              <b-button
                size="sm"
                target="_blank"
                class="w-100"
                variant="primary"
                href="https://meetings.hubspot.com/lukasz-zakrzewski"
              >
                {{ $t('AutentiMeeting') }}
              </b-button>

              <div class="d-flex justify-content-center">
                <b-button
                  size="sm"
                  target="_blank"
                  class="mt-25"
                  variant="flat-primary"
                  href="https://account.autenti.com/register?refId=4bsystems"
                >
                  {{ $t('CreateFreeAccount') }}
                </b-button>
              </div>
            </div>
          </b-alert>

          <b-form-group>
            <b-form-checkbox
              v-model="autentiTest"
              checked="true"
              name="check-button"
              variant="primary"
              switch
              inline
            >
              {{ autentiTest ? $t('TestMode') : $t('ProductionMode') }}
            </b-form-checkbox>
          </b-form-group>

          <b-form-group :label="$t('APIKey')">
            <b-form-input
              v-model="autentiToken"
              :disabled="!autentiEnabled"
              type="password"
            />
          </b-form-group>
          <b-form-group :label="$t('Email')">
            <b-form-input
              v-model="autentiEmail"
              :disabled="!autentiEnabled"
              type="text"
            />
          </b-form-group>
          <b-form-group :label="$t('contact.companyName')">
            <b-form-input
              v-model="autentiOrganizationName"
              :disabled="!autentiEnabled"
              type="text"
            />
          </b-form-group>
          <b-form-group :label="$t('OrganizationDetails.vatId')">
            <b-form-input
              v-model="autentiOrganizationTaxNumber"
              :disabled="!autentiEnabled"
              type="text"
            />
          </b-form-group>
          <b-form-group :label="$t('OrganizationPosition')">
            <b-form-input
              v-model="autentiOrganizationJobTitle"
              :disabled="!autentiEnabled"
              type="text"
            />
          </b-form-group>
          <b-overlay
            :show="loading"
            no-wrap
            spinner-variant="primary"
          />
        </b-col>

        <b-col
          lg="6"
        >
          <div class="embed-responsive embed-responsive-16by9 h-100 rounded">
            <iframe
              class="embed-responsive-item"
              src="https://www.youtube.com/embed/3HGVO3sbinc"
              allowfullscreen
            />
          </div>
        </b-col>
      </b-row>
    </b-card>

    <b-card>
      <template #header>
        <h4 class="d-flex align-items-center">
          PlantNet

          <b-form-checkbox
            v-model="imageRecognitionPlantNetEnabled"
            class="ml-1"
            checked="true"
            name="check-button"
            variant="primary"
            switch
            inline
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </h4>
      </template>

      <b-row>
        <b-col>
          <sw-select :name="$t('DefaultLanguage')">
            <v-select
              v-model="imageRecognitionPlantNetDefaultLanguage"
              :clearable="false"
              :options="['pl', 'en', 'de']"
            >
              <template #no-options>
                {{ $t('NoOptions') }}
              </template>
              <template #option="{ label }">
                {{ $t(`langs.${label}`) }}
              </template>
              <template #selected-option="{ label }">
                {{ $t(`langs.${label}`) }}
              </template>
            </v-select>
          </sw-select>
          <b-overlay
            :show="loading"
            no-wrap
            spinner-variant="primary"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-button
      variant="primary"
      size="sm"
      @click="onSaveSettings"
    >
      {{ $t('Save') }}
    </b-button>
  </div>
</template>

<script>
import { BAlert } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { GET_PRIVATE_SETTINGS, SAVE_SETTINGS } from '@/@constants/mutations'
import vSelect from 'vue-select'

export default {
  name: 'Integrations',
  components: {
    vSelect,
    BAlert,
  },
  data() {
    return {
      loading: false,
      autentiToken: '',
      autentiEmail: '',
      autentiEnabled: false,
      autentiTest: false,
      autentiOrganizationName: '',
      autentiOrganizationTaxNumber: '',
      autentiOrganizationJobTitle: '',

      imageRecognitionPlantNetEnabled: false,
      imageRecognitionPlantNetDefaultLanguage: 'pl',
    }
  },
  computed: {
    ...mapGetters({
      system: 'system/getSettings',
    }),
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.loading = true
      this.$store.dispatch(`system/${GET_PRIVATE_SETTINGS}`)
        .then(res => {
          this.$set(this, 'autentiToken', res.data.item?.autentiToken || '**************************')
          this.$set(this, 'autentiEmail', res.data.item?.autentiEmail || '')
          this.$set(this, 'autentiEnabled', res.data.item?.autentiEnabled || false)
          this.$set(this, 'autentiTest', res.data.item?.autentiTest || false)
          this.$set(this, 'autentiOrganizationName', res.data.item?.autentiOrganizationName || '')
          this.$set(this, 'autentiOrganizationTaxNumber', res.data.item?.autentiOrganizationTaxNumber || '')
          this.$set(this, 'autentiOrganizationJobTitle', res.data.item?.autentiOrganizationJobTitle || '')

          this.$set(this, 'imageRecognitionPlantNetEnabled', res.data.item?.imageRecognitionPlantNetEnabled || false)
          this.$set(this, 'imageRecognitionPlantNetDefaultLanguage', res.data.item?.imageRecognitionPlantNetDefaultLanguage || 'pl')

          this.loading = false
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          this.loading = false
        })
    },
    onSaveSettings() {
      this.loading = true
      const payload = {
        autentiToken: this.autentiToken,
        autentiEmail: this.autentiEmail,
        autentiTest: this.autentiTest,
        autentiEnabled: this.autentiEnabled,
        autentiOrganizationName: this.autentiOrganizationName,
        // eslint-disable-next-line radix
        autentiOrganizationTaxNumber: this.autentiOrganizationTaxNumber,
        autentiOrganizationJobTitle: this.autentiOrganizationJobTitle,
        imageRecognitionPlantNetEnabled: this.imageRecognitionPlantNetEnabled,
        imageRecognitionPlantNetDefaultLanguage: this.imageRecognitionPlantNetDefaultLanguage,
      }
      if (this.autentiToken.includes('*')) delete payload.autentiToken

      this.$store.dispatch(`system/${SAVE_SETTINGS}`, { privateData: payload })
        .then(() => {
          this.loading = false
          this.showToast('success', this.$i18n.t('ChangesSaved'))
          this.loadData()
        })
        .catch(err => {
          this.loading = false
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
  },
}
</script>

<style scoped>

</style>
