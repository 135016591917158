<template>
  <div class="custom-grid">
    <div
      v-for="(row, rowIndex) in grid"
      :key="`row-${rowIndex}`"
      class="custom-row"
    >
      <div
        v-for="(cell, colIndex) in row"
        :key="`cell-${rowIndex}-${colIndex}`"
        class="custom-cell"
        :class="{ selected: isSelected(rowIndex, colIndex) }"
        @mouseover="handleHover(rowIndex, colIndex)"
        @mousedown="startSelection(rowIndex, colIndex)"
        @mouseup="endSelection"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      grid: Array(5)
        .fill(null)
        .map(() => Array(5).fill(null)), // Tworzenie siatki 5x5
      startCell: null, // Początkowa komórka zaznaczenia
      endCell: null, // Końcowa komórka zaznaczenia
      selectionActive: false, // Flaga zaznaczenia
    }
  },
  computed: {
    selectedRows() {
      if (!this.startCell || !this.endCell) return 0
      return Math.abs(this.endCell.row - this.startCell.row) + 1
    },
    selectedCols() {
      if (!this.startCell || !this.endCell) return 0
      return Math.abs(this.endCell.col - this.startCell.col) + 1
    },
  },
  methods: {
    startSelection(row, col) {
      this.startCell = { row, col }
      this.endCell = { row, col }
      this.selectionActive = true
    },
    handleHover(row, col) {
      if (this.selectionActive) {
        this.endCell = { row, col }
      }
    },
    endSelection() {
      this.selectionActive = false
      this.emitSelection()
    },
    isSelected(row, col) {
      if (!this.startCell || !this.endCell) return false
      const rowStart = Math.min(this.startCell.row, this.endCell.row)
      const rowEnd = Math.max(this.startCell.row, this.endCell.row)
      const colStart = Math.min(this.startCell.col, this.endCell.col)
      const colEnd = Math.max(this.startCell.col, this.endCell.col)
      return row >= rowStart && row <= rowEnd && col >= colStart && col <= colEnd
    },
    emitSelection() {
      this.$emit('selection', {
        rows: this.selectedRows,
        cols: this.selectedCols,
      })
    },
  },
}
</script>

<style scoped>
.custom-grid {
    display: flex;
    flex-direction: column; /* Układ pionowy dla wierszy */
    gap: 2px; /* Odstęp między wierszami */
}

.custom-row {
    display: flex; /* Układ poziomy dla kolumn w wierszu */
    gap: 2px; /* Odstęp między komórkami */
}

.custom-cell {
    width: 30px; /* Szerokość komórki */
    height: 30px; /* Wysokość komórki */
    border: 1px solid #ccc; /* Obrys komórki */
    background-color: #f9f9f9; /* Domyślne tło */
    cursor: pointer; /* Wskaźnik kursora */
}
.custom-cell.selected {
    background-color: rgba(var(--theme-color-primary), .6) !important;
    border-color: rgba(var(--theme-color-primary), .6) !important;
}
</style>
