<template>
  <div v-if="item">
    <sw-select :name="$t('Creator')">
      <v-select
        v-model="item.trainingTrainer"
        :clearable="false"
        label="title"
        :options="trainers"
        :reduce="option => option.id"
      >
        <template #no-options>
          {{ $t('NoOptions') }}
        </template>
        <template #option="{ name, avatar }">
          <b-avatar
            size="20"
            :src="`data:image/webp;base64,${avatar ? avatar.preview : ''}`"
            :text="name[0].toUpperCase()"
          />
          <span class="pl-50">{{ name }}</span>
        </template>
        <template #selected-option="{ name, avatar }">
          <b-avatar
            size="20"
            :src="`data:image/webp;base64,${avatar ? avatar.preview : ''}`"
            :text="name[0].toUpperCase()"
          />
          <span class="pl-50">{{ name }}</span>
        </template>
      </v-select>
    </sw-select>

    <b-form-group :label="$t('Name')">
      <b-form-input v-model="item.name" />
    </b-form-group>

    <b-form-group :label="$t('EditWebsite')">
      <b-input-group class="input-group-merge">
        <b-input-group-prepend :is-text="true">
          <feather-icon icon="GlobeIcon" />
        </b-input-group-prepend>
        <b-form-input v-model="item.url" />
      </b-input-group>
    </b-form-group>

    <b-button
      variant="primary"
      size="sm"
      :disabled="loading || !item.name"
      @click="saveItem"
    >
      {{ $t('Save') }}
    </b-button>
  </div>
  <div
    v-else
    class="d-flex justify-content-center"
  >
    <b-spinner
      variant="primary"
      class="m-2"
    />
  </div>
</template>

<script>
import axiosIns from '@/libs/axios'
import keysCuter from '@/helpers/keysCuter'
import { BInputGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'TrainingForm',
  components: {
    vSelect,
    BInputGroup,
  },
  props: {
    editedItem: {
      default: null,
      required: true,
      type: Object,
    },
  },
  data: () => ({
    item: null,
    loading: false,
    trainers: [],
  }),
  computed: {
    isEditMode() {
      return Boolean(this.item?.id)
    },
  },
  async mounted() {
    this.loading = true
    await this.loadTrainers()

    if (this.editedItem?.id) {
      await this.loadItem()
    } else {
      this.item = this.editedItem
    }
    this.loading = false
  },
  methods: {
    async loadTrainers() {
      this.trainerLoading = true
      try {
        const params = { fields_load: 'TRAINERS' }
        const response = await axiosIns.get('1/trainingTrainers', { params })

        if (response.data?.data?.items) this.trainers = response.data.data.items
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.trainerLoading = false
      }
    },
    async loadItem() {
      const { id } = this.editedItem

      try {
        const params = {
          fields_load: 'TRAININGS_FULL',
          eq_id: id,
        }
        const resp = await axiosIns.get('1/trainings', { params })
        if (resp.data.data.items) {
          [this.item] = resp.data.data.items
        }
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },

    closeModal(toReturn) {
      this.$emit('close-modal', toReturn)
      this.item = null
    },

    async saveItem() {
      this.loading = true
      try {
        let payload = { ...this.item }

        payload.trainingTrainer = this.getObjectId(payload.trainingTrainer)
        payload.url = payload.url
          .replaceAll('youtube.com', 'youtube-nocookie.com')
          .replaceAll('watch?v=', 'embed/')

        payload = keysCuter(payload, ['trainer'])
        const resp = await axiosIns({
          url: '1/trainings',
          method: payload.id ? 'PATCH' : 'POST',
          data: payload,
        })

        this.closeModal(resp)
        this.showToast('success', this.$i18n.t('success.contactUpdated'))
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>

</style>
