<template>
  <validation-observer
    ref="linkThreadForm"
    v-slot="{ invalid }"
    tag="form"
  >
    <validation-provider
      v-slot="{ errors }"
      :name="$t('SelectContacts')"
    >
      <b-form-group>
        <sw-select :name="$t('SelectContacts')">
          <v-select
            id="offer-thread-1"
            v-model="threads"
            :options="threadList"
            label="name"
            :filterable="false"
            :selectable="(thread) => thread.offersTotal < system.contactThreadOffersLimit"
            :state="errors.length > 0 ? false:null"
            :placeholder="$t('SelectContacts')"
            @search="loadThreads"
          >
            <template #no-options="{ search }">
              <span v-if="search.length">
                {{ $t('NoData') }}
              </span>
              <span v-else>
                {{ $t('TypeToSearch') }}
              </span>
            </template>
            <template #option="{ name, contact, offersTotal }">
              <div class="d-flex align-items-center">
                <avatar
                  :user="contact"
                  class="mr-25"
                />  - {{ name }}
                <feather-icon
                  v-if="offersTotal >= system.contactThreadOffersLimit"
                  v-b-tooltip
                  icon="AlertCircleIcon"
                  class="ml-50"
                  :title="$tc('ThreadOfferCountIsToBig', system.contactThreadOffersLimit, { limit: system.contactThreadOffersLimit })"
                />
              </div>
            </template>

            <template #selected-option="{ name, contact }">
              <div class="d-flex">
                <avatar
                  :user="contact"
                  class="mr-25"
                /> – {{ name }}
              </div>
            </template>
          </v-select>
        </sw-select>

        <small class="text-danger">{{ errors[0] }}</small>
      </b-form-group>
    </validation-provider>

    <!--      Button: Save      -->
    <b-button
      size="sm"
      variant="primary"
      :disabled="invalid || isLoading"
      @click="save"
    >
      {{ $t('Save') }}
    </b-button>

    <b-overlay
      no-wrap
      :show="isLoading"
      spinner-variant="primary"
    />
  </validation-observer>
</template>

<script>
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { THREADS_SEARCH } from '@/@constants/fields'
import { mapGetters } from 'vuex'

export default {
  components: {
    vSelect,
    ValidationObserver,
    ValidationProvider,
  },

  data: () => ({
    isLoading: false,

    threads: [],
    threadList: [],
  }),

  computed: {
    ...mapGetters({
      system: 'system/getSettings',
    }),
  },

  methods: {
    async loadThreads(search, isLoading) {
      this.threadList = await this.selectSearch(search, isLoading, THREADS_SEARCH, 'name,contact-firstName,contact-lastName', '1/contacts/threads')
    },

    save() {
      // console.log(true)
    },
  },
}
</script>
