<template>
  <div>
    <b-button
      :id="`p-${targetId}`"
      v-b-tooltip.hover.v-primary
      variant="primary"
      class="btn-icon"
      :title="$t('funnel.ChangeStatus')"
      :class="{ 'btn-sm p-25': small }"
      :disabled="!isEdit"
      @click="mMain = true"
    >
      <feather-icon icon="MoveIcon" />
    </b-button>

    <b-modal
      v-model="mMain"
      hide-footer
      :title="$t('ChooseStatus')"
      tabindex="none"
      size="lg"
      modal-class="my-overlay"
    >
      <b-form-group v-if="statusList.length">
        <b-form-radio-group
          v-model="selectedStatus"
          text-field="name"
          value-field="id"
          class="demo-inline-spacing"
          name="radio-inline"
          @change="saveStatus"
        >
          <b-form-radio
            v-for="item in statusList"
            :key="item.id"
            :value="item.id"
          >
            {{ item.name | textIsland(18, '…') }}
          </b-form-radio>
        </b-form-radio-group>
      </b-form-group>

      <div
        v-else
        class="p-2 text-center text-primary"
      >
        <feather-icon icon="XCircleIcon" />

        {{ $t('NoData') }}
      </div>

      <b-overlay
        no-wrap
        :show="isLoading"
        spinner-variant="primary"
      />
    </b-modal>
  </div>
</template>

<script>
import { BFormRadio, BFormRadioGroup, VBTooltip } from 'bootstrap-vue'
import {
  GET_PROJECT_STATUSES,
  GET_PROJECT_TASK_STATUSES,
  UPDATE_PROJECT,
  UPDATE_PROJECT_TASK,
} from '@/@constants/mutations'

export default {
  components: {
    BFormRadio,
    BFormRadioGroup,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    targetId: {
      type: Number,
      required: true,
    },
    statusId: {
      type: Number,
      required: true,
    },
    projectId: {
      type: Number,
      required: false,
      default: null,
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
    isTask: {
      type: Boolean,
      required: false,
      default: false,
    },
    isEdit: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ['update'],
  data: () => ({
    isLoading: false,

    mMain: false,

    selectedStatus: 37,
    statusList: [],
  }),
  watch: {
    statusId: {
      deep: true,
      handler() { this.loadStatus() },
    },
    mMain(n) {
      if (n) this.loadStatus()
    },
  },
  mounted() { this.ini() },
  methods: {
    ini() {
      this.loadStatuses()

      this.loadStatus()
    },

    async loadStatus() {
      this.selectedStatus = this.statusId

      await this.$nextTick()
    },

    loadStatuses() {
      this.isLoading = true

      const type = !this.isTask ? `projectStatuses/${GET_PROJECT_STATUSES}` : `taskStatuses/${GET_PROJECT_TASK_STATUSES}`

      this.$store.dispatch(type)
        .then(res => { this.statusList = res })

        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })

        .finally(() => { this.isLoading = false })
    },

    saveStatus() {
      const payload = this.getStatusPayload()

      if (payload) this.sendStatus(payload)
    },

    getStatusPayload() {
      const payload = {
        id: String(this.targetId),
      }

      payload[!this.isTask ? 'projectStatus' : 'projectTaskStatus'] = this.selectedStatus

      if (this.projectId) payload.project = String(this.projectId)

      return payload
    },

    sendStatus(payload) {
      this.isLoading = true

      const type = !this.isTask ? `projects/${UPDATE_PROJECT}` : `tasks/${UPDATE_PROJECT_TASK}`

      this.$store.dispatch(type, payload)
        .then(() => {
          if (!this.isTask) {
            this.showToast('success', this.$i18n.t('ProjectUpdated'))
          } else {
            this.showToast('success', this.$i18n.t('TaskUpdated'))
          }

          this.mMain = false
          this.$emit('update')
        })

        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })

        .finally(() => { this.isLoading = false })
    },
  },
}
</script>
