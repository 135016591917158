export const URGENT = 'URGENT'
export const HIGH = 'HIGH'
export const NORMAL = 'NORMAL'
export const LOW = 'LOW'

// eslint-disable-next-line import/prefer-default-export
export const taskPriorityList = [
  { value: URGENT, label: 'PriorityValues.Urgent', color: 'danger' },
  { value: HIGH, label: 'PriorityValues.High', color: 'warning' },
  { value: NORMAL, label: 'PriorityValues.Normal', color: 'primary' },
  { value: LOW, label: 'PriorityValues.Low', color: 'secondary' },
]

export const taskPriorityByValue = value => taskPriorityList.find(type => type.value === value) || { label: '', value: null, color: '' }
