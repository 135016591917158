<template>
  <div class="email-app-details">
    <!-- Email Header -->
    <div class="email-detail-header">
      <!-- Header: Left -->
      <div class="email-header-left d-flex align-items-center">
        <span class="go-back mr-1">
          <feather-icon
            :icon="$store.state.appConfig.isRTL ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
            size="20"
            class="align-bottom"
            @click="$emit('close-email-view')"
          />
        </span>

        <h4 class="email-subject mb-0">
          {{ emailViewData.subject }}
        </h4>
      </div>

      <!-- Header: Right -->
      <div class="email-header-right ml-2 pl-1 d-flex align-items-center">
        <!-- Mark Starred -->
        <!--        <feather-icon-->
        <!--          v-b-tooltip.hover.v-primary-->
        <!--          icon="StarIcon"-->
        <!--          size="17"-->
        <!--          class="cursor-pointer"-->
        <!--          :class="{ 'text-warning fill-current': emailViewData.isStarred }"-->
        <!--          :title="$t('AddToFavorites')"-->
        <!--          @click="$emit('toggle-email-starred')"-->
        <!--        />-->

        <!-- Assign To Thread -->
        <feather-icon
          v-if="!emailViewData.conversation || !emailViewData.conversation.contactThread"
          v-b-tooltip.hover.v-primary
          :title="$t('AssignToThread')"
          icon="LinkIcon"
          size="17"
          class="cursor-pointer ml-1"
          @click.stop="$emit('assign-to-thread')"
        />
        <b-badge
          v-else
          v-b-tooltip.hover.v-primary
          variant="light-primary"
          :title="`${$t('AssignedTo')} ${emailViewData.conversation.contactThread.name}`"
        >
          <div
            class="d-flex align-items-start"
            style="column-gap: .34rem"
          >
            <feather-icon icon="LinkIcon" />

            <span>
              {{ `${ emailViewData.conversation.contactThread.contact.firstName } ${ emailViewData.conversation.contactThread.contact.lastName }` }}
              <feather-icon icon="ArrowRightIcon" />
              {{ emailViewData.conversation.contactThread.name }}
            </span>
          </div>
        </b-badge>

        <feather-icon
          v-b-tooltip
          size="17"
          :title="$t('Answer')"
          icon="CornerUpLeftIcon"
          class="cursor-pointer ml-1"
          @click="$emit('open-action-form', { type: 'REPLAY', deliveredAt: formatDate((emailViewData.deliveredAt ? emailViewData.deliveredAt.date : ( emailViewData.createdAt ? emailViewData.createdAt.date : '' )), { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' }, $i18n.locale), contactThread: emailViewData.conversation ? emailViewData.conversation.contactThread : null, content: emailViewData.contentHtml, subject: emailViewData.subject, imap: emailViewData.userMailbox, threadId: emailViewData.threadId, toAddresses: (emailViewData.toAddresses || []).map(e => ({ email: e.mail })), fromMail: emailViewData.fromMail })"
        />
        <feather-icon
          v-b-tooltip
          size="17"
          :title="$t('Forward')"
          icon="CornerUpRightIcon"
          class="cursor-pointer ml-1"
          @click="$emit('open-action-form', { type: 'FORWARD', contactThread: emailViewData.conversation ? emailViewData.conversation.contactThread : null, content: emailViewData.contentHtml, subject: emailViewData.subject, imap: emailViewData.userMailbox })"
        />

        <!-- Move email to folder -->
        <!--        <b-dropdown-->
        <!--          v-b-tooltip.hover.v-primary-->
        <!--          variant="link"-->
        <!--          no-caret-->
        <!--          toggle-class="p-0"-->
        <!--          class="ml-75"-->
        <!--          right-->
        <!--          :title="$t('Move')"-->
        <!--        >-->
        <!--          <template #button-content>-->
        <!--            <feather-icon-->
        <!--              icon="FolderIcon"-->
        <!--              size="17"-->
        <!--              class="align-middle text-body"-->
        <!--            />-->
        <!--          </template>-->

        <!--          <b-dropdown-item @click="$emit('move-to-folder', 'INBOX.Spam')">-->
        <!--            <feather-icon icon="InfoIcon" />-->
        <!--            <span class="align-middle ml-50">-->
        <!--              {{ $t('Spam') }}-->
        <!--            </span>-->
        <!--          </b-dropdown-item>-->

        <!--          <b-dropdown-item-->
        <!--            v-show="$route.params.folder !== 'trash'"-->
        <!--            @click="$emit('move-to-folder', 'INBOX.Trash')"-->
        <!--          >-->
        <!--            <feather-icon icon="TrashIcon" />-->
        <!--            <span class="align-middle ml-50">-->
        <!--              {{ $t('Trash') }}-->
        <!--            </span>-->
        <!--          </b-dropdown-item>-->
        <!--        </b-dropdown>-->

        <!-- Mark Un-Read -->
        <!--        <feather-icon-->
        <!--          v-b-tooltip.hover.v-primary-->
        <!--          icon="CornerUpLeftIcon"-->
        <!--          class="ml-75 cursor-pointer"-->
        <!--          size="17"-->
        <!--          :title="$t('MarkAsUnread')"-->
        <!--          @click="$emit('mark-email-unread')"-->
        <!--        />-->

        <!-- More -->
        <!--        <b-dropdown-->
        <!--          variant="link"-->
        <!--          no-caret-->
        <!--          toggle-class="p-0"-->
        <!--          class="ml-75"-->
        <!--          right-->
        <!--        >-->
        <!--          <template #button-content>-->
        <!--            <feather-icon-->
        <!--              icon="MoreVerticalIcon"-->
        <!--              size="17"-->
        <!--              class="align-middle text-body"-->
        <!--            />-->
        <!--          </template>-->

        <!--          <b-dropdown-item>-->
        <!--            <feather-icon-->
        <!--              icon="MailIcon"-->
        <!--              class="mr-50"-->
        <!--            />-->

        <!--            <span>{{ $t('MarkAsUnread') }}</span>-->
        <!--          </b-dropdown-item>-->

        <!--          <b-dropdown-item v-if="!emailViewData.mailThread"-->
        <!--                           @click="removeItem(null)"-->
        <!--          >-->
        <!--            <feather-icon-->
        <!--              icon="TrashIcon"-->
        <!--              class="mr-50"-->
        <!--            />-->

        <!--            <span>{{ $t('Delete') }}</span>-->
        <!--          </b-dropdown-item>-->
        <!--        </b-dropdown>-->
      </div>
    </div>

    <div
      ref="scroll-container"
      :settings="perfectScrollbarSettings"
      class="email-scroll-area scroll-area"
      style="overflow: auto"
    >
      <!--      <b-row v-if="!emailViewData.mailThread && !emailViewData.inReplyToId">-->
      <!--        <b-col-->
      <!--          cols="12"-->
      <!--        >-->
      <!--          <email-single-message-card-->
      <!--            :all-keys="allKeys"-->
      <!--            :message="emailViewData"-->
      <!--            :show-delete="true"-->
      <!--            :selected-email="selectedEmail"-->
      <!--          />-->
      <!--        </b-col>-->
      <!--      </b-row>-->

      <!--      <b-row v-else-if="!emailViewData.mailThread && emailViewData.inReplyToId">-->
      <b-row v-if="emailViewData.inReplyToId">
        <b-col
          cols="12"
        >
          <email-single-message-card
            :all-keys="allKeys"
            :message="emailViewData"
            :show-delete="true"
            :selected-email="selectedEmail"
          />
        </b-col>
        <b-col sm="12">
          <div class="w-100">
            <email-single-message-card
              v-for="(loadedEmail, index) in sortedLoadedEmails"
              :key="`loded_email_${index}`"
              :all-keys="allKeys"
              :message="loadedEmail"
              :show-delete="true"
              :selected-email="selectedEmail"
              @remove="removeItem(loadedEmail.id)"
            />
          </div>
        </b-col>

        <b-col v-if="((sortedLoadedEmails.length && sortedLoadedEmails.at(-1).inReplyToId) && allowLoadMore) || ((emailViewData.inReplyToId && !sortedLoadedEmails.length) && allowLoadMore)"
               sm="12"
               class="d-flex justify-content-center align-items-center"
        >
          <b-button v-if="!threadEmailsLoading"
                    variant="primary"
                    @click="loadThreadEmail(sortedLoadedEmails.length > 0 ? sortedLoadedEmails.at(-1).inReplyToId : emailViewData.inReplyToId)"
          >
            {{ $t('More') }}
          </b-button>

          <b-spinner v-else
                     variant="primary"
          />
        </b-col>
      </b-row>
      <b-row v-else-if="emailViewData.threadId">
        <b-col
          cols="12"
        >
          <!--          <email-single-message-card-->
          <!--            :all-keys="allKeys"-->
          <!--            :message="emailViewData"-->
          <!--            :show-delete="true"-->
          <!--            :selected-email="selectedEmail"-->
          <!--          />-->
        </b-col>
        <b-col sm="12">
          <div class="w-100">
            <email-single-message-card
              v-for="(loadedEmail, index) in sortedLoadedEmails"
              :key="`loded_email_${index}`"
              :all-keys="allKeys"
              :message="loadedEmail"
              :show-delete="true"
              :selected-email="selectedEmail"
              @remove="removeItem(loadedEmail.id)"
            />
          </div>
        </b-col>

        <b-col v-if="emailViewData.threadId && allowLoadMore"
               sm="12"
               class="d-flex justify-content-center align-items-center"
        >
          <b-button v-if="!threadEmailsLoading"
                    variant="primary"
                    @click="loadThreadEmails"
          >
            {{ $t('More') }}
          </b-button>

          <b-spinner v-else
                     variant="primary"
          />
        </b-col>
      </b-row>

      <b-row v-else>
        <b-col v-if="emailViewData.mailThread && emailViewData.mailThread.mailMsgIds && emailViewData.mailThread.mailMsgIds.length"
               sm="12"
        >
          <div class="w-100">
            <email-single-message-card
              v-for="(loadedEmail, index) in sortedLoadedEmails"
              :key="`loded_email_${index}`"
              :all-keys="allKeys"
              :message="loadedEmail"
              :show-delete="true"
              :selected-email="selectedEmail"
              @remove="removeItem(loadedEmail.id)"
            />
          </div>
        </b-col>
        <b-col v-else
               cols="12"
        >
          <email-single-message-card
            :all-keys="allKeys"
            :message="emailViewData.email ? emailViewData.email : emailViewData"
            :selected-email="selectedEmail"
            :show-delete="true"
            @remove="removeItem(emailViewData.id)"
          />
        </b-col>

        <b-col v-if="emailViewData.mailThread && emailViewData.mailThread.mailMsgIds && allowLoadMore"
               sm="12"
               class="d-flex justify-content-center align-items-center"
        >
          <b-button v-if="!threadEmailsLoading"
                    variant="primary"
                    @click="loadThreadEmails"
          >
            {{ $t('More') }}
          </b-button>

          <b-spinner v-else
                     variant="primary"
          />
        </b-col>
      </b-row>

      <!--            <div class="static-scroll top"-->
      <!--                 @mouseenter="startScroll('top')"-->
      <!--                 @mouseleave="stopScroll"-->
      <!--            >-->
      <!--              <sw-icon icon="ChevronUpIcon"-->
      <!--                       size="20"-->
      <!--              />-->
      <!--            </div>-->
      <!--            <div class="static-scroll down"-->
      <!--                 @mouseenter="startScroll('down')"-->
      <!--                 @mouseleave="stopScroll"-->
      <!--            >-->
      <!--              <sw-icon icon="ChevronDownIcon"-->
      <!--                       size="20"-->
      <!--              />-->
      <!--            </div>-->
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { ref, watch } from '@vue/composition-api'
import EmailSingleMessageCard from '@/views/components/emails/EmailSingleMessageCard.vue'
import { VBTooltip } from 'bootstrap-vue'
import { GET_GLOBAL_EMAILS, OPEN_MODAL } from '@/@constants/mutations'
import { formatDate } from '@core/utils/filter'
import store from '@/store'

export default {
  components: {
    EmailSingleMessageCard,
    // 3rd Party
    VuePerfectScrollbar,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    emailViewData: {
      type: Object,
      required: true,
    },
    selectedEmail: {
      required: false,
    },
    allKeys: {
      required: false,
    },
    allEmails: {
      required: false,
    },
  },
  setup(props) {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const showWholeThread = ref(false)

    watch(() => props.emailViewData.id, () => {
      showWholeThread.value = false
    })

    return {
      // UI
      perfectScrollbarSettings,
      showWholeThread,
    }
  },
  data: () => ({
    loadedEmails: [],
    loadedEmailIds: [],
    page: 1,
    threadEmailsLoading: false,
    allowLoadMore: true,
    isMainDeleted: false,
    scrollInterval: null, // Used to store the interval for scrolling
  }),
  computed: {
    sortedLoadedEmails() {
      return [...this.loadedEmails].sort((a, b) => new Date(b.deliveredAt?.date) - new Date(a.deliveredAt?.date))
    },
  },
  watch: {
    emailViewData: {
      deep: true,
      handler(nw) {
        this.loadedEmails = []
        this.loadedEmailIds = []
        this.threadEmailsLoading = false
        this.allowLoadMore = true
        this.page = 1
        this.isMainDeleted = false

        if (nw?.mailThread) {
          this.loadMailThreadEmails()
        }

        if (nw?.threadId) {
          this.loadThreadEmails()
        }

        if (nw.inReplyToId) {
          this.loadThreadEmail(nw.inReplyToId)
        }
      },
    },
  },

  methods: {
    formatDate,
    async loadMailThreadEmails() {
      this.threadEmailsLoading = true
      const nextIds = (this.emailViewData.mailThread.mailMsgIds.filter(id => !this.loadedEmailIds.includes(id)) || []).slice(0, 4)
      const { data } = await store.dispatch(`globalEmails/${GET_GLOBAL_EMAILS}`, {
        filters: { messageIds: nextIds, selectedEmail: this.allEmails.mapKeys('id') },
      })

      this.loadedEmails.push(...data.items)
      if (data.items.length === 0) {
        this.allowLoadMore = false
      }
      this.loadedEmailIds.push(...nextIds)

      setTimeout(() => {
        this.threadEmailsLoading = false
      }, 1000)
    },
    async loadThreadEmails() {
      this.threadEmailsLoading = true
      // eslint-disable-next-line no-unused-vars
      //   removeIds: this.loadedEmailIds
      // const nextIds = (this.emailViewData.threadMails.filter(a => (typeof a === 'string' ? !this.loadedEmailIds.includes(a) : !this.loadedEmailIds.includes(a.id))).slice(0, 6).map(e => e.id || e))
      const { data } = await store.dispatch(`globalEmails/${GET_GLOBAL_EMAILS}`, {
        filters: { selectedEmail: this.allEmails.mapKeys('id'), threadId: this.emailViewData.threadId },
        sorter: {
          sortDesc: 'asc',
          sortBy: 'deliveredAt',
        },
        pagination: {
          perPage: 3,
          page: this.page,
        },
      })

      this.loadedEmails.push(...data.items)
      if (data.items.length === 0) {
        this.allowLoadMore = false
      }
      this.page += 1
      this.loadedEmailIds.push(...data.items.map(e => e.id))

      setTimeout(() => {
        this.threadEmailsLoading = false
      }, 1000)
    },
    async loadThreadEmail(mailId) {
      if (!mailId) {
        this.allowLoadMore = false
        return
      }
      this.threadEmailsLoading = true
      const nextIds = [mailId]
      const { data } = await store.dispatch(`globalEmails/${GET_GLOBAL_EMAILS}`, {
        filters: { messageIds: nextIds, selectedEmail: this.allEmails.mapKeys('id') },
      })

      this.loadedEmails.push(...data.items)
      if (data.items.length === 0) {
        this.allowLoadMore = false
      }
      this.loadedEmailIds.push(...nextIds)

      if (this.loadedEmailIds.length <= 2 && data.items[0]?.inReplyToId) {
        await this.loadThreadEmail(data.items[0]?.inReplyToId)
      }

      setTimeout(() => {
        this.threadEmailsLoading = false
      }, 1000)
    },
    removeItem(id) {
      this.$emit('remove-item', id)

      const loadedIndex = this.loadedEmails.findIndex(e => e.id === id)
      if (loadedIndex > 0) {
        this.$delete(this.loadedEmails, loadedIndex)
      }

      if (this.emailViewData.id === id) {
        this.isMainDeleted = true
      }
    },
    openModal() {
      this.$store.commit(`modal/${OPEN_MODAL}`,
        {
          modalType: 'linkThreadModal',
          size: 'md',
          modalTitle: this.$i18n.t('AssignToThread'),
          okButtonTitle: '',
        })
    },
    startScroll(direction = 'top') {
      // Get the Perfect Scrollbar container
      const scrollContainer = this.$refs['scroll-container'].$el
      if (!scrollContainer) return

      // Start a smooth scrolling interval
      this.scrollInterval = setInterval(() => {
        if (direction === 'top') scrollContainer.scrollTop -= 2
        else scrollContainer.scrollTop += 2
        // scrollContainer.scrollBy({ top: 600, behavior: 'smooth' }) // Scroll down incrementally
      }, 2) // Adjust the interval time (ms) and scroll amount as needed
    },
    stopScroll() {
      // Clear the scrolling interval
      if (this.scrollInterval) {
        clearInterval(this.scrollInterval)
        this.scrollInterval = null
      }
    },
  },
}
</script>

<style lang="scss">
//.scroll-area {
//  scroll-behavior: smooth;
//}
.static-scroll {
  &.top {
    right: 30px;
  }
  &.down {
    right: 75px;
  }
  position: fixed;
  bottom: 10px;
  background-color: rgb(var(--theme-color-primary));
  color: white; border-radius: 50%;
  z-index: 9999;
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}
</style>
