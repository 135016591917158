<template>
  <app-collapse-item
    :title="$t('AdditionalFields')"
    :is-title="false"
  >
    <template #subTitle>
      <div
        class="d-flex font-weight-bold flex-wrap align-items-center"
        style="gap: .5rem"
      >
        {{ $t('AdditionalFields') }}

        <b-badge variant="light-primary">
          <span v-if="isLoading">
            <b-spinner small />
          </span>

          <span v-else>{{ fieldList.length }}</span>
        </b-badge>
      </div>
    </template>

    <b-button
      v-if="checkRequiredPermissions([$roles.CONTACT_ADDON_FIELD_MODIFY])"
      size="sm"
      class="mb-25"
      variant="primary"
      :disabled="!contactId"
      @click="openModal"
    >
      <feather-icon icon="PlusIcon" />

      {{ $t('Add') }}
    </b-button>

    <b-row v-if="fieldList.length">
      <b-col
        v-for="field in fieldList"
        :key="field.id"
        md="6"
      >
        <div class="border-bottom my-50 px-50 py-25 d-flex flex-wrap justify-content-between">
          <div>
            <b-button
              v-if="checkRequiredPermissions([$roles.CONTACT_ADDON_FIELD_MODIFY])"
              v-b-tooltip.hover.v-danger
              variant="flat-danger"
              class="btn-icon p-25 mr-25"
              size="sm"
              :title="$t('Delete')"
              :disabled="!contactId"
              @click="deleteField(field.id)"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>

            <b-button
              v-if="checkRequiredPermissions([$roles.CONTACT_ADDON_FIELD_MODIFY])"
              v-b-tooltip.hover.v-warning
              variant="flat-warning"
              class="btn-icon p-25 mr-25"
              size="sm"
              :title="$t('Edit')"
              :disabled="!contactId"
              @click="openModal(field)"
            >
              <feather-icon icon="EditIcon" />
            </b-button>

            <b class="mb-25 mr-50">
              {{ field.contactAddonFieldType.name }}
            </b>
          </div>

          <b-form-checkbox
            v-if="field.contactAddonFieldType.type === 'CHECKBOX'"
            disabled
            :checked="field.value"
          />

          <div v-else-if="field.contactAddonFieldType.type === 'PERCENTAGE'">
            {{ field.value }}%
          </div>

          <div v-else-if="field.contactAddonFieldType.type === 'TOOLTIP'">
            <div
              v-if="field.contactAddonFieldTextOptions && field.contactAddonFieldTextOptions.length"
              class="d-flex"
              style="gap: .5rem"
            >
              <b-badge
                v-for="(option, i) in field.contactAddonFieldTextOptions"
                :key="i"
                variant="light-primary"
              >
                {{ option }}
              </b-badge>
            </div>

            <template v-else>
              {{ field.value }}
            </template>
          </div>

          <div v-else>
            <div
              v-if="field.contactAddonFieldTypeOptions.length"
              class="d-flex"
              style="gap: .5rem"
            >
              <b-badge
                v-for="(option, i) in field.contactAddonFieldTypeOptions"
                :key="i"
                variant="light-primary"
              >
                {{ option.name }}
              </b-badge>
            </div>

            <template v-else>
              {{ field.value }}
            </template>
          </div>
        </div>
      </b-col>
    </b-row>

    <div
      v-else
      class="text-center pt-1 text-primary font-weight-bold"
    >
      <feather-icon
        size="17"
        icon="XCircleIcon"
      />
      {{ $t('NoData') }}
    </div>

    <b-overlay
      :show="isLoading"
      no-wrap
      spinner-variant="primary"
    />
  </app-collapse-item>
</template>

<script>
import { DELETE_CONTACT_ADDON_FIELD, GET_CONTACT_ADDON_FIELDS, OPEN_MODAL } from '@/@constants/mutations'
import { mapGetters } from 'vuex'
import { VBTooltip } from 'bootstrap-vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    contactId: {
      type: [Number, String],
      required: false,
      default: null,
    },
    thread: {
      type: Object,
      required: false,
      deep: null,
    },
  },

  data: () => ({
    isLoading: false,

    fieldList: [],
  }),

  computed: {
    ...mapGetters({
      modalState: 'modal/getModalState',
    }),
  },

  watch: {
    contactId(n) { if (n) this.loadFields() },

    modalState: {
      deep: true,
      handler(n) { if (n.reloadContent) this.addField(n.reloadContent) },
    },
  },

  mounted() { this.ini() },

  methods: {
    ini() { this.loadFields() },

    loadFields() {
      if (this.contactId) {
        this.isLoading = true

        this.$store.dispatch(`addonFields/${GET_CONTACT_ADDON_FIELDS}`, this.contactId)
          .then(res => { this.fieldList = res })

          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          })

          .finally(() => { this.isLoading = false })
      }
    },

    openModal(field) {
      this.$store.commit(`modal/${OPEN_MODAL}`,
        {
          modalType: 'addAdditionalInformation',
          size: 'md',
          modalTitle: this.$i18n.t('Field'),
          okButtonTitle: '',
          contactId: this.contactId,
          thread: this.thread,
          data: {
            fieldList: this.fieldList,
            field,
          },
        })
    },

    deleteField(id) {
      const { contactId } = this

      if (id && contactId) {
        this.showAlert('warning', this.$i18n.t('alerts.AreYouSure'))
          .then(() => {
            this.$store.dispatch(`addonFields/${DELETE_CONTACT_ADDON_FIELD}`, { contactId, id })
              .then(() => { this.fieldList.splice(this.fieldList.findIndex(el => Number(el.id) === Number(id)), 1) })

              .catch(err => {
                this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
              })

              .finally(() => { this.isLoading = false })
          })
      }
    },

    addField(field) {
      if (field.id) {
        const oldField = this.fieldList.findIndex(f => f.id === field.id)

        if (typeof oldField === 'undefined' || oldField < 0) {
          this.fieldList.push(field)
        } else {
          this.fieldList[oldField] = field
          this.$forceUpdate()
        }
      }
    },
  },
}
</script>
