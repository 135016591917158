<template>
  <b-row>
    <b-col sm="12"
           class="mt-1 mt-md-0"
    >
      <div class="d-flex align-items-center">
        <b-button size="sm"
                  class="mb-50"
                  variant="primary"
                  @click="editField(JSON.parse(JSON.stringify(defaultTemplate)), -1)"
        >
          <sw-icon icon="PlusIcon"
                   class="mr-25"
          />
          {{ $t('Add') }}
        </b-button>
        <div class="ml-50">
          <vue-select
            v-model="pagination.limit"
            style="min-width: 75px"
            class="per-page-selector d-inline-block mr-50 pb-50 select-size-sm"
            :options="[15, 25, 50]"
            :clearable="false"
            @input="reloadTemplates(pagination.currentPage, $event)"
          />
        </div>
      </div>

      <b-table
        ref="selectableTable"
        :items="templates"
        class="table-wrap-words"
        :fields="fields"
        striped
        responsive
        selectable
        select-mode="multi"
        :style="{ minHeight: tableSize(templates, 150) }"
        :busy="loading"
        show-empty
        @row-selected="onRowSelected"
      >
        <template #head()="{ label }">
          {{ $t(`${label}`) }}
        </template>
        <template #empty>
          <div
            class="text-center py-1 text-primary font-weight-bold"
          >
            <feather-icon
              size="17"
              icon="XCircleIcon"
            />
            {{ $t('NoData') }}
          </div>
        </template>
        <template #head(selected)>
          <b-form-checkbox
            variant="primary"
            :checked="templates.length === selectedTemplates.length"
            @change="changeAllSelection"
          />
        </template>
        <template #cell(selected)="rowSelected">
          <b-form-checkbox
            v-model="selectedTemplates"
            variant="primary"
            :value="rowSelected.item"
            @change="changeSelection(rowSelected.rowSelected, rowSelected.index)"
          />
        </template>
        <template #cell(content)="row">
          <div @click="previewContent = row.item.content; templatePreview = !templatePreview">
            <sw-icon icon="EyeIcon"
                     class="text-primary cursor-pointer mr-25"
            />
            <span :inner-html.prop="row.value | truncate(35, '...')" />
          </div>
        </template>
        <template #cell(action)="row">
          <b-dropdown
            id="dropdown-dropleft"
            dropleft
            no-caret
            variant="flat"
          >
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" />
            </template>

            <b-dropdown-item @click="previewContent = row.item.content; templatePreview = !templatePreview">
              <dropdown-item-label
                icon="EyeIcon"
                :label="$t('Preview')"
              />
            </b-dropdown-item>
            <b-dropdown-item @click="editField(row.item, row.index)">
              <dropdown-item-label
                icon="EditIcon"
                :label="$t('Edit')"
              />
            </b-dropdown-item>
            <b-dropdown-item @click="removeTemplates(row.item.id, row.index)">
              <dropdown-item-label
                icon="TrashIcon"
                :label="$t('Delete')"
              />
            </b-dropdown-item>
          </b-dropdown>
        </template>
        <template #cell()="row">
          <table-default-cell
            :field="row.field"
            :value="row.value"
          />
        </template>

        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner
              class="align-middle"
              variant="primary"
            />
          </div>
        </template>
      </b-table>

      <div class="d-flex justify-content-center">
        <div>
          <b-pagination
            v-model="pagination.currentPage"
            :total-rows="rows"
            :per-page="pagination.limit"
            align="center"
            @change="reloadTemplates($event, pagination.limit)"
          />
        </div>
      </div>
    </b-col>

    <transition name="fade">
      <div
        v-if="selectedTemplates.length"
        style="position: fixed; right: 30px; bottom: 20px"
        class="shadow"
      >
        <b-button
          variant="danger"
          class="d-flex align-items-center"
          size="sm"
          @click="removeTemplates"
        >
          <feather-icon icon="TrashIcon" />
          <span class="pr-1 pl-25">{{ $t('Delete') }}</span>
          <b-badge variant="light text-danger">
            {{ selectedTemplates.length }}
          </b-badge>
        </b-button>
      </div>
    </transition>

    <b-modal
      v-model="templatePreview"
      hide-footer
      :title="$t('Preview')"
      size="xl"
    >
      <div style="max-width: 100%; width: 100%; position:relative;">
        <email-preview-modal :template="previewContent" />
      </div>
    </b-modal>

    <b-modal
      v-model="editedTemplate.show"
      size="lg"
      hide-footer
      :title="$t(editedTemplate.index > -1 ? 'Edit' : 'Create')"
      @hide="closeModal"
    >
      <div v-if="editedTemplate.show">
        <div class="form-label-group">
          <b-form-group :label="$t('Title')">
            <b-form-input
              id="template-name"
              v-model="editedTemplate.item.name"
            />
          </b-form-group>
        </div>
        <div
          class="form-label-group"
        >
          <b-form-group :label="$t('Content')">
            <quill-editor
              v-model="editedTemplate.item.content"
              :options="editorOption"
            >
              <div
                id="toolbar"
                slot="toolbar"
              >
                <!-- Add a bold button -->
                <span class="ql-formats">
                  <button class="ql-clean" />
                  <button class="ql-bold" />
                  <button class="ql-italic" />
                  <button class="ql-link" />
                </span>
                <span class="ql-formats">
                  <button
                    class="ql-script"
                    value="sub"
                  />
                  <button
                    class="ql-script"
                    value="super"
                  />
                </span>
                <span class="ql-formats">
                  <button
                    class="ql-list"
                    value="ordered"
                  />
                  <button
                    class="ql-list"
                    value="bullet"
                  />
                </span>
                <span class="ql-formats">
                  <button
                    class="ql-indent"
                    value="-1"
                  />
                  <button
                    class="ql-indent"
                    value="+1"
                  />
                </span>
                <span class="ql-formats">
                  <select class="ql-align" />
                  <select class="ql-color" />
                </span>
                <span class="ql-formats">
                  <select class="ql-size" />
                  <select class="ql-header" />
                  <select class="ql-font" />
                </span>

                <!-- You can also add your own -->
                <span class="ql-formats">
                  <b-button
                    id="custom-button"
                    variant="success"
                    class="w-auto"
                    @click="$emit('open-modal')"
                  >
                    <span class="text-white">
                      <feather-icon
                        icon="CodeIcon"
                        class="mr-25"
                      />
                    </span>

                    <span class="text-white">
                      {{ $t('ShortCodes') }}
                    </span>
                  </b-button>
                </span>
              </div>
            </quill-editor>
          </b-form-group>

          <div v-if="editedTemplate.item.files && editedTemplate.item.files.length">
            {{ $t('Files') }}
            <documents-list :documents="editedTemplate.item.files"
                            delete-mode
                            list-all
                            @deleteFile="$delete(editedTemplate.item.files, $event)"
            />
          </div>

          <DragDropUpload
            ref="dragdropupload"
            :is-refresh="false"
            @onDeleteFiles="files = []"
            @onChangeFiles="changeFiles"
          />
        </div>
        <b-button
          size="sm"
          variant="primary"
          :disabled="!editedTemplate.item.content || !editedTemplate.item.name"
          @click="saveTemplate"
        >
          {{ $t('Save') }}
        </b-button>
      </div>
    </b-modal>
  </b-row>
</template>

<script>
import VueSelect from 'vue-select'
import {
  BTable, BPagination,
} from 'bootstrap-vue'
import {
  GET_TEMPLATES, SAVE_TEMPLATE, DELETE_TEMPLATE, UPLOAD_FILES,
} from '@/@constants/mutations'
import { mapGetters } from 'vuex'
import { defaultEmailTemplate } from '@/@data/emailTemplates'
import EmailPreviewModal from '@/views/modals/EmailPreviewModal.vue'
import { quillEditor } from 'vue-quill-editor'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
import DragDropUpload from '@/views/components/DragDropUpload.vue'
import DocumentsList from '@/views/components/DocumentsList.vue'

const defaultTemplate = {
  name: '',
  content: defaultEmailTemplate,
  files: [],
}

export default {
  name: 'Email',
  components: {
    DocumentsList,
    DragDropUpload,
    EmailPreviewModal,
    BTable,
    BPagination,
    VueSelect,
    quillEditor,
  },
  data: () => ({
    defaultTemplate,
    editedTemplate: {
      show: false,
      item: JSON.parse(JSON.stringify(defaultTemplate)),
      index: -1,
    },
    sorter: {
      sortBy: 'createdAt',
      sortDesc: true,
    },
    files: [],
    pagination: {
      limit: 25,
      currentPage: 1,
    },
    editorOption: {
      placeholder: '',
      modules: {
        toolbar: '#toolbar',
      },
    },
    loading: false,
    selectedTemplates: [],
    fields: [
      {
        key: 'selected',
      },
      {
        key: 'name',
        label: 'Title',
      },
      {
        key: 'content',
        label: 'Template',
      },
      {
        key: 'files',
        label: 'Files',
        tag: 'file',
      },
      {
        key: 'action',
        label: 'Action',
      },
    ],
    editedTemplateId: null,
    template: {
      content: defaultEmailTemplate,
      name: '',
      files: [],
    },
    previewContent: '',
    templatePreview: false,
  }),
  computed: {
    ...mapGetters({
      shortcodes: 'templates/getShortcodes',
      templates: 'templates/getEmailTemplates',
      rows: 'templates/getEmailTemplatesLength',
      system: 'system/getSettings',
    }),
  },
  mounted() {
    this.reloadTemplates()
  },
  methods: {
    reloadTemplates(page = this.pagination.currentPage, limit = this.pagination.limit) {
      this.loading = true

      this.$store.dispatch(`templates/${GET_TEMPLATES}`, { type: 'email', page: this.rows < limit ? 1 : page, limit })
        .then(() => {
          setTimeout(() => { this.loading = false }, 500)
        })
        .catch(() => {
          this.loading = false
          this.showToast('error', this.$i18n.t('ProblemOccured'))
        })
    },
    changeFiles(files) {
      this.files.push(...files)
    },
    editField(item, index) {
      const payload = {
        index,
        show: true,
        item: {
          content: (item?.content || defaultEmailTemplate).replaceAll('\n', '<br />'),
          name: item?.name,
          files: item?.files || [],
        },
      }

      this.$set(this, 'editedTemplate', JSON.parse(JSON.stringify(payload)))
    },
    changeSelection(e, index) {
      if (!e) this.$refs.selectableTable.selectRow(index)
      else this.$refs.selectableTable.unselectRow(index)
    },
    changeAllSelection() {
      if (this.templates.length === this.selectedTemplates.length) this.$refs.selectableTable.clearSelected()
      else this.$refs.selectableTable.selectAllRows()
    },
    onRowSelected(items) {
      this.selectedTemplates = items
    },
    insertShortcode(shortcode) {
      const { content } = this.template
      const textarea = this.$refs.content.$refs.input
      const sentenceLength = content.length
      let position = textarea.selectionStart

      if (position === undefined) {
        position = sentenceLength - 1
      }

      const before = content.substr(0, position)
      const after = content.substr(position, sentenceLength)

      this.template.content = before + shortcode.shortcode + after

      this.$nextTick().then(() => {
        textarea.focus()
      })
    },
    async saveTemplate() {
      const { editedTemplate } = this
      const content = editedTemplate.item.content.replaceAll('\n', '<br>')
      if (this.templates[this.editedTemplate.index]?.id) editedTemplate.item.id = this.templates[this.editedTemplate.index]?.id
      editedTemplate.item.content = content

      if (this.files?.length) {
        const filesPayload = {
          uploadedFiles: this.files.filter(e => !e.token),
          type: 'building',
        }
        const { files } = await this.$store.dispatch(`uploader/${UPLOAD_FILES}`, filesPayload)

        editedTemplate.item.files = [...files, ...editedTemplate.item.files.filter(e => e.token).map(e => e.token)]
      } else {
        editedTemplate.item.files = editedTemplate.item.files.filter(e => e.token).map(e => e.token)
      }

      this.$store.dispatch(`templates/${SAVE_TEMPLATE}`, { type: 'email', template: editedTemplate.item })
        .then(() => {
          // Object.assign(this.templates[this.editedTemplate.index], this.editedTemplate.item)
          this.reloadTemplates()

          this.closeModal()
          this.showToast('success', editedTemplate.item.id ? this.$i18n.t('templates.TemplateUpdated') : this.$i18n.t('templates.TemplateSaved'))
        })
        .catch(() => {
          this.showToast('error', this.$i18n.t('ProblemOccured'))
        })
    },
    removeTemplates(id, index) {
      const templatesToRemove = id && index > -1 ? [id] : this.selectedTemplates.map(template => template.id.toString())
      this.$store.dispatch(`templates/${DELETE_TEMPLATE}`, { templatesToRemove, type: 'email' })
        .then(() => {
          if (id && index > -1) {
            this.$delete(this.templates, index)
          } else this.reloadTemplates()
          this.showToast('success', this.$i18n.t('RemovedSuccessfully'))
        })
        .catch(() => {
          this.showToast('error', this.$i18n.t('ProblemOccured'))
        })
    },
    closeModal() {
      this.files = []
      this.$set(this, 'editedTemplate', JSON.parse(JSON.stringify({
        show: false,
        item: JSON.parse(JSON.stringify(defaultTemplate)),
        index: -1,
      })))
    },
  },
}
</script>

<style scoped>

</style>
