export const propertyStatus = [
  'PROPERTY_SEPARATION',
  'MARITAL_COMMUNITY',
  'FREE_STATUS',
]

export const civilStatus = [
  'BACHELOR',
  'MARRIED',
  'PARTNER',
  'WIDOWER',
  'DIVORCED',
  'SEPARATED',
]

export const documentTypes = [
  'DRIVING_LICENSE',
  'ID_CARD',
  'PASSPORT',
  'RESIDENCE_CARD',
]

export const agreementStatuses = [
  'NEW',
  'ACCEPTED',
  'CANCELLED',
  'SIGNING',
  'SIGNED_MANUALLY',
  'SIGNED_ELECTRONICALLY',
]

export const offerStatuses = [
  'NEW',
  'CANCELLED',
  'ACCEPTED',
  'EXPIRED',
]

export const electronicSignatureTypes = [
  'AUTENTI',
  'WWW_SIGNATURE',
]
