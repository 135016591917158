<template>
  <sw-tabs :card="false">
    <b-tab
      :title="$t('GeneralIndicators')"
      title-item-class="btn btn-sm p-0"
      title-link-class="px-50 py-75 h-100 d-flex align-items-center justify-content-center"
    >
      <b-card>
        <b-row>
          <b-col
            sm="12"
            md="6"
          >
            <sw-select :name="$t('task.Type')">
              <v-select
                v-model="filters.type"
                :options="types"
                :reduce="i => i.key"
                @input="clearSource"
              >
                <template #option="{ label }">
                  <span>{{ label }}</span>
                </template>
                <template #selected-option="{ label }">
                  <span>{{ label }}</span>
                </template>
                <template #no-options>
                  {{ $t('NoOptions') }}
                </template>
              </v-select>
            </sw-select>
          </b-col>

          <!--    Filter by Source    -->
          <b-col
            sm="12"
            md="6"
          >
            <b-form-group
              :label="$t('ContactSource')"
              class="mb-0"
            >
              <sw-select :disabled="!filters.type">
                <v-select
                  v-model="filters.source"
                  :options="filtredSources"
                  :disabled="!filters.type"
                  :reduce="i => i.id"
                >
                  <template #option="{ name, type }">
                    <span
                      v-if="filters.type && filters.type !== 'FACEBOOK'"
                      class="d-flex"
                      style="gap: .24rem"
                    >
                      <span v-if="types.find(({key}) => key === type)">{{ types.find(({key}) => key === type).label }}</span>
                      <span>–</span>
                      <span>{{ name }}</span>
                    </span>
                    <span v-else>{{ $t('Connection') }} - {{ name }}</span>
                  </template>
                  <template #selected-option="{ name, type }">
                    <span
                      v-if="filters.type !== 'FACEBOOK'"
                      class="d-flex"
                      style="gap: .24rem"
                    >
                      <span v-if="types.find(({key}) => key === type)">{{ types.find(({key}) => key === type).label }}</span>
                      <span>–</span>
                      <span>{{ name }}</span>
                    </span>
                    <span v-else>{{ $t('Connection') }} - {{ name }}</span>
                  </template>
                  <template #no-options>
                    {{ $t('NoOptions') }}
                  </template>
                </v-select>
              </sw-select>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>

      <b-card v-if="stats">
        <b-row style="row-gap: 1rem">
          <b-col
            lg="6"
            class="d-flex align-items-center"
          >
            <div class="d-flex align-items-center justify-content-start">
              <b-avatar
                variant="light-primary"
                size="50"
              >
                <feather-icon
                  icon="ArchiveIcon"
                  size="15"
                />
              </b-avatar>

              <div class="d-flex flex-column ml-75 w-100">
                <p class="mb-25 h4">
                  {{ stats.all }}
                </p>

                <p class="mb-0 font-medium-1">
                  {{ $t('TotalNumberSubmissions') }}
                </p>
              </div>
            </div>
          </b-col>

          <b-col lg="6">
            <b-row style="row-gap: .75rem">
              <b-col
                lg="12"
                class="d-flex align-items-center justify-content-start"
              >
                <b-avatar variant="light-primary">
                  <feather-icon icon="PlusIcon" />
                </b-avatar>

                <div class="d-flex flex-column ml-75 w-100">
                  <p class="mb-25">
                    {{ `${$t('NewSubmissions')}: ${(stats.all - stats.accepted - stats.rejected) || 0}` }}
                  </p>

                  <b-progress
                    :value="(stats.all - stats.accepted - stats.rejected) || 0"
                    variant="primary"
                    :max="stats.all"
                    style="height: 6px"
                  />
                </div>
              </b-col>

              <b-col
                lg="12"
                class="d-flex align-items-center justify-content-start"
              >
                <b-avatar variant="light-primary">
                  <feather-icon icon="StarIcon" />
                </b-avatar>

                <div class="d-flex flex-column ml-75 w-100">
                  <p class="mb-25">
                    {{ `${$t('SubmissionsTaken')}: ${stats.accepted}` }}
                  </p>

                  <b-progress
                    :value="stats.accepted"
                    variant="primary"
                    :max="stats.all"
                    style="height: 6px"
                  />
                </div>
              </b-col>

              <b-col
                lg="12"
                class="d-flex align-items-center justify-content-start"
              >
                <b-avatar variant="light-primary">
                  <feather-icon icon="XIcon" />
                </b-avatar>

                <div class="d-flex flex-column ml-75 w-100">
                  <p class="mb-25">
                    {{ `${$t('RejectedSubmissions')}: ${stats.rejected}` }}
                  </p>

                  <b-progress
                    :value="stats.rejected"
                    variant="primary"
                    :max="stats.all"
                    style="height: 6px"
                  />
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card>

      <h4 class="text-secondary mb-1">
        {{ $t('CloseReasons') }}
      </h4>

      <b-row
        v-if="stats"
        style="row-gap: 1.34rem"
      >
        <b-col
          v-for="(reason, reasonIndex) in stats.contactApplicationStatusReasons"
          :key="`reason_${reasonIndex}`"
          md="6"
          lg="4"
        >
          <b-card
            class="w-100 mb-0"
            no-body
          >
            <div class="card-body d-flex flex-column">
              <p class="font-medium-1 mb-50">
                {{ `${reason.name}: ${reason.all}` }}
              </p>

              <b-progress
                :value="reason.all"
                variant="primary"
                :max="stats.all"
                style="height: 8px"
              />
            </div>
          </b-card>
        </b-col>

        <b-col
          v-for="(reason, reasonIndex) in stats.contactApplicationStatusReasons"
          :key="`reason_${reasonIndex}`"
          md="6"
          lg="4"
        >
          <b-card
            class="w-100 mb-0"
            no-body
          >
            <div class="card-body d-flex flex-column">
              <p class="font-medium-1 mb-50">
                {{ `${reason.name}: ${reason.all}` }}
              </p>

              <b-progress
                :value="reason.all"
                variant="primary"
                :max="stats.all"
                style="height: 8px"
              />
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-tab>

    <b-tab
      :title="$t('CloseReasons')"
      title-item-class="btn btn-sm p-0"
      title-link-class="px-50 py-75 h-100 d-flex align-items-center justify-content-center"
    >
      <b-card
        no-body
        class="p-2 pb-0"
      >
        <b-row>
          <b-col
            sm="12"
            md="6"
          >
            <sw-select :name="$t('task.Type')">
              <v-select
                v-model="filters.type"
                :options="types"
                :reduce="i => i.key"
                @input="clearSource"
              >
                <template #option="{ label }">
                  <span>{{ label }}</span>
                </template>
                <template #selected-option="{ label }">
                  <span>{{ label }}</span>
                </template>
                <template #no-options>
                  {{ $t('NoOptions') }}
                </template>
              </v-select>
            </sw-select>
          </b-col>

          <!--    Filter by Source    -->
          <b-col
            sm="12"
            md="6"
          >
            <b-form-group
              :label="$t('ContactSource')"
              class="mb-0"
            >
              <sw-select :disabled="!filters.type">
                <v-select
                  v-model="filters.source"
                  :options="filtredSources"
                  :disabled="!filters.type"
                  :reduce="i => i.id"
                >
                  <template #option="{ name, type }">
                    <span
                      v-if="filters.type !== 'FACEBOOK'"
                      class="d-flex"
                      style="gap: .24rem"
                    >
                      <span v-if="types.find(({key}) => key === type)">{{ types.find(({key}) => key === type).label }}</span>
                      <span>–</span>
                      <span>{{ name }}</span>
                    </span>
                    <span v-else>{{ $t('Connection') }} - {{ name }}</span>
                  </template>
                  <template #selected-option="{ name, type }">
                    <span
                      v-if="filters.type !== 'FACEBOOK'"
                      class="d-flex"
                      style="gap: .24rem"
                    >
                      <span v-if="types.find(({key}) => key === type)">{{ types.find(({key}) => key === type).label }}</span>
                      <span>–</span>
                      <span>{{ name }}</span>
                    </span>
                    <span v-else>{{ $t('Connection') }} - {{ name }}</span>
                  </template>
                  <template #no-options>
                    {{ $t('NoOptions') }}
                  </template>
                </v-select>
              </sw-select>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
      <b-card>
        <!-- eslint-disable -->
        <sw-table
          table-id="b3cd8ab723a740379c196f71e1d4b03c"
          show-columns
          :fields.async="tableFields"
          :pagination="pagination"
          @change-pagination="Object.assign(pagination, $event)"
          @reload-content="loadData"
          @set-fields="tableFields = $event"
        >
          <template #table>
            <b-table
              :items="items"
              :fields="fields"
              striped
              responsive
              :busy="loading"
              show-empty
            >
              <template #head()="item">
                <div
                  class="d-flex flex-row align-items-center"
                  style="column-gap: .34rem"
                >
                  {{ $t(item.label) }}

                  <feather-icon
                    v-if="item.field.tooltip"
                    v-b-tooltip.hover.v-primary
                    :title="item.field.tooltip ? $t(item.field.tooltip) : ''"
                    icon="InfoIcon"
                  />
                </div>
              </template>

              <template #cell(source)="{ item }">
                  <b-badge
                          v-if="item.contactApplicationIntegration"
                          variant="light-primary"
                          class="mb-25 text-capitalize"
                  >
                      <span class="text-capitalize">{{ (item.contactApplicationIntegration.type || '').toLowerCase().replaceAll('_', ' ') }}</span>
                  </b-badge>
                  <b-badge
                          v-if="item.integrationFacebookWebhook"
                          variant="light-primary"
                          class="mb-25 text-capitalize"
                  >
                      Facebook
                  </b-badge>
              </template>

              <template #cell(name)="{ item }">
                {{ getUserName(item) }}
              </template>

                <template #cell(servedBy)="{ value }">
                <avatar :show-name="false" :user="value" />
              </template>

              <template #cell(note)="{ value }">
                <div
                        v-b-tooltip.hover.v-primary
                  :title="value"
                >{{ value | truncate(35) }}</div>
              </template>

              <template #cell(NumberOfSuccesses)="{ value }">
                <b-badge variant="success">
                  {{ value }}
                </b-badge>
              </template>

              <!--    Other    -->
                <!--    Other    -->
                <template #cell()="row">
                    <table-default-cell
                            :field="row.field"
                            :value="row.value"
                    />
                </template>

                <!--      Empty      -->
                <template #empty>
                    <empty-content />
                </template>

                <!--      Table Busy      -->
                <template #table-busy>
                    <div class="text-center text-danger my-2">
                        <b-spinner
                                class="align-middle"
                                variant="primary"
                        />
                    </div>
                </template>
            </b-table>
          </template>
        </sw-table>
      </b-card>
    </b-tab>
  </sw-tabs>
</template>

<script>
import {
  BTab, BTable, VBPopover, VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import axiosIns from '@/libs/axios'
import { GET_APPLICATIONS } from '@/@constants/mutations'
import { formatDate } from '@core/utils/filter'

export default {
  components: {
    vSelect,
    BTable,
    BTab,
  },

  directives: {
    'b-popover': VBPopover,
    'b-tooltip': VBTooltip,
  },

  data: () => ({
    loading: false,

    types: [
      { key: 'GOOGLE_ADS', label: 'Google' },
      { key: 'HTML', label: 'HTML' },
      { key: 'WORDPRESS', label: 'Wordpress' },
      { key: 'FACEBOOK', label: 'Facebook' },
    ],

    applicationStatuses: [
      { key: 'NEW', label: 'AgreementStatuses.NEW' },
      { key: 'REJECTED', label: 'AgreementStatuses.REJECTED' },
      { key: 'ACCEPTED', label: 'WasTaken' },
    ],

    filters: {
      type: '',
      source: '',
      status: '',
      createdAt: '',
      createdBy: '',
    },

    pagination: {
      perPage: 25,
      totalRecords: 0,
      currentPage: 1,
    },

    filterCreatedAtOptions: [],

    items: [],
    tableFields: [
      { key: 'Name', label: 'Name', visible: true },
      { key: 'source', label: 'ContactSource', visible: true },
      { key: 'contactApplicationStatusReason', label: 'CloseReason', visible: true },
      { key: 'note', label: 'Note', visible: true },
      { key: 'servedBy', label: 'RejectedBy', visible: true },
      { key: 'servedAt', label: 'RejectionDate', visible: true },
    ],

    totalNumberSubmissions: 500,
    newSubmissions: 340,
    submissionsTaken: 240,
    rejectedSubmissions: 40,

    stats: null,
    connections: [],
    sources: [],
  }),

  computed: {
    fields() {
      return this.tableFields.filter(field => field.visible)
    },
    filtredSources() {
      if (this.filters.type === 'FACEBOOK') return this.connections
      return this.sources.filter(({ type }) => type === this.filters.type)
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.loadData()
      },
    },
  },

  async mounted() {
    await this.loadWebhooks()
    await this.loadTypes()

    await this.ini()
  },

  methods: {
    formatDate,
    ini() {
      this.filterCreatedAtOptions = [
        { text: this.$t('Last7Days'), value: 'last7Days' },
        { text: this.$t('ThisMonth'), value: 'thisMonth' },
        { text: this.$t('LastMonth'), value: 'lastMonth' },
        { text: this.$t('Last6Months'), value: 'last6Months' },
      ]

      this.loadData()
    },

    async loadData() {
      this.loading = true

      try {
        const params = {}
        if (this.filters?.source) {
          if (this.filters.type === 'FACEBOOK') {
            params['eq_integrationFacebookWebhook-id'] = this.filters.source
          } else {
            params['eq_contactApplicationIntegration-id'] = this.filters.source
          }
        } else if (this.filters?.type) {
          if (this.filters.type === 'FACEBOOK') {
            params.isNotNull_integrationFacebookWebhook = 1
          } else {
            params['eq_contactApplicationIntegration-type'] = this.filters.type
          }
        }

        const resp = await axiosIns.get('1/reports/contactApplications/contactApplicationStatusReasons', { params })
        this.stats = resp.data.data?.contactApplications || null
      } catch (err) {
        console.error(err)
      }

      const payload = {
        pagination: this.pagination,
        filters: { ...this.filters, closed: true },
        contact: null,
      }

      this.$store.dispatch(`applications/${GET_APPLICATIONS}`, payload)
        .then(res => {
          this.items = res.data.items
          this.pagination.totalRecords = res.data.totalItemCount
          this.loading = false
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          this.loading = false
        })
    },
    async loadWebhooks() {
      try {
        const res = await axiosIns.get('1/integration/facebook/connection/webhook', { params: { fields_load: this.$fields.FACEBOOK_WEBHOOKS } })
        const connections = res?.data?.data?.items?.sort((a, b) => new Date(b.createdAt.date) - new Date(a.createdAt.date))
        this.connections = connections || []
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    clearSource() {
      this.filters.source = ''
    },
    async loadTypes() {
      try {
        const resp = await axiosIns.get('1/settings/contactsApplicationsIntegrations', { params: { fields_load: this.$fields.SETTINGS_CONTACTS_APPLICATIONS_INTEGRATIONS } })
        if (resp?.data?.data?.items) this.sources = resp.data.data.items
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
  },
}
</script>
