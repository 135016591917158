/* eslint-disable */

import Vue from 'vue'
// eslint-disable-next-line import/no-cycle
import axiosIns from '@/libs/axios'
import axios from 'axios'
import * as fields from '@/@constants/fields'
import {
  CONTACT_THREAD_ASSIGNED_ORDER_VIEW_ALL,
  CONTACT_THREAD_ASSIGNED_ORDER_VIEW_SELF,
  CONTACT_THREAD_ORDER_VIEW_ALL,
  APPLICATION_VIEW_ALL, APPLICATION_VIEW_ASSIGNED_CONTACT_THREAD,
} from '@/helpers/permissions'
import { ORGANIZATION_VIEW_ALL } from '@/@constants/mutations'
import {checkRequiredModule, checkRequiredPermissions} from "@core/mixins";

let source
let source2

const getParams = (searchArr = [], searchFields) => {
  const params = {}

  searchArr.forEach((field, index) => {
    params[`search_${index + 1}`] = field
    params[`search_fields_${index + 1}`] = searchFields
  })

  return params
}

export default {
  namespaced: true,
  state: {
    searchData: [
      // {
      //   key: 'users', loading: false, data: []
      // },
      {
        icon: 'UsersIcon', key: 'contacts', loading: false, data: []
      },
      {
        icon: 'ShoppingBagIcon', key: 'orders', loading: false, data: [], requiredModule: 'orderModule', requiredPermissions: [CONTACT_THREAD_ORDER_VIEW_ALL, CONTACT_THREAD_ASSIGNED_ORDER_VIEW_ALL, CONTACT_THREAD_ASSIGNED_ORDER_VIEW_SELF]
      },
      {
        icon: 'GlobeIcon', key: 'organizations', loading: false, data: [], requiredPermissions: [ORGANIZATION_VIEW_ALL]
      },
      {
        icon: 'ArchiveIcon', key: 'applications', loading: false, data: [], requiredPermissions: [APPLICATION_VIEW_ALL, APPLICATION_VIEW_ASSIGNED_CONTACT_THREAD], requiredModule: 'applicationModule'
      },
    ]
  },
  getters: {
    getSearchSectionsWithData: state => state.searchData
  },
  mutations: {
    SET_SEARCH_DATA(state, { key, items, loading }) {
      const index = state.searchData.findIndex(stateItem => stateItem.key === key)
      if (index !== -1) {
        Vue.set(state.searchData[index], 'loading', loading)
        Vue.set(state.searchData[index], 'data', items)
      }
    },
  },
  actions: {
    'RUN_SEARCH_QUERY': ({ dispatch, commit }, { search, options = [] }) => new Promise((resolve, reject) => {
      if (search) {
        const _search = search.split(' ').filter(f => !!f).map(word => `%${word}%`)
        const requests = options.filter(dataItem => {
          return ((
              (!dataItem?.requiredModule || checkRequiredModule(dataItem.requiredModule))
              && (!dataItem?.requiredPermissions?.length || checkRequiredPermissions(dataItem.requiredPermissions))
          ) ? dataItem : false)
        }).map(option => dispatch(option, _search))

        Promise.all(requests)
      }
    }),

    'GET_USERS': ({ commit }, search) => new Promise((resolve, reject) => {
      commit('SET_SEARCH_DATA', { key: 'users', items: [], loading: true })
      let params = {
        searchArr: JSON.stringify(search),
        search_fields: 'userId,firstName,lastName,mailAddress-mail,mailAddressPrivate-mail,phoneNumber-phone,phoneNumberPrivate-phone',
        fields_load: fields.USERS_SEARCH,
        limit: 25,
      }

      axiosIns.get('1/users', { params })
        .then(res => {
          commit('SET_SEARCH_DATA', { key: 'users', items: res.data.data.items, loading: false })
          resolve()
        })
        .catch(err => {
          reject(err)
        })
    }),
    'GET_APPLICATIONS': ({ commit }, search) => new Promise((resolve, reject) => {
      commit('SET_SEARCH_DATA', { key: 'applications', items: [], loading: true })
      let params = {
        searchArr: JSON.stringify(search),
        search_fields: 'firstName,lastName,contactThread-name,contactThread-contact-firstName,contactThread-contact-lastName',
        fields_load: fields.APPLICATIONS,
        limit: 25,
      }

      axiosIns.get('1/contacts/applications', { params })
        .then(res => {
          commit('SET_SEARCH_DATA', { key: 'applications', items: res.data.data.items, loading: false })
          resolve()
        })
        .catch(err => {
          reject(err)
        })
    }),

    'GET_ORDERS': ({ commit }, search) => new Promise((resolve, reject) => {
      commit('SET_SEARCH_DATA', { key: 'orders', items: [], loading: true })
      let params = {
        searchArr: JSON.stringify(search),
        search_fields: 'number',
        fields_load: fields.ORDERS,
        limit: 25,
      }
      axiosIns.get(`1/contacts/threads/carts/orders`, { params })
        .then(res => {
          commit('SET_SEARCH_DATA', { key: 'orders', items: res.data.data.items, loading: false })
          resolve()
        })
        .catch(err => {
          reject(err)
        })
    }),

    'GET_CONTACTS': ({ commit }, search) => new Promise((resolve, reject) => {
      if (typeof source !== typeof undefined) source.cancel('Operation canceled due to new request.')

      source = axios.CancelToken.source()

      commit('SET_SEARCH_DATA', { key: 'contacts', items: [], loading: true })
      let params = {
        // ...getParams(search, 'firstName,lastName,mailAddresses-mail,phoneNumbers-phone'),
        searchArr: JSON.stringify(search),
        search_fields: 'firstName,lastName,mailAddresses-mail,phoneNumbers-phone,addressCity,addressCorrespondenceCity,organization-addressCorrespondenceCity,organization-addressCity,contactAddonFields-value,contactAddonFields-contactAddonFieldType-name,contactAddonFields-contactAddonFieldTypeOptions-name,contactAddonFields-contactAddonFieldTextOptions',
        fields_load: fields.CONTACTS_SEARCH,
        limit: 25,
      }
      axiosIns.get('1/contacts', { params, cancelToken: source.token })
        .then(res => res.data.data)
        .then(({ items }) => {
          const modifiedSearchArr = search.map(str => str.slice(1, -1).toLowerCase().trim());
          const searchRegex = new RegExp(modifiedSearchArr.join(' '))

          const res = items.map(i => ({
            id: i.id,
            firstName: i.firstName,
            lastName: i.lastName,
            email: i.email,
            phone: i.phone,
            contactAddonFields: i.contactAddonFields.filter(af =>
              searchRegex.test(af.value.toLowerCase())
              || searchRegex.test(af.contactAddonFieldType.name.toLowerCase())
              || af.contactAddonFieldTypeOptions.filter(option => searchRegex.test(option.name.toLowerCase())).length
              || af.contactAddonFieldTextOptions.length
            ).slice(0, 3)
          }))

          commit('SET_SEARCH_DATA', { key: 'contacts', items: res, loading: false })
          resolve()
        })
        .catch(err => {
          reject(err)
        })
    }),

    'GET_CONVERSATIONS': ({ commit }, search) => new Promise((resolve, reject) => {
      commit('SET_SEARCH_DATA', { key: 'conversations', items: [], loading: true })
      // console.log('GET_CONVERSATIONS', search)
    }),

    'GET_ORGANIZATIONS': ({ commit, getters }, search) => new Promise((resolve, reject) => {
      if (typeof source2 !== typeof undefined) source2.cancel('Operation canceled due to new request.')

      source2 = axios.CancelToken.source()

      commit('SET_SEARCH_DATA', { key: 'organizations', items: [], loading: true })

      let params = {
        searchArr: JSON.stringify(search),
        search_fields: 'name,vatId',
        fields_load: fields.ORGANIZATIONS_SEARCH
      }

      // params['eq_contacts-id'] = store.getters['auth/getCurrentUser'].id

      axiosIns.get('1/organizations', { params, cancelToken: source2.token })
        .then(res => res.data.data)
        .then(({ items }) => {

          commit('SET_SEARCH_DATA', { key: 'organizations', items: items, loading: false })
          resolve()
        })
        .catch(err => {
          reject(err)
        })
    }),
  },
}
