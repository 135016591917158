<template>
  <div>
    <!--    <b-button @click="$emit('edit', item)">-->
    <!--      {{ $t('Edit') }}-->
    <!--    </b-button>-->
    <!--    {{ item }}-->
    <div class="d-flex justify-content-between align-items-start">
      <h6>{{ $t(automationConditionTypeByValue(item.type)) }}</h6>
      <div
        v-if="showActions"
        class="d-flex"
      >
        <div
          class="text-warning cursor-pointer mr-1"
          @click="$emit('edit', item)"
        >
          <feather-icon icon="EditIcon" />
        </div>

        <div
          class="text-danger cursor-pointer"
          @click="$emit('delete')"
        >
          <feather-icon icon="TrashIcon" />
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-between w-100">
      <div class="w-100">
        <!-- Type 1 -->
        <div class="w-100">
          <div class="d-flex justify-content-between w-100">
            <div>
              <!-- Map contacts statuses -->
              <div v-if="item.type === CONTACT_STATUS">
                <b-badge
                  v-for="(value, valueIndex) in item.contactStatuses"
                  :key="`condition_item_${index}_${valueIndex}`"
                  class="mr-25"
                  variant="light-primary"
                >
                  {{ value.name }}
                </b-badge>
              </div>
              <!-- Map thread statuses -->
              <div v-else-if="item.type === CONTACT_THREAD_FUNNEL_TYPE_POINT">
                <b-badge
                  v-for="(value, valueIndex) in item.contactThreadFunnelTypePoints"
                  :key="`condition_item_${index}_${valueIndex}`"
                  class="mr-25"
                  variant="light-primary"
                >
                  {{ value.name }}
                </b-badge>
              </div>
              <div v-else-if="item.type === CONTACT_THREAD_FUNNEL_PROCESS_STATUS">
                <b-badge
                  v-for="(value, valueIndex) in item.contactThreadFunnelProcessStatuses"
                  :key="`condition_item_${index}_${valueIndex}`"
                  class="mr-25"
                  variant="light-primary"
                >
                  <span v-if="value === 'OPEN'">{{ $t('messageStatus.Open') }}</span>
                  <span v-if="value === 'PAUSED'">{{ $t('Paused') }}</span>
                  <span v-if="value === 'CLOSED_POSITIVE'">{{ $t('funnel.status.COMPLETED') }}</span>
                  <span v-if="value === 'CLOSED_NEGATIVE'">{{ $t('funnel.status.REJECTED') }}</span>
                </b-badge>
              </div>
              <div v-else-if="[CONTACT_ASSIGNED_USER, CONTACT_THREAD_ASSIGNED_USER].includes(item.type)">
                <avatar-group
                  :items="item.users"
                />
              </div>
              <div v-else-if="[CONTACT_ADDON_FIELD].includes(item.type)">
                <b-badge v-if="item.contactAddonFieldType && item.contactAddonFieldType.name"
                         variant="light-primary"
                         class="mr-25"
                >
                  {{ item.contactAddonFieldType.name }}
                </b-badge>
                <b-badge
                  v-for="(value, valueIndex) in item.values"
                  :key="`condition_item_${index}_${valueIndex}`"
                  class="mr-25"
                  variant="light-primary"
                >
                  <span v-if="value.label">
                    {{ $t(value.label) }} <span v-if="value.suffix"
                                                class="ml-25"
                    >{{ $t(value.suffix) }}</span>
                  </span>
                  <span v-else>
                    {{ $t(getAddonFieldOptionValue(value).label) }} <span v-if="getAddonFieldOptionValue(value).suffix"
                                                                          class="ml-25"
                    >{{ $t(getAddonFieldOptionValue(value).suffix) }}</span>
                  </span>
                </b-badge>
                <b-badge variant="light-primary" v-if="item.optionIgnoreYear"
                         class="mr-25"
                >
                  {{ $t('IgnoreAge') }}
                </b-badge>
              </div>
              <!-- Map other values -->
              <div v-else>
                <b-badge
                  v-for="(value, valueIndex) in item.values"
                  :key="`condition_item_${index}_${valueIndex}`"
                  class="mr-25"
                  variant="light-primary"
                >
                  <!--                    Constants values need to check for label-->
                  <span v-if="value.name">
                    <span v-if="$te(value.name)">
                      {{ $t(value.name) }}
                    </span>
                    <span v-else-if="$te(`offer.status.${value.name}`)">
                      {{ $t(`${value.name}`) }}
                    </span>
                    <span v-else>
                      {{ value.name }}
                    </span>
                  </span>
                  <span v-else-if="value.label">
                    <span v-if="$te(value.label)">
                      {{ $tc(value.label, 1) }}
                    </span>
                    <span v-else-if="$te(`offer.status.${value.label}`)">
                      {{ $tc(`${value.label}`, 1) }}
                    </span>
                    <span v-else>
                      {{ value.label }}
                    </span>
                  </span>
                  <span v-else>
                    <span v-if="$te(value)">
                      {{ $tc(value, 1) }}
                    </span>
                    <span v-else-if="$te(`offer.status.${value}`)">
                      {{ $tc(`offer.status.${value}`, 1) }}
                    </span>
                    <span v-else>
                      {{ value }}
                    </span>
                  </span>
                </b-badge>
              </div>
            </div>
          </div>
        </div>
        <!-- Type 2 -->
      </div>
    </div>
  </div>
</template>

<script>
import {
  automationConditionTypeByValue,
  CONTACT_APPLICATION_INTEGRATION_TYPE,
  CONTACT_ASSIGNED_USER,
  CONTACT_STATUS,
  CONTACT_THREAD_ASSIGNED_USER,
  CONTACT_THREAD_CART_OFFER_STATUS,
  CONTACT_THREAD_FUNNEL_PROCESS_STATUS,
  CONTACT_THREAD_FUNNEL_TYPE_POINT,
  CONTACT_ADDON_FIELD,
} from '@/@types/automationsTypes'

export default {
  name: 'AutomationDetailsCondition',
  props: {
    item: {
      default: null,
      required: true,
    },
    index: {
      default: null,
      required: true,
    },
    showActions: {
      default: true,
      required: false,
      type: Boolean,
    },
  },
  data: () => ({
    CONTACT_STATUS,
    CONTACT_THREAD_FUNNEL_TYPE_POINT,
    CONTACT_THREAD_CART_OFFER_STATUS,
    CONTACT_APPLICATION_INTEGRATION_TYPE,
    CONTACT_ASSIGNED_USER,
    CONTACT_THREAD_ASSIGNED_USER,
    CONTACT_THREAD_FUNNEL_PROCESS_STATUS,
    CONTACT_ADDON_FIELD,

    addonContactFieldOptions: [
      { value: 'now', label: 'IsToday' },
      ...(new Array(10)).fill(null).map((e, i) => ({ value: `+${i + 1} day`, label: i + 1, suffix: 'AutomationDaysBefore' })),
      ...(new Array(10)).fill(null).map((e, i) => ({ value: `-${i + 1} day`, label: i + 1, suffix: 'AutomationDaysAfter' })),
    ],
  }),
  methods: {
    automationConditionTypeByValue,
    getAddonFieldOptionValue(field) {
      return this.addonContactFieldOptions.find(e => e.value === field)
    },
  },
}
</script>

<style scoped>

</style>
