<!--eslint-disable-->

<template>
    <b-row class="mx-0 px-0 cursor-pointer mt-25">
        <!--    Option: Address    -->
        <b-col sm="12">
            <b-row>
                <b-col sm="12">
                    <b-form-group>
                        <b-form-input
                            id="popover-footerlabl--0"
                            v-model.trim="item.footerTitle"
                            :placeholder="$t('Title')"
                            max-length="50"
                            disabled
                        />
                    </b-form-group>

                    <!--    Popover    -->
                    <b-popover
                        target="popover-footerlabl--0"
                        triggers="focus"
                        variant="primary"
                    >
                        <span>{{ $t('MaximumLength') + ': 50' }}</span>
                    </b-popover>
                </b-col>
            </b-row>

            <b-row>
                <b-col sm="12"
                       md="6"
                       lg="4"
                >
                    <b-form-group>
                        <b-form-input
                        id="popover-footerAddress1"
                        v-model.trim="item.footerAddress1"
                        :placeholder="$t('lineOne')"
                        disabled
                        max-length="50"
                    />
                    </b-form-group>

                    <!--    Popover    -->
                    <b-popover
                        target="popover-footerAddress1"
                        triggers="focus"
                        variant="primary"
                    >
                        <span>{{ $t('MaximumLength') + ': 50' }}</span>
                    </b-popover>
                </b-col>
                <b-col sm="12"
                       md="6"
                       lg="4"
                >
                    <b-form-group>
                        <b-form-input
                        id="popover-footerAddress2"
                        v-model.trim="item.footerAddress2"
                        :placeholder="$t('lineTwo')"
                        disabled
                        max-length="50"
                    />
                    </b-form-group>

                    <!--    Popover    -->
                    <b-popover
                        target="popover-footerAddress2"
                        triggers="focus"
                        variant="primary"
                    >
                        <span>{{ $t('MaximumLength') + ': 50' }}</span>
                    </b-popover>
                </b-col>
                <b-col sm="12"
                       md="6"
                       lg="4"
                >
                    <b-form-group>
                        <b-form-input
                        id="popover-footerAddress3"
                        v-model.trim="item.footerAddress3"
                        :placeholder="$t('lineThree')"
                        disabled
                        max-length="50"
                    />
                    </b-form-group>

                    <!--    Popover    -->
                    <b-popover
                        target="popover-footerAddress3"
                        triggers="focus"
                        variant="primary"
                    >
                        <span>{{ $t('MaximumLength') + ': 50' }}</span>
                    </b-popover>
                </b-col>
            </b-row>
        </b-col>

        <b-col
            md="4"
        >
            <b-form-group>
                <b-form-input
                id="popover-footerName-0"
                v-model.trim="item.footerLinks[0].name"
                :placeholder="$t('Name')"
                disabled
                max-length="50"
            />
            </b-form-group>

            <!--    Popover    -->
            <b-popover
                target="popover-footerName-0"
                triggers="focus"
                variant="primary"
            >
                <span>{{ $t('MaximumLength') + ': 50' }}</span>
            </b-popover>
        </b-col>

        <b-col
            md="8"
        >
            <b-form-group>
                <b-form-input
                v-model.trim="item.footerLinks[0].link"
                :placeholder="$t('Link')"
                disabled
            />
            </b-form-group>
        </b-col>
        <b-col
            md="4"
        >
            <b-form-group>
                <b-form-input
                id="popover-footerName-1"
                v-model.trim="item.footerLinks[1].name"
                :placeholder="$t('Name')"
                disabled
                max-length="50"
            />
            </b-form-group>

            <!--    Popover    -->
            <b-popover
                target="popover-footerName-1"
                triggers="focus"
                variant="primary"
            >
                <span>{{ $t('MaximumLength') + ': 50' }}</span>
            </b-popover>
        </b-col>
        <b-col
            md="8"
        >
            <b-form-group>
                <b-form-input
                v-model.trim="item.footerLinks[1].link"
                :placeholder="$t('Link')"
                disabled
            />
            </b-form-group>
        </b-col>
        <b-col
            md="4"
        >
            <b-form-group>
                <b-form-input
                id="popover-footerName-2"
                v-model.trim="item.footerLinks[2].name"
                :placeholder="$t('Name')"
                disabled
                max-length="50"
            />
            </b-form-group>

            <!--    Popover    -->
            <b-popover
                target="popover-footerName-2"
                triggers="focus"
                variant="primary"
            >
                <span>{{ $t('MaximumLength') + ': 50' }}</span>
            </b-popover>
        </b-col>

        <b-col
            md="8"
        >
            <b-form-group>
                <b-form-input
                v-model.trim="item.footerLinks[2].link"
                :placeholder="$t('Link')"
                disabled
            />
            </b-form-group>
        </b-col>

        <!--    Option: Description    -->
        <b-col sm="12">
            <div>
                <b-form-group>
                    <b-form-textarea
                        id="popover-footerDesc"
                        v-model.trim="item.footerDescription"
                        :placeholder="$t('Description')"
                        max-length="170"
                        disabled
                    />
                </b-form-group>

                <!--    Popover    -->
                <b-popover
                    target="popover-footerDesc"
                    triggers="focus"
                    variant="primary"
                    placement="top"
                >
                    <span>{{ $t('MaximumLength') + ': 170' }}</span>
                </b-popover>
            </div>
        </b-col>

    </b-row>
</template>

<script>
import { BFormTextarea, BPopover } from 'bootstrap-vue'

export default {
  components: {
    BPopover,
    BFormTextarea,
  },
  props: ['item', 'defImg', 'translationNr'],
  emits: ['delete', 'edit'],
  data: () => ({
    id: `${Math.random().toString(36).substr(2, 9)}`,
  }),
  methods: {
  },
}
</script>
