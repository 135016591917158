<template>
  <component
    :is="tag"
    v-on="$listeners"
  >
    <b-avatar
      size="42"
      badge
      class="badge-minimal"
      badge-variant="primary"
      :text="(user.firstName && user.firstName[0].toUpperCase()) + (user.lastName && user.lastName[0].toUpperCase())"
      :src="user.avatar"
    />

    <div class="d-flex flex-column w-100">
      <div class="chat-info d-flex flex-column w-100">
        <h5 class="mb-0 w-100">
          {{ user.firstName }} {{ user.lastName }}
        </h5>

        <p class="card-text text-nowrap">
          {{ isChatContact ? user.message : user.email | truncate(34, '…') }}
        </p>
      </div>

      <div
        v-if="isChatContact"
        class="chat-meta text-nowrap mt-md-n25"
      >
        <small class="float-right chat-time">
          {{
            (formatDate(new Date(user.createdAt), { month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' }, $i18n.locale) ||
              user.createdAt.split('.')[0]) | truncate(24, '')
          }}
        </small>
        <b-badge
          v-if="user.chatConversationMessagesUnreadAmount"
          pill
          variant="primary"
          class="float-right"
        >
          {{ user.chatConversationMessagesUnreadAmount }}
        </b-badge>
      </div>
    </div>
  </component>
</template>

<script>
import { formatDate } from '@core/utils/filter'

export default {
  name: 'ChatUser',
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    user: {
      type: Object,
      required: true,
    },
    isChatContact: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    formatDate,
  },
}
</script>

<style scoped>

</style>
