import {
  DELETE_PRODUCT,
  SAVE_PRODUCT,
  UPDATE_PRODUCT,
  GET_PRODUCT,
  GET_PRODUCT_ATTRIBUTES,
  UPDATE_PRODUCT_ATTRIBUTES,
  SAVE_PRODUCT_ATTRIBUTES,
  DELETE_PRODUCT_ATTRIBUTES,
  GET_PRODUCTS,
  GET_PRODUCT_STORAGE,
  UPDATE_PRODUCT_STORAGE,
  CREATE_PRODUCT_STORAGE,
} from '@/@constants/mutations'
// eslint-disable-next-line import/no-cycle
import axiosIns from '@/libs/axios'
import * as fields from '@/@constants/fields'
import axios from 'axios'
/* eslint-disable */

const getAttributeFilters = (filters, params) => {
  let count = 0
  filters.forEach((filter, index) => {
    if (['TIME', 'DATE'].includes(filter.type) && filter?.value?.length) {
      const value = filter.value.dateRange()
      if (value.length) {
        params[`eq_${index}_shopProductAttributes-shopAttribute-id`] = filter.id
        if (value[0] || value[1]) {
          count++
        }
        // GET MIN RANGE VAL
        if (value[0]) {
          params[`gte_${index}_shopProductAttributes-value`] = value[0]
        }
        // GET MAX RANGE VAL
        if (value[1]) {
          params[`lte_${index}_shopProductAttributes-value`] = value[1]
        }
      }
    } else if (['PERCENTAGE', 'TEXT', 'METRIC'].includes(filter.type)) {
      if (filter.value && filter.value.length) {
        params[`eq_${index}_shopProductAttributes-shopAttribute-id`] = filter.id
        params[`eq_${index}_shopProductAttributes-value`] = filter.value
        count++
      }
    } else if (filter?.value && ((Array.isArray(filter.value) && filter.value.length) || (typeof filter.value === 'object' && filter.value?.id))) {
      if (['TEXT_ARRAY', 'TEXT_MULTIPLE_ARRAY'].includes(filter.type)) {
        // GET VALUES FROM ARRAY
        const value = Array.isArray(filter.value) ? (filter.value.map(item => (typeof item === 'string' ? item : item.id))) : (typeof filter.value === 'object' ? filter.value.id : filter.value)
        if (value) {
          params[`eq_${index}_shopProductAttributes-shopAttribute-id`] = filter.id
          params[`eqArr_${index}_shopProductAttributes-valueShopAttributeOptions-id`] = JSON.stringify(Array.isArray(value) ? [...value] : [value])
          if (Array.isArray(value)) count += value.length
          else count++
        }
      } else if (['DIGITAL'].includes(filter.type)) {
        if (filter.value[0] || filter.value[1]) count++
        // GET MIN RANGE VAL
        if (filter.value[0]) {
          params[`eq_${index}_shopProductAttributes-shopAttribute-id`] = filter.id
          params[`gte_${index}_shopProductAttributes-valueFloat`] = filter.value[0]
        }
        // GET MAX RANGE VAL
        if (filter.value[1]) {
          params[`eq_${index}_shopProductAttributes-shopAttribute-id`] = filter.id
          params[`lte_${index}_shopProductAttributes-valueFloat`] = filter.value[1]
        }
      }
    }
  })
  return count
}

const addFiltersToAttributeGroups = (params, category = null, status = null, group = null, priceMin = null, priceMax = null, search, loyaltyPlan = null, marketingPlan = null) => {
  Object.keys(params).forEach(paramKey => {
    if (paramKey.includes('shopProductAttributes-shopAttribute-id')) {
      const items = paramKey.split('_')
      const index = items[1]

      if (category !== null) params[`eq_${index}_shopCategories-id`] = category
      else if (group !== null) params[`eq_${index}_shopCategories-userGroups-id`] = status

      if (status !== null) params[`eq_${index}_active`] = status
      if (loyaltyPlan !== null) params[`eq_${index}_shopProductLoyaltyPlans-loyaltyPlan-id`] = loyaltyPlan
      if (marketingPlan !== null) params[`eq_${index}_shopProductMarketingPlans-marketingPlan-id`] = marketingPlan
      if (priceMin && priceMin !== '0') params[`gte_${index}_offerPrice`] = parseFloat(priceMin)
      if (priceMax && priceMax !== '0') params[`lte_${index}_offerPrice`] = parseFloat(priceMax)
      if (search && search.length) {
        params[`like_${index}_translations-name`] = `%${search}%`
        // params.search_fields = 'translations-name'
      }
      // if (addonValue1 !== null) params[`eq_${index}_addonValue1`] = addonValue1
    }
  })
}

let source

export default {
  namespaced: true,
  actions: {
    // eslint-disable-next-line no-unused-vars
    [GET_PRODUCT]: ({ commit }, id) => new Promise((resolve, reject) => {
      axiosIns.get(`1/shops/products/${id}`, { params: { fields_load: fields.PRODUCT, orderBy: 'createdAt.desc', } })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [GET_PRODUCTS]: ({ commit }, payload) => new Promise((resolve, reject) => {
      if (!payload.skip) {
        if (typeof source !== typeof undefined) source.cancel('Operation canceled due to new request.')
        source = axios.CancelToken.source()
      }

      const {
        pagination, search, filters, attributeFilters, sorter, withFields = null
      } = payload

      const params = {
        orderBy: 'createdAt.desc',
      }

      if (!withFields) params.fields_load = fields.PRODUCT_LIST
      else params.fields_load = withFields

      if (sorter) {
        const sortDir = sorter?.sortDesc ? 'desc' : 'asc'
        const sortBy = sorter?.sortBy || 'id'
        params.orderBy = `${sortBy}.${sortDir}`
      }

      if (pagination) {
        params.page = pagination.currentPage
        params.limit = pagination.perPage
      }

      if (attributeFilters) {
        // eslint-disable-next-line prefer-const
        const count = getAttributeFilters(attributeFilters, params)
        if (count) params.district_count = count
      }

      if (filters) {
        if (params.district_count) {
          if (filters.category) {
            addFiltersToAttributeGroups(params, filters.category)
          }
          if (filters.subcategory) {
            addFiltersToAttributeGroups(params, filters.subcategory)
          }
          if (filters.status) {
            addFiltersToAttributeGroups(params, null, filters.status !== 'Inactive' ? 1 : 0)
          }
          if (filters.group) {
            addFiltersToAttributeGroups(params, null, null, filters.group)
          }
          if (filters.loyaltyPlan) {
            addFiltersToAttributeGroups(params, null, null, null, null, null, null, filters.loyaltyPlan)
          }

          if (filters.marketingPlan) {
            addFiltersToAttributeGroups(params, null, null, null, null, null, null, null, filters.marketingPlan)
          }

          if (filters.priceMin || filters.priceMax) {
            addFiltersToAttributeGroups(params, null, null, null, filters.priceMin, filters.priceMax)
          }
          if (search && search.length) {
            addFiltersToAttributeGroups(params, null, null, null, null, null, search)
          }

        } else {
          if (filters.subcategory) {
            params[`eq_shopCategories-id`] = filters.subcategory
          }
          else if (filters.category) {
            params[`eq_shopCategories-id`] = filters.category
          }
          else if (filters.group) {
            params[`eq_shopCategories-userGroups-id`] = filters.group
          }

          if (filters.loyaltyPlan) {
            params[`eq_shopProductLoyaltyPlans-loyaltyPlan-id`] = filters.loyaltyPlan
          }
          if (filters.marketingPlan) {
            params[`eq_shopProductMarketingPlans-marketingPlan-id`] = filters.marketingPlan
          }
          if (filters.status) {
            params[`eq_active`] = filters.status !== 'Inactive' ? 1 : 0
          }
          if (filters.priceMin) params[`gte_offerPrice`] = filters.priceMin
          if (filters.priceMax) params[`lte_offerPrice`] = filters.priceMax

          if (search && search.length) {
            params.search = `%${search}%`
            params.search_fields = 'translations-name'
          }
        }
      } else if (params.district_count) {
        if (search && search.length) {
          addFiltersToAttributeGroups(params, null, null, null, null, null, search)
        }
      } else if (search && search.length) {
        params.search = `%${search}%`
        params.search_fields = 'translations-name'
      }

      axiosIns.get('1/shops/products', { params, cancelToken: source.token })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [DELETE_PRODUCT]: ({ commit }, id) => new Promise((resolve, reject) => {
      axiosIns.delete('1/shops/products', { data: [id] })
        .then(() => {
          resolve()
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [UPDATE_PRODUCT]: ({ commit }, product) => new Promise((resolve, reject) => {
      axiosIns.patch('1/shops/products', product)
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [SAVE_PRODUCT]: ({ commit }, product) => new Promise((resolve, reject) => {
      axiosIns.post('1/shops/products', product)
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [GET_PRODUCT_ATTRIBUTES]: ({ commit }, id) => new Promise((resolve, reject) => {
      axiosIns.get(`1/shops/products/${id}/attributes`, { params: { fields_load: fields.PRODUCT_ATTRIBUTES } })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [UPDATE_PRODUCT_ATTRIBUTES]: ({ commit }, { id, attributes }) => new Promise((resolve, reject) => {
      if (attributes.length) {
        axiosIns.patch(`1/shops/products/${id}/attributes`, attributes)
          .then(res => {
            resolve(res.data)
          })
          .catch(err => {
            reject(err)
          })
      } else resolve()
    }),
    // eslint-disable-next-line no-unused-vars
    [SAVE_PRODUCT_ATTRIBUTES]: ({ commit }, { id, attributes }) => new Promise((resolve, reject) => {
      if (attributes.length) {
        axiosIns.post(`1/shops/products/${id}/attributes`, attributes)
          .then(res => {
            resolve(res.data)
          })
          .catch(err => {
            reject(err)
          })
      } else resolve()
    }),
    // eslint-disable-next-line no-unused-vars
    [DELETE_PRODUCT_ATTRIBUTES]: ({ commit }, { id, attributesIdList }) => new Promise((resolve, reject) => {
      if (attributesIdList.length) {
        axiosIns.delete(`1/shops/products/${id}/attributes`, { data: attributesIdList })
          .then(res => {
            resolve(res.data)
          })
          .catch(err => {
            reject(err)
          })
      } else resolve()
    }),
    [GET_PRODUCT_STORAGE]: ({ commit }, { productId, pagination }) => new Promise((resolve, reject) => {
      const params = {
        fields_load: fields.SHOP_PRODUCTS_STORAGE
      }

      if (pagination) {
        params.page = pagination.currentPage
        params.limit = pagination.perPage
      }

      axiosIns.get(`1/shops/products/${productId}/storages`, { params })
          .then(res => {
            resolve(res.data)
          })
          .catch(err => {
            reject(err)
          })
    }),
    [UPDATE_PRODUCT_STORAGE]: ({ commit }, { productId }) => new Promise((resolve, reject) => {
      axiosIns.patch(`1/shops/products/${productId}/storages`)
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
    }),
    [CREATE_PRODUCT_STORAGE]: ({ commit }, { productId, storageItems }) => new Promise((resolve, reject) => {
      axiosIns.post(`1/shops/products/${productId}/storages`, storageItems)
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
    }),
  },
}

// Test
