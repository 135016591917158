<template>
<!--    eslint-disable -->
  <sw-table
    table-id="268111d8432fd194e412354f3dc6ff4b3aafcf"
    show-columns
    show-search
    :fields.async="fields"
    :pagination="pagination"
    @change-pagination="Object.assign(pagination, $event)"
    @change-search-query="search = $event"
    @reload-content="initData"
    @set-fields="fields = $event"
  >
    <template #table>
      <!--      Table      -->
      <b-table
        :items="items"
        :fields="tableFields"
        striped
        responsive
        :style="{ minHeight: tableSize(items, 100) }"
        :busy="loading"
        show-empty
        :sort-by.sync="sorter.sortBy"
        :sort-desc.sync="sorter.sortDesc"
        :no-local-sorting="true"
        @sort-changed="Object.assign(sorter, $event); initData()"
      >
        <!--    Head    -->
        <template #head()="{ label }">
          {{ $t(`${label}`) }}
        </template>
        <template #cell(id)="{ index }">
          #{{ getOrderNo(index, pagination, sorter) }}
        </template>

          <template #cell(name)="{ item }">
              <span v-if="item.contactThreadGoogleAdsClientAccount">
                  {{ item.contactThreadGoogleAdsClientAccount.name }}
              </span>
        </template>

          <template #cell(status)="{ value }">
              <b-badge :variant="getStatusByValue(value).color" v-if="getStatusByValue(value)">
                  {{ $t(getStatusByValue(value).label) }}
              </b-badge>
          </template>
          <template #cell(contactThread)="row">
        <span v-if="row.item.contactThreadGoogleAdsClientAccount && row.item.contactThreadGoogleAdsClientAccount.contactThread">
          <router-link :class="{ 'deleted-text': row.item.contactThreadGoogleAdsClientAccount.contactThread.contact && row.item.contactThreadGoogleAdsClientAccount.contactThread.contact.deletedAt }"
                       :to="`/contact/${row.item.contactThreadGoogleAdsClientAccount.contactThread.contact.id}/thread/${row.item.contactThreadGoogleAdsClientAccount.contactThread.id}/googleAds`"
          >
              {{ getUserName(row.item.contactThreadGoogleAdsClientAccount.contactThread.contact) }} - {{ row.item.contactThreadGoogleAdsClientAccount.contactThread.name }}
          </router-link>
        </span>
              <span v-else>
          <feather-icon icon="MinusIcon" />
        </span>
          </template>

        <template #cell()="row">
          <table-default-cell
            :field="row.field"
            :value="row.value"
          />
        </template>
        <template #empty>
          <empty-content />
        </template>

        <!--      Table Busy      -->
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner
              class="align-middle"
              variant="primary"
            />
          </div>
        </template>
      </b-table>
    </template>
  </sw-table>
</template>

<script>
import axiosIns from '@/libs/axios'
import { BTable } from 'bootstrap-vue'

export default {
  name: 'GoogleAccountLogs',
  components: {
    BTable,
  },
  props: {
    thread: {
      default: null,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    items: [],
    fields: [
      { key: 'id', label: '#', visible: true },
      { key: 'name', label: 'Name', visible: true },
      { key: 'status', label: 'StatusChangesTo', visible: true },
      // { key: 'googleAdsCampaignIds', label: 'Campaigns', visible: true },
      { key: 'createdAt', label: 'CreatedAt', visible: true },
      {
        key: 'createdBy', label: 'CreatedBy', visible: true, tag: 'avatar',
      },
      {
        key: 'contactThread', label: 'Thread', visible: true, onlyGlobal: true,
      },
    ],
    sorter: {
      sortBy: 'id',
      sortDesc: true,
    },
    pagination: {
      perPage: 25,
      currentPage: 1,
      totalRecords: 0,
    },
    search: '',
  }),

  computed: {
    visibleFields() {
      return this.fields
        .filter(field => !field?.requiredModule || this.checkRequiredModule(field?.requiredModule))
        .filter(field => !field.onlyGlobal || (this.$route.name === 'google-ads' && field.onlyGlobal))
    },
    tableFields() {
      return this.visibleFields.filter(field => field.visible)
    },
  },

  mounted() {
    this.initData()
  },

  methods: {
    getStatusByValue(value) {
      const a = [
        { label: 'SessionActive', v: 'ACTIVE', color: 'light-success' },
        { label: 'SessionInactive', v: 'INACTIVE', color: 'light-danger' },
      ]
      if (a.findWhere('v', value)) return a.findWhere('v', value)
      return null
    },
    async initData() {
      try {
        this.loading = true
        const params = {
          fields_load: 'GOOGLE_ADS_LOG',
          orderBy: 'id.desc',
        }

        if (this.pagination) {
          params.page = this.pagination.currentPage
          params.limit = this.pagination.perPage
        }

        if (this.sorter) {
          const sortDir = this.sorter?.sortDesc ? 'desc' : 'asc'
          const sortBy = this.sorter?.sortBy || 'id'
          params.orderBy = `${sortBy}.${sortDir}`
        }

        // if (this.search.length) {
        //   const searchString = this.search.replaceAll(' ', '%')
        //   params.search = `%${searchString}%`
        //   params.search_fields = []
        // }

        const resp = await axiosIns.get(this.thread ? `1/contacts/threads/${this.getObjectId(this.thread)}/googleAdsClientAccounts/logs` : '1/contacts/threads/googleAdsClientAccounts/logs', { params })

        this.items = resp.data.data?.items
        this.pagination.totalRecords = resp.data.data?.totalItemCount || 0
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>

</style>
