<template>
  <div
    v-if="!loading"
    key="applications"
  >
    <template v-if="items.length">
      <card-info
        v-for="(application, i) in items"
        :key="`application_${i}`"
        :item="application"
        title-max="40"
        :action-remove="checkRequiredPermissions([$roles.APPLICATION_DELETE])"
        @remove="deleteApplication(application, i)"
      >
        <template #start>
          <div class="d-flex align-items-center gap-0.34">
            <template v-if="resolveApplicationType(application)">
              <strong
                v-if="application.integrationFacebookWebhook"
                :title="application.integrationFacebookWebhook.name"
              >
                {{ application.integrationFacebookWebhook.name | truncate(20) }}
              </strong>
              <strong v-else-if="application.clientChatMessage && application.clientChatMessage.clientChat && application.clientChatMessage.clientChat.browserSession && application.clientChatMessage.clientChat.browserSession.widgetChat">
                <strong>{{ application.clientChatMessage.clientChat.browserSession.widgetChat.name }}</strong>
              </strong>
              <strong v-else-if="application.clientChatMessage && application.clientChatMessage.clientChat && application.clientChatMessage.clientChat.integrationWhatsAppSubscriptionPhoneNumber && application.clientChatMessage.clientChat.integrationWhatsAppSubscriptionPhoneNumber.whatsAppPhoneNumberDisplay">
                <strong>{{ application.clientChatMessage.clientChat.integrationWhatsAppSubscriptionPhoneNumber.whatsAppPhoneNumberDisplay }}</strong>
              </strong>
              <strong
                v-else-if="application.contactApplicationIntegration"
                :title="application.contactApplicationIntegration.name"
              >
                {{ application.contactApplicationIntegration.name | truncate(20) }}
              </strong>
              <strong
                v-else-if="application.pluginMake"
                :title="application.pluginMake.name"
              >
                {{ application.pluginMake.name | truncate(20) }}
              </strong>
              <strong
                v-else-if="application.pluginZapier"
                :title="application.pluginZapier.name"
              >
                {{ application.pluginZapier.name | truncate(20) }}
              </strong>
              |
            </template>

            <span>{{ resolveApplicationTitle(application) }}</span>
          </div>
        </template>

        <template #end>
          <avatar-group
            v-if="application.contactThread.contact"
            :to="protectGetUsers ? { name: 'user' } : undefined"
            :items="application.contactThread.contact.contactAssignedUsers"
            items-field="user"
            :max="3"
            size="16"
            force-redirect
            show-more
          />
        </template>

        <p class="h5 mb-50">
          {{ $t('BasicInformation') }}:
        </p>

        <b-list-group
          horizontal="md"
          class="list-group:border-radius-bottom-0"
        >
          <b-list-group-item
            v-if="getUserName(application).length"
            class="flex-grow-1"
          >
            {{ getUserName(application) }}
          </b-list-group-item>

          <b-list-group-item
            v-if="isMailExists || application.mailAddress"
            class="flex-grow-1"
          >
            <feather-icon
              v-if="isMailExists"
              :id="`email_exist_button_${key}`"
              icon="AlertCircleIcon"
              size="12"
            />

            <b-popover
              v-if="isMailExists"
              :target="`email_exist_button_${key}`"
              triggers="hover"
            >
              <template #title>
                <span>{{ $t('TypedEmailExist') }}</span>
              </template>
              <div
                v-for="existedContact in isMailExists.contacts"
                :key="`contact_email_${existedContact.id}_${key}`"
              >
                <router-link :to="{ name: 'contact', params: { contactId: existedContact.id } }">
                  {{ existedContact.firstName }} {{ existedContact.lastName }}
                </router-link>
              </div>
              <!--                        test@mail-saleswizardapp.com-->
            </b-popover>

            <span
              v-if="application.mailAddress"
              v-b-tooltip
              :title="application.mailAddress.mail"
            >
              {{ application.mailAddress.mail | truncate(25) }}
            </span>

            <feather-icon
              v-else
              icon="MinusIcon"
            />
          </b-list-group-item>

          <b-list-group-item
            v-if="isPhoneExists || application.phoneNumber"
            class="flex-grow-1"
          >
            <feather-icon
              v-if="isPhoneExists"
              :id="`phone_exist_button_${key}`"
              icon="AlertCircleIcon"
              size="12"
              class="mr-25"
            />
            <b-popover
              v-if="isPhoneExists"
              :target="`phone_exist_button_${key}`"
              triggers="hover"
            >
              <template #title>
                <span>{{ $t('TypedPhoneExist') }}</span>
              </template>
              <div
                v-for="existedContact in isPhoneExists.contacts"
                :key="`contact_phone_${existedContact.id}_${key}`"
              >
                <router-link :to="{ name: 'contact', params: { contactId: existedContact.id } }">
                  {{ existedContact.firstName }} {{ existedContact.lastName }}
                </router-link>
              </div>
              <!--                        test@mail-saleswizardapp.com-->
            </b-popover>
            <span v-if="application.phoneNumber">
              {{ application.phoneNumber.phone }}
            </span>
            <feather-icon
              v-else
              icon="MinusIcon"
            />
          </b-list-group-item>
        </b-list-group>

        <b-collapse :id="`application_${i}-collapse`">
          <div class="d-flex flex-column w-100 border-left border-right">

            <b-list-group flush>
              <b-list-group-item
                v-for="(field, j) in application.contactApplicationFields"
                :key="`field_${i}_${j}`"
                class="d-flex flex-wrap justify-content-between"
              >
                <span
                  v-if="$te(`ApplicationsFields.${field.type}`)"
                  v-b-tooltip
                  :title="$t(`ApplicationsFields.${field.type}`)"
                >
                  {{ $t(`ApplicationsFields.${field.type}`) | textIsland(45) }}
                </span>

                <span
                  v-else
                  v-b-tooltip
                  :title="typeName(field.type)"
                >
                  {{ typeName(field.type) | textIsland(45) }}
                </span>

                <strong v-if="field.value">{{ field.value }}</strong>

                <feather-icon
                  v-else
                  icon="MinusIcon"
                />
              </b-list-group-item>
            </b-list-group>

            <div
              v-if="application.message"
              class="px-3 px-md-3 px-lg-5"
            >
              <b-alert
                show
                variant="primary"
              >
                <div class="alert-heading">
                  {{ $t('Message') }}
                </div>
                <div class="alert-body">
                  {{ application.message }}
                </div>
              </b-alert>
            </div>

            <div
              class="px-3 px-md-3 px-lg-5"
            >
              <b-alert
                v-if="application.contactApplicationStatusReason || application.note"
                show
                variant="primary"
              >
                <div class="alert-heading">
                  {{ $t('Reason') }}:
                  <span v-if="application.contactApplicationStatusReason">
                    {{ application.contactApplicationStatusReason.name }}
                  </span>
                  <feather-icon
                    v-else
                    icon="MinusIcon"
                  />
                </div>

                <div class="alert-body">
                  {{ $t('Note') }}:
                  <template v-if="application.note">
                    {{ application.note }}
                  </template>
                  <feather-icon
                    v-else
                    icon="MinusIcon"
                  />
                </div>
              </b-alert>
            </div>
          </div>
        </b-collapse>

        <div
          v-b-toggle="`application_${i}-collapse`"
          class="w-100 bg-light-primary rounded-bottom text-center"
        >
          <feather-icon icon="ChevronDownIcon" />
        </div>

        <template #footer-end>
          <div
            v-if="application.leadgenCreatedAt"
            v-b-tooltip.hover.v-primary
            class="d-flex align-items-center"
            style="gap: .34rem"
            :title="$t('LeadgenCreatedAt')"
          >
            <sw-icon
              icon="ClockIcon"
              style="margin-top: -.1rem"
            />

            {{ application.leadgenCreatedAt.date | dateFormat }}
          </div>
        </template>
      </card-info>
    </template>

    <div
      v-else
      class="p-1 text-center text-primary"
    >
      <sw-icon icon="XCircleIcon" />

      {{ $t('NoData') }}
    </div>
  </div>

  <div
    v-else
    class="d-flex justify-content-center"
  >
    <b-spinner
      variant="primary"
      class="m-2"
    />
  </div>
</template>

<script>
import {
  BCollapse,
  BPopover, VBToggle,
  VBTooltip,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import axiosIns from '@/libs/axios'
import CardInfo from '@core/components/@sw/modern/CardInfo.vue'
import { DELETE_APPLICATION, GET_APPLICATIONS } from '@/@constants/mutations'
import i18n from '@/libs/i18n'

export default {
  name: 'SwApplications',
  components: {
    BPopover,
    CardInfo,
    BCollapse,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
  },
  props: {
    contact: {
      default: null,
      type: Object,
    },
    isMailExists: {
      type: [Boolean, Object],
      default: null,
    },
    isPhoneExists: {
      type: [Boolean, Object],
      default: null,
    },
    thread: {
      default: null,
    },
  },
  data: () => ({
    loading: false,

    showCollapse: false,
    items: [],
    sorter: {
      sortBy: 'createdAt',
      sortDesc: true,
    },
    pagination: {
      perPage: 1000,
      currentPage: 1,
      totalRecords: 0,
    },
  }),
  watch: {
    contact: {
      deep: true,
      handler(newValue) {
        if (newValue?.id) {
          this.reloadContent()
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      statuses: 'typeSettings/geContactStatuses',
      user: 'auth/getCurrentUser',
    }),
    protectGetUsers() {
      return this.checkRequiredPermissions([this.$roles.USER_VIEW_ALL, this.$roles.USER_VIEW_STRUCTURE])
    },
  },
  async mounted() {
    this.reloadContent()

    try {
      const params = { fields_load: this.$fields.CONTACT_APPLICATION_CLOSE_REASONS, orderBy: 'position.asc' }
      const resp = await axiosIns.get('1/settings/contactApplicationStatusReasons', { params })

      if (resp?.data?.data?.items) {
        const items = resp.data?.data?.items || []
        this.reasons = items
      }
    } catch (err) {
      this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
    }
  },
  methods: {
    async checkExistedEmails() {
      let emails = this.items.map(({ mailAddress }) => {
        if (mailAddress?.mail) return mailAddress.mail

        return null
      }).filter(Boolean)
      emails = emails?.filter(Boolean)
      if (!emails?.length) return

      try {
        const resp = await axiosIns.post('1/contacts/getExistsByEmails', emails)

        if (resp?.data?.data?.exists) this.existedEmails = resp.data.data.exists || []
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    async checkExistedPhones() {
      this.existedPhones = []
      const phones = this.items.map(({ phoneNumber }) => {
        if (phoneNumber?.phone) return phoneNumber.phone

        return null
      }).filter(Boolean)

      if (!phones?.length) return

      try {
        const resp = await axiosIns.post('1/contacts/getExistsByPhones', phones)

        if (resp?.data?.data?.exists) this.existedPhones = resp.data.data?.exists || []
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    reloadContent() {
      this.loading = true
      this.$nextTick(() => {
        const payload = {
          pagination: this.pagination,
          filters: this.filters,
          contact: this.isGlobalRoute ? null : this.contact,
          thread: this.isGlobalRoute ? null : this.thread,
        }

        if (this.isGlobalRoute || (!this.isGlobalRoute && this.contact?.contactThreads?.length)) {
          this.$store.dispatch(`applications/${GET_APPLICATIONS}`, payload)
            .then(res => {
              this.items = res.data.items
              this.pagination.totalRecords = res.data.totalItemCount
              this.loading = false

              this.checkExistedEmails()
              this.checkExistedPhones()
            })
            .catch(err => {
              this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
              this.loading = false
            })
        } else this.loading = false
      })
    },
    deleteApplication({ id }, index) {
      this.showAlert('error', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('alerts.WontbeAble'))
        .then(() => {
          this.$store.dispatch(`applications/${DELETE_APPLICATION}`, id)
            .then(() => {
              this.showToast('success', this.$i18n.t('RemovedSuccessfully'))
              this.items.splice(index, 1)
            })
            .catch(err => {
              this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            })
        })
    },
    typeName(type) {
      const i = type.replaceAll('_', ' ').toLowerCase()
      return i.charAt(0).toUpperCase() + i.slice(1)
    },
    resolveApplicationType(el) {
      return el.integrationFacebookWebhook
        || (el.clientChatMessage && el.clientChatMessage.clientChat && el.clientChatMessage.clientChat.browserSession && el.clientChatMessage.clientChat.browserSession.widgetChat)
        || (el.clientChatMessage && el.clientChatMessage.clientChat && el.clientChatMessage.clientChat.integrationWhatsAppSubscriptionPhoneNumber && el.clientChatMessage.clientChat.integrationWhatsAppSubscriptionPhoneNumber.whatsAppPhoneNumberDisplay)
        || el.contactApplicationIntegration
        || el.pluginMake
        || el.pluginZapier
    },
    resolveApplicationTitle(el) {
      switch (true) {
        case el.pluginMake !== null: return 'Make'
        case el.pluginZapier !== null: return 'Zapier'
        case el.integrationFacebookWebhook !== null: return 'Facebook'
        case el.contactApplicationIntegration !== null: return this.typeName(el.contactApplicationIntegration.type)
        case el.clientChatMessage !== null:
          if (el.clientChatMessage && el.clientChatMessage.clientChat && el.clientChatMessage.clientChat.browserSession && el.clientChatMessage.clientChat.browserSession.widgetChat) return `${i18n.t('LeadGenerator')} - ${i18n.t('Widget')}`
          if (el.clientChatMessage && el.clientChatMessage.clientChat && el.clientChatMessage.clientChat.integrationWhatsAppSubscriptionPhoneNumber) return `${i18n.t('LeadGenerator')} - ${i18n.t('Whatsapp')}`
          if (el.clientChatMessage && el.clientChatMessage.clientChat.chatClient) return `${i18n.t('LeadGenerator')} - ${i18n.t('MobileClients')}`

          return i18n.t('LeadGenerator')
        default:
          return null
      }
    },
  },
}
</script>
