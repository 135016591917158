<template>
  <div>
    <!--      Section: Header      -->
    <div class="d-flex justify-content-between">
      <div>
        <b-button
          v-if="checkRequiredPermissions([$roles.PROJECT_MODIFY])"
          size="sm"
          variant="primary"
          :to="{ name: 'projects/new', params: { thread: thread || currentThread } }"
        >
          {{ $t('Add') }}

          <feather-icon icon="PlusIcon" />
        </b-button>
      </div>

      <b-button-group>
        <b-button
          variant="outline-primary"
          size="sm"
          class="btn-icon"
          @click="$store.commit('projects/setView', 'grid')"
        >
          <feather-icon icon="TrelloIcon" />
        </b-button>

        <b-button
          variant="outline-primary"
          size="sm"
          class="btn-icon"
          @click="$store.commit('projects/setView', 'extensible')"
        >
          <feather-icon icon="ListIcon" />
        </b-button>
      </b-button-group>
    </div>

    <!--      Section: categoryList      -->
    <views
      :is-loading="isLoading"
      :view="$store.getters['projects/getView']"
      :start-filters="currentThread ? { contactThreads: [currentThread] } : startFilters"
      :show-filters="currentThread ? false : showFilters"
    />

    <b-overlay
      :show="isLoading"
      no-wrap
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
    />
  </div>
</template>

<script>
import {
  BButtonGroup, VBModal, VBToggle, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { Polish as pl } from '@/libs/i18n/locales/flatpickr/pl'
import { english as en } from '@/libs/i18n/locales/flatpickr/en'
import { mapGetters } from 'vuex'
import Views from '../components/list/Views.vue'

export default {
  components: {
    BButtonGroup,
    Views,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    startFilters: {
      type: Object,
      required: false,
      default: () => ({ assignedUsers: [JSON.parse(localStorage.getItem('user'))] }),
    },
    thread: {
      type: Object,
      required: false,
      default: null,
    },
    showFilters: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data: () => ({
    isLoading: false,

    flatPickerConfig: {
      mode: 'range',
      locale: [pl, en],
    },
  }),
  computed: {
    ...mapGetters({
      currentThread: 'contact/thread',
    }),
    locale() {
      return this.$i18n.locale
    },
  },
}
</script>
