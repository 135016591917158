import { $themeConfig } from '@themeConfig'
import navMenuItems from '@/navigation/vertical'
import { UPDATE_NAV } from '@/@constants/mutations'
// eslint-disable-next-line import/no-cycle
import axiosIns from '@/libs/axios'
import Vue from 'vue'

/* eslint-disable */
export default {
  namespaced: true,
  state: {
    isVerticalMenuCollapsed: localStorage.getItem('vuexy-menu-is-collapsed') ? localStorage.getItem('vuexy-menu-is-collapsed') == 'hidden' : true,
    isSwGroup: $themeConfig.layout.menu.isSwGroup,
    items: JSON.parse(JSON.stringify(navMenuItems)),
    globalInfo: {
      unreadChatConversations: 0,
      unreadClientChats: 0,
      subscriptionData: {
        wallet: 0,
        userLimit: 0, /* (-1 = unlimit) */
        contactLimit: 0, /* (-1 = unlimit) */
        estimatedFees: {
          plantnet_per_request: 0,
          openai_per_token: 0,
          mail_per_mail: 0,
          sms_per_sms: 0,
        },
      },
    },
  },
  getters: {
    items: state => state.items,
    globalInfo: state => state.globalInfo,
    globalInfoSubscriptionData: state => state.globalInfo.subscriptionData,
    itemsFull: state => type => {
      const allMenuItems = JSON.parse(JSON.stringify(navMenuItems)).map(item => {
        const i = state.items.find(e => e.key === item.key)
        if (i) return i

        return item
      })

      const a = allMenuItems.map(item => {
        const navMenuItem = navMenuItems.find(menuItem => menuItem.key === item.key)

        if (navMenuItem) {
          const {
            title,
            route,
            icon,
            requiredPermissions,
            requiredModule,
            viewPermissions,
          } = navMenuItem

          return {
            ...item,
            title,
            route,
            icon,
            requiredPermissions,
            requiredModule,
            viewPermissions,
          }
        }

        return item
      }).filter(item => {
        if (!type) return true

        if (type === 'hide') return item.hide

        return !item.hide
      }).sort((c, b) => c.position - b.position)
      return a
    },
  },
  mutations: {
    UPDATE_VERTICAL_MENU_COLLAPSED(state, val) {
      localStorage.setItem('vuexy-menu-is-collapsed', val ? 'hidden' : 'visible')
      state.isVerticalMenuCollapsed = val
      // state.isSwGroup = !val
    },
    UPDATE_VERTICAL_MENU_SW_GROUP(state, val) {
      state.isSwGroup = val
    },

    CLEAR_GLOBAL_INFO(state, field) {
      // eslint-disable-next-line operator-assignment
      state.globalInfo[field] -= 1
    },

    SET_GLOBAL_INFO(state, val) {
      state.globalInfo = val
    },

    SET_NAV(state, val) {
      Vue.set(state, 'items', val)
    },
  },
  actions: {
    GET_GLOBAL_INFO: async ({ commit }) => {
      const { data: response } = await axiosIns.get('1/globalInfo')

      commit('SET_GLOBAL_INFO', response.data)
    },

    CLEAR_GLOBAL_INFO: ({ commit }) => {
      commit('CLEAR_GLOBAL_INFO', 'unreadClientChats')
    },

    [UPDATE_NAV]: ({ commit, rootGetters }) => {
      const { navBarConfig } = rootGetters['system/getSettings']
      let nav = []

      if (navBarConfig && navBarConfig.length) {
        navMenuItems.every(item => {
          const isExists = navBarConfig.some(item2 => item2.key === item.key)

          if (!isExists) navBarConfig.push(item)

          return isExists
        })

        nav = navBarConfig
      } else {
        nav = navMenuItems
      }

      commit('SET_NAV', nav)
    },

    SET_NAV: ({ commit }, nav) => commit('SET_NAV', nav),
  },
}
