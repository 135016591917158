<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <!-- Sidebar Content -->
      <div
        class="sidebar-content"
        :class="{ 'show': mqShallShowLeftSidebar }"
      >

        <!-- Header -->
        <div class="chat-fixed-search d-flex justify-content-between px-1">
          <!-- Sidebar close icon -->
          <b-button
            size="sm"
            variant="flat-danger"
            class="btn-icon ml-n50 mr-25 d-md-none"
          >
            <feather-icon
              icon="XIcon"
              @click="$emit('close-mq-shall-show-left-sidebar')"
            />
          </b-button>

          <div class="d-flex align-items-center w-100">
            <b-input-group class="input-group-merge round w-100">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>

              <b-form-input
                v-model="search"
                :placeholder="$t('Search')"
                class="input-search"
              />
            </b-input-group>
          </div>
        </div>

        <!-- ScrollArea: Chat & Contacts -->
        <vue-perfect-scrollbar
          class="chat-user-list-wrapper list-group scroll-area pt-1"
        >
          <h4 class="chat-list-title mt-0">
            {{ $t('ActiveChats') }}
          </h4>
          <!-- Chats -->
          <ul
            v-if="getFilteredConversationUsers.length"
            class="chat-users-list chat-list media-list"
          >
            <chat-user
              v-for="(user, index) in getFilteredConversationUsers"
              :key="`user_${user.id}_${index}`"
              :user="user"
              is-chat-contact
              tag="li"
              :class="{ 'active': selectedConversationId === user.conversationId }"
              @click="
                openConversation(user.conversationId, user)
                $emit('close-mq-shall-show-left-sidebar')
              "
            />
          </ul>
          <div
            v-else
            class="pl-2"
          >
            <feather-icon icon="MinusIcon" />
          </div>
          <h4 class="chat-list-title">
            {{ $t('Others') }}
          </h4>
          <!-- Chats -->
          <ul
            v-if="getFilteredAvailableUsers.length"
            class="chat-users-list chat-list media-list"
          >
            <chat-user
              v-for="user in getFilteredAvailableUsers"
              :key="`user_${user.id}`"
              :user="user"
              tag="li"
              @click="
                $emit('open-conversation', null, user)
                $emit('close-mq-shall-show-left-sidebar')
              "
            />
          </ul>
          <div
            v-else
            class="pl-2"
          >
            <feather-icon icon="MinusIcon" />
          </div>
        </vue-perfect-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import ChatUser from '@/views/core/chat/ChatUser.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ChatUsersSidebar',
  components: {
    // 3rd party
    VuePerfectScrollbar,
    // SFC
    ChatUser,
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/getCurrentUser',
    }),
    getConversationUsers() {
      return this.conversationUsers.map(conversation => ({
        conversationId: conversation.id,
        ...conversation.users.filter(user => user.id !== this.currentUser.id)[0],
        message: conversation.chatConversationMessagesLast?.message || '',
        createdAt: conversation.chatConversationMessagesLast?.createdAt.date || new Date().toLocaleDateString(),
        chatConversationMessagesUnreadAmount: conversation.chatConversationMessagesUnreadAmount,
      }))
    },
    getAvailableUsers() {
      const conversationIds = this.getConversationUsers.map(user => user.id)
      return this.availableUsers.filter(user => !conversationIds.includes(user.id) && this.currentUser.id !== user.id)
    },
    getFilteredConversationUsers() {
      return this.getConversationUsers.filter(user => (user.firstName.includes(this.search) || user.lastName.includes(this.search))) || []
    },
    getFilteredAvailableUsers() {
      return this.getAvailableUsers.filter(user => (user.firstName.includes(this.search) || user.lastName.includes(this.search))) || []
    },
  },
  props: {
    selectedConversationId: {
      type: String,
      default: '',
      required: false,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      default: false,
      required: true,
    },
    availableUsers: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
      required: true,
    },
    conversationUsers: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
      required: true,
    },
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }
    return {
      perfectScrollbarSettings,
    }
  },
  data: () => ({
    search: '',
  }),
  methods: {
    openConversation(conversationId, user) {
      // eslint-disable-next-line
      user.chatConversationMessagesUnreadAmount = 0
      this.$emit('open-conversation', user.conversationId, user)
    },
  },
}
</script>

<style scoped>

</style>
