<template>
  <b-row>
    <b-col>
      <transition
        key="folder"
        name="slide-fade"
        mode="out-in"
      >
        <folder-list
          v-if="!selectedFolder"
        />
        <document-list
          v-else
          key="files"
        />
      </transition>
    </b-col>
  </b-row>
</template>

<script>
import FolderList from '@/views/core/documents/FolderList.vue'
import DocumentList from '@/views/core/documents/DocumentList.vue'

export default {
  name: 'SwFolders',
  components: { FolderList, DocumentList },
  computed: {
    selectedFolder() {
      return this.$route.params?.folderId
    },
  },
}
</script>

<style scoped>

</style>
