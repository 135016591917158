<template>
  <div>
    <!--    Option: Options    -->
    <b-row class="mx-0">
      <b-col
        sm="12"
        md="6"
        lg="8"
        class="px-1"
      >
        <my-option
          :title="$t('Options')"
        >
          <b-form-group>
            <b-form-checkbox
              v-model="newWebsite.contactIsDetails"
              checked="true"
              name="check-button"
              variant="primary"
              switch
              inline
              class="w-100"
            >
              {{
                !newWebsite.contactIsDetails ? $t('CompanyDataActive') : $t('DataOfUserSendingOfferActive')
              }}
            </b-form-checkbox>
          </b-form-group>
        </my-option>
      </b-col>
    </b-row>

    <!--    Option: Title    -->
    <b-row class="mx-0">
      <b-col
        sm="12"
        md="6"
        lg="8"
        class="px-1"
      >
        <my-option
          :title="$t('Title')"
          :tip="$t('MaximumLength') + ': 40'"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="$t('Title')"
            rules="required"
            class="w-100"
          >
            <b-form-group>
              <b-form-input
                id="popover-bannerTitle1"
                v-model.trim="newWebsite.contactTitle"
                :placeholder="$t('Title')"
                :state="errors.length > 0 ? false:null"
                maxlength="40"
              />
              <small class="text-danger">{{ errors[0] }}</small>

              <!--    Popover    -->
              <b-popover
                target="popover-bannerTitle1"
                triggers="focus"
                variant="primary"
              >
                <span>{{ $t('MaximumLength') + ': 40' }}</span>
              </b-popover>
            </b-form-group>
          </validation-provider>
        </my-option>
      </b-col>
    </b-row>

    <!--    Option: Company's data    -->
    <b-row
      v-if="!newWebsite.contactIsDetails"
      class="mx-0"
    >
      <b-col
        sm="12"
        md="6"
        lg="8"
        class="px-1"
      >
        <my-option
          :title="$t('companysData')"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="$t('Phone')"
            rules="required"
            class="w-100"
          >
            <b-form-group>
              <b-form-input
                id="popover-bannerTitle1"
                v-model.trim="newWebsite.contactPhone"
                :placeholder="$t('Phone')"
                :state="errors.length > 0 ? false:null"
                maxlength="40"
              />
              <small class="text-danger">{{ errors[0] }}</small>

              <!--    Popover    -->
              <b-popover
                target="popover-bannerTitle1"
                triggers="focus"
                variant="primary"
              >
                <span>{{ $t('MaximumLength') + ': 40' }}</span>
              </b-popover>
            </b-form-group>
          </validation-provider>

          <validation-provider
            v-slot="{ errors }"
            :name="$t('Email')"
            rules="required"
            class="w-100"
          >
            <b-form-group>
              <b-form-input
                id="popover-bannerTitle1"
                v-model.trim="newWebsite.contactEmail"
                :placeholder="$t('Email')"
                :state="errors.length > 0 ? false:null"
                maxlength="40"
              />
              <small class="text-danger">{{ errors[0] }}</small>

              <!--    Popover    -->
              <b-popover
                target="popover-bannerTitle1"
                triggers="focus"
                variant="primary"
              >
                <span>{{ $t('MaximumLength') + ': 40' }}</span>
              </b-popover>
            </b-form-group>
          </validation-provider>
        </my-option>
      </b-col>
    </b-row>

    <!--    Option: Description    -->
    <!--              <b-row class="mx-0">-->
    <!--                <b-col-->
    <!--                  sm="12"-->
    <!--                  md="6"-->
    <!--                  lg="8"-->
    <!--                  class="px-1"-->
    <!--                >-->
    <!--                  <my-option-->
    <!--                    :title="$t('task.Description')"-->
    <!--                    :tip="$t('MaximumLength') + ': 170'"-->
    <!--                  >-->
    <!--                    <validation-provider-->
    <!--                      #default="{ errors }"-->
    <!--                      :name="$t('Title')"-->
    <!--                      rules="required"-->
    <!--                      class="w-100"-->
    <!--                    >-->
    <!--                      <b-form-group>-->
    <!--                        <b-form-textarea-->
    <!--                          id="popover-bannerDesc"-->
    <!--                          v-model.trim="newWebsite.contactDesc"-->
    <!--                          :placeholder="$t('Title')"-->
    <!--                          :state="errors.length > 0 ? false:null"-->
    <!--                          maxlength="170"-->
    <!--                        />-->
    <!--                        <small class="text-danger">{{ errors[0] }}</small>-->

    <!--                        &lt;!&ndash;    Popover    &ndash;&gt;-->
    <!--                        <b-popover-->
    <!--                          target="popover-bannerDesc"-->
    <!--                          triggers="focus"-->
    <!--                          variant="primary"-->
    <!--                        >-->
    <!--                          <span>{{ $t('MaximumLength') + ': 170' }}</span>-->
    <!--                        </b-popover>-->
    <!--                      </b-form-group>-->
    <!--                    </validation-provider>-->
    <!--                  </my-option>-->
    <!--                </b-col>-->
    <!--              </b-row>-->

    <!--    Option: Avatar    -->
    <b-col
      sm="12"
      md="6"
      lg="4"
      class="px-1"
    >
      <my-option
        :title="$t('contact.avatar')"
        :tip="`${$t('PreferredFile')}: (150x150)px/1.25GB`"
        :my-class="'dragDrop rec'"
      >
        <div>
          <b-img
            id="img-contactAvatar"
            class="px-0 py-0 center"
            thumbnail
            fluid

            :src="!!newWebsite.contactAvatar && newWebsite.contactAvatar.preview ? `data:image/webp;base64,${newWebsite.contactAvatar.preview}`: require('@/assets/icons/user/user.svg')"
          />

          <b-button
            v-b-tooltip.hover.v-primary
            variant="primary"
            :title="$t('DragDropFile')"
          >
            <feather-icon icon="PlusIcon" />
            <input
              type="file"
              @change="onChange($event, 'contactAvatar', 1250)"
            >
          </b-button>
        </div>
      </my-option>
    </b-col>
  </div>
</template>

<script>
import { BPopover, VBTooltip } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import myOption from '@/views/offers/website/Option.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    myOption,
    ValidationProvider,
    BPopover,
  },

  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  props: {
    value: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    newWebsite: { },
  }),

  mounted() {
    this.newWebsite = this.value
  },

  methods: {
    onChange(event, option, maxSize) {
      if (event.target.files[0].size > maxSize * 1000) {
        this.showToast('danger', this.$i18n.tc('MoreThanKB', 1, {
          type: this.$i18n.t(`SystemMoreThan.${option}`),
          size: maxSize,
        }))
        return
      }

      const [first] = event.target.files

      if (typeof this.newWebsite[option] !== 'undefined') {
        this.newWebsite[option] = first
      } else {
        // eslint-disable-next-line default-case
        switch (option) {
          case 'newOption-avatar':
            this.newOption.avatar = first
            break
        }
      }

      document.querySelector(`#img-${option}`).src = URL.createObjectURL(first)

      // eslint-disable-next-line consistent-return
      return first
    },
  },
}
</script>
