<template>
  <b-card-body class="d-flex flex-column justify-content-center text-center">
    <h2 class="text-primary">
      {{ $t('ScanQrCode') }}
    </h2>

    <p class="mb-3">
      {{ $t('ScanQrCodeText') }}
    </p>

    <qr-code
      :value="getDomain"
      size="240"
      level="H"
    />
  </b-card-body>
</template>

<script>
export default {
  computed: {
    getDomain() {
      return window.location.port
                        || window.location.origin.toString() === 'https://demo.saleswizardapp.com'
        ? 'https://d1.saleswizardapp.com'
        : window.location.origin
    },
  },
}
</script>
