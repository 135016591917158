<template>
  <b-card>
    <!-- eslint-disable -->
    <sw-table
      table-id="9dba833d14ef4aa1b27e43fc0be24465"
      show-columns
      show-search
      :pagination="pagination"
      :fields.async="tableFields"
      @change-search-query="searchQuery = $event"
      @change-pagination="Object.assign(pagination, $event)"
      @reload-content="loadData"
      @set-fields="tableFields = $event"
    >
      <template #table>
        <b-table
          ref="selectableTable"
          :items="items"
          class="table-wrap-words mx-0 px-0"
          :fields="fields"
          striped
          responsive
          :busy="loading"
          show-empty
          :sort-by.sync="sorter.sortBy"
          :sort-desc.sync="sorter.sortDesc"
          :no-local-sorting="true"
          @sort-changed="Object.assign(sorter, $event); loadData()"
        >
          <template #head()="{ label }">
            {{ $t(`${label}`) }}
          </template>

          <template #cell(id)="{ index }">
            #{{ getOrderNo(index, pagination, sorter) }}
          </template>

          <!--        Type         -->
          <template #cell(type)="{ value }">
            <b-badge
                variant="primary"
            >
              {{ $t(`calculators.types.${value}`) }}
            </b-badge>
          </template>

          <!--        Status         -->
          <template #cell(status)="{ value }">
            <b-badge
              :variant="value === 'Done' ? 'success' : 'primary'"
            >
              {{ $t(`calculators.${value}`) }}
            </b-badge>
          </template>

          <!--        User         -->
          <template #cell(user)="{ value }">
            <avatar
              v-if="value"
              :user="value"
            />

            <feather-icon
              v-else
              icon="MinusIcon"
            />
          </template>

          <!--        Files         -->
          <template #cell(files)="{ value }">
            <documents-list
                v-if="Array.isArray(value) && value.length"
                :documents="value"
            />

            <feather-icon
                v-else
                icon="MinusIcon"
            />
          </template>

          <!--     Action     -->
          <template #cell(action)="{ item, index }">
            <b-dropdown
                id="dropdown-dropleft"
                dropleft
                no-caret
                variant="flat"
            >
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" />
              </template>

              <b-dropdown-item>
                <dropdown-item-label
                    icon="CopyIcon"
                    :label="$t('Duplicate')"
                />
              </b-dropdown-item>

              <b-dropdown-item>
                <dropdown-item-label
                    icon="FileIcon"
                    :label="$t('ConvertToOffer')"
                />
              </b-dropdown-item>
            </b-dropdown>
          </template>

          <!--    Other    -->
          <template #cell()="row">
            <table-default-cell
                :field="row.field"
                :value="row.value"
            />
          </template>

          <!--      Empty      -->
          <template #empty>
            <empty-content />
          </template>

          <!--      Table Busy      -->
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner
                  class="align-middle"
                  variant="primary"
              />
            </div>
          </template>
        </b-table>
      </template>
    </sw-table>
  </b-card>
</template>

<script>
import { BTable } from 'bootstrap-vue'
import DocumentsList from '@/views/components/DocumentsList.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    DocumentsList,
    BTable,
  },

  data: () => ({
    loading: false,

    searchQuery: '',

    sorter: {
      sortBy: 'id',
      sortDesc: true,
    },

    pagination: {
      perPage: 25,
      currentPage: 1,
      totalRecords: 100,
    },

    items: [],
    tableFields: [
      {
        key: 'id', label: '#', visible: true, sortable: true,
      },
      {
        key: 'number', label: 'CalculationNumber', visible: true, sortable: true,
      },
      {
        key: 'type', label: 'CalculationType', visible: true, sortable: true,
      },
      {
        key: 'assignedContact', label: 'AssignedContact', visible: true, sortable: true,
      },
      {
        key: 'user', label: 'User', visible: true, sortable: true,
      },
      {
        key: 'status', label: 'Status', visible: true, sortable: true,
      },
      {
        key: 'files', label: 'OfferFiles', visible: true, sortable: true,
      },
      {
        key: 'executionDate', label: 'ExecutionDate', visible: true, sortable: true,
      },
      {
        visible: true, key: 'action', label: 'Action',
      },
    ],
  }),

  computed: {
    fields() {
      return this.tableFields.filter(field => field.visible)
        .filter(e => !e.requiredViewRanks || this.checkRequiredViewPermissions(e.requiredViewRanks))
    },
    ...mapGetters({
      user: 'auth/getCurrentUser',
    }),
  },

  mounted() {
    this.items.push(
      {
        id: -1,
        number: '123',
        type: 'PhotovoltaicInstallation',
        assignedContact: null,
        user: this.user,
        status: 'Done',
        executionDate: '23.04.2024, 13:21',
        files: [],
      },
      {
        id: -2,
        number: '123',
        type: 'PhotovoltaicInstallation',
        assignedContact: null,
        user: this.user,
        status: 'Sketch',
        executionDate: '23.04.2024, 13:21',
        files: [],
      },
    )
  },

  methods: {
    loadData() {
      this.$nextTick(() => {
        this.loading = true

        // eslint-disable-next-line no-unused-vars
        const payload = {
          pagination: this.pagination,
          searchQuery: this.searchQuery,
          sorter: this.sorter,
          filters: {},
        }

        this.loading = false
      })
    },
  },
}
</script>
