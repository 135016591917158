var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('sw-table',{attrs:{"table-id":"268111d8432fd194e412354f3dc6ff4b3aafcf","show-columns":"","show-search":"","fields":_vm.fields,"pagination":_vm.pagination},on:{"change-pagination":function($event){return Object.assign(_vm.pagination, $event)},"change-search-query":function($event){_vm.search = $event},"reload-content":_vm.initData,"set-fields":function($event){_vm.fields = $event}},scopedSlots:_vm._u([{key:"table",fn:function(){return [_c('b-table',{style:({ minHeight: _vm.tableSize(_vm.items, 100) }),attrs:{"items":_vm.items,"fields":_vm.tableFields,"striped":"","responsive":"","busy":_vm.loading,"show-empty":"","sort-by":_vm.sorter.sortBy,"sort-desc":_vm.sorter.sortDesc,"no-local-sorting":true},on:{"update:sortBy":function($event){return _vm.$set(_vm.sorter, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.sorter, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.sorter, "sortDesc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.sorter, "sortDesc", $event)},"sort-changed":function($event){Object.assign(_vm.sorter, $event); _vm.initData()}},scopedSlots:_vm._u([{key:"head()",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(("" + label)))+" ")]}},{key:"cell(id)",fn:function(ref){
var index = ref.index;
return [_vm._v(" #"+_vm._s(_vm.getOrderNo(index, _vm.pagination, _vm.sorter))+" ")]}},{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [(item.contactThreadGoogleAdsClientAccount)?_c('span',[_vm._v(" "+_vm._s(item.contactThreadGoogleAdsClientAccount.name)+" ")]):_vm._e()]}},{key:"cell(status)",fn:function(ref){
var value = ref.value;
return [(_vm.getStatusByValue(value))?_c('b-badge',{attrs:{"variant":_vm.getStatusByValue(value).color}},[_vm._v(" "+_vm._s(_vm.$t(_vm.getStatusByValue(value).label))+" ")]):_vm._e()]}},{key:"cell(contactThread)",fn:function(row){return [(row.item.contactThreadGoogleAdsClientAccount && row.item.contactThreadGoogleAdsClientAccount.contactThread)?_c('span',[_c('router-link',{class:{ 'deleted-text': row.item.contactThreadGoogleAdsClientAccount.contactThread.contact && row.item.contactThreadGoogleAdsClientAccount.contactThread.contact.deletedAt },attrs:{"to":("/contact/" + (row.item.contactThreadGoogleAdsClientAccount.contactThread.contact.id) + "/thread/" + (row.item.contactThreadGoogleAdsClientAccount.contactThread.id) + "/googleAds")}},[_vm._v(" "+_vm._s(_vm.getUserName(row.item.contactThreadGoogleAdsClientAccount.contactThread.contact))+" - "+_vm._s(row.item.contactThreadGoogleAdsClientAccount.contactThread.name)+" ")])],1):_c('span',[_c('feather-icon',{attrs:{"icon":"MinusIcon"}})],1)]}},{key:"cell()",fn:function(row){return [_c('table-default-cell',{attrs:{"field":row.field,"value":row.value}})]}},{key:"empty",fn:function(){return [_c('empty-content')]},proxy:true},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[_c('b-spinner',{staticClass:"align-middle",attrs:{"variant":"primary"}})],1)]},proxy:true}])})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }