// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line import/no-cycle
import axiosIns from '@/libs/axios'
import { CONTACT_ADDON_FIELDS } from '@/@constants/fields'
import {
  CREATE_CONTACT_ADDON_FIELD,
  DELETE_CONTACT_ADDON_FIELD,
  GET_CONTACT_ADDON_FIELD,
  GET_CONTACT_ADDON_FIELDS,
  TEST_CONTACT_ADDON_FIELD,
  UPDATE_CONTACT_ADDON_FIELD,
} from '@/@constants/mutations'

export default {
  namespaced: true,
  actions: {
    [GET_CONTACT_ADDON_FIELDS]: (_, contactId) => new Promise((resolve, reject) => {
      const params = {}
      params.fields_load = CONTACT_ADDON_FIELDS

      axiosIns.get(`1/contact/${contactId}/addonFields`, { params })
        .then(res => {
          resolve(res.data.data.items.sort(((a, b) => a.contactAddonFieldType?.position - b.contactAddonFieldType?.position)))
        })
        .catch(err => reject(err))
    }),

    [GET_CONTACT_ADDON_FIELD]: (_, { contactId, fieldId }) => new Promise((resolve, reject) => {
      const params = {}
      params.fields_load = CONTACT_ADDON_FIELDS

      axiosIns.get(`1/contact/${contactId}/addonFields/${fieldId}`, { params })
        .then(res => resolve(res.data.data.item))
        .catch(err => reject(err))
    }),

    [CREATE_CONTACT_ADDON_FIELD]: (_, { contactId, payload }) => new Promise((resolve, reject) => {
      axiosIns.post(`1/contact/${contactId}/addonFields`, payload)
        .then(res => resolve(res.data.data.items[0].id))
        .catch(err => reject(err))
    }),

    [UPDATE_CONTACT_ADDON_FIELD]: (_, { contactId, payload }) => new Promise((resolve, reject) => {
      axiosIns.patch(`1/contact/${contactId}/addonFields`, payload)
        .then(res => resolve(res.data.data.items[0].id))
        .catch(err => reject(err))
    }),

    [DELETE_CONTACT_ADDON_FIELD]: (_, { contactId, id }) => new Promise((resolve, reject) => {
      axiosIns.delete(`1/contact/${contactId}/addonFields`, { data: [id] })
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    }),

    [TEST_CONTACT_ADDON_FIELD]: (_, level = 0) => new Promise((resolve, reject) => {
      const params = {}
      params.fields_info = level

      axiosIns.get('1/contact/0/addonFields', { params })
        .then(res => resolve(res.data.data))
        .catch(err => reject(err))
    }),
  },
}
