<template>
  <div>
    <b-button
      variant="primary"
      size="sm"
      class="mb-1"
      @click="onSaveSettings"
    >
      {{ $t('Save') }}
    </b-button>

    <b-card class="position-relative">
      <template #header>
        <h4 class="d-flex align-items-center">
          {{ $t('Ringostat') }}
        </h4>
      </template>

      <b-row>
        <b-col sm="12">
          <b-form-group :label="$t('APIKey')">
            <b-form-input
              v-model="apiKey"
              type="password"
            />
          </b-form-group>
        </b-col>

        <b-col v-if="checkRequiredModule('chatGptModule')"
               sm="12"
        >
          <div class="divider text-primary divider-left">
            <div class="divider-text font-medium-1">
              {{ $t('AITranscription') }}
            </div>
          </div>
          <b-form-group>
            <b-form-checkbox
              v-model="phoneCallGenerateTranscription"
              variant="primary"
              switch
              inline
              class="w-100"
            >
              {{ $t('TranscriptionOn') }}
            </b-form-checkbox>
          </b-form-group>
          <b-form-group>
            <b-form-checkbox
              v-model="phoneCallGenerateTranscriptionSummmary"
              variant="primary"
              switch
              inline
              class="w-100"
            >
              {{ $t('TranscriptionOnSummary') }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <div class="divider text-primary divider-left">
        <div class="divider-text font-medium-1">
          {{ $t('Users') }}
        </div>
      </div>
      <b-row>
        <b-col v-for="(userRecord, index) in users"
               :key="`user_${index}`"
               sm="12"
               md="6"
               lg="4"
        >
          <b-card class="rounded border"
                  no-body
          >
            <div class="py-75 px-1">
              <sw-select :name="$t('User')">
                <v-select
                  v-model="userRecord.user"
                  :options="userList"
                  label="firstName"
                  :filterable="false"
                  :selectable="a => !users.some(e => e.user && e.user.id === a.id)"
                  @search="searchUsers"
                >
                  <template #no-options="{ search }">
                    <span v-if="search.length">
                      {{ $t('NoData') }}
                    </span>
                    <span v-else>
                      {{ $t('TypeToSearch') }}
                    </span>
                  </template>

                  <template #option="{ firstName, lastName, avatar }">
                    <div class="d-flex align-items-center">
                      <avatar
                        :user="{ firstName, lastName, avatar }"
                        class="mr-25"
                      />
                    </div>
                  </template>

                  <template #selected-option="{ firstName, lastName, avatar }">
                    <div class="d-flex">
                      <avatar
                        :user="{ firstName, lastName, avatar }"
                        class="mr-25"
                      />
                    </div>
                  </template>
                </v-select>
              </sw-select>

              <b-form-group label="Ringostat SIP">
                <b-form-input v-model="userRecord.ringostatSip" />
              </b-form-group>

              <b-button size="sm"
                        class="w-100"
                        variant="flat-danger"
                        @click="$delete(users, index)"
              >
                <sw-icon icon="TrashIcon"
                         class="mr-25"
                />
                {{ $t('Delete') }}
              </b-button>
            </div>
          </b-card>
        </b-col>

        <b-col sm="12"
               md="6"
               lg="4"
               class="pb-1 mb-1"
               @click="addUser"
        >
          <b-alert
            variant="primary"
            show
            class="d-flex align-items-center justify-content-center h-100 cursor-pointer"
          >
            <span>
              {{ $t('Add') }}
            </span>

            <sw-icon
              icon="PlusIcon"
              size="25"
            />
          </b-alert>
        </b-col>
      </b-row>

      <b-overlay
        :show="loading"
        no-wrap
        spinner-variant="primary"
      />
    </b-card>

    <b-button
      variant="primary"
      size="sm"
      @click="onSaveSettings"
    >
      {{ $t('Save') }}
    </b-button>
  </div>
</template>

<script>
// import vSelect from 'vue-select'
import axiosIns from '@/libs/axios'
import { USERS_SEARCH } from '@/@constants/fields'
import vSelect from 'vue-select'
import { GET_PRIVATE_SETTINGS, SAVE_SETTINGS } from '@/@constants/mutations'

export default {
  name: 'Ringostat',
  components: {
    vSelect,
    // vSelect,
  },
  data() {
    return {
      loading: false,
      phoneCallGenerateTranscription: false,
      phoneCallGenerateTranscriptionSummmary: false,
      apiKey: '',
      users: [],
      userList: [],
      baseUsers: [],
    }
  },
  computed: {},
  watch: {
    phoneCallGenerateTranscription(nw) {
      if (!nw) {
        this.phoneCallGenerateTranscriptionSummmary = false
      }
    },
  },
  mounted() {
    if (this.checkRequiredModule('callModule')) {
      this.loadData()
    }
  },
  methods: {
    // onSaveSettings() {
    //   this.loading = true
    //   const payload = {
    //     fakturowniaToken: this.fakturowniaToken,
    //     fakturowniaDomain: this.fakturowniaDomain,
    //   }
    //
    //   if (this.fakturowniaToken.includes('********')) {
    //     delete payload.fakturowniaToken
    //   }
    //
    //   this.$store.dispatch(`system/${SAVE_SETTINGS}`, { privateData: payload })
    //     .then(() => {
    //       this.loading = false
    //       this.showToast('success', this.$i18n.t('ChangesSaved'))
    //       this.loadData()
    //     })
    //     .catch(err => {
    //       console.log(err)
    //       this.loading = false
    //       this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
    //     })
    // },
    async loadData() {
      this.loading = true
      try {
        const [response, responseSettings] = await Promise.all([
          await axiosIns.get('1/integration/ringostat/settings'),
          this.$store.dispatch(`system/${GET_PRIVATE_SETTINGS}`),
        ])
        const { apiKey, users = [] } = response.data.data
        const token = apiKey ? '*******************' : ''

        if (users.length) {
          const selectedUsers = await axiosIns.get('1/users', {
            params: {
              fields_load: USERS_SEARCH,
              eqArr_id: JSON.stringify(users.mapKeys('id')),
            },
          })
          this.users = users.map(userRecord => ({ ...userRecord, user: selectedUsers.data.data?.items.find(user => user?.id === userRecord?.id) }))
          this.baseUsers = users
        }

        if (responseSettings) {
          this.$set(this, 'phoneCallGenerateTranscription', responseSettings.data.item?.phoneCallGenerateTranscription || token)
          this.$set(this, 'phoneCallGenerateTranscriptionSummmary', responseSettings.data.item?.phoneCallGenerateTranscriptionSummmary || '')
        }

        this.$set(this, 'apiKey', token)
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.loading = false
      }
    },
    async searchUsers(search, isLoading) {
      this.userList = await this.selectSearch(search, isLoading, USERS_SEARCH, 'firstName,lastName', '1/users', 25)
    },
    addUser() {
      this.users.push({
        user: '',
        ringostatSip: '',
      })
    },
    async onSaveSettings() {
      this.loading = true
      const payload = {}

      if (this.apiKey.includes('********')) {
        delete payload.apiKey
      } else {
        payload.apiKey = this.apiKey
      }
      payload.users = this.users.map(userRecord => ({ id: this.getObjectId(userRecord.user), ringostatSip: userRecord.ringostatSip }))

      await axiosIns.patch('1/integration/ringostat/settings', { ...payload, users: this.baseUsers.map(userRecord => ({ id: userRecord.id, ringostatSip: null })) })

      try {
        if (this.checkRequiredModule('chatGptModule')) {
          const payloadGtp = {
            phoneCallGenerateTranscription: this.phoneCallGenerateTranscription,
            phoneCallGenerateTranscriptionSummmary: this.phoneCallGenerateTranscriptionSummmary,
          }
          this.$store.dispatch(`system/${SAVE_SETTINGS}`, { privateData: payloadGtp })
        }
        await axiosIns.patch('1/integration/ringostat/settings', payload)

        this.showToast('success', this.$i18n.t('ChangesSaved'))
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.loading = false
      }

      // this.$store.dispatch(`system/${SAVE_SETTINGS}`, { privateData: payload })
      //   .then(() => {
      //     this.loading = false
      //     this.showToast('success', this.$i18n.t('ChangesSaved'))
      //     this.loadData()
      //   })
      //   .catch(err => {
      //     this.loading = false
      //     this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      //   })
    },
  },
}
</script>

<style scoped>

</style>
