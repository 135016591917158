<template>
  <span />
</template>

<script>
export default {
  name: 'ActionAlert',
  async mounted() {
    setTimeout(() => {
      const isShown = localStorage.getItem('new_contact_alert')
      // eslint-disable-next-line eqeqeq
      if (isShown != 1) {
        this.showAlert('info', this.$i18n.t('NewViewIsAvailable'), this.$i18n.t('NewContactViewIsAvailableChangeItOrStayOnOld'), true, this.$i18n.t('Try'))
          .then(() => {
            this.$store.state.appConfig.views.contact = 'new'
            this.showToast('success', this.$i18n.t('GoToContactCardToSeeChanges'))
          })
          .catch(() => {
            this.$store.state.appConfig.views.contact = 'old'
          })
          .finally(() => {
            localStorage.setItem('new_contact_alert', '1')
          })
      }
    }, 1000)
  },
}
</script>

<style scoped>

</style>
