const status = () => {
  const statusList = [
    'NEW',
    'CANCELLED',
    'ACCEPTED',
    'EXPIRED',
  ]

  return statusList
}

export default status
