<template>
  <div>
    <validation-observer
      v-slot="{invalid}"
      class="d-flex flex-column"
    >
      <div>
        <!--    START:Name    -->
        <b-form-group :label="`${$t('Name')}*`">
          <validation-provider
            v-slot="{ errors }"
            :name="$t('Name')"
            rules="required"
          >
            <b-form-input
              v-model="form.name"
              :label="$t('Name')"
              :placeholder="$t('Name')"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <!--    END:Name    -->

        <!--    START:Availability    -->
        <b-form-group :label="$t('Availability')">
          <b-row style="row-gap: 1rem">
            <b-col
              v-for="day in daysOfWeek"
              :key="`open_hour_${day}`"
              cols="12"
              lg="6"
              xl="4"
            >
              <h5 class="mb-0">
                <b-form-checkbox
                  v-model="form[`availability${day}Enabled`]"
                  switch
                  @change="(e) => {
                    if (e) {
                      form[`availability${day}From`] = '0:00'
                      form[`availability${day}To`] = '23:59'
                    }
                  }"
                >
                  {{ $t(`days.${day.toUpperCase()}`) }}
                </b-form-checkbox>
              </h5>

              <b-row
                v-if="form[`availability${day}Enabled`]"
                :key="`open_hour_${day}_1`"
                style="row-gap: .5rem"
              >
                <b-col
                  sm="12"
                  md="6"
                >
                  <b-form-group class="mb-0">
                    <b-input-group class="mb-0">
                      <b-form-input
                        v-model="form[`availability${day}From`]"
                        :disabled="!form[`availability${day}Enabled`]"
                        type="text"
                        placeholder="HH:mm"
                      />
                      <b-input-group-append>
                        <b-form-timepicker
                          size="sm"
                          button-variant="outline-primary"
                          button-only
                          :label-close-button="$t('Close')"
                          :label-no-time-selected="$t('NoTimeSelected')"
                          :disabled="!form[`availability${day}Enabled`]"
                          :value="form[`availability${day}From`]"
                          @input="(e) => {
                            const [h, m] = e.split(':')
                            form[`availability${day}From`] = `${h}:${m}`
                          }"
                        />
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>

                <b-col
                  sm="12"
                  md="6"
                >
                  <b-form-group class="mb-0">
                    <b-input-group class="mb-0">
                      <b-form-input
                        v-model="form[`availability${day}To`]"
                        :disabled="!form[`availability${day}Enabled`]"
                        type="text"
                        placeholder="HH:mm"
                      />
                      <b-input-group-append>
                        <b-form-timepicker
                          size="sm"
                          button-variant="outline-primary"
                          button-only
                          :label-close-button="$t('Close')"
                          :label-no-time-selected="$t('NoTimeSelected')"
                          :disabled="!form[`availability${day}Enabled`]"
                          :value="form[`availability${day}To`]"
                          @input="(e) => {
                            const [h, m] = e.split(':')
                            form[`availability${day}To`] = `${h}:${m}`
                          }"
                        />
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>

              <div
                v-if="!form[`availability${day}Enabled`]"
                :key="`open_hour_${day}_2`"
                class="bg-light-primary rounded p-1 text-center"
                variant="primary"
              >
                <div class="alert-body">
                  <strong>{{ $t(`days.${day.toUpperCase()}`) }}</strong> - {{ $t('Closed') }}
                </div>
              </div>
            </b-col>
          </b-row>
        </b-form-group>
        <!--    END:Availability    -->

        <!--    START:User Validation    -->
        <label class="mb-50">{{ $t('UserValidation') }}:</label>
        <div>
          <b-form-checkbox v-model="form.collectContactFirstName">
            {{ $t('Name') }}
          </b-form-checkbox>

          <b-form-checkbox v-model="form.collectContactEmail">
            {{ $t('Email') }}
          </b-form-checkbox>
        </div>
        <!--    END:User Validation    -->

        <!--    START:Privacy Policy Url    -->
        <b-form-group
          :label="`${$t('Policy')} (URL)`"
          class="mt-50"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="`${$t('Policy')} (URL)`"
          >
            <b-form-input
              id="title"
              v-model="form.privacyPolicyUrl"
              :state="errors.length > 0 ? false:null"
              :placeholder="`${$t('Policy')} (URL)`"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <!--    END:Privacy Policy Url    -->
      </div>

      <div>
        <div class="divider text-primary">
          <div class="divider-text">
            {{ $t('AssistantAi') }}
          </div>
        </div>

        <!--    Select list    -->
        <sw-select :name="$t('AssistantAi')">
          <v-select
            v-model="form.salesAiEnvironment"
            :options="aiAssistants"
            label="name"
            :reduce="item => item.id"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :placeholder="$t('Select')"
          >
            <template #no-options>
              {{ $t('NoOptions') }}
            </template>
          </v-select>
        </sw-select>

        <!--    START:Availability    -->
        <b-form-group :label="$t('Availability')">
          <b-row style="row-gap: .5rem">
            <b-col
              v-for="day in daysOfWeek"
              :key="`open_hour_${day}_ai`"
              cols="12"
              lg="6"
              xl="4"
            >
              <h5 class="mb-0">
                <b-form-checkbox
                  v-model="form[`aiEnabled${day}`]"
                  switch
                  @change="(e) => {
                    if (e) {
                      form[`aiEnabled${day}From`] = '00:00'
                      form[`aiEnabled${day}To`] = '23:59'
                    }
                  }"
                >
                  {{ $t(`days.${day.toUpperCase()}`) }}
                </b-form-checkbox>
              </h5>

              <b-row
                v-if="form[`aiEnabled${day}`]"
                :key="`open_hour_${day}_1_ai`"
                style="row-gap: .5rem"
              >
                <b-col
                  sm="12"
                  md="6"
                >
                  <b-form-group class="mb-0">
                    <b-input-group class="mb-0">
                      <b-form-input
                        v-model="form[`aiEnabled${day}From`]"
                        :disabled="!form[`aiEnabled${day}`]"
                        type="text"
                        placeholder="HH:mm"
                      />
                      <b-input-group-append>
                        <b-form-timepicker
                          size="sm"
                          button-variant="outline-primary"
                          button-only
                          :label-close-button="$t('Close')"
                          :label-no-time-selected="$t('NoTimeSelected')"
                          :disabled="!form[`aiEnabled${day}`]"
                          :value="form[`aiEnabled${day}From`]"
                          @input="(e) => {
                            const [h, m] = e.split(':')
                            form[`aiEnabled${day}From`] = `${h}:${m}`
                          }"
                        />
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>

                <b-col
                  sm="12"
                  md="6"
                >
                  <b-form-group class="mb-0">
                    <b-input-group class="mb-0">
                      <b-form-input
                        v-model="form[`aiEnabled${day}To`]"
                        :disabled="!form[`aiEnabled${day}`]"
                        type="text"
                        placeholder="HH:mm"
                      />
                      <b-input-group-append>
                        <b-form-timepicker
                          size="sm"
                          button-variant="outline-primary"
                          button-only
                          :label-close-button="$t('Close')"
                          :label-no-time-selected="$t('NoTimeSelected')"
                          :disabled="!form[`aiEnabled${day}`]"
                          :value="form[`aiEnabled${day}To`]"
                          @input="(e) => {
                            const [h, m] = e.split(':')
                            form[`aiEnabled${day}To`] = `${h}:${m}`
                          }"
                        />
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>

              <div
                v-if="!form[`aiEnabled${day}`]"
                :key="`open_hour_${day}_2`"
                class="bg-light-primary rounded p-1 text-center"
                variant="primary"
              >
                <div class="alert-body">
                  <strong>{{ $t(`days.${day.toUpperCase()}`) }}</strong> - {{ $t('Closed') }}
                </div>
              </div>
            </b-col>
          </b-row>
        </b-form-group>
        <!--    END:Availability    -->
      </div>

      <div>
        <div class="divider text-primary">
          <div class="divider-text">
            {{ $t('offer.website.Identity') }}
          </div>
        </div>

        <!--    START:Sygnet    -->
        <b-row style="row-gap: 1rem">
          <b-col
            cols="12"
            md="5"
            lg="3"
            xl="2"
          >
            <sw-form-input-image
              v-model="form.signet"
              :label="$t('Signet')"
              :info="$tc('file.Max', 1, { size: 50 })"
              max-size="50"
              fluid-x
              height="150px"
            />
          </b-col>
          <!--    END:Sygnet    -->

          <!--    START:Logo    -->
          <b-col
            cols="12"
            md="7"
            lg="4"
            xl="3"
          >
            <sw-form-input-image
              v-model="form.logo"
              label="Logo"
              :info="$tc('file.Max', 1, { size: 100 })"
              max-size="100"
              fluid-x
              height="150px"
            />
          </b-col>
        </b-row>
        <!--    END:Logo    -->
      </div>

      <div>
        <div class="divider text-primary">
          <div class="divider-text">
            {{ $t('Preferences') }}
          </div>
        </div>

        <!--    START:Color    -->
        <b-row style="row-gap: 1rem">
          <b-col
            cols="12"
            md="5"
            lg="3"
            xl="2"
          >
            <sw-form-input-color
              v-model="form.color"
              :label="$t('Color')"
              fluid-x
            />
          </b-col>

          <b-col
            cols="12"
            md="7"
            lg="4"
            xl="3"
          >
            <b-form-group
              :label="`${$t('Policy')} (URL)`"
              class="mt-50"
            >
              <template #label>
                <b-form-checkbox
                  v-model="form.welcomeMessageEnabled"
                  switch
                >
                  {{ $t(`WelcomeMessage`) }}
                </b-form-checkbox>
              </template>

              <validation-provider
                v-slot="{ errors }"
                :name="`${$t('Policy')} (URL)`"
              >
                <b-form-textarea
                  v-model="form.welcomeMessage"
                  type="text"
                  :state="errors.length > 0 ? false:null"
                  :disabled="!form.welcomeMessageEnabled"
                  trim
                  maxlength="80"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <!--    END:Color    -->
      </div>

      <div>
        <div class="divider text-primary">
          <div class="divider-text">
            {{ $t('DomainsWithAccess') }}
          </div>
        </div>

        <!--    START:Domains    -->
        <b-list-group
          v-if="form.allowedDomains.length"
          class="mb-1"
        >
          <b-list-group-item
            v-for="(domain, i) in form.allowedDomains"
            :key="i"
            class="d-flex justify-content-between align-items-center"
          >
            {{ domain }}

            <b-button
              variant="danger"
              size="sm"
              class="btn-icon p-25"
              @click="form.allowedDomains.splice(i, 1)"
            >
              <sw-icon icon="XIcon" />
            </b-button>
          </b-list-group-item>
        </b-list-group>
        <!--    END:Domains    -->

        <!--    START:No Data    -->
        <div
          v-else
          class="text-center mt-50 mb-2 font-weight-bold"
        >
          <sw-icon
            size="17"
            icon="XCircleIcon"
          />
          {{ $t('NoData') }}
        </div>
        <!--    END:No Data    -->

        <!--    START:Push Domain    -->
        <b-input-group v-if="!form.allowedDomains.length">
          <!--    START:Name    -->
          <b-form-input
            v-model="newDomain"
            :label="$t('Domain')"
            :placeholder="$t('Domain')"
          />
          <!--    END:Name    -->

          <b-input-group-append>
            <b-button
              variant="primary"
              :disabled="!newDomain.length || Boolean(form.allowedDomains.find(domain => domain === newDomain))"
              @click="form.allowedDomains.push(newDomain); newDomain = ''"
            >
              {{ $t('Add') }}

              <sw-icon icon="PlusIcon" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
        <!--    END:Push Domain    -->
      </div>

      <div class="d-flex justify-content-end mt-2">
        <b-button
          :disabled="invalid || loading"
          variant="primary"
          size="sm"
          @click="save"
        >
          <template v-if="!loading">
            {{ $t('Save') }}
          </template>

          <b-spinner
            v-else
            small
            variant="light"
          />
        </b-button>
      </div>
    </validation-observer>

    <sw-info-overlay :show="loadingGlobal">
      <template #icon>
        <b-spinner variant="primary" />
      </template>
    </sw-info-overlay>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import { BFormTimepicker, BInputGroupAppend } from 'bootstrap-vue'
import {
  CREATE_WIDGET_CHATS, GET_WIDGET_CHAT, HIDE_MODAL, RELOAD_CONTENT, UPDATE_WIDGET_CHAT,
} from '@/@constants/mutations'
import axiosIns from '@/libs/axios'

export default {
  components: {
    ValidationProvider,
    // eslint-disable-next-line vue/no-unused-components
    BFormTimepicker,
    // eslint-disable-next-line vue/no-unused-components
    BInputGroupAppend,
    ValidationObserver,
    vSelect,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data: () => ({
    loadingGlobal: false,
    loading: false,
    aiAssistants: [],

    daysOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],

    newDomain: '',

    form: {
      availabilityMondayEnabled: true,
      availabilityMondayFrom: '00:00',
      availabilityMondayTo: '23:59',
      availabilityTuesdayEnabled: true,
      availabilityTuesdayFrom: '00:00',
      availabilityTuesdayTo: '23:59',
      availabilityWednesdayEnabled: true,
      availabilityWednesdayFrom: '00:00',
      availabilityWednesdayTo: '23:59',
      availabilityThursdayEnabled: true,
      availabilityThursdayFrom: '00:00',
      availabilityThursdayTo: '23:59',
      availabilityFridayEnabled: true,
      availabilityFridayFrom: '00:00',
      availabilityFridayTo: '23:59',
      availabilitySaturdayEnabled: true,
      availabilitySaturdayFrom: '00:00',
      availabilitySaturdayTo: '23:59',
      availabilitySundayFrom: '00:00',
      availabilitySundayTo: '23:59',
      aiEnabledMondayEnabled: true,
      aiEnabledMondayFrom: '00:00',
      aiEnabledMondayTo: '23:59',
      aiEnabledTuesdayEnabled: true,
      aiEnabledTuesdayFrom: '00:00',
      aiEnabledTuesdayTo: '23:59',
      aiEnabledWednesdayEnabled: true,
      aiEnabledWednesdayFrom: '00:00',
      aiEnabledWednesdayTo: '23:59',
      aiEnabledThursdayEnabled: true,
      aiEnabledThursdayFrom: '00:00',
      aiEnabledThursdayTo: '23:59',
      aiEnabledFridayEnabled: true,
      aiEnabledFridayFrom: '00:00',
      aiEnabledFridayTo: '23:59',
      aiEnabledSaturdayEnabled: true,
      aiEnabledSaturdayFrom: '00:00',
      aiEnabledSaturdayTo: '23:59',
      aiEnabledSundayFrom: '00:00',
      aiEnabledSundayTo: '23:59',
      allowedDomains: [],
    },
  }),
  computed: {
    ...mapGetters({
      modalConfig: 'modal/getModalState',
    }),
  },
  async mounted() {
    await this.loadAiAssistants()

    if (this.data?.id) await this.loadItem(this.data.id)
  },
  methods: {
    async loadItem(id) {
      this.loadingGlobal = true

      const item = await this.$store.dispatch(`chatWidgets/${GET_WIDGET_CHAT}`, id)

      // Prepare dates
      // eslint-disable-next-line
      for (const day of this.daysOfWeek) {
        const enabled = item[`availability${day}Enabled`]
        const from = item[`availability${day}From`]
        const to = item[`availability${day}To`]

        const aiEnabled = item[`aiEnabled${day}`]
        const aiFrom = item[`aiEnabled${day}From`]
        const aiTo = item[`aiEnabled${day}To`]

        if (enabled) item[`availability${day}Enabled`] = enabled
        // eslint-disable-next-line
        if (from) item[`availability${day}From`] = from.date.split(' ')[1].split('.')[0].substring(0, 5)
        // eslint-disable-next-line
        if (to) item[`availability${day}To`] = to.date.split(' ')[1].split('.')[0].substring(0, 5)

        if (aiEnabled) item[`aiEnabled${day}`] = aiEnabled
        // eslint-disable-next-line
        if (aiFrom) item[`aiEnabled${day}From`] = aiFrom.date.split(' ')[1].split('.')[0].substring(0, 5)
        // eslint-disable-next-line
        if (aiTo) item[`aiEnabled${day}To`] = aiTo.date.split(' ')[1].split('.')[0].substring(0, 5)
      }

      if (item.salesAiEnvironment?.id) item.salesAiEnvironment = item.salesAiEnvironment.id

      this.form = item

      this.loadingGlobal = false
    },

    async getPayload() {
      const payload = JSON.parse(JSON.stringify(this.form))

      if (this.form.id) payload.id = this.form.id
      if (this.form.signet) payload.signet = this.form.signet?.base64 || this.form.signet
      if (this.form.logo) payload.logo = this.form.logo?.base64 || this.form.logo

      if (!this.form.id) payload.enabled = true

      if (payload.color.hex) payload.color = payload.color.hex

      if (this.form.updatedAt) delete payload.updatedAt
      if (this.form.createdAt) delete payload.createdAt
      if (this.form.createdBy) delete payload.createdBy

      return payload
    },

    async save() {
      this.loading = true

      const payload = await this.getPayload()

      const action = payload.id ? UPDATE_WIDGET_CHAT : CREATE_WIDGET_CHATS

      const newItem = await this.$store.dispatch(`chatWidgets/${action}`, payload)

      payload.id = newItem.id

      this.$store.commit(`modal/${RELOAD_CONTENT}`, payload)
      await this.$store.dispatch(`swModal/${HIDE_MODAL}`, this.id)

      this.loading = false
    },

    async loadAiAssistants() {
      const params = {
        fields_load: 'AI_ENVIRONMENT_SHORT',
      }

      const list = await axiosIns.get('1/ai/salesai/environments', { params })

      this.aiAssistants = list.data.data.items
    },
  },
}
</script>
