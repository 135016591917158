<template>
  <div class="mb-1">
    <b-col
      v-if="!viewMode"
      md="12"
      class="mb-2 px-0"
    >
      <div
        class="mb-1 d-flex flex-wrap"
        style="gap: .34rem"
      >
        <b-button
          variant="warning"
          size="sm"
          @click="$router.go(-1)"
        >
          <feather-icon icon="ArrowLeftIcon" />

          {{ $t('Return') }}
        </b-button>

        <b-button
          size="sm"
          :disabled="!rankItem.name"
          variant="primary"
          @click="savePermission"
        >
          {{ $t('Save') }}
        </b-button>
      </div>

      <b-form-group :label="$t('PermissionName')">
        <b-form-input v-model="rankItem.name" />
      </b-form-group>
    </b-col>

    <b-col
      v-if="viewMode && !hideTitle"
      sm="12"
    >
      <h3 class="text-primary">
        {{ $t('contact.rank') }}: {{ rankItem.name }}
      </h3>
    </b-col>

    <!--    <b-col-->
    <!--      v-if="mode === 'view'"-->
    <!--      cols="12"-->
    <!--      class="pl-0 ml-0"-->
    <!--    >-->
    <!--      <h4>{{ $t('AssignedPermission') }}: <span class="text-primary">{{ permissionName }}</span></h4>-->
    <!--    </b-col>-->

    <b-tabs>
      <b-tab :title="$t('Uprawnienia systemowe')">
        <b-form-checkbox-group>
          <!-- Section View -->
          <b-card
            v-for="(section, index) in getAvailableRanks"
            :key="`section_${index}`"
          >
            <b-card-title>
              <h3>{{ $t(`${section.section}`) }}</h3>

              <div class="d-flex flex-wrap">
                <b-button
                  v-if="!viewMode"
                  v-b-tooltip
                  variant="flat-primary"
                  size="sm"
                  class="px-25"
                  :title="$t('SelectAll')"
                  @click="selectProcess(section, true)"
                >
                  <feather-icon
                    class="mr-50"
                    icon="CheckSquareIcon"
                  />

                  <span class="align-middle">{{ $t('SelectAll') }}</span>
                </b-button>

                <b-button
                  v-if="!viewMode"
                  v-b-tooltip
                  variant="flat-primary"
                  size="sm"
                  class="px-25"
                  :title="$t('UnselectAll')"
                  @click="selectProcess(section, false)"
                >
                  <feather-icon
                    class="mr-50"
                    icon="SquareIcon"
                  />

                  <span class="align-middle">{{ $t('UnselectAll') }}</span>
                </b-button>
              </div>
            </b-card-title>

            <b-row
              class="mt-n1"
              :class="{ 'locked-section': checkRequiredRanks(section) }"
            >
              <!-- Section Ranks -->
              <b-col
                v-for="(ranks, key) in section.ranks"
                :key="`section_${index}_${key}`"
                sm="12"
                md="6"
                lg="4"
              >
                <b-form-checkbox
                  v-for="rank in ranks"
                  :key="rank"
                  v-model="rankItem.permissions"
                  :value="rank.toString()"
                  class="d-block mt-25"
                  :disabled="viewMode"
                  :class="[ `custom-control-${resolveTypeColor(key)}` ]"
                  :variant="resolveTypeColor(key)"
                >
                  <span class="text-break">{{ $t(`ranks.${rank}`) }}</span>
                </b-form-checkbox>
              </b-col>

              <!-- Subsections -->
              <b-col>
                <div
                  v-for="(subSection, subSectionIndex) in section.subsections"
                  :key="`section_${index}_${subSectionIndex}`"
                >
                  <b-row v-if="checkRequiredModules(subSection)">
                    <b-col
                      cols="12"
                      sm="12"
                      class="mt-2"
                    >
                      <h5>
                        {{ $t(`${subSection.section}`) }}
                      </h5>
                    </b-col>
                    <b-col
                      v-for="(ranks, key) in subSection.ranks"
                      :key="`section_${index}_${subSectionIndex}_${key}`"
                      sm="12"
                      md="6"
                      lg="4"
                    >
                      <div
                        v-for="(rank, rankIndex) in ranks"
                        :key="`section_${index}_${rankIndex}_${subSectionIndex}_${key}`"
                      >
                        <b-form-checkbox
                          v-if="typeof rank !== 'object'"
                          v-model="rankItem.permissions"
                          :value="rank.toString()"
                          class="d-block mt-25"
                          :disabled="viewMode"
                          :class="[ `custom-control-${resolveTypeColor(key)}` ]"
                          :variant="resolveTypeColor(key)"
                        >
                          <span class="text-break">{{ $t(`ranks.${rank}`) }}</span>
                        </b-form-checkbox>
                        <div v-else>
                          <b-form-checkbox
                            v-model="rankItem.permissions"
                            v-b-tooltip
                            :value="rank.rank.toString()"
                            class="d-block mt-25"
                            :disabled="viewMode"
                            :variant="resolveTypeColor(key)"
                            :class="[ `custom-control-${resolveTypeColor(key)}` ]"
                            :title="rank.information ? $t(`${rank.information}`) : ''"
                          >
                            <span>
                              <span class="text-break">{{ $t(`ranks.${rank.rank}`) }}</span>

                              <span
                                v-if="rank.information"
                                class="ml-50"
                              >
                                <feather-icon icon="AlertCircleIcon" />
                              </span>
                            </span>
                          </b-form-checkbox>
                          <sw-select>
                            <v-select
                              v-if="rank.type"
                              v-show="rankItem.permissions.includes(rank.rank.toString())"
                              v-model="rankItem.chatViewRanks"
                              :disabled="viewMode"
                              :reduce="r => r.id"
                              class="mt-50"
                              multiple
                              :options="ranksList"
                              label="name"
                            />
                          </sw-select>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
            </b-row>

            <!-- Section Overlat -->
            <b-overlay
              no-wrap
              :show="checkRequiredRanks(section)"
              spinner-variant="primary"
              opacity=".9"
            >
              <template #overlay>
                <div>
                  <feather-icon icon="LockIcon" />
                  {{ $t('SelectToUnlock') }}
                </div>

                <ul v-if="section.meta && section.meta.requiredRanks">
                  <li
                    v-for="(rank, index) in section.meta.requiredRanks"
                    :key="`$${rank}_${index}`"
                    class="text-break"
                  >
                    {{ $t(`ranks.${rank}`) }}
                  </li>
                </ul>
              </template>
            </b-overlay>
          </b-card>
        </b-form-checkbox-group>
      </b-tab>

      <!-- View Permissions -->
      <b-tab
        v-if="checkRequiredHostnameFragments(['hydro'])"
        :title="$t('Uprawnienia widokowe')"
      >
        <div
          v-for="(section, index) in viewPermissionsList"
          :key="`section_${index}`"
        >
          <b-card v-if="!section.meta.requiredModule || (section.meta.requiredModule && checkRequiredModules(section.meta.requiredModule))">
            <b-card-title>
              <h3>{{ $tc(`${section.section}`, 1) }} <span v-if="section.addonLabel">({{ $t(section.addonLabel) }})</span></h3>
              <div class="d-flex flex-wrap">
                <b-button
                  v-if="!viewMode"
                  v-b-tooltip
                  variant="flat-primary"
                  size="sm"
                  class="px-25"
                  :title="$t('SelectAll')"
                  @click="selectViewProcess(section, true)"
                >
                  <feather-icon
                    class="mr-50"
                    icon="CheckSquareIcon"
                  />

                  <span class="align-middle">{{ $t('SelectAll') }}</span>
                </b-button>

                <b-button
                  v-if="!viewMode"
                  v-b-tooltip
                  variant="flat-primary"
                  size="sm"
                  class="px-25"
                  :title="$t('UnselectAll')"
                  @click="selectViewProcess(section, false)"
                >
                  <feather-icon
                    class="mr-50"
                    icon="SquareIcon"
                  />

                  <span class="align-middle">{{ $t('UnselectAll') }}</span>
                </b-button>
              </div>
            </b-card-title>

            <b-row
              class="mt-n1"
              :class="{ 'locked-section': checkRequiredRanks(section) }"
            >
              <b-col
                v-if="section.ranks"
                sm="12"
              >
                <b-row>
                  <b-col
                    v-for="(rankObject, key) in section.ranks"
                    :key="`ranks_section_${index}__${key}`"
                    sm="12"
                    md="6"
                    lg="3"
                  >
                    <b-form-checkbox
                      v-model="rankItem.viewPermissions"
                      :value="rankObject.key.toString()"
                      class="d-block mt-25"
                      :disabled="viewMode"
                      :class="[ `custom-control-${resolveTypeColor(key)}` ]"
                      :variant="resolveTypeColor(key)"
                    >
                      <span v-if="!rankObject.label"
                            class="text-break"
                      >{{ $t(`ViewPermissions.${rankObject.key}`) }}</span>
                      <span v-else
                            class="text-break"
                      >{{ $tc(`${rankObject.coreLabel}`, 1, { column: $t(rankObject.label) }) }}</span>
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
              <b-col
                v-if="section.form"
                sm="12"
              >
                <b-row>
                  <b-col sm="12">
                    <h5>
                      {{ $t(`Form`) }}
                    </h5>
                  </b-col>
                  <b-col
                    v-for="(rankObject, key) in section.form"
                    :key="`form_section_${index}__${key}`"
                    sm="12"
                    md="6"
                    lg="3"
                  >
                    <b-form-checkbox
                      v-model="rankItem.viewPermissions"
                      :value="rankObject.key.toString()"
                      class="d-block mt-25"
                      :disabled="viewMode"
                      :class="[ `custom-control-${resolveTypeColor(key)}` ]"
                      :variant="resolveTypeColor(key)"
                    >
                      <span v-if="!rankObject.label"
                            class="text-break"
                      >{{ $t(`ViewPermissions.${rankObject.key}`) }}</span>
                      <span v-else
                            class="text-break"
                      >{{ $tc(`${rankObject.coreLabel}`, 1, { column: $t(rankObject.label) }) }}</span>
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
              <!-- Subsections -->
              <b-col>
                <div
                  v-for="(subSection, subSectionIndex) in section.subsections"
                  :key="`section_${index}_${subSectionIndex}`"
                >
                  <b-row v-if="checkRequiredModules(subSection)">
                    <b-col
                      cols="12"
                      sm="12"
                      class="mt-1"
                    >
                      <h5>
                        {{ $tc(`${subSection.section}`, 1) }} <span v-if="subSection.addonLabel">({{ $t(subSection.addonLabel) }})</span>
                      </h5>
                    </b-col>
                    <!-- List of subsection ranks -->
                    <b-col
                      v-for="(rankObject, key) in subSection.ranks"
                      :key="`section_${index}_${subSectionIndex}_${key}`"
                      sm="12"
                      md="6"
                      lg="4"
                    >
                      <b-form-checkbox
                        v-model="rankItem.viewPermissions"
                        :value="rankObject.key.toString()"
                        class="d-block mt-25"
                        :disabled="viewMode"
                        :class="[ `custom-control-${resolveTypeColor(key)}` ]"
                        :variant="resolveTypeColor(key)"
                      >
                        <span v-if="!rankObject.label"
                              class="text-break"
                        >{{ $t(`ViewPermissions.${rankObject.key}`) }}</span>
                        <span v-else
                              class="text-break"
                        >{{ $tc(`${rankObject.coreLabel}`, 1, { column: $t(rankObject.label) }) }}</span>
                        <!--                        <span class="text-break">{{ $t(`ViewPermissions.${rankObject.key}`) }}</span>-->
                      </b-form-checkbox>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
            </b-row>

            <!-- Section Overlat -->
            <b-overlay
              no-wrap
              :show="checkRequiredRanks(section)"
              spinner-variant="primary"
              opacity=".9"
            >
              <template #overlay>
                <div>
                  <feather-icon icon="LockIcon" />
                  {{ $t('SelectToUnlock') }}
                </div>

                <ul v-if="section.meta && section.meta.requiredRanks">
                  <li
                    v-for="(rank, index) in section.meta.requiredRanks"
                    :key="`$${rank}_${index}`"
                    class="text-break"
                  >
                    {{ $t(`ranks.${rank}`) }}
                  </li>
                </ul>
              </template>
            </b-overlay>
          </b-card>
        </div>
      </b-tab>
    </b-tabs>
    <b-button
      v-if="!viewMode"
      :disabled="!rankItem.name"
      variant="primary"
      @click="savePermission"
    >
      {{ $t('Save') }}
    </b-button>
  </div>
</template>

<script>
/* eslint-disable */

import { mapGetters } from 'vuex'
import { BCardTitle, BFormCheckboxGroup, BFormInput, BTab, BTabs, VBTooltip } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { getRanksList } from '@/helpers/permissions'
import axiosIns from '@/libs/axios'
import { viewPermissionsList } from '@/helpers/viewPermissions'

export default {
    name: 'RolesCreator',
    components: {
        BTabs,
        BTab,
        BFormCheckboxGroup,
        vSelect,
        BFormInput,
        BCardTitle,
    },
    directives: {
        'b-tooltip': VBTooltip,
    },
    props: {
        mode: {
            // select, view, edit
            default: 'edit',
            type: String,
        },
        defaultPermissions: {
            default: null,
            type: Array,
        },
        defaultRank: {
            default: null,
            type: Object,
        },
        permissionName: {
            default: null,
            type: String,
        },

        // new
        rankId: {
            default: null,
            type: String,
        },
        viewMode: {
            default: false,
            type: Boolean,
        },
        hideTitle: {
            default: false,
            type: Boolean,
        }
    },
    data: () => ({
        viewPermissionsList,
        rankItem: {
          name: '',
          permissions: [],
          chatViewRanks: [],
          viewPermissions: [],
        },
        permissionsList: (getRanksList()),
    }),
    computed: {
        ...mapGetters({
            ranksList: 'ranks/getRanksList',
            settings: 'system/getSettings',
        }),
        getAvailableRanks() {
            return this.permissionsList.filter(section => !section?.meta?.requiredModules?.length || this.checkRequiredModules(section?.meta?.requiredModules));
        }
    },
    watch: {
        // defaultRank: {
        //     deep: true,
        //     handler(newVal, oldVal) {
        //         if (newVal && !oldVal) {
        //             this.$refs['rank-select'].updateValue(newVal)
        //         }
        //     },
        // },
        '$route.params.rankId': {
            deep: true,
            handler(newValue, oldValue) {
                if (newValue !== oldValue) this.getRank()
            },
        },
        rankId: {
            deep: true,
            handler(newValue, oldValue) {
                if (newValue !== oldValue) this.getRank()
            },
        },
    },
    async mounted() {
        await this.getRank()
        // this.$store.dispatch('ranks/GET_RANKS_LIST')
        // if (this.defaultRank) {
        //     this.onSelectRank(this.defaultRank.id)
        //     this.$refs['rank-select'].updateValue(this.defaultRank.name)
        // }
        // if (this.defaultPermissions) this.permissions = [...this.defaultPermissions]
    },
    methods: {
        async getRank() {
            this.loading = true

            try {
                const id = this?.rankId || this.$route.params.rankId
                if (id === 'new' || !id) return

                const params = {fields_load: this.$fields.RANKS,}

                const resp = await axiosIns.get(`1/ranks/${id}`, { params })

                if (resp?.data?.data) {
                    this.rankItem = resp.data.data.item
                }
            } catch (err) {
                this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            } finally {
                this.loading = false
            }
        },
        resolveTypeColor(type) {
            if (type === 'view') return 'primary'
            if (type === 'modify') return 'warning'
            if (type === 'delete') return 'danger'

            return 'primary'
        },
        // eslint-disable-next-line consistent-return
        // checkRequired(requiredRanks) {
        //     if (requiredRanks.requiredToView) {
        //         let flag = false
        //         requiredRanks.requiredToView.forEach(requiredRank => {
        //             if (requiredRanks.sectionKey === 'contacts' && !this.permissions.includes(requiredRank[0])) {
        //                 return
        //             }
        //             if (!flag) {
        //                 flag = this.permissions.includes(requiredRank[0])
        //             }
        //         })
        //         return !flag
        //     }
        // },
        savePermission() {
            let rank = { ...this.rankItem }

            rank.chatViewRanks = rank.chatViewRanks.mapKeys()
            rank.permissions = (rank.permissions || []).filter(rank => !!this.$roles[rank])

            delete rank.createdAt
            delete rank.createdBy

            if (!rank.viewPermissions || !rank.viewPermissions.length) rank.viewPermissions = []

            this.$store.dispatch('ranks/UPDATE_RANK', rank)
                .then(res => {
                    this.$store.dispatch('ranks/GET_RANKS_LIST')
                    if (!rank.id) rank.id = res.data?.items[0]?.id
                    this.rankItem = rank
                    this.id = res.data?.items[0]?.id
                    this.name = rank.name
                    this.$store.dispatch('auth/REFRESH_USER_DATA')
                    this.showToast('success', this.$i18n.t('success.contactUpdated'))
                })
                .catch(err => {
                    this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
                })
        },
        // onDeleteRank(rankId) {
        //     this.showAlert('error', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('alerts.WontbeAble'))
        //         .then(() => {
        //             this.$store.dispatch('ranks/DELETE_RANK', rankId)
        //                 .then(() => {
        //                     this.id = null
        //                     this.permissions = []
        //                     this.name = ''
        //                     this.rankItem = ''
        //                     this.showToast('success', this.$i18n.t('PermissionDelete'), this.$i18n.t('alert.Success'))
        //                 })
        //                 .catch(err => {
        //                     this.showToast('danger', this.$i18n.t(`errors.${err.response.data.message}`))
        //                 })
        //         })
        // },
        // onSelectRank(val) {
        //     if (val) {
        //         this.$store.dispatch('ranks/GET_RANK', val)
        //             .then(res => {
        //                 this.id = res.id
        //                 this.permissions = res.permissions
        //                 this.name = res.name
        //                 this.chatViewRanks = res.chatViewRanks.map(rank => (rank === res.id ? { id: res.id, name: res.name } : rank))
        //             })
        //     } else {
        //         this.id = null
        //         this.permissions = []
        //         this.name = ''
        //         this.rankItem = ''
        //     }
        // },

        // New
        // eslint-disable-next-line consistent-return
        checkRequiredRanks(section) {
            const requiredRanks = section?.meta?.requiredRanks
            if (!requiredRanks) return false

            const selectedRanks = this.permissions
            // eslint-disable-next-line
            for (const rank of requiredRanks) {
                if (this.rankItem.permissions.includes(rank)) {
                    return false
                }
            }

            this.removeLockedRanks(section)
            return true
        },
        checkRequiredModules(section) {
            const module = section?.meta?.requiredModules

            if (!module) return true
            return this.checkRequiredModule(module)
        },
        removeLockedRanks({ ranks, subsections = [] }) {
            const _ranks = ranks
            subsections.forEach(subsection => {
                this.removeLockedRanks(subsection)
            })

            for (const rankType in _ranks) {
                _ranks[rankType].forEach(rank => {
                    const index = this.rankItem.permissions.findIndex(p => p === rank)
                    if (index !== -1) {
                        this.$delete(this.rankItem.permissions, index)
                    }
                })
            }
        },
        selectProcess({ ranks, subsections = [] }, select) {
            const _ranks = ranks
            subsections.forEach(subsection => {
                this.selectProcess(subsection, select)
            })

            for (const rankType in _ranks) {
                _ranks[rankType].forEach(rank => {
                    const index = this.rankItem.permissions.findIndex(p => p === rank?.rank || p === rank)
                    if (index !== -1) {
                        if (!select) this.$delete(this.rankItem.permissions, index)
                    } else {
                        if (select) this.rankItem.permissions.push(typeof rank === 'object' ? rank.rank : rank)
                    }
                })
            }
        },
        selectViewProcess({ ranks, subsections = [], form = [] }, select) {
            const _ranks = ranks?.length ? ranks : form
            subsections.forEach(subsection => {
                this.selectViewProcess(subsection, select)
            })

            if (!Array.isArray(_ranks)) return []
            for (const rankType of _ranks) {
                const index = this.rankItem.viewPermissions.findIndex(p => p === rankType?.key)
                if (index !== -1) {
                    if (!select) this.$delete(this.rankItem.viewPermissions, index)
                } else {
                    if (select) this.rankItem.viewPermissions.push(rankType.key)
                }
            }
        },
    },
}
</script>

<style scoped>
.locked-section {
    max-height: 19.5vh;
    overflow: hidden;
}
</style>
