<!--eslint-disable-->

<template>
  <div>
    <h4 class="mb-1">
      {{ $t('UserCommissionForOwnSales') }}
    </h4>

    <b-row style="row-gap: 1rem">
      <!--    START::Select a commission type    -->
      <b-col md="6">
        <validation-provider
          v-slot="{ errors }"
          :name="$t('SelectCommissionType')"
          rules="required"
        >
          <sw-select
            class="mb-0"
            :state="errors.length > 0 ? false:null"
            :name="$t('SelectCommissionType')"
            :tip="$t('YouWillSpecifyValueOfCommissionForSaleInEachProduct')"
          >
            <v-select
              v-model="target.commissionType"
              :options="commissionTypes"
              label="label"
              :reduce="i => i.value"
            >
              <template #selected-option="{ label }">
                <span>{{ $t(`${label}`) }}</span>
              </template>
              <template #option="{ label }">
                <span>{{ $t(`${label}`) }}</span>
              </template>
              <template #no-options>
                {{ $t('NoOptions') }}
              </template>
            </v-select>
          </sw-select>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col>
      <!--    END::Select a commission type    -->

      <!--    START::Choose the method of determining the amount of commission    -->
      <b-col md="6">
        <validation-provider
          v-slot="{ errors }"
          :name="$t('ChooseMethodOfDeterminingAmountOfCommission')"
          rules="required"
        >
          <sw-select
            class="mb-0"
            :state="errors.length > 0 ? false:null"
            :name="$t('ChooseMethodOfDeterminingAmountOfCommission')"
            :tip="$t('YouWillSpecifyAmountOfCommissionDependingOnNumber')"
          >
            <v-select
              v-model="target.determineCommissionAmountType"
              :options="determineCommissionAmountTypes"
              label="label"
              :reduce="i => i.value"
            >
              <template #selected-option="{ label }">
                <span>{{ $t(`${label}`) }}</span>
              </template>
              <template #option="{ label }">
                <span>{{ $t(`${label}`) }}</span>
              </template>
              <template #no-options>
                {{ $t('NoOptions') }}
              </template>
            </v-select>
          </sw-select>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col>
      <!--    END::Choose the method of determining the amount of commission    -->

      <!--    START::Enable User Discount    -->
      <b-col sm="12">
        <validation-provider
          v-slot="{ errors }"
          :name="$t('EnableUserDiscount')"
        >
          <div class="d-flex">
            <b-form-checkbox
              v-model="target.enableUserDiscount"
              variant="primary"
              switch
            >
              {{ $t('EnableUserDiscount') }}
            </b-form-checkbox>

            <b-button
              v-b-tooltip.focus.v-primary
              variant="flat-primary"
              :title="$t('ItEnablesUserToGrantDiscountToCounterpartyUpToAmountOfHisOwnCommission')"
              class="btn-icon p-25 mt-n50 ml-25"
              size="sm"
            >
              <feather-icon icon="InfoIcon" />
            </b-button>
          </div>

          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col>
      <!--    END::Enable User Discount    -->

      <!--    START::Specify the maximum User Discount    -->
      <!--      <b-col sm="12">-->
      <!--        <validation-provider-->
      <!--          #default="{ errors }"-->
      <!--          :name="$t('SpecifyMaximumUserDiscount')"-->
      <!--          rules="required"-->
      <!--        >-->
      <!--          <sw-select class="mb-0"-->
      <!--            :state="errors.length > 0 ? false:null"-->
      <!--            :name="$t('SpecifyMaximumUserDiscount')"-->
      <!--          >-->
      <!--            <v-select-->
      <!--              v-model="target.maxUserDiscount"-->
      <!--              :options="maxUserDiscounts"-->
      <!--            >-->
      <!--              <template #no-options>-->
      <!--                {{ $t('NoOptions') }}-->
      <!--              </template>-->
      <!--            </v-select>-->
      <!--          </sw-select>-->

      <!--          <small class="text-danger">{{ errors[0] }}</small>-->
      <!--        </validation-provider>-->
      <!--      </b-col>-->
      <!--    END::Specify the maximum User Discount    -->
    </b-row>

    <b-overlay
      :show="isLoading"
      no-wrap
      spinner-variant="primary"
    />
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import { VBTooltip } from 'bootstrap-vue'

export default {
  components: {
    ValidationProvider,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    target: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    cleaveOptions: {
      number: {
        delimiter: ' ',
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
        numeralPositiveOnly: true,
      },
    },
    // Wartość bezwzględna przypisana w produkcie
    // Wartość procentowa przypisana w produkcie
    // Wartość procentowa od całości sprzedaży
    commissionTypes: [
      { value: 'ABSOLUTE_VALUE_IN_PRODUCT', label: 'AbsoluteValueAssignedInTheProduct' },
      { value: 'PERCENTAGE_IN_PRODUCT', label: 'PercentageAssignedInTheProduct' },
      { value: 'PERCENTAGE_TOTAL', label: 'PercentageOfTotalSales' },
    ],
    // Wysokość prowizji za dany produkt zależna od ilości sprzedaży różnych produktów
    // Wysokość prowizji zależna od wartości sprzedaży różnych produktów
    determineCommissionAmountTypes: [
      { value: 'COMMISSION_BY_PRODUCT', label: 'AmountOfCommissionForAGivenProduct' },
      { value: 'COMMISSION_BY_AMOUNT', label: 'CommissionAmountDependsOnSales' },
    ],
    maxUserDiscounts: ['maxUserDiscounts'],
  }),
}
</script>
