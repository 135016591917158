<template>
  <div>
    <!--    Option: Name     -->
    <b-form-group :label="$t('Name')">
      <b-form-input
        v-model="item.name"
        :placeholder="$t('Name')"
      />
    </b-form-group>
    <!-- FILE -->
    <div v-if="documentsType === 'FILE'">
      <b-form-group :label="$t('File')">
        <div v-if="editedFile">
          <documents-list :documents="[editedFile.file]" />
        </div>
        <drag-drop-upload
          v-else
          :mt="false"
          :multiple="false"
          @onChangeFiles="changeFiles($event)"
        />
      </b-form-group>
    </div>
    <!-- VIDEO -->
    <div v-if="documentsType === 'VIDEO'">
      <b-form-group
        v-if="!editedFile"
        :label="$t('LinkYT')"
      >
        <b-form-input
          v-model.trim="item.url"
          :placeholder="$t('LinkYT')"
        />
      </b-form-group>
    </div>

    <b-button
      variant="success"
      size="sm"
      :disabled="!item.name || (!allowByFile && !editedFile)"
      @click="$emit('save-item', item)"
    >
      {{ $t('Save') }}
    </b-button>
  </div>
</template>

<script>
import DragDropUpload from '@/views/components/DragDropUpload.vue'
import DocumentsList from '@/views/components/DocumentsList.vue'

export default {
  name: 'NewFolderModal',
  components: {
    DragDropUpload,
    DocumentsList,
  },
  props: {
    editedFile: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    item: {
      name: '',
      url: '',
      file: '',
    },
    isModalOpen: false,
  }),
  computed: {
    documentsType() {
      if (this.$route.name === 'documents') return 'FILE'

      return 'VIDEO'
    },
    allowByFile() {
      if (this.documentsType !== 'VIDEO') {
        return !!this.item.file
      }

      const p = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/
      if (this.item.url.match(p)) {
        return this.item.url.match(p)[1]
      }
      return false
    },
  },
  mounted() {
    if (this.editedFile) {
      Object.keys(this.editedFile).forEach(key => {
        if (key === 'id') this.item.id = this.editedFile.id
        // eslint-disable-next-line no-prototype-builtins
        else if (this.item.hasOwnProperty(key)) this.item[key] = this.editedFile[key]
      })
    }
  },
  methods: {
    changeFiles(files) {
      this.$set(this.item, 'file', files[0])
    },
  },
}
</script>

<style scoped>

</style>
