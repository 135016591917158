import {
  CREATE_CHAT_CONVERSATION,
  CREATE_CHAT_CONVERSATION_MESSAGE,
  GET_CHAT_CONVERSATION_MESSAGES,
  GET_CHAT_CONVERSATIONS, GET_CHAT_CONVERSATIONS_UNREAD_MESSAGES,
  GET_USERS,
} from '@/@constants/mutations'
// eslint-disable-next-line import/no-cycle
import axiosIns from '@/libs/axios'
import { CHAT_VIEW_RANK } from '@/helpers/permissions'
/* eslint-disable */
import * as fields from '@/@constants/fields'
import ClientWebsocketService from '@/helpers/websocket'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // eslint-disable-next-line no-unused-vars
    [GET_USERS]: ({ commit, rootGetters }, payload) => new Promise((resolve, reject) => {
      const currentUser = rootGetters['auth/getCurrentUser']
      let params = {}
      if (payload?.search) {
        const search = payload.search.replaceAll(' ', '%')
        params = {
          search: `%${search}%`,
          search_fields: 'firstName,lastName,mailAddress-mail,mailAddressPrivate-mail,phoneNumber-phone,phoneNumberPrivate-phone',
        }
      }
      params.fields_load = fields.BASIC_USER

      if (currentUser?.rank?.permissions?.includes(CHAT_VIEW_RANK.toString())) {
        params.eqArr_rank = JSON.stringify([...currentUser?.rank?.chatViewRanks.map(item => item?.id || item).filter(Boolean)])
      }

      axiosIns.get('1/users', { params })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [GET_CHAT_CONVERSATIONS]: ({ commit }) => new Promise((resolve, reject) => {
      const params = {
        fields_load: fields.CHAT,
      }

      axiosIns.get('1/chats/conversations', { params })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [GET_CHAT_CONVERSATIONS_UNREAD_MESSAGES]: ({ commit }) => new Promise((resolve, reject) => {
      const params = {
        fields_load: fields.CHAT_UNREAD_MESSAGES,
      }

      axiosIns.get('1/chats/conversations', { params })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    GET_CLIENT_CHAT_CONVERSATIONS_UNREAD_MESSAGES: ({ commit }) => new Promise((resolve, reject) => {
      const params = {
        fields_load: fields.CHAT_UNREAD_MESSAGES,
      }

      axiosIns.get('1/chats/conversations', { params })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [GET_CHAT_CONVERSATION_MESSAGES]: ({ commit }, { conversationId, loadFromLastId = null, loadFromFirstId = null }) => new Promise((resolve, reject) => {
      const params = {
        fields_load: fields.CHAT_CONVERSATION,
        limit: 20,
      }

      if (loadFromLastId) {
        params.gt_id = loadFromLastId
      } else if (loadFromFirstId) {
        params.lt_id = loadFromFirstId
        params.orderBy = 'id.desc'
      } else {
        params.orderBy = 'id.desc'
      }

      axiosIns.get(`1/chats/conversations/${conversationId}/messages`, { params })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    GET_CLIENT_CHAT_CONVERSATION_MESSAGES: ({ commit }, {
      conversationId, loadFromLastId = null, loadFromFirstId = null, typing = false,
    }) => new Promise((resolve, reject) => {
      const params = {
        fields_load: 'CLIENT_CHAT_USER_MESSAGES',
        limit: 20,
        withActivity: true,
      }

      if (typing) {
        params.writing = true
      }

      if (loadFromLastId) {
        params.gt_id = loadFromLastId
      } else if (loadFromFirstId) {
        params.lt_id = loadFromFirstId
        params.orderBy = 'id.desc'
      } else {
        params.orderBy = 'id.desc'
      }

      axiosIns.get(`1/clientChats/${conversationId}/messages`, { params })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [CREATE_CHAT_CONVERSATION_MESSAGE]: ({ commit }, { conversationId, message, files }) => new Promise((resolve, reject) => {
      axiosIns.post(`1/chats/conversations/${conversationId}/messages`, { message, files })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    CREATE_CLIENT_CHAT_CONVERSATION_MESSAGE: ({ commit }, {
      // eslint-disable-next-line no-unused-vars
      conversationId, message, files, user = null, callback = null,
      // eslint-disable-next-line no-unused-vars,no-async-promise-executor
    }) => new Promise(async (resolve, reject) => {
      const payload = {
        message,
      }

      if (Array.isArray(files) && files.length) {
        payload.files = files.map(f => {
          if (f?.token) return f.token

          return f
        })

        delete payload.message
      }

      if (user?.id) {
        payload.senderUser = user.id
      }

      let ws = ClientWebsocketService.getInstance('CHAT')
      let a = {}
      if (typeof ws?.fetch === 'function') {
        a = await ws.fetch('sw-client-chat:send', { ...payload, id: conversationId })
      } else {
        ClientWebsocketService.instances.delete('CHAT')
        ws = new ClientWebsocketService('CHAT')
        ws.connect({
          url: `wss://r.saleswizardapp.com/socketer/ws/user/${!window.location.href.includes('https') ? 'd1.saleswizardapp.com' : window.location.host}`,
          token: localStorage.getItem('token'),
          useInitMessageAsHeaders: true,
          autoReconnect: 500,
        })

        if (callback) {
          callback(conversationId)
        }

        a = await ws.fetch('sw-client-chat:send', { ...payload, id: conversationId })
      }

      resolve(a)

      // axiosIns.post(`1/clientChats/${conversationId}/messages`, payload)
      //   .then(res => {
      //     resolve(res.data)
      //   })
      //   .catch(err => {
      //     reject(err)
      //   })
    }),
    // eslint-disable-next-line no-unused-vars
    [CREATE_CHAT_CONVERSATION]: ({ commit }, { users }) => new Promise((resolve, reject) => {
      axiosIns.post('1/chats/conversations', { users })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
  },
}
