<template>
  <div>
    <div
      v-if="!loading"
      class="pt-1"
    >
      <b-form-group
        :label="$t('EditTimeLimit')"
        style="max-width: 650px; width: 100%"
      >
        <div class="mb-2 d-flex flex-row align-items-center">
          <b-button :variant="system.contactThreadTaskDeleteTime === 0 ? 'primary' : 'outline-primary'"
                    @click="system.contactThreadTaskDeleteTime = 0"
          >
            {{ $t('Never') }}
          </b-button>
          <b-input-group class="input-group-merge form-send-message mx-50">
            <b-input-group-prepend is-text>
              <span class="char-count">{{ $t('To') }}</span>
            </b-input-group-prepend>

            <b-form-input
              id="EditTimeLimit"
              :value="system.contactThreadTaskDeleteTime > 0 ? system.contactThreadTaskDeleteTime : ''"
              type="number"
              min="0"
              @input="e => system.contactThreadTaskDeleteTime = e"
            />

            <b-input-group-append is-text>
              <span class="char-count">min</span>
            </b-input-group-append>
          </b-input-group>

          <b-button :variant="system.contactThreadTaskDeleteTime === -1 ? 'primary' : 'outline-primary'"
                    @click="system.contactThreadTaskDeleteTime = -1"
          >
            {{ $t('Always') }}
          </b-button>
        </div>
      </b-form-group>

      <b-form
        ref="form"
        class="repeater-form"
      >
        <!-- Row Loop -->
        <draggable v-model="tasks">
          <b-row
            v-for="(task, index) in tasks"
            :id="task.id"
            :key="task.id"
            ref="row"
            style="row-gap: .5rem"
            class="cursor-pointer"
          >
            <!-- Item Name -->
            <b-col
              v-b-toggle="`col_${index}`"
              lg="6"
              class="d-flex align-items-center"
              style="column-gap: .5rem"
            >
              <feather-icon
                icon="MoveIcon"
                class="text-primary mr-75"
                size="18"
              />
              <b-button
                :id="`task_${index}`"
                variant="primary"
                size="sm"
                class="btn-icon"
              >
                <feather-icon icon="InfoIcon" />
              </b-button>

              <b-popover
                custom-class="popover-tasks"
                :target="`task_${index}`"
                triggers="hover"
                placement="right"
              >
                <b-form-checkbox
                  v-model="tasks[index].isContentRequired"
                  class="ml-1 mt-50"
                  disabled
                  variant="primary"
                >
                  {{ $t('AddTextFieldToTask') }}
                </b-form-checkbox>

                <b-form-checkbox
                  v-model="tasks[index].isAddressRequired"
                  class="ml-1 mt-50"
                  disabled
                  variant="primary"
                >
                  {{ $t('AddGoogleAddress') }}
                </b-form-checkbox>

                <b-form-checkbox
                  v-model="tasks[index].isReportRequired"
                  class="ml-1 mt-50"
                  disabled
                  variant="primary"
                >
                  {{ $t('RequestTaskReports') }}
                </b-form-checkbox>

                <b-form-checkbox
                  v-model="tasks[index].isReminderActive"
                  disabled
                  class="ml-1 mt-50"
                  variant="primary"
                >
                  {{ $t('SendTaskReminder') }}
                </b-form-checkbox>
              </b-popover>

              <p class="mb-0">
                {{ task.name }}
              </p>
            </b-col>

            <!-- Remove Button -->
            <b-col
              lg="6"
              class="d-flex"
              style="column-gap: .5rem"
            >
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-warning"
                size="sm"
                @click="editField(task, index)"
              >
                <feather-icon
                  icon="EditIcon"
                  class="mr-25"
                />

                <span>
                  {{ $t('Edit') }}
                </span>
              </b-button>

              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                size="sm"
                @click="removeItem(task.id, index)"
              >
                <feather-icon
                  icon="XIcon"
                  class="mr-25"
                />

                <span>
                  {{ deleteTasks.includes(task.id ? task.id.toString() : task.id) ? $t('Cancel') : $t('Delete') }}
                </span>
              </b-button>
            </b-col>

            <b-col cols="12">
              <hr>
            </b-col>
          </b-row>
        </draggable>
      </b-form>

      <b-button
        variant="primary"
        class="mr-1"
        size="sm"
        @click="onSaveForm"
      >
        <span>{{ $t('Save') }}</span>
      </b-button>

      <b-button
        variant="outline-primary"
        size="sm"
        :disabled="getLastId() + 1 < 1001"
        @click="addField"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-25"
        />
        <span>{{ $t('Add') }}</span>
      </b-button>

      <b-overlay
        no-wrap
        :show="loading"
        spinner-variant="primary"
      />

      <!-- edited modal -->
      <b-modal
        v-model="isFieldEdited"
        hide-footer
        no-close-on-backdrop
      >
        <!-- Edited name  -->
        <b-form-group
          :label="$t('Name')"
          label-for="task_name"
        >
          <b-form-input
            id="task_name"
            v-model="editedField.name"
            type="text"
          />
        </b-form-group>

        <!-- -->
        <b-form-checkbox
          v-model="editedField.isContentRequired"
          class="mt-1"
          variant="primary"
        >
          {{ $t('AddTextField') }}
        </b-form-checkbox>

        <b-form-checkbox
          v-model="editedField.isAddressRequired"
          class="mt-1"
          variant="primary"
        >
          {{ $t('AddGoogleAddress') }}
        </b-form-checkbox>

        <b-form-checkbox
          v-model="editedField.isReportRequired"
          class="mt-1"
          variant="primary"
        >
          {{ $t('RequestTaskReports') }}
        </b-form-checkbox>

        <b-form-checkbox
          v-model="editedField.isReminderActive"
          class="mt-1"
          variant="primary"
        >
          {{ $t('SendTaskReminder') }}
        </b-form-checkbox>

        <!-- SendTaskReminder -->
        <b-button
          variant="success"
          class="mt-2"
          :disabled="getLastId() + 1 < 1001"
          @click="saveEditedField"
        >
          {{ $t('Confirm') }}
        </b-button>
      </b-modal>
      <!-- edited modal -->
    </div>
    <div
      v-else
      class="d-flex justify-content-center"
    >
      <b-spinner
        variant="primary"
        class="m-5"
      />
    </div>
  </div>
</template>

<script>
import {
  BForm, BFormGroup, BInputGroupAppend, BModal, BPopover, VBPopover, VBToggle,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'
import {
  DELETE_TASKS, GET_TASKS, SAVE_SETTINGS, SAVE_TASKS,
} from '@/@constants/mutations'
import draggable from 'vuedraggable'

export default {
  name: 'Tasks',
  components: {
    BForm,
    BFormGroup,
    BModal,
    BPopover,
    BInputGroupAppend,
    draggable,
  },
  directives: {
    'b-popover': VBPopover,
    'b-toggle': VBToggle,
    Ripple,
  },
  mixins: [heightTransition],
  data: () => ({
    loading: false,
    areChangesSaved: true,
    isFieldEdited: false,
    editedField: {
      id: null,
      name: '',
      isContentRequired: false,
      isReportRequired: false,
      isAddressRequired: false,
      isReminderActive: false,
    },
    editedFieldIndex: 0,
    tasks: [],
  }),
  computed: {
    ...mapGetters({
      deleteTasks: 'typeSettings/getDeleteTasks',
      system: 'system/getSettings',
    }),
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.loading = true
      Promise.all([
        this.$store.dispatch(`typeSettings/${GET_TASKS}`, { gte_id: '1001' }),
      ]).then(res => {
        // eslint-disable-next-line prefer-destructuring
        this.tasks = res[0]
      }).catch(err => {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }).finally(() => {
        this.loading = false
      })
    },
    getReminderTime(value) {
      return this.reminderTimes.find(r => r.value === value)?.label
    },
    removeItem(id, index) {
      if (id) this.$store.commit('typeSettings/ADD_DELETE_TASKS', id)
      else this.tasks.splice(index, 1)
      this.areChangesSaved = false
    },
    editField(field, index) {
      this.isFieldEdited = true
      this.editedField = { ...field }
      this.editedFieldIndex = index
      this.areChangesSaved = false
    },
    addField() {
      this.isFieldEdited = true
      this.editedField = {
        id: null,
        name: '',
        isContentRequired: false,
        isReportRequired: false,
        isAddressRequired: false,
        isReminderActive: false,
      }
      this.editedFieldIndex = -1
      this.areChangesSaved = false
    },
    saveEditedField() {
      const item = this.editedField
      if (this.editedFieldIndex !== -1) {
        this.tasks[this.editedFieldIndex] = item
      } else {
        this.tasks.push(item)
      }
      this.isFieldEdited = false
      this.areChangesSaved = false
      this.resetField()
    },
    saveSettings() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(`system/${SAVE_SETTINGS}`, { savePayload: { contactThreadTaskDeleteTime: this.system.contactThreadTaskDeleteTime } })
          .then(() => {
            resolve()
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            reject(err)
          })
      })
    },
    onSaveForm() {
      this.loading = true
      this.areChangesSaved = true
      const items = []
      this.tasks.forEach((task, index) => {
        const {
          // eslint-disable-next-line prefer-const
          id, name, isContentRequired, isReportRequired, isAddressRequired,
          isReminderActive,
        } = task

        if (!this.deleteTasks.includes(task.id ? task.id.toString() : task.id)) {
          if (id && name) {
            items.push({
              position: index,
              icon: 'ListIcon',
              id: id.toString(),
              name,
              isContentRequired,
              isReportRequired,
              isAddressRequired,
              isReminderActive,
            })
          } else if (name) {
            items.push({
              position: index,
              icon: 'ListIcon',
              name,
              isContentRequired,
              isReportRequired,
              isAddressRequired,
              isReminderActive,
            })
          }
        }
      })

      if (this.deleteTasks.length) {
        this.$store.dispatch(`typeSettings/${DELETE_TASKS}`, this.deleteTasks)
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          })
      }

      Promise.all([
        this.$store.dispatch(`typeSettings/${SAVE_TASKS}`, items),
        this.saveSettings(),
      ])
        .then(() => {
          this.loadData()
          this.showToast('success', this.$i18n.t('success.contactUpdated'))
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
        .finally(() => {
          this.loading = false
        })
    },
    resetField() {
      this.editedField = {
        id: null, name: '', isContentRequired: false, isReportRequired: false, isAddressRequired: false,
      }
      this.editedFieldIndex = 0
    },
    getLastId() { return this.tasks.lenght && this.tasks.reduce((max, task) => (task.id > max.id ? task : max), this.tasks[0]).id },
  },

}
</script>

<style lang="scss">
  .popover-tasks {
    max-width: unset !important;
    & .popover-body {
      width: 400px;
    }
  }
</style>
