var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h4',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$t('BasicInformation'))+" ")]),_c('b-row',{staticStyle:{"row-gap":"1rem"}},[_c('b-col',{attrs:{"md":"6","lg":"4"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"mb-50"},[_vm._v(_vm._s(_vm.$t('Name')))]),_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"max-length":"34"},model:{value:(_vm.target.name),callback:function ($$v) {_vm.$set(_vm.target, "name", $$v)},expression:"target.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"6","lg":"4"}},[_c('validation-provider',{attrs:{"name":_vm.$t('QualifiedStatus'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('sw-select',{staticClass:"mb-0",attrs:{"name":_vm.$t('QualifiedStatus'),"state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"options":_vm.qualifiedStatusItems,"label":"label","reduce":function (i) { return i.value; }},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('NoOptions'))+" ")]},proxy:true},{key:"selected-option",fn:function(ref){
var label = ref.label;
return [_c('span',[_vm._v(_vm._s(_vm.$tc(label, 2)))])]}},{key:"option",fn:function(ref){
var label = ref.label;
return [_c('span',[_vm._v(_vm._s(_vm.$tc(label, 2)))])]}}],null,true),model:{value:(_vm.target.qualifiedStatus),callback:function ($$v) {_vm.$set(_vm.target, "qualifiedStatus", $$v)},expression:"target.qualifiedStatus"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"6","lg":"4"}},[_c('validation-provider',{attrs:{"name":_vm.$t('QualificationStatusDate'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('sw-select',{staticClass:"mb-0",attrs:{"name":_vm.$t('QualificationStatusDate'),"state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"value":'CalendarMonth',"options":['CalendarMonth']},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('NoOptions'))+" ")]},proxy:true},{key:"selected-option",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t('CalendarMonth')))])]},proxy:true},{key:"option",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t('CalendarMonth')))])]},proxy:true}],null,true)})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('SpecifyStatusQualificationEndDate'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('sw-select',{staticClass:"mb-0",attrs:{"name":_vm.$t('SpecifyStatusQualificationEndDate'),"state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"options":[-1 ].concat( _vm.qualifiedStatusEndDateItems)},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('NoOptions'))+" ")]},proxy:true},{key:"selected-option",fn:function(ref){
var label = ref.label;
return [(label !== -1)?_c('span',[_vm._v(_vm._s(label)+" "+_vm._s(_vm.$t("DayOfEachMonth")))]):_c('span',[_vm._v(_vm._s(_vm.$t('LastDayOfMonth')))])]}},{key:"option",fn:function(ref){
var label = ref.label;
return [(label !== -1)?_c('span',[_vm._v(_vm._s(label)+" "+_vm._s(_vm.$t("DayOfEachMonth")))]):_c('span',[_vm._v(_vm._s(_vm.$t('LastDayOfMonth')))])]}}],null,true),model:{value:(_vm.target.qualifiedStatusEndDaysAmount),callback:function ($$v) {_vm.$set(_vm.target, "qualifiedStatusEndDaysAmount", $$v)},expression:"target.qualifiedStatusEndDaysAmount"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('DateOfConclusionOfContract'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('sw-select',{staticClass:"mb-0",attrs:{"name":_vm.$t('DateOfConclusionOfContract'),"state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"options":[-1]},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('NoOptions'))+" ")]},proxy:true},{key:"selected-option",fn:function(ref){
var label = ref.label;
return [(label !== -1)?_c('span',[_vm._v(_vm._s(_vm.$t('From'))+" "+_vm._s(label)+" "+_vm._s(_vm.$t("DayOfMonth")))]):_c('span',[_vm._v(_vm._s(_vm.$t('CalendarMonth')))])]}},{key:"option",fn:function(ref){
var label = ref.label;
return [(label !== -1)?_c('span',[_vm._v(_vm._s(_vm.$t('From'))+" "+_vm._s(label)+" "+_vm._s(_vm.$t("DayOfMonth")))]):_c('span',[_vm._v(_vm._s(_vm.$t('CalendarMonth')))])]}}],null,true),model:{value:(_vm.target.contractDaysAmount),callback:function ($$v) {_vm.$set(_vm.target, "contractDaysAmount", $$v)},expression:"target.contractDaysAmount"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-overlay',{attrs:{"show":_vm.isLoading,"no-wrap":"","spinner-variant":"primary"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }