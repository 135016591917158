<template>
  <div
    class="d-flex flex-wrap"
    style="column-gap: .34rem; row-gap: .34rem;"
  >
    <b-button
      v-for="(activity, index) in activities"
      :key="`activity_${index}`"
      :variant="activity.isActive ? 'underline-primary' : 'flat-primary'"
      size="sm"
      class="px-lg-50 py-lg-25 d-flex align-items-center"
      style="column-gap: .34rem"
      @click="$emit('change-activitie-status', activity.key, !activity.isActive)"
    >
      <span>
        {{ $tc(`${activity.title}`) }}
      </span>

      <span>
        ({{ getState(activity.key) || 0 }})
      </span>
    </b-button>
  </div>
</template>

<script>

export default {
  name: 'TimelineActivities',
  components: {
  },
  props: {
    activities: {
      type: Array,
      default: () => [],
    },
    stats: {
      type: Object,
      required: false,
      default: null,
    },
  },

  methods: {
    getState(key) {
      if (!this.stats) return null

      if (key === 'all') return Object.values(this.stats).reduce((a, c) => a + c)

      const state = this.stats[key]

      if (!state) return null

      return state
    },
  },
}
</script>

<style>
.btn-underline-primary {
  position: relative;

  background: rgba(var(--theme-color-primary), .14);

  color: rgba(var(--theme-color-primary), 1);
}

.btn-underline-primary:hover { color: rgba(var(--theme-color-primary), 1); }
</style>
