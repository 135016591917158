<!--eslint-disable-->

<template>
  <validation-observer
    v-if="product"
    ref="edit-product-modal"
  >
    <b-row>
      <!--     Code     -->
      <b-col sm="12">
        <validation-provider
          v-slot="{ errors }"
          :name="$t('offer.product.code')"
          rules="required"
        >
          <b-form-group :label="$t('offer.product.code')">
            <b-form-input
              v-model.trim="product.overwriteCode"
              :placeholder="$t('offer.product.code')"
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col sm="12">
        <b-form-group :label="$t('offer.product.priceNet')">
          <validation-provider
            v-slot="{ errors }"
            :name="$t('contact.phone')"
            rules="required"
          >
            <cleave
              v-model="product.overwriteOfferPrice"
              :options="cleaveOptions.number"
              class="form-control"
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <!-- Attributes -->
      <b-table
        :items="attributes"
        :fields="constAttributeFields"
        striped
        responsive
        hover
        class="constTable"
        :tbody-tr-class="hideNotBase"
      >
        <!--    Table Head    -->
        <template #head()="item">
          <span class="text-nowrap">
            {{ $tc(item.field.label) | truncate(34, '...') }}
          </span>
        </template>

        <!-- Table position -->
        <template #cell(position)="{ value, index }">
          <div class="d-flex align-items-center">
            <b-button-group class="btn-group-vertical mr-25">
              <b-button
                variant="flat-primary"
                size="sm"
                class="btn-icon p-md-0"
                :disabled="isEdited || value === 0"
                @click="moveAttr(index, true)"
              >
                <feather-icon icon="ArrowUpIcon" />
              </b-button>

              <b-button
                variant="flat-primary"
                size="sm"
                class="btn-icon p-0"
                :disabled="isEdited || value === attributes.length -1"
                @click="moveAttr(index, false)"
              >
                <feather-icon icon="ArrowDownIcon" />
              </b-button>
            </b-button-group>

            {{ value }}
          </div>
        </template>

        <!-- Table Name -->
        <template #cell(name)="{ item }">
          {{ item.shopAttribute.translations[0].name }}
        </template>

        <!-- Table Value -->
        <template #cell(value)="{ item, index }">
          <div v-if="['TEXT_ARRAY', 'TEXT_MULTIPLE_ARRAY'].includes(item.shopAttribute.type)">
            <div v-if="Array.isArray(item.valueShopAttributeOptions)">
              <b-badge
                v-for="(value, itemIndex) in item.valueShopAttributeOptions"
                :key="`${index}_attributeItem_${itemIndex}_${value}`"
                variant="primary"
                class="ml-25"
              >
                {{ value.translations[0].name }}
              </b-badge>
            </div>
            <div v-else>
              <b-badge
                variant="primary"
                class="ml-25"
              >
                {{ item.valueShopAttributeOptions.translations[0].name }}
              </b-badge>
            </div>
          </div>
          <div v-else>
            {{ item.value }}
          </div>
        </template>

        <!--    Table Type    -->
        <template #cell(type)="{ item }">
          {{ $t(`offer.attribute.types.${item.shopAttribute.type}`) }}
        </template>

        <!--    Table Website    -->
        <template #cell(visibleOfferWWW)="{ value }">
          <span class="d-flex">
            <b-badge
              v-if="value"
              v-b-tooltip.hover.v-success
              variant="light-success"
              pill
              :title="$t('Active')"
            >
              <feather-icon icon="CheckIcon" />
            </b-badge>
            <b-badge
              v-else
              v-b-tooltip.hover.v-danger
              variant="light-danger"
              pill
              :title="$t('InActive')"
            >
              <feather-icon icon="MinusIcon" />
            </b-badge>
          </span>
        </template>

        <!--    Table Status    -->
        <template #cell(active)="{ value }">
          <span class="d-flex">
            <b-badge
              v-if="value"
              v-b-tooltip.hover.v-success
              variant="light-success"
              pill
              :title="$t('Active')"
            >
              <feather-icon icon="CheckIcon" />
            </b-badge>
            <b-badge
              v-else
              v-b-tooltip.hover.v-danger
              variant="light-danger"
              pill
              :title="$t('InActive')"
            >
              <feather-icon icon="MinusIcon" />
            </b-badge>
          </span>
        </template>

        <!--    Table Action    -->
        <template #cell(action)="{ item, index }">
          <b-dropdown
            variant="link"
            class="dropdown-icon-wrapper text-decoration-none"
            no-caret
            :disabled="isEdited"
          >
            <template
              #button-content
              class="sr-only"
            >
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle"
              />
            </template>

            <!--    Action: Edit    -->
            <b-dropdown-item @click="editAttribute(item, index)">
              {{ $t('Edit') }}
            </b-dropdown-item>

            <!--    Action: Delete    -->
            <b-dropdown-item @click="remove(index)">
              {{ $t('Delete') }}
            </b-dropdown-item>
          </b-dropdown>
        </template>

        <!--    Other    -->
          <!--    Other    -->
          <template #cell()="row">
              <table-default-cell
                      :field="row.field"
                      :value="row.value"
              />
          </template>

          <!--      Empty      -->
          <template #empty>
              <empty-content />
          </template>

          <!--      Table Busy      -->
          <template #table-busy>
              <div class="text-center text-danger my-2">
                  <b-spinner
                          class="align-middle"
                          variant="primary"
                  />
              </div>
          </template>
      </b-table>
    </b-row>
  </validation-observer>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Cleave from 'vue-cleave-component'
import { BButtonGroup, BTable } from 'bootstrap-vue'
import Vue from 'vue'

export default {
  name: 'SectionCheckoutEditProduct',
  components: {
    BButtonGroup,
    BTable,
    ValidationProvider,
    ValidationObserver,
    Cleave,
  },
  props: {
    product: {
      default: null,
      required: true,
    },
  },
  data: () => ({
    productItem: {
      overwriteCode: '',
      overwriteContactThreadCartDraftProductAttributes: [],
      overwriteOfferPrice: 0,
    },
    cleaveOptions: {
      number: {
        delimiter: ' ',
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
      },
    },
  }),
  methods: {
    moveAttr(index, direction) {
      if (direction && this.productItem.overwriteContactThreadCartDraftProductAttributes[index].position > 0) {
        this.productItem.overwriteContactThreadCartDraftProductAttributes[index].position -= 1
        this.productItem.overwriteContactThreadCartDraftProductAttributes[index - 1].position += 1

        const oldAttr = this.productItem.overwriteContactThreadCartDraftProductAttributes[index]

        Vue.set(this.productItem.overwriteContactThreadCartDraftProductAttributes, index, this.productItem.overwriteContactThreadCartDraftProductAttributes[index - 1])
        Vue.set(this.productItem.overwriteContactThreadCartDraftProductAttributes, index - 1, oldAttr)
      } else if (this.productItem.overwriteContactThreadCartDraftProductAttributes[index].position < this.productItem.overwriteContactThreadCartDraftProductAttributes.length - 1) {
        this.productItem.overwriteContactThreadCartDraftProductAttributes[index].position += 1
        this.productItem.overwriteContactThreadCartDraftProductAttributes[index + 1].position -= 1

        const oldAttr = this.productItem.overwriteContactThreadCartDraftProductAttributes[index]

        Vue.set(this.productItem.overwriteContactThreadCartDraftProductAttributes, index, this.productItem.overwriteContactThreadCartDraftProductAttributes[index + 1])
        Vue.set(this.productItem.overwriteContactThreadCartDraftProductAttributes, index + 1, oldAttr)
      }
    },
    async save() {
      const isValid = await this.$refs['edit-product-modal'].validate()
      if (!isValid) return

      this.$emit('save', this.product)
    },
  },
}
</script>

<style scoped>

</style>
