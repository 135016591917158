<template>
  <div>
    <!--    Buttons    -->
    <div
      class="mb-1 d-flex flex-wrap"
      style="gap: .34rem"
    >
      <!--    Button: Cancel    -->
      <b-button
        variant="warning"
        size="sm"
        @click="$router.go(-1)"
      >
        <feather-icon icon="ArrowLeftIcon" />

        {{ $t('Return') }}
      </b-button>
    </div>

    <b-card>
      <!--     Form     -->
      <b-form>
        <b-row>
          <b-col sm="12"
                 lg="6"
          >
            <b-form-group :label="$t('Name')">
              <b-form-input v-model="shortcut.name" />
            </b-form-group>
          </b-col>
          <b-col sm="12"
                 lg="6"
          >
            <b-form-group :label="$t('Icon')">
              <b-button
                variant="gradient-primary"
                size="sm"
                @click="isSidebarOpen = true"
              >
                <feather-icon
                  :key="`status_icon`"
                  :icon="shortcut.icon"
                  size="20"
                />
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-card>

    <b-card :title="$t('Sections')">
      <b-row>
        <b-col v-for="(section, index) in shortcut.shortcutSections"
               :key="`section_${index}`"
               sm="12"
               lg="6"
        >
          <b-card class="border rounded"
                  header-class="m-0 p-0"
          >

            <template #header>
              <div class="d-flex justify-content-between align-items-center w-100 m-25 p-25 ml-50">
                <div class="mb-0 pb-0 mt-25">
                  <h4 class="mb-0">
                    {{ section.name }}
                  </h4>
                  <small class="text-muted">{{ $t(sections.find(s => s.key === section.type).label) }}</small>
                </div>
                <div>
                  <b-button
                    class="mr-25 mt-25 btn-icon"
                    variant="flat-warning"
                    @click="sectionModal = { editedItem: JSON.parse(JSON.stringify(section)), show: true, editedIndex: index }"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>

                  <b-button
                    class="mt-25 btn-icon"
                    variant="flat-danger"
                    @click="deleteSection(section, index)"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </div>
              </div>
            </template>

            <div v-if="sections.find(s => s.key === section.type) && sections.find(s => s.key === section.type).request"
                 class="d-flex flex-wrap"
            >
              <b-badge v-for="(sectionField, sectionFieldindex) in section.fields"
                       :key="`section_fields_${index}_${sectionFieldindex}`"
                       variant="primary"
                       class="m-25 flex-grow-1"
              >
                <span v-if="findOption(section, sectionField)"
                      :key="`${(options[section.type] || []).find(e => e.id === sectionField)}_${sectionFieldindex}_${index}`"
                      v-b-tooltip
                      :title="(options[section.type] || []).find(e => e.id === sectionField)[sections.find(s => s.key === section.type).request.label]"
                >{{ (options[section.type] || []).find(e => e.id === sectionField)[sections.find(s => s.key === section.type).request.label] | truncate(30) }}</span>

              </b-badge>
            </div>
            <div v-else
                 class="d-flex flex-wrap"
            >
              <b-badge v-for="(sectionField, sectionFieldindex) in section.fields"
                       :key="`section_fields_${index}_${sectionFieldindex}`"
                       variant="primary"
                       class="m-25 flex-grow-1"
              >
                <span v-if="shortcutTypeFieldByValueAndType(sectionField, section.type) && shortcutTypeFieldByValueAndType(sectionField, section.type).key && shortcutTypeFieldByValueAndType(sectionField, section.type).key.includes('IDENTITY_DOCUMENT')">{{ $t('PersonIdentityDocument') }}: </span>{{ shortcutTypeFieldByValueAndType(sectionField, section.type) ? $t(shortcutTypeFieldByValueAndType(sectionField, section.type).label) : '-' }}
              </b-badge>
            </div>

          </b-card>
        </b-col>
        <b-col sm="12"
               md="6"
               lg="4"
               @click="$set(sectionModal, 'show', true)"
        >
          <b-alert
            variant="primary"
            show
            class="d-flex align-items-center justify-content-center h-100 cursor-pointer"
          >
            <span>
              {{ $t('Add') }}
            </span>

            <sw-icon
              icon="PlusIcon"
              size="25"
            />
          </b-alert>
        </b-col>
      </b-row>
    </b-card>

    <!--     Save Button     -->
    <b-button
      variant="primary"
      :disabled="!shortcut.name || loading"
      @click="saveContract"
    >
      {{ $t('Save') }}
    </b-button>

    <!-- sidebar -->
    <b-sidebar
      v-model="isSidebarOpen"
      class="icons-sidebar"
      shadow
      no-header
      :right="true"
    >
      <template #default="{ hide }">
        <div class="p-2 d-flex justify-content-between align-items-center bg-light">
          <div>
            <h4 class="text-capitalize mb-0">
              {{ $t('ChangeIcon') }}
            </h4>
          </div>

          <feather-icon
            icon="XIcon"
            size="18"
            class="cursor-pointer"
            @click="hide"
          />
        </div>

        <vue-perfect-scrollbar
          class="perfect-scroll"
          :settings="{ maxScrollbarLength: 60 }"
        >
          <div
            v-if="shortcut"
            class="sidebar-content"
          >
            <b-row>
              <b-col
                cols="12"
                sm="12"
              >
                <b-card class="text-center">
                  <div class="pb-25">
                    {{ $t('CurrentIcon') }}
                  </div>
                  <feather-icon
                    class="text-primary"
                    size="30"
                    :icon="shortcut.icon"
                  />
                </b-card>
              </b-col>

              <b-col
                v-for="(icon, index) in iconsList"
                :key="`icon_${index}`"
                cols="12"
                md="4"
                sm="6"
                @click="shortcut.icon = icon"
              >
                <b-card class="text-center">
                  <feather-icon
                    :icon="icon"
                    size="25"
                  />
                </b-card>
              </b-col>
            </b-row>
          </div>
        </vue-perfect-scrollbar>
      </template>
    </b-sidebar>
    <!-- sidebar -->

    <!-- Edit / Add Section Modal -->
    <b-modal v-model="sectionModal.show"
             no-close-on-backdrop
             :title="sectionModal.editedIndex > -1 ? $t('Edit') : $t('Add')"
             size="md"
             hide-footer
             @hide="clearModal"
    >
      <b-form-group :label="$t('Name')">
        <b-form-input v-model="sectionModal.editedItem.name" />
      </b-form-group>
      <sw-select :name="$t('task.Type')">
        <v-select
          v-model="sectionModal.editedItem.type"
          :options="sections"
          :reduce="i => i.key"
          :selectable="allowSelectSection"
          @option:selected="selectType"
        >
          <template #option="{ label }">
            {{ $t(label) }}
          </template>
          <template #selected-option="{ label }">
            {{ $t(label) }}
          </template>
          <template #no-options>
            {{ $t('NoOptions') }}
          </template>
        </v-select>
      </sw-select>

      <transition name="fade">
        <sw-select v-if="getAvailableTypeFields.length"
                   :name="$t('Fields')"
        >
          <v-select
            v-model="sectionModal.editedItem.fields"
            :options="getAvailableTypeFields"
            multiple
            :close-on-select="false"
            :reduce="i => i.key"
          >
            <template #option="{ label, key }">
              <span><span v-if="key && key.includes('IDENTITY_DOCUMENT')">{{ $t('PersonIdentityDocument') }}: </span>{{ $t(label) }}
              </span>
            </template>
            <template #selected-option="{ label, key }">
              <span><span v-if="key && key.includes('IDENTITY_DOCUMENT')">{{ $t('PersonIdentityDocument') }}: </span>{{ $t(label) }}
              </span>
            </template>
            <template #no-options>
              {{ $t('NoOptions') }}
            </template>
          </v-select>
        </sw-select>

        <sw-select v-if="shortcutTypeByValue(sectionModal.editedItem.type) && shortcutTypeByValue(sectionModal.editedItem.type).request && shortcutTypeByValue(sectionModal.editedItem.type).request.label"
                   :name="$t('Fields')"
        >
          <v-select
            v-model="sectionModal.editedItem.fields"
            :options="options[sectionModal.editedItem.type]"
            multiple
            :close-on-select="false"
            :reduce="i => i[shortcutTypeByValue(sectionModal.editedItem.type).request.key]"
          >
            <template #option="item">
              <span v-if="shortcutTypeByValue(sectionModal.editedItem.type)">{{ item[shortcutTypeByValue(sectionModal.editedItem.type).request.label] }}</span>
            </template>
            <template #selected-option="item">
              <span v-if="shortcutTypeByValue(sectionModal.editedItem.type)">{{ item[shortcutTypeByValue(sectionModal.editedItem.type).request.label] }}</span>
            </template>
            <template #no-options>
              {{ $t('NoOptions') }}
            </template>
          </v-select>
        </sw-select>
      </transition>

      <div v-if="sectionModal.editedItem.type === 'THREAD_FORM'">
        <sw-select :name="$t('contact.contactThreadFunnelTypePoint')">
          <v-select
            :value="sectionModal.editedItem.fieldsConfig && sectionModal.editedItem.fieldsConfig.defaultThreadPoint ? sectionModal.editedItem.fieldsConfig.defaultThreadPoint : ''"
            :reduce="nType => nType.id.toString()"
            item-value="id"
            item-text="name"
            label="name"
            :options="contactThreadFunnelTypesPoints"
            @option:selected="selectConfigOption($event, 'defaultThreadPoint')"
          >
            <template #no-options>
              {{ $t('NoOptions') }}
            </template>
          </v-select>
        </sw-select>
      </div>

      <div v-if="sectionModal.editedItem.type === 'GENERATE_NOTE'">
        <sw-select
          :name="$t('note.Type')"
        >
          <v-select
            :value="sectionModal.editedItem.fieldsConfig && sectionModal.editedItem.fieldsConfig.defaultNote ? sectionModal.editedItem.fieldsConfig.defaultNote : ''"
            :options="noteTypes"
            :reduce="nType => nType.id.toString()"
            item-value="id"
            item-text="name"
            label="name"
            :clearable="false"

            @option:selected="selectConfigOption($event, 'defaultNote')"
          >
            <template #no-options>
              {{ $t('NoOptions') }}
            </template>
            <template #option="{ name, icon }">
              <feather-icon
                :icon="icon"
                size="16"
                class="align-middle mr-50"
              />
              <span>{{ name }}</span>
            </template>
            <template #selected-option="{ name, icon }">
              <feather-icon
                :icon="icon"
                size="16"
                class="align-middle mr-50"
              />
              <span>{{ name }}</span>
            </template>
          </v-select>
        </sw-select>
      </div>

      <b-button
        variant="primary"
        :disabled="!sectionModal.editedItem.type || !sectionModal.editedItem.name"
        @click="saveEditedSection"
      >
        {{ $t('Save') }}
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import { BForm, BSidebar, VBTooltip } from 'bootstrap-vue'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// import keysCuter from '@/helpers/keysCuter'
import { useWindowScroll } from '@vueuse/core'
import icons from '@helpers/icons'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import vSelect from 'vue-select'
import * as shortcutTypes from '@/@types/shortcutTypes'
import { shortcutTypeByValue, shortcutTypeFieldByValueAndType } from '@/@types/shortcutTypes'
import axiosIns from '@/libs/axios'
import keysCuter from '@/helpers/keysCuter'
import { GET_NOTES } from '@/@constants/mutations'

export default {
  name: 'ContactGeneratorDetails',
  components: {
    vSelect,
    BSidebar,
    BForm,
    VuePerfectScrollbar,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data: () => ({
    loading: false,
    isSidebarOpen: false,
    noteTypes: [],

    shortcut: {
      icon: 'CircleIcon',
      name: '',
      shortcutSections: [],
    },
    iconsList: [],
    contactThreadFunnelTypesPoints: [],

    sectionModal: {
      show: false,
      editedIndex: -1,
      editedItem: { type: '', fields: [], fieldsConfig: {} },
    },

    options: {
      CONTACT_ADDITIONAL_FIELDS: [],
    },

    sections: shortcutTypes.shortcutTypes,
  }),
  computed: {
    getAvailableTypeFields() {
      return shortcutTypes.shortcutTypeByValue(this.sectionModal.editedItem.type)?.fields || []
    },
  },
  watch: {
    '$route.params.contactId': {
      deep: true,
      handler() { this.getTemplate() },
    },
  },
  async mounted() {
    this.iconsList = icons()
    await Promise.all(
      this.loadNoteTypes(),
      this.getTemplate(),
      this.loadThreadStatuses(),
    )
    this.scrollPos = useWindowScroll()
  },
  methods: {
    async loadThreadStatuses() {
      try {
        const resp = await axiosIns.get('1/settings/contactThreadFunnelTypes', { params: { fields_load: this.$fields.SETTINGS_FUNNEL_TYPE, orderBy: 'position.asc' } })

        if (resp?.data?.data?.items) {
          this.contactThreadFunnelTypesPoints = (resp.data.data.items || []).flatMap(type => type.contactThreadFunnelTypePoints.map(point => ({ ...point, typeId: type.id, name: `${type.name} - ${point.name}` })))
          this.contactThreadFunnelTypes = (resp.data.data.items || [])

          // eslint-disable-next-line prefer-destructuring
          this.$nextTick(() => {
            this.$set(this, 'contactThreadFunnelTypesPoint', this.contactThreadFunnelTypesPoints[0])
            this.thread.name = this.contactThreadFunnelTypesPoints[0]?.name
          })
        }
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    selectConfigOption(value, field) {
      if (!this.sectionModal.editedItem.fieldsConfig) {
        this.$set(this.sectionModal.editedItem, 'fieldsConfig', {})
      }

      if (!this.sectionModal.editedItem.fieldsConfig[field] || Array.isArray(this.sectionModal.editedItem.fieldsConfig[field])) {
        this.$set(this.sectionModal.editedItem.fieldsConfig, field, {})
      }

      this.$set(this.sectionModal.editedItem.fieldsConfig, field, this.getObjectId(value))
    },
    async loadNoteTypes() {
      const noteTypes = await this.$store.dispatch(`typeSettings/${GET_NOTES}`)
      this.noteTypes = noteTypes
    },
    allowSelectSection(section) {
      if (this.shortcut.shortcutSections.find(s => s.type === section.key)) return false

      if (section.requiredTypes?.length) {
        let flag = true
        section.requiredTypes.forEach(requiredType => {
          if (!(this.shortcut.shortcutSections.find(s => s.type === requiredType) && flag)) {
            flag = false
          }
        })

        return flag
      }
      return true
    },
    shortcutTypeByValue,
    shortcutTypeFieldByValueAndType,
    findOption(section, sectionField) {
      return (this.options[section.type] || []).find(e => e.id === sectionField)
    },
    getTemplate() {
      this.sections.forEach(section => {
        if (section?.request) {
          this.selectType(section)
        }
      })

      setTimeout(() => {
        if (this.$route.params?.contactId && this.$route.params.contactId !== 'new') {
          this.loading = true
          this.$nextTick(async () => {
            const params = { fields_load: 'SHORTCUTS' }
            const res = await axiosIns.get(`1/shortcuts/${this.$route.params.contactId}`, { params })

            this.shortcut = res.data.data.item
            this.shortcut.shortcutSections = res.data.data.item.shortcutSections.map(e => ({ ...e, fields: e.fields.filter(a => !['IDENTITY_DOCUMENT'].includes(a)) }))
            this.loading = false
          })
        } else if (this.$route.params?.contractId === 'new') {
          this.$set(this, 'shortcut', {
            name: '',
            sections: [],
          })
        }
      }, 1000)
    },
    clearModal() {
      this.sectionModal = {
        show: false,
        editedIndex: -1,
        editedItem: {
          name: '', type: '', fields: [], fieldsConfig: {},
        },
      }
    },
    saveEditedSection() {
      if (this.sectionModal.editedIndex > -1) {
        this.$set(this.shortcut.shortcutSections[this.sectionModal.editedIndex], 'fieldsConfig', this.sectionModal.editedItem.fieldsConfig)
        this.$set(this.shortcut.shortcutSections[this.sectionModal.editedIndex], 'fields', this.sectionModal.editedItem.fields)
        this.$set(this.shortcut.shortcutSections[this.sectionModal.editedIndex], 'type', this.sectionModal.editedItem.type)
        this.$set(this.shortcut.shortcutSections[this.sectionModal.editedIndex], 'name', this.sectionModal.editedItem.name)
      } else {
        this.shortcut.shortcutSections.push({ ...this.sectionModal.editedItem })
      }

      this.clearModal()
    },
    async deleteSection(section, index, skipConfirm = false) {
      if (!skipConfirm) {
        const confirmed = await this.showAlert('warning', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('SectionsDependedOnDeletedWillBeDeletedToo'))
        if (!confirmed) return
      }

      const dependSections = this.sections.filter(s => s?.requiredTypes?.includes(section.type))
      if (dependSections) {
        this.shortcut.shortcutSections.forEach((s, i) => {
          if (dependSections.find(a => a.key === s.type)) {
            this.deleteSection(s, i, true)
          }
        })
      }

      try {
        if (section.id) {
          await axiosIns.delete(`1/shortcuts/${this.$route.params.contactId}/sections`, { data: JSON.stringify([section.id]) })
        }

        if (!skipConfirm) {
          this.showToast('success', this.$i18n.t('RemovedSuccessfully'))
        }
        this.$delete(this.shortcut.shortcutSections, index)
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    async selectType(section) {
      if (section?.request) {
        const response = await axiosIns({
          url: section.request.url,
          params: section.request.params,
          method: 'GET',
        })
        if (section.key === 'GENERATE_POLL') {
          this.options[section.key] = response.data.data.items.map(e => e.pollFields.sort(((a, b) => a.position - b.position)).map(field => {
            const fieldName = field.translations[0]?.name
            return { ...field, label: `${e.name} - ${fieldName}` }
          })).flatMap(e => e)
        } else {
          this.options[section.key] = response.data.data.items.map(e => ({ ...e, label: e[section.request.label] }))
        }
      }
    },
    async saveContract() {
      this.loading = true
      const payload = { ...this.shortcut }
      const sectionsPayload = payload.shortcutSections

      const newSections = sectionsPayload.filter(section => !section.id)
      const updateSections = sectionsPayload.filter(section => section.id)

      delete payload.shortcutSections

      let method = 'POST'

      if (this.$route.params?.contactId && this.$route.params?.contactId !== 'new') {
        method = 'PATCH'
        payload.id = this.$route.params.contactId
      }

      try {
        const resp = await axiosIns({
          url: '1/shortcuts',
          method,
          data: keysCuter(payload),
        })

        if (newSections.length) {
          await axiosIns.post(`1/shortcuts/${resp.data.data.items[0]?.id}/sections`, newSections.map(e => ({ ...e, shortcut: resp.data.data.items[0]?.id })))
        }

        if (updateSections.length) {
          await axiosIns.patch(`1/shortcuts/${resp.data.data.items[0]?.id}/sections`, updateSections.map(e => ({ ...e, shortcut: resp.data.data.items[0]?.id })))
        }

        this.$router.push({ name: 'contact-generator', params: { contactId: resp.data.data.items[0]?.id } })
        this.showToast('success', this.$i18n.t('ChangesSaved'))
        // this.showToast('success', this.$i18n.t(`${action === CREATE_CONTRACT_TEMPLATE ? 'ContractTemplateCreated' : 'ContractTemplateUpdated'}`))
        this.loading = false
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss">
</style>
