import * as ranks from '@/helpers/permissions'
import {
  SHOW_SYSTEM_MENU_AGREEMENTS, SHOW_SYSTEM_MENU_APPLICATIONS, SHOW_SYSTEM_MENU_CALENDAR,
  SHOW_SYSTEM_MENU_CHAT, SHOW_SYSTEM_MENU_CONTACTS, SHOW_SYSTEM_MENU_DASHBOARD,
  SHOW_SYSTEM_MENU_FUNNELS,
  SHOW_SYSTEM_MENU_LOYALTY_PLANS,
  SHOW_SYSTEM_MENU_MARKETING_PLANS,
  SHOW_SYSTEM_MENU_MATERIALS,
  SHOW_SYSTEM_MENU_NEW_CONTACT,
  SHOW_SYSTEM_MENU_OFFERS, SHOW_SYSTEM_MENU_ORGANIZATIONS,
  SHOW_SYSTEM_MENU_PAYMENTS,
  SHOW_SYSTEM_MENU_PROJECTS,
  SHOW_SYSTEM_MENU_SETTINGS,
  SHOW_SYSTEM_MENU_SHOP, SHOW_SYSTEM_MENU_TASKS,
  SHOW_SYSTEM_MENU_TIMELINE,
  SHOW_SYSTEM_MENU_ORDERS,
  SHOW_SYSTEM_MENU_USERS,
} from '@/helpers/viewPermissions'

export default [
  {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'HomeIcon',
    viewPermissions: [
      [SHOW_SYSTEM_MENU_DASHBOARD],
    ],
  },
  {
    title: 'News',
    icon: 'GridIcon',
    children: [
      {
        title: 'List',
        route: { name: 'newsList' },
        requiredPermissions: [
          [ranks.NEWS_POST_VIEW_ALL],
        ],
      },
      {
        title: 'Add',
        route: { name: 'newsNew' },
        requiredPermissions: [
          [ranks.NEWS_POST_MODIFY],
        ],
      },
    ],
    requiredPermissions: [
      [ranks.NEWS_POST_VIEW_ALL],
    ],
    requiredModule: ['newsModule'],
  },
  {
    title: 'Applications',
    route: 'applications',
    icon: 'ArchiveIcon',
    requiredPermissions: [
      [ranks.APPLICATION_VIEW_ALL, ranks.APPLICATION_VIEW_ASSIGNED_CONTACT_THREAD],
      [ranks.CONTACT_ADD],
      [ranks.CONTACT_THREAD_ADD],
    ],
    requiredModule: ['applicationModule'],
    viewPermissions: [
      [SHOW_SYSTEM_MENU_APPLICATIONS],
    ],
  },
  {
    title: 'Tasks',
    route: 'tasks',
    icon: 'ListIcon',
    requiredPermissions: [
      [ranks.CONTACT_VIEW_ALL, ranks.CONTACT_VIEW_ASSIGNED, ranks.CONTACT_VIEW_STRUCTURE, ranks.CONTACT_VIEW_STRUCTURE_FIRST],
      [ranks.CONTACT_THREAD_VIEW_ALL, ranks.CONTACT_THREAD_VIEW_ASSIGNED, ranks.CONTACT_THREAD_VIEW_STRUCTURE, ranks.CONTACT_THREAD_VIEW_STRUCTURE_FIRST],
      [ranks.CONTACT_THREAD_TASK_VIEW_ALL, ranks.CONTACT_THREAD_TASK_VIEW_ASSIGNED, ranks.CONTACT_THREAD_TASK_VIEW_STRUCTURE],
    ],
    requiredModule: [],
    viewPermissions: [
      [SHOW_SYSTEM_MENU_TASKS],
    ],
  },
  {
    title: 'Calendar',
    route: 'calendar',
    icon: 'CalendarIcon',
    requiredPermissions: [
      [ranks.CONTACT_VIEW_ALL, ranks.CONTACT_VIEW_ASSIGNED, ranks.CONTACT_VIEW_STRUCTURE, ranks.CONTACT_VIEW_STRUCTURE_FIRST],
      [ranks.CONTACT_THREAD_VIEW_ALL, ranks.CONTACT_THREAD_VIEW_ASSIGNED, ranks.CONTACT_THREAD_VIEW_STRUCTURE, ranks.CONTACT_THREAD_VIEW_STRUCTURE_FIRST],
      [ranks.CONTACT_THREAD_TASK_VIEW_ALL, ranks.CONTACT_THREAD_TASK_VIEW_ASSIGNED, ranks.CONTACT_THREAD_TASK_VIEW_STRUCTURE],
    ],
    requiredModule: [],
    viewPermissions: [
      [SHOW_SYSTEM_MENU_CALENDAR],
    ],
  },
  {
    title: 'Contacts',
    route: 'contacts',
    icon: 'UserCheckIcon',
    requiredPermissions: [[ranks.CONTACT_VIEW_ALL, ranks.CONTACT_VIEW_ASSIGNED, ranks.CONTACT_VIEW_STRUCTURE, ranks.CONTACT_VIEW_STRUCTURE_FIRST]],
    requiredModule: [],
    viewPermissions: [
      [SHOW_SYSTEM_MENU_CONTACTS],
    ],
  },
  {
    title: 'Organizations',
    route: 'organizations',
    icon: 'GlobeIcon',
    requiredPermissions: [[ranks.ORGANIZATION_VIEW_ALL]],
    viewPermissions: [
      [SHOW_SYSTEM_MENU_ORGANIZATIONS],
    ],
  },
  {
    title: 'NewContact',
    route: 'new-contacts',
    icon: 'UserPlusIcon',
    requiredModule: [],
    requiredPermissions: [
      [ranks.CONTACT_ADD],
    ],
    viewPermissions: [
      [SHOW_SYSTEM_MENU_NEW_CONTACT],
    ],
  },
  {
    title: 'GlobalEmails',
    route: 'emails',
    icon: 'MailIcon',
    requiredModule: ['campaignModule'],
    viewPermissions: [

    ],
  },
  {
    title: 'Campaigns',
    icon: 'CompassIcon',
    requiredModule: ['campaignModule'],
    children: [
      {
        title: 'Sms',
        route: 'sms',
        requiredModule: [],
      },
      {
        title: 'Mailing',
        route: 'mailing',
        requiredModule: [],
      },
    ],
  },
  {
    title: 'YourFunnels',
    route: 'sales-funnel',
    icon: 'FilterIcon',
    requiredPermissions: [
      [ranks.CONTACT_VIEW_ALL, ranks.CONTACT_VIEW_ASSIGNED, ranks.CONTACT_VIEW_STRUCTURE, ranks.CONTACT_VIEW_STRUCTURE_FIRST],
      [ranks.CONTACT_THREAD_VIEW_ALL, ranks.CONTACT_THREAD_VIEW_ASSIGNED, ranks.CONTACT_THREAD_VIEW_STRUCTURE, ranks.CONTACT_THREAD_VIEW_STRUCTURE_FIRST],
    ],
    requiredModule: [],
    and: true,
    children: [
      {
        title: 'EmptyFunnels',
      },
    ],
    viewPermissions: [
      [SHOW_SYSTEM_MENU_FUNNELS],
    ],
  },
  {
    title: 'OfferList',
    icon: 'CreditCardIcon',
    requiredModule: ['offerModule'],
    route: { name: 'offr/offerList' },
    requiredPermissions: [[ranks.CONTACT_THREAD_OFFER_VIEW_ALL, ranks.CONTACT_THREAD_ASSIGNED_OFFER_VIEW_ALL, ranks.CONTACT_THREAD_ASSIGNED_OFFER_VIEW_SELF]],
    viewPermissions: [
      [SHOW_SYSTEM_MENU_OFFERS],
    ],
  },
  {
    title: 'AgreementsCount',
    route: 'agreements',
    icon: 'BookOpenIcon',
    requiredModule: ['agreementModule'],
    requiredPermissions: [[ranks.CONTACT_THREAD_AGREEMENT_VIEW_ALL, ranks.CONTACT_THREAD_ASSIGNED_AGREEMENT_VIEW_ALL, ranks.CONTACT_THREAD_ASSIGNED_AGREEMENT_VIEW_SELF]],
    viewPermissions: [
      [SHOW_SYSTEM_MENU_AGREEMENTS],
    ],
  },
  {
    title: 'Orders',
    route: 'orders',
    icon: 'ShoppingBagIcon',
    requiredPermissions: [[ranks.CONTACT_THREAD_ASSIGNED_ORDER_VIEW_ALL, ranks.CONTACT_THREAD_ASSIGNED_ORDER_VIEW_SELF, ranks.CONTACT_THREAD_ORDER_VIEW_ALL]],
    viewPermissions: [
      [SHOW_SYSTEM_MENU_ORDERS],
    ],
    requiredModule: ['orderModule'],
  },
  // Build
  {
    title: 'contactTabs.Payments',
    route: 'schedules',
    icon: 'DollarSignIcon',
    requiredModule: ['paymentModule'],
    requiredPermissions: [[ranks.CONTACT_THREAD_PAYMENT_VIEW_ALL, ranks.CONTACT_THREAD_ASSIGNED_PAYMENT_VIEW_ALL, ranks.CONTACT_THREAD_ASSIGNED_PAYMENT_VIEW_SELF]],
    viewPermissions: [
      [SHOW_SYSTEM_MENU_PAYMENTS],
    ],
  },
  {
    title: 'contactTabs.Timeline',
    route: 'timeline',
    icon: 'GitPullRequestIcon',
    requiredPermissions: [[ranks.CONTACT_THREAD_TIMELINE_VIEW_ALL]],
    viewPermissions: [
      [SHOW_SYSTEM_MENU_TIMELINE],
    ],
    requiredModule: [],
  },
  {
    title: 'Structure',
    route: 'structure',
    icon: 'LayersIcon',
    requiredPermissions: [],
    requiredModule: ['loyaltyiModule'],
  },
  {
    title: 'Ranking',
    route: 'ranking',
    icon: 'BarChartIcon',
    requiredPermissions: [],
    requiredModule: ['none'],
  },
  {
    title: 'MarketingPlan',
    icon: 'HexagonIcon',
    children: [
      {
        title: 'YourPlans',
        route: 'marketingPlans',
        requiredPermissions: [[ranks.MARKETING_PLAN_VIEW_ALL]],
        requiredModule: ['marketingModule'],
      },
      {
        title: 'Commissions',
        route: 'commissions',
        requiredPermissions: [[ranks.MARKETING_PLAN_POINT_VIEW_ALL, ranks.MARKETING_PLAN_POINT_VIEW_SELF, ranks.MARKETING_PLAN_POINT_VIEW_STRUCTURE]],
        requiredModule: ['marketingModule'],
      },
    ],
    viewPermissions: [
      [SHOW_SYSTEM_MENU_MARKETING_PLANS],
    ],
    requiredPermissions: [[ranks.MARKETING_PLAN_POINT_VIEW_ALL, ranks.MARKETING_PLAN_POINT_VIEW_SELF, ranks.MARKETING_PLAN_POINT_VIEW_STRUCTURE], [ranks.MARKETING_PLAN_VIEW_ALL]],
    requiredModule: ['marketingModule'],
  },
  {
    title: 'LoyaltyPlan',
    icon: 'StarIcon',
    children: [
      {
        title: 'YourPlans',
        route: 'loyaltyPlans',
        requiredPermissions: [[ranks.LOYALTY_PLAN_VIEW_ALL]],
      },
      {
        title: 'Points',
        route: 'loyaltyPoints',
        requiredPermissions: [[
          // ranks.USER_LOYALTY_PLAN_VIEW_ALL,
          // ranks.USER_LOYALTY_PLAN_VIEW_SELF,
          // ranks.USER_LOYALTY_PLAN_VIEW_STRUCTURE,
          // ranks.USER_LOYALTY_PLAN_VIEW_STRUCTURE_FIRST,
          ranks.LOYALTY_PLAN_POINT_VIEW_ALL,
          ranks.LOYALTY_PLAN_POINT_VIEW_SELF,
          ranks.LOYALTY_PLAN_POINT_VIEW_STRUCTURE,
          ranks.LOYALTY_PLAN_POINT_VIEW_STRUCTURE_FIRST,
        ]],
      },
    ],
    viewPermissions: [
      [SHOW_SYSTEM_MENU_LOYALTY_PLANS],
    ],
    requiredPermissions: [[
      // ranks.USER_LOYALTY_PLAN_VIEW_ALL,
      // ranks.USER_LOYALTY_PLAN_VIEW_SELF,
      // ranks.USER_LOYALTY_PLAN_VIEW_STRUCTURE,
      // ranks.USER_LOYALTY_PLAN_VIEW_STRUCTURE_FIRST,
      ranks.LOYALTY_PLAN_POINT_VIEW_ALL,
      ranks.LOYALTY_PLAN_POINT_VIEW_SELF,
      ranks.LOYALTY_PLAN_POINT_VIEW_STRUCTURE,
      ranks.LOYALTY_PLAN_POINT_VIEW_STRUCTURE_FIRST,
    ], [ranks.LOYALTY_PLAN_VIEW_ALL]],
    requiredModule: ['loyaltyModule'],
  },
  {
    title: 'Construction',
    icon: 'LayersIcon',
    requiredModule: ['buildingModule'],
    children: [
      {
        title: 'Realizations',
        route: 'listConstruction',
        // icon: 'TruckIcon',
        requiredPermissions: [[ranks.BUILDING_VIEW_ALL, ranks.BUILDING_VIEW_ASSIGNED, ranks.BUILDING_VIEW_GROUP]],
        requiredModule: ['buildingModule'],
      },
      {
        title: 'ListBudget',
        route: 'listBudget',
        // icon: 'DollarSignIcon',
        requiredPermissions: [[ranks.BUILDING_BUDGET_TEMPLATE_VIEW_ALL]],
        requiredModule: ['buildingModule'],
      },
      {
        title: 'ListContracts',
        route: 'listContracts',
        // icon: 'FileIcon',
        requiredPermissions: [[ranks.BUILDING_AGREEMENT_VIEW_ALL]],
        requiredModule: ['buildingModule'],
      },
      {
        title: 'ListCosts',
        route: 'listCosts',
        // icon: 'PieChartIcon',
        requiredPermissions: [[ranks.BUILDING_INVOICE_COST_VIEW_ALL]],
        requiredModule: ['buildingModule'],
      },
      {
        title: 'ListContractor',
        route: 'listContractor',
        // icon: 'InboxIcon',
        requiredPermissions: [[ranks.CONTRACTOR_VIEW_ALL]],
        requiredModule: ['buildingModule'],
      },
      {
        title: 'CreateDeposits',
        route: 'deposits',
        // icon: 'BriefcaseIcon',
        requiredPermissions: [[ranks.BUILDING_DEPOSIT_ITEM_VIEW_ALL, ranks.BUILDING_GUARANTEE_ITEM_VIEW_ALL, ranks.BUILDING_DEBIT_ITEM_VIEW_ALL]],
        requiredModule: ['buildingModule'],
      },
      {
        title: 'CreateProtocols',
        route: 'protocols',
        // icon: 'ArchiveIcon',
        requiredPermissions: [[ranks.BUILDING_AGREEMENT_PROTOCOL_VIEW_FULL, ranks.BUILDING_AGREEMENT_PROTOCOL_VIEW_ALL]],
        requiredModule: ['buildingModule'],
      },
    ],
  },
  {
    title: 'ranks.sections.investment',
    route: 'shop',
    icon: 'BoxIcon',
    requiredModule: ['offerModule'],
    requiredPermissions: [[ranks.SHOP_PRODUCT_VIEW_ALL, ranks.SHOP_PRODUCT_VIEW_GROUP, ranks.SHOP_CATEGORY_MODIFY, ranks.SHOP_ATTRIBUTE_MODIFY]],
    children: [
      {
        title: 'Locals',
        requiredPermissions: [[ranks.SHOP_PRODUCT_VIEW_ALL, ranks.SHOP_PRODUCT_VIEW_GROUP]],
        route: { name: 'shop' },
      },
      {
        title: 'Categories',
        route: { name: 'shop/categoryList' },
        requiredPermissions: [[ranks.SHOP_CATEGORY_MODIFY]],
      },
      {
        title: 'AttributeList',
        route: { name: 'shop/attributeList' },
        requiredPermissions: [[ranks.SHOP_ATTRIBUTE_MODIFY]],
      },
    ],
    viewPermissions: [
      [SHOW_SYSTEM_MENU_SHOP],
    ],
  },
  {
    title: 'Projects',
    icon: 'BookmarkIcon',
    children: [
      {
        title: 'Projects',
        route: { name: 'projects' },
      },
      {
        title: 'Tasks',
        route: { name: 'projects/tasks' },
      },
      {
        title: 'MicroTaskList',
        route: { name: 'projects/tasks/micro' },
      },
    ],
    viewPermissions: [
      [SHOW_SYSTEM_MENU_PROJECTS],
    ],
    requiredPermissions: [[ranks.PROJECT_VIEW_ALL]],
    requiredModule: ['projectModule'],
  },
  // {
  //   title: 'ProductEdit',
  //   route: 'productEdit',
  //   icon: 'SettingsIcon',
  // },
  // Build
  {
    title: 'Chat',
    route: 'chat',
    icon: 'MessageCircleIcon',
    requiredPermissions: [
      [ranks.CHAT_VIEW_ALL, ranks.CHAT_VIEW_RANK],
    ],
    viewPermissions: [
      [SHOW_SYSTEM_MENU_CHAT],
    ],
    requiredModule: [],
  },
  {
    title: 'Materials',
    icon: 'HardDriveIcon',
    children: [
      {
        title: 'Docs',
        route: 'documents',
        requiredPermissions: [],
        requiredModule: [],
      },
      {
        title: 'Videos',
        route: 'videos',
        requiredPermissions: [],
        requiredModule: [],
      },
    ],
    viewPermissions: [
      [SHOW_SYSTEM_MENU_MATERIALS],
    ],
  },
  {
    title: 'Users',
    route: 'users',
    icon: 'UsersIcon',
    requiredPermissions: [
      [ranks.USER_VIEW_ALL, ranks.USER_VIEW_STRUCTURE],
    ],
    viewPermissions: [
      [SHOW_SYSTEM_MENU_USERS],
    ],
    requiredModule: [],
  },
  {
    title: 'Settings',
    icon: 'SettingsIcon',
    requiredPermissions: [
      [ranks.SETTINGS],
    ],
    viewPermissions: [
      [SHOW_SYSTEM_MENU_SETTINGS],
    ],
    requiredModule: [],
    children: [
      {
        title: 'System',
        children: [
          {
            title: 'General',
            route: { name: 'system-settings' },
          },
          {
            title: 'Groups',
            route: { name: 'group-settings' },
          },
          {
            title: 'RanksSettings',
            route: { name: 'ranks' },
          },
          {
            title: 'TemplateSettings',
            route: { name: 'template-settings' },
          },
          {
            title: 'Integrations',
            route: { name: 'integrations' },
            requiredModule: ['agreementModule'],
          },
          {
            title: 'Automations',
            route: { name: 'automations' },
            requiredModule: ['automationModule'],
            requiredPermissions: [ranks.AUTOMATION_VIEW_ALL],
          },
        ],
      },
      {
        title: 'ThreadsAndContacts',
        route: { name: 'adminSetting' },
        children: [
          {
            title: 'Contacts',
            route: { name: 'contact-settings' },
          },
          {
            title: 'Threads',
            route: { name: 'thread-settings' },
          },
          {
            title: 'Polls',
            route: { name: 'polls' },
            requiredModule: ['pollModule'],
          },
          {
            title: 'Applications',
            route: { name: 'settingApplications' },
            requiredModule: ['applicationModule'],
          },
        ],
      },
      {
        title: 'Offer.Cart',
        requiredModule: ['offerModule'],
        children: [
          {
            title: 'General',
            route: { name: 'offer-settings' },
          },
          {
            title: 'Shop',
            route: { name: 'shop-settings' },
          },
          {
            title: 'ListWebsite',
            route: { name: 'offr/listWebsite' },
          },
          {
            title: 'ImageTypes',
            route: { name: 'picture-types' },
          },
        ],
      },
      {
        title: 'AgreementsCount',
        requiredModule: ['agreementModule'],
        children: [
          {
            title: 'ContractGenerator',
            route: { name: 'contracts-generator' },
            requiredModule: ['agreementModule'],
          },
          {
            title: 'AgreementTypes',
            route: { name: 'agreement-types' },
            requiredModule: ['agreementModule'],
          },
          {
            title: 'Components',
            route: { name: 'components' },
            requiredModule: ['agreementModule'],
          },
        ],
      },
      {
        title: 'Projects',
        requiredModule: ['projectModule'],
        children: [
          {
            title: 'Settings',
            route: { name: 'project-settings-status' },
          },
          {
            title: 'Tags',
            route: { name: 'project-settings-tags' },
          },
          {
            title: 'Categories',
            route: { name: 'project-settings-categories' },
          },
        ],
        requiredPermissions: [
          [ranks.PROJECT_VIEW_ALL],
          [ranks.PROJECT_MODIFY],
        ],
      },
    ],
  },
]

// Ofert
// - strony www
// - oferty - limit
// - komponenty
// - typy umów
