<template>
  <div
    v-b-tooltip.hover.v-primary
    class="d-flex flex-row align-items-center"
    style="column-gap: .5rem"
    :title="tip"
  >
    <b-avatar
      :src="avatar"
      :variant="variant"
      rounded="sm"
      size="2rem"
    >
      <sw-icon
        v-if="!avatar"
        :icon="icon"
      />
    </b-avatar>

    <div
      v-if="!loading"
      class="d-flex flex-column"
    >
      <small
        v-if="content"
        class="font-small-1 mb-n25"
      >
        {{ content | truncate(maxLength + 4) }}
      </small>

      <p class="font-small-4 font-weight-bold mb-0">
        {{ name | truncate(maxLength) }}
      </p>
    </div>
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    avatar: {
      type: String,
      required: false,
      default: null,
    },
    icon: {
      type: String,
      required: false,
      default: 'UserIcon',
    },
    content: {
      type: String,
      required: false,
      default: '',
    },
    tip: {
      type: String,
      required: false,
      default: null,
    },
    variant: {
      type: String,
      required: false,
      default: 'light-primary',
    },
    maxLength: {
      type: Number,
      required: false,
      default: 19,
    },
  },
}
</script>
