<template>
  <div>
    <b-alert
      variant="primary"
      show
    >
      <div class="alert-body">
        <feather-icon icon="InfoIcon" />

        {{ $t('projectTagTip') }}
      </div>
    </b-alert>

    <b-card class="mb-1">
      <!--      New Tag      -->
      <span class="d-flex align-items-center alert-heading">
        <b-form-input
          v-model="newTag"
          :placeholder="$t('Tag')"
        />

        <!--      Button: Add      -->
        <b-button
          variant="outline-primary"
          class="btn-icon ml-1"
          @click="add()"
        >
          <feather-icon icon="PlusIcon" />
        </b-button>
      </span>
    </b-card>

    <b-card no-body>
      <b-card-body>
        <b-row
          v-if="tagList.length > 0"
          style="row-gap: .5rem"
        >
          <b-col
            v-for="(tag, indexTag) in tagList"
            :key="indexTag"
            md="6"
            lg="3"
          >
            <b-button
              v-b-tooltip.hover.v-danger
              size="sm"
              variant="outline-danger"
              :title="$t('Delete')"
              class="w-100 text-left"
              @click="remove(tag.id)"
            >
              {{ `#${tag.name}` }}
            </b-button>
          </b-col>
        </b-row>

        <div
          v-else
          class="text-center w-100 text-primary font-weight-bold"
        >
          <feather-icon
            size="17"
            icon="XCircleIcon"
          />

          {{ $t('EmptyArray') }}
        </div>
      </b-card-body>
    </b-card>

    <b-overlay
      :show="isLoading"
      no-wrap
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
    />
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { CREATE_PROJECT_TAG, GET_PROJECT_TAGS, REMOVE_PROJECT_TAG } from '@/@constants/mutations'

export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data: () => ({
    isLoading: false,

    tagList: [],
    newTag: '',
  }),
  mounted() {
    this.ini()
  },
  methods: {
    ini() {
      this.loadTags()
    },

    loadTags() {
      this.isLoading = true

      this.$store.dispatch(`projectTags/${GET_PROJECT_TAGS}`)
        .then(res => {
          this.tagList = res
        })

        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })

        .finally(() => { this.isLoading = false })
    },

    add() {
      const { newTag } = this

      if (newTag) {
        const payload = this.getPayload()

        this.send(payload)
      }
    },

    getPayload() {
      const payload = {
        name: this.newTag,
      }

      return payload
    },

    send(payload) {
      this.$store.dispatch(`projectTags/${CREATE_PROJECT_TAG}`, payload)
        .then(res => {
          this.tagList.push({
            id: res,
            name: payload.name,
          })

          this.newTag = ''
        })

        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })

        .finally(() => { this.isLoading = false })
    },

    remove(id) {
      this.$store.dispatch(`projectTags/${REMOVE_PROJECT_TAG}`, id)
        .then(() => {
          this.tagList.splice(this.tagList.findIndex(el => el.id === id), 1)
        })

        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })

        .finally(() => { this.isLoading = false })
    },
  },
}
</script>
