<template>
  <div
    id="funnel"
    class="funnel"
  >
    <user-arrears-popover
      :target="target"
      container="funnel"
      @openModal="openModal"
    />
    <div v-if="!loading">
      <div
        class="d-flex"
        style="overflow-x: auto"
      >
        <!-- people group 1 -->
        <div class="mr-1 mr-md-2 pt-25"
             style="max-width: 45px"
        >
          <b-button
            class="btn-icon mt-50"
            variant="outline-secondary"
            @click="scrollNext('right')"
          >
            <feather-icon size="18"
                          icon="ChevronRightIcon"
            />
          </b-button>
          <b-button
            class="btn-icon mt-50"
            variant="outline-secondary"
            @click="scrollPrev('left')"
          >
            <feather-icon size="18"
                          icon="ChevronLeftIcon"
            />
          </b-button>

          <b-button
            class="btn-icon mt-50"
            variant="outline-secondary"
            @click="getFunnelStructure"
          >
            <feather-icon size="18"
                          icon="RefreshCwIcon"
            />
          </b-button>

          <!--          <b-button-->
          <!--            :id="`show_sorter`"-->
          <!--            variant="outline-secondary"-->
          <!--            class="btn-icon mt-50"-->
          <!--          >-->
          <!--            <sw-icon icon="hi-solid-sort-ascending" />-->
          <!--          </b-button>-->

          <!--          <b-popover-->
          <!--            :target="`show_sorter`"-->
          <!--            placement="left"-->
          <!--            triggers="hover"-->
          <!--            custom-class="toggle-columns"-->
          <!--          >-->
          <!--            <template #title>-->
          <!--              <span>-->
          <!--                {{ $t('Sort') }}-->
          <!--              </span>-->
          <!--            </template>-->

          <!--            <b-form-checkbox switch-->
          <!--                             :value="true"-->
          <!--                             :unchecked-value="false"-->
          <!--                             :checked="sorter.sortDesc"-->
          <!--                             @change="Object.assign(sorter, { ...sorter, sortDesc: $event }); getFunnelStructure()"-->
          <!--            >-->
          <!--              {{ $t('Sort') }}: {{ $t(!sorter.sortDesc ? 'Ascending' : 'Descending') }}-->
          <!--            </b-form-checkbox>-->

          <!--            <b-form-radio-group :checked="sorter.sortBy"-->
          <!--                                @change="Object.assign(sorter, { ...sorter, sortBy: $event }); getFunnelStructure()"-->
          <!--            >-->
          <!--              <b-list-group>-->
          <!--                <b-list-group-item-->
          <!--                  v-for="(sortField, index) in fields.filter(e => e.sortBy)"-->
          <!--                  :key="`visible_sort_field_${sortField.label}_${index}`"-->
          <!--                  tag="label"-->
          <!--                >-->
          <!--                  <b-form-radio :value="sortField.sortBy"-->
          <!--                                :checked="sortField.sortBy === sorter.sortBy"-->
          <!--                  >-->
          <!--                    <span class="text-capitalize">{{ $t(`${sortField.label === '#' ? 'ID' : sortField.label}`) }}</span>-->
          <!--                  </b-form-radio>-->
          <!--                </b-list-group-item>-->
          <!--              </b-list-group>-->
          <!--            </b-form-radio-group>-->
          <!--          </b-popover>-->
        </div>

        <transition name="fade">
          <div v-if="showScrollTargets"
               style="position: fixed; bottom: 0; top: 0; left: 0; width: 50px; background-color: rgba(var(--theme-color-primary), 1); color: white; z-index: 9999"
               class="d-flex justify-content-center align-items-center"
               @dragover="scroll('left')"
          >
            <sw-icon icon="ChevronLeftIcon"
                     size="40"
            />
          </div>
        </transition>
        <transition name="fade">
          <div v-if="showScrollTargets"
               style="position: fixed; bottom: 0; top: 0; right: 0; width: 50px; background-color: rgba(var(--theme-color-primary), 1); color: white; z-index: 9999"
               class="d-flex justify-content-center align-items-center"
               @dragover="scroll('right')"
          >
            <sw-icon icon="ChevronRightIcon"
                     size="40"
            />
          </div>
        </transition>

        <div ref="scrollContainer"
             class="d-flex scroll-container position-relative w-100"
             :class="showScrollTargets ? 'px-2' : ''"
             style="overflow-x: auto; transition: all .25s"
        >
          <div
            v-for="(group, i) in funnelPoints"
            :id="`funnel_group_${i}`"
            :key="`funnel_group_${i}`"
            style="min-width: min(305px, 84%); min-height: 400px"
            class="mr-1 mr-md-2 p-25 scroll-item"
            :style="{ 'background-color': highlightIndex === i ? 'rgba(var(--theme-color-primary), .05)' : '' }"
            @dragenter="highlightIndex = i"
          >
            <div
              class="d-flex align-items-center mb-25"
              style="column-gap: .15rem"
            >
              <b-button
                v-if="checkRequiredPermissions([$roles.SETTINGS])"
                v-b-tooltip.hover.v-primary
                variant="flat-primary"
                size="sm"
                class="btn-icon p-25"
                :title="$t('Settings')"
                @click="openModalEditFunnel(group)"
              >
                <feather-icon
                  icon="SettingsIcon"
                  size="16"
                />
              </b-button>

              <h4 class="mb-0 text-primary font-weight-bold">
                <span v-b-tooltip
                      :title="group.name"
                >{{ group.name }}</span>
                <sw-icon v-if="group.description"
                         v-b-tooltip
                         class="text-primary ml-25"
                         icon="AlertCircleIcon"
                         :title="group.description"
                />
              </h4>
            </div>

            <div class="d-flex align-items-center">
              <small>
                {{ $t('funnel.LeadsCount') }}:
                <b-badge variant="light-primary">
                  {{ group ? (group.totalItemCount || 0) : 0 }}
                </b-badge>
              </small>
            </div>

            <!-- draggable -->
            <vue-perfect-scrollbar
              :settings="perfectScrollbarSettings"
              class="funnel-scrollbar mt-1"
              @dragenter="highlightIndex = i"
              @ps-y-reach-end="loadMoreInGroup(group, i)"
            >

              <component :is="'b-card'"
                         v-if="group.loading && !group.users.length"
                         disabled
                         class="d-flex justify-content-center"
              >
                <div class="d-flex justify-content-center">
                  <b-spinner variant="primary" />
                </div>
              </component>

              <div
                v-if="!group.loading && !group.users.length"
                disabled
                class="d-flex justify-content-center align-items-center font-weight-bold"
              >
                <sw-icon icon="XIcon"
                         size="17"
                />
                {{ $t('NoData') }}
              </div>

              <draggable
                v-if="!isMobile"
                :id="`group_${group.id}`"
                :list="group.users"
                tag="ul"
                group="people"
                class="d-none d-lg-flex list-group list-group-flush cursor-move"
                ghost-class="ghost-item"
                :style="{ 'min-height': '400px' }"
                @dragenter="highlightIndex = i"
                @start="removeTarget"
                @unchoose="changeFunnelPoint($event)"
              >
                <b-card
                  v-for="(singleThread, index) in group.users"
                  :key="index"
                  class="card-sm"
                  :class="[
                    ...compressedViewClasses,
                    $store.state.funnelMain.selectedUsers.find(e => e.id === singleThread.id) ? 'border border-primary' : '',
                    singleThread.status === 'OPEN' || singleThread.status === 'PAUSED' ? (singleThread.status === 'PAUSED' ? 'bg-light-warning' : '') : (singleThread.status === 'CLOSED_POSITIVE' ? 'bg-light-success' : 'bg-light-danger')
                  ]"
                  :data-contact="singleThread.contact.id"
                  :data-thread="singleThread.id"
                  :data-opened-id="singleThread.openedId"
                  @click="toggleSelection(singleThread)"
                >
                  <items
                    :is-compressed="isCompressed"
                    :single-thread="singleThread"
                    :index="index"
                    :list="funnelPoints"
                    :contact="singleThread.contact.id"
                    :thread="singleThread.id"
                    class="p-50"
                    @changeTarget="changeTarget"
                    @changeFunnelPoint="changeFunnelPoint"
                  />
                  <div v-if="Array.isArray(singleThread.contact.contactTypes) && singleThread.contact.contactTypes.length"
                       class="d-flex flex-wrap"
                  >
                    <b-badge
                      v-for="tag in getFilteredContactTags(singleThread.contact.contactTypes)"
                      :key="`tag_${tag.id}_${index}`"
                      v-b-tooltip
                      variant="light-secondary"
                      :title="tag.name"
                      class="mr-25 mb-25 flex-grow-1"
                    >
                      <div class="d-flex">
                        <div
                          class="color-dot mr-25"
                          :style="{ backgroundColor: tag.color || '#fff' }"
                          style="border: 1px solid white"
                        />
                        {{ tag.name | truncate(15) }}
                      </div>
                    </b-badge>
                  </div>
                </b-card>
              </draggable>

              <div v-else>
                <b-card
                  v-for="(singleThread, index) in group.users"
                  :key="index"
                  class="p-50 card-sm"
                  :class="[...compressedViewClasses, $store.state.funnelMain.selectedUsers.find(e => e.id === singleThread.id) ? 'border border-primary' : '',]"
                  :data-contact="singleThread.contact.id"
                  :data-thread="singleThread.id"
                  :data-opened-id="singleThread.openedId"
                  @click="toggleSelection(singleThread)"
                >
                  <items
                    :is-compressed="isCompressed"
                    :single-thread="singleThread"
                    :index="index"
                    :list="funnelPoints"
                    :contact="singleThread.contact.id"
                    :thread="singleThread.id"
                    @changeTarget="changeTarget"
                    @changeFunnelPoint="changeFunnelPoint"
                  />
                  <div v-if="Array.isArray(singleThread.contact.contactTypes) && singleThread.contact.contactTypes.length"
                       class="d-flex flex-wrap"
                  >
                    <b-badge
                      v-for="tag in getFilteredContactTags(singleThread.contact.contactTypes)"
                      :key="`tag_${tag.id}_${index}`"
                      v-b-tooltip
                      variant="light-secondary"
                      :title="tag.name"
                      class="mr-25 mb-25 flex-grow-1"
                    >
                      <div class="d-flex">
                        <div
                          class="color-dot mr-25"
                          :style="{ backgroundColor: tag.color || '#fff' }"
                          style="border: 1px solid white"
                        />
                        {{ tag.name | truncate(15) }}
                      </div>
                    </b-badge>
                  </div>
                </b-card>
              </div>

              <component :is="'div'"
                         v-if="group.loading && group.users.length"
                         disabled
                         class="d-flex justify-content-center"
              >
                <div class="d-flex justify-content-center">
                  <b-spinner variant="primary" />
                </div>
              </component>
            </vue-perfect-scrollbar>
          </div>

          <div class="mr-1 mr-md-2 pt-25">
            <b-button
              v-if="checkRequiredPermissions([$roles.SETTINGS])"
              id="add-new-button"
              v-b-tooltip.v-primary.hover
              :title="$t('AddStatus')"
              class="btn-icon"
              variant="outline-primary"
              @click="openModalEditFunnel({
                contactThreadFunnelType: $route.params.id,
                position: funnelPoints.length + 1
              })"
            >
              <feather-icon icon="PlusIcon" />
            </b-button>
          </div>
        </div>
      </div>
      <div ref="checkpoint_loader" />
    </div>
    <div
      v-else
      class="d-flex justify-content-center"
    >
      <b-spinner
        variant="primary"
        class="m-5"
      />
    </div>

    <mass-update-modal
      :key="'mass-update-modal-funnel' +massUpdateContacts"
      :contacts="massUpdateContacts"
      :show-modal="showMassUpdateModal"
      @close-modal="closeMassUpdateModal"
    />

    <import-contact-threads-modal
      v-if="checkRequiredPermissions([$roles.CONTACT_THREAD_ADD])"
      :show="showThreadImport"
      @close-modal="() => { $emit('closeModal'); getFunnelStructure() }"
      @reload-content="closeMassUpdateModal"
    />
  </div>
</template>

<script>
import {
  VBPopover, VBTooltip,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
// eslint-disable-next-line import/no-cycle
import axiosIns from '@/libs/axios'
import { mapGetters } from 'vuex'
import * as roles from '@/helpers/permissions'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import * as fields from '@/@constants/fields'
import useVerticalLayout from '@core/layouts/layout-vertical/useVerticalLayout'
import { onUnmounted } from '@vue/composition-api'
import MassUpdateModal from '@/pages/contact.old/components/MassUpdateModal.vue'
import ImportContactThreadsModal from '@/views/modals/ImportContactThreadsModal.vue'
import { resolveFilters } from '@/store/funnel/funnel'
// import { resolveParams } from '@/store/offer/offers/offers'
import UserArrearsPopover from '../userArrears/UserArrearsPopover.vue'
import { OPEN_MODAL, UPDATE_CONTACT_THREAD } from '../../../@constants/mutations'
import Items from './components/Items.vue'

export default {
  name: 'Funnel',
  components: {
    // BFormRadioGroup,
    // BPopover,
    ImportContactThreadsModal,
    MassUpdateModal,
    draggable,
    UserArrearsPopover,
    VuePerfectScrollbar,
    Items,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-popover': VBPopover,
  },
  props: ['funnelData', 'target', 'isCompressed', 'showMassUpdateModal', 'showThreadImport'],
  data: () => ({
    scrollIndex: 0,
    currentIndex: 0,
    highlightIndex: null,
    scrollSpeed: 2,
    showScrollTargets: false,
    direction: null,
    intervalId: null,

    massUpdateContacts: [],
    roles,
    funnelPoints: [],
    funnelPointsIds: [],
    loading: false,
    page: 1,
    changedItemCopy: {
      fromIndex: null,
      toIndex: null,
      thread: null,
    },
    fields: [
      {
        key: 'id', label: '#', visible: true, sortBy: 'id',
      },
    ],
    sorter: {
      sortDesc: true,
      sortBy: 'id',
    },
  }),
  computed: {
    ...mapGetters({
      filters: 'funnelMain/getFilters',
      currentUser: 'auth/getCurrentUser',
      modalState: 'modal/getModalState',
    }),
    compressedViewClasses() {
      return this.isCompressed ? ['mb-0', 'shadow-none', 'border'] : []
    },
  },
  watch: {
    '$route.params.id': function () {
      this.page = 1
      this.getFunnelStructure()
      // this.$store.commit('funnelMain/CLEAR_FILTERS')
      // this.filters.assignedUsers = [this.currentUser]
    },
    filters: {
      deep: true,
      handler() {
        this.getFunnelStructure()
      },
    },
    modalState: {
      deep: true,
      handler(n) {
        if (n.reloadContent) this.getFunnelStructure()
      },
    },
  },
  mounted() {
    // this.$store.commit('funnelMain/SET_FILTER', { field: 'status', value: null })
    // setTimeout(() => {
    //   this.getFunnelStructure()
    // }, 250)
    this.getFunnelStructure()
  },
  setup() {
    const { isMobile, resizeHandler } = useVerticalLayout()

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    return {
      perfectScrollbarSettings,
      isMobile,
    }
  },
  methods: {
    handleMouseMove(event) {
      const container = event.currentTarget
      const rect = container.getBoundingClientRect()
      const threshold = 75 // Distance from the edge to start scrolling

      // Stop any ongoing scroll
      clearInterval(this.intervalId)

      // If near the left edge, scroll left
      if (event.clientX - rect.left < threshold) {
        this.scroll('left')
      } else if (rect.right - event.clientX < threshold) {
        this.scroll('right')
      }
    },
    scrollViewTo(direction) {
      // const container = this.$el.querySelector('.scroll-container')
      const container = this.$refs.scrollContainer
      const scrollItems = container.getElementsByClassName('scroll-item')

      // console.log(scrollItems)
      // setTimeout(() => {
      if (direction === 'left') {
        if (this.currentIndex > 0) {
          this.currentIndex -= 1
          scrollItems[this.currentIndex].scrollIntoView({ behavior: 'smooth', inline: 'start' })
        }
        // Ensure we do not go out of bounds
        // container.scrollLeft -= 300
        // this.scrollIndex -= 1
        // const element = document.querySelector(`#funnel_group_${this.scrollIndex + 4}`)
        // element.scrollTo()
      } else if (direction === 'right') {
        if (this.currentIndex < scrollItems.length - 1) {
          this.currentIndex += 1
          scrollItems[this.currentIndex].scrollIntoView({ behavior: 'smooth', inline: 'start' })
        }
        // container.scrollLeft += 300
        // this.scrollIndex += 1
        // const element = document.querySelector(`#funnel_group_${this.scrollIndex + 4}`)
        // element.scrollTo()
      }
    },
    scroll(direction) {
      const container = this.$el.querySelector('.scroll-container')

      // setTimeout(() => {
      if (direction === 'left') {
        container.scrollLeft -= this.scrollSpeed
      } else if (direction === 'right') {
        container.scrollLeft += this.scrollSpeed
      }
      // }, 50) // Adjust the interval to control the speed
    },
    stopScroll() {
      clearInterval(this.intervalId)
    },
    getFilteredContactTags(tags = []) {
      const tagsCopy = JSON.parse(JSON.stringify(tags))
      if (!tagsCopy) return []

      return tagsCopy.sort(((a, b) => a.position - b.position)).filter(tag => tag?.showInContactThreadFunnel)
    },
    closeMassUpdateModal() {
      this.$emit('closeModal')
      this.massUpdateContacts = []
      this.getFunnelStructure()
    },
    getCurrentDate() {
      const date = new Date()
      const month = (date.getMonth() + 1) < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
      const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
      const hour = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
      const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
      const seconds = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds()
      return `${date.getFullYear()}-${month}-${day} ${hour}:${minutes}:${seconds}`
    },
    toggleSelection(item) {
      if (this.$store.state.funnelMain.selectedUsers.find(e => e.id === item.id)) {
        const index = this.$store.state.funnelMain.selectedUsers.findIndex(e => e.id === item.id)
        this.$store.state.funnelMain.selectedUsers.splice(index, 1)
      } else {
        this.$store.state.funnelMain.selectedUsers.push(item)
      }
    },
    async getFunnelStructure() {
      // this.loading = true

      const { id } = this.$route.params
      // const funnelItems = []

      if (!Number.isNaN(id)) {
        this.$nextTick(() => {
          this.$store.dispatch('funnelMain/GET_FUNNEL_POINTS', { funnelId: id })
            .then(funnelPoints => {
              if (funnelPoints?.length) {
                this.funnelPoints = funnelPoints.map(e => ({
                  ...e, users: [], loading: false, allowMore: false, totalItemCount: 0,
                }))

                this.$nextTick(async () => {
                  await this.loadInitStatusesData()
                })
              }
              // const points = funnelPoints.map(point => point.id)
              // this.funnelPointsIds = points
              //
              // let params = {
              //   collection_field: 'contactThreadFunnelTypePoint',
              //   collection_values: JSON.stringify(points),
              //   fields_load: fields.FUNNEL,
              //   limit: this.filters.limit,
              // }
              //
              // params = resolveFilters(params, { filters: this.filters, sorter: this.sorter, search: this.search })
              //
              // axiosIns.get('1/contacts/threads/funnelProcesses', { params })
              //   .then(res => {
              //     funnelPoints.forEach(point => {
              //       const pointData = res.data.data.collection.filter(collectionItem => collectionItem.collectionValue === point.id)
              //       funnelItems.push({
              //         ...point,
              //         users: (pointData.length ? pointData[0]?.items : []).map(i => ({ ...i, ...i.contactThread, openedId: i.id })),
              //         totalItemCount: pointData[0]?.totalItemCount || 0,
              //       })
              //     })
              //   })
              //   .finally(() => {
              //     this.loading = false
              //     this.massUpdateContacts = []
              //     this.funnelPoints = funnelItems
              //     funnelItems.forEach(({ users }) => {
              //       users.forEach(({ contact }) => {
              //         if (!this.massUpdateContacts.find(u => u.id === contact.id)) {
              //           this.massUpdateContacts.push(contact)
              //         }
              //       })
              //     })
              //   })
            })
        })
      } else {
        // this.loading = false
      }
    },
    async loadInitStatusesData() {
      try {
        const points = this.funnelPoints.map(e => this.getObjectId(e))
        this.funnelPoints.forEach((_, index) => {
          this.$set(this.funnelPoints[index], 'loading', true)
          this.$set(this.funnelPoints[index], 'users', [])
          this.$set(this.funnelPoints[index], 'totalItemCount', 0)
        })

        let params = {
          collection_field: 'contactThreadFunnelTypePoint',
          collection_values: JSON.stringify(points),
          fields_load: fields.FUNNEL,
          orderBy: 'id.desc',
          limit: 25,
        }

        params = resolveFilters(params, { filters: this.filters, sorter: null, search: '' })

        const repsonse = await axiosIns.get('1/contacts/threads/funnelProcesses', { params })

        if (repsonse?.data?.data?.collection) {
          repsonse.data.data.collection.forEach(collection => {
            const { items = [], collectionValue: id, totalItemCount = 0 } = collection
            const statusIndex = this.funnelPoints.findIndex(e => e.id === id)
            if (statusIndex > -1) {
              this.$set(this.funnelPoints[statusIndex], 'users', items.map(i => ({ ...i, ...i.contactThread, openedId: i.id })))
              this.$set(this.funnelPoints[statusIndex], 'totalItemCount', totalItemCount)
              this.$set(this.funnelPoints[statusIndex], 'allowMore', items.length < totalItemCount)
            }
          })
        }
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.funnelPoints.forEach((_, index) => {
          this.$set(this.funnelPoints[index], 'loading', false)
        })
        this.loading = false
      }
    },
    /* eslint-disable */
      async loadMoreInGroup(group, index) {
          if (!group.allowMore || group.loading) return;

          this.$set(this.funnelPoints[index], 'loading', true);

          const lowestId = Math.min(...group.users.map(e => Number(e.openedId)));
          if (!lowestId) {
              this.$set(this.funnelPoints[index], 'loading', false);
              return;
          }

          const params = {
              lt_id: lowestId,
              'eq_contactThreadFunnelTypePoint-id': group.id,
              fields_load: fields.FUNNEL,
              orderBy: 'id.desc',
              limit: 10,
          };

          const _params = resolveFilters(params, {
              filters: this.filters,
              sorter: null,
              search: '',
          });

          try {
              const response = await axiosIns.get('1/contacts/threads/funnelProcesses', { params: _params });
              const items = response?.data?.data?.items || [];
              if (items.length) {
                  this.funnelPoints[index].users.push(...items.map(i => ({ ...i, ...i.contactThread, openedId: i.id })));

                  if (items.length < 10) {
                      this.$set(this.funnelPoints[index], 'allowMore', false);
                  }
              } else {
                  this.$set(this.funnelPoints[index], 'allowMore', false);
              }
          } catch (error) {
              console.error('Błąd podczas ładowania danych:', error);
          } finally {
              this.$set(this.funnelPoints[index], 'loading', false);
          }
      },

      changeFunnelPoint(e) {
      this.highlightIndex = null
      this.showScrollTargets = false
      const { to, target } = e
      const oldPointId = target.id.replace('group_', '')
      const newFunnelPointId = to.id.replace('group_', '')
      const contactId = e.item.contact ? this.getObjectId(e.item.contact) : e.item.getAttribute('data-contact')
      const threadId = e.item.thread ? this.getObjectId(e.item.thread) : e.item.getAttribute('data-thread')
      const openedId = e.item.openedId ? e.item.openedId : e.item.getAttribute('data-opened-id')

      if (oldPointId !== newFunnelPointId) {
        const payload = {
          contactId: contactId.toString(),
          data: {
            id: threadId.toString(),
            openedId,
            contactThreadFunnelTypePoint: newFunnelPointId.toString(),
          },
        }

        this.$store.dispatch(`singleContact/${UPDATE_CONTACT_THREAD}`, payload)
          .then(async () => {
            const params = {
              fields_load: fields.FUNNEL,
              eq_id: openedId,
            }
            const response = await axiosIns.get('1/contacts/threads/funnelProcesses', { params })
            if (response.data?.data?.items?.length) {
              const record = response.data?.data?.items[0]
              const funnelIndex = this.funnelPoints.findIndex(a => a.id === newFunnelPointId)
              if (funnelIndex > -1) {
                  this.$set(this.funnelPoints[funnelIndex], 'totalItemCount', this.funnelPoints[funnelIndex].totalItemCount + 1)
                const itemIndex = this.funnelPoints[funnelIndex].users.findIndex(a => a.openedId === openedId)
                if (itemIndex > -1) {
                  this.$set(this.funnelPoints[funnelIndex].users, itemIndex, { ...record, ...record.contactThread, openedId: record.id })
                } else {
                  this.funnelPoints[funnelIndex].users.push({ ...record, ...record.contactThread, openedId: record.id })
                }
              }

              // remove from old
              const oldFunnelIndex = this.funnelPoints.findIndex(a => a.id === oldPointId)
              if (oldFunnelIndex > -1) {
                  this.$set(this.funnelPoints[oldFunnelIndex], 'totalItemCount', this.funnelPoints[oldFunnelIndex].totalItemCount - 1)
                const itemIndex = this.funnelPoints[oldFunnelIndex].users.findIndex(a => a.openedId === openedId)
                if (itemIndex > -1) {
                  this.$delete(this.funnelPoints[oldFunnelIndex].users, itemIndex)
                }
              }
            }
            // this.getFunnelStructure()
            this.showToast('success', this.$i18n.t('StatusUpdated'), this.$i18n.t('Created'))
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          })
      }
    },
    removeTarget() {
      this.showScrollTargets = true
      this.$root.$emit('bv::hide::popover')
      this.$emit('changeTarget', null)
    },
    changeTarget(target) {
      this.$emit('changeTarget', target)
    },
    openModal(modalType = 'note', modalTitle = 'Nowa Notatka', okButtonTitle = 'Dodaj notatkę', thread = null) {
      this.$root.$emit('bv::hide::popover')
      this.$store.commit(`modal/${OPEN_MODAL}`,
        {
          modalType,
          modalTitle,
          size: 'lg',
          okButtonTitle,
          threadFor: thread.id,
          thread,
        })
    },

    openModalEditFunnel(field) {
      this.$store.commit(`modal/${OPEN_MODAL}`,
        {
          modalType: 'newFunnelField',
          modalTitle: this.$i18n.t('Field'),
          data: field,
        })
    },
    /* eslint-disable */
    scrollNext() {
        const container = this.$refs.scrollContainer;
        const scrollItems = container.getElementsByClassName('scroll-item');
        const testButton = document.getElementById('add-new-button');

        const isButtonVisible = () => {
            const rect = testButton.getBoundingClientRect();
            return rect.left >= 0 && rect.right <= window.innerWidth;
        };

        if (!isButtonVisible()) {
            if (this.currentIndex < scrollItems.length - 1) {
                this.currentIndex++;
                const nextItem = scrollItems[this.currentIndex];

                // Scroll by the width of the next item
                container.scrollLeft = nextItem.offsetLeft;
            }
        }
    },
    scrollPrev() {
        const container = this.$refs.scrollContainer;
        const scrollItems = container.getElementsByClassName('scroll-item');

        if (this.currentIndex > 0) {
            this.currentIndex--;
            const prevItem = scrollItems[this.currentIndex];

            // Scroll back by the width of the previous item
            container.scrollLeft = prevItem.offsetLeft;
        }
    },
  },
}
</script>

<style lang="scss">
    .funnel-scrollbar {
      height: 80vh;
    }

    .funnel {
        .popover {
            min-width: 280px;
            max-width: 370px !important;
            .popover-body {
                padding: 0.65rem 0 !important;
            }
        }
    }

    .ghost-item {
        border: 1px solid var(--purple);
    }

    .card-sm {
        & .card-body {
            padding: 10px !important;
        }
    }

    .scroll-container {
      scroll-behavior: smooth;
    }
</style>
