<template>
  <b-button
    v-if="show"
    v-b-tooltip.hover.v-primary
    :title="name"
    :variant="variant"
    class="btn-icon px-0 py-0"
    style="cursor: default !important;"
  >
    <slot name="icon">
      <feather-icon icon="InfoIcon" />
    </slot>
  </b-button>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },

  props: {
    name: {
      type: [String, Object],
      required: false,
      default: '–',
    },
    icon: {
      type: String,
      required: false,
      default: 'Info',
    },
    variant: {
      type: String,
      required: false,
      default: 'flat-primary',
    },
    show: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
}
</script>
