var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.checkRequiredPermissions([_vm.$roles.SHOP_CATEGORY_MODIFY]))?_c('b-button',{attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){_vm.showCategoryModal = true}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"PlusIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t('Add')))])],1):_vm._e(),_c('div',{staticClass:"mt-1"},[_c('b-card',{staticClass:"myTableOffer",staticStyle:{"overflow-x":"scroll"}},[_c('sw-select',{staticClass:"pb-0"},[_c('v-select',{staticClass:"mb-0",attrs:{"options":_vm.filteredLocales,"reduce":function (e) { return e.value; },"label":"name"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(label))+" ")]}},{key:"selected-option",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(label))+" ")]}}]),model:{value:(_vm.selectedLocale),callback:function ($$v) {_vm.selectedLocale=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"selectedLocale"}})],1),_c('my-table',{attrs:{"selected-locale":_vm.selectedLocale,"loading":_vm.loading,"items":_vm.categories},on:{"openEditModal":_vm.openEditModal,"deleteItem":_vm.deleteItem,"createOffer":_vm.createOffer}})],1)],1),(_vm.checkRequiredPermissions([_vm.$roles.SHOP_CATEGORY_MODIFY]))?_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.saveItems}},[_vm._v(" "+_vm._s(_vm.$t('Save'))+" ")]):_vm._e(),_c('b-modal',{attrs:{"title":_vm.$t('offer.category.new'),"size":"md","hide-footer":"","no-close-on-backdrop":""},on:{"close":_vm.closeModal},model:{value:(_vm.showCategoryModal),callback:function ($$v) {_vm.showCategoryModal=$$v},expression:"showCategoryModal"}},[_c('sw-select',{staticClass:"pb-0"},[_c('v-select',{staticClass:"mb-0",attrs:{"options":_vm.filteredLocales,"reduce":function (e) { return e.value; },"label":"name"},on:{"option:selected":_vm.checkAvailableLocale},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(label))+" ")]}},{key:"selected-option",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(label))+" ")]}}]),model:{value:(_vm.selectedLocale),callback:function ($$v) {_vm.selectedLocale=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"selectedLocale"}})],1),_c('validation-observer',{attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"12","lg":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Name')}},[_c('b-form-input',{attrs:{"value":_vm.selectedTranslationObject(_vm.item).name,"placeholder":_vm.$t('Name'),"state":errors.length > 0 ? false:null},on:{"input":function($event){return _vm.setTranslationValue($event, 'name')}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),(!_vm.parent)?_c('sw-select',{attrs:{"name":_vm.$t('offer.Group')}},[_c('v-select',{attrs:{"placeholder":_vm.$t('offer.Group'),"options":_vm.groups,"label":"name"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var name = ref.name;
var color = ref.color;
return [_c('div',{staticClass:"color-dot",staticStyle:{"border":"1px solid white"},style:({ backgroundColor: color })}),_vm._v(" "+_vm._s(name)+" ")]}},{key:"selected-option",fn:function(ref){
var name = ref.name;
var color = ref.color;
return [_c('div',{staticClass:"color-dot",staticStyle:{"border":"1px solid white"},style:({ backgroundColor: color })}),_vm._v(" "+_vm._s(name)+" ")]}},{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('NoOptions'))+" ")]},proxy:true}],null,false,2246547976),model:{value:(_vm.item.userGroups),callback:function ($$v) {_vm.$set(_vm.item, "userGroups", $$v)},expression:"item.userGroups"}})],1):_vm._e()],1)],1)],1),_c('div',{staticClass:"d-flex pt-1 mt-1 border-top"},[_c('b-button',{attrs:{"variant":"primary","disabled":_vm.saveDisable,"size":"sm"},on:{"click":_vm.saveItem}},[_vm._v(" "+_vm._s(_vm.$t('Save'))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }