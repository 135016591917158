<template>
  <div v-if="thread">
    <schedules
      :allow-filters="false"
      :thread-id="thread.id"
      :contact-id="thread.contact.id"
    />
  </div>
</template>

<script>
import Schedules from '@/pages/Schedules.vue'

export default {
  name: 'Payments',
  components: { Schedules },
  props: {
    // eslint-disable-next-line vue/require-prop-types
    thread: {
      default: null,
    },
  },
}
</script>

<style scoped>

</style>
