<template>
  <div>
    <b-input-group class="input-group-merge search-input-group mx-25 mb-1">
      <b-input-group-prepend is-text>
        <feather-icon icon="SearchIcon" />
      </b-input-group-prepend>
      <b-form-input
        v-model="search"
        :placeholder="$t('Search')"
        class="input-search"
      />
    </b-input-group>
    <b-list-group v-if="user">
      <div v-if="getUserFilteredFields().length">
        <DetailListItem v-for="field in getUserFilteredFields()"
                        :key="`detail_field_${field.icon}`"
                        :label="$t(field.label)"
                        :value="field.value"
                        :icon="field.icon"
                        :tip="field.tip"
                        :badge="field.badge"
                        :show-copy="field.copy"
                        :avatar="field.avatar"
        />
      </div>
      <div v-else
           class="text-center py-1"
      >
        <sw-icon icon="XCircleIcon" />
        {{ $t('NoData') }}
      </div>
    </b-list-group>
    <div v-if="user.isOrganization">
      <div class="divider mt-1 mt-md-2 mb-1 mb-md-2 font-weight-bold text-primary">
        <div class="divider-text text-primary">
          {{ $t('Company') }}
        </div>
      </div>

      <b-list-group v-if="user.organization">
        <div v-if="getUserOrganizationFilteredFields().length">
          <DetailListItem v-for="field in getUserOrganizationFilteredFields()"
                          :key="`detail_field_${field.icon}`"
                          :label="$t(field.label)"
                          :value="field.value"
                          :icon="field.icon"
                          :tip="field.tip"
                          :badge="field.badge"
                          :show-copy="field.copy"
                          :avatar="field.avatar"
          />
        </div>
        <div v-else
             class="text-center py-1"
        >
          <sw-icon icon="XCircleIcon" />
          {{ $t('NoData') }}
        </div>
      </b-list-group>

      <div>
        <div class="divider mt-1 mt-md-2 mb-1 mb-md-2 font-weight-bold text-primary">
          <div class="divider-text text-primary">
            {{ $t('contact.PersonIdentityDocuments') }}
          </div>
        </div>

        <b-row v-if="user.personIdentityDocuments.length">
          <b-col v-for="(document, documentIndex) in user.personIdentityDocuments"
                 :key="`document_${documentIndex}`"
                 sm="12"
                 md="6"
          >
            <div class="p-50 shadow">
              <b-list-group>
                <DetailListItem icon="CreditCardIcon"
                                :value="$t(`contact.${document.type}`)"
                /><DetailListItem :label="$t('OrganizationDetails.addressNumber')"
                                  :value="document.number"
                /><DetailListItem :label="$t('contact.authorityIssuing')"
                                  :value="document.authorityIssuing"
                /><DetailListItem :label="$t('contact.releaseDate')"
                                  :value="document.releaseDate ? formatDate(document.releaseDate.date, { month: 'short', day: 'numeric', year: 'numeric' }, $i18n.locale) : null"
                /><DetailListItem :label="$t('contact.expirationDate')"
                                  :value="document.expirationDate ? formatDate(document.expirationDate.date, { month: 'short', day: 'numeric', year: 'numeric' }, $i18n.locale) : null"
                />
              </b-list-group>
            </div>
          </b-col>
        </b-row>
        <div v-else
             class="text-center py-1"
        >
          <sw-icon icon="XCircleIcon" />
          {{ $t('NoData') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DetailListItem from '@/views/core/user/detail/DetailListItem.vue'
import { formatDate } from '@core/utils/filter'

export default {
  components: { DetailListItem },
  props: {
    user: {
      default: null,
    },
  },
  data: () => ({
    search: '',
  }),
  methods: {
    formatDate,
    fieldsFilter(fields) {
      return fields
        .filter(type => !type?.requiredView || this.checkRequiredViewPermissions(type.requiredView))
        .filter(type => !type?.requiredModule || this.checkRequiredModule(type?.requiredModule || ''))
        .filter(type => !type?.requiredPermissions || this.checkRequiredPermissions(type?.requiredPermissions || []))
        .filter(field => !this.search.length || this.$i18n.t(field.label).toLowerCase().includes(this.search.toLowerCase()))
        .filter(field => field.show)
    },
    getPlansList(plans = []) {
      return plans.map(plan => `${plan.loyaltyPlan?.name || plan.marketingPlan?.name}${plan.superSeller ? '⭐' : ''}`).join(', ')
    },
    getUserFilteredFields() {
      const fields = [
        {
          label: 'Id', icon: 'ToolIcon', value: this.user.userId, show: true, badge: true, copy: true,
        },
        {
          label: 'Pesel', icon: 'BookIcon', value: this.user.personalId, show: !this.user.isOrganization,
        },
        {
          label: 'PrivateEmail', icon: 'MailIcon', value: this.user.mailAddressPrivate?.mail, show: true, copy: true,
        },
        {
          label: 'PrivatePhone', icon: 'PhoneIcon', value: this.user.phoneNumberPrivate?.phone, show: true, copy: true,
        },
        {
          label: 'contact.bankAccountName', icon: 'PocketIcon', value: this.user.bankAccountName, show: true,
        },
        {
          label: 'contact.bankAccountNumber', icon: 'CreditCardIcon', value: this.user.bankAccountNumber, show: true, copy: true,
        },
        {
          label: 'contact.parentUser', icon: 'UserIcon', value: this.user.parentUser, show: true, avatar: true,
        },
        {
          label: 'Address', icon: 'MapPinIcon', value: this.getAddress(this.user), show: !this.user.isOrganization, tip: `${this.$i18n.t('OrganizationDetails.addressStreet')} ${this.$i18n.t('OrganizationDetails.addressNumber')}, ${this.$i18n.t('OrganizationDetails.addressCity')} ${this.$i18n.t('OrganizationDetails.addressPostalCode')}`,
        },
        {
          label: 'AddressForCorrespondence', icon: 'BookOpenIcon', value: this.getAddress(this.user, 'correspondence'), show: !this.user.isOrganization, tip: `${this.$i18n.t('OrganizationDetails.addressStreet')} ${this.$i18n.t('OrganizationDetails.addressNumber')}, ${this.$i18n.t('OrganizationDetails.addressCity')} ${this.$i18n.t('OrganizationDetails.addressPostalCode')}`,
        },
        {
          label: 'LoyaltyPlans', icon: 'StarIcon', value: this.getPlansList(this.user.userLoyaltyPlans), requiredModule: 'loyaltyModule', show: true,
        },
        {
          label: 'MarketingPlans', icon: 'HexagonIcon', value: this.getPlansList(this.user.userMarketingPlans), requiredModule: 'marketingModule', show: true,
        },
      ]

      return this.fieldsFilter(fields)
    },

    getUserOrganizationFilteredFields() {
      const fields = [
        {
          label: 'Name', icon: 'GlobeIcon', value: this.user.organization?.name, show: true,
        },
        {
          label: 'Nip', icon: 'BookIcon', value: this.user.organization?.vatId, show: true, badge: true, copy: true,
        },
        {
          label: 'Address', icon: 'MapPinIcon', value: this.getAddress(this.user.organization), show: this.user.isOrganization, tip: `${this.$i18n.t('OrganizationDetails.addressStreet')} ${this.$i18n.t('OrganizationDetails.addressNumber')}, ${this.$i18n.t('OrganizationDetails.addressCity')} ${this.$i18n.t('OrganizationDetails.addressPostalCode')}`,
        },
        {
          label: 'AddressForCorrespondence', icon: 'BookOpenIcon', value: this.getAddress(this.user.organization, 'correspondence'), show: this.user.isOrganization, tip: `${this.$i18n.t('OrganizationDetails.addressStreet')} ${this.$i18n.t('OrganizationDetails.addressNumber')}, ${this.$i18n.t('OrganizationDetails.addressCity')} ${this.$i18n.t('OrganizationDetails.addressPostalCode')}`,
        },
      ]

      return this.fieldsFilter(fields)
    },
  },
}
</script>

<style scoped>

</style>
