<template>
  <b-card-text>
    <b-form>
      <validation-observer tag="form">
        <b-row>
          <!--    Type    -->
          <b-col
            sm="12"
            md="12"
            lg="12"
          >
            <validation-provider
              v-slot="{ errors }"
              :name="$t('offer.attribute.type')"
              rules="required"
            >
              <sw-select :name="$t('offer.attribute.type')">
                <v-select
                  v-model.trim="newAttribute.type"
                  :clearable="false"
                  :options="attributeTypes"
                  :placeholder="$t('offer.attribute.type')"
                  :clear-search-on-blur="() => { return false }"
                >
                  <template #option="option">
                    {{ $t(`offer.attribute.types.${option.label}`) }}
                  </template>
                  <template #selected-option="option">
                    {{ $t(`offer.attribute.types.${option.label}`) }}
                  </template>
                  <template #no-options="{ search }">
                    <span v-if="search.length">
                      {{ $t('NoData') }}
                    </span>
                    <span v-else>
                      {{ $t('TypeToSearch') }}
                    </span>
                  </template>
                </v-select>
              </sw-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <!--    Name    -->
          <b-col
            sm="12"
            md="12"
            lg="12"
          >
            <validation-provider
              v-slot="{ errors }"
              :name="$t('Name')"
              rules="required"
            >
              <b-form-group :label="$t('Name')">
                <b-form-input
                  v-model.trim="newAttribute.translations[0].name"
                  :placeholder="$t('Name')"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!--    Value: Options    -->
          <b-col
            v-if="newAttribute.type === 'TEXT_ARRAY' || newAttribute.type === 'TEXT_MULTIPLE_ARRAY'"
            sm="12"
            md="12"
            lg="12"
          >
            <validation-provider
              v-slot="{ errors }"
              :name="$t('Value')"
            >
              <b-form-group :label="$t('Value')">
                <!-- Tags List -->
                <b-form-tags
                  v-model="newAttribute.shopAttributeOptions"
                  no-outer-focus
                  :placeholder="$t('TagsPlaceholder')"
                >
                  <template
                    #default="{
                      inputAttrs,
                      inputHandlers,
                      removeTag
                    }"
                  >
                    <b-input-group aria-controls="my-custom-tags-list">
                      <input
                        v-bind="inputAttrs"
                        :placeholder="$t('NewValue')"
                        class="form-control"
                        v-on="inputHandlers"
                      >
                      <b-input-group-append>
                        <b-button
                          variant="primary"
                          :disabled="!inputAttrs.value"
                          @click="addItem(inputAttrs.value); inputAttrs.value = ''"
                        >
                          {{ $t('Add') }}
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                    <div
                      id="my-custom-tags-list"
                      class="d-flex flex-wrap mb-0"
                    >
                      <!-- Always use the tag value as the :key, not the index! -->
                      <!-- Otherwise screen readers will not read the tag
                       additions and removals correctly -->
                      <div
                        v-if="newAttribute.shopAttributeOptions && newAttribute.shopAttributeOptions.length"
                        class="d-flex flex-wrap mb-0"
                      >
                        <b-card
                          v-for="tag in newAttribute.shopAttributeOptions"
                          :id="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                          :key="tag"
                          tag="li"
                          class="shadow-none border mt-1 mr-1 mb-0 flex-grow-1"
                          body-class="py-1 pr-2 text-nowrap"
                        >
                          <strong>{{ JSON.parse(tag).translations[0].name }}</strong>
                          <b-button
                            variant="link"
                            size="sm"
                            :aria-controls="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                            class="py-0"
                            @click="removeTag(tag)"
                          >
                            <feather-icon icon="TrashIcon" />
                          </b-button>
                        </b-card>
                      </div>
                    </div>
                  </template>
                </b-form-tags>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </validation-observer>
    </b-form>

    <div class="d-flex justify-content-end pt-1 mt-1 border-top">
      <b-button
        size="sm"
        variant="primary"
        @click="saveAttribute"
      >
        {{ $t('Save') }}
      </b-button>
    </div>
  </b-card-text>
</template>

<script>
import {
  BCardText, BForm, BFormTags, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import { attributeTypes } from '@/helpers/offer/attributeTypes'
import vSelect from 'vue-select'

export default {
  components: {
    BForm,
    BCardText,
    ValidationObserver,
    ValidationProvider,
    vSelect,
    BFormTags,
    BInputGroup,
    BInputGroupAppend,
  },
  props: {
    oldAttribute: {
      type: Object,
      required: false,
    },
  },
  data: () => ({
    newAttribute: {
      translations: [
        { name: '', locale: 'pl_pl' },
      ],
      shopAttributeOptions: [],
      type: '',
      active: true,
      base: false,
    },
    required,

    attributeTypes: (attributeTypes)(),
  }),
  created() {
    if (this.oldAttribute && Object.keys(this.oldAttribute).length) {
      this.newAttribute = this.oldAttribute
    }
  },
  methods: {
    addItem(item) {
      this.newAttribute.shopAttributeOptions.push({
        translations: [
          {
            name: item,
            locale: 'pl_pl',
          },
        ],
      })
    },
    saveAttribute() {
      this.$emit('saveAttribute', this.newAttribute)
      this.$emit('closeModal')
    },
  },
}
</script>
