// eslint-disable-next-line import/no-cycle
import axiosIns from '@/libs/axios'
import * as fields from '@/@constants/fields'
import {
  GET_TEMPLATES, DELETE_TEMPLATE, SAVE_TEMPLATE, SAVE_FOOTER, GET_FOOTER, SAVE_OFFER_EMAIL, GET_OFFER_EMAIL,
} from '../../../../@constants/mutations'

function getTemplateType(type) {
  return type === 'sms' ? 'templateSmses' : 'templateMailes'
}

export default {
  namespaced: true,
  state: {
    emailTemplates: [],
    emailRows: 0,
    smsTemplates: [],
    smsRows: 0,
    shortcodes: [
      { label: 'firstName', shortcode: '{%firstName%}', sample: 'Adam' },
      { label: 'lastName', shortcode: '{%lastName%}', sample: 'Nowak' },
      { label: 'phone', shortcode: '{%phone%}', sample: '505 606 707' },
      { label: 'email', shortcode: '{%email%}', sample: 'test@test.pl' },
    ],
  },
  getters: {
    getShortcodes: state => state.shortcodes,
    getSmsTemplates: state => state.smsTemplates,
    getSmsTemplatesLength: state => state.smsRows,
    getEmailTemplates: state => state.emailTemplates,
    getEmailTemplatesLength: state => state.emailRows,
  },
  mutations: {
    // eslint-disable-next-line no-unused-vars
    SET_TEMPLATES: (state, payload) => {
      const { type, templates, totalRows } = payload
      state[type === 'sms' ? 'smsTemplates' : 'emailTemplates'] = templates
      state[type === 'sms' ? 'smsRows' : 'emailRows'] = totalRows
    },
  },
  actions: {
    [GET_TEMPLATES]: ({ commit }, payload) => new Promise((resolve, reject) => {
      // eslint-disable-next-line no-unused-vars
      const { type, page, limit } = payload
      let params = {}
      if (page || limit) {
        params = {
          page,
          limit,
        }
      }

      params.fields_load = type === 'sms' ? fields.SETTINGS_TEMPLATES : 'SETTINGS_TEMPLATES_EMAIL'

      axiosIns.get(`1/settings/${getTemplateType(type)}`, { params })
        .then(res => {
          commit('SET_TEMPLATES', { type, templates: res.data.data.items, totalRows: res.data.data.totalItemCount })
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [DELETE_TEMPLATE]: ({ commit }, payload) => new Promise((resolve, reject) => {
      const { templatesToRemove, type } = payload
      axiosIns.delete(`1/settings/${getTemplateType(type)}`, { data: templatesToRemove })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [SAVE_TEMPLATE]: ({ commit }, payload) => new Promise((resolve, reject) => {
      const { template, type } = payload
      if (template.id) {
        axiosIns.patch(`1/settings/${getTemplateType(type)}`, template)
          .then(res => {
            resolve(res.data)
          })
          .catch(err => {
            reject(err)
          })
      } else {
        axiosIns.post(`1/settings/${getTemplateType(type)}`, template)
          .then(res => {
            resolve(res.data)
          })
          .catch(err => {
            reject(err)
          })
      }
    }),
    // eslint-disable-next-line no-unused-vars
    [SAVE_FOOTER]: ({ commit }, content) => new Promise((resolve, reject) => {
      const payload = {
        id: '1',
        emailFooter: content,
      }
      axiosIns.patch('1/settings/global', payload)
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [GET_FOOTER]: ({ commit }) => new Promise((resolve, reject) => {
      axiosIns.get('1/settings/global', { params: { fields_load: fields.SETTINGS_GLOBAL } })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [SAVE_OFFER_EMAIL]: ({ commit }, content) => new Promise((resolve, reject) => {
      const payload = {
        id: '1',
        translations: content,
      }
      axiosIns.patch('1/settings/global', payload)
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [GET_OFFER_EMAIL]: ({ commit }) => new Promise((resolve, reject) => {
      axiosIns.get('1/settings/global', { params: { fields_load: fields.SETTINGS_GLOBAL } })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
  },
}
