<template>
  <b-button
    v-if="!filter.hide"
    variant="flat-primary"
    size="sm"
    class="p-0 px-25"
    style="cursor: default"
  >
    <b-input-group class="filters-search:modern">
      <b-input-group-prepend>
        <sw-icon icon="SearchIcon" />
      </b-input-group-prepend>

      <b-form-input
        v-model="search"
        :placeholder="`${$t('Search')}…`"
        @change="$emit('change', { filter, option: $event }); $emit('search', { filter, option: search })"
      />
    </b-input-group>
  </b-button>
</template>

<script>
export default {
  emits: ['change', 'search'],
  data: () => ({
    filter: { },

    search: '',
  }),
  watch: {
    value: {
      deep: true,
      handler(n) { if (n) this.filter = JSON.parse(JSON.stringify(n)) },
    },
  },
  async mounted() {
    if (this.value) this.filter = JSON.parse(JSON.stringify(this.value))
  },
}
</script>
