var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticStyle:{"row-gap":"1rem"}},[_c('b-col',{attrs:{"md":"6","lg":"4"}},[_c('sw-select',{attrs:{"name":_vm.$t('AccordingToSale')}},[_c('v-select',{attrs:{"options":[
          'numberOfOrders',
          'offers',
          'arrangements' ],"multiple":""},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('NoOptions'))+" ")]},proxy:true},{key:"option",fn:function(ref){
        var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(label))+" ")]}},{key:"selected-option",fn:function(ref){
        var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(label))+" ")]}}])})],1)],1),_c('b-col',{attrs:{"md":"6","lg":"4"}},[_c('sw-select',{attrs:{"name":_vm.$t('ByUser')}},[_c('assigned-users',{attrs:{"is-multiple":true,"mode":'<contacts>show all contacts</contacts>'}})],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-checkbox',{attrs:{"variant":"primary","switch":""}},[_vm._v(" "+_vm._s(_vm.$t('ShowSubUsers'))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }