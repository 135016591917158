<!--eslint-disable-->

<template>
  <div v-if="item">
    <sw-select
      :name="item.type === 'CRON' ? $t('CheckedActivity') : $t('Trigger')"
      class="flex-grow-1"
    >
      <v-select
        :value="item.automationProcedureTrigger.type"
        :options="getTriggerTypes"
        :reduce="r => r.value"
        :filter="(options, search) => filterByKey(options, search, 'label')"
        @input="changeItemTriggerField($event, 'type')"
        :disabled="item.id"
      >
        <template #no-options>
          {{ $t('NoOptions') }}
        </template>
        <template #option="option">
          {{ $t(`${option.label}`) }}
          <b-badge
            variant="primary"
            class="mr-25"
          >
            <feather-icon icon="Share2Icon" />
            {{ option.conditions.length }}
          </b-badge>
          <b-badge
            variant="primary"
            class="mr-25"
          >
            <feather-icon icon="StarIcon" />
            {{ option.actions.length }}
          </b-badge>
        </template>
        <template #selected-option="option">
          {{ $t(`${option.label}`) }}
        </template>
      </v-select>
    </sw-select>

    <!-- Additional Fields -->
    <b-row v-if="item.automationProcedureTrigger.type === 'CONTACT_THREAD_TIMELINE_NO_ACTION'">
        <b-col sm="12">
            <b-alert show variant="primary">
                <div class="alert-body">
                    {{ $t('NoActionOnTimeline') }}
                </div>
            </b-alert>
        </b-col>
    </b-row>

    <!-- Additional Fields -->
    <b-row v-if="getTypeAdditionalFields.includes(CONTACT_STATUSES)">
      <b-col sm="6">
        <sw-select
          :name="$t('ChangeFrom')"
          class="flex-grow-1"
        >
          <v-select
            :placeholder="$t('All')"
            label="name"
            :value="item.automationProcedureTrigger.contactStatusFrom"
            :options="contactStatuses"
            :reduce="r => r.id"
            @input="changeItemTriggerField($event, 'contactStatusFrom')"
          >
            <template #no-options>
              {{ $t('NoOptions') }}
            </template>
          </v-select>
        </sw-select>
      </b-col>
      <b-col sm="6">
        <sw-select
          :name="$t('ChangeTo')"
          class="flex-grow-1"
        >
          <v-select
            :placeholder="$t('All')"
            label="name"
            :value="item.automationProcedureTrigger.contactStatusTo"
            :options="contactStatuses"
            :reduce="r => r.id"
            @input="changeItemTriggerField($event, 'contactStatusTo')"
          >
            <template #no-options>
              {{ $t('NoOptions') }}
            </template>
          </v-select>
        </sw-select>
      </b-col>
    </b-row>

    <!-- Additional Fields -->
    <b-row v-if="getTypeAdditionalFields.includes(THREAD_STATUSES)">
      <b-col sm="6">
        <sw-select
          :name="$t('ChangeFrom')"
          class="flex-grow-1"
        >
          <v-select
            :placeholder="$t('All')"
            label="name"
            :value="item.automationProcedureTrigger.contactThreadFunnelTypePointFrom"
            :options="contactThreadFunnelTypesPoints"
            :reduce="r => r.id"
            @input="changeItemTriggerField($event, 'contactThreadFunnelTypePointFrom')"
          >
            <template #no-options>
              {{ $t('NoOptions') }}
            </template>
          </v-select>
        </sw-select>
      </b-col>
      <b-col sm="6">
        <sw-select
          :name="$t('ChangeTo')"
          class="flex-grow-1"
        >
          <v-select
            :placeholder="$t('All')"
            label="name"
            :value="item.automationProcedureTrigger.contactThreadFunnelTypePointTo"
            :options="contactThreadFunnelTypesPoints"
            :reduce="r => r.id"
            @input="changeItemTriggerField($event, 'contactThreadFunnelTypePointTo')"
          >
            <template #no-options>
              {{ $t('NoOptions') }}
            </template>
          </v-select>
        </sw-select>
      </b-col>
    </b-row>

      <b-row v-if="getTypeAdditionalFields.includes(THREAD_CLOSE_STATUSES)">
          <b-col sm="6">
              <sw-select
                      :name="$t('ChangeFrom')"
                      class="flex-grow-1"
              >
                  <v-select
                          :placeholder="$t('All')"
                          :value="item.automationProcedureTrigger.contactThreadFunnelProcessStatusFrom"
                          :options="contactThreadCloseStatuses"
                          :reduce="r => r.value"
                          @input="changeItemTriggerField($event, 'contactThreadFunnelProcessStatusFrom')"
                  >
                      <template #no-options>
                          {{ $t('NoOptions') }}
                      </template>
                      <template #option="option">
                        {{ $tc(`${option.label}`, 2) }}
                      </template>
                      <template #selected-option="option">
                        {{ $tc(`${option.label}`, 2) }}
                      </template>
                  </v-select>
              </sw-select>
          </b-col>
          <b-col sm="6">
              <sw-select
                      :name="$t('ChangeTo')"
                      class="flex-grow-1"
              >
                  <v-select
                          :placeholder="$t('All')"
                          :value="item.automationProcedureTrigger.contactThreadFunnelProcessStatusTo"
                          :options="contactThreadCloseStatuses"
                          :reduce="r => r.value"
                          @input="changeItemTriggerField($event, 'contactThreadFunnelProcessStatusTo')"
                  >
                      <template #no-options>
                          {{ $t('NoOptions') }}
                      </template>
                      <template #option="option">
                        {{ $tc(`${option.label}`, 2) }}
                      </template>
                      <template #selected-option="option">
                        {{ $tc(`${option.label}`, 2) }}
                      </template>
                  </v-select>
              </sw-select>
          </b-col>
      </b-row>

    <b-row v-if="getTypeAdditionalFields.includes(THREAD_STATUS)">
<!--      <b-col sm="6">-->
<!--        <sw-select-->
<!--          :name="$t('ChangeFrom')"-->
<!--          class="flex-grow-1"-->
<!--        >-->
<!--          <v-select-->
<!--            :placeholder="$t('All')"-->
<!--            label="name"-->
<!--            :value="item.automationProcedureTrigger.contactThreadFunnelTypeFrom"-->
<!--            :options="contactThreadFunnelTypes"-->
<!--            :reduce="r => r.id"-->
<!--            @input="changeItemTriggerField($event, 'contactThreadFunnelTypeFrom')"-->
<!--          >-->
<!--            <template #no-options>-->
<!--              {{ $t('NoOptions') }}-->
<!--            </template>-->
<!--          </v-select>-->
<!--        </sw-select>-->
<!--      </b-col>-->
      <b-col sm="6">
        <sw-select
          :name="$t('ChangeTo')"
          class="flex-grow-1"
        >
          <v-select
            :placeholder="$t('All')"
            label="name"
            :value="item.automationProcedureTrigger.contactThreadFunnelTypeTo"
            :options="contactThreadFunnelTypes"
            :reduce="r => r.id"
            @input="changeItemTriggerField($event, 'contactThreadFunnelTypeTo')"
          >
            <template #no-options>
              {{ $t('NoOptions') }}
            </template>
          </v-select>
        </sw-select>
      </b-col>
    </b-row>

    <!-- Webhook Fields -->
    <b-row v-if="getTypeAdditionalFields.includes(CONTACT_APPLICATION_INTEGRATION_TYPE)">
      <b-col sm="6">
        <sw-select
          :name="$t('Typ')"
          class="flex-grow-1"
        >
          <v-select
            :placeholder="$t('All')"
            label="label"
            :value="item.automationProcedureTrigger.webhookType"
            :options="applicationTypes"
            :reduce="r => r.value"
            @input="changeItemTriggerField($event, 'webhookType'); item.automationProcedureTrigger.contactApplicationIntegration = ''; item.automationProcedureTrigger.integrationFacebookWebhook =''"
          >
            <template #no-options>
              {{ $t('NoOptions') }}
            </template>
          </v-select>
        </sw-select>
      </b-col>

      <b-col
        v-if="item.automationProcedureTrigger.webhookType !== 'FACEBOOK'"
        sm="6"
      >
        <sw-select
          :name="$t('Webhook')"
          class="flex-grow-1"
        >
          <v-select
            :placeholder="$t('All')"
            label="name"
            :value="item.automationProcedureTrigger.contactApplicationIntegration"
            :options="selectedTypeItems"
            :reduce="r => r.id"
            @input="changeItemTriggerField($event, 'contactApplicationIntegration')"
          >
            <template #no-options>
              {{ $t('NoOptions') }}
            </template>
          </v-select>
        </sw-select>
      </b-col>

      <b-col
        v-else
        sm="6"
      >
        <sw-select
          :name="$t('Webhook')"
          class="flex-grow-1"
        >
          <v-select
            :placeholder="$t('All')"
            label="name"
            :value="item.automationProcedureTrigger.integrationFacebookWebhook"
            :options="fbWebhooks"
            :reduce="r => r.id"
            @input="changeItemTriggerField($event, 'integrationFacebookWebhook')"
          >
            <template #no-options>
              {{ $t('NoOptions') }}
            </template>
          </v-select>
        </sw-select>
      </b-col>
    </b-row>

    <!-- Additional Fields -->
    <b-row v-if="getTypeAdditionalFields.includes(ORDER_STATUSES)">
      <b-col sm="6">
        <sw-select
          :name="$t('ChangeFrom')"
          class="flex-grow-1"
        >
          <v-select
            :placeholder="$t('All')"
            label="name"
            :value="item.automationProcedureTrigger.contactThreadOrderStatusFrom"
            :options="contactThreadOrderStatuses"
            :reduce="r => r.value"
            @input="changeItemTriggerField($event, 'contactThreadOrderStatusFrom')"
          >
            <template #no-options>
              {{ $t('NoOptions') }}
            </template>
            <template #option="option">
              {{ $tc(`offer.status.${option.label}`, 2) }}
            </template>
            <template #selected-option="option">
              {{ $tc(`offer.status.${option.label}`, 2) }}
            </template>
          </v-select>
        </sw-select>
      </b-col>

      <b-col sm="6">
        <sw-select
          :name="$t('ChangeTo')"
          class="flex-grow-1"
        >
          <v-select
            :placeholder="$t('All')"
            label="name"
            :value="item.automationProcedureTrigger.contactThreadOrderStatusTo"
            :options="contactThreadOrderStatuses"
            :reduce="r => r.value"
            @input="changeItemTriggerField($event, 'contactThreadOrderStatusTo')"
          >
            <template #no-options>
              {{ $t('NoOptions') }}
            </template>
            <template #option="option">
              {{ $tc(`offer.status.${option.label}`, 2) }}
            </template>
            <template #selected-option="option">
              {{ $tc(`offer.status.${option.label}`, 2) }}
            </template>
          </v-select>
        </sw-select>
      </b-col>
    </b-row>

    <!-- Additional Fields -->
    <b-row v-if="getTypeAdditionalFields.includes(DAYS_COUNT)">
      <b-col sm="6">
        <b-form-group :label="$t('DaysCount')">
          <b-form-input type="number" v-model="item.automationProcedureTrigger.executionDelayDays" @change="changeItemTriggerField($event, 'executionDelayDays')" />
        </b-form-group>
      </b-col>
    </b-row>
    <!-- Task types -->
    <b-row v-if="getTypeAdditionalFields.includes(TASK_TYPES) || getTypeAdditionalFields.includes(TASK_TYPE)">
      <b-col sm="6">
        <sw-select
            :name="$t('task.Type')"
            class="flex-grow-1"
        >
          <v-select
              :placeholder="$t('All')"
              label="name"
              :value="item.automationProcedureTrigger.contactThreadTaskTypes"
              :options="taskTypes"
              :reduce="r => r.id"
              multiple
              @input="changeItemTriggerField($event, 'contactThreadTaskTypes')"
          >
            <template #no-options>
              {{ $t('NoOptions') }}
            </template>
            <template #option="option">
              {{ $tc(option.name) }}
            </template>
            <template #selected-option="option">
              {{ $tc(option.name) }}
            </template>
          </v-select>
        </sw-select>
      </b-col>
      <b-col sm="6" v-if="getTypeAdditionalFields.includes(TASK_TYPES)">
        <sw-select
            :name="$t('ChangeTo')"
            class="flex-grow-1"
        >
          <v-select
              :placeholder="$t('All')"
              :value="item.automationProcedureTrigger.contactThreadTaskStatusTo"
              :options="['D', 'ND']"
              @input="changeItemTriggerField($event, 'contactThreadTaskStatusTo')"
          >
            <template #no-options>
              {{ $t('NoOptions') }}
            </template>
            <template #option="{ label }">
              {{ $t(`task.status.${label}`) }}
            </template>
            <template #selected-option="{ label }">
              {{ $t(`task.status.${label}`) }}
            </template>
          </v-select>
        </sw-select>
      </b-col>
    </b-row>

    <!-- Offer statuses -->
    <b-row v-if="getTypeAdditionalFields.includes(OFFER_STATUSES)">
      <b-col sm="6">
        <sw-select
          :name="$t('ChangeFrom')"
          class="flex-grow-1"
        >
          <v-select
            :placeholder="$t('All')"
            label="name"
            :value="item.automationProcedureTrigger.contactThreadOfferStatusFrom"
            :options="contactThreadOfferStatuses"
            :reduce="r => r.value"
            @input="changeItemTriggerField($event, 'contactThreadOfferStatusFrom')"
          >
            <template #no-options>
              {{ $t('NoOptions') }}
            </template>
            <template #option="option">
              {{ $tc(`offer.status.${option.label}`, 1) }}
            </template>
            <template #selected-option="option">
              {{ $tc(`offer.status.${option.label}`, 1) }}
            </template>
          </v-select>
        </sw-select>
      </b-col>
      <b-col sm="6">
        <sw-select
          :name="$t('ChangeTo')"
          class="flex-grow-1"
        >
          <v-select
            :placeholder="$t('All')"
            label="name"
            :value="item.automationProcedureTrigger.contactThreadOfferStatusTo"
            :options="contactThreadOfferStatuses"
            :reduce="r => r.value"
            @input="changeItemTriggerField($event, 'contactThreadOfferStatusTo')"
          >
            <template #no-options>
              {{ $t('NoOptions') }}
            </template>
            <template #option="option">
              {{ $tc(`offer.status.${option.label}`, 1) }}
            </template>
            <template #selected-option="option">
              {{ $tc(`offer.status.${option.label}`, 1) }}
            </template>
          </v-select>
        </sw-select>
      </b-col>
    </b-row>

    <!-- Additional Fields -->
    <b-row v-if="getTypeAdditionalFields.includes(CHECKLIST_POINT)">
      <b-col sm="6">
        <sw-select
          :name="$t('ChecklistPoints')"
          class="flex-grow-1"
        >
          <v-select
            :placeholder="$t('All')"
            label="name"
            :value="item.automationProcedureTrigger.contactThreadChecklistTypePoint"
            :options="contactThreadChecklistTypesPoints"
            :reduce="r => r.id"
            @input="changeItemTriggerField($event, 'contactThreadChecklistTypePoint')"
          >
            <template #no-options>
              {{ $t('NoOptions') }}
            </template>
          </v-select>
        </sw-select>
      </b-col>

      <b-col sm="6">
        <b-form-radio-group
          id="skin-radio-group"
          stacked
          :checked="item.automationProcedureTrigger.contactThreadChecklistPointAction"
          @change="changeItemTriggerField($event, 'contactThreadChecklistPointAction')"
        >
          <b-form-radio
            v-for="({ label, value }, index) in automationsCheckboxActions"
            :key="`skin_${index}`"
            :value="value"
            variant="primary"
          >
            {{ $t(label) }}
          </b-form-radio>
        </b-form-radio-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import {
  automationsCheckboxActions,
  automationsTriggerTypes,
  CHECKLIST_POINT,
  CONTACT_APPLICATION_INTEGRATION_TYPE,
  CONTACT_STATUSES,
  OFFER_STATUSES,
  TASK_TYPES,
  TASK_TYPE,
  THREAD_STATUS,
  ORDER_STATUSES,
  THREAD_STATUSES,
  THREAD_CLOSE_STATUSES,
  DAYS_COUNT,
} from '@/@types/automationsTypes'
import { BFormRadioGroup } from 'bootstrap-vue'
import axiosIns from '@/libs/axios'

export default {
  name: 'AutomationsDetailsTrigger',
  components: { BFormRadioGroup, vSelect },
  props: {
    item: {
      default: null,
      required: true,
    },
    applicationTypes: { type: Array, required: true, default: () => ([]) },
    contactStatuses: { type: Array, required: true, default: () => ([]) },
    contactThreadChecklistTypesPoints: { type: Array, required: true, default: () => ([]) },
    contactThreadFunnelTypesPoints: { type: Array, required: true, default: () => ([]) },
    contactThreadCloseStatuses: { type: Array, required: true, default: () => ([]) },
    contactThreadFunnelTypes: { type: Array, required: true, default: () => ([]) },
    contactThreadOrderStatuses: { type: Array, required: true, default: () => ([]) },
    contactThreadOfferStatuses: { type: Array, required: true, default: () => ([]) },
    taskTypes: { type: Array, required: true, default: () => ([]) },
  },
  data: () => ({
    THREAD_CLOSE_STATUSES,
    THREAD_STATUSES,
    THREAD_STATUS,
    CONTACT_STATUSES,
    CHECKLIST_POINT,
    ORDER_STATUSES,
    OFFER_STATUSES,
    TASK_TYPES,
    TASK_TYPE,
    DAYS_COUNT,
    CONTACT_APPLICATION_INTEGRATION_TYPE,

    automationsCheckboxActions,
    automationsTriggerTypes,

    webhooks: [],
    fbWebhooks: [],
  }),
  computed: {
    getTriggerTypes() {
      return this.item.type === 'CRON' ? this.automationsTriggerTypes.filter(e => e?.type === 'CRON') : this.automationsTriggerTypes.filter(e => !e?.type)
    },
    getTypeAdditionalFields() {
      return this.automationsTriggerTypes.findWhere('value', this.item.automationProcedureTrigger.type)?.additionalFields || []
    },
    selectedTypeItems() {
      return this.webhooks.filter(i => i.type === this.item.automationProcedureTrigger.webhookType)
    },
  },
  async mounted() {
    if (this.checkRequiredModule('applicationModule')) {
      axiosIns.get('1/settings/contactsApplicationsIntegrations', { params: { fields_load: this.$fields.SETTINGS_CONTACTS_APPLICATIONS_INTEGRATIONS } })
        .then(res => {
          this.webhooks = res.data.data.items
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })

      try {
        const res = await axiosIns.get('1/integration/facebook/connection/webhook', { params: { fields_load: this.$fields.FACEBOOK_WEBHOOKS } })
        const webhooks = res?.data?.data?.items?.sort((a, b) => new Date(b.createdAt.date) - new Date(a.createdAt.date))

        this.fbWebhooks = webhooks || []
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    }
  },
  methods: {
    changeItemField(value, field) {
      this.$emit('change', { field, value })
    },
    changeItemTriggerField(value, field) {
      this.$emit('change-trigger', { field, value })
    },
  },
}
</script>

<style scoped>

</style>
