<template>
  <b-row>
    <b-col>
      <transition
        name="slide-fade"
        mode="out-in"
      >
        <template-list
          v-if="!selectedTemplate"
          key="templates"
        />
        <template-details
          v-else
          key="details"
        />
      </transition>
    </b-col>
  </b-row>
</template>

<script>

import TemplateList from '@/views/offers/productTemplate/TemplateList.vue'
import TemplateDetails from '@/views/offers/productTemplate/TemplateDetails.vue'

export default {
  name: 'OfferProductTemplate',
  components: {
    TemplateList,
    TemplateDetails,
  },
  computed: {
    selectedTemplate() {
      return this.$route.params?.templateId
    },
  },
}
</script>

<style scoped>

</style>
