export default [
  {
    path: '/ranking',
    component: () => import('@/pages/ranking/Ranking.vue'),
    name: 'ranking',
    meta: {
      autorize: true,
      pageTitle: 'Ranking',
    },
  },
]
