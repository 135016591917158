<!--eslint-disable-->

<template>
  <div>
    <h4 class="mb-1">
      {{ $t('Punctation') }}
    </h4>

    <b-row style="row-gap: 1rem">
      <!--    START::Choose a point of point    -->
      <b-col md="6">
        <validation-provider
          v-slot="{ errors }"
          :name="$t('ChooseHowToAssignLoyaltyPointsToPurchasedProducts')"
          rules="required"
        >
          <sw-select
            :name="$t('ChooseHowToAssignLoyaltyPointsToPurchasedProducts')"
            :tip="$t('HereWouDetermineWhatValueOfPointsYouWillReceive')"
            :state="errors.length > 0 ? false:null"
          >
            <v-select
              v-model="target.typeOfAssignLoyaltyPoints"
              :options="typesOfAssignPoints"
              label="label"
              :reduce="i => i.value"
            >
              <template #no-options>
                {{ $t('NoOptions') }}
              </template>
              <template #selected-option="{ label }">
                <span>{{ $t(`${label}`) }}</span>
              </template>
              <template #option="{ label }">
                <span>{{ $t(`${label}`) }}</span>
              </template>
            </v-select>
          </sw-select>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col>
      <!--    END::Choose a point of point    -->

      <!--    START::Select a commission type    -->
      <b-col md="6">
        <validation-provider
          v-slot="{ errors }"
          :name="$t('SelectHowPointValueOfProductsYouBuyWillBeAssigned')"
          rules="required"
        >
          <sw-select
            :name="$t('SelectHowPointValueOfProductsYouBuyWillBeAssigned')"
            :tip="$t('HereYouSpecifyForWhatValueOfCollectedPoints')"
            :state="errors.length > 0 ? false:null"
          >
            <v-select
              v-model="target.typeOfAssignLoyaltyPointsToOwnPurchase"
              :options="typesOfAssignPoints"
              label="label"
              :reduce="i => i.value"
            >
              <template #no-options>
                {{ $t('NoOptions') }}
              </template>
              <template #selected-option="{ label }">
                <span>{{ $t(`${label}`) }}</span>
              </template>
              <template #option="{ label }">
                <span>{{ $t(`${label}`) }}</span>
              </template>
            </v-select>
          </sw-select>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col>
      <!--    END::Select a commission type    -->

      <!--    START::Checkbox Inu    -->
      <b-col sm="12">
        <b-form-checkbox
          v-model="target.chargePointsForSales"
          variant="primary"
          switch
        >
          <span v-html="$t('DoNotChargePointsForSalesMadeWithLoyaltyPlanPoints')" />
        </b-form-checkbox>
      </b-col>
      <!--    END::Checkbox Inu    -->
    </b-row>

    <b-overlay
      :show="isLoading"
      no-wrap
      spinner-variant="primary"
    />
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import { VBTooltip } from 'bootstrap-vue'

export default {
  components: {
    ValidationProvider,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    target: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    typesOfAssignPoints: [
      { value: 'PRODUCT_VALUE', label: 'TypeOfAssignLoyaltyPointsProduct' },
      // { value: 'PLAN_VALUE', label: 'TypeOfAssignLoyaltyPointsPlan' },
    ],
    // typeOfAssignLoyaltyPointsToOwnPurchaseItems: [
    //   { value: '', label: '' },
    // ],
  }),
}
</script>
