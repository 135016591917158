<template>
  <app-collapse
    type="margin"
    accordion
  >
    <!--    START::Basic Information    -->
    <app-collapse-item
      class="mt-0"
    >
      <template #header>
        <div
          class="d-flex align-items-center"
          style="gap: .34rem"
        >
          <sw-icon
            icon="UserIcon"
            class="mt-n25"
          />

          <h6 class="mb-0">
            {{ $t('contact.basicInformations') }}
          </h6>

          <b-spinner
            v-if="!contact"
            type="grow"
            small
            class="my-50 mx-auto"
            variant="primary"
          />
        </div>
      </template>

      <template v-if="contact">
        <!--    START::Contact Info    -->
        <b-row
          class="px-1"
          style="row-gap: 1.34rem"
        >
          <b-col
            v-for="(info, i) in basicInformation"
            :key="i"
            cols="12"
            md="6"
            lg="4"
          >
            <!--    START::Field    -->
            <info-field
              :icon="info.icon"
              :title="info.title"
              :content="info.content"
            />
            <!--    END::Field    -->
          </b-col>

          <!--    START::Field Groups    -->
          <b-col
            cols="12"
          >
            <!--    START::Field    -->
            <info-field
              icon="BookmarkIcon"
              :title="$t('Groups')"
            >
              <div
                v-if="contact.userGroups.length"
                class="d-flex flex-wrap"
                style="row-gap: .34rem; column-gap: .5rem;"
              >
                <b-badge
                  v-for="group in contact.userGroups"
                  :key="`group_${group.id}`"
                  variant="light-secondary"
                >
                  <div
                    class="color-dot mr-0"
                    :style="{ backgroundColor: group.color }"
                    style="width: 8px; height: 8px;"
                  />

                  <span :style="{ color: group.color || '#fff' }">
                    {{ group.name }}
                  </span>
                </b-badge>
              </div>

              <sw-icon
                v-else
                icon="MinusIcon"
              />
            </info-field>
            <!--    END::Field    -->
          </b-col>
          <!--    END::Field Groups    -->
        </b-row>
        <!--    END::Contact Info    -->

        <!--    START::Contact Primary Address    -->
        <div class="divider">
          <span class="divider-text">
            {{ $t('PrimaryAddress') }}
          </span>
        </div>

        <b-row
          class="px-1"
          style="row-gap: 1.34rem"
        >
          <b-col
            v-for="(info, i) in primaryAddress"
            :key="i"
            cols="12"
            md="6"
          >
            <!--    START::Field    -->
            <info-field
              :icon="info.icon"
              :title="info.title"
              :content="info.content"
            />
            <!--    END::Field    -->
          </b-col>
        </b-row>
        <!--    END::Contact Primary Address    -->

        <!--    START::Contact Correspondence Address    -->
        <div class="divider">
          <span class="divider-text">
            {{ $t('AddressForCorrespondence') }}
          </span>
        </div>

        <b-row
          class="px-1"
          style="row-gap: 1.34rem"
        >
          <b-col
            v-for="(info, i) in correspondenceAddress"
            :key="i"
            cols="12"
            md="6"
          >
            <!--    START::Field    -->
            <info-field
              :icon="info.icon"
              :title="info.title"
              :content="info.content"
            />
            <!--    END::Field    -->
          </b-col>
        </b-row>
        <!--    END::Contact Correspondence Address    -->

        <!--    START::Contact Company    -->
        <template v-if="contact.isOrganization">
          <!--    START::Contact Company Info    -->
          <div class="divider">
            <span class="divider-text">
              {{ $t('Company') }}
            </span>
          </div>
          <b-row
            class="px-1"
            style="row-gap: 1.34rem"
          >
            <b-col
              v-for="(info, i) in company"
              :key="i"
              cols="12"
              md="6"
            >
              <!--    START::Field    -->
              <info-field
                :icon="info.icon"
                :title="info.title"
                :content="info.content"
              />
              <!--    END::Field    -->
            </b-col>
          </b-row>
          <!--    END::Contact Company Info    -->

          <!--    START::Contact Company Address    -->
          <div class="divider">
            <span class="divider-text">
              {{ $t('CompanyAddress') }}
            </span>
          </div>
          <b-row
            class="px-1"
            style="row-gap: 1.34rem"
          >
            <b-col
              v-for="(info, i) in companyAddress"
              :key="i"
              cols="12"
              md="6"
            >
              <!--    START::Field    -->
              <info-field
                :icon="info.icon"
                :title="info.title"
                :content="info.content"
              />
              <!--    END::Field    -->
            </b-col>
          </b-row>
          <!--    END::Contact Company Address    -->
        </template>
        <!--    END::Contact Company    -->
      </template>
    </app-collapse-item>
    <!--    END::Basic Information    -->

    <!--    START::Additional fields    -->
    <app-collapse-item
      class="mt-0"
      :is-visible="true"
    >
      <template #header>
        <div
          class="d-flex align-items-center"
          style="gap: .34rem"
        >
          <sw-icon
            icon="ListIcon"
            class="mt-n25"
          />

          <h6 class="mb-0">
            {{ $t('AdditionalFields') }}
          </h6>

          <b-button
            v-if="protectEditAdditionalFields && contact"
            variant="flat-primary"
            size="sm"
            class="p-25 mt-n25"
            @click.stop="mEditAdditionalField()"
          >
            {{ $t('Add') }}

            <sw-icon icon="PlusIcon" />
          </b-button>

          <b-spinner
            v-if="!contact"
            type="grow"
            small
            class="my-50 mx-auto"
            variant="primary"
          />
        </div>
      </template>

      <template v-if="contact">
        <b-row
          v-if="additionalFields.length"
          class="px-1"
          style="row-gap: 1.34rem"
        >
          <b-col
            v-for="(info, i) in additionalFields"
            :key="i"
            cols="12"
            md="6"
          >
            <!--    START::Field    -->
            <info-field
              :id="info.id"
              :item-key="info"
              icon="ListIcon"
              :title="info.contactAddonFieldType.name"
              :content="info.value || info.contactAddonFieldTypeOptions"
              :type="(info.contactAddonFieldType.type || '').toUpperCase()"
              :action-edit="protectEditAdditionalFields"
              :action-remove="protectEditAdditionalFields"
              @edit="mEditAdditionalField($event.key)"
              @remove="removeAdditionalField($event.key, i)"
            >
              <template
                v-if="
                  info.contactAddonFieldType.type === 'RADIAL'
                    || info.contactAddonFieldType.type === 'TEXT_ARRAY'
                    || info.contactAddonFieldType.type === 'TEXT_MULTIPLE_ARRAY'
                "
              >
                <template v-if="(info.value || info.contactAddonFieldTypeOptions).length">
                  {{ Array.isArray(info.value || info.contactAddonFieldTypeOptions) ? (info.value || info.contactAddonFieldTypeOptions).map(({name}) => name).toString() : (info.value || info.contactAddonFieldTypeOptions) }}
                </template>

                <sw-icon
                  v-else
                  icon="MinusIcon"
                />
              </template>

              <template v-if="info.contactAddonFieldType.type === 'TOOLTIP'">
                <template v-if="(info.contactAddonFieldTextOptions || []).length">
                  {{ Array.isArray(info.contactAddonFieldTextOptions) ? (info.contactAddonFieldTextOptions).join(', ') : (info.value || info.contactAddonFieldTypeOptions) }}
                </template>

                <sw-icon
                  v-else
                  icon="MinusIcon"
                />
              </template>
            </info-field>
            <!--    END::Field    -->
          </b-col>
        </b-row>

        <!--    START::No data    -->
        <div
          v-else
          class="text-center text-primary mt-n75"
        >
          <feather-icon icon="XCircleIcon" />
          {{ $t('NoData') }}
        </div>
        <!--    END::No data    -->
      </template>
    </app-collapse-item>
    <!--    END::Additional fields    -->

    <!--    START::Personal IDs    -->
    <app-collapse-item class="mt-0">
      <template #header>
        <div
          class="d-flex align-items-center"
          style="gap: .34rem"
        >
          <sw-icon
            icon="CreditCardIcon"
            class="mt-n25"
          />

          <h6 class="mb-0">
            {{ $t('contact.PersonIdentityDocuments') }}
          </h6>

          <b-spinner
            v-if="!contact"
            type="grow"
            small
            class="my-50 mx-auto"
            variant="primary"
          />
        </div>
      </template>

      <template v-if="contact">
        <b-table
          v-if="identityDocuments.length"
          :fields="documentsFields"
          :items="identityDocuments"
          class="px-1"
        >
          <!--    Head    -->
          <template #head()="{ label }">
            {{ $t(`${label}`) }}
          </template>

          <!--    Head    -->
          <template #cell(type)="{ value }">
            {{ $t(`contact.${value}`) }}
          </template>
          <!--    Other    -->

          <!--    Other    -->
          <template #cell()="row">
            <table-default-cell
              :field="row.field"
              :value="row.value"
            />
          </template>

          <!--      Table Busy      -->
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner
                class="align-middle"
                variant="primary"
              />
            </div>
          </template>
        </b-table>

        <!--    START::No data    -->
        <div
          v-else
          class="text-center text-primary mt-n75"
        >
          <feather-icon icon="XCircleIcon" />
          {{ $t('NoData') }}
        </div>
        <!--    END::No data    -->
      </template>
    </app-collapse-item>
    <!--    END::Personal IDs    -->

    <!--    START::Delivery Addresses    -->
    <app-collapse-item class="mt-0">
      <template #header>
        <div
          class="d-flex align-items-center"
          style="gap: .34rem"
        >
          <sw-icon
            icon="TruckIcon"
            class="mt-n25"
          />

          <h6 class="mb-0">
            {{ $t('DeliveryAddresses') }}
          </h6>

          <b-spinner
            v-if="!contact"
            type="grow"
            small
            class="my-50 mx-auto"
            variant="primary"
          />
        </div>
      </template>

      <template v-if="contact">
        <!--    START::Delivery Address Info    -->
        <b-row
          v-if="deliveryAddresses.length"
          class="px-1"
          style="row-gap: 1.34rem"
        >
          <b-col
            v-for="(info, i) in deliveryAddresses"
            :key="i"
            cols="12"
            md="6"
            lg="4"
          >
            <!--    START::Field    -->
            <info-field
              icon="GlobeIcon"
              :content="getAddress(info).content"
            />
            <!--    END::Field    -->
          </b-col>
        </b-row>
        <!--    END::Delivery Address Info    -->

        <!--    START::No data    -->
        <div
          v-else
          class="text-center text-primary mt-n75"
        >
          <feather-icon icon="XCircleIcon" />
          {{ $t('NoData') }}
        </div>
        <!--    END::No data    -->
      </template>
    </app-collapse-item>
    <!--    END::Delivery Addresses    -->
  </app-collapse>
</template>

<script>
import InfoField from '@/pages/contact/components/utils/InfoField.vue'
import { mapActions, mapGetters } from 'vuex'
import { contactPositionTypeByValue } from '@/@types/contactPositionTypes'
import i18n from '@/libs/i18n'
import { BTable } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  GET_CURRENT_CONTACT,
  GET_CURRENT_CONTACT_ADDITIONAL_FIELDS, GET_CURRENT_CONTACT_DELIVERY_ADDRESSES,
  GET_CURRENT_CONTACT_IDENTITY_DOCUMENTS, OPEN_MODAL, REMOVE_CURRENT_CONTACT_ADDITIONAL_FIELD,
} from '@/@constants/mutations'
import * as $roles from '@/helpers/permissions'
import EventBus from '@/event-bus'
import { eEditContactAddonField } from '@/@types/events'

export default {
  components: { BTable, InfoField },
  directives: {
    Ripple,
  },
  data: () => ({
    contact: null,
    additionalFields: [],
    identityDocuments: [],
    deliveryAddresses: [],
    documentsFields: [
      { key: 'type', label: 'task.Type', active: true },
      { key: 'number', label: 'OrganizationDetails.addressNumber', active: true },
      { key: 'authorityIssuing', label: 'contact.authorityIssuing', active: true },
      { key: 'releaseDate', label: 'contact.releaseDate', active: true },
      { key: 'expirationDate', label: 'contact.expirationDate', active: true },
    ],
  }),
  computed: {
    ...mapGetters({
      currentContact: 'contact/contact',
    }),
    protectEditAdditionalFields() {
      return this.checkRequiredPermissions([$roles.CONTACT_ADDON_FIELD_MODIFY])
    },
    basicInformation() {
      return [
        {
          icon: 'UserIcon',
          title: i18n.t('contact.firstName'),
          content: this.contact.firstName,
        },
        {
          icon: 'UserIcon',
          title: i18n.t('contact.familyName'),
          content: this.contact.familyName,
        },
        {
          icon: 'UserIcon',
          title: i18n.t('contact.lastName'),
          content: this.contact.lastName,
        },
        {
          icon: 'UserIcon',
          title: i18n.t('ContactPosition'),
          content: i18n.t(contactPositionTypeByValue(this.contact.position)),
        },
        {
          icon: 'UserIcon',
          title: i18n.t('contact.personalId'),
          content: this.contact.personalId,
        },
        {
          icon: 'PhoneIcon',
          title: i18n.t('Phone'),
          content: this.$options.filters.phoneFormat(this.contact.phone || this.contact.phoneNumberPrivate || ''),
        },
        {
          icon: 'PhoneIcon',
          title: i18n.t('contact.phoneNumbers'),
          content: this.contact.phoneNumbers.filter(({ phone }) => phone !== this.contact.phone).map(({ phone }) => this.$options.filters.phoneFormat(phone || '')).join(',<br>'),
        },
        {
          icon: 'MailIcon',
          title: i18n.t('Email'),
          content: this.contact.email || this.contact.mailAddressPrivate,
        },
        {
          icon: 'MailIcon',
          title: i18n.t('contact.mailAddresses'),
          content: this.contact.mailAddresses.filter(({ mail }) => mail !== this.contact.email).map(({ mail }) => mail).join(',<br>'),
        },
        {
          icon: 'UsersIcon',
          title: i18n.t('contact.maritalStatus'),
          content: this.contact.maritalStatus ? this.$i18n.t(`contact.${this.contact.maritalStatus}`) : '',
        },
        {
          icon: 'DollarSignIcon',
          title: i18n.t('contact.financialPosition'),
          content: this.contact.financialPosition ? this.$i18n.t(`contact.${this.contact.financialPosition}`) : '',
        },
      ]
    },
    primaryAddress() {
      return [
        {
          icon: 'HomeIcon',
          title: i18n.t('contact.addressCountry'),
          content: this.contact.addressCountry,
        },
        {
          icon: 'HomeIcon',
          title: i18n.t('contact.addressCity'),
          content: this.contact.addressCity,
        },
        {
          icon: 'HomeIcon',
          title: i18n.t('contact.addressStreet'),
          content: this.contact.addressStreet,
        },
        {
          icon: 'HomeIcon',
          title: i18n.t('contact.addressNumber'),
          content: this.contact.addressNumber,
        },
        {
          icon: 'HomeIcon',
          title: i18n.t('contact.addressPostalCode'),
          content: this.contact.addressPostalCode,
        },
      ]
    },
    correspondenceAddress() {
      return [
        {
          icon: 'MailIcon',
          title: i18n.t('contact.addressCountry'),
          content: this.contact.addressCorrespondenceCountry,
        },
        {
          icon: 'MailIcon',
          title: i18n.t('contact.addressCity'),
          content: this.contact.addressCorrespondenceCity,
        },
        {
          icon: 'MailIcon',
          title: i18n.t('contact.addressStreet'),
          content: this.contact.addressCorrespondenceStreet,
        },
        {
          icon: 'MailIcon',
          title: i18n.t('contact.addressNumber'),
          content: this.contact.addressCorrespondenceNumber,
        },
        {
          icon: 'MailIcon',
          title: i18n.t('contact.addressPostalCode'),
          content: this.contact.addressCorrespondencePostalCode,
        },
      ]
    },
    company() {
      return [
        {
          icon: 'BriefcaseIcon',
          title: i18n.t('Name'),
          content: this.contact?.organization?.name || '',
        },
        {
          icon: 'BriefcaseIcon',
          title: i18n.t('contact.vatId'),
          content: this.contact?.organization?.vatId || '',
        },
      ]
    },
    companyAddress() {
      return [
        {
          icon: 'GlobeIcon',
          title: i18n.t('contact.addressCountry'),
          content: this.contact.organization?.addressCountry || '',
        },
        {
          icon: 'GlobeIcon',
          title: i18n.t('contact.addressCity'),
          content: this.contact.organization?.addressCity || '',
        },
        {
          icon: 'GlobeIcon',
          title: i18n.t('contact.addressStreet'),
          content: this.contact.organization?.addressStreet || '',
        },
        {
          icon: 'GlobeIcon',
          title: i18n.t('contact.addressNumber'),
          content: this.contact.organization?.addressNumber || '',
        },
        {
          icon: 'GlobeIcon',
          title: i18n.t('contact.addressPostalCode'),
          content: this.contact.organization?.addressPostalCode || '',
        },
      ]
    },
  },
  watch: {
    async currentContact() {
      await Promise.all([
        this.loadContact(),
        this.loadAdditionalFields(),
        this.loadIdentityDocuments(),
        this.loadDeliveryAddresses(),
      ])
    },
  },
  async mounted() {
    EventBus.$on(eEditContactAddonField, this.handleEditContactAddonField)
    if (this.currentContact) {
      await Promise.all([
        this.loadContact(),
        this.loadAdditionalFields(),
        this.loadIdentityDocuments(),
        this.loadDeliveryAddresses(),
      ])
    }
  },
  destroyed() {
    EventBus.$off(eEditContactAddonField, this.handleEditContactAddonField)
  },
  methods: {
    ...mapActions({
      getContact: `contact/${GET_CURRENT_CONTACT}`,
      getContactAdditionalFields: `contact/${GET_CURRENT_CONTACT_ADDITIONAL_FIELDS}`,
      getContactIdentityDocuments: `contact/${GET_CURRENT_CONTACT_IDENTITY_DOCUMENTS}`,
      getContactDeliveryAddresses: `contact/${GET_CURRENT_CONTACT_DELIVERY_ADDRESSES}`,

      removeContactAdditionalFields: `contact/${REMOVE_CURRENT_CONTACT_ADDITIONAL_FIELD}`,
    }),
    contactPositionTypeByValue,

    getAddress(item) {
      const {
        city, postalCode, street, number, country, type,
      } = item

      let icon
      let title

      switch (type) {
        // Primary address
        case 'CONTACT_PRIMARY':
          icon = 'HomeIcon'
          title = 'PrimaryAddress'
          break

          // Correspondence address
        case 'CONTACT_CORRESPONDENCE':
          icon = 'MailIcon'
          title = 'AddressForCorrespondence'
          break

          // Other address
        default:
          icon = 'GlobeIcon'
          title = 'SecondaryAddress'
          break
      }

      return {
        icon,
        title,
        content: `${city} ${street} ${number}, ${postalCode} ${country}`,
      }
    },
    async loadContact() {
      this.loading = true

      try {
        this.contact = await this.getContact()
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }

      this.loading = false
    },
    async loadAdditionalFields() {
      this.loading = true

      try {
        this.additionalFields = await this.getContactAdditionalFields()
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }

      this.loading = false
    },
    async loadIdentityDocuments() {
      this.loading = true

      try {
        this.identityDocuments = await this.getContactIdentityDocuments()
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }

      this.loading = false
    },
    async loadDeliveryAddresses() {
      this.loading = true

      try {
        this.deliveryAddresses = await this.getContactDeliveryAddresses()
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }

      this.loading = false
    },
    mEditAdditionalField(field) {
      this.$store.commit(`modal/${OPEN_MODAL}`,
        {
          modalType: 'addAdditionalInformation',
          size: 'md',
          modalTitle: this.$i18n.t('Field'),
          okButtonTitle: '',
          contactId: this.contact.id,
          thread: this.process,
          data: {
            fieldList: this.additionalFields,
            field,
          },
        })
    },
    async removeAdditionalField(field, i) {
      try {
        await this.showAlert('warning', this.$i18n.t('alerts.AreYouSure'))
      } catch (err) { return }

      try {
        await this.removeContactAdditionalFields(field.id)

        this.additionalFields.splice(i, 1)
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    handleEditContactAddonField() {
      // if (response.id) {
      //   const editedItemIndex = this.additionalFields.findIndex(item => item.id === response.id)
      //   if (editedItemIndex > -1) {
      //     this.$set(this.additionalFields, editedItemIndex, response)
      //   } else {
      //     this.additionalFields.push(response)
      //   }
      // }
      this.loadAdditionalFields()
    },
  },
}
</script>
