import { OPEN_AI_CONFIG, OPEN_AI_LOG, OPEN_AI_REPORT_MESSAGE } from '@/@constants/fields'
// eslint-disable-next-line import/no-cycle
import axiosIns from '@/libs/axios'
import {
  CREATE_OPEN_AI_CONFIG,
  CREATE_OPEN_AI_REPORT_MESSAGE,
  GEN_OPENAI_RESPONSE_V3,
  GEN_OPENAI_RESPONSE_V35,
  GET_OPEN_AI_CONFIG,
  GET_OPEN_AI_CONFIG_ALL,
  GET_OPEN_AI_LOG_ALL,
  GET_OPEN_AI_REPORT_MESSAGE_ALL,
  REMOVE_OPEN_AI_REPORT_MESSAGE,
  TEST_OPEN_AI_CONFIG,
  TEST_OPEN_AI_LOG,
  TEST_OPEN_AI_REPORTS,
  UPDATE_OPEN_AI_CONFIG,
} from '@/@constants/mutations'

export default {
  namespaced: true,
  state: {
    promptRoot: 'Jesteś pomocnym zawsze uprzejmym asystentem i kontaktujesz się z klientem. Sformatuj swoją odpowiedź wyłącznie przy użyciu znaczników HTML5. Usuń z wygenerowanej wiadomości wszystko co mogło by obrazić drugą stronę.',

    styles: {
      FORMAL: 'Utrzymaj wygenerowaną wiadomość w grzecznym, formalnym tonie.',
      INFORMAL: 'Utrzymaj wygenerowaną wiadomość w grzecznym ale luźnym tonie, możesz dodać kilka emoticon.',
    },

    types: {
      sms: 'Sformatuj odpowiedź do wiadomości sms.',
      email: 'Sformatuj odpowiedź do wiadomości email.',
    },

    prompts: {
      sms: {
        REQUESTING_CONTACT: 'Przygotuj krótką wiadomość SMS, w której poprosisz klienta o szybki kontakt.',
        MEETING_PROPOSAL: 'Przygotuj krótką wiadomość SMS, w której zaprosisz klienta na spotkanie.',
        INFORMATION_ABOUT_SENDING_OFFER: 'Przygotuj krótką wiadomość SMS, w której poinformujesz klienta o nowej ofercie.',
      },
      email: {
        REQUESTING_CONTACT: `
          <p>Przygotuj krótką wiadomość Email, w której poprosisz klienta o szybki kontakt.</p>
          <br />
          <p>–––––––––––––––</p>
          <p>Pozdrawiam,</p>
          <p>{% loggedUser.firstName %} {% loggedUser.lastName %}</p>
          <p>tel.: {% loggedUser.phone %}</p>
          <p>e-mail: {% loggedUser.email %}</p>
        `,
        MEETING_PROPOSAL: `
          <p>Przygotuj krótką wiadomość Email, w której zaprosisz klienta na spotkanie.</p>
          <br />
          <p>–––––––––––––––</p>
          <p>Pozdrawiam,</p>
          <p>{% loggedUser.firstName %} {% loggedUser.lastName %}</p>
          <p>tel.: {% loggedUser.phone %}</p>
          <p>e-mail: {% loggedUser.email %}</p>
        `,
        INFORMATION_ABOUT_SENDING_OFFER: `
          <p>Przygotuj krótką wiadomość Email, w której poinformujesz klienta o nowej ofercie.</p>
          <br />
          <p>–––––––––––––––</p>
          <p>Pozdrawiam,</p>
          <p>{% loggedUser.firstName %} {% loggedUser.lastName %}</p>
          <p>tel.: {% loggedUser.phone %}</p>
          <p>e-mail: {% loggedUser.email %}</p>
        `,
      },
    },
  },
  getters: {
    getPromptRoot: state => state.promptRoot,
    getPrompts: state => state.prompts,
    'getPrompts.SMS': state => state.prompts.sms,
    'getPrompts.EMAIL': state => state.prompts.email,
    'getType.SMS': state => state.types.sms,
    'getType.EMAIL': state => state.types.email,
  },
  actions: {
    [GEN_OPENAI_RESPONSE_V3]: (state, payload) => new Promise((resolve, reject) => {
      const prompt = `${state.promptRoot} ${payload.content}`

      axiosIns.post('1/chatgpt/competions/3', { prompt, source: payload.source })
        .then(res => resolve(res.data.data))
        .catch(err => reject(err))
    }),

    [GEN_OPENAI_RESPONSE_V35]: ({ state }, {
      system, content, source, contactThread,
    }) => new Promise((resolve, reject) => {
      const messages = []

      messages.push(
        {
          role: 'system',
          content: `${state.promptRoot} ${system}`,
        },
        {
          role: 'user',
          content,
        },
      )

      const payload = {
        contactThread,
        messages,
        source,
      }

      axiosIns.post('1/chatgpt/competions/3.5', { ...payload })
        .then(res => {
          const { data } = res.data
          resolve({
            content: data.result.content,
            totalTokens: data.totalTokens,
          })
        })
        .catch(err => reject(err))
    }),

    [GET_OPEN_AI_CONFIG_ALL]: () => new Promise((resolve, reject) => {
      const params = {}
      params.fields_load = OPEN_AI_CONFIG
      params.orderBy = 'createdAt.asc'

      axiosIns.get('1/chatgpt/config', { params })
        .then(res => resolve(res.data.data.items.reverse()))
        .catch(err => reject(err))
    }),

    [GET_OPEN_AI_CONFIG]: (_, id) => new Promise((resolve, reject) => {
      const params = {}
      params.fields_load = OPEN_AI_CONFIG

      axiosIns.get(`1/chatgpt/config/${id}`, { params })
        .then(res => resolve(res.data.data.item))
        .catch(err => reject(err))
    }),

    [CREATE_OPEN_AI_CONFIG]: (_, payload) => new Promise((resolve, reject) => {
      axiosIns.post('1/chatgpt/config', payload)
        .then(res => resolve(res.data.data.items[0].id))
        .catch(err => reject(err))
    }),

    [UPDATE_OPEN_AI_CONFIG]: (_, payload) => new Promise((resolve, reject) => {
      axiosIns.patch('1/chatgpt/config', payload)
        .then(res => resolve(res.data.data.items[0].id))
        .catch(err => reject(err))
    }),

    [GET_OPEN_AI_LOG_ALL]: (_, { pagination }) => new Promise((resolve, reject) => {
      const params = {
        orderBy: 'id.desc',
      }
      params.fields_load = OPEN_AI_LOG

      if (pagination) {
        params.page = pagination.currentPage
        params.limit = pagination.perPage
      }

      axiosIns.get('1/chatgpt/logs', { params })
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    }),

    [CREATE_OPEN_AI_REPORT_MESSAGE]: (_, payload) => new Promise((resolve, reject) => {
      axiosIns.post('1/chatgpt/reports', payload)
        .then(res => resolve(res.data.data.items[0].id))
        .catch(err => reject(err))
    }),

    [REMOVE_OPEN_AI_REPORT_MESSAGE]: (_, id) => new Promise((resolve, reject) => {
      axiosIns.delete('1/chatgpt/reports', { data: [id] })
        .then(res => resolve(res))
        .catch(err => reject(err))
    }),

    [GET_OPEN_AI_REPORT_MESSAGE_ALL]: (_, { pagination }) => new Promise((resolve, reject) => {
      const params = {
        orderBy: 'id.desc',
      }
      params.fields_load = OPEN_AI_REPORT_MESSAGE
      params.orderBy = 'id.desc'

      if (pagination) {
        params.page = pagination.currentPage
        params.limit = pagination.perPage
      }

      axiosIns.get('1/chatgpt/reports', { params })
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    }),

    [TEST_OPEN_AI_CONFIG]: (_, level = 0) => new Promise((resolve, reject) => {
      const params = {}
      params.fields_info = level

      axiosIns.get('1/chatgpt/config', { params })
        .then(res => resolve(res.data.data))
        .catch(err => reject(err))
    }),

    [TEST_OPEN_AI_LOG]: (_, level = 0) => new Promise((resolve, reject) => {
      const params = {}
      params.fields_load = level

      axiosIns.get('1/chatgpt/logs', { params })
        .then(res => resolve(res.data.data))
        .catch(err => reject(err))
    }),

    [TEST_OPEN_AI_REPORTS]: (_, level = 0) => new Promise((resolve, reject) => {
      const params = {}
      params.fields_info = level

      axiosIns.get('1/chatgpt/reports', { params })
        .then(res => resolve(res.data.data))
        .catch(err => reject(err))
    }),
  },
}
