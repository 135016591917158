// eslint-disable-next-line import/no-cycle
import axiosIns from '@/libs/axios'
import { PROJECT_TASK_COMMENTS } from '@/@constants/fields'
import {
  CREATE_PROJECT_TASK_COMMENT,
  GET_PROJECT_TASK_COMMENT,
  GET_PROJECT_TASK_COMMENTS,
  TEST_PROJECT_TASK_COMMENT,
  UPDATE_PROJECT_TASK_COMMENT,
} from '@/@constants/mutations'

export default {
  namespaced: true,
  actions: {
    [GET_PROJECT_TASK_COMMENTS]: (_, { projectId, taskId }) => new Promise((resolve, reject) => {
      const params = {}
      params.fields_load = PROJECT_TASK_COMMENTS

      axiosIns.get(`1/projects/${projectId}/tasks/${taskId}/comments`, { params })
        .then(res => resolve(res.data.data.items))
        .catch(err => reject(err))
    }),

    [GET_PROJECT_TASK_COMMENT]: (_, { projectId, taskId, commentId }) => new Promise((resolve, reject) => {
      const params = {}
      params.fields_load = PROJECT_TASK_COMMENTS

      axiosIns.get(`1/projects/${projectId}/tasks/${taskId}/comments/${commentId}`, { params })
        .then(res => resolve(res.data.data.item))
        .catch(err => reject(err))
    }),

    [CREATE_PROJECT_TASK_COMMENT]: (_, { projectId, taskId, payload }) => new Promise((resolve, reject) => {
      axiosIns.post(`1/projects/${projectId}/tasks/${taskId}/comments`, payload)
        .then(res => resolve(res.data.data.items[0].id))
        .catch(err => reject(err))
    }),

    [UPDATE_PROJECT_TASK_COMMENT]: (_, { projectId, taskId, payload }) => new Promise((resolve, reject) => {
      axiosIns.patch(`1/projects/${projectId}/tasks/${taskId}/comments`, payload)
        .then(res => resolve(res.data.data.items[0].id))
        .catch(err => reject(err))
    }),

    [TEST_PROJECT_TASK_COMMENT]: (_, level = 0) => new Promise((resolve, reject) => {
      const params = {}
      params.fields_info = level

      axiosIns.get('1/projects/0/tasks/0/comments', { params })
        .then(res => resolve(res.data.data))
        .catch(err => reject(err))
    }),
  },
}
