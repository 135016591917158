<template>
  <validation-observer
    ref="newProjectForm"
    tag="form"
  >
    <b-row style="row-gap: .5rem">
      <!--      New Task: Title      -->
      <validation-provider
        v-slot="{ errors }"
        class="col-12 mb-n50"
        rules="required|max-input"
        :name="$t('Title')"
      >
        <b-form-group :label="$t('Title')">
          <b-form-input
            v-model="name"
            :disabled="!isEdit"
            :placeholder="$t('Title')"
            :state="errors.length > 0 ? false:null"
            @input="e => $emit('getTitle', e)"
          />

          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>

      <!--      Thread      -->
      <validation-provider
        v-slot="{ errors }"
        class="col-12"
        :name="$t('SelectContacts')"
      >
        <b-form-group>
          <sw-select
            :name="$t('SelectContacts')"
            :disabled="!isEdit"
            class="mb-0"
          >
            <v-select
              id="offer-thread-1"
              v-model="contactThreads"
              :options="contactThreadList"
              label="name"
              :filterable="false"
              :selectable="(thread) => thread.offersTotal < system.contactThreadOffersLimit"
              :state="errors.length > 0 ? false:null"
              multiple
              :placeholder="$t('SelectContacts')"
              :disabled="!isEdit"
              @search="loadThreads"
              @select="save"
            >
              <template #no-options="{ search }">
                <span v-if="search.length">
                  {{ $t('NoData') }}
                </span>
                <span v-else>
                  {{ $t('TypeToSearch') }}
                </span>
              </template>
              <template #option="{ name, contact, offersTotal }">
                <div class="d-flex align-items-center">
                  <avatar
                    :user="contact"
                    class="mr-25"
                  />  - {{ name }}
                  <feather-icon
                    v-if="offersTotal >= system.contactThreadOffersLimit"
                    v-b-tooltip
                    icon="AlertCircleIcon"
                    class="ml-50"
                    :title="$tc('ThreadOfferCountIsToBig', system.contactThreadOffersLimit, { limit: system.contactThreadOffersLimit })"
                  />
                </div>
              </template>

              <template #selected-option="{ name, contact }">
                <div class="d-flex">
                  <avatar
                    :user="contact"
                    class="mr-25"
                    text-variant="text-white"
                  /> – {{ name }}
                </div>
              </template>
            </v-select>
          </sw-select>

          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>

      <!--      User      -->
      <validation-provider
        v-slot="{ errors }"
        class="col-12 mt-n50"
        :name="$t('SelectUsers')"
      >
        <b-form-group>
          <sw-select
            :name="$t('SelectUsers')"
            :disabled="!isEdit"
            class="mb-0"
          >
            <v-select
              id="offer-thread-1"
              v-model="assignedUsers"
              :options="userList"
              label="firstName"
              :filterable="false"
              :state="errors.length > 0 ? false:null"
              multiple
              :placeholder="$t('SelectUsers')"
              :disabled="!isEdit"
              @search="loadUsers"
              @select="save"
            >
              <template #no-options="{ search }">
                <span v-if="search.length">
                  {{ $t('NoData') }}
                </span>
                <span v-else>
                  {{ $t('TypeToSearch') }}
                </span>
              </template>

              <template #option="{ firstName, lastName, avatar }">
                <div class="d-flex align-items-center">
                  <avatar
                    :user="{ firstName, lastName, avatar }"
                    class="mr-25"
                  />
                </div>
              </template>

              <template #selected-option="{ firstName, lastName, avatar }">
                <div class="d-flex">
                  <avatar
                    :user="{ firstName, lastName, avatar }"
                    class="mr-25"
                    text-variant="text-white"
                  />
                </div>
              </template>
            </v-select>
          </sw-select>

          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>

      <!--      Time      -->
      <div class="col-12 mt-n50">
        <!--    Task time -->
        <b-form-checkbox
          v-model="isWholeDay"
          variant="primary"
          :disabled="!isEdit"
          class="custom-control-primary"
        >
          {{ $t('WholeDay') }}
        </b-form-checkbox>

        <b-row class="mt-50">
          <b-col :md="isWholeDay ? 12 : 6">
            <sw-select :name="isWholeDay ? $t('ProjectTerm') : $t('ProjectStartTerm')">
              <sw-select-fpr
                :is-empty="startAt"
                @clear="() => {
                  startAt = ''

                  if (isWholeDay) endAt = ''
                }"
              >
                <flat-pickr
                  :key="`time_${isWholeDay}`"
                  v-model="startAt"
                  :disabled="!isEdit"
                  :placeholder="isWholeDay ? $t('ProjectTerm') : $t('ProjectStartTerm')"
                  class="form-control"
                  :config="{ ...flatPickerConfig, minDate: moment().format('YYYY.MM.DD hh:mm'), enableTime: true, locale: getCalendarLocale($i18n.locale), maxDate: endAt && !isWholeDay ? endAt : '', dateFormat: 'Y-m-d H:i' }"
                />
              </sw-select-fpr>
            </sw-select>
          </b-col>

          <b-col
            v-if="!isWholeDay"
            md="6"
          >
            <sw-select :name="isWholeDay ? $t('ProjectTerm') : $t('ProjectEndTerm')">
              <sw-select-fpr
                :is-empty="endAt"
                @clear="endAt = ''"
              >
                <flat-pickr
                  :key="`time_end_${isWholeDay}`"
                  v-model="endAt"
                  :disabled="!isEdit"
                  :placeholder="isWholeDay ? $t('ProjectTerm') : $t('ProjectEndTerm')"
                  class="form-control"
                  :config="{ ...flatPickerConfig, enableTime: !isWholeDay, locale: getCalendarLocale($i18n.locale), dateFormat: isWholeDay ? 'Y-m-d' : 'Y-m-d H:i', minDate: startAt ? startAt : moment().format('YYYY.MM.DD hh:mm') }"
                />
              </sw-select-fpr>
            </sw-select>
          </b-col>
        </b-row>
      </div>

      <!--      Description      -->
      <b-form-group
        class="col-12"
        :label="$t('note.Description')"
      >
        <b-form-textarea
          v-model="description"
          style="height: 30rem"
          :disabled="!isEdit"
          :placeholder="$t('note.Description')"
        />
      </b-form-group>

      <b-overlay
        no-wrap
        :show="isLoading || isMeLoading"
        spinner-variant="primary"
      />
    </b-row>
  </validation-observer>
</template>

<script>
import { Polish as pl } from '@/libs/i18n/locales/flatpickr/pl'
import { english as en } from '@/libs/i18n/locales/flatpickr/en'
import { BFormTextarea, VBTooltip } from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { THREADS_SEARCH, USERS_SEARCH } from '@/@constants/fields'
import moment from 'moment/moment'

export default {
  components: {
    BFormTextarea,
    flatPickr,
    vSelect,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    isEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
    project: {
      type: Object,
      required: true,
    },
    thread: {
      type: Object,
      required: false,
      default: undefined,
    },
  },
  emits: ['getTitle', 'save'],
  data: () => ({
    isMeLoading: false,

    isWholeDay: false,

    name: '',
    description: '',

    startAt: '',
    endAt: '',

    flatPickerConfig: {
      enableTime: true,
      dateFormat: 'Y-m-d H:i',
      time_24hr: true,
      locale: [pl, en],
    },

    contactThreadList: [],
    contactThreads: [],

    userList: [],
    assignedUsers: [],

    moment,
  }),
  computed: {
    locale() {
      return this.$i18n.locale
    },
    ...mapGetters({
      system: 'system/getSettings',
    }),
  },
  watch: {
    project: {
      deep: true,
      handler() {
        this.loadProject()
      },
    },

    isWholeDay: {
      deep: true,
      handler(n) {
        if (n) {
          this.startAt = this.startAt ? moment(this.startAt).format('YYYY-MM-DD HH:mm') : ''
          this.endAt = this.startAt ? moment(this.startAt, 'YYYY-MM-DD HH:mm').add('hours', 23.99).format('YYYY-MM-DD HH:mm') : ''
        }
      },
    },

    name() { this.save() },
    contactThreads() { this.save() },
    assignedUsers() { this.save() },
    startAt() { this.save() },
    endAt() { this.save() },
    description() { this.save() },
  },
  mounted() {
    this.ini()
  },
  methods: {
    ini() {
      if (!this.thread?.id) {
        this.loadThreads('', loading => { this.isMeLoading = loading })
      } else {
        this.loadThread(this.thread.id, loading => { this.isMeLoading = loading })
      }

      this.loadProject()
    },

    loadProject() {
      this.isMeLoading = true
      const { project } = this

      if (project.id) {
        this.name = project.name
        this.description = project.description
        this.contactThreads = project.contactThreads
        this.assignedUsers = project.assignedUsers
        this.startAt = (project.startAt?.date || project.startAt || '').slice(0, 16)
        this.endAt = (project.endAt?.date || project.endAt || '').slice(0, 16)
      }

      if (!this.isWholeDay) {
        this.isWholeDay = moment(this.endAt).diff(moment(this.startAt), 'days', true) === 1
          || Math.round(moment(this.endAt).diff(moment(this.startAt), 'hours', true) * 10) / 10 === 24
      }

      if (this.isEdit) this.$nextTick(() => this.$refs.newProjectForm.reset())
      this.isMeLoading = false
    },

    getPayload() {
      const payload = {
        name: this.name,
        description: this.description,
        startAt: this.startAt || '',
        endAt: this.endAt || '',
        contactThreads: this.contactThreads,
        assignedUsers: this.assignedUsers,
      }

      if (!this.isWholeDay) {
        this.isWholeDay = moment(this.endAt).diff(moment(this.startAt), 'days', true) === 1
          || Math.round(moment(this.endAt).diff(moment(this.startAt), 'hours', true) * 10) / 10 === 24
      }

      if (this.isWholeDay) {
        payload.startAt = payload.startAt ? moment(payload.startAt).format('YYYY-MM-DD HH:mm') : ''
        payload.endAt = payload.startAt ? moment(payload.startAt, 'YYYY-MM-DD HH:mm').add('hours', 23.99).format('YYYY-MM-DD HH:mm') : ''
      }

      if (this.project?.id) payload.id = this.project.id

      return payload
    },

    async loadThreads(search, isLoading) {
      this.contactThreadList = await this.selectSearch(search, isLoading, THREADS_SEARCH, 'name,contact-firstName,contact-lastName', '1/contacts/threads')
    },

    async loadThread(id, isLoading) {
      this.contactThreads = await this.selectSearch(String(id), isLoading, THREADS_SEARCH, 'id', '1/contacts/threads')

      this.$nextTick(() => this.$refs.newProjectForm.reset())
    },

    async loadUsers(search, isLoading) {
      this.userList = await this.selectSearch(search, isLoading, USERS_SEARCH, 'firstName,lastName', '1/users')
    },

    save() {
      if (this.isEdit) {
        if (this.isWholeDay) {
          this.startAt = this.startAt ? moment(this.startAt).format('YYYY-MM-DD HH:mm') : ''
          this.endAt = this.startAt ? moment(this.startAt, 'YYYY-MM-DD HH:mm').add('hours', 23.99).format('YYYY-MM-DD HH:mm') : ''
        }

        this.validationForm().then(() => {
          const payload = this.getPayload()

          if (payload) this.$emit('save', payload)
        })
      }
    },

    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.newProjectForm.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>
