// eslint-disable-next-line import/no-cycle
import axiosIns from '@/libs/axios'
import {
  CREATE_FILE,
  CREATE_FOLDER,
  GET_FILES,
  GET_FOLDERS,
  REMOVE_FILE,
  REMOVE_FOLDER,
  UPDATE_FILE,
  UPDATE_FOLDER,
} from '@/@constants/mutations'
import * as fields from '@/@constants/fields'
import { DOCUMENTS_FOLDERS } from '@/@constants/fields'

export default {
  namespaced: true,
  actions: {
    // eslint-disable-next-line no-unused-vars
    [GET_FOLDERS]: ({ commit }, { documentsType, pagination }) => new Promise((resolve, reject) => {
      const params = {
        fields_load: DOCUMENTS_FOLDERS,
      }

      if (documentsType) {
        params.eq_type = documentsType
      }

      if (pagination) {
        params.page = pagination.currentPage
        params.limit = pagination.perPage
      }

      axiosIns.get('1/documents/folders', { params })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),

    // eslint-disable-next-line no-unused-vars
    [GET_FILES]: ({ commit }, { folderId, payload }) => new Promise((resolve, reject) => {
      const { sortBy = 'name', sortDesc = 'desc' } = payload
      const params = {
        fields_load: fields.DOCUMENTS_FOLDERS_FILES,
      }

      params.orderBy = 'name.asc'

      if (sortBy) {
        params.orderBy = `${sortBy}.${sortDesc ? 'desc' : 'asc'}`
      }

      axiosIns.get(`1/documents/folders/${folderId}/files`, { params })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),

    // eslint-disable-next-line no-unused-vars
    [CREATE_FOLDER]: ({ dispatch }, payload) => new Promise((resolve, reject) => {
      axiosIns.post('1/documents/folders', payload)
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),

    // eslint-disable-next-line no-unused-vars
    [UPDATE_FOLDER]: ({ commit }, payload) => new Promise((resolve, reject) => {
      axiosIns.patch('1/documents/folders', payload)
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),

    // eslint-disable-next-line no-unused-vars
    [REMOVE_FOLDER]: ({ commit }, payload) => new Promise((resolve, reject) => {
      axiosIns.delete('1/documents/folders', { data: [payload] })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [CREATE_FILE]: ({ dispatch }, { folderId, file }) => new Promise((resolve, reject) => {
      axiosIns.post(`1/documents/folders/${folderId}/files`, file)
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),

    // eslint-disable-next-line no-unused-vars
    [UPDATE_FILE]: ({ commit }, { folderId, file }) => new Promise((resolve, reject) => {
      axiosIns.patch(`1/documents/folders/${folderId}/files`, file)
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),

    // eslint-disable-next-line no-unused-vars
    [REMOVE_FILE]: ({ commit }, { folderId, fileId }) => new Promise((resolve, reject) => {
      axiosIns.delete(`1/documents/folders/${folderId}/files`, { data: [fileId] })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
  },
}
