<template>
  <div>
    <div
      v-if="!loading"
    >
      <div>
        <b-form
          ref="form"
          class="repeater-form"
        >
          <!-- Row Loop -->
          <draggable v-model="contactStatuses">
            <b-row
              v-for="(status, index) in contactStatuses"
              :id="`status_${index}`"
              :key="`status_${index}`"
              ref="row"
              class="cursor-pointer"
            >

              <!-- Item Name -->
              <b-col md="4">
                <div class="d-flex align-items-center">
                  <feather-icon
                    icon="MoveIcon"
                    class="text-primary mr-75"
                    size="18"
                  />
                  <b-form-group
                    :label="$t('Name')"
                    label-for="status-name"
                    class="w-100 flex-grow-1"
                  >
                    <b-form-input
                      id="status-name"
                      v-model="status.name"
                      type="text"
                      size="sm"
                    />
                  </b-form-group>
                </div>
              </b-col>

              <b-col md="2">
                <label>{{ $t('AmountOfDaysInStatus') }}</label>
                <div>
                  <b-form-input
                    v-model="status.maxDurationStay"
                    size="sm"
                    type="text"
                  >
                    {{ status.maxDurationStay }}
                  </b-form-input>
                </div>
              </b-col>

              <!-- Profession -->
              <b-col
                lg="2"
                md="1"
                class="mt-1 mt-md-0"
              >
                <b-form-group
                  :label="$t('Color')"
                  label-for="color"
                >
                  <b-form-input
                    id="color"
                    v-model="status.color"
                    type="color"
                    size="sm"
                  />
                </b-form-group>
              </b-col>

              <!-- Remove Button -->
              <b-col
                lg="2"
                md="3"
                class="mb-50"
              >
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="mt-0 mt-md-2"
                  size="sm"
                  @click="removeItem(status.id, index)"
                >
                  <feather-icon
                    icon="XIcon"
                    class="mr-25"
                  />
                  <span>{{ deleteContactStatuses.includes(status.id ? status.id.toString() : status.id) ? $t('Cancel') : $t('Delete') }}</span>
                </b-button>
              </b-col>
              <b-col cols="12">
                <hr>
              </b-col>
            </b-row>
          </draggable>

        </b-form>
      </div>
      <b-button
        variant="success"
        class="mr-1"
        size="sm"
        @click="onSaveForm"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-25"
        />
        <span>{{ $t('Save') }}</span>
      </b-button>
      <b-button
        variant="primary"
        size="sm"
        @click="addField"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-25"
        />
        <span>{{ $t('Add') }}</span>
      </b-button>
    </div>
    <div
      v-else
      class="d-flex justify-content-center"
    >
      <b-spinner
        variant="primary"
        class="m-5"
      />
    </div>
  </div>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BRow, BCol, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'
import { DELETE_CONTACT_STATUSES, GET_CONTACT_STATUSES, SAVE_CONTACT_STATUSES } from '@/@constants/mutations'
import draggable from 'vuedraggable'

export default {
  name: 'FunnelStatuses',
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    draggable,
  },
  directives: {
    Ripple,
  },
  data: () => ({
    loading: false,
    areChangesSaved: true,
    contactStatuses: [],
  }),
  computed: {
    ...mapGetters({
      deleteContactStatuses: 'typeSettings/getDeleteContactStatuses',
    }),
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.loading = true
      this.$store.dispatch(`typeSettings/${GET_CONTACT_STATUSES}`)
        .then(res => {
          this.contactStatuses = res.data.items
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
        .finally(() => {
          this.loading = false
        })
    },
    addField() {
      const fieldData = {
        id: null, name: '', color: '#7367f0', maxDurationStay: 0,
      }
      this.contactStatuses.push(fieldData)
      this.areChangesSaved = false
    },
    removeItem(id, index) {
      if (id) this.$store.commit('typeSettings/ADD_DELETE_CONTACT_STATUSES', id)
      else this.contactStatuses.splice(index, 1)
      this.areChangesSaved = false
    },
    onInputChange(index, field, event) {
      this.contactStatuses[index][field] = event.target.value
      this.areChangesSaved = false
    },
    onSaveForm() {
      this.loading = true
      this.areChangesSaved = true
      const statuses = []
      this.contactStatuses.forEach((status, index) => {
        const {
          color, id, name, maxDurationStay,
        } = status
        if (!this.deleteContactStatuses.includes(id ? id.toString() : id)) {
          if (id && name) {
            statuses.push({
              // eslint-disable-next-line radix
              color, id: id.toString(), name, maxDurationStay: parseInt(maxDurationStay), position: index,
            })
          } else if (name) {
            statuses.push({
              // eslint-disable-next-line radix
              color, name, maxDurationStay: parseInt(maxDurationStay), position: index,
            })
          }
        }
      })

      if (this.deleteContactStatuses.length) {
        this.$store.dispatch(`typeSettings/${DELETE_CONTACT_STATUSES}`, this.deleteContactStatuses)
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          })
      }

      this.$store.dispatch(`typeSettings/${SAVE_CONTACT_STATUSES}`, statuses)
        .then(() => {
          this.loadData()
          this.showToast('success', this.$i18n.t('success.contactUpdated'))
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },

}
</script>

<style scoped>

</style>
