<template>
  <b-row>
    <b-col>
      <transition
        name="slide-fade"
        mode="out-in"
      >
        <contact-generator-list
          v-if="!selectedContract"
          key="list"
        />
        <contact-generator-details
          v-else
          key="details"
        />
      </transition>
    </b-col>
  </b-row>
</template>

<script>
import ContactGeneratorList from '@/views/core/contactGenerator/ContactGeneratorList.vue'
import ContactGeneratorDetails from '@/views/core/contactGenerator/ContactGeneratorDetails.vue'

export default {
  name: 'ContactsGenerator',
  components: {
    ContactGeneratorList,
    ContactGeneratorDetails,
  },
  computed: {
    selectedContract() {
      return this.$route.params?.contactId
    },
  },
}
</script>

<style scoped>

</style>
