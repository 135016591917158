<template>
  <div class="oversize-lg-modal">
    <b-modal
      :visible="show"
      size="xl"
      hide-footer
      @hidden="closeModal"
    >
      <b-row>
        <b-col sm="12">
          <b-alert
            show
            variant="primary"
          >
            <div class="alert-body">
              <div>
                <feather-icon icon="AlertCircleIcon" />
                Pierwszy wiersz pliku XLS powinien zostać wypałniony nagłówkami, są one wymagane w celu spójności pobieranych danych. Nagłówki te nie biorą udziału w procesie importu!
              </div>
              <div>
                <feather-icon icon="AlertCircleIcon" />
                W celu utworzenia produktu pierwsza kolumna (ID) powinna pozostać pusta
              </div>
              <div>
                <feather-icon icon="AlertCircleIcon" />
                Nazwa kategorii oraz podkategorii powinna być spójna z nazwą zdefiniowaną w systemie, w przeciwnym wypadku zostanie utworzona nowa kategoria
              </div>
              <div>
                <feather-icon icon="AlertCircleIcon" />
                Każdy atrybut składa się z 5 kolumn (ID, typu, nazwy, wartości oraz widoczności na stronie oferty). W celu utworzenia nowego atrybutu kolumna (ID) powinna pozostać pusta, pola (typ, nazwa) są polami definiowanymi w ustawieniach systemu zarówno nazwa jak i typ powinny być identyczne do już zdefiniowanych. Wartość atrybutu zależna jest od jego typu w przypadku list wyboru wartości powinny być oddzielone przecinkami
              </div>
              <div>
                <feather-icon icon="AlertCircleIcon" />
                W polu stanu magazynowego określa się wartość, która zostanie dopisana do aktualnego stanu
              </div>
              <div>
                <feather-icon icon="AlertCircleIcon" />
                Wartość w polu zdjęć powinna zostać wypełniona nazwami zdjęć oraz ich rozszerzeniami (example.png), które powinny zostać zaimportowane. Zdjęcia należy zapisać po przecinku w celu dodania kilku, zostaną one dodane do listy aktualnych zdjęć zdefiniowanych w produkcie.
              </div>
              <div>
                <feather-icon icon="AlertCircleIcon" />
                Wykorzystane zdjęcia należy przesłać w pole widoczne na samym dole tabeli podglądowej, po prawidłowym wczytaniu pliku XLS
              </div>
            </div>
          </b-alert>
        </b-col>
        <!-- Uploader -->
        <b-col sm="12">
          <drag-drop-upload
            tname="xlsxFile"
            :disabled="loading || uploadLoading"
            :show-files="false"
            @onChangeFiles="changeFiles"
          >
            <div
              v-if="uploadLoading"
              style="z-index: 999999"
              class="d-flex align-content-center align-items-center justify-content-center"
            >
              <b-spinner
                variant="primary"
                class="mr-25"
              />
              {{ uploadProcess }}
            </div>
          </drag-drop-upload>
        </b-col>
      </b-row>

      <b-row
        v-if="productsCount"
        class="mt-1"
      >
        <b-col sm="12">
          <div class="d-flex justify-content-between align-items-center mb-1">
            <div>
              {{ $t('ContactsFromFile') }}
              <b-badge>{{ productsCount }}</b-badge>
            </div>
          </div>
        </b-col>
        <b-col
          sm="12"
          class="table-padding-x-0"
        >
          <div class="d-flex justify-content-between">
            <sw-select class="ml-50">
              <v-select
                v-model="pagination.perPage"
                :options="[25, 50, 100, 250]"
                style="max-width: 100px"
                :clearable="false"
                class="per-page-selector w-100"
              />
            </sw-select>
            <!-- Pagination -->
            <b-pagination
              v-model="pagination.currentPage"
              :total-rows="pagination.totalRecords"
              :per-page="pagination.perPage"
              align="center"
            />
          </div>
          <validation-observer
            ref="validator"
            tag="div"
          >
            <b-table
              :items="importedProducts"
              :fields="fields"
              responsive
              hover
              show-empty
              :total="pagination.totalRecords"
              :current-page="pagination.currentPage"
              :per-page="pagination.perPage"
            >
              <template #head()="{ label }">
                {{ $t(`${label}`) }}
              </template>

              <!--    Table Product Name    -->
              <template #cell(name)="{ item }">
                <div v-if="item.translations && item.translations.length">
                  <router-link
                    v-b-tooltip.hover.left
                    :to="{ name: 'productView', params: { productId: item.id } }"

                    :title="item.translations[0].name"
                  >
                    <span class="doubleLineText">
                      {{ item.translations[0].name | truncate(26, '...') }}
                    </span>
                  </router-link>
                </div>
              </template>
              <!--    Table Product Category    -->
              <template #cell(category)="{ item }">
                <div v-if="item.shopCategories && item.shopCategories.length && item.shopCategories[0] && item.shopCategories[0].translations">
                  <span
                    v-b-tooltip.hover.left

                    :title="item.shopCategories[0].translations[0].name"
                    class="doubleLineText"
                  >
                    {{ item.shopCategories[0].translations[0].name | truncate(26, '...') }}
                  </span>
                </div>
              </template>

              <!--    Table Product Subcategory    -->
              <template #cell(subcategory)="{ item }">
                <div v-if="item.shopCategories && item.shopCategories.length && item.shopCategories[1] && item.shopCategories[1].translations">
                  <span
                    v-b-tooltip.hover.left

                    :title="item.shopCategories[1].translations[0].name"
                    class="doubleLineText"
                  >
                    {{ item.shopCategories[1].translations[0].name | truncate(26, '...') }}
                  </span>
                </div>
              </template>
              <template #cell(vat)="{ item }">
                {{ item.vat }}%
              </template>
              <template #cell(offerPrice)="{ value }">
                {{ value || 0 | priceFormat }} {{ $store.getters['system/getSettings'] ? ($store.getters['system/getSettings'].defaultCurrency || 'PLN') : 'PLN' }}
              </template>
              <template #cell(active)="{ item }">
                <span class="d-flex justify-content-center">
                  <b-badge
                    v-if="item.active"
                    v-b-tooltip.hover.v-success
                    variant="light-success"
                    pill
                    :title="$t('Active')"
                  >
                    <feather-icon icon="CheckIcon" />
                  </b-badge>
                  <b-badge
                    v-else
                    v-b-tooltip.hover.v-danger
                    variant="light-danger"
                    pill
                    :title="$t('InActive')"
                  >
                    <feather-icon icon="MinusIcon" />
                  </b-badge>
                </span>
              </template>
              <template #cell(attributes)="row">
                <b-button
                  size="sm"
                  class="mr-2 btn-icon"
                  variant="outline-primary"
                  @click="row.toggleDetails"
                >
                  <feather-icon :icon="row.detailsShowing ? 'MinusIcon' : 'PlusIcon'" />
                </b-button>
              </template>

              <!-- Subcategory Section -->
              <template #row-details="{ item, index }">
                <div v-if="item.attributes">
                  <b-list-group flush>
                    <b-list-group-item
                      v-for="(attribute, key) in item.attributes"
                      :key="`${key}_${index}`"
                    >
                      <b-badge
                        v-if="!attribute.id"
                        variant="light-warning"
                        class="mr-25"
                      >
                        {{ $t('New') }}
                      </b-badge>
                      <b-badge
                        variant="light-primary"
                        class="mr-25"
                      >
                        {{ $t(`offer.attribute.types.${attribute.type}`) }}
                      </b-badge>
                      {{ attribute.name }} (<strong>{{ attribute.value }}</strong>)
                    </b-list-group-item>
                  </b-list-group>
                </div>
              </template>
              <!--    Other    -->
              <!--    Other    -->
              <template #cell()="row">
                <table-default-cell
                  :field="row.field"
                  :value="row.value"
                />
              </template>

              <!--      Empty      -->
              <template #empty>
                <empty-content />
              </template>

              <!--      Table Busy      -->
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner
                    class="align-middle"
                    variant="primary"
                  />
                </div>
              </template>
            </b-table>
          </validation-observer>

          <div class="d-flex justify-content-between">
            <sw-select class="ml-50">
              <v-select
                v-model="pagination.perPage"
                :options="[25, 50, 100, 250]"
                style="max-width: 100px"
                :clearable="false"
                class="per-page-selector w-100"
              />
            </sw-select>
            <!-- Pagination -->
            <b-pagination
              v-model="pagination.currentPage"
              :total-rows="pagination.totalRecords"
              :per-page="pagination.perPage"
              align="center"
            />
          </div>
        </b-col>
        <b-col
          sm="12"
          class="my-50"
        >
          Zdjęcia użyte w pliku XLS
          <drag-drop-upload
            tname="imagesPack"
            :disabled="loading"
            :show-files="true"
            accept="image/*"
            :multiple="true"
            @onChangeFiles="changeImages"
          />
        </b-col>
        <b-col sm="12">
          <b-button
            :disabled="loading"
            size="sm"
            variant="primary"
            class="mr-25"
            @click="saveImportedProducts"
          >
            Import
          </b-button>
          <b-button
            size="sm"
            variant="outline-secondary"
            @click="closeModal"
          >
            {{ $t('Cancel') }}
          </b-button>
        </b-col>
      </b-row>
      <b-overlay
        no-wrap
        :show="loading"
        spinner-variant="primary"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BAlert, BModal, BTable, VBTooltip,
} from 'bootstrap-vue'
import XLSX from 'xlsx'
import {
  GET_ATTRIBUTE,
  GET_CATEGORY,
  GET_CONTACT_STATUSES,
  GET_GROUPS,
  GET_PICTURE_TYPE,
  SAVE_ATTRIBUTE,
  SAVE_CATEGORY,
  UPLOAD_FILES,
} from '@/@constants/mutations'
import vSelect from 'vue-select'
import { ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import DragDropUpload from '@/views/components/DragDropUpload.vue'
import axiosIns from '@/libs/axios'
import { formatDate } from '@fullcalendar/core'

export default {
  name: 'ImportProductsModal',
  components: {
    BTable,
    BAlert,
    BModal,
    vSelect,
    DragDropUpload,
    ValidationObserver,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    show: {
      default: true,
      type: Boolean,
    },
  },
  data: () => ({
    required,
    fields: [
      { key: 'id', label: '#' },
      { key: 'category', label: 'Offer.Category' },
      { key: 'subcategory', label: 'Offer.UnderCategory' },
      { key: 'attributes', label: 'offer.product.constantAttributes' },
      { key: 'name', label: 'Name' },
      { key: 'active', label: 'offer.product.active' },
      { key: 'code', label: 'offer.product.code' },
      { key: 'offerPrice', label: 'Offer.NetPrice' },
      { key: 'vat', label: 'Vat' },
      { key: 'webUrl', label: 'OutsideShopUrl' },
      { key: 'storageDiff', label: 'CurrentStorageAmount' },
      { key: 'images', label: 'offer.product.pictures' },
    ],
    uploadLoading: false,
    uploadProcess: 'Przetwarzanie pliku',
    importedProducts: [],
    productsCount: 0,
    loading: false,
    sorter: {
      sortBy: 'createdAt',
      sortDesc: true,
    },
    pagination: {
      perPage: 100,
      currentPage: 1,
      totalRecords: 0,
    },
    images: [],
    categories: [],
    groups: [],
    productPictureTypes: [],
    attributes: [],
  }),
  watch: {
    importedProducts: {
      deep: true,
      handler(newValue, oldValue) {
        if (!newValue.length && oldValue.length) {
          this.importedProducts = oldValue
        }
      },
    },
  },
  async mounted() {
    await Promise.all([this.loadCategories(), this.loadGroups(), this.loadAttributes(), this.loadProductPictureTypes()])
  },
  methods: {
    formatDate,
    // Init Data
    loadProductPictureTypes() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(`productPictures/${GET_PICTURE_TYPE}`)
          .then(res => {
            this.productPictureTypes = res.data.items
            resolve()
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            reject(err)
          })
      })
    },
    loadCategories() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(`categories/${GET_CATEGORY}`)
          .then(res => {
            this.categories = res.data.items
            resolve()
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            reject(err)
          })
      })
    },
    loadGroups() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(`groups/${GET_GROUPS}`)
          .then(res => {
            this.groups = res.data.items
            resolve()
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            reject(err)
          })
      })
    },
    loadAttributes() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(`attributes/${GET_ATTRIBUTE}`)
          .then(res => {
            this.attributes = res.data.items?.filter(item => item.active) || []

            resolve()
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            reject(err)
          })
      })
    },

    closeModal() {
      this.importedProducts = []
      this.productsCount = 0
      this.uploadProcess = 'Przetwarzanie pliku'
      this.loading = false
      this.uploadLoading = false
      this.$emit('close-modal')
    },
    changeFiles(files) {
      if (files.length) this.readFile(files[0])
    },
    changeImages(files) {
      if (files.length) {
        this.images = files
      }
    },
    readFile(file) {
      this.uploadProcess = 'Przetwarzanie pliku'
      this.uploadLoading = true

      setTimeout(() => {
        const reader = new FileReader()
        reader.onload = e => {
          const ab = e.target.result
          const wb = XLSX.read(new Uint8Array(ab), { type: 'array' })
          const wsname = wb.SheetNames[0]
          const ws = wb.Sheets[wsname]

          const data = XLSX.utils.sheet_to_json(ws, { blankrows: false, header: 1 })

          this.prepareContactsFromXLS(data)
        }
        reader.readAsArrayBuffer(file)
      }, 1000)
    },
    async prepareContactsFromXLS(rows = []) {
      rows.shift()
      this.uploadProcess = 'Pobieranie danych'
      const products = []

      const rowsChunks = rows.splitIntoChunks(100)
      // eslint-disable-next-line
      for (const row of rowsChunks) {
        // eslint-disable-next-line
        for (const productRow of row) {
          const attributesChunk = (productRow || []).slice(13).reduce((chunks, item, index) => {
            const chunkIndex = Math.floor(index / 5)
            // eslint-disable-next-line
            chunks[chunkIndex] = chunks[chunkIndex] || []
            chunks[chunkIndex].push(item)
            return chunks
          }, [])

          /* eslint-disable */
          const attributes = attributesChunk.map(attributeChunk => {
            const start = attributeChunk.length === 5 ? 0 : 1
            const attribute = {
              type: this.getKeyByValue(this.$i18n.messages[this.$i18n.locale].offer?.attribute?.types, attributeChunk[1 - start] ? String(attributeChunk[1 - start]).trim() : ''),
              name: attributeChunk[2 - start] ? attributeChunk[2 - start].trim() : '' || '',
              value: attributeChunk[3 - start] ? (
                isNaN(attributeChunk[3 - start]) ? attributeChunk[3 - start] : Number.parseFloat(attributeChunk[3 - start]).toFixed(2)
              ) : '' || '',
              visibleOfferWWW: attributeChunk[4 - start],
            }

            if (attributeChunk.length === 5) {
              attribute.id = attributeChunk[0] ? String(attributeChunk[0]).trim() : '' || ''
            }

            const shopAttribute = this.attributes.find(shopAttributeItem => {
              if (shopAttributeItem.type === attribute.type && this.getTranslationsField(shopAttributeItem, 'name').toLowerCase().trim() === attribute.name.toLowerCase().trim()) {
                return shopAttributeItem
              }

              return null
            })

            if (shopAttribute) {
              attribute.shopAttributeId = shopAttribute.id
            }

            return attribute
          })

            const part3 = productRow[3] ? (
                isNaN(productRow[3]) ? productRow[3] : Number.parseFloat(productRow[3]).toFixed(2)
            ) : '' || ''

          const product = {
            id: productRow[0],
            category: productRow[1],
            subCategory: productRow[2],
            name: `${part3} ${productRow[4]}` || '',
            description: productRow[5],
            active: Boolean(productRow[6]),
            code: productRow[7],
            offerPrice: productRow[8],
            vat: productRow[9],
            webUrl: productRow[10],
            storageSum: productRow[11],
            images: productRow[12],
            attributes,
            showDetails: false,
          }

          products.push(product)
        }
      }
      this.uploadProcess = 'Pobieranie danych'

      this.pagination.totalRecords = products.length
      await this.assignExistedProducts(products)
    },
    async assignExistedProducts(productList = []) {
      this.productsCount = productList.length

      const idsChunks = productList.map(product => product?.id).filter(Boolean).splitIntoChunks(200)

      const params = { fields_load: this.$fields.PRODUCTS_EXPORT }

      const responses = await Promise.all(idsChunks.map(chunk => axiosIns.get('1/shops/products', { params: { ...params, eqArr_id: JSON.stringify(chunk) } })))
      const products = responses.flatMap(p => p?.data?.data?.items)

      const assignedProductList = productList.map(importedProduct => {
        if (!importedProduct.id) {
          // Process for non existed product
          const product = {
            offerPrice: importedProduct.offerPrice,
            translations: [{ name: importedProduct.name, locale: 'pl_pl', description: importedProduct.description }],
            shopCategories: [
              { translations: [{ name: importedProduct.category?.trim() || '', locale: 'pl_pl' }], children: [] },
              { translations: [{ name: importedProduct.subCategory?.trim() || '', locale: 'pl_pl' }] },
            ],
            active: Boolean(importedProduct.active),
            code: importedProduct.code,
            vat: importedProduct.vat,
            webUrl: importedProduct.webUrl,
            storageDiff: importedProduct.storageSum,
            attributes: importedProduct.attributes,
            images: importedProduct.images,
            shopProductStorages: [{
              amount: Number(importedProduct.storageSum || 0),
              note: 'Import',
            }],
          }

          return product
        }

        const existedProduct = products.find(existedProductItem => existedProductItem.id === importedProduct.id)

        if (!existedProduct) return null

        const assignedProduct = {
          ...existedProduct,
          offerPrice: importedProduct.offerPrice,
          translations: [{ name: importedProduct.name, locale: 'pl_pl', description: importedProduct.description }],
          shopCategories: [
            null,
            null,
          ],
          active: Boolean(importedProduct.active),
          code: importedProduct.code,
          vat: importedProduct.vat,
          webUrl: importedProduct.webUrl,
          storageDiff: importedProduct.storageSum,
          attributes: importedProduct.attributes,
          images: importedProduct.images,
          shopProductStorages: [...existedProduct.shopProductStorages, { amount: Number(importedProduct.storageSum || 0), note: 'Import' }],
        }

        if (existedProduct.shopCategories.length >= 1 && this.getTranslationsField(existedProduct.shopCategories[0], 'name') === importedProduct.category) {
          // eslint-disable-next-line prefer-destructuring
          assignedProduct.shopCategories[0] = { ...existedProduct.shopCategories[0], action: 'UPDATE', type: 'CATEGORY' }
        } else {
          assignedProduct.shopCategories[0] = {
            translations: [{ name: importedProduct.category, locale: 'pl_pl' }], type: 'CATEGORY', action: 'CREATE', children: [],
          }
        }

        if (existedProduct.shopCategories.length > 1 && this.getTranslationsField(existedProduct.shopCategories[1], 'name') === importedProduct.subCategory) {
          // eslint-disable-next-line prefer-destructuring
          assignedProduct.shopCategories[1] = { ...existedProduct.shopCategories[1], action: 'UPDATE', type: 'SUB_CATEGORY' }
        } else {
          assignedProduct.shopCategories[1] = { translations: [{ name: importedProduct.subCategory?.trim() || '', locale: 'pl_pl' }], type: 'SUB_CATEGORY', action: 'CREATE' }
        }

        if (assignedProduct.translations?.length && assignedProduct.translations[0].name) {
          assignedProduct.translations[0].name = importedProduct.name.trim()
        }

        return assignedProduct
      })
      this.uploadProcess = 'Renderowanie widoku'

      setTimeout(() => {
        this.importedProducts = assignedProductList.filter(Boolean)
        this.uploadLoading = false
      }, 1000)
    },
    async loadContactStatuses() {
      this.loading = true

      try {
        const { data } = await this.$store.dispatch(`typeSettings/${GET_CONTACT_STATUSES}`)
        this.contactStatuses = data.items
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.loading = false
      }
    },
    /* eslint-disable */
    async saveImportedProducts() {
        this.loading = true
        // Wszystkie shopAttribute które nie istnieją
        const shopAttributesToUpdate = []
        const shopAttributesToCreate = []

        const categoriesToCreate = {}

        this.importedProducts.forEach((product, productIndex) => {
            // Categories
            let category = null
            if (product.shopCategories.length >= 1 && product.shopCategories[0]) {
                const name = (this.getTranslationsField(product.shopCategories[0], 'name') || '')
                category = (this.categories.find(cat => this.getTranslationsField(cat, 'name') || '') === name)

                if (category && !categoriesToCreate[name]) {
                    categoriesToCreate[name] = { ...category, newChildren: [] }
                } else if (!category && !categoriesToCreate[name]) {
                    categoriesToCreate[name] = { ...product.shopCategories[0], newChildren: [] }
                }
            }

            if (product.shopCategories.length > 1 && product.shopCategories[1]) {
                const categoryName = (this.getTranslationsField(product.shopCategories[0], 'name') || '')
                const subcategoryName = (this.getTranslationsField(product.shopCategories[1], 'name') || '')

                if (categoriesToCreate[categoryName]) {
                    // Jest
                    if (!categoriesToCreate[categoryName].children?.find(child => this.getTranslationsField(child, 'name') === subcategoryName)) {
                      categoriesToCreate[categoryName].newChildren.push(product.shopCategories[1])
                    }
                } else {
                    category = this.categories?.find(cat => this.getTranslationsField(cat, 'name') === categoryName.trim())
                    categoriesToCreate[categoryName] = { ...category, newChildren: [product.shopCategories[1]] }
                }
            }

            // attributes
            product.attributes.forEach(attribute => {
                const existAttribute = this.attributes.find(createdAttribute => this.getTranslationsField(createdAttribute, 'name') === attribute.name)

                if (!existAttribute) {
                    shopAttributesToCreate.push({ ...attribute, productIndex })
                } else if (existAttribute) {
                    const toPush = { productIndex, shopAttribute: existAttribute, newOptions: [] }

                    if (attribute.type?.includes('ARRAY')) {
                        const values = String(attribute.value).replaceAll(', ', ',').split(',')

                        toPush.newOptions = values.filter(ato => !existAttribute.shopAttributeOptions.find(atoo => this.getTranslationsField(atoo, 'name') === ato))
                        if (toPush.newOptions.length) {
                            existAttribute.shopAttributeOptions.push(...toPush.newOptions)
                            const isAdded = shopAttributesToUpdate.find(({ id }) => id === existAttribute.id)
                            if (!isAdded) {
                                shopAttributesToUpdate.push(existAttribute)
                            }
                        }
                    }
                }
            })
        })

        if (Object.values(categoriesToCreate).length) {
          await this.saveCategories(Object.values(categoriesToCreate))

          await this.loadCategories()
        }

        if (shopAttributesToUpdate.length || shopAttributesToCreate.length) {
            if (shopAttributesToUpdate.length) {
                await this.updateShopAttributes(shopAttributesToUpdate)
            }

            if (shopAttributesToCreate.length) {
                await this.createShopAttributes(shopAttributesToCreate)
            }

            await this.loadAttributes()
        }

        // Upload Images
        let images = this.images
        if (images.length) {
            const chunks = images.splitIntoChunks(5)

            const responses = await Promise.all(chunks.map(a => this.$store.dispatch(`uploader/${UPLOAD_FILES}`, { uploadedFiles: a, type: 'building' })))
            const chunkArray = responses.flatMap(p => p?.files)

            images = images.map((image, index) => ({ ...image, name: image.name, token: chunkArray[index] })).filter(image => image.token)
        }

        const productsPayloads = this.importedProducts.map((product, productIndex) => {
            if (!product?.shopProductPictures) product.shopProductPictures = []
            // Images
            if (images.length && product.images) {
                const newImagesNames = product.images.replaceAll(', ', ',').split(',')
                if (newImagesNames.length) {
                    const indexFrom = product.shopProductPictures.length

                    const newImages = newImagesNames.map((imageName, index) => {
                        return {
                            shopProductPictureType: this.getObjectId(this.productPictureTypes[0]) ?? null,
                            position: indexFrom + index + 1,
                            file: images.find(image => image.name === imageName)?.token ?? null
                        }
                    }).filter(image => image.shopProductPictureType && image.file) || []

                    product.shopProductPictures = [...product.shopProductPictures, ...newImages]
                }
            }

            // Categories
            let category = null
            if (product.shopCategories[0]) {
                category = this.categories.find(c => this.getTranslationsField(c, 'name') === this.getTranslationsField(product.shopCategories[0], 'name'))
                if (category) product.shopCategories[0] = this.getObjectId(category)
            }

            if (product.shopCategories[1] && category) {
                const subcategory = category.children.find(c => this.getTranslationsField(c, 'name') === this.getTranslationsField(product.shopCategories[1], 'name'))

                if (subcategory) product.shopCategories[1] = this.getObjectId(subcategory)
            }

            product.shopCategories = product.shopCategories.filter(Boolean)

            // Attributes
            product.attributes = product.attributes.map(attribute => {
                const existAttribute = this.attributes.find(createdAttribute => this.getTranslationsField(createdAttribute, 'name') === attribute.name)

                if (existAttribute) {
                    attribute.shopAttribute = this.getObjectId(existAttribute)
                    if (attribute.type?.includes('ARRAY')) {
                        attribute.valueShopAttributeOptions = String(attribute.value).replaceAll(', ', ',').split(',').map(optionString => {
                            const existedOption = existAttribute.shopAttributeOptions.find(option => this.getTranslationsField(option, 'name') === optionString)

                            if (existedOption) return existedOption.id

                            return null
                        }).filter(Boolean)
                        attribute.value = null
                    }
                }

                const a = {
                    ...attribute,
                    visibleOfferWWW: Boolean(attribute.visibleOfferWWW),
                    value: attribute.value === null ? null : String(attribute.value)
                }

                if (!product.id) delete a.id

                return a
            })


            product.shopProductAttributes = product.attributes
            product.shopProductPictures = product.shopProductPictures.map(image => {
                return {
                    ...image,
                    file: typeof image.file === 'string' ? image.file : image.file?.token,
                    shopProductPictureType: this.getObjectId(image.shopProductPictureType)
                }
            })
            product.code = String(product.code || '-')
            delete product.attributes
            delete product.images
            delete product.minimumPrice
            delete product.offerPriceWithDiscount
            delete product.relatedShopProducts
            delete product.shopProductDiscounts
            delete product.shopProductStoragesSum

            if (product.translations[0]?.name) {
                product.translations[0].name = product.translations[0].name.replaceAll('undefined', '')
            }

            return product
        })

        const toUpdate = productsPayloads.filter(product => product.id)
        const toCreate = productsPayloads.filter(product => !product?.id)

        const requests = []

        if (toCreate.length)
            requests.push(this.$store.dispatch('importer/UPLOAD_IMPORTED_CONTACTS', { data: toCreate, uploadUrl: '1/shops/products', importerLabel: 'ImportNewProducts', chunkSize: 25 }))

        if (toUpdate.length)
            requests.push(this.$store.dispatch('importer/UPLOAD_IMPORTED_CONTACTS', { data: toUpdate, uploadUrl: '1/shops/products', importerLabel: 'ImportUpdateProducts', method: 'PATCH', chunkSize: 25 }))

        if (requests.length) {
            Promise.all(requests)
                .then(() => {
                    this.showToast('success', this.$i18n.t('success.contactUpdated'))
                })
        }

        this.closeModal()
    },
      async saveCategories(categories) {
        const categoriesToPush = categories.map(category => {
            const existed = this.categories.find(c => this.getTranslationsField(category, 'name') === this.getTranslationsField(c, 'name'))

            const children = [...category.children, ...category.newChildren.map(c => ({ translations: c.translations.map(t => ({ locale: 'pl_pl', name: t.name })), paymentSchedule: [] }))]
            // const userGroups = category?.userGroups ? category.userGroups.mapKeys('id') : [this.getObjectId(this.groups[0])]
            const userGroups = category?.userGroups ? category.userGroups.mapKeys('id') : []

            const preparedCategory = {
                translations: (category.translations || []).map(t => ({ translations: t.name, locale: 'pl_pl' })),
                children: children.filter((value, index, self) => {
                    return index === self.findIndex(t => this.getTranslationsField(value, 'name') === this.getTranslationsField(t, 'name'))
                }),
                userGroups,
            }

            if (category?.id) preparedCategory.id = category.id
            if (existed?.id) preparedCategory.id = existed.id

            return preparedCategory
        })
        return await this.$store.dispatch(`categories/${SAVE_CATEGORY}`, categoriesToPush)
      },
      async updateShopAttributes(attributes) {
        const attributesToPush = attributes.map(attribute => {
            return {
                ...attribute,
                shopAttributeOptions: [...new Set(attribute.shopAttributeOptions.filter(Boolean))].map(option => {
                    if (typeof option === 'string') return {
                        translations: [{ locale: 'pl_pl', name: option }]
                    }

                    return option
                })
            }
        })
          return await this.$store.dispatch(`attributes/${SAVE_ATTRIBUTE}`, attributesToPush)
      },
      async createShopAttributes(attributes) {
          const attributeMap = {};

          for (const item of attributes) {
              if (attributeMap[item.name]) {
                  if (item.type?.includes('ARRAY')) attributeMap[item.name].value += `, ${item.value}`;
              } else {
                  attributeMap[item.name] = { ...item };
              }
          }

          const attributesToPush = Object.values(attributeMap).map(attribute => {
            return  {
                      "translations": [
                          {
                              "name": attribute.name,
                              "locale": "pl_pl",
                          }
                      ],
                      "type": attribute.type,
                      "active": true,
                      "base": false,
                      "shopAttributeOptions": attribute.type?.includes('ARRAY') ? [...new Set(String(attribute.value).replaceAll(', ', ',').split(','))].filter(Boolean).map(option => {
                          if (typeof option === 'string') return {
                              translations: [{ locale: 'pl_pl', name: option }]
                          }

                          return option
                      }) : [],
                }
          })
          return await this.$store.dispatch(`attributes/${SAVE_ATTRIBUTE}`, attributesToPush)
      },
    },
}
</script>

<style lang="scss">
.badge-item {
  transition: all 1s
}

.table-padding-x-0 {
  td, th {
    padding: 0.6rem 0.5rem !important;
  }
}

[dir=ltr] .modal-xl {
  margin-left: 15% !important;
  margin-right: 15% !important;
}
</style>
