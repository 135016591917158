<template>
  <expand-window
    :title="$t('FullWindow')"
  >
    <marketing-plans :contact-thread="thread" />
  </expand-window>
</template>

<script>
import MarketingPlans from '@/pages/commissions/List.vue'
import ExpandWindow from '@core/components/@sw/ExpandWindow.vue'

export default {
  components: { ExpandWindow, MarketingPlans },
  props: {
    thread: {
      default: null,
    },
  },
}
</script>
