var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticClass:"d-flex flex-row-reverse"},[_c('b-col',{attrs:{"sm":"12","md":"12","lg":"12"}},[_c('b-button',{staticClass:"mb-50",attrs:{"variant":"primary","size":"sm","to":{ name: 'ranks', params: { rankId: 'new' } }}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_vm._v(" "+_vm._s(_vm.$t('Add'))+" ")],1),_c('div',[_c('b-card',{staticClass:"myTableOffer"},[_c('sw-table',{attrs:{"table-id":"888d94a6be794a91837ddedc66349064","show-pagination":false},on:{"reload-content":_vm.getRanks},scopedSlots:_vm._u([{key:"table",fn:function(){return [_c('b-table',{attrs:{"items":_vm.items,"fields":_vm.fields,"busy":_vm.loading,"striped":"","responsive":"","show-empty":""},scopedSlots:_vm._u([{key:"head()",fn:function(item){return [_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm._f("truncate")(_vm.$tc(item.field.label),34, '...'))+" ")])]}},{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_c('router-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",modifiers:{"hover":true,"left":true}}],staticClass:"doubleLineText",attrs:{"to":{ name: 'ranks', params: { rankId: item.id } },"title":item.name}},[_vm._v(" "+_vm._s(_vm._f("truncate")(item.name,64))+" ")])]}},{key:"cell(createdBy)",fn:function(ref){
var value = ref.value;
return [_c('avatar',{attrs:{"user":value,"size":28}})]}},{key:"cell(permissions)",fn:function(ref){
var value = ref.value;
return [_c('b-badge',{attrs:{"variant":"primary"}},[_vm._v(" "+_vm._s(value.length)+" ")])]}},{key:"cell(action)",fn:function(ref){
var item = ref.item;
return [_c('b-dropdown',{staticClass:"dropdown-icon-wrapper text-decoration-none",attrs:{"variant":"link","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"align-middle",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{attrs:{"to":{ name: 'ranks', params: { rankId: item.id } }}},[_c('span',[_vm._v(_vm._s(_vm.$t('Edit')))])])],1)]}},{key:"cell()",fn:function(row){return [_c('table-default-cell',{attrs:{"field":row.field,"value":row.value}})]}},{key:"empty",fn:function(){return [_c('empty-content')]},proxy:true},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[_c('b-spinner',{staticClass:"align-middle",attrs:{"variant":"primary"}})],1)]},proxy:true}])})]},proxy:true}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }