/* eslint-disable */

export const trainings = [
  { name: 'SalesWizard', url: 'https://youtube.com/embed/Zv2isWcOWSI' },

  { name: 'TrainingFiles.Presentation', url: 'https://www.youtube.com/embed/YNh-TD-T_Yg' },
  { name: 'TrainingFiles.GenerateOffer', url: 'https://youtube.com/embed/SlGE29hEJVQ' },
  { name: 'TrainingFiles.AgreementComponents', url: 'https://youtube.com/embed/vdygqQu42M0' },
  { name: 'TrainingFiles.Autenti', url: 'https://youtube.com/embed/5jfzhvNNYhY' },
  { name: 'TrainingFiles.Categories', url: 'https://youtube.com/embed/0v2CqnJObAw' },
  { name: 'TrainingFiles.ProductsInShop', url: 'https://youtube.com/embed/8pvDOZW9t0I' },
  { name: 'TrainingFiles.ProductsImages', url: 'https://youtube.com/embed/-0SFovi6e6A' },
  { name: 'TrainingFiles.ProductAttributes', url: 'https://youtube.com/embed/8Y514Cjcx00' },
  { name: 'TrainingFiles.OffersAndAgreements', url: 'https://youtube.com/embed/0WARHC2RG70' },

  { name: 'TrainingFiles.Websites', url: 'https://youtube.com/embed/FU8amw7Mi3k' },
  { name: 'TrainingFiles.GenerateOffers', url: 'https://youtube.com/embed/THywoplASNo' },

  { name: 'TrainingFiles.AutentiSigning', url: 'https://youtube.com/embed/qEOizN0IvdE' },
  { name: 'TrainingFiles.Integrations', url: 'https://youtube.com/embed/iZY7jl3ctMM' },
  { name: 'TrainingFiles.WordpressIntegration', url: 'https://youtube.com/embed/Ak0t1OT5JTQ' },
  { name: 'TrainingFiles.WordpressIntegration2', url: 'https://youtube.com/embed/JTJu1MaNw-Y' },

  { name: 'TrainingFiles.SalesWizardUpdateProjectsModuleOrganizationsAdditionalFieldsInContacts', url: 'https://www.youtube.com/embed/1WE1Xao2Q38' },
  { name: 'TrainingFiles.SalesWizardCRMAutomationOpenAIRequestsProcesses', url: 'https://www.youtube.com/embed/u1GAC4Ym8MM' },
  { name: 'TrainingFiles.IntegrationOfSalesWizardCRMWithFacebookAds', url: 'https://www.youtube.com/embed/ZipOC5ZWUtQ' },
  { name: 'TrainingFiles.AutomateRepetitiveTasksInSalesWizardCRM', url: 'https://www.youtube.com/embed/zcfVry_Qf50' },
  { name: 'TrainingFiles.HandlingRequestsInSalesWizardCRM', url: 'https://www.youtube.com/embed/Y3PqkANyoQ0' },
  { name: 'TrainingFiles.PersonalizationOfCRMSystemSalesWizard', url: 'https://www.youtube.com/embed/v3h0CYU2AMk' },
  { name: 'TrainingFiles.DashboardOfCRMSystemSalesWizard', url: 'https://www.youtube.com/embed/sMry-92L7sg' },
  { name: 'TrainingFiles.ContactSettingsStatusesTagsAdditionalFieldsInSalesWizardCRM', url: 'https://www.youtube.com/embed/kN3LueiwSXU' },
  { name: 'TrainingFiles.ProcessFunnelSettingsStatusesAndClosureReasonsInSalesWizardCRM', url: 'https://www.youtube.com/embed/pLjtALpdxtU' },
  { name: 'TrainingFiles.ProcessFunnelSettingsChecklistsAndSurveysInSalesWizardCRM', url: 'https://www.youtube.com/embed/cML444jCF-A' },
  { name: 'TrainingFiles.ProcessFunnelSettingsNotesAndTasksInSalesWizardCRM', url: 'https://www.youtube.com/embed/YRaJnWrcHrU' },
  { name: 'TrainingFiles.CartSettingsQuotingOrdersWebsiteOffers', url: 'https://www.youtube.com/embed/9XeK3oKKWOg' },
  { name: 'TrainingFiles.ContractAndDocumentGeneratorInSalesWizardCRM', url: 'https://www.youtube.com/embed/5A98a7WBz1Y' },
  { name: 'TrainingFiles.ContactsInCRMSalesWizard', url: 'https://www.youtube.com/embed/wriRRrUJPu8' },
  { name: 'TrainingFiles.CreatingProcessesInContactsSalesWizardCRM', url: 'https://www.youtube.com/embed/NVAyEEeI82c' },
  { name: 'TrainingFiles.SendingEmailMessagesFromCRMSalesWizardCRM', url: 'https://www.youtube.com/embed/0o92DS9eNFY' },
  { name: 'TrainingFiles.NotesInCustomerActivitiesInCRMSalesWizardCRM', url: 'https://www.youtube.com/embed/UE9lov-EjNE' },
  { name: 'TrainingFiles.SendingSMSFromCRMSalesWizardCRM', url: 'https://www.youtube.com/embed/Sr8ERBRLG84' },
  { name: 'TrainingFiles.CreatingAndReportingTaskExecutionInCRMSalesWizardCRM', url: 'https://www.youtube.com/embed/AsYh03vSr2w' },

  { name: 'TrainingFiles.SuccessesAndFailuresInCustomerProcesses', url: 'https://www.youtube.com/embed/CJ-nEwpQdKY' },
  { name: 'TrainingFiles.UtilizeChecklistsInProcesses', url: 'https://www.youtube.com/embed/Vj8a-pmISCY' },
  { name: 'TrainingFiles.ProjectsModuleInSalesWizardCRM', url: 'https://www.youtube.com/embed/t0KxxX3sRz4' },

  { name: 'TrainingFiles.PostIntegration', url: 'https://www.youtube.com/embed/dyj0fRkbymQ' },
  { name: 'TrainingFiles.WhatsappIntegration', url: 'https://www.youtube.com/embed/n4U3nA9hZ1M' },
  { name: 'TrainingFiles.Campaign', url: 'https://www.youtube.com/embed/1Zn2NUuhOG4' },
].reverse()
