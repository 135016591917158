var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-calendar overflow-hidden border"},[_c('sw-filters',{attrs:{"shorts":[
      {
        title: _vm.$t('TasksAssignedTo'),
        type: 'avatar',
        value: function () { return _vm.filters.assignedTo; },
        clear: function () { _vm.filters.assignedTo = []; _vm.refetchEvents() }
      },
      {
        title: _vm.$t('TaskTypes'),
        value: function () {
          var valueList = []

          _vm.filters.taskTypes.forEach(function (status) { return valueList.push(_vm.taskTypes.find(function (ref) {
                var id = ref.id;

                return id === status;
                }).name); }
          )

          return valueList.join(', ')
        },
        clear: function () { _vm.filters.taskTypes = []; _vm.refetchEvents() }
      },
      {
        value: function () { return _vm.filters.showUnsignedTasks ? _vm.$t('ShowUnsignedTasks') : null; },
        clear: function () { _vm.filters.showUnsignedTasks = false; _vm.refetchEvents() }
      },
      {
        title: _vm.$t('TaskStatus'),
        value: function () { return _vm.filters.taskStatus && _vm.filters.taskStatus.length ? _vm.$t(("task.status." + (_vm.filters.taskStatus))) : ''; },
        clear: function () { _vm.filters.taskStatus = '' }
      } ]}},[_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('sw-select',{attrs:{"name":_vm.$t('TasksAssignedTo')}},[_c('assigned-users',{attrs:{"value":[].concat( _vm.filters.assignedTo )},on:{"input":_vm.changeAssignedUsers}})],1)],1),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('sw-select',{attrs:{"name":_vm.$t('TaskTypes')}},[_c('v-select',{attrs:{"label":"name","item-value":"id","item-text":"name","reduce":function (taskItem) { return taskItem.id.toString(); },"multiple":"","close-on-select":false,"options":_vm.taskTypes},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('NoOptions'))+" ")]},proxy:true},{key:"option",fn:function(ref){
    var name = ref.name;
return [_c('span',{staticClass:"ml-50"},[_vm._v(_vm._s(name))])]}},{key:"selected-option",fn:function(ref){
    var name = ref.name;
return [_c('span',{staticClass:"ml-50"},[_vm._v(_vm._s(name))])]}}]),model:{value:(_vm.filters.taskTypes),callback:function ($$v) {_vm.$set(_vm.filters, "taskTypes", $$v)},expression:"filters.taskTypes"}})],1)],1),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('sw-select',{attrs:{"name":_vm.$t('TaskStatus')}},[_c('v-select',{attrs:{"options":_vm.taskStatus},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('NoOptions'))+" ")]},proxy:true},{key:"option",fn:function(ref){
    var label = ref.label;
return [_c('span',{staticClass:"ml-50"},[_vm._v(_vm._s(_vm.$t(("task.status." + label))))])]}},{key:"selected-option",fn:function(ref){
    var label = ref.label;
return [_c('span',{staticClass:"ml-50"},[_vm._v(_vm._s(_vm.$t(("task.status." + label))))])]}}]),model:{value:(_vm.filters.taskStatus),callback:function ($$v) {_vm.$set(_vm.filters, "taskStatus", $$v)},expression:"filters.taskStatus"}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-checkbox',{attrs:{"variant":"primary"},on:{"change":function($event){return _vm.reloadTasks(_vm.pagination.currentPage, _vm.pagination.perPage)}},model:{value:(_vm.filters.showUnsignedTasks),callback:function ($$v) {_vm.$set(_vm.filters, "showUnsignedTasks", $$v)},expression:"filters.showUnsignedTasks"}},[_vm._v(" "+_vm._s(_vm.$t('ShowUnsignedTasks'))+" ")])],1)],1)],1),_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col position-relative"},[_c('div',{staticClass:"card shadow-none border-0 mb-0 rounded-0"},[_c('div',{staticClass:"card-body pb-0 pt-1"},[_c('full-calendar',{ref:"refCalendar",staticClass:"full-calendar calendar-custom",attrs:{"options":Object.assign({}, _vm.calendarOptions, {locales: _vm.items, locale: _vm.$i18n.locale})},scopedSlots:_vm._u([{key:"eventContent",fn:function(ref){
    var event = ref.event;
return [_c(_vm.getViewByCalendar(event._context.viewApi.type),{tag:"component",staticStyle:{"width":"100%","height":"100%"},attrs:{"data":event}})]}}])})],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }