var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.checkRequiredPermissions([_vm.$roles.DOCUMENT_MODIFY]))?_c('b-button',{staticClass:"mb-50",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){_vm.isModalOpen = true}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_vm._v(" "+_vm._s(_vm.$t('Add'))+" ")],1):_vm._e(),_c('b-card',{attrs:{"id":"docs"}},[_c('sw-select',[_c('v-select',{staticClass:"per-page-selector d-inline-block mb-50 w-100",attrs:{"options":[25, 50, 100, 250],"clearable":false},on:{"input":_vm.getFolders},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('NoOptions'))+" ")]},proxy:true}]),model:{value:(_vm.pagination.perPage),callback:function ($$v) {_vm.$set(_vm.pagination, "perPage", $$v)},expression:"pagination.perPage"}})],1),_c('b-table',{staticClass:"table-wrap-words rounded",attrs:{"items":_vm.folders,"fields":_vm.foldersFields,"striped":"","responsive":"","busy":_vm.loading,"show-empty":""},scopedSlots:_vm._u([{key:"head()",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(("" + label)))+" ")]}},{key:"cell(name)",fn:function(data){return [_c('router-link',{attrs:{"to":{ name: _vm.$route.name, params: { folderId: data.item.id } }}},[_c('span',{staticClass:"text-primary"},[_vm._v(_vm._s(data.value === 'Trainings' ? _vm.$t('Trainings') : data.value))])])]}},{key:"cell(typesOfSignature)",fn:function(ref){
var value = ref.value;
return [(value.length)?_vm._l((value),function(type){return _c('b-badge',{key:type.name,attrs:{"variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t(type.name))+" ")])}):_c('feather-icon',{attrs:{"icon":"MinusIcon"}})]}},{key:"cell(createdBy)",fn:function(ref){
var value = ref.value;
return [_c('avatar',{attrs:{"user":value,"to":{ name: 'user', params: { userId: value.id } }}})]}},{key:"cell(sharedRanks)",fn:function(data){return [(_vm.checkRequiredPermissions([_vm.$roles.DOCUMENT_MODIFY]))?_c('b-button',{attrs:{"size":"sm","variant":"flat-primary"},on:{"click":function($event){_vm.selectedTarget = { id: data.index, item: _vm.folders[data.index] }
            _vm.showFolderSharesModal = true}}},[_c('feather-icon',{attrs:{"icon":"Share2Icon"}})],1):_c('feather-icon',{attrs:{"icon":"MinusIcon"}})]}},{key:"cell(action)",fn:function(ref){
            var item = ref.item;
            var index = ref.index;
return [(_vm.checkRequiredPermissions([_vm.$roles.DOCUMENT_MODIFY]) && item.name !== 'Trainings')?_c('b-dropdown',{attrs:{"id":"dropdown-dropleft","dropleft":"","no-caret":"","variant":"flat"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{attrs:{"icon":"MoreVerticalIcon"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.editFolder(item, index)}}},[_vm._v(" "+_vm._s(_vm.$t('Edit'))+" ")]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.deleteFolder(item, index)}}},[_vm._v(" "+_vm._s(_vm.$t('Delete'))+" ")])],1):_c('feather-icon',{attrs:{"icon":"MinusIcon"}})]}},{key:"cell()",fn:function(row){return [_c('table-default-cell',{attrs:{"field":row.field,"value":row.value}})]}},{key:"empty",fn:function(){return [_c('empty-content')]},proxy:true},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[_c('b-spinner',{staticClass:"align-middle",attrs:{"variant":"primary"}})],1)]},proxy:true}])}),_c('b-pagination',{staticClass:"pt-50",attrs:{"total-rows":_vm.pagination.totalRecords,"per-page":_vm.pagination.perPage,"align":"center"},on:{"change":_vm.getFolders},model:{value:(_vm.pagination.currentPage),callback:function ($$v) {_vm.$set(_vm.pagination, "currentPage", $$v)},expression:"pagination.currentPage"}})],1),_c('b-modal',{attrs:{"visible":_vm.isModalOpen,"title":"Folder","hide-footer":"","no-close-on-backdrop":""},on:{"hide":function($event){_vm.isModalOpen = false; _vm.editedFolder = { folder: null, index: -1 }}}},[_c('new-folder-modal',{attrs:{"edited-folder":_vm.editedFolder.folder},on:{"save-item":_vm.saveItem}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }