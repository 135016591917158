<template>
  <div>
    <b-button
      variant="primary"
      size="sm"
      class="mb-1"
      @click="onSaveSettings"
    >
      {{ $t('Save') }}
    </b-button>

    <b-card class="position-relative">
      <template #header>
        <h4 class="d-flex align-items-center">
          {{ $t('Fakturownia') }}
        </h4>
      </template>

      <b-row>
        <b-col sm="12">
          <b-form-group :label="$t('APIKey')">
            <b-form-input
              v-model="fakturowniaToken"
              type="password"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12">
          <b-form-group :label="$t('Domain')">
            <b-form-input
              v-model="fakturowniaDomain"
              type="text"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col sm="12">
          <div class="divider divider-left">
            <div class="divider-text text-primary">
              {{ $t('SellerData') }}
            </div>
          </div>
        </b-col>
        <b-col
          sm="6"
        >
          <b-form-group :label="$t('Name')">
            <b-form-input
              id="basicInput"
              v-model="fakturowniaSellerCompanyName"
            />
          </b-form-group>
        </b-col>

        <b-col
          sm="6"
        >
          <b-form-group :label="$t('contact.vatId')">
            <b-form-input
              id="basicInput"
              v-model="fakturowniaSellerCompanyVatId"
            />
          </b-form-group>
        </b-col>

      </b-row>

      <b-row>

        <b-col
          md="6"
        >
          <b-form-group :label="$t('City')">
            <b-form-input
              id="basicInput"
              v-model="fakturowniaSellerAddressCity"
            />
          </b-form-group>
        </b-col>

        <b-col
          md="6"
        >
          <b-form-group :label="$t('contact.postalCode')">
            <b-form-input
              id="basicInput"
              v-model="fakturowniaSellerAddressPostalCode"
              class="form-control"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="6">
          <b-form-group :label="$t('Street')">
            <b-form-input
              id="basicInput"
              v-model="fakturowniaSellerAddressStreet"
            />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group :label="$t('BuildingNumber')">
            <b-form-input
              id="basicInput"
              v-model="fakturowniaSellerAddressNumber"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-overlay
        :show="loading"
        no-wrap
        spinner-variant="primary"
      />
    </b-card>

    <b-button
      variant="primary"
      size="sm"
      @click="onSaveSettings"
    >
      {{ $t('Save') }}
    </b-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { GET_PRIVATE_SETTINGS, SAVE_SETTINGS } from '@/@constants/mutations'

export default {
  name: 'ImageRecognition',
  components: {
  },
  data() {
    return {
      loading: false,
      fakturowniaToken: '',
      fakturowniaDomain: '',

      fakturowniaSellerCompanyName: '',
      fakturowniaSellerCompanyVatId: '',
      fakturowniaSellerAddressCity: '',
      fakturowniaSellerAddressPostalCode: '',
      fakturowniaSellerAddressStreet: '',
      fakturowniaSellerAddressNumber: '',
    }
  },
  computed: {
    ...mapGetters({
      system: 'system/getSettings',
    }),
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.loading = true
      this.$store.dispatch(`system/${GET_PRIVATE_SETTINGS}`)
        .then(res => {
          const token = res.data.item?.fakturowniaTokenFilled ? '*******************' : ''

          this.$set(this, 'fakturowniaToken', res.data.item?.fakturowniaToken || token)
          this.$set(this, 'fakturowniaDomain', res.data.item?.fakturowniaDomain || '')

          this.$set(this, 'fakturowniaSellerCompanyName', res.data.item?.fakturowniaSellerCompanyName || '')
          this.$set(this, 'fakturowniaSellerCompanyVatId', res.data.item?.fakturowniaSellerCompanyVatId || '')
          this.$set(this, 'fakturowniaSellerAddressCity', res.data.item?.fakturowniaSellerAddressCity || '')
          this.$set(this, 'fakturowniaSellerAddressPostalCode', res.data.item?.fakturowniaSellerAddressPostalCode || '')
          this.$set(this, 'fakturowniaSellerAddressStreet', res.data.item?.fakturowniaSellerAddressStreet || '')
          this.$set(this, 'fakturowniaSellerAddressNumber', res.data.item?.fakturowniaSellerAddressNumber || '')
          this.$set(this, 'fakturowniaSellerAddressCountry', res.data.item?.fakturowniaSellerAddressCountry || '')

          this.loading = false
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          this.loading = false
        })
    },
    onSaveSettings() {
      this.loading = true
      const payload = {
        fakturowniaToken: this.fakturowniaToken,
        fakturowniaDomain: this.fakturowniaDomain,
        fakturowniaSellerAddressCountry: this.fakturowniaSellerAddressCountry || null,
        fakturowniaSellerCompanyName: !this.fakturowniaSellerIsCompany ? null : this.fakturowniaSellerCompanyName || null,
        fakturowniaSellerCompanyVatId: !this.fakturowniaSellerIsCompany ? null : this.fakturowniaSellerCompanyVatId || null,
        fakturowniaSellerAddressCity: this.fakturowniaSellerAddressCity || null,
        fakturowniaSellerAddressPostalCode: this.fakturowniaSellerAddressPostalCode || null,
        fakturowniaSellerAddressStreet: this.fakturowniaSellerAddressStreet || null,
        fakturowniaSellerAddressNumber: this.fakturowniaSellerAddressNumber || null,
      }

      if (this.fakturowniaToken.includes('********')) {
        delete payload.fakturowniaToken
      }

      this.$store.dispatch(`system/${SAVE_SETTINGS}`, { privateData: payload })
        .then(() => {
          this.loading = false
          this.showToast('success', this.$i18n.t('ChangesSaved'))
          this.loadData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
  },
}
</script>

<style scoped>

</style>
