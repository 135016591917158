<template>
  <b-list-group-item :ref="`field_item_${label}_${icon}`"
                     class="d-flex align-items-start py-50"
  >
    <span class="mr-1">
      <sw-icon :icon="icon" v-if="icon"
               class="text-primary"
               size="16"
      />
    </span>
    <div class="flex-grow-1 d-flex align-items-center justify-content-between flex-wrap">
      <p class="mb-0">
        {{ label }}
      </p>

      <template>
        <span v-if="value"
              @click="copy(value)"
        >
          <span v-if="!badge && !avatar"
                v-html="value"
          />
          <b-badge v-else-if="badge"
                   variant="light-primary"
          >{{ value }}</b-badge>
          <avatar v-else-if="avatar"
                  :user="value"
          />

          <sw-icon v-if="tip"
                   v-b-tooltip
                   class="text-primary ml-25"
                   icon="AlertCircleIcon"
                   :title="tip"
          />
          <sw-icon v-if="showCopy"
                   v-b-tooltip
                   class="text-success ml-25"
                   icon="CopyIcon"
                   :title="$t('Copy')"
          />
        </span>
        <sw-icon v-else
                 icon="MinusIcon"
        />
      </template>
    </div>
  </b-list-group-item>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'

export default {
  name: 'DetailListItem',
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    tip: {
      type: String,
      default: '',
    },
    badge: {
      type: Boolean,
      default: false,
    },
    avatar: {
      type: Boolean,
      default: false,
    },
    showCopy: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    copy(txt) {
      if (!this.showCopy) return

      this.$copyText(txt, this.$refs[`field_item_${this.label}_${this.icon}`]).then(() => {
        this.showToast('primary', this.$i18n.t('CopiedToClipboard'))
      }, e => {
        this.showToast('danger', e)
      })
    },
  },
}
</script>

<style scoped>

</style>
