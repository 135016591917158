const icons = () => {
  const iconsList = [
    'ActivityIcon',
    'AirplayIcon',
    'AlertCircleIcon',
    'AlertOctagonIcon',
    'AlertTriangleIcon',
    'ArchiveIcon',
    'AtSignIcon',
    'AwardIcon',
    'BarChart2Icon',
    'BarChartIcon',
    'BellOffIcon',
    'BellIcon',
    'BookIcon',
    'BookmarkIcon',
    'BriefcaseIcon',
    'CalendarIcon',
    'CameraOffIcon',
    'CameraIcon',
    'CastIcon',
    'CheckCircleIcon',
    'CheckSquareIcon',
    'CheckIcon',
    'ClipboardIcon',
    'ClockIcon',
    'CoffeeIcon',
    'ColumnsIcon',
    'CopyIcon',
    'CreditCardIcon',
    'DatabaseIcon',
    'DeleteIcon',
    'DiscIcon',
    'DivideSquareIcon',
    'DollarSignIcon',
    'DownloadIcon',
    'Edit3Icon',
    'ExternalLinkIcon',
    'EyeIcon',
    'FileMinusIcon',
    'FilePlusIcon',
    'FileTextIcon',
    'FileIcon',
    'FilmIcon',
    'FlagIcon',
    'FolderMinusIcon',
    'FolderPlusIcon',
    'FolderIcon',
    'GiftIcon',
    'GridIcon',
    'HardDriveIcon',
    'HelpCircleIcon',
    'ImageIcon',
    'InboxIcon',
    'InfoIcon',
    'LayersIcon',
    'LayoutIcon',
    'Link2Icon',
    'LinkIcon',
    'ListIcon',
    'MailIcon',
    'MapIcon',
    'MessageCircleIcon',
    'MessageSquareIcon',
    'MicIcon',
    'Navigation2Icon',
    'PackageIcon',
    'PaperclipIcon',
    'PhoneCallIcon',
    'PhoneForwardedIcon',
    'PhoneIncomingIcon',
    'PhoneMissedIcon',
    'PhoneOffIcon',
    'PhoneOutgoingIcon',
    'PhoneIcon',
    'PieChartIcon',
    'PlusCircleIcon',
    'PlusSquareIcon',
    'PlusIcon',
    'PrinterIcon',
    'Trash2Icon',
    'TrashIcon',
    'UploadIcon',
    'UserCheckIcon',
    'UserMinusIcon',
    'UserPlusIcon',
    'UserXIcon',
    'UserIcon',
  ]

  return iconsList
}

export default icons
