<template>
  <div>
    <div class="d-flex flex-wrap flex-row justify-content-between align-content-center align-items-center">
      <div class="pr-1">
        <sw-select>
          <sw-select-fpr
            :is-empty="dateFilter"
            @clear="dateFilter = ''"
          >
            <flat-pickr
              v-model="dateFilter"
              style="min-width: 300px"
              class="form-control"
              :config="{ mode: 'range', locale: getCalendarLocale($i18n.locale) }"
              :placeholder="$t('ContactCreatedAt')"
              @input="ini()"
            />
          </sw-select-fpr>
        </sw-select>
      </div>

      <b-form-group>
        <b-form-radio-group
          v-model="activeFilter"
          :options="filterCreatedAtOptions"
          buttons
          button-variant="outline-primary"
          size="sm"
          class="flex-wrap"
          style="row-gap: .25rem"
          @input="setDate"
        />
      </b-form-group>
    </div>

    <b-row>
      <b-col
        v-for="(panel, i) in panels"
        :key="i"
        sm="12"
        :md="!Boolean(i % 2) && i === panels.length - 1 ? 12 : 6"
        class="mb-1"
      >
        <char-panel
          style="height: 100%"
          :title="panel.title"
          :items="panel.items"
          :tip="panel.tip"
          :count="panel.count"
        />
      </b-col>
    </b-row>

    <h4 class="text-secondary mt-75 mb-75">
      {{ $t('ThreadTags') }}
    </h4>

    <b-row>
      <b-col
        md="6"
      >
        <char-panel
          :title="$t('ThreadsAndContacts')"
          :items="contactTags"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BFormRadioGroup, VBPopover, VBTooltip } from 'bootstrap-vue'
import CharPanel from '@/pages/reports/tabs/components/CharPanel.vue'
import axiosIns from '@/libs/axios'
import moment from 'moment/moment'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BFormRadioGroup,
    CharPanel,
    flatPickr,
  },

  directives: {
    'b-popover': VBPopover,
    'b-tooltip': VBTooltip,
  },

  data: () => ({
    isLoading: false,

    activeFilter: null,
    dateFilter: '',
    filterCreatedAtOptions: [],

    panels: [
      {
        title: 'ContactsAndProcesses',
        items: [],
      },
      {
        title: 'TypesOfContacts',
        items: [],
      },
      {
        title: 'DelayedContacts',
        tip: 'DelayedContact',
        items: [],
      },
      {
        title: 'ContactsByStatus',
        items: [],
      },
      {
        title: 'NumberOfOpenProcessesInContacts',
        count: true,
        items: [],
      },
    ],
    contactTags: [],
  }),

  mounted() {
    this.ini()
  },

  methods: {
    setDate(dateValue) {
      if (dateValue === 'last7Days') {
        const startAt = moment().subtract(7, 'days').format('YYYY-MM-DD hh:mm')

        this.dateFilter = [startAt]
      }

      if (dateValue === 'thisMonth') {
        const startAt = moment().startOf('month').format('YYYY-MM-DD hh:mm')
        const endAt = moment().endOf('month').format('YYYY-MM-DD hh:mm')

        this.dateFilter = [startAt, endAt]
      }

      if (dateValue === 'lastMonth') {
        const startAt = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD hh:mm')
        const endAt = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD hh:mm')

        this.dateFilter = [startAt, endAt]
      }

      if (dateValue === 'last6Months') {
        const startAt = moment().subtract(6, 'months').startOf('month').format('YYYY-MM-DD hh:mm')
        const endAt = moment().format('YYYY-MM-DD hh:mm')

        this.dateFilter = [startAt, endAt]
      }

      if (dateValue === null) this.dateFilter = ''
    },
    async ini() {
      this.filterCreatedAtOptions = [
        { text: this.$t('All'), value: null },
        { text: this.$t('Last7Days'), value: 'last7Days' },
        { text: this.$t('ThisMonth'), value: 'thisMonth' },
        { text: this.$t('LastMonth'), value: 'lastMonth' },
        { text: this.$t('Last6Months'), value: 'last6Months' },
      ]

      try {
        const params = {}

        if (this.dateFilter) {
          const [startAt, endAt] = this.dateFilter.dateRange()
          if (startAt) params.gte_createdAt = moment(startAt, 'YYYY-MM-DD').format('YYYY-MM-DD hh:mm')
          if (endAt) params.lt_createdAt = moment(endAt, 'YYYY-MM-DD').format('YYYY-MM-DD hh:mm')
        }

        const { data } = await axiosIns.get('1/reports/contacts', { params })
        const { contacts } = data.data || {}

        this.initData(contacts)
      } catch (err) {
        console.error(err)
      }
    },

    async initData(contacts) {
      // Close statuses
      const {
        contactThreadStatus, contactStatus, all, organization, contactTypes, contactThreads,
      } = contacts
      await this.setContactThreadStatuses(contactThreadStatus)

      await this.setDelayedContacts(contactStatus)

      await this.setContactsByStatuses(contactStatus, all)

      await this.setContactsByType(all, organization)

      await this.setContactsByTags(contactTypes, all)

      await this.setContactProcessCount(contactThreads, all)
      // Contact statuses
    },

    async setContactProcessCount(counts, all) {
      const itemIndex = this.panels.findIndex(a => a.title === 'NumberOfOpenProcessesInContacts')
      this.panels[itemIndex].items = Object.entries(counts).map((count, index) => ({
        // eslint-disable-next-line no-nested-ternary
        title: index === 0 ? 'NoProcesses' : (index > 1 ? 'Processes' : 'Process'),
        beforeValue: count[1],
        value: Math.floor((count[1] / all) * 100) || 0,
        variant: 'primary',
      }))
    },

    async setDelayedContacts(statuses) {
      const index = this.panels.findIndex(a => a.title === 'DelayedContacts')
      this.panels[index].items = statuses.map(({ name, all, delayed }) => ({
        title: name,
        beforeValue: delayed,
        value: Math.floor((delayed / all) * 100) || 0,
        variant: 'primary',
      }))
    },

    async setContactsByStatuses(statuses, allContacts) {
      const index = this.panels.findIndex(a => a.title === 'ContactsByStatus')
      this.panels[index].items = statuses.map(({ name, all }) => ({
        title: name,
        beforeValue: all,
        value: Math.floor((all / allContacts) * 100) || 0,
        variant: 'primary',
      }))
    },

    // eslint-disable-next-line no-unused-vars
    async setContactsByTags(contactTypes, allContacts) {
      this.contactTags = contactTypes.map(({ name, all }) => ({
        title: name,
        beforeValue: all,
        value: Math.floor((all / allContacts) * 100) || 0,
        variant: 'primary',
      }))
    },

    async setContactsByType(all, areCompany) {
      const index = this.panels.findIndex(a => a.title === 'TypesOfContacts')
      const types = [
        {
          title: 'RelatedToCompanies',
          variant: 'success',
          beforeValue: areCompany,
          value: Math.floor(((areCompany) / all) * 100) || 0,
        },
        {
          title: 'Individuals',
          variant: 'danger',
          beforeValue: all - areCompany,
          value: Math.floor(((all - areCompany) / all) * 100) || 0,
        },
      ]

      this.panels[index].items = types
    },

    async setContactThreadStatuses(contactThreadStatuses) {
      const all = Object.keys(contactThreadStatuses).map(key => contactThreadStatuses[key]).reduce((a, b) => a + b, 0)
      const index = this.panels.findIndex(a => a.title === 'ContactsAndProcesses')
      this.panels[index].items = [
        {
          title: 'SuccessfulClosedProcessesFunnel',
          beforeValue: contactThreadStatuses?.closedNegative || 0,
          value: Math.round(((contactThreadStatuses?.closedNegative || 0) / all) * 100) || 0,
          variant: 'success',
        },
        {
          title: 'FailedClosedProcessesFunnel',
          beforeValue: contactThreadStatuses?.closedPositive || 0,
          value: Math.round(((contactThreadStatuses?.closedPositive || 0) / all) * 100) || 0,
          variant: 'danger',
        },
        {
          title: 'OpenProcessesContacts',
          beforeValue: contactThreadStatuses?.open || 0,
          value: Math.round(((contactThreadStatuses?.open || 0) / all) * 100) || 0,
        },
      ]
    },
  },
}
</script>
