<template>
  <div>
    <!--    Section: Filters    -->
    <sw-filters
      v-if="!hideFilters"
      :shorts="[
        {
          title: $t('contact.creator'),
          type: 'avatar',
          value: () => offerFilters.createdBy ? [offerFilters.createdBy] : null,
          clear: () => { offerFilters.createdBy = ''; reloadContent() }
        },
        {
          title: $t('AssignedUsers'),
          type: 'avatar',
          value: () => offerFilters.assignedUsers,
          clear: () => { offerFilters.assignedUsers = ''; reloadContent() }
        },
        {
          title: $t('offer.offerStatus'),
          value: () => offerFilters.status ? $tc(`offer.status.${offerFilters.status}`, 1) : null,
          clear: () => { offerFilters.status = ''; reloadContent() }
        },
        {
          title: $t('task.Created_Time'),
          value: () => offerFilters.createdAt,
          clear: () => { offerFilters.createdAt = ''; reloadContent() }
        },
        {
          title: $t('ShowOffersWith'),
          variant: 'warning',
          value: () => {
            switch(offerFilters.withoutMessages) {
            case('all'): return $t('AllOfThem')
            case('sent'): return $t('Shipped')
            case('not_sent'): return $t('NotSent')
            default: return ''
            }
          },
          clear: () => { offerFilters.withoutMessages = 'all'; reloadContent() }
        },
      ]"
    >
      <b-row>
        <!--    Filter by creator    -->
        <b-col
          sm="12"
          md="6"
          lg="4"
        >
          <sw-select :name="$t('contact.creator')">
            <select-user
              :is-multiple="false"
              :value="offerFilters.createdBy"
              :placeholder="$t('TypeToSearch')"
              @input="value => $set(offerFilters, 'createdBy', value)"
            />
          </sw-select>
        </b-col>

        <!--    Filter by assignedUsers    -->
        <b-col
          sm="12"
          md="6"
          lg="8"
        >
          <sw-select :name="$t('AssignedUsers')">
            <select-user
              :value="offerFilters.assignedUsers"
              :placeholder="$t('TypeToSearch')"
              @input="value => $set(offerFilters, 'assignedUsers', value)"
            />
          </sw-select>
        </b-col>

        <!--    Filter by status    -->
        <b-col
          sm="12"
          md="6"
          lg="4"
        >
          <sw-select :name="$t('offer.offerStatus')">
            <v-select
              v-model="offerFilters.status"
              :options="offerStatus"
            >
              <template #option="option">
                {{ $tc(`offer.status.${option.label}`, 1) }}
              </template>
              <template #selected-option="option">
                {{ $tc(`offer.status.${option.label}`, 1) }}
              </template>
            </v-select>
          </sw-select>
        </b-col>

        <!--    Filter by created date    -->
        <b-col
          sm="12"
          md="6"
          lg="4"
        >
          <sw-select :name="$t('task.Created_Time')">
            <sw-select-fpr
              :is-empty="offerFilters.createdAt"
              @clear="offerFilters.createdAt = ''"
            >
              <flat-pickr
                v-model="offerFilters.createdAt"
                class="form-control"
                :config="{ mode: 'range', locale: getCalendarLocale($i18n.locale) }"
              />
            </sw-select-fpr>
          </sw-select>
        </b-col>

        <!--    Filter by offer type (something like that XD)    -->
        <b-col
          sm="12"
          md="6"
          lg="4"
        >
          <b-form-group :label="$t('ShowOffersWith')">
            <b-form-radio
              v-model="offerFilters.withoutMessages"
              name="offer-messages"
              value="all"
              class="custom-control-primary"
              variant="primary"
            >
              {{ $t('AllOfThem') }}
            </b-form-radio>

            <b-form-radio
              v-model="offerFilters.withoutMessages"
              name="offer-messages"
              value="sent"
              class="custom-control-primary mt-50"
              variant="primary"
            >
              {{ $t('Shipped') }}
            </b-form-radio>

            <b-form-radio
              v-model="offerFilters.withoutMessages"
              name="offer-messages"
              value="not_sent"
              class="custom-control-primary mt-50"
              variant="primary"
            >
              {{ $t('NotSent') }}
            </b-form-radio>
          </b-form-group>
        </b-col>
      </b-row>
    </sw-filters>

    <!--      Section: Table      -->
    <component :is="thread ? 'div' : 'b-card'">
      <table-offer
        ref="table"
        :filters="offerFilters"
        @status="statusList => status = statusList"
      />
    </component>
  </div>
</template>

<script>
import { BFormRadio, VBTooltip } from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import tableOffer from '@/views/offers/Table.vue'
import offerStatus from '@/helpers/offer/offerStatus'
import selectUser from '@/views/components/AssignedUsers.vue'

export default {
  components: {
    vSelect,
    flatPickr,
    tableOffer,
    selectUser,
    BFormRadio,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    hideFilters: {
      type: Boolean,
      default: false,
    },
    thread: {
      default: null,
    },
    funnel: {
      default: null,
    },
    funnelPoint: {
      default: null,
    },
    contacts: {
      default: () => [],
    },
    propsUser: {
      type: Object,
      required: false,
      default: null,
    },
    propsOfferStatus: {
      type: String,
      required: false,
      default: null,
    },
    propsOfferMessages: {
      type: String,
      default: '',
      required: false,
    },
  },
  data: () => ({
    offerFilters: {
      createdBy: '',
      createdAt: '',
      sendTime: '',
      status: '',
      assignedUsers: [],
      withoutMessages: 'all',
    },
    status: [],
    offerStatus: (offerStatus)(),
    creators: [],

    showNewOfferModal: false,
  }),
  computed: {
    locale() {
      return this.$i18n.locale
    },
  },
  watch: {
    funnel(n, o) { if (n !== o) this.reloadContent() },
    funnelPoint(n, o) { if (n !== o) this.reloadContent() },
    thread(n, o) { if (n !== o) this.reloadContent() },
    contacts(n, o) { if (n !== o) this.reloadContent() },
  },
  mounted() {
    if (this.funnel) {
      this.offerFilters.funnel = this.getObjectId(this.funnel)
    }
    if (this.funnelPoint) {
      this.offerFilters.funnelPoint = this.getObjectId(this.funnelPoint)
    }
    if (this.thread) {
      this.offerFilters.thread = this.getObjectId(this.thread)
    }
    if (this.contacts) {
      this.offerFilters.contacts = (this.contacts || []).mapKeys('id')
    }

    if (this.propsUser) {
      this.$set(this.offerFilters, 'assignedUsers', [this.propsUser])
    } else if (this.propsUser === false) {
      this.$set(this.offerFilters, 'assignedUsers', [])
    }

    if (this.propsOfferStatus) {
      this.$set(this.offerFilters, 'status', this.propsOfferStatus)
    }

    if (this.propsOfferMessages) {
      this.$set(this.offerFilters, 'withoutMessages', this.propsOfferMessages)
    }

    this.reloadContent()
  },
  methods: {
    reloadContent() {
      if (this.$refs.table) {
        this.$refs.table.loadOffers()
      }
    },
    loadCreators() {},
    getStatus(name) {
      const stat = this?.status.filter(el => el.status === name)[0] ?? 0

      return stat?.totalGrossPrice ?? 0
    },
  },
}
</script>

<style>
.bigTable table { min-height: 16rem }
</style>
