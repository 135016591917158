<template>
  <div>
    <div
      class="d-flex"
      style="column-gap: .5rem"
    >
      <b-button
        size="sm"
        variant="success"
        @click="saveStatus()"
      >
        {{ $t('Save') }}
      </b-button>

      <b-button
        size="sm"
        variant="primary"
        @click="openModal()"
      >
        {{ $t('Add') }}

        <feather-icon icon="PlusIcon" />
      </b-button>
    </div>

    <draggable
      v-if="statusList.length"
      v-model="statusList"
      tag="div"
      class="cursor-pointer mt-1"
    >
      <b-row
        v-for="status in statusList"
        :id="status.id"
        :key="status.id"
        ref="row"
        style="gap: .5rem"
        class="border-bottom py-1"
      >
        <!-- Item Name -->
        <b-col
          md="5"
          class="d-flex align-items-center"
          style="gap: .5rem"
        >
          <feather-icon
            icon="MoveIcon"
            size="18"
          />

          <p class="mb-0 h5">
            {{ status.name }}
          </p>
        </b-col>

        <!-- Status -->
        <b-col
          md="2"
        >
          <b-form-group class="mb-0">
            <div
              class="d-flex align-items-center flex-md-column"
              style="column-gap: .5rem"
            >
              <label>
                {{ $t('Status') }}
              </label>

              <b-button-group>
                <b-button
                  v-b-tooltip.hover.v-success
                  :variant="status.type === typeList.COMPLETED ? 'success' : 'outline-success'"
                  size="sm"
                  class="btn-icon p-25"
                  :title="$t(!isTask ? 'AssociateStatusWithCompletedProject' : 'AssociateStatusWithCompletedTask')"
                  @click="changeType(status, typeList.COMPLETED)"
                >
                  <feather-icon icon="CheckIcon" />
                </b-button>

                <b-button
                  v-b-tooltip.hover.v-danger
                  :variant="status.type === typeList.REJECTED ? 'danger' : 'outline-danger'"
                  size="sm"
                  class="btn-icon p-25"
                  :title="$t(!isTask ? 'AssociateStatusWithNotCompletedProject' : 'AssociateStatusWithNotCompletedTask')"
                  @click="changeType(status, typeList.REJECTED)"
                >
                  <feather-icon icon="MinusIcon" />
                </b-button>
              </b-button-group>
            </div>
          </b-form-group>
        </b-col>

        <!-- Remove Button -->
        <b-col
          md="4"
          class="d-flex align-items-center"
          style="gap: .5rem"
        >
          <b-button
            v-b-tooltip.hover.v-warning
            size="sm"
            variant="outline-warning"
            class="btn-icon"
            :title="$t('Edit')"
            @click="openModal(status)"
          >
            <feather-icon icon="EditIcon" />
          </b-button>

          <b-button
            v-b-tooltip.hover.v-danger
            variant="outline-danger"
            size="sm"
            class="btn-icon"
            :title="removedFields.includes(status.id ? status.id.toString() : false) ? $t('Cancel') : $t('Delete')"
            @click="deleteStatusSure(status.id)"
          >
            <feather-icon icon="XIcon" />
          </b-button>
        </b-col>
      </b-row>
    </draggable>

    <div
      v-else
      class="text-center py-1 text-primary font-weight-bold"
    >
      <feather-icon
        size="17"
        icon="XCircleIcon"
      />
      {{ $t('NoData') }}
    </div>

    <b-overlay
      :show="isLoading"
      no-wrap
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
    />
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { BButtonGroup, VBTooltip } from 'bootstrap-vue'
import {
  DELETE_PROJECT_STATUS,
  DELETE_PROJECT_TASK_STATUS,
  GET_PROJECT_STATUSES,
  GET_PROJECT_TASK_STATUSES,
  OPEN_MODAL,
  UPDATE_PROJECT_STATUS,
  UPDATE_PROJECT_TASK_STATUS,
} from '@/@constants/mutations'
import { mapGetters } from 'vuex'

export default {
  components: {
    draggable,
    BButtonGroup,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    isTask: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    isLoading: false,

    statusList: [],

    removedFields: [],

    typeList: {
      COMPLETED: 'COMPLETED',
      REJECTED: 'REJECTED',
      NONE: null,
    },
  }),
  computed: {
    ...mapGetters({
      modalState: 'modal/getModalState',
    }),
  },
  watch: {
    modalState: {
      deep: true,
      handler(n) {
        if (n.reloadContent) { this.loadNewStatut(n.reloadContent) }
      },
    },
  },
  mounted() {
    this.ini()
  },
  methods: {
    ini() { this.loadStatuses() },

    loadStatuses() {
      this.isLoading = true

      const type = !this.isTask ? `projectStatuses/${GET_PROJECT_STATUSES}` : `taskStatuses/${GET_PROJECT_TASK_STATUSES}`

      this.$store.dispatch(type)
        .then(res => { this.statusList = res })

        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })

        .finally(() => { this.isLoading = false })
    },

    loadNewStatut(statut) {
      const { statusList } = this

      if (typeof statut.id !== 'undefined') {
        const update = statusList.findIndex(s => s.id === statut.id)

        if (update > -1) {
          statusList[update].name = statut.name
        } else {
          statusList.push(statut)
        }

        this.$forceUpdate()
      }
    },

    openModal(status) {
      this.$store.commit(`modal/${OPEN_MODAL}`,
        {
          modalType: 'addStatusModal',
          size: 'sm',
          modalTitle: this.$i18n.t(!status?.id ? 'AddStatus' : 'funnel.ChangeStatus'),
          okButtonTitle: '',
          data: {
            status,
            isTask: this.isTask,
            newPosition: this.statusList.length,
          },
        })
    },

    deleteStatus(id) {
      if (id > 0) {
        this.isLoading = true

        const type = !this.isTask ? `projectStatuses/${DELETE_PROJECT_STATUS}` : `taskStatuses/${DELETE_PROJECT_TASK_STATUS}`

        this.$store.dispatch(type, id)
          .then(() => {
            this.statusList.splice(this.statusList.findIndex(el => Number(el.id) === Number(id)), 1)
          })

          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          })

          .finally(() => { this.isLoading = false })
      } else {
        this.showToast('danger', this.$i18n.t('errors.SOMETHING_WRONG'))
      }
    },

    deleteStatusSure(id) {
      this.showAlert('warning', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('alerts.WontbeAble'))
        .then(() => this.deleteStatus(id))
    },

    sendType(payload) {
      this.isLoading = true

      const type = !this.isTask ? `projectStatuses/${UPDATE_PROJECT_STATUS}` : `taskStatuses/${UPDATE_PROJECT_TASK_STATUS}`

      this.$store.dispatch(type, payload)
        .then(() => {
          const statusToClear = this.statusList.find(el => el.type === payload.type)

          this.statusList.find(el => Number(el.id) === Number(payload.id)).type = payload.type

          if (statusToClear) {
            statusToClear.type = null

            const oldStatusPayload = this.getPayload(statusToClear)

            this.$store.dispatch(type, oldStatusPayload)
              .then(() => {
                this.showToast('success', this.$i18n.t(payload?.id ? 'ProjectUpdated' : 'ProjectAdded'))
              })

              .catch(err => {
                this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
              })

              .finally(() => { this.isLoading = false })
          } else {
            this.showToast('success', this.$i18n.t(payload?.id ? 'ProjectUpdated' : 'ProjectAdded'))
          }
        })

        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })

        .finally(() => { this.isLoading = false })
    },

    update(payload) {
      this.isLoading = true

      const type = !this.isTask ? `projectStatuses/${UPDATE_PROJECT_STATUS}` : `taskStatuses/${UPDATE_PROJECT_TASK_STATUS}`

      this.$store.dispatch(type, payload)
        .then(() => { this.showToast('success', this.$i18n.t(payload?.id ? 'ProjectUpdated' : 'ProjectAdded')) })

        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })

        .finally(() => { this.isLoading = false })
    },

    getPayload(status, fPosition) {
      const {
        id,
        name,
        position,
        type,
      } = status

      const payload = {
        name,
        position,
        type,
      }

      if (id) payload.id = id

      if (fPosition) payload.position = fPosition

      return payload
    },

    changeType(status, type) {
      if (type) {
        const payload = this.getPayload(status)

        payload.type = status.type !== type ? type : null

        this.sendType(payload)
      }
    },

    sortList() {
      const { statusList } = this

      statusList.forEach((_, pos) => { statusList[pos].position = pos })
    },

    saveStatus() {
      const { statusList } = this

      if (statusList.length) {
        this.sortList()

        const payloadList = statusList.map(status => this.getPayload(status))

        if (payloadList.length) this.update(payloadList)
      }
    },
  },
}
</script>
