var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.card ? 'b-card' : 'div',{tag:"component",staticClass:"rounded-lg overflow-hidden",attrs:{"no-body":""}},[_c('b-tabs',{staticClass:"tabs-scroll",staticStyle:{"row-gap":".5rem"},attrs:{"nav-class":"btn-group btn-group-row-gap-0.34","nav-wrapper-class":{
      'bg-white before-bg-tonal-primary border-bottom-light-primary position-relative': _vm.card,
      'bg-white shadow rounded-lg': !_vm.card,
      'sticky-to-top': _vm.sticky
    },"content-class":"px-1 pt-1 mb-0 b-card-body","pills":"","lazy":_vm.lazy,"no-nav-style":"","align":_vm.align,"active-nav-item-class":"opacity-disabled"},on:{"input":function($event){return _vm.$emit('input', $event)}},scopedSlots:_vm._u([(_vm.$slots['tabs-end'])?{key:"tabs-end",fn:function(){return [_vm._t("tabs-end")]},proxy:true}:null],null,true)},[(_vm.tabs && _vm.tabs.length && !_vm.$slots.default)?_vm._l((_vm.tabs),function(tab,i){return _c('b-tab',{key:i,attrs:{"title":_vm.$t(tab.title),"active":(_vm.activeTabKey && _vm.activeTabKey === tab.key) || tab.active,"disabled":tab.disabled,"title-item-class":"btn btn-sm p-0","title-link-class":"px-75 py-75 h-100 d-flex align-items-center justify-content-center"},scopedSlots:_vm._u([(tab.icon)?{key:"title",fn:function(){return [_c('div',{staticClass:"d-flex flex-row align-items-center",staticStyle:{"column-gap":".5rem"}},[_c('feather-icon',{attrs:{"icon":tab.icon}}),(
                !_vm.isMobile
                  && typeof tab.showTitle === 'undefined' ? true : tab.showTitle
              )?_c('span',[_vm._v(" "+_vm._s(_vm.$t(tab.title))+" ")]):_vm._e()],1)]},proxy:true}:null],null,true)},[(tab.component)?_c(tab.component,{tag:"component",attrs:{"update":_vm.update}}):_c('div',{staticClass:"p-2 text-center text-primary"},[_c('feather-icon',{attrs:{"icon":"XCircleIcon"}}),_vm._v(" "+_vm._s(_vm.$t('NoData'))+" ")],1)],1)}):_vm._e(),_vm._t("default")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }