<template>
  <validation-observer
    ref="newFunnelFieldForm"
    v-slot="{ invalid }"
    tag="form"
  >
    <!--      Name      -->
    <validation-provider
      v-slot="{ errors }"
      rules="required"
      :name="$t('Name')"
    >
      <b-form-group
        :label="$t('Name')"
      >
        <b-form-input
          v-model="name"
          :placeholder="$t('Name')"
          :state="errors.length > 0 ? false:null"
        />

        <small class="text-danger">{{ errors[0] }}</small>
      </b-form-group>
    </validation-provider>

    <!--      AmountOfDaysInStatus      -->
    <validation-provider
      v-slot="{ errors }"
      :name="$t('AmountOfDaysInStatus')"
    >
      <b-form-group
        :label="$t('AmountOfDaysInStatus')"
      >
        <b-form-input
          id="maxDurationStay"
          v-model="maxDurationStay"
          :placeholder="$t('AmountOfDaysInStatus')"
          type="number"
          :state="errors.length > 0 ? false:null"
        />

        <small class="text-danger">{{ errors[0] }}</small>
      </b-form-group>
    </validation-provider>

    <!--      ProgressPercent      -->
    <!--    <validation-provider-->
    <!--      v-slot="{ errors }"-->
    <!--      :name="$t('ProgressPercent')"-->
    <!--    >-->
    <!--      <b-form-group-->
    <!--        :label="$t('ProgressPercent')"-->
    <!--      >-->
    <!--        <b-form-input-->
    <!--          id="maxDurationStay"-->
    <!--          v-model="progressPercent"-->
    <!--          :placeholder="$t('ProgressPercent')"-->
    <!--          type="number"-->
    <!--          :state="errors.length > 0 ? false:null"-->
    <!--        />-->

    <!--        <small class="text-danger">{{ errors[0] }}</small>-->
    <!--      </b-form-group>-->
    <!--    </validation-provider>-->

    <!--      Color      -->
    <validation-provider
      v-slot="{ errors }"
      :name="$t('Color')"
    >
      <b-form-group
        :label="$t('Color')"
      >
        <b-form-input
          id="color"
          v-model="color"
          :placeholder="$t('Color')"
          size="sm"
          type="color"
          :state="errors.length > 0 ? false:null"
        />

        <small class="text-danger">{{ errors[0] }}</small>
      </b-form-group>
    </validation-provider>

    <!--      Button: Save      -->
    <b-button
      variant="primary"
      class="w-100"
      :disabled="invalid"
      @click="save()"
    >
      {{ $t('Save') }}
    </b-button>
  </validation-observer>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { CLOSE_MODAL, RELOAD_CONTENT } from '@/@constants/mutations'
import { mapGetters } from 'vuex'
import axiosIns from '@/libs/axios'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data: () => ({
    isLoading: false,

    contactThreadFunnelType: null,
    position: null,

    id: null,
    name: '',
    maxDurationStay: 0,
    progressPercent: 0,
    color: '',
  }),
  computed: {
    ...mapGetters({
      modalConfig: 'modal/getModalState',
    }),
  },
  mounted() {
    this.ini()
  },
  methods: {
    ini() { this.loadConfig() },

    loadConfig() {
      const { data } = this.modalConfig
      const {
        contactThreadFunnelType,
        position,
        id,
        name,
        maxDurationStay,
        progressPercent,
        color,
      } = data

      if (!contactThreadFunnelType) {
        console.error('The contactThreadFunnelType cannot be empty')

        return
      }

      this.contactThreadFunnelType = contactThreadFunnelType
      this.position = position

      if (id) {
        this.id = id
        this.name = name
        this.maxDurationStay = maxDurationStay
        this.progressPercent = progressPercent
        this.color = color
      }
    },

    save() {
      if (this.validationForm()) {
        const payload = this.getPayload()

        this.send(payload)
      }
    },

    getPayload() {
      const {
        contactThreadFunnelType,
        position,

        id,
        name,
        maxDurationStay,
        progressPercent,
        color,
      } = this

      const payload = {
        contactThreadFunnelType,
        name,
        maxDurationStay: Number(maxDurationStay) || 0,
        progressPercent: Number(progressPercent) || 0,
        color,
      }

      if (id) payload.id = id
      if (position) payload.position = position

      return payload
    },

    send(payload) {
      axiosIns.put('1/settings/contactThreadFunnelTypePoints', payload)
        .then(() => {
          this.$store.commit(`modal/${RELOAD_CONTENT}`, { ...payload, progressPercent: 0 })

          this.$nextTick(() => this.close())
        })

        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },

    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.newFunnelFieldForm.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },

    close() { this.$store.commit(`modal/${CLOSE_MODAL}`) },
  },
}
</script>
