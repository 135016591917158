<template>
  <!-- This is minimal example with nothing but a scroll to top component -->
  <scroll-to-top v-if="enableScrollToTop" />
</template>

<script>
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    ScrollToTop,
  },
  setup() {
    const { enableScrollToTop } = $themeConfig.layout

    return {
      enableScrollToTop,
    }
  },
}
</script>
