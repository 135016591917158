<template>
  <component
    :is="tag"
    v-bind="{ ...bindProps, lazy: false, forceShow: true }"
  >
    <div v-show="!defaultPoll">
      <sw-select>
        <v-select
          v-model="poll"
          :options="polls"
          label="name"
          :disabled="!!editedPoll"
          :selectable="option => !disabledPolls.includes(option.id)"
          @input="changePoll"
        />
      </sw-select>
    </div>

    <validation-observer
      v-if="fields.length"
      :ref="defaultPoll ? `required_poll_${defaultPoll.id}` : 'poll_items'"
      tag="div"
      class="mb-1"
    >
      <b-row
        class="d-flex flex-column flex-md-row"
        style="row-gap: 1rem"
      >
        <b-col
          v-for="(field, index) in fields"
          :key="`base_${index}`"
          md="6"
          class="d-flex flex-column"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="field.translations[0].name"
            :rules="isFieldRequired(field)"
            class="d-flex flex-column"
          >
            <!--              :class="{'required': isFieldRequired(field)}"-->
            <div
              class="d-flex flex-column"
            >
              <div class="d-flex flex-row-reverse align-items-center justify-content-between flex-grow-1">
                <feather-icon
                  v-if="field.translations[0].description"
                  v-b-tooltip
                  icon="AlertCircleIcon"
                  class="text-primary ml-1"
                  :title="field.translations[0].description"
                  style="min-width: 1rem"
                />

                <div class="d-flex flex-column flex-grow-1">
                  <label v-if="!['CHECKBOX'].includes(field.type)">
                    {{ field.translations[0].name }}

                    <sw-icon v-if="isFieldRequired(field)"
                             v-b-tooltip
                             icon="AlertCircleIcon"
                             class="text-danger"
                             :title="$t('FieldRequiredForAgreement')"
                    />
                  </label>
                  <b-form-input
                    v-if="['TEXT'].includes(field.type)"
                    v-model="field.value"
                    type="text"
                  />

                  <b-form-checkbox
                    v-else-if="['CHECKBOX'].includes(field.type)"
                    v-model="field.value"
                    variant="primary"
                  >
                    {{ field.translations[0].name }}
                  </b-form-checkbox>

                  <flat-pickr
                    v-else-if="['TIME', 'DATE'].includes(field.type)"
                    v-model="field.value"
                    class="form-control flex-grow-1"
                    :config="{ locale: locale, ...timeConfig(field.type) }"
                  />

                  <b-input-group
                    v-else-if="['DIGITAL', 'PERCENTAGE', 'METRIC'].includes(field.type)"
                    :append="field.type === 'PERCENTAGE' ? '%' : ''"
                    class="input-group-merge flex-grow-1"
                  >
                    <b-form-input
                      v-model="field.value"
                      type="number"
                    />
                  </b-input-group>

                  <div
                    v-else-if="['RADIAL'].includes(field.type)"
                  >
                    <b-form-radio
                      v-for="(fieldItem, fieldIndex) in field.pollFieldOptions"
                      :key="`radio_${field.id}_${fieldIndex}`"
                      v-model="field.pollFieldOptionsValue"
                      class="mb-25"
                      :name="`radio_${field.id}`"
                      :value="fieldItem.id"
                    >
                      {{ fieldItem.translations[0].name }}
                    </b-form-radio>
                  </div>

                  <sw-select
                    v-else-if="['TEXT_ARRAY', 'TEXT_MULTIPLE_ARRAY'].includes(field.type)"
                    class="mb-0"
                  >
                    <v-select
                      v-model="field.pollFieldOptionsValue"
                      :multiple="field.type === 'TEXT_MULTIPLE_ARRAY'"
                      :options="field.pollFieldOptions"
                      :filter="(options, search) => filterByLang(options, search, 'Contracts.LoadTitles.')"
                      :get-option-label="field => field.id"
                    >
                      <template #option="item">
                        {{ item.translations[0].name }}
                      </template>
                      <template #selected-option="item">
                        {{ item.translations[0].name }}
                      </template>
                      <template #no-options>
                        {{ $t('NoOptions') }}
                      </template>
                    </v-select>
                  </sw-select>
                </div>
              </div>

              <small class="text-danger">{{ errors[0] }}</small>
            </div>
          </validation-provider>
        </b-col>
      </b-row>
    </validation-observer>

    <div
      v-else
      class="text-center mb-2 text-primary font-weight-bold"
    >
      <feather-icon
        size="17"
        icon="XCircleIcon"
      />
      {{ $t('NoData') }}
    </div>

    <b-button
      v-if="!defaultPoll"
      :disabled="!poll || !fields.length || loading"
      size="sm"
      variant="primary"
      @click="savePoll"
    >
      {{ $t('Save') }}
    </b-button>

    <template
      v-if="tag !== 'div'"
      #subTitle
    >
      {{ $t('Filled') }}
      <b-badge :variant="getTotalFilledValues() !== getRequiredFieldsValues ? 'light-danger' : 'light-success'">
        {{ getTotalFilledValues() }} / {{ getRequiredFieldsValues }}
      </b-badge>
    </template>
  </component>
</template>

<script>
/* eslint-disable */
import vSelect from 'vue-select'
import { GET_POLLS } from '@/@constants/mutations'
import flatPickr from 'vue-flatpickr-component'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import { BFormRadio, VBTooltip } from 'bootstrap-vue'

export default {
  name: 'NewPollModal',
  components: {
    vSelect,
    flatPickr,
    ValidationObserver,
    ValidationProvider,
    BFormRadio,
  },
  props: {
    areFieldsRequired: {
      type: Boolean,
      default: false,
    },
    tag: {
      default: 'div',
    },
    title: {
      default: '',
    },
    disabledPolls: {
      type: Array,
      default: () => [],
    },
    editedPoll: {
      type: Object,
      default: null,
    },
    defaultPoll: {
      type: Object,
      default: null,
    },
    // pollFieldId.option
    requiredPollFields: {
      type: Array,
      default: null,
    },
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data: () => ({
    poll: '',
    fields: [],
    polls: [],
    loading: false,
    required,
      flag: false,
  }),
  computed: {
    locale() {
      return this.$i18n.locale
    },
    getRequiredFieldsValues() {
      if (this.requiredPollFields) return this.requiredPollFields.filter(field => field.includes('value')).length

      return this.fields.length
    },
    bindProps() {
      if (this.tag !== 'div') {
        return {
          title: this.title,
          subTitle: true,
        }
      }

      return {}
    },
  },
  watch: {
    editedPoll: {
      deep: true,
      handler(newValue) {
        if (newValue?.poll) {
          this.$set(this, 'poll', { ...newValue.poll, id: newValue.id, exist: true })
          this.changePoll(newValue.poll)
          this.editPoll()
        }
      },
    },
  },
  mounted() {
    if (this.defaultPoll) {
      this.$set(this, 'poll', this.defaultPoll)
      this.changePoll(this.defaultPoll)
    }

    if (this.editedPoll) {
      this.$set(this, 'poll', { ...this.editedPoll.poll, id: this.editedPoll.id, exist: true })
      this.changePoll(this.editedPoll.poll)
      this.editPoll()
      return
    }
      if (this.checkRequiredModule('pollModule')) {
          this.$store.dispatch(`polls/${GET_POLLS}`, {})
              .then(res => {
                  this.polls = res.data.items.map(e => ({ ...e, pollFields: e.pollFields.sort(((a, b) => a.position - b.position)) }))
              })
              .catch(err => {
                  this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
              })
      }

  },
  methods: {
    getTotalFilledValues() {
      return this.fields
          .filter((poll, index) => {
            let id = this.getObjectId(poll?.pollField) || poll?.id
            return this.requiredPollFields ? this.requiredPollFields.find(r => r.includes(id + '.value')) : false
          })
          .filter(({ value, pollFieldOptionsValue, type }) => {
            return (type === 'CHECKBOX') || pollFieldOptionsValue?.length || pollFieldOptionsValue?.id || value
          }).length
    },
    isFieldRequired(field) {
      if (this.requiredPollFields) {
        const requiredFieldsIds = this.requiredPollFields.map(fieldCode => {
          const fieldCodeId = fieldCode.split('.')[0]
          return fieldCodeId
        })

        if (field?.type !== 'CHECKBOX' && requiredFieldsIds.includes(this.getObjectId(field?.pollField || field))) return 'required'

        return ''
      } else if (this.areFieldsRequired) return 'required'

      return ''
    },
    editPoll() {
      this.$nextTick(() => {
        const editPolls = JSON.parse(JSON.stringify(this.editedPoll.contactThreadPollFields))
        editPolls.sort(((a, b) => a.position - b.position)).forEach(field => {
          const index = this.fields.sort(((a, b) => a.position - b.position)).findIndex(item => item.id === field.pollField.id)
          if (index !== -1) {
            const payload = {
              ...this.fields[index],
              id: field.id,
              pollField: this.fields[index].id,
              value: this.fields[index].type !== 'CHECKBOX' ? field.value : Boolean(Number(field.value)),
              pollFieldOptionsValue: this.fields[index].type === 'RADIAL' ? field.pollFieldOptions[0]?.id : field.pollFieldOptions,
            }
            this.$set(this.fields, index, payload)
          }
        })
      })
    },
    filterByLang(options = [], search = '') {
      const opt = options.filter(option => {
        if (Array.isArray(option.translations) && option.translations.length) {
          const name = option.translations[0]?.name || ''
          if (name.toLocaleLowerCase().includes(search.toLocaleLowerCase())) {
            return option
          }
        }

        return false
      })

      return opt
    },
    timeConfig(type) {
      return type === 'TIME' ? {enableTime: true, noCalendar: true, dateFormat: 'H:i'} : {}
    },
    changePoll(pool) {
      if (pool) {
          const pollCopy = JSON.parse(JSON.stringify(pool))
        this.fields = pollCopy?.pollFields.sort(((a, b) => a.position - b.position))?.map(field => ({
        ...field,
        value: field.type !== 'CHECKBOX' ? (field.value || '') : (Boolean(field.value) ? '1' : '0'),
      })) } else { this.fields = [] }
    },
    async savePoll() {
        this.flag = true
      if (this.areFieldsRequired || this.requiredPollFields?.length) {
        const ref = this.defaultPoll ? `required_poll_${this.defaultPoll.id}` : 'poll_items'

        const isValid = await this.$refs[ref].validate()
        if (!isValid) return false
      }

      this.loading = true
      const fields = this.fields.sort(((a, b) => a.position - b.position)).map(field => {
        const item = {
          ...field,
          value: field.type !== 'CHECKBOX' ? (field.value || '') : (Boolean(Number(field.value)) ? '1' : '0'),
          pollFieldOptions:
              Array.isArray(field?.pollFieldOptionsValue)
                  ? (field?.pollFieldOptionsValue || []).map(value => (typeof value === 'string' ? value : value?.id))
                  : (typeof field.pollFieldOptionsValue === 'string' ? [field.pollFieldOptionsValue] : (field.pollFieldOptionsValue?.id ? [field.pollFieldOptionsValue?.id] : [])),
        }

        return item
      })

      const preparedFields = fields.sort(((a, b) => a.position - b.position)).map(field => {
        if (!['TEXT_ARRAY', 'TEXT_MULTIPLE_ARRAY', 'RADIAL'].includes(field.type)) delete field.pollFieldOptions
        if (!this.editedPoll) {
          field.pollField = field.id
          delete field.id
        }

        if (!field?.pollField) {
          field.pollField = field.id
          delete field.id
        }

        delete field.pollFieldOptionsValue
        delete field.translations
        delete field.type
        return field
      })

      if (!this.defaultPoll) this.$emit('save-item', preparedFields, this.poll)
      else return { fields: preparedFields.sort(((a, b) => a.position - b.position)), poll: this.poll }
    },
  },
}
</script>

<style lang="scss">
  .required {
    label {
      position: relative;
      &::after {
        color: red;
        content: '*';
        position: absolute;
        right: -8px;
        top: 0;
      }
    }
  }
</style>
