<template>
  <component
    :is="isMobile ? 'div' : 'app-timeline-item'"
    variant="primary"
    icon="FilterIcon"
    class="pb-2 d-flex flex-column justify-content-start align-items-start position-relative"
    style="row-gap: .34rem"
  >
    <slot />

    <component
      :is="isContact ? 'div' : 'b-card'"
      class="w-100 rounded-lg mb-0 position-relative"
      no-body
    >
      <b-card-body :class="`p-${ isContact ? '0' : '1' }`">
        <div
          class="d-flex mb-50"
          style="column-gap: .5rem"
        >
          <b-avatar
            v-if="isMobile"
            rounded="sm"
            size="sm"
            variant="light-primary"
          >
            <span class="d-flex align-items-center">
              <feather-icon
                icon="FilterIcon"
                size="14"
              />
            </span>
          </b-avatar>

          <router-link
            v-if="thread && !isContact"
            class="font-small-3"
            :class="{ 'deleted-text': thread.contact.deletedAt }"
            :to="`/contact/${thread.contact.id}/thread/${thread.id}/timeline`"
          >
            {{ thread.contact.firstName }} {{ thread.contact.lastName }} – {{ thread.name }}
          </router-link>

          <p
            v-else
            class="mb-0"
          >
            {{ $t('CurrentContact') }}
          </p>
        </div>
        <div
          v-if="data"
          class="font-weight-bold w-100 d-flex flex-row flex-wrap justify-content-between"
        >
          <b-alert
            v-if="action !== 'changeStatus'"
            class="w-100 mb-0"
            show
            variant="primary"
          >
            <div class="alert-heading">
              <div
                class="d-flex flex-wrap justify-content-between"
                style="column-gap: .5rem; row-gap: .34rem;"
              >
                <div>
                  <span class="font-weight-normal mx-25"> {{ $t('StatusChangesTo') }}</span> <span v-if="data.contactThreadFunnelTypePoint">
                  <span v-if="data.contactThreadFunnelTypePoint.contactThreadFunnelType">{{ data.contactThreadFunnelTypePoint.contactThreadFunnelType.name }} - </span>
                  {{ data.contactThreadFunnelTypePoint.name }}
                </span>
                  <span class="font-weight-normal mx-25">{{ $t('day') }}</span> <span class="font-italic">{{ formatDate(timelineDate, { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' }, $i18n.locale) }}</span>
                </div>
                <avatar
                  :user="data.createdBy"
                  text-variant="primary"
                />
              </div>
            </div>
          </b-alert>
          <b-alert
            v-else-if="action === 'changeStatus'"
            class="w-100 mb-0"
            show
            :variant="data.status === 'CLOSED_POSITIVE' ? 'success' : (data.status === 'OPEN' ? 'info' : 'danger')"
          >
            <div class="alert-heading">
              <div
                class="d-flex flex-wrap justify-content-between"
                style="column-gap: .5rem; row-gap: .34rem;"
              >
                <div>
                  {{ data.status === 'CLOSED_POSITIVE' ? $t('funnel.status.COMPLETED') : (data.status === 'OPEN' ? $t('WasOpened') : $t('funnel.status.REJECTED')) }}
                  <span v-if="data.status === 'OPEN'"
                        class="font-weight-normal mx-25"
                  >({{ data.contactThreadFunnelTypePoint.contactThreadFunnelType.name }})</span>
                  <span class="font-weight-normal mx-25">{{ $t('day') }}</span>
                  <span class="font-italic">{{ formatDate(timelineDate, { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' }, $i18n.locale) }}</span>
                </div>

                <avatar
                  :user="data.createdBy"
                  :text-variant="data.status === 'CLOSED_POSITIVE' ? 'success' : (data.status === 'OPEN' ? 'primary' : 'danger')"
                />
              <!--              <b-button-->
              <!--                size="sm"-->
              <!--                class="font-weight-bolder text-uppercase"-->
              <!--                :variant="thread.status === 'CLOSED_POSITIVE' ? 'flat-success' : 'flat-danger'"-->
              <!--                @click="openThread"-->
              <!--              >-->
              <!--                {{ $t('Open') }} {{ $t('Thread').toLowerCase() }}-->
              <!--              </b-button>-->
              </div>
            </div>

            <div
              v-if="data.status !== 'OPEN'"
              class="alert-body d-flex flex-wrap flex-column"
            >
              <div v-if="data.contactThreadFunnelTypePoint && data.contactThreadFunnelTypePoint.contactThreadFunnelType">
                <span class="font-weight-bolder">{{ $t('Funnels') }}</span>: {{ data.contactThreadFunnelTypePoint.contactThreadFunnelType.name }} - {{ data.contactThreadFunnelTypePoint.name }}
              </div>
              <div v-if="data.contactThreadStatusReason">
                <span class="font-weight-bolder">{{ $t('CloseReason') }}</span>: {{ data.contactThreadStatusReason.name }}
              </div>
              <div><span class="font-weight-bolder">{{ $t('Description') }}</span>: {{ data.statusNote }}</div>
            </div>
          </b-alert>
        </div>
      </b-card-body>
    </component>

    <hr
      v-if="isContact"
      class="w-100 mb-0"
    >
  </component>
</template>

<script>
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { VBTooltip } from 'bootstrap-vue'
import { DELETE_CONTACT_NOTE, OPEN_MODAL } from '@/@constants/mutations'
import { mapGetters } from 'vuex'
import * as roles from '@/helpers/permissions'
import { formatDate } from '@core/utils/filter'
import useSwUtils from '@/composable/useSwUtils'
import useVerticalLayout from '@core/layouts/layout-vertical/useVerticalLayout'
import { onUnmounted } from '@vue/composition-api'

export default {
  name: 'Note',
  components: { AppTimelineItem },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data: () => ({
    roles,
  }),
  computed: {
    ...mapGetters({
      selectedThread: 'singleContact/getSelectedThread',
      currentUser: 'auth/getCurrentUser',
    }),
  },
  props: ['data', 'timelineDate', 'threadId', 'thread', 'isContact', 'automation', 'action'],
  methods: {
    formatDate,
    deleteNote(noteId) {
      this.showAlert('error', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('alerts.WontbeAble'))
        .then(() => {
          this.$store.dispatch(`singleContact/${DELETE_CONTACT_NOTE}`, { noteId, threadId: this.selectedThread.id })
            .then(() => {
              this.showToast('success', this.$i18n.t('NoteRemoved'))
              this.$emit('remove-item')
            })
            .catch(() => {
              this.showToast('danger', this.$i18n.t('ProblemOccured'))
            })
        })
    },
    editNote(note) {
      this.$store.commit(`modal/${OPEN_MODAL}`,
        {
          modalType: 'note',
          modalTitle: this.$i18n.t('EditNote'),
          okButtonTitle: this.$i18n.t('Save'),
          threadFor: this.threadId,
          editedData: note,
        })
    },
  },
  setup() {
    const { isTimeLimitReached } = useSwUtils()
    const { isMobile, resizeHandler } = useVerticalLayout()

    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    return {
      isTimeLimitReached,
      isMobile,
    }
  },
}
</script>

<style scoped>

</style>
