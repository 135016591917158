var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.tag,{tag:"component",attrs:{"name":_vm.label}},[_c('v-select',{class:_vm.classes,attrs:{"multiple":_vm.isMultiple,"close-on-select":!_vm.isMultiple || _vm.closeOnSelect,"options":_vm.options,"clearable":_vm.isClearable,"item-value":"id","value":_vm.value,"placeholder":_vm.placeholder,"label":"firstName","filterable":false,"disabled":_vm.disabled,"selectable":_vm.isUserSelectable},on:{"search":_vm.onSearch,"input":_vm.onChange},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var id = ref.id;
var avatar = ref.avatar;
var firstName = ref.firstName;
var lastName = ref.lastName;
return [(firstName)?_c('b-avatar',{attrs:{"size":"sm","src":avatar,"text":firstName.slice(0,1).toUpperCase() + lastName.slice(0,1).toUpperCase()}}):_vm._e(),_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(firstName)+" "+_vm._s(lastName)+" "+_vm._s(id === '0' ? ("- " + (_vm.$t('Contact'))) : ''))])]}},{key:"selected-option",fn:function(ref){
var avatar = ref.avatar;
var firstName = ref.firstName;
var lastName = ref.lastName;
return [(firstName)?_c('b-avatar',{staticClass:"border border-white",attrs:{"size":"sm","src":avatar,"text":firstName.slice(0,1).toUpperCase() + lastName.slice(0,1).toUpperCase()}}):_vm._e(),_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(firstName)+" "+_vm._s(lastName))])]}}])},[_c('template',{slot:"no-options"},[_vm._v(" "+_vm._s(_vm.mode === 'users' ? _vm.$t('typeToSearchUser') : _vm.$t('typeToSearchContact'))+" ")])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }