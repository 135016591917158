import {
  DELETE_CHECKLIST,
  DELETE_CONTACT_STATUSES,
  DELETE_NOTES,
  DELETE_STATUSES,
  DELETE_TASKS,
  GET_CHECKLIST,
  GET_CONTACT_STATUSES,
  GET_NOTES,
  GET_STATUSES,
  GET_TASKS,
  RELOAD_SETTINGS,
  SAVE_CONTACT_STATUSES,
  SAVE_NOTES, SAVE_TASKS,
  DELETE_THREAD_TAGS,
  GET_THREAD_TAGS,
  SAVE_THREAD_TAGS,
} from '@/@constants/mutations'
// eslint-disable-next-line import/no-cycle
import axiosIns from '@/libs/axios'
import * as fields from '@/@constants/fields'

export default {
  namespaced: true,
  state: {
    funnelStatuses: [],
    checklist: [],
    notes: [],
    notesToDelete: [],
    tasks: [],
    tasksToDelete: [],
    contactStatuses: [],
    contactStatusesToDelete: [],
    threadTags: [],
    threadTagsToDelete: [],
  },
  getters: {
    getFunnelStatuses: state => state.funnelStatuses,
    getChecklist: state => state.checklist,
    // Notes
    getNotes: state => state.notes,
    getDeleteNotes: state => state.notesToDelete,
    // Tasks
    getTasks: state => state.tasks,
    getDeleteTasks: state => state.tasksToDelete,
    getOfferPolls: state => state.offerPolls,
    // Contact statuses
    geContactStatuses: state => state.contactStatuses,
    getDeleteContactStatuses: state => state.contactStatusesToDelete,
    // Thread Tags
    getThreadTags: state => state.threadTags,
    getDeleteThreadTags: state => state.threadTagsToDelete,
  },
  mutations: {
    SAVE_SETTING_FIELD(state, payload) {
      const { setting, data } = payload
      state[setting] = data
    },
    // Contact statuses
    [GET_CONTACT_STATUSES](state, payload) {
      state.contactStatuses = payload
    },
    [GET_THREAD_TAGS](state, payload) {
      state.threadTags = payload
    },
    ADD_DELETE_CONTACT_STATUSES(state, payload) {
      const statuses = state.contactStatusesToDelete
      if (!statuses.includes(payload)) statuses.push(payload.toString())
      else statuses.splice(statuses.indexOf(payload), 1)
    },
    ADD_DELETE_THREAD_TAGS(state, payload) {
      const tags = state.threadTagsToDelete
      if (!tags.includes(payload)) tags.push(payload.toString())
      else tags.splice(tags.indexOf(payload), 1)
    },
    [GET_STATUSES]() {
    },
    [GET_CHECKLIST]() {
    },
    // Notes
    [GET_NOTES](state, payload) {
      state.notes = payload
    },
    ADD_DELETE_NOTES(state, payload) {
      const notes = state.notesToDelete
      if (!notes.includes(payload)) notes.push(payload.toString())
      else notes.splice(notes.indexOf(payload), 1)
    },
    // Tasks
    [GET_TASKS](state, payload) {
      state.tasks = payload
    },
    ADD_DELETE_TASKS(state, payload) {
      const tasks = state.tasksToDelete
      if (!tasks.includes(payload)) tasks.push(payload.toString())
      else tasks.splice(tasks.indexOf(payload), 1)
    },
  },
  actions: {
    [RELOAD_SETTINGS]: ({ dispatch }) => {
      dispatch(GET_CONTACT_STATUSES)
      dispatch(GET_STATUSES)
      dispatch(GET_CHECKLIST)
      dispatch(GET_NOTES)
      dispatch(GET_TASKS)
      dispatch(GET_THREAD_TAGS)
    },
    // Contact statuses
    [GET_CONTACT_STATUSES]: ({ commit }) => new Promise((resolve, reject) => {
      axiosIns.get('1/settings/contactStatuses', { params: { fields_load: fields.SETTINGS_CONTACTS_STATUSES, orderBy: 'position.asc' } })
        .then(res => {
          commit(GET_CONTACT_STATUSES, res.data.data.items)
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    [DELETE_CONTACT_STATUSES]: ({ state }, statuses) => new Promise((resolve, reject) => {
      axiosIns.delete('1/settings/contactStatuses', { data: JSON.stringify(statuses) })
        .then(() => {
          state.contactStatusesToDelete = []
          resolve()
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [SAVE_CONTACT_STATUSES]: ({ commit, dispatch }, statuses) => new Promise((resolve, reject) => {
      axiosIns.put('1/settings/contactStatuses', statuses)
        .then(() => {
          // dispatch(GET_CONTACT_STATUSES)
          resolve()
        })
        .catch(err => {
          reject(err)
        })
    }),
    // Thread Tags
    [GET_THREAD_TAGS]: ({ commit }) => new Promise((resolve, reject) => {
      axiosIns.get('1/settings/contactTypes', { params: { fields_load: fields.SETTINGS_CONTACTS_TAGS, orderBy: 'position.asc' } })
        .then(res => {
          commit(GET_THREAD_TAGS, res.data.data.items)
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    [DELETE_THREAD_TAGS]: ({ state }, tags) => new Promise((resolve, reject) => {
      axiosIns.delete('1/settings/contactTypes', { data: JSON.stringify(tags) })
        .then(() => {
          state.threadTagsToDelete = []
          resolve()
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [SAVE_THREAD_TAGS]: ({ commit, dispatch }, statuses) => new Promise((resolve, reject) => {
      if (statuses.length) {
        axiosIns.put('1/settings/contactTypes', statuses)
          .then(() => {
            dispatch(GET_THREAD_TAGS)
            resolve()
          })
          .catch(err => {
            reject(err)
          })
      } else {
        dispatch(GET_THREAD_TAGS)
        resolve()
      }
    }),
    // Statuses
    [GET_STATUSES]: ({ commit }) => {
      commit(GET_STATUSES)
    },
    // eslint-disable-next-line no-unused-vars
    [DELETE_STATUSES]: ({ dispatch }, id) => {
      dispatch(DELETE_STATUSES)
    },
    // Checklist
    [GET_CHECKLIST]: ({ commit }) => {
      commit(GET_CHECKLIST)
    },
    // eslint-disable-next-line no-unused-vars
    [DELETE_CHECKLIST]: ({ dispatch }, id) => {
      dispatch(DELETE_CHECKLIST)
    },
    // Notes
    [GET_NOTES]: ({ commit }) => new Promise((resolve, reject) => {
      axiosIns.get('1/settings/contactThreadNoteTypes', { params: { fields_load: fields.SETTINGS_CONTACTS_THREADS_NOTES_TYPES, orderBy: 'position.asc' } })
        .then(res => {
          commit(GET_NOTES, res.data.data.items)
          resolve(res.data.data.items)
        })
        .catch(err => {
          reject(err)
        })
    }),
    [DELETE_NOTES]: ({ state }, notes) => new Promise((resolve, reject) => {
      axiosIns.delete('1/settings/contactThreadNoteTypes', { data: JSON.stringify(notes) })
        .then(() => {
          state.notesToDelete = []
          resolve()
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [SAVE_NOTES]: ({ commit, dispatch }, notes) => new Promise((resolve, reject) => {
      axiosIns.put('1/settings/contactThreadNoteTypes', notes)
        .then(() => {
          // dispatch(GET_NOTES)
          resolve()
        })
        .catch(err => {
          reject(err)
        })
    }),
    // Tasks
    [GET_TASKS]: ({ commit }, filters) => new Promise((resolve, reject) => {
      axiosIns.get('1/settings/contactThreadTaskTypes', {
        params: { fields_load: fields.SETTINGS_CONTACTS_THREADS_TASKS_TYPES, orderBy: 'position.asc', ...filters },
      })
        .then(res => {
          commit(GET_TASKS, res.data.data.items)
          resolve(res.data.data.items)
        })
        .catch(err => {
          reject(err)
        })
    }),
    [DELETE_TASKS]: ({ state }, tasks) => new Promise((resolve, reject) => {
      axiosIns.delete('1/settings/contactThreadTaskTypes', { data: JSON.stringify(tasks) })
        .then(() => {
          state.tasksToDelete = []
          resolve()
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [SAVE_TASKS]: ({ commit, dispatch }, tasks) => new Promise((resolve, reject) => {
      axiosIns.put('1/settings/contactThreadTaskTypes', tasks)
        .then(() => {
          // dispatch(GET_TASKS)
          resolve()
        })
        .catch(err => {
          reject(err)
        })
    }),
  },
}
