<template>
  <div>
    <b-alert
      show
      variant="primary"
      class="w-100 mb-50"
    >
      <div
        class="alert-body d-flex flex-wrap align-items-center justify-content-between"
        style="gap: .5rem"
      >
        <div :class="{ 'd-flex align-items-center justify-content-center': !connections.length }">
          <!--          <span v-if="!connections.length">-->
          <!--            <template v-if="!facebookUserID || !facebookAccessToken">{{ $t('fb.AddConnectionTip') }}</template>-->
          <!--            <template>-->
          <!--              <feather-icon icon="XCircleIcon" />-->
          <!--              {{ $t('NoConnections') }}-->
          <!--            </template>-->
          <!--          </span>-->

          <span v-if="!connections.length">
            {{ $t('fb.NoConnections') }}
          </span>

          <div
            v-else
            class="d-flex flex-wrap"
            style="column-gap: .5rem; row-gap: .34rem"
          >
            <span>
              <b-button
                class="btn-icon mr-25"
                variant="outline-primary"
                size="sm"
                @click="loadConnections"
              >
                <feather-icon icon="RefreshCwIcon" />
              </b-button>
              {{ $t('Connections') }}
            </span>

            <!--    Tak jakby jednak to chcieli   -->
            <!--            :variant="connection.status === CONNECTION_STATUS.ACTIVE ? 'primary' : 'light-primary'"-->
            <!--            :title="connection.status === CONNECTION_STATUS.ACTIVE ? $t('Deactivate') : $t('Activate')"-->
            <!--              @click="changeStatusConnection(connection.id, index)"-->
          </div>
        </div>

        <b-button
          variant="primary"
          size="sm"
          @click="loginProcess"
        >
          <feather-icon
            icon="PlusIcon"
            style="top: 0"
          />
          {{ $t('Add') }}
        </b-button>
        <!--          :is-logged="Boolean(facebookUserID || false)"-->
        <!--        <facebook-button-->
        <!--          ref="FB_BTN"-->
        <!--          @login="loginProcess"-->
        <!--          @logout="logoutProcess"-->
        <!--          @init="initProcess"-->
        <!--        />-->
      </div>
    </b-alert>

    <b-row v-if="connections.length">
      <b-col
        v-for="(connection, index) in connections"
        :key="`connection_${connection.id}_${index}`"
        md="4"
      >
        <b-card
          footer-class="p-50"
          header-bg-variant="primary"
          header-text-variant="light"
          header-class="p-50"
        >
          <template #header>
            <div class="d-flex justify-content-between w-100 pl-1 pr-50">
              <div>
                <strong>{{ connection.name }}</strong>
              </div>

              <div class="d-flex">
                <!--                <div-->
                <!--                  v-b-tooltip-->
                <!--                  :title="$t('Deactivate')"-->
                <!--                  class="cursor-pointer mr-1"-->
                <!--                  :disabled="deactivateLoading"-->
                <!--                  @click="deactivateProcess(connection.id, index)"-->
                <!--                >-->
                <!--                  <feather-icon icon="XIcon" />-->
                <!--                </div>-->

                <!--                <div-->
                <!--                  v-b-tooltip-->
                <!--                  :title="$t('Refresh')"-->
                <!--                  class="cursor-pointer mr-1"-->
                <!--                  @click="loginProcess()"-->
                <!--                >-->
                <!--                  <feather-icon icon="RefreshCwIcon" />-->
                <!--                </div>-->

                <div
                  v-b-tooltip
                  :title="$t('Edit')"
                  class="cursor-pointer"
                  @click="mConnectionEdited = connection; mConnection = true"
                >
                  <feather-icon icon="EditIcon" />
                </div>
              </div>
            </div>
          </template>

          <template #footer>
            <div
              v-b-tooltip
              class="d-flex justify-content-between align-items-center flex-wrap"
              :title="$t('Created')"
            >
              <div />
              <div v-if="connection.createdAt">
                {{ formatDate(connection.createdAt.date, { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' }, $i18n.locale) }}
              </div>
              <div v-else>
                {{ formatDate(new Date(), { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' }, $i18n.locale) }}
              </div>
            </div>
          </template>

          <div
            v-if="connection"
            class="d-flex flex-column"
          >
            <div class="d-flex justify-content-between align-items-center mt-1">
              <div>
                <b-avatar
                  variant="primary"
                  size="25"
                >
                  <feather-icon icon="BookmarkIcon" />
                </b-avatar>
                {{ $t('Token') }}
              </div>

              <div>
                <b-badge
                  v-b-tooltip.hover
                  :variant="connection.userTokenActive || connection.userToken ? 'light-success' : 'light-danger'"
                  pill
                >
                  {{ connection.userTokenActive || connection.userToken ? $t('Active') : $t('InActive') }}
                </b-badge>
              </div>
            </div>
          </div>

          <div
            v-else
            class="p-1 text-center text-primary mt-1 mb-n50"
          >
            <feather-icon icon="XCircleIcon" />
            {{ $t('NoData') }}
          </div>
        </b-card>
      </b-col>
    </b-row>

    <div
      class="w-100 mb-50 d-flex flex-column-reverse flex-md-row align-items-end align-items-md-center justify-content-between"
      style="gap: .5rem"
    >
      <b-alert
        show
        variant="primary"
        class="w-100 mb-0"
      >
        <div class="alert-body">
          <div class="d-flex justify-content-between align-items-center">
            <span>
              <b-button
                class="btn-icon mr-25"
                variant="outline-primary"
                size="sm"
                @click="loadWebhooks"
              >
                <feather-icon icon="RefreshCwIcon" />
              </b-button>
              {{ $t('AuthorizeNumbers') }}
            </span>

            <div class="d-flex justify-content-center">
              <!--                :disabled="!facebookUserID || !facebookAccessToken"-->
              <b-button
                variant="primary"
                size="sm"
                :disabled="!connections.length"
                @click="() => { mWebhook = true }"
              >
                <feather-icon
                  icon="PlusIcon"
                  style="top: 0"
                />

                {{ $t('Add') }}
              </b-button>
            </div>
          </div>
        </div>
      </b-alert>
    </div>

    <sw-table
      table-id="6f4be455aee84ed8abe78ebb5c313258"
      :show-columns="false"
      :show-search="false"
      :show-pagination="false"
      :show-refresh="false"
    >
      <template #table>
        <b-card no-body>
          <b-table
            ref="selectableTable"
            :style="{ 'min-height': '0 !important' }"
            :items="webhooks"
            class="table-wrap-words mx-0 px-0 pb-0 mb-0"
            :fields="webhookFields"
            striped
            responsive
            :busy="loading"
            show-empty
          >
            <template #head()="{ label }">
              {{ $t(`${label}`) }}
            </template>

            <template #cell(integrationWhatsAppSubscription)="{ value }">
              <div
                v-if="value && value.integrationWhatsAppConnection"
                class="d-flex align-items-center"
              >
                <b-badge
                  :variant="value.integrationWhatsAppConnection.userTokenActive ? 'light-success' : 'light-danger'"
                  pill
                >
                  <feather-icon :icon="value.integrationWhatsAppConnection.userTokenActive ? 'CheckIcon' : 'XIcon'" />
                </b-badge>
                <span class="ml-25">{{ value.integrationWhatsAppConnection.name }}</span>
              </div>
              <feather-icon
                v-else
                icon="MinusIcon"
              />
            </template>

            <template #cell(status)="{ item }">
              <b-badge
                v-if="item.status !== 'ACTIVE'"
                variant="light-danger"
              >
                <feather-icon icon="XIcon" />
                {{ $t('NotActive2') }}
              </b-badge>
              <b-badge
                v-else
                variant="light-success"
              >
                <feather-icon icon="CheckIcon" />
                {{ $t('Active2') }}
              </b-badge>
            </template>

            <template #cell(action)="{ item, index }">
              <b-spinner v-if="item.deactivateLoading"
                         small
              />
              <b-button
                v-else-if="item.status === 'ACTIVE'"
                v-b-tooltip.left
                size="sm"
                variant="flat-danger"
                :title="$t('Deactivate')"
                :disabled="deactivateLoading"
                @click="changeStatusProcess(item.integrationWhatsAppSubscription.integrationWhatsAppConnection.id, item.id, index, 'DEACTIVATE')"
              >
                <feather-icon icon="XIcon" />
                {{ $t('Deactivate') }}
              </b-button>
              <div v-else
                   :id="`status_change_${index}`"
              >
                <b-button
                  size="sm"
                  variant="flat-success"
                  class="cursor-pointer mr-1"
                  :disabled="deactivateLoading"
                >
                  <feather-icon icon="CheckIcon" />
                  {{ $t('Activate') }}
                </b-button>
              </div>
              <b-popover :target="`status_change_${index}`"
                         triggers="click"
              >
                <template #title>
                  {{ $t('AuthorizationPin') }}
                </template>
                <div>
                  <pincode-input
                    v-model="pin"
                    :length="6"
                    class="pincode-input-sm"
                  />
                </div>
                <b-button size="sm"
                          class="mt-50 w-100"
                          variant="primary"
                          :disabled="String(pin).length !== 6 || deactivateLoading"
                          @click="changeStatusProcess(item.integrationWhatsAppSubscription.integrationWhatsAppConnection.id, item.id, index, 'ACTIVE')"
                >
                  {{ $t('Activate') }}
                </b-button>
              </b-popover>
            </template>

              <!--    Other    -->
              <template #cell()="row">
                  <table-default-cell
                          :field="row.field"
                          :value="row.value"
                  />
              </template>

              <!--      Empty      -->
              <template #empty>
                  <empty-content />
              </template>

              <!--      Table Busy      -->
              <template #table-busy>
                  <div class="text-center text-danger my-2">
                      <b-spinner
                              class="align-middle"
                              variant="primary"
                      />
                  </div>
              </template>
          </b-table>
        </b-card>
      </template>
    </sw-table>

    <!--    <b-row-->
    <!--      v-if="webhooks.length && !loading"-->
    <!--      class="mt-1"-->
    <!--    >-->
    <!--      <b-col-->
    <!--        v-for="(webhook, index) in webhooks"-->
    <!--        :key="`webhook_${webhook.id}_${index}`"-->
    <!--        sm="12"-->
    <!--        md="6"-->
    <!--      >-->
    <!--        <b-card-->
    <!--          footer-class="p-50"-->
    <!--          header-bg-variant="primary"-->
    <!--          header-text-variant="light"-->
    <!--          header-class="p-50"-->
    <!--        >-->
    <!--          <template #header>-->
    <!--            <div class="d-flex justify-content-between w-100 px-1">-->
    <!--              <div>-->
    <!--                <strong>{{ webhook.integrationWhatsAppConnection.name }}</strong>-->
    <!--                {{ `– ${webhook.name || '-'}` }}-->
    <!--              </div>-->

    <!--              <div-->
    <!--                v-b-tooltip-->
    <!--                :title="$t('Delete')"-->
    <!--                class="cursor-pointer"-->
    <!--                @click="deleteWebhook(webhook.id, webhook.integrationWhatsAppConnection.id, index)"-->
    <!--              >-->
    <!--                <feather-icon-->
    <!--                  icon="TrashIcon"-->
    <!--                />-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </template>-->

    <!--          <div class="d-flex flex-column">-->
    <!--            <div class="d-flex justify-content-between align-items-center mt-1">-->
    <!--              &lt;!&ndash;                Business&ndash;&gt;-->
    <!--              &lt;!&ndash;                &ndash;&gt;-->
    <!--              <div-->
    <!--                v-b-tooltip.v-primary-->
    <!--                :title="$t('Business')"-->
    <!--              >-->
    <!--                <b-avatar-->
    <!--                  variant="primary"-->
    <!--                  size="25"-->
    <!--                >-->
    <!--                  <feather-icon icon="BookmarkIcon" />-->
    <!--                </b-avatar>-->

    <!--                {{ webhook.businessName }}-->
    <!--              </div>-->

    <!--              <span-->
    <!--                v-b-tooltip.v-success-->
    <!--                class="text-success cursor-pointer"-->
    <!--                :title="`${$t('Copy')} ID`"-->
    <!--                @click="copyText(webhook.businessId)"-->
    <!--              >-->
    <!--                #{{ webhook.businessId }}-->
    <!--              </span>-->
    <!--            </div>-->

    <!--            <div class="d-flex justify-content-between align-items-center mt-1">-->
    <!--              <div-->
    <!--                v-b-tooltip.v-primary-->
    <!--                :title="$t('WhatsappAccount')"-->
    <!--              >-->
    <!--                <b-avatar-->
    <!--                  variant="primary"-->
    <!--                  size="25"-->
    <!--                >-->
    <!--                  <feather-icon icon="ListIcon" />-->
    <!--                </b-avatar>-->

    <!--                {{ webhook.whatsAppAccountName }}-->
    <!--              </div>-->

    <!--              <span-->
    <!--                v-b-tooltip.v-success-->
    <!--                class="text-success cursor-pointer"-->
    <!--                :title="`${$t('Copy')} ID`"-->
    <!--                @click="copyText(webhook.whatsAppAccountId)"-->
    <!--              >-->
    <!--                #{{ webhook.whatsAppAccountId }}-->
    <!--              </span>-->
    <!--            </div>-->
    <!--          </div>-->

    <!--          <template #footer>-->
    <!--            <div-->
    <!--              v-b-tooltip-->
    <!--              class="d-flex justify-content-between align-items-center"-->
    <!--              :title="$t('Created')"-->
    <!--            >-->
    <!--              <avatar :user="webhook.createdBy" />-->
    <!--              <div v-if="webhook.createdAt">-->
    <!--                {{ formatDate(webhook.createdAt.date, { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' }, $i18n.locale) }}-->
    <!--              </div>-->
    <!--              <div v-else>-->
    <!--                {{ formatDate(new Date(), { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' }, $i18n.locale) }}-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </template>-->
    <!--        </b-card>-->
    <!--      </b-col>-->
    <!--    </b-row>-->

    <!--    <div-->
    <!--      v-else-->
    <!--      class="text-center py-50 font-weight-bold text-primary"-->
    <!--    >-->
    <!--      <template v-if="loading">-->
    <!--        <b-spinner-->
    <!--          small-->
    <!--          variant="primary"-->
    <!--        />-->

    <!--        {{ $t('Loading') }}-->
    <!--      </template>-->

    <!--      <template v-else>-->
    <!--        <feather-icon-->
    <!--          size="17"-->
    <!--          icon="XCircleIcon"-->
    <!--        />-->
    <!--        {{ $t('NoData') }}-->
    <!--      </template>-->
    <!--    </div>-->

    <!--    START::Modal -> Connects    -->
    <b-modal
      v-model="mConnection"
      :title="$t('Connection')"
      hide-footer
      no-close-on-backdrop
      @hidden="closeConnectionModal"
    >
      <b-alert
        v-if="mConnectionEdited && mConnectionEdited.userId"
        variant="primary"
        show
      >
        <div class="alert-body">
          {{ $tc('RefreshConnection', 1, { name: mConnectionEdited.name }) }}
          <!--          Połączenie "{{ mConnectionEdited.name }}" zostanie odświeżone, w tym momencie możesz zmienić jego nazwę.-->
        </div>
      </b-alert>
      <validation-observer
        ref="newContactFieldForm"
        v-slot="{ invalid }"
        tag="form"
      >
        <validation-provider
          v-slot="{ errors }"
          rules="required"
          :name="$t('Name')"
        >
          <b-form-group>
            <b-form-input
              id="basicInput"
              v-model="mConnectionEdited.name"
              :placeholder="$t('Name')"
              :state="errors.length > 0 ? false:null"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <!-- Footer -->
        <div class="d-flex justify-content-end pt-1 mt-1 border-top">
          <b-button
            variant="secondary mr-25"
            size="sm"
            @click="closeConnectionModal"
          >
            {{ $t('Cancel') }}
          </b-button>

          <b-button
            :disabled="invalid || loading"
            variant="primary"
            size="sm"
            @click="saveConnection"
          >
            {{ $t('Save') }}
          </b-button>
        </div>
      </validation-observer>
    </b-modal>
    <!--    END::Modal -> Connects    -->

    <!--    START::Modal -> Webhooks    -->
    <b-modal
      v-model="mWebhook"
      :title="$t('Account')"
      hide-footer
      no-close-on-backdrop
      @hidden="closeWebhookModal"
    >
      <validation-observer
        ref="newContactFieldForm"
        v-slot="{ invalid }"
        tag="form"
      >
        <!--        <validation-provider-->
        <!--          v-slot="{ errors }"-->
        <!--          rules="required"-->
        <!--          :name="$t('Name')"-->
        <!--        >-->
        <!--          <b-form-group :label="$t('Name')">-->
        <!--            <b-form-input-->
        <!--              id="basicInput"-->
        <!--              v-model="mWebhookEdited.name"-->
        <!--              :placeholder="$t('Name')"-->
        <!--              :state="errors.length > 0 ? false:null"-->
        <!--            />-->

        <!--            <small class="text-danger">{{ errors[0] }}</small>-->
        <!--          </b-form-group>-->
        <!--        </validation-provider>-->

        <validation-provider
          v-slot="{ errors }"
          rules="required"
          :name="$t('Connection')"
        >
          <sw-select
            :name="$t('Connection')"
            :state="errors.length > 0 ? false:null"
          >
            <!--                :selectable="c => c.status === CONNECTION_STATUS.ACTIVE"-->
            <v-select
              v-model="mWebhookEdited.connection"
              :options="connections"
              :reduce="item => item.id"
              label="name"
              @input="() => {
                loadConnectionBusinesses(mWebhookEdited.connection)
                mWebhookEdited.page = null
                mWebhookEdited.form = null
              }"
            >
              <template #no-options>
                {{ $t('NoOptions') }}
              </template>
            </v-select>

            <small class="text-danger">{{ errors[0] }}</small>
          </sw-select>
        </validation-provider>

        <validation-provider
          v-slot="{ errors }"
          rules="required"
          :name="$t('WhatsappAccount')"
        >
          <sw-select
            :name="$t('WhatsappAccount')"
            :state="errors.length > 0 ? false : null"
            :disabled="!mWebhookEdited.connection"
          >
            <v-select
              v-model="mWebhookEdited.page"
              label="name"
              :options="loadedAccounts"
              :disabled="!mWebhookEdited.connection"
              :reduce="item => ({ name: item.name, pageId: item.id })"
              @input="() => {
                loadConnectionForm(mWebhookEdited.connection, mWebhookEdited.page.pageId)

                mWebhookEdited.form = null
              }"
            >
              <template #no-options>
                {{ $t('NoOptions') }}
              </template>
            </v-select>

            <small class="text-danger">{{ errors[0] }}</small>
          </sw-select>
        </validation-provider>

        <validation-provider
          v-slot="{ errors }"
          rules="required"
          :name="$t('ChoosePhoneNumber')"
        >
          <sw-select
            :name="$t('ChoosePhoneNumber')"
            :state="errors.length > 0 ? false:null"
            :disabled="!mWebhookEdited.connection || !mWebhookEdited.page"
          >
            <v-select
              v-model="mWebhookEdited.form"
              label="display_phone_number"
              :options="loadedWhatsappNumbers"
              :reduce="item => ({ name: item.display_phone_number, formId: item.id })"
              :disabled="!mWebhookEdited.connection || !mWebhookEdited.page"
            >
              <template #no-options>
                {{ $t('NoOptions') }}
              </template>
            </v-select>

            <small class="text-danger">{{ errors[0] }}</small>
          </sw-select>
        </validation-provider>

        <div class="d-flex align-items-center justify-content-between flex-wrap">
          {{ $t('AuthorizationPin') }}
          <pincode-input
            v-model="mWebhookEdited.pin"
            :length="6"
            :disabled="!mWebhookEdited.form"
          />
        </div>

        <!-- Footer -->
        <div class="d-flex justify-content-end pt-1 mt-1 border-top">
          <b-button
            variant="secondary mr-25"
            size="sm"
            @click="closeWebhookModal"
          >
            {{ $t('Cancel') }}
          </b-button>

          <b-button
            :disabled="invalid || loading || String(mWebhookEdited.pin).length !== 6"
            variant="primary"
            size="sm"
            @click="saveWebhook"
          >
            {{ $t('Save') }}
          </b-button>
        </div>
      </validation-observer>
    </b-modal>
    <!--    END::Modal -> Webhooks    -->
  </div>
</template>

<script>
import axiosIns from '@/libs/axios'
import { BPopover, BTable, VBTooltip } from 'bootstrap-vue'
import payloadKeysCutter from '@/helpers/keysCuter'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import { formatDate } from '@core/utils/filter'
import PincodeInput from 'vue-pincode-input'

export default {
  name: 'Facebook',
  components: {
    BTable,
    ValidationObserver,
    ValidationProvider,
    vSelect,
    PincodeInput,
    BPopover,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data: () => ({
    pin: '',
    loading: false,

    CONNECTION_STATUS: {
      ACTIVE: 'ACTIVE',
      DEACTIVATED: 'DEACTIVATED',
    },

    webhookFields: [
      // { key: 'id', label: '#' },
      { key: 'status', label: 'Status' },
      { key: 'whatsAppPhoneNumberDisplay', label: 'Phone' },
      { key: 'integrationWhatsAppSubscription', label: 'Connection' },
      { key: 'whatsAppPhoneNumberName', label: 'Account' },
      { key: 'createdAt', label: 'CreatedTime' },
      { key: 'action', label: 'Action' },
    ],

    facebookAccessToken: null,
    facebookUserID: null,
    connections: [],
    webhooks: [],
    fbUser: null,

    FB_SDK: null,

    connectionLoading: false,
    mConnection: false,
    mWebhook: false,

    mConnectionEdited: {
      name: '',
    },
    mWebhookEdited: {
      pin: '',
      id: null,
      name: '',
      connection: null,
      page: null,
      form: null,
    },
    loadedAccounts: [],
    loadedWhatsappNumbers: [],

    emptyConnection: {
      name: '',
    },

    emptyWebhook: {
      id: null,
      name: '',
      connection: null,
      page: null,
      form: null,
    },
    removeLoading: false,
    deactivateLoading: false,
  }),
  async mounted() {
    await this.loadConnections()
    await this.loadWebhooks()

    if (this.$route.query?.fb_user_token) {
      const r = await axiosIns.put('1/integration/whatsapp/connections', { code: this.$route.query.fb_user_token })
      if (!r.data?.data?.items?.length) return

      this.facebookAccessToken = this.$route.query.fb_user_token
      this.facebookUserID = this.$route.query.fb_user
      this.mConnectionEdited.name = r.data.data.items[0].name
      this.mConnectionEdited.id = r.data.data.items[0].id

      const existedConnection = this.connections.findWhere('id', r.data.data.items[0].id)
      if (existedConnection) {
        this.mConnectionEdited = { ...existedConnection, reauthorize: true }
      }

      this.mConnection = true
      window.history.replaceState({}, null, '/')
    }
  },
  methods: {
    formatDate,
    closeConnectionModal() {
      this.mConnection = false
      this.$set(this, 'mConnectionEdited', this.emptyConnection)

      // this.FB_SDK.FB.logout()
      // this.logoutProcess()

      this.$forceUpdate()
    },
    closeWebhookModal() {
      this.mWebhook = false
      this.$set(this, 'mWebhookEdited', this.emptyWebhook)

      this.$forceUpdate()

      this.loadConnectionBusinesses()
    },
    async loadConnections() {
      this.connectionLoading = true

      try {
        const resp = await axiosIns.get('1/integration/whatsapp/connections', {
          params: {
            fields_load: 'WHATSAPP_CONNECTIONS',
          },
        })

        this.connections = resp?.data?.data?.items || []
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.connectionLoading = false
      }
    },

    async loadWebhooks() {
      this.loading = true

      try {
        const res = await axiosIns.get('1/integration/whatsapp/connections/subscriptionPhoneNumbers', { params: { fields_load: 'WHATSAPP_SUBSCRIPTION' } })
        const webhooks = res?.data?.data?.items?.sort((a, b) => new Date(b.createdAt.date) - new Date(a.createdAt.date)).map(e => ({ ...e, deactivateLoading: false }))

        this.webhooks = webhooks || []
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }

      this.loading = false
    },

    async loadConnectionBusinesses(connectionId) {
      if (!connectionId) return

      try {
        const res = await axiosIns.get(`1/integration/whatsapp/connections/${connectionId}/whatsappAccounts`)
        this.loadedAccounts = res?.data?.data ?? []
      } catch (err) {
        this.showToast('danger', this.$i18n.t('ConnectionRefused'), err)
      }
    },
    async loadConnectionForm(connectionId, pageId) {
      if (!connectionId || !pageId) return

      try {
        const res = await axiosIns.get(`1/integration/whatsapp/connections/${connectionId}/whatsappAccounts/${pageId}/phoneNumbers`)
        this.loadedWhatsappNumbers = res?.data?.data ?? []
      } catch (err) {
        this.showToast('danger', this.$i18n.t('ConnectionRefused'), err)
      }
    },
    async changeStatusProcess(connectionId, id, index, status = 'ACTIVE') {
      const confirmed = await this.showAlert('warning', this.$i18n.t('alerts.AreYouSure'))
      if (!confirmed) return

      this.$set(this.webhooks[index], 'deactivateLoading', true)
      try {
        const payload = {
          status,
          id,
        }

        if (status === 'ACTIVE') {
          payload.pin = Number(this.pin)
        }

        await axiosIns({
          method: 'PATCH',
          url: `1/integration/whatsapp/connections/${connectionId}/subscriptionPhoneNumbers`,
          data: payload,
        })

        this.$set(this.webhooks[index], 'status', status)
        this.showToast('success', this.$i18n.t('success.contactUpdated'))
        this.pin = ''
      } catch (err) {
        if (err?.response?.data?.message === 'INVALID_CREDENTIALS') {
          this.showToast('danger', this.$i18n.t('TypedPinIsInvalid'), err)
        } else this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.$set(this.webhooks[index], 'deactivateLoading', false)
      }
    },
    async saveConnection() {
      this.loading = true

      // if (!this.mConnectionEdited?.id) {
      //   if (!this.facebookUserID || !this.facebookAccessToken) {
      //     this.loading = false
      //
      //     return
      //   }
      // }

      try {
        const payload = {
          // userId: this.facebookUserID,
          // userToken: this.facebookAccessToken,
          ...this.mConnectionEdited,
        }

        if (payload?.id) delete payload.userId
        if (payload?.id && !payload?.reauthorize) delete payload.userToken

        payloadKeysCutter(payload, ['pages'])

        await axiosIns({
          method: payload?.id ? 'PATCH' : 'POST',
          url: '1/integration/whatsapp/connections',
          data: payload,
        })

        await this.loadConnections()
        this.showToast('success', this.$i18n.t('success.contactUpdated'))
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.closeConnectionModal()
        this.loading = false
      }
    },

    async saveWebhook() {
      this.loading = true

      const {
        connection,
        page,
        form,
        pin,
      } = this.mWebhookEdited

      if (!(connection && page && form && pin)) {
        this.showToast('danger', this.$i18n.t('notComplete'))
        this.loading = false

        return
      }

      try {
        const payload = {
          status: 'ACTIVE',
          integrationWhatsAppConnection: connection,
          // Number
          whatsAppPhoneNumberId: form.formId,
          whatsAppPhoneNumberName: form.name,
          // Account
          whatsAppAccountId: page.pageId,
          whatsAppAccountName: page.name,
          pin: Number(pin),
        }

        await axiosIns({
          method: 'POST',
          url: `1/integration/whatsapp/connections/${connection}/subscriptionPhoneNumbers`,
          data: payload,
        })

        this.showToast('success', this.$i18n.t('notifications.actions.add'))

        await this.loadWebhooks()
        this.closeWebhookModal()
      } catch (err) {
        if (err?.response?.data?.message === 'INVALID_CREDENTIALS') {
          this.showToast('danger', this.$i18n.t('TypedPinIsInvalid'), err)
        } else this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.loading = false
      }
    },
    // eslint-disable-next-line no-unused-vars
    async deleteWebhook(id, connection, index) {
      const accepted = await this.showAlert('error', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('alerts.WontbeAble'))
      if (!accepted) return

      try {
        const resp = await axiosIns.delete(`1/integration/whatsapp/connections/${connection}/subscriptions`, { data: JSON.stringify([id]) })

        if (resp) this.$delete(this.webhooks, index)
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },

    loginProcess() {
      const { origin } = window.location
      window.location.replace(`https://whatsapp.saleswizardapp.com/?app=${origin}`)
    },
    logoutProcess() {
      this.facebookAccessToken = null
      this.facebookUserID = null
    },
    initProcess(e) {
      this.$set(this, 'FB_SDK', e)
    },

    copyText(txt) {
      this.showToast('success', this.$i18n.t('CopiedToClipboard'))

      navigator.clipboard.writeText(txt)
    },

  },
}
</script>

<style lang="scss">
.collapse-integration-card {
  .card-header {
    padding-left: 0 !important;
  }
}
</style>
