<template>
  <div class="d-flex flex-column flex-wrap justify-content-between">
    <label
      class="d-flex flex-row align-items-center h6"
      style="column-gap: .34rem"
    >
      {{ count }}
      {{ $t(title) }}
    </label>

    <b-alert
      :variant="variant"
      show
      :style="`width: ${value}%`"
      style="min-width: min-content; max-width: 100%"
      class="overflow-hidden"
    >
      <div class="alert-body text-center text-nowrap py-25">
        <template v-if="beforeValue">
          {{ beforeValue }} /
        </template>

        {{ value }}%
      </div>
    </b-alert>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
      default: '',
    },
    variant: {
      type: String,
      required: false,
      default: 'primary',
    },
    value: {
      type: Number,
      required: true,
      default: 0,
    },
    beforeValue: {
      type: Number,
      required: false,
      default: 0,
    },
    count: {
      type: Number,
      required: false,
      default: 0,
    },
  },
}
</script>
