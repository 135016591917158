<template>
  <b-card>
    <campaigns
      :id="contact.id"
    />
  </b-card>
</template>

<script>
import { mapGetters } from 'vuex'
import Campaigns from '@/pages/campaigns/List.vue'

export default {
  components: { Campaigns },
  computed: {
    ...mapGetters({
      contact: 'contact/contact',
    }),
  },
}
</script>
