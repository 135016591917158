import {
  GET_PRODUCT_TEMPLATE,
  GET_PRODUCT_TEMPLATES,
  SAVE_PRODUCT_TEMPLATE,
  UPDATE_PRODUCT_TEMPLATE, DELETE_PRODUCT_TEMPLATE,
} from '@/@constants/mutations'
// eslint-disable-next-line import/no-cycle
import axiosIns from '@/libs/axios'
import * as fields from '@/@constants/fields'

export default {
  namespaced: true,
  actions: {
    // eslint-disable-next-line no-unused-vars
    [GET_PRODUCT_TEMPLATE]: ({ commit }, id) => new Promise((resolve, reject) => {
      axiosIns.get(`1/settings/agreementTemplates/components/${id}`, { params: { fields_load: fields.SETTINGS_AGREEMENTS_TEMPLATES_COMPONENTS } })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [GET_PRODUCT_TEMPLATES]: ({ commit }, { pagination = null, filters = null }) => new Promise((resolve, reject) => {
      const params = { fields_load: fields.SETTINGS_AGREEMENTS_TEMPLATES_COMPONENTS }

      if (pagination) {
        params.page = pagination.currentPage
        params.limit = pagination.perPage
      }

      if (filters?.type) {
        // eslint-disable-next-line no-unused-expressions
        Array.isArray(filters.type)
          ? params.eqArr_type = JSON.stringify(filters.type)
          : params.eq_type = filters.type
      }

      axiosIns.get('1/settings/agreementTemplates/components', { params })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [DELETE_PRODUCT_TEMPLATE]: ({ commit }, id) => new Promise((resolve, reject) => {
      axiosIns.delete('1/settings/agreementTemplates/components', { data: [id] })
        .then(() => {
          resolve()
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [SAVE_PRODUCT_TEMPLATE]: ({ commit }, template) => new Promise((resolve, reject) => {
      axiosIns.post('1/settings/agreementTemplates/components', template)
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [UPDATE_PRODUCT_TEMPLATE]: ({ commit }, template) => new Promise((resolve, reject) => {
      axiosIns.patch('1/settings/agreementTemplates/components', template)
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
  },
}
