var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.rootUserId)?_c('b-card',{staticClass:"mb-1"},[_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('div',{staticClass:"flex-grow-1 mr-1 order-2 mb-50"},[_c('b-input-group',{staticClass:"input-group-merge search-input-group mx-25 mb-25",staticStyle:{"min-width":"150px"}},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"SearchIcon"}})],1),_c('b-form-input',{staticClass:"input-search",attrs:{"placeholder":_vm.$t('Search')},on:{"input":function($event){_vm.isTyping = true},"blur":_vm.init},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('sw-select',{attrs:{"name":_vm.$t('SelectYear')}},[_c('v-select',{attrs:{"options":_vm.years},on:{"input":function($event){_vm.filters.month = ''; _vm.init()}},model:{value:(_vm.filters.year),callback:function ($$v) {_vm.$set(_vm.filters, "year", $$v)},expression:"filters.year"}})],1)],1),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('sw-select',{attrs:{"name":_vm.$t('SelectMonth')}},[_c('v-select',{attrs:{"disabled":!_vm.filters.year,"options":_vm.months,"reduce":function (d) { return d.value; },"selectable":function (monthVal) { return _vm.filters.year !== _vm.currentYear || monthVal.value <= _vm.currentMonth; }},on:{"input":function($event){return _vm.init()}},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('NoOptions'))+" ")]},proxy:true},{key:"option",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(("" + label)))+" ")]}},{key:"selected-option",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(("" + label)))+" ")]}}],null,false,672003112),model:{value:(_vm.filters.month),callback:function ($$v) {_vm.$set(_vm.filters, "month", $$v)},expression:"filters.month"}})],1)],1),_c('b-col',{attrs:{"sm":"12"}},[(_vm.checkRequiredModule('marketingModule') && _vm.checkRequiredPermissions([_vm.$roles.MARKETING_PLAN_VIEW_ALL]))?_c('div',[_c('sw-select',{attrs:{"name":_vm.$t('SelectMarketingPlan')}},[_c('v-select',{attrs:{"options":_vm.marketingPlans,"label":"name","reduce":function (plan) { return plan.id; },"clearable":false},on:{"input":function($event){return _vm.init()}},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('NoOptions'))+" ")]},proxy:true}],null,false,2260934444),model:{value:(_vm.filters.marketingPlan),callback:function ($$v) {_vm.$set(_vm.filters, "marketingPlan", $$v)},expression:"filters.marketingPlan"}})],1)],1):_vm._e()]),_c('b-col',{attrs:{"sm":"12"}},[_c('div',{staticClass:"d-flex"},[_c('span',[_vm._v(_vm._s(_vm.$t('userRole'))+":")]),_vm._l((_vm.ranks),function(rank){return _c('b-badge',{key:("rank_" + (rank.id)),staticClass:"cursor-pointer ml-25",attrs:{"variant":_vm.filters.ranks.includes(rank.id) ? 'primary' : 'light-primary'},on:{"click":function () {
                     var existIndex = _vm.filters.ranks.findIndex(function (selectedRank) { return selectedRank === rank.id; })
                     if (existIndex > -1) { _vm.$delete(_vm.filters.ranks, existIndex) }
                     else { _vm.filters.ranks.push(rank.id) }

                     _vm.init()
                   }}},[_vm._v(" "+_vm._s(rank.name)+" ")])})],2)])],1)],1):_vm._e(),(!_vm.loading && !_vm.isTyping)?_c('div',[(_vm.items.length)?_c('div',_vm._l((_vm.items),function(item,index){return _c('sw-multilevel-structure',{key:index,attrs:{"root-item":item,"show-root":"","off":!item['children'].length,"show-childs":"","child-key":"children"},scopedSlots:_vm._u([{key:"root-title",fn:function(ref){
                   var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"font-small-3"},[(item && item.user)?_c('avatar',{attrs:{"user":item.user}}):_c('feather-icon',{attrs:{"icon":"MinusIcon"}})],1),_c('b-badge',{staticClass:"ml-25 font-small-2",attrs:{"variant":"primary"}},[_c('feather-icon',{attrs:{"icon":"ToolIcon"}}),(item.user && item.user.userId)?_c('span',{staticClass:"ml-25"},[_vm._v(_vm._s(item.user.userId))]):_c('feather-icon',{attrs:{"icon":"MinusIcon"}})],1),_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"ml-25 font-small-2",attrs:{"variant":"primary","title":_vm.$t('SellProducts')}},[_c('feather-icon',{attrs:{"icon":"ShoppingCartIcon"}}),_vm._v(" "+_vm._s(item.products || 0)+" ")],1)],1)]}}],null,true)})}),1):_c('div',{staticClass:"text-center py-1 text-primary font-weight-bold"},[_c('feather-icon',{attrs:{"size":"17","icon":"XCircleIcon"}}),_vm._v(" "+_vm._s(_vm.$t('NoData'))+" ")],1)]):_c('b-card',[_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('b-spinner',{attrs:{"variant":"primary"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }