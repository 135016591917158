<template>
  <div>
    <b-button
      variant="primary"
      class=" btn btn-icon btn-sm mr-50 mr-lg-25"
      @click="mMain = true"
    >
      <feather-icon icon="MoveIcon" />
    </b-button>

    <b-modal
      v-model="mMain"
      hide-footer
      :title="$t('ChooseStatus')"
      tabindex="none"
      size="lg"
      modal-class="my-overlay"
    >
      <b-form-group v-if="list.length">
        <b-form-radio-group
          v-model="selectedNew"
          text-field="name"
          value-field="id"
          class="demo-inline-spacing"
          name="radio-inline"
          @change="
            $emit(
              'changeFunnelPoint',
              {
                to: list.find(el => el.id === selectedNew),
                target: selected
              }
            )
          "
        >
          <b-form-radio
            v-for="item in list"
            :key="item.id"
            :value="item.id"
          >
            <span>
              {{ item.name | textIsland(18, '…') }}
            </span>
          </b-form-radio>
        </b-form-radio-group>
      </b-form-group>

      <div
        v-else
        class="p-2 text-center text-primary"
      >
        <feather-icon icon="XCircleIcon" />

        {{ $t('NoData') }}
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BFormRadio, BFormRadioGroup } from 'bootstrap-vue'

export default {
  components: {
    BFormRadioGroup,
    BFormRadio,
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
    selected: {
      type: Object,
      required: true,
    },
  },
  emits: ['changeFunnelPoint'],
  data: () => ({
    mMain: null,
    selectedNew: null,
  }),
  mounted() {
    this.selectedNew = this.selected.id
  },
}
</script>
