<template>
  <component
    :is="isComponent ? 'div' : 'b-modal'"
    id="compose-mail"
    :visible="shallShowEmailComposeModal"
    title="Compose Email"
    footer-class="d-flex justify-content-between"
    body-class="p-0"
    size="lg"
    static
    @change="(val) => $emit('update:shall-show-email-compose-modal', val)"
  >
    <!-- Modal Header -->
    <template v-if="!isComponent"
              #modal-header
    >
      <h5 class="modal-title">
        {{ $t('Create') }}
      </h5>

      <div class="modal-actions">
        <feather-icon
          icon="MinusIcon"
          class="cursor-pointer"
          @click="saveAsDraft"
        />

        <feather-icon
          icon="XIcon"
          class="ml-1 cursor-pointer"
          @click="discardEmail"
        />
      </div>
    </template>

    <!-- Modal: Body -->
    <b-form>
      <!-- Field: From -->
      <div class="compose-mail-form-field">
        <label
          for="email-to"
          class="form-label text-nowrap"
        >
          {{ $t('From') }}:
        </label>
        <sw-select class="mb-n25">
          <v-select
            id="offer-thread-1"
            v-model="fromIamp"
            :options="imapList"
            label="name"
            :filterable="false"
            @option:selected="selectImap"
          >
            <template #no-options>
              {{ $t('NoData') }}
            </template>
          </v-select>
        </sw-select>
      </div>

      <!-- Field: To -->
      <div class="compose-mail-form-field">
        <label
          for="email-to"
          class="form-label text-nowrap"
        >
          {{ $t('To') }}:
        </label>

        <sw-select class="mb-n25">
          <v-select
            id="offer-thread-1"
            v-model="composeData.toAddresses"
            :options="contactList"
            label="email"
            taggable
            multiple
            :filterable="false"
            :selectable="c => {
              if (/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(c.email))
                return true

              return false
            }"
            :clear-search-on-blur="() => handleBlur('toAddresses')"
            :create-option="createOption"
            @search="loadContacts"
          >
            <template #no-options="{ search }">
              <span v-if="search.length">
                {{ $t('NoData') }}
              </span>

              <span v-else>
                {{ $t('TypeToSearch') }}
              </span>
            </template>

            <template #option="{ avatar, firstName, lastName, type, email }">
              <span v-if="type && firstName">
                <b-avatar
                  size="sm"
                  :src="avatar"
                  :text="(firstName || '').slice(0,1).toUpperCase() + (lastName || '').slice(0,1).toUpperCase()"
                />
                <span class="ml-50 pt-25">{{ firstName + ' ' + lastName | truncate(26) }}
                  <span v-if="type === 'c'">- {{ $t('Contact') }}</span>
                  <div><small>{{ email }}</small></div>
                </span>
              </span>
            </template>

            <template #selected-option="{ avatar, firstName, lastName, type, email }">
              <span v-if="type && firstName">
                <b-avatar
                  size="sm"
                  class="border border-white"
                  :src="avatar"
                  :text="(firstName || '').slice(0,1).toUpperCase() + (lastName || '').slice(0,1).toUpperCase()"
                />
                <span class="ml-50 pt-25">
                  {{ firstName + ' ' + lastName | truncate(26) }}
                  <span v-if="type === 'c'"> - {{ $t('Contact') }}</span>
                  <div><small>{{ email }}</small></div>
                </span>
              </span>
            </template>
          </v-select>
          <small v-if="search.length"
                 class="text-danger"
          />
        </sw-select>

        <span
          class="cursor-pointer text-nowrap"
          @click="showCcField=!showCcField"
        >Cc</span>

        <span
          class="ml-1 cursor-pointer text-nowrap"
          @click="showBccField=!showBccField"
        >Bcc</span>
      </div>

      <!-- Field: Cc (Hidden Initially) -->
      <div
        v-show="showCcField"
        class="compose-mail-form-field"
      >
        <label
          for="email-cc"
          class="form-label text-nowrap"
        >
          CC:
        </label>

        <sw-select class="mb-n25">
          <v-select
            id="offer-thread-1"
            v-model="composeData.ccAddresses"
            :options="contactList"
            label="email"
            taggable
            multiple
            :filterable="false"
            :selectable="c => {
              if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(c.email))
                return true

              return false
            }"
            :clear-search-on-blur="() => handleBlur('ccAddresses')"
            :create-option="createOption"
            @search="loadContacts"
          >
            <template #no-options="{ search }">
              <span v-if="search.length">
                {{ $t('NoData') }}
              </span>

              <span v-else>
                {{ $t('TypeToSearch') }}
              </span>
            </template>
          </v-select>
        </sw-select>
      </div>

      <!-- Field: Bcc (Hidden Initially) -->
      <div
        v-show="showBccField"
        class="compose-mail-form-field"
      >
        <label
          for="email-bcc"
          class="form-label text-nowrap"
        >
          Bcc:
        </label>

        <sw-select class="mb-n25">
          <v-select
            id="offer-thread-1"
            v-model="composeData.bccAddresses"
            :options="contactList"
            label="email"
            taggable
            multiple
            :filterable="false"
            :selectable="c => {
              if (c.email || /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(c))
                return true

              return false
            }"
            :clear-search-on-blur="() => handleBlur('bccAddresses')"
            :create-option="createOption"
            @search="loadContacts"
          >
            <template #no-options="{ search }">
              <span v-if="search.length">
                {{ $t('NoData') }}
              </span>

              <span v-else>
                {{ $t('TypeToSearch') }}
              </span>
            </template>
          </v-select>
        </sw-select>
      </div>

      <!-- Thread -->
      <div class="compose-mail-form-field">
        <label
          for="email-to"
          class="form-label text-nowrap"
        >
          {{ $t('Thread') }}:
        </label>

        <sw-select
          class="mb-n25"
        >
          <v-select
            id="offer-thread-1"
            v-model="composeData.contactThread"
            :options="threadList"
            label="name"
            :filterable="false"
            @search="loadThreads"
          >
            <template #no-options="{ search }">
              <span v-if="search.length">
                {{ $t('NoData') }}
              </span>

              <span v-else>
                {{ $t('TypeToSearch') }}
              </span>
            </template>

            <template #option="{ name, contact }">
              <div class="d-flex align-items-center">
                <avatar v-if="contact"
                        :user="contact"
                        class="mr-25"
                />  - {{ name }}
              </div>
            </template>

            <template #selected-option="{ name, contact }">
              <div class="d-flex">
                <avatar v-if="contact"
                        :user="contact"
                        class="mr-25"
                /> – {{ name }}
              </div>
            </template>
          </v-select>
        </sw-select>
      </div>

      <!-- Field: Subject -->
      <div class="compose-mail-form-field">
        <label
          for="email-subject"
          class="text-nowrap"
        >
          {{ $t('Subject') }}:
        </label>

        <b-form-input
          id="email-subject"
          v-model="composeData.subject"
        />
      </div>

      <!-- Field: Message - Quill Editor -->
      <div class="message-editor">
        <quill-editor
          id="quil-content"
          ref="content-quill"
          key="1"
          v-model="composeData.contentHtml"
          class="quil-content-small"
          :options="editorOption"
        />

        <div
          id="quill-toolbar"
          class="d-flex border-bottom-0"
        >
          <!-- Add a bold button -->
          <button class="ql-bold" />
          <button class="ql-italic" />
          <button class="ql-underline" />
          <button class="ql-align" />
          <button class="ql-link" />
          <button class="ql-image" />

          <!--          <div class="ql-formats">-->
          <!--            <b-button-->
          <!--              id="custom-button"-->
          <!--              variant="primary"-->
          <!--              :disabled="fileLoading"-->
          <!--              @click="$refs.image_input.click()"-->
          <!--            >-->
          <!--              <span class="text-white">-->
          <!--                <feather-icon icon="ImageIcon" />-->
          <!--              </span>-->
          <!--              <input-->
          <!--                id="image_input"-->
          <!--                ref="image_input"-->
          <!--                style="display: none"-->
          <!--                type="file"-->
          <!--                accept="image/*"-->
          <!--                @change="uploadFile"-->
          <!--              >-->
          <!--            </b-button>-->
          <!--          </div>-->
        </div>
      </div>

      <b-form-group v-if="composeData.footer"
                    :label="$t('Footer')"
                    class="px-1"
                    style="color: #b4b7bd"
      >
        <quill-editor
          id="quil-content"
          key="2"
          v-model="composeData.footer"
          class="quil-content-small"
          :options="editorOption"
          disabled
        />
        <!--        <b-form-textarea v-if="composeData.footer"-->
        <!--                         disabled-->
        <!--                         :value="composeData.footer"-->
        <!--                         rows="4"-->
        <!--        >-->
        <!--        </b-form-textarea>-->
      </b-form-group>

      <!--      <sw-select class="px-1 mt-25"-->
      <!--                 :name="$t('PlanedSendDate')"-->
      <!--      >-->
      <!--        <sw-select-fpr-->
      <!--          :is-empty="composeData.scheduledAt"-->
      <!--          @clear="composeData.scheduledAt = '';"-->
      <!--        >-->
      <!--          <flat-pickr-->
      <!--            ref="timeRange"-->
      <!--            v-model="composeData.scheduledAt"-->
      <!--            class="form-control"-->
      <!--            :config="{ dateFormat: 'd-m-Y H:i', enableTime: true, locale: getCalendarLocale($i18n.locale), minDate: new Date() }"-->
      <!--          />-->
      <!--        </sw-select-fpr>-->
      <!--      </sw-select>-->
    </b-form>

    <!-- Modal Footer -->
    <template v-if="!isComponent"
              #modal-footer
    >
      <!-- Footer: Left Content -->
      <div class="d-flex align-items-center"
           style="row-gap: .5rem; column-gap: .5rem;"
      >
        <b-button
          variant="primary"
          :disabled="
            !fromIamp
              || !composeData.toAddresses
              || !composeData.toAddresses.length
              || !composeData.subject
              || !composeData.subject.length
              || !composeData.contentHtml
              || !composeData.contentHtml.length
              || isLoading"
          @click="sendEmail(null)"
        >
          {{ $t('Send') }}
        </b-button>

        <drag-drop-upload
          :default-value="composeData.files"
          show-count
          size="sm"
          type="btn"
          :show-files="false"
          @onChangeFiles="changeFiles"
        />
      </div>
    </template>
    <div v-if="isComponent"
         class="d-flex align-items-center d-flex p-1 mt-1 border-top"
    >
      <b-button
        variant="primary"
        :disabled="
          !fromIamp
            || !composeData.toAddresses
            || !composeData.toAddresses.length
            || !composeData.subject
            || !composeData.subject.length
            || !composeData.contentHtml
            || !composeData.contentHtml.length
            || isLoading"
        @click="sendEmail(null)"
      >
        {{ $t('Send') }}
      </b-button>

      <drag-drop-upload
        :default-value="composeData.files"
        show-count
        size="sm"
        class="ml-25"
        type="btn"
        :show-files="false"
        @onChangeFiles="changeFiles"
      />
    </div>
  </component>
</template>

<script>
import { BForm, BFormInput } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import vSelect from 'vue-select'
import * as $i18n from '@core/libs/i18n/utils'
import { CONTACTS_SEARCH, THREADS_SEARCH, USER_MAILBOX } from '@/@constants/fields'
import {
  CLOSE_MODAL, GET_USER_MAILBOXES, RELOAD_CONTENT, SEND_EMAIL, UPLOAD_FILES,
} from '@/@constants/mutations'
import DragDropUpload from '@/views/components/DragDropUpload.vue'
import { mapGetters } from 'vuex'
import { quillEditor } from 'vue-quill-editor'
import axiosIns from '@/libs/axios'
import flatPickr from 'vue-flatpickr-component'

export default {
  directives: {
    Ripple,
  },
  components: {
    DragDropUpload,

    // BSV
    BForm,
    BFormInput,

    // 3rd Party
    quillEditor,
    vSelect,
    flatPickr,
  },
  model: {
    prop: 'shallShowEmailComposeModal',
    event: 'update:shall-show-email-compose-modal',
  },
  props: {
    isComponent: {
      type: Boolean,
      required: false,
      default: false,
    },
    shallShowEmailComposeModal: {
      type: Boolean,
      required: false,
    },
    actionData: {
      type: Object,
      default: null,
    },
  },
  setup(_, { emit }) {
    const composeData = ref({
      toAddresses: [],
      ccAddresses: [],
      bccAddresses: [],
      subject: '',
      contentHtml: '',
      files: [],
      footer: '',
      threadId: '',
      scheduledAt: '',
    })

    const showCcField = ref(false)
    const showBccField = ref(false)

    const editorOption = {
      modules: {
        toolbar: '#quill-toolbar',
      },
      placeholder: $i18n.t('Message'),
    }

    /* eslint-disable global-require */
    const emailToOptions = [
      { avatar: require('@/assets/images/avatars/1-small.png'), name: 'Jane Foster' },
      { avatar: require('@/assets/images/avatars/3-small.png'), name: 'Donna Frank' },
      { avatar: require('@/assets/images/avatars/5-small.png'), name: 'Gabrielle Robertson' },
      { avatar: require('@/assets/images/avatars/7-small.png'), name: 'Lori Spears' },
      { avatar: require('@/assets/images/avatars/9-small.png'), name: 'Sandy Vega' },
      { avatar: require('@/assets/images/avatars/11-small.png'), name: 'Cheryl May' },
    ]
    /* eslint-enable global-require */

    const discardEmail = () => {
      composeData.value = {
        contactThread: '',
        toAddresses: [],
        ccAddresses: [],
        bccAddresses: [],
        subject: '',
        contentHtml: '',
        footer: '',
        scheduledAt: '',
        threadId: '',
        files: [],
      }

      emit('update:shall-show-email-compose-modal', false)
    }

    return {
      composeData,
      editorOption,
      emailToOptions,
      showCcField,
      showBccField,

      // Email actions
      discardEmail,
    }
  },
  data: () => ({
    isLoading: false,
    fileLoading: false,

    fromIamp: '',

    contactList: [],
    threadList: [],
    imapList: [],
    search: '',
    type: '',
    newOption: '',
    footerImages: [],
    contentImages: [],
  }),
  watch: {
    'composeData.toAddresses': function () {
      this.search = ''
    },
  },
  async mounted() {
    await this.loadImapListFull()
    if (this.actionData) {
      const {
        // eslint-disable-next-line no-unused-vars
        type, imap, subject, content, contactThread, toAddresses, id, fromMail, deliveredAt, imageFiles = [], threadId = null,
        contactList = [],
      } = this.actionData

      // eslint-disable-next-line no-underscore-dangle
      let _subject = ''
      if (type === 'FORWARD' && !((subject || '').includes('Fwd:')) && !((subject || '').includes('Fwd:'))) {
        _subject = 'Fwd: '
      } else if (type === 'REPLAY' && !((subject || '').includes('Re:')) && !((subject || '').includes('Odp:'))) {
        _subject = 'Re: '
      }

      if (contactList?.length) {
        this.contactList = contactList
      }

      if (threadId) {
        this.composeData.threadId = threadId
      }

      this.composeData.subject = _subject + (subject ? subject.replaceAll('Fwd:', '').replaceAll('Fwd', '') : '')

      // eslint-disable-next-line no-underscore-dangle
      let _content = content
      if (imageFiles?.length) {
        const responses = await Promise.all(imageFiles.map(image => axiosIns.get(`storage/1/download/${image.token}`)))
        this.footerImages.push(...responses.map((e, i) => {
          const filename = `${e.data.data.name}`
          const base64 = `data:image/png;base64,${e.data.data.file}`
          return {
            token: imageFiles[i]?.token,
            base64,
            cid: filename,
            filename,
            size: imageFiles[i]?.size,
          }
        }))

        this.footerImages.forEach(image => {
          // cid, filename, token, base64
          if (_content.includes(image.cid)) {
            _content = _content.replaceAll(`cid:${image.filename}`, image.base64)
          }
        })
      }

      if (type !== 'DRAFT' && !this.isComponent) {
        this.composeData.contentHtml = `<br />-------------------------------------------------------------------------------<br>
        <blockquote>
          <div>${this.$i18n.t('Subject')}: ${subject || '-'}</div>
          <div>${this.$i18n.t('Date')}: ${deliveredAt || '-'}</div>
          <div>${this.$i18n.t('From')}: ${fromMail || '-'}</div>
          <br>${_content}</blockquote>`
      } else {
        this.composeData.contentHtml = `${_content || ''}`
      }
      if (contactThread) this.composeData.contactThread = contactThread
      if (imap) {
        this.fromIamp = imap
        await this.selectImap(imap)
      } else if (this.imapList?.length) {
        // eslint-disable-next-line prefer-destructuring
        this.fromIamp = this.imapList[0]
        await this.selectImap(this.imapList[0])
      }
      if (toAddresses) {
        if (this.contactList?.length) {
          this.composeData.toAddresses = toAddresses.map(toAddress => this.contactList.find(contact => contact.email === toAddress) || toAddress)
        } else {
          this.composeData.toAddresses = toAddresses
        }
      }
      if (fromMail) this.composeData.toAddresses = [fromMail]
      if (id) this.composeData.id = id

      this.type = type
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/getCurrentUser',
    }),
  },
  methods: {
    async uploadFile(inputResponse) {
      this.fileLoading = true
      const { files } = inputResponse.target

      const imageData = {
        token: null,
        base64: '',
        cid: '',
        filename: '',
      }

      const formData = new FormData()
      files.forEach(file => {
        const extension = file.name.split('.').at(-1)
        // const name = file.name.replace(`.${extension}`, '').replace(`${extension}`, '')
        const uniqueId = `img${Math.random().toString(36).substring(2, 15)}`
        formData.append('files[]', file, `${uniqueId}.${extension}`)
        imageData.cid = uniqueId
        imageData.filename = uniqueId
        imageData.size = file.size
      })
      const response = await axiosIns.post('storage/1/upload', formData, { noApi: true })

      // eslint-disable-next-line prefer-destructuring
      imageData.token = response.data.data.files[0]

      const fr = new FileReader()

      fr.onload = () => {
        imageData.base64 = fr.result
        const range = this.$refs['content-quill'].quill.getSelection()
        if (range) {
          this.$refs['content-quill'].quill.insertEmbed(range.index, 'image', imageData.base64)
          this.footerImages.push(imageData)
          this.$refs.image_input.value = null
          this.fileLoading = false
        }
      }

      fr.readAsDataURL(files[0])
    },
    async loadContacts(search, isLoading) {
      this.isLoading = true

      // eslint-disable-next-line no-unused-vars
      const { project, projects } = this

      // eslint-disable-next-line no-unused-vars
      const filters = {}

      if (project) filters.allowedUsers = projects.find(p => p.id === (project?.id || project)).assignedUsers.map(u => u.id)

      this.search = search

      this.contactList = await this.selectSearch(search, isLoading, CONTACTS_SEARCH, 'firstName,lastName', '1/contacts', 25)

      this.isLoading = false
    },

    async loadImapListFull() {
      this.isLoading = true

      const { items } = await this.$store.dispatch(`mailbox/${GET_USER_MAILBOXES}`)

      this.imapList = items.filter(a => a.active)

      this.isLoading = false
    },

    async loadImapList(search, isLoading) {
      this.isLoading = true

      const list = await this.selectSearch(search, isLoading, USER_MAILBOX, 'name', '1/users/me/mailboxes', 25)

      this.imapList = list.filter(i => i.imapPass)

      if (!this.imapList.length && !search.length) await this.loadImapListFull()

      this.isLoading = false
    },

    async loadThreads(search, isLoading) {
      this.isLoading = true

      this.threadList = await this.selectSearch(search, isLoading, THREADS_SEARCH, 'name,contact-firstName,contact-lastName', '1/contacts/threads', 25)

      this.isLoading = false
    },

    async sendEmail(isDraft = false) {
      this.isLoading = true

      const { fromIamp } = this
      const mail = JSON.parse(JSON.stringify(this.composeData))

      if ((fromIamp && mail.subject && mail.contentHtml && mail.toAddresses) || (isDraft)) {
        // if (mail.contactThread) {
        //   mail.contactThread = mail.contactThread.id
        //   delete mail.contactThread
        // }

        let footerImagesPayload = []

        let content = !isDraft ? `${mail.contentHtml}<br><br>${(mail.footer || '').replaceAll('\n', '<br>')}` : mail.contentHtml
        const imagesCopy = this.footerImages.map(e => ({ ...e, finded: false }))
        if (this.footerImages?.length) {
          imagesCopy.forEach((image, index) => {
            // cid, filename, token, base64
            if (content.includes(image.base64)) {
              content = content.replaceAll(image.base64, `cid:${image.filename.replaceAll('.png', '').replaceAll('.jpg', '').replaceAll('.jpeg', '').replaceAll('.webp', '')
                .replace(/[^a-z0-9]/gi, '')}`)
              imagesCopy[index].finded = true
            }
          })
          footerImagesPayload = imagesCopy.filter(e => e.finded).map(e => e.token)
        }

        const [parsedContent, tokens, size = 0] = await this.scanContentAndReplaceBaseWithCid(content)

        if (tokens.length) {
          footerImagesPayload.push(...tokens)
          content = parsedContent
        }

        if (imagesCopy?.length || this.composeData.files?.length) {
          const allFiles = [
            ...(imagesCopy || []).filter(e => e.finded),
            ...(this.composeData.files || []),
          ]

          const totalSize = allFiles.reduce((a, b) => +a + +(b.size || 0), size)
          const mb = (totalSize / (1024 * 1024)).toFixed(2)

          if (mb > 25) {
            this.showToast('error', this.$i18n.tc('YourFilesSizeIsBiggerThanMb', 1, { allowedSize: 25, filesSize: mb }))
            this.isLoading = false
            return
          }
        }

        let formData = null

        const payload = { uploadedFiles: this.composeData.files.filter(e => !e.token), type: 'building', compress: false }
        const { files } = await this.$store.dispatch(`uploader/${UPLOAD_FILES}`, payload)

        formData = {
          files: [...files, ...this.composeData.files.map(e => e?.token).filter(Boolean)],
          imageFiles: footerImagesPayload,
          toAddresses: mail.toAddresses.map(user => (user.email ? user.email : user)),
          ccAddresses: mail.ccAddresses.map(user => user.email),
          bccAddresses: mail.bccAddresses.map(user => user.email),
          subject: mail.subject,
          scheduledAt: mail.scheduledAt || null,
          contactThread: this.getObjectId(mail.contactThread),
          contentHtml: content,
          status: isDraft ? 'DRAFT' : 'QUEUE',
          id: mail.id,
        }

        // formData.append('toAddresses[]', mail.toAddresses.map(user => (user.email ? user.email : user)))
        // if (mail.ccAddresses.length) formData.append('ccAddresses[]', mail.ccAddresses.map(user => user.email))
        // if (mail.bccAddresses.length) formData.append('bccAddresses[]', mail.bccAddresses.map(user => user.email))
        // if (mail.contactThread) formData.append('contactThread', this.getObjectId(mail.contactThread))
        // if (this.composeData.files) this.composeData.files.forEach(file => formData.append('files[]', file, file.name))
        // if (footerImagesPayload.length) footerImagesPayload.forEach(file => formData.append('imageFiles[]', file, file.name))
        // formData.append('subject', mail.subject)
        // formData.append('contentHtml', content)

        // eslint-disable-next-line no-unreachable
        if (this.type === 'DRAFT') {
          // if (mail.contactThread) {
          //   formData.contactThread = this.getObjectId(mail.contactThread)
          // }

          if (!isDraft) {
            formData.mailboxFolder = fromIamp.sendFolder || 'INBOX.Sent'
          }
        } else if (isDraft) {
          formData.status = 'DRAFT'
        } else {
          formData.mailboxFolder = fromIamp.sendFolder || 'INBOX.Sent'
        }

        if (mail.threadId) {
          formData.threadId = mail.threadId
        }

        if (this.isComponent) {
          this.$store.commit(`modal/${CLOSE_MODAL}`)
        }
        this.$store.dispatch(`globalEmails/${SEND_EMAIL}`, {
          id: fromIamp.id,
          mail: formData,
          mailId: mail.id,
        })
          .then(async () => {
            if (this.isComponent) {
              await this.$store.commit(`modal/${RELOAD_CONTENT}`, payload)
            }

            if (!isDraft) this.showToast('success', this.$i18n.t('SendedSuccessfully'))
            this.$emit('update:shall-show-email-compose-modal', false)
            this.$emit('save', false)
            this.discardEmail()
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          })
      }

      this.isLoading = false
    },

    changeFiles(files) { this.composeData.files = files },
    saveAsDraft() {
      const { fromIamp } = this
      const mail = JSON.parse(JSON.stringify(this.composeData))

      if (fromIamp && mail.subject && mail.contentHtml) {
        this.sendEmail(true)
      } else {
        this.$emit('update:shall-show-email-compose-modal', false)
      }
    },
    createOption(value) {
      // this.contactList.push({ email: value })
      this.newOption = ({ email: value })
      return ({ email: value })
    },
    handleBlur(field = 'toAddresses') {
      if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.newOption?.email)) {
        return true
      }

      if (!this.composeData[field].find(e => e?.email === this.newOption.email || e === this.newOption.email)) {
        // Create a new option from the search input
        this.composeData[field].push({ email: this.newOption?.email })
      }

      return true
    },
    async selectImap(event) {
      if (event.footer) {
        /* eslint-disable */
        let footer = event.footer

        if (event.footerImageFiles.length) {
          const responses = await Promise.all(event.footerImageFiles.map(image => axiosIns.get(`storage/1/download/${image.token}`)))
          this.footerImages.push(...responses.map((e, i) => {
              const filename = `${e.data.data.name}`
              const base64 = `data:image/png;base64,${e.data.data.file}`
              return {
                  token: event.footerImageFiles[i]?.token,
                  base64,
                  cid: filename,
                  filename,
                  size: event.footerImageFiles[i]?.size,
              }
          }))

          this.footerImages.forEach(image => {
            // cid, filename, token, base64
            if (footer.includes(image.cid)) {
              footer = footer.replaceAll(`cid:${image.filename}`, image.base64)
            }
          })
        }

        this.composeData.footer = footer
      } else {
        this.composeData.footer = ''
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';

.quil-content-small .ql-editor {
  min-height: 15rem !important;
  max-height: 15rem !important;
}
</style>

<style lang="scss" scoped>
form ::v-deep {

  // Mail To vue-select style
  .v-select {
    .vs__dropdown-toggle {
      border: 0;
      box-shadow: none;
    }
    .vs__open-indicator {
      display: none;
    }
  }

  // Quill Editor Style
  .quill-editor {
    .ql-container.ql-snow {
      border-bottom: 0 !important;
    }
  }
}
</style>
