<template>
  <b-card>
    <h4>{{ $t('negotiations.ShowNegotiationStatisticsSelectedPartner') }}</h4>

    <b-row class="mt-1">
      <!--    Partner    -->
      <b-col
        sm="12"
        md="6"
        lg="4"
      >
        <sw-select :name="$t('Partner')">
          <v-select
            v-model="partner"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :placeholder="$t('Partner')"
            :options="partnerList"
          >
            <template #option="{ label }">
              {{ label }}
            </template>
            <template #selected-option="{ label }">
              {{ label }}
            </template>
            <template #no-options>
              {{ $t('NoOptions') }}
            </template>
          </v-select>
        </sw-select>
      </b-col>

      <!--    Negotiations    -->
      <b-col
        sm="12"
        md="6"
        lg="4"
      >
        <sw-select :name="$t('Negotiations')">
          <v-select
            v-model="negotiation"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :placeholder="$t('Negotiations')"
            :options="negotiationList"
          >
            <template #option="{ label }">
              {{ label }}
            </template>
            <template #selected-option="{ label }">
              {{ label }}
            </template>
            <template #no-options>
              {{ $t('NoOptions') }}
            </template>
          </v-select>
        </sw-select>
      </b-col>

      <!--    Type    -->
      <b-col
        sm="12"
        md="12"
        lg="4"
      >
        <sw-select :name="$t('negotiations.Type')">
          <v-select
            v-model="types"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :placeholder="$t('negotiations.Type')"
            :options="typeList"
            taggable
            multiple
          >
            <template #option="{ label }">
              {{ label }}
            </template>
            <template #selected-option="{ label }">
              {{ label }}
            </template>
            <template #no-options>
              {{ $t('NoOptions') }}
            </template>
          </v-select>
        </sw-select>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
  },
  data: () => ({
    partner: '',
    partnerList: [],

    negotiation: '',
    negotiationList: [],

    types: '',
    typeList: ['Wszystkie zakończone', 'Okres', 'Klientów', 'Rodzaj negocjacji'],
  }),
}
</script>
