// eslint-disable-next-line import/no-cycle
import axiosIns from '@/libs/axios'
import {
  ADD_BOOKING,
  CHANGE_USER_AVATAR, CHANGE_USER_PASSWORD, DELETE_BOOKING, GET_BOOKING, GET_BOOKING_ALL, UPDATE_BOOKING,
} from '@/@constants/mutations'
import * as fields from '@/@constants/fields'

export default {
  namespaced: true,
  state: {},
  getters: {
  },
  mutations: {
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    [CHANGE_USER_AVATAR]: ({ commit }, payload) => new Promise((resolve, reject) => {
      const { avatar, userId } = payload
      axiosIns.patch(`1/users/${userId}/profile`, { id: userId.toString(), avatar })
        .then(res => {
          resolve(res.data.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [CHANGE_USER_PASSWORD]: ({ commit }, payload) => new Promise((resolve, reject) => {
      // eslint-disable-next-line no-unused-vars
      const { oldPassword, newPassword, userId } = payload
      const data = {
        old: oldPassword,
        new: newPassword,
      }
      axiosIns.patch(`1/users/${userId}/changePassword`, data)
        .then(res => {
          resolve(res.data.data)
        })
        .catch(err => {
          reject(err)
        })
    }),

    [ADD_BOOKING]: (_, payload) => new Promise((resolve, reject) => {
      axiosIns.post('1/users/me/bookings', payload)
        .then(res => resolve(res.data.data.items[0]))
        .catch(err => reject(err))
    }),

    [GET_BOOKING_ALL]: () => new Promise((resolve, reject) => {
      axiosIns.get('1/users/me/bookings', { params: { fields_load: fields.BOOKING } })
        .then(res => resolve(res.data.data))
        .catch(err => reject(err))
    }),

    [GET_BOOKING]: (_, id) => new Promise((resolve, reject) => {
      axiosIns.get(`1/users/me/bookings/${id}`, { params: { fields_load: fields.BOOKING } })
        .then(res => resolve(res.data.data))
        .catch(err => reject(err))
    }),

    [UPDATE_BOOKING]: (_, payload) => new Promise((resolve, reject) => {
      axiosIns.patch('1/users/me/bookings', payload)
        .then(res => resolve(res.data.data))
        .catch(err => reject(err))
    }),

    [DELETE_BOOKING]: (_, id) => new Promise((resolve, reject) => {
      axiosIns.delete('1/users/me/bookings', { data: [id.toString()] })
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    }),
  },
}
