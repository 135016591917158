var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-button',{attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.openEditModal({ name: '', })}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_vm._v(" "+_vm._s(_vm.$t('Add'))+" ")],1),_c('b-row',{staticClass:"d-flex flex-row-reverse"},[_c('b-col',{attrs:{"sm":"12","md":"12","lg":"12"}},[_c('div',{staticClass:"mt-1"},[_c('b-card',{staticClass:"myTableOffer"},[_c('b-table',{staticClass:"offerTable",style:({ minHeight: _vm.tableSize(_vm.items, 100) }),attrs:{"busy":_vm.loading,"items":_vm.items,"fields":_vm.fields,"striped":"","show-empty":"","responsive":"","hover":""},scopedSlots:_vm._u([{key:"head()",fn:function(item){return [_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm._f("truncate")(_vm.$tc(item.field.label),34, '...'))+" ")])]}},{key:"cell(name)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value)+" ")]}},{key:"cell(createdBy)",fn:function(ref){
var value = ref.value;
return [_c('avatar',{attrs:{"user":value}})]}},{key:"cell(action)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('b-dropdown',{staticClass:"dropdown-icon-wrapper text-decoration-none",attrs:{"variant":"link","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"align-middle",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.deleteProcess(item, index)}}},[_vm._v(" "+_vm._s(_vm.$t('Delete'))+" ")])],1)]}},{key:"cell()",fn:function(row){return [_c('table-default-cell',{attrs:{"field":row.field,"value":row.value}})]}},{key:"empty",fn:function(){return [_c('empty-content')]},proxy:true},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[_c('b-spinner',{staticClass:"align-middle",attrs:{"variant":"primary"}})],1)]},proxy:true}])})],1)],1)])],1),_c('b-modal',{attrs:{"visible":!!_vm.editedField,"title":_vm.$t('SmsSender'),"size":"md","hide-footer":"","no-close-on-backdrop":""},on:{"close":_vm.closeModal}},[(!!_vm.editedField)?_c('div',[_c('b-form-group',{attrs:{"label":_vm.$t('Name')}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('Name')},model:{value:(_vm.editedField.name),callback:function ($$v) {_vm.$set(_vm.editedField, "name", $$v)},expression:"editedField.name"}})],1)],1):_vm._e(),_c('b-button',{attrs:{"variant":"primary","size":"sm"},on:{"click":_vm.pushToServer}},[_vm._v(" "+_vm._s(_vm.$t('Save'))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }