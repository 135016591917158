<template>
  <b-card>
    <b-table
      ref="selectableTable"
      :items="history"
      :fields="fields"
      striped
      responsive
      :busy="loading"
    >
      <template #head()="{ label }">
        {{ $t(`${label}`) }}
      </template>
      <template #cell(user)="row">
        <b-avatar
          v-b-tooltip
          class="pull-up"
          variant="primary"
          :src="row.item.user.avatar"
          :text="row.item.user.firstName.slice(0,1).toUpperCase() + row.item.user.lastName.slice(0,1).toUpperCase()"
          :title="`${row.item.user.firstName} ${row.item.user.lastName}`"
          circle
        />
      </template>

      <template #cell(blocked)="row">
        <b-badge :variant="!row.item.active ? 'light-danger' : 'light-success'">
          {{ !row.item.active ? $t('SessionInactive') : $t('SessionActive') }}
        </b-badge>
      </template>

      <template #cell(createdAt)="row">
        {{ row.item.createdAt.date.split('.')[0] }}
      </template>

      <template #cell(userAgent)="row">
        <span
          :id="`agent_${row.item.id}`"
          :class="{ 'cursor-pointer': row.item.userAgent.length > 25 }"
        >{{ row.item.userAgent | truncate(25, ' ...') }}</span>
        <b-tooltip
          v-if="row.item.userAgent.length > 25"
          :target="`agent_${row.item.id}`"
          triggers="hover"
        >
          {{ row.item.userAgent }}
        </b-tooltip>
      </template>

      <template #cell(cityName)="row">
        <div v-if="row.item.postalCode || row.item.cityName">
          <span v-if="row.item.postalCode">
            {{ row.item.postalCode }},
          </span>
          <span>
            {{ row.item.cityName || '-' }}
          </span>
        </div>
        <feather-icon
          v-else
          icon="MinusIcon"
        />
      </template>

      <template #cell(action)="row">
        <b-button
          v-if="row.item.active"
          size="sm"
          @click="logoutSession(row.item.session)"
        >
          {{ $t('Logout') }}
        </b-button>
        <div v-else>
          <feather-icon icon="MinusIcon" />
        </div>
      </template>

      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner
            class="align-middle"
            variant="primary"
          />
        </div>
      </template>
    </b-table>
    <div class="d-flex justify-content-between">
      <sw-select>
        <v-select
          v-model="pagination.limit"
          class="per-page-selector d-inline-block mr-50 pb-50 select-size-sm"
          :options="[5, 10, 15, 25]"
          :clearable="false"
          @input="reloadHistory(pagination.currentPage, $event)"
        >
          <template #no-options>
            {{ $t('NoOptions') }}
          </template>
        </v-select>
      </sw-select>
      <div>
        <b-pagination
          v-model="pagination.currentPage"
          :total-rows="historyRows"
          :per-page="pagination.limit"
          align="center"
          @change="reloadHistory($event, pagination.limit)"
        />
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  BPagination, BTable, BTooltip, VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { GET_USER_HISTORY, LOGOUT_SESSION } from '@/@constants/mutations'
import { mapGetters } from 'vuex'

export default {
  name: 'AuthLogs',
  components: {
    vSelect,
    BPagination,
    BTable,
    BTooltip,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data: () => ({
    history: [],
    historyRows: 0,
    loading: false,
    sorter: {
      sortBy: 'createdAt',
      sortDesc: true,
    },
    pagination: {
      limit: 20,
      currentPage: 1,
    },
    fields: [
      {
        key: 'user',
        label: 'User',
      },
      {
        key: 'blocked',
        label: 'LoginStatus',
      },
      {
        key: 'clientIp',
        label: 'IP',
      },
      {
        key: 'userAgent',
        label: 'UserAgent',
      },
      {
        key: 'cityName',
        label: 'City',
      },
      {
        key: 'createdAt',
        label: 'Date',
      },
      {
        key: 'action',
        label: 'Action',
      },
    ],
  }),
  computed: {
    ...mapGetters({
      user: 'auth/getCurrentUser',
      currentUser: 'auth/getCurrentUser',
    }),
  },
  mounted() {
    this.reloadHistory()
  },
  methods: {
    logoutSession(session) {
      this.$store.dispatch(`auth/${LOGOUT_SESSION}`, session)
        .then(() => {
          this.reloadHistory()
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
    reloadHistory(page = this.pagination.currentPage, limit = this.pagination.limit) {
      this.loading = true

      this.$nextTick(() => {
        this.$store.dispatch(`authLogs/${GET_USER_HISTORY}`, {
          userId: this.currentUser.id, page: this.rows < limit ? 1 : page, limit, user: this.user,
        })
          .then(res => {
            setTimeout(() => {
              this.historyRows = res.data.totalItemCount
              this.history = res.data.items
              this.loading = false
            }, 500)
          })
          .catch(() => {
            this.loading = false
            this.showToast('error', this.$i18n.t('ProblemOccured'))
          })
      })
    },
  },
}
</script>

<style scoped>

</style>
