<template>
  <!--  <Timeline-->
  <!--    v-if="selectedThread"-->
  <!--    is-contact-->
  <!--    is-new-contact-->
  <!--  />-->
  <div>
    <global-timeline
      :thread="selectedThread"
      hide-filters
    />
  </div>
</template>
<script>
// import Timeline from '@/views/core/singleContact/tabs/Timeline.vue'
import GlobalTimeline from '@/pages/GlobalTimeline.vue'

export default {
  components: { GlobalTimeline },

  props: {
    selectedThread: {
      type: Boolean,
      required: true,
    },
  },
}
</script>
