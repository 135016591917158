<template>
  <component
    :is="isMobile ? 'div' : 'app-timeline-item'"
    variant="success"
    icon="PhoneIcon"
    class="pb-2 d-flex flex-column justify-content-start align-items-start"
    style="row-gap: .34rem"
  >
    <slot />
    <component
      :is="isContact ? 'div' : 'b-card'"
      class="w-100 rounded-lg mb-0 position-relative"
      no-body
    >
      <b-card-body :class="`p-${ isContact ? '0' : '1' }`">
        <div
          class="d-flex mb-50"
          style="column-gap: .5rem"
        >
          <b-avatar
            v-if="isMobile"
            rounded="sm"
            size="sm"
            variant="light-success"
          >
            <span class="d-flex align-items-center">
              <feather-icon
                icon="PhoneIcon"
                size="14"
              />
            </span>
          </b-avatar>

          <router-link
            v-if="thread && !isContact"
            class="font-small-3"
            :class="{ 'deleted-text': thread.contact.deletedAt }"
            :to="`/contact/${thread.contact.id}/thread/${thread.id}/timeline`"
          >
            {{ thread.contact.firstName }} {{ thread.contact.lastName }} – {{ thread.name }}
          </router-link>

          <p
            v-else
            class="mb-0"
          >
            {{ $t('CurrentContact') }}
          </p>
        </div>

        <div
          v-if="thread"
          class="w-100 font-weight-bold d-flex flex-row flex-wrap justify-content-between"
          style="column-gap: .64rem; row-gap: .34rem;"
        >
          <div
            class="d-flex flex-wrap flex-row align-items-center"
            style="column-gap: .5rem; row-gap: .34rem;"
          >
            <b-badge variant="light-primary">
              <feather-icon icon="PhoneIcon" />
              {{ $t('MessageSMS') }}
            </b-badge>

            <div
              class="d-flex flex-row align-items-center"
              style="column-gap: .5rem; row-gap: .34rem;"
            >
              <small class="text-lowercase">{{ $t('FromTheDay') }}</small>

              <span class="font-italic">{{ formatDate(timelineDate, { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' }, $i18n.locale) }}</span>
            </div>

            <div
              v-if="data.sharedRanks.length"
              class="d-flex flex-row align-items-center"
              style="column-gap: .34rem"
            >
              <avatar
                :user="data.createdBy"
                size="sm"
                show-tooltip
                :show-name="false"
                class="mr-n25"
              />

              <span class="font-small-2 text-lowercase">{{ $t('SharedFor') }}</span>

              <div>
                <b-avatar-group
                  v-if="data.sharedRanks.length > 1"
                  size="sm"
                >
                  <b-avatar
                    v-for="(rank, uindex) in data.sharedRanks"
                    :key="`user_${rank.id}_${uindex}_${data.id}`"
                    v-b-tooltip.hover
                    :title="rank.name"
                    class="pull-up"
                    :text="rank.name.slice(0, 2).toUpperCase()"
                  />
                </b-avatar-group>

                <avatar
                  v-else-if="data.sharedRanks.length === 1"
                  :user="data.sharedRanks[0]"
                />
              </div>
            </div>
          </div>

          <b-button
            v-if="smses.some(e => e.status === 'QUEUE') && checkRequiredPermissions([$roles.CONTACT_THREAD_SMS_ADD])"
            variant="outline-danger"
            size="sm"
            @click="cancelSend"
          >
            <feather-icon icon="XIcon" />
            {{ $t('CancelSend') }}
          </b-button>
        </div>

        <hr
          v-if="smses.length"
          class="border-light-primary"
        >

        <div
          v-if="smses.length"
          class="w-100 d-flex flex-column justify-content-start align-items-start px-md-50"
          style="row-gap: .34rem"
        >
          <div
            class="w-100 d-flex flex-row flex-wrap align-items-center justify-content-between"
            style="column-gap: .5rem; row-gap: .34rem;"
          >
            <div
              v-if="smses.length"
              class="d-flex flex-row flex-wrap align-items-center"
              style="column-gap: .5rem; row-gap: .34rem;"
            >
              {{ $t('From') }}

              <avatar
                :user="data.createdBy"
                size="sm"
                class="mr-n25"
                show-tooltip
                :show-name="false"
              />

              {{ $t('To').toLowerCase() }}

              <div
                class="d-flex flex-row flex-wrap align-items-center"
                style="column-gap: .5rem; row-gap: .34rem;"
              >
                <b-badge
                  v-for="(sms, index) in smses"
                  :key="`${sms.id}_email`"
                  v-b-tooltip
                  :variant="messageContentIndex === index ? 'primary' : 'light-primary'"
                  :title="$t(`SmsStatuses.${sms.status}`)"
                  @click="messageContentIndex = index"
                >
                  {{ sms.number }}
                  <span
                    class="ml-50 bullet bullet-sm"
                    :class="[smsStatus(sms.status)]"
                  />
                </b-badge>
              </div>
            </div>
          </div>

          <div
            v-if="data.scheduledAt"
            class="d-flex"
          >
            {{ $t('PlanedSending') }}: {{ formatDate(data.scheduledAt.date, { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' }, $i18n.locale) }}
          </div>

          <div
            v-if="smses && smses.length"
          >
            <div v-html="smses[messageContentIndex].contentMessage" />
          </div>
          <div v-else-if="data.content">
            <div v-html="data.content" />
          </div>
          <feather-icon
            v-else
            icon="MinusIcon"
          />
        </div>
        <div v-else-if="data.content">
          <hr class="border-light-primary">
          <div v-html="data.content" />
        </div>
      </b-card-body>
    </component>

    <hr
      v-if="isContact"
      class="w-100 mb-0"
    >
  </component>
</template>

<script>
import { BButtonGroup, VBTooltip } from 'bootstrap-vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import smsStatus from '@/helpers/smsStatuses'
import { formatDate } from '@core/utils/filter'
import useVerticalLayout from '@core/layouts/layout-vertical/useVerticalLayout'
import { onUnmounted } from '@vue/composition-api'
import axiosIns from '@/libs/axios'

export default {
  name: 'Sms',
  components: { BButtonGroup, AppTimelineItem },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: ['data', 'timelineDate', 'threadId', 'thread', 'isContact'],
  setup() {
    const { isMobile, resizeHandler } = useVerticalLayout()

    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    return {
      isMobile,
    }
  },
  data: () => ({
    messageContentIndex: 0,
    smses: [],
  }),
  mounted() {
    this.smses = this.data?.smses || []
  },
  methods: {
    smsStatus,
    formatDate,
    async cancelSend() {
      const confirmed = await this.showAlert('warning', this.$i18n.t('alerts.AreYouSure'))
      if (!confirmed) return

      try {
        await axiosIns.patch('1/contacts/threads/smses', { id: this.data.id, cancelSmses: true })

        this.$set(this, 'smses', this.smses.map(sms => ({ ...sms, status: sms.status === 'QUEUE' ? 'CANCELLED' : sms.status })))
        this.showToast('success', this.$i18n.t('success.contactUpdated'))
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
  },
}
</script>

<style lang="scss">
.pl-0 .card .card-header {
  padding-left: 0 !important;
}
</style>
