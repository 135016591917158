<template>
  <div>
    <transition name="fade">
      <b-card v-if="!selectedFunnel">
        <div class="d-flex justify-content-center align-items-center p-md-2">
          <div>
            <h4 class="mb-1">
              {{ $t('SelectFunnelToGenerateReport') }}
            </h4>

            <sw-select>
              <v-select
                v-model="selectedFunnel"
                :placeholder="$t('SelectProcessFunnel')"
                :options="funnels"
                label="name"
                class="rounded"
                :reduce="a => a.id"
                @option:selected="loadData"
              >
                <template #no-options="{ search }">
                  <span v-if="search.length">
                    {{ $t('NoData') }}
                  </span>
                  <span v-else>
                    {{ $t('TypeToSearch') }}
                  </span>
                </template>
              </v-select>
            </sw-select>
          </div>
        </div>
      </b-card>
    </transition>
    <!--   Details   -->
    <transition name="fade">
      <div v-if="selectedFunnel">
        <div class="d-flex flex-wrap flex-row justify-content-between align-content-center align-items-center">
          <div class="pr-1">
            <sw-select>
              <sw-select-fpr
                :is-empty="dateFilter"
                @clear="dateFilter = ''"
              >
                <flat-pickr
                  v-model="dateFilter"
                  style="min-width: 300px"
                  class="form-control"
                  :config="{ mode: 'range', locale: getCalendarLocale($i18n.locale) }"
                  :placeholder="$t('ProcessCreatedAt')"
                  @input="loadData"
                />
              </sw-select-fpr>
            </sw-select>
          </div>

          <b-form-group>
            <b-form-radio-group
              v-model="activeFilter"
              :options="filterCreatedAtOptions"
              buttons
              button-variant="outline-primary"
              size="sm"
              class="flex-wrap"
              style="row-gap: .25rem"
              @input="setDate"
            />
          </b-form-group>
        </div>

        <sw-select>
          <v-select
            v-model="selectedFunnel"
            :placeholder="$t('SelectProcessFunnel')"
            class="text-primary"
            :options="funnels"
            label="name"
            :reduce="a => a.id"
            @option:selected="loadData"
          >
            <template #no-options="{ search }">
              <span v-if="search.length">
                {{ $t('NoData') }}
              </span>
              <span v-else>
                {{ $t('TypeToSearch') }}
              </span>
            </template>
          </v-select>
        </sw-select>

        <sw-tabs>
          <b-tab
            :title="$t('GeneralIndicators')"
            title-item-class="btn btn-sm p-0"
            title-link-class="p-75"
          >
            <b-row>
              <b-col md="6">
                <b-row>
                  <b-col
                    v-for="(item, i) in generalList.slice(0, generalList.length /2)"
                    :key="i"
                    sm="12"
                    class="d-flex flex-column"
                  >
                    <label
                      v-b-tooltip.hover.v-primary
                      class="d-flex flex-row align-items-center font-weight-bold mb-50"
                      style="column-gap: .34rem"
                      :title="item.tooltip && item.tooltip.length ? $t(item.tooltip) : ''"
                    >
                      {{ $t(item.title) }}

                      <feather-icon
                        v-if="item.tooltip && item.tooltip.length"
                        icon="InfoIcon"
                      />
                    </label>

                    <b-alert
                      :variant="item.variant"
                      show
                    >
                      <div class="alert-body text-center px-25 py-25">
                        {{ item.value }}

                        <span v-if="item.unit">{{ $tc(item.unit, 2) }}</span>
                      </div>
                    </b-alert>
                  </b-col>
                </b-row>
              </b-col>

              <b-col md="6">
                <b-row>
                  <b-col
                    v-for="(item, i) in generalList.slice(generalList.length /2)"
                    :key="i"
                    sm="12"
                    class="d-flex flex-column"
                  >
                    <label
                      v-b-tooltip.hover.v-primary
                      class="d-flex flex-row align-items-center font-weight-bold mb-50"
                      style="column-gap: .34rem"
                      :title="item.tooltip && item.tooltip.length ? $t(item.tooltip) : ''"
                    >
                      {{ $t(item.title) }}

                      <feather-icon
                        v-if="item.tooltip && item.tooltip.length"
                        icon="InfoIcon"
                      />
                    </label>

                    <b-alert
                      :variant="item.variant"
                      show
                    >
                      <div class="alert-body text-center px-25 py-25">
                        {{ item.value }}

                        {{ $tc(item.unit, 2) }}
                      </div>
                    </b-alert>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-tab>

          <b-tab
            :title="$t('Details')"
            title-item-class="btn btn-sm p-0"
            title-link-class="p-75"
          >
            <!-- eslint-disable -->
            <sw-table
              table-id="4c9276148cb34123972564a773f5cfd1"
              show-columns
              :fields.async="tableFields"
              :pagination="pagination"
              @change-pagination="Object.assign(pagination, $event)"
              @set-fields="tableFields = $event"
              @reload-content="loadData"
            >
              <template #table>
                <b-table
                  :items="items"
                  :fields="fields"
                  striped
                  responsive
                  :busy="loading"
                  show-empty
                >
                  <template #head()="item">
                    <div
                      v-b-tooltip.hover.v-primary
                      class="d-flex flex-row align-items-center"
                      style="column-gap: .34rem"
                      :title="item.field.tooltip ? $t(item.field.tooltip) : ''"
                    >
                      {{ $t(item.label) }}

                      <feather-icon
                        v-if="item.field.tooltip"
                        icon="InfoIcon"
                      />
                    </div>
                  </template>

                  <template #cell(maxDurationStay)="{ item }">
                    <b-badge variant="primary">
                      <span v-if="item.maxDurationStay !== null">
                        {{ item.maxDurationStay }}
                        {{ $tc('Days', item.maxDurationStay).toLowerCase() }}
                      </span>

                      <span v-else>-</span>
                    </b-badge>
                  </template>

                  <template #cell(stayDurationAvg)="{ item }">
                    <span v-if="item.stayDurationAvg != null">
                      {{ secondsToHours(item.stayDurationAvg, false).hours }}{{ $t('Days')[0] }}

                      {{ secondsToHours(item.stayDurationAvg, false).minutes }}{{ $t('Hour')[0] }}

                      {{ secondsToHours(item.stayDurationAvg, false).seconds }}{{ $t('Minutes')[0] }}
                    </span>
                    <span v-else>-</span>
                  </template>

                  <template #cell(statusSuccess)="{ item }">
                    <b-badge variant="success">
                      <span v-if="item.status">{{ item.status.closedPositive || '-' }}</span>
                    </b-badge>
                  </template>

                  <template #cell(statusFailure)="{ item }">
                    <b-badge variant="danger">
                      <span v-if="item.status">{{ item.status.closedNegative || '-' }}</span>
                    </b-badge>
                  </template>

                  <template #cell(predictedValue)="{ item }">
                    <b-badge
                      v-if="item.predictedValue"
                      variant="info"
                    >
                      <span v-if="item.predictedValue.all !== null">
                        {{ (item.predictedValue.all) | priceFormat }} {{ settings.defaultCurrency || 'PLN' }}
                      </span>
                      <span v-else>-</span>
                    </b-badge>
                  </template>

                  <template #cell(predictedValueSuccess)="{ item }">
                    <b-badge
                      v-if="item.predictedValue && item.predictedValue.status"
                      variant="light-success"
                    >
                      <span v-if="item.predictedValue.status.closedPositive !== null">
                        {{ (item.predictedValue.status.closedPositive || 0) | priceFormat }} {{ settings.defaultCurrency || 'PLN' }}
                      </span>

                      <span v-else>-</span>
                    </b-badge>
                  </template>

                  <template #cell(predictedValueFailure)="{ item }">
                    <b-badge
                      v-if="item.predictedValue && item.predictedValue.status"
                      variant="light-danger"
                    >
                      <span v-if="item.predictedValue.status.closedNegative !== null">
                        {{ (item.predictedValue.status.closedNegative || 0) | priceFormat }} {{ settings.defaultCurrency || 'PLN' }}
                      </span>
                      <span v-else>-</span>
                    </b-badge>
                  </template>

                  <!--    Other    -->
                    <!--    Other    -->
                    <template #cell()="row">
                        <table-default-cell
                                :field="row.field"
                                :value="row.value"
                        />
                    </template>

                    <!--      Empty      -->
                    <template #empty>
                        <empty-content />
                    </template>

                    <!--      Table Busy      -->
                    <template #table-busy>
                        <div class="text-center text-danger my-2">
                            <b-spinner
                                    class="align-middle"
                                    variant="primary"
                            />
                        </div>
                    </template>
                </b-table>
              </template>
            </sw-table>
          </b-tab>
        </sw-tabs>

        <h4 class="text-secondary mb-75 d-flex justify-content-between">
          {{ $t('Indicators') }}

          <b-button
                  variant="primary"
                  size="sm"
                  :disabled="exportLoading"
                  @click="getRecordsToExport"
          >
              <span v-if="!exportLoading"><feather-icon icon="DownloadIcon" />Export</span>
              <span v-else>
            <b-spinner
                    small
                    variant="light"
            />
          </span>
          </b-button>
        </h4>

        <sw-tabs v-if="!loading">
          <b-tab
            title-item-class="btn btn-sm p-0"
            title-link-class="px-50 py-75 h-100 d-flex align-items-center align-content-center justify-content-center"
            lazy
            :title="$t('Successes')"
          >
            <indicators-tab
              :disabled-reasons="disabledNames"
              :funnel="selectedFunnel"
              :date-filter="dateFilter"
              title="Successes"
              status="CLOSED_POSITIVE"
              :reasons="getPositive"
              :chart-data="getPositiveChartStats"
            >
              <template #legend>
                <div style="width: max-content">
                  <div
                    v-for="reason in getAllPositive"
                    :key="`getAllPositive_${reason.id}`"
                    :style="{ textDecoration: disabledNames.includes(reason.name) ? 'line-through' : '' }"
                    :class="{ 'cursor-pointer': getAllPositive.length > 1}"
                    @click="getAllPositive.length > 1 ? changeSelectedStatuses(reason.name) : null"
                    class="text-nowrap"
                  >
                    <div
                      class="color-dot"
                      :style="{ backgroundColor: reason.color }"
                    />
                    {{ reason.name }}
                  </div>
                </div>
              </template>
            </indicators-tab>
          </b-tab>

          <b-tab
            title-item-class="btn btn-sm p-0"
            title-link-class="px-50 py-75 h-100 d-flex align-items-center justify-content-center"
            lazy
            :title="$t('Failures')"
          >
            <indicators-tab
              :disabled-reasons="disabledNames"
              :funnel="selectedFunnel"
              :date-filter="dateFilter"
              title="Failures"
              status="CLOSED_NEGATIVE"
              :reasons="getNegative"
              :chart-data="getNegativeChartStats"
            >
              <template #legend>
                <div style="width: max-content">
                  <div
                    v-for="reason in getAllNegative"
                    :key="`getAllNegative_${reason.id}`"
                    :style="{ textDecoration: disabledNames.includes(reason.name) ? 'line-through' : '' }"
                    :class="{ 'cursor-pointer': getAllNegative.length > 1}"
                    @click="getAllNegative.length > 1 ? changeSelectedStatuses(reason.name) : null"
                    class="text-nowrap"
                  >
                    <div
                      class="color-dot"
                      :style="{ backgroundColor: reason.color }"
                    />
                    {{ reason.name }}
                  </div>
                </div>
              </template>
            </indicators-tab>
          </b-tab>
        </sw-tabs>
      </div>
    </transition>
  </div>
</template>

<script>
import {
  BFormRadioGroup, BTab, BTable, VBPopover, VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import IndicatorsTab from '@/pages/reports/tabs/components/IndicatorsTab.vue'
import axiosIns from '@/libs/axios'
import moment from 'moment'
import flatPickr from 'vue-flatpickr-component'
import { GET_FUNNELS } from '@/@constants/mutations'
import XLSX from 'xlsx'
import { formatDate } from '@core/utils/filter'
import { mapGetters } from 'vuex'

export default {
  components: {
    BFormRadioGroup,
    vSelect,
    BTab,
    BTable,
    IndicatorsTab,
    flatPickr,
  },

  directives: {
    'b-popover': VBPopover,
    'b-tooltip': VBTooltip,
  },

  data: () => ({
    loading: true,
    exportLoading: false,

    filters: {
      createdAt: null,
      createdAtOptions: null,
    },
    selectedFunnel: '',
    sorter: {
      sortBy: 'createdAt',
      sortDesc: true,
    },
    pagination: {
      perPage: 25,
      totalRecords: 0,
      currentPage: 1,
    },

    disabledNames: [],
    filterCreatedAtOptions: [],
    dateFilter: '',
    activeFilter: '',
    items: [],
    tableFields: [
      { key: 'name', label: 'Status', visible: true },
      { key: 'maxDurationStay', label: 'PlannedTime', visible: true },
      { key: 'stayDurationAvg', label: 'AverageDwellTime', visible: true },
      {
        key: 'statusSuccess', label: 'NumberOfSuccesses', visible: true, tooltip: 'SuccessCountTip',
      },
      {
        key: 'statusFailure', label: 'NumberOfLosses', visible: true, tooltip: 'FailureCountTip',
      },
      { key: 'predictedValue', label: 'TotalProcessValue', visible: true },
      { key: 'predictedValueSuccess', label: 'SuccessfulClosedProcesses', visible: true },
      { key: 'predictedValueFailure', label: 'FailedClosedProcesses', visible: true },
    ],
    generalList: [],
    reasons: [],
    chartData: [],
    itemsa: [],
    funnels: [],
  }),

  computed: {
    ...mapGetters({
      settings: 'system/getSettings',
    }),
    fields() {
      return this.tableFields.filter(field => field.visible)
    },
    getAllNegative() {
      return this.reasons.map((a, index) => ({ ...a, index })).filter(t => t.status === 'CLOSED_NEGATIVE')
    },
    getAllPositive() {
      return this.reasons.map((a, index) => ({ ...a, index })).filter(t => t.status === 'CLOSED_POSITIVE')
    },
    getNegative() {
      return this.reasons.map((a, index) => ({ ...a, index })).filter(t => t.status === 'CLOSED_NEGATIVE').filter(t => !this.disabledNames.includes(t.name))
    },
    getPositive() {
      return this.reasons.map((a, index) => ({ ...a, index })).filter(t => t.status === 'CLOSED_POSITIVE').filter(t => !this.disabledNames.includes(t.name))
    },

    getNegativeChartStats() {
      const items = this.chartData.map((a, index) => ({ ...a, index })).filter(t => t.status === 'CLOSED_NEGATIVE').filter(t => !this.disabledNames.includes(t.name))
      const labels = items.map(reason => reason.name)
      const returnData = {
        labels,
        datasets: [
          {
            data: items.map(reason => reason.closed),
            backgroundColor: items.map(reason => reason.color),
          },
        ],
      }
      // console.log(returnData)
      return returnData
    },
    getPositiveChartStats() {
      const items = this.chartData.map((a, index) => ({ ...a, index })).filter(t => t.status === 'CLOSED_POSITIVE').filter(t => !this.disabledNames.includes(t.name))
      const labels = items.map(reason => reason.name)
      const returnData = {
        labels,
        datasets: [
          {
            data: items.map(reason => reason.closed),
            backgroundColor: items.map(reason => reason.color),
          },
        ],
      }
      // console.log(returnData)
      return returnData
    },
  },

  async mounted() {
    this.loading = true
    await this.ini()

    await this.getSettings()

    await this.loadData()
    this.loading = false
  },

  methods: {
    setDate(dateValue) {
      if (dateValue === 'last7Days') {
        const startAt = moment().subtract(7, 'days').format('YYYY-MM-DD hh:mm')

        this.dateFilter = [startAt]
      }

      if (dateValue === 'thisMonth') {
        const startAt = moment().startOf('month').format('YYYY-MM-DD hh:mm')
        const endAt = moment().endOf('month').format('YYYY-MM-DD hh:mm')

        this.dateFilter = [startAt, endAt]
      }

      if (dateValue === 'lastMonth') {
        const startAt = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD hh:mm')
        const endAt = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD hh:mm')

        this.dateFilter = [startAt, endAt]
      }

      if (dateValue === 'last6Months') {
        const startAt = moment().subtract(6, 'months').startOf('month').format('YYYY-MM-DD hh:mm')
        const endAt = moment().format('YYYY-MM-DD hh:mm')

        this.dateFilter = [startAt, endAt]
      }

      if (dateValue === null) this.dateFilter = ''
    },
    async getSettings() {
      try {
        const params = { fields_load: this.$fields.CONTACT_THREAD_CLOSE_REASONS, orderBy: 'position.asc' }
        const resp = await axiosIns.get('1/settings/contactThreadStatusReasons', { params })

        if (resp?.data?.data?.items) {
          const statuses = resp.data?.data?.items || []
          const success = getComputedStyle(document.body).getPropertyValue('--theme-color-success')
          const danger = getComputedStyle(document.body).getPropertyValue('--theme-color-danger')
          this.reasons = [
            // eslint-disable-next-line no-unused-vars
            ...statuses.filter(t => t.status === 'CLOSED_POSITIVE').map((t, index) => ({ ...t, color: String(this.lightenDarkenColor(success, -20 + (index * 10))).toUpperCase() })),
            // eslint-disable-next-line no-unused-vars
            ...statuses.filter(t => t.status === 'CLOSED_NEGATIVE').map((t, index) => ({ ...t, color: String(this.lightenDarkenColor(danger, -20 + (index * 10))).toUpperCase() })),
          ]
        }
      } catch (err) {
        console.error(err)
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }

      try {
        this.funnels = await this.$store.dispatch(`funnelMain/${GET_FUNNELS}`) || []
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    ini() {
      this.filterCreatedAtOptions = [
        { text: this.$t('All'), value: null },
        { text: this.$t('Last7Days'), value: 'last7Days' },
        { text: this.$t('ThisMonth'), value: 'thisMonth' },
        { text: this.$t('LastMonth'), value: 'lastMonth' },
        { text: this.$t('Last6Months'), value: 'last6Months' },
      ]
    },

    changeSelectedStatuses(name) {
      if (this.disabledNames.includes(name)) {
        const index = this.disabledNames.indexOf(name)
        if (index > -1) this.$delete(this.disabledNames, index)
      } else {
        this.disabledNames.push(name)
      }
    },
    async loadData() {
      this.loading = true

      // Init stats
      // try {
      //   const params = {}
      //
      //   if (this.dateFilter) {
      //     const [startAt, endAt] = this.dateFilter.dateRange()
      //     if (startAt) params.gte_createdAt = moment(startAt, 'YYYY-MM-DD').format('YYYY-MM-DD hh:mm')
      //     if (endAt) params.lt_createdAt = moment(endAt, 'YYYY-MM-DD').format('YYYY-MM-DD hh:mm')
      //   }
      //
      //   if (this.selectedFunnel) {
      //     params['eq_contactThreadFunnelTypePoint-contactThreadFunnelType-id'] = this.getObjectId(this.selectedFunnel)
      //   }
      //
      //   const resp = await axiosIns.get('1/reports/contactThreads', { params })
      //   const {
      //     // eslint-disable-next-line no-unused-vars
      //     all, contactThreadStatusReasons, predictedValue, statusClosedTimeAvg,
      //   } = resp.data.data.contactThreads
      //   // await this.setGeneralStats(statusClosedTimeAvg, predictedValue)
      // } catch (err) {
      //   console.log(err)
      // }

      // Status table
      try {
        const params = {}

        if (this.dateFilter) {
          const [startAt, endAt] = this.dateFilter.dateRange()
          if (startAt) params.gte_createdAt = moment(startAt, 'YYYY-MM-DD').format('YYYY-MM-DD hh:mm')
          if (endAt) params.lt_createdAt = moment(endAt, 'YYYY-MM-DD').format('YYYY-MM-DD hh:mm')
        }

        if (this.selectedFunnel) {
          params['eq_contactThreadFunnelType-id'] = this.getObjectId(this.selectedFunnel)
        }

        const resp = await axiosIns.get('1/reports/contactThreadFunnelProcesses', { params })
        // eslint-disable-next-line no-unused-vars
        const { contactThreadFunnelProcesses } = resp.data.data

        const success = getComputedStyle(document.body).getPropertyValue('--theme-color-success')

        const danger = getComputedStyle(document.body).getPropertyValue('--theme-color-danger')

        this.chartData = [
          // eslint-disable-next-line no-unused-vars
          ...contactThreadFunnelProcesses.contactThreadFunnelProcessStatusReasons.filter(t => t.status === 'CLOSED_POSITIVE').map((t, index) => ({ ...t, color: String(this.lightenDarkenColor(success, -20 + (index * 10))).toUpperCase() })),
          // eslint-disable-next-line no-unused-vars
          ...contactThreadFunnelProcesses.contactThreadFunnelProcessStatusReasons.filter(t => t.status === 'CLOSED_NEGATIVE').map((t, index) => ({ ...t, color: String(this.lightenDarkenColor(danger, -20 + (index * 10))).toUpperCase() })),
        ]

        await this.setGeneralStats(contactThreadFunnelProcesses.statusClosedDurationAvg, contactThreadFunnelProcesses.predictedValue)

        this.items = contactThreadFunnelProcesses?.contactThreadFunnelTypePoints || []
      } catch (err) {
        console.error(err)
      }

      this.loading = false
    },

    async setGeneralStats(statusClosedTimeAvg, predictedValue) {
      this.generalList = [
        {
          title: 'AverageProcessDuration',
          value: `
            ${this.secondsToHours(statusClosedTimeAvg?.all || 0, false).days}${this.$i18n.t('Days')[0]}
            ${this.secondsToHours(statusClosedTimeAvg?.all || 0, false).hours}${this.$i18n.t('Hour')[0]}
            ${this.secondsToHours(statusClosedTimeAvg?.all || 0, false).minutes}${this.$i18n.t('Minutes', this.secondsToHours(statusClosedTimeAvg?.all || 0).minutes)[0]}
          `,
          variant: 'primary',
          tooltip: 'TotalTimeTooltip',
        },
        {
          title: 'AverageSuccessProcessDuration',
          value: `
            ${this.secondsToHours(statusClosedTimeAvg?.status?.closedPositive || 0, false).days}${this.$i18n.t('Days')[0]}
            ${this.secondsToHours(statusClosedTimeAvg?.status?.closedPositive || 0, false).hours}${this.$i18n.t('Hour')[0]}
            ${this.secondsToHours(statusClosedTimeAvg?.status?.closedPositive || 0, false).minutes}${this.$i18n.t('Minutes', this.secondsToHours(statusClosedTimeAvg?.status?.closedPositive || 0).minutes)[0]}
          `,
          variant: 'success',
        },
        {
          title: 'AverageFailureProcessDuration',
          value: `
            ${this.secondsToHours(statusClosedTimeAvg?.status?.closedNegative || 0, false).days}${this.$i18n.t('Days')[0]}
            ${this.secondsToHours(statusClosedTimeAvg?.status?.closedNegative || 0, false).hours}${this.$i18n.t('Hour')[0]}
            ${this.secondsToHours(statusClosedTimeAvg?.status?.closedNegative || 0, false).minutes}${this.$i18n.t('Minutes', this.secondsToHours(statusClosedTimeAvg?.status?.closedNegative || 0).minutes)[0]}
          `,
          variant: 'danger',
        },
        {
          title: 'TotalFunnelValue', unit: 'PLN', value: this.$options.filters.priceFormat(predictedValue?.all || 0), variant: 'primary',
        },
        {
          title: 'SuccessProcessValue', unit: 'PLN', value: this.$options.filters.priceFormat(predictedValue?.status?.closedPositive || 0), variant: 'success',
        },
        {
          title: 'FailureProcessValue', unit: 'PLN', value: this.$options.filters.priceFormat(predictedValue?.status?.closedNegative || 0), variant: 'danger',
        },
      ]
    },
    async getRecordsToExport() {
      this.exportLoading = true
      try {
        const { total } = await this.loadExportItems(1, 1)
        const chunksToLoad = Math.ceil(total / 75)
        const requests = new Array(chunksToLoad).fill(null)
        const response = await Promise.all(requests.map((_, index) => this.loadExportItems(index + 1, 75)))

        const items = []
        response.forEach(res => {
          items.push(...res?.items.map(exportedItem => ({
            ...exportedItem,
          })))
        })

        this.$nextTick(() => {
          this.exportRecords(items)
        })
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    /* eslint-disable */
      async exportRecords(recordsList) {
          const wb = XLSX.utils.book_new()

          const order = [
              { key: 'id', label: 'ID' },
              { key: 'contact', label: 'Contact' },
              { key: 'name', label: 'Thread' },
              { key: 'link', label: 'Link' },
              { key: 'status', label: 'Status' },
              { key: 'company', label: 'Company' },
              { key: 'statusClosedAt', label: 'ClosingDate' },
              { key: 'contactThreadStatusReason', label: 'Reason' },
              { key: 'statusNote', label: 'Note' },
              { key: 'predictedValue', label: 'CustomerValue' },
              { key: 'assignedUsers', label: 'AssignedUser' },
          ]

          const aoaToExport = [
              ...recordsList.sort((a, b) => (b.status > a.status) ? 1 : -1).map(t => order.map(({ key }) => {
                  if (key === 'contact') {
                      if (t[key]) return `${this.getUserName(t[key])}`
                      else return `-`
                  }

                  else if (key === 'statusClosedContactThreadFunnelTypePoint' && t[key]) {
                      if (t.contactThreadFunnelType?.name) return `${t.contactThreadFunnelType?.name} - ${t[key]?.name || ''}`
                      return t[key]?.name || '-'
                  }

                  else if (key === 'contactThreadStatusReason') {
                      return t[key]?.name || '-'
                  }

                  else if (key === 'company' && t?.contact?.organization) {
                      return t?.contact?.organization?.name || '-'
                  }

                  else if (key === 'files') {
                      return t[key]?.length || 0
                  }

                  else if (key === 'status') {
                      return t[key] === 'CLOSED_POSITIVE' ? this.$i18n.t('funnel.status.COMPLETED') : this.$i18n.t('funnel.status.REJECTED')
                  }

                  else if (key === 'link') {
                      return t.id && t?.contact?.id ? `${window.location.origin}/contact/${t.contact.id}/thread/${t.id}/timeline` : '-'
                  }

                  else if (key === 'assignedUsers') {
                      return t[key] ? t[key]?.map(user => this.getUserName(user))?.join(', ') : '-'
                  }

                  else if (typeof t[key] === 'boolean') return t[key] ? 1 : 0
                  else if (key === 'updatedAt' && t[key]?.date) {
                      return t.status !== 'O' ? this.formatDate(t[key].date, {
                          month: 'numeric',
                          day: 'numeric',
                          year: 'numeric'
                      }, this.$i18n.locale) : '-'
                  }
                  else if (t[key]?.date) return this.formatDate(t[key].date, { month: 'numeric', day: 'numeric', year: 'numeric' }, this.$i18n.locale)

                  return t[key] ?? ''
              }).flat(Infinity)),
          ]

          const headers = order.map(({ label }) => this.$i18n.t(label))
          aoaToExport.unshift(headers)

          const ws = XLSX.utils.aoa_to_sheet(aoaToExport);
          XLSX.utils.book_append_sheet(wb, ws, `${this.$i18n.t('FunnelsAndProcesses').replace(' ', '_')}`);
          XLSX.writeFile(wb, `${this.$i18n.t('FunnelsAndProcesses').replace(' ', '_')}.xlsx`);

          this.exportLoading = false
      },
      formatDate,
      async loadExportItems(page = 1, perPage = 1) {
          const params = {
              fields_load: this.$fields.FUNNEL,
              limit: perPage,
              page,
          }

          if (this.status) {
              params.eq_status = this.status
          }

          if (this.dateFilter) {
              const [startAt, endAt] = this.dateFilter.dateRange()
              if (startAt) params.gte_createdAt = moment(startAt, 'YYYY-MM-DD').format('YYYY-MM-DD hh:mm')
              if (endAt) params.lt_createdAt = moment(endAt, 'YYYY-MM-DD').format('YYYY-MM-DD hh:mm')
          }

          if (this.selectedFunnel) {
              params['eq_contactThreadFunnelType-id'] = this.getObjectId(this.selectedFunnel)
          }

          params.isNotNull_contactThreadStatusReason = 1

          const { data } = await axiosIns.get('1/contacts/threads/funnelProcesses', { params })

          return { total: data.data.totalItemCount, items: data.data.items.map(i => ({ ...i, ...i.contactThread })) }
      },
  },
}
</script>
