<template>
  <div>
    <!-- eslint-disable -->
    <!--    START::Add Button    -->
    <!--    <b-button-->
    <!--      variant="primary"-->
    <!--      size="sm"-->
    <!--      @click="isModalOpen = true"-->
    <!--    >-->
    <!--      {{ $t('Add') }}-->

    <!--      <feather-icon icon="PlusIcon" />-->
    <!--    </b-button>-->
    <!--    END::Add Button    -->

    <!--    Section: Filters    -->
    <sw-filters
      v-if="$route.name !== 'contact'"
      :shorts="[
        {
          title: $t('Seller'),
          type: 'avatar',
          value: () => filters.seller,
          clear: () => { filters.seller = []; loadStatistics(); reloadContent() }
        },
        {
          title: $t('CommissionFor'),
          type: 'avatar',
          value: () => filters.provisionFor,
          clear: () => { filters.provisionFor = []; loadStatistics(); reloadContent() }
        },
        {
          title: $t('PaymentMethod'),
          value: () => filters.paymentMethod ? $t(filters.paymentMethod.toLowerCase()) : null,
          clear: () => { filters.paymentMethod = ''; loadStatistics(); reloadContent() }
        },
        {
          title: $t('StatusOfOrder'),
          value: () => filters.orderStatus ? $tc(`offer.status.${filters.orderStatus}`, 2) : null,
          clear: () => { filters.orderStatus = ''; loadStatistics(); reloadContent() }
        },
        {
          title: $t('OrderDate'),
          value: () => filters.orderDate,
          clear: () => { filters.orderDate = ''; loadStatistics(); reloadContent() }
        }
      ]"
    >
      <filters :filters.async="filters" />
    </sw-filters>

    <!--    START::Calculation    -->
    <!--    <calculation-->
    <!--      :is-loading="isLoading"-->
    <!--      :date="nextCommissionRecalculation"-->
    <!--    />-->
    <!--    END::Calculation    -->

    <!--    START::Items    -->
    <b-row class="mt-1">
      <b-col
        v-for="stat in stats"
        :key="stat.key"
        md="6"
        @click="status = stat.key"
      >
        <item
          :is-selected="status === stat.key"
          :is-loading="loading"
          :name="$t(stat.name)"
          :tip="$t(stat.info)"
          :val="stat.value"
          :val-group="stat.groupValue"
          :val-all="stat.allValue"
        />
      </b-col>
    </b-row>
    <!--    END::Items    -->

    <!--    START::Table    -->
    <div class="mt-50">
      <my-table ref="table" :contact-thread="contactThread" @load-stats="e => {
        this.search = e
        loadStatistics()
      }" :status="status" :filters="filters" />
    </div>
    <!--    END::Table    -->

    <!--    START::Modal -> New    -->
    <!--    <b-modal-->
    <!--      v-model="isModalOpen"-->
    <!--      :title="$t('AddCommission')"-->
    <!--      hide-footer-->
    <!--      tabindex="none"-->
    <!--      size="md"-->
    <!--    >-->
    <!--      <my-modal />-->

    <!--      <b-button-->
    <!--        variant="success"-->
    <!--        class="mt-2"-->
    <!--      >-->
    <!--        {{ $t('AddCommission') }}-->
    <!--      </b-button>-->
    <!--    </b-modal>-->
    <!--    END::Modal -> New    -->
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'
import axiosIns from '@/libs/axios'
import moment from 'moment'
import Filters from './commissions/Filters.vue'
import Item from './Item.vue'
import MyTable from './commissions/Table.vue'

export default {
  name: 'SwCommissions',
  components: {
    Filters,
    // Calculation,
    Item,
    MyTable,
    // MyModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    contactThread: {
      default: null,
      required: false,
    },
  },
  data: () => ({
    loading: false,
    nextCommissionRecalculation: '',
    status: 'NEW',
    stats: [
      {
        key: 'NEW', name: 'NotionalCommissions', allValue: 0, value: 0, groupValue: 0, info: 'HypotheticalCommissionsInfo',
      },
      {
        key: 'REALIZED', name: 'QualifiedCommissions', allValue: 0, value: 0, groupValue: 0, info: 'RealizedCommissionsInfo',
      },
    ],

    filters: {
      seller: null,
      provisionFor: null,
      paymentMethod: '',
      orderStatus: '',
      orderCreatedAt: '',
      orderDate: '',
    },

    isModalOpen: false,
    search: '',
  }),
  watch: {
    filters: {
      deep: true,
      handler() {
        if (!this.loading) {
          this.loadStatistics()
        }
      },
    },
  },
  methods: {
    reloadContent() {
      if (this.$refs.table) {
        this.$refs.table.loadData()
      }
    },
    async loadStatistics() {
      this.loading = true
      try {
        const params = {}

        if (this.contactThread) params['eq_contactThreadCartBox-contactThread-id'] = this.getObjectId(this.contactThread)

        if (this.filters?.seller?.length) params['eqArr_contactThreadCartBox-contactThreadCartOrder-seller-id'] = JSON.stringify(this.filters.seller.mapKeys())
        if (this.filters?.provisionFor?.length) params['eqArr_user-id'] = JSON.stringify(this.filters.provisionFor.mapKeys())
        if (this.filters?.paymentMethod) params['eq_contactThreadCartBox-paymentType'] = this.filters.paymentMethod
        if (this.filters?.orderStatus) params['eq_contactThreadCartBox-contactThreadCartOrder-status'] = this.filters.orderStatus
        if (this.filters?.orderCreatedAt) {
          const [startAt, endAt] = this.filters.orderCreatedAt.dateRange()
          if (startAt) params['gte_contactThreadCartBox-contactThreadCartOrder-createdAt'] = `${moment(startAt, 'YYYY-MM-DD').format('YYYY-MM-DD')} 00:00:00`
          if (endAt) params['lt_contactThreadCartBox-contactThreadCartOrder-createdAt'] = `${moment(endAt, 'YYYY-MM-DD').format('YYYY-MM-DD')} 23:59:59`
        }

        if (this.filters?.orderDate) {
          const [startAt, endAt] = this.filters.orderDate.dateRange()
          if (startAt) params['gte_contactThreadCartBox-contactThreadCartOrder-orderDate'] = `${moment(startAt, 'YYYY-MM-DD').format('YYYY-MM-DD')} 00:00:00`
          if (endAt) params['lt_contactThreadCartBox-contactThreadCartOrder-orderDate'] = `${moment(endAt, 'YYYY-MM-DD').format('YYYY-MM-DD')} 23:59:59`
        }

        if (this.search && this.search.length) {
          const searchString = this.search.replaceAll(' ', '%')
          params.search = `%${searchString}%`
          params.search_fields = 'contactThreadCartBox-contactThreadCartOrder-number,user-firstName,user-lastName,user-userId'
        }

        const resp = await axiosIns.get('1/marketingPlans/pointPayments/getStatistics', { params })

        if (resp.data?.data) {
          const types = {
            all: 'allValue',
            me: 'value',
            other: 'groupValue',
          }
          Object.keys(resp.data?.data).forEach(type => {
            // type => 'all', 'me', 'other'
            Object.keys(resp.data?.data[type]).forEach(key => {
              // key => 'REALIZED', 'NEW'
              const index = this.stats.findIndex(item => item.key === key)
              if (index > -1) this.stats[index][types[type]] = resp.data?.data[type][key]
            })
          })
        }
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
