import {
  DELETE_PICTURE_TYPE, GET_PICTURE_TYPE, SAVE_PICTURE_TYPE,
} from '@/@constants/mutations'
// eslint-disable-next-line import/no-cycle
import axiosIns from '@/libs/axios'
import { SHOP_PICTURE_TYPES } from '@/@constants/fields'

export default {
  namespaced: true,
  actions: {
    // eslint-disable-next-line no-unused-vars
    [GET_PICTURE_TYPE]: ({ commit }) => new Promise((resolve, reject) => {
      axiosIns.get('1/settings/shopProductPictureTypes', { params: { fields_load: SHOP_PICTURE_TYPES } })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [DELETE_PICTURE_TYPE]: ({ commit }, id) => new Promise((resolve, reject) => {
      axiosIns.delete('1/settings/shopProductPictureTypes', { data: [id] })
        .then(() => {
          resolve()
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [SAVE_PICTURE_TYPE]: ({ commit }, types) => new Promise((resolve, reject) => {
      axiosIns.put('1/settings/shopProductPictureTypes', types)
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
  },
}
