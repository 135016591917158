<template>
  <component
    :is="isMobile ? 'div' : 'app-timeline-item'"
    variant="warning"
    icon="FrownIcon"
    class="pb-2 d-flex flex-column justify-content-start align-items-start position-relative"
    style="row-gap: .34rem"
  >
    <slot />
    <component
      :is="isContact ? 'div' : 'b-card'"
      class="w-100 rounded-lg mb-0 position-relative"
      no-body
    >
      <b-card-body :class="`p-${ isContact ? '0' : '1' }`">
        <div
          class="d-flex mb-50"
          style="column-gap: .5rem"
        >
          <b-avatar
            v-if="isMobile"
            rounded="sm"
            size="sm"
            variant="light-success"
          >
            <span class="d-flex align-items-center">
              <feather-icon
                icon="FileIcon"
                size="14"
              />
            </span>
          </b-avatar>

          <router-link
            v-if="thread && !isContact"
            class="font-small-3"
            :class="{ 'deleted-text': thread.contact.deletedAt }"
            :to="`/contact/${thread.contact.id}/thread/${thread.id}/timeline`"
          >
            {{ thread.contact.firstName }} {{ thread.contact.lastName }} – {{ thread.name }}
          </router-link>

          <p
            v-else
            class="mb-0"
          >
            {{ $t('CurrentContact') }}
          </p>
        </div>
        <div class="d-flex flex-column flex-md-row mt-25 mt-md-0 font-weight-bold">
          <div>
            {{ $t('Complaint') }}
            <!--            <router-link :to="{ name: 'automations', params: { automationId: data.automationProcedure.id } }">-->
            <span class="text-primary" />
            <!--            </router-link>-->
          </div>
          <span class="font-italic ml-50 ml-md-25">– {{ formatDate(timelineDate, { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' }, $i18n.locale) }}</span>
        </div>

        <hr class="border-light-primary">

        <app-collapse
          class="pl-0 w-100 px-md-50"
          hover
        >
          <app-collapse-item
            :title="$t('ShowDetails')"
            :is-padding="false"
            arrow-left
            :is-title="false"
          >
            <template #subTitle>
              <div
                class="d-flex flex-row"
                style="row-gap: .34rem; column-gap: .5rem;"
              >
                <strong>{{ $t('ShowDetails') }}</strong>

                <b-badge variant="light-primary">
                  {{ data.orderNumber }}
                </b-badge>

                <b-badge :variant="complaintStatusVariantByValue(data.status)">
                  {{ $t(complaintStatusByValue(data.status)) }}
                </b-badge>
              </div>
            </template>

            <b-row class="mt-50">
              <b-col
                sm="12"
                md="6"
                class="d-flex flex-column flex-md-row"
              >
                <strong>{{ $t('Contact') }}</strong>

                <router-link
                  class="ml-50"
                  :class="{ 'deleted-text': data.contactThread.contact.deletedAt }"
                  :to="`/contact/${data.contactThread.contact.id}`"
                >
                  {{ (`${data.contactThread.contact.firstName} ${data.contactThread.contact.lastName}`) | truncate(24, '…') }}
                </router-link>
              </b-col>

              <!-- Thread -->
              <b-col
                sm="12"
                md="6"
                class="d-flex flex-column flex-md-row"
              >
                <strong>{{ $t('Thread') }}</strong>

                <router-link
                  class="ml-50"
                  :class="{ 'deleted-text': data.contactThread.contact.deletedAt }"
                  :to="`/contact/${data.contactThread.contact.id}/thread/${data.contactThread.id}/offers`"
                >
                  {{ data.contactThread.name | truncate(24) }}
                </router-link>
              </b-col>

              <b-col
                sm="12"
                md="6"
                class="d-flex flex-column flex-md-row"
              >
                <strong>{{ $t('Address') }}</strong>

                <div class="ml-50">
                  {{ getAddress(data) }}
                </div>
              </b-col>

              <b-col
                sm="12"
                md="6"
                class="d-flex flex-column flex-md-row"
              >
                <strong>{{ $t('OrderDate') }}</strong>
                <div
                  v-if="data.orderDate"
                  class="ml-50"
                >
                  {{ formatDate(data.orderDate.date, { month: 'short', day: 'numeric', year: 'numeric' }, $i18n.locale) }}
                </div>
              </b-col>

              <b-col
                v-if="data.note"
                sm="12"
                class="mt-25"
              >
                <b-alert
                  show
                  variant="primary"
                >
                  <div class="alert-body">
                    {{ data.note }}
                  </div>
                </b-alert>
              </b-col>
            </b-row>
          </app-collapse-item>
        </app-collapse>

      </b-card-body>
    </component>
  </component>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { formatDate } from '@core/utils/filter'
import { complaintStatusByValue, complaintStatusVariantByValue } from '@/@types/complainStatus'
import useVerticalLayout from '@core/layouts/layout-vertical/useVerticalLayout'
import { onUnmounted } from '@vue/composition-api'

export default {
  name: 'TimelineAutomation',
  components: {
    AppTimelineItem,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: ['data', 'timelineDate', 'threadId', 'thread', 'isContact', 'automation'],
  methods: {
    formatDate,
    complaintStatusByValue,
    complaintStatusVariantByValue,
  },
  setup() {
    const { isMobile, resizeHandler } = useVerticalLayout()

    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    return {
      isMobile,
    }
  },
}
</script>

<style scoped>

</style>
