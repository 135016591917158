const currency = () => {
  const currencyList = [
    'PLN',
    'EUR',
    'USD',
    'CNY',
    'JPY',
    'KRW',
    'CAD',
    'RUB',
    'UAH',
    'TRY',
    'BGN',
    'MXN',
  ]

  return currencyList
}

export default currency
