var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-alert',{attrs:{"show":"","variant":"primary"}},[_c('div',{staticClass:"alert-body",staticStyle:{"text-align":"start"}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"MailIcon"}}),_c('span',{staticClass:"ml-25"},[_vm._v(" "+_vm._s(_vm.$t('gEmail.settings.hereYouWillDefineYourCompany'))+" ")])],1)]),_c('div',{staticClass:"mb-1"},[(_vm.emailAccountList.length)?_c('b-row',_vm._l((_vm.emailAccountList),function(account,i){return _c('b-col',{key:i,attrs:{"sm":"12"}},[_c('b-card',[_c('email-account-item',{attrs:{"account":account},on:{"update":function (item) {
              // $set(emailAccountList, i, item)
              _vm.loadEmailAccounts()
            // emailAccountList[i] = item
            },"update-pass":_vm.loadEmailAccounts}})],1)],1)}),1):_c('div',{staticClass:"text-center py-1 font-weight-bold text-primary"},[_c('feather-icon',{attrs:{"size":"17","icon":"XCircleIcon"}}),_vm._v(" "+_vm._s(_vm.$t('NoData'))+" ")],1)],1),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.openModal}},[_vm._v(" "+_vm._s(_vm.$t('gEmail.settings.addANewEmail'))+" ")]),_c('b-modal',{attrs:{"title":_vm.$t('Configuration'),"hide-footer":"","tabindex":"none","size":"lg"},model:{value:(_vm.isSetUserEmailModal),callback:function ($$v) {_vm.isSetUserEmailModal=$$v},expression:"isSetUserEmailModal"}},[_c('set-user-email-modal',{attrs:{"user":_vm.user},on:{"update":function (item) {
        _vm.isSetUserEmailModal = false
        _vm.loadEmailAccounts()
      }}}),_c('b-overlay',{attrs:{"show":_vm.isLoading,"no-wrap":"","spinner-variant":"primary"}})],1),_c('b-overlay',{attrs:{"show":_vm.isLoading,"no-wrap":"","spinner-variant":"primary"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }