import * as fields from '@/@constants/fields'

const organizationListBreadcrumb = {
  text: 'Organizations',
  active: false,
  to: '/organizations',
}

export default [
  {
    path: '/organizations',
    component: () => import('@/pages/organizations/Container.vue'),
    name: 'organizations',
    meta: {
      autorize: true,
      pageTitle: 'Organizations',
    },
  },
  {
    path: '/organizations/new',
    component: () => import('@/pages/organizations/New.vue'),
    name: 'organizationsNew',
    meta: {
      autorize: true,
      pageTitle: 'NewOrganization',
      breadcrumb: [
        organizationListBreadcrumb,
        {
          text: 'NewOrganization',
          active: true,
        },
      ],
    },
  },
  {
    path: '/organizations/edit/:id',
    component: () => import('@/pages/organizations/New.vue'),
    name: 'organizationsEdit',
    meta: {
      autorize: true,
      pageTitle: 'OrganizationsEdit',
      breadcrumb: [
        organizationListBreadcrumb,
        {
          active: false,
          request: {
            to: '/organizations/{id}', routeParam: 'id', endpoint: '/organizations/{id}', fields: ['name'], fields_load: fields.ORGANIZATIONS_SEARCH,
          },
        },
      ],
    },
  },
  {
    path: '/organizations/:id/:tab?',
    component: () => import('@/pages/organizations/Edit.vue'),
    name: 'organizationsPreview',
    meta: {
      autorize: true,
      pageTitle: 'OrganizationsEdit',
      breadcrumb: [
        organizationListBreadcrumb,
        {
          active: true,
          request: {
            routeParam: 'id', endpoint: '/organizations/{id}', fields: ['name'], fields_load: fields.ORGANIZATIONS_SEARCH,
          },
        },
      ],
    },
  },
]
