<script>
import DragDropUpload from '@/views/components/DragDropUpload.vue'
import EditAiConnectionData from '@/pages/settings/ai/components/EditAiConnectionData.vue'
import { UPLOAD_FILES } from '@/@constants/mutations'
import axiosIns from '@/libs/axios'

export default {
  components: {
    EditAiConnectionData,
    DragDropUpload,
  },
  props: {
    show: {
      required: true,
      default: false,
    },
  },
  emits: ['save-modal', 'hide-modal'],
  data: () => ({
    loading: false,
    file: [],
  }),
  methods: {
    setFiles(e) {
      this.file.push(...e)
    },
    async hideModal() {
      this.$emit('hide-modal')

      this.file = []
      this.loading = false
    },
    async uploadFile() {
      this.loading = true
      try {
        const payload = { uploadedFiles: this.file, type: 'building' }
        const resp = await this.$store.dispatch(`uploader/${UPLOAD_FILES}`, payload)
        if (resp.files) {
          const fileTokens = resp.files

          const response = await axiosIns.post('1/ai/salesai/files', fileTokens.map(token => ({ file: token })))
          this.$emit('save-modal', response)
          this.showToast('success', this.$i18n.t('success.contactUpdated'))
        }
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        await this.hideModal()
        this.loading = false
      }
    },
  },
}
</script>

<template>
  <b-modal
    :visible="show"
    :title="$t('offer.website.QAndA')"
    hide-footer
    size="lg"
    @hide="hideModal"
  >
    <edit-ai-connection-data
      body-text="HereYouCanUploadDataInTheFormOfQuestionAndAnswerFilesBasedOnWhichAIWillGenerateResponsesDownloadATemplateToPrepareTheFileCorrectly"
      heading-text="offer.website.QAndA"
      icon-size="25"
      icon-header
      show-body
      :show-sample="true"
      :add-header="true"
      :show-add="false"
    />
    <drag-drop-upload accept=".pdf,.txt"
                      :is-refresh="false"
                      @onChangeFiles="setFiles"
                      @onDeleteFiles="file = []"
    />
    <b-button
      class="mt-50"
      size="sm"
      variant="primary"
      :disabled="!file.length || loading"
      @click="uploadFile"
    >
      <feather-icon icon="PlusIcon" />
      {{ $t('Add') }}</b-button>
  </b-modal>
</template>

<style scoped>

</style>
