<template>
  <div class="chats mt-n50">
    <div
      v-for="(msgGrp, index) in messages"
      :key="String(index)"
      class="chat d-flex align-items-end justify-content-end"
      :class="{ 'chat-left': !msgGrp.sentFromCRM }"
    >
      <div class="d-flex flex-column">
        <div class="chat-body mx-50 mr-md-75">
          <p
            v-if="msgGrp.files && msgGrp.files.length"
            class="chat-content d-flex bg-transparent shadow-none mb-0 mr-0 mt-n1 ml-0 pt-2 pb-0 px-0"
            style="background-image: unset"
          >
            <documents-list
              :documents="msgGrp.files"
              show
              :classes="['text-secondary']"
            />
          </p>
          <div
            v-if="msgGrp.message || msgGrp.mentionedClientChatMessage"
            v-b-tooltip
            class="chat-content mb-0 mx-0"
            :title="formatDate(msgGrp.createdAt.date, { month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' }, $i18n.locale)"
          >
            <div v-if="msgGrp.mentionedClientChatMessage"
                 class="chat-content-replay mb-50"
            >
              <div>
                <small>{{ $t('ReplayTo') }}</small>
              </div>
              <small class="text-muted">{{ msgGrp.mentionedClientChatMessage.message | truncate(50) }}</small>
            </div>
            <p v-html="getHtml(msgGrp.message)" />
          </div>
        </div>

        <small
          v-if="msgGrp.createdAt && isToday(msgGrp.createdAt.date)"
          class="mt-25 text-muted"
          :class="{
            'text-right mr-75': (msgGrp.senderUser || msgGrp.senderAi),
            'text-left ml-75': !msgGrp.sentFromCRM
          }"
        >{{ formatDate(msgGrp.createdAt.date, { hour: 'numeric', minute: 'numeric' }, $i18n.locale) }}</small>
        <small
          v-else-if="msgGrp.createdAt"
          class="mt-25 text-muted"
          :class="{
            'text-right mr-75': (msgGrp.senderUser || msgGrp.senderAi),
            'text-left ml-75': !msgGrp.sentFromCRM
          }"
        >{{ formatDate(msgGrp.createdAt.date, { month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' }, $i18n.locale) }}</small>
      </div>

      <div class="chat-avatar">
        <b-avatar
          v-if="msgGrp.senderAi"
          size="24"
          class="box-shadow-1"
        >
          <feather-icon icon="CpuIcon" />
        </b-avatar>
        <b-avatar
          v-else-if="msgGrp.clientChat && msgGrp.clientChat.chatClient && !msgGrp.senderUser"
          size="24"
          class="box-shadow-1"
          :text="`${(msgGrp.clientChat.chatClient.firstName[0] || '') + (msgGrp.clientChat.chatClient.lastName[0] || '')}`.toUpperCase()"
          :src="msgGrp.clientChat.chatClient.avatar"
        />
        <b-avatar
          v-else-if="msgGrp.createdBy"
          size="24"
          class="box-shadow-1"
          :text="`${(msgGrp.createdBy.firstName[0] || '') + (msgGrp.createdBy.lastName[0] || '')}`.toUpperCase()"
        />
        <b-avatar
          v-else
          size="24"
          class="box-shadow-1"
          variant="primary"
        />
      </div>
    </div>

    <div
      v-if="writing"
      class="chat d-flex align-items-end justify-content-end chat-left"
    >
      <div class="chat-body mx-50 mr-md-75">
        <div class="chat-content mb-0 mx-0">
          <div class="typing">
            <div class="typing__dot" />
            <div class="typing__dot" />
            <div class="typing__dot" />
          </div>
        </div>
      </div>

      <div class="chat-avatar">
        <b-avatar
          v-if="getChatClient"
          size="24"
          class="box-shadow-1"
          :text="`${(getChatClient.firstName[0] || '') + (getChatClient.lastName[0] || '')}`.toUpperCase()"
          :src="getChatClient.avatar"
        />
        <b-avatar
          v-else
          size="24"
          class="box-shadow-1"
          variant="primary"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { formatDate } from '@core/utils/filter'
import DocumentsList from '@/views/components/DocumentsList.vue'
import moment from 'moment'

export default {
  name: 'ChatMessages',
  components: {
    DocumentsList,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/getCurrentUser',
    }),
    getChatClient() {
      return this.conversation.chatClient
    },
  },
  props: {
    writing: {
      type: Boolean,
      default: false,
    },
    conversation: {
      type: [Object, null],
    },
    messages: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
      required: true,
    },
  },
  data: () => ({
    lastMessLeft: 0,
    lastMessRight: 0,
  }),
  watch: {
    messages() {
      this.calcLastMess()
    },
  },
  methods: {
    formatDate,
    getLinks(text) {
      const markdownRegex = /\[(.*?)\]\((.*?)\)/g
      // Regex to capture plain URLs, ensuring not to replace URLs inside <a> tags
      const urlRegex = /(?<!href=["'])https?:\/\/[^\s]+/g

      let result = text

      // Replace markdown links [label](url) with <a href="url">label</a>
      result = result.replace(markdownRegex, (match, label, url) => `<a style="color: white !important; text-decoration: underline" target="_blank" href="${url}">${label}</a>`)

      // After replacing markdown links, now replace plain URLs
      // Use a secondary step to make sure we're not replacing URLs within the already created <a> tags
      result = result.replace(urlRegex, url => {
        // Ensure we don't add <a> tag around URLs that are already part of an <a> tag
        if (!url.includes('</a>') && !url.includes('<a href')) {
          return `<a style="color: white !important; text-decoration: underline" target="_blank" href="${url}">${url}</a>`
        }
        return url // If it's already part of an <a>, don't touch it
      })

      return result
    },
    getHtml(message = '') {
      return this.getLinks(message).replace(/\n/g, '<br>')
    },
    isToday(date) {
      return moment(date).isSame(new Date(), 'day')
    },

    lastMess(index, side) {
      if (this.lastMessLeft === 0) this.calcLastMess()

      if (side) return index === this.lastMessRight

      return index === this.lastMessLeft
    },

    calcLastMess() {
      // this.lastMessLeft = this.messages.indexOf(this.messages.findLast(el => el.createdBy.id !== this.currentUser.id))
      // this.lastMessRight = this.messages.indexOf(this.messages.findLast(el => el.createdBy.id === this.currentUser.id))
    },
  },
}
</script>

<style>
.typing {
  position: relative;
  margin-left: 5px;
  border-radius: 20px;
}

.typing__dot {
  float: left;
  width: 8px;
  height: 8px;
  margin: 0 4px;
  background: white;
  border-radius: 50%;
  opacity: 0;
  animation: loadingFade 1s infinite;
}

.typing__dot:nth-child(1) {
  animation-delay: 0s;
}

.typing__dot:nth-child(2) {
  animation-delay: 0.2s;
}

.typing__dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes loadingFade {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.9;
  }
  100% {
    opacity: 0.2;
  }
}
</style>
