<template>
  <div class="mb-1">
    <span
      v-if="title || icon || showTime"
      class="d-flex align-items-center"
    >
      <span class="d-flex row flex-md-row justify-content-start align-items-center w-100 ml-25 mb-1 mt-50">
        <span class="d-flex align-items-center text-nowrap mb-25 mb-md-0">
          <feather-icon
            v-if="icon.length > 0"
            size="18"
            :icon="icon"
            class="mr-25 text-primary"
          />
          <h4 class="ml-25 mb-0 text-primary">{{ title }}</h4>
        </span>

        <span
          v-if="showTime"
          class="mt-25 mt-md-0 ml-md-2"
          style="min-width: 15rem;"
        >
          <flat-pickr
            v-model="date"
            class="form-control"
            :config="{ locale: getCalendarLocale($i18n.locale), mode: 'range' }"
          />
        </span>
      </span>
    </span>

    <b-row>
      <slot />
    </b-row>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    flatPickr,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    icon: {
      type: String,
      required: false,
      default: '',
    },
    showTime: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    /* eslint-disable */
    nowDate: `${('0' + new Date().getDate()).slice(-2)}.${('0' + (new Date().getUTCMonth() + 1)).slice(-2)}.${new Date().getUTCFullYear()}`,
    /* eslint-disable */
    nowTime: `${('0' + new Date().getHours()).slice(-2)}:${('0' + new Date().getMinutes()).slice(-2)}`,
    date: new Date(),
  }),
  computed: {
    locale() {
      return this.$i18n.locale
    },
  },
  created() {
    this.getNow()
    setInterval(() => this.getNow(), 1000)
  },
  methods: {
    getNow() {
      const date = new Date()

      /* eslint-disable */
      this.nowDate = `${('0' + date.getDate()).slice(-2)}.${('0' + (date.getUTCMonth() + 1)).slice(-2)}.${date.getUTCFullYear()}`
      /* eslint-disable */
      this.nowTime = `${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}`
    },
  },
}
</script>
