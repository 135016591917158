<template>
  <b-card class="mb-75">
    <template #header>
      <h5
        class="text-primary mb-0 text-capitalize d-flex flex-row align-items-center"
        style="column-gap: .34rem"
      >
        {{ $t(title) }}

        <feather-icon
          v-if="tip.length"
          v-b-tooltip.hover.v-primary
          :title="$t(tip)"
          icon="InfoIcon"
        />
      </h5>
    </template>

    <div
      class="d-flex flex-column"
      style="row-gap: .5rem"
    >
      <char-item
        v-for="(item, i) in items"
        :key="i"
        :title="item.title"
        :value="item.value"
        :before-value="item.beforeValue"
        :variant="item.variant"
        :count="count && i > 0 ? i : null"
        class="mb-n50"
      />
    </div>
  </b-card>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'
import CharItem from './CharItem.vue'

export default {
  components: { CharItem },

  directives: {
    'b-tooltip': VBTooltip,
  },

  props: {
    title: {
      type: String,
      required: true,
      default: '',
    },
    count: {
      type: Boolean,
      required: false,
      default: false,
    },
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    tip: {
      type: String,
      required: false,
      default: '',
    },
  },
}
</script>
