<template>
  <div>
    <b-button
      variant="warning"
      size="sm"
      class="mb-1 mr-50"
      @click="$router.push({ name: 'organizations' })"
    >
      <feather-icon icon="ArrowLeftIcon" />

      {{ $t('Return') }}
    </b-button>

    <!--    <b-button-->
    <!--      :variant="`${ isPreview ? 'outline-primary' : 'primary' }`"-->
    <!--      size="sm"-->
    <!--      class="mb-1 mr-50"-->
    <!--      @click="isPreview = !isPreview"-->
    <!--    >-->
    <!--      {{ $t(isPreview ? 'Edit' : 'Preview') }}-->
    <!--    </b-button>-->

    <organization
      is-not-cancel
      :back-to="{ name: 'organizations' }"
      :is-modal-view="false"
    />
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import Organization from '@/views/modals/OrganizationModal.vue'

export default {
  components: {
    Organization,
  },
  setup() {
    const id = ref()
    const isPreview = ref(true)

    return {
      id,
      isPreview,
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id
    } else {
      this.isPreview = false
    }
  },
}
</script>
