<template>
  <sw-tabs-search :tabs="filteredTabs" />
</template>

<script>
import router from '@/router'
import ReportRates from './tabs/ReportRates.vue'
import ContactsRates from './tabs/ContactsRates.vue'
import FunnelsAndProcesses from './tabs/FunnelsAndProcesses.vue'
import Applications from './tabs/Applications.vue'
import Notes from './tabs/Notes.vue'

export default {
  data: () => ({
    tabList: [
      // ReportRates
      {
        title: 'ReportRates',
        icon: 'BarChart2Icon',
        component: ReportRates,
        children: [],
        requiredModules: ['applicationModule'],
      },

      // Contacts
      {
        title: 'Contacts',
        icon: 'UsersIcon',
        component: ContactsRates,
        children: [],
      },

      // FunnelsAndProcesses
      {
        title: 'FunnelsAndProcesses',
        icon: 'FilterIcon',
        component: FunnelsAndProcesses,
        children: [
          {
            title: 'FunnelProcesses',
          },
          {
            title: 'SuccessRates',
          },
          {
            title: 'FailureRates',
          },
        ],
      },

      // Applications
      {
        title: 'Applications',
        icon: 'ArchiveIcon',
        component: Applications,
        dsc: 'CloseReasons',
        requiredModules: ['applicationModule'],
      },
      // Notes
      {
        title: 'Notes',
        icon: 'FileIcon',
        component: Notes,
      },
    ],
  }),
  computed: {
    filteredTabs() {
      return (this.tabList || [])
        .filter(tab => !tab?.requiredViews || !tab?.requiredViews?.length || this.checkRequiredViewPermissions(tab.requiredViews || []))
        .filter(tab => !tab?.requiredModules || !tab?.requiredModules?.length || this.checkRequiredModule(tab.requiredModules || []))
        .filter(tab => !tab?.requiredPermissions || !tab?.requiredPermissions?.length || this.checkRequiredPermissions(tab.requiredPermissions || []))
        .filter(tab => !tab?.requiredParams || !tab?.requiredParams?.length || !tab.requiredParams.find(param => !router.currentRoute.params[param]))
    },
  },
  async mounted() {
    // const resp = await axiosIns.get('1/reports/contactApplications/contactApplicationStatusReasons?fields_info=0')
    // const resp1 = await axiosIns.get('1/reports/contactThreads?fields_info=0')
    // const resp2 = await axiosIns.get('1/reports/contacts?fields_info=0')
    // console.log('contactApplications', resp)
    // console.log('contactThreads', resp1)
    // console.log('contacts', resp2)
  },
}
</script>
