<!--eslint-disable-->

<template>
  <div>
    <!--    Section: Filters    -->
    <b-row>
      <!--    Filter by status    -->
      <b-col
        sm="12"
        md="6"
        lg="4"
      >
        <sw-select :name="$t('offer.offerStatus')">
          <v-select
            v-model="offerFilters.status"
            :options="offerStatus"
          >
            <template #option="option">
              {{ $tc(`offer.status.${option.label}`, 1) }}
            </template>
            <template #selected-option="option">
              {{ $tc(`offer.status.${option.label}`, 1) }}
            </template>
          </v-select>
        </sw-select>
      </b-col>
      <!--    Filter by sent date    -->
      <b-col
        sm="12"
        md="6"
        lg="4"
      >
        <sw-select :name="$t('ShipmentData')">
          <sw-select-fpr
            :is-empty="offerFilters.sendTime"
            @clear="offerFilters.sendTime = ''"
          >
            <flat-pickr
              v-model.trim="offerFilters.sendTime"
              class="form-control"
              :config="{ mode: 'range', locale: getCalendarLocale($i18n.locale) }"
            />
          </sw-select-fpr>
        </sw-select>
      </b-col>
    </b-row>

    <!--      Section: Table      -->
    <h4>
      {{ $t('Offers') }}
    </h4>

    <!--      Table      -->
    <table-offer
      :thread-id="threadId || thread.id"
      :filters="offerFilters"
    />
  </div>
</template>

<script>
/* eslint-disable */

import { VBTooltip } from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import offerStatus from '@/helpers/offer/offerStatus'
import tableOffer from '@/views/offers/Table.vue'
import {mapGetters} from 'vuex'

export default {
  components: {
    vSelect,
    flatPickr,
    tableOffer,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    threadId: {
      type: String,
      required: false,
      default: null,
    },
  },
  setup() {
    const offers = [
      {
        lp: 0,
        creationTime: '12:33 | 12.05.2021',
        name: 'Zawartość Mało Tajna',
        status: 'Created',
        content: [
          { id: 0, name: 'Mieszkanie' },
          { id: 1, name: 'Garaż' },
        ],
        shippedToCustomer: '12:33 | 12.05.2021',
        messageStatus: 'Sent',
        nettPrice: 12000,
        validityOfferTerm: '12.05.2021',
        isPDF: true,
        isWWW: false,
        isAgreement: true,
        isElectronicSignature: true,
        documents: [],
        views: 101,
        creator: { id: 0, name: 'Emily Totsu', avatar: '' },
      },
      {
        lp: 1,
        creationTime: '12:33 | 12.05.2021',
        name: 'Różana Republika',
        status: 'Canceled',
        content: [
          { id: 0, name: 'Mieszkanie' },
        ],
        shippedToCustomer: '12:33 | 12.05.2021',
        messageStatus: 'NotPosted',
        nettPrice: 148000,
        validityOfferTerm: '12.05.2021',
        isPDF: true,
        isWWW: false,
        isAgreement: false,
        isElectronicSignature: false,
        documents: [],
        views: 11023,
        creator: { id: 0, name: 'Emily Totsu', avatar: '' },
      },
    ]

    return { offers }
  },
  data: () => ({
    offerFilters: {
      status: '',
      sendTime: '',
    },
    offerStatus: (offerStatus)(),

    offers: [
      {
        lp: 0,
        creationTime: '12:33 | 12.05.2021',
        name: 'Zawartość Mało Tajna',
        status: 'Created',
        content: [
          { id: 0, name: 'Mieszkanie' },
          { id: 1, name: 'Garaż' },
        ],
        shippedToCustomer: '12:33 | 12.05.2021',
        messageStatus: 'Sent',
        nettPrice: 12000,
        validityOfferTerm: '12.05.2021',
        isPDF: true,
        isWWW: false,
        isAgreement: true,
        isElectronicSignature: true,
        documents: [],
        views: 101,
        creator: { id: 0, name: 'Emily Totsu', avatar: '' },
      },
      {
        lp: 1,
        creationTime: '12:33 | 12.05.2021',
        name: 'Różana Republika',
        status: 'Canceled',
        content: [
          { id: 0, name: 'Mieszkanie' },
        ],
        shippedToCustomer: '12:33 | 12.05.2021',
        messageStatus: 'NotPosted',
        nettPrice: 148000,
        validityOfferTerm: '12.05.2021',
        isPDF: true,
        isWWW: false,
        isAgreement: false,
        isElectronicSignature: false,
        documents: [],
        views: 11023,
        creator: { id: 0, name: 'Emily Totsu', avatar: '' },
      },
    ],
  }),
  computed: {
    ...mapGetters({
      thread: 'contact/thread',
    }),
    locale() {
      return this.$i18n.locale
    },
  },
}
</script>

<style>
.doubleLineText {
  width: 9rem !important;

  display: inline-block;

  overflow-wrap: anywhere;

  white-space: normal;
}

.bigTable table { min-height: 16rem }
</style>
