<template>
  <component
    :is="isMobile ? 'div' : 'app-timeline-item'"
    variant="success"
    icon="CheckSquareIcon"
    class="pb-2 d-flex flex-column justify-content-start align-items-start position-relative"
    style="row-gap: .34rem"
  >
    <slot />

    <component
      :is="isContact ? 'div' : 'b-card'"
      class="w-100 rounded-lg mb-0 position-relative"
      no-body
    >
      <b-card-body :class="`p-${ isContact ? '0' : '1' }`">
        <div
          class="d-flex mb-50"
          style="column-gap: .5rem"
        >
          <b-avatar
            v-if="isMobile"
            rounded="sm"
            size="sm"
            variant="light-success"
          >
            <span class="d-flex align-items-center">
              <feather-icon
                icon="ListIcon"
                size="14"
              />
            </span>
          </b-avatar>

          <router-link
            v-if="thread && !isContact"
            class="font-small-3"
            :class="{ 'deleted-text': thread.contact.deletedAt }"
            :to="`/contact/${thread.contact.id}/thread/${thread.id}/timeline`"
          >
            {{ thread.contact.firstName }} {{ thread.contact.lastName }} – {{ thread.name }}
          </router-link>

          <p
            v-else
            class="mb-0"
          >
            {{ $t('CurrentContact') }}
          </p>
        </div>

        <div class="w-100 font-weight-bold">
          <div
            v-if="thread"
            class="w-100 d-flex flex-row flex-wrap justify-content-between"
          >
            <div
              class="d-flex flex-wrap flex-row align-items-center"
              style="column-gap: .5rem; row-gap: .5rem"
            >
              <b-badge
                v-if="automation && automation.automationProcedure"
                class="mr-25"
                variant="primary"
              >
                <feather-icon icon="CpuIcon" />
                {{ $t('Automation') }}: {{ automation.automationProcedure.name }}
              </b-badge>
              <b-badge variant="light-primary">
                <feather-icon icon="CheckSquareIcon" />
                {{ data.contactThreadChecklistPointDatum.contactThreadChecklistTypePoint.contactThreadChecklistType.name }}
              </b-badge>

              <div
                class="d-flex flex-row align-items-center"
                style="column-gap: .5rem; row-gap: .5rem"
              >
                <small class="text-lowercase">{{ $t('FromTheDay') }}</small>
                <span class="font-italic">{{ formatDate(timelineDate, { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' }, $i18n.locale) }}</span>
              </div>
            </div>
          </div>
        </div>

        <hr class="border-light-primary">

        <div
          class="d-flex flex-wrap align-items-center px-md-50"
          style="row-gap: .34rem; column-gap: .64rem;"
        >
          <b-form-checkbox
            variant="primary"
            disabled
            :checked="data.checked"
            :class="{ 'custom-control-secondary': isPointDeleted }"
            class="d-flex flex-column align-items-center"
          >
            <span
              class="d-flex flex-column flex-md-row align-items-md-center font-small-2"
              :style="{ textDecoration: isPointDeleted ? 'line-through' : '' }"
              style="color: #5e5873 !important"
            >
              <span style="color: #5e5873">
                {{ data.contactThreadChecklistPointDatum.contactThreadChecklistTypePoint.name }}
              </span>

              <span
                v-if="data.checked && data.doneAt && data.doneAt.date"
                style="color: #5e5873"
              >
                -
                {{ formatDate(data.doneAt.date.split('.')[0], { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' }, $i18n.locale) }}
              </span>
            </span>
          </b-form-checkbox>

          <avatar
            :user="data.updatedBy"
            size="sm"
            show-tooltip
            :show-name="false"
          />
        </div>
      </b-card-body>
    </component>

    <hr
      v-if="isContact"
      class="w-100 mb-0"
    >
  </component>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { formatDate } from '@core/utils/filter'
import useVerticalLayout from '@core/layouts/layout-vertical/useVerticalLayout'
import { onUnmounted } from '@vue/composition-api'

export default {
  name: 'TimelineMail',
  components: {
    AppTimelineItem,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: ['data', 'timelineDate', 'threadId', 'thread', 'isContact', 'automation'],
  setup() {
    const { isMobile, resizeHandler } = useVerticalLayout()

    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    return {
      isMobile,
    }
  },
  computed: {
    isPointDeleted() {
      const point = this.data.contactThreadChecklistPointDatum.contactThreadChecklistTypePoint
      return !!(point.deletedAt && point.deletedAt.date)
    },
  },
  methods: {
    formatDate,
  },
}
</script>

<style scoped>

</style>
