export const attributeTypes = () => {
  const typeList = [
    'DIGITAL',
    'PERCENTAGE',
    'TEXT',
    'DATE',
    'TIME',
    'TEXT_ARRAY',
    'TEXT_MULTIPLE_ARRAY',
  ]

  return typeList
}

export const pollTypes = () => {
  const typeList = [
    'DIGITAL',
    'PERCENTAGE',
    'TEXT',
    'DATE',
    'TIME',
    'CHECKBOX',
    'RADIAL',
    'TEXT_ARRAY',
    'TEXT_MULTIPLE_ARRAY',
  ]

  return typeList
}

export const contactInfoTypes = () => {
  const typeList = [
    'DIGITAL',
    'PERCENTAGE',
    'TEXT',
    'DATE',
    'TIME',
    'CHECKBOX',
    'EMAIL',
    'RADIAL',
    'TEXT_ARRAY',
    'TEXT_MULTIPLE_ARRAY',
    'TOOLTIP',
  ]

  return typeList
}
