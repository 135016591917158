<template>
  <div>
    <div
      v-if="!loading"
      class="pt-1"
    >
      <div>
        <!--   Add new checklist   -->
        <b-form class="pb-1 w-100 d-flex flex-column flex-sm-row mb-1">
          <b-form-input
            v-model="newChecklistName"
            style="max-width: 200px; min-width: 200px"
            size="sm"
            required
            :placeholder="$t('ChecklistName')"
          />

          <b-button
            style="min-width: 125px; max-width: 150px"
            class="ml-sm-1 mt-50 mt-sm-0"
            variant="success"
            size="sm"
            :disabled="newChecklistName === ''"
            @click="addChecklist"
          >
            {{ $t('Add') }}
          </b-button>
        </b-form>

        <!--   Add new checklist   -->
        <app-collapse
          accordion
          type="margin"
        >
          <draggable
            v-model="checklists"
            tag="app-collapse"
            group="people"
            style="min-height: 3rem;"
            accordion
            type="margin"
          >
            <app-collapse-item
              v-for="(checklist, index) in checklists"
              :key="checklist.id + '_' + index"
              show-actions
              :show-copy="true"
              :show-edit="false"
              @delete="removeChecklist(index, checklist.id)"
              @copy="copyCheckList(checklist)"
            >
              <template #title>
                <feather-icon
                  icon="MoveIcon"
                  class="text-primary mr-25"
                  size="18"
                />
                {{ checklist.name }}
              </template>
              <!-- funnel settings -->
              <div class="d-flex align-items-end flex-wrap">
                <b-form-group class="w-100">
                  <div
                    class="d-flex flex-row flex-wrap align-items-center"
                    style="column-gap: .75rem; row-gap: .34rem;"
                  >
                    <label
                      class="h6 mb-0"
                      style="width: max-content"
                    >
                      {{ $t('ChecklistName') }}
                    </label>

                    <b-form-input
                      v-model="checklist.name"
                      size="sm"
                      type="text"
                      style="max-width: 200px"
                      @blur="onChangeChecklistName"
                    />
                  </div>
                </b-form-group>
              <!--              <b-button-->
              <!--                variant="danger"-->
              <!--                size="sm"-->
              <!--                class="mb-1 ml-sm-1"-->
              <!--                @click="removeChecklist(index, checklist.id)"-->
              <!--              >-->
              <!--                <feather-icon icon="TrashIcon" />-->
              <!--                {{ $t('Delete') }}-->
              <!--              </b-button>-->
              </div>

              <!-- funnel settings -->
              <draggable
                v-model="checklist.contactThreadChecklistTypePoints"
                tag="div"
                class="cursor-pointer"
              >
                <b-row
                  v-for="(checklistField, checklistFieldIndex) in checklist.contactThreadChecklistTypePoints"
                  :id="`${checklistField.id}_${checklistFieldIndex}`"
                  :key="`${checklistField.id}_${checklistFieldIndex}`"
                  ref="row"
                >
                  <!-- Item Name -->
                  <b-col
                    sm="12"
                    class="d-flex flex-wrap flex-column flex-lg-row justify-content-between"
                    style="column-gap: .5rem; row-gap: .5rem"
                  >
                    <div
                      class="d-flex flex-column flex-lg-row"
                      style="column-gap: .5rem"
                    >
                      <div
                        class="h5 d-flex align-items-center mb-lg-0"
                        style="column-gap: .5rem"
                      >
                        <feather-icon
                          icon="MoveIcon"
                          class="text-primary mr-25"
                          size="18"
                        />

                        {{ checklistField.name }}
                      </div>

                      <b-button-group
                        class="d-flex flex-column align-items-start flex-lg-row align-items-lg-center"
                        size="sm"
                      >
                        <b-button
                          v-b-tooltip.hover.v-primary
                          variant="flat-primary"
                          class="btn-icon p-25 d-flex align-items-center"
                          style="column-gap: .5rem"
                          :title="checklistField.description"
                          :disabled="!checklistField.description"
                        >
                          <feather-icon icon="InfoIcon" />

                          <div class="d-lg-none">
                            <p
                              v-if="checklistField.description"
                              class="d-flex justify-content-start mb-0"
                            >
                              {{ checklistField.description | textIsland(34) }}
                            </p>

                            <feather-icon
                              v-else
                              icon="MinusIcon"
                            />
                          </div>
                        </b-button>

                        <b-button
                          v-b-tooltip.hover.v-primary
                          variant="flat-primary"
                          class="btn-icon p-25 d-flex align-items-center"
                          style="column-gap: .5rem"
                          :title="checklistField.url"
                          :disabled="!checklistField.url"
                        >
                          <feather-icon icon="YoutubeIcon" />

                          <div class="d-lg-none">
                            <p
                              v-if="checklistField.url"
                              class="d-flex justify-content-start mb-0"
                            >
                              {{ checklistField.url | textIsland(24) }}
                            </p>

                            <feather-icon
                              v-else
                              icon="MinusIcon"
                            />
                          </div>
                        </b-button>
                      </b-button-group>
                    </div>

                    <div
                      class="d-flex"
                      style="column-gap: .5rem"
                    >
                      <b-button
                        size="sm"
                        variant="outline-warning"
                        @click="editField(index, checklistField, checklistFieldIndex, checklist.id)"
                      >
                        <feather-icon
                          icon="EditIcon"
                          class="mr-25"
                        />

                        <span>{{ $t('Edit') }}</span>
                      </b-button>

                      <b-button
                        :ref="`item_${checklistFieldIndex}_${checklistField.id}`"
                        size="sm"
                        variant="outline-danger"
                        @click="removeItem(checklistField.id, checklistFieldIndex, index)"
                      >
                        <span>
                          <feather-icon
                            icon="XIcon"
                            class="mr-25"
                          />

                          <span>{{ removedFields.includes(checklistField.id ? checklistField.id.toString() : false) ? $t('Cancel') : $t('Delete') }}</span>
                        </span>
                      </b-button>
                    </div>
                  </b-col>

                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </draggable>

              <b-button
                variant="primary"
                size="sm"
                @click="addField(index, checklist.id)"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>{{ $t('Add') }}</span>
              </b-button>
            </app-collapse-item>
          </draggable>
        </app-collapse>
      </div>
      <!--    <b-button-->
      <!--      variant="success"-->
      <!--      class="mr-1 mt-1"-->
      <!--      size="sm"-->
      <!--      @click="onSaveForm"-->
      <!--    >-->
      <!--      <feather-icon-->
      <!--        icon="PlusIcon"-->
      <!--        class="mr-25"-->
      <!--      />-->
      <!--      <span>Zapisz</span>-->
      <!--    </b-button>-->
      <!-- edited modal -->
      <b-modal
        v-model="fieldChanges.isFieldEdited"
        :title="$t('Checklist')"
        hide-footer
        no-close-on-backdrop
      >
        <!-- Edited name  -->
        <b-form-group
          :label="$t('Name')"
          label-for="status_name"
        >
          <b-form-input
            id="status_name"
            v-model="fieldChanges.editedField.name"
            type="text"
          />
        </b-form-group>

        <!-- Edited Tooltip  -->
        <b-form-group
          :label="$t('Tooltip')"
          label-for="status_name"
        >
          <b-form-input
            id="status_name"
            v-model="fieldChanges.editedField.description"
            type="text"
          />
        </b-form-group>

        <!-- Edited LinkYT  -->
        <b-form-group
          :label="$t('LinkYT')"
          label-for="status_name"
        >
          <b-form-input
            id="status_name"
            v-model="fieldChanges.editedField.url"
            type="text"
          />
        </b-form-group>

        <b-button
          variant="success"
          class="mt-50 float-right"
          size="sm"
          :disabled="!fieldChanges.editedField.name"
          @click="saveField"
        >
          {{ $t('Save') }}
        </b-button>
      </b-modal>
      <!-- edited modal -->
      <b-button
        variant="primary"
        class="mr-1 mt-1"
        size="sm"
        @click="onSaveForm"
      >
        <span>{{ $t('Save') }}</span>
      </b-button>
    </div>
    <div
      v-else
      class="d-flex justify-content-center"
    >
      <b-spinner
        variant="primary"
        class="m-5"
      />
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BButtonGroup,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BModal,
  BRow,
  BSpinner,
  VBTooltip,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import draggable from 'vuedraggable'
import axiosIns from '@/libs/axios'

export default {
  name: 'Checklist',
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BModal,
    AppCollapse,
    AppCollapseItem,
    draggable,
    BButtonGroup,
    BSpinner,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data: () => ({
    loading: false,
    removedFields: [],
    //
    newChecklistName: '',
    checklists: [],
    //
    fieldChanges: {
      isFieldEdited: false,
      checklistIndex: null,
      checklistId: null,
      editedFieldIndex: null,
      editedField: {
        id: null, name: '', contactThreadChecklistType: null, description: '', url: '',
      },
    },
    areChangesSaved: true,
    checklistT: null,
    ranks: [],
  }),
  async mounted() {
    this.loading = true
    this.$store.dispatch('ranks/GET_RANKS_LIST')
      .then(() => {
        this.ranks = ([{ name: this.$i18n.t('Contact'), id: 2 }, ...this.$store.getters['ranks/getRanksList']])
      })
    this.initData()
  },
  methods: {
    initData() {
      axiosIns.get('1/settings/contactThreadChecklistTypes', { params: { fields_load: this.$fields.SETTINGS_CHECKLIST_TYPES, orderBy: 'position.asc' } })
        .then(res => {
          this.checklists = res.data.data.items
        })
        .finally(() => {
          this.loading = false
        })
        .finally(() => {
          this.loading = false
        })
    },
    onChangeChecklistName() {
      this.areChangesSaved = false
    },
    async copyCheckList(checklist) {
      const checklistPayload = {
        name: `${this.$i18n.t('Copy1')} - ${checklist.name}`,
        position: this.checklists.length + 1,
      }

      try {
        const response = await axiosIns.post('1/settings/contactThreadChecklistTypes', checklistPayload)

        if (response.data?.data?.items) {
          const contactThreadChecklistTypeId = response.data.data.items[0]?.id
          const positionsPayload = checklist.contactThreadChecklistTypePoints.map(e => {
            const point = { ...e }

            delete point.id
            point.contactThreadChecklistType = contactThreadChecklistTypeId

            return point
          })

          await axiosIns.put('1/settings/contactThreadChecklistTypePoints', positionsPayload)

          this.showToast('success', this.$i18n.t('success.contactUpdated'))
          await this.initData()
        }
        // contactThreadChecklistType
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    removeChecklist(checklistIndex, checkListId) {
      this.showAlert('error', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('alerts.WontbeAble'))
        .then(() => {
          axiosIns.delete('1/settings/contactThreadChecklistTypes', { data: [checkListId] })
            .then(() => {
              this.checklists.splice(checklistIndex, 1)
            })
            .catch(err => {
              this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            })
        })
    },
    addChecklist() {
      // Add checklist request
      const checklistName = this.newChecklistName
      axiosIns.post('1/settings/contactThreadChecklistTypes', { name: checklistName })
        .then(res => {
          // res holds checklist id
          this.checklists.push({
            name: checklistName, id: res.data.data.items[0].id, contactThreadChecklistTypePoints: [],
          })
          this.showToast('success', this.$i18n.t('ChecklistAdded'))
        })
        .catch(() => {
          this.showToast('danger', this.$i18n.t('ProblemOccured'))
        })
        .finally(() => {
          this.newChecklistName = ''
        })
    },
    removeItem(fieldId, index, checklistIndex) {
      if (fieldId) {
        if (this.removedFields.includes(fieldId.toString())) this.removedFields.splice(this.removedFields.indexOf(fieldId.toString()), 1)
        else this.removedFields.push(fieldId.toString())
      } else this.checklists[checklistIndex].contactThreadChecklistTypePoints.splice(index, 1)
      this.areChangesSaved = false
    },
    editField(checklistIndex, field, index, checklistId) {
      this.fieldChanges.isFieldEdited = true
      this.fieldChanges.editedField = { ...field, isNew: !field.id }
      this.fieldChanges.editedFieldIndex = index
      this.fieldChanges.checklistIndex = checklistIndex
      this.fieldChanges.checklistId = checklistId
    },
    addField(checklistIndex, checklistId) {
      this.resetField()
      this.fieldChanges.isFieldEdited = true
      this.fieldChanges.checklistIndex = checklistIndex
      this.fieldChanges.checklistId = checklistId
    },
    saveField() {
      const editedField = this.fieldChanges
      if (editedField.checklistId) {
        const {
          id, name, contactThreadChecklistType, isNew, description, url,
        } = editedField.editedField

        const item = {
          id, name, contactThreadChecklistType, description, url,
        }

        item.contactThreadChecklistType = editedField.checklistId.toString()
        item.position = 0

        if (!item.id) delete item.id
        else item.id = item.id.toString()

        if (item.id || isNew) {
          this.checklists[editedField.checklistIndex].contactThreadChecklistTypePoints[editedField.editedFieldIndex] = item
        } else {
          this.checklists[editedField.checklistIndex].contactThreadChecklistTypePoints.push(item)
        }

        this.resetField()
        this.areChangesSaved = false
      } else {
        this.resetField()
        this.areChangesSaved = false
        this.showToast('danger', this.$i18n.t('ProblemOccured'))
      }
    },
    onSaveForm() {
      this.loading = true
      const checklists = this.checklists.map((checklist, index) => ({
        id: checklist.id, name: checklist.name, description: checklist.description, url: this.convertYtLinkToEmbed(checklist.url || ''), position: index + 1,
      }))

      let points = []
      this.checklists.forEach(checklist => checklist.contactThreadChecklistTypePoints.forEach((point, index) => {
        const {
          id, name, contactThreadChecklistType, description, url,
        } = point

        const preparedPoint = {
          name,
          contactThreadChecklistType,
          description,
          url: this.convertYtLinkToEmbed(url),
        }

        if (id) {
          preparedPoint.id = id.toString()
        }

        preparedPoint.position = index

        points.push(preparedPoint)
      }))

      points = points.filter(point => !this.removedFields.includes(point.id))
      axiosIns.put('1/settings/contactThreadChecklistTypes', checklists)
        .then(() => {
          if (this.removedFields.length) {
            axiosIns.delete('1/settings/contactThreadChecklistTypePoints', { data: this.removedFields })
              .then(() => {
                this.save(points)
              })
              .catch(err => {
                this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
                this.removedFields = []
              })
              .finally(() => {
                this.loading = false
              })
          } else this.save(points)
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          this.removedFields = []
        })
        .finally(() => {
          this.loading = false
        })
    },
    save(points) {
      if (points.length) {
        axiosIns.put('1/settings/contactThreadChecklistTypePoints', points)
          .then(() => {
            axiosIns.get('1/settings/contactThreadChecklistTypes', { params: { fields_load: this.$fields.SETTINGS_CHECKLIST_TYPES, orderBy: 'position.asc' } })
              .then(items => {
                this.checklists = items.data.data.items
                this.showToast('success', this.$i18n.t('success.contactUpdated'))
                this.areChangesSaved = true
              })
              .catch(err => {
                this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
              })
              .finally(() => {
                this.loading = false
                this.removedFields = []
              })
          })
          .catch(err => {
            this.removedFields = []
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        this.showToast('success', this.$i18n.t('success.contactUpdated'))
        this.loading = false
      }
    },
    resetField() {
      this.fieldChanges.isFieldEdited = false
      this.fieldChanges.editedField = {
        id: null, name: '', contactThreadChecklistType: null, description: '', url: '',
      }
      this.fieldChanges.editedFieldIndex = null
      this.fieldChanges.checklistId = null
      this.fieldChanges.checklistIndex = null
    },
  },
}
</script>
