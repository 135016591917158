<template>
  <b-row>
    <b-col>
      <transition
        name="slide-fade"
        mode="out-in"
      >
        <polls-list
          v-if="!selectedPoll"
          key="polls"
        />
        <poll-details
          v-else
          key="details"
        />
      </transition>
    </b-col>
  </b-row>
</template>

<script>
import PollsList from '@/views/core/polls/PollsList.vue'
import PollDetails from '@/views/core/polls/PollDetails.vue'

export default {
  name: 'Polls',
  components: { PollDetails, PollsList },
  computed: {
    selectedPoll() {
      return this.$route.params?.pollId
    },
  },
}
</script>

<style scoped>

</style>
