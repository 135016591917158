<template>
  <b-alert
    show
    variant="primary"
  >
    <div class="alert-heading d-flex justify-content-between align-items-center">
      <div>
        <feather-icon
          v-if="iconHeader"
          :icon="icon"
          class="mr-25"
          :size="iconSize"
        />
        {{ $t(headingText) }}
      </div>
      <div>
        <b-button
          v-if="showSample"
          size="sm"
          variant="primary"
          class="mr-25"
          download
          :href="require('@/assets/pdf/Przykładowa_struktura_bazy_wiedzy_dla_Asystenta_AI.pdf')"
        >
          <feather-icon icon="DownloadIcon" />
          {{ $t('DownloadSampleData') }}
        </b-button>
        <b-button
          v-if="addHeader && showAdd"
          size="sm"
          variant="primary"
          @click="$emit('add')"
        >
          <feather-icon icon="PlusIcon" />
          {{ $t('Add') }}
        </b-button>
      </div>
    </div>
    <div
      v-if="showBody"
      class="alert-body"
    >
      <div class="d-flex align-items-center">
        <feather-icon
          v-if="!iconHeader"
          :icon="icon"
          class="mr-1"
          :size="iconSize"
        />
        <div>
          {{ $t(bodyText) }}
          <div
            v-if="!addHeader"
            class="mt-50 justify-content-end"
            :class="{ 'd-flex justify-items-end': showBody }"
          >
            <b-button
              v-if="showSample"
              size="sm"
              variant="primary"
              class="mr-25"
              download
              :href="require('@/assets/pdf/Przykładowa_struktura_bazy_wiedzy_dla_Asystenta_AI.pdf')"
            >
              <feather-icon icon="DownloadIcon" />
              {{ $t('DownloadSampleData') }}
            </b-button>
            <b-button
              v-if="showAdd"
              size="sm"
              variant="primary"
              @click="$emit('add')"
            >
              <feather-icon icon="PlusCircleIcon" />
              {{ $t('Add') }}
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </b-alert>
</template>

<script>
export default {
  name: 'EditAiConnectionData',
  props: {
    headingText: {
      type: String,
      default: '',
    },
    bodyText: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: 'AlertCircleIcon',
    },
    iconHeader: {
      type: Boolean,
      default: false,
    },
    addHeader: {
      type: Boolean,
      default: false,
    },
    iconSize: {
      type: [String, Number],
      default: '40',
    },
    showSample: {
      type: Boolean,
      default: false,
    },
    showBody: {
      type: Boolean,
      default: false,
    },
    showAdd: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['add', 'sample'],
}
</script>

<style scoped>

</style>
