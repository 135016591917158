var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"position":"relative"},attrs:{"id":("input_" + (_vm.contactAddonFieldType.id))}},[(['DIGITAL', 'PERCENTAGE'].includes(_vm.contactAddonFieldType.type))?_c('validation-provider',{attrs:{"name":_vm.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"value":_vm.value,"placeholder":_vm.$t('Value'),"disabled":!_vm.contactAddonFieldType || _vm.disabled,"state":errors.length > 0 ? false:null,"type":"number"},on:{"input":function($event){return _vm.updateValue('set', 'value',$event)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,1937279942)}):_vm._e(),(['DATE'].includes(_vm.contactAddonFieldType.type))?_c('div',[_c('b-form-group',{staticStyle:{"position":"relative"}},[_c('validation-provider',{staticStyle:{"position":"relative"},attrs:{"name":_vm.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"mb-0",staticStyle:{"position":"relative"}},[_c('cleave',{staticClass:"form-control rounded",attrs:{"value":_vm.value,"options":_vm.cleaveConfig,"placeholder":_vm.$t('TypeDate')},on:{"input":function (e) { _vm.updateValue('set', 'value', e) },"blur":function($event){return _vm.checkDate(_vm.value)},"on-blur":function($event){return _vm.checkDate(_vm.value)}}}),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":_vm.openPicker}},[_c('sw-icon',{attrs:{"icon":"ClockIcon"}})],1)],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1524547988)})],1),_c('sw-select-fpr',{staticStyle:{"display":"none","position":"relative"},on:{"clear":function($event){return _vm.updateValue('set', 'value', '')}}},[_c('flat-pickr',{ref:("input_flatpicker_date_" + (_vm.contactAddonFieldType.id)),staticClass:"form-control",staticStyle:{"visibility":"hidden","width":"0","padding":"0","margin":"0","height":"0"},attrs:{"value":_vm.value && _vm.value.includes('-') ? _vm.value : _vm.getFormatValue(_vm.value),"disabled":_vm.disabled,"config":Object.assign({}, {locale: _vm.getCalendarLocale(_vm.$i18n.locale)}, _vm.timeConfig('DATE')),"placeholder":_vm.$t('SelectDate')},on:{"on-close":function($event){return _vm.updateValue('set', 'value', $event, 'DATE')}}})],1)],1):_vm._e(),(['TIME'].includes(_vm.contactAddonFieldType.type))?_c('validation-provider',{attrs:{"name":_vm.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('sw-select-fpr',{on:{"clear":function($event){return _vm.updateValue('set', 'value', '')}}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"value":_vm.value,"disabled":_vm.disabled,"config":Object.assign({}, {locale: _vm.getCalendarLocale(_vm.$i18n.locale)}, _vm.timeConfig('TIME')),"placeholder":_vm.$t('SelectTime')},on:{"on-close":function($event){return _vm.updateValue('set', 'value', $event, 'TIME')}}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,4104662090)}):_vm._e(),(['CHECKBOX'].includes(_vm.contactAddonFieldType.type))?_c('validation-provider',{attrs:{"name":_vm.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-checkbox',{attrs:{"checked":_vm.value,"variant":"primary","disabled":!_vm.contactAddonFieldType || _vm.disabled,"placeholder":_vm.$t('Value')},on:{"change":function($event){return _vm.updateValue('set', 'value', $event)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3202636298)}):_vm._e(),(['EMAIL'].includes(_vm.contactAddonFieldType.type))?_c('validation-provider',{attrs:{"rules":"email","name":_vm.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"value":_vm.value,"placeholder":_vm.$t('Value'),"disabled":!_vm.contactAddonFieldType || _vm.disabled,"state":errors.length > 0 ? false:null},on:{"input":function($event){return _vm.updateValue('set', 'value',$event)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,1191492875)}):_vm._e(),(['TEXT_MULTIPLE_ARRAY'].includes(_vm.contactAddonFieldType.type))?_c('validation-provider',{attrs:{"name":_vm.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mb-0"},[_c('sw-select',[_c('v-select',{attrs:{"value":_vm.contactAddonFieldTypeOptions,"placeholder":_vm.$t('Select'),"options":_vm.contactAddonFieldType.pollFieldOptions,"filterable":false,"label":"name","item-value":"id","disabled":_vm.disabled,"item-text":"name","state":errors.length > 0 ? false:null,"reduce":function (e) { return e.id; },"multiple":""},on:{"option:selected":function($event){return _vm.updateValue('set', 'contactAddonFieldTypeOptions',$event)},"option:deselected":function($event){_vm.updateValue('set', 'contactAddonFieldTypeOptions', (function () {
            var a = [].concat( (_vm.contactAddonFieldTypeOptions || []) );

            // Find the index of the item to be removed
            var index = a.findIndex(function (e) { return e.id === $event.id; });

            // Remove the item if it exists in the array
            if (index > -1) {
              a.splice(index, 1);
            }

            return a
          })())}},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('NoOptions'))+" ")]},proxy:true},{key:"option",fn:function(item){return [_vm._v(" "+_vm._s(item.translations[0].name)+" ")]}},{key:"selected-option",fn:function(item){return [_vm._v(" "+_vm._s(item.translations[0].name)+" ")]}}],null,true)})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,2152706255)}):_vm._e(),(['TEXT_ARRAY'].includes(_vm.contactAddonFieldType.type))?_c('validation-provider',{attrs:{"name":_vm.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mb-0"},[_c('sw-select',[_c('v-select',{attrs:{"value":_vm.contactAddonFieldTypeOptions,"placeholder":_vm.$t('Select'),"options":_vm.contactAddonFieldType.pollFieldOptions,"filterable":false,"label":"name","disabled":_vm.disabled,"item-value":"id","item-text":"name","state":errors.length > 0 ? false:null},on:{"option:selected":function($event){return _vm.updateValue('set', 'contactAddonFieldTypeOptions',$event)},"option:deselected":function($event){return _vm.updateValue('set', 'contactAddonFieldTypeOptions','')}},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('NoOptions'))+" ")]},proxy:true},{key:"option",fn:function(item){return [_vm._v(" "+_vm._s(item.translations[0].name)+" ")]}},{key:"selected-option",fn:function(item){return [_vm._v(" "+_vm._s(item.translations[0].name)+" ")]}}],null,true)})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,1010458431)}):_vm._e(),(['RADIAL'].includes(_vm.contactAddonFieldType.type))?_c('validation-provider',{attrs:{"name":_vm.name}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-radio-group',{on:{"change":function($event){return _vm.updateValue('set', 'value',$event)}}},_vm._l((_vm.contactAddonFieldType.pollFieldOptions),function(option,i){return _c('b-form-radio',{key:i,attrs:{"disabled":_vm.disabled,"aria-describedby":ariaDescribedby,"value":option}},[(option.translations)?_c('span',[_vm._v(" "+_vm._s(option.translations[0].name)+" ")]):_vm._e()])}),1)]}}],null,false,12743730)})],1):_vm._e(),(!_vm.contactAddonFieldType || ['TEXT'].includes(_vm.contactAddonFieldType.type))?_c('validation-provider',{attrs:{"name":_vm.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"value":_vm.value,"disabled":!_vm.contactAddonFieldType || _vm.disabled,"state":errors.length > 0 ? false:null,"placeholder":_vm.$t('Value')},on:{"input":function($event){return _vm.updateValue('set', 'value', $event)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,693027499)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }