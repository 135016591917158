<template>
  <div>
    <sw-filters
      v-if="!hideFilters"
      :shorts="[
        {
          title: $t('CreatedBy'),
          type: 'avatar',
          value: () => filters.authors,
          clear: () => { filters.authors = [] }
        },
        {
          title: $t('CreatedTime'),
          value: () => filters.createdAt,
          clear: () => { filters.createdAt = '' }
        },
        {
          variant: 'primary',
          value: () => filters.showSketchesOnly ? $t('ShowSketchesOnly') : null,
          clear: () => { filters.showSketchesOnly = false }
        },
      ]"

      :res="[
        {
          title: $t('All'),
          value: () => posts.length,
        }
      ]"
    >
      <b-row style="row-gap: 1rem">
        <b-col
          sm="12"
          md="6"
          lg="8"
        >
          <sw-select :name="$t('CreatedBy')">
            <v-select
              v-model="filters.authors"
              :options="userList"
              label="firstName"
              :filterable="false"
              multiple
              :placeholder="$t('CreatedBy')"
              @search="loadUsers"
            >
              <template #no-options="{ search }">
                <span v-if="search.length">
                  {{ $t('NoData') }}
                </span>
                <span v-else>
                  {{ $t('TypeToSearch') }}
                </span>
              </template>

              <template #option="{ firstName, lastName, avatar }">
                <div class="d-flex align-items-center">
                  <avatar
                    :user="{ firstName, lastName, avatar }"
                    class="mr-25"
                  />
                </div>
              </template>

              <template #selected-option="{ firstName, lastName, avatar }">
                <div class="d-flex">
                  <avatar
                    :user="{ firstName, lastName, avatar }"
                    class="mr-25"
                    text-variant="text-white"
                  />
                </div>
              </template>
            </v-select>
          </sw-select>
        </b-col>

        <b-col
          sm="12"
          md="6"
          lg="4"
        >
          <sw-select :name="$t('CreatedTime')">
            <sw-select-fpr
              :is-empty="filters.createdAt"
              @clear="filters.createdAt = ''"
            >
              <flat-pickr
                v-model="filters.createdAt"
                class="form-control"
                :placeholder="$t('CreatedTime')"
                :config="{ ...flatPickerConfig, locale: getCalendarLocale($i18n.locale) }"
              />
            </sw-select-fpr>
          </sw-select>
        </b-col>

        <b-col
          v-if="checkRequiredPermissions([$roles.NEWS_POST_MODIFY])"
          sm="12"
          md="6"
          lg="4"
          class="d-flex align-items-center"
        >
          <b-form-checkbox
            v-model="filters.showSketchesOnly"
            class="custom-control-primary"
            variant="primary"
          >
            {{ $t('ShowSketchesOnly') }}
          </b-form-checkbox>
        </b-col>
      </b-row>
    </sw-filters>

    <div class="my-50">
      <b-button
        v-if="checkRequiredPermissions([$roles.NEWS_POST_MODIFY])"
        variant="primary"
        size="sm"
        :to="{ name: 'newsNew' }"
      >
        <feather-icon icon="PlusIcon" />

        {{ $t('Add') }}
      </b-button>
    </div>

    <sw-table
      table-id="e70a6ff45145417dae980123d1a04438"
      show-search
      :pagination="pagination"
      @change-search-query="searchQuery = $event"
      @change-pagination="Object.assign(pagination, $event)"
      @reload-content="loadPosts"
    >
      <template #table>
        <b-row v-if="posts.length">
          <b-col
            v-for="(post, index) in posts"
            :key="index"
            md="6"
            lg="4"
          >
            <b-card
              class="p-relative overflow-hidden"
              no-body
            >
              <router-link
                :to="{ name: 'newsPost', params: { id: post.id } }"
                class="p-relative d-flex flex-column justify-content-between"
              >
                <div
                  class="p-relative overflow-hidden d-flex justify-content-center align-items-center"
                  style="height: 240px"
                  :style="!post.picture || !post.picture.preview || isLoading ? 'background: rgba(var(--theme-color-primary), .14);' : ''"
                >
                  <img
                    :src="post.picture ? `data:image/webp;base64,${post.picture.preview ? post.picture.preview : post.picture}` : system.logo || system.signet"
                    class="img-fluid"
                    :style="
                      post.picture ? `
                    width: 100%;
                    height: 100%;

                    object-fit: cover;
                  ` : ''
                    "
                  >
                </div>

                <b-card-body
                  class="d-flex flex-column justify-content-between"
                  style="row-gap: 1rem"
                >
                  <div
                    class="d-flex align-items-start"
                    style="row-gap: .34rem; column-gap: .5rem;"
                  >
                    <div
                      class="text-dark d-flex flex-column"
                      style="flex: 1; row-gap: .34rem"
                    >
                      <span class="h4 mb-0">
                        {{ post.subject }}
                      </span>

                      <span v-if="post.introduction">
                        {{ post.introduction | truncate(80) }}
                      </span>
                    </div>

                    <span
                      v-if="post.status === 'SKETCH'"
                      class="text-secondary text-uppercase d-flex align-items-center"
                      style="gap: .34rem"
                    >
                      <feather-icon icon="AlertOctagonIcon" />

                      <span style="margin-top: .1rem">
                        {{ $t('news.status.SKETCH') }}
                      </span>
                    </span>
                  </div>

                  <div
                    class="text-secondary d-flex flex-wrap align-items-center justify-content-between"
                    style="gap: .34rem"
                  >
                    <div
                      class="text-secondary d-flex flex-wrap align-items-center"
                      style="gap: .34rem"
                    >
                      <avatar
                        variant="light-primary"
                        :user="post.author"
                        :show-name="false"
                        size="20"
                      />

                      {{ post.createdAt.date.split(' ')[0] }}
                    </div>

                    <b-button
                      v-if="checkRequiredPermissions([$roles.NEWS_POST_MODIFY])"
                      size="sm"
                      variant="flat-primary"
                      class="p-25"
                      :to="{ name: 'newsEdit', params: { id: post.id } }"
                    >
                      {{ $t('Edit') }}

                      <feather-icon
                        icon="SettingsIcon"
                        class="spinner ml-25"
                      />
                    </b-button>
                  </div>
                </b-card-body>
              </router-link>
            </b-card>
          </b-col>
        </b-row>

        <b-alert
          v-else
          variant="primary"
          show
          class-name="shadow border border-secondary"
          style="border-color: #DDD !important;"
        >
          <div
            class="alert-body text-left overflow-hidden position-relative"
          >
            <div
              class="d-flex align-items-center justify-content-center w-100 position-relative zindex-1"
              style="min-height: 240px"
            >
              <div
                class="d-flex flex-column justify-content-center align-items-center"
                style="min-height: 240px"
              >
                <!--      START->Announcement: No Posts      -->
                <h3 class="text-primary font-weight-bolder">
                  {{ $t('NoNews') }}
                </h3>
                <!--      END->Announcement: No Posts      -->

                <!--      START->Button: Add Post      -->
                <b-button
                  variant="primary"
                  class="mt-1 d-flex align-items-center"
                  style="column-gap: .34rem"
                  :to="{ name: 'newsNew' }"
                >
                  {{ $t('AddNewPost') }}

                  <feather-icon
                    icon="PlusIcon"
                    style="top: 0"
                  />
                </b-button>
                <!--      END->Button: Add Post      -->
              </div>
            </div>

            <!--      START->Illustration      -->
            <div class="text-center w-100 mt-2 position-absolute position-top-0">
              <img
                src="@/assets/images/pricing-Illustration.svg"
                alt="Illustration"
                width="950px"
                style="opacity: .14; z-index: 0;"
              >
            </div>
            <!--      END->Illustration      -->
          </div>
        </b-alert>
      </template>
    </sw-table>

    <b-overlay
      :show="isLoading"
      no-wrap
      spinner-variant="primary"
    />
  </div>
</template>

<script>
import { Polish as pl } from '@/libs/i18n/locales/flatpickr/pl'
import { english as en } from '@/libs/i18n/locales/flatpickr/en'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import { USERS_SEARCH } from '@/@constants/fields'
import { mapGetters } from 'vuex'
import { DOWNLOAD_FILE, GET_NEWS_ALL, GET_SINGLE_USER } from '@/@constants/mutations'

export default {
  components: {
    vSelect,
    flatPickr,
  },

  props: {
    hideFilters: {
      type: Boolean,
      required: false,
    },
  },

  data: () => ({
    isLoading: false,

    posts: [],

    filters: {
      authors: [],
      createdAt: '',
      showSketchesOnly: false,
    },

    userList: [],
    flatPickerConfig: {
      mode: 'range',
      locale: [pl, en],
    },

    sorter: {
      sortBy: 'createdAt',
      sortDesc: true,
    },
    pagination: {
      perPage: 25,
      totalRecords: 0,
      currentPage: 1,
    },
    searchQuery: '',
  }),

  computed: {
    locale() {
      return this.$i18n.locale
    },

    ...mapGetters({
      system: 'system/getSettings',
      currentUser: 'auth/getCurrentUser',
    }),
  },

  watch: {
    filters: {
      deep: true,
      handler() {
        this.loadPosts()
      },
    },
  },

  mounted() {
    this.ini()
  },

  methods: {
    ini() {
      const author = Number(this.$route.params?.author) || null

      if (!author) {
        this.loadPosts()
      } else {
        this.loadUser(author, this.isLoading)
      }
    },

    async loadPosts() {
      this.isLoading = true

      const payload = {
        pagination: this.pagination,
        search: this.searchQuery,
        filters: this.filters,
      }

      await this.$store.dispatch(`news/${GET_NEWS_ALL}`, payload)
        .then(res => {
          this.posts = res

          this.downloadPictures()
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    async loadUsers(search, isLoading) {
      const filters = {}

      this.userList = await this.selectSearch(search, isLoading, USERS_SEARCH, 'firstName,lastName', '1/users', 25, filters)
    },

    async loadUser(id) {
      this.isLoading = true

      await this.$store.dispatch(`users/${GET_SINGLE_USER}`, id)
        .then(author => {
          this.filters.authors.push(author)
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    b64toBlob(b64Data, contentType = '', sliceSize = 512) {
      const byteCharacters = atob(b64Data)
      const byteArrays = []

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize)

        const byteNumbers = new Array(slice.length)
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i)
        }

        const byteArray = new Uint8Array(byteNumbers)
        byteArrays.push(byteArray)
      }

      const blob = new Blob(byteArrays, { type: contentType })
      return blob
    },

    async downloadPictures() {
      this.posts.forEach((post, index) => {
        if (post.picture) {
          this.$store.dispatch(`uploader/${DOWNLOAD_FILE}`, { fileToken: post.picture.token, filename: post.picture.name })
            .then(fileRes => {
              const { data } = fileRes

              this.posts[index].picture = data.file
            })
        }
      })
    },
  },
}
</script>
