<template>
  <div id="offer-settings"
       class="position-relative"
  >
    <h4>{{ $t('General') }}</h4>
    <b-card>
      <b-form-group>
        <b-form-checkbox
          v-model="hideOrderProcessInCart"
          checked="true"
          name="check-button"
          variant="primary"
          switch
          inline
          class="w-100"
        >
          {{ $t('HideOrderProcess') }}
        </b-form-checkbox>
      </b-form-group>
      <b-form-group class="mb-0">
        <b-form-checkbox
          v-model="defaultCartOrderProcess"
          checked="true"
          name="check-button"
          variant="primary"
          switch
          inline
          class="w-100"
        >
          {{ $t('SetOrderAsDefault') }}
        </b-form-checkbox>
      </b-form-group>
    </b-card>

    <h4>{{ $t('Order') }}</h4>
    <b-card>

      <b-form-group>
        <template #label>
          {{ $t('Patter') }}
          <b-badge id="short-modal"
                   class="cursor-pointer"
                   variant="success"
          >
            <sw-icon icon="CopyIcon" />
          </b-badge>
        </template>
        <b-form-input
          id="contactThreadCartOrderNumberPattern"
          v-model="contactThreadCartOrderNumberPattern"
        />
      </b-form-group>

      <b-form-group :label="$t('StartOrdinalNumber')">
        <b-form-input
          id="contactThreadCartOrderNumberPattern"
          v-model="contactThreadCartOrderNumberOrdinalNumberNext"
          type="number"
          min="1"
        />
      </b-form-group>

      <sw-select :name="$t('RestartOrdinalNumberType')">
        <v-select v-model="contactThreadCartOrderNumberOrdinalNumberType"
                  :options="odrinalNumberRestartTypes"
                  :reduce="e => e.value"
        >
          <template #option="{ label }">
            {{ $t(`${label}`) }}
          </template>
          <template #selected-option="{ label }">
            {{ $t(`${label}`) }}
          </template>
        </v-select>
      </sw-select>

      <b-popover target="short-modal"
                 container="offer-settings"
      >
        <vue-perfect-scrollbar style="max-height: 350px">
          <b-list-group flush>
            <b-list-group-item v-for="a in (new Array(6).fill(null).map((e, i) => i + 1))"
                               :key="a"
                               class="cursor-pointer d-flex justify-content-between"
                               @click="copyValue(`{ORDINAL_NUMBER:${a}}`)"
            >
              <div>{{ $tc('OrdinalNumber', a, { length: a }) }}</div>
            </b-list-group-item>

            <b-list-group-item class="cursor-pointer d-flex justify-content-between"
                               @click="copyValue(`{CREATE_MONTHLY}`)"
            >
              <div>{{ $tc('Months', 0) }}</div>
            </b-list-group-item>
            <b-list-group-item class="cursor-pointer d-flex justify-content-between"
                               @click="copyValue(`{CREATE_MONTHLY_SHORT}`)"
            >
              <div>{{ $tc('MonthShort') }}</div>
            </b-list-group-item>
            <b-list-group-item class="cursor-pointer d-flex justify-content-between"
                               @click="copyValue(`{CREATE_DAY}`)"
            >
              <div>{{ $tc('Days', 1) }}</div>
            </b-list-group-item>
            <b-list-group-item class="cursor-pointer d-flex justify-content-between"
                               @click="copyValue(`{CREATE_DAY_SHORT}`)"
            >
              <div>{{ $tc('DayShort') }}</div>
            </b-list-group-item>
            <b-list-group-item class="cursor-pointer d-flex justify-content-between"
                               @click="copyValue(`{CREATE_YEAR}`)"
            >
              <div>{{ $tc('Years', 1) }}</div>
            </b-list-group-item>
            <b-list-group-item class="cursor-pointer d-flex justify-content-between"
                               @click="copyValue(`{CREATE_YEAR_SHORT}`)"
            >
              <div>{{ $tc('YearShort') }}</div>
            </b-list-group-item>
          </b-list-group>
        </vue-perfect-scrollbar>
      </b-popover>
    </b-card>

    <b-modal v-model="statusModal.show"
             :title="$t(statusModal.index > -1 ? 'Edit' : 'Add')"
    >
      123
    </b-modal>

    <b-button
      variant="primary"
      :disabled="loading"
      @click="save"
    >
      {{ $t('Save') }}
    </b-button>

    <b-overlay
      no-wrap
      :show="loading"
      spinner-variant="primary"
    />
  </div>
</template>

<script>
import {
  GET_PRIVATE_SETTINGS, GET_SETTINGS, SAVE_OFFER_EMAIL, SAVE_SETTINGS,
} from '@/@constants/mutations'
import { mapGetters } from 'vuex'
import { BPopover } from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import vSelect from 'vue-select'
import axiosIns from '@/libs/axios'

const defaultStatus = {
  name: '',
  description: '',
  position: null,
}

export default {
  name: 'OfferSettings',
  components: {
    BPopover, VuePerfectScrollbar, vSelect,
  },
  data: () => ({
    loading: false,

    defaultCartOrderProcess: false,
    hideOrderProcessInCart: false,
    contactThreadCartOrderNumberPattern: '',
    contactThreadCartOrderNumberOrdinalNumberNext: 0,
    contactThreadCartOrderNumberOrdinalNumberType: 'NEVER_RESET',

    odrinalNumberRestartTypes: [
      { value: 'NEVER_RESET', label: 'NeverReset' },
      { value: 'RESET_EVERY_YEAR', label: 'ResetEveryYear' },
      { value: 'RESET_EVERY_MONTH', label: 'ResetEveryMonth' },
    ],

    orderStatuses: [],
    defaultStatus,
    statusModal: {
      show: false,
      index: -1,
      item: JSON.parse(JSON.stringify(defaultStatus)),
    },
  }),
  computed: {
    ...mapGetters({
      system: 'system/getSettings',
    }),
  },
  async mounted() {
    this.loading = true
    try {
      const [resp, privateResp, orderStatusResponse = null] = await Promise.all([
        this.$store.dispatch(`system/${GET_SETTINGS}`),
        this.$store.dispatch(`system/${GET_PRIVATE_SETTINGS}`),
        // axiosIns.get('1/settings/orderStatuses', { params: { fields_load: 'ORDER_STATUS' } }),
      ])
      this.defaultCartOrderProcess = resp.defaultCartOrderProcess || false
      this.hideOrderProcessInCart = resp.hideOrderProcessInCart || false
      this.contactThreadCartOrderNumberPattern = privateResp?.data?.item?.contactThreadCartOrderNumberPattern || ''
      this.contactThreadCartOrderNumberOrdinalNumberNext = privateResp?.data?.item?.contactThreadCartOrderNumberOrdinalNumberNext || 0
      this.contactThreadCartOrderNumberOrdinalNumberType = privateResp?.data?.item?.contactThreadCartOrderNumberOrdinalNumberType || 'NEVER_RESET'

      if (orderStatusResponse && orderStatusResponse?.data?.data?.items) {
        this.orderStatuses = orderStatusResponse.data?.data?.items
      }
    } catch (err) {
      this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
    } finally {
      this.loading = false
    }
  },
  methods: {
    // Order Status Actions
    async closeEditModal() {
      this.$set(this, 'statusModal', {
        show: false,
        index: -1,
        item: JSON.parse(JSON.stringify(this.defaultStatus)),
      })
    },
    async editStatus(editedStatus, index) {
      this.$set(this, 'statusModal', {
        show: false,
        index: index > -1 ? index : -1,
        item: JSON.parse(JSON.stringify(editedStatus || this.defaultStatus)),
      })
    },
    async removeStatus(index) {
      this.$delete(this.orderStatuses, index)
    },
    async saveStatus() {
      if (this.statusModal.index !== -1) {
        this.$set(this.orderStatuses, this.statusModal.index, this.statusModal.item)
      } else {
        this.orderStatuses.push(this.statusModal.item)
      }

      await this.closeEditModal()
    },
    async saveStatuses() {
      const payload = this.orderStatuses.map((status, index) => ({ ...status, position: index + 1 }))
      await axiosIns.put('1/settings/orderStatuses', payload)
    },
    // Order Status Actions
    async save() {
      this.loading = true
      this.system.defaultCartOrderProcess = this.defaultCartOrderProcess
      this.system.hideOrderProcessInCart = this.hideOrderProcessInCart
      this.system.contactThreadCartOrderNumberPattern = this.contactThreadCartOrderNumberPattern
      this.system.contactThreadCartOrderNumberOrdinalNumberNext = String(this.contactThreadCartOrderNumberOrdinalNumberNext || 0)
      this.system.contactThreadCartOrderNumberOrdinalNumberType = this.contactThreadCartOrderNumberOrdinalNumberType

      try {
        await Promise.all([this.saveSettings(), this.saveStatuses()])
        this.showToast('success', this.$i18n.t('success.contactUpdated'))
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.loading = false
      }
    },
    saveSettings() {
      return this.$store.dispatch(`system/${SAVE_SETTINGS}`, {
        savePayload: {
          defaultCartOrderProcess: this.defaultCartOrderProcess,
          hideOrderProcessInCart: this.hideOrderProcessInCart,
          contactThreadCartOrderNumberPattern: this.contactThreadCartOrderNumberPattern,
          contactThreadCartOrderNumberOrdinalNumberNext: String(this.contactThreadCartOrderNumberOrdinalNumberNext || 0),
          contactThreadCartOrderNumberOrdinalNumberType: this.contactThreadCartOrderNumberOrdinalNumberType,
        },
      })
    },
    saveTemplate(payload) {
      return this.$store.dispatch(`templates/${SAVE_OFFER_EMAIL}`, payload)
    },
    copyValue(value) {
      this.$copyText(value, this.$el).then(() => {
        this.showToast('primary', this.$i18n.t('CopiedToClipboard'))
        this.$root.$emit('bv::hide::popover')
      }, e => {
        this.showToast('success', e)
      })
    },
  },
}
</script>

<style lang="scss">
#offer-settings {
    .popover {
        min-width: 280px;
        max-width: 350px !important;
        .popover-body {
            padding: 0.65rem 0 !important;
        }
    }
}
</style>
