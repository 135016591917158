<template>
  <div>Offer Polls</div>
</template>

<script>
export default {
  name: 'OfferPolls',
}
</script>

<style scoped>

</style>
