// eslint-disable-next-line import/no-cycle
import axiosIns from '@/libs/axios'
import {
  GET_WIDGET_CHAT, GET_WIDGET_CHATS, CREATE_WIDGET_CHATS, UPDATE_WIDGET_CHAT, DELETE_WIDGET_CHAT,
} from '@/@constants/mutations'
import { WIDGET_CHATS } from '@/@constants/fields'
// eslint-disable-next-line import/no-cycle
import { checkRequiredModule } from '@core/mixins'

export default {
  namespaced: true,
  actions: {
    [GET_WIDGET_CHAT]: (_, id) => new Promise((resolve, reject) => {
      const params = {
        fields_load: WIDGET_CHATS,
      }

      axiosIns.get(`1/widget/chat/${id}`, { params })
        .then(res => resolve(res.data.data.item))
        .catch(err => reject(err))
    }),
    [GET_WIDGET_CHATS]: () => new Promise((resolve, reject) => {
      const params = {
        fields_load: WIDGET_CHATS,
      }

      if (checkRequiredModule('clientBrowserModule')) {
        axiosIns.get('1/widget/chat', { params })
          .then(res => resolve(res.data.data.items))
          .catch(err => reject(err))
      } else resolve([])
    }),
    [CREATE_WIDGET_CHATS]: (_, payload) => new Promise((resolve, reject) => {
      axiosIns.post('1/widget/chat', payload)
        .then(res => resolve(res.data.data.items[0]))
        .catch(err => reject(err))
    }),
    [UPDATE_WIDGET_CHAT]: (_, payload) => new Promise((resolve, reject) => {
      axiosIns.patch('1/widget/chat', payload)
        .then(res => resolve(res.data.data.items[0]))
        .catch(err => reject(err))
    }),
    [DELETE_WIDGET_CHAT]: (_, id) => new Promise((resolve, reject) => {
      axiosIns.delete('1/widget/chat', { data: JSON.stringify([id]) })
        .then(res => resolve(res))
        .catch(err => reject(err))
    }),
  },
}
