export const THREAD_STATUSES = 'THREAD_STATUSES'
export const THREAD_CLOSE_STATUSES = 'THREAD_CLOSE_STATUSES'
export const THREAD_STATUS = 'THREAD_STATUS'
export const CONTACT_STATUSES = 'CONTACT_STATUSES'
export const ORDER_STATUSES = 'ORDER_STATUSES'
export const TASK_TYPES = 'TASK_TYPES'
export const TASK_TYPE = 'TASK_TYPE'
export const OFFER_STATUSES = 'OFFER_STATUSES'
export const CHECKLIST_POINT = 'CHECKLIST_POINT'
export const ASSIGNED_USER = 'ASSIGNED_USER'

export const CONTACT_THREAD_PRIMARY_CREATED = 'CONTACT_THREAD_PRIMARY_CREATED'
export const DAYS_COUNT = 'DAYS_COUNT'

// Conditions
export const CONTACT_APPLICATION_INTEGRATION_TYPE = 'CONTACT_APPLICATION_INTEGRATION_TYPE'
export const CONTACT_STATUS = 'CONTACT_STATUS'
export const CONTACT_THREAD_CART_OFFER_STATUS = 'CONTACT_THREAD_CART_OFFER_STATUS'
export const CONTACT_THREAD_FUNNEL_TYPE_POINT = 'CONTACT_THREAD_FUNNEL_TYPE_POINT'
export const CONTACT_ASSIGNED_USER = 'CONTACT_ASSIGNED_USER'
export const CONTACT_THREAD_ASSIGNED_USER = 'CONTACT_THREAD_ASSIGNED_USER'
export const CONTACT_ADDON_FIELD = 'CONTACT_ADDON_FIELD'

// Actions
export const SEND_SMS = 'SEND_SMS'
export const SEND_EMAIL = 'SEND_EMAIL'
export const CREATE_CONTACT_THREAD_TASK = 'CREATE_CONTACT_THREAD_TASK'
export const CREATE_CONTACT_WITH_CONTACT_THREAD = 'CREATE_CONTACT_WITH_CONTACT_THREAD'
export const CHANGE_CONTACT_STATUS = 'CHANGE_CONTACT_STATUS'
export const CHANGE_CONTACT_THREAD_FUNNEL_TYPE_POINT = 'CHANGE_CONTACT_THREAD_FUNNEL_TYPE_POINT'
export const ASSIGN_USER_CONTACT = 'ASSIGN_USER_CONTACT'
export const ASSIGN_USER_CONTACT_THREAD = 'ASSIGN_USER_CONTACT_THREAD'
export const CREATE_CONTACT_THREAD_NOTE = 'CREATE_CONTACT_THREAD_NOTE'
export const CREATE_CONTACT_THREAD_CHECKLIST = 'CREATE_CONTACT_THREAD_CHECKLIST'
export const CONTACT_THREAD_FUNNEL_PROCESS_STATUS = 'CONTACT_THREAD_FUNNEL_PROCESS_STATUS'

// eslint-disable-next-line import/prefer-default-export
export const automationsCheckboxActions = [
  { value: 'ALL', label: 'AutomationSection.All' },
  { value: 'CHECK', label: 'AutomationSection.Check' },
  { value: 'UNCHECK', label: 'AutomationSection.Uncheck' },
]

export const automationCheckboxActionsByValue = value => automationsCheckboxActions.find(type => type.value === value)?.label || ''

export const automationsTypes = [
  {
    value: 'CRON', label: 'AutomationSection.Cron', icon: 'ClockIcon', description: 'CronInfoBox',
  },
  {
    value: 'TRIGGER', label: 'AutomationSection.Trigger', icon: 'ZapIcon', description: 'TriggerInfoBox',
  },
]

export const automationTypeByValue = value => automationsTypes.find(type => type.value === value)?.label || ''

export const automationsConditionTypes = [
  { value: CONTACT_STATUS, label: 'AutomationSection.ContactStatus' },
  { value: CONTACT_THREAD_FUNNEL_TYPE_POINT, label: 'AutomationSection.ContactThreadFunnelTypePoint' },
  { value: CONTACT_THREAD_CART_OFFER_STATUS, label: 'AutomationSection.ContactThreadCartOfferStatus' },
  { value: CONTACT_ASSIGNED_USER, label: 'AutomationSection.ContactAssignedUser' },
  { value: CONTACT_THREAD_ASSIGNED_USER, label: 'AutomationSection.ContactThreadAssignedUser' },
  { value: CONTACT_THREAD_FUNNEL_PROCESS_STATUS, label: 'AutomationSection.ContactThreadFunnelProcessStatus' },
  { value: CONTACT_ADDON_FIELD, label: 'ContactAddonFields' },
  // { value: CONTACT_APPLICATION_INTEGRATION_TYPE, label: 'AutomationSection.ContactApplicationIntegrationType' },
]

export const automationConditionTypeByValue = value => automationsConditionTypes.find(type => type.value === value)?.label || ''

export const automationsActionTypes = [
  { value: SEND_SMS, label: 'AutomationSection.SendSms' },
  { value: SEND_EMAIL, label: 'AutomationSection.SendEmail' },
  { value: CREATE_CONTACT_WITH_CONTACT_THREAD, label: 'AutomationSection.CreateContact' },
  { value: CREATE_CONTACT_THREAD_TASK, label: 'AutomationSection.CreateContactThreadTask' },
  { value: CREATE_CONTACT_THREAD_NOTE, label: 'AutomationSection.CreateContactThreadNote' },
  { value: CHANGE_CONTACT_STATUS, label: 'AutomationSection.ChangeContactStatus' },
  { value: CHANGE_CONTACT_THREAD_FUNNEL_TYPE_POINT, label: 'AutomationSection.ChangeContactThreadFunnelTypePoint' },
  { value: ASSIGN_USER_CONTACT, label: 'AutomationSection.AssignUserContact' },
  { value: ASSIGN_USER_CONTACT_THREAD, label: 'AutomationSection.AssignUserContactThread' },
  { value: CREATE_CONTACT_THREAD_CHECKLIST, label: 'AutomationSection.AssignChecklistToContactThread' },
]

export const automationActionTypeByValue = value => automationsActionTypes.find(type => type.value === value)?.label || ''

const allActionTypes = [
  SEND_SMS,
  SEND_EMAIL,
  CHANGE_CONTACT_STATUS,
  CHANGE_CONTACT_THREAD_FUNNEL_TYPE_POINT,
  ASSIGN_USER_CONTACT,
  ASSIGN_USER_CONTACT_THREAD,
  CREATE_CONTACT_THREAD_TASK,
  CREATE_CONTACT_THREAD_NOTE,
]

export const automationActionTypesByValue = value => automationsActionTypes.find(type => type.value === value)?.label || ''

export const automationsTriggerTypes = [
  // {
  //   value: 'CONTACT_STATUS_CHANGE',
  //   label: 'Zmiana statusu Kontaktu',
  //   additionalFields: [CONTACT_STATUSES],
  //   conditions: [ASSIGNED_USER],
  //   actions: [
  //     SEND_SMS,
  //     SEND_EMAIL,
  //   ],
  // },
  {
    value: 'CONTACT_THREAD_STATUS_CHANGE',
    label: 'AutomationSection.ContactThreadStatusChange',
    additionalFields: [THREAD_STATUSES],
    conditions: [CONTACT_ASSIGNED_USER, CONTACT_STATUS],
    actions: [
      SEND_SMS,
      SEND_EMAIL,
      ASSIGN_USER_CONTACT,
      ASSIGN_USER_CONTACT_THREAD,
      CREATE_CONTACT_THREAD_TASK,
      CREATE_CONTACT_THREAD_NOTE,
      CREATE_CONTACT_THREAD_CHECKLIST,
    ],
  },
  {
    value: 'CONTACT_THREAD_FUNNEL_PROCESS_STATUS_CHANGE',
    label: 'AutomationSection.ContactThreadCloseStatusChange',
    additionalFields: [THREAD_CLOSE_STATUSES],
    conditions: [CONTACT_STATUS, CONTACT_THREAD_ASSIGNED_USER, CONTACT_ASSIGNED_USER, CONTACT_THREAD_FUNNEL_TYPE_POINT],
    actions: allActionTypes,
  },
  {
    value: 'CONTACT_THREAD_CHECKLIST_POINT_CHANGE',
    label: 'AutomationSection.ContactThreadChecklistPointChange',
    additionalFields: [CHECKLIST_POINT],
    conditions: [CONTACT_STATUS, CONTACT_THREAD_ASSIGNED_USER, CONTACT_ASSIGNED_USER, CONTACT_THREAD_FUNNEL_TYPE_POINT],
    actions: allActionTypes,
  },
  {
    value: 'CONTACT_APPLICATION_ADD',
    label: 'AutomationSection.ContactApplicationAdd',
    additionalFields: [CONTACT_APPLICATION_INTEGRATION_TYPE],
    conditions: [],
    actions: [CREATE_CONTACT_WITH_CONTACT_THREAD],
  },
  {
    value: 'CONTACT_ADD',
    label: 'AutomationSection.ContactAdd',
    additionalFields: [],
    conditions: [CONTACT_ASSIGNED_USER],
    actions: [ASSIGN_USER_CONTACT],
  },
  {
    value: 'CONTACT_THREAD_CART_ORDER_STATUS_CHANGE',
    label: 'AutomationSection.ContactThreadCartOrderStatusChange',
    additionalFields: [ORDER_STATUSES],
    conditions: [CONTACT_STATUS, CONTACT_THREAD_ASSIGNED_USER, CONTACT_ASSIGNED_USER, CONTACT_THREAD_FUNNEL_TYPE_POINT],
    actions: allActionTypes,
  },
  {
    value: 'CONTACT_THREAD_CART_OFFER_SEND',
    label: 'AutomationSection.ContactThreadOfferSend',
    additionalFields: [],
    conditions: [CONTACT_STATUS, CONTACT_THREAD_FUNNEL_TYPE_POINT, CONTACT_THREAD_ASSIGNED_USER, CONTACT_ASSIGNED_USER, CONTACT_THREAD_CART_OFFER_STATUS],
    actions: allActionTypes,
  },
  {
    value: 'CONTACT_THREAD_CART_OFFER_OPEN',
    label: 'AutomationSection.ContactThreadOfferOpen',
    additionalFields: [],
    conditions: [CONTACT_STATUS, CONTACT_THREAD_FUNNEL_TYPE_POINT, CONTACT_THREAD_ASSIGNED_USER, CONTACT_ASSIGNED_USER, CONTACT_THREAD_CART_OFFER_STATUS],
    actions: allActionTypes,
  },
  {
    value: 'CONTACT_THREAD_CART_OFFER_STATUS_CHANGE',
    label: 'AutomationSection.ContactThreadCartOfferStatusChange',
    additionalFields: [OFFER_STATUSES],
    conditions: [CONTACT_STATUS, CONTACT_THREAD_ASSIGNED_USER, CONTACT_ASSIGNED_USER, CONTACT_THREAD_FUNNEL_TYPE_POINT],
    actions: allActionTypes,
  },
  // {
  //   value: 'CONTACT_THREAD_CART_OFFER_ACCEPT',
  //   label: 'AutomationSection.ContactThreadCartOfferAccept',
  //   additionalFields: [],
  //   conditions: [CONTACT_STATUS, CONTACT_THREAD_ASSIGNED_USER, CONTACT_ASSIGNED_USER, CONTACT_THREAD_FUNNEL_TYPE_POINT],
  //   actions: allActionTypes,
  // },
  {
    value: 'CONTACT_THREAD_AGREEMENT_ACCEPT',
    label: 'AutomationSection.ContactThreadAgreementAccept',
    additionalFields: [],
    conditions: [CONTACT_STATUS, CONTACT_THREAD_ASSIGNED_USER, CONTACT_ASSIGNED_USER, CONTACT_THREAD_FUNNEL_TYPE_POINT],
    actions: allActionTypes,
  },

  // NEW
  {
    value: 'CONTACT_THREAD_TASK_STATUS_CHANGE',
    label: 'AutomationSection.TaskDone',
    additionalFields: [TASK_TYPES],
    conditions: [CONTACT_STATUS, CONTACT_THREAD_ASSIGNED_USER, CONTACT_ASSIGNED_USER, CONTACT_THREAD_FUNNEL_TYPE_POINT],
    actions: allActionTypes,
  },
  {
    value: 'CONTACT_THREAD_TASK_ADD',
    label: 'AutomationSection.TaskAdd',
    additionalFields: [TASK_TYPE],
    conditions: [CONTACT_STATUS, CONTACT_THREAD_ASSIGNED_USER, CONTACT_ASSIGNED_USER, CONTACT_THREAD_FUNNEL_TYPE_POINT],
    actions: allActionTypes,
  },
  {
    value: 'CONTACT_THREAD_ADD',
    label: 'AutomationSection.ThreadCreate',
    additionalFields: [],
    conditions: [CONTACT_STATUS, CONTACT_THREAD_ASSIGNED_USER, CONTACT_ASSIGNED_USER],
    actions: [
      SEND_SMS,
      SEND_EMAIL,
      CREATE_CONTACT_THREAD_TASK,
      CREATE_CONTACT_THREAD_NOTE,
      CREATE_CONTACT_THREAD_CHECKLIST,
    ],
  },
  {
    value: 'CONTACT_THREAD_FUNNEL_PROCESS_ADD',
    label: 'AutomationSection.ThreadProcessCreate',
    additionalFields: [THREAD_STATUS],
    conditions: [CONTACT_STATUS, CONTACT_THREAD_ASSIGNED_USER, CONTACT_ASSIGNED_USER],
    actions: [
      SEND_SMS,
      SEND_EMAIL,
      CREATE_CONTACT_THREAD_TASK,
      CREATE_CONTACT_THREAD_NOTE,
      CREATE_CONTACT_THREAD_CHECKLIST,
    ],
  },
  // CRON ACTIONS
  {
    type: 'CRON',
    value: 'CONTACT_THREAD_FUNNEL_TYPE_POINT_DELAYED',
    label: 'ProcessStatusDelayed',
    additionalFields: [DAYS_COUNT],
    conditions: [CONTACT_STATUS, CONTACT_THREAD_ASSIGNED_USER, CONTACT_ASSIGNED_USER, CONTACT_THREAD_FUNNEL_TYPE_POINT, CONTACT_THREAD_FUNNEL_PROCESS_STATUS],
    actions: allActionTypes,
  },
  {
    type: 'CRON',
    value: 'CONTACT_THREAD_TIMELINE_NO_ACTION',
    label: 'NoActionInThread',
    additionalFields: [DAYS_COUNT],
    conditions: [CONTACT_STATUS, CONTACT_THREAD_ASSIGNED_USER, CONTACT_ASSIGNED_USER, CONTACT_THREAD_FUNNEL_TYPE_POINT, CONTACT_THREAD_FUNNEL_PROCESS_STATUS],
    actions: allActionTypes,
  },
  {
    type: 'CRON',
    value: 'CONTACT_STATUS_DELAYED',
    label: 'ContactStatusDelayed',
    additionalFields: [DAYS_COUNT],
    conditions: [CONTACT_STATUS, CONTACT_ASSIGNED_USER],
    actions: [
      SEND_SMS,
      SEND_EMAIL,
      ASSIGN_USER_CONTACT,
      ASSIGN_USER_CONTACT_THREAD,
      CREATE_CONTACT_THREAD_TASK,
      CREATE_CONTACT_THREAD_NOTE,
      CREATE_CONTACT_THREAD_CHECKLIST,
    ],
  },
  {
    type: 'CRON',
    value: 'CONTACT_THREAD_CART_OFFER_NO_VIEWS',
    label: 'OfferNotOpen',
    additionalFields: [DAYS_COUNT],
    conditions: [CONTACT_STATUS, CONTACT_THREAD_ASSIGNED_USER, CONTACT_ASSIGNED_USER, CONTACT_THREAD_FUNNEL_TYPE_POINT, CONTACT_THREAD_FUNNEL_PROCESS_STATUS],
    actions: allActionTypes,
  },
  {
    type: 'CRON',
    value: 'CONTACT_THREAD_PRIMARY_CREATED',
    label: 'AutomationContactCreated',
    additionalFields: [],
    conditions: [CONTACT_STATUS, CONTACT_ASSIGNED_USER, CONTACT_ADDON_FIELD],
    actions: allActionTypes,
  },
]

// export const automationTriggerTypeByValue = value => automationsTypes.find(type => type.value === value)?.label || '11'
export const automationTriggerTypeByValue = value => automationsTriggerTypes.find(type => type.value === value)?.label || ''
