<!--eslint-disable-->

<template>
  <b-row class="d-flex flex-row-reverse">
    <b-col
      sm="12"
      md="12"
      lg="12"
    >
      <!--    Button: New Contract    -->
      <b-button
        class="mb-50"
        variant="primary"
        size="sm"
        :to="{ name: 'components', params: { templateId: 'new' } }"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-25"
        />
        {{ $t('Add') }}
      </b-button>
      <!--    Categories View    -->
      <div>
        <b-card class="myTableOffer">
          <sw-table
            table-id="5c9cdab3d17d402789ce64df48377461"
            :pagination="pagination"
            @change-pagination="Object.assign(pagination, $event)"
            @reload-content="getTemplates"
          >
            <template #table>
              <b-table
                :items="templates"
                :fields="fields"
                :busy="loading"
                striped
                responsive
                show-empty
              >
                <!--    Table Head    -->
                <template #head()="item">
                  <span class="text-nowrap">
                    {{ $tc(item.field.label) | truncate(34, '...') }}
                  </span>
                </template>

                <!--    Name    -->
                <template #cell(name)="{ item }">
                  <router-link
                    v-b-tooltip.hover.left
                    :to="{ name: 'components', params: { templateId: item.id } }"
                    :title="item.name"
                    class="doubleLineText"
                  >
                    {{ item.name | truncate(26, '...') }}
                  </router-link>
                </template>

                <!--    Status    -->
                <template #cell(type)="{ value }">
                  <!--    Sketches    -->
                  <b-badge variant="primary">
                    {{ getComponentLabel(value) }}
                  </b-badge>
                </template>

                <!--    Creator    -->
                <template #cell(createdBy)="{ value }">
                  <avatar
                    :user="value"
                    :size="28"
                  />
                </template>

                <!--    Table Action    -->
                <template #cell(action)="{ item }">
                  <b-dropdown
                    variant="link"
                    class="dropdown-icon-wrapper text-decoration-none"
                    no-caret
                  >
                    <template
                      #button-content
                      class="sr-only"
                    >
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="align-middle"
                      />
                    </template>
                    <!--    Action: Edit    -->
                    <b-dropdown-item :to="{ name: 'components', params: { templateId: item.id } }">
                      {{ $t('Edit') }}
                    </b-dropdown-item>
                    <!--    Action: Edit    -->
                    <b-dropdown-item @click="deleteTemplate(item.id)">
                      {{ $t('Delete') }}
                    </b-dropdown-item>
                  </b-dropdown>
                </template>

                <!--    Other    -->
                  <!--    Other    -->
                  <template #cell()="row">
                      <table-default-cell
                              :field="row.field"
                              :value="row.value"
                      />
                  </template>

                  <!--      Empty      -->
                  <template #empty>
                      <empty-content />
                  </template>

                  <!--      Table Busy      -->
                  <template #table-busy>
                      <div class="text-center text-danger my-2">
                          <b-spinner
                                  class="align-middle"
                                  variant="primary"
                          />
                      </div>
                  </template>
              </b-table>
            </template>
          </sw-table>
        </b-card>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { BTable, VBTooltip } from 'bootstrap-vue'
import { DELETE_PRODUCT_TEMPLATE, GET_PRODUCT_TEMPLATES } from '@/@constants/mutations'
import { formatDate } from '@core/utils/filter'
import { components } from '@/@data/components'

export default {
  components: {
    BTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data: () => ({
    fields: [
      { key: 'name', label: 'Name' },
      { key: 'type', label: 'task.Type' },
      { key: 'createdBy', label: 'CreatedBy' },
      { key: 'createdAt', label: 'offer.CreatedTime' },
      { key: 'action', label: 'Action' },
    ],
    loading: false,
    sorter: {
      sortBy: 'createdAt',
      sortDesc: true,
    },
    pagination: {
      perPage: 25,
      currentPage: 1,
      totalRecords: 100,
    },
    templates: [],
  }),
  mounted() {
    this.getTemplates()
  },
  methods: {
    formatDate,
    getTemplates() {
      this.loading = true
      this.$nextTick(() => {
        this.$store.dispatch(`productTemplate/${GET_PRODUCT_TEMPLATES}`, { pagination: this.pagination })
          .then(res => {
            this.templates = res.data.items
            this.pagination.totalItemCount = res.data.totalItemCount
            this.loading = false
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            this.loading = false
          })
      })
    },
    getComponentLabel(value) {
      const component = components.find(c => c.value === value)
      if (component) return this.$i18n.t(`${component.label}`)

      return ''
    },
    deleteTemplate(id) {
      this.showAlert('error', this.$i18n.t('alerts.AreYouSure'))
        .then(() => {
          this.$store.dispatch(`productTemplate/${DELETE_PRODUCT_TEMPLATE}`, id)
            .then(() => {
              this.showToast('success', this.$i18n.t('RemovedSuccessfully'))
              this.getTemplates()
            })
            .catch(err => {
              this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
// .form-group { margin: 0 }

.stickyFilterBar {
  position: sticky;

  top: 7rem;
}
</style>
