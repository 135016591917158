<template>
  <div>
    <!--  GOOGLE  -->
    <b-row ref="container">
      <b-col
        sm="12"
        class="mb-50 mt-n50"
      >
        <template v-for="type in types">
          <b-button
            :key="type.label"
            :variant="selectedType.value === type.value ? 'primary' : 'outline-primary'"
            class="mr-50 mt-50"
            @click="selectedType = type"
          >
            {{ type.label }}
          </b-button>
        </template>
      </b-col>

      <b-col
        v-if="selectedType.value !== 'FACEBOOK'"
        key="NOT_FACEBOOK"
        sm="12"
      >
        <b-alert
          show
          variant="primary"
        >
          <div class="alert-body">
            <div class="d-flex flex-wrap justify-content-between align-items-center px-1">
              <p>
                {{ $t('AddNewIntegration') }}
              </p>

              <div>
                <b-button
                  v-show="checkRequiredPermissions([$roles.SETTINGS])"
                  v-if="selectedType.value === 'FACEBOOK' && !allowFbIntegration"
                  variant="primary"
                  size="sm"
                  class="d-flex align-items-center justify-content-center"
                  :to="{ name: 'integrations' }"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-25 mt-25"
                  />

                  {{ $t('Set') }}
                </b-button>

                <b-button
                  :disabled="selectedType.value === 'FACEBOOK' && !allowFbIntegration"
                  variant="primary"
                  size="sm"
                  class="d-flex align-items-center justify-content-center"
                  @click="() => { showModal = true; edited.type = selectedType.value; edited.accessKey = getRandomString() }"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-25"
                  />

                  {{ $t('Add') }}
                </b-button>
              </div>
            </div>
          </div>
        </b-alert>
      </b-col>

      <b-col
        v-if="selectedType.value === 'WORDPRESS'"
        key="WORDPRESS"
        sm="12"
      >
        <b-alert
          show
          variant="primary"
        >
          <div class="alert-body">
            <div class="d-flex flex-wrap justify-content-between align-items-center px-1">
              <p>
                {{ $t('IntegrationInstructionInTrainingVideos') }}
              </p>

              <div
                class="d-flex flex-wrap flex-column flex-md-row"
                style="column-gap: .5rem; row-gap: .5rem;"
              >
                <b-button
                  variant="primary"
                  size="sm"
                  class="d-flex align-items-center"
                  href="https://youtube.com/embed/Ak0t1OT5JTQ"
                  target="_blank"
                >
                  <feather-icon
                    icon="LinkIcon"
                    class="mt-25 mr-25"
                  />

                  Contact Form 7
                </b-button>

                <b-button
                  class="d-flex align-items-center"
                  variant="primary"
                  size="sm"
                  href="https://youtube.com/embed/JTJu1MaNw-Y"
                  target="_blank"
                >
                  <feather-icon
                    icon="LinkIcon"
                    class="mt-25 mr-25"
                  />

                  WP Forms
                </b-button>
              </div>
            </div>
          </div>
        </b-alert>
      </b-col>

      <b-col
        v-if="selectedType.value === 'HTML'"
        key="HTML"
        sm="12"
      >
        <b-alert
          show
          variant="primary"
        >
          <div
            class="alert-body d-flex flex-column"
            style="row-gap: .75rem"
          >
            <div class="d-flex flex-wrap flex-column justify-content-between align-items-start flex-md-row">
              <p>
                {{ $t('ApplicationInstruction') }}
              </p>

              <b-button
                variant="primary"
                size="sm"
                download
                :href="require('@/assets/pdf/SalesWizard_Webhook_HTML.pdf')"
              >
                <feather-icon
                  icon="DownloadIcon"
                  class="mr-25"
                />

                {{ $t('Download') }}
              </b-button>
            </div>

            <div class="d-flex flex-wrap flex-column justify-content-between align-items-start flex-md-row">
              <p>
                {{ $t('AddNewIntegration') }}
              </p>

              <b-button
                ref="copy-code"
                variant="primary"
                size="sm"
                @click="copyScript"
              >
                <feather-icon
                  icon="CopyIcon"
                  class="mr-25"
                />

                {{ $t('Copy') }}
              </b-button>
            </div>
          </div>
        </b-alert>
      </b-col>

      <b-col
        v-if="selectedType.value === 'FACEBOOK'"
        key="FACEBOOK"
        sm="12"
      >
        <facebook />
      </b-col>

      <!--   Google Items   -->
      <b-col
        v-for="(item, index) in selectedTypeItems"
        :key="`google_${item.id}`"
        sm="12"
        lg="4"
      >
        <b-card
          footer-class="p-50"
          header-bg-variant="primary"
          header-text-variant="light"
          header-class="p-50"
        >
          <template #header>
            <div class="d-flex justify-content-between w-100 px-1">
              <div>{{ item.name || '-' }}</div>

              <div
                v-if="item.active"
                v-b-tooltip
                :title="$t('Deactivate')"
                class="cursor-pointer"
              >
                <feather-icon
                  icon="XIcon"
                  @click="deactivate(item, index)"
                />
              </div>
            </div>
          </template>

          <div class="d-flex justify-content-between align-items-center mt-1">
            <div>
              <b-avatar
                variant="primary"
                size="25"
              >
                <feather-icon icon="PocketIcon" />
              </b-avatar>

              {{ $t('task.Status') }}
            </div>

            <div class="font-weight-bold">
              <b-badge :variant="item.active ? 'light-success' : 'light-danger'">
                {{ item.active ? $t('Active') : $t('NotActive') }}
              </b-badge>
            </div>
          </div>

          <div class="d-flex justify-content-between align-items-center mt-1">
            <div>
              <b-avatar
                variant="primary"
                size="25"
              >
                <feather-icon icon="KeyIcon" />
              </b-avatar>

              {{ $t('AccessKey') }}
            </div>

            <sw-hidden-content>
              <template #content>
                <div class="font-weight-bold">
                  {{ item.accessKey }}
                </div>
              </template>
            </sw-hidden-content>
          </div>

          <b-badge
            v-if="item.active"
            variant="light-primary"
            class="w-100 mt-75"
            @click="getIntegrationUrl(item)"
          >
            <feather-icon icon="LinkIcon" />

            {{ $t('CopyIntegrationUrl') }}
          </b-badge>

          <template #footer>
            <div
              v-b-tooltip
              class="d-flex flex-wrap justify-content-between align-items-center px-1"
              style="row-gap: .5rem"
              :title="$t('Created')"
            >
              <avatar :user="item.createdBy" />

              <p class="mb-0">
                {{ formatDate(item.createdAt.date, { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' }, $i18n.locale) }}
              </p>
            </div>
          </template>
        </b-card>
      </b-col>
    </b-row>
    <!--  FACEBOOK  -->

    <b-modal
      v-model="showModal"
      :title="edited.id ? $t('New') : $t('Edit')"
      hide-footer
      no-close-on-backdrop
      @hide="closeModal"
    >
      <b-form-group
        class="mt-1"
        :label="$t('Name')"
      >
        <b-form-input
          id="basicInput"
          v-model="edited.name"
        />
      </b-form-group>

      <sw-select :name="$t('task.Type')">
        <v-select
          v-model="edited.type"
          :options="types"
          label="label"
          :reduce="i => i.value"
          :selectable="option => option.value !== 'FACEBOOK'"
        >
          <template #no-options>
            {{ $t('NoOptions') }}
          </template>
        </v-select>
      </sw-select>

      <b-form-group
        class="mt-1 d-none"
        :label="$t('AccessKey')"
      >
        <b-form-input
          id="basicInput"
          v-model="edited.accessKey"
          disabled
        />
      </b-form-group>

      <!-- Footer -->
      <div class="d-flex justify-content-end pt-1 mt-1 border-top">
        <b-button
          variant="secondary mr-1"
          @click="onModalClose"
        >
          {{ $t('Cancel') }}
        </b-button>

        <b-button
          :disabled="!edited.type"
          variant="primary"
          @click="save"
        >
          {{ $t('Save') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BAlert, VBTooltip } from 'bootstrap-vue'
import axiosIns from '@/libs/axios'
import vSelect from 'vue-select'
import { formatDate } from '@core/utils/filter'
import { GET_PRIVATE_SETTINGS } from '@/@constants/mutations'
import Facebook from '@/pages/settings/applications/Facebook.vue'

const emptyItem = {
  name: '',
  type: '',
  accessKey: Math.random().toString(36).substr(2, 20),
}

export default {
  name: 'Applications',
  components: {
    BAlert,
    vSelect,
    Facebook,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data: () => ({
    loading: false,
    allowFbIntegration: false,
    // Edit
    showModal: false,
    edited: { ...emptyItem },
    items: [],
    types: [
      { label: 'Google', value: 'GOOGLE_ADS' },
      { label: 'HTML', value: 'HTML' },
      { label: 'Wordpress', value: 'WORDPRESS' },
      { label: 'Facebook', value: 'FACEBOOK' },
    ],
    selectedType: { label: 'Google', value: 'GOOGLE_ADS' },
    code: `
<?php
if($_SERVER['REQUEST_METHOD'] === 'POST') {
    $url = 'https://dev.saleswizardapp.com/webhook/ehdjya1664865867';
    $key = 'y26gybjlwke';
    $test = false;

    $ip = getenv('HTTP_CLIENT_IP')?:
        getenv('HTTP_X_FORWARDED_FOR')?:
            getenv('HTTP_X_FORWARDED')?:
                getenv('HTTP_FORWARDED_FOR')?:
                    getenv('HTTP_FORWARDED')?:
                        getenv('REMOTE_ADDR')?:
                            null;

    $id = bin2hex(random_bytes(4));

    $companyName = $_POST['companyName'];
    $phone = $_POST['phone'];
    $email = $_POST['email'];
    $age = $_POST['age'];

    $data = [
        'lead_ip' => $ip,
        'lead_id' => $id,
        'html_key' => $key,
        'is_test' => $test,
        'user_column_data' => [
            [
                'string_value' => $companyName,
                'column_id' => 'COMPANY_NAME'
            ],
            [
                'string_value' => $phone,
                'column_id' => 'PHONE'
            ],
            [
                'string_value' => $email,
                'column_id' => 'EMAIL'
            ],
            [
                'string_value' => $age,
                'column_id' => 'how_old_are_you?'
            ],
        ],
    ];

    $ch = curl_init($url);
    curl_setopt($ch, CURLOPT_POST, true);
    curl_setopt($ch, CURLOPT_POSTFIELDS, json_encode($data));

    curl_setopt($ch, CURLOPT_HTTPHEADER, array(
        'Content-Type: application/json',
        'Accept: application/json'
    ));
    curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);

    $resultStr = curl_exec($ch);
    try {
        $resultArr = json_decode($resultStr, true, 512, JSON_THROW_ON_ERROR);
    } catch (JsonException $e) {
        echo 'Response decode problem.';
        exit;
    }

    $resultCode = curl_getinfo($ch, CURLINFO_HTTP_CODE);
    if($resultCode === 200 && empty($resultArr)) {
        echo 'The form was sent successfully.';
    } else {
        $errorMessage = $resultArr['message'] ?? 'Unknown';
        echo 'Error: '.$errorMessage;
    }
    exit;
}
?>
<form id="form" method="post" action="/script.php">
    <label for="companyName">Company name:</label>
    <br/>
    <input type="text" id="companyName" name="companyName" />
    <br/>
    <label for="phone">Phone:</label>
    <br/>
    <input type="text" id="phone" name="phone" />
    <br/>
    <label for="email">E-mail:</label>
    <br/>
    <input type="email" id="email" name="email" />
    <br/>
    <label for="age">How old are you?</label>
    <br/>
    <input type="number" id="age" name="age" />
    <br/>
    <input type="submit" value="Send" />
</form>
    `,
  }),
  computed: {
    selectedTypeItems() {
      return this.items.filter(item => item.type === this.selectedType.value)
    },

    blockFB() {
      const origin = window.location.origin.toString()

      const a = origin === 'https://kliencidlafirm.saleswizard.pl'
      const b = origin === 'https://crm.oze-centrum.pl/'
      const is4B = origin === 'https://4bsystems.saleswizard.pl'
      const isTest = origin === 'https://test.saleswizard.pl'
      const isD1 = origin === 'https://d1.saleswizardapp.com'
      const local = origin === 'https://campter.saleswizard.pl'
      const c = origin === 'https://twocolours.saleswizard.pl'
      const campter = origin === 'http://localhost:8080'
      const network = origin.includes('192') || origin.includes('172') || origin.includes('oze')

      return !(is4B || isTest || isD1 || local || network || campter || a || b || c)
    },
  },
  async mounted() {
    try {
      const { data } = await this.$store.dispatch(`system/${GET_PRIVATE_SETTINGS}`)

      if (data?.item) {
        this.$set(this, 'allowFbIntegration', data.item.autentiTest)
      }
    } catch (err) {
      this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
    }

    this.init()
  },
  methods: {
    formatDate,
    getRandomString() {
      return Math.random().toString(36).substr(2, 9)
    },
    copyScript() {
      this.$copyText(this.code, this.$refs['copy-code']).then(() => {
        this.showToast('primary', this.$i18n.t('CopiedToClipboard'))
        this.onModalClose()
      }, e => {
        this.showToast('success', e)
      })
    },
    getIntegrationUrl({ token }) {
      // Copy
      this.$copyText(`${window.location.origin}/webhook/${token}`, this.$refs.container).then(() => {
        this.showToast('primary', this.$i18n.t('CopiedToClipboard'))
        this.onModalClose()
      }, e => {
        this.showToast('success', e)
      })
      return `${window.location.origin}/webhook/${token}`
    },
    init() {
      this.loading = true
      axiosIns.get('1/settings/contactsApplicationsIntegrations', { params: { fields_load: this.$fields.SETTINGS_CONTACTS_APPLICATIONS_INTEGRATIONS } })
        .then(res => {
          this.items = res.data.data.items
          this.checkFacebookParams()
          this.loading = false
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
    checkFacebookParams() {
      const fbUser = this.$route.query?.fb_user
      if (!fbUser) return

      this.selectedType = { label: 'Facebook', value: 'FACEBOOK' }
    },
    editInvestment(item) {
      this.showModal = true
      this.edited = { ...item }
    },
    onModalClose() {
      this.showModal = false
      this.edited = { ...emptyItem }
    },
    closeModal() {
      this.edited = { ...emptyItem, accessKey: this.getRandomString() }
      this.showModal = false
    },
    async deactivate(item) {
      const accepted = await this.showAlert('error', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('alerts.WontbeAble'))
      if (!accepted) return

      try {
        const resp = await axiosIns.patch('1/settings/contactsApplicationsIntegrations', { id: item.id, active: false })

        if (resp) {
          const itemIndex = this.items.findIndex(i => i.id === item.id)
          this.$set(this.items[itemIndex], 'active', false)
        }
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    async save() {
      this.showModal = false
      this.loading = true
      const type = this.edited
      delete type.createdBy

      type.active = true

      try {
        const resp = type.id
          ? await axiosIns.patch('1/settings/contactsApplicationsIntegrations', type)
          : await axiosIns.post('1/settings/contactsApplicationsIntegrations', type)

        if (resp) this.showToast('success', this.$i18n.t('success.contactUpdated'))
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.loading = false
        this.edited = { ...emptyItem }
        this.init()
      }
    },
  },
}
</script>

<style scoped>

</style>
