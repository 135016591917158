// eslint-disable-next-line import/prefer-default-export
export const emails = [
  {
    path: '/emails',
    name: 'emails',
    component: () => import('@/pages/globalEmail/Email.vue'),
    meta: {
      autorize: true,
      pageTitle: 'GlobalEmails',
    },
  },
]
