<template>
  <validation-observer
    ref="kurczaki"
    tag="div"
    class="new-contract-modal"
  >
    <component
      :is="tag"
      v-if="showTop"
    >
      <transition name="fade">
        <div
          v-if="agreement.agreementTemplate"
          class="d-flex align-items-center flex-wrap mb-1"
        >
          <span class="font-weight-bolder pr-1">{{ $t('Preview') }} {{ $t('AgreementsCount').toLowerCase() }}</span>
          <b-button
            variant="outline-warning"
            size="sm"
            @click="openAgreementPreview"
          >
            <feather-icon icon="EyeIcon" />
          </b-button>
        </div>
      </transition>
      <sw-select :name="$t('Contracts.AgreementType')">
        <v-select
          v-model="contractType"
          :options="contractTypes"
          label="name"
          @input="selectContractTypes"
        />
      </sw-select>
      <b-form-group>
        <v-select
          v-model="agreement.agreementTemplate"
          :disabled="!contractType"
          :options="contracts"
          label="name"
          @input="getRequiredPolls"
        />
      </b-form-group>
      <div
        v-if="agreement.agreementTemplate"
      >
        <b-row>
          <b-col md="6">
            <validation-provider
              v-slot="{ errors }"
              :name="$t('SignerUser')"
              rules="required"
            >
              <b-form-group
                :label="$t('SignerUser')"
              >
                <v-select
                  v-model="agreement.signerUser"
                  :options="agreement.agreementTemplate.signerUsers"
                  style="min-width: 325px"
                >
                  <template #no-options>
                    {{ $t('NoOptions') }}
                  </template>
                  <template #option="option">
                    <avatar :user="option" />
                  </template>
                  <template #selected-option="option">
                    <avatar :user="option" />
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <b-form-group class="w-100">
              <b-form-checkbox
                v-model="agreement.anonymised"
                checked="false"
                variant="primary"
                name="check-button"
                inline
              >
                <span>{{ $t('AnonymousContractTip') }}</span>
              </b-form-checkbox>
            </b-form-group>
            <b-form-group class="w-100 my-0">
              <b-form-checkbox
                v-model="agreement.allowElectronicSignature"
                variant="primary"
                :disabled="!autentiEnabled"
              >
                {{ $t('Offer.EnableElectronicSignatureOfContract') }}
                <feather-icon
                  v-b-tooltip
                  icon="InfoIcon"
                  class="ml-1"
                  :title="$t('Offer.Tips.EnableElectronicSignatureOfContract')"
                />
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <!-- Polls -->
      <div
        v-if="requiredPolls.length && checkRequiredModule('pollModule')"
        class="mb-1"
      >
        <b-alert
          show
          variant="primary"
          class="px-1 py-50"
        >
          <feather-icon icon="AlertCircleIcon" /> {{ $t('AgreementRequiresToCompletePolls') }}
        </b-alert>
        <app-collapse
          accordion
          type="border"
          class="shadow-none"
        >
          <new-poll-modal
            v-for="poll in requiredPolls"
            :key="`required_poll_${poll.id}`"
            :ref="`required_poll_${poll.id}`"
            :title="poll.name"
            :tag="'app-collapse-item'"
            :default-poll="poll"
            :edited-poll="getContactEditedPoll(poll)"
            :are-fields-required="true"
          />
        </app-collapse>
      </div>
    </component>

    <component
      :is="tag"
      v-show="thread"
    >
      {{ $t('ContractorDataForContract') }}
      <contractor-requirements
        ref="contractor-requirements"
        :thread="thread || {}"
        :selected-agreement="agreement.agreementTemplate"
      />
    </component>
    <!-- Payment Schedule -->

    <validation-observer
      ref="payment-schedule-validator"
      tag="div"
    >
      <!-- Reservation -->
      <!--      <component-->
      <!--        :is="tag"-->
      <!--        v-if="agreement.agreementTemplate && agreement.agreementTemplate.advancePayment"-->
      <!--        class="mt-1"-->
      <!--      >-->
      <!--        <div class="d-flex justify-content-between align-items-center pr-50">-->
      <!--          <h4>{{ $t('AdvancePayment') }}</h4>-->
      <!--          <b-badge :variant="leftToAssign.value < 0 ? 'light-danger' : 'light-primary'">-->
      <!--            {{ leftToAssign.label }}-->
      <!--          </b-badge>-->
      <!--        </div>-->
      <!--        <div class="d-flex justify-content-between">-->
      <!--          <div class="flex-grow-1 mr-50">-->
      <!--            &lt;!&ndash; Payment schedule - price &ndash;&gt;-->
      <!--            <validation-provider-->
      <!--              #default="{ errors }"-->
      <!--              :name="$t('Offer.Price')"-->
      <!--              rules="required"-->
      <!--            >-->
      <!--              <b-form-group class="pb-25">-->
      <!--                &lt;!&ndash;                <b-form-input&ndash;&gt;-->
      <!--                &lt;!&ndash;                  v-model="reservation.price"&ndash;&gt;-->
      <!--                &lt;!&ndash;                  class="form-control"&ndash;&gt;-->
      <!--                &lt;!&ndash;                  @input="reservation.value = (($event * 100) / totalGross).toFixed(2)"&ndash;&gt;-->
      <!--                &lt;!&ndash;                />&ndash;&gt;-->
      <!--                <cleave-->
      <!--                  v-model="reservation.price"-->
      <!--                  :options="cleaveOptions.number"-->
      <!--                  class="form-control"-->
      <!--                  @input="reservation.value = (($event * 100) / totalGross).toFixed(2)"-->
      <!--                />-->
      <!--                <small class="text-danger">{{ errors[0] }}</small>-->
      <!--              </b-form-group>-->
      <!--            </validation-provider>-->
      <!--          </div>-->
      <!--          <div class="flex-grow-1 mr-50">-->
      <!--            &lt;!&ndash; Payment schedule - term &ndash;&gt;-->
      <!--            <validation-provider-->
      <!--              #default="{ errors }"-->
      <!--              :name="$t('Term')"-->
      <!--              rules="required"-->
      <!--            >-->
      <!--              <b-form-group class="pb-25">-->
      <!--                <flat-pickr-->
      <!--                  v-model="reservation.date"-->
      <!--                  :placeholder="$t('Term')"-->
      <!--                  class="form-control"-->
      <!--                  :config="{ locale: locale, altFormat: 'd-m-Y', altInput: true }"-->
      <!--                />-->
      <!--                <small class="text-danger">{{ errors[0] }}</small>-->
      <!--              </b-form-group>-->
      <!--            </validation-provider>-->
      <!--          </div>-->
      <!--          <div class="flex-grow-1 mr-50">-->
      <!--            &lt;!&ndash; Payment schedule - percent &ndash;&gt;-->
      <!--            <validation-provider-->
      <!--              #default="{ errors }"-->
      <!--              :name="$t('PercentValue')"-->
      <!--              rules="required"-->
      <!--            >-->
      <!--              <b-form-group class="pb-25">-->
      <!--                <b-input-group-->
      <!--                  append="%"-->
      <!--                  class="input-group-merge"-->
      <!--                >-->
      <!--                  <b-form-input-->
      <!--                    v-model="reservation.value"-->
      <!--                    max="100"-->
      <!--                    type="number"-->
      <!--                    @blur="reservation.price = (totalGross * ($event.target.value / 100)).toFixed(2)"-->
      <!--                  />-->
      <!--                </b-input-group>-->
      <!--                <small class="text-danger">{{ errors[0] }}</small>-->
      <!--              </b-form-group>-->
      <!--            </validation-provider>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </component>-->

      <!--      <component-->
      <!--        :is="tag"-->
      <!--        v-if="agreement.agreementTemplate && agreement.agreementTemplate.paymentScheduleToPay"-->
      <!--        class="mt-1"-->
      <!--      >-->
      <!--        <div class="d-flex justify-content-between align-items-center pr-50">-->
      <!--          <h4>{{ $t('Costs.AmountToPay') }}</h4>-->
      <!--        </div>-->
      <!--        <div class="d-flex justify-content-between">-->
      <!--          <div class="flex-grow-1 mr-50">-->
      <!--            &lt;!&ndash; Payment schedule - price &ndash;&gt;-->
      <!--            <validation-provider-->
      <!--              :name="$t('Offer.Price')"-->
      <!--              rules="required"-->
      <!--            >-->
      <!--              <b-form-group class="pb-25">-->
      <!--                <cleave-->
      <!--                  :value="restToPay"-->
      <!--                  :options="cleaveOptions.number"-->
      <!--                  class="form-control"-->
      <!--                  disabled-->
      <!--                />-->
      <!--              </b-form-group>-->
      <!--            </validation-provider>-->
      <!--          </div>-->
      <!--          <div class="flex-grow-1 mr-50">-->
      <!--            &lt;!&ndash; Payment schedule - term &ndash;&gt;-->
      <!--            <validation-provider-->
      <!--              #default="{ errors }"-->
      <!--              :name="$t('Term')"-->
      <!--              rules="required"-->
      <!--            >-->
      <!--              <b-form-group class="pb-25">-->
      <!--                <flat-pickr-->
      <!--                  v-model="totalPaymentDate"-->
      <!--                  :placeholder="$t('Term')"-->
      <!--                  class="form-control"-->
      <!--                  :config="{ locale: locale, altFormat: 'd-m-Y', altInput: true }"-->
      <!--                />-->
      <!--                <small class="text-danger">{{ errors[0] }}</small>-->
      <!--              </b-form-group>-->
      <!--            </validation-provider>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </component>-->

      <!-- Payment Schedule -->
      <!--      <div-->
      <!--        v-if="agreementTemplate && agreementTemplate.paymentSchedule"-->
      <!--        class="mt-1"-->
      <!--      >-->
      <!--        <component-->
      <!--          :is="tag"-->
      <!--          v-for="(paymentScheduleItem, index) in paymentSchedules"-->
      <!--          :key="`payment_schedule_${index}`"-->
      <!--        >-->
      <!--          <div class="d-flex justify-content-between align-items-center pr-50">-->
      <!--            <h4>{{ $t('Tranche') }}: {{ index + 1 }}</h4>-->
      <!--            <b-badge :variant="leftToAssign.value < 0 ? 'light-danger' : 'light-primary'">-->
      <!--              {{ leftToAssign.label }}-->
      <!--            </b-badge>-->
      <!--          </div>-->
      <!--          <div class="d-flex justify-content-between">-->
      <!--            <div class="flex-grow-1 mr-50">-->
      <!--              &lt;!&ndash; Payment schedule - price &ndash;&gt;-->
      <!--              <validation-provider-->
      <!--                #default="{ errors }"-->
      <!--                :name="`${index + 1}.${$t('Offer.Price')}`"-->
      <!--                rules="required"-->
      <!--              >-->
      <!--                <b-form-group class="pb-25">-->
      <!--                  <cleave-->
      <!--                    v-model="paymentScheduleItem.price"-->
      <!--                    :options="cleaveOptions.number"-->
      <!--                    class="form-control"-->
      <!--                    @input="paymentScheduleItem.value = (($event * 100) / totalGross).toFixed(2)"-->
      <!--                  />-->
      <!--                  <small class="text-danger">{{ errors[0] }}</small>-->
      <!--                </b-form-group>-->
      <!--              </validation-provider>-->
      <!--            </div>-->
      <!--            <div class="flex-grow-1 mr-50">-->
      <!--              &lt;!&ndash; Payment schedule - term &ndash;&gt;-->
      <!--              <validation-provider-->
      <!--                #default="{ errors }"-->
      <!--                :name="`${index + 1}.${$t('Term')}`"-->
      <!--                rules="required"-->
      <!--              >-->
      <!--                <b-form-group class="pb-25">-->
      <!--                  <flat-pickr-->
      <!--                    v-model="paymentScheduleItem.date"-->
      <!--                    :placeholder="$t('Term')"-->
      <!--                    class="form-control"-->
      <!--                    :config="{ locale: locale, altFormat: 'd-m-Y', altInput: true }"-->
      <!--                  />-->
      <!--                  <small class="text-danger">{{ errors[0] }}</small>-->
      <!--                </b-form-group>-->
      <!--              </validation-provider>-->
      <!--            </div>-->
      <!--            <div class="flex-grow-1 mr-50">-->
      <!--              &lt;!&ndash; Payment schedule - percent &ndash;&gt;-->
      <!--              <validation-provider-->
      <!--                #default="{ errors }"-->
      <!--                :name="`${index + 1}.${$t('PercentValue')}`"-->
      <!--                rules="required"-->
      <!--              >-->
      <!--                <b-form-group class="pb-25">-->
      <!--                  <b-input-group-->
      <!--                    append="%"-->
      <!--                    class="input-group-merge"-->
      <!--                  >-->
      <!--                    <b-form-input-->
      <!--                      v-model="paymentScheduleItem.value"-->
      <!--                      max="100"-->
      <!--                      type="number"-->
      <!--                      @input="paymentScheduleItem.price = (totalGross * ($event / 100)).toFixed(2)"-->
      <!--                    />-->
      <!--                  </b-input-group>-->
      <!--                  <small class="text-danger">{{ errors[0] }}</small>-->
      <!--                </b-form-group>-->
      <!--              </validation-provider>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </component>-->
      <!--      </div>-->

      <div v-if="!hideFooter">
        <b-button
          :disabled="!agreement.agreementTemplate"
          variant="primary"
          size="sm"
          class="mt-50"
          @click="saveProcess(null, true)"
        >
          {{ $t('Save') }}
        </b-button>
        <b-button
          variant="secondary"
          size="sm"
          class="ml-50 mt-50"
          @click="$emit('close-modal')"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>
    </validation-observer>
  </validation-observer>
</template>
<script>
import {
  CREATE_POLL,
  GET_CONTRACTS_TEMPLATES,
  GET_POLLS,
  GET_PRIVATE_SETTINGS,
  SAVE_OFFER_AGREEMENT,
  SAVE_POLL_OPTION,
} from '@/@constants/mutations'
import vSelect from 'vue-select'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import NewPollModal from '@/views/modals/NewPollModal.vue'
import { BAlert, VBTooltip } from 'bootstrap-vue'
import moment from 'moment'
import flatPickr from 'vue-flatpickr-component'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import ContractorRequirements from '@/views/core/singleContact/tabs/components/ContractorRequirements.vue'
import axiosIns from '@/libs/axios'
import Cleave from 'vue-cleave-component'

export default {
  name: 'NewContractModal',
  components: {
    ContractorRequirements,
    NewPollModal,
    vSelect,
    AppCollapse,
    BAlert,
    flatPickr,
    ValidationObserver,
    ValidationProvider,
    Cleave,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    tag: {
      default: 'div',
      type: String,
    },
    paymentSchedule: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: true,
    },
    showTop: {
      type: Boolean,
      required: false,
      default: true,
    },
    totalGross: Number,
    offerId: {
      type: String,
      default: null,
    },
    thread: {
      type: Object,
      default: null,
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
    cart: {
      type: [Object, String],
      default: null,
    },
  },
  data: () => ({
    agreement: {
      agreementTemplate: '',
      allowElectronicSignature: false,
      anonymised: false,
      signerUser: '',
      contactThreadCartDraftAgreementAssignedContacts: [],
    },

    required,
    contractType: '',
    cleaveOptions: {
      number: {
        delimiter: ' ',
        numeral: true,
        blocks: [3, 3, 3],
      },
    },
    reservation: {
      date: '',
      value: '',
      price: '',
    },
    paymentSchedules: [],
    contactPolls: [],
    contracts: [],
    requiredPolls: [],
    contractTypes: [],
    totalPaymentDate: '',
    autentiEnabled: false,
  }),
  computed: {
    isCartView() {
      return this.$route.name === 'checkout'
    },
    locale() {
      return this.$i18n.locale
    },
  },
  watch: {
    thread: {
      deep: true,
      handler() {
        this.getContactPolls()
      },
    },
    cart: {
      deep: true,
      handler() {
        this.getContactPolls()
      },
    },
  },
  mounted() {
    this.clear()
    this.initProcess()
    this.loadTypes()
    this.loadSystemSettings()
  },
  methods: {
    changeSettings() {
      if (!this.isCartView) return

      this.$set(this.cart.agreements)
    },
    loadSystemSettings() {
      this.$store.dispatch(`system/${GET_PRIVATE_SETTINGS}`)
        .then(res => {
          this.$set(this, 'autentiEnabled', res.data.item.autentiEnabled)

          this.autentiEnabled = res.data.item.autentiEnabled
          this.loading = false
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          this.loading = false
        })
    },
    clear() {
      this.paymentSchedules = []
      this.contactPolls = []
      this.contracts = []
      this.requiredPolls = []
      this.contractTypes = []
    },
    selectContractTypes(value) {
      if (!value) {
        this.getRequiredPolls(null)
        this.agreementTemplate = ''
      } else {
        this.getContractTemplates()
      }
    },
    // calculateTotalGrossPercent(percent) {
    //   return ((this.totalGross * (percent / 100)) ?? 0).toFixed(2)
    // },
    initProcess() {
      if (this.checkRequiredModule('pollModule')) {
        this.getContactPolls()
      }
      // this.getContractTemplates()
      // this.calculatePrices()
    },
    // calculatePrices() {
    //   this.$nextTick(() => {
    //     if (this.paymentSchedule?.length) {
    //       // eslint-disable-next-line no-unused-vars
    //       const overDuePayments = []
    //       const payments = []
    //       this.paymentSchedule.forEach(({ date, value }) => {
    //         const isOverdue = moment().diff(moment(date, 'YYYY-MM-DD'), 'days')
    //         if (isOverdue > 0) {
    //           overDuePayments.push(value)
    //         } else {
    //           payments.push({ date, value })
    //         }
    //       })
    //
    //       const totalOverdueValue = overDuePayments.reduce((a, b) => +a + +b, 0)
    //       if (payments.length) {
    //         payments.forEach((payment, index) => {
    //           payments[index].value = Number(payments[index].value) + Number(totalOverdueValue)
    //           payments[index].price = this.calculateTotalGrossPercent(Number(payments[index].value))
    //         })
    //       } else {
    //         payments.push({ date: moment().format('YYYY-MM-DD'), price: this.calculateTotalGrossPercent(totalOverdueValue), value: totalOverdueValue })
    //       }
    //
    //       this.$set(this, 'paymentSchedules', payments)
    //     }
    //   })
    // },
    async loadTypes() {
      try {
        const resp = await axiosIns.get('1/settings/agreementTypes', { params: { fields_load: this.$fields.SETTINGS_AGREEMENTS_TYPES } })
        this.contractTypes = resp.data.data?.items || []
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    async validatePolls() {
      const polls = []
      // eslint-disable-next-line
      for (const requiredPoll of this.requiredPolls) {
        const ref = `required_poll_${requiredPoll.id}`
        // eslint-disable-next-line no-await-in-loop
        const response = this.$refs[ref][0].savePoll()
        if (response) {
          const { fields, poll } = response
          polls.push({ fields, poll })
        }
      }

      if (polls.length === this.requiredPolls.length) {
        return polls
      }

      this.showToast('error', this.$i18n.t('SomeFieldsAreNotFilled'))
      return false
    },
    // async validatePayments() {
    //   return new Promise(resolve => {
    //     this.$nextTick(async () => {
    //       if (this.agreementTemplate?.paymentSchedule || this.agreementTemplate?.advancePayment) {
    //         console.log(this.$refs['payment-schedule-validator'])
    //         const isValid = await this.$refs['payment-schedule-validator'].validate()
    //         if (!isValid) resolve(false)
    //       }
    //
    //       resolve(true)
    //     })
    //   })
    // },
    // async validateRequirements() {
    //   // eslint-disable-next-line no-return-await
    //   return await this.$refs['contractor-requirements'].validateFields()
    // },
    // eslint-disable-next-line no-unused-vars
    async saveProcess(offerId = null, saveContract = true) {
      const isValid = await this.$refs.kurczaki.validate()
      if (isValid) {
        if (this.checkRequiredModule('pollModule')) {
          const polls = await this.validatePolls()
          if (polls) {
            const isOk = await Promise.all([...polls.map(poll => this.saveItem(poll)), this.$refs['contractor-requirements'].save()])
              .then(() => {
                this.showToast('success', this.$i18n.t('PollsAndContactsUpdated'))
                if (saveContract) this.saveContract(offerId)
                return true
              })
              .catch(err => {
                this.$emit('close-modal', true)
                this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
                return false
              })

            return isOk
          }
        } else if (saveContract) {
          this.saveContract(offerId)
        } else {
          return true
        }
      } else {
        this.showToast('error', this.$i18n.t('AllFieldsAreRequired'))
        return false
      }

      return false
    },
    saveContract(offerId = null) {
      if (this.agreementTemplate) {
        const payload = {
          contactId: this.thread.contact.id,
          threadId: this.thread.id,
          offerId: offerId ?? this.offerId,
          agreementId: typeof this.agreement.agreementTemplate === 'string' ? this.agreement.agreementTemplate : this.agreement.agreementTemplate?.id,
          signerUser: typeof this.signerUser === 'string' ? this.signerUser : this.signerUser?.id,
          anonymised: this.agreement.anonymised,
          allowElectronicSignature: this.agreement.allowElectronicSignature,
          contactThreadAgreementPaymentScheduleItems: [],
          agreementTemplate: (typeof this.agreement.agreementTemplate === 'string' ? this.agreement.agreementTemplate : this.agreement.agreementTemplate?.id),
        }

        let totalPrice = this.totalGross

        if (this.agreement.agreementTemplate?.paymentSchedule) {
          this.paymentSchedules.forEach(({ price, date }) => {
            payload.contactThreadAgreementPaymentScheduleItems.push({ value: parseFloat(price), date, type: 'PS' })
            totalPrice -= parseFloat(price)
          })
        }

        if (this.agreement.agreementTemplate?.advancePayment) {
          const { price, date } = this.reservation
          payload.contactThreadAgreementPaymentScheduleItems.push({ value: parseFloat(price), date, type: 'R' })
          totalPrice -= parseFloat(price)
        }

        payload.contactThreadAgreementPaymentScheduleItems.push({ value: totalPrice, date: this.totalPaymentDate ? this.totalPaymentDate : moment().add(7, 'days'), type: 'T' })

        this.$store.dispatch(`offer/${SAVE_OFFER_AGREEMENT}`, payload)
          .then(() => {
            this.showToast('success', this.$i18n.t('OfferWasUpdated'))
            this.$emit('close-modal', true)
            this.$nextTick(() => {
              this.$set(this.$store.state.cart, 'files', [])
            })
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          })
      }
    },
    // POLL UPDATES
    saveItem({ fields, poll }) {
      return new Promise((resolve, reject) => {
        const { contact, id } = this.thread
        const payload = {
          contactId: contact.id, threadId: id, pollId: typeof poll === 'string' ? poll : poll?.id,
        }
        if (!poll?.exist) {
          this.$store.dispatch(`singleContact/${CREATE_POLL}`, payload)
            .then(res => {
              const threadPollId = res.data.items[0]?.id
              if (threadPollId) {
                this.saveFields(threadPollId, fields)
                  .then(() => {
                    resolve()
                  })
                  .catch(() => {
                    reject()
                  })
              }
            })
            .catch(err => {
              this.$emit('close-modal', true)
              this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
              reject()
            })
        } else {
          this.saveFields(payload.pollId, fields)
            .then(() => {
              resolve()
            })
            .catch(() => {
              reject()
            })
        }
      })
    },
    saveFields(pollId, fields = []) {
      return new Promise((resolve, reject) => {
        const { contact, id } = this.thread
        const payload = {
          contactId: contact.id, threadId: id, pollId, options: fields,
        }
        if (fields.length) {
          this.$store.dispatch(`singleContact/${SAVE_POLL_OPTION}`, payload)
            .then(() => {
              resolve()
            })
            .catch(err => {
              this.$emit('close-modal', true)
              this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
              reject()
            })
        } else resolve()
      })
    },
    // POLL UPDATES
    getContractTemplates() {
      this.$nextTick(() => {
        this.$store.dispatch(`contractTemplates/${GET_CONTRACTS_TEMPLATES}`, { filters: { type: typeof this.contractType === 'string' ? this.contractType : this.contractType?.id } })
          .then(res => {
            this.contracts = res.data.items
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    getRequiredPolls(poll = []) {
      if (poll && this.checkRequiredModule('pollModule')) {
        // eslint-disable-next-line no-underscore-dangle
        const _poll = [poll]

        let assignedPolls = []
        if (Array.isArray(_poll)) {
          _poll.forEach(pollItem => pollItem.assignedPolls.forEach(({ id }) => { assignedPolls.push(id) }))
          assignedPolls = [...new Set(assignedPolls)]
        } else assignedPolls = assignedPolls.map(({ id }) => id)

        if (assignedPolls && assignedPolls.length) {
          if (this.checkRequiredModule('pollModule')) {
            this.$store.dispatch(`polls/${GET_POLLS}`, { filters: { polls: assignedPolls } })
              .then(res => {
                this.requiredPolls = res.data.items
              })
              .catch(err => {
                this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
              })
          }
        }
      } else {
        this.requiredPolls = []
      }
      // this.calculatePrices()
    },
    getContactPolls() {
      this.$nextTick(() => {
        if (this.checkRequiredModule('pollModule')) {
          this.loading = true
          this.$nextTick(() => {
            const { contact, id } = this.thread
            const payload = {
              contactId: contact.id, threadId: id,
            }
            if (this.checkRequiredModule('pollModule')) {
              this.$store.dispatch(`singleContact/${GET_POLLS}`, payload)
                .then(res => {
                  this.contactPolls = res.data.items
                  this.loading = false
                })
                .catch(err => {
                  this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
                  this.loading = false
                })
            }
          })
        }
      })
    },
    getContactEditedPoll(pollToSearch) {
      const findItem = this.contactPolls.find(({ poll }) => poll.id === pollToSearch.id)
      return findItem || null
    },
    async openAgreementPreview() {
      this.showAlert('warning', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('ContinuingWillSaveTheNecessaryFields'))
        .then(async () => {
          const isOk = await this.saveProcess(null, false)
          if (isOk) {
            this.$nextTick(() => {
              const templateId = typeof this.agreement.agreementTemplate === 'string' ? this.agreement.agreementTemplate : this.agreement.agreementTemplate?.id
              const payload = {
                agreementTemplate: templateId,
                anonymised: this.agreement.anonymised,
                allowElectronicSignature: this.agreement.allowElectronicSignature,
                contactThreadCart: this.cart,
                contactThreadOffer: this.offerId,
                signerUser: typeof this.agreement.signerUser === 'string' ? this.agreement.signerUser : this.agreement.signerUser?.id,
                contactThreadAgreementPaymentScheduleItems: [],
              }

              let totalPrice = this.totalGross

              if (this.agreement.agreementTemplate?.paymentSchedule) {
                this.paymentSchedules.forEach(({ price, date }) => {
                  payload.contactThreadAgreementPaymentScheduleItems.push({ value: parseFloat(price), date, type: 'PS' })
                  totalPrice -= parseFloat(price)
                })
              }

              if (this.agreement.agreementTemplate?.advancePayment) {
                const { price, date } = this.reservation
                payload.contactThreadAgreementPaymentScheduleItems.push({ value: parseFloat(price), date, type: 'R' })
                totalPrice -= parseFloat(price)
              }

              payload.contactThreadAgreementPaymentScheduleItems.push({ value: totalPrice, date: this.totalPaymentDate ? this.totalPaymentDate : moment().add(7, 'days'), type: 'T' })

              axiosIns.post(`1/contacts/${this.thread.contact?.id}/threads/${this.thread?.id}/offers/agreements/getPdf`, payload)
                .then(res => {
                  const a = document.createElement('a')
                  a.href = `data:application/pdf;base64,${res.data.data?.file}`
                  a.setAttribute('target', '_blank')
                  a.download = this.$i18n.t('Preview')
                  a.click()
                })
                .catch(err => {
                  this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
                })
            })
          }
        })
    },
  },
}
</script>

<style lang="scss">
  .new-contract-modal {
    .shadow-none {
      box-shadow: unset !important;
      * {
        box-shadow: unset !important;
      }
    }
  }
</style>
