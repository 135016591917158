<template>
  <div class="row mb-50">
    <ul class="col-md-6 mb-0">
      <!--    START::Phone    -->
      <li class="d-flex flex-wrap flex-md-row align-items-center">
        <strong>
          <feather-icon
            icon="PhoneIcon"
            class="mr-75"
          />
          <span class="font-weight-bold">{{ $t('Phone') }}</span>
        </strong>

        <a
          v-if="organization.phone"
          :href="`tel:${organization.phone}`"
          class="py-50"
        >
          {{ organization.phone | phoneFormat }}
        </a>

        <feather-icon
          v-else
          icon="MinusIcon"
        />
      </li>
      <!--    END::Phone    -->

      <!--    START::VatPayer    -->
      <li
        class="d-flex flex-wrap flex-md-row align-items-center"
      >
        <strong>
          <feather-icon
            icon="StarIcon"
            class="mr-75"
          />
          <span class="font-weight-bold">{{ $t('VatPayer') }}</span>
        </strong>

        <b-alert
          show
          :variant="organization.vatPayer ? 'success' : 'danger'"
          class="text-uppercase py-25 px-50 m-0 my-25 font-small-2"
        >
          {{ $t(organization.vatPayer ? 'Yes' : 'NoNo') }}
        </b-alert>
      </li>
      <!--    END::VatPayer    -->

      <!--    START::numberOfRelatedContacts    -->
      <li class="d-flex flex-wrap flex-md-row align-items-center">
        <strong>
          <feather-icon
            icon="StarIcon"
            class="mr-75"
          />
          <span class="font-weight-bold">{{ $t('numberOfRelatedContacts') }}</span>
        </strong>

        <b-alert
          show
          variant="primary"
          class="text-capitalize py-25 px-50 m-0 my-25 font-small-2"
        >
          {{ organization.allAssignedContacts || 0 }}
        </b-alert>
      </li>
      <!--    END::numberOfRelatedContacts    -->

      <!--    START::numberOfRelatedContacts    -->
      <li class="d-flex flex-wrap flex-md-row align-items-center">
        <strong>
          <feather-icon
            icon="StarIcon"
            class="mr-75"
          />
          <span class="font-weight-bold">{{ $t('totalNumberOfThreads') }}</span>
        </strong>

        <b-alert
          show
          variant="primary"
          class="text-capitalize py-25 px-50 m-0 my-25 font-small-2"
        >
          {{ organization.allContactThreads || 0 }}
        </b-alert>
      </li>
      <!--    END::numberOfRelatedContacts    -->
    </ul>

    <ul class="col-md-6 mb-0">
      <!--    START::Created At    -->
      <li
        v-if="organization.createdAt"
        class="d-flex flex-wrap flex-md-row align-items-center"
      >
        <strong>
          <feather-icon
            icon="StarIcon"
            class="mr-75"
          />
          <span class="font-weight-bold">{{ $t('CreatedAt') }}</span>
        </strong>

        <p class="text-capitalize">
          {{ organization.createdAt.date.split(' ')[0] }}
        </p>
      </li>
      <!--    END::Created At    -->

      <!--    START::assignedUsers    -->
      <li
        class="d-flex flex-wrap flex-md-row align-items-center mt-50"
      >
        <strong>
          <feather-icon
            icon="UserPlusIcon"
            class="mr-75"
          />
          <span class="font-weight-bold">{{ $t('AssignedUsers') }}</span>
        </strong>

        <avatar-group
          v-if="organization.assignedContactsUsers"
          :items="organization.assignedContactsUsers"
        />
        <feather-icon
          v-else
          icon="MinusIcon"
        />
      </li>
      <!--    END::assignedUsers    -->

      <!--    START::Created By    -->
      <li
        v-if="organization.createdBy"
        class="d-flex flex-wrap flex-md-row align-items-center mt-50"
      >
        <strong>
          <feather-icon
            icon="UserIcon"
            class="mr-75"
          />
          <span class="font-weight-bold">{{ $t('CreatedBy') }}</span>
        </strong>
        <avatar
          v-if="organization.createdBy"
          size="30"
          :user="organization.createdBy"
          :show-name="false"
          show-tooltip
        />
        <feather-icon
          v-else
          icon="MinusIcon"
        />
      </li>
      <li class="d-flex flex-wrap flex-md-row align-items-center mt-50">
        <strong>
          <feather-icon
            icon="GlobeIcon"
            class="mr-75"
          />
          <span class="font-weight-bold">{{ $t('ParentOrganization') }}</span>
        </strong>
        <span
          v-if="organization.parentOrganization && organization.parentOrganization.name"
          v-b-tooltip
          :title="organization.parentOrganization.name"
        >
          {{ organization.parentOrganization.name | truncate(30) }}
        </span>
        <feather-icon
          v-else
          icon="MinusIcon"
        />
      </li>
      <!--    END::Created By    -->
    </ul>
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: ['organization'],
  computed: {
    ...mapGetters({
      organizationStatuses: 'typeSettings/geContactStatuses',
    }),
    phoneNumber() {
      return this.organization.phone || this.organization.phoneNumber?.phone || this.organization.phoneNumbers[0]?.phone || ''
    },
  },
  methods: {
    tooltipContent(title, date) {
      return `
        <span>${title}</span>
        <small>${this.$i18n.t('From').toLowerCase()}: ${date.date.split('.')[0]}</small>
      `
    },
  },
}
</script>

<style scoped>
li {

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

p {
    margin-bottom: 0;

    padding: .5rem 0;
}
</style>
