<template>
  <div
    style="height: 84%; min-height: 140px; cursor: pointer;"
    @click="addItem()"
  >
    <b-alert
      variant="primary"
      show
      class="d-flex align-items-center justify-content-center h-100"
      style="gap: .14rem"
    >
      <span style="font-size: 1.6rem">
        {{ $t('Add') }}
      </span>

      <sw-icon
        icon="PlusIcon"
        size="34"
      />
    </b-alert>
  </div>
</template>

<script>
import { ADD_MODAL, SHOW_MODAL } from '@/@constants/mutations'

export default {
  data: () => ({
    mNew: '',
  }),
  mounted() {
    this.createModals()
  },
  methods: {
    createModals() {
      this.$store.dispatch(`swModal/${ADD_MODAL}`, {
        title: this.$i18n.t('ChatWidget'),
        component: 'm-new-chat-widget',
        size: 'xl',
        hideFooter: true,
      }).then(id => this.$set(this, 'mNew', id))
    },

    addItem() {
      const { mNew } = this

      this.$store.dispatch(`swModal/${SHOW_MODAL}`, mNew)
    },
  },
}
</script>
