<template>
  <div>
    <b-button
      variant="primary"
      size="sm"
      class="mb-1"
      @click="onSaveSettings"
    >
      {{ $t('Save') }}
    </b-button>

    <b-row v-if="system">
      <b-col sm="12">
        <b-card>
          <b-form-group :label="$t('Name')">
            <b-form-input v-model="system.mobileName" />
          </b-form-group>
        </b-card>
      </b-col>
      <b-col
        sm="12"
        md="4"
      >
        <b-card class="text-center">
          <sw-form-input-image
            v-model="system.mobileLogo"
            :label="$t('Logo')"
            :info="$tc('file.Max', 1, { size: 100 })"
            fluid-x
            max-size="100"
            :clearable="false"
            @input="e => onChange(e.fr, 'mobileLogo', 100)"
          />
        </b-card>
      </b-col>

      <b-col
        sm="12"
        md="8"
      >
        <b-card>
          <b-row style="row-gap: .5rem">
            <b-col
              v-for="color in Object.keys(colors)"
              :key="color"
              sm="12"
              md="6"
              lg="4"
            >
              <sw-form-input-color
                :value="getFormatedColor(colors[color])"
                :default-color="getFormatedColor(defaultColors[color])"
                :label="$t('Styles.' + color.ucFirst())"
                fluid-x
                @input="onChangeColor($event.hex, color)"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-overlay
      :show="loading"
      no-wrap
      spinner-variant="primary"
    />

    <b-button
      variant="primary"
      size="sm"
      @click="onSaveSettings"
    >
      {{ $t('Save') }}
    </b-button>
  </div>
</template>

<script>
import { GET_PRIVATE_SETTINGS, SAVE_SETTINGS } from '@/@constants/mutations'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'

const defaultColors = {
  primary: '115, 103, 240',
  secondary: '130, 134, 139',
  info: '0, 207, 232',
  danger: '234, 84, 85',
  warning: '255, 159, 67',
  success: '40, 199, 111',
}
export default {
  name: 'MobileApp',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
  },
  data() {
    return {
      system: null,

      defaultColors,
      colors: { ...defaultColors },

      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      coreSystemConfig: 'system/getSettings',
    }),
  },
  mounted() {
    this.loadData()
  },
  methods: {
    getFormatedColor(color) {
      // Color format  0, 0, 0 -> #000000
      const colors = color.split(', ')
      const r = this.rgbToHex(colors[0])
      const g = this.rgbToHex(colors[1])
      const b = this.rgbToHex(colors[2])
      return `#${r}${g}${b}`
    },
    rgbToHex(c) {
      // eslint-disable-next-line radix
      const hex = parseInt(c).toString(16)
      return hex.length === 1 ? `0${hex}` : hex
    },
    hexToRgb(hex) {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
      return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      } : null
    },
    onChangeColor(color, colorKey) {
      // Color format #000000 -> 0, 0, 0
      const rgb = this.hexToRgb(color)
      const formatedColor = `${rgb.r}, ${rgb.g}, ${rgb.b}`

      this.$set(this.colors, colorKey, formatedColor)
    },
    onTargetInput(ref) {
      this.$refs[ref].click()
    },
    onChange(event, option, maxSize) {
      const fr = new FileReader()
      if (event.target.files[0].size > maxSize * 1000) {
        this.showToast('danger', this.$i18n.tc('MoreThanKB', 1, { type: this.$i18n.t(`SystemMoreThan.${option}`), size: maxSize }))
        return
      }
      fr.onload = () => {
        this.system[option] = fr.result
      }
      fr.readAsDataURL(event.target.files[0])
    },

    loadData() {
      this.loading = true
      this.$store.dispatch(`system/${GET_PRIVATE_SETTINGS}`)
        .then(res => {
          const def = { ...this.defaultColors }
          this.system = { ...res.data.item, mobileLogo: res.data.item.mobileLogo || this.coreSystemConfig.logo }
          this.colors = Object.assign(def, res.data.item.mobileStyles?.colors) || { ...this.defaultColors }
          this.loading = false
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          this.loading = false
        })
    },
    onSaveSettings() {
      this.loading = true
      const payload = {
        mobileName: this.system.mobileName,
        mobileLogo: this.system.mobileLogo,
        mobileStyles: {
          colors: this.colors,
        },
      }

      // eslint-disable-next-line no-unreachable
      this.$store.dispatch(`system/${SAVE_SETTINGS}`, { privateData: payload })
        .then(() => {
          this.loading = false
          this.showToast('success', this.$i18n.t('ChangesSaved'))
          this.loadData()
        })
        .catch(err => {
          this.loading = false
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
  },
}
</script>

<style scoped>

</style>
