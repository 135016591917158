import {
  ADD_PAYMENT, ADD_PAYMENT_COLLECT, GET_PAYMENTS, GET_PAYMENTS_STATS,
} from '@/@constants/mutations'
// eslint-disable-next-line import/no-cycle
import axiosIns from '@/libs/axios'
import * as fields from '@/@constants/fields'
import moment from 'moment'

const resolveFilters = filters => {
  const params = {}

  if (filters?.status) {
    if (filters.status === 'NEW') {
      params.eq_status = filters.status
      params.gte_deadlineDate = `${(moment()).format('YYYY-MM-DD')} 00:00:00`
    } else if (filters.status === 'PARTLY_NO_COLLECTED') {
      params.eq_status = 'PARTLY_COLLECTED'
      params.lt_deadlineDate = `${(moment()).format('YYYY-MM-DD')} 00:00:00`
    } else if (filters.status === 'PARTLY_COLLECTED') {
      params.eq_status = 'PARTLY_COLLECTED'
      params.gte_deadlineDate = `${(moment()).format('YYYY-MM-DD')} 00:00:00`
    } else if (filters.status === 'NO_COLLECTED') {
      params.eq_status = 'NEW'
      params.lt_deadlineDate = `${(moment()).format('YYYY-MM-DD')} 00:00:00`
    } else if (filters.status === 'COLLECTED') {
      params.eq_status = 'COLLECTED'
    }
  }

  if (filters?.type) {
    params.eq_type = filters.type
  }

  if (Array.isArray(filters?.assignedUsers) && filters?.assignedUsers.length) {
    params['eqArr_contactThread-assignedUsers-id'] = JSON.stringify(filters.assignedUsers.map(u => (typeof u === 'string' ? u : u?.id)))
  }

  if (filters?.contacts && filters.contacts.length) {
    params['eqArr_contactThread-contact-id'] = JSON.stringify(filters.contacts)
  }

  if (filters?.funnelPoint) {
    params['eq_contactThread-contactThreadFunnelProcesses-contactThreadFunnelTypePoint-id'] = filters.funnelPoint
  }

  if (filters?.funnel) {
    params['eq_contactThread-contactThreadFunnelProcesses-contactThreadFunnelTypePoint-contactThreadFunnelType-id'] = filters.funnel
  }

  if (filters?.thread) {
    params['eq_contactThread-id'] = filters.thread
  }

  if (filters?.paymentMethod) {
    params['eq_contactThreadCartOrder-contactThreadCartBox-paymentType'] = filters.paymentMethod
  }

  if (filters?.createdAt) {
    const value = filters.createdAt.dateRange()
    if (value[0]) {
      // eslint-disable-next-line prefer-destructuring
      params['gte_contactThreadAgreement-createdAt'] = `${moment(value[0], 'YYYY-MM-DD').format('YYYY-MM-DD')} 00:00:00`
    }
    if (value[1]) {
      // eslint-disable-next-line prefer-destructuring
      params['lte_contactThreadAgreement-createdAt'] = `${moment(value[1], 'YYYY-MM-DD').format('YYYY-MM-DD')} 23:59:59`
    }
  }

  if (filters?.orderDate) {
    const value = filters.orderDate.dateRange()
    if (value[0]) {
      // eslint-disable-next-line prefer-destructuring
      params['gte_contactThreadCartOrder-orderDate'] = `${moment(value[0], 'YYYY-MM-DD').format('YYYY-MM-DD')} 00:00:00`
    }
    if (value[1]) {
      // eslint-disable-next-line prefer-destructuring
      params['lte_contactThreadCartOrder-orderDate'] = `${moment(value[1], 'YYYY-MM-DD').format('YYYY-MM-DD')} 23:59:59`
    }
  }

  if (filters?.orderCreatedAt) {
    const value = filters.orderCreatedAt.dateRange()
    if (value[0]) {
      // eslint-disable-next-line prefer-destructuring
      params['gte_contactThreadCartOrder-createdAt'] = `${moment(value[0], 'YYYY-MM-DD').format('YYYY-MM-DD')} 00:00:00`
    }
    if (value[1]) {
      // eslint-disable-next-line prefer-destructuring
      params['lte_contactThreadCartOrder-createdAt'] = `${moment(value[1], 'YYYY-MM-DD').format('YYYY-MM-DD')} 23:59:59`
    }
  }

  if (filters?.unpaid) {
    params.eqArr_status = JSON.stringify(['NEW', 'PARTLY_COLLECTED'])
  }

  if (filters?.unpaidAfterTerm) {
    // params.eqArr_status = JSON.stringify(['NO_COLLECTED', 'PARTLY_NO_COLLECTED'])
    params.lt_deadlineDate = new Date()
  }

  // if (filters?.unpaid && filters?.unpaidAfterTerm) {
  //   params.eqArr_status = JSON.stringify(['NO_COLLECTED', 'PARTLY_NO_COLLECTED', 'NEW', 'PARTLY_COLLECTED'])
  // }

  return params
}

export default {
  namespaced: true,
  actions: {
    // eslint-disable-next-line no-unused-vars
    [GET_PAYMENTS]: ({ commit }, {
      contactId = null, threadId = null, pagination, filters, searchQuery = '', sorter,
      // eslint-disable-next-line no-async-promise-executor
    }) => new Promise(async (resolve, reject) => {
      const params = {
        ...resolveFilters(filters),
        fields_load: fields.CONTACTS_THREADS_PAYMENTS,
        orderBy: 'createdAt.desc',
      }

      if (pagination) {
        params.page = pagination.currentPage
        params.limit = pagination.perPage
      }

      if (sorter) {
        const sortDir = sorter?.sortDesc ? 'desc' : 'asc'
        const sortBy = sorter?.sortBy || 'id'
        params.orderBy = `${sortBy}.${sortDir}`
      }

      if (searchQuery && searchQuery.length) {
        // eslint-disable-next-line no-underscore-dangle
        const _search = searchQuery.replaceAll(' ', '%')
        params.search = `%${_search}%`
        params.search_fields = [
          'contactThread-contact-firstName',
          'contactThread-contact-lastName',
          'contactThread-contact-mailAddress-mail',
          'contactThreadCartOrder-number',
        ].join(',')
      }

      const url = threadId && contactId ? `1/contacts/${contactId}/threads/${threadId}/payments` : '1/contacts/threads/payments'

      // eslint-disable-next-line no-unused-vars
      axiosIns.get(url, { params })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [GET_PAYMENTS_STATS]: ({ commit }, { contactId = null, threadId = null, filters = {} }) => new Promise((resolve, reject) => {
      const params = {}
      const url = threadId && contactId ? `1/contacts/${contactId}/threads/${threadId}/payments/getStatistics` : '1/contacts/threads/payments/getStatistics'
      axiosIns.get(url, { params })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [ADD_PAYMENT_COLLECT]: ({ commit }, { paymentId, payment }) => new Promise((resolve, reject) => {
      axiosIns.post(`1/contacts/threads/payments/${paymentId}/collects`, payment)
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    [ADD_PAYMENT]: (_, { payload, contact, thread }) => new Promise((resolve, reject) => {
      axiosIns.post(`1/contacts/${contact}/threads/${thread}/payments`, payload)
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
  },
}

// Test
