<template>
  <validation-observer
    ref="pieczarki"
    tag="div"
    class="new-contract-modal"
  >
    <component :is="tag">
      <transition name="fade">
        <div
          v-if="selectedContract"
          class="d-flex align-items-center flex-wrap mb-1"
        >
          <span class="font-weight-bolder pr-1">{{ $t('Preview') }} {{ $t('AgreementsCount').toLowerCase() }}</span>
          <b-button
            variant="outline-warning"
            size="sm"
            @click="openAgreementPreview"
          >
            <feather-icon icon="EyeIcon" />
          </b-button>
        </div>
      </transition>
      <sw-select :name="$t('Contracts.AgreementType')">
        <v-select
          v-model="contractType"
          :options="contractTypes.filter(e => e.agreementTemplates && e.agreementTemplates.length)"
          label="name"
          @input="selectContractTypes"
        >
          <template #no-options>
            {{ $t('NoOptions') }}
          </template>
        </v-select>
      </sw-select>
      <sw-select>
        <v-select
          v-model="selectedContract"
          :disabled="!contractType"
          :options="contracts"
          label="name"
          @input="getRequiredPolls"
        >
          <template #no-options>
            {{ $t('NoOptions') }}
          </template>
        </v-select>
      </sw-select>
      <div
        v-if="selectedContract"
        class="mb-1"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="$t('SignerUser')"
          rules="required"
        >
          <sw-select :name="$t('SignerUser')">
            <v-select
              v-model="signerUser"
              :options="selectedContract.signerUsers"
              style="min-width: 325px"
            >
              <template #no-options>
                {{ $t('NoOptions') }}
              </template>
              <template #option="option">
                <avatar :user="option" />
              </template>
              <template #selected-option="option">
                <avatar :user="option" />
              </template>
            </v-select>
          </sw-select>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>

        <b-form-group class="w-100">
          <b-form-checkbox
            v-model="autentiSigning"
            variant="primary"
          >
            {{ $t('TrainingFiles.AutentiSigning') }}
          </b-form-checkbox>
        </b-form-group>
      </div>
      <!-- Polls -->
      <div
        v-if="requiredPolls.length && checkRequiredModule('pollModule')"
        class="mb-1"
      >
        <b-alert
          show
          variant="primary"
          class="px-1 py-50"
        >
          <feather-icon icon="AlertCircleIcon" /> {{ $t('AgreementRequiresToCompletePolls') }}
        </b-alert>
        <app-collapse
          type="border"
          class="shadow-none"
        >
          <new-poll-modal
            v-for="poll in requiredPolls"
            :key="`required_poll_${poll.id}`"
            :ref="`required_poll_${poll.id}`"
            :title="poll.name"
            :tag="'app-collapse-item'"
            :default-poll="poll"
            :edited-poll="getContactEditedPoll(poll)"
            :are-fields-required="true"
          />
        </app-collapse>
      </div>
    </component>
    <component
      :is="tag"
      v-show="thread"
    >
      {{ $t('ContractorDataForContract') }}
      <contractor-requirements
        ref="contractor-requirements"
        :thread="thread || {}"
        :selected-agreement="selectedContract"
      />
    </component>
    <!-- Payment Schedule -->

    <validation-observer
      ref="payment-schedule-validator"
      tag="div"
    >
      <!-- Reservation -->
      <component
        :is="tag"
        v-if="selectedContract && selectedContract.paymentScheduleToPay"
        class="mt-1"
      >
        <div class="d-flex justify-content-between align-items-center pr-50">
          <h4>{{ $t('Costs.AmountToPay') }}</h4>
        </div>
        <div class="d-flex justify-content-between">
          <div class="flex-grow-1 mr-50">
            <!-- Payment schedule - price -->
            <validation-provider
              :name="$t('Offer.Price')"
              rules="required"
            >
              <b-form-group class="pb-25">
                <cleave
                  v-model="restToPay"
                  :options="cleaveOptions.number"
                  class="form-control"
                  @input="() => {
                    reservation.value = 0
                    reservation.price = 0
                  }"
                />
              </b-form-group>
            </validation-provider>
          </div>
          <div class="flex-grow-1 mr-50">
            <!-- Payment schedule - term -->
            <validation-provider
              v-slot="{ errors }"
              :name="$t('Term')"
              rules="required"
            >
              <b-form-group class="pb-25">
                <flat-pickr
                  v-model="totalPaymentDate"
                  :placeholder="$t('Term')"
                  class="form-control"
                  :config="{ locale: getCalendarLocale($i18n.locale), altFormat: 'd-m-Y', altInput: true }"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </div>
        </div>
      </component>

      <component
        :is="tag"
        v-if="selectedContract && selectedContract.advancePayment"
      >
        <div class="d-flex justify-content-between align-items-center pr-50">
          <h4>{{ $t('AdvancePayment') }}</h4>
        </div>
        <div class="d-flex justify-content-between">
          <div class="flex-grow-1 mr-50">
            <!-- Payment schedule - price -->
            <validation-provider
              v-slot="{ errors }"
              :name="$t('Offer.Price')"
              rules="required"
            >
              <b-form-group class="pb-25">
                <cleave
                  v-model="reservation.price"
                  :options="cleaveOptions.number"
                  class="form-control"
                  @input="reservation.value = (restToPay ? reservation.price * 100 / restToPay : 0).toFixed(2)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </div>
          <div class="flex-grow-1 mr-50">
            <!-- Payment schedule - term -->
            <validation-provider
              v-slot="{ errors }"
              :name="$t('Term')"
              rules="required"
            >
              <b-form-group class="pb-25">
                <flat-pickr
                  v-model="reservation.date"
                  :placeholder="$t('Term')"
                  class="form-control"
                  :config="{ locale: getCalendarLocale($i18n.locale), altFormat: 'd-m-Y', altInput: true }"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </div>
          <div class="flex-grow-1 mr-50">
            <!-- Payment schedule - percent -->
            <validation-provider
              v-slot="{ errors }"
              :name="$t('PercentValue')"
              rules="required"
            >
              <b-form-group class="pb-25">
                <b-input-group
                  append="%"
                  class="input-group-merge"
                >
                  <b-form-input
                    v-model="reservation.value"
                    max="100"
                    type="number"
                    @input="reservation.price = (reservation.value * restToPay / 100).toFixed(2)"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </div>
        </div>
      </component>

      <div>
        <b-button
          :disabled="!selectedContract"
          variant="primary"
          size="sm"
          class="mt-50"
          @click="saveProcess(null, true)"
        >
          {{ $t('Save') }}
        </b-button>
      </div>
    </validation-observer>
  </validation-observer>
</template>

<script>
import { required } from '@validations'
import ContractorRequirements from '@/views/core/singleContact/tabs/components/ContractorRequirements.vue'
import NewPollModal from '@/views/modals/NewPollModal.vue'
import vSelect from 'vue-select'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import { BAlert, VBTooltip } from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Cleave from 'vue-cleave-component'
import {
  CREATE_POLL,
  GET_CONTRACTS_TEMPLATES,
  GET_POLLS,
  GET_PRIVATE_SETTINGS,
  SAVE_CONTACT_OFFER_AGREEMENT,
  SAVE_POLL_OPTION,
} from '@/@constants/mutations'
import moment from 'moment'
import axiosIns from '@/libs/axios'
import { mapGetters } from 'vuex'

export default {
  components: {
    ContractorRequirements,
    NewPollModal,
    vSelect,
    AppCollapse,
    BAlert,
    flatPickr,
    ValidationObserver,
    ValidationProvider,
    Cleave,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    tag: {
      default: 'div',
      type: String,
    },
  },
  data: () => ({
    required,
    contractType: '',
    selectedContract: '',
    restToPay: 0,
    totalPaymentDate: '',
    reservation: {
      date: '',
      value: 0,
      price: 0,
    },
    cleaveOptions: {
      number: {
        delimiter: ' ',
        numeral: true,
        blocks: [3, 3, 3],
      },
    },
    paymentSchedules: [],
    contactPolls: [],
    contracts: [],
    requiredPolls: [],
    contractTypes: [],
    signerUser: '',
    anonymised: false,
    autentiEnabled: false,
    autentiSigning: false,
    thread: null,
    contact: null,
  }),
  computed: {
    ...mapGetters({
      modalConfig: 'modal/getModalState',
    }),
    locale() {
      return this.$i18n.locale
    },
  },
  watch: {
    thread: {
      deep: true,
      handler() {
        this.getContactPolls()
      },
    },
  },
  mounted() {
    const { thread, data } = this.modalConfig.data

    this.thread = thread
    this.contact = data

    this.thread.contact = this.contact

    this.clear()
    this.initProcess()
    this.loadTypes()
  },
  methods: {
    loadSystemSettings() {
      this.$store.dispatch(`system/${GET_PRIVATE_SETTINGS}`)
        .then(res => {
          this.$set(this, 'autentiEnabled', res.data.item?.autentiEnabled || false)
          this.loading = false
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          this.loading = false
        })
    },
    clear() {
      this.paymentSchedules = []
      this.contactPolls = []
      this.contracts = []
      this.requiredPolls = []
      this.contractTypes = []
    },
    selectContractTypes(value) {
      if (!value) {
        this.getRequiredPolls(null)
        this.selectedContract = ''
      } else {
        this.getContractTemplates()
      }
    },
    initProcess() {
      if (this.checkRequiredModule('pollModule')) {
        this.getContactPolls()
      }

      this.calculatePrices()
    },
    calculatePrices() {
      this.$nextTick(() => {
        if (this.paymentSchedule?.length) {
          // eslint-disable-next-line no-unused-vars
          const overDuePayments = []
          const payments = []
          this.paymentSchedule.forEach(({ date, value }) => {
            const isOverdue = moment().diff(moment(date, 'YYYY-MM-DD'), 'days')
            if (isOverdue > 0) {
              overDuePayments.push(value)
            } else {
              payments.push({ date, value })
            }
          })

          this.$set(this, 'paymentSchedules', payments)
        }
      })
    },
    async loadTypes() {
      try {
        const resp = await axiosIns.get('1/settings/agreementTypes', { params: { fields_load: this.$fields.SETTINGS_AGREEMENTS_TYPES } })
        this.contractTypes = resp.data.data?.items || []
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    async validatePolls() {
      const polls = []
      // eslint-disable-next-line
      for (const requiredPoll of this.requiredPolls) {
        const ref = `required_poll_${requiredPoll.id}`
        // eslint-disable-next-line no-await-in-loop
        const response = this.$refs[ref][0].savePoll()
        if (response) {
          const { fields, poll } = response
          polls.push({ fields, poll })
        }
      }

      if (polls.length === this.requiredPolls.length) {
        return polls
      }

      this.showToast('error', this.$i18n.t('SomeFieldsAreNotFilled'))
      return false
    },
    // eslint-disable-next-line no-unused-vars
    async saveProcess(offerId = null, saveContract = true) {
      const isValid = await this.$refs.pieczarki.validate()

      if (isValid) {
        if (this.checkRequiredModule('pollModule')) {
          const polls = await this.validatePolls()
          if (polls) {
            const isOk = await Promise.all([...polls.map(poll => this.saveItem(poll)), this.$refs['contractor-requirements'].save()])
              .then(() => {
                this.showToast('success', this.$i18n.t('PollsAndContactsUpdated'))
                if (saveContract) this.saveContract(offerId)
                return true
              })
              .catch(err => {
                this.$emit('close', true)
                this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
                return false
              })

            return isOk
          }
        } else if (saveContract) {
          this.saveContract(offerId)
        } else {
          return true
        }
      } else {
        this.showToast('error', this.$i18n.t('AllFieldsAreRequired'))
        return false
      }

      return false
    },
    saveContract(offerId = null) {
      if (this.selectedContract) {
        const payload = {
          contactId: this.thread.contact.id,
          threadId: this.thread.id,
          offerId: offerId ?? this.thread.id,
          agreementId: typeof this.selectedContract === 'string' ? this.selectedContract : this.selectedContract?.id,
          signerUser: typeof this.signerUser === 'string' ? this.signerUser : this.signerUser?.id,
          anonymised: this.anonymised,
          allowElectronicSignature: this.autentiSigning,
          contactThreadAgreementPaymentScheduleItems: [],
          agreementTemplate: (typeof this.selectedContract === 'string' ? this.selectedContract : this.selectedContract?.id),
          contactThreadAgreement: this.totalPaymentDate,
          value: this.restToPay,
        }

        if (this.selectedContract?.advancePayment) {
          const { price, date } = this.reservation
          payload.contactThreadAgreementPaymentScheduleItems.push({ value: parseFloat(price), date, type: 'R' })
        }

        this.$store.dispatch(`offer/${SAVE_CONTACT_OFFER_AGREEMENT}`, payload)
          .then(() => {
            this.showToast('success', this.$i18n.t('OfferWasUpdated'))
            this.$emit('close', true)
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          })
      }
    },
    // POLL UPDATES
    saveItem({ fields, poll }) {
      return new Promise((resolve, reject) => {
        const { contact, id } = JSON.parse(JSON.stringify(this.thread))
        const payload = {
          contactId: contact.id, threadId: id, pollId: typeof poll === 'string' ? poll : poll?.id,
        }

        contact.status = contact.status.name
        delete contact.statusUpdatedAt
        if (contact.contactAssignedUsers) contact.contactAssignedUsers = contact.contactAssignedUsers.map(el => el.id)
        if (contact.phoneNumbers) contact.phoneNumbers = contact.phoneNumbers.map(el => el.phone)
        if (contact.mailAddresses) contact.mailAddresses = contact.mailAddresses.map(el => el.mail)
        if (contact.contactTypes) contact.contactTypes = contact.contactTypes.map(el => el.id)
        if (contact.userGroups) contact.userGroups = contact.userGroups.map(el => el.id)

        delete contact.createdAt
        delete contact.createdBy

        if (this.autentiSigning) contact.status = 'SIGNING'

        if (!poll?.exist) {
          this.$store.dispatch(`singleContact/${CREATE_POLL}`, payload)
            .then(res => {
              const threadPollId = res.data.items[0]?.id
              if (threadPollId) {
                this.saveFields(threadPollId, fields)
                  .then(() => {
                    resolve()
                  })
                  .catch(() => {
                    reject()
                  })
              }
            })
            .catch(err => {
              this.$emit('close', true)
              this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
              reject()
            })
        } else {
          this.saveFields(payload.pollId, fields)
            .then(() => {
              resolve()
            })
            .catch(() => {
              reject()
            })
        }
      })
    },
    saveFields(pollId, fields = []) {
      return new Promise((resolve, reject) => {
        const { contact, id } = this.thread
        const payload = {
          contactId: contact.id, threadId: id, pollId, options: fields,
        }
        if (fields.length) {
          this.$store.dispatch(`singleContact/${SAVE_POLL_OPTION}`, payload)
            .then(() => {
              resolve()
            })
            .catch(err => {
              this.$emit('close', true)
              this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
              reject()
            })
        } else resolve()
      })
    },
    // POLL UPDATES
    getContractTemplates() {
      this.$nextTick(() => {
        this.$store.dispatch(`contractTemplates/${GET_CONTRACTS_TEMPLATES}`, { filters: { type: typeof this.contractType === 'string' ? this.contractType : this.contractType?.id } })
          .then(res => {
            this.contracts = res.data.items
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    getRequiredPolls(poll = []) {
      if (poll && this.checkRequiredModule('pollModule')) {
        // eslint-disable-next-line no-underscore-dangle
        const _poll = [poll]

        let assignedPolls = []
        if (Array.isArray(_poll)) {
          _poll.forEach(pollItem => pollItem.assignedPolls.forEach(({ id }) => { assignedPolls.push(id) }))
          assignedPolls = [...new Set(assignedPolls)]
        } else assignedPolls = assignedPolls.map(({ id }) => id)

        if (assignedPolls && assignedPolls.length) {
          if (this.checkRequiredModule('pollModule')) {
            this.$store.dispatch(`polls/${GET_POLLS}`, { filters: { polls: assignedPolls } })
              .then(res => {
                this.requiredPolls = res.data.items
              })
              .catch(err => {
                this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
              })
          }
        }
      } else {
        this.requiredPolls = []
      }

      this.calculatePrices()
    },
    getContactPolls() {
      this.$nextTick(() => {
        if (this.checkRequiredModule('pollModule')) {
          this.loading = true
          this.$nextTick(() => {
            const { contact, id } = this.thread
            const payload = {
              contactId: contact.id, threadId: id,
            }
            if (this.checkRequiredModule('pollModule')) {
              this.$store.dispatch(`singleContact/${GET_POLLS}`, payload)
                .then(res => {
                  this.contactPolls = res.data.items
                  this.loading = false
                })
                .catch(err => {
                  this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
                  this.loading = false
                })
            }
          })
        }
      })
    },
    getContactEditedPoll(pollToSearch) {
      const findItem = this.contactPolls.find(({ poll }) => poll.id === pollToSearch.id)
      return findItem || null
    },
    async openAgreementPreview() {
      this.showAlert('warning', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('ContinuingWillSaveTheNecessaryFields'))
        .then(async () => {
          const isOk = await this.saveProcess(null, false)
          if (isOk) {
            this.$nextTick(() => {
              const templateId = typeof this.selectedContract === 'string' ? this.selectedContract : this.selectedContract?.id
              const payload = {
                agreementTemplate: templateId,
                anonymised: this.anonymised,
                allowElectronicSignature: this.autentiSigning,
                contactThreadCart: this.cart,
                signerUser: typeof this.signerUser === 'string' ? this.signerUser : this.signerUser?.id,
                contactThreadAgreementPaymentScheduleItems: [],
                contactThreadAgreement: this.totalPaymentDate,
                value: this.restToPay,
              }

              axiosIns.post(`1/contacts/${this.thread.contact?.id}/threads/${this.thread?.id}/agreements/getPdf`, payload)
                .then(res => {
                  const a = document.createElement('a')
                  a.href = `data:application/pdf;base64,${res.data.data?.file}`
                  a.setAttribute('target', '_blank')
                  a.download = this.$i18n.t('Preview')
                  a.click()
                })
                .catch(err => {
                  this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
                })
            })
          }
        })
    },
  },
}
</script>
