<template>
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex">
        <b-form-checkbox v-model="$store.state.funnelMain.selectedUsers"
                         :value="singleThread"
                         variant="primary"
                         disabled
        />

        <p
          v-if="singleThread.createdAt"
          v-b-tooltip
          class="m-0 text-normal text-dark"
          :title="`${$i18n.t('Created')}: ${singleThread.createdAt.date.split('.')[0]}`"
        >
          <feather-icon icon="ClockIcon" />
          {{ singleThread.createdAt.date.split(' ')[0] }}
        </p>
      </div>

      <b-dropdown
        variant="flat-secondary"
        class="dropdown-icon-wrapper"
        toggle-class="btn-icon rounded-circle"
        size="sm"
        right
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="16"
          />
        </template>

        <b-dropdown-item
          v-if="checkRequiredPermissions([
            $roles.CONTACT_THREAD_TASK_ADD,
            { role: $roles.CONTACT_THREAD_ASSIGNED_TASK_ADD, data: singleThread, userId: currentUser.id, field: 'assignedUsers' },
          ])"
          @click="openModal('task', $t('NewTask'), $t('AddTask'), singleThread)"
        >
          <dropdown-item-label icon="PlusIcon"
                               :label="$t('AddTask')"
          />
        </b-dropdown-item>

        <b-dropdown-item
          v-if="checkRequiredPermissions([
            $roles.CONTACT_THREAD_NOTE_ADD,
            { role: $roles.CONTACT_THREAD_ASSIGNED_NOTE_ADD, data: singleThread, userId: currentUser.id, field: 'assignedUsers' },
          ])"
          @click="openModal('note', $t('NewNote'), $t('AddNote'), singleThread)"
        >
          <dropdown-item-label icon="FileIcon"
                               :label="$t('AddNote')"
          />
        </b-dropdown-item>

        <b-dropdown-item @click="openModal('sms', $t('Sms'), $t('Send'), singleThread)">
          <dropdown-item-label icon="MessageCircleIcon"
                               :label="$t('Sms')"
          />
        </b-dropdown-item>

        <b-dropdown-item @click="openModal('mail', $t('Email'), $t('Send'), singleThread)">
          <dropdown-item-label icon="MailIcon"
                               :label="$t('Email')"
          />
        </b-dropdown-item>
      </b-dropdown>
    </div>

    <div class="d-flex flex-row justify-content-start align-items-center mb-25">
      <!--    START::Move Panel    -->
      <mp
        v-b-tooltip
        :title="$t('funnel.ChangeStatus')"
        :list="list"
        :selected="singleThread.contactThreadFunnelTypePoint"
        @changeFunnelPoint="
          e => $emit('changeFunnelPoint', {
            ...e,
            item: {
              'contact': singleThread.contact,
              'thread': singleThread,
              'openedId': singleThread.openedId,
            }
          })
        "
      />
      <!--    END::Move Panel    -->

      <div class="ml-md-25">
        <div class="mb-0 font-weight-bold d-flex align-items-center justify-content-between flex-wrap">
          <router-link
            :class="{ 'deleted-text': singleThread.contact.deletedAt }"
            :to="`/contact/${singleThread.contact.id}/thread/${singleThread.id}/timeline`"
            class="mr-50"
          >
            <div class="d-flex align-items-center">
              <avatar
                :user="singleThread.contact"
                :show-name="false"
                size="14"
                is-contact
              />

              {{ `${singleThread.contact.firstName} ${singleThread.contact.lastName}` | textIsland(20, '…') }}
            </div>
          </router-link>
        </div>

        <small class="text-dark">
          {{ singleThread.name | truncate(20, '…') }}
        </small>
      </div>
    </div>

    <b-badge
      v-if="singleThread.contact.organization"
      v-b-tooltip.hover.v-secondary
      variant="light-dark"
      class="text-wrap text-left"
      style="opacity: .75"
      :title="`${$t('Organization')}: ${singleThread.contact.organization.name}`"
    >
      <b-link v-if="checkRequiredPermissions([$roles.ORGANIZATION_VIEW_ALL]) && singleThread.contact.organization.id"
              :to="`/organizations/${singleThread.contact.organization.id}`"
              target="_blank"
      >
        {{ singleThread.contact.organization.name | truncate(!isCompressed ? 64 : 34, '…') }}
      </b-link>
      <span v-else>{{ singleThread.contact.organization.name | truncate(!isCompressed ? 64 : 34, '…') }}</span>
    </b-badge>

    <template v-if="!isCompressed">
      <div class="w-100 text-center" v-if="singleThread.predictedValue">
        <strong
          v-b-tooltip.hover.v-success
          class="font-medium-1 d-flex text-success align-items-center justify-content-center"
          :title="$t('CustomerValue')"
        >
          {{ (singleThread.predictedValue ? singleThread.predictedValue : 0) | priceFormat }} {{ singleThread.predictedValueCurrency || 'PLN' }}
        </strong>
      </div>
    </template>

    <div class="d-flex flex-wrap flex-row align-items-start justify-content-between">
      <div class="d-flex align-items-center">
        <b-button
          :disabled="singleThread.contact.deletedAt"
          variant="flat-success"
          :to="`/contact/${singleThread.contact.id}/thread/${singleThread.id}/timeline`"
          target="_blank"
          class="btn-icon rounded mr-25"
          size="sm"
        >
          <feather-icon
            class="text-success"
            icon="ExternalLinkIcon"
          />
        </b-button>

        <!-- Assigned Contacts -->
        <b-popover
          v-if="singleThread.contactThreadAssignedContacts && singleThread.contactThreadAssignedContacts.length"
          :target="`popover_${singleThread.id}_${index}`"
          triggers="hover"
          placement="top"
          class="mr-50"
        >
          <template #title>
            {{ $t('AssignedContact') }}
          </template>
          <router-link
            v-for="(threadItem, itemIndex) in singleThread.contactThreadAssignedContacts"
            :key="`assigned_${singleThread.contact.id}_contact_${itemIndex}`"
            class="d-block"
            :class="{ 'deleted-text': threadItem.contact.deletedAt }"
            :to="`/contact/${threadItem.contact.id}`"
          >
            {{ threadItem.contact.firstName }} {{ threadItem.contact.firstName }}
          </router-link>
        </b-popover>

        <feather-icon
          v-if="singleThread.contactThreadAssignedContacts && singleThread.contactThreadAssignedContacts.length"
          :id="`popover_${singleThread.id}_${index}`"
          icon="UsersIcon"
          class="text-primary"
          :class="!isCompressed ? 'mr-50' : 'mr-25'"
        />

        <template v-if="!isCompressed">
          <b-badge
            v-b-tooltip.hover.v-primary
            variant="light-primary"
            class="mr-25"
            :title="$t('funnel.ChecklistProgress')"
            :class="{ 'cursor-pointer': singleThread.contactThreadChecklistDetails.length }"
          >
            <template v-if="singleThread.contactThreadChecklistDetails.length">
              <span @click.stop="openChecklistModal(singleThread)">
                <feather-icon
                  icon="ZoomInIcon"
                  class="mr-25"
                />

                {{ `${checklistProgress(singleThread.contactThreadChecklistDetails).done} / ${checklistProgress(singleThread.contactThreadChecklistDetails).all}` }}
              </span>
            </template>

            <feather-icon
              v-else
              icon="MinusIcon"
            />
          </b-badge>

          <!--          <b-badge-->
          <!--            v-b-tooltip.hover.v-dark-->
          <!--            variant="light-dark"-->
          <!--            class="mr-25"-->
          <!--            :title="$t('funnel.StatusesProgress')"-->
          <!--          >-->
          <!--            {{ singleThread.contactThreadFunnelTypePoint.progressPercent }}%-->
          <!--          </b-badge>-->
        </template>

        <div v-else>
          <b-button
            :id="`p-${_uid}`"
            variant="flat-primary"
            class="btn-sm btn-icon rounded-circle"
          >
            <feather-icon icon="MoreHorizontalIcon" />
          </b-button>

          <b-popover
            ref="popover"
            :target="`p-${_uid}`"
            triggers="focus"
            placement="auto"
            container="my-container"
          >
            <b-list-group>
              <b-list-group-item
                v-b-tooltip.hover.v-success
                class="py-25 px-50 border-0 text-center d-flex flex-column justify-content-center"
                :title="$t('CustomerValue')"
                v-if="singleThread.predictedValue"
              >
                {{ $t('CustomerValue') }}

                <strong class="d-flex align-items-center justify-content-center text-success">
                  {{ (singleThread.predictedValue ? singleThread.predictedValue : 0) | priceFormat }} {{ singleThread.predictedValueCurrency || 'PLN' }}
                </strong>
              </b-list-group-item>

              <b-list-group-item
                v-b-tooltip.hover.v-primary
                class="py-25 px-50 border-0 text-center d-flex flex-column justify-content-center"
                :title="$t('funnel.ChecklistProgress')"
                :class="{ 'cursor-pointer': singleThread.contactThreadChecklistDetails.length }"
                @click="() => { if (singleThread.contactThreadChecklistDetails.length) openChecklistModal(singleThread) }"
              >
                {{ $t('funnel.ChecklistProgress') }}

                <strong class="text-primary">
                  <template v-if="singleThread.contactThreadChecklistDetails.length">
                    <feather-icon
                      icon="ZoomInIcon"
                      class="mr-25"
                    />

                    {{ `${checklistProgress(singleThread.contactThreadChecklistDetails).done} / ${checklistProgress(singleThread.contactThreadChecklistDetails).all}` }}
                  </template>

                  <feather-icon
                    v-else
                    icon="MinusIcon"
                  />
                </strong>
              </b-list-group-item>

<!--              <b-list-group-item-->
<!--                v-b-tooltip.hover.v-dark-->
<!--                class="py-25 px-50 border-0 text-center d-flex flex-column justify-content-center"-->
<!--                :title="$t('funnel.StatusesProgress')"-->
<!--              >-->
<!--                {{ $t('funnel.StatusesProgress') }}-->

<!--                <strong class="text-dark&quot;">-->
<!--                  <template v-if="singleThread.contactThreadChecklistDetails.length">-->
<!--                    {{ checklistProgress(singleThread.contactThreadChecklistDetails).percent }}%-->
<!--                  </template>-->

<!--                  <feather-icon-->
<!--                    v-else-->
<!--                    icon="MinusIcon"-->
<!--                  />-->
<!--                </strong>-->
<!--              </b-list-group-item>-->
            </b-list-group>
          </b-popover>
        </div>
      </div>

      <user-arrears
        :user="singleThread"
        @changeTarget="target => $emit('changeTarget', target)"
      />
    </div>
  </div>
</template>

<script>
import { BPopover, VBTooltip } from 'bootstrap-vue'
import UserArrears from '@/views/components/userArrears/UserArrears.vue'
import * as roles from '@/helpers/permissions'
import { OPEN_MODAL } from '@/@constants/mutations'
import { mapGetters } from 'vuex'
import useVerticalLayout from '@core/layouts/layout-vertical/useVerticalLayout'
import { onUnmounted } from '@vue/composition-api'
import MovePanel from './MovePanel.vue'

export default {
  components: {
    BPopover,
    UserArrears,
    mp: MovePanel,
  },
  props: {
    isCompressed: {
      type: undefined,
      required: true,
    },
    singleThread: {
      type: undefined,
      required: true,
    },
    index: {
      type: undefined,
      required: true,
    },
    list: {
      type: Array,
      required: true,
    },
    contact: {
      type: undefined,
      required: true,
    },
    thread: {
      type: undefined,
      required: true,
    },
  },
  emits: ['changeTarget', 'changeFunnelPoint'],
  setup() {
    const { isMobile, resizeHandler } = useVerticalLayout()

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    return {
      perfectScrollbarSettings,
      isMobile,
    }
  },
  data: () => ({
    roles,
  }),
  computed: {
    ...mapGetters({
      filters: 'funnelMain/getFilters',
      currentUser: 'auth/getCurrentUser',
    }),
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  methods: {
    openChecklistModal(thread) {
      this.$store.commit(`modal/${OPEN_MODAL}`,
        {
          modalType: 'listOfChecklistModal',
          modalTitle: this.$t('Checklist'),
          thread,
          size: 'lg',
        })
    },

    checklistProgress(checklist) {
      let numberOfChecklist = 0
      let numberOfDoneChecklist = 0

      checklist.forEach(detail => {
        numberOfChecklist += detail.contactThreadChecklistTypePointsCount
        numberOfDoneChecklist += detail.contactThreadChecklistPointDataProgress
      })

      if (numberOfDoneChecklist > numberOfChecklist) console.error(`[SW -> Checklist Item] The number of completed Checklist Items cannot exceed the number of all Checklist Items. Done ${numberOfDoneChecklist} out of ${numberOfChecklist}`)

      return {
        done: numberOfDoneChecklist,
        all: numberOfChecklist,
        percent: Math.round((numberOfDoneChecklist * 100) / numberOfChecklist),
      }
    },
    openModal(modalType = 'note', modalTitle = 'Nowa Notatka', okButtonTitle = 'Dodaj notatkę', thread = null) {
      this.$root.$emit('bv::hide::popover')
      this.$store.commit(`modal/${OPEN_MODAL}`,
        {
          modalType,
          size: 'lg',
          modalTitle,
          okButtonTitle,
          threadFor: thread.id,
          thread,
        })
    },
  },
}
</script>
