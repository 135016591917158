<template>
  <b-modal
    :id="id"
    :title="title"
    :hide-footer="hideFooter"
    no-close-on-backdrop
    :visible="visible"
    :size="size"
    button-size="sm"
    :ok-title="$t(okTitle)"
    :cancel-title="$t(cancelTitle)"
    @hide="onCloseRemove ? removeModal() : hideModal()"
    @ok="saveModal"
  >
    <component
      v-if="visible"
      :is="component"
      :id="id"
      :data="data"
    />

    <b-overlay
      :show="isLoading"
      no-wrap
      spinner-variant="primary"
    />
  </b-modal>
</template>

<script>

import { HIDE_MODAL, REMOVE_MODAL, SAVE_MODAL_DATA } from '@/@constants/mutations'

export default {
  props: {
    id: {
      type: String,
      required: true,
    },

    title: {
      type: String,
      required: true,
    },

    component: {
      type: String,
      required: true,
    },

    visible: {
      type: Boolean,
      required: false,
      default: false,
    },

    data: {
      type: Object,
      required: false,
      default: null,
    },

    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },

    hideFooter: {
      type: Boolean,
      required: false,
      default: false,
    },

    okTitle: {
      type: String,
      required: false,
      default: 'Save',
    },

    cancelTitle: {
      type: String,
      required: false,
      default: 'Cancel',
    },

    size: {
      type: String,
      required: false,
      default: 'sm',
    },

    onCloseRemove: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  methods: {
    async removeModal() {
      return this.$store.dispatch(`swModal/${REMOVE_MODAL}`, this.id)
    },

    async hideModal() {
      return this.$store.dispatch(`swModal/${HIDE_MODAL}`, this.id)
    },

    async saveModal() {
      return this.$store.dispatch(`swModal/${SAVE_MODAL_DATA}`, this.id)
    },
  },
}
</script>
