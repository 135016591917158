import { render, staticRenderFns } from "./AssignedUsers.vue?vue&type=template&id=31301c99&scoped=true"
import script from "./AssignedUsers.vue?vue&type=script&lang=js"
export * from "./AssignedUsers.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31301c99",
  null
  
)

export default component.exports