// eslint-disable-next-line import/no-cycle
import axiosIns from '@/libs/axios'
import {
  ADD_USER_MAILBOX,
  GET_USER_MAILBOX,
  GET_USER_MAILBOXES,
  TEST_USER_MAILBOX,
  UPDATE_USER_MAILBOX,
} from '@/@constants/mutations'
import * as fields from '@/@constants/fields'

const params = {}
params.fields_load = fields.USER_MAILBOX

export default {
  namespaced: true,

  actions: {
    [GET_USER_MAILBOXES]: () => axiosIns.get('1/users/me/mailboxes', { params })
      .then(res => res.data.data),

    [GET_USER_MAILBOX]: (_, id) => axiosIns.get(`1/users/me/mailboxes/${id}`, { params })
      .then(res => res.data.data),

    [ADD_USER_MAILBOX]: (_, payload) => axiosIns.post('1/users/me/mailboxes', payload)
      .then(res => res.data.data.items[0]),

    [UPDATE_USER_MAILBOX]: (_, payload) => axiosIns.patch('1/users/me/mailboxes', payload)
      .then(res => res.data.data.items[0]),

    [TEST_USER_MAILBOX]: (_, level = 0) => axiosIns.get('1/users/me/mailboxes', {
      params: {
        fields_info: level,
      },
    })
      .then(res => res.data.data),
  },
}
