<template>
  <validation-observer
    ref="form"
    class="d-flex flex-column"
    style="row-gap: 1.34rem"
  >
    <!--    START:Header    -->
    <div
      class="d-flex flex-column flex-md-row align-md-items-center justify-md-content-between"
      style="column-gap: 1.5rem"
    >
      <!--    START:Name & Hint    -->
      <b-row
        class="d-flex flex-grow-1"
        style="row-gap: .34rem"
      >
        <!--    START:Name    -->
        <b-col
          cols="12"
          md="6"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="$t('EnterFieldName')"
            rules="required"
          >
            <b-input-group>
              <b-form-input
                v-model="form.name"
                :label="$t('EnterFieldName')"
                :placeholder="$t('EnterFieldName')"
              />

              <b-input-group-append>
                <b-button
                  v-b-tooltip.hover.v-primary
                  variant="outline-primary"
                  :title="$t('RangeListOfASingleSelection')"
                  class="btn-icon px-75"
                  style="cursor: default !important"
                >
                  <sw-icon icon="InfoIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <!--    END:Name    -->

        <!--    START:Hint    -->
        <b-col
          cols="12"
          md="6"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="$t('AddHint')"
          >
            <b-form-input
              v-model="form.hint"
              :label="$t('AddHint')"
              :placeholder="$t('AddHint')"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <!--    END:Hint    -->
      </b-row>
      <!--    END:Name & Hint    -->

      <!--    START:Toolbar MD    -->
      <div
        class="d-none d-md-flex align-items-md-start mt-md-50"
        style="row-gap: .5rem"
      >
        <b-form-checkbox
          v-model="form.show"
          v-b-tooltip.hover.v-success
          checked="true"
          :title="$t('FieldHiddenVisible')"
          class="custom-control-success"
          name="show"
          switch
        >
          <span class="switch-icon-left">
            <feather-icon icon="EyeIcon" />
          </span>
          <span class="switch-icon-right">
            <feather-icon icon="EyeOffIcon" />
          </span>
        </b-form-checkbox>

        <b-button
          v-b-tooltip.hover.v-danger
          variant="flat-danger"
          size="sm"
          class="btn-icon mt-md-n25"
          :title="$t('Delete')"
          @click="$emit('rm')"
        >
          <feather-icon icon="TrashIcon" />
        </b-button>
      </div>
      <!--    END:Toolbar MD    -->
    </div>
    <!--    END:Header    -->

    <!--    START:Content    -->
    <div>
      <h5>
        {{ $t('DefineFieldValues') }}
      </h5>

      <!--    START:Fields    -->
      <b-list-group
        v-if="form.options.fieldList.length"
        class="mb-25"
        flush
      >
        <b-list-group-item
          v-for="(field, i) in form.options.fieldList"
          :key="i"
          class="mb-1 py-1"
          style="background: var(--bg-shopItem-lighten-3); border: 0; border-radius: 6px;"
        >
          <!--    START:Header    -->
          <div
            class="d-flex flex-column justify-content-between align-items-end flex-md-row align-items-md-center"
            style="column-gap: 1.34rem; row-gap: .64rem;"
          >
            <!--    START:Field Name & Value    -->
            <b-row
              class="flex-grow-1"
              style="row-gap: .5rem"
            >
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('Name')"
                  rules="required"
                >
                  <b-form-input
                    v-model="field.name"
                    :label="$t('Name')"
                    :placeholder="$t('Name')"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>

              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('Value')"
                  rules="required"
                >
                  <b-form-input
                    v-model="field.value"
                    :label="$t('Value')"
                    :placeholder="$t('Value')"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>

              <b-col
                cols="12"
                md="4"
              >
                <sw-select
                  rules="required"
                  classes="mb-0"
                >
                  <v-select
                    v-model="form.options.unit"
                    :options="validatedValues"
                    :placeholder="$t('SelectValidatedValue')"
                    :clearable="false"
                  >
                    <template #no-options="{ search }">
                      <span v-if="search.length">
                        {{ $t('NoData') }}
                      </span>

                      <span v-else>
                        {{ $t('TypeToSearch') }}
                      </span>
                    </template>
                  </v-select>
                </sw-select>
              </b-col>
            </b-row>
            <!--    END:Field Name & Value    -->

            <!--    START:Delete Field    -->
            <b-button
              variant="danger"
              size="sm"
              class="btn-icon"
              @click="
                field.ranges.length
                  ? showAlert('warning', $i18n.t('alerts.AreYouSure'), $i18n.t('alerts.WontbeAble'))
                    .then(() => form.options.fieldList.splice(i, 1))
                    .catch(() => {})
                  : form.options.fieldList.splice(i, 1)
              "
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          <!--    END:Delete Field    -->
          </div>
          <!--    END:Header    -->

          <!--    START:Table    -->
          <h6 class="mt-2">
            {{ $t('DefineFieldValues') }}
          </h6>

          <b-table
            :items="field.ranges"
            :fields="fields"
            striped
            responsive
            show-empty
            class="border rounded-lg mb-0"
          >
            <!--    Head    -->
            <template #head()="{ label }">
              <sw-icon
                v-if="label === 'xSeparator'"
                icon="xIcon"
              />

              <span v-else-if="label === 'toSeparator'" />

              <template v-else>
                {{ $t(`${label}`) }}
              </template>
            </template>

            <!--    START:X    -->
            <template #cell(xSeparator)>
              <sw-icon icon="XIcon" />
            </template>
            <!--    END:X    -->

            <!--    START:toSeparator    -->
            <template #cell(toSeparator)>
              {{ $t('To').toLocaleLowerCase() }}
            </template>
            <!--    END:toSeparator    -->

            <!--    START:New Inverter Form    -->
            <template #bottom-row>
              <!--    START:From    -->
              <td style="min-width: 10rem">
                <b-input-group>
                  <b-form-input
                    v-model="field.newItem.from"
                    :label="$t('From')"
                    type="number"
                    :placeholder="$t('From')"
                  />
                </b-input-group>
              </td>
              <!--    END:From    -->

              <!--    START:To    -->
              <td style="width: 0">
                {{ $t('To').toLocaleLowerCase() }}
              </td>
              <!--    END:To    -->

              <!--    START:To    -->
              <td style="min-width: 10rem">
                <b-input-group>
                  <b-form-input
                    v-model="field.newItem.to"
                    :label="$t('To')"
                    type="number"
                    :placeholder="$t('To')"
                  />
                </b-input-group>
              </td>
              <!--    END:To    -->

              <!--    START:Action    -->
              <td style="min-width: 14rem">
                <sw-select
                  rules="required"
                  classes="mb-0 w-100"
                >
                  <v-select
                    v-model="field.newItem.action"
                    :options="actions"
                    :get-option-label="name => $t(`calculators.actions.${name}`)"
                    :placeholder="$t('SelectAction')"
                    :clearable="false"
                  >
                    <template #no-options="{ search }">
                      <span v-if="search.length">
                        {{ $t('NoData') }}
                      </span>

                      <span v-else>
                        {{ $t('TypeToSearch') }}
                      </span>
                    </template>
                  </v-select>
                </sw-select>
              </td>
              <!--    END:Action    -->

              <!--    START:Value    -->
              <td style="min-width: 10rem">
                <b-input-group>
                  <b-form-input
                    v-model="field.newItem.value"
                    :label="$t('Value')"
                    type="number"
                    :placeholder="$t('Value')"
                  />
                </b-input-group>
              </td>
              <!--    END:Value    -->

              <!--    START:X    -->
              <td style="width: 0">
                <sw-icon icon="XIcon" />
              </td>
              <!--    END:X    -->

              <!--    START:Multiplier    -->
              <td style="min-width: 14rem">
                <sw-select
                  rules="required"
                  classes="mb-0 w-100"
                >
                  <v-select
                    v-model="field.newItem.multiplier"
                    :options="multipliers"
                    label="name"
                    :placeholder="$t('Multiplier')"
                    :clearable="false"
                  >
                    <template #no-options="{ search }">
                      <span v-if="search.length">
                        {{ $t('NoData') }}
                      </span>

                      <span v-else>
                        {{ $t('TypeToSearch') }}
                      </span>
                    </template>
                  </v-select>
                </sw-select>
              </td>
              <!--    END:Multiplier    -->

              <!--    START:Add Button    -->
              <td class="d-flex justify-content-end">
                <b-button
                  variant="success"
                  class="m-25"
                  :disabled="!field.newItem.from || !field.newItem.to || !field.newItem.action || !field.newItem.value || !field.newItem.multiplier"
                  @click="addNewRangeItem(i)"
                >
                  {{ $t('Add') }}

                  <feather-icon icon="PlusIcon" />
                </b-button>
              </td>
              <!--    END:Add Button    -->
            </template>
            <!--    END:New Inverter Form    -->

            <template #cell(actions)="{ index }">
              <b-button
                variant="flat-danger"
                size="sm"
                @click="field.ranges.splice(index, 1)"
              >
                <feather-icon icon="TrashIcon" />

                {{ $t('Delete') }}
              </b-button>
            </template>

            <!--    START:From    -->
            <template #cell(from)="{ index }">
              <b-input-group>
                <b-form-input
                  v-model="field.ranges[index].from"
                  :label="$t('From')"
                  type="number"
                  :placeholder="$t('From')"
                />
              </b-input-group>
            </template>
            <!--    END:From    -->

            <!--    START:To    -->
            <template #cell(to)="{ index }">
              <b-input-group>
                <b-form-input
                  v-model="field.ranges[index].to"
                  :label="$t('To')"
                  type="number"
                  :placeholder="$t('To')"
                />
              </b-input-group>
            </template>
            <!--    END:To    -->

            <!--    START:Action    -->
            <template #cell(action)="{ index }">
              <sw-select
                rules="required"
                classes="mb-0 w-100"
              >
                <v-select
                  v-model="field.ranges[index].action"
                  :options="actions"
                  :get-option-label="name => $t(`calculators.actions.${name}`)"
                  :placeholder="$t('SelectAction')"
                  :clearable="false"
                >
                  <template #no-options="{ search }">
                    <span v-if="search.length">
                      {{ $t('NoData') }}
                    </span>

                    <span v-else>
                      {{ $t('TypeToSearch') }}
                    </span>
                  </template>
                </v-select>
              </sw-select>
            </template>
            <!--    END:Action    -->

            <!--    START:Value    -->
            <template #cell(value)="{ index }">
              <b-input-group>
                <b-form-input
                  v-model="field.ranges[index].value"
                  :label="$t('Value')"
                  type="number"
                  :placeholder="$t('Value')"
                />
              </b-input-group>
            </template>
            <!--    END:Value    -->

            <!--    START:Multiplier    -->
            <template #cell(multiplier)="{ index }">
              <sw-select
                rules="required"
                classes="mb-0 w-100"
              >
                <v-select
                  v-model="field.ranges[index].multiplier"
                  :options="multipliers"
                  label="name"
                  :placeholder="$t('Multiplier')"
                  :clearable="false"
                >
                  <template #no-options="{ search }">
                    <span v-if="search.length">
                      {{ $t('NoData') }}
                    </span>

                    <span v-else>
                      {{ $t('TypeToSearch') }}
                    </span>
                  </template>
                </v-select>
              </sw-select>
            </template>
            <!--    END:Multiplier    -->

            <!--    Other    -->
            <template #cell()="row">
              <table-default-cell
                :field="row.field"
                :value="row.value"
              />
            </template>

            <!--    Empty    -->
            <template #empty>
              <empty-content />
            </template>

            <!--    Table Busy    -->
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner
                  class="align-middle"
                  variant="primary"
                />
              </div>
            </template>
          </b-table>
          <!--    END:Table    -->
        </b-list-group-item>
      </b-list-group>
      <!--    END:Fields    -->

      <!--    START:No Fields    -->
      <empty-content
        v-else
        class="mt-n50 mb-25"
      />
      <!--    END:No Fields    -->

      <!--    START:Add New Field    -->
      <b-button
        variant="outline-primary"
        class="w-100"
        @click="form.options.fieldList.push({ ranges: [], newItem: { } })"
      >
        <feather-icon icon="PlusIcon" />

        {{ $t('Add') }}
      </b-button>
      <!--    END:Add New Field    -->
    </div>
    <!--    END:Content    -->

    <!--    START:Options    -->
    <b-form-group
      :label="$t('Options')"
      class="mb-0"
    >
      <template #label>
        <h5 class="mb-25">
          {{ $t('Options') }}
        </h5>
      </template>

      <b-form-checkbox
        v-model="form.required"
        class="custom-control-primary"
      >
        {{ $t('MandatoryChoice') }}
      </b-form-checkbox>
    </b-form-group>
    <!--    END:Options    -->

    <!--    START:Toolbar SM    -->
    <div
      class="d-flex align-items-center justify-content-end mt-n1 d-md-none"
      style="row-gap: .5rem"
    >
      <b-form-checkbox
        v-model="form.show"
        v-b-tooltip.hover.v-success
        checked="true"
        :title="$t('FieldHiddenVisible')"
        class="custom-control-success"
        name="show"
        switch
      >
        <span class="switch-icon-left">
          <feather-icon icon="EyeIcon" />
        </span>
        <span class="switch-icon-right">
          <feather-icon icon="EyeOffIcon" />
        </span>
      </b-form-checkbox>

      <b-button
        v-b-tooltip.hover.v-danger
        variant="flat-danger"
        size="sm"
        class="btn-icon"
        :title="$t('Delete')"
        @click="$emit('rm')"
      >
        <feather-icon icon="TrashIcon" />
      </b-button>
    </div>
    <!--    END:Toolbar SM    -->
  </validation-observer>
</template>

<script>
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { BInputGroupAppend, BTable, VBTooltip } from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BInputGroupAppend,
    ValidationObserver,
    ValidationProvider,
    vSelect,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },

  emits: ['isValid', 'rm'],

  data: () => ({
    form: {
      options: {
        fieldList: [],
      },
      show: true,
    },

    validatedValues: [],

    fields: [
      { key: 'from', label: 'From' },
      { key: 'toSeparator', label: 'toSeparator' },
      { key: 'to', label: 'To' },
      { key: 'action', label: 'calculators.Action' },
      { key: 'value', label: 'Value' },
      { key: 'xSeparator', label: 'xSeparator' },
      { key: 'multiplier', label: 'Multiplier' },
      { key: 'actions', label: 'Actions' },
    ],

    actions: [
      'ADD',
      'SUB',
      'MUL',
      'DIV',
    ],

    multipliers: [
      { id: -1, name: 'Test' },
    ],
  }),

  watch: {
    form: {
      deep: true,
      async handler() {
        this.$emit('isValid', await this.$refs.form.validate({ silent: true }))
      },
    },
  },

  methods: {
    addNewRangeItem(index) {
      this.form.options.fieldList[index].ranges.push(this.form.options.fieldList[index].newItem)

      this.form.options.fieldList[index].newItem = { }
    },
  },
}
</script>
