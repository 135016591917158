<template>
  <target-view
    :key="`new-task-${id}`"
    :target-id="id"
    :thread="thread"
  />
</template>

<script>
import TargetView from '@/pages/projects/components/new/View.vue'

export default {
  components: {
    TargetView,
  },
  computed: {
    id() { return Number(this.$route.params?.id || null) },
    thread() { return this.$route.params?.thread || null },
  },
}
</script>
