var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('sw-filters',{attrs:{"shorts":[
      {
        title: _vm.$t('TasksAssignedTo'),
        type: 'avatar',
        value: function () { return _vm.filters.assignedUsers; },
        clear: function () {
          _vm.filters.assignedUsers = []
          _vm.changeAssignedUsers()
        }
      },
      {
        title: _vm.$t('SelectPlanedTasksRange'),
        value: function () { return _vm.filters.timeRange; },
        clear: function () {
          _vm.filters.timeRange = '';
          _vm.loadData()
        }
      } ]}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('sw-select',{staticClass:"mb-0",attrs:{"name":_vm.$t('TasksAssignedTo')}},[_c('assigned-users',{attrs:{"value":[].concat( _vm.filters.assignedUsers )},on:{"input":_vm.changeAssignedUsers}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('sw-select',{attrs:{"name":_vm.$t('SelectPlanedTasksRange'),"tip":_vm.$t('SpecifyTheTimeRangeOfScheduledTasks')}},[_c('sw-select-fpr',{attrs:{"is-empty":_vm.filters.timeRange},on:{"clear":function($event){_vm.filters.timeRange = ''; _vm.loadData()}}},[_c('flat-pickr',{ref:"timeRange",staticClass:"form-control",attrs:{"config":{ dateFormat: 'd-m-Y', locale: _vm.getCalendarLocale(_vm.$i18n.locale), mode: 'range' }},on:{"on-close":function($event){return _vm.loadData()}},model:{value:(_vm.filters.timeRange),callback:function ($$v) {_vm.$set(_vm.filters, "timeRange", $$v)},expression:"filters.timeRange"}})],1)],1)],1)],1)],1),_c('b-alert',{attrs:{"show":"","variant":"primary"}},[_c('div',{staticClass:"alert-body d-flex align-items-center py-1"},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"AlertCircleIcon","size":"20"}}),_vm._v(" "+_vm._s(_vm.$t('ReportShowsCompletionRatesForScheduledTasks'))+" ")],1)]),_c('b-card',[_c('b-table',{ref:"selectableTable",style:({ minHeight: _vm.tableSize(_vm.items, 50) }),attrs:{"items":_vm.items,"fields":_vm.fields,"striped":"","responsive":"","busy":_vm.loading,"show-empty":""},scopedSlots:_vm._u([{key:"head()",fn:function(head){return [_vm._v(" "+_vm._s(_vm.$t(("" + (head.label))))+" "),(head.field.tip)?_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"text-primary",attrs:{"icon":"AlertCircleIcon","title":_vm.$t(head.field.tip)}}):_vm._e()]}},{key:"cell(name)",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"py-50"},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"cell(total)",fn:function(ref){
    var item = ref.item;
return [_c('b-badge',{staticClass:"font-small-2",staticStyle:{"width":"100px"},attrs:{"variant":"primary"}},[_vm._v(" "+_vm._s(item.stats.all || 0)+" ")])]}},{key:"cell(open)",fn:function(ref){
    var item = ref.item;
return [_c('b-badge',{staticClass:"font-small-2",staticStyle:{"width":"100px"},attrs:{"variant":"light-primary"}},[_vm._v(" "+_vm._s(item.stats.O || 0)+" ")])]}},{key:"cell(completed)",fn:function(ref){
    var item = ref.item;
return [_c('b-badge',{staticClass:"font-small-2",staticStyle:{"width":"100px"},attrs:{"variant":"light-success"}},[_vm._v(" "+_vm._s(item.stats.D || 0)+" ")])]}},{key:"cell(notDone)",fn:function(ref){
    var item = ref.item;
return [_c('b-badge',{staticClass:"font-small-2",staticStyle:{"width":"100px"},attrs:{"variant":"light-danger"}},[_vm._v(" "+_vm._s(item.stats.ND || 0)+" ")])]}},{key:"cell(completedRate)",fn:function(ref){
    var item = ref.item;
return [_c('b-progress-bar',{staticClass:"rounded",staticStyle:{"min-width":"50px"},attrs:{"striped":"","value":(((item.stats.D || 0) / (item.stats.all || 0)) * 100) || 0,"variant":"primary","max":"100"}},[_vm._v(" "+_vm._s(((((item.stats.D || 0) / (item.stats.all || 0)) * 100) || 0).toFixed(0))+"% ")])]}},{key:"cell()",fn:function(row){return [_c('table-default-cell',{attrs:{"field":row.field,"value":row.value}})]}},{key:"empty",fn:function(){return [_c('empty-content')]},proxy:true},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[_c('b-spinner',{staticClass:"align-middle",attrs:{"variant":"primary"}})],1)]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }