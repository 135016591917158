// eslint-disable-next-line import/prefer-default-export
export const defaultEmailTemplate = `<p style="font-weight: 600; font-size: 18px; margin-bottom: 0;">
                Witaj użytkowniku
            </p>
            <p style="font-weight: 700; font-size: 20px; margin-top: 0; --text-opacity: 1; color: #fea801;">
                {% loggedUser.firstName %} {% loggedUser.lastName %}
            </p>
            <p class="sm-leading-32" style="margin: 0 0 24px; --text-opacity: 1; color: #263238; color: rgba(38, 50, 56, var(--text-opacity));">
                To jest przykładowa treść szablonu wiadomości email
            </p>
`
