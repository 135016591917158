<template>
  <b-col
    sm="12"
    :md="md"
    :lg="lg"
  >
    <b-card class="offer-tile position-relative mb-1 mb-md-2">
      <template #header>
        <div class="d-flex justify-content-between align-items-start mb-25 w-100">
          <h5 class="mt-25">
            {{ title }}
          </h5>
          <feather-icon
            v-if="tip"
            v-b-tooltip.v-primary
            size="18"
            :title="tip"
            icon="InfoIcon"
            class="text-primary cursor-pointer"
          />
        </div>
      </template>

      <!--      <div class="d-flex justify-content-center align-items-center mb-25">-->
      <div class="mb-25 mt-n1 d-flex pb-1">
        <b-img
          v-if="image"
          :src="image"
          style="max-width: 50px; height: 50px; width: 50px"
          class="mr-50 text-primary"
        />

        <div v-if="svgIcon"
             class="d-inline-block mr-50 svg-icon-tile"
             v-html="svgIcon"
        />

        <div class="d-flex justify-content-end align-items-center flex-grow-1">
          <b-alert
            variant="primary"
            show
            class="my-0 text-center"
            style="min-width: 75px"
          >
            <div class="alert-body">
              <strong>{{ val }}</strong>
            </div>
          </b-alert>

          <b-button
            v-if="!hideAll"
            style="position:absolute;bottom: 0;left: 0;right: 0"
            class="w-100"
            size="sm"
            variant="flat-primary"
            @click="$emit('redirect', to)"
          >
            {{ $t('ShowAll') }}
          </b-button>
        </div>
      </div>
      <b-overlay
        no-wrap
        :show="val === -1"
        spinner-variant="primary"
      />
    </b-card>
  </b-col>
</template>

<script>
import { BAlert, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    BAlert,
  },
  props: {
    image: {
      default: null,
    },
    svgIcon: {
      default: null,
    },
    hideAll: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    tip: {
      type: String,
      required: false,
      default: '',
    },
    val: {
      type: Number,
      required: false,
      default: -1,
    },
    to: {
      type: String,
      required: false,
      default: '',
    },
    md: {
      type: Number,
      required: false,
      default: 6,
    },
    lg: {
      type: Number,
      required: false,
      default: 12,
    },
  },
}
</script>

<style>
.offer-tile {
  transition: all .16s ease-out;
}

.offer-tile:hover {
  transform: translateY(-.3rem);
  box-shadow: 0 14px 34px 0 rgb(34 41 47 / 14%);
}

.svg-icon-tile {
    svg {
        height: 50px;
        width: 50px;
        max-width: 50px;
        line-height: 50px;
    }
}
</style>
