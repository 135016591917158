<template>
  <b-card
    v-if="item"
  >
    <template #header>
      <h4>{{ $t(item.name) }}</h4>
    </template>
    <b-embed
      type="iframe"
      aspect="16by9"
      width="100%"
      height="100%"
      :src="item.url"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      style="min-height: 18rem"
      allowfullscreen
      class="rounded shadow-lg"
    />
  </b-card>
</template>

<script>
import { BEmbed } from 'bootstrap-vue'

export default {
  name: 'ListCard',
  components: {
    BEmbed,
  },
  props: {
    item: {
      type: Object,
      default: null,
      required: true,
    },
  },
}
</script>

<style scoped>

</style>
