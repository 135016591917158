<template>
  <b-button-group
    class="d-flex flex-wrap"
    :class="{ 'w-100': isMobile }"
  >
    <!-- Add Offer -->
    <b-button
      v-if="checkRequiredModule('offerModule') && checkRequiredPermissions([$roles.SHOP_PRODUCT_VIEW_ALL, $roles.SHOP_PRODUCT_VIEW_GROUP]) && checkRequiredPermissions([$roles.CONTACT_THREAD_OFFER_ADD])"
      v-b-tooltip.hover.v-primary
      size="sm"
      variant="primary"
      class="mb-25"
      :class="{ 'btn-icon': isMobile }"
      :disabled="cart.contactThread && thread.id === cart.contactThread.id"
      :title="cart.contactThread && thread.id !== cart.contactThread.id ? $t('offer.ActivateCheckout') : $t('offer.ActiveCheckout')"
      @click="activateCheckout"
    >
      <feather-icon icon="ShoppingCartIcon" />

      <span class="d-none d-lg-inline ml-25">
        {{ cart.contactThread && thread.id !== cart.contactThread.id ? $t('offer.ActivateCheckout') : $t('offer.ActiveCheckout') }}
      </span>
    </b-button>

    <!--    Modal: Create/Edit Offer     -->
    <b-modal
      v-model="showNewOfferModal"
      :title="$t('offer.Create')"
      size="md"
      hide-footer
      no-close-on-backdrop
    >
      <offer-modal />
    </b-modal>

    <!-- Add Note Nav -->
    <b-button
      v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_CARD_NOTES]) && checkRequiredPermissions([$roles.CONTACT_THREAD_NOTE_VIEW_ALL, $roles.CONTACT_THREAD_NOTE_VIEW_SELF, $roles.CONTACT_THREAD_NOTE_VIEW_SHARED], [
        $roles.CONTACT_THREAD_NOTE_ADD,
        { role: $roles.CONTACT_THREAD_ASSIGNED_NOTE_ADD, data: thread, userId: user.id, field: 'assignedUsers' },
      ])"
      v-b-tooltip.hover.v-primary
      size="sm"
      variant="primary"
      class="mb-25"
      :class="{ 'btn-icon': isMobile }"
      :title="$t('NewNote')"
      @click="openModal('note', $t('NewNote'), $t('AddNote'))"
    >
      <feather-icon icon="FileIcon" />

      <span class="d-none d-lg-inline ml-25">
        {{ $t('NewNote') }}
      </span>
    </b-button>
    <!-- Add Task Nav -->
    <b-button
      v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_CARD_TASKS]) && checkRequiredPermissions([$roles.CONTACT_THREAD_TASK_VIEW_ALL, $roles.CONTACT_THREAD_TASK_VIEW_ASSIGNED, $roles.CONTACT_THREAD_TASK_VIEW_STRUCTURE], [
        $roles.CONTACT_THREAD_TASK_ADD,
        { role: $roles.CONTACT_THREAD_ASSIGNED_TASK_ADD, data: thread, userId: user.id, field: 'assignedUsers' },
      ])"
      v-b-tooltip.hover.v-primary
      size="sm"
      variant="primary"
      class="mb-25"
      :class="{ 'btn-icon': isMobile }"
      :title="$t('NewTask')"
      @click="openModal('task', $t('NewTask'), $t('AddTask'))"
    >
      <feather-icon icon="CheckIcon" />

      <span class="d-none d-lg-inline ml-25">
        {{ $t('NewTask') }}
      </span>
    </b-button>
    <!-- Add SMS Nav -->
    <b-button
      v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_CARD_SMS]) && checkRequiredPermissions([$roles.CONTACT_THREAD_SMS_VIEW_ALL, $roles.CONTACT_THREAD_SMS_VIEW_SHARED, $roles.CONTACT_THREAD_SMS_VIEW_SELF],[$roles.CONTACT_THREAD_SMS_ADD])"
      v-b-tooltip.hover.v-primary
      size="sm"
      variant="primary"
      class="mb-25"
      :class="{ 'btn-icon': isMobile }"
      :title="$t('Sms')"
      @click="openModal('sms', $t('Sms'), $t('Send'))"
    >
      <feather-icon icon="MessageCircleIcon" />

      <span class="d-none d-lg-inline ml-25">
        {{ $t('Sms') }}
      </span>
    </b-button>
    <!-- Add Mail Nav -->
    <b-button
      v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_CARD_EMAILS]) && checkRequiredPermissions([$roles.CONTACT_THREAD_MAIL_VIEW_ALL], [$roles.CONTACT_THREAD_MAIL_ADD])"
      v-b-tooltip.hover.v-primary
      size="sm"
      variant="primary"
      class="mb-25"
      :class="{ 'btn-icon': isMobile }"
      :title="$t('Email')"
      @click="openModal('mail', $t('Email'), $t('Send'))"
    >
      <feather-icon icon="MailIcon" />

      <span class="d-none d-lg-inline ml-25">
        {{ $t('Email') }}
      </span>
    </b-button>
  </b-button-group>
</template>

<script>
import { BButton, BButtonGroup, VBTooltip } from 'bootstrap-vue'
import * as roles from '@/helpers/permissions'
import offerModal from '@/views/modals/OfferModal.vue'
import { mapGetters } from 'vuex'
import useVerticalLayout from '@core/layouts/layout-vertical/useVerticalLayout'
import { onUnmounted } from '@vue/composition-api'
import { OPEN_MODAL, UPDATE_CART } from '../../../../@constants/mutations'

export default {
  name: 'UserNav',
  components: {
    BButton,
    BButtonGroup,
    offerModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: ['thread', 'user'],
  data: () => ({
    roles,
    showNewOfferModal: false,
  }),
  computed: {
    ...mapGetters({
      cart: 'cart/getCart',
      system: 'system/getSettings',
    }),
  },
  setup() {
    const { isMobile, resizeHandler } = useVerticalLayout()

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    return {
      perfectScrollbarSettings,
      isMobile,
    }
  },
  methods: {
    changeSettings(value, place) {
      this.$set(this.cart, place, value)

      this.$nextTick(() => {
        this.$store.dispatch(`cart/${UPDATE_CART}`, { cart: this.cart })
          .then(() => {
            this.showToast('success', this.$i18n.t('CartUpdated'))
          })
      })
    },
    activateCheckout() {
      const {
        id, name, contact, offersTotal,
      } = this.thread
      const thread = {
        id, name, contact,
      }

      if (offersTotal >= this.system.contactThreadOffersLimit) {
        this.showAlert('warning', '', this.$i18n.tc('ThreadOfferCountIsToBig', this.system.contactThreadOffersLimit, { limit: this.system.contactThreadOffersLimit }))
        return
      }

      if (this.thread?.id) {
        const text = this.$i18n.tc('offer.CartAlreadyActivated', 1, { thread: this.thread.name, contact: `${this.thread.contact.firstName} ${this.thread.contact.lastName}` })
        this.showAlert('error', this.$i18n.t('alerts.AreYouSure'), text)
          .then(() => {
            this.changeSettings(thread, 'contactThread')
          })
      } else this.changeSettings(thread, 'contactThread')
    },
    openModal(modalType = 'note', modalTitle = 'Nowa Notatka', okButtonTitle = 'Dodaj notatkę') {
      this.$store.commit(`modal/${OPEN_MODAL}`,
        {
          modalType,
          modalTitle,
          okButtonTitle,
          size: 'lg',
          threadFor: this.thread.id,
        })
    },
  },
}
</script>

<style scoped>

</style>
