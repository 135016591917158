<!--eslint-disable-->

<template>
  <div class="d-flex flex-column item-container pt-1 pt-md-0 flex-md-row align-items-md-center px-md-1 py-1 py-md-0">
    <div
      class="d-none d-md-flex flex-column justify-content-center justify-content-md-start"
      style="gap: .24rem"
    >
      <b-button
        v-b-tooltip.hover.right.v-success
        :variant="task.doneType === doneList.COMPLETED ? 'success' : 'outline-success'"
        size="sm"
        :disabled="!isEdit || !checkRequiredPermissions([$roles.PROJECT_MODIFY])"
        class="btn-icon p-25"
        :class="{ 'cursor-pointer': checkRequiredPermissions([$roles.PROJECT_MODIFY]) }"
        :title="$t('MarkAsSuccess')"
        @click="$emit('done', { task: task, type: task.doneType !== doneList.COMPLETED ? doneList.COMPLETED : null })"
      >
        <feather-icon icon="CheckIcon" />
      </b-button>

      <b-button
        v-b-tooltip.hover.right.v-danger
        :variant="task.doneType === doneList.REJECTED ? 'danger' : 'outline-danger'"
        size="sm"
        :disabled="!isEdit || !checkRequiredPermissions([$roles.PROJECT_MODIFY])"
        class="btn-icon p-25"
        :class="{ 'cursor-pointer': checkRequiredPermissions([$roles.PROJECT_MODIFY]) }"
        :title="$t('MarkAsFailure')"
        @click="$emit('done', { task: task, type: task.doneType !== doneList.REJECTED ? doneList.REJECTED : null })"
      >
        <feather-icon icon="MinusIcon" />
      </b-button>
    </div>

    <div class="ml-md-50 w-100 overflow-hidden py-md-1">
      <div class="d-flex flex-column align-items-start">
        <div
          class="d-flex align-items-center"
          style="gap: .5rem"
        >
          <div
            class="d-flex d-md-none flex-column"
            style="gap: .24rem"
          >
            <b-button
              v-b-tooltip.hover.v-success
              :variant="task.doneType === doneList.COMPLETED ? 'success' : 'outline-success'"
              size="sm"
              :disabled="!isEdit || !checkRequiredPermissions([$roles.PROJECT_MODIFY])"
              class="btn-icon p-25"
              :class="{ 'cursor-pointer': isEdit && checkRequiredPermissions([$roles.PROJECT_MODIFY]) }"
              :title="$t('MarkAsSuccess')"
              @click="$emit('done', { task: task, type: task.doneType !== doneList.COMPLETED ? doneList.COMPLETED : null })"
            >
              <feather-icon icon="CheckIcon" />
            </b-button>

            <b-button
              v-b-tooltip.hover.v-danger
              :variant="task.doneType === doneList.REJECTED ? 'danger' : 'outline-danger'"
              size="sm"
              :disabled="!isEdit || !checkRequiredPermissions([$roles.PROJECT_MODIFY])"
              class="btn-icon p-25"
              :class="{ 'cursor-pointer': isEdit && checkRequiredPermissions([$roles.PROJECT_MODIFY]) }"
              :title="$t('MarkAsFailure')"
              @click="$emit('done', { task: task, type: task.doneType !== doneList.REJECTED ? doneList.REJECTED : null })"
            >
              <feather-icon icon="MinusIcon" />
            </b-button>
          </div>

          <div
            class="d-flex flex-wrap flex-column flex-md-row align-items-start"
            style="column-gap: .75rem"
          >
            <strong
              v-b-tooltip.hover.v-success
              :class="{ 'cursor-pointer': isEdit && checkRequiredPermissions([$roles.PROJECT_MODIFY]) }"
              :title="$t(isEdit ? 'MarkAsSuccess' : task.doneType === null ? 'NotFinished' : 'Ended')"
              @click="() => { if (isEdit && checkRequiredPermissions([$roles.PROJECT_MODIFY])) $emit('done', { task: task, type: task.doneType !== doneList.COMPLETED && task.doneType !== doneList.REJECTED ? doneList.COMPLETED : null }) }"
            >
              <s v-if="task.doneType === doneList.COMPLETED || task.doneType === doneList.REJECTED">{{ task.name }}</s>

              <template v-else>
                {{ task.name }}
              </template>
            </strong>

            <!--      Time      -->
            <sw-date
              :date="task.endAt ? task.endAt.date : ''"
              :title="$t('MicroTaskCompletionDate')"
            />
          </div>
        </div>

        <div
          class="mt-50 d-flex flex-wrap"
          style="gap: .5rem"
        >
          <b-badge
            v-if="projectTask || task.projectTask"
            v-b-tooltip.hover.v-primary
            variant="light-primary"
            :title="$t('Offer.Project')"
          >
            <template v-if="projectTask">
              <span class="d-none d-md-inline-block">{{ projectTask.name }}</span>
              <span class="d-md-none">{{ projectTask.name | textIsland(32) }}</span>
            </template>

            <template v-else>
              <span class="d-none d-md-inline-block">{{ task.projectTask.project.name }}</span>
              <span class="d-md-none">{{ task.projectTask.project.name | textIsland(32) }}</span>
            </template>
          </b-badge>

          <b-badge
            v-b-tooltip.hover.v-success
            variant="light-success"
            :title="$t('timeline.Task')"
          >
            {{ (projectTask ? projectTask.name : task.projectTask.name) | textIsland(32) }}
          </b-badge>

          <template v-if="projectTask || task.projectTask.projectTaskCategories.length">
            <template v-if="projectTask">
              <b-badge
                v-for="category in projectTask.projectTaskCategories"
                :key="category.id"
                v-b-tooltip.hover.v-warning
                :title="$t('CategoryTask')"
                variant="light-warning"
                style="font-size: .7rem"
              >
                <span class="d-none d-md-inline-block">{{ category.name }}</span>
                <span class="d-md-none">{{ category.name | textIsland(34) }}</span>
              </b-badge>
            </template>

            <template v-else>
              <b-badge
                v-for="category in task.projectTask.projectTaskCategories"
                :key="category.id"
                v-b-tooltip.hover.v-warning
                :title="$t('CategoryTask')"
                variant="light-warning"
                style="font-size: .7rem"
              >
                <span class="d-none d-md-inline-block">{{ category.name }}</span>
                <span class="d-md-none">{{ category.name | textIsland(34) }}</span>
              </b-badge>
            </template>
          </template>

          <b-badge
            v-else
            v-b-tooltip.hover.v-warning
            :title="$t('CategoryTask')"
            variant="light-warning"
            style="font-size: .7rem"
          >
            <feather-icon icon="MinusIcon" />
          </b-badge>
        </div>
      </div>

      <div
        v-if="task.doneType === doneList.COMPLETED || task.doneType === doneList.REJECTED"
        class="d-flex justify-content-end justify-content-md-start justify-content-md-start align-items-center mt-md-25 pt-25 font-small-2"
        style="gap: .5rem"
      >
        <span class="d-none d-md-block">
          {{ $t('projects.taskDoneBy') }}
        </span>

        <b-avatar
          v-if="task.doneBy"
          v-b-tooltip.hover
          class="pull-up"
          size="16"
          :src="task.doneBy.avatar"
          :title="`${task.doneBy.firstName} ${task.doneBy.lastName}`"
        />

        {{ $t('day') }}

        <span
          v-b-tooltip.hover.v-success
          class="d-flex flex-md-row align-items-start"
          style="gap: 0 .5rem"
          :title="$t('ExecutionDate')"
        >
          <span
            class="d-inline-flex align-items-center"
            style="gap: .24rem"
          >
            <feather-icon
              icon="ClockIcon"
              size="13"
            />

            <span>
              {{ task.doneAt.date.slice(11, 16) }}
            </span>
          </span>

          <span
            class="d-inline-flex align-items-center"
            style="gap: .24rem"
          >
            <feather-icon
              icon="CalendarIcon"
              size="13"
            />

            <span>
              {{ task.createdAt.date.slice(0, 10) }}
            </span>
          </span>
        </span>
      </div>
    </div>

    <div
      class="d-flex flex-row-reverse flex-md-row justify-content-between justify-content-md-start align-items-md-center mt-25 mt-md-n25"
      style="gap: .5rem"
    >
      <div class="d-flex align-items-center">
        <b-button
          v-if="checkRequiredPermissions([$roles.PROJECT_MODIFY])"
          v-b-tooltip.hover.v-primary
          variant="flat-primary"
          size="sm"
          class="btn-icon p-25 ml-25"
          :disabled="!isEdit"
          :title="$t('Settings')"
          @click="openModalAddMicroTask()"
        >
          <feather-icon
            icon="SettingsIcon"
            size="16"
          />
        </b-button>

        <b-button
          v-if="checkRequiredPermissions([$roles.PROJECT_MODIFY])"
          v-b-tooltip.hover.v-danger
          variant="flat-danger"
          size="sm"
          class="btn-icon p-25 mx-25"
          :disabled="!isEdit"
          :title="$t('Delete')"
          @click="$emit('remove', task)"
        >
          <feather-icon
            icon="XIcon"
            size="16"
          />
        </b-button>

        <avatar-group
          :items="[task.assignedUser || '']"
          size="20"
          class="mr-25"
        />

        <b-badge
          v-b-tooltip.hover.v-primary
          variant="light-primary"
          :title="$t('AllDocuments')"
          class="d-flex align-items-center mr-auto"
        >
          <feather-icon
            icon="FileIcon"
            class="mr-25"
          />

          {{ [0, ...task.projectDocuments.map(d => d.files.length)].reduce((a, b) => a + b) }}
        </b-badge>
      </div>

      <span
        v-b-tooltip.hover.v-primary
        class="d-flex flex-md-column align-items-start font-small-2"
        style="gap: 0 .5rem"
        :title="$t('offer.CreatedTime')"
      >
        <!--        <span-->
        <!--          class="d-inline-flex align-items-center"-->
        <!--          style="gap: .24rem"-->
        <!--        >-->
        <!--          <feather-icon-->
        <!--            icon="ClockIcon"-->
        <!--            size="13"-->
        <!--          />-->

        <!--          <span>-->
        <!--            {{ task.createdAt.date.slice(11, 16) }}-->
        <!--          </span>-->
        <!--        </span>-->


            <sw-date
                :date="task.createdAt ? task.createdAt.date : ''"
                :title="$t('MicroTaskCompletionDate')"
            />

      </span>
    </div>

    <b-overlay
      no-wrap
      :show="isLoading"
      spinner-variant="primary"
    />
  </div>
</template>

<script>
/* eslint-disable */

import { VBTooltip } from 'bootstrap-vue'
import useVerticalLayout from '@core/layouts/layout-vertical/useVerticalLayout'
import { onUnmounted } from '@vue/composition-api'
import { OPEN_MODAL, REMOVE_MICRO_TASK } from '@/@constants/mutations'
import moment from 'moment/moment'
import SwDate from '@/pages/projects/components/new/components/Date.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    SwDate,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    isEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
    projectTask: {
      type: Object,
      required: false,
      default: null,
    },
    task: {
      type: Object,
      required: true,
    },
  },
  emits: ['done', 'remove'],
  setup() {
    const { isMobile, resizeHandler } = useVerticalLayout()

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    return {
      perfectScrollbarSettings,
      isMobile,
    }
  },
  data: () => ({
    doneList: {
      COMPLETED: 'COMPLETED',
      REJECTED: 'REJECTED',
      NONE: null,
    },

    moment,
  }),
  methods: {
    openModalAddMicroTask() {
      const { task } = this

      if (task.id) {
        this.$store.commit(`modal/${OPEN_MODAL}`,
          {
            modalType: 'addMicroTaskModal',
            size: 'md',
            modalTitle: this.$i18n.t('MicroTask'),
            okButtonTitle: '',
            data: {
              microTask: this.task,
            },
          })
      }
    },

    remove(task) {
      const payload = {
        id: task.id,
        project: task.project.id,
        projectTask: task.project.id,
      }

      this.$store.dispatch(`microTasks/${REMOVE_MICRO_TASK}`, payload)
        .then(res => {
          this.taskList = res
        })

        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })

        .finally(() => { this.isLoading = false })
    },
  },
}
</script>

<style scoped>
.item-container:not(:last-of-type) { border-bottom: 1px solid rgba(0 0 0 / .14) }

.item-container:last-of-type { padding-bottom: 0 !important }
</style>
