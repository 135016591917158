var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.checkRequiredPermissions([_vm.$roles.CONTACT_THREAD_PAYMENT_SCHEDULE_ADD]))?_c('b-button',{staticClass:"mb-50",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.openScheduleModal(_vm.defaultScheduleItem)}}},[_c('sw-icon',{attrs:{"icon":"PlusIcon"}}),_vm._v(" "+_vm._s(_vm.$t('AddPaymentSchedules'))+" ")],1):_vm._e(),_c('b-card',[_c('sw-table',{attrs:{"table-id":"2645411d81567e412354f3dc7ff4b3aafcf","show-columns":"","show-search":false,"fields":_vm.fields,"pagination":_vm.pagination},on:{"change-pagination":function($event){return Object.assign(_vm.pagination, $event)},"change-search-query":function($event){_vm.search = $event},"reload-content":_vm.initData,"set-fields":function($event){_vm.fields = $event}},scopedSlots:_vm._u([{key:"table",fn:function(){return [_c('b-table',{style:({ minHeight: _vm.tableSize(_vm.items, 250) }),attrs:{"items":_vm.items,"fields":_vm.tableFields,"striped":"","responsive":"","busy":_vm.loading,"show-empty":"","sort-by":_vm.sorter.sortBy,"sort-desc":_vm.sorter.sortDesc,"no-local-sorting":true},on:{"update:sortBy":function($event){return _vm.$set(_vm.sorter, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.sorter, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.sorter, "sortDesc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.sorter, "sortDesc", $event)},"sort-changed":function($event){Object.assign(_vm.sorter, $event); _vm.initData()}},scopedSlots:_vm._u([{key:"head()",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(("" + label)))+" ")]}},{key:"cell(id)",fn:function(ref){
var index = ref.index;
return [_vm._v(" #"+_vm._s(_vm.getOrderNo(index, _vm.pagination, _vm.sorter))+" ")]}},{key:"cell(netPrice)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("priceFormat")(_vm.getTotalNetPrice(item)))+" "+_vm._s(_vm.settings.defaultCurrency || 'PLN')+" ")]}},{key:"cell(status)",fn:function(ref){
var value = ref.value;
return [_c('b-badge',{attrs:{"variant":_vm.getStatusInfo(value).variant}},[_vm._v(" "+_vm._s(_vm.$t(_vm.getStatusInfo(value).label))+" ")])]}},{key:"cell(type)",fn:function(ref){
var value = ref.value;
return [(value && _vm.accountTypes.find(function (e) { return e.type === value; }))?_c('span',[_vm._v(" "+_vm._s(_vm.$t(_vm.accountTypes.find(function (e) { return e.type === value; }).label))+" ")]):_c('sw-icon',{attrs:{"icon":"MinusIcon"}})]}},{key:"cell(contactThread)",fn:function(ref){
var item = ref.item;
return [(item && item.contactThread)?_c('span',[_c('router-link',{class:{ 'deleted-text': item.contactThread.contact && item.contactThread.contact.deletedAt },attrs:{"to":("/contact/" + (item.contactThread.contact.id) + "/thread/" + (item.contactThread.id) + "/payments")}},[_vm._v(" "+_vm._s(_vm.getUserName(item.contactThread.contact))+" - "+_vm._s(item.contactThread.name)+" ")])],1):_c('span',[_c('feather-icon',{attrs:{"icon":"MinusIcon"}})],1)]}},{key:"cell()",fn:function(row){return [_c('table-default-cell',{attrs:{"field":row.field,"value":row.value}})]}},{key:"cell(documentType)",fn:function(ref){
var value = ref.value;
return [(value && _vm.documentTypes.find(function (e) { return e.type === value; }))?_c('span',[_vm._v(" "+_vm._s(_vm.$t(_vm.documentTypes.find(function (e) { return e.type === value; }).label))+" ")]):_c('sw-icon',{attrs:{"icon":"MinusIcon"}})]}},{key:"cell(documentIssuingType)",fn:function(ref){
var value = ref.value;
return [(value && _vm.documentIssuingTypes.find(function (e) { return e.type === value; }))?_c('span',[_vm._v(" "+_vm._s(_vm.$t(_vm.documentIssuingTypes.find(function (e) { return e.type === value; }).label))+" ")]):_c('sw-icon',{attrs:{"icon":"MinusIcon"}})]}},{key:"cell(serviceSellDate)",fn:function(ref){
var value = ref.value;
return [(value && _vm.serviceSellDates.find(function (e) { return e.type === value; }))?_c('span',[_vm._v(" "+_vm._s(_vm.$t(_vm.serviceSellDates.find(function (e) { return e.type === value; }).label))+" ")]):_c('span',[_vm._v(_vm._s(_vm.$t('CurrentMonth')))])]}},{key:"cell(action)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.checkRequiredPermissions([_vm.$roles.CONTACT_THREAD_PAYMENT_SCHEDULE_EDIT]))?_c('b-dropdown',{attrs:{"id":"dropdown-grouped","dropleft":"","no-caret":"","variant":"flat"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{attrs:{"icon":"MoreVerticalIcon"}})]},proxy:true}],null,true)},[_c('div',[(item.status === 'ACTIVE')?_c('b-dropdown-item',{on:{"click":function($event){return _vm.changePaymentScheduleStatus(item, index, 'HOLD')}}},[_c('dropdown-item-label',{attrs:{"icon":"PauseIcon","label":_vm.$t('Hold')}})],1):_c('b-dropdown-item',{on:{"click":function($event){return _vm.changePaymentScheduleStatus(item, index, 'ACTIVE')}}},[_c('dropdown-item-label',{attrs:{"icon":"PlayIcon","label":_vm.$t('Resume')}})],1),_c('b-dropdown-item',{on:{"click":function($event){return _vm.openScheduleModal(item, index)}}},[_c('dropdown-item-label',{attrs:{"icon":"EditIcon","label":_vm.$t('EditSchedule')}})],1)],1)]):_vm._e()]}},{key:"empty",fn:function(){return [_c('empty-content')]},proxy:true},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[_c('b-spinner',{staticClass:"align-middle",attrs:{"variant":"primary"}})],1)]},proxy:true}])})]},proxy:true}])})],1),_c('b-modal',{attrs:{"title":_vm.$t(_vm.scheduleModal.item && !_vm.scheduleModal.item.id ? 'Create' : 'Edit'),"size":"lg","hide-footer":""},model:{value:(_vm.scheduleModal.show),callback:function ($$v) {_vm.$set(_vm.scheduleModal, "show", $$v)},expression:"scheduleModal.show"}},[(_vm.scheduleModal.show)?_c('PaymentScheduleForm',{attrs:{"edited-item":_vm.scheduleModal.item},on:{"save":_vm.saveModalForm}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }