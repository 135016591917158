<!--eslint-disable-->

<template>
  <b-button-group
    v-if="processes.length"
    class="d-flex flex-wrap mb-n25"
    :class="{ 'w-100': isMobile }"
  >
    <!-- Add Offer -->
    <b-button
      v-if="checkRequiredModule('offerModule')"
      id="activeCheckout2"
      v-b-tooltip.hover.v-primary
      size="sm"
      variant="outline-primary"
      class="mb-25"
      :class="{ 'btn-icon': isMobile }"
      :title="cart.contactThread && processes.find(a => a.id === cart.contactThread.id) ? $t('offer.ActiveCheckout') : $t('offer.ActivateCheckout')"
      @click="showCartModal = true"
    >
      <feather-icon icon="ShoppingCartIcon" />

      <span class="d-none d-lg-inline ml-25">
        {{ cart.contactThread && processes.find(a => a.id === cart.contactThread.id) ? $t('offer.ActiveCheckout') : $t('offer.ActivateCheckout') }}
      </span>
    </b-button>

    <b-modal
      v-model="showCartModal"
      :title="$t('offer.ActivateCheckout')"
      hide-footer
    >
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="scrollable-container media-list scroll-area"
        tagname="div"
        style="max-height: 500px"
      >
        <b-list-group>
          <b-list-group-item
            v-for="(process, index) in processes"
            :key="`process_${index}`"
            class="d-flex flex-column flex-md-row text-wrap justify-content-between"
          >
            <avatar
              text-variant="primary"
              :user="{ ...process.contact }"
              :truncate="30"
            >
              <template #subtitle>
                <small>{{ process.name }}</small>
              </template>
            </avatar>

            <div>
              <b-button
                block
                class="mt-25"
                size="sm"
                :disabled="!(cart.contactThread && process.id !== cart.contactThread.id)"
                :variant="cart.contactThread && process.id !== cart.contactThread.id ? 'outline-primary' : 'primary'"
                @click="activateCheckout(process)"
              >
                {{ cart.contactThread && process.id !== cart.contactThread.id ? $t('Activate') : $t('Active') }}
              </b-button>
            </div>
          </b-list-group-item>
        </b-list-group>
      </vue-perfect-scrollbar>
    </b-modal>

    <!--    Modal: Create/Edit Offer     -->
    <b-modal
      v-model="showNewOfferModal"
      :title="$t('offer.Create')"
      size="md"
      hide-footer
      no-close-on-backdrop
    >
      <offer-modal />
    </b-modal>

    <!-- Add Note Nav -->
    <b-button
      v-b-tooltip.hover.v-primary
      size="sm"
      variant="outline-primary"
      class="mb-25"
      :class="{ 'btn-icon': isMobile }"
      :title="$t('NewNote')"
      @click="openModal('note', $t('NewNote'), $t('AddNote'))"
    >
      <feather-icon icon="FileIcon" />

      <span class="d-none d-lg-inline ml-25">
        {{ $t('NewNote') }}
      </span>
    </b-button>
    <!-- Add Task Nav -->
    <b-button
      v-b-tooltip.hover.v-primary
      size="sm"
      variant="outline-primary"
      class="mb-25"
      :class="{ 'btn-icon': isMobile }"
      :title="$t('NewTask')"
      @click="openModal('task', $t('NewTask'), $t('AddTask'))"
    >
      <feather-icon icon="CheckIcon" />

      <span class="d-none d-lg-inline ml-25">
        {{ $t('NewTask') }}
      </span>
    </b-button>
    <!-- Add SMS Nav -->
    <b-button
      v-b-tooltip.hover.v-primary
      size="sm"
      variant="outline-primary"
      class="mb-25"
      :class="{ 'btn-icon': isMobile }"
      :title="$t('Sms')"
      @click="openModal('sms', $t('Sms'), $t('Send'))"
    >
      <feather-icon icon="MessageCircleIcon" />

      <span class="d-none d-lg-inline ml-25">
        {{ $t('Sms') }}
      </span>
    </b-button>
    <!-- Add Mail Nav -->
    <b-button
      v-b-tooltip.hover.v-primary
      size="sm"
      variant="outline-primary"
      class="mb-25"
      :class="{ 'btn-icon': isMobile }"
      :title="$t('Email')"
      @click="openModal('mail', $t('Email'), $t('Send'))"
    >
      <feather-icon icon="MailIcon" />

      <span class="d-none d-lg-inline ml-25">
        {{ $t('Email') }}
      </span>
    </b-button>
  </b-button-group>
</template>

<script>
/* eslint-disable */

import { BButton, BButtonGroup, VBTooltip } from 'bootstrap-vue'
import * as roles from '@/helpers/permissions'
import offerModal from '@/views/modals/OfferModal.vue'
import { mapGetters } from 'vuex'
import useVerticalLayout from '@core/layouts/layout-vertical/useVerticalLayout'
import { onUnmounted } from '@vue/composition-api'
import { OPEN_MODAL, UPDATE_CART } from '@/@constants/mutations'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  name: 'UserNav',
  components: {
    BButton,
    BButtonGroup,
    offerModal,
    VuePerfectScrollbar,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
    mainThreadId: {
      type: [String, Number],
      default: null,
      required: false,
    },
    processes: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  data: () => ({
    roles,
    showCartModal: false,
    showNewOfferModal: false,
    perfectScrollbarSettings: {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    },
  }),
  computed: {
    ...mapGetters({
      cart: 'cart/getCart',
      system: 'system/getSettings',
    }),
  },
  methods: {
    changeSettings(value, place) {
      this.$set(this.cart, place, value)

      this.$nextTick(() => {
        this.$store.dispatch(`cart/${UPDATE_CART}`, { cart: this.cart })
          .then(() => {
            this.showToast('success', this.$i18n.t('CartUpdated'))
          })
      })
    },
    activateCheckout(process) {
      const {
        id, name, contact, offersTotal,
      } = process

      const thread = {
        id, name, contact,
      }

      if (offersTotal >= this.system.contactThreadOffersLimit) {
        this.showAlert('warning', '', this.$i18n.tc('ThreadOfferCountIsToBig', this.system.contactThreadOffersLimit, { limit: this.system.contactThreadOffersLimit }))
        return
      }

      if (this.thread?.id) {
        const text = this.$i18n.tc('offer.CartAlreadyActivated', 1, { thread: this.thread.name, contact: `${this.thread.contact.firstName} ${this.thread.contact.lastName}` })
        this.showAlert('error', this.$i18n.t('alerts.AreYouSure'), text)
          .then(() => {
            this.changeSettings(thread, 'contactThread')
            this.showCartModal = false
          })
      } else {
        this.changeSettings(thread, 'contactThread')
        this.showCartModal = false
      }
    },
    openModal(modalType = 'note', modalTitle = 'Nowa Notatka', okButtonTitle = 'Dodaj notatkę') {
      this.$store.commit(`modal/${OPEN_MODAL}`,
        {
          modalType,
          modalTitle,
          okButtonTitle,
          size: 'lg',
          threadFor: this.mainThreadId,
          allowThreads: this.processes,
        })
    },
  },
  setup() {
    const { isMobile, resizeHandler } = useVerticalLayout()

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    return {
      perfectScrollbarSettings,
      isMobile,
    }
  },
}
</script>

<style scoped>

</style>
