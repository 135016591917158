<template>
  <div>
    <b-alert
      variant="primary"
      show
    >
      <div class="alert-body">
        {{ $t('AIReportTip') }}
      </div>
    </b-alert>

    <validation-observer
      v-slot="{ invalid }"
      tag="form"
    >
      <validation-provider
        v-slot="{ errors }"
        :name="$t('ReasonForReporting')"
      >
        <b-form-group>
          <sw-select
            :name="$t('ReasonForReporting')"
          >
            <v-select
              v-model="reason"
              :placeholder="$t('ReasonForReporting')"
              :options="typeList"
              :filterable="false"
              :state="errors.length > 0 ? false:null"
              :clearable="false"
            >
              <template #no-options>
                {{ $t('NoOptions') }}
              </template>

              <template #option="{ label }">
                {{ $t(`ai.reportsTypes.${label}`) }}
              </template>

              <template #selected-option="{ label }">
                {{ $t(`ai.reportsTypes.${label}`) }}
              </template>
            </v-select>
          </sw-select>

          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>

      <validation-provider
        v-slot="{ errors }"
        :name="$t('Message')"
        rules="required"
        class="w-100"
      >
        <b-form-group :label="$t('Message')">
          <b-form-textarea
            id="popover-bannerDesc"
            v-model.trim="message"
            :placeholder="$t('Message')"
            :state="errors.length > 0 ? false:null"
            maxlength="110"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>

      <div class="mt-1 d-flex justify-content-end">
        <b-button
          type="button"
          variant="primary"
          size="sm"
          :disabled="invalid || isLoading"
          @click="save"
        >
          {{ $t('Send') }}
        </b-button>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { BFormTextarea } from 'bootstrap-vue'
import { CREATE_OPEN_AI_REPORT_MESSAGE, HIDE_MODAL } from '@/@constants/mutations'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: false,
      default: null,
    },
  },

  data: () => ({
    isLoading: false,

    reason: null,
    message: '',

    typeList: [
      {
        label: 'NO_ANSWER',
        type: 'NO_ANSWER',
      },
      {
        label: 'PRIVACY_POLICY_VIOLATION',
        type: 'PRIVACY_POLICY_VIOLATION',
      },
      {
        label: 'OTHER',
        type: 'OTHER',
      },
    ],

    REPORT_TYPE: {
      NO_ANSWER: 'NO_ANSWER',
      PRIVACY_POLICY_VIOLATION: 'PRIVACY_POLICY_VIOLATION',
      OTHER: 'OTHER',
    },
  }),

  methods: {
    async save() {
      this.isLoading = true
      const payload = this.getPayload()

      if (payload) {
        await this.send(payload)
      } else {
        this.isLoading = false
      }
    },

    getPayload() {
      const { data, message, reason } = this

      if (data.content && message) {
        return {
          content: data.content,
          message,
          reason: reason.type,
          source: data.source,
          contactThread: data?.contactThread ? this.getObjectId(data.contactThread) : null,
        }
      }

      return null
    },

    async send(payload) {
      this.isLoading = true

      this.$store.dispatch(`ai/${CREATE_OPEN_AI_REPORT_MESSAGE}`, payload)
        .then(() => {
          this.showToast('success', this.$i18n.t('success.thankYou'))

          this.closeModal()
        })

        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err.content.data.message}`))
        })

        .finally(() => {
          this.isLoading = false
        })
    },

    closeModal() {
      this.$store.dispatch(`swModal/${HIDE_MODAL}`, this.id)
    },
  },
}
</script>
