<template>
  <b-row>
    <b-col>
      <transition
        name="slide-fade"
        mode="out-in"
      >
        <ranks-list
          v-if="!selectedRank"
          key="list"
        />
        <rank-details
          v-else
          key="details"
        />
      </transition>
    </b-col>
  </b-row>
</template>

<script>
import RankDetails from '@/views/settings/ranks/RankDetails.vue'
import RanksList from '@/views/settings/ranks/RanksList.vue'

export default {
  name: 'Ranks',
  components: {
    RanksList,
    RankDetails,
  },
  computed: {
    selectedRank() {
      return this.$route.params?.rankId
    },
  },
}
</script>

<style scoped>

</style>
