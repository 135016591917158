<template>
  <div>
    <!--    <div-->
    <!--      class="mb-1 d-flex"-->
    <!--      style="gap: .74rem"-->
    <!--    >-->
    <!--      <b-button-->
    <!--        variant="primary"-->
    <!--        size="sm"-->
    <!--      >-->
    <!--        {{ $t('ExpandAll') }}-->

    <!--        <feather-icon icon="ChevronsDownIcon" />-->
    <!--      </b-button>-->

    <!--      <b-button-->
    <!--        variant="primary"-->
    <!--        size="sm"-->
    <!--      >-->
    <!--        {{ $t('CollapseAll') }}-->

    <!--        <feather-icon icon="ChevronsUpIcon" />-->
    <!--      </b-button>-->
    <!--    </div>-->

    <b-card class="mb-1">
      <b-row>
        <b-col sm="12">
          <div class="flex-grow-1 mr-1 order-2 mb-50">
            <b-input-group
              class="input-group-merge search-input-group mx-25 mb-25"
              style="min-width: 150px"
            >
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                v-model="search"
                :placeholder="$t('Search')"
                class="input-search"
                @input="isTyping = true"
                @blur="init"
              />
            </b-input-group>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <div v-if="!loading && !isTyping">
      <div v-if="items.length">
        <sw-multilevel-structure
          v-for="(item, index) in items"
          :key="index"
          :root-item="item"
          show-root
          :off="!item['children'].length"
          show-childs
          child-key="children"
        >
          <template #root-title="{ item }">
            <div class="d-flex align-items-center justify-content-between w-100">
              <div>
                <div class="font-small-3 flex-grow-1">
                  <b-link v-if="item && item.organization && item.organization.id"
                          :to="`/organizations/${item.organization.id}`"
                          target="_blank"
                  >
                    <sw-icon
                      icon="ExternalLinkIcon"
                      class="text-success mr-25 cursor-pointer"
                    />
                  </b-link>
                  <span v-if="item && item.organization && item.organization.name">
                    {{ item.organization.name }}
                  </span>
                  <feather-icon v-else
                                icon="MinusIcon"
                  />
                </div>
              </div>

              <div>
                <b-badge v-if="item && item.organization && item.organization.vatId"
                         v-b-tooltip
                         class="mx-25"
                         variant="light-primary"
                         :title="$t('VatId')"
                >
                  {{ item.organization.vatId }}
                </b-badge>

                <b-badge v-if="item && item.organization && item.organization.vatPayer"
                         class="mx-25"
                         variant="light-primary"
                >
                  {{ $t('VatPayer') }}
                </b-badge>

                <b-badge v-if="item && item.organization"
                         v-b-tooltip
                         class="mx-25"
                         variant="light-primary"
                         :title="$t('OrganizationBranches')"
                >
                  <sw-icon icon="HomeIcon" />
                  {{ item.organization.organizationBranchesCount || 0 }}
                </b-badge>
              </div>
            </div>
          </template>
        </sw-multilevel-structure>
      </div>
      <div
        v-else
        class="text-center py-1 text-primary font-weight-bold"
      >
        <feather-icon
          size="17"
          icon="XCircleIcon"
        />
        {{ $t('NoData') }}
      </div>
    </div>
    <b-card v-else>
      <div class="d-flex justify-content-center align-items-center">
        <b-spinner variant="primary" />
      </div>
    </b-card>
  </div>
</template>

<script>
import axiosIns from '@/libs/axios'
import { VBTooltip } from 'bootstrap-vue'
// import Item from './components/Item.vue'

export default {
  components: {
    // Item,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    rootUserId: {
      default: null,
      required: false,
      type: [String, Number],
    },
  },
  data: () => ({
    loading: true,
    isTyping: false,
    items: [],
    search: '',
    ranks: [],
    filters: {
      ranks: [],
      month: '',
      year: '',
      marketingPlan: null,
    },

    months: [
      { label: 'January', value: 1 },
      { label: 'February', value: 2 },
      { label: 'March', value: 3 },
      { label: 'April', value: 4 },
      { label: 'May', value: 5 },
      { label: 'June', value: 6 },
      { label: 'July', value: 7 },
      { label: 'August', value: 8 },
      { label: 'September', value: 9 },
      { label: 'October', value: 10 },
      { label: 'November', value: 11 },
      { label: 'December', value: 12 },
    ],
    years: [],

    currentMonth: null,
    currentYear: null,
    marketingPlans: [],
  }),
  async mounted() {
    const startYear = 2022
    const currentYear = (new Date()).getFullYear()

    this.currentYear = currentYear
    this.currentMonth = (new Date()).getMonth() + 1

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i <= currentYear - startYear; i++) {
      this.years.push(startYear + i)
    }

    await this.init()
  },
  methods: {
    async init() {
      this.loading = true
      let params = {}

      if (this.search?.length) {
        const search = this.search.replaceAll(' ', '%')
        params = {
          search: `%${search}%`,
          search_fields: 'name,vatId',
        }
      }

      /* eslint-disable */
      const organizationsTree = await axiosIns.get('1/organizations/getStructure', { params })
      const data = organizationsTree.data.data.map(structureRecord => {
          if (!organizationsTree.data.data.find(e => e.organization.id === structureRecord.organization?.parentOrganization?.id)) {
              return { ...structureRecord, organization: { ...structureRecord.organization, parentOrganization: null } }
          }

          return { ...structureRecord }
      })
      this.items = await this.createNested(data || [], null) || []

      this.loading = false
      this.isTyping = false
    },
    // eslint-disable-next-line no-unused-vars
    async createNested(data, parentId = null) {
      const result = []
        for (const item of data) {
          if (
              (parentId === null && item.organization.parentOrganization === null) ||
              (item.organization.parentOrganization && item.organization.parentOrganization.id === parentId)
          )
          {
              const newItem = {
                  organization: item.organization,
                  children: await this.createNested(data, item.organization.id),
              }

              result.push(newItem)
          } else if (
              item.organization.parentOrganization && !data.find(e => e.organization?.id === item.organization.parentOrganization?.id)
          ) {
              const newItem = {
                  organization: item.organization,
                  products: item.products,
                  children: await this.createNested(data.filter(e => item?.organization?.id === e?.organization?.id), item.organization.id),
              }

              result.push(newItem)
          }
      }

        return result
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
