<template>
<!--  <ExpandWindow :title="$t('FullWindow')">-->
  <Email :thread="thread" />
<!--  </ExpandWindow>-->
</template>

<script>
// import ExpandWindow from '@core/components/@sw/ExpandWindow.vue'
import Email from '@/views/core/singleContact/tabs/Emails.vue'

export default {
  name: 'EmailExpand',
  components: { Email },
  props: {
    thread: {
      default: null,
    },
  },
}
</script>

<style scoped>

</style>
