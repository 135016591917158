<template>
  <div>
    <b-form-group>
      <b-form-input v-model="search" :placeholder="$t('TypeToSearch')" />
    </b-form-group>

    <b-row>
      <b-col
        v-for="(file, index) in filteredStaticFiles"
        :key="`file_${index}`"
        sm="12"
        md="6"
      >
        <list-card :item="file" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { trainings } from '@/@data/staticFiles'
import ListCard from '@/pages/onboarding/components/ListCard.vue'

export default {
  name: 'List',
  components: { ListCard },
  data: () => ({
    staticFiles: trainings,
    search: '',
  }),
  computed: {
    filteredStaticFiles() {
      if (this.search) {
        return this.staticFiles.filter(file => this.$i18n.t(file.name).toLowerCase().includes(this.search.toLowerCase()))
      }

      return this.staticFiles
    },
  },
}
</script>

<style scoped>

</style>
