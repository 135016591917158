<template>
  <div>
    <div>
      <h5>{{ $t('Styles.Buttons') }}</h5>
      <div class="d-flex justify-content-between">
        <b-button :variant="`${color}`">
          Lorem
        </b-button>
        <b-button :variant="`flat-${color}`">
          Lorem
        </b-button>
        <b-button :variant="`outline-${color}`">
          Lorem
        </b-button>
        <b-button
          :variant="`${color}`"
          class="btn-icon"
        >
          <feather-icon icon="UserIcon" />
        </b-button>
      </div>
    </div>
    <div class="mt-1">
      <h5>{{ $t('Styles.Text') }}</h5>
      <p :class="[`text-${color}`]">
        Lorem ipsum dolor <strong>sit amet</strong>, consectetur adipisicing elit. <i>Accusamus, consequuntur cumque cupiditate</i> delectus dignissimos dolorum illo in, ipsam laudantium magni maxime mollitia officia quaerat quibusdam ratione reiciendis sapiente vel veniam.
      </p>
    </div>
    <div>
      <h5>{{ $t('Styles.Badges') }}</h5>
      <div class="d-flex">
        <b-badge
          :variant="`${color}`"
          class="mr-1"
        >
          Lorem
        </b-badge>
        <b-badge
          :variant="`light-${color}`"
          class="mr-1"
        >
          Lorem
        </b-badge>
        <b-badge
          :variant="`${color}`"
          class="badge-glow mr-1"
        >
          Lorem
        </b-badge>
      </div>
    </div>
    <div class="mt-1">
      <h5>{{ $t('Styles.Alerts') }}</h5>
      <b-alert
        :variant="color"
        show
      >
        <h4 class="alert-heading">
          Lorem ipsum dolor sit amet
        </h4>
        <div class="alert-body">
          Lorem ipsum dolor sit amet
        </div>
      </b-alert>
    </div>
  </div>
</template>

<script>
import { BAlert } from 'bootstrap-vue'

export default {
  name: 'ColorPreview',
  components: {
    BAlert,
  },
  props: {
    color: {
      default: 'primary',
      type: String,
    },
  },
}
</script>

<style scoped>

</style>
