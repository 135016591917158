<template>
  <div>
    <b-card>
      <sw-table
        table-id="1cee94f00f8a4e2a8605e675e15198a9"
        :pagination="pagination"
        @change-pagination="Object.assign(pagination, $event)"
        @reload-content="loadLogs"
      >
        <template #table>
          <b-table
            striped
            responsive
            :items="items"
            :fields="fields"
            :per-page="pagination.perPage"
            class="mb-0"
            :busy="isLoading"
            @reload-content="loadLogs"
          >
            <template #head()="{ label }">
              {{ $t(`${label}`) }}
            </template>

            <template #cell(name)="{ item }">
              <span v-if="item.automationProcedure"
                    class="py-50"
              >
                <router-link v-if="checkRequiredPermissions([$roles.AUTOMATION_MODIFY])"
                             :to="{ name: 'automations', params: { automationId: item.automationProcedure.id } }"
                >
                  {{ item.automationProcedure.name }}
                </router-link>
                <span v-else>{{ item.automationProcedure.name }}</span>
              </span>
              <feather-icon v-else
                            icon="MinusIcon"
              />
            </template>

            <template #cell(trigger)="{ item }">
              <b-badge v-if="item.automationProcedure"
                       variant="light-primary"
              >
                {{ $t(automationTriggerTypeByValue(item.automationProcedure.automationProcedureTrigger.type)) }}
              </b-badge>
            </template>

            <template #cell(actions)="{ item }">
              <b-badge v-if="item.automationProcedure"
                       variant="primary"
              >
                {{ item.automationProcedure.automationProcedureConditions ? item.automationProcedure.automationProcedureConditions.length : 0 }}
              </b-badge>
            </template>

            <template #cell(conditions)="{ item }">
              <b-badge v-if="item.automationProcedure"
                       variant="primary"
              >
                {{ item.automationProcedure.automationProcedureActions ? item.automationProcedure.automationProcedureActions.length : 0 }}
              </b-badge>
            </template>

              <!--    Other    -->
              <template #cell()="row">
                  <table-default-cell
                          :field="row.field"
                          :value="row.value"
                  />
              </template>

              <!--      Empty      -->
              <template #empty>
                  <empty-content />
              </template>

              <!--      Table Busy      -->
              <template #table-busy>
                  <div class="text-center text-danger my-2">
                      <b-spinner
                              class="align-middle"
                              variant="primary"
                      />
                  </div>
              </template>
          </b-table>
        </template>
      </sw-table>
    </b-card>
  </div>
</template>

<script>
import { BTable, VBTooltip } from 'bootstrap-vue'
import axiosIns from '@/libs/axios'
import { formatDate } from '../../@core/utils/filter'
import { automationTriggerTypeByValue } from '../../@types/automationsTypes'

export default {
  components: {
    BTable,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },

  props: {
    automationId: {
      default: null,
      required: false,
      type: [String, Number],
    },
  },

  data: () => ({
    isLoading: false,
    sorter: {
      sortBy: 'createdAt',
      sortDesc: true,
    },
    pagination: {
      perPage: 25,
      currentPage: 1,
      totalRecords: 100,
    },

    fields: [
      {
        key: 'createdAt',
        label: 'Done1',
      },
      {
        key: 'name',
        label: 'Automation',
      },
      {
        key: 'trigger',
        label: 'Trigger',
      },
      {
        key: 'actions',
        label: 'Actions',
      },
      {
        key: 'conditions',
        label: 'AdditionalConditions',
      },
    ],
    items: [],
  }),

  mounted() {
    this.ini()
  },

  methods: {
    automationTriggerTypeByValue,
    formatDate,
    ini() {
      this.loadLogs()
    },

    async loadLogs() {
      this.isLoading = true

      const params = {
        fields_load: this.$fields.SINGLE_AUTOMATION_LOG,
        orderBy: 'id.desc',
      }

      if (this.pagination) {
        params.page = this.pagination.currentPage
        params.limit = this.pagination.perPage
      }

      try {
        const url = this.automationId ? `1/automations/procedures/${this.automationId}/logs` : '1/automations/procedures/logs'
        const resp = await axiosIns.get(url, { params })

        this.items = resp?.data?.data?.items || []
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
