<template>
  <sw-tabs
    lazy
    :tabs="tabs"
    :card="false"
  />
<!--  <sw-top-page-bar-->
<!--    v-model="activeTab"-->
<!--    is-tab-view-->
<!--    :tab-view-list="tabList"-->
<!--  >-->
<!--    <template v-if="activeTab">-->
<!--      <div-->
<!--        v-if="activeTab.title === tabList[0].title"-->
<!--        :key="0"-->
<!--      >-->
<!--        <projects />-->
<!--      </div>-->

<!--      <div-->
<!--        v-if="activeTab.title === tabList[1].title"-->
<!--        :key="1"-->
<!--      >-->
<!--        <tasks />-->
<!--      </div>-->

<!--      <div-->
<!--        v-if="activeTab.title === tabList[2].title"-->
<!--        :key="2"-->
<!--      >-->
<!--        <micro-tasks />-->
<!--      </div>-->
<!--    </template>-->
<!--  </sw-top-page-bar>-->
</template>

<script>
import Projects from './projects/List.vue'
import Tasks from './tasks/List.vue'
import MicroTasks from './MicroTasks/List.vue'

export default {
  data: () => ({
    activeTab: null,
    tabs: [
      {
        title: 'Projects',
        component: Projects,
        icon: 'GridIcon',
      },
      {
        title: 'Tasks',
        component: Tasks,
        icon: 'CheckSquareIcon',
      },
      {
        title: 'microTasks',
        component: MicroTasks,
        icon: 'SquareIcon',
      },
    ],
  }),
}
</script>
