<!--eslint-disable-->

<template>
  <b-card v-if="filters">
    <b-row>
      <b-col
        md="6"
        lg="4"
      >
        <sw-select
          :name="$t('Seller')"
        >
          <assigned-users
            :value="filters.seller"
            @input="payload => filters.seller = payload"
          />
        </sw-select>
      </b-col>

      <b-col
        md="6"
        lg="4"
      >
        <sw-select
          :name="$t('CommissionFor')"
        >
          <assigned-users
            :value="filters.provisionFor"
            @input="payload => filters.provisionFor = payload"
          />
        </sw-select>
      </b-col>

      <b-col
        md="6"
        lg="4"
      >
        <sw-select :name="$t('task.Type')">
          <v-select
              v-model="filters.type"
              :options="types"
          >
            <template #no-options>
              {{ $t('NoOptions') }}
            </template>
            <template #option="{ label }">
              {{ $t(label) }}
            </template>
            <template #selected-option="{ label }">
              {{ $t(label) }}
            </template>
          </v-select>
        </sw-select>
      </b-col>

        <b-col
                md="6"
                lg="4"
        >
            <sw-select :name="$t('OrderDate')">
                <sw-select-fpr
                    :is-empty="filters.orderDate"
                    @clear="filters.orderDate = ''"
                >
                    <flat-pickr
                            ref="timeRange"
                            v-model="filters.orderDate"
                            class="form-control"
                            :config="{ ...flatPickerConfig, locale: getCalendarLocale($i18n.locale) }"
                    />
                </sw-select-fpr>
            </sw-select>
        </b-col>

      <!--      <b-col-->
      <!--        md="6"-->
      <!--        lg="4"-->
      <!--      >-->
      <!--        <sw-select :name="$t('DateOfConclusionOfContract')">-->
      <!--          <sw-select-fpr>-->
      <!--            <flat-pickr-->
      <!--              ref="timeRange"-->
      <!--              v-model="filters.agreementDate"-->
      <!--              class="form-control"-->
      <!--              :config="{ ...flatPickerConfig, locale: getCalendarLocale($i18n.locale) }"-->
      <!--            />-->
      <!--          </sw-select-fpr>-->
      <!--        </sw-select>-->
      <!--      </b-col>-->
    </b-row>
  </b-card>
</template>

<script>
import AssignedUsers from '@/views/components/AssignedUsers.vue'
import flatPickr from 'vue-flatpickr-component'
import { Polish as pl } from '@/libs/i18n/locales/flatpickr/pl'
import { english as en } from '@/libs/i18n/locales/flatpickr/en'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
    AssignedUsers,
    flatPickr,
  },
  props: {
    filters: {
      required: true,
      default: null,
      type: Object,
    },
  },
  data: () => ({
    flatPickerConfig: {
      mode: 'range',
      locale: [pl, en],
    },
    types: [
      'COMMISSION',
      'WEBSITE_TAKEOVER',
      'COMMISSION_CORRECTION',
    ],
  }),
  computed: {
    locale() {
      return this.$i18n.locale
    },
  },
}
</script>
