<template>
  <div id="offer-settings">
    <h4>{{ $t('General') }}</h4>
    <b-card>
      <b-form-group
        :label="$t('MaxOffers')"
      >
        <b-form-input
          id="contactThreadOffersLimit"
          v-model="system.contactThreadOffersLimit"
          type="number"
        />
      </b-form-group>

      <b-form-group>
        <b-form-checkbox
          v-model="hideOfferProcessInCart"
          checked="true"
          name="check-button"
          variant="primary"
          switch
          inline
          class="w-100"
        >
          {{ $t('HideOfferProcess') }}
        </b-form-checkbox>
      </b-form-group>

      <b-form-group class="mb-0">
        <b-form-checkbox
          v-model="allowEditingOfferEmail"
          checked="true"
          name="check-button"
          variant="primary"
          switch
          inline
          class="w-100 py-25"
        >
          {{ $t('AllowEditingOfferEmail') }}
        </b-form-checkbox>
      </b-form-group>
    </b-card>

    <b-overlay
      no-wrap
      :show="loading"
      spinner-variant="primary"
    />

    <offer-email
      @save="save"
    />
  </div>
</template>

<script>
import OfferEmail from '@/views/core/admin/templatesTabs/OfferEmail.vue'
import {
  GET_SETTINGS, SAVE_OFFER_EMAIL, SAVE_SETTINGS,
} from '@/@constants/mutations'
import { mapGetters } from 'vuex'

export default {
  name: 'OfferSettings',
  components: {
    OfferEmail,
  },
  data: () => ({
    loading: false,
    allowEditingOfferEmail: false,
    hideOfferProcessInCart: false,
  }),
  computed: {
    ...mapGetters({
      system: 'system/getSettings',
    }),
  },
  async mounted() {
    this.loading = true
    try {
      const resp = await this.$store.dispatch(`system/${GET_SETTINGS}`)
      this.allowEditingOfferEmail = resp.allowEditingOfferEmail || false
      this.hideOfferProcessInCart = resp.hideOfferProcessInCart || false
    } catch (err) {
      this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
    } finally {
      this.loading = false
    }
  },
  methods: {
    async save(translations) {
      this.loading = true
      this.system.allowEditingOfferEmail = this.allowEditingOfferEmail
      this.system.hideOfferProcessInCart = this.hideOfferProcessInCart

      try {
        await Promise.all([this.saveTemplate(translations), this.saveSettings()])
        this.showToast('success', this.$i18n.t('success.contactUpdated'))
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.loading = false
      }
    },
    saveSettings() {
      const d = {
        allowEditingOfferEmail: this.allowEditingOfferEmail,
        hideOfferProcessInCart: this.hideOfferProcessInCart,
      }

      if (this.hideOfferProcessInCart) {
        d.defaultCartOrderProcess = true
        d.hideOrderProcessInCart = false
      }
      return this.$store.dispatch(`system/${SAVE_SETTINGS}`, {
        savePayload: d,
      })
    },
    saveTemplate(payload) {
      return this.$store.dispatch(`templates/${SAVE_OFFER_EMAIL}`, payload)
    },
    copyValue(value) {
      this.$copyText(value, this.$el).then(() => {
        this.showToast('primary', this.$i18n.t('CopiedToClipboard'))
        this.$root.$emit('bv::hide::popover')
      }, e => {
        this.showToast('success', e)
      })
    },
  },
}
</script>

<style lang="scss">
#offer-settings {
    .popover {
        min-width: 280px;
        max-width: 350px !important;
        .popover-body {
            padding: 0.65rem 0 !important;
        }
    }
}
</style>
