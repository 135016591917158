<template>
  <b-alert
    show
    variant="success"
    class="p-2 d-flex flex-wrap align-items-center justify-content-between"
    style="row-gap: .5rem"
  >
    <div class="d-flex align-items-center">
      <b-avatar variant="success">
        <feather-icon icon="DollarSignIcon" />
      </b-avatar>

      <div class="d-flex flex-column ml-75">
        <strong>
          {{ $t('ForSettlement') }}
        </strong>

        <div>
          <span>
            {{ $t('NetAmount') }}:
            <strong>{{ val | priceFormat }}</strong>
            {{ settings.defaultCurrency || 'PLN' }} |
          </span>
          <span>
            {{ $t('Gross') }}:
            <strong>{{ valGross | priceFormat }}</strong>
            {{ settings.defaultCurrency || 'PLN' }} |
          </span>
          <span>
            {{ $t('VAT') }}:
            <strong>{{ valGross - val | priceFormat }}</strong>
            {{ settings.defaultCurrency || 'PLN' }}
          </span>
        </div>
      </div>

      <b-overlay
        :show="isLoading"
        no-wrap
        spinner-variant="success"
      />
    </div>

    <div>
      <b-button
        :disabled="disabled"
        variant="success"
        @click="$emit('openModal')"
      >
        {{ $t('Settle') }}
      </b-button>
    </div>
  </b-alert>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    val: {
      type: Number,
      required: true,
    },
    valGross: {
      type: Number,
      required: true,
    },
  },
  emits: ['openModal'],
  computed: {
    ...mapGetters({ settings: 'system/getSettings' }),
  },
}
</script>
