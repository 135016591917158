<template>
  <div>
    <!--    Option: Name     -->
    <b-form-group :label="$t('Name')">
      <b-form-input
        v-model="item.name"
        :placeholder="$t('Name')"
      />
    </b-form-group>
    <!--    Option: Permissions     -->
    <sw-select :name="`${$t('ShareTo')} ${$t('Permissions')}`">
      <v-select
        v-model.trim="item.sharedRanks"
        multiple
        :placeholder="$t('Share')"
        label="name"
        :options="shareRankList"
      >
        <template #option="value">
          {{ value.name }}
        </template>
        <template #selected-option="value">
          {{ value.name }}
        </template>

        <template #no-options>
          {{ $t('NoOptions') }}
        </template>
      </v-select>
    </sw-select>

    <!--    Option: Users     -->
    <sw-select :name="`${$t('ShareTo')} ${$t('Users')}`">
      <assigned-users
        :value="item.sharedUsers"
        :is-multiple="true"
        @input="changeAssignedUsers"
      />
    </sw-select>

    <b-button
      variant="success"
      size="sm"
      :disabled="!item.name"
      @click="$emit('save-item', item)"
    >
      {{ $t('Save') }}
    </b-button>
  </div>
</template>

<script>
import axiosIns from '@/libs/axios'
import vSelect from 'vue-select'
import AssignedUsers from '@/views/components/AssignedUsers.vue'

export default {
  name: 'NewFolderModal',
  components: {
    vSelect,
    AssignedUsers,
  },
  props: {
    editedFolder: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    item: {
      name: '',
      sharedRanks: [],
      sharedUsers: [],
    },
    isModalOpen: false,
    shareRankList: [],
    shareUserList: [],
  }),
  mounted() {
    this.getShare()
    if (this.editedFolder) {
      Object.keys(this.editedFolder).forEach(key => {
        if (key === 'id') this.item.id = this.editedFolder.id
        // eslint-disable-next-line no-prototype-builtins
        else if (this.item.hasOwnProperty(key)) this.item[key] = this.editedFolder[key]
      })

      // console.log(this.item)
    }
  },
  methods: {
    changeAssignedUsers(payload) {
      this.item.sharedUsers = payload
    },
    async getShare() {
      await axiosIns.get('1/ranks', { params: { fields_load: this.$fields.RANKS } })
        .then(res => {
          this.shareRankList = res.data.data.items
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
  },
}
</script>

<style scoped>

</style>
