import { countryCodes } from '@/@data/countryCodes'
import store from '@/store'

// Return price in thousand format
// 1000000 -> 1 000 000.00
const priceFormat = value => {
  const parts = parseFloat(value).toFixed(2).toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  return parts.join(',')
}

const priceGrossFormat = value => {
  const parts = parseFloat(value).toFixed(2).toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

  if (store.getters['system/getSettings'].removeGrossEnds) return parts[0]
  return parts.join(',')
}

// Return number divided by 3 block segments (except code [+48, +1289])
const phoneFormat = value => {
  let phone = value ?? ''

  if (phone?.value) phone = phone.value // Reactive variable ??? XD

  const code = countryCodes.find(({ dial_code }) => phone.includes(dial_code))
  if (code) {
    phone = phone.replace(code.dial_code, '')
  }

  return `${code?.dial_code ?? ''}${phone.substr(0, 3)
  } ${phone.substr(3, 3)
  } ${phone.substr(6, phone.length - 6)}`
}

// Return Html formatted content
const htmlFormat = value => {
  const d = document.createElement('div')
  d.innerHTML = `<div>${value}</div>`
  if (d.innerHTML && d.querySelector('#saleswizard-content-html')) return d.querySelector('#saleswizard-content-html').innerHTML

  return value?.replaceAll('\n', '<br />')
}

const htmlToText = value => {
  const div = document.createElement('div')
  div.innerHTML = value

  return div.textContent || div.innerText || ''
}

const dateFormat = value => value.split('.')[0]

const dateFormatShort = value => value.split('.')[0].toString().split(' ')[0]

export {
  htmlFormat, priceFormat, phoneFormat, htmlToText, priceGrossFormat, dateFormat, dateFormatShort,
}
