<template>
  <div>
    <b-card
      no-body
      class="p-1"
    >
      <b-form-group
        :label="$t('assignOrder')"
      >
        <v-select
          id="offer-thread-1"
          v-model="thread"
          :options="threads"
          label="name"
          :filterable="false"
          :selectable="(thread) => thread.offersTotal < system.contactThreadOffersLimit"
          @search="getThreads"
        >
          <template #no-options="{ search }">
            <span v-if="search.length">
              {{ $t('NoData') }}
            </span>
            <span v-else>
              {{ $t('TypeToSearch') }}
            </span>
          </template>
          <template #option="{ name, contact, offersTotal }">
            <div class="d-flex align-items-center">
              <avatar
                :user="contact"
                class="mr-25"
              />  - {{ name }}
              <feather-icon
                v-if="offersTotal >= system.contactThreadOffersLimit"
                v-b-tooltip
                icon="AlertCircleIcon"
                class="ml-50"
                :title="$tc('ThreadOfferCountIsToBig', system.contactThreadOffersLimit, { limit: system.contactThreadOffersLimit })"
              />
            </div>
          </template>

          <template #selected-option="{ name, contact }">
            <div class="d-flex">
              <avatar
                :user="contact"
                class="mr-25"
              />  - {{ name }}
            </div>
          </template>
        </v-select>
      </b-form-group>
    </b-card>

    <b-row class="d-flex">
      <b-col
        sm="12"
        md="12"
        lg="8"
        class="position-relative"
      >
        <!--    Cart Items    -->
        <new-contract-modal
          :key="contactKey"
          ref="contract-modal"
          :total-gross="totalGross"
          :payment-schedule="cartProducts[0].shopProduct.shopCategories[1].paymentSchedule || null"
          :thread="cart.contactThread"
          :cart="cart.id"
          hide-footer
          tag="b-card"
          :show-top="false"
          class="position-relative"
        />
      </b-col>

      <b-col
        sm="12"
        md="12"
        lg="4"
        class="pl-lg-25 pl-md-1"
      >
        <b-card>
          <b-button
            variant="primary"
            class="mb-50 mr-50 w-100"
            @click="$emit('nextStep')"
          >
            {{ $t('Next') }}
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import axiosIns from '@/libs/axios'
import NewContractModal from '@/views/modals/NewContractModal.vue'

export default {
  components: {
    vSelect,
    NewContractModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    totalGross: Number,
  },
  data: () => ({
    threads: [],
    thread: '',
  }),
  computed: {
    ...mapGetters({
      cart: 'cart/getCart',
      system: 'system/getSettings',
      cartProducts: 'cart/getCartProducts',
    }),
    contactKey() {
      return this.cart?.contactThread?.id ?? 0
    },
  },
  methods: {
    getThreads(search, loading) {
      if (search.length) {
        loading(true)
        // eslint-disable-next-line no-underscore-dangle
        const _search = search.replaceAll(' ', '%')
        const params = {
          fields: [
            'id',
            'name',
            'contact.firstName',
            'contact.lastName',
            'offersTotal',
          ].join(','),
          search: `%${_search}%`,
          search_fields: 'name,contact-firstName,contact-lastName',
        }
        axiosIns.get('1/contacts/threads', { params })
          .then(res => {
            this.threads = res.data.data.items
            loading(false)
          })
      }
    },
  },
}
</script>
