<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link
          class="brand-logo"
          style="margin-bottom: 1rem; margin-top: .5rem"
        >
          <b-img
            :src="system.logo"
            style="max-width: 175px; width: 100%"
          />
        </b-link>

        <div v-if="!isSuccess">
          <b-card-title class="mb-1">
            {{ $t('ForgetPassword') }}
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t('TypeEmailAddressToRemindPassword') }}
          </b-card-text>
        </div>
        <!-- form -->
        <validation-observer
          ref="loginForm"
          v-slot="{invalid}"
        >
          <!-- Set password form -->
          <b-form
            v-if="!$route.params.token && !isSuccess"
            class="auth-login-form mt-50"
            @submit.prevent="sendEmail"
          >
            <!-- email -->
            <b-form-group
              label="Email"
              label-for="forgot-password-email"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="forgot-password-email"
                  v-model="emailAddress"
                  :state="errors.length > 0 ? false:null"
                  name="forgot-password-email"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid || loading"
            >
              <b-spinner
                v-if="loading"
                small
              />
              <span v-else>{{ $t('SendResetLink') }}</span>
            </b-button>
          </b-form>

          <b-form
            v-if="$route.params.token && !isSuccess"
            class="auth-login-form mt-50"
            @submit.prevent="setPassword"
          >
            <input
              type="email"
              :value="user.email"
              disabled
              style="display: none"
            >
            <!-- password -->
            <b-form-group>
              <validation-provider
                v-slot="{ errors }"
                :name="$t('login.Password')"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    placeholder="********"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                    <feather-icon
                      id="popover-password-requirements"
                      class="cursor-pointer ml-50"
                      :class="{ 'text-danger': !passwordMatch }"
                      icon="AlertCircleIcon"
                    />

                    <b-popover
                      ref="popover-show"
                      target="popover-password-requirements"
                      triggers="hover"
                    >
                      <template #title>
                        {{ $t('PasswordRequirements.PasswordRequirements') }}
                      </template>
                      <p
                        v-for="req in passwordRequirements"
                        :key="req"
                        class="text-muted"
                      >
                        {{ $t(`PasswordRequirements.${req}`) }}
                      </p>
                    </b-popover>
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid || loading || !passwordMatch"
            >
              <b-spinner
                v-if="loading"
                small
              />
              <span v-else>{{ $t('login.SetPassword') }}</span>
            </b-button>
          </b-form>
        </validation-observer>

        <div v-if="isSuccess">
          <b-card-title class="mb-1">
            {{ $t('SuccessfullySentActivationEmail') }}
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t('InEmailSentToYou') }}
          </b-card-text>
          <div class="info-box bg-success">
            <feather-icon
              class="text-light pb-50"
              icon="CheckIcon"
              size="40"
            />
          </div>
        </div>
        <b-card-text
          v-else
          class="text-center mt-2"
        >
          <router-link to="/login">
            <feather-icon icon="ChevronLeftIcon" />
            {{ $t('BackToLogin') }}
          </router-link>
        </b-card-text>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BForm, BFormGroup, BLink, BInputGroup, BInputGroupAppend, BImg, BPopover, BCardText, BCardTitle,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Logo from '@/assets/images/core/logo.png'
import { mapGetters } from 'vuex'

export default {
  components: {
    // BSV
    BPopover,
    BImg,
    BForm,
    BFormGroup,
    BCardTitle,
    BCardText,
    BLink,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      isSuccess: false,
      loading: false,
      appLogo: Logo,
      emailAddress: '',
      password: '',
      passwordFieldType: 'password',
      required,
      user: {
        firstName: '',
        email: '',
      },
      passwordRequirements: [
        'OneLetter',
        'OneDigit',
        'NoSpaces',
        'OneSpecialChar',
        'MinLen',
        'MaxLen',
      ],
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordMatch() {
      const regex = /^(?=^.{8,32}$)(?=.*\d)(?=.*(\W|_)+)(?!.*\n)(?=.*[A-Z])(?=.*[a-z]).*$/
      return regex.test(this.password)
    },
    ...mapGetters({
      system: 'system/getSettings',
    }),
  },
  beforeMount() {
    // if (this.$route.params.token) {
    //   this.$store.dispatch('auth/GET_USER_BY_TOKEN', this.$route.params.token)
    //     .then(res => {
    //       const userLocale = res.data.locale.split('_')[0]
    //       this.$i18n.locale = userLocale
    //       this.user = res.data
    //     })
    //     .catch(() => {
    //       this.showToast('danger', this.$i18n.t('errors.TOKEN_INVALID'))
    //       this.$router.push('/login')
    //     })
    // } else this.$router.push('/login')
  },
  methods: {
    togglePasswordType() {
      if (this.passwordFieldType === 'password') this.passwordFieldType = 'text'
      else this.passwordFieldType = 'password'
    },
    sendEmail() {
      this.loading = true
      setTimeout(() => {
        this.loading = false
        this.isSuccess = true
      }, 1000)
    },
    setPassword() {
      this.loading = true
      setTimeout(() => {
        this.loading = false
        this.isSuccess = true
      }, 1000)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.auth-wrapper.auth-v1 {
  .auth-inner {
    max-width: 500px;
  }
}

.info-box {
  margin: 20px auto;
  text-align: center;
  line-height: 80px;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  box-shadow: 0px 0px 0px 20px rgba(234, 84, 85, .2);

  &.bg-primary {
    box-shadow: 0px 0px 0px 20px rgba(48, 119, 255, .2) !important;
  }

  &.bg-success {
    box-shadow: 0px 0px 0px 20px rgba(40, 199, 111, .2) !important;
  }
}
</style>
