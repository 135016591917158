<template>
  <div>
    <div
      v-if="loading"
      class="text-center py-3"
    >
      <b-spinner
        variant="primary"
        size="sm"
      />
    </div>

    <template v-else>
      <b-row>
        <b-col
          v-for="item in chats"
          :key="item.id"
          sm="12"
          lg="6"
        >
          <chat-item
            :item="item"
            @update="updateItem"
            @delete="deleteItem"
          />
        </b-col>

        <!--  New Chat  -->
        <b-col
          sm="12"
          lg="6"
        >
          <chat-item-new />
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import { GET_WIDGET_CHATS } from '@/@constants/mutations'
import { mapGetters } from 'vuex'
import ChatItem from './components/ChatItem.vue'
import ChatItemNew from './components/ChatItemNew.vue'

export default {
  components: { ChatItem, ChatItemNew },
  data: () => ({
    loading: false,

    chats: [
      // {
      //   id: 1, name: 'Test Chat', apiKey: 'QnBDM2h5MVFURXJSaDUvV3EwMjc4Zz09', active: true, connected: true, createdAt: { date: '13.11.2023, 13:09' },
      // },
      // {
      //   id: 2, name: 'Miau Chat', apiKey: 'QnBDM2h5MVFURXJSaDUvV3EwMjc4Zz09', active: false, connected: false, createdAt: { date: '13.11.2023, 10:09' },
      // },
    ],
  }),
  computed: {
    ...mapGetters({
      modalState: 'modal/getModalState',
    }),
  },
  watch: {
    modalState: {
      deep: true,
      async handler() {
        await this.loadItems()
      },
    },
  },
  mounted() {
    this.loadItems()
  },
  methods: {
    async loadItems() {
      this.loading = true

      this.chats = await this.$store.dispatch(`chatWidgets/${GET_WIDGET_CHATS}`)

      this.loading = false
    },

    findItemIndexById(id) {
      return this.chats.findIndex(item => item.id === id)
    },

    updateItem(newItem) {
      const index = this.findItemIndexById(newItem.id)

      this.chats[index] = newItem

      this.$forceUpdate()
    },

    deleteItem(id) {
      const index = this.findItemIndexById(id)

      this.chats.splice(index, 1)

      this.$forceUpdate()
    },
  },
}
</script>
