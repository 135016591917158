<template>
  <div>
    <!--    <div v-if="!loading">-->
    <!--      <b-row>-->
    <!--        <b-col sm="12">-->
    <!--          <b-card-->
    <!--            class="text-center card-congratulations"-->
    <!--          >-->
    <!--            <h3 class="text-white">-->
    <!--              {{ getUserName(user) }}-->
    <!--            </h3>-->
    <!--          </b-card>-->
    <!--        </b-col>-->
    <!--        <b-col-->
    <!--          lg="6"-->
    <!--          md="7"-->
    <!--        >-->
    <!--          <avatar-upload :user="user" />-->
    <!--        </b-col>-->

    <!--        <b-col-->
    <!--          lg="6"-->
    <!--          md="5"-->
    <!--        >-->
    <!--          <password-changer :user="user" />-->
    <!--        </b-col>-->

    <!--        <b-col-->
    <!--          lg="6"-->
    <!--          md="5"-->
    <!--          class="d-md-none"-->
    <!--        >-->
    <!--          <b-card class="text-center">-->
    <!--            <b-card-title-->
    <!--              title-tag="h5"-->
    <!--              class="d-flex flex-wrap align-items-center"-->
    <!--            >-->
    <!--              {{ $t('ChangeLanguage') }}-->
    <!--            </b-card-title>-->

    <!--            <sw-select>-->
    <!--              <locale />-->
    <!--            </sw-select>-->
    <!--          </b-card>-->
    <!--        </b-col>-->

    <!--        <b-col-->
    <!--          v-if="checkRequiredModule('mailboxModule')"-->
    <!--          cols="12"-->
    <!--        >-->
    <!--          <b-card class="text-center">-->
    <!--            <b-card-title-->
    <!--              title-tag="h5"-->
    <!--              class="d-flex flex-wrap align-items-center"-->
    <!--              style="column-gap: .5rem"-->
    <!--            >-->
    <!--              {{ $t('gEmail.email') }}-->
    <!--            </b-card-title>-->

    <!--            <global-email :user="user" />-->
    <!--          </b-card>-->
    <!--        </b-col>-->

    <!--        <b-col cols="12" v-if="checkRequiredModule('bookingModule')">-->
    <!--          <meeting-calendar :user="user" />-->
    <!--        </b-col>-->
    <!--      </b-row>-->
    <!--    </div>-->
    <!--    <div-->
    <!--      v-else-->
    <!--      class="d-flex justify-content-center"-->
    <!--    >-->
    <!--      <b-spinner-->
    <!--        variant="primary"-->
    <!--        class="m-5"-->
    <!--      />-->
    <!--    </div>-->

    <!--    <negotiation-statistics v-if="false" />-->

    <b-row>
      <b-col sm="12"
             md="4"
      >
        <b-card v-if="user">
          <div class="text-center">
            <b-avatar
              variant="light-primary"
              size="90"
              :src="user.avatar"
            />
            <h4 class="mt-1">
              {{ getUserName(user) | truncate(40) }}
            </h4>
            <div>
              <b-badge v-if="user.userId"
                       variant="light-primary"
                       class="mr-25"
              >
                <sw-icon icon="ToolIcon"
                         class="mr-25"
                />
                {{ user.userId }}
              </b-badge>
              <b-badge v-if="user.rank"
                       variant="light-primary"
              >
                {{ user.rank.name }}
              </b-badge>
            </div>
          </div>

          <div class="mt-2">
            <div class="d-flex justify-content-between align-items-center mt-75 flex-wrap">
              <div>
                <sw-icon class="mr-25 text-primary"
                         icon="AtSignIcon"
                />
                <span class="d-none d-md-inline">{{ $t('Email') }}</span>
              </div>
              <strong>
                <span v-if="user.email">{{ user.email }}</span>
                <sw-icon v-else
                         icon="MinusIcon"
                />
              </strong>
            </div>

            <div class="d-flex justify-content-between align-items-center mt-75 flex-wrap">
              <div>
                <sw-icon class="mr-25 text-primary"
                         icon="PhoneIcon"
                />
                <span class="d-none d-md-inline">{{ $t('Phone') }}</span>
              </div>
              <strong>
                <span v-if="user.phone">{{ user.phone }}</span>
                <sw-icon v-else
                         icon="MinusIcon"
                />
              </strong>
            </div>

            <hr>

            <!-- Modules -->
            <div v-if="checkRequiredModule('marketingModule')"
                 class="d-flex justify-content-between align-items-center mt-75 flex-wrap"
            >
              <div>
                <sw-icon class="mr-25 text-primary"
                         icon="HexagonIcon"
                />
                <span class="d-none d-md-inline">{{ $t('MarketingPlans') }}</span>
              </div>
              <strong>
                <div v-if="Array.isArray(user.userMarketingPlans) && user.userMarketingPlans.length">
                  <span v-for="({ marketingPlan, superSeller }, planIndex) in user.userMarketingPlans"
                        :key="`marketingPlans_${planIndex}`"
                        variant="light-primary"
                  >
                    {{ marketingPlan.name }}
                    <feather-icon v-if="superSeller"
                                  v-b-tooltip
                                  icon="StarIcon"
                                  :title="$t('SuperSeller')"
                    />
                  </span>
                </div>
                <feather-icon
                  v-else
                  icon="MinusIcon"
                />
              </strong>
            </div>
            <div v-if="checkRequiredModule('loyaltyModule')"
                 class="d-flex justify-content-between align-items-center mt-75 flex-wrap"
            >
              <div>
                <sw-icon class="mr-25 text-primary"
                         icon="StarIcon"
                />
                <span class="d-none d-md-inline">{{ $t('LoyaltyPlans') }}</span>
              </div>
              <strong>
                <div v-if="Array.isArray(user.userLoyaltyPlans) && user.userLoyaltyPlans.length">
                  <span v-for="({ loyaltyPlan }, planIndex) in user.userLoyaltyPlans"
                        :key="`loyaltyPlans_${planIndex}`"
                        variant="light-primary"
                        class="mr-25"
                  >
                    {{ loyaltyPlan.name }}
                  </span>
                </div>
                <feather-icon
                  v-else
                  icon="MinusIcon"
                />
              </strong>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col sm="12"
             md="8"
      >

        <b-tabs>
          <!-- Avatar Section -->
          <b-tab :active="$route.params.tab === 'avatar'"
                 :title="$t('Avatar')"
                 @click="$route.params.tab !== 'avatar' ? $router.push({ name: 'user-profile', params: { tab: 'avatar' } }) : ''"
          >
            <avatar-upload :user="user" />
          </b-tab>

          <!-- Security Section -->
          <b-tab :active="$route.params.tab === 'security'"
                 :title="$t('Password')"
                 @click="$route.params.tab !== 'security' ? $router.push({ name: 'user-profile', params: { tab: 'security' } }) : ''"
          >
            <password-changer :user="user" />
          </b-tab>

          <!-- Avatar Section -->
          <b-tab v-if="checkRequiredModule('mailboxModule')"
                 :active="$route.params.tab === 'mailbox'"
                 :title="$t('gEmail.email')"
                 @click="$route.params.tab !== 'mailbox' ? $router.push({ name: 'user-profile', params: { tab: 'mailbox' } }) : ''"
          >
            <global-email :user="user" />
          </b-tab>

          <!-- Avatar Section -->
          <b-tab v-if="checkRequiredModule('bookingModule')"
                 :active="$route.params.tab === 'calendar'"
                 :title="$t('ReservationCalendars')"
                 @click="$route.params.tab !== 'calendar' ? $router.push({ name: 'user-profile', params: { tab: 'calendar' } }) : ''"
          >
            <b-card><meeting-calendar :user="user" /></b-card>
          </b-tab>
        </b-tabs>

      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable */
import { GET_SINGLE_USER } from '@/@constants/mutations'
import { mapGetters } from 'vuex'
import AvatarUpload from '@/views/core/user/profileSettings/AvatarUpload.vue'
import PasswordChanger from '@/views/core/user/profileSettings/PasswordChanger.vue'
import NegotiationStatistics from '@/views/core/user/profileSettings/NegotiationStatistics.vue'
import Locale from '@core/layouts/components/app-navbar/components/Locale.vue'
import { BCardTitle } from 'bootstrap-vue'
import MeetingCalendar from '@/pages/user/current/components/MeetingCalendar.vue'
import globalEmail from './components/globalEmail/GlobalEmail.vue'

export default {
  name: 'ProfileSettings',
  components: {
    MeetingCalendar,
    Locale,
    BCardTitle,
    AvatarUpload,
    PasswordChanger,
    NegotiationStatistics,
    globalEmail,
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapGetters({
      user: 'users/getSingleUser',
      currentUser: 'auth/getCurrentUser',
    }),
  },
  mounted() {
    this.loading = true
    this.$store.dispatch(`users/${GET_SINGLE_USER}`, this.currentUser.id)
      .then(() => {
        this.loading = false
      })
      .catch(err => {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      })
  },
}
</script>

<style scoped>
@media only screen and (min-width: 992px) and (max-width: 768px) {
  display: flex;
  column-gap: 2rem;
}
</style>
