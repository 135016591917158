<template>
  <div>
    <div
      v-if="type === 'dropZone'"
      :key="tname"
      class="drag-drop-upload"
    >
      <div
        id="app"
        class="flex w-full h-screen items-center justify-center text-center"
        :class="{ disUploader: disabled, 'mt-1': mt }"
      >
        <label
          :for="'assetsFieldHandle'+tname"
          class="block cursor-pointer w-100"
        >
          <div
            class="border rounded uploader-body border-light-primary"
            :class="{ 'p-2': !uploadButton }"
            @dragover="dragover"
            @dragleave="dragleave"
            @drop="drop"
          >
            <input
              :id="'assetsFieldHandle' + tname"
              :ref="`file_${tname}`"
              type="file"
              :multiple="multiple"
              :name="`fields_${tname}[assetsFieldHandle${tname}][]`"
              class="w-px h-px opacity-0 overflow-hidden absolute"
              style="display: none"
              :accept="accept"
              :disabled="disabled"
              @change="onChange(null, isRefresh)"
            >
            <div>
              <slot>
                <div v-if="showCount && fileList.length">
                  <feather-icon
                    icon="UploadIcon"
                    size="30"
                    class="d-block mx-auto mb-75 text-primary"
                  />

                  <div class="d-flex flex-wrap align-items-center justify-content-center"
                       style="column-gap: .5rem; row-gap: .5rem;"
                  >
                    <b-button
                      size="sm"
                      variant="outline-danger"
                      class="btn-icon rounded-circle p-25"
                      @click="remove()"
                    >
                      <feather-icon
                        icon="TrashIcon"
                        size="14"
                      />
                    </b-button>

                    <p class="mb-0 h4 text-primary">
                      {{ $t('Selected') }}:
                      {{ fileList.length }}
                    </p>
                  </div>
                </div>

                <div v-else>
                  <feather-icon
                    icon="UploadIcon"
                    size="30"
                    class="d-block mx-auto mb-1 text-primary"
                  />

                  {{ $t('DragDropFiles') }}
                </div>
              </slot>
            </div>
          </div>
        </label>
      </div>

      <!-- Oversize files -->
      <transition name="fade">
        <div
          v-if="isOversize"
          class="mx-1"
        >
          <b-alert
            class="p-50"
            show
          >
            {{ $tc('MoreThanTotalMb', 1, { size: maxAllFilesSize }) }}
          </b-alert>
        </div>
      </transition>

      <!-- File List -->
      <vue-perfect-scrollbar
        v-if="fileList.length && showFiles"
        :settings="{ maxScrollbarLength: 60 }"
        class="scrollable-container media-list scroll-area mt-1 px-2"
        tagname="div"
        style="max-height: 200px"
      >
        <div
          v-for="(file, index) in fileList"
          :key="index"
          class="d-flex align-items-center justify-content-between mt-50 pb-50 border-bottom"
        >
          <span>
            <span class="mr-1">
              <feather-icon
                :icon="iconType(file.type)"
                size="16"
              />
            </span>
            <span>
              <span :style="{ 'text-decoration': filesToRemove.includes(index) ? 'line-through' : '' }">{{ file.name | truncate(35, '...') }}</span>
              <feather-icon
                v-if="filesToRemove.includes(index)"
                v-b-tooltip
                class="text-primary ml-1"
                icon="AlertCircleIcon"
                :title="$tc('MoreThanMbWontBeUploaded', 1, { size: 25 })"
              />
            </span>
          </span>
          <b-button
            size="sm"
            variant="outline-danger"
            class="btn-icon rounded-circle ml-1"
            @click="remove(fileList.indexOf(file))"
          >
            <feather-icon
              icon="TrashIcon"
              size="14"
            />
          </b-button>
        </div>
      </vue-perfect-scrollbar>
    </div>

    <div
      v-else
      :key="tname"
    >
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="flat-primary"
        :size="size"
        :disabled="disabled"
        @click="$refs[`file_${tname}`].click()"
      >
        <feather-icon icon="PaperclipIcon" />

        <!--        {{ $t('Upload') }}-->

        <template v-if="fileList.length">
          {{ fileList.length }}
        </template>
      </b-button>

      <input
        :id="'assetsFieldHandle' + tname"
        :ref="`file_${tname}`"
        type="file"
        :multiple="multiple"
        :name="`fields_${tname}[assetsFieldHandle${tname}][]`"
        class="w-px h-px opacity-0 overflow-hidden absolute"
        style="display: none"
        :accept="accept"
        :disabled="disabled"
        @change="onChange(null, isRefresh)"
      >
    </div>
  </div>
</template>

<script>
import iconType from '@helpers/iconType'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BAlert, VBTooltip } from 'bootstrap-vue'
import Vue from 'vue'
import Ripple from 'vue-ripple-directive'

const DragDropUpload = Vue.extend({
  name: 'DragDropUpload',
  components: {
    VuePerfectScrollbar,
    BAlert,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    type: {
      default: 'dropZone',
      type: String,
    },
    size: {
      default: 'md',
      type: String,
    },
    multiple: {
      default: true,
      type: Boolean,
    },
    isRefresh: {
      default: true,
      type: Boolean,
    },
    showFiles: {
      default: true,
      type: Boolean,
    },
    showCount: {
      default: false,
      type: Boolean,
    },
    uploadButton: {
      default: false,
      type: Boolean,
    },
    accept: {
      default: '*',
      type: String,
    },
    tname: {
      type: String,
      default: '',
    },
    maxAllFilesSize: {
      type: Number,
      default: null,
    },
    maxFiles: {
      type: Number,
      default: null,
      required: false,
    },
    mt: {
      type: Boolean,
      default: true,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    defaultValue: {
      type: [Array, Object],
      default: null,
      required: false,
    },
  },
  data: () => ({
    fileList: [],
    filesToRemove: [],
    isOversize: false,
  }),
  watch: {
    defaultValue: {
      deep: true,
      handler(newValue) {
        this.fileList = newValue
      },
    },
  },
  mounted() {
    if (this.defaultValue) {
      this.fileList = this.defaultValue
    }
  },
  methods: {
    iconType(type) {
      return iconType(type)
    },
    onChange(editedList = null, isRefresh = this.isRefresh) {
      const files = editedList || [...this.$refs[`file_${this.tname}`].files]
      const filesToUpload = [...files]
      const filesToRemove = []
      let totalSize = 0
      files.forEach((file, index) => {
        if (file.size > 40000000) {
          filesToUpload.splice(index, 1)
          filesToRemove.push(index)
        } else totalSize += file.size
      })

      if (this.maxAllFilesSize && totalSize > this.maxAllFilesSize * 1000000) {
        this.isOversize = true
        return
      }

      if (isRefresh) {
        this.fileList = files
      } else {
        this.fileList.push(...files)
      }

      this.filesToRemove = filesToRemove
      this.$refs[`file_${this.tname}`].value = ''
      this.$emit('onChangeFiles', filesToUpload)
    },
    remove(i) {
      this.fileList.splice(i, 1)
      this.$nextTick(() => {
        this.$emit('onDeleteFiles')
        this.onChange(this.fileList, true)
      })
    },
    dragover(event) {
      event.preventDefault()
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains('border-primary')) {
        event.currentTarget.classList.add('border-primary')
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.remove('border-primary')
    },
    drop(event) {
      event.preventDefault()
      this.$refs[`file_${this.tname}`].files = event.dataTransfer.files
      this.onChange(null) // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.remove('border-primary')
    },
  },
})

export default DragDropUpload
</script>

<style lang="scss">
.disUploader {
  opacity: .5;

  .uploader-body {
    background: var(--bg-shopItem-lighten-1);

    border: 1px solid var(--bg-shopItem) !important;
  }
}
</style>
