<template>
  <validation-observer
    ref="newScheduleForm"
    v-slot="{invalid}"
    tag="form"
  >
    <!--      Thread      -->
    <b-row>
      <b-col sm="12"
             md="6"
      >
        <validation-provider
          v-slot="{ errors }"
          rules="required"
          :name="$t('SelectContacts')"
        >
          <sw-select
            :name="$t('SelectContacts')"
          >
            <v-select
              v-model="newSchedule.payer"
              :options="contactThreadList"
              label="name"
              :filterable="false"
              :clearable="false"
              :state="errors.length > 0 ? false:null"
              @search="loadThreads"
            >
              <template #no-options="{ search }">
                <span v-if="search.length">
                  {{ $t('NoData') }}
                </span>
                <span v-else>
                  {{ $t('TypeToSearch') }}
                </span>
              </template>

              <template #option="{ name, contact, offersTotal }">
                <div class="d-flex align-items-center">
                  <avatar
                    :user="contact"
                    class="mr-25"
                  />  - {{ name }}
                  <feather-icon
                    v-if="offersTotal >= system.contactThreadOffersLimit"
                    v-b-tooltip
                    icon="AlertCircleIcon"
                    class="ml-50"
                    :title="$tc('ThreadOfferCountIsToBig', system.contactThreadOffersLimit, { limit: system.contactThreadOffersLimit })"
                  />
                </div>
              </template>

              <template #selected-option="{ name, contact }">
                <div class="d-flex">
                  <avatar
                    :user="contact"
                    class="mr-25"
                  /> – {{ name }}
                </div>
              </template>
            </v-select>
          </sw-select>

          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col>

      <b-col sm="12"
             md="6"
      >
        <validation-provider
          v-slot="{ errors }"
          rules="required"
          :name="$t('ChooseType')"
        >
          <sw-select
            :name="$t('ChooseType')"
          >
            <v-select
              v-model="newSchedule.type"
              :options="paymentTypeList"
              :clearable="false"
              :state="errors.length > 0 ? false:null"
              @option:selected="$event === 'LOYALTY_POINTS' ? mode = 0 : ''"
            >
              <template #no-options>
                {{ $t('NoOptions') }}
              </template>

              <template #option="{ label }">
                <span>{{ $t(paymentTypes[label]) }}</span>
              </template>

              <template #selected-option="{ label }">
                <span>{{ $t(paymentTypes[label]) }}</span>
              </template>
            </v-select>
          </sw-select>

          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col>

      <b-col sm="12"
             md="6"
      ><b-form-group :label="$t('SaleDate')">
        <validation-provider
          v-slot="{ errors }"
          :name="$t('Sale')"
        >
          <flat-pickr
            v-model="newSchedule.sellDate"
            class="form-control"
            :config="{ locale: getCalendarLocale($i18n.locale) }"
            :state="errors.length > 0 ? false:null"
          />

          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      </b-col>

      <b-col sm="12"
             md="6"
      ><b-form-group :label="$t('PaymentDate')">
        <validation-provider
          v-slot="{ errors }"
          rules="required"
          :name="$t('PaymentDate')"
        >
          <flat-pickr
            v-model="newSchedule.deadlineDate"
            class="form-control"
            :config="{ locale: getCalendarLocale($i18n.locale) }"
            :state="errors.length > 0 ? false:null"
          />

          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      </b-col>
    </b-row>

    <b-form-group :label="$t('Note')">
      <validation-provider
        v-slot="{ errors }"
        :name="$t('Note')"
      >
        <b-form-textarea v-model="newSchedule.note"
                         rows="4"
        />

        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>

    <b-tabs v-if="checkRequiredModule('fakturowniaModule')"
            v-model="mode"
    >
      <b-tab :title="$t('BasicMode')" />
      <b-tab :disabled="newSchedule === 'LOYALTY_POINTS'"
             :title="$t('ExtendedMode')"
      >
        <div class="divider divider-left">
          <div class="divider-text text-primary">
            {{ $t('PaymentPositions') }}
          </div>
        </div>

        <b-button size="sm"
                  variant="primary"
                  @click="positionModal.show = true"
        >
          <sw-icon icon="PlusIcon" />
          {{ $t('Add') }}
        </b-button>

        <b-table
          :items="newSchedule.contactThreadPaymentItems"
          :fields="positionFields"
          class="mt-25"
          striped
          responsive
          :style="{ minHeight: tableSize(newSchedule.contactThreadPaymentItems, 100) }"
          show-empty
          :no-local-sorting="true"
        >
          <!--    Head    -->
          <template #head()="{ label }">
            {{ $t(`${label}`) }}
          </template>

          <template #cell(priceNet)="{ value, item }">
            <div class="text-nowrap">
              1x <strong>{{ value | priceFormat }}</strong> {{ $store.getters['system/getSettings'] ? ($store.getters['system/getSettings'].defaultCurrency || 'PLN') : 'PLN' }}
            </div>
            <div class="text-nowrap">
              {{ item.quantity }}x <strong>{{ item.priceNetTotal | priceFormat }}</strong> {{ $store.getters['system/getSettings'] ? ($store.getters['system/getSettings'].defaultCurrency || 'PLN') : 'PLN' }}
            </div>
          </template>
          <template #cell(vatPercentage)="{ value }">
            {{ value }}%
          </template>
          <template #cell(priceGross)="{ value, item }">
            <div class="text-nowrap">
              1x <strong>{{ value | priceFormat }}</strong> {{ $store.getters['system/getSettings'] ? ($store.getters['system/getSettings'].defaultCurrency || 'PLN') : 'PLN' }}
            </div>
            <div class="text-nowrap">
              {{ item.quantity }}x <strong>{{ item.priceGrossTotal | priceFormat }}</strong> {{ $store.getters['system/getSettings'] ? ($store.getters['system/getSettings'].defaultCurrency || 'PLN') : 'PLN' }}
            </div>
          </template>
          <template #cell(quantityUnit)="{ value }">
            {{ $t(`unit.${value}`) }}
          </template>

          <!--    Other    -->
          <template #cell()="row">
            <table-default-cell
              :field="row.field"
              :value="row.value"
            />
          </template>

          <template #cell(action)="{ item, index }">
            <b-dropdown
              id="dropdown-grouped"
              dropleft
              no-caret
              variant="flat"
            >
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" />
              </template>
              <div>
                <b-dropdown-item @click="positionModal.show = true; positionModal.item = JSON.parse(JSON.stringify(item)); positionModal.index = index">
                  <dropdown-item-label
                    icon="EditIcon"
                    :label="$t('Edit')"
                  />
                </b-dropdown-item>
                <b-dropdown-item @click="$delete(newSchedule.contactThreadPaymentItems, index)">
                  <dropdown-item-label
                    icon="TrashIcon"
                    :label="$t('Delete')"
                  />
                </b-dropdown-item>
              </div>
            </b-dropdown>
          </template>

          <!--      Empty      -->
          <template #empty>
            <empty-content />
          </template>

          <!--      Table Busy      -->
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner
                class="align-middle"
                variant="primary"
              />
            </div>
          </template>
        </b-table>

        <b-form-group class="mt-1">
          <b-form-checkbox v-model="newSchedule.sentToFakturowania"
                           variant="primary"
          >
            {{ $t('SentToFakturownia') }}
          </b-form-checkbox>
        </b-form-group>
        <sw-select v-if="newSchedule.sentToFakturowania"
                   :name="$t('SelectDocumentType')"
        >
          <v-select v-model="newSchedule.documentType"
                    :reduce="e => e.type"
                    :options="documentTypes"
          >
            <template #selected-option="{ label }">
              {{ $t(label) }}
            </template>
            <template #option="{ label }">
              {{ $t(label) }}
            </template>
          </v-select>
        </sw-select>

        <b-form-group v-if="newSchedule.sentToFakturowania && newSchedule.documentType !== 'VAT'">
          <b-form-checkbox v-model="newSchedule.generateVatAfterPaid"
                           variant="primary"
          >
            {{ $t('GenerateWhenStatusPaid') }}
          </b-form-checkbox>
        </b-form-group>
      </b-tab>
    </b-tabs>

    <b-form-group :label="$t(newSchedule.type !== 'LOYALTY_POINTS' ? 'Offer.GrossPrice' : 'LoyaltyPoints')">
      <b-input-group>
        <cleave
          v-if="mode === 0"
          v-model="newSchedule.value"
          :placeholder="$t(newSchedule.type !== 'LOYALTY_POINTS' ? 'Offer.GrossPrice' : 'LoyaltyPoints')"
          :options="{
            delimiter: ' ',
            numeral: true,
            numeralThousandsGroupStyle: 'thousand',
          }"
          class="form-control"
        />
        <cleave
          v-else
          :value="getTotalPositionGrossPrice"
          disabled
          :placeholder="$t(newSchedule.type !== 'LOYALTY_POINTS' ? 'Offer.GrossPrice' : 'LoyaltyPoints')"
          :options="{
            delimiter: ' ',
            numeral: true,
            numeralThousandsGroupStyle: 'thousand',
          }"
          class="form-control"
        />

        <b-input-group-append v-if="newSchedule.type !== 'LOYALTY_POINTS'">
          <b-button
            variant="outline-primary"
            disabled
          >
            {{ $store.getters['system/getSettings'] ? ($store.getters['system/getSettings'].defaultCurrency || 'PLN') : 'PLN' }}
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>

    <!--      Save      -->
    <b-button
      type="button"
      variant="primary"
      :disabled="invalid"
      size="sm"
      @click="saveSchedule"
    >
      {{ $t('Save') }}
    </b-button>

    <b-modal v-model="positionModal.show"
             hide-footer
             :title="$t(positionModal.index > -1 ? 'Edit' : 'AddItem')"
             @hidden="positionModal.show = false; positionModal.item = JSON.parse(JSON.stringify(defaultPosition)); positionModal.index = -1"
    >
      <b-form-group :label="$t('Name')">
        <b-form-input
          v-model="positionModal.item.name"
        />
      </b-form-group>

      <sw-select :name="$t('Unit')">
        <v-select
          v-model.trim="positionModal.item.quantityUnit"
          :options="units"
          :get-option-label="name => $t(`unit.${name}`)"
        />
      </sw-select>

      <b-form-group :label="$t('Offer.Amount')">
        <b-form-input
          v-model="positionModal.item.quantity"
          type="number"
          placeholder="0"
        />
      </b-form-group>

      <b-form-group :label="$t('Offer.NetPrice')">
        <b-form-input
          v-model="positionModal.item.priceNet"
          type="number"
          placeholder="0"
        />
      </b-form-group>

      <sw-select name="Vat">
        <v-select
          v-model="positionModal.item.vatPercentage"
          :options="[0, 8, 23]"
          :clearable="false"
        />
      </sw-select>

      <b-button
        type="button"
        variant="primary"
        size="sm"
        @click="savePositionItem"
      >
        {{ $t('Save') }}
      </b-button>
    </b-modal>
  </validation-observer>
</template>

<script>
import { ADD_PAYMENT, CLOSE_MODAL, RELOAD_CONTENT } from '@/@constants/mutations'
import { BInputGroupAppend, BTable } from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { THREADS_SEARCH } from '@/@constants/fields'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import Cleave from 'vue-cleave-component'
import { Polish as pl } from '@/libs/i18n/locales/flatpickr/pl'
import { english as en } from '@/libs/i18n/locales/flatpickr/en'
import flatPickr from 'vue-flatpickr-component'
import units from '@/helpers/productUnits'

const defaultPosition = {
  name: '',
  priceGross: 0,
  priceGrossTotal: 0,
  priceNet: 0,
  priceNetTotal: 0,
  quantity: 1,
  quantityUnit: 'PIECE',
  vatPercentage: 23,
}

export default {
  components: {
    BTable,
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
    vSelect,
    Cleave,
    flatPickr,
  },
  data: () => ({
    units,
    defaultPosition,
    paymentTypes: {
      ADVANCE: 'AdvancePayment',
      INSTALLMENT: 'Installment',
      BANK_INSTALLMENT: 'Bank installment',
      TRANSFER: 'Przelew',
      CASH: 'Cash',
      LOYALTY_POINTS: 'LoyaltyPoints',
      TOTAL_PAYMENT: 'TotalAmount',
    },
    paymentTypeList: [
      'ADVANCE',
      'INSTALLMENT',
      'BANK_INSTALLMENT',
      'TRANSFER',
      'CASH',
      'LOYALTY_POINTS',
      'TOTAL_PAYMENT',
    ],

    positionFields: [
      { key: 'name', label: 'Name' },
      { key: 'priceNet', label: 'Offer.NetPrice' },
      { key: 'vatPercentage', label: 'Vat' },
      { key: 'priceGross', label: 'Offer.GrossPrice' },
      { key: 'quantity', label: 'Offer.Amount' },
      { key: 'quantityUnit', label: 'Unit' },
      { key: 'action', label: 'Action' },
    ],

    flatPickerConfig: {
      mode: 'range',
      locale: [pl, en],
    },

    positionModal: {
      show: false,
      item: JSON.parse(JSON.stringify(defaultPosition)),
      index: -1,
    },

    newSchedule: {
      payer: '',
      type: '',
      note: '',
      sellDate: '',
      value: 0,
      deadlineDate: '',
      sentToFakturowania: false,
      generateVatAfterPaid: false,
      documentType: 'PRO_FORMA',
      contactThreadPaymentItems: [],
    },
    contactThreadList: [],
    mode: 0,
    documentTypes: [
      { type: 'PRO_FORMA', label: 'ProFormaInvoice' },
      { type: 'VAT', label: 'VatInvoice' },
    ],
  }),
  computed: {
    ...mapGetters({
      system: 'system/getSettings',
      modalConfig: 'modal/getModalState',
      oldThread: 'singleContact/getSelectedThread',
      newThread: 'contact/thread',
    }),
    thread() {
      return this.$route.name.includes('new_contact') ? this.newThread : this.oldThread
    },
    getTotalPositionGrossPrice() {
      return this.newSchedule.contactThreadPaymentItems.reduce((a, b) => +a + +b.priceGrossTotal, 0)
    },
  },
  mounted() { this.ini() },
  methods: {
    ini() {
      const { contactId } = this.modalConfig

      if (this.thread) {
        this.newSchedule.payer = { ...this.thread }
      } else if (contactId) this.loadThread(contactId)
      else {
        this.newSchedule.payer = null
      }
    },

    clearNewSchedule() {
      this.mode = 0
      this.newSchedule = {
        payer: '',
        type: '',
        value: 0,
        note: '',
        sellDate: '',
        deadlineDate: '',
        sentToFakturowania: false,
        documentType: 'PRO_FORMA',
        contactThreadPaymentItems: [],
      }
    },

    getNewSchedulePayload() {
      const payload = JSON.parse(JSON.stringify(this.newSchedule))

      const thread = payload.payer.id
      const contact = payload.payer.contact.id

      payload.value = parseFloat(`${payload.value || 0}`)
      payload.payer = contact
      payload.contactThread = thread
      payload.status = 'NEW'
      payload.part = 0
      payload.note = String(payload.note) || null

      if (payload.documentType === 'VAT') {
        payload.generateVatAfterPaid = false
      }

      if (!payload.sentToFakturowania) {
        payload.documentType = null
        payload.generateVatAfterPaid = false
      }

      payload.contactThreadPaymentItems = payload.contactThreadPaymentItems.map(e => ({
        ...e,
        name: e.name,
        priceGross: Number(e.priceGross),
        priceGrossTotal: Number(e.priceGrossTotal),
        priceNet: Number(e.priceNet),
        priceNetTotal: Number(e.priceNetTotal),
        quantity: Number(e.quantity),
        quantityUnit: e.quantityUnit,
        vatPercentage: Number(e.vatPercentage),
      }))

      if (this.mode !== 0) {
        payload.value = this.getTotalPositionGrossPrice
      } else {
        payload.contactThreadPaymentItems = []
      }

      return { payload, contact, thread }
    },

    saveSchedule() {
      const { payload, contact, thread } = this.getNewSchedulePayload()

      if (payload) {
        this.$store.dispatch(`payments/${ADD_PAYMENT}`, { payload, contact, thread })
          .then(res => {
            this.$store.commit(`modal/${RELOAD_CONTENT}`, res)

            this.$nextTick(() => this.close())
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          })
          .finally(() => this.clearNewSchedule())
      }
    },

    close() { this.$store.commit(`modal/${CLOSE_MODAL}`) },

    async loadThreads(search, isLoading) {
      this.contactThreadList = await this.selectSearch(search, isLoading, THREADS_SEARCH, 'name,contact-firstName,contact-lastName', '1/contacts/threads')
    },

    async loadThread(id, isLoading = () => {}) {
      const contact = await this.selectSearch(id, isLoading, THREADS_SEARCH, 'contact-id', '1/contacts/threads')

      // eslint-disable-next-line prefer-destructuring
      this.newSchedule.payer = contact[0]
    },

    savePositionItem() {
      const itemCopy = JSON.parse(JSON.stringify(this.positionModal.item))

      const totalNetPrice = parseFloat(parseFloat((Number(itemCopy.priceNet) * Number(itemCopy.quantity))).toFixed(2))
      const totalGrossPrice = parseFloat(parseFloat(totalNetPrice * (1 + Number(itemCopy.vatPercentage) / 100)).toFixed(2))
      const grossPrice = parseFloat(parseFloat(itemCopy.priceNet * (1 + Number(itemCopy.vatPercentage) / 100)).toFixed(2))

      itemCopy.priceGrossTotal = totalGrossPrice
      itemCopy.priceNetTotal = totalNetPrice
      itemCopy.priceGross = grossPrice

      if (this.positionModal.index > -1) {
        this.$set(this.newSchedule.contactThreadPaymentItems, this.positionModal.index, itemCopy)
      } else {
        this.newSchedule.contactThreadPaymentItems.push(itemCopy)
      }

      this.$set(this, 'positionModal', {
        show: false,
        item: JSON.parse(JSON.stringify(this.defaultPosition)),
        index: -1,
      })
    },
  },
}
</script>
