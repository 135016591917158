<template>
  <div
    class="calendar-status text-dark mx-75"
    :class="getEventColor()"
    :style="getEventDoneEffect()"
  >
    <div style="display: flex !important; align-items: center; column-gap: .3rem;">
      {{ getDate(data.start, data.end) }}

      <strong>
        {{ data.title }}
      </strong>

      <div
        v-if="data.extendedProps.contactThread && data.extendedProps.contactThread.contact"
        style="display: flex !important; flex-direction: row; align-items: center; column-gap: .3rem; margin-top: -.05rem;"
      >
        <feather-icon
          icon="ArrowRightIcon"
          size="10"
        />

        <avatar
          :user="data.extendedProps.contactThread.contact"
          :show-avatar="false"
          text-variant="text-dark"
          style="margin-top: -.1rem;"
        />
      </div>
    </div>
  </div>
</template>

<script>
// import { BPopover } from 'bootstrap-vue'
import { formatDate } from '@core/utils/filter'
import moment from 'moment/moment'

export default {
  name: 'DefaultView',
  components: {
    // BPopover,
  },
  props: {
    data: {
      default: null,
      type: Object,
      required: true,
    },
  },
  data: () => ({
    uid: Math.random().toString(16).slice(2),
  }),
  methods: {
    getEventColor() {
      switch (this.data.extendedProps.status) {
        case 'O': return 'calendar-status-info'
        case 'D': return 'calendar-status-success'
        case 'ND': return 'calendar-status-danger'
        default: return 'calendar-status-primary'
      }
    },
    getDate(startDate, endDate) {
      const start = moment(startDate)
      const end = moment(endDate)

      const diff = end.endOf('hour').diff(start.startOf('hour'), 'hours')

      // If whole day
      if (diff === 23 || !endDate) return ''

      // If not whole day
      if (diff < 23) return `${moment(startDate).format('HH:mm')} - ${moment(endDate).format('HH:mm')}`

      // Else
      return moment(startDate).format('HH:mm')
    },

    formatDate,
    taskTypeVariant(value) {
      let variant = 'light-primary'
      if (value === 'D') variant = 'light-success'
      else if (value === 'O') variant = 'light-info'
      else if (value === 'ND') variant = 'light-danger'
      return variant
    },
    getEventDoneEffect() {
      const { status } = this.data.extendedProps

      if (status === 'D' || status === 'ND') return 'text-decoration: line-through !important'

      return ''
    },
  },
}
</script>

<style scoped>

</style>
