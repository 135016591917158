<template>
  <b-modal
    v-model="isModalOpen"
    :title="$tc('RecordsCountMassUpdate', products.length, { length: products.length })"
    hide-footer
    @hide="$emit('close-modal', true)"
  >
    <div class="d-flex align-items-center mb-1">
      <b-form-checkbox
        v-model="isDeleteMode"
        variant="primary"
        switch
      >
        <span class="switch-icon-right">
          <feather-icon icon="DatabaseIcon" />
        </span>
        <span class="switch-icon-left">
          <feather-icon icon="TrashIcon" />
        </span>
      </b-form-checkbox>
      <span v-if="!isDeleteMode">{{ $t('UpdateRecords') }}</span>
      <span v-else>{{ $t('DeleteRecords') }}</span>
    </div>

    <div v-if="!isDeleteMode">
      <b-row>
        <b-col sm="12"
               md="6"
        >
          <div class="d-flex align-items-center mb-50">
            <b-form-checkbox
              v-model="allowUpdateStatus"
              variant="primary"
              switch
            />
            <span>{{ $t('UpdateStatus') }}</span>
          </div>
        </b-col>
        <b-col sm="12"
               md="6"
        >
          <div v-if="allowUpdateStatus">
            <b-form-checkbox
              v-model="active"
              class="custom-control-primary mb-50"
            >
              {{ $t('offer.product.active') }}
            </b-form-checkbox>
          </div>
        </b-col>
      </b-row>

      <hr>

      <div class="d-flex align-items-center mb-1">
        <b-form-checkbox
          v-model="allowUpdatePrice"
          variant="primary"
          switch
        />
        <span>{{ $t('PriceUpdate') }}</span>
      </div>
      <div v-if="allowUpdatePrice">
        <b-row>
          <b-col
            sm="6"
            class="d-flex align-items-center"
          >
            <b-form-checkbox
              v-model="isPercentValue"
              variant="primary"
              switch
            >
              <span class="switch-icon-right">
                <feather-icon icon="DollarSignIcon" />
              </span>
              <span class="switch-icon-left">
                <feather-icon icon="PercentIcon" />
              </span>
            </b-form-checkbox>
            <span v-if="isPercentValue">{{ $t('ChangeByPercentage') }}</span>
            <span v-else>{{ $t('ChangeByValue') }}</span>
          </b-col>

          <b-col
            sm="6"
            class="d-flex align-items-center"
          >
            <b-form-checkbox
              v-model="isDecreaseValue"
              variant="primary"
              switch
            >
              <span class="switch-icon-right">
                <feather-icon icon="PlusIcon" />
              </span>
              <span class="switch-icon-left">
                <feather-icon icon="MinusIcon" />
              </span>
            </b-form-checkbox>
            <span v-if="isDecreaseValue">{{ $t('Decrease') }}</span>
            <span v-else>{{ $t('Increase') }}</span>
          </b-col>
        </b-row>

        <transition name="fade">
          <b-alert
            v-if="isDecreaseValue"
            show
            variant="primary"
            class="mt-50"
          >
            <div class="alert-body">
              {{ $t('LessZeroPriceInfo') }}
            </div>
          </b-alert>
        </transition>

        <b-form-group
          :label="$t('ChangeValue')"
          class="mt-50"
        >
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon :icon="isDecreaseValue ? 'MinusIcon' : 'PlusIcon'" />
            </b-input-group-prepend>
            <b-form-input
              v-model="value"
              type="number"
            />
          </b-input-group>
        </b-form-group>
      </div>
    </div>

    <b-button
      variant="primary"
      size="sm"
      :disabled="isPercentValue && value > 100"
      @click="updateProductPrices"
    >
      {{ $t('Save') }}
    </b-button>
  </b-modal>
</template>

<script>
import { BInputGroupPrepend, BInputGroup } from 'bootstrap-vue'
import { UPDATE_PRODUCT } from '@/@constants/mutations'
import axiosIns from '@/libs/axios'

export default {
  name: 'PriceUpdateModal',
  components: {
    BInputGroupPrepend,
    BInputGroup,
  },
  props: {
    products: {
      type: Array,
      default: () => ([]),
    },
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    active: false,
    allowUpdateStatus: false,
    allowUpdatePrice: false,
    isDeleteMode: false,
    isPercentValue: false,
    isDecreaseValue: false,
    value: 0,
    isModalOpen: false,
  }),
  watch: {
    showModal(newValue) {
      this.isModalOpen = newValue
    },
  },
  methods: {
    splitToChunks(array, size = 250) {
      const result = []
      for (let i = 0; i < array.length; i += size) {
        result.push(array.slice(i, i + size))
      }

      return result
    },
    async updateProductPrices() {
      let updatePayload = this.products.map(({ id, offerPrice = 0 }) => {
        let price = offerPrice
        if (price === 0) return { id, offerPrice }

        const updateValue = this.isPercentValue
          ? offerPrice * (this.value / 100)
          : this.value

        price = this.isDecreaseValue ? (+price - +updateValue) : (+price + +updateValue)

        if (price < 0) return { id, offerPrice }
        return { id, offerPrice: price }
      }).map(item => ({ ...item, massUpdate: true }))

      if (!this.allowUpdatePrice) {
        updatePayload = updatePayload.map(p => ({ id: p.id }))
      }

      if (this.allowUpdateStatus) {
        updatePayload = updatePayload.map(p => ({ ...p, active: this.active }))
      }

      const chunks = this.splitToChunks(updatePayload)
      try {
        const resp = this.isDeleteMode
          ? await Promise.all(chunks.map(chunk => axiosIns.delete('1/shops/products', { data: JSON.stringify(chunk.map(c => c.id)) })))
          : await Promise.all(chunks.map(chunk => this.$store.dispatch(`products/${UPDATE_PRODUCT}`, chunk)))

        const length = resp.reduce((a, b) => a + (b.data.items?.length || 0), 0)
        this.showAlert('success', this.$i18n.tc('RecordsCountSaved', length, { length }), '', false)
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.value = 0
        this.isPercentValue = false
        this.isDecreaseValue = false
        this.active = false
        this.allowUpdateStatus = false
        this.allowUpdatePrice = false
        this.isDeleteMode = false
        this.$emit('close-modal')
      }
    },
  },
}
</script>

<style scoped>

</style>
