<template>
  <div ref="user_container">
    <b-row
      v-if="user"
      class="mb-2"
      style="row-gap: 2rem"
    >
      <b-col
        md="4"
      >
        <div
          class="d-flex flex-column sticky-to-top"
          style="row-gap: 1rem;"
        >
          <!--    START::Basic Information    -->
          <b-card
            class="m-0"
            no-body
          >
            <template #header>
              <div
                class="w-100 mb-n50 d-flex flex-row align-items-center justify-content-between position-relative"
                style="row-gap: .84rem"
              >
                <div
                  class="d-flex flex-row align-items-center"
                  style="column-gap: .34rem"
                >
                  <b-avatar
                    size="28"
                    class="border border-white"
                    :src="user.avatar"
                    :text="user.firstName ? user.firstName.slice(0,1).toUpperCase() + user.lastName.slice(0,1).toUpperCase() : ''"
                  />

                  <p class="h5 mb-0">
                    {{ user.firstName }} {{ user.lastName }}
                  </p>
                </div>

                <!--    START::Contact Buttons    -->
                <b-button-group
                  class="d-flex flex-row justify-content-between"
                  size="sm"
                >
                  <!--    START::Edit    -->
                  <b-button
                    v-if="checkRequiredPermissions([$roles.USER_EDIT])"
                    v-b-tooltip
                    :title="$t('Edit')"
                    variant="outline-warning"
                    class="btn-icon"
                    :to="`/user/edit/${user.id}`"
                  >
                    <sw-icon
                      icon="EditIcon"
                      size="14"
                    />
                  </b-button>
                  <!--    START::Edit    -->

                  <!-- Deactivate -->
                  <b-button
                    v-if="checkRequiredPermissions([$roles.USER_EDIT]) && user.canLogin"
                    v-b-tooltip
                    :variant="user.blocked ? 'outline-success' : 'outline-danger'"
                    :title="user.blocked ? $t('Unblock') : $t('Block')"
                    class="btn-icon"
                    @click="changeUserStatus(user.id)"
                  >
                    <sw-icon
                      :icon="user.blocked ? 'UnlockIcon' : 'LockIcon'"
                      size="14"
                    />
                  </b-button>

                  <!--    START::Delete    -->
                  <b-button
                    v-if="user.activated && checkRequiredPermissions([$roles.USER_RESET_PASSWORD]) && user.canLogin"
                    v-b-tooltip
                    variant="outline-primary"
                    :title="$t('login.ResetPassword')"
                    size="sm"
                    class="btn-icon"
                    @click="resetUserPassword(user.id)"
                  >
                    <sw-icon
                      icon="KeyIcon"
                      size="14"
                    />
                  </b-button>
                  <!--    START::Delete    -->
                </b-button-group>
                <!--    START::Contact Buttons    -->
              </div>
            </template>

            <b-alert
              v-if="user.note && user.note.length"
              variant="primary"
              class="rounded-0 mb-25 px-2 py-75"
              show
            >
              {{ user.note }}
            </b-alert>

            <b-list-group flush>
              <!--    START::Tags    -->
              <b-list-group-item
                v-if="Array.isArray(user.userGroups) && user.userGroups.length"
                v-b-tooltip.hover.v-dark
                :title="$t('Groups')"
                class="d-flex align-items-start py-50"
              >
                <span class="mr-1">
                  <sw-icon
                    class="text-primary"
                    icon="HashIcon"
                    size="16"
                  />
                </span>

                <div
                  class="d-flex flex-wrap"
                  style="row-gap: .34rem; column-gap: .5rem;"
                >
                  <b-badge
                    v-for="tag in user.userGroups"
                    :key="`tag_${tag.id}`"
                    variant="light-secondary"
                    class="p-25 d-flex align-items-center"
                  >
                    <div
                      class="color-dot mr-25"
                      :style="{ backgroundColor: tag.color || '#fff' }"
                      style="border: 1px solid white"
                    />

                    {{ tag.name }}
                  </b-badge>
                </div>
              </b-list-group-item>
              <!--    END::Tags    -->

              <!--    START::Organization    -->
              <b-list-group-item
                v-if="user.organization"
                v-b-tooltip.hover.v-dark
                :title="$t('Organization')"
                class="d-flex align-items-start py-50"
              >
                <span class="mr-1">
                  <sw-icon
                    class="text-primary"
                    icon="GlobeIcon"
                    size="16"
                  />
                </span>

                <span>{{ user.organization.name }}</span>
              </b-list-group-item>
              <!--    END::Organization    -->

              <!--    START::Phone    -->
              <b-list-group-item
                v-if="user.phone"
                v-b-tooltip.hover.v-dark
                :title="$t('Phone')"
                class="d-flex align-items-start py-50"
                :href="user.phone ? `tel:${user.phone}` : ''"
              >
                <span class="mr-1">
                  <sw-icon
                    class="text-primary"
                    icon="PhoneIcon"
                    size="16"
                  />
                </span>

                <div
                  class="flex-grow-1 d-flex align-items-center justify-content-between"
                  style="column-gap: .5rem; row-gap: .34rem;"
                >
                  <span>{{ user.phone | phoneFormat }}</span>

                  <b-button
                    v-b-tooltip.hover.v-success
                    variant="flat-success"
                    class="btn-icon p-25"
                    :title="$t('Copy')"
                    @click.prevent="copy(user.phone)"
                  >
                    <sw-icon icon="CopyIcon" />
                  </b-button>
                </div>
              </b-list-group-item>
              <!--    END::Phone    -->

              <!--    START::Email    -->
              <b-list-group-item
                v-if="user.email"
                v-b-tooltip.hover.v-dark
                :title="$t('Email')"
                class="d-flex align-items-start py-50"
                :href="user.email ? `mailto:${user.email}` : ''"
              >
                <span class="mr-1">
                  <sw-icon
                    class="text-primary"
                    icon="MailIcon"
                    size="16"
                  />
                </span>

                <div
                  class="flex-grow-1 d-flex flex-wrap align-items-center justify-content-between"
                  style="column-gap: .5rem; row-gap: .34rem;"
                >
                  <div
                    style="
                          max-width: calc(100% - 2.5rem);
                          white-space: nowrap;
                          text-overflow: ellipsis;
                          overflow: hidden;
                        "
                  >
                    {{ user.email }}
                  </div>

                  <b-button
                    v-b-tooltip.hover.v-success
                    variant="flat-success"
                    size="sm"
                    class="btn-icon p-25"
                    :title="$t('Copy')"
                    @click.prevent="copy(user.email)"
                  >
                    <sw-icon icon="CopyIcon" />
                  </b-button>
                </div>
              </b-list-group-item>
              <!--    END::Email    -->
            </b-list-group>
          </b-card>
          <!--    END::Basic Information    -->

          <!--    START::Information    -->
          <b-card
            class="m-0"
            no-body
          >
            <template #header>
              <div class="mb-n1 w-100 d-flex align-items-center justify-content-between">
                <h5 class="mb-0 text-capitalize">
                  {{ $t('Information') }}
                </h5>
              </div>
            </template>

            <b-list-group flush>
              <!--    START::Account type    -->
              <b-list-group-item class="d-flex align-items-start py-50">
                <span class="mr-1">
                  <sw-icon icon="BriefcaseIcon"
                           class="text-primary"
                           size="16"
                  />
                </span>
                <div class="flex-grow-1 d-flex align-items-center justify-content-between flex-wrap">
                  <p class="mb-0">
                    {{ $t('AccountType') }}
                  </p>

                  <template>
                    {{ !user.isOrganization ? $t('privatePerson') : $t('company') }}
                  </template>
                </div>
              </b-list-group-item>
              <!--    END::Email    -->
              <!--    END::Email    -->
              <b-list-group-item class="d-flex align-items-start py-50">
                <span class="mr-1">
                  <sw-icon icon="UserIcon"
                           class="text-primary"
                           size="16"
                  />
                </span>
                <div class="flex-grow-1 d-flex align-items-center justify-content-between flex-wrap">
                  <p class="mb-0">
                    {{ $t('contact.parentUser') }}
                  </p>

                  <template>
                    <avatar v-if="user.parentUser"
                            :user="user.parentUser"
                    />
                    <sw-icon v-else
                             icon="MinusIcon"
                    />
                  </template>
                </div>
              </b-list-group-item>

              <b-list-group-item
                class="d-flex align-items-start justify-content-center bg-tonal-primary text-primary cursor-pointer py-50"
                @click="showMoreModal = true"
              >
                <p class="mb-0">
                  {{ $t('ShowMore') }}…
                </p>
              </b-list-group-item>
            </b-list-group>
          </b-card>
        </div>
      </b-col>

      <b-col md="8">
        <b-tabs>
          <b-tab :title="$t('RanksSettings')">
            <b-card>
              <rank-details
                v-if="user"
                view-mode
                hide-title
                :rank-id="user.rank.id"
              />
            </b-card>
          </b-tab>
          <b-tab v-if="checkRequiredPermissions([
                   $roles.USER_STRUCTURE_VIEW_ALL,
                   $roles.USER_STRUCTURE_VIEW_STRUCTURE,
                   $roles.USER_STRUCTURE_VIEW_STRUCTURE_FIRST,
                 ])"
                 :title="$t('Structure')"
          >
            <structure :root-user-id="user.id" />
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>

    <b-card
      v-else
      no-body
      class="d-flex justify-content-center align-items-center"
    >
      <b-spinner
        variant="primary"
        class="my-2 mx-auto"
      />
    </b-card>

    <b-modal v-model="showMoreModal"
             hide-footer
             size="lg"
             :title="$t('ShowMore')"
    >
      <UserDetails v-if="user"
                   :user="user"
      />
    </b-modal>
  </div>
</template>

<script>
import { BButtonGroup, VBTooltip } from 'bootstrap-vue'
import { CHANGE_USER_STATUS, GET_SINGLE_USER } from '@/@constants/mutations'
import axiosIns from '@/libs/axios'
import UserDetails from '@/views/core/user/detail/Details.vue'
import RankDetails from '@/views/settings/ranks/RankDetails.vue'
import Structure from '@/pages/structure/Structure.vue'

export default {
  name: 'UserNew',
  components: {
    Structure, RankDetails, UserDetails, BButtonGroup,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data: () => ({
    loading: true,
    showMoreModal: false,
    user: null,
    ranks: [],
  }),
  '$route.params.userId': {
    deep: true,
    handler(newValue, oldValue) {
      if (newValue !== oldValue) this.loadUser()
    },
  },
  mounted() {
    this.loadUser()
  },
  methods: {
    async loadUser() {
      const { userId } = this.$route.params
      this.loading = true

      try {
        // eslint-disable-next-line no-unused-vars
        const [userResponse, ranksListResponse] = await Promise.all([
          this.$store.dispatch(`users/${GET_SINGLE_USER}`, userId),
          axiosIns.get('1/ranks', { params: { fields_load: this.$fields.RANKS } }),
        ])

        this.user = userResponse
        this.ranks = ranksListResponse.data.data.items
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.loading = false
      }
    },

    async resetUserPassword(userId) {
      try {
        await this.$store.dispatch('auth/RESET_USER_PASSWORD', userId)
        this.showAlert('success', '', this.$i18n.t('UserRestartInfo'), false)
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    async changeUserStatus(userId) {
      try {
        await this.$store.dispatch(`users/${CHANGE_USER_STATUS}`, { status: !this.user.blocked, userId })
        this.user.blocked = !this.user.blocked
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },

    copy(txt) {
      this.$copyText(txt, this.$refs.user_container).then(() => {
        this.showToast('primary', this.$i18n.t('CopiedToClipboard'))
      }, e => {
        this.showToast('danger', e)
      })
    },
  },
}
</script>

<style scoped>

</style>
