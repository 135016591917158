<template>
  <div
    class="d-flex flex-column"
    style="max-height: 480px"
    :class="{ 'py-2': !targetId }"
  >
    <div v-if="targetId">
      <h4>{{ $t('Comments') }}</h4>

      <!--      Task: Comments      -->
      <div
        v-if="commentList.length"
        class="position-relative mt-50 px-1 mb-1 overflow-y-scroll"
      >
        <!--      Task Comments log      -->
        <div
          class="h-100 d-flex flex-column"
          style="gap: 1.24rem"
        >
          <div
            v-for="(comment, commentIndex) in commentList"
            :key="commentIndex"
            class="w-100 text-dark d-flex flex-row align-content-start"
            style="gap: .64rem"
          >
            <b-avatar
              :src="comment.createdBy.avatar"
              size="34"
            />

            <!--      Task Comments log: content      -->
            <div
              class="mt-50 d-flex flex-column"
              style="row-gap: .14rem"
            >
              <!--      Task Comments log content: user      -->
              <div
                class="mb-25 font-small-3 d-flex flex-wrap align-items-center"
                style="gap: .14rem .34rem"
              >
                <strong>
                  {{ `${comment.createdBy.firstName} ${comment.createdBy.lastName}` }}
                </strong>

                <!--      Time      -->
                <date
                  :date="comment.createdAt.date"
                  :title="$t('offer.CreatedTime')"
                />
              </div>

              <!--      Task Comments log content: comment      -->
              <b-badge
                variant="light-primary"
                class="mr-auto text-left text-wrap"
              >
                {{ comment.content }}
              </b-badge>
            </div>
          </div>
        </div>
      </div>

      <!--      Task: No Comments      -->
      <div
        v-else
        class="text-center py-1 text-primary font-weight-bold"
      >
        <feather-icon
          size="17"
          icon="XCircleIcon"
        />

        {{ $t('NoComments') }}
      </div>

      <!--      Task Comments: Bar      -->
      <div
        v-if="targetId"
        class="shadow rounded bg-white px-50 pt-50 py-md-50 d-flex flex-column flex-md-row align-items-center"
      >
        <b-form-input
          v-model="newComment"
          :placeholder="$t('Comment') + '…'"
          class="rounded-0 border-0 shadow-none"
          style="box-shadow: none !important;"
          :disabled="!isEdit"
        />

        <div class="d-flex align-items-center justify-content-between ml-auto">
          <span class="text-primary text-nowrap">
            {{ newComment.length }} / {{ newCommentLimit }}
          </span>

          <b-button
            variant="flat-primary"
            class="rounded-0"
            :disabled="1 > newComment.length || newComment.length > newCommentLimit || !isEdit"
            @click="newMessage(newComment)"
          >
            {{ $t('Send') }}
          </b-button>
        </div>
      </div>

      <b-overlay
        no-wrap
        :show="isLoading || isMeLoading"
        spinner-variant="primary"
      />
    </div>

    <b-overlay
      :show="!targetId"
      no-wrap
    >
      <template #overlay>
        <strong class="text-primary">
          {{ $t('saveFirst') }}
        </strong>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import Date from '@/pages/projects/components/new/components/Date.vue'
import { VBTooltip } from 'bootstrap-vue'
import {
  CREATE_PROJECT_COMMENT,
  CREATE_PROJECT_TASK_COMMENT,
  GET_PROJECT_COMMENTS,
  GET_PROJECT_TASK_COMMENTS,
} from '@/@constants/mutations'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Date,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    isEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
    isTask: {
      type: Boolean,
      required: false,
      default: false,
    },
    projectId: {
      type: [Number, null],
      required: false,
      default: null,
    },
    targetId: {
      type: [Number, null],
      required: false,
      default: null,
    },
  },
  data: () => ({
    isMeLoading: false,

    newComment: '',
    newCommentLimit: 2000,

    commentList: [],
  }),
  watch: {
    projectId(n) { if (n) this.loadComments() },
    targetId(n) { if (n) this.loadComments() },
  },
  mounted() {
    this.ini()
  },
  methods: {
    ini() {
      this.loadComments()
    },

    loadComments() {
      const { isTask, projectId, targetId } = this

      this.isMeLoading = true

      if (targetId) {
        const type = !isTask ? `projectComments/${GET_PROJECT_COMMENTS}` : `taskComments/${GET_PROJECT_TASK_COMMENTS}`
        const payload = {
          projectId: !isTask ? targetId : projectId,
        }

        if (isTask) payload.taskId = targetId

        this.$store.dispatch(type, payload)
          .then(res => { this.commentList = res })

          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          })

          .finally(() => { this.isMeLoading = false })
      } else {
        this.isMeLoading = false
      }
    },

    getPayload(content) {
      const payload = {
        content,
      }

      return payload
    },

    newMessage(content) {
      if (content.length > 0 && content.length < this.newCommentLimit) {
        const payload = this.getPayload(content)

        if (!this.isTask) {
          this.sendToProject(payload)
        } else {
          this.sendToTask(payload)
        }
      }
    },

    sendToProject(payload) {
      this.isMeLoading = true

      const { targetId } = this

      const projectId = targetId

      this.$store.dispatch(`projectComments/${CREATE_PROJECT_COMMENT}`, { projectId, payload })
        .then(() => {
          this.showToast('success', this.$i18n.t('CommentAdded'))

          this.newComment = ''

          this.loadComments()
        })

        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })

        .finally(() => { this.isMeLoading = false })
    },

    sendToTask(payload) {
      this.isMeLoading = true

      const { projectId } = this

      const taskId = this.targetId

      this.$store.dispatch(`taskComments/${CREATE_PROJECT_TASK_COMMENT}`, { projectId, taskId, payload })
        .then(() => {
          this.showToast('success', this.$i18n.t('CommentAdded'))

          this.newComment = ''

          this.loadComments()
        })

        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })

        .finally(() => { this.isMeLoading = false })
    },
  },
}
</script>
