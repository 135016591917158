<template>
  <b-button
    v-if="!filter.hide"
    variant="flat-primary"
    size="sm"
    class="p-25"
    @click="$emit('click')"
  >
    <sw-icon icon="RefreshCwIcon" />
  </b-button>
</template>

<script>
export default {
  emits: ['click'],
  data: () => ({
    filter: { },
  }),
  watch: {
    value: {
      deep: true,
      handler(n) { if (n) this.filter = JSON.parse(JSON.stringify(n)) },
    },
  },
  async mounted() {
    if (this.value) this.filter = JSON.parse(JSON.stringify(this.value))
  },
}
</script>
