<template>
  <div id="sw-endpoints-tester">
    <b-card>
      <div>
        <b-form-group class="w-100">
          <b-input-group>
            <!--            <b-input-group-prepend is-text>-->
            <!--              {{ axiosIns.defaults.baseURL }}-->
            <!--            </b-input-group-prepend>-->

            <!--            <b-form-input v-model="endpoint" />-->
            <v-select v-model="endpoint"
                      :filter="(options, search) => options.filter(q => findOptions(q, search))"
                      :options="Object.values(endpointsList).sort((a, b) => (b.path > a.path) ? 1 : -1)"
                      class="w-100"
                      label="path"
            >
              <template #no-options>
                {{ $t('NoOptions') }}
              </template>
              <template #selected-option="{ path, potentialEntity }">
                <span v-if="potentialEntity"
                      class="text-primary"
                >({{ potentialEntity }})</span>
                {{ path }}
              </template>
              <template #option="{ path, potentialEntity, methods = [] }">
                <div>
                  <span v-if="potentialEntity"
                        class="text-primary"
                  >({{ potentialEntity }})</span>
                  {{ path }}
                </div>
                <div>
                  <b-badge v-for="(method, methodIndex) of methods"
                           :key="`${path}_${methodIndex}`"
                           class="mr-25"
                  >
                    {{ method }}
                  </b-badge>
                </div>
              </template>
            </v-select>
          </b-input-group>
        </b-form-group>

        <b-row v-if="endpoint && endpoint.requirements">
          <b-col v-for="([key], index) in Object.entries(endpoint.requirements)"
                 :key="`requirement_${index}`"
                 sm="12"
                 md="6"
                 lg="4"
          >
            <b-form-group :label="key">
              <b-form-input
                v-model="endpoint.requirements[key]"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <div v-if="endpoint">
      <b-badge v-for="method in endpoint.methods"
               :key="method"
               class="cursor-pointer p-1 mr-1 font-medium-1"
               :variant="selectedMethod === method ? 'primary' : 'light-primary'"
               @click="selectedMethod = method; fieldsToFill = []"
      >
        {{ method }}
      </b-badge>
    </div>
    <b-card v-if="endpoint">
      <div v-if="['GET'].includes(selectedMethod)">
        <b-form-group>
          <template #label>
            <label>Poziom danych</label>
            <sw-icon v-b-tooltip
                     icon="AlertCircleIcon"
                     title="fields_info"
            />
          </template>
          <b-form-input v-model="getConfig.fieldsInfo" />
        </b-form-group>

        <b-form-checkbox v-model="getConfig.timestamps">
          Ignoruj czasy (utworzenie, aktualizacja, usuwanie)
          <sw-icon v-b-tooltip
                   icon="AlertCircleIcon"
                   title="fields_ignore=ts"
          />
        </b-form-checkbox>
      </div>

      <div v-if="['POST', 'PATCH', 'PUT'].includes(selectedMethod)">
        <b-button size="sm"
                  class="my-50"
                  :disabled="fieldsLoading"
                  @click="getFields"
        >
          Pobierz pola
        </b-button>

        <b-row>
          <b-col v-for="([key], index) in Object.entries(fieldsToFill)"
                 :key="`payload_field_${index}`"
                 md="4"
                 sm="6"
          >
            <b-form-group>
              <template #label>
                <div class="d-flex flex-column">
                  <span>{{ key.ucFirst() }}</span> <span class="text-primary">:{{ fieldsToFill[key].type }}</span>
                </div>
              </template>
              <b-input-group>
                <b-input-group-prepend v-if="fieldsToFill[key].endpoint">
                  <b-button :id="`popover_target_button_${key}_${index}`"
                            variant="outline-primary"
                  >
                    <feather-icon icon="MoreVerticalIcon" />
                  </b-button>

                  <!-- Endpoint details -->
                  <b-popover
                    :target="`popover_target_button_${key}_${index}`"
                    triggers="focus"
                    variant="primary"
                    container="sw-endpoints-tester"
                  >
                    <template #title>
                      <div class="d-flex flex-column">
                        <div>{{ fieldsToFill[key].endpoint.potentialEntity }}</div>
                        <small>{{ fieldsToFill[key].endpoint.path }}</small>
                      </div>
                    </template>

                    <div>
                      <!--                          <div class="d-flex justify-content-between">-->
                      <!--                              <span>Pola:</span>-->
                      <!--                              <span class="text-primary">-->
                      <!--                                  <feather-icon icon="DownloadIcon" />Załaduj-->
                      <!--                              </span>-->
                      <!--                          </div>-->

                      <div class="d-flex justify-content-between">
                        <span>Przykładowe wartości:</span>
                        <span v-if="!fieldsToFill[key].optionsLoading"
                              class="text-primary cursor-pointer"
                              @click="loadSamples(fieldsToFill[key].endpoint, key, index)"
                        >
                          <feather-icon icon="DownloadIcon"
                                        class="mr-25"
                          />
                          {{ fieldsToFill[key].options.length ? 'Załaduj więcej' : 'Załaduj' }}
                        </span>
                        <b-spinner v-else
                                   size="sm"
                        />
                      </div>

                      <b-list-group v-if="fieldsToFill[key].options.length">
                        <b-list-group-item v-for="(option, optionIndex) in fieldsToFill[key].options"
                                           :key="`options_${key}_${index}_${optionIndex}`"
                                           @click="fieldsToFill[key].value = option.id"
                        >
                          {{ Object.values(option).join(', ') }}
                        </b-list-group-item>
                      </b-list-group>
                    </div>
                  </b-popover>
                </b-input-group-prepend>
                <b-form-input v-model="fieldsToFill[key].value" />
                <b-input-group-append is-text>
                  <feather-icon icon="TrashIcon"
                                @click="removeFieldToFill(key)"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <b-card v-if="response">
      <pre>{{ JSON.stringify(response, null, "\t") }}</pre>
    </b-card>

    <b-button
      class="mt-50"
      variant="primary"
      @click="push"
    >
      {{ $t('Save') }}
    </b-button>
  </div>
</template>

<script>
/* eslint-disable */
import vSelect from 'vue-select'
import {BFormTextarea, BInputGroup, BInputGroupPrepend, BInputGroupAppend, BPopover, VBTooltip} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import axiosIns from '@/libs/axios'

export default {
  name: 'Coppier',
  components: {
    BPopover,
    vSelect,
    BFormTextarea,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
  },
    directives: {
      'b-tooltip': VBTooltip,
    },
  filters: {
    jsonFormatter: value => JSON.stringify(value, null, 2),
  },
  data: () => ({
    endpointsList: [],
    response: '',
    fieldsToFill: {},
    payload: '{}',
    endpoint: '/1/trainingTrainers',
    axiosIns,
    selectedMethod: 'GET',
    fieldsLoading: false,
    payloadFields: [],
    getConfig: {
        fieldsInfo: 0,
        timestamps: false,
    },

    methods: [
        'GET',
        'POST',
        'PATCH',
        'DELETE',
        'PUT',
    ]
  }),
  async mounted() {
    this.loading = true
    try {
      const resp = await axiosIns.get('/endpoints', { dev: true })

      this.endpointsList = resp.data.data
    } finally {
      this.loading = false
    }
    // const user = typeof this.currentUser === 'string' ? JSON.parse(this.currentUser) : this.currentUser
    // if (user?.email !== 'test@saleswizardapp.com' || user?.email !== 'superadmin@saleswizardapp.com') {
    //   this.$router.push('/')
    // }
  },
  methods: {
      async loadSamples(endpoint, key, index) {
          this.fieldsToFill[key].optionsLoading = true

          const checkResponse = await axiosIns({
              method: 'GET',
              params: { fields_info: 0 },
              url: endpoint.path.replaceAll('/api/v1/user/', ''),
              headers: {
                  SwVersion: '08.10.2024/9:00',
              }
          })

          const fields = ['id', 'createdAt']
          const keys = Object.keys(checkResponse.data.data)
          const fieldsToCheck = {
              translations: 'translations.name',
              name: 'name',
              title: 'title',
              firstName: 'firstName',
              lastName: 'lastName',
          }

          Object.entries(fieldsToCheck).forEach(([key, field]) => {
              if (keys.includes(key)) {
                  fields.push(field)
              }
          })

          const response = await axiosIns({
              method: 'GET',
              params: {
                  fields: fields.join(','),
                  limit: 5,
                  page: (this.fieldsToFill[key].options?.length / 5) + 1,
              },
              url: endpoint.path.replaceAll('/api/v1/user/', ''),
          })

          this.fieldsToFill[key].options.push(...(response.data?.data?.items || []))
          this.fieldsToFill[key].optionsLoading = false
      },
      async getFields() {
        try {
            const fieldsToSkip = [
                'deletedAt',
                'deletedBy',
                'updatedAt',
                'updatedBy',
                'createdAt',
                'createdBy',
                '->',
                'deleted',
            ]

            if (this.selectedMethod === 'POST') fieldsToSkip.push('id*')

            const resp = await axiosIns({
                method: 'GET',
                params: { fields_info: 0 },
                url: this.endpoint.path.replaceAll('/api/v1/user/', ''),
            })

            const fields = {}
            Object.entries(resp.data.data)
                .filter(([key, _]) => !fieldsToSkip.includes(key))
                .forEach(([key, value]) => {
                    const v = value.replaceAll('App\\Entity\\', '').replaceAll(' [ALLOW]', '')
                    const endpoint = Object.values(this.endpointsList).find(endpoint => endpoint?.potentialEntity === v && endpoint?.methods?.includes('GET') && !Object.keys(endpoint?.requirements && !endpoint.path.includes('Statistics')).length);
                    if (v) fields[key.replaceAll('*', '')] = {
                        type: v,
                        value: '',
                        endpoint,
                        options: [],
                        optionsLoading: false,
                    }
                })

            this.fieldsToFill = fields
        } catch (err) {
            console.error(err)
        }
      },
    async push() {
      // const payload = JSON.parse(this.payload)

      if (this.endpoint) {
          const c = {}

          if (this.selectedMethod === 'GET') {
              c.params = {
                    fields_info: Math.abs(this.getConfig.fieldsInfo),
                    fields_ignore: 'ts'
              }

              if (!this.getConfig.timestamps) {
                  delete c.params.fields_ignore
              }
          }

        try {
          const resp = await axiosIns({
            method: this.selectedMethod,
            url: this.endpoint.path.replaceAll('/api/v1/user/', ''),
            ...c,
            headers: {
              SwVersion: '08.10.2024/9:00',
            }
          })

            this.response = resp.data.data
        } catch (err) {
            this.response = err?.response?.data
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        }
      } else {
          const x = [
              {
                  "name": "4B Systems",
                  "translations": [
                      {
                          "locale": "pl_pl",
                          "pageTitle": "4B Systems oferta",
                          "bannerTitle": "Rozwiązania IT dla Twojej firmy",
                          "bannerDescription": "Systemy CRM | Systemy B2B i B2C | Sklepy internetowe | Aplikacje mobilne | Systemy zamówień online",
                          "companyTitle": "Dlaczego warto nas wybrać?",
                          "companyDescription": "Co wyróżnia Software House 4B Systems?",
                          "companyOptions": [
                              "Doświadczenie w najnowszych technologiach programowania",
                              "Elastyczność i partnerstwo",
                              "Kilkadziesiąt zrealizowanych projektów",
                              "Wsparcie we wdrożeniu wybranego rozwiązania IT",
                              "Wsparcie w marketingu internetowym projektu"
                          ],
                          "productTitle": "Twoja oferta",
                          "productDescription": "Zapoznaj się z proponowanym rozwiązaniem",
                          "faqTitle": "Najczęstsze pytania Klientów",
                          "faqDescription": "Przeczytaj o co najczęściej pytają nasi Klienci.",
                          "questionsAndAnswers": [
                              {
                                  "answer": "Tak, jesteśmy polskim producentem oprogramowania. Oferujemy usługi programowania oraz gotowe usługi (SaaS) oprogramowania w abonamencie. Wejdź na naszą stronę www.4bsystems.pl aby dowiedzieć się więcej.",
                                  "question": "Czy 4B Systems to polska firma?"
                              },
                              {
                                  "answer": "Przede wszystkim programowanie w rozliczeniu godzinowym lub projektowym. Projektujemy i programujemy przede wszystkim w PHP - Laravel, CodeIgniter, Symfony. Zajmujemy się także outsourcingiem programistów Python.",
                                  "question": "Jakie usługi oferujecie?"
                              },
                              {
                                  "answer": "Na stronie oferty znajdziesz kilka wzmianek o realizowanych projektach. Po więcej zapraszamy do sekcji Case Studies na naszej stronie www - https://4bsystems.pl/case-studies-4bsystems/.",
                                  "question": "Jakie projekty realizowaliście?"
                              },
                              {
                                  "answer": "Przede wszystkim jest to oprogramowanie webowe CRM - SalesWizard. Ponadto tworzymy i rozwijamy własny silnik rezerwacyjny OrderEngine, który wykorzystujemy w branży wynajmu (Bookable.pl) oraz zamawiania jedzenia (Foodeliver.pl). Zapraszamy na strony tych produktów po więcej informacji.",
                                  "question": "Jakie usługi SaaS oferujecie?"
                              }
                          ],
                          "videoTitle": "Prezentacja 4B Systems",
                          "videoDescription": "Dowiedz się więcej o 4B Systems z naszej prezentacji video!",
                          "videoLink": "https://www.youtube-nocookie.com/embed/T4lkBNezdEk",
                          "opinionTitle": "Rekomendacje naszych Klientów",
                          "opinionDescription": "Przeczytaj opinie i case studies z naszych realizacji!",
                          "contactTitle": "Masz pytania? Skontaktuj się ze mną!",
                          "informationGridTitle": "Poznaj naszą specjalizację!",
                          "informationGridDescription": "Zobacz w jakich projektach specjalizuje się nasz zespół programistów!",
                          "portfolioName": "Poznaj inne nasze produkty i usługi",
                          "cooperationName": "Nasi Klienci"
                      }
                  ],
                  "logo": null,
                  "favicon": null,
                  "menuColor": "#ffffff",
                  "footerColor": "#428a61",
                  "bannerColor": "#aee5c5",
                  "buttonColor": "#ff7300",
                  "banner": null,
                  "productDetails": false,
                  "productPrices": true,
                  "contactCompanyDetails": true,
                  "contactAvatar": null,
                  "generatePdf": false,
                  "additionalFiles": false,
                  "offerWebTemplateOpinionItems": [
                      {
                          "translations": [
                              {
                                  "locale": "pl_pl",
                                  "title": "System rezerwacyjny",
                                  "description": "Współpraca z 4B Systems sp. z o.o. we wdrożeniu systemu SalesWizard pokazała nam, że jest to solidny partner dla wsparcia rozwoju naszego autorskiego oprogramowania.",
                                  "author": "Krzysztof Janas, CEO Infoski.pl"
                              }
                          ],
                          "avatar": null
                      },
                      {
                          "translations": [
                              {
                                  "locale": "pl_pl",
                                  "title": "Etykietomat.pl",
                                  "description": "Serdecznie polecam firmę 4B Systems. Po tym projekcie zyskali moje pełne zaufanie i z przyjemnością skorzystam jeszcze z ich usług.",
                                  "author": "Michał Pabiasz, CEO Flexon s.c."
                              }
                          ],
                          "avatar": null
                      },
                      {
                          "translations": [
                              {
                                  "locale": "pl_pl",
                                  "title": "CRM dla kancelarii",
                                  "description": "Wybraliśmy 4B Systems jako dostawcę rozwiązania LegallyCRM dla naszej Kancelarii.",
                                  "author": "Jacek Wrzosek, Adversum.pl"
                              }
                          ],
                          "avatar": null
                      },
                      {
                          "translations": [
                              {
                                  "locale": "pl_pl",
                                  "title": "Wzmocnienie zespołu programistów",
                                  "description": "Skorzystaliśmy z usługi outsourcingu pracownika 4B Systems sp. z o.o. do wykonywania odpowiedzialnych zadań w projekcie opartym o Python.",
                                  "author": "Mateusz Kozak, CTO w Bankilo.pl"
                              }
                          ],
                          "avatar": null
                      }
                  ],
                  "offerWebTemplatePortfolioItems": [
                      {
                          "translations": [
                              {
                                  "locale": "pl_pl",
                                  "title": "System rezerwacyjny dla hoteli",
                                  "url": "https://bookable.pl/"
                              }
                          ],
                          "file": null
                      },
                      {
                          "translations": [
                              {
                                  "locale": "pl_pl",
                                  "title": "Oprogramowanie CRM dla firm",
                                  "url": "https://saleswizard.pl/"
                              }
                          ],
                          "file": null
                      },
                      {
                          "translations": [
                              {
                                  "locale": "pl_pl",
                                  "title": "System zamówień online jedzenia",
                                  "url": "https://foodeliver.pl/"
                              }
                          ],
                          "file": null
                      },
                      {
                          "translations": [
                              {
                                  "locale": "pl_pl",
                                  "title": "Onboarding pracowników ",
                                  "url": "https://4bsystems.pl/uslugi-saas-dla-biznesu/"
                              }
                          ],
                          "file": null
                      },
                      {
                          "translations": [
                              {
                                  "locale": "pl_pl",
                                  "title": "CRM dla branży OZE",
                                  "url": "https://4bsystems.pl/uslugi-saas-dla-biznesu/solarcrm-system-dla-fotowoltaiki/"
                              }
                          ],
                          "file": null
                      },
                      {
                          "translations": [
                              {
                                  "locale": "pl_pl",
                                  "title": "CRM dla kancelarii prawnych",
                                  "url": "https://4bsystems.pl/uslugi-saas-dla-biznesu/legallycrm-system-crm-dla-prawnikow/"
                              }
                          ],
                          "file": null
                      },
                      {
                          "translations": [
                              {
                                  "locale": "pl_pl",
                                  "title": "Oprogramowanie sprzedaży w MLM",
                                  "url": "https://4bsystems.pl/uslugi-saas-dla-biznesu/mlmseed-system-sprzedazy-bezposredniej/"
                              }
                          ],
                          "file": null
                      }
                  ],
                  "active": true,
                  "faqEnabled": true,
                  "videoEnabled": true,
                  "opinionsEnabled": true,
                  "attachmentsEnabled": false,
                  "contactDetails": true,
                  "bannerTextColor": "#000000",
                  "companyBanner": null,
                  "questionSelectedColor": "#73b58e",
                  "questionSelectedTextColor": "#000000",
                  "contactTextColor": "#000000",
                  "videoTextColor": "#000000",
                  "footerEnabled": true,
                  "footerShowAddress": true,
                  "footerCopyright": "Prawa autorskie zastrzeżone",
                  "contactPhone": "",
                  "contactEmail": "",
                  "companyEnabled": true,
                  "footerTextColor": "#ffffff",
                  "fontFamily": "Montserrat",
                  "filesBannerText": "Dodatkowe pliki oferty",
                  "footerLinkTextColor": "#000000",
                  "showProductsDescription": true,
                  "hideOfferPrice": false,
                  "sectionsOrder": [
                      "ABOUT_COMPANY",
                      "PRODUCTS",
                      "OPINIONS",
                      "VIDEO",
                      "INFORMATION_GRID",
                      "PORTFOLIO",
                      "QUESTION_AND_ANSWER",
                      "CONTACT",
                      "COOPERATION",
                      "OFFER_FILES"
                  ],
                  "informationGridEnabled": true,
                  "informationGridAsBoxes": true,
                  "offerWebTemplateInformationGridItems": [
                      {
                          "translations": [
                              {
                                  "locale": "pl_pl",
                                  "title": "Rozwiązania AI",
                                  "description": "Wdrażamy procesy oparte o OpenAI",
                                  "linkUrl": ""
                              }
                          ],
                          "icon": "CpuIcon"
                      },
                      {
                          "translations": [
                              {
                                  "locale": "pl_pl",
                                  "title": "Aplikacje mobilne",
                                  "description": "Aplikacje mobilne B2C i B2B na platformy Android i iOS",
                                  "linkUrl": ""
                              }
                          ],
                          "icon": "TabletIcon"
                      },
                      {
                          "translations": [
                              {
                                  "locale": "pl_pl",
                                  "title": "Aplikacje webowe",
                                  "description": "Aplikacje przeglądarkowe - systemy CRM, B2B, B2C",
                                  "linkUrl": ""
                              }
                          ],
                          "icon": "MonitorIcon"
                      },
                      {
                          "translations": [
                              {
                                  "locale": "pl_pl",
                                  "title": "Systemy zamówień online i sklepy online",
                                  "description": "Strony zamówień i sklepy online oparte o rozwiązania własne i open source",
                                  "linkUrl": ""
                              }
                          ],
                          "icon": "ShoppingCartIcon"
                      },
                      {
                          "translations": [
                              {
                                  "locale": "pl_pl",
                                  "title": "Programowanie na zlecenie",
                                  "description": "Realizujemy projekty w rozliczeniu godzinowym i projektowym",
                                  "linkUrl": ""
                              }
                          ],
                          "icon": "CodeIcon"
                      },
                      {
                          "translations": [
                              {
                                  "locale": "pl_pl",
                                  "title": "Outsourcing IT",
                                  "description": "Realizujemy usługi wzmocnienia zewnętrznych zespołów programistów",
                                  "linkUrl": ""
                              }
                          ],
                          "icon": "GitBranchIcon"
                      }
                  ],
              }
          ]
          await axiosIns.post('1/settings/offerWebTemplate', x)
      }
    },
      async removeFieldToFill(key) {
          delete this.fieldsToFill[key]
          this.$forceUpdate()
      },
      findOptions(q, search) {
          const findByString = search.replaceAll('%', '')

          if (search.startsWith('%')) {
              return q.path?.startsWith(findByString)
          } else if (search.endsWith('%')) {
              return q.path?.endsWith(findByString)
          } else return q.path.includes(findByString)
      }
  },
  setup() {
    const endpoints = ref([
      { name: 'Strona oferty www', method: 'POST', url: '1/settings/offerWebTemplate' },
      { name: 'Strona oferty www', method: 'PATCH', url: '1/settings/offerWebTemplate' },
      { name: 'Zgłoszenia', method: 'POST', url: '1/contacts/applications' }
    ])

    // const { filters, loading, response, data } = useContacts()

    return {
      endpoints,
      // filters,
      // loading,
      // data,
      // response,
    }
  },
}
</script>

<style lang="scss">
#sw-endpoints-tester {
    .popover {
      min-width: 380px;
      max-width: 350px !important;
    }
}
</style>
