var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"newProjectForm",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('EnterNameOfProcess')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('EnterNameOfProcess')}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('Title'),"state":errors.length > 0 ? false:null},on:{"input":function (e) { return _vm.$emit('getTitle', e); }},model:{value:(_vm.proces.title),callback:function ($$v) {_vm.$set(_vm.proces, "title", $$v)},expression:"proces.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('SelectFunnel')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('sw-select',{attrs:{"name":_vm.$t('SelectFunnel')}},[_c('v-select',{staticClass:"mb-1",attrs:{"id":"select_funnel","options":_vm.funnelList,"label":"name","reduce":function (funnel) { return funnel.contactThreadFunnelTypePoints[0].id; },"state":errors.length > 0 ? false:null},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('NoOptions'))+" ")]},proxy:true},{key:"selected-option",fn:function(ref){
var name = ref.name;
return [_vm._v(" "+_vm._s(_vm._f("truncate")(name,15, '...'))+" ")]}},{key:"option",fn:function(ref){
var name = ref.name;
return [_vm._v(" "+_vm._s(_vm._f("truncate")(name,15, '...'))+" ")]}}],null,true),model:{value:(_vm.proces.funnel),callback:function ($$v) {_vm.$set(_vm.proces, "funnel", $$v)},expression:"proces.funnel"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('SelectFunnel')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(!_vm.modalConfig.editedData)?_c('sw-select',{attrs:{"name":_vm.$t('SelectChecklist')}},[_c('v-select',{staticClass:"mb-1",attrs:{"id":"select_checklist","options":_vm.checklists,"label":"name","multiple":"","reduce":function (checklist) { return checklist.id.toString(); },"state":errors.length > 0 ? false:null},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('NoOptions'))+" ")]},proxy:true},{key:"selected-option",fn:function(ref){
var name = ref.name;
return [_vm._v(" "+_vm._s(name)+" ")]}},{key:"option",fn:function(ref){
var name = ref.name;
return [_vm._v(" "+_vm._s(name)+" ")]}}],null,true),model:{value:(_vm.proces.checklist),callback:function ($$v) {_vm.$set(_vm.proces, "checklist", $$v)},expression:"proces.checklist"}})],1):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('div',{staticClass:"mb-1"},[_c('label',[_vm._v(_vm._s(_vm.$t('AssignedUsers')))]),_c('assigned-users',{attrs:{"value":_vm.proces.assignedUsers,"is-multiple":true},on:{"input":_vm.changeProcesAssignedUsers}})],1),_c('b-form-group',{staticClass:"mb-1"},[_c('label',[_vm._v(_vm._s(_vm.$t('CustomerValue')))]),_c('b-row',[_c('b-col',{attrs:{"sm":"9"}},[_c('cleave',{staticClass:"form-control",attrs:{"options":_vm.cleaveOptions.number},model:{value:(_vm.proces.value),callback:function ($$v) {_vm.$set(_vm.proces, "value", $$v)},expression:"proces.value"}})],1),_c('b-col',{attrs:{"sm":"3"}},[_c('sw-select',[_c('v-select',{attrs:{"options":_vm.currencyList},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('NoOptions'))+" ")]},proxy:true}],null,true),model:{value:(_vm.proces.currency),callback:function ($$v) {_vm.$set(_vm.proces, "currency", $$v)},expression:"proces.currency"}})],1)],1)],1)],1),_c('div',{staticClass:"mt-1"},[_c('label',[_vm._v(_vm._s(_vm.$t('AssignedContact')))]),_vm._l((_vm.proces.assignedContacts),function(contact,index){return _c('div',{key:("thread_assigned_user_" + index),staticClass:"d-flex flex-wrap flex-md-nowrap mt-50"},[_c('sw-select',[_c('v-select',{staticClass:"w-50 mr-50 select-size-sm",staticStyle:{"min-width":"255px"},attrs:{"item-value":"id","options":_vm.contacts,"label":"firstName","filterable":false},on:{"search":_vm.onSearch},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var id = ref.id;
var avatar = ref.avatar;
var firstName = ref.firstName;
var lastName = ref.lastName;
return [_c('b-avatar',{attrs:{"size":"sm","src":avatar,"text":firstName.slice(0,1).toUpperCase() + lastName.slice(0,1).toUpperCase()}}),_c('span',{staticClass:"ml-50 pt-25"},[_vm._v(" "+_vm._s(firstName)+" "+_vm._s(lastName)+" "+_vm._s(id === '0' ? ("- " + (_vm.$t('Contact'))) : ''))])]}},{key:"selected-option",fn:function(ref){
var avatar = ref.avatar;
var firstName = ref.firstName;
var lastName = ref.lastName;
return [_c('b-avatar',{staticClass:"border border-white",attrs:{"size":"sm","src":avatar,"text":firstName.slice(0,1).toUpperCase() + lastName.slice(0,1).toUpperCase()}}),_c('span',{staticClass:"ml-50 pt-25"},[_vm._v(" "+_vm._s(firstName)+" "+_vm._s(lastName))])]}}],null,true),model:{value:(contact.contact),callback:function ($$v) {_vm.$set(contact, "contact", $$v)},expression:"contact.contact"}},[_c('template',{slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('typeToSearchContact'))+" ")])],2)],1),_c('b-input-group',{staticClass:"input-group-merge ml-25",staticStyle:{"min-width":"100px"},attrs:{"size":"sm"}},[_c('b-form-input',{attrs:{"type":"number","placeholder":_vm.$t('Part') + ' %'},model:{value:(contact.part),callback:function ($$v) {_vm.$set(contact, "part", $$v)},expression:"contact.part"}})],1),_c('b-button',{staticClass:"ml-50",attrs:{"variant":"flat-danger","size":"sm"},on:{"click":function($event){return _vm.deleteContact(index)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}})],1)],1)}),_c('div',{staticClass:"mt-50"},[_c('b-button',{attrs:{"size":"sm","variant":"flat-success"},on:{"click":_vm.addContact}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)],1)],2),_c('div',{staticClass:"d-flex justify-content-end pt-1 mt-1 border-top"},[_c('b-button',{staticClass:"mr-50",attrs:{"variant":"secondary","size":"sm"},on:{"click":_vm.onModalClose}},[_vm._v(" "+_vm._s(_vm.$t('Cancel'))+" ")]),_c('b-button',{attrs:{"variant":"primary","size":"sm","disabled":invalid},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('Save'))+" ")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }