import { render, staticRenderFns } from "./ShortcodesModal.vue?vue&type=template&id=0b87ccfa&scoped=true"
import script from "./ShortcodesModal.vue?vue&type=script&lang=js"
export * from "./ShortcodesModal.vue?vue&type=script&lang=js"
import style0 from "./ShortcodesModal.vue?vue&type=style&index=0&id=0b87ccfa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b87ccfa",
  null
  
)

export default component.exports