<template>
  <div v-if="checkRequiredModule(['fakturowniaModule', 'googleAdsModule']) && checkRequiredPermissions([$roles.CONTACT_THREAD_PAYMENT_SCHEDULE_VIEW_ALL])">
    <b-tabs>
      <b-tab v-if="checkRequiredModule('paymentModule') && checkRequiredPermissions([
               $roles.CONTACT_THREAD_PAYMENT_VIEW_ALL,
               $roles.CONTACT_THREAD_ASSIGNED_PAYMENT_VIEW_ALL,
               $roles.CONTACT_THREAD_ASSIGNED_PAYMENT_VIEW_SELF,
             ])"
             :title="$t('Payments')"
      >
        <schedules />
      </b-tab>
      <b-tab :title="$t('PaymentSchedule')">
        <PaymentSchedule />
      </b-tab>
    </b-tabs>
  </div>
  <div v-else>
    <schedules />
  </div>
</template>

<script>
import Schedules from '@/pages/Schedules.vue'
import PaymentSchedule from '@/pages/contact/components/PaymentSchedule.vue'

export default {
  name: 'List',
  components: { PaymentSchedule, Schedules },
}
</script>

<style scoped>

</style>
