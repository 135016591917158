<template>
  <b-alert
    show
    variant="primary"
    class="p-2 d-flex align-items-center shadow"
    :class="{ 'selected border-primary': isSelected }"
  >
    <b-avatar variant="primary">
      <feather-icon icon="HexagonIcon" />
    </b-avatar>

    <span class="d-flex flex-column ml-75">
      <strong>
        {{ name }}
      </strong>

      <span>
        {{ val | priceFormat }}
        {{ $store.getters['system/getSettings'] ? ($store.getters['system/getSettings'].defaultCurrency || 'PLN') : 'PLN' }}
      </span>
    </span>

    <b-overlay
      :show="isLoading"
      no-wrap
      spinner-variant="primary"
    />
  </b-alert>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
    val: {
      type: Number,
      required: true,
    },
    isSelected: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>
