import Vue from 'vue'
import i18n from '@/libs/i18n'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line import/no-cycle
import { discordWebhook } from '@/helpers/discordWebhook'

/* eslint-disable */
export const showToast = (variant = 'primary', text = 'Empty message', errorLog = null) => {
  let title = ''
  let icon = ''

  if (variant === 'success') {
    title = i18n.t('alerts.Success')
    icon = 'CheckIcon'
  } else if (variant === 'error' || variant === 'danger') {
    title = i18n.t('alerts.Failure')
    icon = 'XIcon'
  } else {
    title = i18n.t('Communicate')
    icon = 'HelpCircleIcon'
  }

  const props = {
    title,
    icon,
    text,
    variant: variant === 'error' ? 'danger' : variant,
  }

  if (text.toLowerCase() === i18n.t('errors.UNKNOWN_BUG').toLowerCase()) {
    console.log('Info ===============> ', errorLog)
    if (errorLog?.message.includes('Redirect') || errorLog?.message.includes("reading 'send'")) return console.log('Redirect ====> ')

    discordWebhook().pushErrorMessage(errorLog)
    // eslint-disable-next-line consistent-return
    return
  }

  if (variant === 'primary') {
    props.title = text
    props.icon = 'CopyIcon'
    delete props.text
  }
  Vue.$toast({
    component: ToastificationContent,
    props,
  }, {
    position: 'top-center',
  })
}

export const _ = null
