<template>
  <div>
    <component
      :is="to && forceLink ? 'router-link' : 'div'"
      v-if="user"
      :target="avatarTarget"
      :to="to"
      :class="globalClass && globalClass.length ? globalClass : 'd-flex align-items-center'"
    >
      <b-avatar
        v-if="showAvatar"
        v-b-tooltip
        :variant="variant"
        :size="size"
        :src="user.avatar"
        :text="getAvatarText"
        :title="showTooltip ? `${getFullName}` : ''"
      />

      <div :class="subtitleClasses">
        <span
          v-if="showName && user.name"
          class="w-100"
          :class="`${showAvatar && 'ml-25'} ${textVariant} ${textNowrap ? 'text-nowrap' : 'text-wrap'}`"
        >
          {{ user.name }}
        </span>

        <span
          v-else-if="showName"
          class="w-100"
          :class="`${showAvatar && 'ml-25'} ${textVariant} ${textNowrap ? 'text-nowrap' : 'text-wrap'}`"
        >
          {{ getFullName }}
        </span>

        <small class="text-muted ml-25">
          <slot
            v-if="!!$slots.subtitle"
            name="subtitle"
          />
        </small>
      </div>

      <slot
        v-if="!!$slots.after"
        name="after"
      />
    </component>
    <feather-icon
      v-else
      icon="MinusIcon"
    />
  </div>
</template>

<script>
/* eslint-disable */
import { VBTooltip } from 'bootstrap-vue'
import useAppCustomizer from '@core/layouts/components/app-customizer/useAppCustomizer'
import textIsland from '../../filters/parts/textIsland'

export default {
  name: 'Avatar',
  methods: { textIsland },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    truncate: {
      type: Number,
      default: 0,
    },
    globalClass: {
      type: String,
      default: null,
    },
    to: {
      type: Object,
      default: null,
    },
    forceLink: {
      type: Boolean,
      default: false,
    },
    size: {
      type: [Number, String],
      default: 24,
    },
    user: {
      default: null,
      required: true,
    },
    variant: {
      type: String,
      default: 'light-primary',
    },
    textVariant: {
      type: String,
      default: 'text-primary',
    },
    textNowrap: {
      type: Boolean,
      default: true,
    },
    showTooltip: {
      type: Boolean,
      default: false,
    },
    showName: {
      type: Boolean,
      default: true,
    },
    showAvatar: {
      default: true,
      type: Boolean,
    },
  },
  setup() {
    const { isAvatarLink, avatarTarget } = useAppCustomizer()

    return {
      isAvatarLink,
      avatarTarget,
    }
  },
  computed: {
    subtitleClasses() {
      if (this.$slots.subtitle) return ['d-flex', 'flex-column', 'ml-50']

      return []
    },
    getFirstName() {
      return this.user?.firstName || ''
    },
    getLastName() {
      return this.user?.lastName || ''
    },
    getFullName() {
      const name = `${this.getFirstName} ${this.getLastName}`
      return this.truncate ? this.$options.filters.truncate(name, this.truncate, '...') : name
    },
    getAvatarText() {
      return (this.user?.firstName ? this.user.firstName[0].toUpperCase() : '') + (this.user?.lastName ? this.user.lastName[0].toUpperCase() : '')
    },
  },
}
</script>

<style lang="scss">
.vs__dropdown-option--highlight:not(.vs__dropdown-option--selected) {
  .text-light {
    color: var(--theme-color-primary) !important;
  }
}

</style>
