import {
  DELETE_CONTRACT_TEMPLATE,
  GET_CONTRACTS_TEMPLATES,
  CREATE_CONTRACT_TEMPLATE,
  UPDATE_CONTRACT_TEMPLATE, GET_CONTRACT_TEMPLATE,
} from '@/@constants/mutations'
// eslint-disable-next-line import/no-cycle
import axiosIns from '@/libs/axios'
import * as fields from '@/@constants/fields'

export default {
  namespaced: true,
  actions: {
    // eslint-disable-next-line no-unused-vars
    [GET_CONTRACT_TEMPLATE]: ({ commit }, id) => new Promise((resolve, reject) => {
      axiosIns.get(`1/settings/agreementTemplates/${id}`, { params: { fields_load: fields.SETTINGS_AGREEMENTS_TEMPLATES } })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [GET_CONTRACTS_TEMPLATES]: ({ commit }, { pagination = null, filters = null }) => new Promise((resolve, reject) => {
      const params = {
        fields_load: fields.SETTINGS_AGREEMENTS_TEMPLATES,
      }

      if (pagination) {
        params.page = pagination.currentPage
        params.limit = pagination.perPage
      }

      if (filters) {
        if (filters?.type) {
          params.eq_agreementType = filters.type
        }
      }

      axiosIns.get('1/settings/agreementTemplates', { params })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [DELETE_CONTRACT_TEMPLATE]: ({ commit }, id) => new Promise((resolve, reject) => {
      axiosIns.delete('1/settings/agreementTemplates', { data: [id] })
        .then(() => {
          resolve()
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [CREATE_CONTRACT_TEMPLATE]: ({ commit }, contract) => new Promise((resolve, reject) => {
      axiosIns.post('1/settings/agreementTemplates', contract)
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [UPDATE_CONTRACT_TEMPLATE]: ({ commit }, contract) => new Promise((resolve, reject) => {
      axiosIns.patch('1/settings/agreementTemplates', contract)
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
  },
}
