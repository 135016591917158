<template>
  <!--    Filters Header    -->
  <b-row>
    <!--    Filter: Group    -->
    <b-col
      sm="12"
      md="6"
      lg="3"
    >
      <sw-select :name="$t('SelectGroup')">
        <v-select
          v-model="group"
          :options="groups"
          class="w-100"
          label="name"
          @input="selectGroup"
        >
          <template #no-options>
            {{ $t('NoOptions') }}
          </template>
          <template #option="{ name, color }">
            <div
              class="color-dot"
              style="border: 1px solid white"
              :style="{ backgroundColor: color }"
            />
            {{ name }}
          </template>
          <template #selected-option="{ name, color }">
            <div
              class="color-dot"
              style="border: 1px solid white"
              :style="{ backgroundColor: color }"
            />
            {{ name }}
          </template>
        </v-select>
      </sw-select>
    </b-col>

    <!--    Filter: Category    -->
    <b-col
      sm="12"
      md="6"
      lg="3"
    >
      <sw-select
        :disabled="!group"
        :name="$t('ChooseCategory')"
      >
        <v-select
          v-model="category"
          :options="categories"
          class="w-100"
          label="id"
          @input="selectCategory"
        >
          <template #option="item">
            {{ item.translations[0].name }}
          </template>
          <template #selected-option="item">
            {{ item.translations[0].name }}
          </template>
          <template #no-options>
            {{ $t('NoOptions') }}
          </template>
        </v-select>
      </sw-select>
    </b-col>

    <!--    Filter: Subcategory     -->
    <b-col
      sm="12"
      md="6"
      lg="3"
    >
      <sw-select
        :disabled="!group || !category"
        :name="$t('ChooseSubcategory')"
      >
        <v-select
          v-model="subcategory"
          :options="subcategories"
          class="w-100"
          label="id"
          :disabled="!category"
          @input="reloadData"
        >
          <template #option="item">
            {{ item.translations[0].name }}
          </template>
          <template #selected-option="item">
            {{ item.translations[0].name }}
          </template>
          <template #no-options>
            {{ $t('NoOptions') }}
          </template>
        </v-select>
      </sw-select>
    </b-col>

    <!--    Filter: Status    -->
    <b-col
      sm="12"
      md="6"
      lg="3"
    >
      <sw-select :name="$t('ChooseStatus')">
        <v-select
          v-model="status"
          :options="['Active', 'Inactive']"
          class="w-100"
          @input="reloadData"
        >
          <template #option="{ label }">
            {{ $t(`${label}`) }}
          </template>
          <template #selected-option="{ label }">
            {{ $t(`${label}`) }}
          </template>
          <template #no-options>
            {{ $t('NoOptions') }}
          </template>
        </v-select>
      </sw-select>
    </b-col>

    <b-col
      v-if="checkRequiredModule('loyaltyModule') && checkRequiredPermissions([$roles.LOYALTY_PLAN_VIEW_ALL])"
      sm="12"
      md="6"
      lg="3"
    >
      <sw-select :name="$t('SelectLoyaltyPlan')">
        <v-select
          v-model="loyaltyPlan"
          :options="loyaltyPlans"
          label="name"
          :reduce="plan => plan.id"
          @input="reloadData"
        >
          <template #no-options>
            {{ $t('NoOptions') }}
          </template>
        </v-select>
      </sw-select>
    </b-col>

    <b-col
      v-if="checkRequiredModule('marketingModule') && checkRequiredPermissions([$roles.MARKETING_PLAN_VIEW_ALL])"
      sm="12"
      md="6"
      lg="3"
    >
      <sw-select :name="$t('SelectMarketingPlan')">
        <v-select
          v-model="marketingPlan"
          :options="marketingPlans"
          label="name"
          :reduce="plan => plan.id"
          @input="reloadData"
        >
          <template #no-options>
            {{ $t('NoOptions') }}
          </template>
        </v-select>
      </sw-select>
    </b-col>
    <b-col
      sm="12"
      md="6"
    >
      <b-form-group :label="$t('offer.NettoPrice')">
        <div
          class="d-flex"
        >
          <b-input-group
            :prepend="$t('From')"
            class="mr-50"
          >
            <b-form-input
              v-model="priceMin"
              type="number"
              @blur="reloadData"
            />
          </b-input-group>
          <b-input-group
            :prepend="$t('To')"
          >
            <b-form-input
              v-model="priceMax"
              type="number"
              @blur="reloadData"
            />
          </b-input-group>
        </div>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import vSelect from 'vue-select'
import { GET_CATEGORY, GET_GROUPS } from '@/@constants/mutations'
import axiosIns from '@/libs/axios'

export default {
  name: 'Filters',
  components: {
    vSelect,
  },
  data: () => ({
    group: '',
    groups: [],
    category: '',
    categories: [],
    subcategory: '',
    subcategories: [],
    loyaltyPlans: [],
    loyaltyPlan: '',

    marketingPlans: [],
    marketingPlan: '',
    status: '',
    priceMin: 0,
    priceMax: 0,
  }),
  async created() {
    await Promise.all([this.loadGroups(), this.loadCategories(null), this.loadLoyaltyPlans(), this.loadMarketingPlans()])

    if ((window.location.origin.includes('hydropure')) && this.marketingPlans.length) {
      const hydropureProductInPlan = this.marketingPlans.find(e => e.name.includes('Plan marketingowy')) || this.marketingPlans[0]
      if (hydropureProductInPlan) {
        this.marketingPlan = this.getObjectId(hydropureProductInPlan)
      }
    }

    if (localStorage.getItem('shop_filters')) {
      Object.assign(this, JSON.parse(localStorage.getItem('shop_filters')))
      if (typeof this.priceMax === 'string') this.priceMax = parseFloat(this.priceMax)
      if (typeof this.priceMin === 'string') this.priceMin = parseFloat(this.priceMin)
      if (typeof this.group === 'string') this.group = this.groups.find(item => item.id === this.group)
      if (typeof this.category === 'string') this.category = this.categories.find(item => item.id === this.category)
      if (typeof this.subcategory === 'string') this.subcategory = this.subcategories.find(item => item.id === this.subcategory)
    }

    this.reloadData()
  },
  methods: {
    reloadData() {
      const filters = {
        group: this.getId(this.group),
        category: this.getId(this.category),
        subcategory: this.getId(this.subcategory),
        status: this.getId(this.status),
        loyaltyPlan: this.getId(this.loyaltyPlan),
        marketingPlan: this.getId(this.marketingPlan),
        priceMin: this.priceMin,
        priceMax: this.priceMax,
      }

      this.$emit('reloadData', filters)
    },
    // eslint-disable-next-line consistent-return
    async loadLoyaltyPlans() {
      if (this.checkRequiredModule('loyaltyModule') && this.checkRequiredPermissions([this.$roles.LOYALTY_PLAN_VIEW_ALL])) {
        try {
          const resp = await axiosIns.get('1/loyaltyPlans', { params: { fields_load: this.$fields.LOYALTY_PLANS } })

          this.loyaltyPlans = resp?.data?.data?.items || []
          return resp?.data?.data?.items
        } catch (err) {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          return err
        }
      }
    },
    // eslint-disable-next-line consistent-return
    async loadMarketingPlans() {
      if (this.checkRequiredModule('marketingModule') && this.checkRequiredPermissions([this.$roles.MARKETING_PLAN_VIEW_ALL])) {
        try {
          const resp = await axiosIns.get('1/marketingPlans', { params: { fields_load: this.$fields.MARKETING_PLANS } })

          this.marketingPlans = resp?.data?.data?.items || []
          return resp?.data?.data?.items
        } catch (err) {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          return err
        }
      }
    },
    loadGroups() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(`groups/${GET_GROUPS}`)
          .then(res => {
            this.groups = res.data.items
            resolve()
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err.response.data.message}`))
            reject(err)
          })
      })
    },
    selectGroup(event) {
      if (event) {
        this.loadCategories(event.id)
        this.category = ''
        this.subcategory = ''
      } else {
        this.loadCategories(null)
      }

      this.reloadData()
    },
    // Categories
    loadCategories(groupId) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(`categories/${GET_CATEGORY}`, groupId)
          .then(res => {
            this.categories = res.data.items.filter(cat => cat.children.length)
            resolve()
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err.response.data.message}`))
            reject(err)
          })
      })
    },
    selectCategory(event) {
      if (event) this.subcategories = event.children
      else {
        this.subcategory = ''
        this.subcategories = []
      }
      this.reloadData()
    },
    getId(field) {
      // eslint-disable-next-line no-nested-ternary
      return typeof field === 'object' ? (field?.id || '') : field
    },
  },
}
</script>

<style scoped>

</style>
