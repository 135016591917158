var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('import-companies-modal',{attrs:{"show":_vm.showImport},on:{"close-modal":function($event){_vm.showImport = false; _vm.reloadContent()},"reload-content":_vm.reloadContent}}),_c('sw-filters',{attrs:{"shorts":[
      {
        title: _vm.$tc('dashboard.tiles.YourAssignedContacts', 2),
        type: 'avatar',
        value: function () { return _vm.filters.assignedContacts; },
        clear: function () { _vm.filters.assignedContacts = []; _vm.reloadContent() }
      },
      {
        title: _vm.$t('AssignedUsers'),
        type: 'avatar',
        value: function () { return _vm.filters.assignedUsers; },
        clear: function () { _vm.filters.assignedUsers = []; _vm.reloadContent() }
      } ],"mt":""}},[_c('my-filters',{on:{"filters":function (filterList) { return _vm.filters = filterList; }}})],1),_c('div',{staticClass:"my-50"},[_c('b-button',{staticClass:"mr-50",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.$router.push({ name: 'organizationsNew' })}}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}}),_vm._v(" "+_vm._s(_vm.$t('Add'))+" ")],1),(_vm.checkRequiredPermissions([_vm.$roles.ORGANIZATION_DELETE]))?_c('b-button',{staticClass:"mr-50",attrs:{"variant":"primary","size":"sm","disabled":!_vm.selectedCompanies.length},on:{"click":function($event){return _vm.deleteOrganizations(null)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}}),_vm._v(" "+_vm._s(_vm.$t('Delete'))+" "),(_vm.selectedCompanies.length)?_c('span',[_vm._v("("+_vm._s(_vm.selectedCompanies.length)+")")]):_vm._e()],1):_vm._e(),(_vm.checkRequiredPermissions([_vm.$roles.CONTACT_ADD_IMPORT]))?_c('b-button',{staticClass:"mr-50",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){_vm.showImport = true}}},[_c('feather-icon',{attrs:{"icon":"UploadCloudIcon"}}),_vm._v(" "+_vm._s(_vm.$t('Import'))+" ")],1):_vm._e()],1),_c('b-card',[_c('my-table',{ref:"table",attrs:{"filters":_vm.filters},on:{"select-items":function($event){_vm.selectedCompanies = $event},"delete-item":_vm.deleteOrganizations}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }