<template>
  <ExpandWindow :title="$t('FullWindow')">
    <Calls :contact-thread="thread" />
  </ExpandWindow>
</template>

<script>
import ExpandWindow from '@core/components/@sw/ExpandWindow.vue'
import Calls from '@/pages/calls/List.vue'

export default {
  name: 'EmailExpand',
  components: { ExpandWindow, Calls },
  props: {
    thread: {
      default: null,
    },
  },
}
</script>

<style scoped>

</style>
