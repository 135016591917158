import store from '@/store'

const timeLimiter = (createdAt, limit = 15) => {
  const serverDate = new Date(store.getters['system/getSettings'].currentDatetime.date)
  const currentDate = new Date()
  const task = new Date(createdAt)
  const taskEnd = new Date(task.getTime() + limit * 60000)
  return ((currentDate - serverDate) > (currentDate - taskEnd))
}

export default timeLimiter
