var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('sw-table',{attrs:{"table-id":"1cee94f00f8a4e2a8605e675e15198a9","pagination":_vm.pagination},on:{"change-pagination":function($event){return Object.assign(_vm.pagination, $event)},"reload-content":_vm.loadLogs},scopedSlots:_vm._u([{key:"table",fn:function(){return [_c('b-table',{staticClass:"mb-0",attrs:{"striped":"","responsive":"","items":_vm.items,"fields":_vm.fields,"per-page":_vm.pagination.perPage,"busy":_vm.isLoading},on:{"reload-content":_vm.loadLogs},scopedSlots:_vm._u([{key:"head()",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(("" + label)))+" ")]}},{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [(item.automationProcedure)?_c('span',{staticClass:"py-50"},[(_vm.checkRequiredPermissions([_vm.$roles.AUTOMATION_MODIFY]))?_c('router-link',{attrs:{"to":{ name: 'automations', params: { automationId: item.automationProcedure.id } }}},[_vm._v(" "+_vm._s(item.automationProcedure.name)+" ")]):_c('span',[_vm._v(_vm._s(item.automationProcedure.name))])],1):_c('feather-icon',{attrs:{"icon":"MinusIcon"}})]}},{key:"cell(trigger)",fn:function(ref){
var item = ref.item;
return [(item.automationProcedure)?_c('b-badge',{attrs:{"variant":"light-primary"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.automationTriggerTypeByValue(item.automationProcedure.automationProcedureTrigger.type)))+" ")]):_vm._e()]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [(item.automationProcedure)?_c('b-badge',{attrs:{"variant":"primary"}},[_vm._v(" "+_vm._s(item.automationProcedure.automationProcedureConditions ? item.automationProcedure.automationProcedureConditions.length : 0)+" ")]):_vm._e()]}},{key:"cell(conditions)",fn:function(ref){
var item = ref.item;
return [(item.automationProcedure)?_c('b-badge',{attrs:{"variant":"primary"}},[_vm._v(" "+_vm._s(item.automationProcedure.automationProcedureActions ? item.automationProcedure.automationProcedureActions.length : 0)+" ")]):_vm._e()]}},{key:"cell()",fn:function(row){return [_c('table-default-cell',{attrs:{"field":row.field,"value":row.value}})]}},{key:"empty",fn:function(){return [_c('empty-content')]},proxy:true},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[_c('b-spinner',{staticClass:"align-middle",attrs:{"variant":"primary"}})],1)]},proxy:true}])})]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }