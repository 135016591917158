<template>
  <div>
    <b-alert
      show
      variant="primary"
    >
      <div
        class="alert-body"
        style="text-align: start"
      >
        <feather-icon
          class="mr-25"
          icon="MailIcon"
        />
        <span class="ml-25">
          {{ $t('gEmail.settings.hereYouWillDefineYourCompany') }}
        </span>
      </div>
    </b-alert>

    <div class="mb-1">
      <b-row
        v-if="emailAccountList.length"
      >
        <b-col
          v-for="(account, i) in emailAccountList"
          :key="i"
          sm="12"
        >
          <b-card>
            <email-account-item
              :account="account"
              @update="item => {
                // $set(emailAccountList, i, item)
                loadEmailAccounts()
              // emailAccountList[i] = item
              }"
              @update-pass="loadEmailAccounts"
            />
          </b-card>
        </b-col>
      </b-row>

      <div
        v-else
        class="text-center py-1 font-weight-bold text-primary"
      >
        <feather-icon
          size="17"
          icon="XCircleIcon"
        />

        {{ $t('NoData') }}
      </div>
    </div>

    <b-button
      variant="primary"
      @click="openModal"
    >
      {{ $t('gEmail.settings.addANewEmail') }}
    </b-button>

    <b-modal
      v-model="isSetUserEmailModal"
      :title="$t('Configuration')"
      hide-footer
      tabindex="none"
      size="lg"
    >
      <set-user-email-modal
        :user="user"
        @update="item => {
          isSetUserEmailModal = false
          loadEmailAccounts()
        }"
      />

      <b-overlay
        :show="isLoading"
        no-wrap
        spinner-variant="primary"
      />
    </b-modal>

    <b-overlay
      :show="isLoading"
      no-wrap
      spinner-variant="primary"
    />
  </div>
</template>

<script>
import { GET_USER_MAILBOXES } from '@/@constants/mutations'
import SetUserEmailModal from './components/SetUserEmailModal.vue'
import EmailAccountItem from './components/EmailAccountItem.vue'

export default {
  components: {
    SetUserEmailModal,
    EmailAccountItem,
  },

  props: {
    user: {
      type: Object,
      default: null,
    },
  },

  data: () => ({
    isLoading: false,

    emailAccountList: [],

    isSetUserEmailModal: false,
  }),

  mounted() { this.ini() },

  methods: {
    ini() { this.loadEmailAccounts() },

    async loadEmailAccounts() {
      this.isLoading = true

      const { items } = await this.$store.dispatch(`mailbox/${GET_USER_MAILBOXES}`)

      this.emailAccountList = items

      this.isLoading = false
    },

    openModal() { this.isSetUserEmailModal = true },
  },
}
</script>
