<template>
  <div class="checkout">
    <!--    START::Add Button    -->
    <b-button
      variant="warning"
      size="sm"
      @click="goReturn"
    >
      <feather-icon icon="ArrowLeftIcon" />

      {{ $t('Return') }}
    </b-button>
    <!--    END::Add Button    -->

    <!--    START::Select Cart Mode    -->
    <b-row>
      <b-col md="4">
        <sw-select
          :name="$t('SelectCartMode')"
          class="mt-1"
        >
          <v-select
            v-model="cartMode"
            :options="[
              'order',
            ]"
            class="bg-white"
          >
            <template #no-options>
              {{ $t('NoOptions') }}
            </template>

            <template #option="{ label }">
              {{ $t(label) }}
            </template>

            <template #selected-option="{ label }">
              {{ $t(label) }}
            </template>
          </v-select>
        </sw-select>
      </b-col>
    </b-row>
    <!--    END::Select Cart Mode    -->

    <!--    START::Form Wizard    -->
    <form-wizard
      v-if="cartMode === 'order'"
      ref="refFormWizard"
      color="#7367F0"
      :title="null"
      :subtitle="null"
      :finish-button-text="$t('Save')"
      :back-button-text="$t('Previous')"
      hide-buttons
      class="checkout-form-wizard steps-transparent"
    >
      <!--    The Views: Checkout    -->
      <tab-content
        :title="$t('Offer.YourOrder')"
        icon="feather icon-shopping-bag"
      >
        <your-order
          :is-overwrite-vat-in-cart="isOverwriteVatInCart"
          @nextStep="formWizardNextStep()"
          @set-total-price="setTotalPrice"
        />
      </tab-content>

      <!--    The Views: Generating an Offer    -->
      <tab-content
        :title="$t('contact.agreement')"
        icon="feather icon-file"
      >
        <agreement
          :total-gross="120"
          @nextStep="formWizardNextStep()"
        />
      </tab-content>

      <!--    The Views: Generating an Offer    -->
      <tab-content
        :title="$t('Payment')"
        icon="feather icon-dollar-sign"
      >
        <payment
          @nextStep="formWizardNextStep()"
        />
      </tab-content>
    </form-wizard>
    <!--    END::Form Wizard    -->

    <div
      v-else
      class="text-center py-1 text-primary font-weight-bold"
    >
      <feather-icon
        size="17"
        icon="XCircleIcon"
      />

      {{ $t('SelectCartMode') }}
    </div>

    <!--    START::Loading    -->
    <b-overlay
      v-if="isLoading"
      no-wrap
      variant="white"
      :show="isLoading"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
    />
    <!--    END::Loading    -->
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import checkRanks from '@/helpers/checkRanks'
import { GET_SETTINGS } from '@/@constants/mutations'
import Payment from '@/pages/orders/components/new/Payment.vue'
import vSelect from 'vue-select'
import yourOrder from './components/new/YourOrder.vue'
import agreement from './components/new/Agreement.vue'

export default {
  components: {
    Payment,
    FormWizard,
    TabContent,
    yourOrder,
    agreement,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data: () => ({
    totalPrice: 0,
    allowAgreementInCart: true,
    isOverwriteVatInCart: false,
    isLoading: false,
    allowOffer: true,
    allowAgreement: true,
    cartMode: '',
  }),
  mounted() {
    this.isLoading = true

    this.$store.dispatch(`system/${GET_SETTINGS}`)
      .then(res => {
        this.allowAgreementInCart = res.allowAgreementInCart
      })
      .catch(err => {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      })
      .finally(() => {
        this.isLoading = false
      })

    this.loadGlobalConfig()
  },
  methods: {
    goReturn() {
      this.showAlert('warning', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('alerts.AnyChangesWillBeLost'))
        .then(() => this.$router.push({ name: 'orders' }))
    },
    formWizardNextStep() {
      this.$refs.refFormWizard.nextTab()
    },
    setTotalPrice(value) {
      this.$set(this, 'totalPrice', value)
    },
    checkPermissions(...requiredPermissions) {
      const currentUser = this.$store.getters['auth/getCurrentUser']
      const user = typeof currentUser === 'string' ? JSON.parse(currentUser) : currentUser
      const userPermissions = user.rank.permissions

      return checkRanks(userPermissions, ...requiredPermissions)
    },
    loadGlobalConfig() {
      this.isLoading = true

      this.$store.dispatch(`system/${GET_SETTINGS}`)
        .then(item => {
          this.isOverwriteVatInCart = item.overwriteVatInCart
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce.scss';
@import '../../../node_modules/vue-form-wizard/dist/vue-form-wizard.min.css';

.checkout {
  .wizard-tab-content {
    box-shadow: none !important;
    background: transparent !important;
    padding: 0;
  }

  .fixButton { height: max-content }

  .shopCart {
    .card-body {
      .productIcon {
        width : 6rem;
        height: 6rem;
      }
    }

    /*    Cart Item    */
    .shopCartItem {
      max-height: unset;

      padding: 0;

      -webkit-transition: all 250ms;
      -moz-transition: all 250ms;
      transition: all 250ms;

      //&:hover {
      //  box-shadow: 0 14px 28px rgba(0, 0, 0, .06), 0 10px 10px rgba(0, 0, 0, .06);
      //
      //  -webkit-transform: translateY(-5px);
      //  -moz-transform: translateY(-5px);
      //  transform: translateY(-5px);
      //}

      div:first-child {
        padding: 0;
      }

      //.productContainer {
      //  min-width: 10rem;
      //}
    }
  }

  /*    Custom Checkbox    */
  .orderOptions {
    border-radius: 6px;

    -webkit-transition: all 250ms;
    -moz-transition: all 250ms;
    transition: all 250ms;

    &:hover {
      box-shadow: 0 14px 28px rgba(0, 0, 0, .04), 0 10px 10px rgba(0, 0, 0, .04);

      background: var(--bg-shopItem-lighten-3);

      -webkit-transform: translateY(-2px);
      -moz-transform: translateY(-2px);
      transform: translateY(-2px);
    }

    label {
      $padding: .5rem 1rem;
      margin-left: .5rem;

      padding: $padding;

      display: flex;

      &:before,
      &:after {
        margin: $padding;
        margin-top: .6rem;
      }
    }
  }
}
</style>
