<template>
  <!-- Error page-->
  <div
    class="misc-wrapper"
  >
    <b-card class="misc-inner p-2 py-1 p-sm-3">
      <div class="w-100 text-center">
        <b-overlay :show="loading"
                   no-wrap
                   opacity="1"
                   spinner-variant="primary"
        />

        <div class="wrapper d-flex justify-content-center"
             style="width: 100%; height: 100px; text-align: center"
        >
          <svg
            v-if="!loading"
            id="Layer_1"
            class="mx-auto"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 98.5 98.5"
            enable-background="new 0 0 98.5 98.5"
            xml:space="preserve"
          >
            <path
              class="checkmark"
              fill="none"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
              d="M81.7,17.8C73.5,9.3,62,4,49.2,4 C24.3,4,4,24.3,4,49.2s20.3,45.2,45.2,45.2s45.2-20.3,45.2-45.2c0-8.6-2.4-16.6-6.5-23.4l0,0L45.6,68.2L24.7,47.3"
            />
          </svg>
        </div>

        <h2 class="mb-1 mt-1">
          {{ $t('YourAccountNotActive') }}
        </h2>
        <div>
          <p class="mb-2">
            {{ $t('YouCanNowLoginToSystem') }}
          </p>
        </div>
      </div>
    </b-card>
  </div>
  <!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import axios from 'axios'

export default {
  components: {},
  data() {
    return {
      loading: false,
    }
  },
  computed: {
  },
  // eslint-disable-next-line consistent-return
  async mounted() {
    const { token } = this.$route.params

    if (!token) return false

    try {
      const resp = await axios.post(`${this.axiosBaseUrl}/api/v1/client/public/1/confirmEmail`, { token })

      let locale = 'en'
      switch (resp.data.data.locale) {
        case 'pl_pl': locale = 'pl'; break
        case 'en_uk': locale = 'en'; break
        case 'de_de': locale = 'de'; break
        case 'ar_dz': locale = 'ar'; break
        case 'ko_kr': locale = 'ko'; break
        case 'zh_cn': locale = 'zh'; break

        default: locale = 'en'
      }

      this.$i18n.locale = locale

      setTimeout(() => {
        this.loading = false
      }, 250)
    } catch (err) {
      console.error(err)
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';

.wrapper {
  width: 100px;
}

.checkmark {
  stroke: var(--success);
  stroke-dashoffset: 745.74853515625;
  stroke-dasharray: 745.74853515625;
  animation: dash 2s ease-out forwards;
}

@keyframes dash {
  0% {
    stroke-dashoffset: 745.74853515625;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
</style>
