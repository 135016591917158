<!--eslint-disable-->

<template>
  <div>
    <sw-filters-modern
      v-model="filters"
      @change="resolveFilters"
      @refresh="reloadTasks"
      class="mb-50"
    />

    <component :is="tag">
        <sw-table
                table-id="d746341b96a74f7abdd17304dd448c9e"
                :pagination="pagination"
                show-columns
                :fields.async="fields"
                @set-fields="fields = $event"
                @change-pagination="Object.assign(pagination, $event)"
                @reload-content="reloadTasks"
        >
            <template #table>
                <b-table
                        striped
                        responsive
                        hover
                        :items="tasks || []"
                        :fields="fields.filter(e => e.visible)"
                        :per-page="pagination.perPage"
                        :current-page="pagination.currentPage"
                        class="mb-0"
                        :busy="loading"
                        show-empty
                        :sort-by.sync="sorter.sortBy"
                        :sort-desc.sync="sorter.sortDesc"
                        :no-local-sorting="true"
                        @row-clicked="openViewModal"
                        @sort-changed="Object.assign(sorter, $event); reloadTasks()"
                >
                    <template #head()="{ label }">
                        {{ $t(`${label}`) }}
                    </template>
                    <template #cell(id)="{ index }">
                        #{{ getOrderNo(index, pagination, sorter) }}
                    </template>
                    <!--   Task Type   -->
                    <template
                            #cell(contactThreadTaskType)="data"
                    >
                        <b-badge
                                :id="`type_${data.index}`"
                                variant="light-primary"
                                style="padding: 6px 10px"
                        >
                            <!--     Task type content     -->
                            {{ data.value.name }}
                            <feather-icon
                                    v-if="data.item.address"
                                    icon="NavigationIcon"
                            />
                            <!--     Task type content     -->
                        </b-badge>
                        <!--     Task type badge tooltip     -->
                        <b-tooltip
                                v-if="data.item.address"
                                :target="`type_${data.index}`"
                                placement="top"
                                variant="dark"
                        >
                            {{ data.item.address }}
                        </b-tooltip>
                        <!--     Task type badge tooltip     -->
                    </template>
                    <!--   Task Status   -->
                    <template #cell(status)="data">
                        <b-badge
                                :id="`status_${data.index}`"
                                :variant="taskTypeVariant(data.value)"
                                style="padding: 6px 10px"
                        >
                            <!--     Task type content     -->
                            {{ $t(`task.status.${data.value}`) }}
                            <!--     Task type content     -->
                        </b-badge>
                    </template>
                    <!--   Task Desc   -->
                    <template
                            #cell(content)="data"
                    >
                        <span :inner-html.prop="data.value | truncate(40, '...')" />
                    </template>
                    <template #cell(priority)="{ value }">
                  <span v-if="value">
                      <sw-icon icon="FlagIcon" :class="[`text-${taskPriority.taskPriorityByValue(value).color}`]" class="mr-25" />
                      {{ $t(taskPriority.taskPriorityByValue(value).label) }}
                  </span>
                        <feather-icon
                            v-else
                            icon="MinusIcon"
                        />
                    </template>
                    <template #cell(files)="data">
                        <documents-list
                                v-if="data.value.length"
                                :documents="data.value"
                                :index="data.index"
                        />
                        <feather-icon
                                v-else
                                icon="MinusIcon"
                        />
                    </template>
                    <!--   Task Date   -->
                    <!--   Task Create Date   -->

                    <!--   Task sendReminderEmail   -->
                    <template
                            #cell(sendReminderEmail)="{ value }"
                    >
                        <feather-icon
                                size="20"
                                :icon="value ? 'CheckSquareIcon' : 'XSquareIcon'"
                                :class="value ? 'text-success' : 'text-danger'"
                        />
                    </template>
                    <!--   Task sendReminderSms   -->
                    <template
                            #cell(sendReminderSms)="{ value }"
                    >
                        <feather-icon
                                size="20"
                                :icon="value ? 'CheckSquareIcon' : 'XSquareIcon'"
                                :class="value ? 'text-success' : 'text-danger'"
                        />
                    </template>

                    <!--   Task For   -->
                    <template
                            #cell(assignedUser)="{ value }"
                    >
                        <avatar
                                :user="value"
                                :to="{ name: 'user', params: { userId: value.id } }"
                        />
                    </template>
                    <!--   Task Principal   -->
                    <template
                            #cell(createdBy)="{ value }"
                    >
                        <avatar
                                :user="value"
                                :to="{ name: 'user', params: { userId: value.id } }"
                        />
                    </template>
                    <template #table-busy>
                        <div class="text-center text-danger my-2">
                            <b-spinner
                                    class="align-middle"
                                    variant="primary"
                            />
                        </div>
                    </template>
                    <template
                            #cell(action)="data"
                    >
                        <div
                                :id="data.id"
                                class="text-center"
                        >
                            <b-dropdown
                                    id="dropdown-grouped"
                                    variant="flat-primary"
                                    class="dropdown-icon-wrapper"
                            >
                                <template #button-content>
                                    <feather-icon
                                            icon="MoreVerticalIcon"
                                            size="20"
                                            class="align-middle"
                                    />
                                </template>
                                <div v-if="data.item && data.item.status && data.item.status === 'O'">
                                    <b-dropdown-item @click="changeTaskStatus(data.item.id, 'D', data.index, data.item)">
                                        <dropdown-item-label icon="CheckIcon"
                                                             :label="$t('task.status.D')"
                                        />
                                    </b-dropdown-item>
                                    <b-dropdown-item @click="changeTaskStatus(data.item.id, 'ND', data.index, data.item)">
                                        <dropdown-item-label icon="XIcon"
                                                             :label="$t('task.status.ND')"
                                        />
                                    </b-dropdown-item>
                                    <b-dropdown-divider />
                                    <b-dropdown-item
                                            v-if="checkRequiredPermissions([
                      $roles.CONTACT_THREAD_TASK_EDIT,
                      { role: $roles.CONTACT_THREAD_TASK_EDIT_SELF, data: data.item, field: 'createdBy', userId: currentUser.id }
                    ]) && isTimeLimitReached(data.item.createdAt.date, null, 'contactThreadTaskDeleteTime')"
                                            @click="editTask(data.item)"
                                    >
                                        <dropdown-item-label icon="EditIcon"
                                                             :label="$t('Edit')"
                                        />
                                    </b-dropdown-item>
                                    <b-dropdown-item
                                            v-if="checkRequiredPermissions([
                      roles.CONTACT_THREAD_TASK_DELETE,
                    ])"
                                            @click="deleteTask(data.item.id, data.index)"
                                    >
                                        <dropdown-item-label icon="TrashIcon"
                                                             :label="$t('Delete')"
                                        />
                                    </b-dropdown-item>
                                </div>
                                <div v-else>
                                    <b-dropdown-item @click="editTask(data.item, true)">
                                        <dropdown-item-label icon="RepeatIcon"
                                                             :label="$t('RepeatTask')"
                                        />
                                    </b-dropdown-item>
                                    <b-dropdown-item
                                            v-if="data.item.contactThreadTaskType.isReportRequired"
                                            @click="openModal('viewModal', $t('PreviewReport'), '', data.item.contactThreadTaskReport)"
                                    >
                                        <dropdown-item-label icon="ArchiveIcon"
                                                             :label="$t('ShowReport')"
                                        />
                                    </b-dropdown-item>
                                </div>
                            </b-dropdown>
                        </div>
                    </template>
                    <!--    Other    -->
                    <template #cell()="row">
                        <table-default-cell
                                :field="row.field"
                                :value="row.value"
                        />
                    </template>

                    <!--      Empty      -->
                    <template #empty>
                        <empty-content />
                    </template>

                    <!--      Table Busy      -->
                    <template #table-busy>
                        <div class="text-center text-danger my-2">
                            <b-spinner
                                    class="align-middle"
                                    variant="primary"
                            />
                        </div>
                    </template>
                </b-table>
            </template>
        </sw-table>
    </component>
  </div>
</template>

<script>
import {
  BBadge, BButtonGroup, BTable, BTooltip, VBTooltip,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import {
  DELETE_CONTACT_TASK,
  GET_CONTACT_TASKS,
  GET_TASKS,
  OPEN_MODAL,
  UPDATE_CONTACT_TASK,
} from '@/@constants/mutations'
import DocumentsList from '@/views/components/DocumentsList.vue'
import * as roles from '@/helpers/permissions'
import { formatDate } from '@core/utils/filter'
import useSwUtils from '@/composable/useSwUtils'
import * as taskPriority from '@/@data/taskPriority'
import i18n from '@/libs/i18n'
import EventBus from '@/event-bus'
import { eChangeThreadTaskStatus } from '@/@types/events'

export default {
  name: 'Tasks',
  components: {
    DocumentsList,
    BTable,
    BBadge,
    BTooltip,
    BButtonGroup,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    thread: {
      default: null,
    },
    tag: {
      default: 'div',
    },
  },
  data: () => ({
    taskPriority,
    roles,
    taskStatus: 'O',
    // tasks: [],
    taskType: [],
    taskTypes: [],
    total: 0,
    sorter: {
      sortBy: 'startAt',
      sortDesc: true,
    },
    pagination: {
      perPage: 25,
      currentPage: 1,
      totalRecords: 1,
    },
    fields: [
      {
        key: 'id', label: '#', visible: true, sortable: true,
      },
      {
        key: 'contactThreadTaskType',
        label: 'task.Type',
        visible: true,
      },
      {
        key: 'status',
        label: 'task.Status',
        visible: true,
      },
      {
        key: 'priority',
        label: 'PriorityLabel',
        visible: true,
      },
      {
        key: 'content',
        label: 'task.Description',
        visible: true,
      },
      {
        key: 'files',
        label: 'Files',
        visible: true,
      },
      {
        key: 'startAt',
        label: 'task.DateStart',
        sortable: true,
        visible: true,
      },
      {
        key: 'endAt',
        label: 'task.DateEnd',
        sortable: true,
        visible: true,
      },
      {
        key: 'createdAt',
        label: 'task.Created_Time',
        visible: true,
      },
      {
        key: 'sendReminderEmail',
        label: 'ReminderEmail',
        visible: true,
      },
      {
        key: 'sendReminderSms',
        label: 'ReminderSms',
        visible: true,
      },
      {
        key: 'assignedUser',
        label: 'AssignedUser',
        visible: true,
      },
      {
        key: 'createdBy',
        label: 'task.Principal',
        visible: true,
      },
      {
        key: 'action',
        label: 'Action',
        visible: true,
      },
    ],

    filters: [
      {
        type: 'refresh',
      },
      {
        type: 'radio',
        name: 'taskStatus',
        key: 'taskStatus',
        active: {
          value: 'O',
          key: 'O',
          label: i18n.t('task.status.O'),
        },
        options: [
          {
            value: 'O',
            key: 'O',
            label: i18n.t('task.status.O'),
          },
          {
            value: 'D',
            key: 'D',
            label: i18n.t('task.status.D'),
          },
          {
            value: 'ND',
            key: 'ND',
            label: i18n.t('task.status.ND'),
          },
        ],
      },
      {
        type: 'checkbox',
        name: 'taskTypes',
        key: 'taskTypes',
        pending: true,
        active: [],
        options: [],
      },
    ],
  }),
  computed: {
    ...mapGetters({
      selectedThread: 'contact/thread',
      loading: 'singleContact/getContactTasksLoading',
      tasks: 'singleContact/getContactTasks',
      reloadContent: 'modal/getReloadContent',
      currentUser: 'auth/getCurrentUser',
    }),
  },
  watch: {
    reloadContent(newValue) {
      if (newValue) {
        this.reloadTasks()
      }
    },
    '$route.params.threadId': {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) this.reloadTasks()
      },
    },
  },
  setup() {
    const { isTimeLimitReached } = useSwUtils()

    return {
      isTimeLimitReached,
    }
  },
  async mounted() {
    const fTaskTypes = this.filters.find(f => f.key === 'taskTypes')

    if (fTaskTypes) {
      fTaskTypes.pending = true

      this.$store.dispatch(`typeSettings/${GET_TASKS}`)
        .then(res => {
          const options = res.map(opt => ({
            label: opt.name,
            key: opt.id,
            ...opt,
          }))

          fTaskTypes.options = options
          fTaskTypes.active = options

          this.resolveFilters({
            filter: fTaskTypes,
            option: options,
          })
        })
        .finally(() => { fTaskTypes.pending = false })
    }
  },
  methods: {
    formatDate,
    openViewModal(data) {
      this.$store.commit(`modal/${OPEN_MODAL}`, {
        modalType: 'viewModal',
        modalTitle: this.$i18n.t('PreviewTask'),
        data,
      })
    },
    reloadTasks() {
      this.$store.dispatch(`singleContact/${GET_CONTACT_TASKS}`, { threadId: this.thread?.id || this.selectedThread?.id, sorter: this.sorter })
        .then(res => {
          this.pagination.totalRecords = res
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
    openModal(modalType = 'note', modalTitle = 'Nowa Notatka', okButtonTitle = 'Dodaj notatkę', data) {
      this.$root.$emit('bv::hide::popover')
      this.$store.commit(`modal/${OPEN_MODAL}`,
        {
          modalType,
          modalTitle,
          okButtonTitle,
          threadFor: this.threadId,
          data,
        })
    },
    changeTaskStatus(taskId, statusValue, taskIndex, item) {
      const data = {
        id: taskId.toString(),
        status: statusValue,
      }
      this.showAlert('warning', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('alerts.WontbeAble'))
        .then(() => {
          if (item.contactThreadTaskType.isReportRequired) {
            this.$store.commit(`modal/${OPEN_MODAL}`,
              {
                modalType: 'report',
                modalTitle: this.$i18n.t('NewReport'),
                okButtonTitle: this.$i18n.t('AddReport'),
                threadFor: this.thread?.id || this.selectedThread?.id,
                data: { ...item, statusValue, taskIndex },
              })
          } else {
            this.$store.dispatch(`singleContact/${UPDATE_CONTACT_TASK}`, { updateTaskData: data, threadId: this.thread?.id || this.selectedThread?.id })
              .then(() => {
                this.tasks.splice(taskIndex, 1)
                this.showToast('success', this.$i18n.t('StatusUpdated'))
                EventBus.$emit(eChangeThreadTaskStatus, { threadId: this.modalConfig?.threadId })
              })
              .catch(err => {
                this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
              })
          }
        })
    },
    deleteTask(taskId, taskIndex) {
      this.showAlert('error', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('alerts.WontbeAble'))
        .then(() => {
          this.$store.dispatch(`singleContact/${DELETE_CONTACT_TASK}`, { taskId, threadId: this.thread?.id || this.selectedThread?.id })
            .then(() => {
              this.tasks.splice(taskIndex, 1)
            })
            .catch(() => {
              this.showToast('danger', this.$i18n.t('ProblemOccured'))
            })
        })
    },
    setTasksFilters(field, value) {
      this.$store.commit('singleContact/SET_TASKS_FILTERS', { field, value })
      this.$store.dispatch(`singleContact/${GET_CONTACT_TASKS}`, { threadId: this.thread?.id || this.selectedThread?.id })
    },
    changeTaskViewStatus(value) {
      this.taskStatus = value
      this.setTasksFilters('status', value)
    },
    taskTypeVariant(value) {
      let variant = 'light-primary'
      if (value === 'D') variant = 'light-success'
      else if (value === 'O') variant = 'light-info'
      else if (value === 'ND') variant = 'light-danger'
      return variant
    },
    editTask(task, repeat = false) {
      const taskData = task
      if (repeat) delete taskData.id

      this.$store.commit(`modal/${OPEN_MODAL}`,
        {
          modalType: 'task',
          size: 'lg',
          modalTitle: this.$i18n.t('EditTask'),
          okButtonTitle: this.$i18n.t('Save'),
          threadFor: this.thread?.id || this.selectedThread?.id,
          editedData: taskData,
        })
    },
    resolveFilters({ filter, option }) {
      if (filter.key === 'taskStatus') this.changeTaskViewStatus(option.value)
      if (filter.key === 'taskTypes') {
        const types = []

        // eslint-disable-next-line no-restricted-syntax
        for (const { id } of option) { types.push(id) }

        this.setTasksFilters('types', types)
      }
    },
  },
}
</script>

<style scoped>

</style>
