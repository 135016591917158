<template>
  <div>
    <div class="cart-spec">
      <div class="d-flex mb-25">
        <!-- Contact -->
        <div class="flex-grow-1 text-truncate w-50">
          <div class="font-weight-bolder">
            {{ $t('Contact') }}
          </div>
          <router-link  :class="{ 'deleted-text': thread.contact.deletedAt }" :to="`/contact/${thread.contact.id}`">
            {{ thread.contact.firstName }} {{ thread.contact.lastName }}
          </router-link>
        </div>
        <!-- Thread -->
        <div class="flex-grow-1 text-truncate w-50">
          <div class="font-weight-bolder">
            {{ $t('Thread') }}
          </div>
          <router-link  :class="{ 'deleted-text': thread.contact.deletedAt }" :to="`/contact/${thread.contact.id}/thread/${thread.id}/offers`">
            {{ thread.name | truncate(20) }}
          </router-link>
        </div>
      </div>
      <hr>
      <b-list-group flush>
        <b-list-group-item
          v-for="(message, index) in offer.contactThreadCartOfferMessages"
          :key="`message_${index}`"
        >
          <b-card-text class="mb-50">
            {{ message.content }}
          </b-card-text>
          <small class="text-secondary">{{ formatDate(message.createdAt.date, { month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' }, $i18n.locale) }}</small>
        </b-list-group-item>
      </b-list-group>
    </div>
  </div>
</template>

<script>
import { formatDate, title } from '@core/utils/filter'
import { BCardText } from 'bootstrap-vue'

export default {
  name: 'OfferMessages',
  components: {
    BCardText,
  },
  props: {
    offer: {
      type: Object,
      default: null,
    },
  },
  computed: {
    thread() {
      return this.offer.contactThreadCartBox.contactThread
    },
  },
  methods: {
    formatDate,
    title,
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/swiper.scss';

.shadow-none {
  //& .card-header, & .card-body {
  //  padding-left: 0 !important;
  //  padding-right: 0 !important;
  //}
}
</style>
<style scoped lang="scss">
.cart-item {
  margin-bottom: 6px;
}
</style>
