<template>
  <b-card>
    <template #header>
      <div class="w-100 d-flex align-items-center justify-content-center">
        <b-img :src="$data.$themeConfig.app.appLogoImage" />
      </div>
    </template>

    <hr class="mt-0">

    <div
      class="d-flex flex-column"
      style="row-gap: 1.5rem"
    >
      <div>
        <h3>
          Konsultacja zakupu i wdrożenia SalesWizard CRM
        </h3>

        <p class="mb-0">
          Krótka 30-minutowa konsultacja dotycząca zakupu i wdrożenia rozwiązania proponowanego na webinarze.
        </p>
      </div>

      <div
        class="d-flex flex-column"
        style="row-gap: 1.24rem"
      >
        <div
          class="d-flex flex-row"
          style="column-gap: 1rem"
        >
          <div>
            <feather-icon
              icon="ClockIcon"
              size="20"
            />
          </div>

          <strong>
            30 min
          </strong>
        </div>

        <div
          class="d-flex flex-row"
          style="column-gap: 1rem"
        >
          <div>
            <feather-icon
              icon="VideoIcon"
              size="20"
            />
          </div>

          <strong>
            Szczegóły konferencji internetowej zostaną udostępnione po potwierdzeniu.
          </strong>
        </div>
      </div>
    </div>

    <template #footer>
      <div
        class="d-flex flex-row align-items-center"
        style="column-gap: .5rem"
      >
        <b-avatar
          variant="light-primary"
          :src="currentUser.avatar"
        />

        <p class="d-flex flex-column mb-0">
          <span class="h5 mb-0 text-primary">
            {{ `${currentUser.firstName} ${currentUser.lastName}` }}
          </span>

          <span>
            {{ currentUser.rank.name }}
          </span>
        </p>
      </div>
    </template>
  </b-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { $themeConfig } from '@themeConfig'

export default {
  data: () => ({
    $themeConfig,
  }),
  computed: {
    ...mapGetters({
      currentUser: 'auth/getCurrentUser',
    }),
  },
}
</script>
