import { $themeBreakpoints } from '@themeConfig'
import { GET_SETTINGS } from '@/@constants/mutations'

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    title: 'Dashboard',
    showHelpTooltips: true,
    // showHelpTooltips: localStorage.getItem('showHelpTooltips') === 'true',
  },
  getters: {
    getTitle: state => state.title,
    getShowHelpTooltips: state => state.showHelpTooltips,
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    TOGGLE_HELP_TOOLTIPS(state) {
      localStorage.setItem('showHelpTooltips', String(!state.showHelpTooltips))
      state.showHelpTooltips = !state.showHelpTooltips
    },
    UPDATE_TITLE(state, val) {
      state.title = val
    },
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    UPDATE_TITLE: ({ commit, dispatch, rootGetters }, payload) => {
      const { title, label } = payload
      commit('UPDATE_TITLE', title)
      if (!rootGetters['system/getSettings'].name) {
        dispatch(`system/${GET_SETTINGS}`, null, { root: true })
          .then(settings => {
            document.title = `${label} | ${settings.name}`
          })
      } else {
        document.title = `${label} | ${rootGetters['system/getSettings'].name}`
      }
    },
  },
}
