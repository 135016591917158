var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-alert',{attrs:{"show":"","variant":"primary"}},[_c('div',{staticClass:"alert-body text-center"},[_vm._v(" "+_vm._s(_vm.$t('GenerateNewMakeKey'))+" "),_c('div',{staticClass:"mt-25"},[_c('b-button',{attrs:{"variant":"primary","disabled":_vm.loading},on:{"click":_vm.openEditModal}},[_c('feather-icon',{attrs:{"icon":"KeyIcon"}}),_vm._v(" "+_vm._s(_vm.$t('Generate'))+" ")],1)],1)])]),_c('b-modal',{attrs:{"hide-footer":"","title":_vm.editItemModal.item.id ? _vm.$t('Edit') : _vm.$t('Generate')},model:{value:(_vm.editItemModal.show),callback:function ($$v) {_vm.$set(_vm.editItemModal, "show", $$v)},expression:"editItemModal.show"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Name')}},[_c('b-form-input',{attrs:{"type":"text","size":"sm"},model:{value:(_vm.editItemModal.item.name),callback:function ($$v) {_vm.$set(_vm.editItemModal.item, "name", $$v)},expression:"editItemModal.item.name"}})],1),_c('b-button',{attrs:{"variant":"primary","disabled":_vm.loading,"size":"sm"},on:{"click":_vm.generateNewApiKey}},[(_vm.editItemModal.item.id)?_c('feather-icon',{attrs:{"icon":"KeyIcon"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.editItemModal.item.id ? _vm.$t('Save') : _vm.$t('Generate'))+" ")],1)],1),_c('b-card',[(_vm.items.length)?_c('b-table',{attrs:{"items":_vm.items,"fields":_vm.fields,"busy":_vm.loading,"striped":""},scopedSlots:_vm._u([{key:"head()",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(("" + label)))+" ")]}},{key:"cell(createdBy)",fn:function(ref){
var value = ref.value;
return [_c('avatar',{attrs:{"user":value}})]}},{key:"cell(apiKey)",fn:function(ref){
var value = ref.value;
return [_c('b-badge',{staticClass:"cursor-pointer",attrs:{"variant":"light-success"},on:{"click":function($event){return _vm.copyValue(value)}}},[_c('feather-icon',{attrs:{"icon":"CopyIcon"}}),_vm._v(" "+_vm._s(value)+" ")],1)]}},{key:"cell(action)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('b-dropdown',{attrs:{"id":"dropdown-dropleft","dropleft":"","no-caret":"","variant":"flat"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{attrs:{"icon":"MoreVerticalIcon"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.openEditModal(item)}}},[_vm._v(" "+_vm._s(_vm.$t('Edit'))+" ")]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.removeApiKey(item, index)}}},[_vm._v(" "+_vm._s(_vm.$t('Delete'))+" ")])],1)]}},{key:"cell()",fn:function(row){return [_c('table-default-cell',{attrs:{"field":row.field,"value":row.value}})]}},{key:"empty",fn:function(){return [_c('empty-content')]},proxy:true},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[_c('b-spinner',{staticClass:"align-middle",attrs:{"variant":"primary"}})],1)]},proxy:true}],null,false,3781596182)}):_c('div',{staticClass:"text-center py-25 font-weight-bold text-primary"},[_c('feather-icon',{attrs:{"size":"17","icon":"XCircleIcon"}}),_vm._v(" "+_vm._s(_vm.$t('NoData'))+" ")],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }