<template>
  <div>
    <!--    Section: Filters    -->
    <sw-filters
      v-if="!threadId && !currentThread && !hideFilters"
      :shorts="[
        {
          title: $t('TasksAssignedTo'),
          type: 'avatar',
          value: () => filters.assignedUsers,
          clear: () => { filters.assignedUsers = []; }
        },
        {
          title: $t('TaskStatus'),
          value: () => filters.taskStatus && filters.taskStatus.length ? $t(`task.status.${filters.taskStatus}`) : '',
          clear: () => { filters.taskStatus = '' }
        },

        {
          title: $t('SelectLoyaltyPlan'),
          value: () => filters.loyaltyPlan && plans.find(e => e.id === filters.loyaltyPlan) ? plans.find(e => e.id === filters.loyaltyPlan).name : '',
          clear: () => { filters.loyaltyPlan = '' }
        },

        {
          title: $t('task.Type'),
          value: () => filters.type ? $t(`LoyaltyPointsStatuses.${filters.type}`) : '',
          clear: () => { filters.type = '' }
        },

        {
          value: () => filters.assignedUsersStructure ? $t(`ShowSubUsers`) : '',
          clear: () => { filters.assignedUsersStructure = false }
        },
        {
          title: $t('OrderDate'),
          value: () => filters.orderDate,
          clear: () => { filters.orderDate = ''; loadStatistics(); reloadContent() }
        },
      ]"
    >
      <b-row style="row-gap: 1rem">
        <!--    Filter by creator    -->
        <b-col
          sm="12"
          md="6"
          lg="3"
        >
          <sw-select
            :name="$t('Users')"
            class="mb-0"
          >
            <select-user
              :is-multiple="true"
              :value="filters.assignedUsers"
              :placeholder="$t('TypeToSearch')"
              @input="value => $set(filters, 'assignedUsers', value)"
            />
          </sw-select>
        </b-col>

        <b-col
          sm="12"
          md="6"
          lg="3"
        >
          <sw-select
            :name="$t('SelectLoyaltyPlan')"
            class="mb-0"
          >
            <v-select
              v-model="filters.loyaltyPlan"
              :options="plans"
              label="name"
              :reduce="plan => plan.id"
            >
              <template #no-options>
                {{ $t('NoOptions') }}
              </template>
            </v-select>
          </sw-select>
        </b-col>

        <!--    Filter by status    -->
        <b-col
          v-if="view === 'POINTS_LOGS'"
          sm="12"
          md="6"
          lg="3"
        >
          <sw-select
            :name="$t('task.Type')"
            class="mb-0"
          >
            <v-select
              v-model="filters.type"
              :options="['NEW', 'REALIZED', 'POINTS_PURCHASE', 'BALANCE_CHANGE']"
            >
              <template #option="{label}">
                {{ $t(`LoyaltyPointsStatuses.${label}`) }}
              </template>
              <template #selected-option="{label}">
                {{ $t(`LoyaltyPointsStatuses.${label}`) }}
              </template>
            </v-select>
          </sw-select>
        </b-col>

        <b-col
          v-if="view === 'POINTS_LOGS'"
          sm="12"
          md="6"
          lg="3"
        >
            <sw-select :name="$t('OrderDate')">
                <sw-select-fpr
                        :is-empty="filters.orderDate"
                        @clear="filters.orderDate = ''"
                >
                    <flat-pickr
                            ref="timeRange"
                            v-model="filters.orderDate"
                            class="form-control"
                            :config="{ mode: 'range', locale: getCalendarLocale($i18n.locale) }"
                    />
                </sw-select-fpr>
            </sw-select>
        </b-col>

        <b-col sm="12">
          <b-form-checkbox
            v-model="filters.assignedUsersStructure"
            variant="primary"
            class="mt-n1"
          >
            {{ $t('ShowSubUsers') }}
          </b-form-checkbox>
        </b-col>
      </b-row>
    </sw-filters>

    <div
      class="my-50 d-flex flex-wrap align-items-center"
      style="row-gap: .5rem; column-gap: .5rem;"
    >
      <b-button
        v-if="!hideFilters && checkRequiredPermissions([$roles.LOYALTY_PLAN_POINT_MODIFY])"
        variant="primary"
        size="sm"
        @click="showModal = true"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-25"
        />
        {{ $t('LoyaltyPointsStatuses.BALANCE_CHANGE') }}
      </b-button>

      <b-button-group size="sm">
        <b-button
          :variant="view === 'ACTUAL_POINTS' ? 'primary' : 'flat-primary'"
          size="sm"
          @click="view = 'ACTUAL_POINTS'; filters.type = ''"
        >
          {{ $t('ActualBalance') }}
        </b-button>
        <b-button
          :variant="view === 'POINTS_LOGS' ? 'primary' : 'flat-primary'"
          size="sm"
          @click="view = 'POINTS_LOGS'"
        >
          {{ $t('AwardedPoints') }}
        </b-button>
      </b-button-group>
    </div>

    <!--      Section: Table      -->
    <component :is="!threadId && !currentThread ? 'b-card' : 'div'">
      <points-table
        v-if="view === 'POINTS_LOGS' && checkRequiredPermissions([
          $roles.LOYALTY_PLAN_POINT_VIEW_ALL,
          $roles.LOYALTY_PLAN_POINT_VIEW_SELF,
          $roles.LOYALTY_PLAN_POINT_VIEW_STRUCTURE,
          $roles.LOYALTY_PLAN_POINT_VIEW_STRUCTURE_FIRST,
        ])"
        ref="points-log-table"
        :filters="filters"
        :thread-id="threadId || (currentThread ? currentThread.id : null)"
      />

      <actual-table
        v-else-if="view === 'ACTUAL_POINTS'"
        ref="actual-points-table"
        :filters="filters"
        :thread-id="threadId || (currentThread ? currentThread.id : null)"
      />
    </component>

    <!-- eslint-disable -->
    <loyalty-point-modal :show-modal.async="showModal" @close-modal="closeModal"/>

    <!-- New Point Modal -->
  </div>
</template>

<script>
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import selectUser from '@/views/components/AssignedUsers.vue'
import axiosIns from '@/libs/axios'
import ActualTable from '@/pages/loyaltyPoints/components/ActualTable.vue'
import { BButtonGroup } from 'bootstrap-vue'
// import {
//   USER_LOYALTY_PLAN_VIEW_ALL,
//   USER_LOYALTY_PLAN_VIEW_SELF,
//   USER_LOYALTY_PLAN_VIEW_STRUCTURE,
//   USER_LOYALTY_PLAN_VIEW_STRUCTURE_FIRST,
// } from '@/helpers/permissions'
import { mapGetters } from 'vuex'
import PointsTable from './components/Table.vue'
import LoyaltyPointModal from './components/LoyaltyPointModal.vue'

export default {
  components: {
    ActualTable,
    vSelect,
    flatPickr,
    PointsTable,
    LoyaltyPointModal,
    selectUser,
    BButtonGroup,
  },
  props: {
    propsUser: {
      type: Object,
      required: false,
      default: null,
    },
    threadId: {
      type: String,
      required: false,
      default: null,
    },
    hideFilters: {
      type: Boolean,
      default: false,
    },
    thread: {
      default: null,
    },
    funnel: {
      default: null,
    },
    funnelPoint: {
      default: null,
    },
    contacts: {
      default: () => [],
    },
  },
  data: () => ({
    view: 'ACTUAL_POINTS',
    filters: {
      assignedUsers: [],
      assignedUsersStructure: false,
      loyaltyPlan: '',
      agreementDate: '',
      orderDate: '',
      createdAt: '',
      type: '', // REALIZED
    },
    showModal: false,
    plans: [],
  }),
  computed: {
    ...mapGetters({
      currentThread: 'contact/thread',
    }),
    locale() {
      return this.$i18n.locale
    },
  },
  watch: {
    funnel(n, o) { if (n !== o) this.init() },
    funnelPoint(n, o) { if (n !== o) this.init() },
    thread(n, o) { if (n !== o) this.init() },
    contacts(n, o) { if (n !== o) this.init() },
  },
  async mounted() {
    if (this.funnel) {
      this.filters.funnel = this.getObjectId(this.funnel)
    }
    if (this.funnelPoint) {
      this.filters.funnelPoint = this.getObjectId(this.funnelPoint)
    }
    if (this.thread) {
      this.filters.thread = this.getObjectId(this.thread)
    }
    if (this.contacts) {
      this.filters.contacts = (this.contacts || []).mapKeys('id')
    }
    // if (!this.checkRequiredPermissions([
    //   USER_LOYALTY_PLAN_VIEW_ALL,
    //   USER_LOYALTY_PLAN_VIEW_SELF,
    //   USER_LOYALTY_PLAN_VIEW_STRUCTURE,
    //   USER_LOYALTY_PLAN_VIEW_STRUCTURE_FIRST,
    // ])) {
    //   this.view = 'POINTS_LOGS'
    // }

    await this.loadPlans()
  },
  methods: {
    closeModal() {
      this.showModal = false
      if (this.view === 'ACTUAL_POINTS') {
        this.$refs['actual-points-table'].loadData()
      } else if (this.view === 'POINTS_LOGS') {
        this.$refs['points-log-table'].loadData()
      }
    },
    // eslint-disable-next-line consistent-return
    async loadPlans() {
      if (this.checkRequiredModule('loyaltyModule') && this.checkRequiredPermissions([this.$roles.LOYALTY_PLAN_VIEW_ALL])) {
        this.isLoading = true

        try {
          const resp = await axiosIns.get('1/loyaltyPlans', { params: { fields_load: this.$fields.LOYALTY_PLANS } })

          this.plans = resp?.data?.data?.items || []
        } catch (err) {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>

<style>
</style>
