<template>
  <div :class="{ 'py-2': !((!isTask && projectId) || (isTask && projectId && taskId)) }">
    <div v-if="((!isTask && projectId) || (isTask && projectId && taskId))">
      <div
        class="d-flex"
        style="column-gap: .5rem"
      >
        <b-button
          v-if="projectId && checkRequiredPermissions([$roles.PROJECT_MODIFY])"
          :disabled="!isEdit"
          size="sm"
          variant="primary"
          @click="openModal"
        >
          {{ $t('Add') }}

          <feather-icon icon="PlusIcon" />
        </b-button>

        <b-button
          v-if="projectId"
          v-b-tooltip.hover.v-primary
          :disabled="!isEdit && checkRequiredPermissions([$roles.PROJECT_MODIFY])"
          size="sm"
          variant="outline-primary"
          class="btn-icon"
          :title="$t('Refresh')"
          @click="loadDocuments"
        >
          <feather-icon icon="RefreshCwIcon" />
        </b-button>
      </div>

      <b-table
        ref="selectableTable"
        :items="documentList"
        class="table-wrap-words mt-1 rounded shadow-sm"
        :fields="fields"
        striped
        responsive
        :busy="isLoading || isMeLoading"
        show-empty
      >
        <!--    Head    -->
        <template #head()="{ label }">
          {{ $t(`${label}`) }}
        </template>

        <!--     Files     -->
        <template #cell(files)="{ value }">
          <b-button
            v-for="file in value"
            :key="file.id"
            v-b-tooltip.hover.v-primary
            size="sm"
            variant="flat-primary"
            class="btn-icon"
            :title="file.name"
            @click="downloadDoc(file)"
          >
            <feather-icon
              icon="FileIcon"
            />
          </b-button>
        </template>

        <!--     Action     -->
        <template #cell(action)="{ item }">
          <b-button
            v-b-tooltip.hover.v-danger
            size="sm"
            variant="flat-danger"
            class="btn-icon"
            :disabled="!isEdit"
            :title="$t('Delete')"
          >
            <feather-icon
              icon="XIcon"
              @click="deleteDocument(item.id)"
            />
          </b-button>
        </template>

        <!--    Other    -->
        <!--    Other    -->
        <template #cell()="row">
          <table-default-cell
            :field="row.field"
            :value="row.value"
          />
        </template>

        <!--      Empty      -->
        <template #empty>
          <empty-content />
        </template>

        <!--      Table Busy      -->
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner
              class="align-middle"
              variant="primary"
            />
          </div>
        </template>
      </b-table>
    </div>

    <b-overlay
      :show="!((!isTask && projectId) || (isTask && projectId && taskId))"
      no-wrap
    >
      <template #overlay>
        <strong class="text-primary">
          {{ $t('saveFirst') }}
        </strong>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import { BTable, VBTooltip } from 'bootstrap-vue'
import {
  DELETE_PROJECT_DOCUMENT, DOWNLOAD_FILE, GET_PROJECT_DOCUMENTS, OPEN_MODAL,
} from '@/@constants/mutations'
import textIsland from '@core/filters/parts/textIsland'
import { mapGetters } from 'vuex'

export default {
  name: 'SwDocuments',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    BTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    projectId: {
      type: [Number, String, null],
      required: false,
      default: null,
    },
    taskId: {
      type: [Number, String, null],
      required: false,
      default: null,
    },
    microTaskId: {
      type: [Number, null],
      required: false,
      default: null,
    },
    isTask: {
      type: Boolean,
      required: false,
      default: false,
    },
    isEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    isMeLoading: false,

    fields: [
      { key: 'name', label: 'Name' },
      { key: 'description', label: 'Description' },
      { key: 'project', label: 'Offer.Project' },
      { key: 'projectTask', label: 'timeline.Task' },
      { key: 'projectTaskMicroTask', label: 'MicroTask' },
      { key: 'files', label: 'Files' },
      { key: 'action', label: 'Action' },
    ],

    documentList: [],
  }),
  computed: {
    ...mapGetters({
      modalState: 'modal/getModalState',
    }),
  },
  emits: ['update', 'remove'],
  watch: {
    projectId(n) { if (n) this.loadDocuments() },
    taskId(n) { if (n) this.loadDocuments() },
    modalState: {
      deep: true,
      handler(n) {
        if (n.reloadContent) this.loadDocuments()
      },
    },
  },
  mounted() { this.ini() },
  methods: {
    textIsland() {
      return textIsland
    },

    ini() {
      this.loadDocuments()
    },

    loadDocuments() {
      const { projectId, taskId, isTask } = this

      if ((!isTask && projectId) || (isTask && projectId && taskId)) {
        this.isMeLoading = true

        const payload = { projectId }

        if (taskId) payload.taskId = taskId

        this.$store.dispatch(`projectDocuments/${GET_PROJECT_DOCUMENTS}`, payload)
          .then(res => {
            this.documentList = res

            this.$emit('update', res.files)
          })

          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          })

          .finally(() => { this.isMeLoading = false })
      }
    },

    loadDocument(doc) { if (doc && !this.documentList.find(d => d.id === doc.id)) this.documentList.push(doc) },

    openModal(document) {
      const { projectId, taskId } = this

      this.$store.commit(`modal/${OPEN_MODAL}`,
        {
          modalType: 'addProjectDocumentModal',
          size: 'sm',
          modalTitle: this.$i18n.t(!document?.id ? 'AddDocument' : 'funnel.ChangeDocument'),
          okButtonTitle: '',
          data: {
            document,
            projectId,
            taskId: taskId || '',
            microTaskId: this.microTaskId || '',
          },
        })
    },

    deleteDocument(id) {
      this.showAlert('warning', this.$i18n.t('alerts.AreYouSure'))
        .then(() => { this.remove(id) })
    },

    remove(id) {
      const { projectId } = this

      this.isMeLoading = true

      const payload = {
        documentId: id,
        projectId,
      }

      this.$store.dispatch(`projectDocuments/${DELETE_PROJECT_DOCUMENT}`, payload)
        .then(() => {
          this.documentList.splice(this.documentList.findIndex(document => document.id === id), 1)

          this.$emit('remove', payload.documentId)
        })

        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })

        .finally(() => { this.isMeLoading = false })
    },

    downloadDoc(file) {
      this.$store.dispatch(`uploader/${DOWNLOAD_FILE}`, {
        fileToken: file.token,
        filename: file.name,
      })
        .then(res => {
          const {
            data,
            filename,
          } = res
          const a = document.createElement('a')
          a.href = `data:${data.type};base64,${data.file}`
          a.download = filename
          a.click()
        })
    },
  },
}
</script>
