<template>
  <div>
    <b-button
      variant="primary"
      size="sm"
      class="mb-25"
      @click="isModalOpen = true"
    >
      <feather-icon
        icon="PlusIcon"
        class="mr-25"
      />
      {{ $t('Add') }}
    </b-button>

    <b-card
      id="docs"
      class="mt-1"
    >
      <sw-table
        table-id="a1d1ff0aa8394a68b443aec0aa8c27e7"
        :pagination="pagination"
        @change-pagination="Object.assign(pagination, $event)"
        @reload-content="getPolls"
      >
        <template #table>
          <b-table
            :items="polls"
            class="table-wrap-words rounded"
            :fields="pollFields"
            striped
            responsive
            :busy="loading"
            show-empty
          >
            <!--    Head    -->
            <template #head()="{ label }">
              {{ $t(`${label}`) }}
            </template>

            <!--     Name     -->
            <template #cell(name)="{ item }">
              <router-link
                :to="{ name: 'polls', params: { pollId: item.id } }"
              >
                <span class="text-primary">{{ item.name }}</span>
              </router-link>
            </template>

            <!--    CreatedBy    -->
            <template #cell(createdBy)="{ value }">
              <span
                v-if="value"
                class="d-flex align-items-center text-nowrap"
              >
                <b-avatar
                  v-b-tooltip.hover
                  size="24"
                  :src="value.avatar"
                  class="mr-25 pull-up"
                  :title="value.firstName + ' ' + value.lastName"
                />

                <span class="ml-25">
                  {{ value.firstName }} {{ value.lastName }}
                </span>
              </span>
              <span v-else>
                <feather-icon icon="MinusIcon" />
              </span>
            </template>

            <!-- Action -->
            <template #cell(action)="{ item, index }">
              <b-dropdown
                id="dropdown-dropleft"
                dropleft
                no-caret
                variant="flat"
              >
                <template #button-content>
                  <feather-icon icon="MoreVerticalIcon" />
                </template>
                <b-dropdown-item @click="copyPoll(item, index)">
                  <dropdown-item-label icon="CopyIcon"
                                       :label="$t('Copy')"
                  />
                </b-dropdown-item>
                <b-dropdown-item @click="editPoll(item, index)">
                  <dropdown-item-label icon="EditIcon"
                                       :label="$t('Edit')"
                  />
                </b-dropdown-item>
                <b-dropdown-item @click="removePoll(item, index)">
                  <dropdown-item-label icon="TrashIcon"
                                       :label="$t('Delete')"
                  />
                </b-dropdown-item>
              </b-dropdown>
              <feather-icon
                v-if="false"
                icon="MinusIcon"
              />
            </template>

            <!--    Other    -->
            <!--    Other    -->
            <template #cell()="row">
              <table-default-cell
                :field="row.field"
                :value="row.value"
              />
            </template>

            <!--      Empty      -->
            <template #empty>
              <empty-content />
            </template>

            <!--      Table Busy      -->
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner
                  class="align-middle"
                  variant="primary"
                />
              </div>
            </template>
          </b-table>
        </template>
      </sw-table>
    </b-card>

    <b-modal
      :visible="isModalOpen"
      :title="$t('Poll')"
      hide-footer
      no-close-on-backdrop
      @hide="isModalOpen = false; editedPoll = { poll: null, index: -1 }"
    >
      <new-poll-modal
        :edited-poll="editedPoll.poll"
        @save-item="saveItem"
      />
    </b-modal>
  </div>
</template>

<script>
import { BModal, BTable, VBTooltip } from 'bootstrap-vue'
import { formatDate } from '@core/utils/filter'
import NewPollModal from '@/views/modals/NewSettingsPollModal.vue'
import {
  CREATE_POLL, DELETE_POLL, GET_POLLS, UPDATE_POLL,
} from '@/@constants/mutations'
import axiosIns from '@/libs/axios'

export default {
  name: 'PollList',
  components: {
    BTable,
    BModal,
    NewPollModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data: () => ({
    // Pagination
    sorter: {
      sortBy: 'createdAt',
      sortDesc: true,
    },
    pagination: {
      perPage: 25,
      currentPage: 1,
      totalRecords: 100,
    },
    pollFields: [
      { key: 'name', label: 'Name', visible: true },
      { key: 'createdAt', label: 'CreatedAt', visible: true },
      { key: 'updatedAt', label: 'UpdatedAt', visible: true },
      { key: 'createdBy', label: 'CreatedBy', visible: true },
      { key: 'action', label: 'Action', visible: true },
    ],
    polls: [],
    loading: false,
    isModalOpen: false,
    editedPoll: {
      poll: null,
      index: -1,
    },
  }),
  watch: {
    $route: {
      deep: true,
      handler() {
        this.getPolls()
      },
    },
  },
  mounted() {
    this.getPolls()
  },
  methods: {
    formatDate,
    getPolls() {
      this.loading = true

      this.$nextTick(() => {
        this.$store.dispatch(`polls/${GET_POLLS}`, { pagination: this.pagination })
          .then(res => {
            this.pagination.totalRecords = res.data.totalItemCount
            this.polls = res.data.items
            this.loading = false
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            this.loading = false
          })
      })
    },
    editPoll(item, index) {
      this.$set(this.editedPoll, 'poll', { ...item })
      this.$set(this.editedPoll, 'index', index)
      this.isModalOpen = true
    },
    async copyPoll(item) {
      const response = await axiosIns.post('1/settings/polls', { name: `${item.name} - ${this.$i18n.t('Copy1')}` })
      const pollFields = await axiosIns.get(`1/settings/polls/${item.id}/fields`, { params: { fields_load: 'SETTINGS_POLLS_FIELDS_COPY', orderBy: 'position.asc' } })
      const payload = pollFields.data.data.items

      await axiosIns.put(`1/settings/polls/${response.data.data.items[0]?.id}/fields`, payload)
    },
    saveItem(item) {
      const payload = JSON.parse(JSON.stringify(item))
      let action = CREATE_POLL
      if (payload?.id) action = UPDATE_POLL

      this.$nextTick(() => {
        this.$store.dispatch(`polls/${action}`, payload)
          .then(() => {
            const text = action === CREATE_POLL ? this.$i18n.t('success.offerFolderCreated') : this.$i18n.t('success.offerFolderUpdated')
            this.showToast('success', text, this.$i18n.t('success.saved'))

            this.getPolls()
            this.isModalOpen = false
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          })
      })
    },
    removePoll(poll, index) {
      this.showAlert('error', this.$i18n.t('alerts.AreYouSure'))
        .then(() => {
          this.$store.dispatch(`polls/${DELETE_POLL}`, poll.id)
            .then(() => {
              this.$delete(this.polls, index)
              this.showToast('success', this.$i18n.t('RemovedSuccessfully'))
            })
            .catch(err => {
              this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            })
        })
    },
  },
}
</script>

<style scoped>

</style>
