<!--eslint-disable-->

<template>
  <div v-if="product">
    <b-overlay
      no-wrap
      :show="isLoading"
      spinner-variant="primary"
    />

    <!--  Remove "&& loading" on production -->
    <div>
      <b-alert
        variant="primary"
        show
        class-name="shadow border border-secondary"
        style="border-color: #DDD !important;"
      >
        <div
          class="alert-body text-left overflow-hidden position-relative"
        >
          <div
            class="d-flex align-items-center justify-content-center w-100 position-relative zindex-1"
            style="min-height: 240px"
          >
            <div
              class="d-flex flex-column justify-content-center align-items-center"
              style="min-height: 240px"
            >
              <!--      START->Announcement: No Service      -->
              <h3
                class="text-primary text-md-center font-weight-bolder mb-2"
                style="max-width: 680px; line-height: 2rem"
              >
                {{ $t('YouHaveNotSelectedMarketingPlanToChargeUsersCommissions') }}
              </h3>
              <!--      END->Announcement: No Service      -->

              <!--      START->Button: Add Service      -->
              <sw-select
                class="mb-0"
                :name="$t('SelectMarketingPlan')"
                style="max-width: 340px"
                :disabled="isEdited"
              >
                <v-select
                  :value="selectedPlan"
                  class="bg-white"
                  :disabled="isEdited"
                  :options="plans"
                  label="name"
                  @input="selectPlan"
                >
                  <template #no-options>
                    {{ $t('NoOptions') }}
                  </template>
                </v-select>
              </sw-select>
              <!--      END->Button: Add Service      -->
            </div>
          </div>

          <!--      START->Illustration      -->
          <div class="text-center w-100 mt-2 position-absolute position-top-0">
            <img
              src="@/assets/images/pricing-Illustration.svg"
              alt="Illustration"
              width="950px"
              style="opacity: .14; z-index: 0;"
            >
          </div>
          <!--      END->Illustration      -->
        </div>
      </b-alert>    <!--  Widok przed wybraniem   -->
    </div>

    <b-alert
      v-if="product.shopProductMarketingPlans"
      show
      variant="primary"
    >
      <div class="alert-body">
        {{ $t('ProductMarketingBoxInfo') }}
      </div>
    </b-alert>

    <app-collapse
      class="mt-1"
      type="margin"
    >
      <app-collapse-item
        v-for="(shopProductPlan, i) in product.shopProductMarketingPlans"
        :key="`marketing_plan_${i}`"
        :title="shopProductPlan.marketingPlan.name"
        is-visible
      >
        <b-row style="row-gap: 1rem">
          <b-col md="8">
            <span class="pb-25">
              {{ $t('SpecifyAmountOfNetCommissionDependingOnNumberOfDifferentProductsSold') }}
            </span>

            <div
              v-for="(item, n) in shopProductPlan.shopProductMarketingPlanCommissions"
              :key="n"
              class="d-flex flex-column flex-md-row align-items-start align-items-md-center mt-2 mt-md-50"
              style="column-gap: 1rem"
            >
              <strong class="d-inline-block text-nowrap mb-50 mb-md-0 d-flex justify-content-center align-items-center">
                <b-button
                  class="btn-icon p-25 mr-50"
                  size="sm"
                  variant="flat-danger"
                  :disabled="isEdited"
                  @click="$emit('remove', i, n)"
                >
                  <feather-icon icon="XIcon" />
                </b-button>

                <b-form-group class="mb-0">
                  <b-input-group>
                    <cleave
                      v-model="item.productAmountMin"
                      :options="cleaveOptions.numberPositive"
                      class="form-control"
                      :disabled="isEdited"
                    />
                    <b-input-group-append is-text>
                      <span class="text-muted">{{ $t('Pro') }}.</span>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
                <div class="mx-50">
                  {{ $t('To') }}
                </div>
                <b-form-group class="mb-0">
                  <b-input-group>
                    <cleave
                      v-model="item.productAmountMax"
                      :options="cleaveOptions.numberPositive"
                      class="form-control"
                      :disabled="isEdited"
                      @input="changeMaxRange(i, n, $event)"
                    />
                    <b-input-group-append is-text>
                      <span class="text-muted">{{ $t('Pro') }}.</span>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </strong>

              <div
                class="d-flex align-items-center w-100"
                style="column-gap: 1rem"
              >
                <cleave
                  v-model="item.value"
                  :options="cleaveOptions.numberPositive"
                  class="form-control"
                  :disabled="isEdited"
                />

                <sw-select
                  class="mb-0"
                  style="flex: 1 0 7rem"
                  disabled
                >
                  <v-select
                    value="PLN"
                    :options="['PLN', 'USD', 'EUR']"
                    disabled
                  >
                    <template #no-options>
                      {{ $t('NoOptions') }}
                    </template>

                    <template #option="{ label }">
                      {{ label.toUpperCase() }}
                    </template>

                    <template #selected-option="{ label }">
                      {{ label.toUpperCase() }}
                    </template>
                  </v-select>
                </sw-select>
              </div>
            </div>

            <b-button
              variant="primary"
              size="sm"
              class="mt-1"
              :disabled="isEdited"
              @click="addCommission(i)"
            >
              {{ $t('Add') }}

              <feather-icon icon="PlusIcon" />
            </b-button>
          </b-col>

          <b-col md="4">
            <p class="mb-25">
              <span>
                {{ $t('SpecifyMinimumProductCosts') }}
              </span>

              <b-button
                v-b-tooltip.focus.v-primary
                variant="flat-primary"
                :title="$t('ThisIsValueThatMustBeSatisfiedBeforeUsers')"
                class="btn-icon p-25 mt-n25 ml-25"
                size="sm"
              >
                <feather-icon icon="InfoIcon" />
              </b-button>
            </p>

            <div
              class="d-flex align-items-center w-100"
              style="column-gap: 1rem"
            >
              <cleave
                v-model="shopProductPlan.minimumProductPrice"
                :options="cleaveOptions.numberPositive"
                class="form-control"
                :disabled="isEdited"
              />

              <sw-select
                class="mb-0"
                style="flex: 1 0 7rem"
              >
                <v-select
                  value="PLN"
                  :options="['PLN', 'USD', 'EUR']"
                  disabled
                >
                  <template #no-options>
                    {{ $t('NoOptions') }}
                  </template>

                  <template #option="{ label }">
                    {{ label.toUpperCase() }}
                  </template>

                  <template #selected-option="{ label }">
                    {{ label.toUpperCase() }}
                  </template>
                </v-select>
              </sw-select>
            </div>
          </b-col>
        </b-row>
      </app-collapse-item>
    </app-collapse>    <!--  Widok po wyborze   -->
  </div>
</template>

<script>
/* eslint-disable */

import Cleave from 'vue-cleave-component'
import vSelect from 'vue-select'
import axiosIns from '@/libs/axios'
import { BInputGroup, BInputGroupAppend, VBTooltip } from 'bootstrap-vue'

export default {
  name: 'MarketingPlan',
  components: {
    vSelect,
    Cleave,
    BInputGroupAppend,
    BInputGroup,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    product: {
      required: true,
      default: null,
      type: Object,
    },
    isEdited: {
      required: true,
      default: false,
      type: Boolean,
    },
  },
  data: () => ({
    plans: [],
    isLoading: false,

    cleaveOptions: {
      numberPositive: {
        delimiter: ' ',
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
        numeralPositiveOnly: true,
      },
    },
  }),
  computed: {
    selectedPlan() {
      return this.product?.shopProductMarketingPlans?.length ? this.product.shopProductMarketingPlans[0]?.marketingPlan : null
    },
  },
  async mounted() {
    await this.loadPlans()
  },
  methods: {
    changeMaxRange(planIndex, index, value) {
      const commissions = this.product.shopProductMarketingPlans[planIndex].shopProductMarketingPlanCommissions
      if (commissions.length === 1) return
      if (!this.product.shopProductMarketingPlans[planIndex].shopProductMarketingPlanCommissions[index + 1]) return

      this.product.shopProductMarketingPlans[planIndex].shopProductMarketingPlanCommissions[index + 1].productAmountMin = parseFloat(value) + 1
    },
    addCommission(planIndex) {
      const commissions = this.product.shopProductMarketingPlans[planIndex].shopProductMarketingPlanCommissions
      const firstDiff = (commissions[0].productAmountMax - commissions[0].productAmountMin) || 10
      this.product.shopProductMarketingPlans[planIndex].shopProductMarketingPlanCommissions.push({
        value: 0,
        productAmountMax: commissions.length > 1 ? parseFloat(commissions[commissions.length - 1].productAmountMax) + (firstDiff + 1) : 0,
        productAmountMin: commissions.length > 1 ? parseFloat(commissions[commissions.length - 1].productAmountMax) + 1 : 0,
      })
    },
    async loadPlans() {
      this.isLoading = true

      try {
        const resp = await axiosIns.get('1/marketingPlans', { params: { fields_load: this.$fields.MARKETING_PLANS } })

        this.plans = resp?.data?.data?.items || []
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.isLoading = false
      }
    },
    async selectPlan(event) {
      if (!event) {
        this.$set(this.product, 'shopProductMarketingPlans', [])
        return
      }

      this.$set(this.product, 'shopProductMarketingPlans', [{
        minimumProductPrice: 0,
        shopProductMarketingPlanCommissions: [
          { value: 0, productAmountMax: 10, productAmountMin: 0 },
        ],
        marketingPlan: event,
      }])
    },
  },
}
</script>

<style scoped>

</style>
