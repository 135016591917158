<!--eslint-disable-->

<template>
  <div>
    <!--    Button: New Attribute    -->
    <b-button
      v-if="checkRequiredPermissions([$roles.SHOP_ATTRIBUTE_MODIFY])"
      variant="primary"
      size="sm"
      @click="showAttributeModal = true"
    >
      <feather-icon
        icon="PlusIcon"
        class="mr-25"
      />
      {{ $t('Add') }}
    </b-button>

    <!--    Attribute list    -->
    <b-row class="d-flex flex-row-reverse mt-1">
        <b-col sm="12">
            <b-card>
                <div class="p-2 text-center d-flex justify-content-center" v-if="loading">
                    <b-spinner variant="primary" />
                </div>

                <div v-else>

                    <div v-if="categories.length">
                        <draggable
                                v-model="categories"
                                tag="ul"
                                group="people"
                                class="w-100 list-group list-group-flush cursor-move rounded border"
                        >
                            <template v-for="(item, index) in categories">
                                <b-list-group-item :key="index" tag="li">
                                    <div
                                            class="
                                              d-flex
                                              flex-wrap
                                              flex-column
                                              flex-sm-row justify-content-sm-between align-items-sm-center
                                            "
                                    >
                                        <div>
                                            <b-badge
                                              v-b-tooltip.hover
                                              :variant="!item.active ? 'light-danger' : 'light-success'"
                                              pill
                                              :title="!item.active ? $t('Inactive') : $t('Active')"
                                            >
                                                <feather-icon :icon="!item.active ? 'MinusIcon' : 'CheckIcon'" />
                                            </b-badge>
                                            <b-badge v-b-tooltip.hover :title="$t('offer.attribute.accessIn')" variant="light-warning" class="ml-25 mt-25">
                                                <feather-icon icon="ShoppingCartIcon" /> {{ $t('Offer.Amount') }}: {{ item.shopProductsAmount || 0 }}
                                            </b-badge>
                                            {{ getTranslationsField(item, 'name') }}
                                        </div>
                                        <div>
                                            <b-badge variant="light-primary" class="ml-25 mt-25">
                                                {{ $t(`offer.attribute.types.${item.type}`) }}
                                            </b-badge>

                                            <b-dropdown
                                                    v-if="checkRequiredPermissions([$roles.SHOP_ATTRIBUTE_MODIFY])"
                                                    dropleft
                                                    no-caret
                                                    variant="flat"
                                            >
                                                <template
                                                        #button-content
                                                        class="sr-only"
                                                >
                                                    <feather-icon
                                                            icon="MoreVerticalIcon"
                                                            size="16"
                                                            class="align-middle"
                                                    />
                                                </template>

                                                <!--    Action: Switch Active    -->
                                                <b-dropdown-item @click="item.active = !item.active">
                                                    <dropdown-item-label
                                                            :icon="!item.active ? 'CheckIcon' : 'XIcon'"
                                                            :label="!item.active ? $t('Activate') : $t('Deactivate')"
                                                    />
                                                </b-dropdown-item>

                                                <b-dropdown-divider />

                                                <!-- Action: Edit -->
                                                <b-dropdown-item @click="openEditModal(item, index)">
                                                    <dropdown-item-label icon="EditIcon" :label="$t('Edit')" />
                                                </b-dropdown-item>

                                                <!--    Action: Delete    -->
                                                <b-dropdown-item @click="remove(item, index)">
                                                    <dropdown-item-label icon="TrashIcon" :label="$t('Delete')" />
                                                </b-dropdown-item>
                                            </b-dropdown>
                                        </div>
                                    </div>

                                    <div
                                            v-if="item.shopAttributeOptions && item.shopAttributeOptions.length"
                                            class="d-flex flex-wrap align-items-center"
                                    >
                                        <b-badge
                                                v-for="(value, index) in item.shopAttributeOptions.slice(0, 6)"
                                                :key="`item_${item.id}_${index}`"
                                                class="mr-25 mt-25"
                                                variant="light-secondary"
                                        >
                                            {{ typeof value === 'string' ? JSON.parse(value).translations[0].name : value.translations[0].name }}
                                        </b-badge>

                                        <span @click="showAttributeValuesIndex = index" class="ml-25 mt-25 cursor-pointer" v-if="item.shopAttributeOptions.length > 6">
                                            +{{ item.shopAttributeOptions.length - 6 }} {{ $t('More') }}
                                        </span>
                                    </div>
                                </b-list-group-item>
                            </template>
                        </draggable>
                    </div>

                    <div v-else class="text-center py-1 text-primary font-weight-bold">
                        <feather-icon size="17" icon="XCircleIcon" />
                        {{ $t('NoData') }}
                    </div>

                </div>
            </b-card>
        </b-col>
    </b-row>

    <!--    Modal: Create/Edit Attribute     -->
    <b-modal
      v-model="showAttributeModal"
      :title="$t('offer.attribute.new')"

      size="md"
      hide-footer
      no-close-on-backdrop
      @close="closeModal"
    >
      <attribute-modal
        :old-attribute="{...oldAttribute}"
        @saveAttribute="saveAttribute"
        @closeModal="closeModal"
      />
    </b-modal>

      <b-modal
      :visible="showAttributeValuesIndex > -1"
      size="md"
      hide-footer
      @hide="showAttributeValuesIndex = -1; modalSearch = ''"
    >
          <b-input-group
                  class="input-group-merge search-input-group mx-25 mb-50"
          >
              <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                  v-model="modalSearch"
                  :placeholder="$t('Search')"
                  class="input-search"
              />
          </b-input-group>
          <div v-if="showAttributeValuesIndex > -1 && categories[showAttributeValuesIndex]">
              <div v-if="getFilteredItems(categories[showAttributeValuesIndex].shopAttributeOptions).length">
                  <b-badge
                          v-for="(value, index) in getFilteredItems(categories[showAttributeValuesIndex].shopAttributeOptions)"
                          :key="`item_modal_${categories[showAttributeValuesIndex].id}_${index}`"
                          class="mr-25 mt-25"
                          variant="primary"
                  >
                      {{ typeof value === 'string' ? JSON.parse(value).translations[0].name : value.translations[0].name }}
                  </b-badge>
              </div>

              <div
                      v-else
                      class="text-center py-1 text-primary font-weight-bold"
              >
                  <feather-icon
                          size="17"
                          icon="XCircleIcon"
                  />
                  {{ $t('NoData') }}
              </div>
          </div>
    </b-modal>

    <!-- Push to server btn -->
    <b-button
      v-if="checkRequiredPermissions([$roles.SHOP_ATTRIBUTE_MODIFY])"
      variant="primary"
      @click="pushToServer"
    >
      {{ $t('Save') }}
    </b-button>
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'
import { DELETE_ATTRIBUTE, GET_ATTRIBUTE, SAVE_ATTRIBUTE } from '@/@constants/mutations'
import payloadKeysCutter from '@/helpers/keysCuter'
import draggable from 'vuedraggable'
import attributeModal from '../../views/modals/AttributeModal.vue'

export default {
  components: {
    draggable,
    attributeModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data: () => ({
    loading: false,
    oldAttribute: {},
    oldIndex: -1,
    showAttributeModal: false,

    showAttributeValuesIndex: -1,
    modalSearch: '',

    fields: [
      { key: 'name', label: 'Name' },
      { key: 'shopAttributeOptions', label: 'Value' },
      { key: 'type', label: 'offer.attribute.type' },
      { key: 'shopProductsAmount', label: 'offer.attribute.accessIn' },
      { key: 'active', label: 'offer.attribute.status' },
      // { key: 'base', label: 'offer.attribute.base' },
      { key: 'active', label: 'offer.attribute.status' },
      { key: 'action', label: 'Action' },
    ],
    categories: [],
  }),
  mounted() {
    this.getFromServer()
  },
  methods: {
    getFromServer() {
      this.loading = true
      this.$store.dispatch(`attributes/${GET_ATTRIBUTE}`)
        .then(res => {
          this.categories = res.data.items
          this.loading = false
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          this.loading = false
        })
    },
    pushToServer() {
      const items = JSON.parse(JSON.stringify(this.categories))
      this.saveItems(items)
    },

    getFilteredItems(items = []) {
      return items.filter(e => this.getTranslationsField(e, 'name').toLowerCase().includes(this.modalSearch.toLowerCase()))
    },

    /// Save New Items ///
    saveItems(items) {
      this.loading = true
      // eslint-disable-next-line no-unused-vars
      items.forEach((item, itemIndex) => {
        // eslint-disable-next-line
        if (item.type !== 'TEXT_ARRAY' && item.type !== 'TEXT_MULTIPLE_ARRAY') item.shopAttributeOptions = []
        // eslint-disable-next-line no-unused-vars
        item.shopAttributeOptions.forEach((option, index) => {
          // eslint-disable-next-line
          item.shopAttributeOptions[index] = typeof option === 'string' ? JSON.parse(option) : option
        })

        // eslint-disable-next-line no-param-reassign
        item.position = itemIndex
      })
      const preparedItems = payloadKeysCutter(items)

      this.$store.dispatch(`attributes/${SAVE_ATTRIBUTE}`, preparedItems)
        .then(() => {
          this.showToast('success', this.$i18n.t('success.contactUpdated'))
          this.getFromServer()
        })
        .catch(err => {
          this.loading = false
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
    /// Open Edit Modal ///
    openEditModal(item, index = -1) {
      this.oldAttribute = JSON.parse(JSON.stringify(item))
      this.oldIndex = index
      this.showAttributeModal = true
    },
    closeModal() {
      this.oldAttribute = {}
      this.oldIndex = -1
      this.showAttributeModal = false
    },
    saveAttribute(item) {
      if (this.oldIndex === -1) this.categories.push(item)
      else {
        this.$set(this.categories, this.oldIndex, item)
      }
    },

    /*    Duplicate duplicates categories    */
    duplicate(attribute) {
      const ni = JSON.parse(JSON.stringify(attribute))

      if (ni.id) delete ni.id

      this.categories.push(ni)
    },
    /*    Remove removes categories    */
    remove(item, index) {
      if (item?.id) {
        this.showAlert('error', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('DeleteAttributeAlert'))
          .then(() => {
            this.deleteItemFromDatabase(item.id, index)
          })
      } else {
        this.categories.splice(index, 1)
      }
    },
    deleteItemFromDatabase(id, index) {
      this.$store.dispatch(`attributes/${DELETE_ATTRIBUTE}`, id)
        .then(() => {
          this.categories.splice(index, 1)
          this.showToast('success', this.$i18n.t('RemovedSuccessfully'))
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
  },
}
</script>
