var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100 d-flex flex-column"},_vm._l((_vm.list),function(item,i){return _c('item',{key:i,staticClass:"border-bottom",attrs:{"user":{
      firstName: item.firstName,
      lastName: item.lastName,
      position: i + 1,
      id: item.id,
      avatar: item.avatar,
      userId: item.userId,
      points: item.totalSystemAmount,
      products: item.products,
      converter: item.converter,
    },"type":_vm.type,"max-sale":_vm.largestSell}})}),1)}
var staticRenderFns = []

export { render, staticRenderFns }