var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h4',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$t('Punctation'))+" ")]),_c('b-row',{staticStyle:{"row-gap":"1rem"}},[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('ChooseHowToAssignLoyaltyPointsToPurchasedProducts'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('sw-select',{attrs:{"name":_vm.$t('ChooseHowToAssignLoyaltyPointsToPurchasedProducts'),"tip":_vm.$t('HereWouDetermineWhatValueOfPointsYouWillReceive'),"state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"options":_vm.typesOfAssignPoints,"label":"label","reduce":function (i) { return i.value; }},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('NoOptions'))+" ")]},proxy:true},{key:"selected-option",fn:function(ref){
var label = ref.label;
return [_c('span',[_vm._v(_vm._s(_vm.$t(("" + label))))])]}},{key:"option",fn:function(ref){
var label = ref.label;
return [_c('span',[_vm._v(_vm._s(_vm.$t(("" + label))))])]}}],null,true),model:{value:(_vm.target.typeOfAssignLoyaltyPoints),callback:function ($$v) {_vm.$set(_vm.target, "typeOfAssignLoyaltyPoints", $$v)},expression:"target.typeOfAssignLoyaltyPoints"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('SelectHowPointValueOfProductsYouBuyWillBeAssigned'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('sw-select',{attrs:{"name":_vm.$t('SelectHowPointValueOfProductsYouBuyWillBeAssigned'),"tip":_vm.$t('HereYouSpecifyForWhatValueOfCollectedPoints'),"state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"options":_vm.typesOfAssignPoints,"label":"label","reduce":function (i) { return i.value; }},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('NoOptions'))+" ")]},proxy:true},{key:"selected-option",fn:function(ref){
var label = ref.label;
return [_c('span',[_vm._v(_vm._s(_vm.$t(("" + label))))])]}},{key:"option",fn:function(ref){
var label = ref.label;
return [_c('span',[_vm._v(_vm._s(_vm.$t(("" + label))))])]}}],null,true),model:{value:(_vm.target.typeOfAssignLoyaltyPointsToOwnPurchase),callback:function ($$v) {_vm.$set(_vm.target, "typeOfAssignLoyaltyPointsToOwnPurchase", $$v)},expression:"target.typeOfAssignLoyaltyPointsToOwnPurchase"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-checkbox',{attrs:{"variant":"primary","switch":""},model:{value:(_vm.target.chargePointsForSales),callback:function ($$v) {_vm.$set(_vm.target, "chargePointsForSales", $$v)},expression:"target.chargePointsForSales"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('DoNotChargePointsForSalesMadeWithLoyaltyPlanPoints'))}})])],1)],1),_c('b-overlay',{attrs:{"show":_vm.isLoading,"no-wrap":"","spinner-variant":"primary"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }