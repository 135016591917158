// eslint-disable-next-line import/prefer-default-export
export const aiTopics = [
  {
    variant: 'primary', value: 'CLAIM', label: 'Complaint', text: 'ComplainAiAssistant', icon: 'FrownIcon',
  },
  {
    variant: 'success', value: 'PRODUCT', label: 'Products', text: 'ProductAiAssistant', icon: 'ShoppingCartIcon',
  },
  {
    variant: 'info', value: 'WANT_HUMAN', label: 'SwitchOffHuman', text: 'SwitchOffHumanDescription', icon: 'UserIcon',
  },
  {
    variant: 'warning', value: 'LEAD_GENERATOR', label: 'LeadGenerator', text: 'LeadGeneratorDescription', icon: 'PaperclipIcon',
  },
  // {
  //   variant: 'warning', value: 'KNOWLEDGE_BASE', label: 'KnowledgeBase', text: 'KnowledgeBaseAiAssistant', icon: 'BookIcon',
  // },
]
export const aiTopicsByValue = value => aiTopics.find(type => type.value === value) || null

export const aiPositiveActions = [
  { variant: 'primary', value: 'DISPLAY_NOTIFICATION', label: 'Complaint' },
  { variant: 'success', value: 'CONVERT_TO_CONTACT', label: 'Product' },
  { variant: 'success', value: 'ADD_PROCESS', label: 'Product' },
  { variant: 'success', value: 'ADD_USER', label: 'Product' },
  { variant: 'success', value: 'CREATE_COMPLAINT', label: 'Product' },
]
export const aiPositiveActionsByValue = value => aiPositiveActions.find(type => type.value === value) || null

export const aiNegativeActions = [
  { variant: 'primary', value: 'DISPLAY_NOTIFICATION', label: 'Complaint' },
  { variant: 'success', value: 'ADD_USER_TO_CONVERSATION', label: 'Product' },
  { variant: 'success', value: 'OFF_CONVERSATION_AI', label: 'Product' },
]
export const aiNegativeActionsByValue = value => aiNegativeActions.find(type => type.value === value) || null
