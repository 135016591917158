<template>
  <validation-observer
    ref="form"
    class="d-flex flex-column"
    style="row-gap: 1.34rem"
  >
    <!--    START:Header    -->
    <div
      class="d-flex flex-column flex-md-row align-md-items-center justify-md-content-between"
      style="column-gap: 1.5rem"
    >
      <!--    START:Name & Hint    -->
      <b-row
        class="d-flex flex-grow-1"
        style="row-gap: .34rem"
      >
        <!--    START:Name    -->
        <b-col
          cols="12"
          md="6"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="$t('EnterFieldName')"
            rules="required"
          >
            <b-input-group>
              <b-form-input
                v-model="form.name"
                :label="$t('EnterFieldName')"
                :placeholder="$t('EnterFieldName')"
              />

              <b-input-group-append>
                <b-button
                  v-b-tooltip.hover.v-primary
                  variant="outline-primary"
                  :title="$t('calculators.sectionTypes.SingleSelectionList')"
                  class="btn-icon px-75"
                  style="cursor: default !important"
                >
                  <sw-icon icon="InfoIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <!--    END:Name    -->

        <!--    START:Hint    -->
        <b-col
          cols="12"
          md="6"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="$t('AddHint')"
          >
            <b-form-input
              v-model="form.hint"
              :label="$t('AddHint')"
              :placeholder="$t('AddHint')"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <!--    END:Hint    -->
      </b-row>
      <!--    END:Name & Hint    -->

      <!--    START:Toolbar MD    -->
      <div
        class="d-none d-md-flex align-items-md-start mt-md-50"
        style="row-gap: .5rem"
      >
        <b-form-checkbox
          v-model="form.show"
          v-b-tooltip.hover.v-success
          checked="true"
          :title="$t('FieldHiddenVisible')"
          class="custom-control-success"
          name="show"
          switch
        >
          <span class="switch-icon-left">
            <feather-icon icon="EyeIcon" />
          </span>
          <span class="switch-icon-right">
            <feather-icon icon="EyeOffIcon" />
          </span>
        </b-form-checkbox>

        <b-button
          v-b-tooltip.hover.v-danger
          variant="flat-danger"
          size="sm"
          class="btn-icon mt-md-n25"
          :title="$t('Delete')"
          @click="$emit('rm')"
        >
          <feather-icon icon="TrashIcon" />
        </b-button>
      </div>
      <!--    END:Toolbar MD    -->
    </div>
    <!--    END:Header    -->

    <!--    START:Content    -->
    <div>
      <h5>
        {{ $t('EnterAlgorithmForCalculatingDisplayedValue') }}
      </h5>

      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <sw-select
            :name="$t('SpecifyTypeOfFieldUsed')"
            rules="required"
            classes="mb-0 w-100"
            :clearable="false"
          >
            <v-select
              v-model="form.type"
              :options="types"
              label="name"
              :placeholder="$t('SpecifyTypeOfFieldUsed')"
            >
              <template #no-options="{ search }">
                <span v-if="search.length">
                  {{ $t('NoData') }}
                </span>

                <span v-else>
                  {{ $t('TypeToSearch') }}
                </span>
              </template>
            </v-select>
          </sw-select>
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <sw-select
            :name="$t('SelectValue')"
            rules="required"
            classes="mb-0 w-100"
          >
            <v-select
              v-model="form.value"
              :options="values"
              label="name"
              :placeholder="$t('SelectValue')"
              :clearable="false"
            >
              <template #no-options="{ search }">
                <span v-if="search.length">
                  {{ $t('NoData') }}
                </span>

                <span v-else>
                  {{ $t('TypeToSearch') }}
                </span>
              </template>
            </v-select>
          </sw-select>
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <sw-select
            :name="$t('SelectAction')"
            rules="required"
            classes="mb-0 w-100"
          >
            <v-select
              v-model="form.action"
              label="name"
              :options="actions"
              :get-option-label="name => $t(`calculators.actions.${name}`)"
              :placeholder="$t('SelectAction')"
              :clearable="false"
            >
              <template #no-options="{ search }">
                <span v-if="search.length">
                  {{ $t('NoData') }}
                </span>

                <span v-else>
                  {{ $t('TypeToSearch') }}
                </span>
              </template>
            </v-select>
          </sw-select>
        </b-col>
      </b-row>
    </div>
    <!--    END:Content    -->

    <!--    START:Options    -->
    <b-form-group
      :label="$t('Options')"
      class="mb-0"
    >
      <template #label>
        <h5 class="mb-25">
          {{ $t('Options') }}
        </h5>
      </template>

      <b-form-checkbox
        v-model="form.required"
        class="custom-control-primary"
      >
        {{ $t('MandatoryChoice') }}
      </b-form-checkbox>
    </b-form-group>
    <!--    END:Options    -->

    <!--    START:Toolbar SM    -->
    <div
      class="d-flex align-items-center justify-content-end mt-n1 d-md-none"
      style="row-gap: .5rem"
    >
      <b-form-checkbox
        v-model="form.show"
        v-b-tooltip.hover.v-success
        checked="true"
        :title="$t('FieldHiddenVisible')"
        class="custom-control-success"
        name="show"
        switch
      >
        <span class="switch-icon-left">
          <feather-icon icon="EyeIcon" />
        </span>
        <span class="switch-icon-right">
          <feather-icon icon="EyeOffIcon" />
        </span>
      </b-form-checkbox>

      <b-button
        v-b-tooltip.hover.v-danger
        variant="flat-danger"
        size="sm"
        class="btn-icon"
        :title="$t('Delete')"
        @click="$emit('rm')"
      >
        <feather-icon icon="TrashIcon" />
      </b-button>
    </div>
    <!--    END:Toolbar SM    -->
  </validation-observer>
</template>

<script>
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { BInputGroupAppend, VBTooltip } from 'bootstrap-vue'

export default {
  components: {
    BInputGroupAppend,
    ValidationObserver,
    ValidationProvider,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },

  emits: ['isValid', 'rm'],

  data: () => ({
    form: {
      show: true,
    },

    actions: [
      'ADD',
      'SUB',
      'MUL',
      'DIV',
    ],

    types: [],
    values: [],
  }),

  watch: {
    form: {
      deep: true,
      async handler() {
        this.$emit('isValid', await this.$refs.form.validate({ silent: true }))
      },
    },
  },

  methods: {
    addNewRangeItem(index) {
      this.form.options.fieldList[index].ranges.push(this.form.options.fieldList[index].newItem)

      this.form.options.fieldList[index].newItem = { }
    },
  },
}
</script>
