var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"newContactFieldForm",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('Name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mb-0"},[_c('sw-select',{attrs:{"name":_vm.$t('Name')}},[_c('v-select',{attrs:{"placeholder":_vm.$t('Name'),"options":_vm.addonFieldTypes,"filterable":false,"label":"name","item-value":"id","item-text":"name","state":errors.length > 0 ? false:null},on:{"input":_vm.clearField},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('NoOptions'))+" ")]},proxy:true}],null,true),model:{value:(_vm.contactAddonFieldType),callback:function ($$v) {_vm.contactAddonFieldType=$$v},expression:"contactAddonFieldType"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),(_vm.contactAddonFieldType)?[(['DIGITAL', 'PERCENTAGE'].includes(_vm.contactAddonFieldType.type))?_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('Value')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Value')}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('Value'),"disabled":!_vm.contactAddonFieldType,"state":errors.length > 0 ? false:null,"type":"number"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}):_vm._e(),(['DATE'].includes(_vm.contactAddonFieldType.type))?_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('Value')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Value')}},[_c('sw-select-fpr',{on:{"clear":function($event){_vm.value = ''}}},[_c('flat-pickr',{staticClass:"form-control form-control-sm",attrs:{"config":Object.assign({}, _vm.flatPickerConfig, {locale: _vm.getCalendarLocale(_vm.$i18n.locale)}, _vm.timeConfig('DATE'), {defaultDate: _vm.value})},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}):_vm._e(),(['TIME'].includes(_vm.contactAddonFieldType.type))?_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('Value')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Value')}},[_c('sw-select-fpr',{on:{"clear":function($event){_vm.value = ''}}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":Object.assign({}, _vm.flatPickerConfig, {locale: _vm.getCalendarLocale(_vm.$i18n.locale)}, _vm.timeConfig('TIME'), {defaultDate: _vm.value}),"placeholder":_vm.$t('Value')},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}):_vm._e(),(['CHECKBOX'].includes(_vm.contactAddonFieldType.type))?_c('validation-provider',{attrs:{"name":_vm.$t('Value')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Value')}},[_c('b-form-checkbox',{attrs:{"disabled":!_vm.contactAddonFieldType,"state":errors.length > 0 ? false:null},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}):_vm._e(),(['EMAIL'].includes(_vm.contactAddonFieldType.type))?_c('validation-provider',{attrs:{"rules":"required|email","name":_vm.$t('Value')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Value')}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('Value'),"disabled":!_vm.contactAddonFieldType,"state":errors.length > 0 ? false:null},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}):_vm._e(),(['TEXT_MULTIPLE_ARRAY'].includes(_vm.contactAddonFieldType.type))?_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('Value')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mb-0"},[_c('sw-select',{attrs:{"name":_vm.$t('Value')}},[_c('v-select',{attrs:{"placeholder":_vm.$t('Value'),"options":_vm.contactAddonFieldType.contactAddonFieldTypeOptions,"filterable":_vm.filterOptions,"label":"name","item-value":"id","item-text":"name","state":errors.length > 0 ? false:null,"multiple":""},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('NoOptions'))+" ")]},proxy:true}],null,true),model:{value:(_vm.contactAddonFieldTypeOptions),callback:function ($$v) {_vm.contactAddonFieldTypeOptions=$$v},expression:"contactAddonFieldTypeOptions"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}):_vm._e(),(['TEXT_ARRAY'].includes(_vm.contactAddonFieldType.type))?_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('Value')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mb-0"},[_c('sw-select',{attrs:{"name":_vm.$t('Value')}},[_c('v-select',{attrs:{"placeholder":_vm.$t('Value'),"options":_vm.contactAddonFieldType.contactAddonFieldTypeOptions,"filterable":_vm.filterOptions,"label":"name","item-value":"id","item-text":"name","state":errors.length > 0 ? false:null},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('NoOptions'))+" ")]},proxy:true}],null,true),model:{value:(_vm.contactAddonFieldTypeOptions),callback:function ($$v) {_vm.contactAddonFieldTypeOptions=$$v},expression:"contactAddonFieldTypeOptions"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}):_vm._e(),(['RADIAL'].includes(_vm.contactAddonFieldType.type))?_c('validation-provider',{attrs:{"name":_vm.$t('Value')}},[_c('b-form-group',{attrs:{"label":_vm.$t('offer.attribute.types.RADIAL')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return _vm._l((_vm.contactAddonFieldType.contactAddonFieldTypeOptions),function(option,i){return _c('b-form-radio',{key:i,attrs:{"aria-describedby":ariaDescribedby,"value":option},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_vm._v(" "+_vm._s(option.name)+" ")])})}}],null,true)})],1):_vm._e(),(['TOOLTIP'].includes(_vm.contactAddonFieldType.type))?_c('validation-provider',{attrs:{"name":_vm.$t('Value')}},[_c('b-form-group',{attrs:{"label":_vm.$t('Value')}},[_c('b-input-group',[_c('b-form-input',{attrs:{"placeholder":_vm.$t('Value')},model:{value:(_vm.newValue),callback:function ($$v) {_vm.newValue=$$v},expression:"newValue"}}),_c('b-input-group-append',[_c('b-button',{staticClass:"btn-icon",attrs:{"variant":"outline-primary"},on:{"click":function () {
                if (_vm.newValue.length) {
                  _vm.contactAddonFieldTextOptions.push(_vm.newValue)
                  _vm.newValue = ''
                }
              }}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)],1)],1)],1),_vm._l((_vm.contactAddonFieldTextOptions),function(option,i){return _c('b-form-group',{key:i},[_c('b-input-group',[_c('b-form-input',{attrs:{"placeholder":_vm.$t('Value')},model:{value:(_vm.contactAddonFieldTextOptions[i]),callback:function ($$v) {_vm.$set(_vm.contactAddonFieldTextOptions, i, $$v)},expression:"contactAddonFieldTextOptions[i]"}}),_c('b-input-group-append',[_c('b-button',{staticClass:"btn-icon",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.contactAddonFieldTextOptions.splice(i, 1)}}},[_c('feather-icon',{attrs:{"icon":"MinusIcon"}})],1)],1)],1)],1)})],2):_vm._e()]:_vm._e(),(!_vm.contactAddonFieldType || ['TEXT'].includes(_vm.contactAddonFieldType.type))?_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('Value')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Value')}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('Value'),"disabled":!_vm.contactAddonFieldType,"state":errors.length > 0 ? false:null},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}):_vm._e(),_c('b-alert',{attrs:{"variant":"primary","show":""}},[_c('div',{staticClass:"alert-heading"},[_vm._v(" "+_vm._s(_vm.$t('Description'))+" ")]),_c('div',{staticClass:"alert-body"},[(_vm.contactAddonFieldType)?[(_vm.contactAddonFieldType.description)?_c('span',[_vm._v(_vm._s(_vm.contactAddonFieldType.description))]):_c('feather-icon',{attrs:{"icon":"MinusIcon"}})]:_c('div',{staticClass:"text-center py-50 text-primary font-weight-bold"},[_c('feather-icon',{attrs:{"size":"17","icon":"XCircleIcon"}}),_vm._v(" "+_vm._s(_vm.$t('ChooseTypeFirst'))+" ")],1)],2)]),_c('b-button',{attrs:{"size":"sm","variant":"primary","disabled":!_vm.contactAddonFieldType || (invalid && !['RADIAL'].includes(_vm.contactAddonFieldType.type) && !['TOOLTIP'].includes(_vm.contactAddonFieldType.type))},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('Save'))+" ")]),_c('b-overlay',{attrs:{"no-wrap":"","show":_vm.isLoading,"spinner-variant":"primary"}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }