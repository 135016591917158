<!--eslint-disable-->

<template>
  <compontent :is="isCart ? 'b-card' : 'div'">
    <!--    <b-button-->
    <!--      size="sm"-->
    <!--      @click="$emit('remove', agreement.id)"-->
    <!--    >-->
    <!--      {{ $t('Delete') }} {{ agreement.id }}-->
    <!--    </b-button>-->
    <!--    <transition name="fade">-->
    <!--      <div-->
    <!--        v-if="agreement.agreementTemplate && isCart"-->
    <!--        class="d-flex align-items-center flex-wrap mb-1"-->
    <!--      >-->
    <!--        <span class="font-weight-bolder pr-1">{{ $t('Preview') }} {{ $t('AgreementsCount').toLowerCase() }}</span>-->
    <!--        <b-button-->
    <!--          variant="outline-warning"-->
    <!--          size="sm"-->
    <!--          @click="openAgreementPreview"-->
    <!--        >-->
    <!--          <feather-icon icon="EyeIcon" />-->
    <!--        </b-button>-->
    <!--      </div>-->
    <!--    </transition>-->
    <sw-select :name="$t('Contracts.AgreementType')">
      <v-select
        v-model="agreementType"
        :options="agreementTypes.filter(e => e.agreementTemplates && e.agreementTemplates.length)"
        :reduce="item => item.id"
        :disabled="disabled"
        label="name"
        @input="selectContractTypes"
      />
    </sw-select>

    <sw-select>
      <v-select
        v-if="agreementType"
        v-model="agreement.agreementTemplate"
        :disabled="!agreementType"
        :options="agreements"
        label="name"
        @input="getRequiredPolls"
      >
        <template #no-options>
          {{ $t('NoData') }}
        </template>
      </v-select>
    </sw-select>

    <div v-if="agreement.agreementTemplate">
      <b-row
        class="mb-1"
        style="row-gap: 1rem"
      >
        <b-col md="6">
          <b-form-group class="w-100 mb-25">
            <b-form-checkbox
              v-model="agreement.anonymised"
              checked="false"
              variant="primary"
              name="check-button"
              inline
              @change="updateAgreementField($event, 'anonymised')"
            >
              <span>{{ $t('AnonymousContractTip') }}</span>
            </b-form-checkbox>
          </b-form-group>

          <b-form-group class="w-100 my-0">
            <b-form-radio
              v-model="agreement.electronicSignatureType"
              name="electronicSignatureType"
              variant="primary"
              :value="null"
              @change="updateAgreementField(null, 'electronicSignatureType', true)"
            >
              {{ $t('NoSignature') }}
            </b-form-radio>

            <b-form-radio
              v-model="agreement.electronicSignatureType"
              name="electronicSignatureType"
              variant="primary"
              value="AUTENTI"
              :disabled="!autentiEnabled || !agreement.agreementTemplate.enableElectronicSignatureTypes.includes('AUTENTI')"
              @change="updateAgreementField($event, 'electronicSignatureType', true)"
            >
              {{ $t('Offer.EnableElectronicSignatureOfContract') }}
              <feather-icon
                v-b-tooltip
                icon="InfoIcon"
                class="ml-1"
                :title="$t('Offer.Tips.EnableElectronicSignatureOfContract')"
              />
            </b-form-radio>
            <b-form-radio
              v-model="agreement.electronicSignatureType"
              name="electronicSignatureType"
              variant="primary"
              value="WWW_SIGNATURE"
              @change="updateAgreementField($event, 'electronicSignatureType', true)"
              :disabled="!agreement.agreementTemplate.enableElectronicSignatureTypes.includes('WWW_SIGNATURE')"
            >
              {{ $t('EnableHandwrittenSignature') }}
            </b-form-radio>
          </b-form-group>
        </b-col>

        <b-col md="6">
          <validation-provider
            v-slot="{ errors }"
            :name="$t('SignerUser')"
          >
            <sw-select :name="$t('SignerUser')">
              <v-select
                v-model="agreement.signerUser"
                :options="agreement.agreementTemplate.signerUsers"
                style="min-width: 250px"
                :reduce="item => item.id"
                @input="updateAgreementField($event, 'signerUser')"
              >
                <template #no-options>
                  {{ $t('NoOptions') }}
                </template>
                <template #option="option">
                  <avatar :user="option" />
                </template>
                <template #selected-option="option">
                  <avatar
                    :user="option"
                    :truncate="30"
                  />
                </template>
              </v-select>
            </sw-select>
            <small class="text-danger">{{ errors[0] }}</small>
            <small
              v-if="errors.includes('signerUser')"
              class="text-danger"
            >{{ $t('ThisFieldIsRequired') }}</small>
          </validation-provider>
        </b-col>

        <b-col sm="12">
          <sw-select :name="$t('ranks.sections.contractor')">
            <v-select
              :value="agreement.contactThreadCartDraftAgreementAssignedContacts"
              :options="availableContactToAssign"
              multiple
              :loading="loading"
              :selectable="item => !(selectedContacts.includes(item.contact.id))"
              @option:deselecting="deselectContact"
              @input="updateAgreementContactField($event, 'contactThreadCartDraftAgreementAssignedContacts')"
            >
              <template #no-options>
                {{ $t('NoOptions') }}
              </template>
              <template #option="option">
                <avatar
                  :user="option.contact"
                  :text-variant="'text-light'"
                />
              </template>
              <template #selected-option="option">
                <avatar
                  :user="option.contact"
                  :text-variant="'text-light'"
                  :truncate="30"
                />
              </template>
            </v-select>
          </sw-select>
        </b-col>
      </b-row>

      <!-- AssignedContacts -->
      <!-- eslint-disable -->
      <b-row>
        <b-col
          v-for="(contact, index) in agreement.contactThreadCartDraftAgreementAssignedContacts"
          :key="`agreement_${agreement.id}_contact_${index}`"
          sm="12"
          md="6"
        >
          <section-agreement-item-contact
            :ref="`agreement_${agreement.id}_contact_${index}`"
            :contact.async="contact"
            :required-fields="requiredContactFields"
            @save="updateAgreementContactField(agreement.contactThreadCartDraftAgreementAssignedContacts, 'contactThreadCartDraftAgreementAssignedContacts')"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col
          v-if="requiredPolls.length && checkRequiredModule('pollModule')"
          sm="12"
          class="mb-1"
        >
          <b-alert
            show
            variant="primary"
            class="px-1 py-50"
          >
            <feather-icon icon="AlertCircleIcon" /> {{ $t('AgreementRequiresToCompletePolls') }}
          </b-alert>
          <app-collapse
            accordion
            type="border"
            class="shadow-none"
          >
            <new-poll-modal
              v-for="poll in requiredPolls"
              :key="`required_poll_${poll.id}`"
              :ref="`required_poll_${poll.id}`"
              :title="poll.name"
              :tag="'app-collapse-item'"
              :default-poll="poll"
              :edited-poll="getContactEditedPoll(poll)"
              :required-poll-fields="requiredPollFields(poll.id)"
              update-on-blur
            />
          </app-collapse>
        </b-col>
      </b-row>
    </div>

    <b-button @click="saveAgreement" v-if="!isCart" variant="primary" size="sm">{{ $t('Save') }}</b-button>
  </compontent>
</template>

<script>
/* eslint-disable */

import { CREATE_POLL, GET_CONTRACTS_TEMPLATES, GET_POLLS, SAVE_POLL_OPTION, } from '@/@constants/mutations'
import vSelect from 'vue-select'
import { ValidationProvider } from 'vee-validate'
import { VBTooltip } from 'bootstrap-vue'
import axiosIns from '@/libs/axios'
import { mapGetters } from 'vuex'
import SectionAgreementItemContact from '@/views/offers/checkout/SectionAgreementItemContact.vue'
import NewPollModal from '@/views/modals/NewPollModal.vue'

export default {
  name: 'SectionAgreementItem',
  components: {
    SectionAgreementItemContact,
    NewPollModal,
    vSelect,
    ValidationProvider,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    cartItem: {
      type: Object,
      default: null,
    },
    agreement: {
      type: Object,
      default: null,
      required: true,
    },
    agreementIndex: {
      type: Number,
      default: -1,
    },
    agreementTypes: {
      type: Array,
      default: () => [],
      required: true,
    },
    autentiEnabled: {
      type: Boolean,
      default: false,
    },
    isCart: {
      type: Boolean,
      default: false,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
  },
  emits: ['edit-contact', 'edit-contact-poll'],
  data: () => ({
    loading: false,
    agreementType: '',
    agreements: [],
    requiredPolls: [],
    contactPolls: [],
    errors: [],
  }),
  computed: {
    ...mapGetters({
      cartM: 'cart/getCart',
    }),
    cart() {
      return this.cartItem || this.$store.state.cart
    },
    requiredContactFields() {
      const contactFields = this.agreement?.agreementTemplate.usedAllShortCodes || []
      // return formated to pollFieldId.option
      return contactFields
        .filter(code => code.includes('contractor'))
        .map(code => code.replaceAll('contractor.', ''))
    },
    selectedContacts() {
      return this.agreement.contactThreadCartDraftAgreementAssignedContacts?.map(({ contact }) => contact?.id) || []
    },
    threadAssignedContacts() {
      return ((this.cartItem || this.$store.state.cart)?.contactThread?.contactThreadAssignedContacts || []).map(a => {
        // eslint-disable-next-line
        delete a.id
        return a
      })
    },
    availableContactToAssign() {
      const leftPart = this.threadAssignedContacts.reduce((a, b) => a + +b.part, 0)
      return [{ contact: (this.cartItem || this.$store.state.cart).contactThread.contact, part: leftPart }, ...this.threadAssignedContacts]
    },
  },
  watch: {
    '$store.state.cart.contactThread.id': {
      deep: true,
      handler(nw, ow) {
        if (nw?.contactThread?.id === ow?.contactThread?.id) {
          this.init()
        }
      },
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.getContactPolls()
      if (this.agreement?.agreementTemplate?.assignedPolls) {
        this.requiredPolls = this.agreement.agreementTemplate.assignedPolls
      }
      //
      if (this.agreement?.agreementTemplate?.agreementType) {
        this.agreementType = this.getObjectId(this.agreement?.agreementTemplate?.agreementType)
      }

      this.updateAgreementContactField(this.availableContactToAssign, 'contactThreadCartDraftAgreementAssignedContacts')

      if (!this.agreement.electronicSignatureType) {
        this.agreement.electronicSignatureType = null
        this.$forceUpdate()
      }
    },
    requiredPollFields(pollId) {
      const pollFields = this.agreement?.agreementTemplate.usedAllShortCodes || []
      // return formated to pollFieldId.option
      const f = pollFields
        .filter(code => code.includes(`poll.${pollId}`))
        .map(code => code.replaceAll(`poll.${pollId}.`, '').replaceAll(' %}', ''))

      return f
    },
    async updateAgreementContactField(value = [], field) {
      if (!this.isCart) {
        this.agreement.contactThreadCartDraftAgreementAssignedContacts = value
        return
      }

      this.loading = true
      this.cart.cartAgreements[this.agreementIndex].contactThreadCartDraftAgreementAssignedContacts = value
      const payload = value.map(item => {
        if (item?.id) return { contact: this.getObjectId(item.contact), part: parseFloat(item?.part || 0), id: item.id }

        return { contact: this.getObjectId(item.contact), part: parseFloat(item?.part || 0) }
      })
      try {
        if (this.$store.state.cart.name) {
          await this.updateAgreementField(payload, field, true)
        }
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.loading = false
      }
    },
    async updateAgreementField(value, field, skipId = false) {
      // const index = this.agreementIndex

      // I do know why, but it's workin' only with this :)
      if (field === 'electronicSignatureType') {
        this.agreement.electronicSignatureType = value || null
        const resp = await axiosIns.patch('1/contacts/threads/cartDrafts/me/agreements', { allowElectronicSignature: !!value, id: this.agreement.id, })
        this.$forceUpdate()
      }

      if (this.isCart && this.agreement.id) {
        try {
          const payload = {
            id: this.agreement.id,
            [field]: !skipId ? this.getObjectId(value) : value,
          }
          const resp = await axiosIns.patch('1/contacts/threads/cartDrafts/me/agreements', payload)
          return resp
        } catch (err) {
          return err
        }
      }

      return null
    },
    deselectContact(event) {
      this.showAlert('warning', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('ContractorWillBeDeleted'), true, 'Delete', 'Cancel', 'Skip')
        .then(async res => {
          if (res?.isDenied) {
            // Skip deleting from process
          } else if (res?.isConfirmed) {
            // Delete contact from process
            try {
              // eslint-disable-next-line
              const contactThread = (this.cartItem || this.$store.state.cart)?.contactThread

              if (this.cartItem) this.cartItem.contactThread.contactThreadAssignedContacts = this.agreement.contactThreadCartDraftAgreementAssignedContacts.filter(c => c.contact.id !== contactThread.contact.id)
              else if (this.$store.state.cart) this.$store.state.cart.contactThread.contactThreadAssignedContacts = this.agreement.contactThreadCartDraftAgreementAssignedContacts.filter(c => c.contact.id !== contactThread.contact.id)

              const newItems = this.agreement.contactThreadCartDraftAgreementAssignedContacts.map(c => ({ ...c, contact: c.contact.id })).filter(c => c.contact !== contactThread.contact.id)
              await axiosIns.patch(`1/contacts/${contactThread.contact.id}/threads`, { contact: contactThread.contact.id, contactThreadAssignedContacts: newItems, id: contactThread.id })
            } catch (err) {
              this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            }
          }
        })
        .catch(() => {
          this.updateAgreementContactField([...this.agreement.contactThreadCartDraftAgreementAssignedContacts, event], 'contactThreadCartDraftAgreementAssignedContacts')
        })
    },
    openAgreementPreview() {},
    getContactPolls() {
      this.$nextTick(() => {
        if (this.checkRequiredModule('pollModule')) {
          this.loading = true
          this.$nextTick(() => {
            // const { contact, id } = this.cart.contactThread
            const { contactThread } = (this.cartItem || this.$store.state.cart)
            const { contact, id } = contactThread

            const payload = {
              contactId: contact.id, threadId: id,
            }

            this.$store.dispatch(`singleContact/${GET_POLLS}`, payload)
              .then(res => {
                this.contactPolls = res.data.items
                this.loading = false
              })
              .catch(err => {
                this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
                this.loading = false
              })
          })
        }
      })
    },
    getContactEditedPoll(pollToSearch) {
      const findItem = this.contactPolls.find(({ poll }) => poll.id === pollToSearch.id)
      return findItem || null
    },
    selectContractTypes(value) {
      if (!value) {
        this.getRequiredPolls(null)
        this.agreementTemplate = ''
      } else {
        this.getContractTemplates()
      }
    },
    async getContractTemplates() {
      try {
        const resp = await this.$store.dispatch(`contractTemplates/${GET_CONTRACTS_TEMPLATES}`, { filters: { type: this.getObjectId(this.agreementType) } })
        this.agreements = resp?.data?.items || []
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    getRequiredPolls(poll = []) {
      this.updateAgreementField(null, 'electronicSignatureType', true)

      if (poll && this.checkRequiredModule('pollModule')) {
        // eslint-disable-next-line no-underscore-dangle
        const _poll = [poll]

        let assignedPolls = []
        if (Array.isArray(_poll)) {
          _poll.forEach(pollItem => pollItem.assignedPolls.forEach(({ id }) => { assignedPolls.push(id) }))
          assignedPolls = [...new Set(assignedPolls)]
        } else assignedPolls = assignedPolls.map(({ id }) => id)

        if (assignedPolls && assignedPolls.length) {
            if (this.checkRequiredModule('pollModule')) {
                this.$store.dispatch(`polls/${GET_POLLS}`, {filters: {polls: assignedPolls}})
                    .then(res => {
                        this.requiredPolls = res.data.items
                    })
                    .catch(err => {
                        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
                    })
            }
        }
      } else {
        this.requiredPolls = []
      }

      this.updateAgreementField(poll.id, 'agreementTemplate')
      // this.calculatePrices()
    },
    async saveAgreement() {
      const isValid = await this.save()
      if (!isValid) return

      const threadId = this.cartItem?.contactThread.id
      const contactId = this.cartItem?.contactThread?.contact.id

      try {
        const payload = {
          ...this.agreement,
          agreementTemplate: this.getObjectId(this.agreement.agreementTemplate),
          signerUser: this.getObjectId(this.agreement.signerUser) || null,
          contactThreadAgreementAssignedContacts: this.agreement.contactThreadCartDraftAgreementAssignedContacts.map(item => ({ contact: this.getObjectId(item.contact), part: parseFloat(item.part || 0) })),
        }

        if (!payload.electronicSignatureType)
          delete payload.electronicSignatureType

        if (this.cartItem?.id) {
          payload.contactThreadCartBox = this.cartItem.id
        } else {
          payload.contactThread = threadId
        }
        delete payload.contactThreadCartDraftAgreementAssignedContacts
        const resp = await axiosIns.post(`1/contacts/${contactId}/threads/${threadId}/agreements`, payload)

        this.$emit('close-modal', { resp, reloadId: this.cartItem?.reloadId })
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    async save() {
      let resp = true

      // Validate agreement
      const errors = []
      if (this.agreement?.electronicSignatureType?.length) this.agreement.allowElectronicSignature = true

      if (!this.agreement?.agreementTemplate) errors.push('agreementTemplate')
      if (this.agreement.allowElectronicSignature && this.agreement.electronicSignatureType === 'AUTENTI' && !this.agreement?.signerUser) errors.push('signerUser')
      if (this.agreement.contactThreadCartDraftAgreementAssignedContacts?.length) {
        this.agreement.contactThreadCartDraftAgreementAssignedContacts.forEach((contact, index) => {
          const ref = `agreement_${this.agreement.id}_contact_${index}`
          if (!errors.includes('contacts') && this.$refs[ref][0].filledFields !== this.requiredContactFields.length) errors.push('contacts')
        })
      }

      console.error(errors)
      this.errors = errors
      if (errors.length) return false

      // Polls
      /* eslint-disable */
      if (!this.checkRequiredModule('pollModule')) return resp

      resp = await this.validatePolls()
      if (resp) {
        const isOk = await Promise.all(resp.map(poll => this.saveItem(poll)))
          .then(() => {
            this.showToast('success', this.$i18n.t('PollsAndContactsUpdated'))
            return true
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            return false
          })

        return isOk
      }

      return resp
    },
    async validatePolls() {
      const polls = []
      // eslint-disable-next-line
      for (const requiredPoll of this.requiredPolls) {
        const ref = `required_poll_${requiredPoll.id}`
        // eslint-disable-next-line no-await-in-loop
        const response = await this.$refs[ref][0].savePoll()
        if (response) {
          const { fields, poll } = response
          polls.push({ fields, poll })
        } else {
          this.showToast('error', this.$i18n.t('SomeFieldsAreNotFilled'))
        }
      }

      if (polls.length === this.requiredPolls.length) {
        return polls
      }

      this.showToast('error', this.$i18n.t('SomeFieldsAreNotFilled'))
      return false
    },
    // POLL UPDATES
    saveItem({ fields, poll }) {
      return new Promise((resolve, reject) => {
        const { contact, id } = this.cart.contactThread
        const payload = {
          contactId: contact.id, threadId: id, pollId: typeof poll === 'string' ? poll : poll?.id,
        }
        if (!poll?.exist) {
          this.$store.dispatch(`singleContact/${CREATE_POLL}`, payload)
            .then(res => {
              const threadPollId = res.data.items[0]?.id
              if (threadPollId) {
                this.saveFields(threadPollId, fields)
                  .then(() => {
                    resolve()
                  })
                  .catch(() => {
                    reject()
                  })
              }
            })
            .catch(err => {
              this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
              reject()
            })
        } else {
          this.saveFields(payload.pollId, fields)
            .then(() => {
              resolve()
            })
            .catch(err => {
              reject(err)
            })
        }
      })
    },
    saveFields(pollId, fields = []) {
      return new Promise((resolve, reject) => {
        const { contact, id } = this.cart.contactThread
        const payload = {
          contactId: contact.id, threadId: id, pollId, options: fields,
        }
        if (fields.length) {
          this.$store.dispatch(`singleContact/${SAVE_POLL_OPTION}`, payload)
            .then(() => {
              resolve()
            })
            .catch(err => {
              this.$emit('close-modal', true)
              this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
              reject()
            })
        } else resolve()
      })
    },
  },
}
</script>

<style scoped>

</style>
