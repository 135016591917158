<template>
  <div>
    <!--      START->Scene: Content      -->
    <template v-if="!isLoading">
      <b-row v-if="items.length">
        <b-col
          v-for="(item, i) in items"
          :key="i"
          lg="6"
        >
          <item :item="item" />
        </b-col>
      </b-row>    <!--  If not empty  -->

      <div v-else>
        <empty />    <!--  If empty  -->
      </div>
    </template>
    <!--      END->Scene: Content      -->

    <!--      START->Scene: Loading      -->
    <b-alert
      v-else
      variant="primary"
      show
      class-name="shadow border border-secondary"
      style="border-color: #DDD !important;"
    >
      <div
        class="alert-body text-left overflow-hidden position-relative"
      >
        <div
          class="d-flex align-items-center justify-content-center w-100 position-relative zindex-1"
          style="min-height: 240px"
        >
          <b-spinner variant="primary" />
        </div>

        <!--      START->Illustration      -->
        <div class="text-center w-100 mt-2 position-absolute position-top-0">
          <img
            src="@/assets/images/pricing-Illustration.svg"
            alt="Illustration"
            width="950px"
            style="opacity: .14; z-index: 0;"
          >
        </div>
        <!--      END->Illustration      -->
      </div>
    </b-alert>
    <!--      END->Scene: Loading      -->
  </div>
</template>

<script>
import { BAlert, BSpinner } from 'bootstrap-vue'
import empty from './Empty.vue'
import item from './Item.vue'

export default {
  components: {
    BAlert,
    BSpinner,
    empty,
    item,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    items: {
      type: Array,
      required: false,
      default: () => ([]),
    },
  },
}
</script>
