import { GET_THREAD_CONVERSATION } from '@/@constants/mutations'
// eslint-disable-next-line import/no-cycle
import axiosIns from '@/libs/axios'
import * as fields from '@/@constants/fields'

// GET SINGLE CONVERSATION - and show previes messages
// params.fields = 'id,conversation.id,conversation.mails.id,conversation.mails.msgId,conversation.mails.status,conversation.mails.createdAt,conversation.mails.createdBy.id,conversation.mails.createdBy.firstName,conversation.mails.createdBy.lastName,conversation.mails.createdBy.avatar,conversation.mails.contentHtml,conversation.mails.fromMail,conversation.mails.subject,conversation.mails.conversation,conversation.mails.toAddresses.id,conversation.mails.files,conversation.mails.toAddresses.mail,conversation.mails.toAddresses.user,conversation.mails.toAddresses.user.id,conversation.mails.toAddresses.user.firstName,conversation.mails.toAddresses.user.lastName,conversation.mails.toAddresses.user.avatar'

export default {
  namespaced: true,
  state: {},
  actions: {
    // eslint-disable-next-line no-unused-vars
    [GET_THREAD_CONVERSATION]: ({ commit }, payload) => new Promise((resolve, reject) => {
      const { threadId, filters, pagination } = payload
      const params = {}
      params.fields_load = fields.CHAT_CONVERSATIONS_MAILS
      params.orderBy = 'createdAt.desc'

      if (pagination) {
        params.page = pagination.page
        params.limit = pagination.perPage
      }

      if (filters?.status && filters.status !== 'all') {
        params.eq_status = filters.status
      }

      if (filters?.search) {
        params.search = `%${filters?.search}%`
        params.search_fields = 'fromMail'
      }

      axiosIns.get(`1/contacts/threads/${threadId}/conversations/mails`, { params })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-async-promise-executor
    GET_CONVERSATION: (_, payload) => new Promise(async (resolve, reject) => {
      const { filters, pagination } = payload
      const params = {}
      params.fields_load = fields.CHAT_CONVERSATIONS_MAILS
      params.orderBy = 'createdAt.desc'

      if (pagination) {
        params.page = pagination.page
        params.limit = pagination.perPage
      }

      if (filters?.status && filters.status !== 'all') {
        params.eq_status = filters.status
      }

      if (filters?.search) {
        params.search = `%${filters?.search}%`
        params.search_fields = 'fromMail'
      }

      if (filters?.contacts && filters.contacts.length) {
        params['eqArr_conversation-contactThread-contact-id'] = JSON.stringify(filters.contacts)
      }
      if (filters?.funnelPoint) {
        params['eq_conversation-contactThread-contactThreadFunnelProcesses-contactThreadFunnelTypePoint-id'] = filters.funnelPoint
      }
      if (filters?.funnel) {
        params['eq_conversation-contactThread-contactThreadFunnelProcesses-contactThreadFunnelTypePoint-contactThreadFunnelType-id'] = filters.funnel
      }
      if (filters?.thread) {
        params['eq_conversation-contactThread-id'] = filters.thread
      }

      axiosIns.get('1/contacts/threads/conversations/mails', { params })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
  },
}

// Test
