<template>
  <sw-filters
    :shorts="[
      {
        title: $t('ActivityDate'),
        value: () => activeFilter ? filterCreatedAtOptions.find(e => e.value === activeFilter).text : filters.timeRange,
        clear: () => {
          filters.activeFilter = ''
          filters.timeRange = ''
          updateFilter()
        }
      },
      {
        title: $t('Funnels'),
        value: () => filters.funnel ? filters.funnel.name : null,
        clear: () => {
          filters.funnel = ''
          updateFilter()
        }
      },
      {
        title: $t('AssignedUser'),
        type: 'avatar',
        value: () => assignedUsers,
        clear: () => {
          assignedUsers = []
          filters.contacts = ''
          updateFilter()
        }
      },
      {
        title: $t('Contact'),
        value: () => filters.contacts ? `${filters.contacts.firstName} ${filters.contacts.lastName}` : null,
        clear: () => {
          filters.contacts = ''
          updateFilter()
        }
      },
    ]"
  >
    <b-row>
      <b-col
        sm="12"
        md="6"
      >
        <div class="d-flex align-items-end">
          <sw-select :name="$t('ActivityDate')">
            <sw-select-fpr
              :is-empty="filters.timeRange"
              @clear="() => { filters.timeRange = ''; updateFilter(); activeFilter = '' }"
            >
              <flat-pickr
                v-model.trim="filters.timeRange"
                class="form-control"
                :config="{ mode: 'range', dateFormat: 'd-m-Y H:i', enableTime: true, time_24hr: true, locale: getCalendarLocale($i18n.locale) }"
                @input="updateFilter($event);"
                @on-close="activeFilter = ''"
              />
            </sw-select-fpr>
          </sw-select>

          <b-button id="target-date"
                    variant="outline-primary"
                    class="mb-1 ml-25"
          >
            <sw-icon icon="TargetIcon" />
          </b-button>
        </div>

        <b-popover target="target-date"
                   triggers="hover"
        >
          <b-button
            v-for="(a, index) in filterCreatedAtOptions"
            :key="`randge_${index}`"
            :variant="activeFilter !== a.value ? 'outline-primary' : 'primary'"
            size="sm"
            class="w-100 mb-25"
            style="row-gap: .25rem"
            @click="setDate(a.value)"
          >
            {{ a.text }}
          </b-button>
        </b-popover>
      </b-col>
      <b-col
        sm="12"
        md="6"
      >
        <sw-select :name="$t('Funnels')">
          <v-select
            v-model="filters.funnel"
            :options="funnels"
            label="name"
            :reduce="e => e.id"
            @input="updateFilter"
          />
        </sw-select>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        sm="12"
        md="6"
      >
        <sw-select :name="$t('AssignedUser')">
          <assigned-users
            :value="assignedUsers"
            :disable-current-user="false"
            @input="changeAssignedUsers"
          />
        </sw-select>
      </b-col>
      <b-col
        sm="12"
        md="6"
      >
        <b-form-group :label="$t('Contact')">
          <sw-select
            :disabled="!contacts.length"
            class="mb-0"
          >
            <v-select
              v-model="filters.contacts"
              :options="contacts"
              :disabled="!contacts.length"
              label="firstName"
              @input="updateFilter"
            >
              <template #option="{ firstName, lastName }">
                {{ firstName }} {{ lastName }}
              </template>
              <template #selected-option="{ firstName, lastName }">
                {{ firstName }} {{ lastName }}
              </template>
            </v-select>
          </sw-select>
        </b-form-group>
      </b-col>
    </b-row>
  </sw-filters>
</template>

<script>
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import AssignedUsers from '@/views/components/AssignedUsers.vue'
import { GET_FUNNELS, GET_USER_CONTACTS } from '@/@constants/mutations'
import axiosIns from '@/libs/axios'
import { mapGetters } from 'vuex'
import { BPopover } from 'bootstrap-vue'

export default {
  name: 'TimelineFilters',
  components: {
    flatPickr,
    vSelect,
    AssignedUsers,
    BPopover,
  },
  props: {
    defaultAssigned: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    filters: {
      timeRange: '',
      contacts: '',
      funnel: '',
    },
    assignedUsers: [],
    activeFilter: '',
    filterCreatedAtOptions: [],
    contacts: [],
    funnels: [],
  }),
  computed: {
    locale() {
      return this.$i18n.locale
    },
    ...mapGetters({
      currentUser: 'auth/getCurrentUser',
      systemSettings: 'system/getSettings',
    }),
  },
  mounted() {
    this.filterCreatedAtOptions = [
      { text: this.$t('Today'), value: 'today' },
      { text: this.$t('Last7Days'), value: 'last7Days' },
      { text: this.$t('ThisMonth'), value: 'thisMonth' },
      { text: this.$t('LastMonth'), value: 'lastMonth' },
    ]

    this.getFunnels()
    if (this.currentUser.configTables && this.currentUser.configTables['global-timeline-filters']) {
      const filters = JSON.parse(this.currentUser.configTables['global-timeline-filters'])
      Object.assign(this.filters, filters)
      if (filters?.assignedUsers?.length) {
        this.changeAssignedUsers(this.filters?.assignedUsers)
      } else {
        this.updateFilter()
      }
    } else {
      if (Array.isArray(this.defaultAssigned) && this.defaultAssigned.length) {
        this.changeAssignedUsers(this.defaultAssigned)
      }
      const todayStart = `${moment(new Date()).format('DD-MM-YYYY')} 00:00`
      const todayEnd = `${moment(new Date()).format('DD-MM-YYYY')} 23:59`
      this.filters.timeRange = [todayStart, todayEnd]

      this.activeFilter = 'today'
    }
    // this.filters.timeRange = `${todayStart} ${this.$i18n.t('To')} ${todayEnd}`
  },
  methods: {
    async updateFilter() {
      const filters = {
        timeRange: Array.isArray(this.filters.timeRange) ? this.filters.timeRange.join(' do ') : this.filters.timeRange,
        contacts: this.filters.contacts ? [this.filters.contacts.id] : [...this.contacts.map(contact => contact.id)],
        funnel: typeof this.filters.funnel === 'object' ? this.filters.funnel?.id || '' : this.filters.funnel,
      }

      const newConfig = { ...(this.currentUser.configTables || {}), 'global-timeline-filters': JSON.stringify({ ...filters, contacts: this.filters.contacts, assignedUsers: this.assignedUsers }) }
      this.currentUser.configTables = newConfig
      await axiosIns.patch(`1/users/${this.currentUser.id}/profile`, { id: this.currentUser.id, configTables: newConfig })

      this.$emit('reload-timeline', filters)
    },
    setDate(dateValue) {
      if (dateValue === 'today') {
        const todayStart = `${moment(new Date()).format('DD-MM-YYYY')} 00:00`
        const todayEnd = `${moment(new Date()).format('DD-MM-YYYY')} 23:59`

        this.filters.timeRange = [todayStart, todayEnd]
      }

      if (dateValue === 'last7Days') {
        const startAt = moment().subtract(7, 'days').format('DD-MM-YYYY HH:mm')
        const now = moment().format('DD-MM-YYYY HH:mm')

        this.filters.timeRange = [startAt, now]
      }

      if (dateValue === 'thisMonth') {
        const startAt = moment().startOf('month').format('DD-MM-YYYY HH:mm')
        const endAt = moment().endOf('month').format('DD-MM-YYYY HH:mm')

        this.filters.timeRange = [startAt, endAt]
      }

      if (dateValue === 'lastMonth') {
        const startAt = moment().subtract(1, 'month').startOf('month').format('DD-MM-YYYY HH:mm')
        const endAt = moment().subtract(1, 'month').endOf('month').format('DD-MM-YYYY HH:mm')

        this.filters.timeRange = [startAt, endAt]
      }

      if (dateValue === 'last6Months') {
        const startAt = moment().subtract(6, 'months').startOf('month').format('DD-MM-YYYY HH:mm')
        const endAt = moment().format('YYYY-MM-DD HH:mm')

        this.filters.timeRange = [startAt, endAt]
      }

      if (dateValue === null) this.filters.timeRange = ''

      this.activeFilter = dateValue

      this.updateFilter()
    },
    changeAssignedUsers(payload) {
      this.assignedUsers = payload

      if (!payload.length) {
        this.contacts = []
        this.filters.contacts = ''
        this.updateFilter()
        return
      }

      this.$nextTick(() => {
        this.getAssignedUsersContacts()
      })
    },
    getAssignedUsersContacts() {
      const payload = {
        filters: {
          assignedUsers: this.assignedUsers,
        },
        customFields: this.$fields.CONTACTS_SEARCH,
      }
      this.$store.dispatch(`contacts/${GET_USER_CONTACTS}`, payload)
        .then(res => {
          this.contacts = res.data.items
          this.$nextTick(() => {
            this.updateFilter()
          })
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
    getFunnels() {
      this.$store.dispatch(`funnelMain/${GET_FUNNELS}`)
        .then(res => {
          this.funnels = res
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
  },
}
</script>

<style scoped>

</style>
