<template>
  <b-card>
    <b-tabs pills>
      <b-tab
        v-for="(tab, index) in tabs"
        :key="`setting_tab_${index}`"
        lazy
      >
        <template #title>
          <span>{{ $t(`templates.${tab.title}`) }}</span>
        </template>
        <component
          :is="tab.component"
          :ref="tab.ref"
          @open-modal="openModal"
        />
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import { BTabs, BTab, VBTooltip } from 'bootstrap-vue'
import { OPEN_MODAL } from '@/@constants/mutations'
import Sms from '../../views/core/admin/templatesTabs/Sms.vue'
import Email from '../../views/core/admin/templatesTabs/Email.vue'
import EmailFooter from '../../views/core/admin/templatesTabs/EmailFooter.vue'

export default {
  name: 'Templates',
  components: {
    sms: Sms,
    email: Email,
    email_footer: EmailFooter,
    BTabs,
    BTab,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data: () => ({
    tabs: [
      {
        title: 'SmsTemplates', ref: 'templates_sms', component: 'sms',
      },
      {
        title: 'EmailTemplates', ref: 'templates_email', component: 'email',
      },
      {
        title: 'EmailFooterTemplates', ref: 'templates_email_footer', component: 'email_footer',
      },
    ],
  }),
  methods: {
    openModal(modalType = 'shortcodes', modalTitle = this.$i18n.t('Shortcodes')) {
      this.$root.$emit('bv::hide::popover')
      this.$store.commit(`modal/${OPEN_MODAL}`,
        {
          modalType,
          modalTitle,
        })
    },
  },
}
</script>

<style scoped>

</style>
