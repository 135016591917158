<template>
  <validation-observer ref="offer-to-order">
    <div>
      <b-form-group>
        <b-form-checkbox :disabled="loadLocalOrderNumber"
                         variant="primary"
                         :checked="orderNumber === null || orderNumber === ''"
                         @input="changeAutoGenerateNumber"
        >
          {{ $t('GenerateNumber') }}
        </b-form-checkbox>
      </b-form-group>

      <b-form-group v-if="localOrderNumber && (orderNumber === null || orderNumber === '')"
                    :label="$t('contact.addressNumber') + ' ' + $tc('timeline.ContactThreadOrder', 2)"
      >
        <b-form-input
          readonly
          :value="localOrderNumber"
        />
      </b-form-group>
      <b-form-group v-else
                    :label="$t('contact.addressNumber') + ' ' + $tc('timeline.ContactThreadOrder', 2)"
      >
        <b-form-input
          v-model="orderNumber"
          :state="isNumberTaken ? false : null"
          @blur="checkNumber"
        />
      </b-form-group>
      <div v-if="isNumberTaken">
        <b-alert show
                 variant="danger"
        >
          <div class="alert-body"
               v-html="$tc('OrderNumberAlreadyTaken', 1, { number: orderNumber })"
          />
        </b-alert>
      </div>

      <b-row>
        <b-col sm="12"
               md="6"
        >
          <sw-select :name="$t('OrderDate')">
            <sw-select-fpr
              :is-empty="orderDate.date"
              @clear="orderDate = ''"
            >
              <flat-pickr
                v-model="orderDate"
                :config="{ locale: getCalendarLocale($i18n.locale) }"
                class="form-control"
              />
            </sw-select-fpr>
          </sw-select>
        </b-col>

        <b-col v-if="!checkRequiredHostnameFragments(['hydrop'], true)"
               sm="12"
               md="6"
        >
          <sw-select :name="$t('DeliveryDate')">
            <sw-select-fpr
              :is-empty="getDeliverDate"
              @clear="getDeliverDate = null"
            >
              <flat-pickr
                v-model="getDeliverDate"
                :config="{ locale: getCalendarLocale($i18n.locale), mode: 'range' }"
                class="form-control"
              />
            </sw-select-fpr>
          </sw-select>
        </b-col>

        <b-col v-if="!checkRequiredHostnameFragments(['hydrop'], true)"
               sm="12"
        >
          <b-form-group :label="$t('Note')">
            <b-form-textarea
              id="report-content"
              v-model="orderNote"
              rows="3"
              no-resize
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!--      <div>-->
      <!--        <h4 class="mb-1">-->
      <!--          {{ $t('MarketingPlan') }}-->
      <!--        </h4>-->

      <!--        <b-form-checkbox-->
      <!--          v-model="sellerCommissionEnabled"-->
      <!--          v-b-tooltip.top-->
      <!--          disabled-->
      <!--          title="W przygotowaniu"-->
      <!--          class="custom-control-primary mb-50"-->
      <!--          variant="primary"-->
      <!--          switch-->
      <!--        >-->
      <!--          {{ $t('CalculateUserCommission') }}-->
      <!--        </b-form-checkbox>-->

      <sw-select
        v-if="duplicateMode"
        :name="$t('Seller')"
      >
        <v-select
          key="offertoorderseller"
          v-model="seller"
          :options="threadAssignedUsers"
          label="firstName"
          :filterable="false"
          @search="loadSellers"
        >
          <template #no-options>
            {{ $t('NoData') }}
          </template>
          <template #option="item">
            <avatar :user="item" />
          </template>

          <template #selected-option="item">
            <avatar :user="item" />
          </template>
        </v-select>
      </sw-select>

      <!--        <b-form-checkbox-->
      <!--          v-model="sellerCommissionParentEnabled"-->
      <!--          v-b-tooltip.top-->
      <!--          disabled-->
      <!--          title="W przygotowaniu"-->
      <!--          class="custom-control-primary mt-1"-->
      <!--          variant="primary"-->
      <!--          switch-->
      <!--        >-->
      <!--          {{ $t('ChargeParentUserCommission') }}-->
      <!--        </b-form-checkbox>-->
      <!--      </div>-->

      <div>

        <div v-if="contact">
          <b-form-radio
            v-model="deliveryAddressType"
            value="CONTACT_ADDRESS"
            @change="selectedAddress = addresses[0].id"
          >
            {{ $t('ContactDeliveryAddress') }} ({{ contact.firstName }} {{ contact.lastName }})
          </b-form-radio>
          <b-alert
            v-if="!isPrimaryCopied || !isCorrespondenceCopied"
            show
            variant="primary"
          >
            <div class="alert-body">
              <div class="d-flex flex-column">
                <div class="mb-25 font-weight-bolder">
                  {{ $t('UsebasicContAddressesesesasDelivereddress') }}
                </div>
                <div>
                  <b-button
                    variant="outline-primary"
                    size="sm"
                    class="mr-25"
                    :disabled="isPrimaryCopied"
                    @click="copyAddress('default')"
                  >
                    {{ $t('PrimaryAddress') }}
                  </b-button>
                  <b-button
                    variant="outline-primary"
                    size="sm"
                    :disabled="isCorrespondenceCopied"
                    @click="copyAddress('correspondence')"
                  >
                    {{ $t('AddressForCorrespondence') }}
                  </b-button>
                </div>
              </div>
            </div>
          </b-alert>
          <div
            v-if="addresses.length"
            :class="{ 'hidden-content': !showAllAddresses && addresses.length > 4}"
            class="mt-50"
          >
            <transition-group
              tag="b-row"
              name="fade"
            >
              <b-col
                v-for="(address, index) in visibleAddresses"
                :key="`address_${index}`"
                sm="6"
                class="mb-50"
                @click="selectedAddress = address.id"
              >
                <div
                  v-b-tooltip
                  class="border p-1 rounded cursor-pointer d-flex justify-content-between"
                  :class="{ 'border-primary': address.id === selectedAddress }"
                  :title="$t(typeLabel(address.type))"
                >
                  <div>
                    <feather-icon
                      :icon="typeIcon(address.type)"
                      :class="{ 'text-primary': address.id === selectedAddress }"
                    />
                    {{ formatAddress(address) }}
                  </div>
                  <span :class="{ 'text-primary': address.id === selectedAddress }">{{ address.id === selectedAddress ? $t('Selected') : $t('Change') }}</span>
                </div>
              </b-col>
            </transition-group>
          </div>
          <div
            v-else
            class="text-center py-1 text-primary font-weight-bold"
          >
            <feather-icon
              size="17"
              icon="XCircleIcon"
            />
            {{ $t('NoData') }}
          </div>
          <div
            v-if="addresses.length > 4"
            class="text-center text-primary cursor-pointer"
            @click="showAllAddresses = !showAllAddresses"
          >
            {{ showAllAddresses ? $t('ShowLess') : $t('ShowMore') }}
          </div>
        </div>
        <div class="mt-1">
          <b-form-radio
            v-model="deliveryAddressType"
            value="OTHER_ADDRESS"
            @change="selectedAddress = ''"
          >
            {{ $t('OtherDeliveryAddress') }}
          </b-form-radio>
          <b-row class="mt-50">
            <b-col
              md="6"
              lg="4"
            >
              <b-form-group :label="$t('Country')">
                <b-form-input
                  id="basicInput"
                  v-model="otherAddress.country"
                  :disabled="deliveryAddressType === 'CONTACT_ADDRESS'"
                />
              </b-form-group>
            </b-col>

            <b-col
              md="6"
              lg="4"
            >
              <b-form-group :label="$t('City')">
                <b-form-input
                  id="basicInput"
                  v-model="otherAddress.city"
                  :disabled="deliveryAddressType === 'CONTACT_ADDRESS'"
                />
              </b-form-group>
            </b-col>

            <b-col
              md="6"
              lg="4"
            >
              <b-form-group :label="$t('contact.postalCode')">
                <b-form-input
                  id="basicInput"
                  v-model="otherAddress.postalCode"
                  class="form-control"
                  :disabled="deliveryAddressType === 'CONTACT_ADDRESS'"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <b-form-group :label="$t('Street')">
                <b-form-input
                  id="basicInput"
                  v-model="otherAddress.street"
                  :disabled="deliveryAddressType === 'CONTACT_ADDRESS'"
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group :label="$t('BuildingNumber')">
                <b-form-input
                  id="basicInput"
                  v-model="otherAddress.number"
                  :disabled="deliveryAddressType === 'CONTACT_ADDRESS'"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>

    <!-- Payments -->
    <div>
      <div
        v-if="cartPayments.length"
        class="pt-1"
      >
        <!--        <b-alert-->
        <!--          show-->
        <!--          variant="primary"-->
        <!--        >-->
        <!--          <div class="p-50">-->
        <!--            {{ $t('AdvancePaymentWorksOnNetPrice') }}-->
        <!--            <div>-->
        <!--              {{ $t('offer.NettoPrice') }}: <b>{{ totalPrice | priceFormat }}</b> PLN-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </b-alert>-->
        <b-row class="d-flex flex-row justify-content-center">
          <b-col
            md="12"
            class="mb-25 position-relative"
          >
            <div>
              <validation-provider
                v-slot="{ errors }"
                :name="$t('Offer.Price')"
                rules="required"
              >
                <b-form-group :state="errors.length > 0 ? false:null">
                  <sw-select :name="$t('SpecifyMethodOfPayment')">
                    <v-select
                      v-model="paymentType"
                      :state="errors.length > 0 ? false:null"
                      :options="[
                        { value: 'BANK_INSTALLMENT', label: 'bank_installment' },
                        { value: 'INSTALLMENT', label: 'installment' },
                        { value: 'TRANSFER', label: 'transfer' },
                        { value: 'CASH', label: 'cash' },
                      ]"
                      :reduce="item => item.value"
                    >
                      <template #no-options>
                        {{ $t('NoOptions') }}
                      </template>

                      <template #option="{ label }">
                        {{ $t(label) }}
                      </template>

                      <template #selected-option="{ label }">
                        {{ $t(label) }}
                      </template>
                    </v-select>
                  </sw-select>
                </b-form-group>
              </validation-provider>
            </div>

            <div class="mt-1">
              <div class="d-flex justify-content-between align-items-center pr-50">
                <h4>{{ $t('Costs.AmountToPay') }}</h4>
              </div>

              <div class="d-flex flex-column flex-md-row justify-content-between align-content-center">
                <div class="flex-grow-1 mr-50">
                  <!-- Payment schedule - price -->
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Offer.Price')"
                    rules="required"
                  >
                    <b-form-group
                      class="pb-25"
                      :state="errors.length > 0 ? false:null"
                    >
                      <cleave
                        v-model="cartPayments[totalIndex].value"
                        :state="errors.length > 0 ? false:null"
                        :options="cleaveOptions.number"
                        class="form-control"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </div>

                <div class="flex-grow-1 mr-50">
                  <!-- Payment schedule - term -->
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('TermTermOffer.Price')"
                    rules="required"
                  >
                    <b-form-group
                      class="pb-25"
                      :state="errors.length > 0 ? false:null"
                    >
                      <flat-pickr
                        v-model="cartPayments[totalIndex].date"
                        :placeholder="$t('Term')"
                        class="form-control"
                        :state="errors.length > 0 ? false:null"
                        :config="{ locale: getCalendarLocale($i18n.locale), altFormat: 'd-m-Y', altInput: true }"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </div>
              </div>

              <div class="d-flex justify-content-between align-items-center pr-50">
                <h4>{{ $t('AdvancePayment') }}</h4>

                <b-badge :variant="restToBeWritten.toFixed(2) < 0 ? 'light-danger' : (restToBeWritten.toFixed(2) >0 ? 'light-primary' : 'light-success')">
                  {{ $t('RemainsToBeWrittenOut') }}: {{ restToBeWritten | priceFormat }} {{ settings.defaultCurrenct || 'PLN' }}
                </b-badge>
              </div>

              <div
                v-if="cartPayments[advanceIndex]"
                class="d-flex flex-column flex-md-row justify-content-between align-content-center"
              >
                <div
                  class="flex-grow-1 mr-50"
                  style="max-width: 30px"
                >
                  <b-form-checkbox
                    v-model="cartPayments[advanceIndex].isUsed"
                    class="mt-75"
                    variant="primary"
                    @change="e => { if (!e) { cartPayments[advanceIndex].value = 0 } }"
                  />
                </div>
                <div class="flex-grow-1 mr-50">
                  <!-- Payment schedule - price -->
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Offer.Price')"
                    rules="required"
                  >
                    <b-form-group
                      class="pb-25"
                      :state="errors.length > 0 ? false:null"
                    >
                      <cleave
                        v-model="cartPayments[advanceIndex].value"
                        :state="errors.length > 0 ? false:null"
                        :disabled="!cartPayments[advanceIndex].isUsed"
                        :options="cleaveOptions.number"
                        class="form-control"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </div>

                <div class="flex-grow-1 mr-50">
                  <!-- Payment schedule - term -->
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('TermTermOffer.Price')"
                    rules="required"
                  >
                    <b-form-group
                      class="pb-25"
                      :state="errors.length > 0 ? false:null"
                    >
                      <flat-pickr
                        v-model="cartPayments[advanceIndex].date"
                        :placeholder="$t('Term')"
                        class="form-control"
                        :disabled="!cartPayments[advanceIndex].isUsed"
                        :state="errors.length > 0 ? false:null"
                        :config="{ locale: getCalendarLocale($i18n.locale), altFormat: 'd-m-Y', altInput: true }"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </div>
              </div>
            </div>

            <div
              v-if="paymentType === 'INSTALLMENT'"
              class="mt-1"
            >
              <h4>{{ $t('installment') }}</h4>

              <div class="d-flex align-items-end">
                <div>
                  <label for="SelectNumberOfInstallments">{{ $t('SelectNumberOfInstallments') }}</label>
                  <b-form-input
                    id="SelectNumberOfInstallments"
                    v-model="installmentsCount"
                    type="number"
                    :formatter="item => Math.abs(item) > 25 ? 25 : Math.abs(item)"
                    :disabled="paymentType !== 'INSTALLMENT'"
                  />
                </div>
                <b-button
                  variant="primary"
                  class="ml-25"
                  :disabled="!installmentsCount"
                  @click="calculateInstallments"
                >
                  {{ $t('Calculate') }}
                </b-button>
              </div>

              <!--    START::Specify the net commission amount    -->
              <div>
                <b-row v-if="installmentsCount && cartPayments.filter(p => p.type === 'INSTALLMENT').length">
                  <b-col
                    v-for="(installment, i) of cartPayments.filter(p => p.type === 'INSTALLMENT')"
                    :key="`installment_${i}`"
                    sm="12"
                    md="6"
                    class="mt-25"
                  >

                    <label class="mb-50">{{ i + 1 }}. {{ $t('Installment') }}</label>
                    <div class="d-flex align-items-start">
                      <validation-provider
                        v-slot="{ errors }"
                        :name="(i + 1) + '. ' + $t('Offer.Price') + ' ' + $t('installment').toLowerCase()"
                        class="w-100 mr-25"
                        rules="required"
                      >
                        <b-form-group :state="errors.length > 0 ? false:null">
                          <cleave
                            v-model="installment.value"
                            :options="cleaveOptions.number"
                            class="form-control"
                            :state="errors.length > 0 ? false:null"
                          />
                        </b-form-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>

                      <validation-provider
                        v-slot="{ errors }"
                        :name="(i + 1) + '. ' + $t('Term') + ' ' + $t('installment').toLowerCase()"
                        rules="required"
                      >
                        <b-form-group :state="errors.length > 0 ? false:null">
                          <sw-select
                            class="mt-n25"
                            style="flex: 1 0 8rem"
                          >
                            <sw-select-fpr>
                              <flat-pickr
                                v-model="installment.date"
                                class="form-control"
                                :config="{ locale: getCalendarLocale($i18n.locale), altFormat: 'd-m-Y', altInput: true }"
                                :placeholder="$t('Term')"
                                :state="errors.length > 0 ? false:null"
                              />
                            </sw-select-fpr>
                          </sw-select>
                        </b-form-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </div>
                  </b-col>
                </b-row>

                <b-col
                  v-else
                  sm="12"
                  class="text-center pt-1 text-primary font-weight-bold"
                >
                  <feather-icon
                    size="17"
                    icon="XCircleIcon"
                  />
                  {{ $t('ThereAreNoInstallmentsToDisplay') }}
                </b-col>
              </div>
              <!--    END::Specify the net commission amount    -->
            </div>
          </b-col>
        </b-row>
      </div>
    </div>

    <b-button
      :disabled="loading || isDisable"
      variant="primary"
      size="sm"
      @click="convertProcess"
    >
      {{ $t('Save') }}
    </b-button>
  </validation-observer>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import Cleave from 'vue-cleave-component'
import vSelect from 'vue-select'
import axiosIns from '@/libs/axios'
import * as fields from '@/@constants/fields'
import moment from 'moment'
import { grossPrice, netPriceAfterCartDiscount } from '@/helpers/cartProductPrices'
import { VBTooltip } from 'bootstrap-vue'
import { USERS_SEARCH } from '@/@constants/fields'
import { mapGetters } from 'vuex'

export default {
  name: 'OfferToOrder',
  components: {
    vSelect, ValidationObserver, ValidationProvider, Cleave, flatPickr,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    offer: {
      type: Object,
      default: null,
    },
    duplicateMode: {
      default: false,
      required: false,
      type: Boolean,
    },
  },
  data: () => ({
    loading: false,
    contact: null,
    thread: null,
    orderDate: '',
    orderNote: '',
    loadLocalOrderNumber: false,
    orderNumber: null,
    localOrderNumber: null,
    isNumberTaken: false,

    paymentType: '',
    installmentsCount: 0,
    installments: [],
    cartPayments: [],
    cleaveOptions: {
      number: {
        delimiter: ' ',
        numeral: true,
        blocks: [3, 3, 3],
      },
    },

    sellerCommissionEnabled: false,
    seller: null,
    sellerCommissionParentEnabled: false,

    deliveryAddressType: 'CONTACT_ADDRESS',
    threadAssignedUsers: [],
    delivery: '',
    showAllAddresses: false,
    selectedAddress: '',
    deliveryDateFrom: '',
    deliveryDateTo: '',
    addresses: [],
    otherAddress: {
      city: '',
      country: '',
      number: '',
      postalCode: '',
      street: '',
    },
  }),
  computed: {
    ...mapGetters({
      settings: 'system/getSettings',
    }),
    getDeliverDate: {
      get() {
        return this.deliveryDateFrom || this.deliveryDateTo
          ? [this.deliveryDateFrom, this.deliveryDateTo]
          : ''
      },
      set(value) {
        if (value) {
          [this.deliveryDateFrom, this.deliveryDateTo] = value.dateRange(false)
        } else {
          [this.deliveryDateFrom, this.deliveryDateTo] = [null, null]
        }
      },
    },
    isDisable() {
      if (!this.paymentType) return true
      // if (!this.seller) return true
      if (this.deliveryAddressType === 'CONTACT_ADDRESS' && !this.selectedAddress) return true
      if (this.paymentType === 'INSTALLMENT' && !this.restToBeWritten.toFixed(2).includes('0.00')) return true

      return false
    },
    totalGrossPrice() {
      if (!this.offer.contactThreadCartBox?.contactThreadCartBoxProducts?.length) return 0

      return this.offer.contactThreadCartBox.contactThreadCartBoxProducts.reduce((a, b) => a + (b.priceGrossWithAllDiscount * b.amount), 0)
    },
    totalPrice() {
      if (!this.offer.contactThreadCartBox?.contactThreadCartBoxProducts?.length) return 0

      return this.offer.contactThreadCartBox.contactThreadCartBoxProducts.reduce((a, b) => +a + (+b.priceWithAllDiscount * +b.amount), 0)
    },
    visibleAddresses() {
      return !this.showAllAddresses ? this.addresses.slice(0, 6) : this.addresses
    },
    totalProductsNetPriceAfterDiscount() {
      return this.offer.contactThreadCartBox?.contactThreadCartBoxProducts?.length ? this.offer.contactThreadCartBox.contactThreadCartBoxProducts.reduce((a, b) => +a + ((b.priceWithAllDiscount || 0) * (b.amount || 1)), 0) : 0
    },
    totalProductsGrossPriceAfterDiscount() {
      return this.offer.contactThreadCartBox?.contactThreadCartBoxProducts.length ? this.offer.contactThreadCartBox?.contactThreadCartBoxProducts.reduce((a, b) => +a + ((b.priceGrossWithAllDiscount || 0) * (b.amount || 1)), 0) : 0
    },
    totalScheduleItemsPrice() {
      return (this.cartPayments || []).reduce((a, b) => +a + +(b?.value || 0), 0)
    },
    restToBeWritten() {
      return this.totalProductsGrossPriceAfterDiscount - this.totalScheduleItemsPrice
    },

    advanceIndex() {
      return this.cartPayments.findIndex(p => p.type === 'ADVANCE')
    },
    totalIndex() {
      return this.cartPayments.findIndex(p => p.type === 'TOTAL_PAYMENT')
    },
    isPrimaryCopied() {
      return !!this.addresses.find(address => address.type === 'CONTACT_PRIMARY')
    },
    isCorrespondenceCopied() {
      return !!this.addresses.find(address => address.type === 'CONTACT_CORRESPONDENCE')
    },

  },
  /* eslint-disable */
  watch: {
    thread: {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue?.id !== oldValue?.id) this.assignThreadData(newValue)
      },
    },
    contact: {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue?.id !== oldValue?.id) this.assignContactData(newValue)
      },
    },
    'offer.contactThreadCartBox.contactThreadCartPaymentScheduleItems': {
      deep: true,
      handler(newValue) {
        if (newValue.length) {
          const installments = newValue.filter(i => i.type === 'INSTALLMENT').length
          this.$set(this, 'paymentType', installments ? 'INSTALLMENT' : this.offer?.contactThreadCartBox?.paymentType)
          this.$set(this, 'installmentsCount', installments)

          this.$nextTick(() => {
            const payments = newValue.map(i => ({ ...i, date: i.date?.date?.split(' ')[0] || i.date }))
            this.$set(this, 'cartPayments', payments)
          })
        }
      },
    },
  },
  async mounted() {
    if (this.offer?.contactThreadCartBox?.contactThread?.contact?.id) {
      const thread = this.offer.contactThreadCartBox.contactThread
      await this.loadContact(thread.contact.id, thread.id)
    }

    if (this.offer?.seller) {
      this.seller = this.offer?.seller
    }

    if (this.offer?.orderDate) {
      this.orderDate = moment(this.offer?.orderDate?.date, 'YYYY-MM-DD HH:ss').format('YYYY-MM-DD')
    }

    if (this.offer?.deliveryDateFrom) {
      this.deliveryDateFrom = moment(this.offer?.deliveryDateFrom?.date, 'YYYY-MM-DD HH:ss').format('YYYY-MM-DD')
    }
    if (this.offer?.deliveryDateTo) {
      this.deliveryDateTo = moment(this.offer?.deliveryDateTo?.date, 'YYYY-MM-DD HH:ss').format('YYYY-MM-DD')
    }

    const resp = await axiosIns.get('1/contacts/threads/carts/orders/getNextNumber')
    this.localOrderNumber = resp.data?.data?.nextNumber

    if (this.offer?.contactThreadCartBox?.contactThreadCartPaymentScheduleItems?.length) {
      const installments = this.offer?.contactThreadCartBox?.contactThreadCartPaymentScheduleItems.filter(i => i.type === 'INSTALLMENT').length
      this.$set(this, 'paymentType', this.offer?.contactThreadCartBox?.paymentType)
      this.$set(this, 'installmentsCount', installments)

      this.$nextTick(() => {
        const payments = this.offer?.contactThreadCartBox?.contactThreadCartPaymentScheduleItems.map(i => ({ ...i, value: String(i.value), date: i.date?.date?.split(' ')[0] || i.date }))
        const advanceIndex = payments.findIndex(e => e.type === 'ADVANCE')
        if (advanceIndex >= 0) payments[advanceIndex].isUsed = true
        else {
          payments.push({
            type: 'ADVANCE',
            value: 0,
            date: moment().format('YYYY-MM-DD'),
            isUsed: false,
          })
        }
        this.$set(this, 'cartPayments', payments)
      })
    } else {
      this.cartPayments = [{
        type: 'TOTAL_PAYMENT',
        value: this.restToBeWritten,
        date: moment().format('YYYY-MM-DD'),
      }, {
        type: 'ADVANCE',
        value: 0,
        date: moment().format('YYYY-MM-DD'),
        isUsed: false,
      }]
    }
  },
  methods: {
      async loadSellers(search, isLoading) {
          if (!search) {
              this.threadAssignedUsers = this.cart.contactThread?.assignedUsers || []
          } else {
              this.threadAssignedUsers = await this.selectSearch(search, isLoading, USERS_SEARCH, 'firstName,lastName', '1/users', 25)
          }
      },
      async checkNumber(e) {
          if (e) {
              const params = {
                  fields_load: this.$fields.BASE_ID,
                  eq_number: e,
              }
              const response = await axiosIns.get('1/contacts/threads/carts/orders', { params })
              this.isNumberTaken = !!response.data.data?.items?.length
          } else {
              this.isNumberTaken = false
          }
      },
      async changeAutoGenerateNumber(e) {
          this.loadLocalOrderNumber = true
          if (e) {
              const resp = await axiosIns.get('1/contacts/threads/carts/orders/getNextNumber')
              this.localOrderNumber = resp.data?.data?.nextNumber
          } else {
              this.localOrderNumber = null
          }

          this.loadLocalOrderNumber = false
      },
    typeIcon(type) {
      if (type === 'CONTACT_PRIMARY') return 'HomeIcon'
      if (type === 'CONTACT_CORRESPONDENCE') return 'MailIcon'

      return 'MapPinIcon'
    },
    typeLabel(type) {
      if (type === 'CONTACT_PRIMARY') return 'PrimaryAddress'
      if (type === 'CONTACT_CORRESPONDENCE') return 'AddressForCorrespondence'

      return ''
    },
    netPriceAfterCartDiscount,
    grossPrice,
    formatAddress(address) {
      return `
        ${address.street} ${address.number}, ${address.postalCode} ${address.city}
      `
    },
    calculateInstallments() {
      const count = this.installmentsCount
      const restPrice = this.totalProductsGrossPriceAfterDiscount - this.cartPayments[1].value
      const singlePriceByCount = parseFloat((restPrice / count).toFixed(2))
      const advancePayment = this.cartPayments[1]
      const installments = [advancePayment]

      // eslint-disable-next-line no-plusplus
      for (let i = 1; i <= count; i++) {
        installments.push({
          value: singlePriceByCount,
          type: 'INSTALLMENT',
          date: moment().add(i, 'month').format('YYYY-MM-DD'),
        })
      }

      this.$set(this, 'cartPayments', installments)
    },
    async loadContact(contactId, threadId) {
      try {
        const contact = await axiosIns.get(`1/contacts/${contactId}`, { params: { fields_load: this.$fields.SINGLE_CONTACT } })
        const thread = await axiosIns.get(`1/contacts/threads/${threadId}`, { params: { fields_load: fields.CONTACT_SINGLE_THREAD } })
        this.contact = contact.data.data.item
        this.thread = thread.data.data.item

        this.assignThreadData(thread.data.data.item)
        this.assignContactData(contact.data.data.item)
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    assignThreadData(thread = null) {
      if (!thread) return

      this.threadAssignedUsers = thread?.assignedUsers || []
    },
    assignContactData(contact = null) {
      if (!contact) return

      this.addresses = contact?.deliveryAddresses || []
      // eslint-disable-next-line prefer-destructuring
      if (this.addresses.length) this.selectedAddress = this.addresses[0]?.id

      if (this.offer.deliveryAddressCity && this.offer.deliveryAddressCountry && this.offer.deliveryAddressStreet) {
          const address = this.visibleAddresses.find(e => (
              e.country === this.offer.deliveryAddressCountry &&
              e.street === this.offer.deliveryAddressStreet &&
              e.city === this.offer.deliveryAddressCity &&
              e.postalCode === this.offer.deliveryAddressPostalCode
          ))?.id

          if (address) {
              this.selectedAddress = address
          } else {
              this.selectedAddress = ''
              this.deliveryAddressType = 'OTHER_ADDRESS'
              this.otherAddress.city = this.offer.deliveryAddressCity
              this.otherAddress.postalCode = this.offer.deliveryAddressPostalCode
              this.otherAddress.country = this.offer.deliveryAddressCountry
              this.otherAddress.street = this.offer.deliveryAddressStreet
              this.otherAddress.number = this.offer.deliveryAddressNumber
          }
      }
    },
    /* eslint-disable */
    async convertProcess() {
      const isValid = await this.$refs["offer-to-order"].validate()
      if (!isValid) return false

      this.loading = true
      const {
        paymentType,
        cartPayments,
        sellerCommissionEnabled,
        seller,
        sellerCommissionParentEnabled,
        deliveryAddressType,
        selectedAddress,
        otherAddress,
        orderDate,
        orderNote,
        deliveryDateFrom,
        deliveryDateTo,
      } = this

      const payload = {
        number: String(this.orderNumber || '') || null,
        contactThreadCartBox: this.offer?.contactThreadCartBox?.id,
        paymentType: paymentType,
        orderDate: orderDate,
        orderNote: orderNote || null,
        deliveryDateFrom: deliveryDateFrom || null,
        deliveryDateTo: deliveryDateTo || null,
        seller: this.getObjectId(seller),
        // seller: null,
        contactThreadCartPaymentScheduleItems: cartPayments
            .filter(payment => {
              if (payment.type === 'ADVANCE' && !payment?.isUsed) {
                return false
              }

              delete payment.isUsed
              return payment
            })
            .map(i => ({ ...i, value: parseFloat(i.value), date: i.date?.date || i.date })),
        sellerCommissionEnabled,
        sellerCommissionParentEnabled,
        deliveryAddress: this.getObjectId(selectedAddress),
      }

      if (deliveryAddressType === 'OTHER_ADDRESS') {
        const addressResponse = await axiosIns.post('1/addresses', otherAddress)

        payload.deliveryAddress = addressResponse?.data?.data?.items[0]?.id
      }

      try {
        const conversionResponse = this.duplicateMode
            ? await axiosIns.post(`1/contacts/threads/carts/orders/${this.offer.id}/doDuplicate`, payload)
            : await axiosIns.post('1/contacts/threads/carts/orders', payload)

        this.showToast('success', this.$i18n.t('success.contactUpdated'))
        this.$emit('close-modal')
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.loading = false
      }
    },
    copyAddress(type = 'default') {
      const contact = this.contact
      if (!contact) return

      const addressFields = {
        default: [
          'addressCountry',
          'addressCity',
          'addressPostalCode',
          'addressStreet',
          'addressNumber',
        ],
        correspondence: [
          'addressCorrespondenceCountry',
          'addressCorrespondenceCity',
          'addressCorrespondencePostalCode',
          'addressCorrespondenceStreet',
          'addressCorrespondenceNumber',
        ],
      }

      this.showAlert('error', this.$i18n.t('alerts.AreYouSure'))
          .then(async () => {
            let copiedAddress = { index: -1 }
            if (contact.isOrganization) {
              copiedAddress = { ...copiedAddress,
                country: contact.organization[addressFields[type][0]],
                city: contact.organization[addressFields[type][1]],
                postalCode: contact.organization[addressFields[type][2]],
                street: contact.organization[addressFields[type][3]],
                number: contact.organization[addressFields[type][4]],
              }
            } else {
              copiedAddress = { ...copiedAddress,
                country: contact[addressFields[type][0]],
                city: contact[addressFields[type][1]],
                postalCode: contact[addressFields[type][2]],
                street: contact[addressFields[type][3]],
                number: contact[addressFields[type][4]],
              }
            }

            copiedAddress.type = type === 'default' ? 'CONTACT_PRIMARY' : 'CONTACT_CORRESPONDENCE'

            const res = await axiosIns.post('1/addresses', copiedAddress)
            if (res?.data?.data?.items) {
              const ids = res?.data?.data?.items.map(item => item.id) || []

              this.addresses.forEach(address => {
                if (!ids.includes(address.id)) ids.push(address.id)
              })

              await axiosIns.patch('1/contacts', { id: contact.id, deliveryAddresses: ids })

              this.contact.deliveryAddresses.push({ ...copiedAddress, id: ids[0] })
            }
          })
    },
  },
}
</script>

<style scoped>

</style>
