import * as emailStatuses from '@/@constants/emailStatuses'

export default [
  {
    title: 'All',
    icon: 'MailIcon',
    status: emailStatuses.ALL,
    value: 0,
    route: 'email-route',
    variant: 'primary',
    text: 'text-primary',
  },
  {
    title: 'Inbox',
    icon: 'EyeIcon',
    status: 'INBOX',
    value: 0,
    route: 'email-route',
    variant: 'primary',
    text: 'text-primary',
  },
  {
    title: 'Sent',
    icon: 'SendIcon',
    status: 'INBOX.Sent',
    value: 0,
    route: 'email-route',
    variant: 'success',
    text: 'text-success',
  },
  {
    title: 'Spam',
    icon: 'InfoIcon',
    status: 'INBOX.Spam',
    value: 0,
    route: 'email-route',
    variant: 'warning',
    text: 'text-warning',
  },
  {
    title: 'gEmail.status.Drafts',
    icon: 'EditIcon',
    status: 'INBOX.Drafts',
    value: 0,
    route: 'email-route',
    variant: 'info',
    text: 'text-info',
  },
  {
    title: 'Trash',
    icon: 'TrashIcon',
    status: 'INBOX.Trash',
    value: 0,
    route: 'email-route',
    variant: 'danger',
    text: 'text-danger',
  },
]
