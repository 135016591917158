/* eslint-disable */
import categories from '@/store/offer/categories/categories'
import attributes from '@/store/offer/attributes/attributes'
import products from '@/store/offer/products/products'
import productPictures from '@/store/offer/products/productPictures'
import cart from '@/store/offer/cart/cart'
import offer from '@/store/offer/offers/offers'
import websites from '@/store/offer/website/website'
import payments from '@/store/offer/payments/payments'
import polls from '@/store/core/polls/polls'
import contractTemplates from '@/store/core/contractTemplates/contractTemplates'
import productTemplate from '@/store/offer/productTemplate/productTemplate'
import agreements from '@/store/offer/agreements/agreements'
import projects from '@/store/core/projects/projects'
import tasks from '@/store/core/projects/tasks'
import microTask from '@/store/core/projects/microTasks'

// eslint-disable-next-line import/prefer-default-export
export const offering = {
  categories,
  attributes,
  products,
  cart,
  offer,
  websites,
  polls,
  contractTemplates,
  payments,
  productTemplate,
  agreements,
  productPictures,
  projects,
  tasks,
  microTask,
}
