<template>
  <div class="media-list media-bordered">
    <log-card
      v-for="log in changeLogs"
      :key="log.updateDate"
      :bug-fixes="log.bugFixes"
      :new-futures="log.newFutures"
      :update-date="log.updateDate"
    />
  </div>
</template>

<script>
import LogCard from '@/pages/changeLogs/LogCard.vue'

export default {
  components: { LogCard },
  data: vm => ({
    changeLogs: [
      {
        updateDate: '23.05.2023 16:00',
        bugFixes: [
          { places: ['Rights'], label: 'Dodano listę uprawnień w systemie' },
          { places: ['Systemic'], label: 'Poprawka zapisywania filtrów w Local Storage' },
          { places: ['Contacts'], label: 'Poprawka interpretacji łącznej filtra tagów' },
        ],
      },
      {
        updateDate: '15.05.2023 16:00',
        newFutures: [
          { places: ['LoyaltyPlans'], label: 'Dodano id zamówienia do tabeli przyznanych punktów', requiredModule: 'loyaltyModule' },
          { places: ['LoyaltyPlans'], label: 'Dodano id zamówienia do tabeli przyznanych punktów', requiredModule: 'loyaltyModule' },
        ],
      },
      {
        updateDate: '15.05.2023 16:00',
        bugFixes: [
          { places: ['Orders'], label: 'Poprawka wyświetlanego statusu oferty wygasłej' },
          { places: ['OfferPages'], label: 'Dodano widok oferty wygasłej' },
          { places: ['Right'], label: 'Dodano nowe uprawnienia ograniczające widok zakładek w menu' },
          // { places: ['Systemic'], label: 'Nowy moduł integracji zgłoszeń z Facebook' },
          { places: ['Systemic'], label: 'Naprawa błędu odświeżania w filtrach' },
          { places: ['Applications'], label: 'Naprawa filtra daty wpływu zgłoszenia' },
        ],
      },
      {
        updateDate: '24.03.2023 16:00',
        newFutures: [
          { places: ['Payments'], label: 'Możliwość ręcznego dodania płatności' },
          { places: ['General'], label: 'Pomniejsze usprawnienia interfejsu' },
        ],
      },
      {
        updateDate: '21.03.2023 20:00',
        newFutures: [
          { places: ['Systemic'], label: 'Rozszerzono możliwości globalnej wyszukiwarki' },
          { places: ['Systemic'], label: 'Od teraz globalna wyszukiwarka jest dostępna także pod skrótem klawiszowy CONTROL + ENTER' },
          { places: ['Settings'], label: 'Automatyczny dobór odpowiedniej saturacji czcionki w globalnych ustawieniach motywu' },
          { places: ['Funnels'], label: 'Możliwość kopiowania lejków' },
          { places: ['Checklist'], label: 'Możliwość dodania podpowiedzi oraz filmiku w checklistach' },
          { places: ['Organizations'], label: 'Od teraz wraz z NIP wybiera się także region przy tworzeniu organizacji' },
          { places: ['General'], label: 'Na górnym pasku dodano przydatne skróty' },
          { places: ['General'], label: 'Pomniejsze usprawnienia interfejsu' },
          { places: ['Contacts', 'Offers', 'Orders'], label: 'Możliwość kopiowania adresów kontaktu do adresów dostaw' },
        ],
        bugFixes: [
          { places: ['Chat'], label: 'Naprawiono kolor czcionki w czacie' },
          { places: ['Settings'], label: 'Naprawiono ustawienia domyślne dla motywu' },
          { places: ['General'], label: 'Naprawiono pomniejsze usterki' },
          { places: ['Contacts'], label: 'Naprawiono błąd wczytywania listy użytkowników' },
          { places: ['Right'], label: 'Naprawiono błąd uprawnienia usuwania płatności' },
        ],
      },
      {
        updateDate: '14.03.2023 20:00',
        bugFixes: [
          { places: ['Funnels'], label: 'Naprawiono usuwanie wielu rekordów na widoku tabelarycznym' },
          { places: ['OfferPages'], label: 'Naprawiono pobieranie podglądu plików umów' },
          { places: ['Agreements'], label: 'Naprawiono błąd generowania umowy z poziomu widoku kontaktu' },
        ],
        newFutures: [
          { places: ['Systemic'], label: 'Dodano funkcję lokalnego zapisu wyświetlanych pól tabel' },
          { places: ['Agreements'], label: 'Dodano możliwość wyszukiwania po imieniu, nazwisku oraz adresie email kontaktu' },
          { places: ['Payments'], label: 'Dodano możliwość wyszukiwania po imieniu, nazwisku oraz adresie email kontaktu' },
          { places: ['Contacts'], label: 'Dodano nowe pole kontaktu "stanowisko"' },
          { places: ['Contacts'], label: 'Dodano pole "stanowisko" do krótkich kodów' },
        ],
      },
      {
        updateDate: '08.03.2023 20:00',
        bugFixes: [
          { places: ['Agreements'], label: 'Poprawka duplikacji umowy dodawanej z koszyka' },
          { places: ['Agreements'], label: 'Poprawka wyświetlania dodanego ręcznie pliku umowy' },
          { places: ['Contacts'], label: 'Poprawka wyświetlania organizacji' },
          { places: ['Systemic', 'Emails'], label: 'Poprawka wyświetlania szablonów wiadomości email' },
        ],
        newFutures: [
          { places: ['Contacts'], label: 'Imię i nazwisko kontaktu na karcie przeglądarki' },
        ],
      },
      {
        updateDate: '05.03.2023 15:00',
        bugFixes: [
          { places: ['Shortcodes', 'Payments'], label: 'Nowe pola płatności netto, brutto' },
          { places: ['Systemic'], label: 'Wyłączone zostało przechowywanie cache aplikacji' },
          { places: ['Agreements'], label: 'Dodano opcji usuwania umów' },
        ],
        newFutures: [],
      },
      {
        updateDate: '04.03.2023 11:00',
        bugFixes: [
          { places: ['Polls', 'Contacts'], label: 'Poprawa dodawania ankiet do kontaktu' },
        ],
        newFutures: [],
      },
      {
        updateDate: '27.02.2023 11:00',
        bugFixes: [
          { places: ['Agreements'], label: 'Poprawa wyświatlania dwóch umów w koszyku' },
          { places: ['Payments'], label: 'Zmiana opisu pola' },
          { places: ['Shortcodes'], label: 'Uzupełnienie brakujących pól' },
          { places: ['Threads'], label: 'Poprawa odświeżania widoków list po dodaniu zadania, notatki, wiadomości' },
          { places: ['Systemic'], label: 'Poprawki w tłumaczeniach' },
        ],
        newFutures: [],
      },
      {
        updateDate: '24.02.2023 20:00',
        bugFixes: [
          { places: ['Right'], label: 'Poprawka widoczności względem uprawnień' },
          { places: ['Polls'], label: 'Poprawka walidacji pól wymaganych' },
          { places: ['Polls'], label: 'Poprawka walidacji pól wymaganych' },
          { places: ['Systemic'], label: 'Poprawa kolorystyki widoku kalendarza' },
          { places: ['Agreements'], label: 'Poprawka dostępności podpisu autenti' },
        ],
        newFutures: [
          { places: ['Users'], label: 'Nowe pole "identyfikator użytkownika"' },
          { places: ['Users'], label: 'Możliwość przypisania planu marketingowego', requiredModule: 'marketingModule' },
          { places: ['Organizations'], label: 'Dodanie opcji płatnika Vat' },
          { places: ['Projects'], label: 'Nowy moduł', requiredModule: 'projectModule' },
          { places: ['Contacts', 'Settings'], label: 'Informacje dodatkowe kontaktu' },
          { places: ['Contacts', 'Shortcodes'], label: 'Nowe shortcody dotyczące informacji dodatkowych' },
        ],
      },
      {
        updateDate: '22.02.2023 11:00',
        bugFixes: [
          { places: ['Agreements'], label: 'Poprawa pól wymaganych dotyczących nazwy organizacji' },
        ],
        newFutures: [
          { places: ['Agreements'], label: 'Dodanie możliwości dodawania dokumentów pozasystemowych' },
        ],
      },
      {
        updateDate: '21.02.2023 16:00',
        bugFixes: [
          { places: ['Offers', 'Orders'], label: 'Brak płatności całkowitej podczas konwertowania oferty na zamówienie' },
          { places: ['Offers', 'Basket'], label: 'Blokada możliwości wysyłki wiadomości w przypadku braku uzupełnionego adresu email Kontaktu' },
          { places: ['ContractGenerator'], label: 'Poprawka generatora na przeglądarce Firefox' },
          { places: ['OfferPages'], label: 'Proprawa wyświetlania cen 0' },
        ],
        newFutures: [
          { places: ['MarketingPlans', 'Products'], label: 'Możliwość dodawania planu marketingowego do produktów', requiredModule: 'marketingModule' },
          { places: ['MarketingPlans', 'Shop'], label: 'Dodanie planu na widoku list oraz dodanie filtra produktów', requiredModule: 'marketingModule' },
          { places: ['MarketingPlans'], label: 'Możliwość tworzenia, edycji planu', requiredModule: 'marketingModule' },
          { places: ['General'], label: 'Dodanie funkcji monitorującej błędne połączenia' },
        ],
      },
      {
        updateDate: '19.02.2023 16:15',
        bugFixes: [
          { places: ['Right', 'LoyaltyPlans'], label: 'Proprawa opisów uprawnień', requiredModule: 'loyaltyModule' },
          { places: ['Right'], label: 'Błąd związany z uprawnieniem widoku listy zgłoszeń' },
          { places: ['Emails', 'Timeline'], label: 'Błąd Wyświetlania wiadomości email' },
          { places: ['LoyaltyPlans'], label: 'Proprawa długiej nazwy użytkownika tworzącego', requiredModule: 'loyaltyModule' },
        ],
        newFutures: [
          { places: ['Offers', 'Orders'], label: 'Możliwość dodania własnego pliku umowy' },
          { places: ['Systemic'], label: 'Filtracja zmian względem modułów i roli', requiredPermissions: [vm.$roles.FULL_ACCESS] },
          { places: ['General'], label: 'Dodanie funkcji walidacji i widoku nieaktualnej wersji systemu' },
          { places: ['General'], label: 'Dodanie funkcji monitorującej błędne połączenia' },
        ],
      },
      {
        updateDate: '17.02.2023 14:30',
        bugFixes: [
          { places: ['Basket', 'Agreements'], label: 'Błąd związany z wyświetlaniem sekcji umów' },
          { places: ['Right'], label: 'Błąd związany z uprawnieniem widoku listy zgłoszeń' },
          { places: ['Dashboard'], label: 'Błąd związany z uprawnieniami' },
        ],
        newFutures: [
          { places: ['Offers'], label: 'Dodanie funkcji edycji wiadomości email oferty przed wysłaniem' },
        ],
      },
    ],
  }),
}
</script>
