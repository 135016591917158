<template>
  <div
    class="d-flex flex-column"
    style="row-gap: 1.34rem"
  >
    <b-alert
      show
      variant="primary"
      class="px-75 py-1 mb-0 d-flex align-items-center justify-content-between"
    >
      <div class="d-flex align-items-center">
        <b-avatar variant="primary">
          <feather-icon icon="UsersIcon" />
        </b-avatar>

        <span class="d-flex flex-column ml-75">
          <strong>
            {{ $t('YourAudience') }}
          </strong>

          <span>
            {{ value.campaignTargets ? value.campaignTargets.length : 0 }}
            {{ $t('ContactsSettings').toLowerCase() }}
          </span>
        </span>
      </div>
    </b-alert>

    <div>
      <h5 class="text-primary">
        {{ $t('ContactStatuses') }}:
      </h5>

      <div
        v-if="value.targetContactStatuses && value.targetContactStatuses.length"
        class="d-flex flex-wrap"
        style="gap: .34rem"
      >
        <b-badge
          v-for="(status, i) in value.targetContactStatuses"
          :key="i"
          variant="light-primary"
        >
          {{ status.name }}
        </b-badge>
      </div>

      <div
        v-else
        class="text-center py-25 font-weight-bold text-primary"
      >
        <feather-icon
          size="17"
          icon="XCircleIcon"
        />

        {{ $t('NoData') }}
      </div>
    </div>

    <div>
      <h5 class="text-primary">
        {{ $t('ContactTags') }}:
      </h5>

      <div
        v-if="value.targetContactTypes && value.targetContactTypes.length"
        class="d-flex flex-wrap"
        style="gap: .34rem"
      >
        <b-badge
          v-for="(tag, i) in value.targetContactTypes"
          :key="i"
          variant="light-primary"
        >
          {{ tag.name }}
        </b-badge>
      </div>

      <div
        v-else
        class="text-center py-25 font-weight-bold text-primary"
      >
        <feather-icon
          size="17"
          icon="XCircleIcon"
        />

        {{ $t('NoData') }}
      </div>
    </div>

    <div>
      <h5 class="text-primary">
        {{ $t('FunnelsStatuses') }}:
      </h5>

      <div
        v-if="value.contactThreadFunnelTypePoints.length"
        class="d-flex flex-column"
        style="row-gap: .75rem"
      >
        <div
          class="alert-body d-flex flex-wrap"
          style="gap: .5rem"
        >
          <b-badge
            v-for="(status, j) in value.contactThreadFunnelTypePoints"
            :key="j"
            variant="light-primary"
          >
            {{ status.name }}
          </b-badge>
        </div>

        <!--        <b-alert-->
        <!--          v-for="(group, i) in funnelGroupList"-->
        <!--          :key="i"-->
        <!--          variant="primary"-->
        <!--          class="m-0"-->
        <!--          show-->
        <!--        >-->
        <!--          <h4-->
        <!--            class="alert-heading px-75 py-50 d-flex align-items-center justify-content-between"-->
        <!--            style="column-gap: .5rem"-->
        <!--          >-->
        <!--            <span-->
        <!--              class="d-flex align-items-center"-->
        <!--              style="column-gap: .5rem"-->
        <!--            >-->
        <!--              <feather-icon icon="FilterIcon" />-->

        <!--              {{ group.funnel.name }}-->
        <!--            </span>-->
        <!--          </h4>-->

        <!--          <div-->
        <!--            class="alert-body d-flex flex-wrap"-->
        <!--            style="gap: .5rem"-->
        <!--          >-->
        <!--            <b-badge-->
        <!--              v-for="(status, j) in group.statusList"-->
        <!--              :key="j"-->
        <!--              variant="primary"-->
        <!--            >-->
        <!--              {{ status.name }}-->
        <!--            </b-badge>-->
        <!--          </div>-->
        <!--        </b-alert>-->
      </div>

      <div
        v-else
        class="text-center py-25 font-weight-bold text-primary"
      >
        <feather-icon
          size="17"
          icon="XCircleIcon"
        />

        {{ $t('NoData') }}
      </div>
    </div>

    <div>
      <h5 class="text-primary">
        {{ $t('Recipients') }}:
      </h5>

      <b-tabs
        v-model="selectedTab"
        class="tabs-scroll"
        style="row-gap: .5rem"
        nav-class="btn-group btn-group-row-gap-0.34 bg-white shadow rounded-lg"
        content-class="px-1 pt-1 mb-0 b-card-body"
        pills
        no-nav-style
        active-nav-item-class="opacity-disabled"
      >
        <b-tab
          v-for="(tab) in recipientsTabs"
          :key="tab.key"
          :disabled="tab.disabled"
          title-item-class="btn btn-sm p-0"
          title-link-class="px-75 py-75 h-100 d-flex align-items-center justify-content-center"
        >
          <template #title>
            <feather-icon class="mr-50"
                          :icon="tab.icon"
            />
            {{ $t(tab.title) }}
          </template>
        </b-tab>
      </b-tabs>

      <sw-table
        table-id="fe60c132cae0470490057053da16e3a3"
        :show-columns="false"
        :show-search="false"
        :pagination="pagination"
        @change-pagination="Object.assign(pagination, $event)"
        @reload-content="loadTargets"
      >
        <template #table>
          <!--      Table      -->
          <b-table
            :items="items"
            :fields="fields"
            striped
            responsive
            :busy="isLoading"
            :style="{ minHeight: tableSize(items, 50) }"
            show-empty
          >
            <!--    Head    -->
            <template #head()="{ label }">
              {{ $t(`${label}`) }}
            </template>
            <!--    Status    -->
            <template #cell(status)="{ value }">
              <b-badge variant="light-primary">
                {{ value === 'QUEUE' ? $t('InQueue') : $tc(`gEmail.status.${value}`, 1) }}
              </b-badge>
            </template>
            <template #cell(email)="{ item }">
              <div v-if="item.contact && item.contact.email">
                {{ item.contact.email }}
              </div>
              <sw-icon
                v-else
                icon="MinusIcon"
              />
            </template>
            <template #cell(contact)="{ item }">
              <div v-if="item.contact">
                <avatar :user="item.contact" />
              </div>
              <sw-icon
                v-else
                icon="MinusIcon"
              />
            </template>

            <!--    Other    -->
            <!--    Other    -->
            <template #cell()="row">
              <table-default-cell
                :field="row.field"
                :value="row.value"
              />
            </template>

            <!--      Empty      -->
            <template #empty>
              <empty-content />
            </template>

            <!--      Table Busy      -->
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner
                  class="align-middle"
                  variant="primary"
                />
              </div>
            </template>
          </b-table>
        </template>
      </sw-table>
    </div>

    <b-overlay
      :show="isLoading"
      no-wrap
      spinner-variant="primary"
    />
  </div>
</template>

<script>
import { BTable, VBTooltip } from 'bootstrap-vue'
import { formatDate } from '@core/utils/filter'
import axiosIns from '@/libs/axios'
// import NotSend from './tabs/NotSend.vue'
// import Delivered from './tabs/Delivered.vue'
// import NotDelivered from './tabs/NotDelivered.vue'
//
export default {
  components: { BTable },
  directives: {
    'b-tooltip': VBTooltip,
  },

  props: {
    value: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    isLoading: false,
    selectedTab: 0,
    recipientsTabs: [
      {
        title: 'All',
        icon: 'GridIcon',
        key: '',
      },
      {
        title: 'InQueue',
        icon: 'ClockIcon',
        key: 'QUEUE',
      },
      {
        title: 'Sended',
        icon: 'SendIcon',
        key: 'SENT',
      },
      // {
      //   title: 'CompaniesStatus.NotDelivered',
      //   icon: 'AlertTriangleIcon',
      //   key: 'NOT_DELIVERED',
      // },
      // {
      //   title: 'CompaniesStatus.NotSend',
      //   icon: 'TruckIcon',
      //   key: 'NOT_SEND',
      // },
    ],

    pagination: {
      perPage: 25,
      currentPage: 1,
      totalRecords: 1,
    },

    fields: [
      { key: 'contact', label: 'Contact', visible: true },
      { key: 'email', label: 'Email', visible: true },
      { key: 'status', label: 'Status', visible: true },
    ],

    items: [],
  }),

  watch: {
    selectedTab() {
      this.pagination = {
        perPage: 25,
        currentPage: 1,
        totalRecords: 1,
      }
      this.loadTargets()
    },
  },

  mounted() {
    this.loadTargets()
  },

  methods: {
    formatDate,
    async loadTargets() {
      this.isLoading = true
      try {
        const params = {
          fields_load: 'CAMPAIGN_TARGETS',
          orderBy: 'id.desc',
          page: this.pagination.currentPage,
          limit: this.pagination.perPage,
        }

        const status = this.recipientsTabs[this.selectedTab].key
        if (status) {
          params.eq_status = status
        }

        const response = await axiosIns.get(`1/campaigns/${this.value.id}/targets`, { params })
        this.items = response.data.data?.items || []
        this.pagination.totalRecords = response.data.data?.totalItemCount
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
