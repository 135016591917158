<template>
  <b-card-text>
    <b-form>
      <validation-observer tag="form">
        <!--     Type     -->
        <b-row>
          <b-col
            sm="12"
            md="12"
            lg="12"
          >
            <sw-select :name="$t('offer.Create')">
              <v-select
                v-model.trim="newOffer.type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :placeholder="$t('offer.Create')"
                :options="offerTypes"
              >
                <template #option="{ label }">
                  {{ $t('offer.' + label) }}
                </template>
                <template #selected-option="{ label }">
                  {{ $t('offer.' + label) }}
                </template>
                <template #no-options>
                  {{ $t('NoOptions') }}
                </template>
              </v-select>
            </sw-select>
          </b-col>
        </b-row>

        <!--     Old Offer     -->
        <b-row>
          <b-col
            sm="12"
            md="12"
            lg="12"
          >
            <validation-provider
              v-slot="{ errors }"
              :name="$t('offer.Select')"
              rules="required"
            >
              <sw-select :name="$t('offer.Select')">
                <v-select
                  v-model.trim="newOffer.selectOld"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :placeholder="$t('offer.Select')"
                  :options="oldOffers"
                  label="name"
                  @search="loadOldOffers"
                  @option:selected="selectOldOffer"
                >
                  <template #option="{ label }">
                    {{ label }}
                  </template>
                  <template #selected-option="{ label }">
                    {{ label }}
                  </template>
                  <template #no-options>
                    {{ $t('NoOptions') }}
                  </template>
                </v-select>
              </sw-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>

        <!--     Name     -->
        <b-row>
          <b-col
            sm="12"
            md="12"
            lg="12"
          >
            <validation-provider
              v-slot="{ errors }"
              :name="$t('Name')"
              rules="required"
            >
              <b-form-group :label="$t('Name')">
                <b-form-input
                  v-model.trim="newOffer.name"
                  :placeholder="$t('Name')"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </validation-observer>
    </b-form>

    <div class="d-flex justify-content-end pt-1 mt-1 border-top">
      <b-button
        size="sm"
        variant="primary"
        @click="pushToServer"
      >
        {{ $t('Save') }}
      </b-button>
    </div>
  </b-card-text>
</template>

<script>
import { BCardText, BForm } from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'

export default {
  components: {
    BForm,
    BCardText,
    ValidationObserver,
    ValidationProvider,
    vSelect,
  },
  computed: {
    ...mapGetters({
      cart: 'cart/getCart',
    }),
  },
  data: () => ({
    required,

    newOffer: {
      type: '',
      selectOld: '',
      name: '',
    },

    // offerTypes: ['ContinueSketch', 'ContinueCart', 'ChooseFromExisting'],
    offerTypes: ['ContinueCart', 'ChooseFromExisting'],
    oldOffers: [],
  }),
  methods: {
    pushToServer() {},

    loadOldOffers() {},
    selectOldOffer() {},
  },
}
</script>
