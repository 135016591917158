<template>
  <div
    class="d-flex flex-column align-items-center"
    :style="posts.length ? 'row-gap: 2rem' : 'row-gap: 1rem'"
  >
    <h3 class="text-center mb-0">
      {{ $t('LastAdded') }}
    </h3>

    <template v-if="posts.length">
      <b-row
        class="w-100"
        style="row-gap: .8rem"
      >
        <router-link
          v-for="(post, index) in posts"
          :key="index"
          :to="{ name: 'newsPost', params: { id: post.id } }"
          class="col-lg-12 px-0 p-relative d-flex flex-row justify-content-start"
          style="height: 95px; column-gap: 1rem"
        >
          <div
            class="p-relative overflow-hidden d-flex justify-content-center align-items-center rounded-lg"
            style="width: 100vw; max-width: 95px;"
            :style="!post.picture || !post.picture.preview ? 'background: rgba(var(--theme-color-primary), .14);' : ''"
          >
            <img
              :src="post.picture ? `data:image/webp;base64,${post.picture.preview ? post.picture.preview : post.picture}` : system.logo || system.signet"
              class="img-fluid"
              :style="
                post.picture && post.picture.preview ? `
                width: 100%;
                height: 100%;

                object-fit: cover;
              ` : ''
              "
            >
          </div>

          <div
            class="d-flex flex-column justify-content-between py-50"
          >
            <span class="h6 mb-0">
              {{ post.subject }}
            </span>

            <div
              class="text-secondary font-small-2 d-flex flex-wrap align-items-center"
              style="gap: .24rem"
            >
              <avatar
                variant="light-primary"
                :user="post.author"
                :show-name="false"
                size="18"
              />

              {{ post.createdAt.date.split(' ')[0] }}
            </div>
          </div>
        </router-link>
      </b-row>

      <b-button
        variant="primary"
        :to="{ name: 'newsList', params: { author: author.id } }"
      >
        {{ $t('SeeMore') }}
      </b-button>
    </template>

    <div
      v-else
      class="text-center text-primary font-weight-bold"
    >
      <feather-icon
        size="17"
        icon="XCircleIcon"
      />

      {{ $t('NoPosts') }}
    </div>

    <b-overlay
      :show="isLoading"
      no-wrap
      spinner-variant="primary"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { GET_NEWS_ALL } from '@/@constants/mutations'

export default {
  props: {
    author: {
      type: [Object, null],
      required: true,
    },
  },

  data: () => ({
    isLoading: false,

    posts: [],
  }),

  computed: {
    ...mapGetters({
      system: 'system/getSettings',
      currentUser: 'auth/getCurrentUser',
    }),
  },

  mounted() {
    this.ini()
  },

  methods: {
    async ini() {
      if (this.author !== null) await this.loadPosts()
    },

    async loadPosts() {
      this.isLoading = true

      const filters = {
        authors: [this.author],
        max: 3,
      }

      this.$store.dispatch(`news/${GET_NEWS_ALL}`, { filters })
        .then(res => {
          this.posts = res.reverse()
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>
