var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-text',[_c('b-form',[_c('validation-observer',{attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"12","lg":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('offer.attribute.type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('sw-select',{attrs:{"name":_vm.$t('offer.attribute.type')}},[_c('v-select',{attrs:{"clearable":false,"options":_vm.attributeTypes,"placeholder":_vm.$t('offer.attribute.type'),"clear-search-on-blur":function () { return false }},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(_vm.$t(("offer.attribute.types." + (option.label))))+" ")]}},{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(_vm.$t(("offer.attribute.types." + (option.label))))+" ")]}},{key:"no-options",fn:function(ref){
var search = ref.search;
return [(search.length)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('NoData'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('TypeToSearch'))+" ")])]}}],null,true),model:{value:(_vm.newAttribute.type),callback:function ($$v) {_vm.$set(_vm.newAttribute, "type", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"newAttribute.type"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"12","md":"12","lg":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Name')}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('Name'),"state":errors.length > 0 ? false:null},model:{value:(_vm.newAttribute.translations[0].name),callback:function ($$v) {_vm.$set(_vm.newAttribute.translations[0], "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"newAttribute.translations[0].name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),(_vm.newAttribute.type === 'TEXT_ARRAY' || _vm.newAttribute.type === 'TEXT_MULTIPLE_ARRAY')?_c('b-col',{attrs:{"sm":"12","md":"12","lg":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Value')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Value')}},[_c('b-form-tags',{attrs:{"no-outer-focus":"","placeholder":_vm.$t('TagsPlaceholder')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var inputAttrs = ref.inputAttrs;
                  var inputHandlers = ref.inputHandlers;
                  var removeTag = ref.removeTag;
return [_c('b-input-group',{attrs:{"aria-controls":"my-custom-tags-list"}},[_c('input',_vm._g(_vm._b({staticClass:"form-control",attrs:{"placeholder":_vm.$t('NewValue')}},'input',inputAttrs,false),inputHandlers)),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"primary","disabled":!inputAttrs.value},on:{"click":function($event){_vm.addItem(inputAttrs.value); inputAttrs.value = ''}}},[_vm._v(" "+_vm._s(_vm.$t('Add'))+" ")])],1)],1),_c('div',{staticClass:"d-flex flex-wrap mb-0",attrs:{"id":"my-custom-tags-list"}},[(_vm.newAttribute.shopAttributeOptions && _vm.newAttribute.shopAttributeOptions.length)?_c('div',{staticClass:"d-flex flex-wrap mb-0"},_vm._l((_vm.newAttribute.shopAttributeOptions),function(tag){return _c('b-card',{key:tag,staticClass:"shadow-none border mt-1 mr-1 mb-0 flex-grow-1",attrs:{"id":("my-custom-tags-tag_" + (tag.replace(/\s/g, '_')) + "_"),"tag":"li","body-class":"py-1 pr-2 text-nowrap"}},[_c('strong',[_vm._v(_vm._s(JSON.parse(tag).translations[0].name))]),_c('b-button',{staticClass:"py-0",attrs:{"variant":"link","size":"sm","aria-controls":("my-custom-tags-tag_" + (tag.replace(/\s/g, '_')) + "_")},on:{"click":function($event){return removeTag(tag)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}})],1)],1)}),1):_vm._e()])]}}],null,true),model:{value:(_vm.newAttribute.shopAttributeOptions),callback:function ($$v) {_vm.$set(_vm.newAttribute, "shopAttributeOptions", $$v)},expression:"newAttribute.shopAttributeOptions"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,957092984)})],1):_vm._e()],1)],1)],1),_c('div',{staticClass:"d-flex justify-content-end pt-1 mt-1 border-top"},[_c('b-button',{attrs:{"size":"sm","variant":"primary"},on:{"click":_vm.saveAttribute}},[_vm._v(" "+_vm._s(_vm.$t('Save'))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }