<template>
  <sw-top-page-bar
    v-model="activeTab"
    is-tab-view
    :tab-view-list="tabList"
  >
    <template v-if="activeTab">
      <div
        v-if="activeTab.title === tabList[0].title"
        :key="0"
      >
        <shop />
      </div>

      <div
        v-if="activeTab.title === tabList[1].title"
        :key="1"
      >
        <category-list />
      </div>

      <div
        v-if="activeTab.title === tabList[2].title"
        :key="1"
      >
        <attribute-list />
      </div>
    </template>
  </sw-top-page-bar>
</template>

<script>
import Shop from '@/pages/Shop.vue'
import CategoryList from '@/pages/shop/CategoryList.vue'
import AttributeList from '@/pages/shop/AttributeList.vue'

export default {
  components: {
    Shop,
    CategoryList,
    AttributeList,
  },

  data: () => ({
    activeTab: null,
    tabList: [
      {
        title: 'Locals',
        icon: 'ShoppingCartIcon',
      },
      {
        title: 'offer.product.category',
        icon: 'ListIcon',
      },
      {
        title: 'offer.product.constantAttributes',
        icon: 'StarIcon',
      },
    ],
  }),
}
</script>
