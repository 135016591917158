<template>
  <div>
    <transition name="zoom-out">
      <component :is="tag"
                 v-if="!isFolderOpen"
                 key="folders"
      >
        <folders />
      </component>
      <!-- Folder documents -->
      <component :is="tag"
                 v-else
      >
        <folder-documents key="documents" />
      </component>
    </transition>
    <!-- folder documents -->
    <!--  Download Users Modal  -->
    <b-modal
      id="modal-download-users"
      title="Plik pobrany przez"
      ok-only
      :ok-title="$t('Share')"
      ok-variant="info"
      no-close-on-backdrop
    />
    <!--  Download Users Modal  -->
  </div>
</template>

<script>
import {
  VBTooltip, VBModal, VBPopover,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { OPEN_FOLDER } from '@/@constants/mutations'
import Folders from './components/documents/Folders.vue'
import FolderDocuments from './components/documents/FolderDocuments.vue'

export default {
  name: 'Documents',
  components: {
    FolderDocuments,
    Folders,
  },
  directives: {
    'b-popover': VBPopover,
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  props: {
    userDocuments: {
      required: false,
    },
    tag: {
      default: 'div',
      required: false,
    },
  },
  data: () => ({
    shareUsers: [],
    selectedUsers: [],
    selected: [],
    shareUserFields: ['name', 'lastName'],
    shareFolders: false,
  }),
  beforeDestroy() {
    this.$store.commit(`documents/${OPEN_FOLDER}`, { folder: null, files: [] })
  },
  computed: {
    ...mapGetters({
      isFolderOpen: 'documents/getOpenedFolder',
    }),
  },
  methods: {
  },
}
</script>

<style scoped>

</style>
