export default [
  {
    path: '/commissions',
    component: () => import('@/pages/commissions/List.vue'),
    name: 'commissions',
    meta: {
      autorize: true,
      pageTitle: 'Commissions',
    },
  },
]
