<template>
  <b-nav-item-dropdown
    id="dropdown-grouped"
    variant="link"
    class="dropdown-language"
    style="list-style: none"
    right
    @toggle="$root.$emit('bv::hide::popover')"
  >
    <template #button-content>
      <b-img
        :src="currentLocale.img"
        width="24px"
        :alt="currentLocale.locale"
        class="border-half-primary rounded"
      />
      <!--      <span class="ml-50 text-body">{{ currentLocale.name }}</span>-->
    </template>

    <b-dropdown-item
      v-for="localeObj in localesArr"
      :key="localeObj.locale"
      @click="setLocale(localeObj)"
    >
      <b-img
        :src="localeObj.img"
        height="14px"
        width="22px"
        :alt="localeObj.locale"
      />
      <span class="ml-50">{{ localeObj.name }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
/* eslint-disable */

import { BDropdownItem, BImg, BNavItemDropdown } from 'bootstrap-vue'
import { localize } from 'vee-validate'
import pl from 'vee-validate/dist/locale/pl.json'
import en from 'vee-validate/dist/locale/en.json'
import de from 'vee-validate/dist/locale/de.json'
import { mapGetters } from 'vuex'
import { EDIT_USER_LANG } from '@/@constants/mutations'

export default {
  name: 'SwLocale',
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
  },
  data: () => ({
    locales: {
      pl,
      en,
      de,
    },
  }),
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    currentLocale() {
      return this.localesArr.find(l => l.locale === this.$i18n.locale)
    },
    ...mapGetters({
      currentUser: 'auth/getCurrentUser',
    }),
  },
  mounted() {
    const user = typeof this.currentUser === 'string' ? JSON.parse(this.currentUser) : this.currentUser
    const userLocale = user.locale.split('_')[0]
    localize(userLocale, this.locales[userLocale])
    this.$i18n.locale = userLocale

    this.updateTitle()
  },
  methods: {
    updateTitle() {
      const currentTitle = this.$store.getters['app/getTitle']
      this.$store.dispatch('app/UPDATE_TITLE', {
        title: currentTitle,
        label: this.$i18n.t(`${currentTitle}`),
      })
    },
    setLocale(localeObj) {
      localize(localeObj.locale, this.locales[localeObj.locale])
      this.$i18n.locale = localeObj.locale

      // Change title locale
      this.updateTitle()
      const user = typeof this.currentUser === 'string' ? JSON.parse(this.currentUser) : this.currentUser
      let locale = 'en_uk'
      // eslint-disable-next-line default-case
      switch (localeObj.locale) {
        case 'en': locale = 'en_uk'; break
        case 'de': locale = 'de_de'; break
        case 'pl': locale = 'pl_pl'; break
      }
      // const locale = localeObj.locale === 'en' ? 'en_uk' : 'pl_pl'
      this.$store.dispatch(`users/${EDIT_USER_LANG}`, { id: user.id.toString(), locale })
        .then(() => {
          localStorage.setItem('user', JSON.stringify({ ...user, locale }))
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
  },
  setup() {
    /* eslint-disable global-require */
    const localesArr = [
      {
        locale: 'en',
        img: require('@/assets/images/flags/us.svg'),
        name: 'English',
      },
      {
        locale: 'pl',
        img: require('@/assets/images/flags/pl.svg'),
        name: 'Polski',
      },
      {
        locale: 'de',
        img: require('@/assets/images/flags/de.png'),
        name: 'Deutsch',
      },
    ]
    /* eslint-disable global-require */
    localize('pl', pl)

    return {
      localesArr,
    }
  },
}
</script>

<style>
  ::marker {
    content: unset !important;
  }
</style>
