<!--eslint-disable-->

<template>
  <div>
    <!--    Button: New Attribute    -->

    <div
      class="mb-1 d-flex flex-wrap"
      style="gap: .34rem"
    >
      <b-button
        variant="warning"
        size="sm"
        @click="$router.go(-1)"
      >
        <feather-icon icon="ArrowLeftIcon" />

        {{ $t('Return') }}
      </b-button>

      <b-button
        variant="primary"
        size="sm"
        @click="showOptionModal = true"
      >
        {{ $t('Add') }}
      </b-button>
    </div>

      <div v-if="pollOptions.length">
          <draggable v-model="pollOptions">
              <b-card
                      v-for="(reason, index) in pollOptions"
                      :key="`positive_${index}`"
                      no-body
                      class="p-75 cursor-pointer mb-1"
              >
                  <div
                          class="d-flex flex-wrap flex-column flex-md-row justify-content-md-between"
                          style="row-gap: .5rem"
                  >
                      <div
                              class="mb-0 d-flex align-items-center"
                              style="column-gap: .34rem"
                      >
                          <feather-icon
                                  icon="MoveIcon"
                                  class="mr-25"
                                  size="18"
                          />

                          <div>
                            <div>
                                <span
                                        v-b-tooltip.hover.left
                                        :title="reason.translations[0].name"
                                        class="h5"
                                >
                                {{ index + 1 }}. {{ reason.translations[0].name | truncate(26, '...') }}
                              </span>
                                <feather-icon
                                        v-if="reason.translations[0].description"
                                        v-b-tooltip.hover.left
                                        :title="reason.translations[0].description"
                                        icon="AlertCircleIcon" />
                            </div>
                              <div class="d-flex flex-wrap align-items-center">
                                  <b-badge variant="light-primary">{{ $t(`offer.attribute.types.${reason.type}`) }}</b-badge>

                                  <div v-if="reason.pollFieldOptions && reason.pollFieldOptions.length">
                                      <sw-icon icon="ChevronRightIcon" />
                                          <b-badge
                                                  v-for="(value, index) in reason.pollFieldOptions"
                                                  :key="`item_${reason.id}_${index}`"
                                                  class="mr-25 mt-25"
                                                  variant="primary"
                                          >
                                              {{ typeof value === 'string' ? JSON.parse(value).translations[0].name : value.translations[0].name }}
                                          </b-badge>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div>
                          <b-button
                                  variant="outline-warning"
                                  class="mr-25"
                                  size="sm"
                                  @click="openEditModal(reason, index)"
                          >
                              <feather-icon
                                      icon="EditIcon"
                                      class="mr-25"
                              />
                              <span>{{ $t('Edit') }}</span>
                          </b-button>

                          <b-button
                                  variant="outline-danger"
                                  class="mr-25"
                                  size="sm"
                                  @click="remove(reason, index)"
                          >
                              <feather-icon
                                      icon="TrashIcon"
                                      class="mr-25"
                              />
                              <span>{{ $t('Delete') }}</span>
                          </b-button>
                      </div>
                  </div>
              </b-card>
          </draggable>
      </div>
      <div
              v-else
              class="text-center py-1 font-weight-bold"
      >
          <feather-icon
                  size="17"
                  icon="XCircleIcon"
          />
          {{ $t('NoData') }}
      </div>

    <!--    Attribute list    -->
<!--    <b-row class="d-flex flex-row-reverse">-->
<!--      <b-col-->
<!--        sm="12"-->
<!--        md="12"-->
<!--        lg="12"-->
<!--      >-->
<!--        &lt;!&ndash;    Categories View    &ndash;&gt;-->
<!--        <div class="mt-1">-->
<!--          <b-card class="myTableOffer">-->
<!--            <b-table-->
<!--              :busy="loading"-->
<!--              :items="pollOptions"-->
<!--              :fields="fields"-->
<!--              show-empty-->
<!--              striped-->
<!--              responsive-->
<!--              hover-->
<!--              class="offerTable"-->
<!--            >-->
<!--              &lt;!&ndash;    Table Head    &ndash;&gt;-->
<!--              <template #head()="item">-->
<!--                <span class="text-nowrap">-->
<!--                  {{ $tc(item.field.label) | truncate(34, '...') }}-->
<!--                </span>-->
<!--              </template>-->

<!--              &lt;!&ndash;    Name    &ndash;&gt;-->
<!--              <template #cell(name)="{ item }">-->
<!--                <span-->
<!--                  v-b-tooltip.hover.left-->
<!--                  :title="item.translations[0].name"-->
<!--                  class="text-primary"-->
<!--                >-->
<!--                  {{ item.translations[0].name | truncate(26, '...') }}-->
<!--                </span>-->
<!--              </template>-->
<!--              &lt;!&ndash;    Name    &ndash;&gt;-->
<!--              <template #cell(description)="{ item }">-->
<!--                <span-->
<!--                  v-b-tooltip.hover.left-->
<!--                  :title="item.translations[0].description"-->
<!--                  class="text-primary"-->
<!--                >-->
<!--                  {{ item.translations[0].description | truncate(26, '...') }}-->
<!--                </span>-->
<!--              </template>-->
<!--              &lt;!&ndash; Type &ndash;&gt;-->
<!--              <template #cell(type)="{ value }">-->
<!--                {{ $t(`offer.attribute.types.${value}`) }}-->
<!--              </template>-->

<!--              <template #cell(pollFieldOptions)="{ item }">-->
<!--                <div-->
<!--                  v-if="item.pollFieldOptions && item.pollFieldOptions.length"-->
<!--                  class="d-flex flex-wrap"-->
<!--                >-->
<!--                  <b-badge-->
<!--                    v-for="(value, index) in item.pollFieldOptions"-->
<!--                    :key="`item_${item.id}_${index}`"-->
<!--                    class="mr-25 mt-25"-->
<!--                    variant="primary"-->
<!--                  >-->
<!--                    {{ typeof value === 'string' ? JSON.parse(value).translations[0].name : value.translations[0].name }}-->
<!--                  </b-badge>-->
<!--                </div>-->
<!--                <feather-icon-->
<!--                  v-else-->
<!--                  icon="MinusIcon"-->
<!--                />-->
<!--              </template>-->
<!--              &lt;!&ndash;    Table Action    &ndash;&gt;-->
<!--              <template #cell(action)="{ item, index }">-->
<!--                <b-dropdown-->
<!--                  variant="link"-->
<!--                  class="dropdown-icon-wrapper text-decoration-none"-->
<!--                  no-caret-->
<!--                >-->
<!--                  <template-->
<!--                    #button-content-->
<!--                    class="sr-only"-->
<!--                  >-->
<!--                    <feather-icon-->
<!--                      icon="MoreVerticalIcon"-->
<!--                      size="16"-->
<!--                      class="align-middle"-->
<!--                    />-->
<!--                  </template>-->

<!--                  &lt;!&ndash;    Action: Delete    &ndash;&gt;-->
<!--                  <b-dropdown-item @click="remove(item, index)">-->
<!--                    {{ $t('Delete') }}-->
<!--                  </b-dropdown-item>-->

<!--                  &lt;!&ndash; Action: Edit &ndash;&gt;-->
<!--                  <b-dropdown-item @click="openEditModal(item, index)">-->
<!--                    {{ $t('Edit') }}-->
<!--                  </b-dropdown-item>-->
<!--                </b-dropdown>-->
<!--              </template>-->

<!--              &lt;!&ndash;    Other    &ndash;&gt;-->
<!--                &lt;!&ndash;    Other    &ndash;&gt;-->
<!--                <template #cell()="row">-->
<!--                    <table-default-cell-->
<!--                            :field="row.field"-->
<!--                            :value="row.value"-->
<!--                    />-->
<!--                </template>-->

<!--                &lt;!&ndash;      Empty      &ndash;&gt;-->
<!--                <template #empty>-->
<!--                    <empty-content />-->
<!--                </template>-->

<!--                &lt;!&ndash;      Table Busy      &ndash;&gt;-->
<!--                <template #table-busy>-->
<!--                    <div class="text-center text-danger my-2">-->
<!--                        <b-spinner-->
<!--                                class="align-middle"-->
<!--                                variant="primary"-->
<!--                        />-->
<!--                    </div>-->
<!--                </template>-->
<!--            </b-table>-->
<!--          </b-card>-->
<!--        </div>-->
<!--      </b-col>-->
<!--    </b-row>-->

    <!--    Modal: Create/Edit Attribute     -->
    <b-modal
      v-model="showOptionModal"
      :title="$t('Field')"
      size="md"
      hide-footer
      no-close-on-backdrop
      @close="closeModal"
    >
      <new-poll-option-modal
        :old-poll-option="{...oldFields}"
        @saveOption="saveOption"
        @closeModal="closeModal"
      />
    </b-modal>

    <!-- Push to server btn -->
    <b-button
      variant="primary"
      :disabled="!pollOptions.length"
      @click="pushToServer"
    >
      {{ $t('Save') }}
    </b-button>
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'
import payloadKeysCutter from '@/helpers/keysCuter'
import NewPollOptionModal from '@/views/modals/NewSettingsPollOptionModal.vue'
import { DELETE_POLL_OPTION, GET_POLL_OPTIONS, SAVE_POLL_OPTION } from '@/@constants/mutations'
import draggable from 'vuedraggable'

export default {
  components: {
    draggable,
    NewPollOptionModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data: () => ({
    loading: false,
    oldFields: {},
    oldIndex: -1,
    showOptionModal: false,
    fields: [
      { key: 'name', label: 'Name' },
      { key: 'pollFieldOptions', label: 'Value' },
      { key: 'description', label: 'task.Description' },
      { key: 'type', label: 'task.Type' },
      { key: 'action', label: 'Action' },
    ],
    pollOptions: [],
  }),
  computed: {
    pollId() {
      return this.$route.params?.pollId
    },
  },
  watch: {
    '$route.params.pollId': {
      deep: true,
      handler() {
        this.getFromServer()
      },
    },
  },
  mounted() {
    this.getFromServer()
  },
  methods: {
    getFromServer() {
      this.loading = true
      if (this.checkRequiredModule('pollModule')) {
        this.$store.dispatch(`polls/${GET_POLL_OPTIONS}`, this.pollId)
          .then(res => {
            this.pollOptions = res.data.items
            this.loading = false
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            this.loading = false
          })
      }
    },
    pushToServer() {
      const items = JSON.parse(JSON.stringify(this.pollOptions))
      this.saveItems(items)
    },

    /// Save New Items ///
    saveItems(items) {
      this.loading = true
      items.forEach(item => {
        // eslint-disable-next-line
        if (item.type !== 'TEXT_ARRAY' && item.type !== 'TEXT_MULTIPLE_ARRAY' && item.type !== 'RADIAL') item.pollFieldOptions = []
        item.pollFieldOptions.forEach((option, index) => {
          if (typeof option === 'string') {
            // eslint-disable-next-line
            option = JSON.parse(option)
          }

          if (Array.isArray(option.translations) && option.translations.length) {
            option.translations.forEach(translation => {
              // eslint-disable-next-line
              translation.locale = 'pl_pl'
            })
          }
          // eslint-disable-next-line
          item.pollFieldOptions[index] = typeof option === 'string' ? JSON.parse(option) : option
        })
      })
      const preparedItems = payloadKeysCutter(items, ['poll', 'value'])

      this.$store.dispatch(`polls/${SAVE_POLL_OPTION}`, { pollId: this.pollId, options: preparedItems.map((e, i) => ({ ...e, position: i + 1 })) })
        .then(() => {
          this.showToast('success', this.$i18n.t('success.contactUpdated'))
          this.getFromServer()
        })
        .catch(err => {
          this.loading = false
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
    /// Open Edit Modal ///
    openEditModal(item, index = -1) {
      this.oldFields = JSON.parse(JSON.stringify(item))
      this.oldIndex = index
      this.showOptionModal = true
    },
    closeModal() {
      this.oldFields = {}
      this.oldIndex = -1
      this.showOptionModal = false
    },
    saveOption(item) {
      if (this.oldIndex === -1) this.pollOptions.push(item)
      else {
        this.$set(this.pollOptions, this.oldIndex, item)
      }
    },
    /*    Remove removes pollOptions    */
    remove(item, index) {
      if (item?.id) {
        this.showAlert('error', this.$i18n.t('alerts.AreYouSure'))
          .then(() => {
            this.deleteItemFromDatabase(item.id, index)
          })
      } else {
        this.pollOptions.splice(index, 1)
      }
    },
    deleteItemFromDatabase(id, index) {
      this.$store.dispatch(`polls/${DELETE_POLL_OPTION}`, { pollId: this.pollId, optionId: id })
        .then(() => {
          this.pollOptions.splice(index, 1)
          this.showToast('success', this.$i18n.t('RemovedSuccessfully'))
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
  },
}
</script>
