const status = () => {
  const statusList = [
    '@',
    'Sent',
    'NotPosted',
    'Delivered',
    'Undelivered',
  ]

  return statusList
}

export default status
