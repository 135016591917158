<template>
  <b-avatar
    v-b-tooltip
    class="avatar-stats"
    variant="light-primary"
    icon
    :title="title"
    size="3rem"
    :badge="value.toString()"
    badge-variant="primary"
  >
    <feather-icon
      size="20"
      :icon="icon"
    />
  </b-avatar>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'

export default {
  name: 'TimelineStatsCard',
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    title: {
      required: true,
      type: String,
      default: '',
    },
    value: {
      required: true,
      type: [String, Number],
      default: '',
    },
    icon: {
      required: true,
      type: String,
      default: 'UserIcon',
    },
  },
}
</script>

<style lang="scss">
  .avatar-stats {
    .b-avatar-badge {
      bottom: -6px !important;
      right: -6px !important;
    }
  }
</style>
