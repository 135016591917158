export default function useSwUnits() {
  /* ------------ VAT Rates ------------ */
  const vatUnits = [
    '0',
    '8',
    '23',
  ]
  /* ------------ Size units ------------ */
  const sizeUnits = [
    'mm',
    'mm2',
    'mm3',
    'cm',
    'cm2',
    'cm3',
    'dm',
    'dm2',
    'dm3',
    'm',
    'm2',
    'm3',
    'km',
    'km2',
    'km3',
  ]
  /* ------------ Currency units ------------ */
  const currencyUnits = [
    'PLN',
    'EUR',
    'USD',
    // 'CNY',
    // 'JPY',
    // 'KRW',
    // 'CAD',
    // 'RUB',
    // 'UAH',
    // 'TRY',
    // 'BGN',
    // 'MXN',
  ]
  /* ------------ VAT Rates ------------ */
  /* ------------ VAT Rates ------------ */

  return {
    vatUnits,
    sizeUnits,
    currencyUnits,
  }
}
