<template>
  <b-modal
    v-model="isModalOpen"
    :title="$tc('MassContactsUpdate', contacts.length, { length: contacts.length })"
    hide-footer
    no-close-on-backdrop
    @hide="$emit('close-modal', true)"
  >
    <b-alert
      show
      variant="primary"
    >
      <div class="alert-body p-1">
        <feather-icon
          icon="AlertCircleIcon"
          size="20"
        />
        {{ $t('ContactsDataWillBeOverwritten') }}
      </div>
    </b-alert>

    <b-button
      class="w-100"
      size="sm"
      variant="gradient-primary"
      @click="showPreview = !showPreview"
    >
      <feather-icon :icon="!showPreview ? 'EyeIcon' : 'EyeOffIcon'" />
      {{ !showPreview ? $t('ShowContacts') : $t('HideContacts') }}
    </b-button>

    <b-card v-if="showPreview">
      <vue-perfect-scrollbar
        :settings="{
          maxScrollbarLength: 60,
          wheelPropagation: false
        }"
        :style="{ height: contacts.length > 3 ? '200px' : 'auto' }"
      >
        <transition-group
          tag="b-list-group"
          name="list"
        >
          <b-list-group-item
            v-for="usedUser in contacts"
            :key="`used_user_${usedUser.id}`"
          >
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center">
                <b-avatar
                  class="mr-50"
                  :text="usedUserAvatarText(usedUser)"
                  variant="light-primary"
                />
                <div class="d-flex flex-column">
                  {{ usedUser.firstName || '' }} {{ usedUser.lastName || '' }}
                  <small class="text-muted">{{ usedUser.email }}</small>
                </div>
              </div>
            </div>
          </b-list-group-item>
        </transition-group>
      </vue-perfect-scrollbar>
    </b-card>

    <b-row class="mt-1">
      <b-col sm="12">
        <b-form-checkbox
          v-model="showContactTypes"
          switch
          variant="primary"
        >
          {{ $t('Tags') }}
        </b-form-checkbox>
        <transition name="fade">
          <sw-select v-if="showContactTypes">
            <v-select
              v-model="newContactTypes"
              multiple
              :options="contactTypes"
              label="name"
              :reduce="tag => tag.id"
            >
              <template #no-options>
                {{ $t('NoOptions') }}
              </template>
              <template #option="{ name, color }">
                <div
                  class="color-dot"
                  style="border: 1px solid white"
                  :style="{ backgroundColor: color }"
                />
                {{ name }}
              </template>
              <template #selected-option="{ name, color }">
                <div
                  class="color-dot"
                  style="border: 1px solid white"
                  :style="{ backgroundColor: color }"
                />
                {{ name }}
              </template>
            </v-select>
          </sw-select>
        </transition>
      </b-col>
      <b-col sm="12">
        <b-form-checkbox
          v-model="showContactStatuses"
          switch
          variant="primary"
        >
          {{ $t('contact.contactStatus') }}
        </b-form-checkbox>
        <transition name="fade">
          <sw-select v-if="showContactStatuses">
            <v-select
              v-model="newContactStatus"
              :options="contactStatuses"
              label="name"
              :reduce="status => status.id"
            >
              <template #no-options>
                {{ $t('NoOptions') }}
              </template>
              <template #option="{ name, color }">
                <div
                  class="color-dot"
                  :style="{ backgroundColor: color }"
                />
                {{ name }}
              </template>
              <template #selected-option="{ name, color }">
                <div
                  class="color-dot"
                  :style="{ backgroundColor: color }"
                />
                {{ name }}
              </template>
            </v-select>
          </sw-select>
        </transition>
      </b-col>
      <b-col sm="12">
        <b-form-checkbox
          v-model="showGroups"
          switch
          variant="primary"
        >
          {{ $t('contact.group') }}
        </b-form-checkbox>
        <transition name="fade">
          <sw-select v-if="showGroups">
            <v-select
              v-model="newContactGroups"
              :options="groups"
              label="name"
              :reduce="group => group.id"
              multiple
            >
              <template #no-options>
                {{ $t('NoOptions') }}
              </template>
              <template #option="{ name, color }">
                <div
                  class="color-dot"
                  style="border: 1px solid white"
                  :style="{ backgroundColor: color }"
                />
                {{ name }}
              </template>
              <template #selected-option="{ name, color }">
                <div
                  class="color-dot"
                  style="border: 1px solid white"
                  :style="{ backgroundColor: color }"
                />
                {{ name }}
              </template>
            </v-select>
          </sw-select>
        </transition>
      </b-col>

      <b-col sm="12">
        <b-form-checkbox
          v-model="showAssignedUsers"
          switch
          variant="primary"
        >
          {{ $t('AssignedUsers') }}
        </b-form-checkbox>
        <transition name="fade">
          <sw-select v-if="showAssignedUsers">
            <v-select
              v-model="newContactAssignedUsers"
              :options="users"
              label="firstName"
              :filterable="false"
              multiple
              @search="loadUsers"
            >
              <template #no-options="{ search }">
                <span v-if="search.length">
                  {{ $t('NoData') }}
                </span>
                <span v-else>
                  {{ $t('TypeToSearch') }}
                </span>
              </template>

              <template #option="{ firstName, lastName, avatar }">
                <div class="d-flex align-items-center">
                  <avatar
                    :user="{ firstName, lastName, avatar }"
                    class="mr-25"
                  />
                </div>
              </template>

              <template #selected-option="{ firstName, lastName, avatar }">
                <div class="d-flex">
                  <avatar
                    :user="{ firstName, lastName, avatar }"
                    class="mr-25"
                    text-variant="text-white"
                  />
                </div>
              </template>
            </v-select>
          </sw-select>
        </transition>
      </b-col>

      <b-col>
        <b-button
          variant="primary"
          size="sm"
          :disabled="(!showGroups && !showContactStatuses && !showContactTypes && !showAssignedUsers) || loading"
          @click="updateProductPrices"
        >
          {{ $t('Save') }}
        </b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import axiosIns from '@/libs/axios'
import vSelect from 'vue-select'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { USERS_SEARCH } from '@/@constants/fields'

export default {
  name: 'MassUpdateModal',
  components: {
    vSelect,
    VuePerfectScrollbar,
  },
  props: {
    contacts: {
      type: Array,
      default: () => ([]),
    },
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showPreview: false,
    isModalOpen: false,

    newContactTypes: [],
    newContactStatus: '',
    newContactGroups: [],

    showContactStatuses: false,
    contactStatuses: [],

    showGroups: false,
    groups: [],

    showAssignedUsers: false,
    newContactAssignedUsers: [],
    users: [],

    showContactTypes: false,
    contactTypes: [],
  }),
  watch: {
    showModal(newValue) {
      this.isModalOpen = newValue
    },
  },
  mounted() {
    this.initData()
  },
  methods: {
    async initData() {
      const [
        contactStatusesResponse,
        groupsResponse,
        contactTypesResponse,
      ] = await Promise.all([
        axiosIns.get('1/settings/contactStatuses', { params: { fields_load: this.$fields.SETTINGS_CONTACTS_STATUSES, orderBy: 'position.asc' } }),
        axiosIns.get('1/users/groups', { params: { fields_load: this.$fields.USERS_GROUPS } }),
        axiosIns.get('1/settings/contactTypes', { params: { fields_load: this.$fields.SETTINGS_CONTACTS_TAGS, orderBy: 'position.asc' } }),
      ])

      this.contactStatuses = contactStatusesResponse?.data?.data?.items || []
      this.groups = groupsResponse?.data?.data?.items || []
      this.contactTypes = contactTypesResponse?.data?.data?.items || []
    },
    usedUserAvatarText({ firstName, lastName }) {
      const fName = firstName || ''
      const lName = lastName || ''
      return `${fName[0] || ''}${lName[0] || ''}`.toUpperCase()
    },
    async loadUsers(search, isLoading) {
      this.users = await this.selectSearch(search, isLoading, USERS_SEARCH, 'userId,firstName,lastName,mailAddress-mail,mailAddressPrivate-mail,phoneNumber-phone,phoneNumberPrivate-phone', '1/users', 25, {})
    },
    splitToChunks(array, size = 250) {
      const result = []
      for (let i = 0; i < array.length; i += size) {
        result.push(array.slice(i, i + size))
      }

      return result
    },
    async updateProductPrices() {
      this.loading = true
      const payloadContactStatus = this.getObjectId(this.newContactStatus)
      const payloadGroups = this.newContactGroups.map(a => this.getObjectId(a))
      const payloadContactTypes = this.newContactTypes.map(a => this.getObjectId(a))
      const payloadContactAssignedUsers = this.newContactAssignedUsers.map(a => this.getObjectId(a))

      const updatePayload = this.contacts.map(({ id }) => {
        const payloadItem = { id }

        if (this.showContactStatuses) payloadItem.status = payloadContactStatus
        if (this.showGroups) payloadItem.showGroups = payloadGroups
        if (this.showContactTypes) payloadItem.contactTypes = payloadContactTypes
        if (this.showAssignedUsers) payloadItem.assignedUsers = payloadContactAssignedUsers

        return payloadItem
      })

      const chunks = this.splitToChunks(updatePayload)
      try {
        const resp = await Promise.all(chunks.map(chunk => axiosIns.patch('1/contacts', chunk)))
        const length = resp.reduce((a, b) => a + (b.data.data.items?.length || 0), 0)

        this.showAlert('success', this.$i18n.tc('SuccessUpdateContacts', length, { length }), '', false)
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.loading = false
        this.showContactStatuses = false
        this.contactStatuses = []

        this.showGroups = false
        this.groups = []

        this.showContactTypes = false
        this.contactTypes = []
        this.$emit('close-modal')
      }
    },
  },
}
</script>

<style scoped>

</style>
