// eslint-disable-next-line no-unused-vars,import/no-cycle
import axiosIns from '@/libs/axios'
import { GET_FUNNEL_POINTS, GET_FUNNELS } from '@/@constants/mutations'
import Vue from 'vue'
import * as fields from '@/@constants/fields'

const getCurrentDate = () => {
  const date = new Date()
  const month = (date.getMonth() + 1) < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
  const hour = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
  const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
  const seconds = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds()
  return `${date.getFullYear()}-${month}-${day} ${hour}:${minutes}:${seconds}`
}

export const resolveFilters = (params, { sorter, filters = {}, search = '' }) => {
  // eslint-disable-next-line no-underscore-dangle
  const _params = { ...(params || {}) }

  if (search) {
    _params.search = `%${search}%`
    _params.search_fields = 'contactThread-contact-firstName,contactThread-contact-lastName'
  }

  if (sorter) {
    const sortDir = sorter?.sortDesc ? 'desc' : 'asc'
    const sortBy = sorter?.sortBy || 'id'
    _params.orderBy = `${sortBy}.${sortDir}`
  }

  if (filters && filters.status) {
    _params.eq_contactThreadFunnelTypePoint = filters.status.toString()
  }

  if (filters.createdAt) {
    const splitedDate = filters.createdAt.dateRange()
    _params['gte_contactThread-createdAt'] = `${splitedDate[0]} 00:00:00`
    if (splitedDate[1]) {
      _params['lte_contactThread-createdAt'] = `${splitedDate[1]} 23:59:59`
    }
  }

  if (filters.agreeMarketing) {
    _params['eq_contactThread-contact-agreeMarketing'] = 1
  }

  if (filters.delayedContactStatuses) {
    _params['lt_contactThread-contact-statusDelayedAt'] = getCurrentDate()
  }

  if (filters && filters.contactCloseStatus) {
    _params.eq_status = filters.contactCloseStatus.toString()
  }
  // else {
  //   console.log(points)
  //   _params.eqArr_contactThreadTaskType = JSON.stringify(points)
  // }

  if (filters) {
    if (filters.assignedUsers && filters.assignedUsers.length) {
      _params['eqArr_contactThread-assignedUsers-id'] = JSON.stringify(
        filters.assignedUsers.map(
          user => (typeof user === 'object' ? user.id : user),
        ),
      )
    }
    // Thread
    if (filters.delayedContactThreadStatuses) {
      _params.lt_contactThreadFunnelTypePointDelayedAt = getCurrentDate()
    }

    if (filters.contactStructure) {
      _params.structure = true
    }

    if (filters.contactsOverdueTasks) {
      _params['lt_contactThread-contactThreadTasks-startAt'] = getCurrentDate()
      _params['eq_contactThread-contactThreadTasks-status'] = 'O'
    }

    if (filters.withOrganization) {
      _params['isNotNull_contactThread-contact-organization'] = ''
    }

    if (filters.tags && filters.tags.length) {
      const tags = filters.tags.map(tag => (typeof tag === 'string' ? tag : tag.id))
      if (filters.tagsSum) {
        _params['inD_contactThread-contact-contactTypes-id'] = JSON.stringify(tags)
      } else {
        _params['eqArr_contactThread-contact-contactTypes-id'] = JSON.stringify(tags)
      }
      // _params['eqArr_contactThread-contact-contactTypes-id'] = JSON.stringify(filters.tags.map(tag => (typeof tag === 'string' ? tag : tag.id)))
    }

    if (filters.closeStatuses && filters.closeStatuses.length) {
      _params.eqArr_status = JSON.stringify(filters.closeStatuses)
    }

    if (filters.agreeMarketing) {
      _params['eq_contactThread-contact-agreeMarketing'] = 1
    }
  }

  return _params
}
export default {
  namespaced: true,
  state: {
    filters: {
      closeStatuses: [],
      tags: [],
      limit: 100,
      status: '',
      assignedUsers: [],
      agreeMarketing: false,
      contactStructure: false,
      delayedContactStatuses: false,
      contactsOverdueTasks: false,
      delayedContactThreadStatuses: false,
      withOrganization: false,
      tagsSum: false,
      contactCloseStatus: '',
      createdAt: '',
    },
    selectedUsers: [],
  },
  getters: {
    getFilters: state => state.filters,
  },
  mutations: {
    SET_FILTER(state, payload) {
      Vue.set(state.filters, payload.field, payload.value)
    },
    CLEAR_FILTERS(state) {
      Vue.set(state, 'filters', {
        status: '',
        assignedUsers: [],
        contactStructure: false,
        delayedContactStatuses: false,
        delayedContactThreadStatuses: false,
        contactsOverdueTasks: false,
        limit: 50,
      })
    },
  },
  actions: {
    [GET_FUNNELS]: () => new Promise((resolve, reject) => {
      axiosIns.get('1/settings/contactThreadFunnelTypes', { params: { fields_load: fields.SETTINGS_FUNNEL_TYPE, orderBy: 'position.asc' } })
        .then(res => {
          resolve(res.data.data.items.filter(item => item.contactThreadFunnelTypePoints.length))
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [GET_FUNNEL_POINTS]: ({ state }, payload) => new Promise((resolve, reject) => {
      const { funnelId, search } = payload

      if (!Number.isNaN(Number(funnelId))) {
        const params = {
          fields_load: fields.SETTINGS_FUNNEL_TYPE,
          ...search,
        }
        axiosIns.get(`1/settings/contactThreadFunnelTypes/${funnelId}`, { params })
          .then(res => {
            resolve((res.data.data?.item?.contactThreadFunnelTypePoints || []).sort((a, b) => a.position - b.position))
          })
          .catch(err => {
            reject(err)
          })
      }
    }),
  },
}
