<template>
  <div>
    <!--    Section: Filters    -->
    <sw-filters
      v-if="!hideFilters"
      :shorts="[
        {
          title: $t('projects.taskDoneBy'),
          type: 'avatar',
          value: () => orderFilters.createdBy ? [orderFilters.createdBy] : null,
          clear: () => { orderFilters.createdBy = ''; reloadContent() }
        },
        {
          title: $t('contact.assignedUsers'),
          type: 'avatar',
          value: () => orderFilters.assignedUsers,
          clear: () => { orderFilters.assignedUsers = []; reloadContent() }
        },
        {
          title: $t('PaymentMethod'),
          value: () => orderFilters.paymentMethod ? $t(orderFilters.paymentMethod) : null,
          clear: () => { orderFilters.paymentMethod = ''; reloadContent() }
        },
        {
          title: $t('StatusOfOrder'),
          value: () => orderFilters.status ? $tc(`offer.status.${orderFilters.status}`, 2) : null,
          clear: () => { orderFilters.status = ''; reloadContent() }
        },
        {
          title: $t('OrderCreationDate'),
          value: () => orderFilters.createdAt,
          clear: () => { orderFilters.createdAt = ''; reloadContent() }
        },
        {
          title: $t('Contracts.DateOfSigningContract'),
          value: () => orderFilters.agreementSignedAt,
          clear: () => { orderFilters.agreementSignedAt = ''; reloadContent() }
        },
      ]"
    >
      <b-row>
        <b-col
          v-if="!threadId && !currentThread"
          md="6"
          lg="4"
        >
          <sw-select
            :name="$t('projects.taskDoneBy')"
            class="mb-0"
          >
            <assigned-users
              :is-multiple="false"
              :value="orderFilters.createdBy"
              :placeholder="$t('TypeToSearch')"
              @input="value => $set(orderFilters, 'createdBy', value)"
            />
          </sw-select>
        </b-col>

        <b-col
          v-if="!threadId && !currentThread"
          md="6"
          lg="4"
        >
          <sw-select
            :name="$t('contact.assignedUsers')"
            class="mb-0"
          >
            <assigned-users
              :value="orderFilters.assignedUsers"
              :placeholder="$t('TypeToSearch')"
              @input="value => $set(orderFilters, 'assignedUsers', value)"
            />
          </sw-select>
        </b-col>

        <b-col
          v-if="!threadId && !currentThread"
          md="6"
          lg="4"
        >
          <sw-select :name="$t('PaymentMethod')">
            <v-select
              v-model="orderFilters.paymentMethod"
              :options="[
                'installment',
                'transfer',
                'cash',
                'loyaltyPlan',
              ]"
            >
              <template #no-options>
                {{ $t('NoOptions') }}
              </template>

              <template #option="{ label }">
                {{ $t(label) }}
              </template>

              <template #selected-option="{ label }">
                {{ $t(label) }}
              </template>
            </v-select>
          </sw-select>
        </b-col>

        <b-col
          md="6"
          lg="4"
        >
          <sw-select :name="$t('StatusOfOrder')">
            <v-select
              v-model="orderFilters.status"
              :options="[
                'NEW',
                'IN_REALIZATION',
                'REALIZED',
                'CANCELLED',
                'IN_REALIZATION',
              ]"
            >
              <!--                'POINTS_PURCHASE',-->
              <template #no-options>
                {{ $t('NoOptions') }}
              </template>

              <template #option="option">
                {{ $tc(`offer.status.${option.label}`, 2) }}
              </template>
              <template #selected-option="option">
                {{ $tc(`offer.status.${option.label}`, 2) }}
              </template>
            </v-select>
          </sw-select>
        </b-col>
        <b-col
          v-if="checkRequiredModule('agreementModule')"
          md="6"
          lg="4"
        >
          <sw-select :name="$t('Contracts.DateOfSigningContract')">
            <sw-select-fpr
              :is-empty="orderFilters.agreementSignedAt"
              @clear="orderFilters.agreementSignedAt = ''"
            >
              <flat-pickr
                v-model="orderFilters.agreementSignedAt"
                class="form-control"
                :config="{ mode: 'range', locale: getCalendarLocale($i18n.locale) }"
              />
            </sw-select-fpr>
          </sw-select>
        </b-col>
        <b-col
          md="6"
          lg="4"
        >
          <sw-select :name="$t('OrderDate')">
            <sw-select-fpr
              :is-empty="orderFilters.orderDate"
              @clear="orderFilters.orderDate = ''"
            >
              <flat-pickr
                v-model="orderFilters.orderDate"
                class="form-control"
                :config="{ mode: 'range', locale: getCalendarLocale($i18n.locale) }"
              />
            </sw-select-fpr>
          </sw-select>
        </b-col>
        <b-col
          v-if="!threadId && !currentThread"
          md="6"
          lg="4"
        >
          <sw-select :name="$t('OrderCreationDate')">
            <sw-select-fpr
              :is-empty="orderFilters.createdAt"
              @clear="orderFilters.createdAt = ''"
            >
              <flat-pickr
                v-model="orderFilters.createdAt"
                class="form-control"
                :config="{ mode: 'range', locale: getCalendarLocale($i18n.locale) }"
              />
            </sw-select-fpr>
          </sw-select>
        </b-col>
      </b-row>
    </sw-filters>

    <!--    START::Table    -->
    <my-table
      ref="table"
      :filters="orderFilters"
      :thread-id="threadId"
    />
    <!-- || (currentThread ? currentThread.id : null) -->
    <!--    END::Table    -->
  </div>
</template>

<script>
import AssignedUsers from '@/views/components/AssignedUsers.vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { Polish as pl } from '@/libs/i18n/locales/flatpickr/pl'
import { english as en } from '@/libs/i18n/locales/flatpickr/en'
import { mapGetters } from 'vuex'
import MyTable from './components/list/Table.vue'

export default {
  components: {
    MyTable,
    AssignedUsers,
    vSelect,
    flatPickr,
  },
  props: {
    hideFilters: {
      type: Boolean,
      default: false,
    },
    thread: {
      default: null,
    },
    funnel: {
      default: null,
    },
    funnelPoint: {
      default: null,
    },
    contacts: {
      default: () => [],
    },
    threadId: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    isLoading: false,
    orderFilters: {
      paymentMethod: '',
      status: '',
      createdAt: '',
      createdBy: '',
      orderDate: '',
      agreementSignedAt: '',
      assignedUsers: '',
    },
    flatPickerConfig: {
      mode: 'range',
      locale: [pl, en],
    },
    list: [],
  }),
  computed: {
    ...mapGetters({
      currentThread: 'contact/thread',
    }),
  },
  watch: {
    funnel(n, o) { if (n !== o) this.init() },
    funnelPoint(n, o) { if (n !== o) this.init() },
    thread(n, o) { if (n !== o) this.init() },
    contacts(n, o) { if (n !== o) this.init() },
  },
  mounted() {
    this.ini()
  },
  methods: {
    ini() {
      if (this.funnel) {
        this.orderFilters.funnel = this.getObjectId(this.funnel)
      }
      if (this.funnelPoint) {
        this.orderFilters.funnelPoint = this.getObjectId(this.funnelPoint)
      }
      if (this.thread) {
        this.orderFilters.thread = this.getObjectId(this.thread)
      }
      if (this.contacts) {
        this.orderFilters.contacts = (this.contacts || []).mapKeys('id')
      }

      this.$nextTick(() => {
        this.isLoading = true

        this.nextCommissionRecalculation = '09.12.2022'
        this.loadData()

        this.isLoading = false
      })
    },
    reloadContent() {
      if (this.$refs.table) {
        this.$refs.table.loadOrders()
      }
    },
    loadData() {
      this.$nextTick(() => {
        const payload = {
          pagination: this.pagination,
        }

        if (this.contactId && this.threadId) {
          payload.threadId = this.threadId
          payload.contactId = this.contactId
        }

        this.list.push(
          {
            orderID: 23434534,
            createdAt: '09.12.2022',
            contact: 'X',
            thread: 'Zgubiony',
            products: 3,
            nettoPrice: 6500,
            grossPrice: 6500,
            statusOfOrder: 'Ended',
            paymentStatus: 'Paid',
            paymentMethod: 'installment',
            commissionType: 'own',
            grossCommissionAmount: {
              val: 1200,
              isQualified: true,
            },
            toPay: 200,
            paidOut: 200,
            assignedUser: 'X',
            whoGetsCommissions: 'X',
            createContract: { name: 'Catest' },
            createdBy: 'X',
          },
        )
      })
    },
  },
}
</script>
