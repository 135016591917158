<template>
  <validation-observer
    ref="form"
    class="d-flex flex-column"
    style="row-gap: 1.34rem"
  >
    <!--    START:Header    -->
    <div
      class="d-flex flex-column flex-md-row align-md-items-center justify-md-content-between"
      style="column-gap: 1.5rem"
    >
      <!--    START:Name & Hint    -->
      <b-row
        class="d-flex flex-grow-1"
        style="row-gap: .34rem"
      >
        <!--    START:Name    -->
        <b-col
          cols="12"
          md="6"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="$t('EnterFieldName')"
            rules="required"
          >
            <b-input-group>
              <b-form-input
                v-model="form.name"
                :label="$t('EnterFieldName')"
                :placeholder="$t('EnterFieldName')"
              />

              <b-input-group-append>
                <b-button
                  v-b-tooltip.hover.v-primary
                  variant="outline-primary"
                  :title="$t('RangeWithQuantity')"
                  class="btn-icon px-75"
                  style="cursor: default !important"
                >
                  <sw-icon icon="InfoIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <!--    END:Name    -->

        <!--    START:Hint    -->
        <b-col
          cols="12"
          md="6"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="$t('AddHint')"
          >
            <b-form-input
              v-model="form.hint"
              :label="$t('AddHint')"
              :placeholder="$t('AddHint')"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <!--    END:Hint    -->
      </b-row>
      <!--    END:Name & Hint    -->

      <!--    START:Toolbar MD    -->
      <div
        class="d-none d-md-flex align-items-md-start mt-md-50"
        style="row-gap: .5rem"
      >
        <b-form-checkbox
          v-model="form.show"
          v-b-tooltip.hover.v-success
          checked="true"
          :title="$t('FieldHiddenVisible')"
          class="custom-control-success"
          name="show"
          switch
        >
          <span class="switch-icon-left">
            <feather-icon icon="EyeIcon" />
          </span>
          <span class="switch-icon-right">
            <feather-icon icon="EyeOffIcon" />
          </span>
        </b-form-checkbox>

        <b-button
          v-b-tooltip.hover.v-danger
          variant="flat-danger"
          size="sm"
          class="btn-icon mt-md-n25"
          :title="$t('Delete')"
          @click="$emit('rm')"
        >
          <feather-icon icon="TrashIcon" />
        </b-button>
      </div>
      <!--    END:Toolbar MD    -->
    </div>
    <!--    END:Header    -->

    <!--    START:Content    -->
    <div>
      <b-table
        :items="form.list"
        :fields="fields"
        striped
        responsive
        show-empty
        class="border rounded-lg mb-0"
      >
        <!--    Head    -->
        <template #head()="{ label }">
          <template>
            {{ $t(`${label}`) }}
          </template>
        </template>

        <!--    START:New Inverter Form    -->
        <template #bottom-row>
          <!--    START:Name    -->
          <td>
            <b-input-group>
              <b-form-input
                v-model="form.newItem.name"
                :label="$t('Name')"
                :placeholder="$t('Name')"
              />
            </b-input-group>
          </td>
          <!--    END:Name    -->

          <!--    START:Value    -->
          <td>
            <b-input-group>
              <b-form-input
                v-model="form.newItem.value"
                :label="$t('Value')"
                type="number"
                :placeholder="$t('Value')"
              />
            </b-input-group>
          </td>
          <!--    END:Value    -->

          <!--    START:Add Button    -->
          <td
            class="d-flex justify-content-end"
            style="max-width: max-content"
          >
            <b-button
              variant="success"
              class="btn-icon m-25"
              :disabled="!form.newItem.name || !form.newItem.value"
              @click="addNewListItem"
            >
              <feather-icon icon="PlusIcon" />
            </b-button>
          </td>
          <!--    END:Add Button    -->
        </template>
        <!--    END:New Inverter Form    -->

        <!--    START:Name    -->
        <template #cell(name)="{ index }">
          <b-input-group>
            <b-form-input
              v-model="form.list[index].name"
              :label="$t('Name')"
              type="number"
              :placeholder="$t('Name')"
            />
          </b-input-group>
        </template>
        <!--    END:Name    -->

        <!--    START:Value    -->
        <template #cell(value)="{ index }">
          <b-input-group>
            <b-form-input
              v-model="form.list[index].value"
              :label="$t('Value')"
              type="number"
              :placeholder="$t('Value')"
            />
          </b-input-group>
        </template>
        <!--    END:Value    -->

        <template #cell(actions)="{ index }">
          <b-button
            variant="flat-danger"
            size="sm"
            @click="form.list.splice(index, 1)"
          >
            <feather-icon icon="TrashIcon" />

            {{ $t('Delete') }}
          </b-button>
        </template>

        <!--    Other    -->
        <template #cell()="row">
          <table-default-cell
            :field="row.field"
            :value="row.value"
          />
        </template>

        <!--    Empty    -->
        <template #empty>
          <empty-content />
        </template>

        <!--    Table Busy    -->
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner
              class="align-middle"
              variant="primary"
            />
          </div>
        </template>
      </b-table>
    </div>
    <!--    END:Content    -->

    <!--    START:Options    -->
    <b-form-group
      :label="$t('Options')"
      class="mb-0"
    >
      <template #label>
        <h5 class="mb-25">
          {{ $t('Options') }}
        </h5>
      </template>

      <b-form-checkbox
        v-model="form.required"
        class="custom-control-primary"
      >
        {{ $t('MandatoryChoice') }}
      </b-form-checkbox>
    </b-form-group>
    <!--    END:Options    -->

    <!--    START:Toolbar SM    -->
    <div
      class="d-flex align-items-center justify-content-end mt-n1 d-md-none"
      style="row-gap: .5rem"
    >
      <b-form-checkbox
        v-model="form.show"
        v-b-tooltip.hover.v-success
        checked="true"
        :title="$t('FieldHiddenVisible')"
        class="custom-control-success"
        name="show"
        switch
      >
        <span class="switch-icon-left">
          <feather-icon icon="EyeIcon" />
        </span>
        <span class="switch-icon-right">
          <feather-icon icon="EyeOffIcon" />
        </span>
      </b-form-checkbox>

      <b-button
        v-b-tooltip.hover.v-danger
        variant="flat-danger"
        size="sm"
        class="btn-icon"
        :title="$t('Delete')"
        @click="$emit('rm')"
      >
        <feather-icon icon="TrashIcon" />
      </b-button>
    </div>
    <!--    END:Toolbar SM    -->
  </validation-observer>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { BInputGroupAppend, BTable, VBTooltip } from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BInputGroupAppend,
    ValidationObserver,
    ValidationProvider,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },

  emits: ['isValid', 'rm'],

  data: () => ({
    form: {
      list: [],
      newItem: { },
    },

    fields: [
      { key: 'name', label: 'Name' },
      { key: 'value', label: 'Value' },
      { key: 'actions', label: 'Actions' },
    ],

    actions: [
      'ADD',
      'SUB',
      'MUL',
      'DIV',
    ],

    multipliers: [
      { id: -1, name: 'Test' },
    ],
  }),

  watch: {
    form: {
      deep: true,
      async handler() {
        this.$emit('isValid', await this.$refs.form.validate({ silent: true }))
      },
    },
  },

  methods: {
    addNewListItem() {
      this.form.list.push(this.form.newItem)

      this.form.newItem = { }
    },
  },
}
</script>
