<template>
  <div>
    <b-card
      no-body
      footer-class="px-75 py-50 d-flex align-items-center justify-content-end"
    >
      <b-card-header
        class="text-white px-75 py-25"
        :style="`background-image: linear-gradient(to right, var(--primary) 40%, ${item.color})`"
      >
        <div
          class="d-flex align-items-center"
          style="gap: .5rem"
          :style="{ opacity: item.enabled ? 1 : .6 }"
        >
          {{ item.name | truncate(24) }}

          <!--          <sw-icon-->
          <!--            v-if="!item.connected"-->
          <!--            class="mt-n25"-->
          <!--            icon="AlertTriangleIcon"-->
          <!--          />-->
        </div>

        <!--    START::Toolbar    -->
        <div
          class="d-flex align-items-center"
          style="gap: .34rem"
        >
          <b-button-group>
            <b-button
              v-b-tooltip.hover.v-primary
              variant="flat-light"
              size="sm"
              class="p-25 btn-icon"
              :title="$t('ConnectedDomains')"
              :disabled="loading"
              @click="showDomainListModal(item.id)"
            >
              <sw-icon icon="ListIcon" />
            </b-button>

            <b-button
              v-b-tooltip.hover.v-primary
              variant="flat-light"
              size="sm"
              class="p-25 btn-icon"
              :title="$t('CheckConnection')"
              :disabled="loading"
              @click="checkItem(item.id)"
            >
              <sw-icon icon="RefreshCwIcon" />
            </b-button>
          </b-button-group>

          <b-button-group>
            <b-button
              v-b-tooltip.hover.v-success
              variant="flat-light"
              size="sm"
              class="p-25 btn-icon"
              :title="$t('Edit')"
              :disabled="loading"
              @click="editItem(item.id)"
            >
              <sw-icon icon="Edit2Icon" />
            </b-button>

            <b-button
              v-b-tooltip.hover.v-danger
              variant="flat-light"
              size="sm"
              class="p-25 btn-icon"
              :title="$t('Delete')"
              :disabled="loading"
              @click="deleteItem(item.id)"
            >
              <sw-icon icon="TrashIcon" />
            </b-button>
          </b-button-group>
        </div>
        <!--    END::Toolbar    -->
      </b-card-header>

      <ul
        class="px-1 py-75 d-flex flex-column"
        style="list-style: none; padding: 0; margin: 0; row-gap: .5rem"
      >
        <!--  Status  -->
        <li
          class="d-flex flex-wrap align-items-center justify-content-between"
          style="list-style: none; padding: 0; gap: .34rem"
        >
          <div
            class="d-flex align-items-center"
            style="column-gap: .5rem"
          >
            <b-badge variant="primary">
              <sw-icon icon="HexagonIcon" />
            </b-badge>

            <p class="mb-0">
              {{ $t('Status') }}
            </p>
          </div>

          <b-button
            v-b-tooltip.hover="{ variant: item.enabled ? 'danger' : 'success' }"
            :variant="item.enabled ? 'success' : 'outline-danger'"
            size="sm"
            :title="$t(item.enabled ? 'Deactivate' : 'Activate')"
            @click="toggleEnabledItem(item)"
          >
            {{ $t(item.enabled ? 'Active' : 'NotActive') }}
          </b-button>
        </li>

        <!--  Is Connected  -->
        <!--        <li-->
        <!--          class="d-flex flex-wrap align-items-center justify-content-between"-->
        <!--          style="list-style: none; padding: 0; gap: .34rem"-->
        <!--        >-->
        <!--          <div-->
        <!--            class="d-flex align-items-center"-->
        <!--            style="column-gap: .5rem"-->
        <!--          >-->
        <!--            <b-badge variant="primary">-->
        <!--              <sw-icon icon="AnchorIcon" />-->
        <!--            </b-badge>-->

        <!--            <p class="mb-0">-->
        <!--              {{ $t('Connections') }} HTML-->
        <!--            </p>-->
        <!--          </div>-->

        <!--          <b-badge :variant="item.connected ? 'light-success' : 'light-danger'">-->
        <!--            <sw-icon :icon="item.enabled ? 'CheckIcon' : 'XIcon'" />-->

        <!--            {{ $t(item.connected ? 'Successful' : 'Failed') }}-->
        <!--          </b-badge>-->
        <!--        </li>-->

        <!--  Code  -->
        <li
          class="d-flex flex-wrap align-items-center justify-content-between"
          style="list-style: none; padding: 0; gap: .34rem"
        >
          <div
            class="d-flex align-items-center"
            style="column-gap: .5rem"
          >
            <b-badge variant="primary">
              <sw-icon icon="CodeIcon" />
            </b-badge>

            <p class="mb-0">
              {{ $t('ChatScript') }}
            </p>
          </div>

          <b-button
            variant="flat-primary"
            size="sm"
            @click="mCopyCode = true"
          >
            {{ $t('Show') }}
          </b-button>
        </li>

        <!--  Assistant AI  -->
        <li
          v-if="item.salesAiEnvironment"
          class="d-flex flex-wrap align-items-center justify-content-between"
          style="list-style: none; padding: 0; gap: .34rem"
        >
          <div
            class="d-flex align-items-center"
            style="column-gap: .5rem"
          >
            <b-badge variant="primary">
              <sw-icon icon="CpuIcon" />
            </b-badge>

            <p class="mb-0">
              {{ $t('AssistantAi') }}
            </p>
          </div>

          <p
            class="mb-0 btn btn-sm text-primary"
            style="cursor: default"
          >
            {{ item.salesAiEnvironment.name }}
          </p>
        </li>
      </ul>

      <template #footer>
        <div class="d-flex align-items-center justify-content-between w-100">
          <div>
            <b-spinner
              v-if="loading"
              small
              variant="primary"
            />
          </div>

          <div
            v-b-tooltip.hover.v-primary
            class="d-flex align-items-center"
            style="gap: .34rem"
            :title="$t('CreatedTime')"
          >
            <sw-icon
              icon="ClockIcon"
              style="margin-top: -.1rem"
            />

            {{ item.createdAt.date.split(' ')[0] }}
          </div>
        </div>
      </template>
    </b-card>

    <!--  Modal - Domain List  -->
    <b-modal
      v-model="mDomainList"
      :title="$t('ConnectedDomains')"
      hide-footer
      tabindex="none"
      size="sm"
      body-class="p-0"
    >
      <b-list-group
        v-if="item.allowedDomains.length"
        flush
      >
        <b-list-group-item
          v-for="domain in item.allowedDomains"
          :key="domain"
          class="d-flex align-items-center justify-content-between"
        >
          {{ domain }}

          <!--          <b-badge :variant="domain.connected ? 'light-success' : 'light-danger'">-->
          <!--            <sw-icon :icon="domain.connected ? 'CheckIcon' : 'XIcon'" />-->

          <!--            {{ $t(domain.connected ? 'Successful' : 'Failed') }}-->
          <!--          </b-badge>-->
        </b-list-group-item>
      </b-list-group>

      <!--    START:No Data    -->
      <div
        v-else
        class="text-center my-2 font-weight-bold"
      >
        <sw-icon
          size="17"
          icon="XCircleIcon"
        />
        {{ $t('NoData') }}
      </div>
      <!--    END:No Data    -->
    </b-modal>

    <!--  Modal - Copy Code  -->
    <b-modal
      v-model="mCopyCode"
      :title="$t('InstructionsForAddingWidget')"
      hide-footer
      tabindex="none"
      size="lg"
    >
      <div
        class="d-flex flex-column"
      >
        <div>
          <div class="divider text-primary">
            <div class="divider-text">
              1. {{ $t('CopyPasteCodeHead') }}
            </div>
          </div>

          <pre
            class="codeExample cursor-pointer"
            @click="
              copy(JSON.parse(JSON.stringify(`
            <script src='${getDomain}/widgets/chat/assets/dist/utils/tailwind.js'></script>
            <link rel='stylesheet' href='${getDomain}/widgets/chat/assets/dist/style.css'></link>`
              )))
            "
          >
&lt;script src='{{ getDomain }}/widgets/chat/assets/dist/utils/tailwind.js'&gt;&lt;/script&gt;
&lt;link rel='stylesheet' href='{{ getDomain }}/widgets/chat/assets/dist/style.css'&gt;&lt;/link&gt;</pre>
        </div>

        <div>
          <div class="divider text-primary">
            <div class="divider-text">
              2. {{ $t('CopyPasteCodeBody') }}
            </div>
          </div>

          <pre
            class="codeExample cursor-pointer"
            @click="
              copy(JSON.parse(JSON.stringify(`
              <script type='module'>
                import SwChatWidget from '${getDomain}/widgets/chat/assets/dist/index.js'

                const chat = new SwChatWidget(${item.id}, '${$i18n.locale}', '${ getHostName }')
              </script>`
              )))
            "
          >
&lt;script type='module'&gt;
  import SwChatWidget from '{{ getDomain }}/widgets/chat/assets/dist/index.js'

  const chat = new SwChatWidget({{ item.id }}, '{{ $i18n.locale }}', '{{ getHostName }}')
&lt;/script&gt;</pre>
        </div>

        <div>
          <div class="divider text-primary">
            <div class="divider-text">
              {{ $t('CopyPasteCodeExample') }}
            </div>
          </div>

          <pre class="codeExample">
&lt;!doctype html&gt;
&lt;html lang=&quot;en&quot;&gt;
&lt;head&gt;
  …
  &lt;script src='{{ getDomain }}/widgets/chat/assets/dist/utils/tailwind.js'&gt;&lt;/script&gt;
  &lt;link rel='stylesheet' href='{{ getDomain }}/widgets/chat/assets/dist/style.css'&gt;&lt;/link&gt;
&lt;/head&gt;
&lt;body&gt;
  …
  &lt;script type='module'&gt;
    import SwChatWidget from '{{ getDomain }}/widgets/chat/assets/dist/index.js'

    const chat = new SwChatWidget({{ item.id }}, '{{ $i18n.locale }}', '{{ getHostName }}')
  &lt;/script&gt;
&lt;/body&gt;
&lt;/html&gt;</pre>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BButtonGroup, VBTooltip } from 'bootstrap-vue'
import {
  ADD_MODAL, DELETE_WIDGET_CHAT, GET_WIDGET_CHAT, SET_MODAL_DATA, SHOW_MODAL, UPDATE_WIDGET_CHAT,
} from '@/@constants/mutations'

export default {
  components: { BButtonGroup },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  emits: ['update', 'delete'],
  data: () => ({
    loading: false,

    mDomainList: false,
    mCopyCode: false,
    mEdit: '',
  }),
  computed: {
    getDomain() {
      return window.location.port
      || window.location.origin.toString() === 'https://demo.saleswizardapp.com'
        ? 'https://d1.saleswizardapp.com'
        : window.location.origin
    },
    getHostName() {
      return window.location.port
          || window.location.hostname.toString() === 'demo.saleswizardapp.com'
        ? 'd1.saleswizardapp.com'
        : window.location.hostname
    },
  },
  mounted() {
    this.createModals()
  },
  methods: {
    createModals() {
      this.$store.dispatch(`swModal/${ADD_MODAL}`, {
        title: this.$i18n.t('ChatWidget'),
        component: 'm-new-chat-widget',
        data: { id: this.id },
        size: 'xl',
        hideFooter: true,
      }).then(id => this.$set(this, 'mEdit', id))
    },

    showDomainListModal() {
      this.mDomainList = true
    },

    async checkItem(id) {
      try {
        this.loading = true

        const newItem = await this.$store.dispatch(`chatWidgets/${GET_WIDGET_CHAT}`, id)

        if (newItem) this.$emit('update', newItem)
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }

      this.loading = false
    },

    editItem(id) {
      this.$store.dispatch(`swModal/${SET_MODAL_DATA}`, {
        id: this.mEdit,
        data: { id },
      }).then(() => this.$store.dispatch(`swModal/${SHOW_MODAL}`, this.mEdit))
    },

    async toggleEnabledItem({ id, enabled }) {
      this.loading = true

      await this.$store.dispatch(`chatWidgets/${UPDATE_WIDGET_CHAT}`, { id, enabled: !enabled })

      const newItem = JSON.parse(JSON.stringify(this.item))

      newItem.enabled = !enabled

      this.$emit('update', newItem)

      this.loading = false
    },

    async deleteItem(id) {
      this.showAlert('error', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('alerts.WontbeAble'))
        .then(async () => {
          try {
            this.loading = true

            await this.$store.dispatch(`chatWidgets/${DELETE_WIDGET_CHAT}`, id)

            this.$emit('delete', id)

            this.loading = false
          } catch (err) {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          }
        })
        .catch(() => { })
    },

    copy(txt) {
      // eslint-disable-next-line no-unused-expressions
      navigator.clipboard?.writeText(txt).then(() => {
        this.showToast('primary', this.$i18n.t('CopiedToClipboard'))
      })
    },
  },
}
</script>
