<template>
  <div>
    <b-button
      variant="success"
      size="sm"
      class="mb-1"
      @click="$router.push('/calculator/new')"
    >
      {{ $t('calculators.CreateNew') }}

      <feather-icon icon="PlusIcon" />
    </b-button>

    <b-card>
      <!-- eslint-disable -->
      <sw-table
        table-id="6fa925f9793342ca985631b207630796"
        show-columns
        show-search
        :pagination="pagination"
        :fields.async="tableFields"
        @change-search-query="searchQuery = $event"
        @change-pagination="Object.assign(pagination, $event)"
        @reload-content="loadData"
        @set-fields="tableFields = $event"
      >
        <template #table>
          <b-table
            ref="selectableTable"
            :items="items"
            class="table-wrap-words mx-0 px-0"
            :fields="fields"
            striped
            responsive
            :busy="loading"
            show-empty
            :sort-by.sync="sorter.sortBy"
            :sort-desc.sync="sorter.sortDesc"
            :no-local-sorting="true"
            @sort-changed="Object.assign(sorter, $event); loadData()"
          >
            <template #head()="{ label }">
              {{ $t(`${label}`) }}
            </template>

            <template #cell(id)="{ index }">
              #{{ getOrderNo(index, pagination, sorter) }}
            </template>

            <!--        NumberOfCalculationsPerformed         -->
            <template #cell(numberOfCalculationsPerformed)="{ value }">
              <b-badge variant="primary">
                {{ value }}
              </b-badge>
            </template>

            <!--        Status         -->
            <template #cell(status)="{ value }">
              <b-badge :variant="value === 'active' ? 'success' : 'danger'">
                {{ $t(`calculators.${value}`) }}
              </b-badge>
            </template>

            <!--        CreatedBy         -->
            <template #cell(createdBy)="{ value }">
              <avatar
                v-if="value"
                :user="value"
              />

              <feather-icon
                v-else
                icon="MinusIcon"
              />
            </template>

            <!--        Files         -->
            <template #cell(files)="{ value }">
              <documents-list
                v-if="Array.isArray(value) && value.length"
                :documents="value"
              />

              <feather-icon
                v-else
                icon="MinusIcon"
              />
            </template>

            <!--     Action     -->
            <template #cell(action)="{ item, index }">
              <b-dropdown
                id="dropdown-dropleft"
                dropleft
                no-caret
                variant="flat"
              >
                <template #button-content>
                  <feather-icon icon="MoreVerticalIcon" />
                </template>

                <b-dropdown-item>
                  <dropdown-item-label
                    icon="EditIcon"
                    :label="$t('Edit')"
                  />
                </b-dropdown-item>

                <b-dropdown-item>
                  <dropdown-item-label
                    :icon="item.status === 'active' ? 'XSquareIcon' : 'CheckSquareIcon'"
                    :label="$t(item.status === 'active' ? 'Deactivate' : 'Activate')"
                  />
                </b-dropdown-item>
              </b-dropdown>
            </template>

            <!--    Other    -->
            <template #cell()="row">
              <table-default-cell
                :field="row.field"
                :value="row.value"
              />
            </template>

            <!--      Empty      -->
            <template #empty>
              <empty-content />
            </template>

            <!--      Table Busy      -->
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner
                  class="align-middle"
                  variant="primary"
                />
              </div>
            </template>
          </b-table>
        </template>
      </sw-table>
    </b-card>
  </div>
</template>

<script>
import { BTable } from 'bootstrap-vue'
import DocumentsList from '@/views/components/DocumentsList.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    DocumentsList,
    BTable,
  },

  data: () => ({
    loading: false,

    searchQuery: '',

    sorter: {
      sortBy: 'id',
      sortDesc: true,
    },

    pagination: {
      perPage: 25,
      currentPage: 1,
      totalRecords: 100,
    },

    items: [],
    tableFields: [
      {
        key: 'id', label: '#', visible: true, sortable: true,
      },
      { key: 'name', label: 'calculators.Name', visible: true },
      { key: 'status', label: 'Status', visible: true },
      { key: 'numberOfCalculationsPerformed', label: 'calculators.NumberOfCalculationsPerformed', visible: true },
      { key: 'calculatorCreationDate', label: 'calculators.CalculatorCreationDate', visible: true },
      { key: 'dateOfTheLastEditionOfTheCalculator', label: 'calculators.DateOfTheLastEditionOfTheCalculator', visible: true },
      { key: 'createdBy', label: 'CreatedBy', visible: true },
      {
        visible: true, key: 'action', label: 'Action',
      },
    ],
  }),

  computed: {
    fields() {
      return this.tableFields.filter(field => field.visible)
        .filter(e => !e.requiredViewRanks || this.checkRequiredViewPermissions(e.requiredViewRanks))
    },
    ...mapGetters({
      user: 'auth/getCurrentUser',
    }),
  },

  mounted() {
    this.items.push(
      {
        id: 1,
        name: 'Calculator 1',
        status: 'active',
        numberOfCalculationsPerformed: 103,
        calculatorCreationDate: '09.01.2024',
        dateOfTheLastEditionOfTheCalculator: '09.01.2024',
        createdBy: this.user,
      },
    )
  },

  methods: {
    loadData() {
      this.$nextTick(() => {
        this.loading = true

        // eslint-disable-next-line no-unused-vars
        const payload = {
          pagination: this.pagination,
          searchQuery: this.searchQuery,
          sorter: this.sorter,
          filters: {},
        }

        this.loading = false
      })
    },
  },
}
</script>
