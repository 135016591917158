<template>
  <div
    class="d-flex flex-column flex-lg-row max-h-md-view"
    style="row-gap: 2rem; column-gap: 1rem;"
  >
    <div
      id="contact-container"
      class="overflow-y-md-auto rounded-lg"
      style="min-width: 24rem; min-height: 30rem"
      :style="isMobile ? 'max-width: 100%' : 'max-width: 24rem'"
    >
      <contact-info :key="`contact-info_${$route.params.contactId}`" />

      <app-collapse type="margin">
        <contact-details :key="`contact-details_${$route.params.contactId}`" />

        <note v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_FORM_NOTE])"
              :key="`note_${$route.params.contactId}`"
        />

        <thread-list :key="`thread-list_${$route.params.contactId}`" />

        <thread-assigned-list :key="`thread-assigned-list_${$route.params.contactId}`" />
      </app-collapse>
    </div>

    <div v-if="$store.state.contact.currentContact"
         id="right-side"
         class="flex-grow-1 overflow-y-md-auto rounded-lg"
    >
      <div v-show="!threadLoading">
        <right-toolbar :key="`right-toolbar_${$route.params.contactId}`" />

        <funnel-points-breadcrumbs :key="`funnel-points-breadcrumbs_${$route.params.contactId}`" />

        <thread-tabs :key="`thread-tabs_${$route.params.contactId}`"
                     class="mt-50"
        />
      </div>
      <div v-show="threadLoading">
        <div class="text-center text-danger my-2">
          <b-spinner
            class="align-middle"
            variant="primary"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LOAD_CURRENT_CONTACT } from '@/@constants/mutations'
import { mapActions, mapGetters } from 'vuex'
import router from '@/router'
import ThreadTabs from './components/ThreadTabs.vue'
import FunnelPointsBreadcrumbs from './components/FunnelPointsBreadcrumbs.vue'
import ContactDetails from './components/ContactDetails.vue'
import ThreadList from './components/ThreadList.vue'
import ThreadAssignedList from './components/ThreadAssignedList.vue'
import RightToolbar from './components/RightToolbar.vue'
import ContactInfo from './components/ContactInfo.vue'
import Note from './components/Note.vue'

export default {
  components: {
    FunnelPointsBreadcrumbs,
    ContactInfo,
    ThreadTabs,
    Note,
    ContactDetails,
    ThreadList,
    ThreadAssignedList,
    RightToolbar,
  },
  data: () => ({
    loading: false,
    isMobile: false,

    router,
  }),
  watch: {
    async $route(to, from) {
      if (to.params.contactId !== from.params.contactId) {
        this.$store.state.contact.currentContact = null
        this.$store.state.contact.currentThread = null
        // this.$store.state.contact.funnels = []
        // this.$store.state.contact.funnelPoints = []
        await this.loadCurrentContact()
      }
    },
  },
  computed: {
    ...mapGetters({
      threadLoading: 'contact/threadLoading',
    }),
  },
  async mounted() {
    this.loading = true

    try {
      await this.loadCurrentContact()
    } catch (err) {
      this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)

      await this.router.push({ name: 'contacts' })
    }

    setTimeout(() => {
      this.$store.commit('contact/changeLoading', false)
    }, 1500)

    this.loading = false
  },
  beforeCreate() {
    this.$store.state.contact.currentContact = null
    this.$store.state.contact.currentThread = null
    this.$store.state.contact.funnels = []
    this.$store.state.contact.funnelPoints = []
  },
  created() {
    this.checkIfMobile()
    window.addEventListener('resize', this.checkIfMobile)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkIfMobile)
  },
  methods: {
    ...mapActions({
      loadCurrentContact: `contact/${LOAD_CURRENT_CONTACT}`,
    }),

    checkIfMobile() {
      this.isMobile = window.innerWidth <= 991 // Bootstrap's mobile breakpoint
    },
  },
}
</script>
