/* eslint-disable */
import axiosIns from '@/libs/axios'
import { GET_PRIVATE_SETTINGS, GET_SETTINGS, SAVE_SETTINGS } from '@/@constants/mutations'
import * as fields from '@/@constants/fields'
import axios from 'axios'

let source
export default {
  namespaced: true,
  state: {
    configLoading: false,
    config: {
      logo: '',
      signet: '',
      favicon: '',
      name: '',
      // this.$store.getters['system/getSettings'].defaultCurrency || 'PLN'

      // ...mapGetters({ settings: 'system/getSettings' }),
      defaultCurrency: 'PLN',
      currentDatetime: '',
      contactThreadOffersLimit: 0,
      contactThreadTaskDeleteTime: 15,
      removeGrossEnds: false,
      allowAgreementInCart: true,
      allowEditingOfferEmail: false,
      defaultCartOrderProcess: false,
      hideOrderProcessInCart: false,
      hideOfferProcessInCart: false,
      chatGptPrivacyAccepted: false,
      navBarConfig: [],
      configTables: null,
    },
    privateSettings: {},
  },
  getters: {
    getSettings: state => state.config,
    getPrivateSettings: state => state.privateSettings,
    getConfigLoading: state => state.configLoading,
  },
  mutations: {
    // eslint-disable-next-line no-unused-vars
    [GET_SETTINGS](state, payload) {
      state.config = payload
    },

    SET_PRIVATE_SETTINGS(state, payload) {
      state.privateSettings = payload
    },

    SET_CONFIG_LOADING(state, payload) {
      state.configLoading = payload
    },
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    [SAVE_SETTINGS]: ({ commit, dispatch, state }, { styles, nav, privateData = null, savePayload = null }) => new Promise((resolve, reject) => {
      let data = state.config
      // eslint-disable-next-line radix
      data.contactThreadOffersLimit = parseInt(data.contactThreadOffersLimit)
      // eslint-disable-next-line radix
      data.contactThreadTaskDeleteTime = parseInt(data.contactThreadTaskDeleteTime || 15)
      delete data.currentDatetime

      if (privateData) {
        data = privateData
      }

      if (savePayload) {
        data = savePayload
      }

      if (styles) data.styles = styles
      if (nav) {
        data.navBarConfig = nav
        data.navBarConfig = (data.navBarConfig || [])?.map(({ key, hide, position }) => ({
          key,
          hide,
          position,
        }))
      }

      if (savePayload) {
        data = savePayload
      }

      data.id = '1'
      axiosIns.patch('1/settings/global', data)
        .then(res => {
          resolve(res)
          dispatch(GET_SETTINGS)
          dispatch(GET_PRIVATE_SETTINGS)
        })
        .catch(err => {
          reject(err)
        })
    }),
    [GET_SETTINGS]: ({ commit, dispatch }, options) => new Promise((resolve, reject) => {
      const { showLoading = false } = options || {}
      const params = { fields_load: fields.SETTINGS_GLOBAL_PUBLIC }
      const config = { params }

      // if (showLoading) commit('SET_CONFIG_LOADING', true)

      // if (typeof source !== typeof undefined) {
      //   source.cancel('Operation canceled due to new request.')
      //   if (showLoading) commit('SET_CONFIG_LOADING', false)
      // }
      //
      // if (showLoading) {
      //   source = axios.CancelToken.source()
      //   config.cancelToken = source.token
      // }

      axiosIns.get('public/1/settings/global', config)
        .then(res => {
          const link = document.querySelector("link[rel*='icon']") || document.createElement('link')
          link.type = 'image/x-icon'
          link.rel = 'shortcut icon'
          link.href = `${res.data.data.item.favicon}`
          document.getElementsByTagName('head')[0].appendChild(link)

          source = undefined
          commit(GET_SETTINGS, { ...res.data.data.item })
          dispatch(GET_PRIVATE_SETTINGS)
          resolve(res.data.data.item)
        }).catch(err => {
          reject(err)
        }).finally(() => {
          setTimeout(() => {
            if (showLoading) commit('SET_CONFIG_LOADING', false)
          }, 2000)
        })
    }),
    [GET_PRIVATE_SETTINGS]: ({ commit }) => new Promise((resolve, reject) => {
      if (!localStorage.getItem('token')) return

      axiosIns.get('1/settings/global', { params: { fields_load: fields.SETTINGS_GLOBAL } })
        .then(res => {
          commit('SET_PRIVATE_SETTINGS', res.data.data.item)
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
  },
}
