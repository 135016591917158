<!--eslint-disable-->

<template>
  <div>
    <b-table
      style="min-height: 150px"
      :busy="busy"
      :items="products"
      :fields="fields"
      :sort-by.sync="sorter.sortBy"
      :sort-desc.sync="sorter.sortDesc"
      :no-local-sorting="true"
      striped
      responsive
      hover
      show-empty
      @sort-changed="$emit('change-sort', $event)"
    >
      <!--    Table Head    -->
      <template #head()="item">
        <span class="text-nowrap">
          {{ $t(item.field.label) | truncate(34, '...') }}
        </span>
      </template>

      <template #cell(id)="{ index }">
        #{{ getOrderNo(index, pagination, sorter) }}
      </template>

      <!--    Table Picture    -->
      <template #cell(addProduct)="{ item, index }">
        <div
          v-if="checkRequiredPermissions([$roles.CONTACT_THREAD_OFFER_ADD])"
          v-b-tooltip
          :title="!Boolean(item.shopProductStoragesSum) ? $t('ProductNotAvailableInStorage') : ''"
        >
          <b-button
            v-if="!productsInCart.includes(item.id)"
            variant="outline-success"
            class="btn-icon round"
            :disabled="!Boolean(item.shopProductStoragesSum) || !item.active"
            @click="$emit('addProductToCart', item)"
          >
            <feather-icon icon="ShoppingCartIcon" />
          </b-button>

          <b-button
            v-else
            variant="danger"
            class="btn-icon round"
            @click="$emit('remove-cart-product', item.id, index)"
          >
            <feather-icon icon="TrashIcon" />
          </b-button>
        </div>
        <feather-icon
          v-else
          icon="MinusIcon"
        />

      </template>

      <!--    Table Picture    -->
      <template #cell(pictures)="{ value }">
        <documents-list :documents="value" />
      </template>

      <!--    Table Product Name    -->
      <template #cell(name)="{ item }">
        <div v-if="item.translations && item.translations.length">
          <router-link
            v-b-tooltip.hover.left
            :to="{ name: 'productView', params: { productId: item.id } }"

            :title="selectedTranslationObject(item).name"
          >
            <span class="doubleLineText">
              {{ selectedTranslationObject(item).name | truncate(26, '...') }}
            </span>
          </router-link>
        </div>
      </template>

      <!--    Table Product Group    -->
      <template #cell(userGroups)="{ item }">
        <div v-if="item.shopCategories && item.shopCategories.length && item.shopCategories[0] && item.shopCategories[0].userGroups && item.shopCategories[0].userGroups[0] && item.shopCategories[0].userGroups[0].name">
          <span
            v-b-tooltip.hover.left

            :title="item.shopCategories[0].userGroups[0].name"
            class="doubleLineText"
          >
            {{ item.shopCategories[0].userGroups[0].name | truncate(26, '...') }}
          </span>
        </div>
        <feather-icon
          v-else
          icon="MinusIcon"
        />
      </template>

      <!--    Table Product Category    -->
      <template #cell(category)="{ item }">
        <div v-if="item.shopCategories && item.shopCategories.length && item.shopCategories[0] && item.shopCategories[0].translations">
          <span
            v-b-tooltip.hover.left

            :title="item.shopCategories[0].translations[0].name"
            class="doubleLineText"
          >
            {{ item.shopCategories[0].translations[0].name | truncate(26, '...') }}
          </span>
        </div>
      </template>

      <!--    Table Product Subcategory    -->
      <template #cell(subcategory)="{ item }">
        <div v-if="item.shopCategories && item.shopCategories.length && item.shopCategories[1] && item.shopCategories[1].translations">
          <span
            v-b-tooltip.hover.left

            :title="item.shopCategories[1].translations[0].name"
            class="doubleLineText"
          >
            {{ item.shopCategories[1].translations[0].name | truncate(26, '...') }}
          </span>
        </div>
      </template>

      <!--    Table Description    -->
      <template #cell(description)="{ item }">
        <div v-if="item.translations && item.translations.length && item.translations[0]">
          <span
            v-b-tooltip.hover.right
            :title="selectedTranslationObject(item).description | truncate(600, '...')"
            :inner-html.prop="(selectedTranslationObject(item).description || '') | truncate(34, '...')"
          />
        </div>
      </template>

      <!--    Table Product VAT    -->
      <template #cell(vat)="{ item }">
        {{ item.vat }}%
      </template>

      <!--    Table Product Amount    -->
      <template #cell(amount)="{ value }">
        <b-badge variant="primary">
          {{ $t('Left') + ': ' + value.max }}
        </b-badge>
      </template>

      <template #cell(offerPriceGross)="{ item }">
        {{ item.offerPrice * ((item.vat / 100) + 1) || 0 | priceGrossFormat }} {{ $store.getters['system/getSettings'] ? ($store.getters['system/getSettings'].defaultCurrency || 'PLN') : 'PLN' }}
      </template>
      <template #cell(offerPrice)="{ value }">
        {{ value || 0 | priceFormat }} {{ $store.getters['system/getSettings'] ? ($store.getters['system/getSettings'].defaultCurrency || 'PLN') : 'PLN' }}
      </template>

      <!--    Table Product Number of reservations    -->
      <template #cell(numberReservations)="{ value }">
        <b-badge variant="warning">
          {{ $t('Offer.Together') + ': ' + value.max }}
        </b-badge>
      </template>

      <!--    Table Product Quantity of sold    -->
      <template #cell(quantitySold)="{ value }">
        <b-badge variant="warning">
          {{ $t('Offer.Together') + ': ' + value.max }}
        </b-badge>
      </template>

        <template #cell(webUrl)="{ value }">
            <b-badge @click="redirectTo(value)" v-if="value" v-b-tooltip :title="value" variant="primary">
                <feather-icon icon="LinkIcon" />
            </b-badge>
            <feather-icon
                    v-else
                    icon="MinusIcon"
            />
        </template>

      <!--    Loyalty plans   -->
      <template #cell(loyaltyPlan)="{ item, index }">
        <span v-if="(item.shopProductLoyaltyPlans || []).length">
          <div
            v-for="(plan, planIndex) in item.shopProductLoyaltyPlans"
            :key="`product_${index}_plan_${planIndex}`"
          >
            <b-badge
              v-if="plan.loyaltyPlan"
              variant="light-primary"
            >
              {{ plan.loyaltyPlan.name }}
            </b-badge>
          </div>
        </span>
        <feather-icon
          v-else
          icon="MinusIcon"
        />
      </template>

      <!--    Marketing plans   -->
      <template #cell(marketingPlan)="{ item, index }">
        <span v-if="(item.shopProductMarketingPlans || []).length">
          <div
            v-for="(plan, planIndex) in item.shopProductMarketingPlans"
            :key="`product_marketing_${index}_plan_${planIndex}`"
          >
            <b-badge
              v-if="plan.marketingPlan"
              variant="light-primary"
            >
              {{ plan.marketingPlan.name }}
            </b-badge>
          </div>
        </span>
        <feather-icon
          v-else
          icon="MinusIcon"
        />
      </template>

      <!--    Table Product is active    -->
      <template #cell(active)="{ item }">
        <span class="d-flex justify-content-center">
          <b-badge
            v-if="Boolean(item.shopProductStoragesSum) && item.active"
            v-b-tooltip.hover.v-success
            variant="light-success"
            pill
            :title="$t('Active')"
          >
            <feather-icon icon="CheckIcon" />
          </b-badge>
          <b-badge
            v-else-if="!Boolean(item.shopProductStoragesSum) && item.active"
            v-b-tooltip.hover.v-warning
            variant="light-warning"
            pill
            :title="$t('ProductNotAvailableInStorage')"
          >
            <feather-icon icon="MinusIcon" />
          </b-badge>
          <b-badge
            v-else
            v-b-tooltip.hover.v-danger
            variant="light-danger"
            pill
            :title="$t('InActive')"
          >
            <feather-icon icon="MinusIcon" />
          </b-badge>
        </span>
      </template>

      <!--    Table Action    -->
      <template #cell(action)="{ item, index }">
        <b-dropdown
          v-if="checkRequiredPermissions([$roles.SHOP_PRODUCT_MODIFY])"
          class="dropdown-icon-wrapper text-decoration-none"
          dropleft
          no-caret
          variant="flat"
        >
          <template
            #button-content
            class="sr-only"
          >
            <feather-icon icon="MoreVerticalIcon" />
          </template>
            <!--    Action: Active    -->
            <b-dropdown-item @click="$emit('toggleActivity', item)">
                <dropdown-item-label
                        :icon="!item.active ? 'CheckIcon' : 'XIcon'"
                        :label="!item.active ? $t('Activate') : $t('Deactivate')"
                />
            </b-dropdown-item>

            <b-dropdown-divider />

            <!--    Action: Copy    -->
            <b-dropdown-item @click="copy(item.id)">
                <dropdown-item-label icon="CopyIcon" :label="$t('Copy')" />
            </b-dropdown-item>

          <!--    Action: Edit    -->
          <b-dropdown-item @click="$router.push({ name: 'productView', params: { productId: item.id } })">
            <dropdown-item-label icon="EditIcon" :label="$t('Edit')" />
          </b-dropdown-item>


          <!--    Action: Delete    -->
          <b-dropdown-item @click="remove(item, index)">
            <dropdown-item-label icon="TrashIcon" :label="$t('Delete')" />
          </b-dropdown-item>
        </b-dropdown>
        <feather-icon
          v-else
          icon="MinusIcon"
        />
      </template>

      <!--    Other    -->
        <!--    Other    -->
        <template #cell()="row">
            <table-default-cell
                    :field="row.field"
                    :value="row.value"
            />
        </template>

        <!--      Empty      -->
        <template #empty>
            <empty-content />
        </template>

        <!--      Table Busy      -->
        <template #table-busy>
            <div class="text-center text-danger my-2">
                <b-spinner
                        class="align-middle"
                        variant="primary"
                />
            </div>
        </template>
    </b-table>
  </div>
</template>

<script>
/* eslint-disable */

import { BTable, VBTooltip } from 'bootstrap-vue'
import DocumentsList from '@/views/components/DocumentsList.vue'
import { mapGetters } from 'vuex'
import {
  GET_PRODUCT,
  GET_PRODUCT_ATTRIBUTES,
  SAVE_PRODUCT,
  SAVE_PRODUCT_ATTRIBUTES,
  UPDATE_PRODUCT_ATTRIBUTES,
} from '@/@constants/mutations'
import payloadKeysCutter from '@/helpers/keysCuter'
import { priceGrossFormat } from '@core/filters/parts/valueFormat'

export default {
  components: {
    BTable,
    DocumentsList,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    total: {
      default: 100,
      type: Number,
    },
    selectedLocale: {
      default: 'pl_pl',
      type: String,
    },
    busy: {
      default: false,
      type: Boolean,
    },
    pagination: {
      default: null,
    },
    productFields: Array,
    sorter: {
      type: Object,
      default: () => ({
        sortBy: 'id',
        sortDesc: true,
      }),
    },
    products: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
      required: true,
    },
  },
  computed: {
    fields() {
      return this.productFields.filter(field => field.visible)
    },
    ...mapGetters({
      productsInCart: 'cart/getCartProductsIds',
    }),
  },
  methods: {
    selectedTranslationObject(listProduct) {
        const { selectedLocale } = this

        return listProduct.translations.find(({ locale }) => locale === selectedLocale) || { name: '-', description: '' }
    },
    priceGrossFormat,
      redirectTo(url) {
          window.open(url, '_blank')
      },
    /*    Remove removes products    */
    remove(product, index) {
      this.$emit('removeProduct', product, index)
    },
    pushAttributes(attributes, productId) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(`products/${SAVE_PRODUCT_ATTRIBUTES}`, { id: productId, attributes })
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            reject(err)
          })
      })
    },
    updateAttributes(attributes, productId) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(`products/${UPDATE_PRODUCT_ATTRIBUTES}`, { id: productId, attributes })
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            reject(err)
          })
      })
    },
    copy(id) {
      this.$store.dispatch(`products/${GET_PRODUCT}`, id)
        .then(({ data }) => {
          const { item } = data
          const itemId = item.id

          delete item.id

          item.shopProductPictures.map((_, index) => delete item.shopProductPictures[index].id)

          item.documents.forEach((el, index) => { item.documents[index] = el.file.token })
          item.relatedShopProducts.forEach((el, index) => { item.relatedShopProducts[index] = el.id })
          item.shopCategories.forEach((el, index) => { item.shopCategories[index] = el.id })
          item.shopProductDiscounts.forEach((el, index) => {
            delete item.shopProductDiscounts[index].id

            item.shopProductDiscounts[index].endAt = el.endAt.date
            item.shopProductDiscounts[index].startAt = el.startAt.date
          })
          item.shopProductHistories = []
          item.shopProductPictures.forEach((el, index) => {
            item.shopProductPictures[index].file = el.file.token

            item.shopProductPictures[index].shopProductPictureType = el.shopProductPictureType.id
          })
          item.translations.forEach((el, index) => {
            delete item.translations[index].id
          })

          item.shopProductStoragesSum = 1

          this.$store.dispatch(`products/${SAVE_PRODUCT}`, item)
            .then(newItem => {
              const newItemId = newItem.data.items[0].id

              this.$store.dispatch(`products/${GET_PRODUCT_ATTRIBUTES}`, itemId)
                .then(res => {
                  const attributes = res.data.items
                  const attributesCopy = JSON.parse(JSON.stringify(attributes))

                  attributesCopy.forEach(i => {
                    // eslint-disable-next-line
                    i.shopAttribute = typeof i.shopAttribute === 'object' ? i.shopAttribute.id : i.shopAttribute
                    // eslint-disable-next-line,no-nested-ternary
                    i.valueShopAttributeOptions = Array.isArray(i.valueShopAttributeOptions)
                      ? i.valueShopAttributeOptions.map(value => (typeof value === 'string' ? value : value.id))
                      : [typeof i.valueShopAttributeOptions === 'object' ? i.valueShopAttributeOptions.id : item.valueShopAttributeOptions]
                  })

                  payloadKeysCutter(attributesCopy, ['shopProduct'])

                  Promise.all([this.pushAttributes(attributesCopy, newItemId)])
                    .then(() => {
                      this.$emit('copy')
                    })
                    .catch(err => {
                      this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
                    })
                })
                .catch(err => {
                  this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
                  this.loading = false
                })
            })
            .catch(err => {
              this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
              this.loading = false
            })
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          this.loading = false
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style>
.doubleLineText {
  width: 9rem !important;

  display: inline-block;

  overflow-wrap: anywhere;

  white-space: normal;
}
</style>
