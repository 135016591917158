<template>
  <div>
    <div class="d-flex flex-column align-items-center">
      <b-avatar
        :size="user.position === 1 ? 84 : 64"
        variant="light-primary"
        :src="user.avatar"
        :text="avatarText(getUserName(user))"
      />

      <b-badge
        variant="success"
        pill
        class="mt-n1 p-0 zindex-1 d-flex align-items-center justify-content-center"
        style="width: 1.5rem; height: 1.5rem"
      >
        {{ user.position }}
      </b-badge>
    </div>

    <div class="d-flex flex-column align-items-center mt-25">
      <span>{{ getUserName(user) }}</span>
      <div class="d-flex">
        <b-badge
          v-if="user.userId"
          v-b-tooltip
          variant="light-primary"
          class="mr-25"
          :title="$t('contact.userId')"
        >
          <feather-icon icon="ToolIcon" />
          {{ user.userId }}
        </b-badge>
        <b-badge variant="primary"
                 class="d-flex justify-content-center align-items-center"
        >
          <feather-icon icon="ShoppingCartIcon"
                        class="mr-25"
                        size="18"
          />
          {{ user.totalSystemAmount }}
        </b-badge>
      </div>
    </div>
  </div>
</template>

<script>
import { avatarText } from '@core/utils/filter'
import { VBTooltip } from 'bootstrap-vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  methods: { avatarText },
}
</script>
