<template>
  <b-row style="row-gap: 1rem">
    <b-col
      md="6"
      lg="4"
    >
      <sw-select
        :name="$t('AccordingToSale')"
      >
        <v-select
          :options="[
            'numberOfOrders',
            'offers',
            'arrangements',
          ]"
          multiple
        >
          <template #no-options>
            {{ $t('NoOptions') }}
          </template>

          <template #option="{ label }">
            {{ $t(label) }}
          </template>

          <template #selected-option="{ label }">
            {{ $t(label) }}
          </template>
        </v-select>
      </sw-select>
    </b-col>

    <b-col
      md="6"
      lg="4"
    >
      <sw-select :name="$t('ByUser')">
        <assigned-users
          :is-multiple="true"
          :mode="'<contacts>show all contacts</contacts>'"
        />
      </sw-select>
    </b-col>

    <b-col sm="12">
      <b-form-checkbox
        variant="primary"
        switch
      >
        {{ $t('ShowSubUsers') }}
      </b-form-checkbox>
    </b-col>
  </b-row>
</template>

<script>
import AssignedUsers from '@/views/components/AssignedUsers.vue'
import vSelect from 'vue-select'
import { Polish as pl } from '@/libs/i18n/locales/flatpickr/pl'
import { english as en } from '@/libs/i18n/locales/flatpickr/en'

export default {
  components: {
    AssignedUsers,
    vSelect,
  },
  data: () => ({
    flatPickerConfig: {
      mode: 'range',
      locale: [pl, en],
    },
  }),
  computed: {
    locale() {
      return this.$i18n.locale
    },
  },
}
</script>
