<template>
  <div>
    <div class="d-flex justify-content-between">
      <b-button
        variant="primary"
        size="sm"
        @click="showModal = true"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-25"
        />
        {{ $t('Add') }}
      </b-button>
    </div>
    <b-card class="mt-1">
      <b-row>
        <b-col>
          <sw-table
            table-id="505dff3e74384e9ab226c80fe1437421"
            :pagination="pagination"
            @change-pagination="Object.assign(pagination, $event)"
            @reload-content="loadTypes"
          >
            <template #table>
              <b-table
                ref="selectableTable"
                :items="typeItems"
                class="table-wrap-words"
                :fields="typeFields"
                striped
                responsive
                :busy="loading"
                show-empty
              >
                <template #head()="{ label }">
                  {{ $t(`${label}`) }}
                </template>
                <template #cell(createdBy)="{ value }">
                  <avatar :user="value" />
                </template>
                <!-- Action -->
                <template #cell(action)="{ item }">
                  <b-dropdown
                    id="dropdown-dropleft"
                    dropleft
                    no-caret
                    variant="flat"
                  >
                    <template #button-content>
                      <feather-icon icon="MoreVerticalIcon" />
                    </template>
                    <b-dropdown-item
                      @click="editInvestment(item)"
                    >
                      {{ $t('Edit') }}
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="deleteInvestment(item, index)"
                    >
                      {{ $t('Delete') }}
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
                <!-- Other -->
                  <!--    Other    -->
                  <template #cell()="row">
                      <table-default-cell
                              :field="row.field"
                              :value="row.value"
                      />
                  </template>

                  <!--      Empty      -->
                  <template #empty>
                      <empty-content />
                  </template>

                  <!--      Table Busy      -->
                  <template #table-busy>
                      <div class="text-center text-danger my-2">
                          <b-spinner
                                  class="align-middle"
                                  variant="primary"
                          />
                      </div>
                  </template>
              </b-table>
            </template>
          </sw-table>
        </b-col>
      </b-row>
      <b-modal
        v-model="showModal"
        :title="editedType.id ? $t('New') : $t('Edit')"
        hide-footer
        no-close-on-backdrop
        @hidden="closeModal"
      >
        <b-form-group>
          <b-form-input
            id="basicInput"
            v-model="editedType.name"
            :placeholder="$t('Name')"
          />
        </b-form-group>
        <!-- Footer -->
        <div class="d-flex justify-content-end pt-1 mt-1 border-top">
          <b-button
            variant="secondary mr-1"
            @click="onModalClose"
          >
            {{ $t('Cancel') }}
          </b-button>
          <b-button
            :disabled="!editedType.name"
            variant="primary"
            @click="saveTypes"
          >
            {{ $t('Save') }}
          </b-button>
        </div>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import { BTable, VBTooltip } from 'bootstrap-vue'
import axiosIns from '@/libs/axios'

const emptyTypes = {
  name: '',
}

export default {
  name: 'AgreementTypes',
  components: {
    BTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data: () => ({
    sorter: {
      sortBy: 'createdAt',
      sortDesc: true,
    },
    pagination: {
      perPage: 25,
      currentPage: 1,
      totalRecords: 1,
    },
    searchQuery: '',
    typeFields: [
      { key: 'name', label: 'Name', visible: true },
      { key: 'createdBy', label: 'CreatedBy', visible: true },
      { key: 'action', label: 'Action', visible: true },
    ],
    loading: false,
    // Edit
    showModal: false,
    editedType: { ...emptyTypes },
    groups: [],
    typeItems: [],
  }),
  mounted() {
    this.loadTypes()
  },
  methods: {
    async loadTypes() {
      this.loading = true
      try {
        const params = {
          page: this.pagination.currentPage,
          limit: this.pagination.perPage,
          fields_load: this.$fields.SETTINGS_AGREEMENTS_TYPES,
        }
        const resp = await axiosIns.get('1/settings/agreementTypes', { params })
        this.typeItems = resp.data?.data?.items || []
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.loading = false
      }
    },
    editInvestment(item) {
      this.showModal = true
      this.editedType = { ...item }
    },
    onModalClose() {
      this.showModal = false
      this.editedType = { ...emptyTypes }
    },
    deleteInvestment(item) {
      axiosIns.delete('1/settings/agreementTypes', { data: [item.id] })
        .then(() => {
          this.showToast('success', this.$i18n.t('RemovedSuccessfully'))
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
        .finally(() => {
          this.loadTypes()
        })
    },
    closeModal() {
      this.editedType = { ...emptyTypes }
      this.showModal = false
    },
    saveTypes() {
      this.showModal = false
      this.loading = true
      const type = this.editedType
      delete type.createdBy
      delete type.agreementTemplates

      if (type.id) {
        // Edit
        axiosIns.patch('1/settings/agreementTypes', type)
          .then(() => {
            this.showToast('success', this.$i18n.t('success.contactUpdated'))
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          })
          .finally(() => {
            this.loading = false
            this.editedType = { ...emptyTypes }
            this.loadTypes()
          })
      } else {
        // New
        axiosIns.post('1/settings/agreementTypes', type)
          .then(() => {
            this.showToast('success', this.$i18n.t('success.contactUpdated'))
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          })
          .finally(() => {
            this.loading = false
            this.editedType = { ...emptyTypes }
            this.loadTypes()
          })
      }
    },
  },
}
</script>

<style scoped>

</style>
