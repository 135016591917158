<template>
  <component
    :is="$route.name !== 'timeline' ? 'div' : 'b-card'"
    :class="{ 'mb-1': $route.name !== 'timeline' }"
  >
    <h4 class="mb-1">
      {{ $t('Statistics') }}
    </h4>

    <div class="d-flex flex-wrap justify-content-between justify-content-md-start mt-n1">
      <div
        v-for="(item, index) in cards"
        :key="`card_${index}`"
        class="mr-1 mt-1"
        :class="{ 'd-none': !checkRequiredViewPermissions(item.requiredViews) || requiredModule(item.requiredModule || '') }"
      >
        <timeline-stats-card
          v-if="checkRequiredPermissions(item.requiredPermissions)"
          :icon="item.icon"
          :value="values[item.valueKey]"
          :title="$tc(`${item.title}`, 2)"
          :class="{ 'border': border }"
        />
      </div>
    </div>
  </component>
</template>

<script>
import TimelineStatsCard from '@/views/core/globalTimeline/TimelineStatsCard.vue'
import {
  CONTACT_THREAD_ASSIGNED_OFFER_VIEW_ALL,
  CONTACT_THREAD_ASSIGNED_OFFER_VIEW_SELF,
  CONTACT_THREAD_ASSIGNED_PAYMENT_VIEW_ALL,
  CONTACT_THREAD_ASSIGNED_PAYMENT_VIEW_SELF,
  CONTACT_THREAD_CHECKLIST_VIEW_ALL,
  CONTACT_THREAD_MAIL_VIEW_ALL,
  CONTACT_THREAD_NOTE_VIEW_ALL,
  CONTACT_THREAD_NOTE_VIEW_SELF,
  CONTACT_THREAD_NOTE_VIEW_SHARED,
  CONTACT_THREAD_OFFER_VIEW_ALL,
  CONTACT_THREAD_PAYMENT_VIEW_ALL,
  CONTACT_THREAD_SMS_VIEW_ALL,
  CONTACT_THREAD_SMS_VIEW_SELF,
  CONTACT_THREAD_SMS_VIEW_SHARED,
  CONTACT_THREAD_TASK_VIEW_ALL,
  CONTACT_THREAD_TASK_VIEW_ASSIGNED, CONTACT_THREAD_TASK_VIEW_STRUCTURE,
} from '@/helpers/permissions'

export default {
  name: 'TimelineStats',
  components: {
    TimelineStatsCard,
  },
  props: {
    border: {
      type: Boolean,
      default: false,
    },
    notes: {
      type: Number,
      default: 0,
    },
    mails: {
      type: Number,
      default: 0,
    },
    tasks: {
      type: Number,
      default: 0,
    },
    smses: {
      type: Number,
      default: 0,
    },
    checklists: {
      type: Number,
      default: 0,
    },
    offers: {
      default: 0,
    },
    orders: {
      default: 0,
    },
    payments: {
      default: 0,
    },
    automations: {
      default: 0,
    },
  },
  data: vm => ({
    cards: [
      {
        icon: 'FileIcon',
        title: 'timeline.ContactThreadNote',
        valueKey: 'notes',
        requiredPermissions: [
          CONTACT_THREAD_NOTE_VIEW_ALL,
          CONTACT_THREAD_NOTE_VIEW_SELF,
          CONTACT_THREAD_NOTE_VIEW_SHARED,
        ],
        requiredViews: [vm.$viewRanks.SHOW_CONTACT_CARD_NOTES],
      },
      {
        icon: 'ListIcon',
        title: 'timeline.ContactThreadTask',
        valueKey: 'tasks',
        requiredPermissions: [CONTACT_THREAD_TASK_VIEW_ALL, CONTACT_THREAD_TASK_VIEW_ASSIGNED, CONTACT_THREAD_TASK_VIEW_STRUCTURE],
        requiredViews: [vm.$viewRanks.SHOW_CONTACT_CARD_TASKS],
      },
      {
        icon: 'CheckCircleIcon',
        title: 'timeline.ContactThreadChecklistPointDatum',
        valueKey: 'checklists',
        requiredPermissions: [CONTACT_THREAD_CHECKLIST_VIEW_ALL],
        requiredViews: [vm.$viewRanks.SHOW_CONTACT_CARD_CHECKLISTS],
      },
      {
        icon: 'MessageCircleIcon',
        title: 'timeline.ContactThreadSms',
        valueKey: 'smses',
        requiredPermissions: [
          CONTACT_THREAD_SMS_VIEW_ALL,
          CONTACT_THREAD_SMS_VIEW_SHARED,
          CONTACT_THREAD_SMS_VIEW_SELF,
        ],
        requiredViews: [vm.$viewRanks.SHOW_CONTACT_CARD_SMS],
      },
      {
        icon: 'MailIcon',
        title: 'timeline.Mail',
        valueKey: 'mails',
        requiredPermissions: [CONTACT_THREAD_MAIL_VIEW_ALL],
        requiredViews: [vm.$viewRanks.SHOW_CONTACT_CARD_EMAILS],
      },
      {
        icon: 'ArchiveIcon',
        title: 'offer.Offer',
        valueKey: 'offers',
        requiredModule: 'offerModule',
        requiredPermissions: [
          CONTACT_THREAD_OFFER_VIEW_ALL,
          CONTACT_THREAD_ASSIGNED_OFFER_VIEW_ALL,
          CONTACT_THREAD_ASSIGNED_OFFER_VIEW_SELF,
        ],
        requiredViews: [vm.$viewRanks.SHOW_CONTACT_CARD_OFFERS],
      },
      {
        icon: 'ShoppingBagIcon',
        title: 'Orders',
        valueKey: 'orders',
        requiredModule: 'orderModule',
        requiredPermissions: [
          CONTACT_THREAD_OFFER_VIEW_ALL,
          CONTACT_THREAD_ASSIGNED_OFFER_VIEW_ALL,
          CONTACT_THREAD_ASSIGNED_OFFER_VIEW_SELF,
        ],
        requiredViews: [vm.$viewRanks.SHOW_CONTACT_CARD_ORDERS],
      },
      {
        icon: 'DollarSignIcon',
        title: 'Schedules',
        valueKey: 'payments',
        requiredModule: 'orderModule',
        requiredPermissions: [
          CONTACT_THREAD_PAYMENT_VIEW_ALL,
          CONTACT_THREAD_ASSIGNED_PAYMENT_VIEW_ALL,
          CONTACT_THREAD_ASSIGNED_PAYMENT_VIEW_SELF,
        ],
        requiredViews: [vm.$viewRanks.SHOW_CONTACT_CARD_PAYMENTS],
      },
      {
        icon: 'CpuIcon',
        title: 'Automations',
        valueKey: 'automations',
        requiredPermissions: [vm.$roles.AUTOMATION_VIEW_ALL],
        requiredModule: 'automationModule',
        requiredViews: [vm.$viewRanks.SHOW_CONTACT_CARD_AUTOMATIONS],
      },
    ],
  }),
  computed: {
    values() {
      return this
    },
  },
  methods: {
    requiredModule(module) {
      if (!module) return false
      return !this.checkRequiredModule(module)
    },
  },
}
</script>

<style scoped>

</style>
