<!-- TODO:Card Info -->
<template>
  <b-card
    no-body
    class="mb-75"
    footer-class="px-75 py-50 d-flex align-items-center justify-content-end"
  >
    <b-card-header
      class="text-white px-75 py-25"
      :style="`background-image: linear-gradient(to right, var(--primary) 40%, ${!item.color || item.color === 'default' ? defaultColor : item.color})`"
    >
      <slot name="header" />

      <div
        v-if="!$slots.header"
        class="d-flex align-items-center"
        style="gap: 1rem"
        :style="{ opacity: enabled ? 1 : .6 }"
      >
        {{ title | truncate(Number(titleMax)) }}

        <!--          <sw-icon-->
        <!--            v-if="!item.connected"-->
        <!--            class="mt-n25"-->
        <!--            icon="AlertTriangleIcon"-->
        <!--          />-->
        <slot name="start" />
      </div>

      <!--    START::Toolbar    -->
      <div
        class="d-flex align-items-center"
        style="gap: .34rem"
      >
        <slot name="end" />

        <avatar-group
          v-if="item.assignedContacts"
          :items="item.assignedContacts"
        />

        <b-button-group v-if="actionRefresh">
          <b-button
            v-if="actionRefresh"
            v-b-tooltip.hover.v-primary
            variant="flat-light"
            size="sm"
            class="p-25 btn-icon"
            :title="$t('CheckConnection')"
            :disabled="loading"
          >
            <sw-icon icon="RefreshCwIcon" />
          </b-button>
        </b-button-group>

        <b-button-group v-if="actionEdit || actionRemove">
          <b-button
            v-if="actionEdit"
            v-b-tooltip.hover.v-success
            variant="flat-light"
            size="sm"
            class="p-25 btn-icon"
            :title="$t('Edit')"
            :disabled="loading"
          >
            <sw-icon icon="Edit2Icon" />
          </b-button>

          <b-button
            v-if="actionRemove"
            v-b-tooltip.hover.v-danger
            variant="flat-light"
            size="sm"
            class="p-25 btn-icon"
            :title="$t('Delete')"
            :disabled="loading"
            @click.stop="$emit('remove', item)"
          >
            <sw-icon icon="TrashIcon" />
          </b-button>
        </b-button-group>
      </div>
      <!--    END::Toolbar    -->
    </b-card-header>

    <div
      v-if="!$slots.body"
      class="px-1 py-2"
    >
      <slot />
    </div>

    <slot name="body" />

    <template #footer>
      <div class="d-flex align-items-center justify-content-between w-100">
        <div class="d-flex align-items-center gap-0.5">
          <slot name="footer-start" />

          <b-spinner
            v-if="pending"
            small
            variant="primary"
          />
        </div>

        <div class="d-flex align-items-center gap-0.75">
          <slot name="footer-end" />

          <div
            v-if="item.createdAt"
            v-b-tooltip.hover.v-primary
            class="d-flex align-items-center"
            style="gap: .34rem"
            :title="$t('CreatedTime')"
          >
            <sw-icon
              icon="ClockIcon"
              style="margin-top: -.1rem"
            />

            {{ item.createdAt.date | dateFormat }}
          </div>
        </div>
      </div>
    </template>
  </b-card>
</template>

<script>
import { BButtonGroup, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: { BButtonGroup },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    titleMax: {
      type: [String, Number],
      required: false,
      default: 34,
    },
    enabled: {
      type: Boolean,
      required: false,
      default: true,
    },
    item: {
      type: Object,
      required: true,
    },
    defaultColor: {
      type: String,
      required: false,
      default: 'var(--primary)',
    },

    actionEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
    actionRemove: {
      type: Boolean,
      required: false,
      default: false,
    },
    actionRefresh: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['remove'],
  data: () => ({
    loading: false,
    pending: false,
  }),
}
</script>
