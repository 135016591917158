<template>
  <div>
    <b-row
      v-if="categoryList.length"
      class="w-100 m-0"
      :style="`row-gap: ${!small ? .84 : .5}rem; min-height: 4rem;`"
    >
      <b-col
        v-for="category in categoryList.slice(0, maxLength || categoryList.length)"
        :key="category.id"
        :sm="!small ? 12 : 12"
        :md="!small ? 4 : 12"
        :lg="!small ? 3 : 12"
      >
        <b-badge
          v-b-tooltip.hover.v-primary
          variant="light-primary"
          class="d-flex justify-content-between align-items-center py-50 px-1"
          :title="`${category.value.done} / ${category.value.total}`"
        >
          {{ category.key }}

          <b-badge variant="primary">
            {{ category.value.donePercent }}%
          </b-badge>
        </b-badge>
      </b-col>
    </b-row>

    <div
      v-else
      class="text-center py-1 text-primary font-weight-bold"
    >
      <feather-icon
        size="17"
        icon="XCircleIcon"
      />

      {{ $t('EmptyArray') }}
    </div>

    <b-overlay
      no-wrap
      :show="isLoading || isMeLoading"
      spinner-variant="primary"
    />
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'
import TargetInfo from '@/pages/projects/components/new/components/Info.vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    TargetInfo,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    project: {
      type: Object,
      required: false,
      default: null,
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
    maxLength: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data: () => ({
    isMeLoading: false,

    categoryList: [],
  }),
  watch: {
    project: {
      deep: true,
      handler(n) { this.getTasks(n) },
    },
  },
  mounted() {
    this.ini()
  },
  methods: {
    ini() {
      this.getTasks(this.project)
    },

    getTasks(project) {
      if (project.projectCategoryCompletedTaskCount) {
        this.isMeLoading = true

        const list = Object.keys(project.projectCategoryCompletedTaskCount).map(key => (
          { key, value: project.projectCategoryCompletedTaskCount[key] }
        ))

        this.categoryList = list

        this.isMeLoading = false
      }
    },
  },
}
</script>
