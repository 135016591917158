// eslint-disable-next-line import/prefer-default-export
export const news = [
  {
    path: '/news/list',
    name: 'newsList',
    component: () => import('@/pages/news/List.vue'),
    meta: {
      autorize: true,
      pageTitle: 'NewsList',
    },
  },
  {
    path: '/news/post/:id',
    name: 'newsPost',
    component: () => import('@/pages/news/Post.vue'),
    meta: {
      autorize: true,
      pageTitle: 'NewsPost',
    },
  },
  {
    path: '/news/preview',
    name: 'newsPreview',
    component: () => import('@/pages/news/Post.vue'),
    meta: {
      autorize: true,
      pageTitle: 'NewsPreview',
    },
  },
  {
    path: '/news/new',
    name: 'newsNew',
    component: () => import('@/pages/news/Edit.vue'),
    meta: {
      autorize: true,
      pageTitle: 'NewsNew',
    },
  },
  {
    path: '/news/edit/:id',
    name: 'newsEdit',
    component: () => import('@/pages/news/Edit.vue'),
    meta: {
      autorize: true,
      pageTitle: 'NewsEdit',
    },
  },
]
