<template>
  <div class="d-inline">
    <div v-if="fieldComponent === 'file'">
      <documents-list
        v-if="Array.isArray(value) && value.length"
        :documents="value"
        :index="`_${Math.random().toString(36).substr(2, 9)}`"
      />
      <feather-icon
        v-else
        icon="MinusIcon"
      />
    </div>
    <div v-else-if="fieldComponent === 'avatar'">
      <avatar-group v-if="Array.isArray(value) && value.length"
                    :items="value"
                    v-bind="{ ...getProps(componentProps, value) }"
      />
      <avatar v-else-if="!Array.isArray(value) && value"
              :user="value"
              v-bind="{ ...getProps(componentProps, value) }"
      />
      <feather-icon
        v-else
        icon="MinusIcon"
      />
    </div>
    <component
      :is="fieldComponent"
      v-else
      v-bind="{ ...getProps(componentProps, value) }"
      class="text-nowrap"
    >
      <span v-if="value.date">
        {{ formatDate(value.date, { month: 'numeric', day: 'numeric', year: 'numeric', ...timeFormat }, $i18n.locale) }}
      </span>
      <span v-else-if="value.name"
            v-b-tooltip
            :title="value.name"
      >
        {{ value.name | truncate(35) }}
      </span>
      <span v-else>
        <span v-if="typeof value === 'boolean'">
          <b-badge :variant="value ? 'light-success' : 'light-danger'">
            <feather-icon :icon="value ? 'CheckIcon' : 'XIcon'" />
          </b-badge>
        </span>
        <span v-else-if="typeof value === 'number'">
          {{ value }}
        </span>
        <span v-else-if="getValue"
              v-b-tooltip
              :title="getValue"
        >
          {{ getValue | truncate(35) }}
        </span>
        <feather-icon
          v-else
          icon="MinusIcon"
        />
      </span>
    </component>
  </div>
</template>

<script>
/**
 * @Props
 * {
 *   @Any    value,
 *   @Object field {
 *      @Object props : {
 *        @Any ...BootstrapComponentProps,
 *        @Function variantFormatter
 *      }
 *
 *      @String tag [BootstrapComponent, HtmlTags]
 *
 *      @Boolean hideTime
 *      @Boolean translate
 *
 *      @Function valueFormatter
 *   }
 * }
 *
 * @Example
 * @Description - Avatar - AvatarGroup
 * { key: 'key', label: 'translation_label', visible: true, tag: 'avatar', }
 *
 * @Example
 * @Description - Badge, Translation, Value callback formatter, Variant prop formatter
 * {
 *         key: 'key',
 *         label: 'translation_label',
 *         visible: true,
 *         translate: true,
 *         valueFormatter: complaintStatusByValue,
 *         tag: 'b-badge',
 *         props: {
 *           variantFormatter: complaintStatusVariantByValue,
 *         },
 *       },
 *
 * @Example
 * @Description - Date without time -
 * { key: 'key', label: 'translation_label', visible: true, hideTime: true, }
 *
 * @Example
 * @Description - Date -
 * { key: 'key', label: 'translation_label', visible: true, }
 */
import { formatDate } from '@core/utils/filter'
import DocumentsList from '@/views/components/DocumentsList.vue'
import { VBTooltip } from 'bootstrap-vue'

export default {
  name: 'TableDefaultCell',
  components: { DocumentsList },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    value: {
      required: true,
      default: '',
    },
    field: {
      required: true,
      default: null,
    },
  },
  computed: {
    getValue() {
      const value = this.field.valueFormatter ? this.field.valueFormatter(this.value) : this.value

      if (this.isTranslationField) {
        return this.$i18n.t(value)
      }

      return value
    },
    componentProps() {
      return this.field?.props || {}
    },
    fieldComponent() {
      return this.field?.tag || 'div'
    },
    timeFormat() {
      return this.field?.hideTime ? {} : { hour: 'numeric', minute: 'numeric' }
    },
    isTranslationField() {
      return Boolean(this.field?.translate)
    },
  },
  methods: {
    formatDate,
    getProps(props, value) {
      // eslint-disable-next-line no-underscore-dangle
      let _props = this.componentProps || {}

      if (_props?.variantFormatter) {
        _props.variant = _props.variantFormatter(value)
      }

      _props = JSON.parse(JSON.stringify(_props))
      delete _props.variantFormatter

      return _props
    },
  },
}
</script>

<style scoped>

</style>
