export const BASE_ID = 'BASE_ID'
export const USER_MARKETING_PLANS = 'USER_MARKETING_PLANS'
export const MARKETING_PLAN_POINTS = 'MARKETING_PLAN_POINTS'
export const MARKETING_PLAN_POINT_PAYMENTS = 'MARKETING_PLAN_POINT_PAYMENTS'
export const AUTENTI_CONNECTIONS = 'AUTENTI_CONNECTIONS'
export const OFFER_PRICE_CHANGE = 'OFFER_PRICE_CHANGE'
export const MOBILE_FULL_USER = 'MOBILE_FULL_USER'
export const BASE_NAME = 'BASE_NAME'
export const MAILBOXES = 'MAILBOXES'
export const BASE_FIELDS = 'BASE_FIELDS'
export const BASE_PRODUCT_ID = 'BASE_PRODUCT_ID'

export const CONTACT_APPLICATION_CLOSE_REASONS = 'CONTACT_APPLICATION_CLOSE_REASONS'
export const CONTACT_THREAD_CLOSE_REASONS = 'CONTACT_THREAD_CLOSE_REASONS'
export const BASE_TRANSLATION_NAME = 'BASE_TRANSLATION_NAME'
export const THREADS_SEARCH = 'THREADS_SEARCH'
export const CONTACTS_SEARCH = 'CONTACTS_SEARCH'
export const ORGANIZATIONS_SEARCH = 'ORGANIZATIONS_SEARCH'
export const USERS_SEARCH = 'USERS_SEARCH'
export const FOLDER_FILES = 'FOLDER_FILES'
export const SHOP_PICTURE_TYPES = 'SHOP_PICTURE_TYPES'
export const USER_LOYALTY_PLANS = 'USER_LOYALTY_PLANS'
export const SETTINGS_WEBSITES = 'SETTINGS_WEBSITES'
export const SHOP_PRODUCTS_STORAGE = 'SHOP_PRODUCTS_STORAGE'
export const CONTACTS_THREADS_PAYMENTS = 'CONTACTS_THREADS_PAYMENTS'
export const SETTINGS_CONTACTS_STATUSES = 'SETTINGS_CONTACTS_STATUSES'
export const SETTINGS_CONTACTS_TAGS = 'SETTINGS_CONTACTS_TAGS'
export const SETTINGS_CONTACTS_THREADS_NOTES_TYPES = 'SETTINGS_CONTACTS_THREADS_NOTES_TYPES'
export const SETTINGS_CONTACTS_THREADS_TASKS_TYPES = 'SETTINGS_CONTACTS_THREADS_TASKS_TYPES'
export const SETTINGS_TEMPLATES = 'SETTINGS_TEMPLATES'
export const SETTINGS_GLOBAL_PUBLIC = 'SETTINGS_GLOBAL_PUBLIC'
export const SETTINGS_GLOBAL = 'SETTINGS_GLOBAL'
export const RANKS = 'RANKS'
export const USERS_GROUPS = 'USERS_GROUPS'
export const SETTINGS_AGREEMENTS_TYPES = 'SETTINGS_AGREEMENTS_TYPES'
export const SETTINGS_AGREEMENTS_TEMPLATES_COMPONENTS = 'SETTINGS_AGREEMENTS_TEMPLATES_COMPONENTS'
export const SETTINGS_AGREEMENTS_TEMPLATES = 'SETTINGS_AGREEMENTS_TEMPLATES'
export const SETTINGS_POLLS = 'SETTINGS_POLLS'
export const SETTINGS_POLLS_FIELDS = 'SETTINGS_POLLS_FIELDS'
export const SETTINGS_CONTACTS_APPLICATIONS_INTEGRATIONS = 'SETTINGS_CONTACTS_APPLICATIONS_INTEGRATIONS'
export const DOCUMENTS_FOLDERS = 'DOCUMENTS_FOLDERS'
export const DOCUMENTS_FOLDERS_FILES = 'DOCUMENTS_FOLDERS_FILES'
export const SETTINGS_FUNNEL_TYPE = 'SETTINGS_FUNNEL_TYPE'
export const SETTINGS_FUNNEL_TYPE_POINTS = 'SETTINGS_FUNNEL_TYPE_POINTS'
export const POLLS = 'POLLS'
export const CHECKLIST_POINT_DATUM = 'CHECKLIST_POINT_DATUM'
export const SETTINGS_CHECKLIST_TYPES = 'SETTINGS_CHECKLIST_TYPES'
export const AGREEMENTS = 'AGREEMENTS'
export const BASIC_USER = 'BASIC_USER'
export const BASIC_CONTACT = 'BASIC_CONTACT'
export const SINGLE_CONTACT = 'SINGLE_CONTACT'
export const CONTACT_SINGLE_THREAD = 'CONTACT_SINGLE_THREAD'
export const CONTACTS = 'CONTACTS'
export const GLOBAL_TIMELINE = 'GLOBAL_TIMELINE'
export const AUTH_LOGS = 'AUTH_LOGS'
export const BUILDING_ORGANIZATIONS = 'BUILDING_ORGANIZATIONS'
export const CHAT = 'CHAT'
export const CHAT_UNREAD_MESSAGES = 'CHAT_UNREAD_MESSAGES'
export const CHAT_CONVERSATIONS_MAILS = 'CHAT_CONVERSATIONS_MAILS'
export const CHAT_CONVERSATION = 'CHAT_CONVERSATION'
export const CART_ME = 'CART_ME'
export const TASKS = 'TASKS'
export const USERS = 'USERS'
export const APPLICATIONS = 'APPLICATIONS'
export const SINGLE_USER = 'SINGLE_USER'
export const OFFERS = 'OFFERS'
export const ORDERS = 'ORDERS'
export const PRODUCT_LIST = 'PRODUCT_LIST'
export const PRODUCT = 'PRODUCT'
export const PRODUCT_ATTRIBUTES = 'PRODUCT_ATTRIBUTES'
export const NOTIFICATIONS = 'NOTIFICATIONS'
export const FUNNEL = 'FUNNEL'
export const OFFERS_WEBSITES = 'OFFERS_WEBSITES'
export const WEBSITE_PREVIEW = 'WEBSITE_PREVIEW'
export const WEBSITE = 'WEBSITE'
export const LOYALTY_PLANS = 'LOYALTY_PLANS'
export const LOYALTY_PLANS_POINTS = 'LOYALTY_PLANS_POINTS'
export const MARKETING_PLANS = 'MARKETING_PLANS'
export const PROJECTS_SEARCH = 'PROJECTS_SEARCH'
export const PROJECTS = 'PROJECTS'
export const PROJECT_TASKS_SEARCH = 'PROJECT_TASKS_SEARCH'
export const PROJECT_TASKS = 'PROJECT_TASKS'
export const MICRO_TASKS_SEARCH = 'MICRO_TASKS_SEARCH'
export const MICRO_TASKS = 'MICRO_TASKS'
export const PROJECTS_STATUSES = 'PROJECTS_STATUSES'
export const PROJECT_TASKS_STATUSES = 'PROJECT_TASKS_STATUSES'
export const PROJECT_COMMENTS = 'PROJECT_COMMENTS'
export const PROJECT_TASK_COMMENTS = 'PROJECT_TASK_COMMENTS'
export const PROJECT_TASK_CATEGORIES = 'PROJECT_TASK_CATEGORIES'
export const PROJECT_DOCUMENTS = 'PROJECT_DOCUMENTS'
export const PROJECT_TAGS = 'PROJECT_TAGS'
export const CONTACT_ADDON_FIELDS = 'CONTACT_ADDON_FIELDS'
export const MARKETING_PLAN_STRUCTURE = 'MARKETING_PLAN_STRUCTURE'
export const CONTACT_ADDON_FIELD_TYPES = 'CONTACT_ADDON_FIELD_TYPES'

export const FACEBOOK_CONNECTIONS = 'FACEBOOK_CONNECTIONS'
export const FACEBOOK_WEBHOOKS = 'FACEBOOK_WEBHOOKS'
export const NEWS = 'NEWS'
export const PRODUCTS_EXPORT = 'PRODUCTS_EXPORT'
export const USERS_STRUCTURE = 'USERS_STRUCTURE'
export const SINGLE_AUTOMATION = 'SINGLE_AUTOMATION'
export const SINGLE_AUTOMATION_LOG = 'SINGLE_AUTOMATION_LOG'

export const OPEN_AI_CONFIG = 'OPEN_AI_CONFIG'
export const OPEN_AI_LOG = 'OPEN_AI_LOG'
export const OPEN_AI_REPORT_MESSAGE = 'OPEN_AI_REPORT_MESSAGE'

export const USER_MAILBOX = 'USER_MAILBOX'
export const CAMPAIGNS = 'CAMPAIGNS'

export const BOOKING = 'BOOKING'
export const WIDGET_CHATS = 'WIDGET_CHATS'

// Contact
export const CONTACT = 'CONTACT'
export const CONTACT_ADDITIONAL_FIELDS = 'CONTACT_ADDITIONAL_FIELDS'
export const CONTACT_THREAD_FUNNEL_PROCESS = 'CONTACT_THREAD_FUNNEL_PROCESS'
export const CONTACT_THREAD_LIST = 'CONTACT_THREAD_LIST'
export const CONTACT_THREAD_LIST_BASE = 'CONTACT_THREAD_LIST_BASE'
export const CONTACT_THREAD = 'CONTACT_THREAD'
export const CONTACT_INFO = 'CONTACT_INFO'
export const CONTACT_DELIVERY_ADDRESSES = 'CONTACT_DELIVERY_ADDRESSES'
export const CONTACT_IDENTITY_DOCUMENTS = 'CONTACT_IDENTITY_DOCUMENTS'
