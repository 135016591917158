<template>
  <div>
    <b-form-group :label="$t('Content')"
                  class="form-label-group"
    >
      <b-form-textarea
        id="report-content"
        v-model="report.content"
        rows="5"
        no-resize
      />
    </b-form-group>
    <!-- Ipload files -->
    <DragDropUpload
      ref="dragdropupload"
      :is-refresh="false"
      @onDeleteFiles="filesToUpload = []"
      @onChangeFiles="changeFiles"
    />
    <!--  Footer  -->
    <div class="d-flex justify-content-end pt-1 mt-1 border-top">
      <b-button
        size="sm"
        variant="secondary"
        @click="onModalClose"
      >
        {{ $t('Cancel') }}
      </b-button>
      <b-button
        class="ml-25"
        size="sm"
        variant="primary"
        :disabled="!report.content"
        @click="onCreateReport"
      >
        {{ okBtn }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { BFormTextarea } from 'bootstrap-vue'
import {
  CLOSE_MODAL,
  CREATE_REPORT,
  HIDE_MODAL, RELOAD_CONTENT,
  UPLOAD_FILES,
} from '@/@constants/mutations'
import { mapGetters } from 'vuex'
import EventBus from '@/event-bus'
import { eChangeThreadTaskStatus } from '@/@types/events'
import DragDropUpload from '../../components/DragDropUpload.vue'

export default {
  name: 'NoteModal',
  components: {
    DragDropUpload,
    BFormTextarea,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: { okBtn: String },
  data: () => ({
    report: {
      content: '',
    },
    filesToUpload: [],
  }),
  computed: {
    ...mapGetters({
      modalConfig: 'modal/getModalState',
    }),
  },
  methods: {
    onModalClose() {
      this.$emit('close')
    },
    changeFiles(files) {
      this.filesToUpload.push(...files)
    },
    async modalClose() { await this.$store.commit(`modal/${CLOSE_MODAL}`) },
    async modalReloadContent(payload) { await this.$store.commit(`modal/${RELOAD_CONTENT}`, payload) },
    onCreateReport() {
      const { report } = this

      this.$store.commit(`modal/${HIDE_MODAL}`)
      this.$store.dispatch(`uploader/${UPLOAD_FILES}`, {
        uploadedFiles: this.filesToUpload, type: 'report', threadId: this.modalConfig.threadId, data: this.modalConfig.data,
      })
        .then(res => {
          report.files = res.files
          this.$store.dispatch(`modal/${CREATE_REPORT}`, { report, threadId: res.threadId, data: res.data })
            .then(ress => {
              this.modalReloadContent({ status: ress?.status }).then(() => this.modalClose())
              this.showToast('success', this.$i18n.t('StatusUpdatedReportAdded'))

              EventBus.$emit(eChangeThreadTaskStatus, { threadId: this.modalConfig.threadId })
            })
            .catch(err => {
              this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            })
          // old
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
      //
    },
  },
}
</script>

<style lang="scss">
</style>
