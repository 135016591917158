<template>
  <b-row>
    <b-col>
      <transition
        name="slide-fade"
        mode="out-in"
      >
        <contracts-list
          v-if="!selectedContract"
          key="polls"
        />
        <contract-details
          v-else
          key="details"
        />
      </transition>
    </b-col>
  </b-row>
</template>

<script>
import ContractsList from '@/views/core/contractsGenerator/ContractsList.vue'
import ContractDetails from '@/views/core/contractsGenerator/ContractDetails.vue'

export default {
  name: 'ContactsGenerator',
  components: {
    ContractsList,
    ContractDetails,
  },
  computed: {
    selectedContract() {
      return this.$route.params?.contractId
    },
  },
}
</script>

<style scoped>

</style>
