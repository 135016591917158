var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.product)?_c('validation-observer',{ref:"edit-product-modal"},[_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('offer.product.code'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('offer.product.code')}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('offer.product.code'),"state":errors.length > 0 ? false:null},model:{value:(_vm.product.overwriteCode),callback:function ($$v) {_vm.$set(_vm.product, "overwriteCode", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"product.overwriteCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3453951091)})],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('offer.product.priceNet')}},[_c('validation-provider',{attrs:{"name":_vm.$t('contact.phone'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"options":_vm.cleaveOptions.number,"state":errors.length > 0 ? false:null},model:{value:(_vm.product.overwriteOfferPrice),callback:function ($$v) {_vm.$set(_vm.product, "overwriteOfferPrice", $$v)},expression:"product.overwriteOfferPrice"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,959320224)})],1)],1),_c('b-table',{staticClass:"constTable",attrs:{"items":_vm.attributes,"fields":_vm.constAttributeFields,"striped":"","responsive":"","hover":"","tbody-tr-class":_vm.hideNotBase},scopedSlots:_vm._u([{key:"head()",fn:function(item){return [_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm._f("truncate")(_vm.$tc(item.field.label),34, '...'))+" ")])]}},{key:"cell(position)",fn:function(ref){
var value = ref.value;
var index = ref.index;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('b-button-group',{staticClass:"btn-group-vertical mr-25"},[_c('b-button',{staticClass:"btn-icon p-md-0",attrs:{"variant":"flat-primary","size":"sm","disabled":_vm.isEdited || value === 0},on:{"click":function($event){return _vm.moveAttr(index, true)}}},[_c('feather-icon',{attrs:{"icon":"ArrowUpIcon"}})],1),_c('b-button',{staticClass:"btn-icon p-0",attrs:{"variant":"flat-primary","size":"sm","disabled":_vm.isEdited || value === _vm.attributes.length -1},on:{"click":function($event){return _vm.moveAttr(index, false)}}},[_c('feather-icon',{attrs:{"icon":"ArrowDownIcon"}})],1)],1),_vm._v(" "+_vm._s(value)+" ")],1)]}},{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.shopAttribute.translations[0].name)+" ")]}},{key:"cell(value)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(['TEXT_ARRAY', 'TEXT_MULTIPLE_ARRAY'].includes(item.shopAttribute.type))?_c('div',[(Array.isArray(item.valueShopAttributeOptions))?_c('div',_vm._l((item.valueShopAttributeOptions),function(value,itemIndex){return _c('b-badge',{key:(index + "_attributeItem_" + itemIndex + "_" + value),staticClass:"ml-25",attrs:{"variant":"primary"}},[_vm._v(" "+_vm._s(value.translations[0].name)+" ")])}),1):_c('div',[_c('b-badge',{staticClass:"ml-25",attrs:{"variant":"primary"}},[_vm._v(" "+_vm._s(item.valueShopAttributeOptions.translations[0].name)+" ")])],1)]):_c('div',[_vm._v(" "+_vm._s(item.value)+" ")])]}},{key:"cell(type)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("offer.attribute.types." + (item.shopAttribute.type))))+" ")]}},{key:"cell(visibleOfferWWW)",fn:function(ref){
var value = ref.value;
return [_c('span',{staticClass:"d-flex"},[(value)?_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-success",modifiers:{"hover":true,"v-success":true}}],attrs:{"variant":"light-success","pill":"","title":_vm.$t('Active')}},[_c('feather-icon',{attrs:{"icon":"CheckIcon"}})],1):_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-danger",modifiers:{"hover":true,"v-danger":true}}],attrs:{"variant":"light-danger","pill":"","title":_vm.$t('InActive')}},[_c('feather-icon',{attrs:{"icon":"MinusIcon"}})],1)],1)]}},{key:"cell(active)",fn:function(ref){
var value = ref.value;
return [_c('span',{staticClass:"d-flex"},[(value)?_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-success",modifiers:{"hover":true,"v-success":true}}],attrs:{"variant":"light-success","pill":"","title":_vm.$t('Active')}},[_c('feather-icon',{attrs:{"icon":"CheckIcon"}})],1):_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-danger",modifiers:{"hover":true,"v-danger":true}}],attrs:{"variant":"light-danger","pill":"","title":_vm.$t('InActive')}},[_c('feather-icon',{attrs:{"icon":"MinusIcon"}})],1)],1)]}},{key:"cell(action)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('b-dropdown',{staticClass:"dropdown-icon-wrapper text-decoration-none",attrs:{"variant":"link","no-caret":"","disabled":_vm.isEdited},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"align-middle",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.editAttribute(item, index)}}},[_vm._v(" "+_vm._s(_vm.$t('Edit'))+" ")]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.remove(index)}}},[_vm._v(" "+_vm._s(_vm.$t('Delete'))+" ")])],1)]}},{key:"cell()",fn:function(row){return [_c('table-default-cell',{attrs:{"field":row.field,"value":row.value}})]}},{key:"empty",fn:function(){return [_c('empty-content')]},proxy:true},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[_c('b-spinner',{staticClass:"align-middle",attrs:{"variant":"primary"}})],1)]},proxy:true}],null,false,1577666928)})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }