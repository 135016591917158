var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{attrs:{"no-body":""}},[_c('div',{staticClass:"d-flex flex-wrap flex-column w-100 justify-content-center align-items-center flex-md-row mb-1",staticStyle:{"row-gap":"1rem"}},[_vm._t("legend"),(_vm.chartData)?_c('pie-chart',{staticClass:"w-100",staticStyle:{"max-width":"max-content"},style:(("height: " + (_vm.isMobile ? 10 : 14) + "rem")),attrs:{"data":_vm.chartData}}):_vm._e()],2),_c('sw-table',{attrs:{"table-id":"d2955a653b9e44f68693d8feda513891","show-columns":"","fields":_vm.fields,"pagination":_vm.pagination},on:{"change-pagination":function($event){return Object.assign(_vm.pagination, $event)},"set-fields":function($event){_vm.fields = $event},"reload-content":_vm.loadData},scopedSlots:_vm._u([{key:"table",fn:function(){return [_c('b-table',{attrs:{"items":_vm.items,"fields":_vm.fields,"striped":"","responsive":"","busy":_vm.loading,"show-empty":""},scopedSlots:_vm._u([{key:"head()",fn:function(item){return [_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary",modifiers:{"hover":true,"v-primary":true}}],staticClass:"d-flex flex-row align-items-center",staticStyle:{"column-gap":".34rem"},attrs:{"title":item.field.tooltip ? _vm.$t(item.field.tooltip) : ''}},[_vm._v(" "+_vm._s(_vm.$t(item.label))+" "),(item.field.tooltip)?_c('feather-icon',{attrs:{"icon":"InfoIcon"}}):_vm._e()],1)]}},{key:"cell(contact)",fn:function(ref){
var item = ref.item;
return [(item.contactThread.contact)?_c('div',{staticClass:"d-flex align-items-center"},[_c('avatar',{attrs:{"user":item.contactThread.contact,"show-name":false,"show-tooltip":""}}),(item.contactThread.contact)?_c('b-link',{class:{ 'deleted-text': item.contacThread && item.contacThread.contact && item.contacThread.contact.deletedAt },attrs:{"href":("/contact/" + (item.contactThread.contact.id)),"target":"_blank"}},[_vm._v(" "+_vm._s(_vm._f("truncate")(_vm.getUserName(item.contactThread.contact),30))+" "),_c('feather-icon',{staticClass:"text-success",attrs:{"icon":"LinkIcon"}})],1):_c('span',[_vm._v(_vm._s(_vm._f("truncate")(_vm.getUserName(item.contactThread.contact),30)))])],1):_vm._e()]}},{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('avatar',{attrs:{"user":item.contact,"show-name":false,"show-tooltip":""}}),(item.contact)?_c('b-link',{class:{ 'deleted-text': item.contact && item.contact.deletedAt },attrs:{"href":("/contact/" + (item.contact.id) + "/thread/" + (item.id) + "/timeline"),"target":"_blank"}},[_vm._v(" "+_vm._s(item.name)+" "),_c('feather-icon',{staticClass:"text-success",attrs:{"icon":"LinkIcon"}})],1):_c('span',[_vm._v(_vm._s(item.name))])],1)]}},{key:"cell(contactThreadStatusReason)",fn:function(ref){
var value = ref.value;
return [_c('b-badge',{attrs:{"variant":"light-primary"}},[_vm._v(" "+_vm._s(value.name)+" ")])]}},{key:"cell(statusClosedContactThreadFunnelTypePoint)",fn:function(ref){
var value = ref.value;
return [_c('b-badge',{attrs:{"variant":"light-primary"}},[(value.contactThreadFunnelType)?_c('span',[_vm._v(_vm._s(value.contactThreadFunnelType.name)+" -")]):_vm._e(),_vm._v(" "+_vm._s(value.name)+" ")])]}},{key:"cell(statusNote)",fn:function(ref){
var value = ref.value;
return [_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary",modifiers:{"hover":true,"v-primary":true}}],attrs:{"title":value}},[_vm._v(" "+_vm._s(_vm._f("truncate")(value,34, '...'))+" ")])]}},{key:"cell(predictedValue)",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("priceFormat")(value))+" "+_vm._s(item.predictedValueCurrency || 'PLN')+" ")]}},{key:"cell(assignedUsers)",fn:function(ref){
var value = ref.value;
return [_c('avatar-group',{attrs:{"items":value}})]}},{key:"cell(company)",fn:function(ref){
var item = ref.item;
return [(item.contactThread.contact && item.contactThread.contact.isOrganization && item.contactThread.contact.organization)?_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],attrs:{"title":item.contactThread.contact.organization.name}},[_vm._v(" "+_vm._s(_vm._f("truncate")(item.contactThread.contact.organization.name,20))+" ")]):_c('feather-icon',{attrs:{"icon":"MinusIcon"}})]}},{key:"cell()",fn:function(row){return [_c('table-default-cell',{attrs:{"field":row.field,"value":row.value}})]}},{key:"empty",fn:function(){return [_c('empty-content')]},proxy:true},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[_c('b-spinner',{staticClass:"align-middle",attrs:{"variant":"primary"}})],1)]},proxy:true}])})]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }