<template>
  <div>
    <div>
      <b-button
        v-if="checkRequiredPermissions([$roles.DOCUMENT_MODIFY]) && !isStaticFolder"
        size="sm"
        variant="primary"
        @click="isModalOpen = true"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-25"
        />
        {{ $t('Add') }}
      </b-button>
    </div>

    <!-- Document View -->
    <b-card
      v-if="documentsType === 'FILE'"
      class="mt-50"
    >
      <b-table
        :items="files"
        class="table-wrap-words rounded"
        :fields="fileFields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        striped
        responsive
        :busy="loading"
        show-empty
        @sort-changed="sortingChanged"
      >
        <!--    Head    -->
        <template #head()="{ label }">
          {{ $t(`${label}`) }}
        </template>

        <!--     Name     -->
        <template #cell(name)="{ value }">
          {{ value }}
        </template>

        <!--    CreatedBy    -->
        <template #cell(createdBy)="{ value }">
          <avatar
            :user="value"
            :to="{ name: 'user', params: { userId: value.id } }"
          />
        </template>

        <!--     Download     -->
        <template #cell(download)="data">
          <b-button
            :href="data.url"
            size="sm"
            variant="flat-primary"
            @click="startDownload(data.item.file)"
          >
            <feather-icon
              icon="DownloadIcon"
            />
          </b-button>
        </template>
        <!--     Action     -->
        <template #cell(action)="{ item, index }">
          <b-dropdown
            v-if="checkRequiredPermissions([$roles.DOCUMENT_MODIFY]) && !isStaticFolder"
            id="dropdown-dropleft"
            dropleft
            no-caret
            variant="flat"
          >
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" />
            </template>
            <b-dropdown-item @click="editFile(item, index)">
              {{ $t('Edit') }}
            </b-dropdown-item>
            <b-dropdown-item @click="deleteFile(item, index)">
              {{ $t('Delete') }}
            </b-dropdown-item>
          </b-dropdown>
          <feather-icon
            v-else
            icon="MinusIcon"
          />
        </template>

        <!--    Other    -->
          <!--    Other    -->
          <template #cell()="row">
              <table-default-cell
                      :field="row.field"
                      :value="row.value"
              />
          </template>

          <!--      Empty      -->
          <template #empty>
              <empty-content />
          </template>

          <!--      Table Busy      -->
          <template #table-busy>
              <div class="text-center text-danger my-2">
                  <b-spinner
                          class="align-middle"
                          variant="primary"
                  />
              </div>
          </template>
      </b-table>
    </b-card>
    <!-- Video View -->
    <div v-if="documentsType === 'VIDEO'">
      <b-row
        v-if="!loading"
      >
        <b-col
          v-for="(file, index) in files"
          :key="index"
          sm="12"
          md="6"
          lg="4"
        >
          <div
            class="d-flex justify-content-between align-items-center pl-1 pb-50 pr-1"
          >
            <h5>
              {{ (!isStaticFolder ? file.name : $t(`${file.name}`)) | truncate(50) }}
            </h5>

            <div
              v-if="!isStaticFolder"
              class="d-flex align-items-center"
            >
              <b-button
                v-if="checkRequiredPermissions([$roles.DOCUMENT_MODIFY])"
                size="sm"
                variant="flat-warning"
                @click="editFile(file, index)"
              >
                <feather-icon
                  icon="EditIcon"
                />
              </b-button>
              <b-button
                v-if="checkRequiredPermissions([$roles.DOCUMENT_MODIFY])"
                size="sm"
                variant="flat-danger"
                @click="deleteFile(file, index)"
              >
                <feather-icon
                  icon="TrashIcon"
                />
              </b-button>
            </div>
          </div>

          <div class="card overflow-hidden mx-1">
            <iframe
              width="100%"
              height="100%"
              :src="file.url"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              style="min-height: 18rem"
              allowfullscreen
            />
          </div>
        </b-col>
      </b-row>
    </div>
    <!-- New Modal -->
    <b-modal
      :visible="isModalOpen"
      title="Folder"
      hide-footer
      no-close-on-backdrop
      @hide="isModalOpen = false; editedFile = { file: null, index: -1 }"
    >
      <new-file-modal
        :edited-file="editedFile.file"
        @save-item="saveItem"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  CREATE_FILE, DOWNLOAD_FILE,
  GET_FILES, REMOVE_FILE,
  UPDATE_FILE, UPLOAD_FILES,
} from '@/@constants/mutations'
import NewFileModal from '@/views/modals/NewDocumentsFileModal.vue'
import { BTable } from 'bootstrap-vue'
import { formatDate } from '@core/utils/filter'
import * as files from '@/@data/staticFiles'

export default {
  name: 'DocumentList',
  components: {
    BTable,
    NewFileModal,
  },
  data: () => ({
    files: [],
    isModalOpen: false,
    sortBy: 'name',
    sortDesc: false,
    editedFile: {
      file: null,
      index: -1,
    },
    fileFields: [
      {
        key: 'name', label: 'Name', visible: true, sortable: true,
      },
      {
        key: 'createdAt', label: 'CreatedAt', visible: true, sortable: true,
      },
      { key: 'createdBy', label: 'CreatedBy', visible: true },
      { key: 'download', label: 'Download', visible: true },
      { key: 'action', label: 'Action', visible: true },
    ],
    loading: false,
  }),
  computed: {
    documentsType() {
      if (this.$route.name === 'documents') return 'FILE'

      return 'VIDEO'
    },
    isStaticFolder() {
      return ['trainings'].includes(this.$route.params?.folderId)
    },
  },
  watch: {
    '$route.params.folderId': {
      deep: true,
      handler() {
        this.loadFiles()
      },
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    formatDate,
    init() {
      if (!this.isStaticFolder) {
        this.loadFiles()
      } else {
        this.setStaticFiles()
      }
    },
    sortingChanged(ctx) {
      this.$set(this, 'sortBy', ctx.sortBy)
      this.$set(this, 'sortDesc', ctx.sortDesc)

      this.$nextTick(() => {
        this.init()
      })
    },
    setStaticFiles() {
      this.files = files[this.$route.params?.folderId]
    },
    startDownload(file) {
      this.$store.dispatch(`uploader/${DOWNLOAD_FILE}`, {
        fileToken: file.token,
        filename: file.name,
      })
        .then(res => {
          const { data, filename } = res
          const a = document.createElement('a')
          a.href = `data:${data.type};base64,${data.file}`
          a.download = filename
          a.click()
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
    editFile(item, index) {
      this.$set(this.editedFile, 'file', { ...item })
      this.$set(this.editedFile, 'index', index)
      this.isModalOpen = true
    },
    loadFiles() {
      this.loading = true
      const { folderId } = this.$route.params

      if (!folderId) this.$router.go(-1)
      const payload = {
        sortBy: this.sortBy,
        sortDesc: this.sortDesc,
      }

      this.$store.dispatch(`globalDocuments/${GET_FILES}`, { folderId, payload })
        .then(res => {
          this.files = res.data.items
          this.loading = false
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          this.loading = false
        })
    },
    deleteFile(item, index) {
      this.$store.dispatch(`globalDocuments/${REMOVE_FILE}`, { fileId: item.id, folderId: this.$route.params.folderId })
        .then(() => {
          this.$delete(this.files, index)
          this.showToast('success', this.$i18n.t('RemovedSuccessfully'))
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
    saveItem(item) {
      const payload = { ...item }

      if (this.documentsType === 'VIDEO') {
        payload.file = null
        const url = payload.url.replaceAll('youtube.com', 'youtube-nocookie.com').replaceAll('watch?v=', 'embed/')
        payload.url = url
        this.uploadToServer(payload)
      } else {
        payload.url = null
        if (payload.file?.token) {
          payload.file = payload.file.token
          this.uploadToServer(payload)
        } else {
          this.$store.dispatch(`uploader/${UPLOAD_FILES}`, { uploadedFiles: [payload.file], type: 'building' })
            .then(res => {
              // eslint-disable-next-line prefer-destructuring
              payload.file = res.files[0]
              this.uploadToServer(payload)
            })
            .catch(err => {
              this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
              this.loading = false
            })
        }
      }
    },
    uploadToServer(payload) {
      let action = CREATE_FILE
      if (payload?.id) action = UPDATE_FILE

      this.$nextTick(() => {
        this.$store.dispatch(`globalDocuments/${action}`, { file: payload, folderId: this.$route.params.folderId })
          .then(() => {
            const text = action === CREATE_FILE ? this.$i18n.t('success.offerFileCreated') : this.$i18n.t('success.offerFileUpdated')
            this.showToast('success', text, this.$i18n.t('success.saved'))

            this.loadFiles()
            this.isModalOpen = false
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          })
      })
    },
  },
}
</script>

<style scoped>

</style>
