var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-alert',{attrs:{"variant":"primary","show":""}},[_c('div',{staticClass:"alert-body"},[_vm._v(" "+_vm._s(_vm.$t('AIReportTip'))+" ")])]),_c('validation-observer',{attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('validation-provider',{attrs:{"name":_vm.$t('ReasonForReporting')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('sw-select',{attrs:{"name":_vm.$t('ReasonForReporting')}},[_c('v-select',{attrs:{"placeholder":_vm.$t('ReasonForReporting'),"options":_vm.typeList,"filterable":false,"state":errors.length > 0 ? false:null,"clearable":false},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('NoOptions'))+" ")]},proxy:true},{key:"option",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(("ai.reportsTypes." + label)))+" ")]}},{key:"selected-option",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(("ai.reportsTypes." + label)))+" ")]}}],null,true),model:{value:(_vm.reason),callback:function ($$v) {_vm.reason=$$v},expression:"reason"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{staticClass:"w-100",attrs:{"name":_vm.$t('Message'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Message')}},[_c('b-form-textarea',{attrs:{"id":"popover-bannerDesc","placeholder":_vm.$t('Message'),"state":errors.length > 0 ? false:null,"maxlength":"110"},model:{value:(_vm.message),callback:function ($$v) {_vm.message=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"message"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('div',{staticClass:"mt-1 d-flex justify-content-end"},[_c('b-button',{attrs:{"type":"button","variant":"primary","size":"sm","disabled":invalid || _vm.isLoading},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('Send'))+" ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }