const settingsListBreadcrumb = {
  text: 'Settings',
  to: '/settings/system',
}

// eslint-disable-next-line import/prefer-default-export
export const settings = [
  {
    path: '/settings/:panel/:tab?',
    name: 'settings',
    component: () => import('@/pages/settings/Panel.vue'),
    meta: {
      autorize: true,
      pageTitle: 'SettingsPanel',
    },
  },
  {
    path: '/settings/agreement-types',
    name: 'agreement-types',
    component: () => import('@/pages/settings/AgreementTypes.vue'),
    meta: {
      autorize: true,
      pageTitle: 'AgreementTypes',
      breadcrumb: [
        settingsListBreadcrumb,
        { active: true, text: 'AgreementsCount' },
      ],
    },
  },
  {
    path: '/settings/checkout',
    name: 'offer-settings',
    component: () => import('@/pages/settings/OfferSettings.vue'),
    meta: {
      autorize: true,
      pageTitle: 'Offer.Cart',
      breadcrumb: [
        settingsListBreadcrumb,
        { active: true, text: 'Offer.Cart' },
      ],
    },
  },
  // {
  //   path: '/settings/system',
  //   name: 'system-settings',
  //   component: () => import('@/pages/settings/System.vue'),
  //   meta: {
  //     autorize: true,
  //     pageTitle: 'System',
  //     breadcrumb: [
  //       settingsListBreadcrumb,
  //       {
  //         text: 'System',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  {
    path: '/settings/integrations',
    name: 'integrations',
    component: () => import('@/pages/settings/Integrations.vue'),
    meta: {
      autorize: true,
      pageTitle: 'Integrations',
      breadcrumb: [
        settingsListBreadcrumb,
        {
          text: 'System',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/automations/general/:automationId?/:automationCopyId?',
    name: 'automations',
    component: () => import('@/pages/settings/Automations.vue'),
    meta: {
      props: true,
      autorize: true,
      pageTitle: 'Automations',
      breadcrumb: [
        settingsListBreadcrumb,
        { text: 'System', to: '/settings/automations' },
        { text: 'Automations', to: '/settings/automations/general' },
      ],
    },
  },
  {
    path: '/settings/templates',
    name: 'template-settings',
    component: () => import('@/pages/settings/Templates.vue'),
    meta: {
      autorize: true,
      pageTitle: 'TemplateSettings',
      breadcrumb: [
        settingsListBreadcrumb,
        {
          text: 'System',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/threads',
    name: 'thread-settings',
    component: () => import('@/pages/settings/ThreadSettings.vue'),
    meta: {
      autorize: true,
      pageTitle: 'Threads',
      breadcrumb: [
        settingsListBreadcrumb,
        { active: true, text: 'ThreadsAndContacts' },
      ],
    },
  },
  {
    path: '/settings/contacts',
    name: 'contact-settings',
    component: () => import('@/pages/settings/ContactSettings.vue'),
    meta: {
      autorize: true,
      pageTitle: 'Contacts',
      breadcrumb: [
        settingsListBreadcrumb,
        { active: true, text: 'ThreadsAndContacts' },
      ],
    },
  },
  {
    path: '/settings/chats',
    name: 'chats-settings',
    component: () => import('@/pages/settings/chats/ChatSettings.vue'),
    meta: {
      autorize: true,
      pageTitle: 'Chats',
      breadcrumb: [
        settingsListBreadcrumb,
      ],
    },
  },
  {
    path: '/settings/ai/general/:id?',
    name: 'edit-ai-connection',
    component: () => import('@/pages/settings/ai/EditAiConnection.vue'),
    meta: {
      autorize: true,
      pageTitle: 'AiConnection',
      breadcrumb: [
        settingsListBreadcrumb,
        { text: 'AI', to: '/settings/ai' },
        { text: 'AiConnection', to: '/settings/ai/general' },
      ],
    },
  },
  {
    path: '/settings/groups',
    name: 'group-settings',
    component: () => import('@/pages/settings/GroupSettings.vue'),
    meta: {
      autorize: true,
      pageTitle: 'Groups',
      breadcrumb: [
        settingsListBreadcrumb,
        {
          text: 'System',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/shop',
    name: 'shop-settings',
    component: () => import('@/pages/settings/Shop.vue'),
    meta: {
      autorize: true,
      pageTitle: 'Shop',
      breadcrumb: [
        settingsListBreadcrumb,
        {
          text: 'System',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/picture-types',
    name: 'picture-types',
    component: () => import('@/pages/settings/ProductPictureTypes.vue'),
    meta: {
      autorize: true,
      pageTitle: 'ImageTypes',
      breadcrumb: [
        settingsListBreadcrumb,
        { active: true, text: 'OfferList' },
      ],
    },
  },
  {
    path: '/settings/system/ranks/:rankId?',
    name: 'ranks',
    component: () => import('@/pages/settings/Ranks.vue'),
    meta: {
      autorize: true,
      pageTitle: 'RanksSettings',
      breadcrumb: [
        settingsListBreadcrumb,
        { text: 'System', to: '/settings/system/general' },
        { text: 'RanksSettings', to: '/settings/system/ranks' },
      ],
    },
  },
  // {
  //   path: '/settings/ranks',
  //   name: 'ranks',
  //   component: () => import('@/pages/settings/Ranks.vue'),
  //   meta: {
  //     autorize: true,
  //     pageTitle: 'RanksSettings',
  //     breadcrumb: [
  //       settingsListBreadcrumb,
  //       { text: 'System', active: true },
  //     ],
  //   },
  // },
  {
    path: '/settings/threads-and-contacts/polls/:pollId?',
    name: 'polls',
    component: () => import('@/pages/settings/Polls.vue'),
    meta: {
      autorize: true,
      pageTitle: 'Polls',
      breadcrumb: [
        settingsListBreadcrumb,
        {
          text: 'ThreadsAndContacts',
          to: '/settings/threads-and-contacts',
        },
        {
          active: false,
          text: 'Polls',
          to: '/settings/threads-and-contacts/polls',
        },
      ],
    },
  },
  // Contracts
  {
    path: '/settings/agreements/contractGenerator/:contractId?',
    name: 'contracts-generator',
    component: () => import('@/pages/settings/ContractsGenerator.vue'),
    meta: {
      autorize: true,
      pageTitle: 'ContractGenerator',
      breadcrumb: [
        settingsListBreadcrumb,
        { text: 'AgreementsCount', to: '/settings/agreements' },
        { text: 'ContractGenerator', to: '/settings/agreements/contractGenerator' },
      ],
    },
  },
  {
    path: '/settings/threads-and-contacts/contactGenerator/:contactId?',
    name: 'contact-generator',
    component: () => import('@/pages/settings/ContactGenerator.vue'),
    meta: {
      pageTitle: 'ContactGenerator',
      breadcrumb: [
        settingsListBreadcrumb,
        { active: true, text: 'ThreadsAndContacts', to: '/settings/threads-and-contacts' },
        { text: 'ContactGenerator', to: '/settings/threads-and-contacts/contactGenerator' },
      ],
    },
  },
  {
    path: '/settings/agreements/components/:templateId?',
    name: 'components',
    component: () => import('@/pages/settings/ComponentsTemplate.vue'),
    meta: {
      autorize: true,
      pageTitle: 'Components',
      breadcrumb: [
        settingsListBreadcrumb,
        { text: 'AgreementsCount', to: '/settings/agreements' },
        { text: 'Components', to: '/settings/agreements/components' },
      ],
    },
  },
  {
    path: '/settings/cart/listWebsite/websites',
    name: 'offr/listWebsite',
    component: () => import('@/pages/website/List.vue'),
    meta: {
      autorize: true,
      pageTitle: 'ListWebsite',
      breadcrumb: [
        settingsListBreadcrumb,
        { to: '/settings/cart', text: 'Cart' },
        { to: '/settings/cart/listWebsite', text: 'ListWebsite' },
      ],
    },
  },
  {
    path: '/settings/cart/listWebsite/website/:websiteId?',
    name: 'offr/editWebsite',
    component: () => import('@/pages/website/Create.vue'),
    meta: {
      autorize: true,
      pageTitle: 'EditWebsite',
      breadcrumb: [
        settingsListBreadcrumb,
        { text: 'Offer.Cart', to: '/settings/cart' },
        { to: '/settings/cart/listWebsite', text: 'ListWebsite' },
      ],
    },
  },
  {
    path: '/settings/projects/status',
    name: 'project-settings-status',
    component: () => import('@/pages/settings/projects/Status.vue'),
    meta: {
      autorize: true,
      pageTitle: 'Settings',
      breadcrumb: [
        settingsListBreadcrumb,
        {
          text: 'Projects',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/projects/tags',
    name: 'project-settings-tags',
    component: () => import('@/pages/settings/projects/TagsList.vue'),
    meta: {
      autorize: true,
      pageTitle: 'ProjectTags',
      breadcrumb: [
        settingsListBreadcrumb,
        {
          text: 'Projects',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/projects/categories',
    name: 'project-settings-categories',
    component: () => import('@/pages/settings/projects/CategoriesList.vue'),
    meta: {
      autorize: true,
      pageTitle: 'ProjectCategories',
      breadcrumb: [
        settingsListBreadcrumb,
        {
          text: 'Projects',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/ai',
    name: 'system-settings-ai',
    component: () => import('@/pages/settings/ai/Ai.vue'),
    meta: {
      autorize: true,
      pageTitle: 'PersonalAI',
      breadcrumb: [
        settingsListBreadcrumb,
        {
          text: 'AI',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/ai/bug-reports',
    name: 'system-settings-ai-bug-reports',
    component: () => import('@/pages/settings/ai/BugReports.vue'),
    meta: {
      autorize: true,
      pageTitle: 'BugReports',
      breadcrumb: [
        settingsListBreadcrumb,
        {
          text: 'BugReports',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/ai/reports',
    name: 'system-settings-ai-reports',
    component: () => import('@/pages/settings/ai/Reports.vue'),
    meta: {
      autorize: true,
      pageTitle: 'Reports',
      breadcrumb: [
        settingsListBreadcrumb,
        {
          text: 'Reports',
          active: true,
        },
      ],
    },
  },
]
