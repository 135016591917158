<template>
  <b-row>
    <b-col
      md="6"
      class="mb-n1"
    >
      <sw-select
        :name="$tc('dashboard.tiles.YourAssignedContacts', 2)"
      >
        <assigned-users
          :value="filters.assignedContacts"
          :is-multiple="true"
          :mode="'<contacts>show all contacts</contacts>'"
          @input="changeAssignedContacts"
        />
      </sw-select>
    </b-col>

    <b-col
      md="6"
      class="mb-n1"
    >
      <sw-select
        :name="$t('AssignedUsers')"
      >
        <assigned-users
          :value="filters.assignedUsers"
          :is-multiple="true"
          @input="changeAssignedUsers"
        />
      </sw-select>
    </b-col>
  </b-row>
</template>

<script>
import { ref } from '@vue/composition-api'
import AssignedUsers from '@/views/components/AssignedUsers.vue'

export default {
  components: {
    AssignedUsers,
  },
  setup() {
    const filters = ref({
      assignedContacts: [],
      assignedUsers: [],
    })

    return {
      filters,
    }
  },
  watch: {
    filters: {
      deep: true,

      handler() {
        this.$emit('filters', this.filters)
      },
    },
  },
  methods: {
    changeAssignedContacts(payload) {
      this.filters.assignedContacts = payload
    },
    changeAssignedUsers(payload) {
      this.filters.assignedUsers = payload
    },
  },
}
</script>
