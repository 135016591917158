<template>
  <div>
    <!--    Option: Title    -->
    <b-row class="mx-0 px-0">
      <b-col
        sm="12"
        md="6"
        lg="8"
        class="px-1 px-0"
      >
        <my-option
          :title="$t('Title')"
          :tip="$t('MaximumLength') + ': 40'"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="$t('Title')"
            rules="required"
            class="w-100"
          >
            <b-form-group>
              <b-form-input
                id="popover-opinionTitle"
                v-model.trim="newWebsite.opinionTitle"
                :placeholder="$t('Title')"
                :state="errors.length > 0 ? false:null"
                maxlength="40"
              />
              <small class="text-danger">{{ errors[0] }}</small>

              <!--    Popover    -->
              <b-popover
                target="popover-opinionTitle"
                triggers="focus"
                variant="primary"
              >
                <span>{{ $t('MaximumLength') + ': 40' }}</span>
              </b-popover>
            </b-form-group>
          </validation-provider>
        </my-option>
      </b-col>
    </b-row>

    <!--    Option: Description    -->
    <b-row class="mx-0">
      <b-col
        sm="12"
        md="6"
        lg="8"
        class="px-1"
      >
        <my-option
          :title="$t('offer.product.description')"
          :tip="$t('MaximumLength') + ': 170'"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="$t('offer.product.description')"
            rules="required"
            class="w-100"
          >
            <b-form-group>
              <b-form-textarea
                id="popover-opinionDesc"
                v-model.trim="newWebsite.opinionDesc"
                :placeholder="$t('offer.product.description')"
                :state="errors.length > 0 ? false:null"
                maxlength="170"
              />
              <small class="text-danger">{{ errors[0] }}</small>

              <!--    Popover    -->
              <b-popover
                target="popover-opinionDesc"
                triggers="focus"
                variant="primary"
              >
                <span>{{ $t('MaximumLength') + ': 170' }}</span>
              </b-popover>
            </b-form-group>
          </validation-provider>
        </my-option>
      </b-col>
    </b-row>

    <!--    Option: opinions    -->
    <b-row class="mx-0">
      <b-col
        sm="12"
        md="12"
        lg="8"
        class="px-1"
      >
        <my-option
          :title="$t('offer.website.CustomersOpinion')"
          :tip="$t('offer.website.tips.CustomersOpinion')"
        >
          <template #action>
            <b-button
              variant="primary"
              size="sm"
              class="ml-50"
              @click="openEditOpinion(-1, { translations: [{ title: '',
                                                             description: '',
                                                             author: '', }], avatar: '' })"
            >
              <feather-icon icon="PlusIcon" />{{ $t('Add') }}
            </b-button>
          </template>
          <!--    Form    -->
          <!--          <b-row class="w-100 mx-0 px-0">-->
          <!--            &lt;!&ndash;    Picture    &ndash;&gt;-->
          <!--            <b-col-->
          <!--              sm="12"-->
          <!--              md="12"-->
          <!--              lg="12"-->
          <!--              class="d-flex px-0 w-100"-->
          <!--            >-->
          <!--              <div-->
          <!--                class="mx-25 mb-25 dragDrop rec d-flex row align-items-center justify-content-start"-->
          <!--              >-->
          <!--                <div>-->
          <!--                  <b-img-->
          <!--                    id="img-newOption-avatar"-->
          <!--                    class="px-0 py-0 center"-->
          <!--                    thumbnail-->
          <!--                    fluid-->

          <!--                    :src="require('@/assets/icons/user/user.svg')"-->
          <!--                  />-->

          <!--                  <b-button-->
          <!--                    v-b-tooltip.hover.v-primary-->
          <!--                    variant="primary"-->
          <!--                    :title="$t('DragDropFile')"-->
          <!--                  >-->
          <!--                    <feather-icon icon="PlusIcon" />-->
          <!--                    <input-->
          <!--                      ref="newOptionAvatar"-->
          <!--                      type="file"-->
          <!--                      @change="onChange($event, 'newOption-avatar', 1250)"-->
          <!--                    >-->
          <!--                  </b-button>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </b-col>-->

          <!--            &lt;!&ndash;    Title    &ndash;&gt;-->
          <!--            <b-col-->
          <!--              sm="12"-->
          <!--              md="12"-->
          <!--              lg="12"-->
          <!--              class="d-flex px-0"-->
          <!--            >-->
          <!--              <b-form-input-->
          <!--                id="popover-opinionTitle1"-->
          <!--                v-model.trim="newOption.title"-->
          <!--                :placeholder="$t('Title')"-->
          <!--                maxlength="50"-->
          <!--              />-->

          <!--              &lt;!&ndash;    Popover    &ndash;&gt;-->
          <!--              <b-popover-->
          <!--                target="popover-opinionTitle1"-->
          <!--                triggers="focus"-->
          <!--                variant="primary"-->
          <!--              >-->
          <!--                <span>{{ $t('MaximumLength') + ': 50' }}</span>-->
          <!--              </b-popover>-->
          <!--            </b-col>-->

          <!--            &lt;!&ndash;    Description    &ndash;&gt;-->
          <!--            <b-col-->
          <!--              sm="12"-->
          <!--              md="12"-->
          <!--              lg="12"-->
          <!--              class="d-flex mt-25 px-0"-->
          <!--            >-->
          <!--              <b-form-textarea-->
          <!--                id="popover-opinionText"-->
          <!--                v-model.trim="newOption.description"-->
          <!--                :placeholder="$t('Opinion')"-->
          <!--                maxlength="170"-->
          <!--              />-->

          <!--              &lt;!&ndash;    Popover    &ndash;&gt;-->
          <!--              <b-popover-->
          <!--                target="popover-opinionText"-->
          <!--                triggers="focus"-->
          <!--                variant="primary"-->
          <!--              >-->
          <!--                <span>{{ $t('MaximumLength') + ': 170' }}</span>-->
          <!--              </b-popover>-->
          <!--            </b-col>-->

          <!--            &lt;!&ndash;    Creator    &ndash;&gt;-->
          <!--            <b-col-->
          <!--              sm="12"-->
          <!--              md="12"-->
          <!--              lg="12"-->
          <!--              class="d-flex mt-25 px-0"-->
          <!--            >-->
          <!--              <b-form-input-->
          <!--                id="popover-opinionCreator"-->
          <!--                v-model.trim="newOption.author"-->
          <!--                :placeholder="$t('Creator')"-->
          <!--                maxlength="50"-->
          <!--              />-->

          <!--              &lt;!&ndash;    Popover    &ndash;&gt;-->
          <!--              <b-popover-->
          <!--                target="popover-opinionCreator"-->
          <!--                triggers="focus"-->
          <!--                variant="primary"-->
          <!--              >-->
          <!--                <span>{{ $t('MaximumLength') + ': 50' }}</span>-->
          <!--              </b-popover>-->

          <!--              &lt;!&ndash;    Add Button    &ndash;&gt;-->
          <!--              <b-button-->
          <!--                variant="primary"-->
          <!--                class="btn-icon ml-1 offerWebsiteAdd"-->
          <!--                @click="addOpinion()"-->
          <!--              >-->
          <!--                <feather-icon icon="PlusIcon" />-->
          <!--              </b-button>-->
          <!--            </b-col>-->
          <!--          </b-row>-->

          <!--    opinions    -->
        </my-option>
      </b-col>

      <b-col sm="12">
        <b-row>
          <b-col
            v-for="(item, index) in newWebsite.opinionOptions"
            :key="index"
            sm="12"
            md="6"
            class="w-100 mt-25"
          >
            <div class="d-flex align-items-center justify-content-between">
              <div>
                {{ index + 1 }}. {{ $t('Opinion') }}
              </div>
              <div>
                <b-button
                  variant="flat-warning"
                  class="btn-icon"
                  size="sm"
                  @click="openEditOpinion(index, item)"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>

                <b-button
                  variant="flat-danger"
                  class="btn-icon ml-25"
                  size="sm"
                  @click="
                    newWebsite.opinionOptions.splice(index, 1)

                    newWebsite.opinionOptions.push()
                  "
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </div>
            </div>
            <opinion
              v-if="!!item.translations.length > 0"
              :item="item"
              :def-img="defImg"
              :translation-nr="0"
            />

            <hr class="mb-0">
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!--    Modal: Change opinion options     -->
    <b-modal
      v-model="showChangeOpinionOptionsModal"
      :title="$t('offer.website.CustomersOpinion')"

      size="sm"
      hide-footer
      no-close-on-backdrop
    >
      <b-form>
        <b-form-group :label="$t('contact.avatar')">
          <div
            class="mx-25 mb-25 dragDrop rec d-flex row align-items-center justify-content-start"
          >
            <div>
              <b-img
                id="img-newOption-avatar-modal"
                class="px-0 py-0 center"
                thumbnail
                fluid

                :src="selectedOption.opt && !!selectedOption.opt.avatar && !!selectedOption.opt.avatar.preview ? `data:image/webp;base64,${selectedOption.opt.avatar.preview}`: require('@/assets/icons/user/user.svg')"
              />

              <b-button
                v-b-tooltip.hover.v-primary
                variant="primary"
                :title="$t('DragDropFile')"
              >
                <feather-icon icon="PlusIcon" />
                <input
                  ref="newOptionAvatar"
                  type="file"
                  @change="
                    selectedOption.opt.avatar = onChange($event, 'newOption-avatar-modal', 1250)
                  "
                >
              </b-button>
            </div>
          </div>
        </b-form-group>

        <validation-provider
          v-slot="{ errors }"
          :name="$t('Question')"
          rules="required"
          class="w-100"
        >
          <b-form-group :label="$t('Question')">
            <b-form-input
              v-model.trim="selectedOption.opt.translations[selectedOption.translationNr].title"
              :placeholder="$t('Question')"
              :state="errors.length > 0 ? false:null"
              maxlength="50"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <validation-provider
          v-slot="{ errors }"
          :name="$t('Answer')"
          rules="required"
          class="w-100"
        >
          <b-form-group :label="$t('Answer')">
            <b-form-input
              v-model.trim="selectedOption.opt.translations[selectedOption.translationNr].description"
              :placeholder="$t('Answer')"
              :state="errors.length > 0 ? false:null"
              maxlength="170"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <validation-provider
          v-slot="{ errors }"
          :name="$t('Creator')"
          rules="required"
          class="w-100"
        >
          <b-form-group :label="$t('Creator')">
            <b-form-input

              v-model.trim="selectedOption.opt.translations[selectedOption.translationNr].author"
              :placeholder="$t('Creator')"
              :state="errors.length > 0 ? false:null"
              maxlength="50"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <!--    Button: Save    -->
        <b-button
          variant="success"
          class="ml-25"
          size="sm"
          @click="() => {
            if (selectedOption.id === -1) {
              newWebsite.opinionOptions.push(selectedOption.opt)
            } else newWebsite.opinionOptions[selectedOption.id] = selectedOption.opt
            // newWebsite.opinionOptions[selectedOption.id] = selectedOption.opt
            $forceUpdate()

            showChangeOpinionOptionsModal = false
          }"
        >
          {{ $t('Save') }}
        </b-button>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  BForm, BFormTextarea, BPopover, VBTooltip,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import myOption from '@/views/offers/website/Option.vue'
import Ripple from 'vue-ripple-directive'
import opinion from '@/views/offers/website/Opinion.vue'
import defImg from '@/assets/icons/user/user.svg'

export default {
  components: {
    BForm,
    opinion,
    BFormTextarea,
    myOption,
    ValidationProvider,
    BPopover,
  },

  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  props: {
    value: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    newWebsite: { },

    defImg,

    selectedOption: { },

    newOption: {
      avatar: '',
      title: '',
      desc: '',
      creator: '',
    },

    aboutNewOption: '',

    showChangeOpinionOptionsModal: false,
  }),

  mounted() {
    this.newWebsite = this.value
  },

  methods: {
    onChange(event, option, maxSize) {
      if (event.target.files[0].size > maxSize * 1000) {
        this.showToast('danger', this.$i18n.tc('MoreThanKB', 1, {
          type: this.$i18n.t(`SystemMoreThan.${option}`),
          size: maxSize,
        }))
        return
      }

      const [first] = event.target.files

      if (typeof this.newWebsite[option] !== 'undefined') {
        this.newWebsite[option] = first
      } else {
        // eslint-disable-next-line default-case
        switch (option) {
          case 'newOption-avatar':
            this.newOption.avatar = first
            break
        }
      }

      document.querySelector(`#img-${option}`).src = URL.createObjectURL(first)

      // eslint-disable-next-line consistent-return
      return first
    },

    addOpinion() {
      if (
        this.newOption.title.length > 0
        && this.newOption.description.length > 0
        && this.newOption.author.length > 0
      ) {
        this.newWebsite.opinionOptions.push({
          avatar: this.newOption.avatar,
          translations: [
            {
              locale: 'pl_pl',
              title: JSON.parse(JSON.stringify(this.newOption.title)),
              description: JSON.parse(JSON.stringify(this.newOption.description)),
              author: JSON.parse(JSON.stringify(this.newOption.author)),
            },
          ],
        })

        document.querySelector('#img-newOption-avatar').src = defImg
        this.newOption.avatar = ''
        this.newOption.title = ''
        this.newOption.description = ''
        this.newOption.author = ''

        if (this.$refs.newOptionAvatar) this.$refs.newOptionAvatar.value = ''
      }
    },

    openEditOpinion(index, item) {
      this.selectedOption = {
        id: index,
        translationNr: 0,
        opt: JSON.parse(JSON.stringify(item)),
      }

      this.showChangeOpinionOptionsModal = true
    },
  },
}
</script>
