<template>
  <component :is="tag" :name="label">
    <v-select
      :multiple="isMultiple"
      :close-on-select="!isMultiple || closeOnSelect"
      :options="options"
      :clearable="isClearable"
      item-value="id"
      :value="value"
      :placeholder="placeholder"
      label="firstName"
      :filterable="false"
      :disabled="disabled"
      :class="classes"
      :selectable="isUserSelectable"
      @search="onSearch"
      @input="onChange"
    >
      <template slot="no-options">
        {{ mode === 'users' ? $t('typeToSearchUser') : $t('typeToSearchContact') }}
      </template>
      <template #option="{ id, avatar, firstName, lastName }">
        <b-avatar
          v-if="firstName"
          size="sm"
          :src="avatar"
          :text="firstName.slice(0,1).toUpperCase() + lastName.slice(0,1).toUpperCase()"
        />
        <span class="ml-50"> {{ firstName }} {{ lastName }}  {{ id === '0' ? `- ${$t('Contact')}` : '' }}</span>
      </template>

      <template #selected-option="{ avatar, firstName, lastName }">
        <b-avatar
          v-if="firstName"
          size="sm"
          class="border border-white"
          :src="avatar"
          :text="firstName.slice(0,1).toUpperCase() + lastName.slice(0,1).toUpperCase()"
        />
        <span class="ml-50"> {{ firstName }} {{ lastName }}</span>
      </template>
    </v-select>
  </component>
</template>

<script>
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import { GET_SELECT_USERS } from '../../@constants/mutations'

export default {
  name: 'AssignedUsers',
  components: {
    vSelect,
  },
  props: {
    disableCurrentUser: {
      type: Boolean,
      default: false,
    },
    classes: {
      default: () => [],
      type: Array,
    },
    disabledIds: {
      default: () => [],
      type: Array,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    currentContact: {
      default: null,
      type: Object,
    },
    defaultOptions: {
      default: null,
      type: Array,
    },
    mode: {
      default: 'users',
      type: String,
    },
    tag: {
      default: 'sw-select',
      type: String,
    },
    isMultiple: {
      default: true,
      type: Boolean,
    },
    isClearable: {
      default: true,
      type: Boolean,
    },
    value: {
      type: [Array, Object, String],
    },
    placeholder: {
      type: String,
      default: '',
    },
    closeOnSelect: {
      type: Boolean,
      default: true,
    },
    label: {
      default: '',
      type: String,
    },
    fields: {
      default: null,
      type: String,
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/getCurrentUser',
    }),
  },
  data: () => ({
    options: [],
  }),
  mounted() {
    if (this.currentContact) this.options = [this.currentContact]
    if (this.defaultOptions && this.defaultOptions.length) {
      if (this.defaultOptions[0].user) {
        this.defaultOptions.forEach(item => this.options.push(item.user))
      } else {
        this.options = [...this.defaultOptions]
      }
    }
  },
  methods: {
    onSearch(search, loading) {
      if (!this.defaultOptions || !this.defaultOptions.length) {
        const path = this.mode === 'users' ? `users/${GET_SELECT_USERS}` : 'contacts/GET_SELECT_CONTACTS'
        if (search.length) {
          const payload = this.mode === 'users' ? search : { search }
          loading(true)
          this.$store.dispatch(path, payload)
            .then(res => {
              this.options = this.currentContact ? [this.currentContact, ...res] : res
              loading(false)
            })
        }
      }
    },
    isUserSelectable(user) {
      if (this.disabledIds.length) {
        const ids = [...this.disabledIds, this.disableCurrentUser ? this.currentUser.id : null].filter(Boolean)
        return !ids.includes(user.id)
      }

      return ((user.id !== this.currentUser.id && this.disableCurrentUser) || !this.disableCurrentUser)
    },
    onChange(val) {
      this.$emit('input', val)
    },
  },
}
</script>

<style scoped>

</style>
