<template>
  <component
    :is="isMobile ? 'div' : 'app-timeline-item'"
    icon="CpuIcon"
    variant="info"
    class="pb-1 position-relative"
  >
    <slot />

    <component
      :is="isContact ? 'div' : 'b-card'"
      class="w-100 rounded-lg mb-0 position-relative"
      no-body
    >
      <b-card-body :class="`p-${ isContact ? '0' : '1' }`">
        <div
          class="d-flex mb-50"
          style="column-gap: .5rem"
        >
          <b-avatar
            v-if="isMobile"
            rounded="sm"
            size="sm"
            variant="light-info"
          >
            <span class="d-flex align-items-center">
              <feather-icon
                icon="CpuIcon"
                size="14"
              />
            </span>
          </b-avatar>

          <router-link
            v-if="thread && !isContact"
            class="font-small-3"
            :class="{ 'deleted-text': thread.contact.deletedAt }"
            :to="`/contact/${thread.contact.id}/thread/${thread.id}/timeline`"
          >
            {{ thread.contact.firstName }} {{ thread.contact.lastName }} – {{ thread.name }}
          </router-link>

          <p
            v-else
            class="mb-0"
          >
            {{ $t('CurrentContact') }}
          </p>
        </div>

        <div class="d-flex flex-column flex-md-row mt-25 mt-md-0 font-weight-bold">
          <div>
            {{ $t('Automation') }}
            <!--            <router-link :to="{ name: 'automations', params: { automationId: data.automationProcedure.id } }">-->
            <span class="text-primary">
              {{ data.automationProcedure.name }}
            </span>
          <!--            </router-link>-->
          </div>
          <span class="font-italic ml-50 ml-md-25">– {{ formatDate(timelineDate, { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' }, $i18n.locale) }}</span>
        </div>
      </b-card-body>
    </component>

    <hr
      v-if="isContact"
      class="w-100 mb-0"
    >
  </component>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { formatDate } from '@core/utils/filter'
import useVerticalLayout from '@core/layouts/layout-vertical/useVerticalLayout'
import { onUnmounted } from '@vue/composition-api'

export default {
  name: 'TimelineAutomation',
  components: {
    AppTimelineItem,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: ['data', 'timelineDate', 'threadId', 'thread', 'isContact', 'automation'],
  setup() {
    const { isMobile, resizeHandler } = useVerticalLayout()

    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    return {
      isMobile,
    }
  },
  methods: {
    formatDate,
  },
}
</script>

<style scoped>

</style>
