<!--eslint-disable-->

<template>
  <div>
    <h4 class="mb-1">
      {{ $t('UserCommissionForSaleOfSubUsers') }}
    </h4>

    <b-row style="row-gap: 1rem">
      <!--    START::Specify the depth of the subordinate structure for which the commission is due    -->
      <b-col md="6">
        <validation-provider
          v-slot="{ errors }"
          :name="$t('SpecifyDepthOfSubordinateStructureForWhichCommissionIsDue')"
        >
          <sw-select
            class="mb-0"
            :name="$t('SpecifyDepthOfSubordinateStructureForWhichCommissionIsDue')"
            :tip="$t('HereYouWillSpecifyHowDeepIsStructureOfUsersWhoseSalesWill')"
          >
            <v-select
              v-model="target.structureDeepLevel"
              :options="[1, 2, 3, 5, 10, 15]"
              :create-option="num => Math.abs(num <= 15 ? Number(num) || 0 : 0)"
              @input="changeDeepLevel"
            >
              <template #no-options>
                {{ $t('NoOptions') }}
              </template>

              <template #option="{ label }">
                {{ `${label} ${$tc('LevelDown', label)}` }}
              </template>

              <template #selected-option="{ label }">
                {{ `${label} ${$tc('LevelDown', label)}` }}
              </template>
            </v-select>
          </sw-select>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col>
      <!--    END::Specify the depth of the subordinate structure for which the commission is due    -->

      <!--    START::Specify the basis for calculating the User's commission    -->
      <b-col md="6">
        <validation-provider
          v-slot="{ errors }"
          :name="$t('SpecifyBasisForCalculatingUserCommission')"
        >
          <sw-select
            class="mb-0"
            :state="errors.length > 0 ? false:null"
            :name="$t('SpecifyBasisForCalculatingUserCommission')"
          >
            <v-select
              v-model="target.basisCalculatingUserCommission"
              :options="basisCalculatingUserCommissions"
              :disabled="!target.structureDeepLevel"
              label="label"
              :reduce="i => i.value"
            >
              <template #selected-option="{ label }">
                <span>{{ $t(`${label}`) }}</span>
              </template>
              <template #option="{ label }">
                <span>{{ $t(`${label}`) }}</span>
              </template>
              <template #no-options>
                {{ $t('NoOptions') }}
              </template>
            </v-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </sw-select></validation-provider>
      </b-col>
      <!--    END::Specify the basis for calculating the User's commission    -->

      <!--    START::Specify the net commission amount    -->
      <b-col
        v-if="target.marketingPlanStructureLevels && target.marketingPlanStructureLevels.length"
        sm="12"
        class="py-50 mx-0 row"
        style="row-gap: 1rem"
      >
        <b-col
          v-for="(level, i) in target.marketingPlanStructureLevels"
          :key="i"
          sm="12"
          md="6"
          lg="4"
          class="d-flex align-items-center"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="(i+1) + '. ' + $t('SpecifyNetCommissionAmount')"
            rules="required"
            class="w-100"
          >
            <label class="mb-50">{{ $t(`${i + 1}. ${$tc('LevelDown')}`) }}</label>

            <b-input-group class="mb-50">
              <cleave
                v-model="target.marketingPlanStructureLevels[i].value"
                :state="errors.length > 0 ? false:null"
                class="form-control"
                :options="cleaveOptions.number"
                type="number"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-secondary"
                  class="btn-icon px-1"
                >
                  %
                </b-button>
              </b-input-group-append>
            </b-input-group>

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-col>

      <b-col
        v-else
        sm="12"
        class="text-center py-1 text-primary font-weight-bold"
      >
        <feather-icon
          size="17"
          icon="XCircleIcon"
        />
        {{ $t('ThereAreNoLevelsToDisplay') }}
      </b-col>
      <!--    END::Specify the net commission amount    -->
    </b-row>

    <b-overlay
      :show="isLoading"
      no-wrap
      spinner-variant="primary"
    />
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import { BInputGroup, BInputGroupAppend, VBTooltip } from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'

export default {
  components: {
    ValidationProvider,
    BInputGroup,
    BInputGroupAppend,
    vSelect,
    Cleave,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    target: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    cleaveOptions: {
      number: {
        delimiter: ' ',
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
        numeralPositiveOnly: true,
      },
    },
    // Wartość % ceny netto sprzedanego produktu określona w Planie marketingowym
    // Wartość % ceny netto sprzedanego produktu określona w produkcie
    // Wartość bezwzględna sprzedanego produktu określona w Planie marketingowym
    // Wartość bezwzględna sprzedanego produktu określona w produkcie
    basisCalculatingUserCommissions: [
      { value: 'PERCENT_BY_PLAN', label: 'ValuePercentOfNetPrice' },
      { value: 'PERCENT_BY_PRODUCT', label: 'ValuePercentOfNetPriceByProduct' },
      { value: 'ABSOLUTE_BY_PLAN', label: 'AbsoluteValueOfProductByMarketing' },
      { value: 'ABSOLUTE_BY_PRODUCT', label: 'AbsoluteValueOfProduct' },
    ],
  }),
  methods: {
    changeDeepLevel(event) {
      if (!event) {
        this.$set(this.target, 'marketingPlanStructureLevels', [])
        return
      }
      this.$set(this.target, 'marketingPlanStructureLevels', [...new Array(event).fill(null).map(() => ({
        value: 0,
      }))])
    },
  },
}
</script>
