<template>
  <div>
    <sw-filters
      :shorts="[
        {
          title: $t('TasksAssignedTo'),
          type: 'avatar',
          value: () => filters.assignedUsers,
          clear: () => {
            filters.assignedUsers = []
            changeAssignedUsers()
          }
        },
        {
          title: $t('SelectPlanedTasksRange'),
          value: () => filters.timeRange,
          clear: () => {
            filters.timeRange = '';
            loadData()
          }
        },
      ]"
    >
      <b-row>
        <b-col md="6">
          <sw-select
            class="mb-0"
            :name="$t('TasksAssignedTo')"
          >
            <assigned-users
              :value="[...filters.assignedUsers]"
              @input="changeAssignedUsers"
            />
          </sw-select>
        </b-col>

        <b-col md="6">
          <sw-select :name="$t('SelectPlanedTasksRange')"
                     :tip="$t('SpecifyTheTimeRangeOfScheduledTasks')"
          >
            <sw-select-fpr
              :is-empty="filters.timeRange"
              @clear="filters.timeRange = ''; loadData()"
            >
              <flat-pickr
                ref="timeRange"
                v-model="filters.timeRange"
                class="form-control"
                :config="{ dateFormat: 'd-m-Y', locale: getCalendarLocale($i18n.locale), mode: 'range' }"
                @on-close="loadData()"
              />
            </sw-select-fpr>
          </sw-select>
        </b-col>
      </b-row>
    </sw-filters>
    <b-alert show
             variant="primary"
    >
      <div class="alert-body d-flex align-items-center py-1">
        <feather-icon icon="AlertCircleIcon"
                      size="20"
                      class="mr-50"
        /> {{ $t('ReportShowsCompletionRatesForScheduledTasks') }}
      </div>
    </b-alert>
    <b-card>
      <b-table
        ref="selectableTable"
        :items="items"
        :fields="fields"
        striped
        :style="{ minHeight: tableSize(items, 50) }"
        responsive
        :busy="loading"
        show-empty
      >
        <!--    Head    -->
        <template #head()="head">
          {{ $t(`${head.label}`) }}
          <feather-icon v-if="head.field.tip"
                        v-b-tooltip
                        class="text-primary"
                        icon="AlertCircleIcon"
                        :title="$t(head.field.tip)"
          />
        </template>

        <template #cell(name)="{ item }">
          <div class="py-50">
            <!--              <feather-icon class="text-primary mr-25" :icon="item.icon" />-->
            {{ item.name }}
          </div>
        </template>
        <template #cell(total)="{ item }">
          <b-badge class="font-small-2"
                   style="width: 100px"
                   variant="primary"
          >
            {{ item.stats.all || 0 }}
          </b-badge>
        </template>
        <template #cell(open)="{ item }">
          <b-badge class="font-small-2"
                   style="width: 100px"
                   variant="light-primary"
          >
            {{ item.stats.O || 0 }}
          </b-badge>
        </template>
        <template #cell(completed)="{ item }">
          <b-badge class="font-small-2"
                   style="width: 100px"
                   variant="light-success"
          >
            {{ item.stats.D || 0 }}
          </b-badge>
        </template>
        <template #cell(notDone)="{ item }">
          <b-badge class="font-small-2"
                   style="width: 100px"
                   variant="light-danger"
          >
            {{ item.stats.ND || 0 }}
          </b-badge>
        </template>
        <template #cell(completedRate)="{ item }">
          <b-progress-bar
            style="min-width: 50px"
            class="rounded"
            striped
            :value="(((item.stats.D || 0) / (item.stats.all || 0)) * 100) || 0"
            variant="primary"
            max="100"
          >
            {{ ((((item.stats.D || 0) / (item.stats.all || 0)) * 100) || 0).toFixed(0) }}%
          </b-progress-bar>
          <!--          <b-badge class="font-small-2"-->
          <!--                   style="width: 100px"-->
          <!--                   variant="primary"-->
          <!--          >-->
          <!--            -->
          <!--          </b-badge>-->
        </template>

        <!--    Other    -->
        <template #cell()="row">
          <table-default-cell
            :field="row.field"
            :value="row.value"
          />
        </template>

        <!--      Empty      -->
        <template #empty>
          <empty-content />
        </template>

        <!--      Table Busy      -->
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner
              class="align-middle"
              variant="primary"
            />
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import axiosIns from '@/libs/axios'
import {
  BProgressBar,
  BTable, VBTooltip,
} from 'bootstrap-vue'
import AssignedUsers from '@/views/components/AssignedUsers.vue'
import flatPickr from 'vue-flatpickr-component'

export default {
  name: 'Tasks',
  components: {
    AssignedUsers, BTable, flatPickr, BProgressBar,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data: () => ({
    items: [],
    fields: [
      { label: 'task.Type', key: 'name' },
      { label: 'TotalCountOfScheduledTasks', key: 'total' },
      { label: 'OpenTasksCount', key: 'open' },
      { label: 'CompletedTasksCount', key: 'completed' },
      { label: 'NotDoneTasksCount', key: 'notDone' },
      { label: 'CompletedTasksRate', key: 'completedRate', tip: 'CompletedTasksRateInfo' },
    ],
    filters: {
      assignedUsers: [],
      timeRange: '',
    },
    loading: true,
  }),
  mounted() {
    this.loadData()
  },
  methods: {
    async loadData() {
      this.loading = true
      try {
        const params = {}

        this.$nextTick(async () => {
          if (this.filters.timeRange) {
            const splitDate = this.filters.timeRange.dateRange()
            params.gte_startAt = `${splitDate[0]} 00:00:00`
            if (splitDate[1]) {
              params.lte_startAt = `${splitDate[1]} 23:59:59`
            }
          }

          if (this.filters.assignedUsers?.length) {
            params.eqArr_assignedUser = JSON.stringify(
              this.filters.assignedUsers.map(user => this.getObjectId(user)).filter(Boolean),
            )
          }

          const response = await axiosIns.get('1/reports/contacts/threads/tasks', { params })

          this.items = response.data.data
        })
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.loading = false
      }
    },
    changeAssignedUsers(payload) {
      this.filters.assignedUsers = payload || []
      this.loadData()
    },
  },
}
</script>

<style scoped>

</style>
