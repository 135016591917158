import {
  DELETE_CATEGORY,
  SAVE_CATEGORY,
  GET_CATEGORY,
} from '@/@constants/mutations'
// eslint-disable-next-line import/no-cycle
import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  actions: {
    // eslint-disable-next-line no-unused-vars
    [GET_CATEGORY]: ({ commit }, groupId = null) => new Promise((resolve, reject) => {
      const params = { isNull_parent: '', fields_load: 'SHOP_CATEGORIES' }

      if (groupId) {
        params['eq_userGroups-id'] = groupId
      }

      axiosIns.get('1/shops/categories', { params })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [DELETE_CATEGORY]: ({ commit }, id) => new Promise((resolve, reject) => {
      axiosIns.delete('1/shops/categories', { data: [id] })
        .then(() => {
          resolve()
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [SAVE_CATEGORY]: ({ commit }, categories) => new Promise((resolve, reject) => {
      axiosIns.put('1/shops/categories', categories)
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
  },
}

// Test
