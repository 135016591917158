<template>
  <b-card v-if="user">
    <validation-observer
      ref="loginForm"
      v-slot="{invalid}"
    >
      <b-form-group
        :label="$t('userEdit.OldPassword')"
        label-for="oldPassword"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="$t('login.Password')"
          rules="required"
        >
          <b-form-input
            id="oldPassword"
            v-model="oldPassword"
            :state="errors.length > 0 ? false:null"
            type="password"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <!-- New Password -->
      <b-form-group
        :label="$t('userEdit.NewPassword')"
        label-for="newPassword"
      >
        <validation-provider
          v-slot="{ errors, dirty }"
          :name="$t('userEdit.NewPassword')"
          rules="required"
        >
          <b-input-group>
            <b-form-input
              id="newPassword"
              v-model="newPassword"
              :state="(errors.length > 0) || (dirty && !passwordMatch) ? false:null"
              type="password"
            />
            <b-input-group-append is-text>
              <feather-icon
                id="popover-password-requirements"
                class="cursor-pointer text-primary"
                icon="HelpCircleIcon"
              />

              <b-popover
                ref="popover-show"
                target="popover-password-requirements"
                triggers="hover"
              >
                <template #title>
                  {{ $t('PasswordRequirements.PasswordRequirements') }}
                </template>
                <p
                  v-for="req in passwordRequirements"
                  :key="req"
                  class="text-muted"
                >
                  {{ $t(`PasswordRequirements.${req}`) }}
                </p>
              </b-popover>
            </b-input-group-append>
          </b-input-group>
          <small class="text-danger">{{ errors[0] }}</small>
          <div v-if="!passwordMatch && dirty">
            <small class="text-danger">{{ $t('ThePasswordDoesntMatchRequirements') }}</small>
          </div>
        </validation-provider>
      </b-form-group>

      <b-form-group
        :label="$t('RepeatPassword')"
        label-for="newPasswordRepeat"
      >
        <validation-provider
          v-slot="{ errors, dirty }"
          :name="$t('RepeatPassword')"
          rules="required"
        >
          <b-form-input
            id="newPasswordRepeat"
            v-model="repeatedPassword"
            :state="errors.length > 0 || newPassword !== repeatedPassword ? false:null"
            type="password"
          />
          <small class="text-danger">{{ errors[0] }}</small>
          <div v-if="dirty && newPassword !== repeatedPassword">
            <small class="text-danger">{{ $t('PasswordsArentTheSame') }}</small>
          </div>
        </validation-provider>
      </b-form-group>

      <b-button
        variant="primary"
        size="sm"
        :disabled="invalid || !passwordMatch"
        @click="onSavePassword"
      >
        {{ $t('Save') }}
      </b-button>
    </validation-observer>
    <!-- New Password Repeat -->
    <b-overlay
      no-wrap
      :show="loading"
      spinner-variant="primary"
    />
  </b-card>
</template>

<script>
import { CHANGE_USER_PASSWORD } from '@/@constants/mutations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { BInputGroup, BInputGroupAppend, BPopover } from 'bootstrap-vue'

export default {
  name: 'PasswordChanger',
  components: {
    ValidationProvider,
    ValidationObserver,
    BPopover,
    BInputGroup,
    BInputGroupAppend,
  },
  props: {
    user: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    loading: false,
    oldPassword: '',
    newPassword: '',
    repeatedPassword: '',
    passwordRequirements: [
      'OneLetter',
      'OneDigit',
      'NoSpaces',
      'OneSpecialChar',
      'MinLen',
      'MaxLen',
    ],
  }),
  computed: {
    passwordMatch() {
      const regex = /^(?=^.{8,32}$)(?=.*\d)(?=.*(\W|_)+)(?!.*\n)(?=.*[A-Z])(?=.*[a-z]).*$/
      return regex.test(this.newPassword)
    },
  },
  methods: {
    onSavePassword() {
      this.loading = true

      const payload = {
        oldPassword: this.oldPassword,
        newPassword: this.newPassword,
        userId: this.user.id,
      }
      this.$store.dispatch(`profileSettings/${CHANGE_USER_PASSWORD}`, payload)
        .then(() => {
          this.loading = false
          this.showToast('success', this.$i18n.t('userEdit.PasswordUpdated'))
          localStorage.removeItem('token')

          localStorage.removeItem('user')
          this.$router.push('/login')
        })
        .catch(err => {
          this.loading = false
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
  },
}
</script>

<style scoped>

</style>
