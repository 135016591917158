<template>
  <div>
    <b-alert
      show
      variant="primary"
      class="w-100 mb-50"
    >
      <div
        class="alert-body d-flex flex-wrap align-items-center justify-content-between"
        style="gap: .5rem"
      >
        <div :class="{ 'd-flex align-items-center justify-content-center': !connections.length }">
          <!--          <span v-if="!connections.length">-->
          <!--            <template v-if="!facebookUserID || !facebookAccessToken">{{ $t('fb.AddConnectionTip') }}</template>-->
          <!--            <template>-->
          <!--              <feather-icon icon="XCircleIcon" />-->
          <!--              {{ $t('NoConnections') }}-->
          <!--            </template>-->
          <!--          </span>-->

          <span v-if="!connections.length">
            {{ $t('fb.NoConnections') }}
          </span>

          <div
            v-else
            class="d-flex flex-wrap"
            style="column-gap: .5rem; row-gap: .34rem"
          >
            <span>{{ $t('Connections') }}</span>

            <!--    Tak jakby jednak to chcieli   -->
            <!--            :variant="connection.status === CONNECTION_STATUS.ACTIVE ? 'primary' : 'light-primary'"-->
            <!--            :title="connection.status === CONNECTION_STATUS.ACTIVE ? $t('Deactivate') : $t('Activate')"-->
            <!--              @click="changeStatusConnection(connection.id, index)"-->
          </div>
        </div>

        <b-button
          variant="primary"
          size="sm"
          @click="loginProcess"
        >
          <feather-icon
            icon="PlusIcon"
            style="top: 0"
          />
          {{ $t('Add') }}
        </b-button>
        <!--          :is-logged="Boolean(facebookUserID || false)"-->
        <!--        <facebook-button-->
        <!--          ref="FB_BTN"-->
        <!--          @login="loginProcess"-->
        <!--          @logout="logoutProcess"-->
        <!--          @init="initProcess"-->
        <!--        />-->
      </div>
    </b-alert>

    <b-row v-if="connections.length">
      <b-col
        v-for="(connection, index) in connections"
        :key="`connection_${connection.id}_${index}`"
        lg="4"
      >
        <b-card
          footer-class="p-50"
          header-bg-variant="primary"
          header-text-variant="light"
          header-class="p-50"
          class="mb-1"
        >
          <template #header>
            <div class="d-flex justify-content-between w-100 pl-1 pr-50">
              <strong>
                {{ connection.name }}
              </strong>

              <div class="d-flex">
                <div
                  v-b-tooltip
                  :title="$t('Deactivate')"
                  class="cursor-pointer mr-1"
                  :disabled="deactivateLoading"
                  @click="deactivateProcess(connection.id, index)"
                >
                  <feather-icon icon="XIcon" />
                </div>

                <div
                  v-b-tooltip
                  :title="$t('Refresh')"
                  class="cursor-pointer mr-1"
                  @click="loginProcess()"
                >
                  <feather-icon icon="RefreshCwIcon" />
                </div>

                <div
                  v-b-tooltip
                  :title="$t('Edit')"
                  class="cursor-pointer"
                  @click="mConnectionEdited = connection; mConnection = true"
                >
                  <feather-icon icon="EditIcon" />
                </div>
              </div>
            </div>
          </template>

          <template #footer>
            <div
              v-b-tooltip
              class="d-flex justify-content-between align-items-center flex-wrap"
              :title="$t('Created')"
            >
              <div />
              <div v-if="connection.createdAt">
                {{ formatDate(connection.createdAt.date, { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' }, $i18n.locale) }}
              </div>
              <div v-else>
                {{ formatDate(new Date(), { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' }, $i18n.locale) }}
              </div>
            </div>
          </template>

          <div
            v-if="connection"
            class="d-flex flex-column"
          >
            <div class="d-flex justify-content-between align-items-center mt-1">
              <div
                class="d-flex align-items-center"
                style="column-gap: .5rem"
              >
                <b-avatar
                  variant="primary"
                  size="25"
                >
                  <feather-icon icon="BookmarkIcon" />
                </b-avatar>

                {{ $t('Token') }}
              </div>

              <b-badge
                v-b-tooltip.hover
                :variant="connection.userTokenActive || connection.userToken ? 'light-success' : 'light-danger'"
                pill
              >
                {{ connection.userTokenActive || connection.userToken ? $t('Active') : $t('InActive') }}
              </b-badge>
            </div>
          </div>

          <div
            v-else
            class="p-1 text-center text-primary mt-1 mb-n50"
          >
            <feather-icon icon="XCircleIcon" />
            {{ $t('NoData') }}
          </div>
        </b-card>
      </b-col>
    </b-row>

    <div
      class="w-100 mb-50 d-flex flex-column-reverse flex-md-row align-items-end align-items-md-center justify-content-between"
      style="gap: .5rem"
    >
      <b-alert
        show
        variant="primary"
        class="w-100 mb-0"
      >
        <div class="alert-body">
          <div class="d-flex justify-content-between align-items-center">
            <span>{{ $t('Forms') }}</span>

            <div class="d-flex justify-content-center">
              <!--                :disabled="!facebookUserID || !facebookAccessToken"-->
              <b-button
                variant="primary"
                size="sm"
                @click="() => { mWebhook = true }"
              >
                <feather-icon
                  icon="PlusIcon"
                  style="top: 0"
                />

                {{ $t('Add') }}
              </b-button>
            </div>
          </div>
        </div>
      </b-alert>
    </div>

    <b-row
      v-if="webhooks.length && !loading"
      class="mt-1"
    >
      <b-col
        v-for="(webhook, index) in webhooks"
        :key="`webhook_${webhook.id}_${index}`"
        sm="12"
        md="6"
        lg="4"
      >
        <b-card
          footer-class="p-50"
          header-bg-variant="primary"
          header-text-variant="light"
          header-class="p-50"
        >
          <template #header>
            <div class="d-flex justify-content-between w-100 px-1">
              <div>
                <strong>{{ webhook.integrationFacebookConnection.name }}</strong>
                {{ `– ${webhook.name || '-'}` }}
              </div>

              <div
                v-b-tooltip
                :title="$t('Delete')"
                class="cursor-pointer"
                @click="deleteWebhook(webhook.id, webhook.integrationFacebookConnection.id, index)"
              >
                <feather-icon
                  icon="TrashIcon"
                />
              </div>
            </div>
          </template>

          <div
            v-if="webhook.integrationFacebookWebhookPage && webhook.integrationFacebookWebhookPage.integrationFacebookWebhookPageForm"
            class="d-flex flex-column mt-1"
            style="row-gap: .75rem"
          >
            <div
              class="d-flex flex-column"
              style="row-gap: .34rem"
            >
              <div
                v-b-tooltip.v-primary
                :title="$t('Page')"
                class="d-flex align-items-center"
                style="column-gap: .5rem"
              >
                <b-avatar
                  variant="primary"
                  size="25"
                >
                  <feather-icon icon="BookmarkIcon" />
                </b-avatar>

                {{ webhook.integrationFacebookWebhookPage.name }}
              </div>

              <span
                v-b-tooltip.v-success
                class="text-success cursor-pointer"
                :title="`${$t('Copy')} ID`"
                @click="copyText(webhook.integrationFacebookWebhookPage.pageId)"
              >
                #{{ webhook.integrationFacebookWebhookPage.pageId }}
              </span>
            </div>

            <div
              class="d-flex flex-column"
              style="row-gap: .34rem"
            >
              <div
                v-b-tooltip.v-primary
                :title="$t('Form')"
                class="d-flex align-items-center"
                style="column-gap: .5rem"
              >
                <b-avatar
                  variant="primary"
                  size="25"
                >
                  <feather-icon icon="ListIcon" />
                </b-avatar>

                {{ webhook.integrationFacebookWebhookPage.integrationFacebookWebhookPageForm.name }}
              </div>

              <span
                v-b-tooltip.v-success
                class="text-success cursor-pointer"
                :title="`${$t('Copy')} ID`"
                @click="copyText(webhook.integrationFacebookWebhookPage.integrationFacebookWebhookPageForm.formId)"
              >
                #{{ webhook.integrationFacebookWebhookPage.integrationFacebookWebhookPageForm.formId }}
              </span>
            </div>
          </div>

          <div
            v-else
            class="p-1 text-center text-primary mt-1 mb-n50"
          >
            <feather-icon icon="XCircleIcon" />
            {{ $t('NoData') }}
          </div>

          <template #footer>
            <div
              v-b-tooltip
              class="d-flex flex-wrap justify-content-between align-items-center px-1"
              style="row-gap: .5rem"
              :title="$t('Created')"
            >
              <avatar :user="webhook.createdBy" />

              <p
                v-if="webhook.createdAt"
                class="mb-0"
              >
                {{ formatDate(webhook.createdAt.date, { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' }, $i18n.locale) }}
              </p>

              <p
                v-else
                class="mb-0"
              >
                {{ formatDate(new Date(), { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' }, $i18n.locale) }}
              </p>
            </div>
          </template>
        </b-card>
      </b-col>
    </b-row>

    <div
      v-else
      class="text-center py-50 font-weight-bold text-primary"
    >
      <template v-if="loading">
        <b-spinner
          small
          variant="primary"
        />

        {{ $t('Loading') }}
      </template>

      <template v-else>
        <feather-icon
          size="17"
          icon="XCircleIcon"
        />
        {{ $t('fb.NoForms') }}
      </template>
    </div>

    <!--    START::Modal -> Connects    -->
    <b-modal
      v-model="mConnection"
      :title="$t('Connection')"
      hide-footer
      no-close-on-backdrop
      @hidden="closeConnectionModal"
    >
      <b-alert
        v-if="mConnectionEdited && mConnectionEdited.userId"
        variant="primary"
        show
      >
        <div class="alert-body">
          {{ $tc('RefreshConnection', 1, { name: mConnectionEdited.name }) }}
          <!--          Połączenie "{{ mConnectionEdited.name }}" zostanie odświeżone, w tym momencie możesz zmienić jego nazwę.-->
        </div>
      </b-alert>
      <validation-observer
        ref="newContactFieldForm"
        v-slot="{ invalid }"
        tag="form"
      >
        <validation-provider
          v-slot="{ errors }"
          rules="required"
          :name="$t('Name')"
        >
          <b-form-group>
            <b-form-input
              id="basicInput"
              v-model="mConnectionEdited.name"
              :placeholder="$t('Name')"
              :state="errors.length > 0 ? false:null"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <!-- Footer -->
        <div class="d-flex justify-content-end pt-1 mt-1 border-top">
          <b-button
            variant="secondary mr-25"
            size="sm"
            @click="closeConnectionModal"
          >
            {{ $t('Cancel') }}
          </b-button>

          <b-button
            :disabled="invalid || loading"
            variant="primary"
            size="sm"
            @click="saveConnection"
          >
            {{ $t('Save') }}
          </b-button>
        </div>
      </validation-observer>
    </b-modal>
    <!--    END::Modal -> Connects    -->

    <!--    START::Modal -> Webhooks    -->
    <b-modal
      v-model="mWebhook"
      :title="$t('Webhook')"
      hide-footer
      no-close-on-backdrop
      @hidden="closeWebhookModal"
    >
      <validation-observer
        ref="newContactFieldForm"
        v-slot="{ invalid }"
        tag="form"
      >
        <validation-provider
          v-slot="{ errors }"
          rules="required"
          :name="$t('Name')"
        >
          <b-form-group :name="$t('Name')">
            <label>{{ $t('Name') }}</label>

            <b-form-input
              id="basicInput"
              v-model="mWebhookEdited.name"
              :placeholder="$t('Name')"
              :state="errors.length > 0 ? false:null"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <validation-provider
          v-slot="{ errors }"
          rules="required"
          :name="$t('Connection')"
        >
          <b-form-group>
            <sw-select
              :name="$t('Connection')"
              :state="errors.length > 0 ? false:null"
            >
              <!--                :selectable="c => c.status === CONNECTION_STATUS.ACTIVE"-->
              <v-select
                v-model="mWebhookEdited.connection"
                :options="connections"
                :reduce="item => item.id"
                label="name"
                @input="() => {
                  loadConnectionPages(mWebhookEdited.connection)
                  mWebhookEdited.page = null
                  mWebhookEdited.form = null
                }"
              >
                <template #no-options>
                  {{ $t('NoOptions') }}
                </template>
              </v-select>

              <small class="text-danger">{{ errors[0] }}</small>
            </sw-select>
          </b-form-group>
        </validation-provider>

        <validation-provider
          v-slot="{ errors }"
          rules="required"
          :name="$t('Page')"
        >
          <b-form-group>
            <sw-select
              :name="$t('Page')"
              :state="errors.length > 0 ? false : null"
              :disabled="!mWebhookEdited.connection"
            >
              <v-select
                v-model="mWebhookEdited.page"
                label="name"
                :options="loadedPages"
                :disabled="!mWebhookEdited.connection"
                :reduce="item => ({ name: item.name, pageId: item.id })"
                @input="() => {
                  loadConnectionForm(mWebhookEdited.connection, mWebhookEdited.page.pageId)

                  mWebhookEdited.form = null
                }"
              >
                <template #no-options>
                  {{ $t('NoOptions') }}
                </template>
              </v-select>

              <small class="text-danger">{{ errors[0] }}</small>
            </sw-select>
          </b-form-group>
        </validation-provider>

        <validation-provider
          v-slot="{ errors }"
          rules="required"
          :name="$t('Form')"
        >
          <b-form-group>
            <sw-select
              :name="$t('Form')"
              :state="errors.length > 0 ? false:null"
              :disabled="!mWebhookEdited.connection || !mWebhookEdited.page"
            >
              <v-select
                v-model="mWebhookEdited.form"
                label="name"
                :options="loadedForms"
                :reduce="item => ({ name: item.name, formId: item.id })"
                :disabled="!mWebhookEdited.connection || !mWebhookEdited.page"
              >
                <template #no-options>
                  {{ $t('NoOptions') }}
                </template>
              </v-select>

              <small class="text-danger">{{ errors[0] }}</small>
            </sw-select>
          </b-form-group>
        </validation-provider>

        <!-- Footer -->
        <div class="d-flex justify-content-end pt-1 mt-1 border-top">
          <b-button
            variant="secondary mr-25"
            size="sm"
            @click="closeWebhookModal"
          >
            {{ $t('Cancel') }}
          </b-button>

          <b-button
            :disabled="invalid || loading"
            variant="primary"
            size="sm"
            @click="saveWebhook"
          >
            {{ $t('Save') }}
          </b-button>
        </div>
      </validation-observer>
    </b-modal>
    <!--    END::Modal -> Webhooks    -->
  </div>
</template>

<script>
import axiosIns from '@/libs/axios'
import { VBTooltip } from 'bootstrap-vue'
import payloadKeysCutter from '@/helpers/keysCuter'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import { formatDate } from '@core/utils/filter'

export default {
  name: 'Facebook',
  components: {
    ValidationObserver,
    ValidationProvider,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data: () => ({
    loading: false,

    CONNECTION_STATUS: {
      ACTIVE: 'ACTIVE',
      DEACTIVATED: 'DEACTIVATED',
    },

    facebookAccessToken: null,
    facebookUserID: null,
    connections: [],
    webhooks: [],
    fbUser: null,

    FB_SDK: null,

    mConnection: false,
    mWebhook: false,

    mConnectionEdited: {
      name: '',
    },
    mWebhookEdited: {
      id: null,
      name: '',
      connection: null,
      page: null,
      form: null,
    },
    loadedPages: [],
    loadedForms: [],

    emptyConnection: {
      name: '',
    },

    emptyWebhook: {
      id: null,
      name: '',
      connection: null,
      page: null,
      form: null,
    },
    removeLoading: false,
    deactivateLoading: false,
  }),
  async mounted() {
    await this.loadConnections()
    await this.loadWebhooks()

    if (this.$route.query?.fb_user) {
      this.facebookAccessToken = this.$route.query.fb_user_token
      this.facebookUserID = this.$route.query.fb_user
      this.mConnectionEdited.name = this.$route.query.fb_user_connection

      const existedConnection = this.connections.findWhere('userId', this.facebookUserID)
      if (existedConnection) {
        this.mConnectionEdited = { ...existedConnection, reauthorize: true }
      }

      this.mConnection = true
      window.history.replaceState({}, null, '/')
    }
  },
  methods: {
    formatDate,
    closeConnectionModal() {
      this.mConnection = false
      this.$set(this, 'mConnectionEdited', this.emptyConnection)

      // this.FB_SDK.FB.logout()
      // this.logoutProcess()

      this.$forceUpdate()
    },
    closeWebhookModal() {
      this.mWebhook = false
      this.$set(this, 'mWebhookEdited', this.emptyWebhook)

      this.$forceUpdate()

      this.loadConnectionPages()
    },
    async loadConnections() {
      this.loading = true

      try {
        const resp = await axiosIns.get('1/integration/facebook/connections', {
          params: {
            fields_load: this.$fields.FACEBOOK_CONNECTIONS,
          },
        })

        this.connections = resp?.data?.data?.items || []
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.loading = false
      }
    },

    async loadWebhooks() {
      this.loading = true

      try {
        const res = await axiosIns.get('1/integration/facebook/connection/webhook', { params: { fields_load: this.$fields.FACEBOOK_WEBHOOKS, neq_status: 'DEACTIVATED' } })
        const webhooks = res?.data?.data?.items?.sort((a, b) => new Date(b.createdAt.date) - new Date(a.createdAt.date))

        this.webhooks = webhooks || []
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }

      this.loading = false
    },

    async loadConnectionPages(connectionId) {
      if (!connectionId) return

      try {
        const res = await axiosIns.get(`1/integration/facebook/connection/${connectionId}/pages`)
        this.loadedPages = res?.data?.data ?? []
      } catch (err) {
        this.showToast('danger', this.$i18n.t('ConnectionRefused'), err)
      }
    },
    async loadConnectionForm(connectionId, pageId) {
      if (!connectionId || !pageId) return

      try {
        const res = await axiosIns.get(`1/integration/facebook/connection/${connectionId}/page/${pageId}/forms`)
        this.loadedForms = res?.data?.data ?? []
      } catch (err) {
        this.showToast('danger', this.$i18n.t('ConnectionRefused'), err)
      }
    },
    async deactivateProcess(id, index) {
      this.deactivateLoading = true
      try {
        const payload = {
          userToken: null,
          id,
        }

        await axiosIns({
          method: 'PATCH',
          url: '1/integration/facebook/connections',
          data: payload,
        })

        this.$set(this.connections[index], 'userToken', null)
        this.$set(this.connections[index], 'userTokenActive', false)
        this.showToast('success', this.$i18n.t('success.contactUpdated'))
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.deactivateLoading = false
      }
    },
    async saveConnection() {
      this.loading = true

      if (!this.mConnectionEdited?.id) {
        if (!this.facebookUserID || !this.facebookAccessToken) {
          this.loading = false

          return
        }
      }

      try {
        const payload = {
          userId: this.facebookUserID,
          userToken: this.facebookAccessToken,
          ...this.mConnectionEdited,
        }

        if (payload?.id) delete payload.userId
        if (payload?.id && !payload?.reauthorize) delete payload.userToken

        payloadKeysCutter(payload, ['pages'])

        await axiosIns({
          method: payload?.id ? 'PATCH' : 'POST',
          url: '1/integration/facebook/connections',
          data: payload,
        })

        await this.loadConnections()
        this.showToast('success', this.$i18n.t('success.contactUpdated'))
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.closeConnectionModal()
        this.loading = false
      }
    },

    async saveWebhook() {
      this.loading = true

      const {
        name,
        connection,
        page,
        form,
      } = this.mWebhookEdited

      if (!(name && connection && page && form)) {
        this.showToast('danger', this.$i18n.t('notComplete'))

        this.loading = false

        return
      }

      try {
        const payload = {
          name,
          integrationFacebookWebhookPage: {
            name: page.name,
            pageId: page.pageId,

            integrationFacebookWebhookPageForm: {
              name: form.name,
              formId: form.formId,
            },
          },
        }

        await axiosIns({
          method: 'POST',
          url: `1/integration/facebook/connection/${connection}/webhook`,
          data: payload,
        })

        this.showToast('success', this.$i18n.t('notifications.actions.add'))

        await this.loadWebhooks()
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.closeWebhookModal()
        this.loading = false
      }
    },
    // eslint-disable-next-line no-unused-vars
    async deleteWebhook(id, connection, index) {
      const accepted = await this.showAlert('error', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('alerts.WontbeAble'))
      if (!accepted) return

      try {
        const resp = await axiosIns.patch(`1/integration/facebook/connection/${connection}/webhook`, { status: 'DEACTIVATED', id })

        if (resp) this.$delete(this.webhooks, index)
        this.showToast('success', this.$i18n.t('success.contactUpdated'))
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },

    loginProcess() {
      const { origin } = window.location
      window.location.replace(`https://facebook.saleswizardapp.com/?app=${origin}`)
    },
    logoutProcess() {
      this.facebookAccessToken = null
      this.facebookUserID = null
    },
    initProcess(e) {
      this.$set(this, 'FB_SDK', e)
    },

    copyText(txt) {
      this.showToast('success', this.$i18n.t('CopiedToClipboard'))

      navigator.clipboard.writeText(txt)
    },
  },
}
</script>

<style lang="scss">
.collapse-integration-card {
  .card-header {
    padding-left: 0 !important;
  }
}
</style>
