<template>
  <div>
    <!--    Button: New Category    -->
    <b-button
      v-if="checkRequiredPermissions([$roles.SHOP_CATEGORY_MODIFY])"
      variant="primary"
      size="sm"
      @click="showCategoryModal = true"
    >
      <feather-icon
        icon="PlusIcon"
        class="mr-50"
      />

      <span class="align-middle">{{ $t('Add') }}</span>
    </b-button>

    <!--    Categories View    -->
    <div class="mt-1">
      <b-card
        class="myTableOffer"
        style="overflow-x: scroll"
      >
        <sw-select class="pb-0">
          <v-select
            v-model.trim="selectedLocale"
            :options="filteredLocales"
            :reduce="e => e.value"
            class="mb-0"
            label="name"
          >
            <template #option="{ label }">
              {{ $t(label) }}
            </template>
            <template #selected-option="{ label }">
              {{ $t(label) }}
            </template>
          </v-select>
        </sw-select>
        <my-table
          :selected-locale="selectedLocale"
          :loading="loading"
          :items="categories"
          @openEditModal="openEditModal"
          @deleteItem="deleteItem"
          @createOffer="createOffer"
        />
      </b-card>
    </div>

    <!-- Save Button -->
    <b-button
      v-if="checkRequiredPermissions([$roles.SHOP_CATEGORY_MODIFY])"
      variant="primary"
      @click="saveItems"
    >
      {{ $t('Save') }}
    </b-button>

    <!--    Modal: Create/Edit Category     -->
    <b-modal
      v-model="showCategoryModal"
      :title="$t('offer.category.new')"

      size="md"
      hide-footer
      no-close-on-backdrop
      @close="closeModal"
    >
      <sw-select class="pb-0">
        <v-select
          v-model.trim="selectedLocale"
          :options="filteredLocales"
          :reduce="e => e.value"
          class="mb-0"
          label="name"
          @option:selected="checkAvailableLocale"
        >
          <template #option="{ label }">
            {{ $t(label) }}
          </template>
          <template #selected-option="{ label }">
            {{ $t(label) }}
          </template>
        </v-select>
      </sw-select>
      <validation-observer tag="form">
        <b-row>
          <b-col
            sm="12"
            md="12"
            lg="12"
          >
            <validation-provider
              v-slot="{ errors }"
              :name="$t('Name')"
              rules="required"
            >
              <b-form-group :label="$t('Name')">
                <b-form-input
                  :value="selectedTranslationObject(item).name"
                  :placeholder="$t('Name')"
                  :state="errors.length > 0 ? false:null"
                  @input="setTranslationValue($event, 'name')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
            <sw-select
              v-if="!parent"
              :name="$t('offer.Group')"
            >
              <v-select
                v-model="item.userGroups"
                :placeholder="$t('offer.Group')"
                :options="groups"
                label="name"
              >
                <template #option="{ name, color }">
                  <div
                    class="color-dot"
                    style="border: 1px solid white"
                    :style="{ backgroundColor: color }"
                  />
                  {{ name }}
                </template>
                <template #selected-option="{ name, color }">
                  <div
                    class="color-dot"
                    style="border: 1px solid white"
                    :style="{ backgroundColor: color }"
                  />
                  {{ name }}
                </template>
                <template #no-options>
                  {{ $t('NoOptions') }}
                </template>
              </v-select>
            </sw-select>
          </b-col>
        </b-row>
      </validation-observer>

      <div class="d-flex pt-1 mt-1 border-top">
        <b-button
          variant="primary"
          :disabled="saveDisable"
          size="sm"
          @click="saveItem"
        >
          {{ $t('Save') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import payloadKeysCutter from '@/helpers/keysCuter'
import {
  DELETE_CATEGORY, GET_CATEGORY, GET_GROUPS, SAVE_CATEGORY,
} from '@/@constants/mutations'
import vSelect from 'vue-select'
import myTable from './Table.vue'

export default {
  components: {
    myTable,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data: () => ({
    required,
    parent: null,
    itemIndex: -1,
    item: {
      translations: [
        { name: '', locale: 'pl_pl' },
      ],
      children: [],
      paymentSchedule: [],
      userGroups: [],
    },

    groups: [],
    categories: [],
    oldCategory: {},
    showCategoryModal: false,
    loading: false,

    selectedLocale: 'pl_pl',
    locales: [
      { value: 'pl_pl', label: 'pl_pl' },
      { value: 'en_uk', label: 'en_uk' },
      { value: 'de_de', label: 'de_de' },
      { value: 'ar_dz', label: 'ar_dz', requiredDomain: 'lasland' },
      { value: 'ko_kr', label: 'ko_kr', requiredDomain: 'lasland' },
      { value: 'zh_cn', label: 'zh_cn', requiredDomain: 'lasland' },
    ],
  }),
  computed: {
    filteredLocales() {
      return this.locales.filter(locale => !locale.requiredDomain || window.location.href.includes(locale.requiredDomain))
    },
    saveDisable() {
      return !this.itemName?.length
    },
    itemName() {
      return this.item.translations[0].name
    },
    locale() {
      return this.$i18n.locale
    },
    getPaymentSchedulePercent() {
      return this.item?.paymentSchedule.reduce((a, { value }) => +a + +value, 0)
    },
  },
  mounted() {
    this.reloadData()
  },
  methods: {
    selectedTranslationObject(item) {
      const { selectedLocale } = this

      return item.translations.find(({ locale }) => locale === selectedLocale) || {}
    },
    setTranslationValue(value, key) {
      const { selectedLocale } = this

      const translationIndex = this.item.translations.findIndex(({ locale }) => selectedLocale === locale)
      if (translationIndex > -1) {
        this.$set(this.item.translations[translationIndex], key, value)
      }
    },
    disabledDates(payment, index) {
      if (index === 0) return {}
      if (this.item.paymentSchedule[index - 1] && this.item.paymentSchedule[index - 1]?.date) {
        return {
          disable: [{ from: '2000-04-01', to: this.item.paymentSchedule[index - 1].date }],
        }
      }
      return {}
    },
    addPaymentSchedule() {
      if (!this.item.paymentSchedule) {
        this.$set(this.item, 'paymentSchedule', [])
      }
      this.item.paymentSchedule.push({ date: '', value: '' })
    },
    ///    Get Groups     ///
    loadGroups() {
      this.$store.dispatch(`groups/${GET_GROUPS}`)
        .then(res => {
          this.groups = res.data.items
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err.response.data.message}`))
        })
    },
    reloadData() {
      this.loadGroups()
      this.loading = true
      this.$store.dispatch(`categories/${GET_CATEGORY}`)
        .then(res => {
          this.categories = res.data.items
          this.categories.forEach(cat => {
            // eslint-disable-next-line
            cat.userGroups = cat.userGroups[0] || []
          })
          this.loading = false
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err.response.data.message}`))
          this.loading = false
        })
    },
    openEditModal(row, parent) {
      if (!this.checkRequiredPermissions([this.$roles.SHOP_CATEGORY_MODIFY])) return

      if (row) this.item = JSON.parse(JSON.stringify(row.item))

      this.checkAvailableLocale(this.selectedLocale)

      this.parent = parent
      this.itemIndex = row?.index || row?.index === 0 ? row.index : -1
      this.showCategoryModal = true
    },
    closeModal() {
      this.parent = null
      this.itemIndex = -1
      this.item = {
        translations: [
          { name: '', locale: 'pl_pl' },
        ],
        children: [],
        paymentSchedule: [],
        userGroups: [],
      }
      this.showCategoryModal = false
    },
    saveItem() {
      this.$nextTick(() => {
        const item = { ...this.item }
        if (!item.children) item.children = []

        if (this.itemIndex === -1 && !this.parent) {
          // Add Root Category
          this.categories.push(this.item)
        } else if (this.parent && this.itemIndex === -1) {
          // Add Subcategory
          this.categories[this.parent.index].children.push(this.item)
        } else if (this.parent && (this.parent.index || this.parent.index === 0) && this.itemIndex !== -1) {
          // Edit Subcategory
          this.$set(this.categories[this.parent.index].children[this.itemIndex], 'paymentSchedule', [])
          this.$set(this.categories[this.parent.index].children, this.itemIndex, item)
        } else {
          // Edit Category
          this.$set(this.categories, this.itemIndex, this.item)
        }
        this.closeModal()
      })
    },
    createOffer(row, parent) {
      if (row?.item?.id && parent?.item?.id) {
        this.$router.push({ name: 'productEdit', params: { defaultCategory: parent.item, defaultSubcategory: row.item } })
      } else if (row?.item?.id) {
        this.$router.push({ name: 'productEdit', params: { defaultCategory: row.item } })
      }
    },
    async deleteItem(row, parent) {
      if (row?.item?.id) {
        this.showAlert('error', this.$i18n.t('alerts.AreYouSure'))
          .then(async () => {
            await this.deleteItemFromDatabase(row.item.id)

            if (parent?.index) {
              this.categories[parent.index].children.splice(row.index, 1)
            } else {
              this.categories.splice(row.index, 1)
            }
          })
      } else if (parent?.index) {
        this.categories[parent.index].children.splice(row.index, 1)
      } else {
        this.categories.splice(row.index, 1)
      }
    },
    deleteItemFromDatabase(id) {
      this.$store.dispatch(`categories/${DELETE_CATEGORY}`, id)
        .then(() => {
          this.showToast('success', this.$i18n.t('RemovedSuccessfully'))
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err.response.data.message}`))
        })
    },
    checkAvailableLocale(object) {
      if (!object) return null

      const value = object?.value || object || 'pl_pl'

      const isInTranslations = Boolean(this.item.translations.find(({ locale }) => value === locale))

      if (!isInTranslations) {
        const translation = {
          name: '',
          locale: value,
        }
        this.item.translations.push(translation)

        return translation
      }
      return null
    },
    saveItems() {
      const categories = JSON.parse(JSON.stringify(this.categories))
      const preparedCategories = payloadKeysCutter(categories, ['parent', '_showDetails'])
      preparedCategories.forEach(cat => {
        const group = typeof cat.userGroups === 'object' ? [cat.userGroups.id] : [cat.userGroups]
        // eslint-disable-next-line
        cat.children = cat.children.map(e => ({ ...e, userGroups: group }))
        // eslint-disable-next-line
        cat.userGroups = group
      })

      this.$store.dispatch(`categories/${SAVE_CATEGORY}`, preparedCategories)
        .then(() => {
          this.showToast('success', this.$i18n.t('success.contactUpdated'))
          this.reloadData()
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err.response.data.message}`))
        })
    },
  },
}
</script>

<style lang="scss">
.stickyFilterBar {
  position: sticky;

  top: 7rem;
}

.myTableOffer {
  overflow: hidden;
  & .card-body {
    .table-responsive {
      margin: unset;
    }
  }
}
</style>
