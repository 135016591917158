<template>
  <div>
    <div
      v-if="!loading"
      class="pt-1"
    >
      <b-overlay
        no-wrap
        :show="loading"
        spinner-variant="primary"
      />
      <div>
        <b-form
          ref="form"
          class="repeater-form"
        >
          <!-- Row Loop -->
          <!--          <draggable-->
          <!--            v-model="reasons"-->
          <!--            class="px-50 pb-50"-->
          <!--          >-->
          <div v-if="reasons.length">
            <draggable v-model="reasons">
              <b-card
                v-for="(reason, index) in reasons"
                :key="`positive_${index}`"
                no-body
                class="p-75 cursor-pointer mb-1"
              >
                <div
                  class="d-flex flex-wrap flex-column flex-md-row justify-content-md-between"
                  style="row-gap: .5rem"
                >
                  <p
                    class="h5 mb-0 d-flex align-items-center"
                    style="column-gap: .34rem"
                  >
                    <feather-icon
                      icon="MoveIcon"
                      class="mr-25"
                      size="18"
                    />

                    {{ reason.name }}
                  </p>

                  <div>
                    <b-button
                      variant="outline-warning"
                      class="mr-25"
                      size="sm"
                      @click="editField(reason, reason.index)"
                    >
                      <feather-icon
                        icon="EditIcon"
                        class="mr-25"
                      />
                      <span>{{ $t('Edit') }}</span>
                    </b-button>

                    <b-button
                      variant="outline-danger"
                      class="mr-25"
                      size="sm"
                      @click="removeItem(reason.id, reason.index)"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-25"
                      />
                      <span>{{ deleteReasons.includes(reason.id ? reason.id.toString() : reason.id) ? $t('Cancel') : $t('Delete') }}</span>
                    </b-button>
                  </div>
                </div>
              </b-card>
            </draggable>
          </div>
          <div
            v-else
            class="text-center py-1 font-weight-bold"
          >
            <feather-icon
              size="17"
              icon="XCircleIcon"
            />
            {{ $t('NoData') }}
          </div>
          <!--          </draggable>-->

        </b-form>
      </div>
      <b-button
        variant="primary"
        class="mr-1"
        size="sm"
        @click="onSaveForm"
      >
        <span>{{ $t('Save') }}</span>
      </b-button>
      <b-button
        variant="outline-primary"
        size="sm"
        @click="addField"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-25"
        />
        <span>{{ $t('Add') }}</span>
      </b-button>
      <!-- edited modal -->
      <b-modal
        v-model="isFieldEdited"
        hide-footer
        :title="editedFieldIndex > -1 ? $t('Edit') : $t('New')"
        no-close-on-backdrop
      >
        <!-- Edited name  -->
        <b-form-group
          :label="$t('Name')"
          label-for="reason_name"
        >
          <b-form-input
            id="reason_name"
            v-model="editedField.name"
            type="text"
          />
        </b-form-group>
        <b-button
          variant="success"
          :disabled="!editedField.name"
          @click="saveEditedField"
        >
          {{ $t('Confirm') }}
        </b-button>
      </b-modal>
      <!-- edited modal -->
    </div>
    <div
      v-else
      class="d-flex justify-content-center"
    >
      <b-spinner
        variant="primary"
        class="m-5"
      />
    </div>
  </div>
</template>

<script>
import { BForm, BFormGroup, BModal } from 'bootstrap-vue'
import axiosIns from '@/libs/axios'
import draggable from 'vuedraggable'

export default {
  name: 'CloseReasons',
  components: {
    BForm,
    BFormGroup,
    BModal,
    draggable,
  },
  data: () => ({
    loading: false,
    areChangesSaved: true,
    isFieldEdited: false,
    editedField: {
      id: null,
      name: '',
    },
    reasons: [],
    editedFieldIndex: 0,
    deleteReasons: [],
  }),
  async mounted() {
    await this.init()
  },
  methods: {
    async init() {
      this.loading = true
      try {
        const params = { fields_load: this.$fields.CONTACT_APPLICATION_CLOSE_REASONS, orderBy: 'position.asc' }
        const resp = await axiosIns.get('1/settings/contactApplicationStatusReasons', { params })

        if (resp?.data?.data?.items) {
          const items = resp.data?.data?.items || []
          this.reasons = items
        }
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.loading = false
      }
    },
    removeItem(id, index) {
      this.showAlert('error', this.$i18n.t('alerts.AreYouSure'))
        .then(async () => {
          if (id) await axiosIns.delete('1/settings/contactApplicationStatusReasons', { data: JSON.stringify([id]) })
          this.reasons.splice(index, 1)
        }).finally(() => {
          this.areChangesSaved = false
        })
    },
    editField(field, index) {
      this.isFieldEdited = true
      this.editedField = { ...field }
      this.editedFieldIndex = index
      this.areChangesSaved = false
    },
    addField() {
      this.isFieldEdited = true
      this.editedField = {
        id: null,
        name: '',
      }
      this.editedFieldIndex = -1
      this.areChangesSaved = false
    },
    saveEditedField() {
      const item = this.editedField
      if (this.editedFieldIndex !== -1) {
        this.$set(this.reasons, this.editedFieldIndex, item)
      } else {
        this.reasons.push(item)
      }

      this.isFieldEdited = false
      this.areChangesSaved = false
      this.editedField = {
        id: null, name: '',
      }
      this.editedFieldIndex = 0
    },
    async onSaveForm() {
      this.loading = true
      const reasonsPayload = (JSON.parse(JSON.stringify(this.reasons)) || []).map((reason, index) => {
        // eslint-disable-next-line no-underscore-dangle
        const _reason = { name: reason.name, position: index }
        if (reason.id) _reason.id = reason.id

        return _reason
      })

      try {
        // if (this.deleteReasons.length) {
        //   await axiosIns.delete('1/settings/contactApplicationStatusReasons', { data: JSON.stringify(this.deleteReasons.map(r => this.getObjectId(r))) })
        // }

        await axiosIns.put('1/settings/contactApplicationStatusReasons', reasonsPayload)

        this.showToast('success', this.$i18n.t('success.contactUpdated'))
        this.areChangesSaved = true
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        await this.init()
      }
    },
  },

}
</script>

<style lang="scss">
.popover-tasks {
  max-width: unset !important;
  & .popover-body {
    width: 400px;
  }
}
</style>
