<template>
  <div id="offer-settings">
    <h4>{{ $t('General') }}</h4>
    <b-card>
      <b-form-group>
        <b-form-checkbox
          v-model="allowSystemAgreementInCart"
          checked="true"
          name="check-button"
          variant="primary"
          switch
          inline
          class="w-100"
        >
          {{ $t('isSystemOfferAgreement') }}
        </b-form-checkbox>
      </b-form-group>
      <b-form-group class="mb-0">
        <b-form-checkbox
          v-model="allowAgreementInCart"
          checked="true"
          name="check-button"
          variant="primary"
          switch
          inline
          class="w-100"
        >
          {{ $t('isOfferArrangement') }}
        </b-form-checkbox>
      </b-form-group>
    </b-card>
    <b-button
      variant="primary"
      :disabled="loading"
      @click="save"
    >
      {{ $t('Save') }}
    </b-button>
    <b-overlay
      no-wrap
      :show="loading"
      spinner-variant="primary"
    />
  </div>
</template>

<script>
import {
  GET_SETTINGS, SAVE_SETTINGS,
} from '@/@constants/mutations'
import { mapGetters } from 'vuex'

export default {
  name: 'OfferSettings',
  components: {
  },
  data: () => ({
    loading: false,
    allowAgreementInCart: false,
    allowSystemAgreementInCart: false,
  }),
  computed: {
    ...mapGetters({
      system: 'system/getSettings',
    }),
  },
  async mounted() {
    this.loading = true
    try {
      const resp = await this.$store.dispatch(`system/${GET_SETTINGS}`)

      this.allowSystemAgreementInCart = resp.allowSystemAgreementInCart || false
      this.allowAgreementInCart = resp.allowAgreementInCart || false
    } catch (err) {
      this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
    } finally {
      this.loading = false
    }
  },
  methods: {
    async save() {
      this.system.allowSystemAgreementInCart = this.allowSystemAgreementInCart
      this.system.allowAgreementInCart = this.allowAgreementInCart

      try {
        await Promise.all([this.saveSettings()])
        this.showToast('success', this.$i18n.t('success.contactUpdated'))
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.loading = false
      }
    },
    async saveSettings() {
      this.loading = true
      await this.$store.dispatch(`system/${SAVE_SETTINGS}`, {
        savePayload: {
          allowSystemAgreementInCart: this.allowSystemAgreementInCart,
          allowAgreementInCart: this.allowAgreementInCart,
        },
      })
      this.loading = false
    },
  },
}
</script>

<style lang="scss">
#offer-settings {
    .popover {
        min-width: 280px;
        max-width: 350px !important;
        .popover-body {
            padding: 0.65rem 0 !important;
        }
    }
}
</style>
