import {
  DELETE_POLL,
  GET_POLLS,
  CREATE_POLL,
  UPDATE_POLL,
  GET_POLL_OPTIONS,
  DELETE_POLL_OPTION,
  SAVE_POLL_OPTION,
} from '@/@constants/mutations'
// eslint-disable-next-line import/no-cycle
import axiosIns from '@/libs/axios'
import * as fields from '@/@constants/fields'

export default {
  namespaced: true,
  actions: {
    // eslint-disable-next-line no-unused-vars
    [GET_POLLS]: ({ commit }, { pagination = null, filters = null }) => new Promise((resolve, reject) => {
      const params = {
        fields_load: fields.SETTINGS_POLLS,
      }

      if (pagination) {
        params.page = pagination.currentPage
        params.limit = pagination.perPage
      }

      if (filters) {
        if (filters.polls) {
          params.eqArr_id = JSON.stringify(filters.polls)
        }
      }

      axiosIns.get('1/settings/polls', { params })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [DELETE_POLL]: ({ commit }, id) => new Promise((resolve, reject) => {
      axiosIns.delete('1/settings/polls', { data: [id] })
        .then(() => {
          resolve()
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [CREATE_POLL]: ({ commit }, pool) => new Promise((resolve, reject) => {
      axiosIns.post('1/settings/polls', pool)
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [UPDATE_POLL]: ({ commit }, pool) => new Promise((resolve, reject) => {
      axiosIns.patch('1/settings/polls', pool)
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),

    // Poll Options
    // eslint-disable-next-line no-unused-vars
    [GET_POLL_OPTIONS]: ({ commit }, pollId) => new Promise((resolve, reject) => {
      axiosIns.get(`1/settings/polls/${pollId}/fields`, { params: { fields_load: fields.SETTINGS_POLLS_FIELDS, orderBy: 'position.asc' } })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [DELETE_POLL_OPTION]: ({ commit }, { pollId, optionId }) => new Promise((resolve, reject) => {
      axiosIns.delete(`1/settings/polls/${pollId}/fields`, { data: [optionId] })
        .then(() => {
          resolve()
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [SAVE_POLL_OPTION]: ({ commit }, { pollId, options }) => new Promise((resolve, reject) => {
      /* eslint-disable */
      options.forEach(option => {
        delete option.updatedAt
        delete option.createdAt
        delete option.deletedAt
        delete option.updatedBy
        delete option.createdBy
        delete option.deletedBy
      })
      axiosIns.put(`1/settings/polls/${pollId}/fields`, options)
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
  },
}
