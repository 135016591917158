<template>
  <sw-tabs
    lazy
    :tabs="tabs"
    :card="false"
  />
</template>

<script>
import DoCalculation from './tabs/doCalculation/index.vue'
import AllCalculation from './tabs/allCalculation/index.vue'
import Calculators from './tabs/calculators/index.vue'

export default {
  data: () => ({
    tabs: [
      {
        title: 'DoCalculation',
        component: DoCalculation,
      },
      {
        title: 'AllCalculation',
        component: AllCalculation,
      },
      {
        title: 'Calculators',
        component: Calculators,
      },
    ],
  }),
}
</script>
