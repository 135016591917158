<template>
  <validation-observer
    ref="newProjectForm"
    v-slot="{ invalid }"
    tag="form"
  >
    <!--    START::Processes – Form    -->
    <validation-provider
      v-slot="{ errors }"
      rules="required"
      :name="$t('EnterNameOfProcess')"
    >
      <b-form-group :label="$t('EnterNameOfProcess')">
        <b-form-input
          v-model="proces.title"
          :placeholder="$t('Title')"
          :state="errors.length > 0 ? false:null"
          @input="e => $emit('getTitle', e)"
        />

        <small class="text-danger">{{ errors[0] }}</small>
      </b-form-group>
    </validation-provider>

    <!--    Processes – Form -> Funnel    -->
    <validation-provider
      v-slot="{ errors }"
      rules="required"
      :name="$t('SelectFunnel')"
    >
      <sw-select
        :name="$t('SelectFunnel')"
      >
        <v-select
          id="select_funnel"
          v-model="proces.funnel"
          class="mb-1"
          :options="funnelList"
          label="name"
          :reduce="funnel => funnel.contactThreadFunnelTypePoints[0].id"
          :state="errors.length > 0 ? false:null"
        >
          <template #no-options>
            {{ $t('NoOptions') }}
          </template>
          <template #selected-option="{ name }">
            {{ name | truncate(15, '...') }}
          </template>
          <template #option="{ name }">
            {{ name | truncate(15, '...') }}
          </template>
        </v-select>
      </sw-select>

      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>

    <!--    Processes – Form -> Checklist    -->
    <validation-provider
      v-slot="{ errors }"
      rules="required"
      :name="$t('SelectFunnel')"
    >
      <sw-select
        v-if="!modalConfig.editedData"
        :name="$t('SelectChecklist')"
      >
        <v-select
          id="select_checklist"
          v-model="proces.checklist"
          class="mb-1"
          :options="checklists"
          label="name"
          multiple
          :reduce="checklist => checklist.id.toString()"
          :state="errors.length > 0 ? false:null"
        >
          <template #no-options>
            {{ $t('NoOptions') }}
          </template>
          <template #selected-option="{ name }">
            {{ name }}
          </template>
          <template #option="{ name }">
            {{ name }}
          </template>
        </v-select>
      </sw-select>

      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>

    <!--    Processes – Form -> Assigned Users    -->
    <div class="mb-1">
      <label>{{ $t('AssignedUsers') }}</label>

      <assigned-users
        :value="proces.assignedUsers"
        :is-multiple="true"
        @input="changeProcesAssignedUsers"
      />
    </div>

    <!--    Processes – Form -> Value    -->
    <b-form-group class="mb-1">
      <label>{{ $t('CustomerValue') }}</label>

      <b-row>
        <b-col
          sm="9"
        >
          <cleave
            v-model="proces.value"
            :options="cleaveOptions.number"
            class="form-control"
          />
        </b-col>

        <b-col
          sm="3"
        >
          <sw-select>
            <v-select
              v-model="proces.currency"
              :options="currencyList"
            >
              <template #no-options>
                {{ $t('NoOptions') }}
              </template>
            </v-select>
          </sw-select>
        </b-col>
      </b-row>
    </b-form-group>

    <!--    Processes – Form -> Assigned Contacts    -->
    <div class="mt-1">
      <label>{{ $t('AssignedContact') }}</label>
      <div
        v-for="(contact, index) in proces.assignedContacts"
        :key="`thread_assigned_user_${index}`"
        class="d-flex flex-wrap flex-md-nowrap mt-50"
      >
        <sw-select>
          <v-select
            v-model="contact.contact"
            item-value="id"
            :options="contacts"
            label="firstName"
            class="w-50 mr-50 select-size-sm"
            style="min-width: 255px"
            :filterable="false"
            @search="onSearch"
          >
            <template slot="no-options">
              {{ $t('typeToSearchContact') }}
            </template>
            <template #option="{ id, avatar, firstName, lastName }">
              <b-avatar
                size="sm"
                :src="avatar"
                :text="firstName.slice(0,1).toUpperCase() + lastName.slice(0,1).toUpperCase()"
              />
              <span class="ml-50 pt-25"> {{ firstName }} {{ lastName }}  {{ id === '0' ? `- ${$t('Contact')}` : '' }}</span>
            </template>

            <template #selected-option="{ avatar, firstName, lastName }">
              <b-avatar
                size="sm"
                class="border border-white"
                :src="avatar"
                :text="firstName.slice(0,1).toUpperCase() + lastName.slice(0,1).toUpperCase()"
              />
              <span class="ml-50 pt-25"> {{ firstName }} {{ lastName }}</span>
            </template>
          </v-select>
        </sw-select>

        <b-input-group
          class="input-group-merge ml-25"
          size="sm"
          style="min-width: 100px"
        >
          <b-form-input
            v-model="contact.part"
            type="number"
            :placeholder="$t('Part') + ' %'"
          />
        </b-input-group>

        <b-button
          class="ml-50"
          variant="flat-danger"
          size="sm"
          @click="deleteContact(index)"
        >
          <feather-icon icon="TrashIcon" />
        </b-button>
      </div>
      <div class="mt-50">
        <b-button
          size="sm"
          variant="flat-success"
          @click="addContact"
        >
          <feather-icon icon="PlusIcon" />
        </b-button>
      </div>
    </div>
    <!--    END::Processes – Form    -->

    <!--    Footer-->
    <div class="d-flex justify-content-end pt-1 mt-1 border-top">
      <b-button
        variant="secondary"
        size="sm"
        class="mr-50"
        @click="onModalClose"
      >
        {{ $t('Cancel') }}
      </b-button>

      <b-button
        variant="primary"
        size="sm"
        :disabled="invalid"
        @click="save"
      >
        {{ $t('Save') }}
      </b-button>
    </div>
    <!--    Footer-->
  </validation-observer>
</template>

<script>
import vSelect from 'vue-select'
import AssignedUsers from '@/views/components/AssignedUsers.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapGetters } from 'vuex'
import currency from '@/helpers/currency'
import axiosIns from '@/libs/axios'
import { GET_FUNNELS } from '@/@constants/mutations'
import Cleave from 'vue-cleave-component'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    AssignedUsers,
    vSelect,
    Cleave,
  },

  data: () => ({
    proces: {
      title: '',
      funnel: '',
      checklist: '',
      assignedUsers: [],
      value: '',
      currency: '',
      assignedContacts: [],
    },

    currencyList: currency(),
    checklists: [],
    funnelList: [],
    contacts: [],
    cleaveOptions: {
      number: {
        delimiter: ' ',
        numeral: true,
        blocks: [3, 3, 3],
      },
      zip: {
        delimiter: '-',
        // numeral: true,
        blocks: [2, 3],
      },
    },
  }),

  computed: {
    ...mapGetters({
      modalConfig: 'modal/getModalState',
    }),
  },

  mounted() {
    this.ini()
  },

  methods: {
    ini() {
      // eslint-disable-next-line prefer-destructuring
      this.currencySelected = this.currencyList[0]
      this.loadChecklists()
      this.loadFunnels()
    },

    onModalClose() {
      this.$emit('close')
    },

    save() {

    },

    changeAssignedUsers(payload) {
      this.assignedUsers = [...payload]
    },

    addContact() {
      this.proces.assignedContacts.push({ ...this.newContact })
    },

    deleteContact(index) {
      this.proces.assignedContacts.splice(index, 1)
    },

    loadChecklists() {
      axiosIns.get('1/settings/contactThreadChecklistTypes', { params: { fields_load: this.$fields.SETTINGS_FUNNEL_TYPE, orderBy: 'position.asc' } })
        .then(res => {
          this.checklists = res.data.data.items
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },

    loadFunnels() {
      this.$store.dispatch(`funnelMain/${GET_FUNNELS}`)
        .then(res => {
          this.funnels = res
        })
        .catch(() => {
          this.showToast('danger', this.$i18n.t('ProblemOccured'))
        })
    },

    changeProcesAssignedUsers(payload) {
      this.proces.assignedUsers = payload
    },

    onSearch(search, loading) {
      if (search.length) {
        loading(true)
        const payload = {
          search,
        }

        this.$store.dispatch('contacts/GET_SELECT_CONTACTS', payload)
          .then(res => {
            this.contacts = res
            loading(false)
          })
      }
    },
  },
}
</script>
