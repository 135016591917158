var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-button',{staticClass:"mb-50",attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.$router.push({ name: 'edit-ai-connection', params: { id: 'new' } })}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_vm._v(" "+_vm._s(_vm.$t('Add'))+" ")],1),_c('b-card',[_c('sw-table',{attrs:{"table-id":"2aa2fe3d81d04ad3ad8816a2cb2d9357","show-columns":"","show-search":"","fields":_vm.connectionFields,"pagination":_vm.pagination},on:{"change-pagination":function($event){return Object.assign(_vm.pagination, $event)},"change-search-query":function($event){_vm.searchQuery = $event},"reload-content":_vm.loadData,"set-fields":function($event){_vm.connectionFields = $event}},scopedSlots:_vm._u([{key:"table",fn:function(){return [_c('b-table',{staticClass:"offer-table",style:({ minHeight: _vm.tableSize(_vm.items, 200) }),attrs:{"items":_vm.items,"fields":_vm.fields,"striped":"","responsive":"","busy":_vm.loading,"show-empty":"","sort-by":_vm.sorter.sortBy,"sort-desc":_vm.sorter.sortDesc,"no-local-sorting":true},on:{"update:sortBy":function($event){return _vm.$set(_vm.sorter, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.sorter, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.sorter, "sortDesc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.sorter, "sortDesc", $event)},"sort-changed":function($event){Object.assign(_vm.sorter, $event); _vm.loadData()}},scopedSlots:_vm._u([{key:"head()",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(("" + label)))+" ")]}},{key:"cell(id)",fn:function(ref){
var index = ref.index;
return [_vm._v(" #"+_vm._s(_vm.getOrderNo(index, _vm.pagination, _vm.sorter))+" ")]}},{key:"cell(files)",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [(Array.isArray(value) && value.length)?_c('documents-list',{attrs:{"attach":'body',"documents":value}}):_c('feather-icon',{attrs:{"icon":"MinusIcon"}})]}},{key:"cell(name)",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'edit-ai-connection', params: { id: item.id } }}},[(value)?_c('span',[_vm._v(_vm._s(value))]):_c('feather-icon',{attrs:{"icon":"MinusIcon"}})],1)]}},{key:"cell(connectedData)",fn:function(ref){
var value = ref.value;
var item = ref.item;
var index = ref.index;
return [(Array.isArray(value) && value)?_c('div',[_c('b-badge',{staticClass:"cursor-pointer",attrs:{"variant":"primary","id":("connectedData_" + index + "_" + (item.id))}},[_vm._v(_vm._s(_vm.$t('Modules'))+": "+_vm._s(value.length))]),_c('b-popover',{attrs:{"target":("connectedData_" + index + "_" + (item.id)),"triggers":"hover"}},[_c('b-list-group',{attrs:{"flush":""}},_vm._l((value),function(listItem,listIndex){return _c('b-list-group-item',{key:("connectedData_" + index + "_" + (item.id) + "_" + listIndex)},[_vm._v(" "+_vm._s(listItem.name)+" ")])}),1)],1)],1):_c('feather-icon',{attrs:{"icon":"MinusIcon"}})]}},{key:"cell(postedData)",fn:function(ref){
var value = ref.value;
var item = ref.item;
var index = ref.index;
return [(Array.isArray(value) && value)?_c('div',[_c('b-badge',{staticClass:"cursor-pointer",attrs:{"variant":"primary","id":("postedData_" + index + "_" + (item.id))}},[_vm._v(_vm._s(_vm.$t('Files'))+": "+_vm._s(value.length))]),_c('b-popover',{attrs:{"target":("postedData_" + index + "_" + (item.id)),"triggers":"hover"}},[_c('b-list-group',{attrs:{"flush":""}},_vm._l((value),function(listItem,listIndex){return _c('b-list-group-item',{key:("postedData_" + index + "_" + (item.id) + "_" + listIndex)},[_vm._v(" "+_vm._s(listItem.name)+" ")])}),1)],1)],1):_c('feather-icon',{attrs:{"icon":"MinusIcon"}})]}},{key:"cell(dataStatus)",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [(value)?_c('b-badge',{attrs:{"variant":_vm.getStatusVariant(value)}},[_vm._v(_vm._s(_vm.$t(value.toLowerCase().ucFirst())))]):_c('feather-icon',{attrs:{"icon":"MinusIcon"}})]}},{key:"cell(status)",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [(value)?_c('b-badge',{attrs:{"variant":_vm.getStatusVariant(value)}},[_vm._v(_vm._s(_vm.$t(value.toLowerCase().ucFirst())))]):_c('feather-icon',{attrs:{"icon":"MinusIcon"}})]}},{key:"cell(model)",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [(value)?_c('span',[_vm._v(_vm._s(value))]):_c('feather-icon',{attrs:{"icon":"MinusIcon"}})]}},{key:"cell(used)",fn:function(ref){
var value = ref.value;
var item = ref.item;
var index = ref.index;
return [_c('b-badge',{attrs:{"variant":"light-primary"}},[_vm._v(" "+_vm._s(item.channelsCount || 0)+" "+_vm._s(_vm.$tc('Channel', item.channelsCount || 0))+" ")])]}},{key:"cell(wear)",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_vm._v(" "+_vm._s(value)+" "+_vm._s(_vm.$t('Tokens').toLowerCase())+" ")]}},{key:"cell(createdBy)",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('avatar',{attrs:{"user":value}})]}},{key:"cell(effects)",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"mr-25",attrs:{"title":_vm.$t('EffectsPositive'),"variant":"light-success"}},[_vm._v(_vm._s(item.effectPositive))]),_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],attrs:{"title":_vm.$t('EffectsNegative'),"variant":"light-danger"}},[_vm._v(_vm._s(item.effectNegative))])]}},{key:"cell(action)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('b-dropdown',{staticClass:"dropdown-icon-wrapper text-decoration-none",attrs:{"variant":"link","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"align-middle",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.$router.push({ name: 'edit-ai-connection', params: { id: item.id } })}}},[_vm._v(" "+_vm._s(_vm.$t('Edit'))+" ")]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.deleteItem(item, index)}}},[_vm._v(" "+_vm._s(_vm.$t('Delete'))+" ")])],1)]}},{key:"cell()",fn:function(row){return [_c('table-default-cell',{attrs:{"field":row.field,"value":row.value}})]}},{key:"empty",fn:function(){return [_c('empty-content')]},proxy:true},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[_c('b-spinner',{staticClass:"align-middle",attrs:{"variant":"primary"}})],1)]},proxy:true}])})]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }