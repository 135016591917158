<template>
  <expand-window
    :title="$t('FullWindow')"
  >
    <orders :thread-id="getObjectId(thread)" />
  </expand-window>
</template>

<script>
import Orders from '@/pages/orders/List.vue'
import ExpandWindow from '@core/components/@sw/ExpandWindow.vue'

export default {
  components: { ExpandWindow, Orders },
  props: {
    thread: {
      default: null,
    },
  },
}
</script>
