<template>
  <div :class="{ 'mt-1': !showFilters }">
    <!--    Section: Filters    -->
    <component
      :is="isTask ? 'task-filters' : 'project-filters'"
      :show-filters="showFilters"
      :start-filters="startFilters"
      :is-loading="isLoading || isMeLoading"
      :is-task="isTask"
      :project-id="projectId"
      @change="fl => {
        filtersList = fl

        statusContent = []

        ini()
      }"
    />

    <div class="w-100 overflow-y-scroll">
      <div
        v-if="view === 'grid'"
        class="d-flex"
        style="overflow-x: auto"
      >
        <div
          v-for="(status, index) in statusList"
          :key="index"
          class="mr-2"
        >
          <!--      Project Header      -->
          <div
            v-b-tooltip.hover
            class="mb-1 d-flex justify-content-between align-items-center"
            :title="status.name"
            :style="`width: ${!isMobile ? '340px' : '74vw'}`"
          >
            <h4>{{ status.name | textIsland(22, '…') }}</h4>

            <b-badge variant="light-primary">
              <h4 class="mb-0 text-primary px-25">
                {{ statusList.name }}

                {{ (status.projects || status.projectTasks).length }}
              </h4>
            </b-badge>
          </div>

          <template v-if="statusContent.length && statusContent.find(c => Number(c.id) === Number(status.id))">
            <item-grid
              :key="statusList.length"
              :is-loading="isLoading || isMeLoading"
              :index="Number(status.id)"
              :status-list="statusContent.find(c => Number(c.id) === Number(status.id)).items"
              :is-task="isTask"
              class="w-100"
              @update="updateItem"
              @reload="reloadItem"
            />
          </template>
        </div>

        <div
          :style="`min-width: ${!isMobile ? '340px' : '74vw'}`"
        >
          <div
            v-b-tooltip.v-primary.hover
            class="w-100 d-flex justify-content-between align-items-center cursor-pointer"
            :title="$t('AddStatus')"
            @click="openModal()"
          >
            <h4 class="mb-0">
              {{ $t('NewStatus') }}
            </h4>

            <b-button
              variant="primary"
              class="btn-icon p-50"
            >
              <feather-icon icon="PlusIcon" />
            </b-button>
          </div>
        </div>
      </div>

      <div
        v-else
        class="d-flex flex-column"
      >
        <b-tabs
          v-model="selectedTab"
          class="mb-2"
        >
          <b-tab
            v-for="(status, index) in statusList"
            :key="index"
            :title="status.name + '  [ ' + (status.projects || status.projectTasks).length + ' ]'"
            style="min-height: 50vh;"
          >
            <template #title>
              <span class="mr-25">{{ status.name }}</span>

              <b-badge
                variant="light-primary"
                class="ml-25"
              >
                {{ (status.projects || status.projectTasks).length }}
              </b-badge>
            </template>

            <template v-if="getStatusContent.length">
              <item-extensible
                v-for="(target, i) in getStatusContent"
                :key="i"
                :is-loading="isLoading || isContentLoading"
                :index="i"
                :project="target.project || null"
                :target="target"
                :is-task="isTask"
                @update="updateItem"
              />
            </template>

            <div
              v-else-if="isLoading || isMeLoading || isContentLoading"
              class="text-center py-1 font-weight-bold w-100 text-primary"
            >
              <b-spinner
                small
                variant="primary"
              />

              {{ $t('Loading') }}
            </div>

            <div
              v-else
              class="text-center py-1 font-weight-bold w-100 text-primary"
            >
              <feather-icon
                size="17"
                icon="XCircleIcon"
              />

              {{ $t('EmptyArray') }}
            </div>
          </b-tab>

          <template #tabs-start>
            <b-nav-item
              role="presentation"
              href="#"
              @click="ini()"
            >
              <b-button
                v-b-tooltip.v-primary.hover
                variant="primary"
                class="btn-icon p-25"
                size="sm"
                :title="$t('Refresh')"
              >
                <feather-icon
                  icon="RefreshCwIcon"
                  class="m-0"
                />
              </b-button>
            </b-nav-item>
          </template>

          <template #tabs-end>
            <b-nav-item
              v-if="checkRequiredPermissions([$roles.PROJECT_MODIFY])"
              role="presentation"
              href="#"
              @click="openModal()"
            >
              <b-button
                v-b-tooltip.v-primary.hover
                variant="primary"
                class="btn-icon p-25"
                size="sm"
                :title="$t('AddStatus')"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="m-0"
                />
              </b-button>
            </b-nav-item>
          </template>
        </b-tabs>

        <b-overlay
          :show="isLoading || isMeLoading"
          no-wrap
          spinner-variant="primary"
          blur="0"
          opacity=".75"
          rounded="sm"
        />
      </div>

      <!--      Modal: Categories      -->
      <b-modal
        :visible="!!mCategories"
        :title="$tc('Categories', 1)"
        size="sm"

        hide-footer
        no-close-on-backdrop
        @hide="mCategories = null"
      >
        <b-card-text class="mt-n25">
          <div
            v-for="(category, indexProgres) in mCategories"
            :key="indexProgres"
            class="my-1"
          >
            <div
              v-b-tooltip.hover
              class="d-flex mt-25 align-items-center justify-content-between"
              :title="category.name"
            >
              <span class="w-50">{{ category.name | truncate(28, '…') }}</span>

              <b-badge variant="light-primary">
                <span class="px-25">
                  {{ category.progres }}%
                </span>
              </b-badge>
            </div>
          </div>
        </b-card-text>
      </b-modal>

      <!--      Modal: Tags      -->
      <b-modal
        :visible="!!mTags"
        :title="$t('Tags')"
        size="sm"

        hide-footer
        no-close-on-backdrop
        @hide="mTags = null"
      >
        <b-card-text class="d-flex flex-wrap">
          <b-badge
            v-for="(tag, indexTag) in mTags"
            :key="indexTag"
            v-b-tooltip.hover.v-primary
            class="m-25"
            variant="light-primary"
            :title="tag"
          >
            <template v-if="indexTag < 6">
              {{ '#' + tag | truncate(14, '…') }}
            </template>
          </b-badge>
        </b-card-text>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  BCardText, BNavItem, BTab, BTabs, VBToggle, VBTooltip,
} from 'bootstrap-vue'
import { Polish as pl } from '@/libs/i18n/locales/flatpickr/pl'
import { english as en } from '@/libs/i18n/locales/flatpickr/en'
import {
  GET_PROJECT_STATUSES,
  GET_PROJECT_TASK_STATUSES,
  GET_PROJECT_TASKS,
  GET_PROJECTS,
  OPEN_MODAL,
  UPDATE_PROJECT,
  UPDATE_PROJECT_TASK,
} from '@/@constants/mutations'
import useVerticalLayout from '@core/layouts/layout-vertical/useVerticalLayout'
import { onUnmounted } from '@vue/composition-api'
import { mapGetters } from 'vuex'
import ItemGrid from './component/ItemGrid.vue'
import ItemExtensible from './component/ItemExtensible.vue'
import ProjectFilters from './project/Filters.vue'
import TaskFilters from './task/Filters.vue'

export default {
  components: {
    BTabs,
    BTab,
    BNavItem,
    BCardText,
    ProjectFilters,
    TaskFilters,
    ItemGrid,
    ItemExtensible,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    view: {
      type: String,
      required: true,
    },
    isTask: {
      type: Boolean,
      required: false,
      default: false,
    },
    showFilters: {
      type: Boolean,
      required: false,
      default: true,
    },
    projectId: {
      type: Number,
      required: false,
      default: null,
    },
    startFilters: {
      type: Object,
      required: false,
      default: null,
    },
  },
  setup() {
    const { isMobile, resizeHandler } = useVerticalLayout()

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    return {
      perfectScrollbarSettings,
      isMobile,
    }
  },
  data: () => ({
    isContentLoading: false,
    isMeLoading: false,

    mTags: false,
    mCategories: false,

    flatPickerConfig: {
      mode: 'range',
      locale: [pl, en],
    },

    filtersList: {},
    statusList: [],

    selectedTab: 0,
    statusContent: [],
  }),
  computed: {
    ...mapGetters({
      modalState: 'modal/getModalState',
    }),
    locale() {
      return this.$i18n.locale
    },
    getStatusContent() {
      const {
        statusContent,
        statusList,
        selectedTab,
      } = this

      const content = statusContent.find(c => c.id === statusList[selectedTab].id)

      return content?.items || []
    },
  },
  watch: {
    selectedTab(n) {
      const statusId = this.statusList[n]?.id

      if (statusId) this.loadContent(statusId)
    },
    modalState: {
      deep: true,
      handler(n) { if (n.reloadContent) this.loadStatuses() },
    },
    view(n) {
      this.statusContent = []

      if (n === 'grid') this.loadContent()
      if (n === 'extensible') this.loadCurrentContent()
    },
  },
  methods: {
    async ini() {
      const { view } = this

      await this.loadStatuses()

      if (view === 'grid') this.loadContent()
      if (view === 'extensible') this.loadCurrentContent()
    },

    async loadStatuses() {
      return new Promise(resolve => {
        this.isMeLoading = true

        // const filters = this.filtersList

        const type = !this.isTask ? `projectStatuses/${GET_PROJECT_STATUSES}` : `taskStatuses/${GET_PROJECT_TASK_STATUSES}`

        this.$store.dispatch(type)
          .then(res => {
            const newList = res

            this.$set(this, 'statusList', newList)
          })

          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          })

          .finally(() => { this.isMeLoading = false; resolve() })
      })
    },

    loadCurrentContent() {
      const statusId = this.statusList[this.selectedTab]?.id

      if (statusId) this.loadContent(statusId)
    },

    loadContent(id) {
      this.isContentLoading = true

      const { statusList } = this
      const filters = this.filtersList

      const filtersList = filters

      filtersList.status = id && this.view === 'extensible' ? id : null

      const type = !this.isTask ? `projects/${GET_PROJECTS}` : `tasks/${GET_PROJECT_TASKS}`

      this.$store.dispatch(type, filtersList)
        .then(res => {
          if (id) {
            const content = { id, items: res }

            this.$set(this, 'statusContent', [content])
          } else {
            const content = statusList.map(item => ({
              id: Number(item.id),
              items: res.filter(i => (!this.isTask ? i.projectStatus.id === item.id : i.projectTaskStatus.id === item.id)),
            })) || []

            this.$set(this, 'statusContent', content)
          }
        })

        .catch(err => this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err))

        .finally(() => { this.isContentLoading = false })
    },

    removeTarget() {
      this.$root.$emit('bv::hide::popover')
      this.$emit('changeTarget', null)
    },

    openModal() {
      this.$store.commit(`modal/${OPEN_MODAL}`,
        {
          modalType: 'addStatusModal',
          size: 'sm',
          modalTitle: this.$i18n.t('AddStatus'),
          okButtonTitle: '',
          data: {
            status: null,
            isTask: this.isTask,
          },
        })
    },

    async updateTargets() {
      const newStatusList = JSON.parse(JSON.stringify(this.statusContent)).map(s => ({
        id: s.id,
        targetList: s.items.map(i => {
          const res = { id: i.id }

          if (this.isTask) res.project = i.project.id

          return res
        }),
      })).flat(1)

      // eslint-disable-next-line
      for (const status of newStatusList) {
        // eslint-disable-next-line
        for (const target of status.targetList) {
          const payload = {
            id: target.id,
            [!this.isTask ? 'projectStatus' : 'projectTaskStatus']: status.id,
          }

          if (target?.project) payload.project = target.project

          // eslint-disable-next-line no-await-in-loop
          await this.updateTarget(payload)
        }
      }

      await this.loadStatuses()
    },

    async updateTarget(payload) {
      const type = !this.isTask ? `projects/${UPDATE_PROJECT}` : `tasks/${UPDATE_PROJECT_TASK}`

      try {
        await this.$store.dispatch(type, payload)
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },

    updateItem() {
      if (this.view === 'grid') {
        this.updateTargets()
      } else {
        this.reloadItem()
      }
    },

    reloadItem() {
      this.loadStatuses()
      this.loadContent()
    },
  },
}
</script>
