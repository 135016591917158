<template>
  <b-button
    :variant="variant"
    :size="size"
    :disabled="loading || disabled"
    @click="$emit('click')"
  >
    <span v-if="!loading">
      <sw-icon v-if="icon"
               :icon="icon"
               class="mr-25"
      />
      <span v-if="label">{{ $te(label) ? $t(label) : label }}</span>
      <span v-if="count"
            class="ml-25"
      >({{ count }})</span>
    </span>
    <b-spinner v-else
               small
    />
  </b-button>
</template>

<script>
export default {
  name: 'SwActionButton',
  props: {
    size: { required: false, default: 'sm', type: String },
    icon: { required: false, default: '', type: String },
    variant: { required: false, default: 'primary', type: String },
    label: { required: true, default: '', type: String },
    count: { required: false, default: 0, type: Number },
    disabled: { required: false, default: false, type: Boolean },
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    setLoading(value = false) {
      this.$set(this, 'loading', value)
    },
  },
}
</script>

<style scoped>

</style>
