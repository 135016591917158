<!--eslint-disable-->

<template>
  <div class="position-relative new-organization">
    <div
      v-if="loading"
      class="position-absolute w-100 h-100 d-flex align-items-center justify-content-center"
      style="z-index: 9"
    >
      <b-spinner variant="primary" />
    </div>

    <div
      ref="shortcode-tabs"
      :style="loading && 'opacity: .5'"
    >
        <component :is="isModalView ? 'div' : 'b-card'" :title="$t('BasicInformation')">
            <b-row>
                <b-col lg="3">
                    <sw-select
                            :name="$t('vatIdType')"
                            :disabled="isPreview"
                    >
                        <v-select
                                v-model="organization.vatIdType"
                                label="country"
                                :options="vatIdTypeList"
                                :disabled="isPreview"
                                :filter="(options, search) => filterByKey(options, search, 'country', 'countryCodes.')"
                                :placeholder="$t('Customizer.Animations.None')"
                        >
                            <template #selected-option="{ country, value }">
                                <span><strong>{{ $te(`countryCodes.${country}`) ? $t(`countryCodes.${country}`) : country }}</strong> {{ value.replaceAll('_', ' ').toUpperCase() }}</span>
                            </template>
                            <template #option="{ country, value }">
                                <span><strong>{{ $te(`countryCodes.${country}`) ? $t(`countryCodes.${country}`) : country }}</strong> {{ value.replaceAll('_', ' ').toUpperCase() }}</span>
                            </template>

                            <template #no-options>
                                {{ $t('noOptions') }}
                            </template>

                            <template #no-options="{ search }">
                <span v-if="search.length">
                  {{ $t('NoData') }}
                </span>
                                <span v-else>
                  {{ $t('TypeToSearch') }}
                </span>
                            </template>
                        </v-select>
                    </sw-select>
                </b-col>

                <b-col lg="9">
                    <!-- Nip -->
                    <label>{{ $t('NIP') }}</label>

                    <b-input-group
                            class="mb-50"
                    >
                        <b-input-group-prepend v-if="nipExists">
                            <b-button
                                    v-b-tooltip
                                    variant="outline-warning"
                                    :title="$t('TypedVatIdAlreadyExists')"
                            >
                                <feather-icon
                                        icon="AlertCircleIcon"
                                        size="12"
                                />
                            </b-button>
                        </b-input-group-prepend>

                        <b-form-input
                                v-model="organization.vatId"
                                type="text"
                                :disabled="isPreview"
                                :placeholder="organization.vatIdType ? organization.vatIdType.placeholder : ''"
                                @blur="checkExistedNip"
                        />

                        <b-input-group-append v-if="organization.vatIdType && organization.vatIdType.country === 'Poland'">
                            <b-button
                                    :disabled="!organization.vatId || isPreview || gusLoading"
                                    variant="outline-primary"
                                    @click="downloadFromGus"
                            >
                                {{ $t('DownloadFromCSO') }}
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-col>
            </b-row>

            <b-form-group>
                <label>{{ $t('contact.companyName') }}</label>
                <b-form-input
                        v-model="organization.name"
                        :disabled="isPreview"
                />
            </b-form-group>

            <div class="d-flex">
                <div
                        sm="3"
                        style="min-width: 150px"
                >
                    <b-form-group :label="$t('VatPayer')">
                        <b-form-radio
                                v-model="organization.vatPayer"
                                :value="true"
                                :disabled="isPreview"
                                variant="primary"
                        >
                            {{ $t(`Yes`) }}
                        </b-form-radio>
                        <b-form-radio
                                v-model="organization.vatPayer"
                                :value="false"
                                :disabled="isPreview"
                                variant="primary"
                        >
                            {{ $t(`NoNo`) }}
                        </b-form-radio>
                    </b-form-group>
                </div>

                <div class="w-100">
                    <sw-select :name="$t('ValueVat')">
                        <v-select
                                v-model="organization.vat"
                                :disabled="!organization.vatPayer || isPreview"
                                :options="vats"
                        >
                            <template #selected-option="{ label }">
                <span v-if="organization.vatPayer">
                  {{ label }}%
                </span>
                                <span v-else>
                  {{ $t('Customizer.Animations.None') }}
                </span>
                            </template>
                            <template #option="{ label }">
                <span v-if="organization.vatPayer">
                  {{ label }}%
                </span>
                                <span v-else>
                  {{ $t('Customizer.Animations.None') }}
                </span>
                            </template>
                            <template #no-options>
                                {{ $t('NoOptions') }}
                            </template>
                        </v-select>
                    </sw-select>
                </div>
            </div>

            <sw-select :name="$t('ParentOrganization')">
                <v-select
                        v-model="organization.parentOrganization"
                        :options="organizations"
                        label="name"
                        :filterable="false"
                        @search="loadOrganizations"
                >
                    <template #no-options="{ search }">
                      <span v-if="search.length">
                        {{ $t('NoData') }}
                      </span>
                              <span v-else>
                        {{ $t('TypeToSearch') }}
                      </span>
                    </template>
                    <template #selected-option="{ name }">
                        {{ name }}
                    </template>
                    <template #option="{ name }">
                        {{ name }}
                    </template>
                </v-select>
            </sw-select>

            <div v-if="organization.vatIdType && (organization.vatIdType === 'eu_vat' || organization.vatIdType.value === 'eu_vat')">
                <sw-select :name="$t('PkbLabel')">
                    <v-select
                        v-model="organization.organizationClassificationCodes"
                        :options="pkdCodes"
                        label="code"
                        multiple
                        :filterable="true"
                        :filter="filter"
                    >
                        <template #no-options="{ search }">
                      <span v-if="search.length">
                        {{ $t('NoData') }}
                      </span>
                            <span v-else>
                        {{ $t('TypeToSearch') }}
                      </span>
                        </template>
                        <template #selected-option="{ code, description }">
                            {{ code }} - {{ description }}
                        </template>
                        <template #option="{ code, description }">
                            {{ code }} - {{ description }}
                        </template>
                    </v-select>
                </sw-select>

<!--                <b-form-group :label="$t('PkbLabel')">-->
<!--                    <cleave-->
<!--                        v-model="organization.organizationClassificationCodes"-->
<!--                        :options="{-->
<!--              delimiter: ' ',-->
<!--              numeral: true,-->
<!--              numeralThousandsGroupStyle: 'thousand',-->
<!--            }"-->
<!--                        class="form-control"-->
<!--                    />-->
<!--                </b-form-group>-->
            </div>

            <div>
                <b-form-group :label="$t('Note')">
                    <b-form-textarea v-model="organization.note" />
                </b-form-group>
            </div>
        </component>
      <hr>

        <app-collapse>
            <app-collapse-item v-if="!isHydropure"  :title="$t('Contacts')">
                <div v-if="!isHydropure">
                    <!--    START::Contacts    -->
                    <b-button
                        v-b-tooltip.hover.v-primary
                        variant="outline-primary"
                        :title="$t('AddContact')"
                        size="sm"
                        class="mt-n50 mt-md-50"
                        @click="showContactOrganizationModal = true"
                    >
                        <feather-icon icon="PlusIcon" />
                        {{ $t('AddContact') }}
                    </b-button>

                    <b-table
                        :items="organization.contacts"
                        :fields="contactListFields"
                        striped
                        responsive
                        :busy="loading"
                        show-empty
                        class="mt-1 border rounded-lg"
                    >
                        <!--    Head    -->
                        <template #head()="{ label }">
                            {{ $t(`${label}`) }}
                        </template>

                        <!--    Contact    -->
                        <template #cell(contact)="{ item }">
                            <b-link
                                :class="{ 'deleted-text': item.deletedAt }"
                                :href="`/contact/${item.id}`"
                                target="_blank"
                            >
                                {{ `${item.firstName} ${item.lastName}` }}
                            </b-link>
                        </template>

                        <template #cell(isNew)="{ value }">
                            <b-badge :variant="value ? 'light-success' : 'light-primary'">
                                {{ value ? $t('New') : $t('Assigned2') }}
                            </b-badge>
                        </template>

                        <template #cell(position)="{ value }">
                                  <span v-if="value">
                                    {{ $t(contactPositionTypeByValue(value)) }}
                                  </span>
                            <feather-icon
                                v-else
                                icon="MinusIcon"
                            />
                        </template>

                        <!--    Action    -->
                        <template #cell(action)="{ index }">
                            <b-button
                                v-b-tooltip.hover.v-danger
                                size="sm"
                                variant="light-danger"
                                class="btn-icon p-25"
                                :title="$t(`RemoveConnectionOrganization`)"
                                @click="deleteContactFromOrganization(index)"
                            >
                                <feather-icon icon="TrashIcon" />
                            </b-button>
                        </template>

                        <!--    Other    -->
                        <!--    Other    -->
                        <template #cell()="row">
                            <table-default-cell
                                :field="row.field"
                                :value="row.value"
                            />
                        </template>

                        <!--      Empty      -->
                        <template #empty>
                            <empty-content />
                        </template>

                        <!--      Table Busy      -->
                        <template #table-busy>
                            <div class="text-center text-danger my-2">
                                <b-spinner
                                    class="align-middle"
                                    variant="primary"
                                />
                            </div>
                        </template>
                    </b-table>
                </div>

                <b-row class="my-1">
                    <b-col
                        sm="12"
                        class="mb-50"
                    >
                        <b-alert show variant="primary">
                            <div class="alert-heading d-flex align-items-center">
                                <b-form-checkbox
                                    v-model="createdContact"
                                    switch
                                    variant="primary"
                                    :disabled="Boolean(organization.contactId)"
                                >
                                </b-form-checkbox>
                                {{ $t('CreateBasicContact') }}
                            </div>
                            <div class="alert-body">{{ $t('CreatedBasicInfoTooltip') }}</div>
                        </b-alert>

                        <!--                        <div-->
                        <!--                                v-b-tooltip.left-->
                        <!--                                style="max-width: 300px"-->
                        <!--                                :title="$t('CreatedBasicInfoTooltip')"-->
                        <!--                        >-->
                        <!--                            <b-form-checkbox-->
                        <!--                                    v-model="createdContact"-->
                        <!--                                    switch-->
                        <!--                                    :disabled="Boolean(organization.contactId)"-->
                        <!--                            >-->
                        <!--                                {{ $t('CreateBasicContact') }} <feather-icon icon="AlertCircleIcon" />-->
                        <!--                            </b-form-checkbox>-->
                        <!--                        </div>-->
                    </b-col>
                    <!-- E-mail -->
                    <b-col
                        v-if="createdContact"
                        md="6"
                        sm="12"
                    >
                        <validation-provider
                            v-for="(email, index) in organization.emails"
                            v-slot="{ errors }"
                            :key="`email_address_${index}`"
                            :name="`${index + 1}.${$t('contact.email')}`"
                        >
            <span>
              {{ index + 1 }}. {{ $t('contact.email') }}
              <feather-icon
                  v-if="index === 0"
                  v-b-tooltip
                  icon="AlertCircleIcon"
                  class="text-primary ml-25"
                  :title="$t('FirstMailIsPrimary')"
              />
            </span>

                            <b-input-group>
                                <b-form-input
                                    v-model.trim="organization.emails[index]"
                                    type="email"
                                    :state="errors.length > 0 ? false:null"
                                    @blur="checkExistedEmails"
                                />

                                <b-input-group-append v-if="index !== 0 || existedMail(email)">
                                    <!-- Delete Email Buttons -->
                                    <b-button
                                        v-if="index !== 0"
                                        variant="outline-danger"
                                        class="btn-icon"
                                        @click="$delete(organization.emails, index)"
                                    >
                                        <feather-icon
                                            icon="TrashIcon"
                                            size="12"
                                        />
                                    </b-button>
                                    <!-- Email Exist Buttons -->
                                    <b-button
                                        v-if="existedMail(email)"
                                        :id="`email_exist_button_${index}`"
                                        variant="outline-warning"
                                    >
                                        <feather-icon
                                            icon="AlertCircleIcon"
                                            size="12"
                                        />
                                        <b-popover
                                            :target="`email_exist_button_${index}`"
                                            triggers="hover"
                                        >
                                            <template #title>
                                                <span>{{ $t('TypedEmailExist') }}</span>
                                            </template>
                                            <div
                                                v-for="existedContact in existedMail(email).contacts"
                                                :key="`contact_${index}_${existedContact.id}`"
                                            >
                                                <router-link :to="{ name: 'contact', params: { contactId: existedContact.id } }">
                                                    {{ existedContact.firstName }} {{ existedContact.lastName }}
                                                </router-link>
                                            </div>
                                            <!--                        test@mail-saleswizardapp.com-->
                                        </b-popover>
                                    </b-button>
                                </b-input-group-append>
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>

                        <b-button
                            size="sm"
                            class="mt-25"
                            variant="primary"
                            @click="organization.emails.push('')"
                        >
                            <feather-icon icon="PlusIcon" />
                            {{ $t('Add') }}
                        </b-button>
                    </b-col>

                    <!-- phone -->
                    <b-col
                        v-if="createdContact"
                        md="6"
                        sm="12"
                    >
                        <validation-provider
                            v-for="(phone, index) in organization.phones"
                            v-slot="{ errors }"
                            :key="`phone_${index}`"
                            :name="`${index + 1}.${$t('contact.phone')}`"
                        >
            <span
                class="mt-1"
            >{{ index + 1 }}. {{ $t('contact.phone') }} <feather-icon
                v-if="index === 0"
                v-b-tooltip
                icon="AlertCircleIcon"
                class="text-primary ml-25"
                :title="$t('FirstPhoneIsPrimary')"
            /></span>
                            <b-input-group>

                                <small class="text-danger">{{ errors[0] }}</small>
                            </b-input-group>

                            <div class="d-flex align-items-center">
                                <b-input-group-prepend>
                                    <sw-select class="mb-0">
                                        <v-select
                                            v-model="organization.phones[index].countryCode"
                                            :options="countryCodes"
                                            label="dial_code"
                                            style="min-width: 125px"
                                            class="mr-50"
                                            :clearable="false"
                                            @option:selected="checkExistedPhones"
                                        />
                                    </sw-select>
                                </b-input-group-prepend>
                                <cleave
                                    v-model="organization.phones[index].value"
                                    :options="cleaveOptions.number"
                                    class="form-control rounded"
                                    :state="errors.length > 0 ? false:null"
                                    @blur="checkExistedPhones"
                                />
                                <b-input-group-append v-if="index !== 0 || existedPhone(phone)">
                                    <b-button
                                        v-if="index !== 0"
                                        variant="outline-danger"
                                        class="btn-icon ml-25"
                                        @click="$delete(organization.phones, index)"
                                    >
                                        <feather-icon
                                            icon="TrashIcon"
                                            size="12"
                                        />
                                    </b-button>
                                    <!-- Email Exist Buttons -->
                                    <b-button
                                        v-if="existedPhone(phone)"
                                        :id="`phone_exist_button_${index}`"
                                        variant="outline-warning"
                                        class=" ml-25"
                                    >
                                        <feather-icon
                                            icon="AlertCircleIcon"
                                            size="12"
                                        />
                                        <b-popover
                                            :target="`phone_exist_button_${index}`"
                                            triggers="hover"
                                        >
                                            <template #title>
                                                <span>{{ $t('TypedPhoneExist') }}</span>
                                            </template>
                                            <div
                                                v-for="existedContact in existedPhone(phone).contacts"
                                                :key="`contact_phone_${index}_${existedContact.id}`"
                                            >
                                                <router-link :to="{ name: 'contact', params: { contactId: existedContact.id } }">
                                                    {{ existedContact.firstName }} {{ existedContact.lastName }}
                                                </router-link>
                                            </div>
                                        </b-popover>
                                    </b-button>
                                </b-input-group-append>
                            </div>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>

                        <b-button
                            size="sm"
                            class="mt-25"
                            variant="primary"
                            @click="organization.phones.push({
              value: '',
              countryCode: {
                code: 'PL',
                dial_code: '+48 ',
                name: 'Poland',
              },
            })"
                        >
                            <feather-icon icon="PlusIcon" />
                            {{ $t('Add') }}
                        </b-button>
                    </b-col>
                </b-row>

                <sw-info-alert
                    v-if="false"
                    :name="$t('OrganizationAddTip')"
                />
                <!--    END::Contacts    -->
                <b-alert v-if="$route.name !== 'organizationsEdit' && createdContact && !isHydropure" show variant="primary">
                    <div class="alert-heading d-flex align-items-center">
                        <b-form-checkbox
                            v-model="createdThread"
                            variant="primary"
                            switch
                        >
                        </b-form-checkbox>
                        {{ $t('AddNewThread') }}
                    </div>
                    <div class="alert-body">{{ $t('CreatedBasicThreadInfoTooltip') }}</div>
                </b-alert>
                <!--                <h4 v-if="$route.name !== 'organizationsEdit' && createdContact && !isHydropure">-->
                <!--                    <b-form-checkbox-->
                <!--                            v-model="createdThread"-->
                <!--                            variant="primary"-->
                <!--                            switch-->
                <!--                    >-->
                <!--                        {{ $t('AddNewThread') }}-->

                <!--                    </b-form-checkbox>-->
                <!--                </h4>-->
                <transition name="fade">
                    <div v-if="createdThread && createdContact && !isHydropure">
                        <validation-provider
                            v-if="$route.name !== 'organizationsEdit'"
                            v-slot="{ errors }"
                            rules="required"
                            :name="$t('SelectFunnel')"
                        >
                            <sw-select
                                :name="$t('SelectFunnel')"
                            >
                                <v-select
                                    id="select_funnel"
                                    v-model="proces.contactThreadFunnelTypePoint"
                                    :options="funnelList"
                                    label="name"
                                    :reduce="funnel => funnel.contactThreadFunnelTypePoints[0].id"
                                    :state="errors.length > 0 ? false:null"
                                    @option:selected="selectFunnel"
                                >
                                    <template #no-options>
                                        {{ $t('NoOptions') }}
                                    </template>
                                    <template #selected-option="{ name }">
                                        {{ name }}
                                    </template>
                                    <template #option="{ name }">
                                        {{ name }}
                                    </template>
                                </v-select>
                            </sw-select>

                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <!--    START::Processes – Form    -->
                        <validation-provider
                            v-if="$route.name !== 'organizationsEdit'"
                            v-slot="{ errors }"
                            rules="required"
                            :name="$t('EnterNameOfProcess')"
                        >
                            <b-form-group :label="$t('EnterNameOfProcess')">
                                <b-form-input
                                    v-model="proces.name"
                                    :placeholder="$t('Name')"
                                    :state="errors.length > 0 ? false:null"
                                    @input="e => $emit('getTitle', e)"
                                />

                                <small class="text-danger">{{ errors[0] }}</small>
                            </b-form-group>
                        </validation-provider>

                        <!--    Processes – Form -> Checklist    -->
                        <validation-provider
                            v-if="$route.name !== 'organizationsEdit'"
                            v-slot="{ errors }"
                            rules="required"
                            :name="$t('SelectFunnel')"
                        >
                            <sw-select
                                v-if="!modalConfig.editedData"
                                :name="$t('SelectChecklist')"
                            >
                                <v-select
                                    id="select_checklist"
                                    v-model="proces.contactThreadChecklistTypes"
                                    :options="checklists"
                                    label="name"
                                    multiple
                                    :reduce="checklist => checklist.id.toString()"
                                    :state="errors.length > 0 ? false:null"
                                >
                                    <template #no-options>
                                        {{ $t('NoOptions') }}
                                    </template>
                                    <template #selected-option="{ name }">
                                        {{ name }}
                                    </template>
                                    <template #option="{ name }">
                                        {{ name }}
                                    </template>
                                </v-select>
                            </sw-select>

                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>

                        <!--    Processes – Form -> Assigned Users    -->
                        <div
                            v-if="$route.name !== 'organizationsEdit'"
                            class="mb-1"
                        >
                            <label>{{ $t('AssignedUsers') }}</label>

                            <assigned-users
                                :value="proces.assignedUsers"
                                :is-multiple="true"
                                @input="changeProcesAssignedUsers"
                            />
                        </div>

                        <!--    Processes – Form -> Value    -->
                        <b-form-group
                            v-if="$route.name !== 'organizationsEdit'"
                        >
                            <label>{{ $t('CustomerValue') }}</label>

                            <b-row style="row-gap: .5rem">
                                <b-col
                                    sm="9"
                                >
                                    <cleave
                                        v-model="proces.predictedValue"
                                        :options="cleaveOptions.number"
                                        class="form-control"
                                    />
                                </b-col>

                                <b-col
                                    sm="3"
                                >
                                    <sw-select>
                                        <v-select
                                            v-model="proces.predictedValueCurrency"
                                            :options="currencyList"
                                        >
                                            <template #no-options>
                                                {{ $t('NoOptions') }}
                                            </template>
                                        </v-select>
                                    </sw-select>
                                </b-col>
                            </b-row>
                        </b-form-group>

                        <!--    Processes – Form -> Assigned Contacts    -->
                        <div
                            v-if="$route.name !== 'organizationsEdit'"
                            class="mt-1"
                        >
                            <label>
                                {{ $t('AssignedContact') }}

                                <b-button size="sm" variant="primary" @click="assignContactsToProcess" :disabled="!organization.contacts.length">
                                    {{ $t('AddEveryContactAssignedToCompany') }}
                                </b-button>
                            </label>

                            <b-row
                                v-for="(contact, index) in proces.contactThreadAssignedContacts"
                                :key="`thread_assigned_user_${index}`"
                                class="mt-50"
                                style="row-gap: .5rem"
                            >
                                <b-col
                                    md="5"
                                    class="mb-n1"
                                >
                                    <sw-select>
                                        <v-select
                                            v-model="contact.contact"
                                            item-value="id"
                                            :options="contacts"
                                            label="firstName"
                                            class="select-size-sm"
                                            :filterable="false"
                                            @search="onSearch"
                                        >
                                            <template slot="no-options">
                                                {{ $t('typeToSearchContact') }}
                                            </template>
                                            <template #option="{ id, avatar, firstName, lastName }">
                                                <b-avatar
                                                    size="sm"
                                                    :src="avatar"
                                                    :text="firstName.slice(0,1).toUpperCase() + lastName.slice(0,1).toUpperCase()"
                                                />
                                                <span class="ml-50 pt-25"> {{ firstName }} {{ lastName }}  {{ id === '0' ? `- ${$t('Contact')}` : '' }}</span>
                                            </template>

                                            <template #selected-option="{ avatar, firstName, lastName }">
                                                <b-avatar
                                                    size="sm"
                                                    class="border border-white"
                                                    :src="avatar"
                                                    :text="firstName.slice(0,1).toUpperCase() + lastName.slice(0,1).toUpperCase()"
                                                />
                                                <span class="ml-50 pt-25"> {{ firstName }} {{ lastName }}</span>
                                            </template>
                                        </v-select>
                                    </sw-select>
                                </b-col>

                                <b-col
                                    md="5"
                                >
                                    <b-input-group
                                        class="input-group-merge"
                                        size="sm"
                                        style="min-width: 100px"
                                    >
                                        <b-form-input
                                            v-model="contact.part"
                                            type="number"
                                            :placeholder="$t('Part') + ' %'"
                                        />
                                    </b-input-group>
                                </b-col>

                                <b-col
                                    md="2"
                                    lg="2"
                                    class="d-flex justify-content-end justify-content-md-start"
                                >
                                    <b-button
                                        variant="danger"
                                        size="sm"
                                        @click="deleteContact(index)"
                                    >
                                        <feather-icon icon="TrashIcon" />

                                        {{ $t('Delete') }}
                                    </b-button>
                                </b-col>
                            </b-row>

                            <div class="mt-50">
                                <b-button
                                    size="sm"
                                    variant="flat-success"
                                    @click="addContact"
                                >
                                    {{ $t('AddContact') }}

                                    <feather-icon icon="PlusIcon" />
                                </b-button>
                            </div>
                        </div>
                    </div>
                </transition>
            </app-collapse-item>
            <app-collapse-item :title="$t('Addresses')">
                <b-tabs>
                    <b-tab :title="$t('PrimaryAddress')">
                        <!-- Main Address - Country -->
                        <b-form-group>
                            <label>{{ $t('Country') }}</label>
                            <b-form-input
                                    v-model="organization.addressCountry"
                                    :disabled="isPreview"
                            />
                        </b-form-group>

                        <b-row>
                            <b-col sm="8">
                                <!-- Main Address - City -->
                                <b-form-group>
                                    <label>{{ $t('City') }}</label>
                                    <b-form-input
                                            v-model="organization.addressCity"
                                            :disabled="isPreview"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col sm="4">
                                <!-- Main Address - Postal code -->
                                <b-form-group>
                                    <label>{{ $t('PostalCode') }}</label>
                                    <b-form-input
                                            v-model="organization.addressPostalCode"
                                            :disabled="isPreview"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col sm="7">
                                <!-- Main Address - Street -->
                                <b-form-group>
                                    <label>{{ $t('Street') }}</label>
                                    <b-form-input
                                            v-model="organization.addressStreet"
                                            :disabled="isPreview"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col sm="5">
                                <!-- Main Address - Building no -->
                                <b-form-group>
                                    <label>{{ $t('BuildingNumber') }}</label>
                                    <b-form-input
                                            v-model="organization.addressNumber"
                                            :disabled="isPreview"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-tab>

                    <b-tab :title="$t('AddressForCorrespondence')">
                        <b-button
                                size="sm"
                                block
                                variant="primary"
                                class="mb-50"
                                :disabled="isPreview"
                                @click="copyMainAddress"
                        >
                            {{ $t('Copy') }} <span class="text-lowercase">({{ $t('PrimaryAddress') }})</span>
                        </b-button>
                        <!-- Correspondence Address - Country -->
                        <b-form-group>
                            <label>{{ $t('Country') }}</label>
                            <b-form-input
                                    v-model="organization.addressCorrespondenceCountry"
                                    :disabled="isPreview"
                            />
                        </b-form-group>
                        <b-row>
                            <b-col sm="8">
                                <!-- Correspondence Address - City -->
                                <b-form-group>
                                    <label>{{ $t('City') }}</label>
                                    <b-form-input
                                            v-model="organization.addressCorrespondenceCity"
                                            :disabled="isPreview"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col sm="4">
                                <!-- Correspondence Address - Postal code -->
                                <b-form-group>
                                    <label>{{ $t('PostalCode') }}</label>
                                    <b-form-input
                                            v-model="organization.addressCorrespondencePostalCode"
                                            :disabled="isPreview"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col sm="7">
                                <!-- Correspondence Address - Street -->
                                <b-form-group>
                                    <label>{{ $t('Street') }}</label>
                                    <b-form-input
                                            v-model="organization.addressCorrespondenceStreet"
                                            :disabled="isPreview"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col sm="5">
                                <!-- Correspondence Address - Building no -->
                                <b-form-group>
                                    <label>{{ $t('BuildingNumber') }}</label>
                                    <b-form-input
                                            v-model="organization.addressCorrespondenceNumber"
                                            :disabled="isPreview"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-tab>
                </b-tabs>
            </app-collapse-item>

            <app-collapse-item show-actions
                               :show-add="true"
                               :show-delete="false"
                               :show-copy="false"
                               :show-edit="false"
                               @add="editedItem = { city: '', country: '', number: '', postalCode: '', street: '', index: -1, name: '' }; isModalOpen = true">
                <template #title>
                    {{ $t('OrganizationBranches') }}

                    <b-badge variant="light-primary" class="ml-25" v-if="(organization.organizationBranches || []).length">{{ (organization.organizationBranches || []).length }}</b-badge>
                </template>
                <b-table
                        :items="organization.organizationBranches"
                        :fields="deliveryFields"
                        striped
                        show-empty
                        responsive
                        hover
                        style="min-height: unset !important;"
                >
                    <!--    Table Head    -->
                    <template #head()="{ label }">
                        {{ $t(`${label}`) }}
                    </template>

                    <template #cell(action)="{ item, index }">
                        <b-button
                                size="sm"
                                variant="warning"
                                @click="editedItem = { ...item, index }; isModalOpen = true"
                        >
                            <feather-icon icon="EditIcon" />
                            {{ $t('Edit') }}
                        </b-button>
                        <b-button
                                size="sm"
                                variant="danger"
                                class="ml-25"
                                @click="deleteAddress(index)"
                        >
                            <feather-icon icon="TrashIcon" />
                            {{ $t('Delete') }}
                        </b-button>
                    </template>

                    <template #empty>
                        <div class="text-center py-1 text-primary font-weight-bold">
                            <feather-icon
                                    size="17"
                                    icon="XCircleIcon"
                            />
                            {{ $t('NoData') }}
                        </div>
                    </template>
                </b-table>
            </app-collapse-item>

            <app-collapse-item :title="$t('OpenHours')">
                <b-row>
                    <b-col
                            v-for="hourKey in Object.keys(organization.openHours)"
                            :key="`open_hour_${hourKey}`"
                            sm="12" md="6"
                    >
                        <div class="p-25">
                            <h5>
                                <b-form-checkbox variant="primary" switch v-model="organization.openHours[hourKey].isOpen" @change="(e) => {
                                    if (e) {
                                        organization.openHours[hourKey].start = '8:00'
                                        organization.openHours[hourKey].end = '16:00'
                                    }
                                }">
                                    {{ $t(`days.${hourKey}`) }}
                                </b-form-checkbox>
                            </h5>
                            <b-row :key="`open_hour_${hourKey}_1`" v-if="organization.openHours[hourKey].isOpen">
                                <b-col sm="12" md="6">
                                    <b-form-group>
                                        <b-input-group class="mb-1">
                                            <b-form-input
                                                    :disabled="!organization.openHours[hourKey].isOpen"
                                                    v-model="organization.openHours[hourKey].start"
                                                    type="text"
                                                    placeholder="HH:mm"
                                            ></b-form-input>
                                            <b-input-group-append>
                                                <b-form-timepicker size="sm" button-variant="outline-primary" button-only :label-close-button="$t('Close')" :label-no-time-selected="$t('NoTimeSelected')" :disabled="!organization.openHours[hourKey].isOpen"
                                                                   :value="organization.openHours[hourKey].start"
                                                                   @input="(e) => {
                                                                   const [h, m] = e.split(':')
                                                                   organization.openHours[hourKey].start = `${h}:${m}`
                                                               }"
                                                />
                                            </b-input-group-append>
                                        </b-input-group>
                                    </b-form-group>
                                </b-col>
                                <b-col sm="12" md="6">
                                    <b-form-group>
                                        <b-input-group class="mb-1">
                                            <b-form-input
                                                    :disabled="!organization.openHours[hourKey].isOpen"
                                                    v-model="organization.openHours[hourKey].end"
                                                    type="text"
                                                    placeholder="HH:mm"
                                            ></b-form-input>
                                            <b-input-group-append>
                                                <b-form-timepicker size="sm" button-variant="outline-primary" button-only :label-close-button="$t('Close')" :label-no-time-selected="$t('NoTimeSelected')" :disabled="!organization.openHours[hourKey].isOpen"
                                                                   :value="organization.openHours[hourKey].end"
                                                                   @input="(e) => {
                                                                   const [h, m] = e.split(':')
                                                                   organization.openHours[hourKey].end = `${h}:${m}`
                                                               }"
                                                />
                                            </b-input-group-append>
                                        </b-input-group>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <div class="bg-light-primary rounded p-50 text-center" :key="`open_hour_${hourKey}_2`" v-if="!organization.openHours[hourKey].isOpen" variant="primary">
                                <div class="alert-body">
                                    <strong>{{ $t(`days.${hourKey}`) }}</strong> - {{ $t('Closed') }}
                                </div>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </app-collapse-item>
        </app-collapse>

      <!--    END::Processes – Form    -->
      <!--  Footer  -->
      <div class="d-flex pt-1 mt-1 border-top">
        <b-button
          variant="primary"
          class="mr-50"
          :disabled="loading || !organization.name || (!$route.name.toLowerCase().includes('edit') && (createdContact && createdThread && !proces.name)) || nipExists"
          @click="createOrganization"
        >
          {{ $t('Save') }}
        </b-button>
        <b-button
          v-if="!isNotCancel"
          variant="secondary"
          @click="onModalClose"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>
    </div>

    <!-- Copntact organization modal -->
    <b-modal
      v-model="showContactOrganizationModal"
      :title="$tc('dashboard.tiles.YourAssignedContacts', 2)"
      hide-footer
      size="lg"
      no-close-on-backdrop
    >
      <b-tabs>
        <b-tab :title="$t('SelectContacts')">
          <sw-select :name="$t('AddContactsToOrganization')">
            <v-select
              id="offer-thread-1"
              v-model="selectedContacts"
              :options="contactList"
              label="name"
              :filterable="false"
              multiple
              :selectable="c => !c.mainOrganizationContact"
              @search="loadContacts"
            >
              <template #no-options="{ search }">
                <span v-if="search.length">
                  {{ $t('NoData') }}
                </span>
                <span v-else>
                  {{ $t('TypeToSearch') }}
                </span>
              </template>

              <template #option="contact">
                <div class="d-flex align-items-center">
                  <feather-icon
                    v-if="contact.mainOrganizationContact || contact.organization"
                    icon="HomeIcon"
                  />
                  <avatar
                    :user="contact"
                    class="mr-25"
                    show-name
                  />
                </div>
              </template>

              <template #selected-option="contact">
                <div class="d-flex align-items-center">
                  <feather-icon
                    v-if="contact.mainOrganizationContact || contact.organization"
                    icon="HomeIcon"
                  />
                  <avatar
                    :user="contact"
                    class="mr-25"
                    text-variant="text-white"
                    show-name
                  />
                </div>
              </template>
            </v-select>
          </sw-select>

          <b-button
            :disabled="!selectedContacts.length"
            size="sm"
            variant="primary"
            @click="() => {
              organization.contacts.push(...selectedContacts.map(c => ({ ...c, isNew: true })));
              selectedContacts = [];
              showContactOrganizationModal = false;
            }"
          >
            {{ $t('Add') }}
          </b-button>
        </b-tab>

        <b-tab :title="$t('CreateContact')">
          <create-contact
            hide-organization
            is-modal-view
            @close="(e) => {
              showContactOrganizationModal = false;
              selectedContacts = []
              if (e && e.id) organization.contacts.push({ ...e, isNew: true });
            }"
          />
        </b-tab>
      </b-tabs>
    </b-modal>

      <!-- Branches -->
      <b-modal
              :visible="isModalOpen"
              hide-footer
              :title="$t('OrganizationBranches')"
              @hidden="isModalOpen = false; editedItem = null"
      >
          <validation-observer
                  v-if="editedItem && isModalOpen"
                  ref="edit-address"
          >
              <validation-provider
                      v-slot="{ errors }"
                      :name="$t('Name')"
                      :rules="'required'"
              >
                  <b-form-group :label="$t('Name')">
                      <b-form-input
                              id="basicInput"
                              v-model="editedItem.name"
                              :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
              </validation-provider>
              <validation-provider
                      v-slot="{ errors }"
                      :name="$t('Country')"
                      :rules="'required'"
              >
                  <b-form-group :label="$t('Country')">
                      <b-form-input
                              id="basicInput"
                              v-model="editedItem.country"
                              :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
              </validation-provider>
              <validation-provider
                      v-slot="{ errors }"
                      :name="$t('City')"
                      :rules="'required'"
              >
                  <b-form-group :label="$t('City')">
                      <b-form-input
                              id="basicInput"
                              v-model="editedItem.city"
                              :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
              </validation-provider>
              <validation-provider
                      v-slot="{ errors }"
                      :name="$t('contact.postalCode')"
                      :rules="'required'"
              >
                  <b-form-group
                          :label="$t('contact.postalCode')"
                          :state="errors.length > 0 ? false:null"
                  >
                      <b-form-input
                              id="basicInput"
                              v-model="editedItem.postalCode"
                              class="form-control"
                              :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
              </validation-provider>

              <validation-provider
                      v-slot="{ errors }"
                      :name="$t('Street')"
                      :rules="'required'"
              >
                  <b-form-group :label="$t('Street')">
                      <b-form-input
                              id="basicInput"
                              v-model="editedItem.street"
                              :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
              </validation-provider>
              <validation-provider
                      v-slot="{ errors }"
                      :name="$t('BuildingNumber')"
                      :rules="'required'"
              >
                  <b-form-group :label="$t('BuildingNumber')">
                      <b-form-input
                              id="basicInput"
                              v-model="editedItem.number"
                              :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
              </validation-provider>
          </validation-observer>

          <b-button
                  size="sm"
                  variant="primary"
                  @click="editAddress"
          >
              {{ $t('Confirm') }}
          </b-button>
      </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  CREATE_ORGANIZATION,
  GET_CONTACT_STATUSES,
  GET_FUNNELS,
  GET_GROUPS,
  GET_ORGANIZATION,
  GET_THREAD_TAGS,
  UPDATE_ORGANIZATION,
} from '@/@constants/mutations'
import {
  BFormTextarea,
  BInputGroupAppend, BInputGroupPrepend, BLink, BPopover, BTab, BTable, BTabs, VBTooltip, BFormTimepicker,
} from 'bootstrap-vue'
import axiosIns from '@/libs/axios'
import vSelect from 'vue-select'
import vatIdTypeList from '@/helpers/vatIdTypeList'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { contactPositionTypeByValue, contactPositionTypes } from '@/@types/contactPositionTypes'
import { countryCodes } from '@/@data/countryCodes'
import Cleave from 'vue-cleave-component'
import { CONTACTS_SEARCH } from '@/@constants/fields'
import currency from '@/helpers/currency'
import AssignedUsers from '@/views/components/AssignedUsers.vue'
import useVerticalLayout from '@core/layouts/layout-vertical/useVerticalLayout'
import { onUnmounted } from '@vue/composition-api'
import CreateContact from '@/pages/contact.old/Create.vue'
import { pkdCodes } from '@/@data/pkdCodes'

const defaultOpenHours = {
  MONDAY: {
    isOpen: false,
    start: '8:00',
    end: '16:00',
  },
  TUESDAY: {
    isOpen: false,
    start: '8:00',
    end: '16:00',
  },
  WEDNESDAY: {
    isOpen: false,
    start: '8:00',
    end: '16:00',
  },
  THURSDAY: {
    isOpen: false,
    start: '8:00',
    end: '16:00',
  },
  FRIDAY: {
    isOpen: false,
    start: '8:00',
    end: '16:00',
  },
  SATURDAY: {
    isOpen: false,
    start: '8:00',
    end: '14:00',
  },
  SUNDAY: {
    isOpen: false,
    start: '8:00',
    end: '16:00',
  },
}

export default {
  name: 'OrganizationModal',
  components: {
    BFormTimepicker,
    BFormTextarea,
    BLink,
    CreateContact,
    AssignedUsers,
    BTable,
    BPopover,
    BTab,
    BTabs,
    BInputGroupAppend,
    BInputGroupPrepend,
    vSelect,
    Cleave,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    okBtn: String,
    isNotCancel: Boolean,
    backTo: { type: Object, required: false },
    isPreview: Boolean,
    isModalView: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    pkdCodes,
    editedItem: null,
    isModalOpen: false,
    deliveryFields: [
      { key: 'name', label: 'Name' },
      { key: 'city', label: 'OrganizationDetails.addressCity' },
      { key: 'country', label: 'OrganizationDetails.addressCountry' },
      { key: 'number', label: 'OrganizationDetails.addressNumber' },
      { key: 'postalCode', label: 'OrganizationDetails.addressPostalCode' },
      { key: 'street', label: 'OrganizationDetails.addressStreet' },
      { key: 'action', label: 'Action' },
    ],

    showContactOrganizationModal: false,
    createdThread: false,
    createdContact: false,
    vats: ['0', '8', '23'],
    isOrganizationExists: false,
    organization: {
      contacts: [],
      note: '',
      organizationClassificationCodes: [],
      parentOrganization: '',
      addressCity: '',
      addressCountry: '',
      addressNumber: '',
      addressPostalCode: '',
      addressStreet: '',
      addressCorrespondenceCountry: '',
      addressCorrespondenceCity: '',
      addressCorrespondencePostalCode: '',
      addressCorrespondenceStreet: '',
      addressCorrespondenceNumber: '',
      emails: [''],
      organizationBranches: [],
      openHours: JSON.parse(JSON.stringify(defaultOpenHours)),
      phones: [
        {
          value: '',
          countryCode: {
            code: 'PL',
            dial_code: '+48 ',
            name: 'Poland',
          },
        },
      ],
      name: '',
      vatIdType: null,
      vatId: '',
      vatPayer: false,
      vat: '0',
    },
    proces: {
      name: '',
      predictedValue: '',
      predictedValueCurrency: '',
      contactThreadFunnelTypePoint: '',
      assignedUsers: [],
      contactThreadAssignedContacts: [],
      contactThreadChecklistTypes: '',
    },

    filters: {
      assignedUsers: [],
      status: '',
      structureContacts: false,
      offers: false,
      action: false,
      timeRange: '',
      tags: [],
      organizations: [],
    },
    loading: false,
    reload: false,
    edited: false,
    blockContacts: true,
    vatIdTypeList,

    cleaveOptions: {
      number: {
        delimiter: ' ',
        numeral: true,
        blocks: [3, 3, 3],
      },
      zip: {
        delimiter: '-',
        // numeral: true,
        blocks: [2, 3],
      },
    },
    contactPositionTypes,
    countryCodes,
    tags: [],
    groups: [],
    assignedUsers: [],
    existedEmails: [],
    existedPhones: [],
    contactList: [],
    selectedContacts: [],

    procesList: [
      {
        id: 1,
        name: 'Sprzedaż',
        tip: 'Sale',
        isActive: false,
      },
      {
        id: 2,
        name: 'Obsługa',
        isActive: false,
      },
      {
        id: 3,
        name: 'Reklamacje',
        isActive: false,
      },
      {
        id: 1,
        name: 'Sprzedaż',
        tip: 'Sale',
        isActive: false,
      },
      {
        id: 2,
        name: 'Obsługa',
        isActive: false,
      },
      {
        id: 3,
        name: 'Reklamacje',
        isActive: false,
      },
      {
        id: 1,
        name: 'Sprzedaż',
        tip: 'Sale',
        isActive: false,
      },
      {
        id: 2,
        name: 'Obsługa',
        isActive: false,
      },
      {
        id: 3,
        name: 'Reklamacje',
        isActive: false,
      },
    ],

    checklists: [],
    funnelList: [],
    contacts: [],
    organizations: [],
    nipExists: false,
    currencyList: currency(),
    newContact: {
      contactType: '',
      contact: '',
      part: '',
    },
    gusLoading: false,
    isProcesses: true,
    contactListFields: [
      {
        key: 'isNew',
        label: 'task.Status',
      },
      {
        key: 'contact',
        label: 'Contact',
      },
      {
        key: 'email',
        label: 'Email',
      },
      {
        key: 'phone',
        label: 'Phone',
      },
      {
        key: 'position',
        label: 'ContactPosition',
      },
      {
        key: 'action',
        label: 'Action',
      },
    ],
  }),
  computed: {
    ...mapGetters({
      system: 'system/getSettings',
      modalConfig: 'modal/getModalState',
      statuses: 'typeSettings/geContactStatuses',
      currentUser: 'auth/getCurrentUser',
    }),
    isHydropure() {
      return window.location.origin.includes('hydro')
    },
  },
  mounted() {
    if (this.isHydropure) {
      this.createdContact = false
      this.createdThread = false
    }
    // eslint-disable-next-line prefer-destructuring
    this.currencySelected = this.currencyList[0]
    this.loadChecklists()
    this.loadFunnels()

    this.proces.assignedUsers = [this.currentUser]

    if (this.modalConfig.data) {
      this.loading = true
      this.organization = this.modalConfig.data

      if (!this.organization.vatIdType) this.organization.vatIdType = ''
      if (this.organization.vatIdType) this.organization.vatIdType = this.vatIdTypeList.find(t => t.value === this.organization.vatIdType) || ''

      this.onGetContactTags()
      this.loadGroups()

      this.edited = true
      this.loading = false
    } else if (this.$route.params.id) {
      this.blockContacts = false

      this.loading = true

      this.getOrganization(this.$route.params.id)
    }
  },
  methods: {
    // eslint-disable-next-line consistent-return
    async editAddress() {
      if (this.$refs['edit-address']) {
        const isValid = await this.$refs['edit-address'].validate()
        if (!isValid) return false
      }

      if (this.editedItem?.index > -1) {
        // Edit action
        this.$set(this.organization.organizationBranches, this.editedItem.index, this.editedItem)
      } else {
        // Create action
        // eslint-disable-next-line
        delete this.editedItem.index
        this.organization.organizationBranches.push(this.editedItem)
      }

      this.isModalOpen = false
      this.editedItem = false
    },
    deleteAddress(index) {
      this.$delete(this.organization.organizationBranches, index)
    },
    contactPositionTypeByValue,
    onModalClose() {
      this.$emit('close')
    },
    async checkExistedNip() {
      const nip = this.organization.vatId?.trim()
      if (!nip) return

      try {
        const resp = await axiosIns.get('1/organizations', { params: { fields_load: this.$fields.BASE_ID, eq_vatId: nip } })

        if (resp?.data?.data?.items?.length) {
          if (resp?.data?.data?.items[0].id === this.organization.id || resp?.data?.data?.items[0]?.id === this.$route.params?.id) {
            this.nipExists = false
          } else {
            this.nipExists = true
          }
        } else {
          this.nipExists = false
        }
      } catch (err) {
        this.showToast('warning', this.$i18n.t('OrganizationNotFound'))
      }
    },
    async downloadFromGus() {
      this.gusLoading = true
      const nip = this.organization.vatId.replaceAll('PL', '')
      if (!nip) return

      try {
        const resp = await axiosIns.get(`1/integration/gus/byNip/${String(nip).replace(/[^a-zA-Z0-9 ]/g, '').replaceAll(' ', '')}`)

        if (resp?.data?.data.length) {
          const company = resp.data.data[0]
          if (company?.reports?.BIR11OsPrawnaPkd) {
            this.organization.organizationClassificationCodes = company?.reports?.BIR11OsPrawnaPkd.map(({ praw_pkdKod }) => {
              // eslint-disable-next-line camelcase
              if (!praw_pkdKod) return null
              const code = [praw_pkdKod.slice(0, 2), praw_pkdKod.slice(2, 4), praw_pkdKod.slice(4)].join('.')

              return this.pkdCodes.find(a => a.code === code)
            }).filter(Boolean)
          }

          this.$set(this.organization, 'name', company.name)
          this.$set(this.organization, 'addressCity', company.city)
          this.$set(this.organization, 'addressCountry', 'Polska')
          this.$set(this.organization, 'addressNumber', company.propertyNumber)
          this.$set(this.organization, 'addressPostalCode', company.zipCode)
          this.$set(this.organization, 'addressStreet', company.street)
          this.copyMainAddress()
        } else {
          this.showToast('danger', this.$i18n.t('OrganizationNotFound'))
        }
      } catch (err) {
        this.showToast('warning', this.$i18n.t('OrganizationNotFound'))
      } finally {
        this.gusLoading = false
      }
    },
    selectFunnel(e) {
      const name = e?.name || this.proces.name
      this.proces.name = name
    },
    getOrganization(id) {
      this.$store.dispatch(`organizations/${GET_ORGANIZATION}`, id)
        .then(res => res.data.item ?? undefined)
        .then(organization => {
          this.organization = {
            ...organization,
            emails: [''],
            phones: [
              {
                value: '',
                countryCode: {
                  code: 'PL',
                  dial_code: '+48 ',
                  name: 'Poland',
                },
              },
            ],
          }

          if (!organization.openHours) {
            this.organization.openHours = JSON.parse(JSON.stringify(defaultOpenHours))
          } else {
            this.organization.openHours = JSON.parse(JSON.stringify(defaultOpenHours))
            Object.keys(organization.openHours).forEach(dayKey => {
              if (organization.openHours[dayKey] === 'CLOSED') {
                this.organization.openHours[dayKey].isOpen = false
              } else {
                const [start = '8:00', end = '16:00'] = organization.openHours[dayKey].split(' - ')
                this.organization.openHours[dayKey].start = start
                this.organization.openHours[dayKey].end = end
              }
            })
          }

          const contactIndex = this.organization.contacts.findIndex(c => c.mainOrganizationContact)
          const mainContact = this.organization.contacts.splice(contactIndex, 1)

          if (mainContact && contactIndex > -1) {
            this.createdContact = true
            this.setMainContactData(mainContact[0])
          } else {
            this.createdContact = false
          }

          if (this.organization.organizationClassificationCodes?.length) this.organization.organizationClassificationCodes = this.organization.organizationClassificationCodes.map(({ code, id: orgId }) => ({ id: orgId, ...(this.pkdCodes.find(a => a.code === code) || {}) }))
          if (!this.organization.vatIdType) this.organization.vatIdType = ''
          if (this.organization.vatIdType) this.organization.vatIdType = this.vatIdTypeList.find(t => t.value === this.organization.vatIdType) || ''

          this.filters.organizations.push(organization.id)

          this.reload = !this.reload

          this.edited = true
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
        .finally(() => {
          this.loading = false
        })
    },
    filter(options, search) {
      return pkdCodes.filter(e => e.description.toLowerCase().includes(search.toLowerCase()) || e.code.toLowerCase().includes(search.toLowerCase()))
    },
    async loadOrganizations(search, loading) {
      this.organizations = await this.selectSearch(search, loading, this.$fields.BASE_NAME, 'name,vatId', '1/organizations', 20)
    },
    setMainContactData(contact) {
      if (contact?.id) this.organization.contactId = contact.id
      if (contact.phoneNumbers?.length) {
        this.organization.phones = contact.phoneNumbers.map(phoneObject => {
          const { phone } = phoneObject
          const phoneItem = {
            value: phone || '',
            countryCode: {
              code: 'PL',
              dial_code: '+48 ',
              name: 'Poland',
            },
          }
          const code = countryCodes.find(({ dial_code }) => phone.includes(dial_code.trim()))
          if (code) {
            phoneItem.value = phone.replace(code.dial_code.trim(), '')
            phoneItem.countryCode = code
          }
          phoneItem.isPrimary = contact.phone === phoneObject.phone

          return phoneItem
        })
        this.organization.phones.sort((a, b) => Number(b?.isPrimary) - Number(a?.isPrimary))
      } else {
        this.organization.phones = [
          {
            value: this.contact?.phone ? this.contact.phone : '',
            countryCode: {
              code: 'PL',
              dial_code: '+48 ',
              name: 'Poland',
            },
          },
        ]
      }
      if (contact.mailAddresses?.length) {
        this.organization.emails = contact.mailAddresses.filter(item => item.mail !== contact.email).map(email => email.mail)
        if (contact.email) this.organization.emails.unshift(contact.email)
      } else if (contact.email) this.organization.emails.unshift(contact.email)
      else this.organization.emails.push('')
    },
    copyMainAddress() {
      this.$set(this.organization, 'addressCorrespondenceCountry', this.organization.addressCountry)
      this.$set(this.organization, 'addressCorrespondenceCity', this.organization.addressCity)
      this.$set(this.organization, 'addressCorrespondencePostalCode', this.organization.addressPostalCode)
      this.$set(this.organization, 'addressCorrespondenceStreet', this.organization.addressStreet)
      this.$set(this.organization, 'addressCorrespondenceNumber', this.organization.addressNumber)
    },
    /* eslint-disable */
    createOrganization() {
      this.loading = true
      const action = this.edited ? UPDATE_ORGANIZATION : CREATE_ORGANIZATION
      const organizationItem = { ...this.organization, vat: parseFloat(this.organization.vat) }

      const { emails, phones, contactId = null, ...organization } = organizationItem
      const contactsToAssign = this.organization.contacts || []

      const openHours = {
          MONDAY: '',
          TUESDAY: '',
          WEDNESDAY: '',
          THURSDAY: '',
          FRIDAY: '',
          SATURDAY: '',
          SUNDAY: '',
      }
      Object.keys(organizationItem.openHours).forEach(dayKey => {
          if (!organizationItem.openHours[dayKey].isOpen) {
              openHours[dayKey] = 'CLOSED'
          } else {
              openHours[dayKey] = `${organizationItem.openHours[dayKey].start} - ${organizationItem.openHours[dayKey].end}`
          }
      })
      organization.openHours = (openHours)
      organization.organizationClassificationCodes = organization.organizationClassificationCodes.map(e => {
          if (e.id) return { code: e.code, id: e.id }
          return { code: e.code }
      })
      organization.parentOrganization = this.getObjectId(organization.parentOrganization) || null

      delete organization.createdAt
      delete organization.deletedAt
      delete organization.updatedAt
      delete organization.createdBy
      delete organization.updatedBy
      delete organization.deletedBy
      delete organization.contacts
      delete organization.users
      delete organization.assignedContactsUsers

      organization.vatIdType = organization.vatIdType?.value || null

      this.$store.dispatch(`modal/${action}`, organization)
        .then(async ({ data }) => {
          this.showToast('success', this.edited ? this.$i18n.t('OrganizationUpdated') : this.$i18n.t('OrganizationAdded'))

          const organizationId = data.items[0]?.id
          if (this.createdContact) {
              const id = await this.processContact({ emails, phones, contactId }, organizationId)

              if (id) {
                  if (this.$route.name !== 'organizationsEdit' && this.createdThread) await this.processThread(id)

                  await this.saveContact(contactsToAssign.map(contact => ({ id: contact.id, organization: organizationId, isOrganization: true })))
              }
          } else {
              await this.saveContact(contactsToAssign.map(contact => ({ id: contact.id, organization: organizationId, isOrganization: true })))
          }

          if (this.backTo) this.$router.push(this.backTo)
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
        .finally(() => {
          this.loading = false
        })
    },
      async processContact(contact, organizationId) {
        const contactPayload = {
            mainOrganizationContact: true,
            isOrganization: true,
            organization: organizationId,
            mailAddresses: contact.emails,
            mailAddress: contact.emails[0] || null,
            phoneNumbers: contact.phones
                .filter(p => p.value)
                .map(phone => (phone.countryCode?.dial_code || '') + phone.value),
        }
        contactPayload.phoneNumber = contactPayload.phoneNumbers?.length ? contactPayload.phoneNumbers[0] : null

          const resp = await this.$store.dispatch(`typeSettings/${GET_CONTACT_STATUSES}`)

          contactPayload.status = resp.data.items[0]?.id

        if (contact.contactId) contactPayload.id = contact.contactId
        else {
            contactPayload.firstName = this.$i18n.t('Organization')
            contactPayload.lastName = this.organization.name
        }

        return await this.saveContact(contactPayload)
      },
      async processThread(contactId) {
        const threadPayload = {
            name: this.proces.name,
            predictedValue: Number(this.proces.predictedValue || 0),
            predictedValueCurrency: this.proces.predictedValueCurrency || 'PLN',
            contactThreadFunnelTypePoint: this.getObjectId(this.proces.contactThreadFunnelTypePoint) || null,
            assignedUsers: (this.proces.assignedUsers || []).mapKeys('id'),
            contactThreadAssignedContacts: (this.proces.contactThreadAssignedContacts || []).map(e => {
              if (e.id) return id
              else return {
                contact: this.getObjectId(e.contact), part: Number(e.part) || 0,
              }
            }),
            contactThreadChecklistTypes: (this.proces.contactThreadChecklistTypes || []).mapKeys('id'),
        }

        if (this.proces.id) threadPayload.id = this.proces.id

        return await this.saveThread(threadPayload, contactId)
      },
    validNIP() {
      this.$set(this.organization, 'vatId', this.organization.vatId.replace(/[^A-Za-z0-9]/g, ''))
    },
    filterByKey(options, search, key = 'name', lang = '') {
      const items = options.filter(option => {
        const value = this.$i18n.te(`${lang}${option[key]}`)
          ? this.$i18n.t(`${lang}${option[key]}`).toLowerCase().includes(search.toLowerCase())
          : option[key].toLowerCase().includes(search.toLowerCase())
        return value
      })

      return items || []
    },
    onGetContactTags() {
      this.$store.dispatch(`typeSettings/${GET_THREAD_TAGS}`)
        .then(res => {
          this.tags = res.data.items
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
    loadGroups() {
      this.$store.dispatch(`groups/${GET_GROUPS}`)
        .then(res => {
          this.groups = res.data.items
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
    checkExistedEmails() {
      this.existedEmails = []
      const { emails } = this.organization
      let preparedMail = emails.filter(mail => !!mail)
      preparedMail = preparedMail?.filter(Boolean)
      axiosIns.post('1/contacts/getExistsByEmails', preparedMail)
        .then(res => {
          this.existedEmails = res.data.data?.exists || []
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
    checkExistedPhones() {
      this.existedPhones = []
      const { phones } = this.organization
      const preparedPhones = phones.filter(phone => phone.value).map(phone => (phone.countryCode?.dial_code || '') + phone.value)
      axiosIns.post('1/contacts/getExistsByPhones', preparedPhones)
        .then(res => {
          this.existedPhones = res.data.data?.exists || []
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
    changeAssignedUsers(payload) {
      this.assignedUsers = [...payload]
    },
    changeProcesAssignedUsers(payload) {
      this.proces.assignedUsers = payload
    },
    existedMail(email) {
      return this.existedEmails.filter(ob => ob.contacts.find(obContact => obContact.id !== this.contact?.id)).find(contact => contact.mail === email)
    },
    existedPhone(phone) {
      return this.existedPhones.filter(ob => ob.contacts.find(obContact => obContact.id !== this.contact?.id)).find(contact => contact.phone === ((phone.countryCode?.dial_code || '') + phone.value))
    },
    async loadContacts(search, isLoading) {
      this.contactList = await this.selectSearch(search, isLoading, CONTACTS_SEARCH, 'firstName,lastName', '1/contacts')
    },

    addContact() {
      this.proces.contactThreadAssignedContacts.push({ ...this.newContact })
    },
    deleteContact(index) {
      this.proces.contactThreadAssignedContacts.splice(index, 1)
    },
    onSearch(search, loading) {
      if (search.length) {
        loading(true)
        const payload = {
          search,
        }

        this.$store.dispatch('contacts/GET_SELECT_CONTACTS', payload)
          .then(res => {
            this.contacts = res
            loading(false)
          })
      }
    },
    loadChecklists() {
      axiosIns.get('1/settings/contactThreadChecklistTypes', { params: { fields_load: this.$fields.SETTINGS_FUNNEL_TYPE, orderBy: 'position.asc' } })
        .then(res => {
          this.checklists = res.data.data.items
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
    loadFunnels() {
      this.$store.dispatch(`funnelMain/${GET_FUNNELS}`)
        .then(res => {
          this.funnelList = res
        })
        .catch(() => {
          this.showToast('danger', this.$i18n.t('ProblemOccured'))
        })
    },
    deleteContactFromOrganization(index) {
      const contact = this.organization.contacts[index]
      if (!contact?.isNew) {
        this.showAlert('warning', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('alerts.WontbeAble'))
          .then(async () => {
            try {
              await this.saveContact({ id: contact.id, organization: null })

              this.$delete(this.organization.contacts, index)
            } catch (err) {
              this.clog(err)
            }
          })
      } else this.$delete(this.organization.contacts, index)
    },
      async saveThread(payload, contactId) {
          try {
              const resp = await axiosIns({
                  url: `1/contacts/${contactId}/threads`,
                  method: payload?.id ? 'PATCH' : 'POST',
                  data: payload,
              })

              if (resp?.data?.data?.items[0]?.id) {
                  await axiosIns.post(`1/contacts/threads/${resp?.data?.data?.items[0]?.id}/funnelProcesses`, {
                      contactThreadFunnelTypePoint: String(payload.contactThreadFunnelTypePoint),
                      status: 'OPEN',
                  })
                  return resp?.data?.data?.items[0]?.id
              }

              return null
          } catch (err) {
              this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
              return null
          }
      },
    async saveContact(payload) {
        if (Array.isArray(payload) && !payload.length) return true
        try {
        const resp = await axiosIns({
          url: '1/contacts',
          method: payload?.id || Array.isArray(payload) ? 'PATCH' : 'POST',
          data: payload,
        })

          if (resp?.data?.data?.items[0]?.id) return resp?.data?.data?.items[0]?.id

          return null
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          return null
      }
    },
    async assignContactsToProcess() {
      this.organization.contacts.forEach(organizationContact => {
        if (!this.proces.contactThreadAssignedContacts.find(e => e.id === organizationContact.id)) {
          this.proces.contactThreadAssignedContacts.push({ contact: organizationContact, part: '', contactType: '' })
        }
      })

    }
  },
  directives: {
    'b-tooltip': VBTooltip,
  },

  setup() {
    const { isMobile, resizeHandler } = useVerticalLayout()

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    return {
      perfectScrollbarSettings,
      isMobile,
    }
  },
}
</script>

<style lang="scss">
.new-organization {
.table-responsive {
  min-height: unset !important;
}
}
</style>
