<template>
  <div>
    <b-row class="mb-0">
      <b-col sm="6" v-if="checkRequiredPermissions([$roles.USER_RANKING_LOYALTY_VIEW_ALL])">
        <b-card :class="{ 'border-primary': selectedType === 'loyalty' }"
                class="cursor-pointer border"
                @click="selectedType = 'loyalty'; init()"
        >
          <div class="d-flex justify-content-between">
            <div>
              <feather-icon size="20"
                            class="text-primary mr-50"
                            icon="HexagonIcon"
              />
              {{ $t('PointsRanking') }}
            </div>
            <span v-if="selectedType === 'loyalty'"
                  class="text-primary"
            >{{ $t('Selected') }}</span>
          </div>
        </b-card>
      </b-col>
      <b-col sm="6" v-if="checkRequiredPermissions([$roles.USER_RANKING_MARKETING_VIEW_ALL])">
        <b-card :class="{ 'border-primary': selectedType === 'marketing' }"
                class="cursor-pointer border"
                @click="selectedType = 'marketing'; init()"
        >
          <div class="d-flex justify-content-between">
            <div>
              <feather-icon size="20"
                            class="text-primary mr-50"
                            icon="ShoppingCartIcon"
              />
              {{ $t('SellRanking') }}
            </div>
            <span v-if="selectedType === 'marketing'"
                  class="text-primary"
            >{{ $t('Selected') }}</span>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <!--    START::Seller -> List    -->
      <b-col md="12">
        <b-card>
          <div
            v-if="selectedType === 'marketing' && checkRequiredModule('marketingModule') && checkRequiredPermissions([$roles.MARKETING_PLAN_VIEW_ALL])"
          >
            <sw-select :name="$t('SelectMarketingPlan')">
              <v-select
                v-model="marketingPlan"
                :options="marketingPlans"
                label="name"
                :reduce="plan => plan.id"
                :clearable="false"
                @input="init"
              >
                <template #no-options>
                  {{ $t('NoOptions') }}
                </template>
              </v-select>
            </sw-select>
          </div>
          <!--          <h3>-->
          <!--            {{ $t('RankOfMonth') }}-->
          <!--          </h3>-->

          <navigation
            :text="`${moment(currentYear, 'YYYY').format('YYYY')} - ${moment(currentMonth + 1, 'M').format('MM')}`"
            :disable-next="moment(currentYear, 'YYYY').year() === moment().year() && moment(currentMonth + 1, 'MM').month() === moment().month()"
            @next="nextMonth"
            @previous="previousMonth"
          />

          <template v-if="list.length">
            <seller-top :top-users="list.slice(0, 3)"
                        class="d-flex justify-content-center mt-1"
            />

            <seller-list
              :list="list"
              class="mt-2"
              :type="selectedType"
            />

            <b-col v-if="productsLegend.length && list.length"
                   sm="12"
            >
              <!-- Legend -->
              <div class="d-flex align-items-center flex-wrap mt-2">
                <div v-for="(product, productIndex) in productsLegend"
                     :key="`product_legend_${productIndex}`"
                     class="mr-75 flex-grow-1"
                >
                  <div class="color-dot"
                       :style="{ 'background-color': product.color || '#7267f0' }"
                  />
                  {{ getTranslationsField(product, 'name') }}
                </div>
              </div>
            </b-col>
          </template>

          <div
            v-else
            class="text-center py-1 text-primary font-weight-bold"
          >
            <feather-icon
              size="17"
              icon="XCircleIcon"
            />
            {{ $t('ThereAreNoUsersToDisplay') }}
          </div>

          <b-overlay
            :show="isLoading"
            no-wrap
            spinner-variant="primary"
          />
        </b-card>
      </b-col>
      <!--    END::Seller -> List    -->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable */
import Navigation from '@/pages/ranking/components/top/Navigation.vue'
import Filters from './components/Filters.vue'
import Item from './components/Item.vue'
import SellerTop from './components/top/TopSeller.vue'
import SellerList from './components/top/TopList.vue'
import axiosIns from "@/libs/axios";
import moment from "moment";
import vSelect from "vue-select";

export default {
  components: {
      vSelect,
    Navigation,
    Filters,
    Item,
    SellerTop,
    SellerList,
  },
  data: () => ({
    isLoading: false,
    currentMonth: moment().month(),
    currentYear: moment().year(),
    list: [],
    productsLegend: [],
    marketingPlans: [],
    marketingPlan: null,
    selectedType: 'loyalty',
  }),
    async mounted() {
      if (!this.checkRequiredPermissions([this.$roles.USER_RANKING_LOYALTY_VIEW_ALL])) {
          this.selectedType = 'marketing'
      }
      await this.loadMarketingPlans()
      await this.init()
    },
    methods: {
      moment,
      previousMonth() {
          this.currentMonth = (this.currentMonth === 0) ? 11 : this.currentMonth - 1;
          this.currentYear = (this.currentMonth === 11) ? this.currentYear - 1 : this.currentYear;

          this.init()
      },
      nextMonth() {
          this.currentMonth = (this.currentMonth === 11) ? 0 : this.currentMonth + 1;
          this.currentYear = (this.currentMonth === 0) ? this.currentYear + 1 : this.currentYear;

          this.init()
      },
      async loadMarketingPlans() {
          if (this.checkRequiredModule('marketingModule') && this.checkRequiredPermissions([this.$roles.MARKETING_PLAN_VIEW_ALL])) {
              try {
                  const resp = await axiosIns.get('1/marketingPlans', { params: { fields_load: this.$fields.MARKETING_PLANS } })

                  const plans = (resp?.data?.data?.items || [])
                  this.marketingPlans = plans
                  if (plans?.length) {
                      this.marketingPlan = this.getObjectId(plans[0])
                  }
                  return plans
              } catch (err) {
                  this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
                  return err
              }
          }
      },
      async init() {
        const productsRecords = {}
        this.isLoading = true
        try {
          const startDate = moment().set({ 'year': this.currentYear, 'month': this.currentMonth, 'date': 1, hour: 0, minute: 0, second: 0 });
          const endDate = moment(startDate).endOf('month');

          const params = {}

          params.gte_createdAt = startDate.format('YYYY-MM-DD HH:mm:ss')
          params.lte_createdAt = endDate.format('YYYY-MM-DD HH:mm:ss')

          if (this.marketingPlan && this.selectedType === 'marketing') {
              params.eq_marketingPlan = this.marketingPlan
          }

          const response = await axiosIns.get(`1/users/ranking/${this.selectedType}/byOrderedProducts`, { params })

          this.list = (response?.data?.data || []).map(item => ({ ...item, ...item.user })).map(item => {
              const _item = { ...item, totalSystemAmount: this.selectedType === 'marketing' ? item.totalAmount : item.totalValue }
              _item.totalAmount = item.products.reduce((a, b) => +a + (this.selectedType === 'marketing' ? +b.totalAmount : +b.totalValue), 0)

              item.products.forEach(product => {
                  if (!productsRecords[product.id]) productsRecords[product.id] = 0

                  productsRecords[product.id] += Number(this.selectedType === 'marketing' ? product.totalAmount : product.totalValue || 0)
              })

              return _item
          }).sort(((a, b) => b.totalAmount - a.totalAmount))

          const productIds = Object.keys(productsRecords)
          const productParams = {
            eqArr_id: JSON.stringify(productIds),
            fields_load: this.$fields.BASE_PRODUCT_ID,
          }
          const productsResponse = await axiosIns.get('1/shops/products', { params: productParams })
          const loadedProducts = productsResponse.data?.data?.items || []
          this.list.forEach((user, index) => {
              user.products.forEach((product, productIndex) => {
                  const productExist = loadedProducts.find(e => Number(e.id) === product.id) || {}

                  this.$set(this.list[index].products, productIndex, { ...product, details: productExist })
              })
          })

          this.productsLegend = Object.keys(productsRecords).map(productId => {
              return loadedProducts.find(e => Number(e.id) === Number(productId)) || null
          }).filter(Boolean)
        } catch (err) {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        } finally {
          this.isLoading = false
        }
      },
    },
}
</script>
