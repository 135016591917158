<template>
  <calendar-view />
</template>

<script>
import CalendarView from '@/views/calendar/Calendar.vue'

export default {
  name: 'SwCalendar',
  components: {
    CalendarView,
  },
}
</script>

<style scoped>

</style>
