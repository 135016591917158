<!--eslint-disable-->

<template>
  <div>
    <h4 class="mb-1">
      {{ $t('SchemeCalculatingCommissionForPayment') }}
    </h4>

    <b-row
      style="row-gap: 1rem"
      class="mb-1"
    >
      <!--    START::Include Minimum Product Costs    -->
      <b-col>
        <b-form-checkbox
          v-model="target.includeMinimumProductCosts"
          variant="primary"
          switch
        >
          {{ $t('IncludeMinimumProductCosts') }}

          <b-button
            v-b-tooltip.focus.v-primary
            variant="flat-primary"
            :title="$t('YouAssignMinimumProductCostsInProductInMarketingPlanSection')"
            class="btn-icon p-25 ml-25"
            size="sm"
          >
            <feather-icon icon="InfoIcon" />
          </b-button>
        </b-form-checkbox>
      </b-col>
      <!--    END::Include Minimum Product Costs    -->
    </b-row>

    <b-row style="row-gap: 1rem">
      <!--    START::Specify the commission calculation order    -->
      <b-col md="6">
        <validation-provider
          v-slot="{ errors }"
          :name="$t('SpecifyCommissionCalculationOrder')"
        >
          <sw-select
            :name="$t('SpecifyCommissionCalculationOrder')"
            :tip="$t('ItMeansHowWithdrawalCommissionWillBeCalculated')"
            class="mt-n50"
          >
            <v-select
              v-model="target.calculationOrderType"
              :options="commissionCalculationOrders"
              :clearable="false"
              :reduce="a => a.value"
            >
              <template #no-options>
                {{ $t('NoOptions') }}
              </template>

              <template #option="{ label }">
                {{ $t(label) }}
              </template>

              <template #selected-option="{ label }">
                {{ $t(label) }}
              </template>
            </v-select>
          </sw-select>

          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col>
      <!--    END::Specify the commission calculation order    -->
    </b-row>
    <!--    END::Bottom    -->

    <b-overlay
      :show="isLoading"
      no-wrap
      spinner-variant="primary"
    />
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import { VBTooltip } from 'bootstrap-vue'

export default {
  components: {
    ValidationProvider,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    target: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    commissionCalculationOrders: [
      { value: 'USER_TO_TOP', label: 'FromSellerUserUp' },
      // { value: 'USER_TO_BOTTOM', label: 'FromSellerUserDown' },
    ],
  }),
}
</script>
