const vatRates = () => {
  const vatRatesList = [
    '0',
    '8',
    '23',
  ]

  return vatRatesList
}

export default vatRates
