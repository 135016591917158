var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h4',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$t('UserCommissionForSaleOfSubUsers'))+" ")]),_c('b-row',{staticStyle:{"row-gap":"1rem"}},[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('SpecifyDepthOfSubordinateStructureForWhichCommissionIsDue')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('sw-select',{staticClass:"mb-0",attrs:{"name":_vm.$t('SpecifyDepthOfSubordinateStructureForWhichCommissionIsDue'),"tip":_vm.$t('HereYouWillSpecifyHowDeepIsStructureOfUsersWhoseSalesWill')}},[_c('v-select',{attrs:{"options":[1, 2, 3, 5, 10, 15],"create-option":function (num) { return Math.abs(num <= 15 ? Number(num) || 0 : 0); }},on:{"input":_vm.changeDeepLevel},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('NoOptions'))+" ")]},proxy:true},{key:"option",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s((label + " " + (_vm.$tc('LevelDown', label))))+" ")]}},{key:"selected-option",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s((label + " " + (_vm.$tc('LevelDown', label))))+" ")]}}],null,true),model:{value:(_vm.target.structureDeepLevel),callback:function ($$v) {_vm.$set(_vm.target, "structureDeepLevel", $$v)},expression:"target.structureDeepLevel"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('SpecifyBasisForCalculatingUserCommission')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('sw-select',{staticClass:"mb-0",attrs:{"state":errors.length > 0 ? false:null,"name":_vm.$t('SpecifyBasisForCalculatingUserCommission')}},[_c('v-select',{attrs:{"options":_vm.basisCalculatingUserCommissions,"disabled":!_vm.target.structureDeepLevel,"label":"label","reduce":function (i) { return i.value; }},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var label = ref.label;
return [_c('span',[_vm._v(_vm._s(_vm.$t(("" + label))))])]}},{key:"option",fn:function(ref){
var label = ref.label;
return [_c('span',[_vm._v(_vm._s(_vm.$t(("" + label))))])]}},{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('NoOptions'))+" ")]},proxy:true}],null,true),model:{value:(_vm.target.basisCalculatingUserCommission),callback:function ($$v) {_vm.$set(_vm.target, "basisCalculatingUserCommission", $$v)},expression:"target.basisCalculatingUserCommission"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),(_vm.target.marketingPlanStructureLevels && _vm.target.marketingPlanStructureLevels.length)?_c('b-col',{staticClass:"py-50 mx-0 row",staticStyle:{"row-gap":"1rem"},attrs:{"sm":"12"}},_vm._l((_vm.target.marketingPlanStructureLevels),function(level,i){return _c('b-col',{key:i,staticClass:"d-flex align-items-center",attrs:{"sm":"12","md":"6","lg":"4"}},[_c('validation-provider',{staticClass:"w-100",attrs:{"name":(i+1) + '. ' + _vm.$t('SpecifyNetCommissionAmount'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"mb-50"},[_vm._v(_vm._s(_vm.$t(((i + 1) + ". " + (_vm.$tc('LevelDown'))))))]),_c('b-input-group',{staticClass:"mb-50"},[_c('cleave',{staticClass:"form-control",attrs:{"state":errors.length > 0 ? false:null,"options":_vm.cleaveOptions.number,"type":"number"},model:{value:(_vm.target.marketingPlanStructureLevels[i].value),callback:function ($$v) {_vm.$set(_vm.target.marketingPlanStructureLevels[i], "value", $$v)},expression:"target.marketingPlanStructureLevels[i].value"}}),_c('b-input-group-append',[_c('b-button',{staticClass:"btn-icon px-1",attrs:{"variant":"outline-secondary"}},[_vm._v(" % ")])],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)}),1):_c('b-col',{staticClass:"text-center py-1 text-primary font-weight-bold",attrs:{"sm":"12"}},[_c('feather-icon',{attrs:{"size":"17","icon":"XCircleIcon"}}),_vm._v(" "+_vm._s(_vm.$t('ThereAreNoLevelsToDisplay'))+" ")],1)],1),_c('b-overlay',{attrs:{"show":_vm.isLoading,"no-wrap":"","spinner-variant":"primary"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }