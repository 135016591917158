<template>
  <div>
    <!--    Option: Options    -->
    <b-row class="mx-0">
      <b-col
        sm="12"
        md="6"
        lg="3"
      >
        <my-option
          :title="$t('BackgroundColor')"
          :my-class="'colorPicker'"
        >
          <input
            type="color"
            style="border-radius: .5rem; overflow: hidden;"
            :value="newWebsite.informationGridBackgroundColor"
            @change="newWebsite.informationGridBackgroundColor = $event.target.value"
          >
        </my-option>
      </b-col>

      <!--    Option: Color Footer    -->
      <b-col
        sm="12"
        md="6"
        lg="3"
      >
        <my-option
          :title="$t('IconColor')"
          :my-class="'colorPicker'"
        >
          <input
            type="color"
            style="border-radius: .5rem; overflow: hidden;"
            :value="newWebsite.informationGridIconColor"
            @change="newWebsite.informationGridIconColor = $event.target.value"
          >
        </my-option>
      </b-col>

      <!--    Option: Color Text Footer    -->
      <b-col
        sm="12"
        md="6"
        lg="3"
      >
        <my-option
          :title="$t('TextColor')"
          :my-class="'colorPicker'"
        >
          <input
            type="color"
            style="border-radius: .5rem; overflow: hidden;"
            :value="newWebsite.informationGridTitleColor"
            @change="newWebsite.informationGridTitleColor = $event.target.value"
          >
        </my-option>
      </b-col>

      <b-col
        sm="12"
        class="px-1"
      >
        <my-option :title="$t('Options')">
          <b-form-group>
            <b-form-checkbox
              v-model="newWebsite.showInformationGridBox"
              checked="true"
              name="check-button"
              switch
              variant="primary"
              inline
              class="w-100"
            >
              {{ $t('ShowItemsAsTiles') }}
            </b-form-checkbox>
          </b-form-group>
        </my-option>
      </b-col>
    </b-row>

    <hr class="mt-0">

    <!--    Option: Title    -->
    <b-row class="mx-0">
      <b-col
        sm="12"
        md="6"
        lg="8"
        class="px-1"
      >
        <my-option
          :title="$t('Title')"
          :tip="$t('MaximumLength') + ': 60'"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="$t('Title')"
            rules="required"
            class="w-100"
          >
            <b-form-group>
              <b-form-input
                id="popover-aboutTitle"
                v-model.trim="newWebsite.informationGridTitle"
                :placeholder="$t('Title')"
                :state="errors.length > 0 ? false:null"
                maxlength="60"
              />
              <small class="text-danger">{{ errors[0] }}</small>

              <!--    Popover    -->
              <b-popover
                target="popover-aboutTitle"
                triggers="focus"
                variant="primary"
              >
                <span>{{ $t('MaximumLength') + ': 60' }}</span>
              </b-popover>
            </b-form-group>
          </validation-provider>
        </my-option>
      </b-col>
    </b-row>

    <!--    Option: Description    -->
    <b-row class="mx-0">
      <b-col
        sm="12"
        md="6"
        lg="8"
        class="px-1"
      >
        <my-option
          :title="$t('task.Description')"
          :tip="$t('MaximumLength') + ': 110'"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="$t('Title')"
            rules="required"
            class="w-100"
          >
            <b-form-group>
              <b-form-textarea
                id="popover-bannerDesc"
                v-model.trim="newWebsite.informationGridDescription"
                :placeholder="$t('Title')"
                :state="errors.length > 0 ? false:null"
                maxlength="110"
              />
              <small class="text-danger">{{ errors[0] }}</small>

              <!--    Popover    -->
              <b-popover
                target="popover-bannerDesc"
                triggers="focus"
                variant="primary"
              >
                <span>{{ $t('MaximumLength') + ': 110' }}</span>
              </b-popover>
            </b-form-group>
          </validation-provider>
        </my-option>
      </b-col>
    </b-row>

    <!--    Option: Boxes    -->
    <b-row class="mx-0">
      <b-col
        sm="12"
        md="12"
        lg="8"
        class="px-1"
      >
        <my-option :title="$t('offer.website.Boxes')">
          <template #action>
            <b-button
              class="ml-50"
              variant="primary"
              size="sm"
              @click="
                selectedOption = { id: -1, opt: JSON.parse(JSON.stringify({
                  icon: 'CircleIcon',
                  translations: [{
                    title: '',
                    description: '',
                    linkUrl: '',
                  }]
                })) }
                showInformationGridOptionsModal = true
              "
            >
              <sw-icon icon="PlusIcon" /> {{ $t('Add') }}
            </b-button>
          </template>
          <!--    Form    -->
          <!--          <b-row class="w-100 mx-0 px-0">-->
          <!--            &lt;!&ndash;    Icon    &ndash;&gt;-->
          <!--            <b-col-->
          <!--              sm="12"-->
          <!--              md="12"-->
          <!--              lg="12"-->
          <!--              class="d-flex mb-25 px-0"-->
          <!--            >-->
          <!--              <b-button-->
          <!--                v-b-tooltip.hover.v-primary-->
          <!--                variant="primary"-->
          <!--                class="btn-icon mb-25"-->
          <!--                size="lg"-->
          <!--                :title="$t('Change')"-->
          <!--                @click="showIconListModal = true"-->
          <!--              >-->
          <!--                <feather-icon-->
          <!--                  :icon="newBox.icon ? newBox.icon : 'BoxIcon'"-->
          <!--                  size="34"-->
          <!--                />-->
          <!--              </b-button>-->
          <!--            </b-col>-->

          <!--            &lt;!&ndash;    Title    &ndash;&gt;-->
          <!--            <b-col-->
          <!--              sm="12"-->
          <!--              md="12"-->
          <!--              lg="12"-->
          <!--              class="d-flex mb-25 px-0"-->
          <!--            >-->
          <!--              <b-form-input-->
          <!--                id="popover-question"-->
          <!--                v-model.trim="newBox.title"-->
          <!--                :placeholder="$t('Title')"-->
          <!--                maxlength="120"-->
          <!--              />-->

          <!--              &lt;!&ndash;    Popover    &ndash;&gt;-->
          <!--              <b-popover-->
          <!--                target="popover-question"-->
          <!--                triggers="focus"-->
          <!--                variant="primary"-->
          <!--              >-->
          <!--                <span>{{ $t('MaximumLength') + ': 120' }}</span>-->
          <!--              </b-popover>-->
          <!--            </b-col>-->

          <!--            &lt;!&ndash;    Description    &ndash;&gt;-->
          <!--            <b-col-->
          <!--              sm="12"-->
          <!--              md="12"-->
          <!--              lg="12"-->
          <!--              class="d-flex align-items-end mb-25 px-0"-->
          <!--            >-->
          <!--              <b-form-textarea-->
          <!--                id="popover-answer"-->
          <!--                v-model.trim="newBox.description"-->
          <!--                :placeholder="$t('Description')"-->
          <!--                maxlength="300"-->
          <!--              />-->

          <!--              &lt;!&ndash;    Popover    &ndash;&gt;-->
          <!--              <b-popover-->
          <!--                target="popover-answer"-->
          <!--                triggers="focus"-->
          <!--                variant="primary"-->
          <!--              >-->
          <!--                <span>{{ $t('MaximumLength') + ': 300' }}</span>-->
          <!--              </b-popover>-->
          <!--            </b-col>-->

          <!--            &lt;!&ndash;    Link    &ndash;&gt;-->
          <!--            <b-col-->
          <!--              sm="12"-->
          <!--              md="12"-->
          <!--              lg="12"-->
          <!--              class="d-flex mb-25 px-0"-->
          <!--            >-->
          <!--              <b-form-input-->
          <!--                id="popover-link"-->
          <!--                v-model.trim="newBox.linkUrl"-->
          <!--                :placeholder="$t('Link')"-->
          <!--                maxlength="120"-->
          <!--              />-->

          <!--              &lt;!&ndash;    Popover    &ndash;&gt;-->
          <!--              <b-popover-->
          <!--                target="popover-link"-->
          <!--                triggers="focus"-->
          <!--                variant="primary"-->
          <!--              >-->
          <!--                <span>{{ $t('MaximumLength') + ': 60' }}</span>-->
          <!--              </b-popover>-->

          <!--              &lt;!&ndash;    Add Button    &ndash;&gt;-->
          <!--              <b-button-->
          <!--                variant="primary"-->
          <!--                class="btn-icon ml-1 offerWebsiteAdd"-->
          <!--                :disabled="!newBox.title.length || !newBox.description.length"-->
          <!--                @click.stop="-->
          <!--                  () => {-->
          <!--                    if (newBox.title.length > 0 && newBox.description.length > 0) {-->
          <!--                      newWebsite.informationGridOptions.push({-->
          <!--                        icon: JSON.parse(JSON.stringify(newBox.icon || 'BoxIcon')),-->
          <!--                        translations: [{-->
          <!--                          locale: 'pl_pl',-->
          <!--                          title: JSON.parse(JSON.stringify(newBox.title)),-->
          <!--                          description: JSON.parse(JSON.stringify(newBox.description)),-->
          <!--                          linkUrl: JSON.parse(JSON.stringify(newBox.linkUrl)),-->
          <!--                        }]-->
          <!--                      })-->

          <!--                      newBox.icon = ''-->
          <!--                      newBox.title = ''-->
          <!--                      newBox.description = ''-->
          <!--                      newBox.linkUrl = ''-->
          <!--                    }-->
          <!--                  }-->
          <!--                "-->
          <!--              >-->
          <!--                <feather-icon icon="PlusIcon" />-->
          <!--              </b-button>-->
          <!--            </b-col>-->
          <!--          </b-row>-->

          <!--    BoxList    -->
        </my-option>
      </b-col>

      <b-col sm="12"
             class="mt-50"
      >
        <b-row>
          <b-col v-for="(item, index) in newWebsite.informationGridOptions"
                 :key="index"
                 sm="12"
                 md="6"
          >
            <b-row class="w-100 px-0 mx-0 mb-25">
              <b-col sm="12"
                     class="d-flex justify-content-between align-items-center mt-25"
              >
                <div>{{ index + 1 }}. {{ $t('unit.BOX') }}</div>
                <div>
                  <!--    Remove Button    -->
                  <b-button
                    variant="flat-warning"
                    class="btn-icon"
                    size="sm"
                    @click="
                      selectedOption = { id: index, opt: JSON.parse(JSON.stringify(item)) }
                      showInformationGridOptionsModal = true
                    "
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>

                  <b-button
                    variant="flat-danger"
                    class="btn-icon ml-25"
                    size="sm"
                    @click="
                      newWebsite.informationGridOptions.splice(index, 1)

                      newWebsite.informationGridOptions.push()
                    "
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </div>
              </b-col>
              <!--    Icon    -->
              <b-col
                sm="12"
                md="12"
                lg="12"
                class="d-flex mb-25 px-0 align-items-center"
              >
                <b-button
                  v-b-tooltip.hover.v-primary
                  variant="primary"
                  class="btn-icon"
                  style="height: 88px; width: 88px"
                  :title="$t('Change')"
                  disabled
                  @click="showIconListModal = true"
                >
                  <feather-icon
                    :icon="item.icon ? item.icon : 'BoxIcon'"
                    size="34"
                  />
                </b-button>

                <div class="flex-grow-1 ml-25">
                  <b-form-input
                    id="popover-question"
                    v-model.trim="item.translations[0].title"
                    :placeholder="$t('Title')"
                    maxlength="120"
                    readonly
                  />
                  <b-form-input
                    id="popover-link"
                    v-model.trim="item.translations[0].linkUrl"
                    class="mt-75"
                    :placeholder="$t('Link')"
                    maxlength="120"
                    readonly
                  />
                </div>
              </b-col>

              <!--    Title    -->
              <b-col
                sm="12"
                md="12"
                lg="12"
                class="d-flex mb-25 px-0"
              />

              <!--    Description    -->
              <b-col
                sm="12"
                md="12"
                lg="12"
                class="d-flex align-items-end mb-25 px-0"
              >
                <b-form-textarea
                  id="popover-answer"
                  v-model.trim="item.translations[0].description"
                  :placeholder="$t('Description')"
                  maxlength="300"
                  readonly
                />
              </b-col>

              <!--    Link    -->
              <!--              <b-col-->
              <!--                sm="12"-->
              <!--                md="12"-->
              <!--                lg="12"-->
              <!--                class="d-flex mb-25 px-0"-->
              <!--              >-->

              <!--                &lt;!&ndash;    Remove Button    &ndash;&gt;-->
              <!--                <b-button-->
              <!--                  variant="outline-danger offerWebsiteAdd"-->
              <!--                  class="btn-icon ml-1"-->
              <!--                  @click.stop="-->
              <!--                    newWebsite.informationGridOptions.splice(index, 1)-->

              <!--                    newWebsite.informationGridOptions.push()-->
              <!--                  "-->
              <!--                >-->
              <!--                  <feather-icon icon="MinusIcon" />-->
              <!--                </b-button>-->
              <!--              </b-col>-->
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-modal
      v-model="showIconListModal"
      :title="$t('IconsList')"
      hide-footer
    >
      <b-form-input
        v-model.trim="searchIcon"
        :placeholder="$t('Search')"
        class="mb-1"
      />

      <b-button
        v-for="(icon, i) in iconList"
        :key="i"
        :variant="icon === (showInformationGridOptionsModal ? selectedOption.opt.icon : newBox.icon) ? 'primary' : 'flat-primary'"
        class="btn-icon"
        :disabled="icon === (showInformationGridOptionsModal ? selectedOption.opt.icon : newBox.icon)"
        @click="() => {
          if (showInformationGridOptionsModal) {
            selectedOption.opt.icon = icon
          } else {
            newBox.icon = icon
          }

          showIconListModal = false

          searchIcon = ''
        }"
      >
        <feather-icon
          :icon="icon"
          size="22"
        />
      </b-button>
    </b-modal>

    <!--    Modal: Change Information Grid options     -->
    <b-modal
      v-if="selectedOption.opt"
      v-model="showInformationGridOptionsModal"
      :title="$t('offer.website.InformationGrid')"
      size="sm"
      hide-footer
      no-close-on-backdrop
    >
      <b-row class="px-1 pt-25 mb-1">
        <!--    Icon    -->
        <b-col
          cols="12"
          class="d-flex mb-25 px-0"
        >
          <b-button
            v-b-tooltip.hover.v-primary
            variant="primary"
            class="btn-icon mb-25"
            size="lg"
            :title="$t('Change')"
            @click="showIconListModal = true"
          >
            <feather-icon
              :icon="selectedOption.opt.icon ? selectedOption.opt.icon : 'BoxIcon'"
              size="34"
            />
          </b-button>
        </b-col>

        <!--    Title    -->
        <b-col
          cols="12"
          class="d-flex mb-25 px-0"
        >
          <b-form-input
            id="popover-question"
            v-model.trim="selectedOption.opt.translations[0].title"
            :placeholder="$t('Title')"
            maxlength="120"
          />

          <!--    Popover    -->
          <b-popover
            target="popover-question"
            triggers="focus"
            variant="primary"
          >
            <span>{{ $t('MaximumLength') + ': 120' }}</span>
          </b-popover>
        </b-col>

        <!--    Description    -->
        <b-col
          cols="12"
          class="d-flex align-items-end mb-25 px-0"
        >
          <b-form-textarea
            id="popover-answer"
            v-model.trim="selectedOption.opt.translations[0].description"
            :placeholder="$t('Description')"
            maxlength="300"
          />

          <!--    Popover    -->
          <b-popover
            target="popover-answer"
            triggers="focus"
            variant="primary"
          >
            <span>{{ $t('MaximumLength') + ': 300' }}</span>
          </b-popover>
        </b-col>

        <!--    Link    -->
        <b-col
          sm="12"
          md="12"
          lg="12"
          class="d-flex mb-25 px-0"
        >
          <b-form-input
            id="popover-link"
            v-model.trim="selectedOption.opt.translations[0].linkUrl"
            :placeholder="$t('Link')"
            maxlength="120"
          />

          <!--    Popover    -->
          <b-popover
            target="popover-link"
            triggers="focus"
            variant="primary"
          >
            <span>{{ $t('MaximumLength') + ': 60' }}</span>
          </b-popover>
        </b-col>
      </b-row>

      <!--    Button: Save    -->
      <b-button
        variant="success"
        size="sm"
        :disabled="!selectedOption.opt.translations[0].title.length || !selectedOption.opt.translations[0].description.length"
        @click="() => {
          if (selectedOption.id === -1) {
            newWebsite.informationGridOptions.push({ ...selectedOption.opt, translations: [{ ...selectedOption.opt.translations[0], locale: 'pl_pl' } ]})
            $forceUpdate()

            showInformationGridOptionsModal = false
          } else {
            if (selectedOption.opt.translations[0].title.length > 0 && selectedOption.opt.translations[0].description.length > 0) {
              newWebsite.informationGridOptions[selectedOption.id] = selectedOption.opt
              $forceUpdate()

              showInformationGridOptionsModal = false
            }
          }
        }
        "
      >
        {{ $t('Save') }}
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import { BFormTextarea, BPopover, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider } from 'vee-validate'
import myOption from '@/views/offers/website/Option.vue'
import * as icons from 'vue-feather-icons'

export default {
  components: {
    BFormTextarea,
    myOption,
    ValidationProvider,
    BPopover,
  },

  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  props: {
    value: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    newWebsite: { },

    newBox: {
      icon: '',
      title: '',
      description: '',
    },

    showIconListModal: false,

    iconList: [],
    searchIcon: '',

    selectedOption: { },
    showInformationGridOptionsModal: false,
  }),

  watch: {
    searchIcon(n) {
      if (!n.length) this.iconList = Object.values(icons).map(i => i.name)

      this.iconList = Object.values(icons)
        .map(i => i.name)
        .filter(icon => icon.toLowerCase().includes(n.toLowerCase()))
    },
  },

  mounted() {
    this.newWebsite = this.value
    this.iconList = Object.values(icons).map(i => i.name)
  },

  methods: {
  },
}
</script>
