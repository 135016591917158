<template>
  <b-form-group
    class="d-flex flex-column sw-select"
    :class="classes"
  >
    <div
      v-if="name"
      class="d-flex mb-25"
    >
      <label>
        {{ name }}
      </label>

      <b-button
        v-if="tip"
        v-b-tooltip
        variant="flat-primary"
        :title="tip"
        class="btn-icon p-25 mt-n25 ml-25"
        size="sm"
      >
        <feather-icon icon="AlertCircleIcon" />
      </b-button>
    </div>

    <b-form-group
      class="d-none d-md-inline-block w-100 mb-0"
      :state="state"
    >
      <validation-provider
        v-slot="{ errors }"
        :name="name"
        :rules="rules"
      >
        <template v-if="$slots.prepend || $slots.append">
          <b-input-group>
            <b-input-group-prepend
              v-if="$slots.prepend"
              :is-text="isText"
            >
              <slot name="append" />
            </b-input-group-prepend>

            <slot />

            <b-input-group-append
              v-if="$slots.append"
              :is-text="isText"
            >
              <slot name="append" />
            </b-input-group-append>
          </b-input-group>
        </template>

        <slot v-else />

        <small
          v-if="rules && name && showError"
          class="text-danger"
        >
          {{ errors[0] }}
        </small>
      </validation-provider>
    </b-form-group>

    <div
      class="d-md-none position-relative overflow-hidden"
      @click="showModal"
    >
      <validation-provider
        v-slot="{ errors }"
        :name="name"
        :rules="rules"
      >
        <template v-if="$slots.prepend || $slots.append">
          <b-input-group>
            <b-input-group-prepend
              v-if="$slots.prepend"
              :is-text="isText"
            >
              <slot name="append" />
            </b-input-group-prepend>

            <slot />

            <b-input-group-append
              v-if="$slots.append"
              :is-text="isText"
            >
              <slot name="append" />
            </b-input-group-append>
          </b-input-group>
        </template>

        <slot v-else />

        <small
          v-if="rules && name && showError"
          class="text-danger"
        >
          {{ errors[0] }}
        </small>
      </validation-provider>

      <div
        class="wall cursor-pointer"
        @click="showModal"
      />
    </div>

    <b-modal
      v-model="sm"
      :title="name"
      hide-footer
      tabindex="none"
      size="lg"
      modal-class="my-overlay sw-select"
    >
      <slot />
    </b-modal>
  </b-form-group>
</template>

<script>
import { BInputGroupAppend, VBTooltip } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'

export default {
  components: { ValidationProvider, BInputGroupAppend },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    name: {
      type: [String, Object],
      required: false,
      default: '',
    },
    disabled: {
      type: null,
      required: false,
      default: false,
    },
    tip: {
      type: String,
      required: false,
      default: '',
    },
    state: {
      required: false,
      default: null,
    },
    classes: {
      required: false,
      default: () => (['w-100']),
    },
    isText: {
      type: Boolean,
      required: false,
      default: false,
    },
    rules: {
      type: String,
      required: false,
      default: '',
    },
    showError: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data: () => ({
    sm: null,
  }),
  methods: {
    showModal() {
      if (!this.disabled) this.sm = true
    },
  },
}
</script>
