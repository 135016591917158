<template>
  <div>
    <!--    Buttons    -->
    <div
      class="mb-1 d-flex flex-wrap"
      style="gap: .34rem"
    >
      <!--    Button: Cancel    -->
      <b-button
        variant="warning"
        size="sm"
        @click="$router.go(-1)"
      >
        <feather-icon icon="ArrowLeftIcon" />

        {{ $t('Return') }}
      </b-button>
    </div>

    <b-card>
      <b-alert
        show
        class="p-1"
        variant="primary"
      >
        {{ $t('AttributesTemplateInfo') }}
      </b-alert>
      <!-- Content -->
      <b-form-group :label="$t('Name')">
        <b-form-input
          v-model="template.name"
          type="text"
        />
      </b-form-group>
      <!-- Type -->
      <sw-select :name="$t('task.Type')">
        <v-select
          v-model="template.type"
          :options="components"
          type="text"
          :reduce="option => option.value"
          item-value="value"
          item-text="label"
          @input="getTemplateTypeSchortcodes"
        >
          <template #no-options>
            {{ $t('NoOptions') }}
          </template>
          <template #option="{ label }">
            {{ $tc(`${label}`) }}
          </template>
          <template #selected-option="{ label }">
            {{ $tc(`${label}`) }}
          </template>
        </v-select>
      </sw-select>
      <sw-select :name="$t('Separator')">
        <v-select
          v-model="template.contentSeparator"
          :options="contentSeparators"
          type="text"
          :reduce="option => option.value"
          item-value="value"
          item-text="label"
          @input="getTemplateTypeSchortcodes"
        >
          <template #no-options>
            {{ $t('NoOptions') }}
          </template>
          <template #option="{ label, value }">
            {{ label ? $tc(`${label}`) : value }}
          </template>
          <template #selected-option="{ label, value }">
            {{ label ? $tc(`${label}`) : value }}
          </template>
        </v-select>
      </sw-select>
      <b-form-group :label="$t('Content')">
        <quill-editor
          ref="editor"
          v-model="template.content"
          class="product"
          :options="editorOption"
        >
          <div
            id="toolbar"
            slot="toolbar"
          >
            <!-- Add a bold button -->
            <span class="ql-formats">
              <button class="ql-clean" />
              <button class="ql-bold" />
              <button class="ql-italic" />
              <button class="ql-link" />
            </span>
            <span class="ql-formats">
              <button
                class="ql-script"
                value="sub"
              />
              <button
                class="ql-script"
                value="super"
              />
            </span>
            <span class="ql-formats">
              <button
                class="ql-list"
                value="ordered"
              />
              <button
                class="ql-list"
                value="bullet"
              />
            </span>
            <span class="ql-formats">
              <button
                class="ql-indent"
                value="-1"
              />
              <button
                class="ql-indent"
                value="+1"
              />
            </span>
            <span class="ql-formats">
              <select class="ql-align" />
              <select class="ql-color" />
            </span>
            <span class="ql-formats">
              <select class="ql-size" />
              <select class="ql-header" />
              <select class="ql-font" />
            </span>

            <!-- You can also add your own -->
            <span class="ql-formats">
              <b-button
                id="custom-button"
                variant="success"
                class="w-auto"
                @click.prevent.stop="isModalOpen = true"
              >
                <span class="text-white">
                  <feather-icon
                    icon="CodeIcon"
                    class="mr-25"
                  />
                </span>

                <span class="text-white">
                  {{ $t('ShortCodes') }}
                </span>
              </b-button>
            </span>
          </div>
        </quill-editor>
      </b-form-group>
      <!--     Save Button     -->
      <b-button
        variant="primary"
        :disabled="!template.name || !template.content || !template.type"
        @click="saveProcess"
      >
        {{ $t('Save') }}
      </b-button>
    </b-card>

    <!--    Modal: Shortcode     -->
    <b-modal
      :visible="isModalOpen"
      :title="$t('Codes')"

      size="lg"
      hide-footer
      no-close-on-backdrop
      @hide="isModalOpen = false"
    >
      <shortcodes-modal
        :default-shortcodes="shortcodes"
        @close-modal="isModalOpen = false"
      />
    </b-modal>

    <transition name="fade">
      <div
        v-if="scrollPos && scrollPos.y.value > 200"
        class="btn position-fixed mr-25"
        style="z-index: 999; bottom: 4.4rem; right: 0; transition-delay: .14s;"
      >
        <b-button
          variant="success"
          class="btn-icon rounded-circle"
          @click.prevent.stop="isModalOpen = true"
        >
          <feather-icon
            icon="CodeIcon"
            size="16"
          />
        </b-button>
      </div>
    </transition>

    <b-overlay
      no-wrap
      :show="loading"
      spinner-variant="primary"
    />
  </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'
import {
  GET_ATTRIBUTE,
  GET_CONTACT_ADDON_FIELD_TYPES,
  GET_PRODUCT_TEMPLATE,
  GET_PRODUCT_TEMPLATES,
  SAVE_PRODUCT_TEMPLATE,
  UPDATE_PRODUCT_TEMPLATE,
} from '@/@constants/mutations'
import ShortcodesModal from '@/views/modals/ShortcodesModal.vue'
import { contractorShortcodes, productShortcodes } from '@/@data/shortcodes'
import { BAlert, VBTooltip } from 'bootstrap-vue'
import keysCuter from '@/helpers/keysCuter'
import vSelect from 'vue-select'
import { components } from '@/@data/components'
import { useWindowScroll } from '@vueuse/core'

export default {
  name: 'OfferProductTemplate',
  components: {
    quillEditor,
    ShortcodesModal,
    BAlert,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data: () => ({
    components,
    scrollPos: 0,
    isModalOpen: false,
    attributes: [],
    shortcodes: [],
    contentSeparators: [
      { label: '', value: ', ' },
      { label: '', value: '. ' },
      { label: '', value: ': ' },
      { label: '', value: '; ' },
      { label: '', value: '/ ' },
      { label: 'NewLine', value: '<br /> ' },
    ],
    template: {
      name: '',
      content: '',
      type: '',
      contentSeparator: '',
    },
    loading: false,
    editorOption: {
      modules: {
        toolbar: '#toolbar',
      },
    },
  }),
  watch: {
    '$route.params.templateId': {
      deep: true,
      handler() { this.getTemplate() },
    },
  },
  mounted() {
    this.getTemplate()
    this.scrollPos = useWindowScroll()
  },
  methods: {
    async getTemplateTypeSchortcodes(type) {
      this.shortcodes = []
      if (type === 'PRODUCT') {
        this.shortcodes = JSON.parse(JSON.stringify(productShortcodes))
        this.getComponentsForShortcodes('ATTRIBUTE')
        this.getAttributes()
      }
      if (type === 'ATTRIBUTE') {
        this.shortcodes = [
          {
            title: 'AttributeList',
            shortcodes: [
              { title: 'Name', code: 'attribute.name' },
              { title: 'Value', code: 'attribute.value' },
            ],
          },
        ]
      }
      if (type === 'PAYMENT_INSTALLMENTS') {
        this.shortcodes = [
          {
            title: 'contactTabs.Payments',
            shortcodes: [
              { title: 'Installment', code: 'payment.installment', options: ['Value', 'Term'] },
            ],
          },
        ]
      }
      if (type === 'CONTRACTOR') {
        const fields = await this.$store.dispatch(`addonFieldTypes/${GET_CONTACT_ADDON_FIELD_TYPES}`)
        const preparedFields = this.prepareAttributeShortcodes(fields, 'contractor.additionalField', 'AdditionalInformation', true)
        this.shortcodes = JSON.parse(JSON.stringify(contractorShortcodes))

        this.shortcodes = this.shortcodes.map(shortcode => {
          if (shortcode.title.includes('Contractor') && !shortcode.shortcodes.some(s => s.code.includes('additional'))) {
            shortcode.shortcodes.push(...preparedFields)
          }

          return shortcode
        })
      }

      if (!this.shortcodes.find(short => short.title === 'NoNumber')) {
        this.shortcodes.push({
          title: 'NoNumber',
          shortcodes: [
            { title: 'Number', code: 'no.1' },
            { title: 'Letter', code: 'no.a' },
          ],
        })
      }
    },
    getComponentsForShortcodes(component) {
      this.$store.dispatch(`productTemplate/${GET_PRODUCT_TEMPLATES}`, { filters: { type: component } })
        .then(res => {
          this.prepareComponentsShortcodes(res.data.items)
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
    getTemplate() {
      if (this.$route.params?.templateId && this.$route.params.templateId !== 'new') {
        this.loading = true
        this.$nextTick(() => {
          this.$store.dispatch(`productTemplate/${GET_PRODUCT_TEMPLATE}`, this.$route.params.templateId)
            .then(res => {
              this.$set(this, 'template', res.data.item)
              this.getTemplateTypeSchortcodes(res.data.item.type)
              this.loading = false
            })
            .catch(err => {
              this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
              this.loading = false
            })
        })
      } else if (this.$route.params?.templateId === 'new') {
        this.$set(this, 'template', {
          name: '',
          content: '',
          type: '',
          contentSeparator: '',
        })
      }
    },
    getAttributes() {
      this.$store.dispatch(`attributes/${GET_ATTRIBUTE}`)
        .then(res => {
          this.prepareAttributeShortcodes(res.data.items)
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
    // eslint-disable-next-line consistent-return
    prepareAttributeShortcodes(attributes, code = 'product.attribute', titlePrefix = '', returnValue = false) {
      const typesSample = {
        DIGITAL: '15',
        PERCENTAGE: '10%',
        TEXT: 'Lorem ipsum dolor',
        DATE: '01.04.2021',
        METRIC: '5',
        TIME: '12:00',
        TEXT_ARRAY: 'Lorem',
        TEXT_MULTIPLE_ARRAY: 'Lorem, ipsum',
      }
      const prepared = attributes.map(item => ({
        titlePrefix, title: item?.translations ? item?.translations[0].name : item.name, code: `${code}.${item.id}`, sample: typesSample[item.type], options: ['Name', 'Value'],
      }))

      if (returnValue) return prepared

      this.shortcodes.push({
        title: 'AttributeList',
        shortcodes: prepared,
      })
    },
    prepareComponentsShortcodes(componentItems) {
      const prepared = componentItems.map(({ name, type, id }) => ({ title: name, code: `component.${id}`, sample: `${type} - ${id}` }))
      this.shortcodes.push({
        title: 'Components',
        shortcodes: prepared,
      })
    },

    saveProcess() {
      this.loading = true
      const payload = { ...this.template }
      payload.type = typeof payload.type === 'object' ? payload.type?.value : payload.type
      let action = SAVE_PRODUCT_TEMPLATE

      if (this.$route.params?.templateId && this.$route.params?.templateId !== 'new') {
        action = UPDATE_PRODUCT_TEMPLATE
        payload.id = this.$route.params.templateId
      }

      this.$store.dispatch(`productTemplate/${action}`, keysCuter(payload))
        .then(res => {
          if (action === SAVE_PRODUCT_TEMPLATE) this.$router.push({ name: 'components', params: { templateId: res.data.items[0]?.id } })
          this.showToast('success', this.$i18n.t(`${action === SAVE_PRODUCT_TEMPLATE ? 'ContractTemplateCreated' : 'ContractTemplateUpdated'}`))
          this.loading = false
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss">
.ql-container {
  min-height: 100px;
}

.product {
  .ql-container {
    min-height: 175px;
  }
}
</style>
