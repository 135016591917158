import { shortcodes } from '@/@data/shortcodes'

export const shortcodePreview = text => {
  // eslint-disable-next-line no-shadow
  const items = shortcodes
  let textCopy = text
  items.forEach(shortcodeType => {
    shortcodeType.shortcodes.forEach(shortcode => {
      const replaceTo = `
          <small style="padding: 2px 4px; background-color: var(--primary); color: white; border-radius: 5px">
            ${shortcode.sample}
          </small>`
      textCopy = textCopy ? textCopy.replaceAll(`{% ${shortcode.code} %}`, replaceTo) : textCopy
    })
  })

  return textCopy
}

export const _ = null
