<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link
          class="brand-logo"
          style="margin-bottom: .5rem; margin-top: .5rem"
        >
          <b-img
            :src="system.logo"
            style="max-width: 225px"
          />
        </b-link>

        <h4>{{ $t('login.Welcome') }}, {{ user.firstName }}</h4>
        <small>{{ $t('login.TypePasswordForAccount') }} {{ user.email }}</small>
        <!-- form -->
        <validation-observer
          ref="loginForm"
          v-slot="{invalid}"
        >
          <b-form
            class="auth-login-form mt-1"
            @submit.prevent="setPassword"
          >
            <input
              type="email"
              :value="user.email"
              disabled
              style="display: none"
            >
            <!-- password -->
            <b-form-group>
              <validation-provider
                v-slot="{ errors }"
                :name="$t('login.Password')"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    placeholder="********"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                    <feather-icon
                      id="popover-password-requirements"
                      class="cursor-pointer ml-50"
                      :class="{ 'text-danger': !passwordMatch }"
                      icon="AlertCircleIcon"
                    />

                    <b-popover
                      ref="popover-show"
                      target="popover-password-requirements"
                      triggers="hover"
                    >
                      <template #title>
                        {{ $t('PasswordRequirements.PasswordRequirements') }}
                      </template>
                      <p
                        v-for="req in passwordRequirements"
                        :key="req"
                        class="text-muted"
                      >
                        {{ $t(`PasswordRequirements.${req}`) }}
                      </p>
                    </b-popover>
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid || loading || !passwordMatch"
            >
              <b-spinner
                v-if="loading"
                small
              />
              <span v-else>{{ $t('login.SetPassword') }}</span>
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BForm, BFormGroup, BLink, BInputGroup, BInputGroupAppend, BImg, BPopover,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Logo from '@/assets/images/core/logo.png'
import { AUTH_REQUEST } from '@/@constants/mutations'
import axiosIns from '@/libs/axios'
import { mapGetters } from 'vuex'

export default {
  components: {
    // BSV
    BPopover,
    BImg,
    BForm,
    BFormGroup,
    BLink,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      loading: false,
      appLogo: Logo,
      password: '',
      passwordFieldType: 'password',
      required,
      user: {
        firstName: '',
        email: '',
      },
      passwordRequirements: [
        'OneLetter',
        'OneDigit',
        'NoSpaces',
        'OneSpecialChar',
        'MinLen',
        'MaxLen',
      ],
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordMatch() {
      const regex = /^(?=^.{8,32}$)(?=.*\d)(?=.*(\W|_)+)(?!.*\n)(?=.*[A-Z])(?=.*[a-z]).*$/
      return regex.test(this.password)
    },
    ...mapGetters({
      system: 'system/getSettings',
    }),
  },
  beforeMount() {
    if (this.$route.params.token) {
      this.$store.dispatch('auth/GET_USER_BY_TOKEN', this.$route.params.token)
        .then(res => {
          const userLocale = res.data.locale.split('_')[0]
          this.$i18n.locale = userLocale
          this.user = res.data
        })
        .catch(() => {
          this.showToast('danger', this.$i18n.t('errors.TOKEN_INVALID'))
          this.$router.push('/login')
        })
    } else this.$router.push('/login')
  },
  methods: {
    togglePasswordType() {
      if (this.passwordFieldType === 'password') this.passwordFieldType = 'text'
      else this.passwordFieldType = 'password'
    },
    setPassword() {
      this.loading = true
      const payload = {
        token: this.$route.params.token,
        newPassword: this.password,
      }
      this.$store.dispatch('auth/SET_USER_PASSWORD', payload)
        .then(() => {
          const authPayload = {
            userEmail: this.user.email,
            password: this.password,
          }
          this.$store.dispatch(`auth/${AUTH_REQUEST}`, authPayload)
            .then(token => {
              axiosIns.defaults.headers.common.Authorization = `Bearer ${token}`
              this.showToast('success', this.$i18n.t('SoonYouWillBeRedirected'), this.$i18n.t('alert.Success'))
              setTimeout(() => {
                this.$router.push('/')
                this.loading = false
              }, 1500)
            })
            .catch(err => {
              this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
              this.loading = false
            })
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.auth-wrapper.auth-v1 {
  .auth-inner {
    max-width: 500px;
  }
}
</style>
