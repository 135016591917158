/* eslint-disable */

import Vue from 'vue'
import {
  ADD_PRODUCT_TO_CART, CHANGE_PRODUCT_AMOUNT,
  GET_USER_CART,
  REMOVE_PRODUCT_FROM_CART,
  UPDATE_CART,
  UPDATE_CART_PRODUCT
} from '@/@constants/mutations'
// eslint-disable-next-line import/no-cycle
import axiosIns from '@/libs/axios'
import * as fields from '@/@constants/fields'
import axios from "axios";
import moment from 'moment'

import i18n from '@/libs/i18n'
import {getObjectId} from "@core/mixins";

let source;

export default {
  namespaced: true,
  state: {
    // ORDER || OFFER
    type: '',
    allowElectronicSignature: false,
    testSalesUser: null,
    allowAddAgreements: true,
    sellerDiscountEnabled: false,
    orderNumber: '',
    deliveryDateFrom: { date: '' },
    deliveryDateTo: { date: '' },

    // New
    id: '',
    name: '',
    cartProducts: [],
    cartAgreements: [],
    contactThread: "",
    overwriteVat: -1,
    sendEmail: false,
    offerValidityDays: 14,
    offerWebTemplate: '',
    orderNote: '',
    hideOfferProductPrice: false,
    hideOfferTotalPrice: false,
    files: [],
    presentationFiles: [],
    seller: '',
    paymentType: 'CASH',
    amountUnitType: 'PIECE',
    cartPayments: [{
      type: 'ADVANCE',
      value: 0,
      date: moment().add(1, 'month').format('YYYY-MM-DD'),
      isUsed: false,
    },{
      type: 'TOTAL_PAYMENT',
      value: 0,
      date: moment().format('YYYY-MM-DD'),
    }],
    purchaseLoyaltyPlan: '',
    autoCreateOrderAfterOfferAccepted: false,
    bookProductInStorage: false,
    sellerCommissionEnabled: false,
    sellerCommissionParentEnabled: false,
    deliveryAddress: [],
    // To Delete after: 'CONTACT_ADDRESS',
    deliveryAddressType: 'CONTACT_ADDRESS',
    otherAddress: {
      city: '',
      country: '',
      number: '',
      postalCode: '',
      street: '',
    },
    offerEmailContent: '',
  },
  getters: {
    getCartProducts: state => state.cartProducts,
    getCartProductsIds: state => state.cartProducts.map(product => product.shopProduct.id),
    getCartThread: state => state.contactThread,
    getCart: state => ({ ...state }),
  },
  mutations: {
    CHANGE_CART_THREAD(state, payload) {
      Vue.set(state, 'contactThread', payload)
    },
    [GET_USER_CART](state, payload) {
      Vue.set(state, 'id', payload.id || '')
      Vue.set(state, 'name', payload.name || i18n.t('Offer.Cart') + ' ' + payload.id)
      Vue.set(state, 'overwriteVat', payload?.overwriteVat == null ? null : String(payload?.overwriteVat))
      Vue.set(state, 'cartProducts', payload.contactThreadCartDraftProducts.map(prod => ({ ...prod, overwriteVat: prod.overwriteVat == null ? null : String(prod.overwriteVat) })) || [])
      Vue.set(state, 'contactThread', payload.contactThread || null)
      Vue.set(state, 'allowElectronicSignature', payload.allowElectronicSignature || false)
      Vue.set(state, 'electronicSignatureType', payload.electronicSignatureType || false)
      Vue.set(state, 'sendEmail', payload.sendEmail || false)
      Vue.set(state, 'offerValidityDays', payload.offerValidityDays || 14)
      Vue.set(state, 'offerWebTemplate', payload.offerWebTemplate || {})
      Vue.set(state, 'hideOfferProductPrice', payload.hideOfferProductPrice || false)
      Vue.set(state, 'hideOfferTotalPrice', payload.hideOfferTotalPrice || false)
      Vue.set(state, 'allowAddAgreements', payload?.allowAddAgreements ?? true)
      Vue.set(state, 'files', state.files)
      Vue.set(state, 'presentationFiles', state.presentationFiles)
      Vue.set(state, 'orderNote', payload.orderNote || '')
      Vue.set(state, 'amountUnitType', 'PIECE')

      // HYDROPURE
      if (window.location.href.includes('hydropure') || window.location.href.includes('192.') && payload.orderNumber && payload.orderNumber != 'null') {
        let filled = ''
        for (let i = payload.orderNumber?.length || 0; i < 6; i++) {
          filled += 0;
        }
        Vue.set(state, 'orderNumber', filled + payload.orderNumber || '')
      } else {
        Vue.set(state, 'orderNumber', payload.orderNumber == 'null' ? '' : payload.orderNumber)
      }

      Vue.set(state, 'orderDate', payload.orderDate?.date ? { date: payload.orderDate?.date } : { date: new Date() })
      Vue.set(state, 'deliveryDateFrom', payload.deliveryDateFrom?.date ? { date: payload.deliveryDateFrom?.date } : { date: '' })
      Vue.set(state, 'deliveryDateTo', payload.deliveryDateTo?.date ? { date: payload.deliveryDateTo?.date } : { date: '' })
      Vue.set(state, 'seller', payload.seller || '')

      // Vue.set(state, 'type', payload?.type || this.state.system.config?.defaultCartOrderProcess ? 'ORDER' : 'OFFER')
      if (payload.type) {
        Vue.set(state, 'type', payload?.type ?? 'OFFER')
      } else {
        Vue.set(state, 'type', this.state.system.config?.defaultCartOrderProcess ? 'ORDER' : 'OFFER')
      }

      Vue.set(state, 'sellerCommissionEnabled', payload.sellerCommissionEnabled || false)
      Vue.set(state, 'sellerCommissionParentEnabled', payload.sellerCommissionParentEnabled || false)
      Vue.set(state, 'autoCreateOrderAfterOfferAccepted', payload?.autoCreateOrderAfterOfferAccepted || false)
      Vue.set(state, 'cartAgreements', payload.contactThreadCartDraftAgreements || [])
      Vue.set(state, 'bookProductInStorage', payload?.bookProductInStorage || false)
      Vue.set(state, 'paymentType', payload?.paymentType || 'CASH')
      Vue.set(state, 'deliveryAddress', payload?.deliveryAddress?.id || '')
      Vue.set(state, 'purchaseLoyaltyPlan', payload?.purchaseLoyaltyPlan?.id || '')
      const scheduleItems = payload?.contactThreadCartDraftPaymentScheduleItems?.map(item =>  ({
        ...item,
        date: item?.date?.date || moment().add(7, 'days').format('YYYY-MM-DD'),
      })) || []
      Vue.set(state, 'cartPayments', scheduleItems?.length ? scheduleItems : [{
        type: 'ADVANCE',
        value: 0,
        date: moment().add(1, 'month').format('YYYY-MM-DD'),
        isUsed: false,
      }, {
        type: 'TOTAL_PAYMENT',
        value: 0,
        date: moment().format('YYYY-MM-DD'),
      }])
      // type
    },
  },
  actions: {
    [UPDATE_CART_PRODUCT]: ({ dispatch }, { product, skip = false }) => new Promise((resolve, reject) => {
      if (!product || (Array.isArray(product) && !product.length)) {
        resolve()
        return
      }

      axiosIns.patch('1/contacts/threads/cartDrafts/me/products', product)
        .then(res => {
          !skip && dispatch(GET_USER_CART, { skipType: true })
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    [UPDATE_CART]: ({ dispatch, state }, { cart, updateFiles = true, skip = false }) => new Promise((resolve, reject) => {
      const payload = JSON.parse(JSON.stringify(cart))
      delete payload.cartProducts

      Object.keys(payload).forEach(key => {
        if (key === 'offerValidityDays') payload[key] = parseFloat(payload[key])
        else if (key === 'contactThreadCartDraftPaymentScheduleItems') payload[key] = payload[key].map(item => ({ ...item, value: parseFloat(item.value) }))
        // else if (key !== 'files') payload[key] = typeof payload[key] === 'object' ? (payload[key]?.id || null) : payload[key]
        // else if (key !== 'presentationFiles') payload[key] = typeof payload[key] === 'object' ? (payload[key]?.id || null) : payload[key]
      })

      if (!cart.id) {
        payload.id = state.id
      }

      if (!updateFiles) {
        delete payload.files
        delete payload.presentationFiles
      }

      delete payload.presentationFiles

      payload.overwriteVat = payload.overwriteVat === null ? null : Number(payload.overwriteVat)
      payload.orderDate = typeof payload.orderDate === 'string' ? payload.orderDate : (payload.orderDate?.date || new Date())
      delete payload.orderDate
      delete payload.orderNote
      delete payload.deliveryDateFrom
      delete payload.deliveryDateTo
      delete payload.cartAgreements
      delete payload.cartPayments
      delete payload.otherAddress
      payload.contactThread = getObjectId(payload.contactThread) || null
      payload.offerWebTemplate = getObjectId(payload.offerWebTemplate) || null
      payload.seller = getObjectId(payload.seller) || null

      // HYDROPURE
      if (window.location.href.includes('hydropure') || window.location.href.includes('192.')) {
        if (!payload.type) {
          payload.type = 'ORDER'
        }

        if (payload.orderNumber && payload.orderNumber != 'null') {
          let filled = ''
          for (let i = payload.orderNumber?.length; i < 6; i++) {
            filled += 0;
          }
          payload.orderNumber = String(filled + payload.orderNumber)
        } else {
          payload.orderNumber = String(payload.orderNumber || '')
        }
      }

      payload.orderNumber = payload.orderNumber || null

      if (!payload.id) return

      axiosIns.patch('1/contacts/threads/cartDrafts/me', payload)
        .then(res => {
          !skip && dispatch(GET_USER_CART)
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    [ADD_PRODUCT_TO_CART]: ({ dispatch, state }, product) => new Promise((resolve, reject) => {
      let payload = {
        amount: 1,
        shopProduct: product.id,
        overwriteVat: state.overwriteVat === null ? null : Number(state.overwriteVat)
      }

      if (Array.isArray(product)) {
        payload = [
          ...product.map(p => ({ amount: 1, shopProduct: p, overwriteVat: state.overwriteVat === null ? null : Number(state.overwriteVat) }))
        ]
      }

      axiosIns.post('1/contacts/threads/cartDrafts/me/products', payload)
        .then(res => {
          dispatch(GET_USER_CART)
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    [CHANGE_PRODUCT_AMOUNT]: ({ dispatch }, product) => new Promise((resolve, reject) => {
      if (typeof source !== typeof undefined) source.cancel("Operation canceled due to new request.")

      source = axios.CancelToken.source()
      let payload = {
        amount: product.amount,
        id: product.id
      }

      axiosIns.patch('1/contacts/threads/cartDrafts/me/products', payload, { cancelToken: source.token })
          .then(res => {
            resolve(res.data)
          })
          .catch(err => {
            reject(err)
          })
    }),
    [REMOVE_PRODUCT_FROM_CART]: ({ dispatch }, { productId, loadCart = true }) => new Promise((resolve, reject) => {
      const data = JSON.stringify((Array.isArray(productId) ? productId.map(id => id) : [productId]))
      axiosIns.delete('1/contacts/threads/cartDrafts/me/products', { data })
        .then(res => {
          loadCart && dispatch(GET_USER_CART)
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    [GET_USER_CART]: ({ commit, dispatch }, additionalOptions) => new Promise(async (resolve, reject) => {
      const params = {
        fields_load: fields.CART_ME,
      }


      // await axiosIns.get('1/contacts/threads/carts/offers', { params: { fields_info: 2 } })

      axiosIns.get('1/contacts/threads/cartDrafts/me', {params})
          .then(async res => {
            const item = res.data.data?.item
            if (Array.isArray(item?.contactThreadCartDraftProducts) && item?.contactThreadCartDraftProducts.length) {
              const productsToDelete = item.contactThreadCartDraftProducts.filter(product => product?.shopProduct.deleted || !product?.shopProduct.active).map(product => product.id)
              item.contactThreadCartDraftProducts = item.contactThreadCartDraftProducts.filter(product => !product?.shopProduct.deleted && product?.shopProduct.active)
              productsToDelete.length && await dispatch(REMOVE_PRODUCT_FROM_CART, {
                productId: productsToDelete,
                loadCart: false
              })
            }

            commit(GET_USER_CART, { ...res.data.data?.item, skipType: additionalOptions?.skipType || false })
            resolve(res.data)
          })
          .catch(err => {
            reject(err)
          })
    }),
  },
}
