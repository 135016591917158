<template>
  <table-email
    status="PLANNED"
    :update="update"
  />
</template>

<script>
import TableEmail from '../Table.vue'

export default {
  name: 'SwSketches',
  components: {
    TableEmail,
  },
  props: {
    update: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>
