var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$route.name !== 'contact')?_c('sw-filters',{attrs:{"shorts":[
      {
        title: _vm.$t('Seller'),
        type: 'avatar',
        value: function () { return _vm.filters.seller; },
        clear: function () { _vm.filters.seller = []; _vm.loadStatistics(); _vm.reloadContent() }
      },
      {
        title: _vm.$t('CommissionFor'),
        type: 'avatar',
        value: function () { return _vm.filters.provisionFor; },
        clear: function () { _vm.filters.provisionFor = []; _vm.loadStatistics(); _vm.reloadContent() }
      },
      {
        title: _vm.$t('DateOfConclusionOfContract'),
        value: function () { return _vm.filters.agreementDate; },
        clear: function () { _vm.filters.agreementDate = ''; _vm.loadStatistics(); _vm.reloadContent() }
      },
      {
        title: _vm.$t('task.Type'),
        value: function () { return _vm.$t(_vm.filters.type); },
        clear: function () { _vm.filters.type = ''; _vm.loadStatistics(); _vm.reloadContent() }
      },
      {
        title: _vm.$t('OrderDate'),
        value: function () { return _vm.filters.orderDate; },
        clear: function () { _vm.filters.orderDate = ''; _vm.loadStatistics(); _vm.reloadContent() }
      } ]}},[_c('filters',{attrs:{"filters":_vm.filters}})],1):_vm._e(),_c('b-row',{staticClass:"mt-1"},_vm._l((_vm.stats),function(stat){return _c('b-col',{key:stat.key,attrs:{"md":"4"},on:{"click":function($event){_vm.status = stat.key}}},[_c('item',{attrs:{"is-selected":_vm.status === stat.key,"is-loading":_vm.loading,"name":_vm.$t(stat.name),"tip":_vm.$t(stat.info),"val":stat.value,"val-group":stat.groupValue,"val-all":stat.allValue}})],1)}),1),_c('table-commission',{ref:"table",attrs:{"contact-thread":_vm.contactThread,"status":_vm.status,"filters":_vm.filters},on:{"load-stats":function (e) {
      this$1.search = e
      _vm.loadStatistics()
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }