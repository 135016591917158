<template>
  <b-overlay
    :show="show"
    no-wrap
    :variant="variant"
  >
    <template #overlay>
      <div
        class="alert-body d-flex flex-row"
        style="column-gap: .5rem"
      >
        <div
          class="text-primary"
          style="margin-top: -.1rem"
        >
          <slot name="icon">
            <feather-icon
              :icon="`${icon}Icon`"
              size="18"
            />
          </slot>
        </div>

        <p
          v-if="name"
          class="text-primary p-0 m-0"
        >
          {{ name }}
        </p>
      </div>
    </template>
  </b-overlay>
</template>

<script>
export default {
  props: {
    name: {
      type: [String, Object],
      required: false,
      default: '',
    },
    icon: {
      type: String,
      required: false,
      default: 'Info',
    },
    variant: {
      type: String,
      required: false,
      default: 'light-primary',
    },
    show: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>
