<template>
  <div class="text-center py-1 text-primary font-weight-bold">
    <feather-icon
      size="17"
      icon="XCircleIcon"
    />
    {{ $t('NoData') }}
  </div>
</template>

<script>
export default {
  name: 'EmptyContent',
}
</script>

<style scoped>

</style>
