<template>
  <!-- Single Card -->
  <div>
    <b-button
      v-if="checkRequiredPermissions([$roles.AUTOMATION_MODIFY])"
      variant="primary"
      class="mb-1"
      to="/settings/automations/general/new"
      size="sm"
    >
      <feather-icon
        icon="PlusIcon"
        class="mr-25"
      />
      {{ $t('Add') }}
    </b-button>

    <b-row>
      <b-col
        v-for="(automation, index) in items"
        :key="`automation_${automation.id}`"
        lg="12"
      >
        <automation-list-card
          :automation="automation"
          @delete="deleteAutomation(automation.id, index)"
          @change-active="changeAutomationActive(automation.id, automation.active, index)"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'
import axiosIns from '@/libs/axios'
import AutomationListCard from '@/views/settings/automations/components/AutomationListCard.vue'

export default {
  name: 'AutomationsList',
  components: {
    AutomationListCard,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data: () => ({
    loading: false,
    items: [],
    showAllItems: false,
  }),
  mounted() {
    this.getItems()
  },
  methods: {
    async getItems() {
      this.loading = true
      try {
        const resp = await axiosIns.get('1/automations/procedures', { params: { fields_load: this.$fields.SINGLE_AUTOMATION } })
        const data = resp.data.data.items.map(automation => {
          // eslint-disable-next-line no-underscore-dangle
          const _automation = automation
          _automation.automationProcedureConditions = _automation.automationProcedureConditions.map(condition => {
            const funnelPoints = condition.contactThreadFunnelTypePoints
            return {
              ...condition,
              contactThreadFunnelTypePoints: funnelPoints.map(point => ({
                ...point,
                name: `${point.contactThreadFunnelType.name} - ${point.name}`,
                typeId: `${point.contactThreadFunnelType.id}`,
              })),
            }
          })

          _automation.automationProcedureActions = _automation.automationProcedureActions.map(action => {
            const point = action.contactThreadFunnelTypePoint
            return {
              ...action,
              contactThreadFunnelTypePoint: point ? {
                ...point,
                name: `${point.contactThreadFunnelType.name} - ${point.name}`,
                typeId: `${point.contactThreadFunnelType.id}`,
              } : '',
            }
          })

          if (_automation.automationProcedureTrigger?.type) {
            let point = _automation.automationProcedureTrigger.contactThreadFunnelTypePointTo
            if (point) {
              _automation.automationProcedureTrigger.contactThreadFunnelTypePointTo = {
                ...point,
                name: `${point.contactThreadFunnelType.name} - ${point.name}`,
                typeId: point.contactThreadFunnelType.id,
              }
            }
            point = _automation.automationProcedureTrigger.contactThreadFunnelTypePointFrom
            if (point) {
              _automation.automationProcedureTrigger.contactThreadFunnelTypePointFrom = {
                ...point,
                name: `${point.contactThreadFunnelType.name} - ${point.name}`,
                typeId: point.contactThreadFunnelType.id,
              }
            }
            point = _automation.automationProcedureTrigger.contactThreadChecklistTypePoint
            if (point) {
              _automation.automationProcedureTrigger.contactThreadChecklistTypePoint = {
                ...point,
                name: `${point.contactThreadChecklistType.name} - ${point.name}`,
                typeId: point.contactThreadChecklistType.id,
              }
            }
          }
          return _automation
        })
        this.$set(this, 'items', data || [])
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.loading = false
      }
    },
    async deleteAutomation(id, index) {
      this.showAlert('error', this.$i18n.t('alerts.AreYouSure'))
        .then(async () => {
          try {
            if (!id) return
            await axiosIns.delete('1/automations/procedures', { data: JSON.stringify([id]) })
            this.$delete(this.items, index)
          } catch (err) {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          }
        })
    },
    async changeAutomationActive(id, currentValue, index) {
      try {
        await axiosIns.patch('1/automations/procedures', { active: !currentValue, id })
        this.$set(this.items[index], 'active', !currentValue)
        this.showToast('success', this.$i18n.t('success.contactUpdated'))
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
  },
}
</script>

<style scoped>

</style>
