<template>
  <b-row>
    <b-col
      v-for="(el, index) in modalConfig.data"
      :key="index"
      md="12"
    >
      <b-badge
        variant="primary"
        class="w-100 text-left px-1 py-50 mb-50 d-flex flex-wrap align-items-center justify-content-between"
        style="column-gap: .34rem; row-gap: .34rem;"
      >
        <div
          class="d-flex align-items-center justify-content-start"
          style="column-gap: .34rem"
        >
          <avatar
            :user="el.event.extendedProps.assignedUser"
            :show-name="false"
            show-tooltip
          />

          {{ el.event.title | textIsland(28) }}
        </div>

        <b-button
          v-b-tooltip.hover.v-dark
          size="sm"
          variant="light"
          class="p-25 btn-icon d-flex align-items-center"
          style="column-gap: .34rem"
          :title="`${el.event.extendedProps.contactThread.contact.firstName} ${el.event.extendedProps.contactThread.contact.lastName}`"
          @click="() => {
            $router.push({ path: `contact/${el.event.extendedProps.contactThread.contact.id}/thread/${el.event.extendedProps.contactThread.id}` })

            closeModal()
          }"
        >
          <feather-icon icon="UserIcon" />
        </b-button>
      </b-badge>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from 'vuex'
import { CLOSE_MODAL } from '@/@constants/mutations'
import { VBTooltip } from 'bootstrap-vue'

export default {
  computed: {
    ...mapGetters({
      modalConfig: 'modal/getModalState',
    }),
  },

  directives: {
    'b-tooltip': VBTooltip,
  },

  methods: {
    closeModal() {
      this.$store.commit(`modal/${CLOSE_MODAL}`)
    },
  },
}
</script>
