import { render, staticRenderFns } from "./DropdownItemLabel.vue?vue&type=template&id=e166ac44&scoped=true"
import script from "./DropdownItemLabel.vue?vue&type=script&lang=js"
export * from "./DropdownItemLabel.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e166ac44",
  null
  
)

export default component.exports