import {
  CREATE_NEWS, GET_NEWS, GET_NEWS_ALL, REMOVE_NEWS, TEST_NEWS, UPDATE_NEWS,
} from '@/@constants/mutations'
// eslint-disable-next-line import/no-cycle
import axiosIns from '@/libs/axios'
import { NEWS } from '@/@constants/fields'
import moment from 'moment'

const getDate = date => date.dateRange()

const STATUS = {
  SKETCH: 'SKETCH',
  PUBLISHED: 'PUBLISHED',
}

export default {
  namespaced: true,
  actions: {
    [GET_NEWS]: (_, { id }) => new Promise((resolve, reject) => {
      const params = {}
      params.fields_load = NEWS

      axiosIns.get(`1/news/posts/${id}`, { params })
        .then(res => resolve(res.data.data.item))
        .catch(err => reject(err))
    }),

    [REMOVE_NEWS]: (_, id) => new Promise((resolve, reject) => {
      axiosIns.delete('1/news/posts', { data: JSON.stringify([id]) })
        .then(res => resolve(res))
        .catch(err => reject(err))
    }),

    [GET_NEWS_ALL]: (_, payload) => new Promise((resolve, reject) => {
      const params = {}
      params.fields_load = NEWS
      params.orderBy = 'id.asc'
      params.eq_status = STATUS.PUBLISHED

      const { filters, pagination, search } = payload

      if (pagination) {
        params.page = pagination.currentPage
        params.limit = pagination.perPage
      }

      if (search && search.length) {
        const searchString = search.replaceAll(' ', '%')
        params.search = `%${searchString}%`
        params.search_fields = 'subject'
      }

      if (filters) {
        if (filters?.createdAt) {
          const value = getDate(filters.createdAt)

          // eslint-disable-next-line prefer-destructuring
          if (value[0]) params.gte_createdAt = value[0]
          // eslint-disable-next-line prefer-destructuring
          params.lte_createdAt = value[1] || moment(value[0])
            .add(1, 'day')
            .format('YYYY-MM-DD')
        }

        if (filters?.authors?.length) params['eqArr_author-id'] = JSON.stringify(filters.authors.map(author => author.id))

        if (filters.showSketchesOnly) params.eq_status = STATUS.SKETCH

        if (filters.max) {
          params.eq_status = STATUS.PUBLISHED
          params.limt = String(filters.max)
        }
      }

      axiosIns.get('1/news/posts', { params })
        .then(res => resolve(res.data.data.items.reverse()))
        .catch(err => reject(err))
    }),

    [CREATE_NEWS]: (_, payload) => new Promise((resolve, reject) => {
      axiosIns.post('1/news/posts', payload)
        .then(res => resolve(res.data.data.items[0].id))
        .catch(err => reject(err))
    }),

    [UPDATE_NEWS]: (_, payload) => new Promise((resolve, reject) => {
      axiosIns.patch('1/news/posts', payload)
        .then(res => resolve(res.data.data.items[0].id))
        .catch(err => reject(err))
    }),

    [TEST_NEWS]: (_, level = 0) => new Promise((resolve, reject) => {
      const params = {}
      params.fields_info = level

      axiosIns.get('1/news/posts', { params })
        .then(res => resolve(res.data.data))
        .catch(err => reject(err))
    }),
  },
}
