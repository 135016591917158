// eslint-disable-next-line import/no-cycle
import axiosIns from '@/libs/axios'
import { GET_USER_HISTORY } from '@/@constants/mutations'
import * as fields from '@/@constants/fields'

export default {
  namespaced: true,
  actions: {
    // eslint-disable-next-line no-unused-vars
    [GET_USER_HISTORY]: ({ commit }, payload) => new Promise((resolve, reject) => {
      const {
        userId, page, limit,
      } = payload

      let params = {}
      if (page || limit) {
        params = {
          page,
          limit,
        }
      }

      params.fields_load = fields.AUTH_LOGS
      params.orderBy = 'createdAt.desc'

      axiosIns.get(`1/users/${userId}/authLogs`, { params })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
  },
}
