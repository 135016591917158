<template>
  <expand-window
    :title="$t('FullWindow')"
  >
    <projects :thread="thread" />
  </expand-window>
</template>

<script>
import ExpandWindow from '@core/components/@sw/ExpandWindow.vue'
import Projects from '../../projects/projects/ListContact.vue'

export default {
  components: { ExpandWindow, Projects },
  props: {
    thread: {
      default: null,
    },
  },
}
</script>
