var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('sw-table',{staticClass:"position-relative overflow-hidden",attrs:{"table-id":"302b02bde5db48209473323ce6c1774a","show-columns":"","show-search":"","fields":_vm.fields,"pagination":_vm.pagination},on:{"change-pagination":function($event){return Object.assign(_vm.pagination, $event)},"change-search-query":function($event){_vm.search = $event},"reload-content":_vm.loadData,"set-fields":function($event){_vm.fields = $event}},scopedSlots:_vm._u([{key:"table",fn:function(){return [_c('b-table',{staticClass:"offer-table",attrs:{"items":_vm.items,"fields":_vm.fields.filter(function (e) { return e.visible; }),"sort-by":_vm.sorter.sortBy,"no-local-sorting":true,"sort-desc":_vm.sorter.sortDesc,"striped":"","responsive":"","busy":_vm.isLoading,"show-empty":""},on:{"update:sortBy":function($event){return _vm.$set(_vm.sorter, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.sorter, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.sorter, "sortDesc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.sorter, "sortDesc", $event)},"sort-changed":function($event){Object.assign(_vm.sorter, $event); _vm.loadData()}},scopedSlots:_vm._u([{key:"head()",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(("" + label)))+" ")]}},{key:"head(select)",fn:function(ref){
var label = ref.label;
return [_c('b-form-checkbox',{attrs:{"variant":"primary"},on:{"change":_vm.selectAllCompanies},model:{value:(_vm.selectAll),callback:function ($$v) {_vm.selectAll=$$v},expression:"selectAll"}})]}},{key:"cell(select)",fn:function(ref){
var item = ref.item;
return [_c('b-form-checkbox',{attrs:{"value":item.id,"variant":"primary"},model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})]}},{key:"cell(id)",fn:function(ref){
var index = ref.index;
return [_vm._v(" #"+_vm._s(_vm.getOrderNo(index, _vm.pagination, _vm.sorter))+" ")]}},{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"text-primary cursor-pointer",attrs:{"title":item.name},on:{"click":function($event){return _vm.$router.push({ name: 'organizationsPreview', params: { 'id': item.id } })}}},[_vm._v(" "+_vm._s(_vm._f("truncate")(item.name,50))+" ")])]}},{key:"cell(vat)",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [(item.vatPayer)?_c('span',[_vm._v(" "+_vm._s(value)+"% ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('Customizer.Animations.None'))+" ")])]}},{key:"cell(address)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.getAddress(item))+" ")])]}},{key:"cell(addressCorrespondence)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.getAddress(item, 'correspondence'))+" ")])]}},{key:"cell(organizationClassificationCodes)",fn:function(ref){
var value = ref.value;
return [(value && value.length)?_c('div',{staticClass:"text-wrap",staticStyle:{"width":"200px"}},[_vm._v(" "+_vm._s(value.map(function (e) { return e.code; }).join(', '))+" ")]):_c('feather-icon',{attrs:{"icon":"MinusIcon"}})]}},{key:"cell(action)",fn:function(ref){
var item = ref.item;
return [_c('b-dropdown',{attrs:{"id":"dropdown-dropleft","dropleft":"","no-caret":"","variant":"flat"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{attrs:{"icon":"MoreVerticalIcon"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.$router.push({ name: 'organizationsEdit', params: { 'id': item.id } })}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"EditIcon"}}),_vm._v(" "+_vm._s(_vm.$t('Edit'))+" ")],1),(_vm.checkRequiredPermissions([_vm.$roles.ORGANIZATION_DELETE]))?_c('b-dropdown-item',{on:{"click":function($event){return _vm.$emit('delete-item', item.id)}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"TrashIcon"}}),_vm._v(" "+_vm._s(_vm.$t('Delete'))+" ")],1):_vm._e()],1)]}},{key:"cell()",fn:function(row){return [_c('table-default-cell',{attrs:{"field":row.field,"value":row.value}})]}},{key:"empty",fn:function(){return [_c('empty-content')]},proxy:true},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[_c('b-spinner',{staticClass:"align-middle",attrs:{"variant":"primary"}})],1)]},proxy:true}])})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }