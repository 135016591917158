<template>
  <div>
    <b-card
      class="mb-1"
      style="min-height: 14rem"
    >
      <template #header>
        <h4 class="mb-0">
          {{ $t('calculators.SummarySection') }}
        </h4>
      </template>

      <!--    START:Alert    -->
      <b-alert
        variant="primary"
        :show="true"
      >
        <div
          class="alert-body d-flex align-items-center"
          style="column-gap: .5rem; row-gap: .34rem;"
        >
          <feather-icon
            icon="InfoIcon"
            size="20"
            class="mt-25"
            style="min-width: 1.5rem"
          />

          {{ $t('calculators.TheSummaryAlert') }}
        </div>
      </b-alert>
      <!--    END:Alert    -->

      <!--    START:Fields    -->
      <b-list-group
        v-if="form.list.length"
        class="mb-75"
        flush
      >
        <b-list-group-item
          v-for="(field, i) in form.list"
          :key="i"
          class="mb-1 py-1"
          style="background: var(--bg-shopItem-lighten-3); border: 0; border-radius: 6px;"
        >
          <!--    START:Field Name & Delete    -->
          <validation-provider
            v-slot="{ errors }"
            :name="$t('EnterAFieldName')"
            rules="required"
          >
            <b-form-group :label="$t('EnterAFieldName')">
              <b-input-group>
                <b-form-input
                  v-model="field.name"
                  :label="$t('EnterAFieldName')"
                  :placeholder="$t('EnterAFieldName')"
                />

                <b-input-group-append>
                  <b-button
                    variant="danger"
                    class="btn-icon px-75"
                    @click="
                      field.formula.length
                        ? showAlert('warning', $i18n.t('alerts.AreYouSure'), $i18n.t('alerts.WontbeAble'))
                          .then(() => form.list.splice(i, 1))
                          .catch(() => {})
                        : form.list.splice(i, 1)
                    "
                  >
                    <sw-icon icon="TrashIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <!--    END:Field Name & Delete    -->

          <h5 class="mb-25">
            {{ $t('EnterTheFormulaForTheSum') }}
          </h5>

          <!--    START:Formula    -->
          <draggable
            :list="field.formula"
            tag="app-collapse"
            :group="`formulas-${i}`"
            class="d-flex flex-wrap align-items-center collapse-draggable"
            style="column-gap: .34rem; row-gap: .34rem;"
            draggable=".item"
          >
            <b-badge
              v-for="(el, j) in field.formula"
              :key="`formula-${i}-el-${j}`"
              v-b-tooltip.hover.v-dark
              pill
              :variant="`${el.type === 'Math' ? 'light-' : ''}dark`"
              class="item cursor-move first-ml-0 last-mr-0 hover:opacity-0.64"
              :class="{ 'ml-75': el.name === '(', 'mr-75': el.name === ')' }"
              :title="$t('Delete')"
              @click="field.formula.splice(j, 1)"
            >
              {{ el.name }}
            </b-badge>

            <template #footer>
              <b-button
                v-b-toggle.field-list
                v-b-tooltip.hover.v-primary
                variant="primary"
                size="sm"
                class="btn-icon p-25"
                :class="{ 'ml-50': field.formula.length }"
                :title="$t('AddField')"
                @click="fieldList = i"
              >
                <sw-icon icon="PlusIcon" />
              </b-button>
            </template>
          </draggable>
          <!--    END:Formula    -->
        </b-list-group-item>
      </b-list-group>
      <!--    END:Fields    -->

      <!--    START:No Fields    -->
      <empty-content v-else />
      <!--    END:No Fields    -->

      <!--    START:Add New Field    -->
      <b-button
        variant="primary"
        class="w-100 mt-25"
        @click="form.list.push(JSON.parse(JSON.stringify(newItem)))"
      >
        <feather-icon icon="PlusIcon" />

        {{ $t('AddValue') }}
      </b-button>
      <!--    END:Add New Field    -->

      <b-form-group class="mt-2">
        <div
          class="d-flex flex-row align-items-center"
          style="column-gap: .34rem"
        >
          <b-form-checkbox v-model="form.markAsFinalValue">
            {{ $t('MarkAsFinalValue') }}
          </b-form-checkbox>

          <feather-icon
            v-b-tooltip.hover.v-primary
            icon="InfoIcon"
            :title="$t('ThefinalvalueisthegrosspricethatwillbeusedintheofferOrderoragreementbasket')"
            class="text-primary"
          />
        </div>
      </b-form-group>
    </b-card>

    <!--    START:Field list    -->
    <b-sidebar
      id="field-list"
      bg-variant="white"
      right
      backdrop
      shadow
    >
      <!-- Header -->
      <div class="px-2 pt-1">
        <b-form-group>
          <b-input-group>
            <b-form-input
              v-model="searchField"
              :label="$t('Search')"
              :placeholder="$t('Search')"
            />
          </b-input-group>
        </b-form-group>
      </div>

      <b-list-group flush>
        <b-list-group-item
          v-for="(section, i) in filterFormulaElements()"
          :key="i"
        >
          <span class="h">
            {{ section.name }}
          </span>

          <b-list-group
            class="d-flex flex-wrap flex-row mt-25"
            style="column-gap: .34rem; row-gap: .34rem;"
            flush
          >
            <b-badge
              v-for="(el, j) in section.elements"
              :key="j"
              pill
              :variant="`${el.type === 'Math' ? 'light-' : ''}dark`"
              class="cursor-pointer first-ml-0 last-mr-0 hover:opacity-0.64"
              @click="form.list[fieldList].formula.push({ ...el })"
            >
              {{ el.name }}
            </b-badge>
          </b-list-group>
        </b-list-group-item>
      </b-list-group>
    </b-sidebar>
    <!--    END:Field list    -->
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import {
  BInputGroupAppend, BSidebar, VBToggle, VBTooltip,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'

export default {
  components: {
    draggable,
    BInputGroupAppend,
    ValidationProvider,
    BSidebar,
  },

  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
  },

  data: () => ({
    form: {
      list: [],
    },

    newItem: {
      name: '',
      formula: [],
    },

    formulaElements: [
      {
        name: 'Math',
        elements: [
          { name: '(', type: 'Math' },
          { name: ')', type: 'Math' },
          { name: '+', type: 'Math' },
          { name: '-', type: 'Math' },
          { name: '*', type: 'Math' },
          { name: '/', type: 'Math' },
        ],
      },
      {
        name: 'Section 1',
        elements: [
          { name: 'Field 1', type: 'Field' },
          { name: 'Field 2', type: 'Field' },
          { name: 'Field 3', type: 'Field' },
        ],
      },
    ],

    fieldList: '',
    searchField: '',
  }),

  methods: {
    filterFormulaElements() {
      const { formulaElements, searchField } = this

      return formulaElements.filter(({ name, elements }) => name.toLowerCase().includes(searchField.toLowerCase())
            || elements.some(el => el.name.toLowerCase().includes(searchField.toLowerCase())))
    },
  },
}
</script>
