<!--eslint-disable-->

<template>
  <b-row class="d-flex flex-row-reverse">
    <b-col
      sm="12"
      md="12"
      lg="12"
    >
      <!--    Button: New Contract    -->
      <b-button
        class="mb-50"
        variant="primary"
        size="sm"
        :to="{ name: 'contact-generator', params: { contactId: 'new' } }"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-25"
        />
        {{ $t('Add') }}
      </b-button>
      <!--    Categories View    -->
      <div>
        <b-card class="myTableOffer">
          <sw-table
            table-id="23c9f393c8984f119a8776c50e4d08af"
            :pagination="pagination"
            @change-pagination="Object.assign(pagination, $event)"
            @reload-content="getContracts"
          >
            <template #table>
              <b-table
                :items="contactsGeneratorShortcuts"
                :fields="fields"
                :busy="loading"
                striped
                responsive
                show-empty
              >
                <!--    Table Head    -->
                <template #head()="item">
                  <span class="text-nowrap">
                    {{ $tc(item.field.label) | truncate(34, '...') }}
                  </span>
                </template>

                <!--    Name    -->
                <template #cell(name)="{ item }">
                    <sw-icon :icon="item.icon" class="text-primary" />
                  <router-link
                    v-b-tooltip.hover.left
                    :to="{ name: 'contact-generator', params: { contactId: item.id } }"
                    :title="item.name"
                    @click="$emit('openEditModal', item)"
                  >
                    {{ item.name | truncate(64) }}
                  </router-link>
                </template>

                <!--    Status    -->
                <template #cell(status)="{ value }">
                  <!--    Sketches    -->
                  <b-badge variant="primary">
                    {{ $t('contractGenerator.status.' + value) }}
                  </b-badge>
                </template>

                  <template #cell(shortcutSections)="{ value }">
                  <!--    Sketches    -->
                  <b-badge variant="primary">
                    {{ value.length }}
                  </b-badge>
                </template>

                <!--    Creator    -->
                <template #cell(createdBy)="{ value }">
                  <avatar
                    :user="value"
                    :size="28"
                  />
                </template>

                <!--    Table Action    -->
                <template #cell(action)="{ item, index }">
                  <b-dropdown
                    variant="link"
                    class="dropdown-icon-wrapper text-decoration-none"
                    no-caret
                  >
                    <template
                      #button-content
                      class="sr-only"
                    >
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="align-middle"
                      />
                    </template>
                    <!--    Action: Edit    -->
                    <b-dropdown-item :to="{ name: 'contact-generator', params: { contactId: item.id } }">
                      <span style="color: #6e6b7b">{{ $t('Edit') }}</span>
                    </b-dropdown-item>
                    <!--    Action: Edit    -->
                    <b-dropdown-item @click="deleteSection(item, index)">
                      {{ $t('Delete') }}
                    </b-dropdown-item>
                  </b-dropdown>
                </template>

                <!--    Other    -->
                  <!--    Other    -->
                  <template #cell()="row">
                      <table-default-cell
                              :field="row.field"
                              :value="row.value"
                      />
                  </template>

                  <!--      Empty      -->
                  <template #empty>
                      <empty-content />
                  </template>

                  <!--      Table Busy      -->
                  <template #table-busy>
                      <div class="text-center text-danger my-2">
                          <b-spinner
                                  class="align-middle"
                                  variant="primary"
                          />
                      </div>
                  </template>
              </b-table>
            </template>
          </sw-table>
        </b-card>
      </div>
    </b-col>
  </b-row>
</template>

<script>
/* eslint-disable */

import { BTable, VBTooltip } from 'bootstrap-vue'
import { DELETE_CONTRACT_TEMPLATE, GET_CONTRACTS_TEMPLATES } from '@/@constants/mutations'
import * as fields from "@/@constants/fields";
import axiosIns from "@/libs/axios";

export default {
  components: {
    BTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data: () => ({
    fields: [
      { key: 'name', label: 'Name' },
      { key: 'shortcutSections', label: 'Sections' },
      { key: 'createdBy', label: 'CreatedBy' },
      { key: 'createdAt', label: 'offer.CreatedTime' },
      { key: 'action', label: 'Action' },
    ],

    loading: false,
    sorter: {
      sortBy: 'createdAt',
      sortDesc: true,
    },
    pagination: {
      perPage: 25,
      currentPage: 1,
      totalRecords: 100,
    },
    contactsGeneratorShortcuts: [],
  }),
  mounted() {
    this.getContracts()
  },
  methods: {
      async deleteSection(section, index) {
          const confirmed = await this.showAlert('warning', this.$i18n.t('alerts.AreYouSure'))
          if (!confirmed) return

          try {
              if (section.id) {
                  await axiosIns.delete(`1/shortcuts`, { data: JSON.stringify([section.id]) })
              }

              this.showToast('success', this.$i18n.t('RemovedSuccessfully'))
              this.$delete(this.contactsGeneratorShortcuts, index)
          } catch (err) {
              this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          }
      },
    async getContracts() {
        this.loading = true
        try {
            const params = {
                orderBy: 'id.desc',
                fields_load: 'SHORTCUTS',
            }

            if (this.pagination) {
                params.page = this.pagination.currentPage
                params.limit = this.pagination.perPage
            }

            const res = await axiosIns.get('1/shortcuts', { params })

            this.contactsGeneratorShortcuts = res.data.data.items
            this.pagination.totalRecords = res.data.data.totalItemCount
        } catch (err) {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        } finally {
            this.loading = false
        }
    },
    async deleteTemplate(id) {
        const confirmed = await this.showAlert('warning', this.$i18n.t('alerts.AreYouSure'))
        if (!confirmed) return

        try {
            await this.$store.dispatch(`contractTemplates/${DELETE_CONTRACT_TEMPLATE}`, id)
            this.showToast('success', this.$i18n.t('RemovedSuccessfully'))
            await this.getContracts()
        } catch (err) {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        }

    },
  },
}
</script>

<style lang="scss" scoped>
// .form-group { margin: 0 }

.stickyFilterBar {
  position: sticky;

  top: 7rem;
}
</style>
