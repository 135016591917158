var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.isComponent ? 'div' : 'b-modal',{tag:"component",attrs:{"id":"compose-mail","visible":_vm.shallShowEmailComposeModal,"title":"Compose Email","footer-class":"d-flex justify-content-between","body-class":"p-0","size":"lg","static":""},on:{"change":function (val) { return _vm.$emit('update:shall-show-email-compose-modal', val); }},scopedSlots:_vm._u([(!_vm.isComponent)?{key:"modal-header",fn:function(){return [_c('h5',{staticClass:"modal-title"},[_vm._v(" "+_vm._s(_vm.$t('Create'))+" ")]),_c('div',{staticClass:"modal-actions"},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"MinusIcon"},on:{"click":_vm.saveAsDraft}}),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon"},on:{"click":_vm.discardEmail}})],1)]},proxy:true}:null,(!_vm.isComponent)?{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"row-gap":".5rem","column-gap":".5rem"}},[_c('b-button',{attrs:{"variant":"primary","disabled":!_vm.fromIamp
            || !_vm.composeData.toAddresses
            || !_vm.composeData.toAddresses.length
            || !_vm.composeData.subject
            || !_vm.composeData.subject.length
            || !_vm.composeData.contentHtml
            || !_vm.composeData.contentHtml.length
            || _vm.isLoading},on:{"click":function($event){return _vm.sendEmail(null)}}},[_vm._v(" "+_vm._s(_vm.$t('Send'))+" ")]),_c('drag-drop-upload',{attrs:{"default-value":_vm.composeData.files,"show-count":"","size":"sm","type":"btn","show-files":false},on:{"onChangeFiles":_vm.changeFiles}})],1)]},proxy:true}:null],null,true)},[_c('b-form',[_c('div',{staticClass:"compose-mail-form-field"},[_c('label',{staticClass:"form-label text-nowrap",attrs:{"for":"email-to"}},[_vm._v(" "+_vm._s(_vm.$t('From'))+": ")]),_c('sw-select',{staticClass:"mb-n25"},[_c('v-select',{attrs:{"id":"offer-thread-1","options":_vm.imapList,"label":"name","filterable":false},on:{"option:selected":_vm.selectImap},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('NoData'))+" ")]},proxy:true}]),model:{value:(_vm.fromIamp),callback:function ($$v) {_vm.fromIamp=$$v},expression:"fromIamp"}})],1)],1),_c('div',{staticClass:"compose-mail-form-field"},[_c('label',{staticClass:"form-label text-nowrap",attrs:{"for":"email-to"}},[_vm._v(" "+_vm._s(_vm.$t('To'))+": ")]),_c('sw-select',{staticClass:"mb-n25"},[_c('v-select',{attrs:{"id":"offer-thread-1","options":_vm.contactList,"label":"email","taggable":"","multiple":"","filterable":false,"selectable":function (c) {
            if (/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(c.email))
              { return true }

            return false
          },"clear-search-on-blur":function () { return _vm.handleBlur('toAddresses'); },"create-option":_vm.createOption},on:{"search":_vm.loadContacts},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
          var search = ref.search;
return [(search.length)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('NoData'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('TypeToSearch'))+" ")])]}},{key:"option",fn:function(ref){
          var avatar = ref.avatar;
          var firstName = ref.firstName;
          var lastName = ref.lastName;
          var type = ref.type;
          var email = ref.email;
return [(type && firstName)?_c('span',[_c('b-avatar',{attrs:{"size":"sm","src":avatar,"text":(firstName || '').slice(0,1).toUpperCase() + (lastName || '').slice(0,1).toUpperCase()}}),_c('span',{staticClass:"ml-50 pt-25"},[_vm._v(_vm._s(_vm._f("truncate")(firstName + ' ' + lastName,26))+" "),(type === 'c')?_c('span',[_vm._v("- "+_vm._s(_vm.$t('Contact')))]):_vm._e(),_c('div',[_c('small',[_vm._v(_vm._s(email))])])])],1):_vm._e()]}},{key:"selected-option",fn:function(ref){
          var avatar = ref.avatar;
          var firstName = ref.firstName;
          var lastName = ref.lastName;
          var type = ref.type;
          var email = ref.email;
return [(type && firstName)?_c('span',[_c('b-avatar',{staticClass:"border border-white",attrs:{"size":"sm","src":avatar,"text":(firstName || '').slice(0,1).toUpperCase() + (lastName || '').slice(0,1).toUpperCase()}}),_c('span',{staticClass:"ml-50 pt-25"},[_vm._v(" "+_vm._s(_vm._f("truncate")(firstName + ' ' + lastName,26))+" "),(type === 'c')?_c('span',[_vm._v(" - "+_vm._s(_vm.$t('Contact')))]):_vm._e(),_c('div',[_c('small',[_vm._v(_vm._s(email))])])])],1):_vm._e()]}}]),model:{value:(_vm.composeData.toAddresses),callback:function ($$v) {_vm.$set(_vm.composeData, "toAddresses", $$v)},expression:"composeData.toAddresses"}}),(_vm.search.length)?_c('small',{staticClass:"text-danger"}):_vm._e()],1),_c('span',{staticClass:"cursor-pointer text-nowrap",on:{"click":function($event){_vm.showCcField=!_vm.showCcField}}},[_vm._v("Cc")]),_c('span',{staticClass:"ml-1 cursor-pointer text-nowrap",on:{"click":function($event){_vm.showBccField=!_vm.showBccField}}},[_vm._v("Bcc")])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showCcField),expression:"showCcField"}],staticClass:"compose-mail-form-field"},[_c('label',{staticClass:"form-label text-nowrap",attrs:{"for":"email-cc"}},[_vm._v(" CC: ")]),_c('sw-select',{staticClass:"mb-n25"},[_c('v-select',{attrs:{"id":"offer-thread-1","options":_vm.contactList,"label":"email","taggable":"","multiple":"","filterable":false,"selectable":function (c) {
            if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(c.email))
              { return true }

            return false
          },"clear-search-on-blur":function () { return _vm.handleBlur('ccAddresses'); },"create-option":_vm.createOption},on:{"search":_vm.loadContacts},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
          var search = ref.search;
return [(search.length)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('NoData'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('TypeToSearch'))+" ")])]}}]),model:{value:(_vm.composeData.ccAddresses),callback:function ($$v) {_vm.$set(_vm.composeData, "ccAddresses", $$v)},expression:"composeData.ccAddresses"}})],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showBccField),expression:"showBccField"}],staticClass:"compose-mail-form-field"},[_c('label',{staticClass:"form-label text-nowrap",attrs:{"for":"email-bcc"}},[_vm._v(" Bcc: ")]),_c('sw-select',{staticClass:"mb-n25"},[_c('v-select',{attrs:{"id":"offer-thread-1","options":_vm.contactList,"label":"email","taggable":"","multiple":"","filterable":false,"selectable":function (c) {
            if (c.email || /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(c))
              { return true }

            return false
          },"clear-search-on-blur":function () { return _vm.handleBlur('bccAddresses'); },"create-option":_vm.createOption},on:{"search":_vm.loadContacts},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
          var search = ref.search;
return [(search.length)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('NoData'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('TypeToSearch'))+" ")])]}}]),model:{value:(_vm.composeData.bccAddresses),callback:function ($$v) {_vm.$set(_vm.composeData, "bccAddresses", $$v)},expression:"composeData.bccAddresses"}})],1)],1),_c('div',{staticClass:"compose-mail-form-field"},[_c('label',{staticClass:"form-label text-nowrap",attrs:{"for":"email-to"}},[_vm._v(" "+_vm._s(_vm.$t('Thread'))+": ")]),_c('sw-select',{staticClass:"mb-n25"},[_c('v-select',{attrs:{"id":"offer-thread-1","options":_vm.threadList,"label":"name","filterable":false},on:{"search":_vm.loadThreads},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
          var search = ref.search;
return [(search.length)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('NoData'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('TypeToSearch'))+" ")])]}},{key:"option",fn:function(ref){
          var name = ref.name;
          var contact = ref.contact;
return [_c('div',{staticClass:"d-flex align-items-center"},[(contact)?_c('avatar',{staticClass:"mr-25",attrs:{"user":contact}}):_vm._e(),_vm._v(" - "+_vm._s(name)+" ")],1)]}},{key:"selected-option",fn:function(ref){
          var name = ref.name;
          var contact = ref.contact;
return [_c('div',{staticClass:"d-flex"},[(contact)?_c('avatar',{staticClass:"mr-25",attrs:{"user":contact}}):_vm._e(),_vm._v(" – "+_vm._s(name)+" ")],1)]}}]),model:{value:(_vm.composeData.contactThread),callback:function ($$v) {_vm.$set(_vm.composeData, "contactThread", $$v)},expression:"composeData.contactThread"}})],1)],1),_c('div',{staticClass:"compose-mail-form-field"},[_c('label',{staticClass:"text-nowrap",attrs:{"for":"email-subject"}},[_vm._v(" "+_vm._s(_vm.$t('Subject'))+": ")]),_c('b-form-input',{attrs:{"id":"email-subject"},model:{value:(_vm.composeData.subject),callback:function ($$v) {_vm.$set(_vm.composeData, "subject", $$v)},expression:"composeData.subject"}})],1),_c('div',{staticClass:"message-editor"},[_c('quill-editor',{key:"1",ref:"content-quill",staticClass:"quil-content-small",attrs:{"id":"quil-content","options":_vm.editorOption},model:{value:(_vm.composeData.contentHtml),callback:function ($$v) {_vm.$set(_vm.composeData, "contentHtml", $$v)},expression:"composeData.contentHtml"}}),_c('div',{staticClass:"d-flex border-bottom-0",attrs:{"id":"quill-toolbar"}},[_c('button',{staticClass:"ql-bold"}),_c('button',{staticClass:"ql-italic"}),_c('button',{staticClass:"ql-underline"}),_c('button',{staticClass:"ql-align"}),_c('button',{staticClass:"ql-link"}),_c('button',{staticClass:"ql-image"})])],1),(_vm.composeData.footer)?_c('b-form-group',{staticClass:"px-1",staticStyle:{"color":"#b4b7bd"},attrs:{"label":_vm.$t('Footer')}},[_c('quill-editor',{key:"2",staticClass:"quil-content-small",attrs:{"id":"quil-content","options":_vm.editorOption,"disabled":""},model:{value:(_vm.composeData.footer),callback:function ($$v) {_vm.$set(_vm.composeData, "footer", $$v)},expression:"composeData.footer"}})],1):_vm._e()],1),(_vm.isComponent)?_c('div',{staticClass:"d-flex align-items-center d-flex p-1 mt-1 border-top"},[_c('b-button',{attrs:{"variant":"primary","disabled":!_vm.fromIamp
          || !_vm.composeData.toAddresses
          || !_vm.composeData.toAddresses.length
          || !_vm.composeData.subject
          || !_vm.composeData.subject.length
          || !_vm.composeData.contentHtml
          || !_vm.composeData.contentHtml.length
          || _vm.isLoading},on:{"click":function($event){return _vm.sendEmail(null)}}},[_vm._v(" "+_vm._s(_vm.$t('Send'))+" ")]),_c('drag-drop-upload',{staticClass:"ml-25",attrs:{"default-value":_vm.composeData.files,"show-count":"","size":"sm","type":"btn","show-files":false},on:{"onChangeFiles":_vm.changeFiles}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }