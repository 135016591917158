<template>
  <span>
    <b-button
      variant="primary"
      size="sm"
      :disabled="exportLoading"
      @click="showModal = true"
    >
      <span v-if="!exportLoading"><feather-icon icon="DownloadIcon" />Export</span>
      <span v-else>
        <b-spinner
          small
          variant="light"
        />
      </span>
    </b-button>

    <b-modal v-model="showModal"
             hide-footer
             @hide="filters.assignedToUser = ''; filters.dateRange = ''"
    >
      <b-form-group>
        <b-form-radio-group
          v-model="activeFilter"
          :options="filterCreatedAtOptions"
          buttons
          button-variant="outline-primary"
          size="sm"
          class="flex-wrap"
          style="row-gap: .25rem"
          @input="setDate"
        />
      </b-form-group>
      <sw-select :name="$t('DataRange')">
        <sw-select-fpr
          :is-empty="filters.dateRange"
          @clear="filters.dateRange = ''"
        >
          <flat-pickr
            v-model="filters.dateRange"
            class="form-control"
            :config="{ mode: 'range', locale: getCalendarLocale($i18n.locale) }"
          />
        </sw-select-fpr>
      </sw-select>

      <b-button
        variant="primary"
        size="sm"
        :disabled="!filters.dateRange"
        @click="processExport"
      >
        <span><feather-icon icon="DownloadIcon" />Export</span>
      </b-button>
    </b-modal>
  </span>
</template>

<script>
import moment from 'moment'
import flatPickr from 'vue-flatpickr-component'
import { BFormRadioGroup } from 'bootstrap-vue'

export default {
  name: 'ExportModal',
  components: {
    BFormRadioGroup,
    flatPickr,
  },
  props: {
    exportLoading: {
      default: false,
      required: true,
      type: Boolean,
    },
  },
  emits: ['export'],
  data: () => ({
    showModal: false,
    filters: {
      dateRange: '',
      assignedToUser: '',
    },
    activeFilter: '',
    filterCreatedAtOptions: [],
  }),
  mounted() {
    this.filterCreatedAtOptions = [
      { text: this.$t('Last7Days'), value: 'last7Days' },
      { text: this.$t('ThisMonth'), value: 'thisMonth' },
      { text: this.$t('LastMonth'), value: 'lastMonth' },
    ]
  },
  methods: {
    moment,
    setDate(dateValue) {
      if (dateValue === 'last7Days') {
        const startAt = moment().subtract(7, 'days').format('YYYY-MM-DD hh:mm')

        this.filters.dateRange = [startAt]
      }

      if (dateValue === 'thisMonth') {
        const startAt = moment().startOf('month').format('YYYY-MM-DD hh:mm')
        const endAt = moment().endOf('month').format('YYYY-MM-DD hh:mm')

        this.filters.dateRange = [startAt, endAt]
      }

      if (dateValue === 'lastMonth') {
        const startAt = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD hh:mm')
        const endAt = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD hh:mm')

        this.filters.dateRange = [startAt, endAt]
      }

      if (dateValue === 'last6Months') {
        const startAt = moment().subtract(6, 'months').startOf('month').format('YYYY-MM-DD hh:mm')
        const endAt = moment().format('YYYY-MM-DD hh:mm')

        this.filters.dateRange = [startAt, endAt]
      }

      if (dateValue === null) this.filters.dateRange = ''
    },
    processExport() {
      const splitedDate = [...new Set(this.filters.dateRange.dateRange())]
      const date = [`${splitedDate[0]} 00:00:00`]
      if (splitedDate[1]) {
        date.push(`${splitedDate[1]} 23:59:59`)
      }

      this.$emit('export', { date })
      this.showModal = false
      this.filters = {
        dateRange: '',
        assignedToUser: '',
      }
    },
  },
}
</script>

<style scoped>

</style>
