<template>
  <div>
    <div class="d-flex justify-content-between">
      <h4>{{ $t('Folders') }}</h4>
    </div>
    <div class="pt-1">
      <b-table-simple
        hover
        responsive
        striped
      >
        <b-thead head-variant="light">
          <b-tr>
            <b-th>{{ $t('FolderName') }}</b-th>
            <b-th>{{ $t('Action') }}</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="(folder, folderIndex) in folders"
            :key="folder.name"
            :class="{ 'd-none': !checkRequiredPermissions(folder.requiredPermissions) }"
            @click="selectFolderForAction(folder.id)"
          >
            <b-td>{{ $t(`${folder.name}`) }}</b-td>
            <!--            <b-td class="d-flex align-items-center">-->
            <!--              <b-avatar-group size="32px">-->
            <!--                <b-avatar-->
            <!--                  v-for="n in 2 * a + 1"-->
            <!--                  :key="n"-->
            <!--                  v-b-tooltip.hover-->
            <!--                  class="pull-up"-->
            <!--                  title="Jenny Looper"-->
            <!--                  :src="-->
            <!--                    require(`@/assets/images/portrait/small/avatar-s-${n}.jpg`)-->
            <!--                  "-->
            <!--                />-->
            <!--              </b-avatar-group>-->
            <!--              <span v-if="a > 2">-->
            <!--                <span-->
            <!--                  :id="`folder_shared_users_${a}`"-->
            <!--                  class="text-muted text-nowrap ml-1"-->
            <!--                >-->
            <!--                  +20 więcej-->
            <!--                </span>-->
            <!--                <b-popover-->
            <!--                  :target="`folder_shared_users_${a}`"-->
            <!--                  placement="right"-->
            <!--                  title="Użytkownicy"-->
            <!--                  triggers="hover"-->
            <!--                >-->
            <!--                  <vue-perfect-scrollbar-->
            <!--                    :settings="{ maxScrollbarLength: 100 }"-->
            <!--                    style="height: 300px"-->
            <!--                  >-->
            <!--                    <div-->
            <!--                      v-for="n in 8"-->
            <!--                      :key="n"-->
            <!--                      class="pb-50 mr-3"-->
            <!--                    >-->
            <!--                      <b-avatar-->
            <!--                        :src="-->
            <!--                          require(`@/assets/images/portrait/small/avatar-s-${n}.jpg`)-->
            <!--                        "-->
            <!--                        class="mr-50"-->
            <!--                      />-->
            <!--                      Jenny Looper-->
            <!--                    </div>-->
            <!--                  </vue-perfect-scrollbar>-->
            <!--                </b-popover>-->
            <!--              </span>-->
            <!--            </b-td>-->
            <b-td>
              <!--              <b-button-->
              <!--                v-b-modal.modal-delete-->
              <!--                v-b-tooltip="$t('Delete')"-->
              <!--                class="mr-1"-->
              <!--                variant="secondary"-->
              <!--                size="sm"-->
              <!--                @click.stop="setFolderForAction(folder.name)"-->
              <!--              >-->
              <!--                <feather-icon icon="TrashIcon" />-->
              <!--              </b-button>-->
              <!--              <b-button-->
              <!--                v-b-modal.modal-edit-->
              <!--                v-b-tooltip="$t('Edit')"-->
              <!--                class="mr-1"-->
              <!--                variant="secondary"-->
              <!--                size="sm"-->
              <!--                @click.stop="setFolderForAction(folder.name)"-->
              <!--              >-->
              <!--                <feather-icon icon="EditIcon" />-->
              <!--              </b-button>-->
              <!--              <b-button-->
              <!--                v-b-modal.modal-share-folder-->
              <!--                v-b-tooltip="$t('Share')"-->
              <!--                class="mr-1"-->
              <!--                variant="secondary"-->
              <!--                size="sm"-->
              <!--                @click.stop="-->
              <!--                  setFolderForAction(folder.name)-->
              <!--                "-->
              <!--              >-->
              <!--                <feather-icon icon="Share2Icon" />-->
              <!--              </b-button>-->
              <!--              <b-button-->
              <!--                v-b-tooltip="$t('Download')"-->
              <!--                class="mr-1"-->
              <!--                variant="secondary"-->
              <!--                size="sm"-->
              <!--              >-->
              <!--                <feather-icon icon="DownloadIcon" />-->
              <!--              </b-button>-->
              <b-button
                variant="success"
                size="sm"
                @click.stop="openFolder(folder, folderIndex)"
              >
                <span v-if="!folder.loading">{{ $t('Open') }} <feather-icon icon="ArrowRightIcon" /></span>
                <b-spinner
                  v-else
                  small
                  variant="light"
                />
              </b-button>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <!-- folder nav -->
      <!--      <transition name="fade">-->
      <!--        <div-->
      <!--          v-if="selectedFolders.length"-->
      <!--          style="position: fixed; right: 20px; bottom: 20px"-->
      <!--          class="d-flex"-->
      <!--        >-->
      <!--          <b-button-->
      <!--            variant="success"-->
      <!--            class="d-flex align-items-center mr-1"-->
      <!--          >-->
      <!--            <feather-icon icon="DownloadIcon" />-->
      <!--            <span class="pl-25 d-sm-flex d-none align-items-center">-->
      <!--              {{ $t("Download") }}-->
      <!--            </span>-->
      <!--          </b-button>-->
      <!--          <b-button-->
      <!--            v-b-modal.modal-share-folder-->
      <!--            variant="primary"-->
      <!--            class="d-flex align-items-center mr-1"-->
      <!--          >-->
      <!--            <feather-icon icon="Share2Icon" />-->
      <!--            <span class="pl-25 d-sm-flex d-none align-items-center">-->
      <!--              {{ $t("Share") }}-->
      <!--            </span>-->
      <!--          </b-button>-->
      <!--          <b-button-->
      <!--            variant="danger"-->
      <!--            class="d-flex align-items-center mr-1"-->
      <!--          >-->
      <!--            <feather-icon icon="TrashIcon" />-->
      <!--            <span class="pl-25 d-sm-flex d-none align-items-center">-->
      <!--              {{ $t("Delete") }}-->
      <!--            </span>-->
      <!--          </b-button>-->
      <!--    </div>-->
      <!--      </transition>-->
      <!-- share folder modal -->
      <!--      <b-modal-->
      <!--        id="modal-share-folder"-->
      <!--        :title="'Udostępnij folder'"-->
      <!--        ok-only-->
      <!--        :ok-title="$t('Share')"-->
      <!--        ok-variant="info"-->
      <!--      >-->
      <!--        <span>Wybierz użytkowników</span>-->
      <!--        <v-select-->
      <!--          v-model="shareForUsers"-->
      <!--          multiple-->
      <!--          :close-on-select="false"-->
      <!--          :options="options"-->
      <!--        >-->

      <!--          <template #option="{ avatar, name }">-->
      <!--            <b-avatar-->
      <!--              size="sm"-->
      <!--              :src="avatar"-->
      <!--            />-->
      <!--            <span class="ml-50"> {{ name }}</span>-->
      <!--          </template>-->

      <!--          <template #selected-option="{ avatar, name }">-->
      <!--            <b-avatar-->
      <!--              size="sm"-->
      <!--              class="border border-white"-->
      <!--              :src="avatar"-->
      <!--            />-->
      <!--            <span class="ml-50"> {{ name }}</span>-->
      <!--          </template>-->
      <!--        </v-select>-->
      <!--      </b-modal>-->
      <!--  Edit Folder Modal  -->
      <!--      <b-modal-->
      <!--        id="modal-edit"-->
      <!--        :title="`Edycja folderu: ${actionFolder}`"-->
      <!--        ok-only-->
      <!--        :ok-title="$t('Save')"-->
      <!--        ok-variant="warning"-->
      <!--      >-->
      <!--        <span>Nazwa folderu</span>-->
      <!--        <b-form-input :value="actionFolder" />-->
      <!--      </b-modal>-->
      <!--  Edit Folder Modal  -->
      <!--  Delete Folder Modal  -->
      <!--      <b-modal-->
      <!--        id="modal-delete"-->
      <!--        :title="`Usuń folder: ${actionFolder}`"-->
      <!--        ok-only-->
      <!--        :ok-title="$t('Delete')"-->
      <!--        ok-variant="danger"-->
      <!--      >-->
      <!--        Czy na pewno chcesz usunąć folder?-->
      <!--      </b-modal>-->
      <!--  Delete Folder Modal  -->
      <!--  Add Folder Modal  -->
      <!--      <b-modal-->
      <!--        id="modal-add-folder"-->
      <!--        title="Dodaj folder"-->
      <!--        ok-only-->
      <!--        :ok-title="$t('Add')"-->
      <!--        ok-variant="success"-->
      <!--        @ok="createNewFolder"-->
      <!--      >-->
      <!--        <span>Nazwa folderu</span>-->
      <!--        <b-form-input v-model="newFolderName" />-->
      <!--      </b-modal>-->
    <!--  Add Folder Modal  -->

    </div>
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { OPEN_FOLDER } from '@/@constants/mutations'

export default {
  name: 'Folders',
  directives: {
    'b-tooltip': VBTooltip,
  },
  data: () => ({
    selectedFolders: [],
    actionFolder: null,
    shareForUsers: [],
  }),
  computed: {
    ...mapGetters({
      folders: 'documents/getFolders',
      currentUser: 'auth/getCurrentUser',
    }),
    checkSelectedFolders() {
      let flag = true
      this.folders.forEach(pageUser => {
        if (flag && this.selectedFolders.includes(pageUser.id)) flag = true
        else flag = false
      })
      return flag
    },
  },
  methods: {
    openFolder(folder, folderIndex) {
      this.folders[folderIndex].loading = true

      const { threadId, contactId } = this.$route.params
      const payload = { threadId, folder, contactId }
      this.$store.dispatch(`documents/${OPEN_FOLDER}`, payload)
        .then(() => {
          this.folders[folderIndex].loading = false
        })
        .then(() => {
          this.folders[folderIndex].loading = false
        })
    },
    setFolderForAction(folder) {
      this.selectedFolders = []
      this.actionFolder = folder
    },
    selectFolderForAction(folderId) {
      const { selectedFolders } = this
      if (selectedFolders.includes(folderId)) {
        selectedFolders.splice(selectedFolders.indexOf(folderId), 1)
      } else selectedFolders.push(folderId)
    },
    selectAllFolders(isChecked) {
      if (isChecked) this.folders.forEach(user => (!this.selectedFolders.includes(user.id) ? this.selectedFolders.push(user.id) : ''))
      else {
        this.folders.forEach(user => {
          const indexOfItem = this.selectedFolders.indexOf(user.id)
          if (indexOfItem > -1) this.selectedFolders.splice(indexOfItem, 1)
        })
      }
    },
  },
}
</script>

<style scoped></style>
