const iconType = type => {
  let icon = 'FileIcon'
  switch (type) {
    case 'application/pdf': icon = 'FileIcon'; break
    case 'image/jpeg':
    case 'image/png':
    case 'image/jpg':
      icon = 'ImageIcon'; break
    default: icon = 'FileIcon'
  }
  return icon
}

export default iconType
