export default (text, length = 6, clamp = '…', response = true) => {
  if (text?.length <= length) return text

  const div = document.createElement('div')

  div.textContent = text

  if (window.innerWidth >= 992 && response) return div.textContent

  return `
    ${div.textContent.slice(0, length * 0.5)}${clamp}${div.textContent.slice(-length * 0.5)}
  `
}
