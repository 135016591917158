// eslint-disable-next-line import/no-cycle
import axiosIns from '@/libs/axios'
import * as fields from '@/@constants/fields'
import axios from 'axios'
import { GET_GLOBAL_TASKS } from '../../../@constants/mutations'

function getCurrentDate() {
  const date = new Date()
  const month = (date.getMonth() + 1) < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
  return `${date.getFullYear()}-${month}-${day}`
}

export const resolveFilters = (params, { filters, sorter }, exporter = true) => {
  /* eslint-disable */
  if (filters && filters.taskStatus && !exporter) params.eq_status = filters.taskStatus
  if (filters && filters.selectedTypes?.length) params.eqArr_contactThreadTaskType = JSON.stringify(filters.selectedTypes)

  if (filters && filters.assignedUsers?.length) {
    params.eqArr_assignedUser = JSON.stringify(
        filters.assignedUsers.map(
            user => (typeof user === 'object' ? user.id : user),
        ),
    )
  }

  if (filters && filters.isDelayed) {
    params.lt_endAt = getCurrentDate(true)
  }

  if (filters && filters.showUnsignedTasks) {
    params.isNull_contactThread = 1
  }

  if (filters && filters.priority) {
    params.eq_priority = filters.priority
  }

  if (filters && filters.isToday) {
    params.gte_startAt = `${getCurrentDate()} 00:00:00`
    params.lt_startAt = `${getCurrentDate()} 23:59:59`
  }

  if (Array.isArray(filters.contactTags) && filters.contactTags.length) {
    params['eqArr_contactThread-contact-contactTypes-id'] = JSON.stringify(filters.contactTags.map(tag => (typeof tag === 'string' ? tag : tag.id)))
  }

  if (filters && filters.id) {
    params.eq_id = filters.id
  }

  if (filters?.contacts && filters.contacts.length) {
    params['eqArr_contactThread-contact-id'] = JSON.stringify(filters.contacts)
  }

  if (filters?.funnelPoint) {
    params['eq_contactThread-contactThreadFunnelProcesses-contactThreadFunnelTypePoint-id'] = filters.funnelPoint
  }
  if (filters?.funnel) {
    params['eq_contactThread-contactThreadFunnelProcesses-contactThreadFunnelTypePoint-contactThreadFunnelType-id'] = filters.funnel
  }
  if (filters?.thread) {
    params['eq_contactThread-id'] = filters.thread
  }

  if (filters?.timeRange) {
    const splitDate = filters.timeRange.dateRange()
    params.gte_startAt = `${splitDate[0]} 00:00:00`
    if (splitDate[1]) {
      params.lte_startAt = `${splitDate[1]} 23:59:59`
    }
  }

  if (filters?.updatedAt) {
    const splitDate = filters.updatedAt.dateRange()

    params.gte_updatedAt = `${splitDate[0]} 00:00:00`
    if (splitDate[1]) {
      params.lte_updatedAt = `${splitDate[1]} 23:59:59`
    }
  }

  params.orderBy = 'startAt.asc'

  if (sorter) {
    const sortDir = sorter?.sortDesc ? 'desc' : 'asc'
    const sortBy = sorter?.sortBy || 'id'
    params.orderBy = `${sortBy}.${sortDir}`
  }

  if (exporter) {
    delete params.eq_status
  }
}

let source
export default {
  namespaced: true,
  actions: {
    // eslint-disable-next-line no-unused-vars,no-async-promise-executor
    [GET_GLOBAL_TASKS]: ({ commit }, payload) => new Promise(async (resolve, reject) => {
      // eslint-disable-next-line no-unused-vars
      const {
        page, perPage, filters, sorter, search, exporter = false,
      } = payload

      if (typeof source !== typeof undefined && !exporter) source.cancel('Operation canceled due to new request.')
      if (!exporter) source = axios.CancelToken.source()

      let params = {}
      if (page || perPage) {
        params = { page, limit: perPage }
      }

      if (search?.length) {
        // eslint-disable-next-line no-underscore-dangle
        const _search = search.replaceAll(' ', '%')
        params = {
          search: `%${_search}%`,
          search_fields: 'content',
        }
      }

      resolveFilters(params, { filters, sorter }, exporter)

      let stats = { }
      if (!exporter) {
        const statsFilters = { ...params }
        delete statsFilters.page
        delete statsFilters.limit
        delete statsFilters.search
        delete statsFilters.search_fields
        delete statsFilters.eq_status
        const { data } = await axiosIns.get('1/contacts/threads/tasks/getStatistics', { params: { ...statsFilters } })
        stats = data.data
      }

      params.fields_load = fields.TASKS
      const cancelToken = (!exporter) ? source.token : null

      axiosIns.get('1/contacts/threads/tasks', { params, cancelToken })
        .then(res => {
          resolve({ ...res.data, stats })
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    GET_USER_TASKS: ({ commit }, payload) => new Promise((resolve, reject) => {
      const { userIds } = payload
      const params = {}

      params.eqArr_assignedUser = JSON.stringify(userIds)
      // params.fields = 'id,sendNotifyEmail.sendNotifySms,assignedUser.id,files,mail,address,contactThreadTaskType.id,contactThreadTaskType.icon,contactThreadTaskType.name,contactThreadTaskType.isAddressRequired,contactThreadTaskType.isContentRequired,contactThreadTaskType.isReportRequired,assignedUser.firstName,assignedUser.id,assignedUser.lastName,assignedUser.avatar,contactThread.id,contactThread.name,contactThread.contact.id,contactThread.contact.firstName,contactThread.contact.lastName,contactThread.assignedUsers.id,contactThread.assignedUsers.firstName,contactThread.assignedUsers.lastName,contactThread.assignedUsers.avatar,contactThreadTaskReport.id,contactThreadTaskReport.content,contactThreadTaskReport.createdAt,contactThreadTaskReport.createdBy.id,contactThreadTaskReport.createdBy.firstName,contactThreadTaskReport.createdBy.lastName,contactThreadTaskReport.createdBy.avatar,contactThreadTaskReport.files,content,createdAt,createdBy.id,createdBy.firstName,createdBy.lastName,createdBy.avatar,files,endAt,startAt,outdated,sms,status'
      params.fields_load = fields.TASKS

      if (payload.status) {
        params.eq_status = payload.status
      }

      axiosIns.get('1/contacts/threads/tasks', { params })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
  },
}
