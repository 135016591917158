import { render, staticRenderFns } from "./UserArrears.vue?vue&type=template&id=cb0f2e96&scoped=true"
import script from "./UserArrears.vue?vue&type=script&lang=js"
export * from "./UserArrears.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cb0f2e96",
  null
  
)

export default component.exports