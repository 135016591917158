/* eslint-disable */
import { FULL_ACCESS } from '@/helpers/permissions'

const checkObjectRanks = item => {
  const {
    role, data, field, userId,
  } = item

  if (
    role.toString().includes('EDIT_ASSIGNED')
    || role.toString().includes('ASSIGNED_TASK_ADD')
    || role.toString().includes('ASSIGNED_NOTE_ADD')
  ) {
    if (Array.isArray(data[field])) {
      return !!data[field].find(user => user.id.toString() === userId)
    }
  }

  if (
    role.toString().includes('EDIT_SELF_ASSIGNED')
    || role.toString().includes('TASK_EDIT_SELF')
    || role.toString().includes('NOTE_EDIT_SELF')
  ) {
    return data[field] && data[field].id ? data[field].id.toString() === userId.toString() : false
  }

  return false
}

const checkRanks = (permissions, ...requiredPermissions) => {
  if (permissions.includes(FULL_ACCESS)) return true

  // eslint-disable-next-line
  for (const reqPerms of requiredPermissions) {
    if (!Array.isArray(reqPerms)) {
      return false
    }

    let exist = false
    // eslint-disable-next-line
    for (const reqPerm of reqPerms) {
      if (typeof reqPerm === 'string') {
        if (permissions.includes(reqPerm)) {
          exist = true
          break
        }
      } else if (typeof reqPerm === 'object') {
        if (permissions.includes(reqPerm.role) && checkObjectRanks(reqPerm)) {
          exist = true
          break
        }
      } else {
        return false
      }
    }
    if (!exist) {
      return false
    }
  }
  return true
}

export default checkRanks
