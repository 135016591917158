export const FULL_ACCESS = 'FULL_ACCESS'
export const USER_STRUCTURE_VIEW_ALL = 'USER_STRUCTURE_VIEW_ALL'
export const USER_STRUCTURE_VIEW_STRUCTURE = 'USER_STRUCTURE_VIEW_STRUCTURE'
export const USER_STRUCTURE_VIEW_STRUCTURE_FIRST = 'USER_STRUCTURE_VIEW_STRUCTURE_FIRST'

// CONTACT
// COMPLAINT
export const CONTACT_THREAD_COMPLAINT_VIEW_ALL = 'CONTACT_THREAD_COMPLAINT_VIEW_ALL' // dostęp do widoczności wszystkich reklamacji w dostępnych wątkach
export const CONTACT_THREAD_ASSIGNED_COMPLAINT_VIEW_ALL = 'CONTACT_THREAD_ASSIGNED_COMPLAINT_VIEW_ALL' // dostęp do widoczności wszystkich reklamacji w przypisanych wątkach
export const CONTACT_THREAD_ASSIGNED_COMPLAINT_VIEW_SELF = 'CONTACT_THREAD_ASSIGNED_COMPLAINT_VIEW_SELF' // dostęp do widoczności tylko własnych reklamacji w przypisanych wątkach
export const CONTACT_THREAD_COMPLAINT_ADD = 'CONTACT_THREAD_COMPLAINT_ADD' // dostęp do dodawania nowych reklamacji w dostępych wątakch
export const CONTACT_THREAD_COMPLAINT_EDIT = 'CONTACT_THREAD_COMPLAINT_EDIT' // dostęp do dodawania nowych reklamacji w dostępych wątakch
export const CONTACT_THREAD_COMPLAINT_DELETE = 'CONTACT_THREAD_COMPLAINT_DELETE' // usuwanie reklamacji
export const CONTACT_VIEW_ALL = 'CONTACT_VIEW_ALL'
export const CONTACT_VIEW_STRUCTURE = 'CONTACT_VIEW_STRUCTURE'
export const CONTACT_VIEW_STRUCTURE_FIRST = 'CONTACT_VIEW_STRUCTURE_FIRST'
export const CONTACT_VIEW_ASSIGNED = 'CONTACT_VIEW_ASSIGNED'

export const TRAINING_VIEW_ALL = 'TRAINING_VIEW_ALL'
export const ONBOARDING_VIEW_ALL = 'ONBOARDING_VIEW_ALL'
export const CONTACT_ADD = 'CONTACT_ADD'
export const CONTACT_ADD_IMPORT = 'CONTACT_ADD_IMPORT'

export const CONTACT_EDIT_DETAILS = 'CONTACT_EDIT_DETAILS'
export const CONTACT_EDIT_ASSIGNED_USERS = 'CONTACT_EDIT_ASSIGNED_USERS'

export const CONTACT_DELETE = 'CONTACT_DELETE'

// CONTACT_THREAD

export const CONTACT_THREAD_VIEW_ALL = 'CONTACT_THREAD_VIEW_ALL'
export const CONTACT_THREAD_VIEW_STRUCTURE = 'CONTACT_THREAD_VIEW_STRUCTURE'
export const CONTACT_THREAD_VIEW_STRUCTURE_FIRST = 'CONTACT_THREAD_VIEW_STRUCTURE_FIRST'
export const CONTACT_THREAD_VIEW_ASSIGNED = 'CONTACT_THREAD_VIEW_ASSIGNED'

export const CONTACT_THREAD_ADD = 'CONTACT_THREAD_ADD'

export const CONTACT_THREAD_EDIT_ALL = 'CONTACT_THREAD_EDIT_ALL'
export const CONTACT_THREAD_EDIT_ASSIGNED = 'CONTACT_THREAD_EDIT_ASSIGNED'
export const CONTACT_THREAD_EDIT_SELF_ASSIGNED = 'CONTACT_THREAD_EDIT_SELF_ASSIGNED'

export const CONTACT_THREAD_DELETE = 'CONTACT_THREAD_DELETE'

// CONTACT_THREAD_TASK

export const CONTACT_THREAD_TASK_VIEW_ALL = 'CONTACT_THREAD_TASK_VIEW_ALL'
export const CONTACT_THREAD_TASK_VIEW_ASSIGNED = 'CONTACT_THREAD_TASK_VIEW_ASSIGNED'

export const CONTACT_THREAD_TASK_VIEW_STRUCTURE = 'CONTACT_THREAD_TASK_VIEW_STRUCTURE'

export const CONTACT_THREAD_TASK_ADD = 'CONTACT_THREAD_TASK_ADD'
export const CONTACT_THREAD_TASK_DELETE = 'CONTACT_THREAD_TASK_DELETE'
export const CONTACT_THREAD_ASSIGNED_TASK_ADD = 'CONTACT_THREAD_ASSIGNED_TASK_ADD'
export const CONTACT_THREAD_TASK_EDIT_SELF = 'CONTACT_THREAD_TASK_EDIT_SELF'
export const CONTACT_THREAD_TASK_EDIT = 'CONTACT_THREAD_TASK_EDIT'
export const CONTACT_THREAD_TASK_CHANGE_STATUS_ALL = 'CONTACT_THREAD_TASK_CHANGE_STATUS_ALL' // dostęp do zmiany statusu wszystkich zadan
export const CONTACT_THREAD_TASK_CHANGE_STATUS_ASSIGNED = 'CONTACT_THREAD_TASK_CHANGE_STATUS_ASSIGNED' // dostęp do zmiany statusu zadania przypisanego dla zalogowanego użytkownika

// CONTACT_THREAD_NOTE
export const CONTACT_THREAD_NOTE_VIEW_ALL = 'CONTACT_THREAD_NOTE_VIEW_ALL'
export const CONTACT_THREAD_NOTE_VIEW_SHARED = 'CONTACT_THREAD_NOTE_VIEW_SHARED'
export const CONTACT_THREAD_NOTE_VIEW_SELF = 'CONTACT_THREAD_NOTE_VIEW_SELF'

export const CONTACT_THREAD_NOTE_ADD = 'CONTACT_THREAD_NOTE_ADD'
export const CONTACT_THREAD_ASSIGNED_NOTE_ADD = 'CONTACT_THREAD_ASSIGNED_NOTE_ADD'
export const CONTACT_THREAD_NOTE_EDIT_SELF = 'CONTACT_THREAD_NOTE_EDIT_SELF'
export const CONTACT_THREAD_NOTE_DELETE_SELF = 'CONTACT_THREAD_NOTE_DELETE_SELF'

// CONTACT_THREAD_CHECKLIST
export const CONTACT_THREAD_CHECKLIST_VIEW_ALL = 'CONTACT_THREAD_CHECKLIST_VIEW_ALL'
export const CONTACT_THREAD_CHECKLIST_EDIT = 'CONTACT_THREAD_CHECKLIST_EDIT'

// CONTACT_THREAD_TIMELINE_VIEW_ALL
export const CONTACT_THREAD_TIMELINE_VIEW_ALL = 'CONTACT_THREAD_TIMELINE_VIEW_ALL'

// CONTACT_THREAD_MAIL
export const CONTACT_THREAD_MAIL_ADD = 'CONTACT_THREAD_MAIL_ADD'
export const CONTACT_THREAD_SMS_ADD = 'CONTACT_THREAD_SMS_ADD'

// SETTINGS
export const SETTINGS = 'SETTINGS'
export const ACTUALIZATIONS = 'ACTUALIZATIONS'

// USER
export const USER_VIEW_ALL = 'USER_VIEW_ALL'
export const USER_VIEW_STRUCTURE = 'USER_VIEW_STRUCTURE'
export const USER_ADD = 'USER_ADD'
export const USER_EDIT = 'USER_EDIT'
export const USER_RESET_PASSWORD = 'USER_RESET_PASSWORD'

export const CONTACT_THREAD_MAIL_VIEW_ALL = 'CONTACT_THREAD_MAIL_VIEW_ALL' // dostęp do Maili w dostępnych wątkach

export const CONTACT_THREAD_SMS_VIEW_ALL = 'CONTACT_THREAD_SMS_VIEW_ALL' // dostęp do SMSów w dostępnych wątkach
export const CONTACT_THREAD_SMS_VIEW_SHARED = 'CONTACT_THREAD_SMS_VIEW_SHARED' // dostęp tylko do udostępnionych SMSów w dostępnych wątkach
export const CONTACT_THREAD_SMS_VIEW_SELF = 'CONTACT_THREAD_SMS_VIEW_SELF' // dostęp tylko do wysłanych przez zalogowanego użytkownika SMSów w dostępnych wątkach

export const ORGANIZATION_DELETE = 'ORGANIZATION_DELETE' // dostęp do usuwania organizacji z systemu
export const ORGANIZATION_VIEW_ALL = 'ORGANIZATION_VIEW_ALL' // dostęp do usuwania organizacji z systemu

// Blog
export const NEWS_POST_VIEW_ALL = 'NEWS_POST_VIEW_ALL'
export const NEWS_POST_MODIFY = 'NEWS_POST_MODIFY'

// AI
export const CHAT_GPT_USE = 'CHAT_GPT_USE'
export const IMAGE_RECOGNITION_USE = 'IMAGE_RECOGNITION_USE'
export const CHAT_GPT_REPORT_ADD = 'CHAT_GPT_REPORT_ADD'

export const AUTOMATION_VIEW_ALL = 'AUTOMATION_VIEW_ALL'
export const AUTOMATION_MODIFY = 'AUTOMATION_MODIFY'
export const REPORT_VIEW_ALL = 'REPORT_VIEW_ALL'

/// ///////////
// Building // - 07.12.2021
/// ///////////

// BUILDINGS
// export const BUILDING_VIEW_ALL = 'BUILDING_VIEW_ALL' // dostęp do widoczności wszystkich budów
// export const BUILDING_VIEW_ASSIGNED = 'BUILDING_VIEW_ASSIGNED' // dostęp do widoczności tylko budów do których jest przypisany zalogowany użytkownik
// export const BUILDING_VIEW_GROUP = 'BUILDING_VIEW_GROUP' // dostęp do wszystkich budów w strukturach zalogowanego użytkownika (niezależnie od przypisania użytkownika do budowy)
// export const BUILDING_ADD = 'BUILDING_ADD' // dostęp do dodawania nowej budowy
// export const BUILDING_EDIT = 'BUILDING_EDIT' // dostęp do edycji dostępnych budów
// export const BUILDING_DELETE = 'BUILDING_DELETE' // dostęp do usuwania dostępnych budów

// BUILDING_BUDGET_LINE
// export const BUILDING_BUDGET_LINE_VIEW_ALL = 'BUILDING_BUDGET_LINE_VIEW_ALL' // dostęp do widoczności linii budżetu w dostępnych budowach
// export const BUILDING_BUDGET_LINE_MODIFY = 'BUILDING_BUDGET_LINE_MODIFY' // dostęp do dodawania/edycji/usuwania linii budżetu w dostępnych budowach
//
// export const BUILDING_PROTOCOL_ADD = 'BUILDING_PROTOCOL_ADD' // dostęp do dodawania protokołu z budowy w dostępnych budowach

// BUILDING_AGREEMENT
// export const BUILDING_AGREEMENT_VIEW_ALL = 'BUILDING_AGREEMENT_VIEW_ALL' // dostęp do widoczności wszystkich umów w dostępnych budowach
// export const BUILDING_AGREEMENT_ADD = 'BUILDING_AGREEMENT_ADD' // dostęp do dodawania nowej umowy w dostępnych umowach
// export const BUILDING_AGREEMENT_EDIT = 'BUILDING_AGREEMENT_EDIT' // dostęp do edycji dostępnych umów
// export const BUILDING_AGREEMENT_DELETE = 'BUILDING_AGREEMENT_DELETE' // dostęp do usuwania dostępnych umów
//
// BUILDING_AGREEMENT_ITEM
// export const BUILDING_AGREEMENT_ITEM_VIEW_ALL = 'BUILDING_AGREEMENT_ITEM_VIEW_ALL' // dostęp do widoczności pozycji umowy w dostępnych umowach
// export const BUILDING_AGREEMENT_ITEM_MODIFY = 'BUILDING_AGREEMENT_ITEM_MODIFY' // dostęp do dodawania/edycji/usuwania pozycji umowy w dostępnych umowach
//
// BUILDING_AGREEMENT_DEPOSIT_ITEM
// export const BUILDING_DEPOSIT_ITEM_VIEW_ALL = 'BUILDING_DEPOSIT_ITEM_VIEW_ALL' // dostęp do widoczności pozycji kaucji umowy w dostępnych umowach
// export const BUILDING_DEPOSIT_ITEM_MODIFY = 'BUILDING_DEPOSIT_ITEM_MODIFY' // dostęp do dodawania/edycji/usuwania pozycji kaucji umowy w dostępnych umowach
//
// export const BUILDING_DEPOSIT_ITEM_COLLECT_VIEW_ALL = 'BUILDING_DEPOSIT_ITEM_COLLECT_VIEW_ALL' // dostęp do widoczności pobranych/zwróconych/potrąconych kaucji w dostępnych obciążeniach
// export const BUILDING_DEPOSIT_ITEM_COLLECT_ADD = 'BUILDING_DEPOSIT_ITEM_COLLECT_ADD' // dostęp do dodawania pobranych/zwróconych/potrąconych kaucji w dostępnych obciążeniach
//
// BUILDING_AGREEMENT_GUARANTEE_ITEM
// export const BUILDING_GUARANTEE_ITEM_VIEW_ALL = 'BUILDING_GUARANTEE_ITEM_VIEW_ALL' // dostęp do widoczności gwarancji umowy w dostępnych umowach
// export const BUILDING_GUARANTEE_ITEM_MODIFY = 'BUILDING_GUARANTEE_ITEM_MODIFY' // dostęp do dodawania/edycji/usuwania pozycji gwarancji umowy w dostępnych umowach
//
// BUILDING_AGREEMENT_DEBIT_ITEM
// export const BUILDING_DEBIT_ITEM_VIEW_ALL = 'BUILDING_DEBIT_ITEM_VIEW_ALL' // dostęp do widoczności pozycji obciążeń umowy w dostępnych umowach
// export const BUILDING_DEBIT_ITEM_MODIFY = 'BUILDING_DEBIT_ITEM_MODIFY' // dostęp do dodawania/edycji/usuwania pozycji obciążeń umowy w dostępnych umowach
//
// export const BUILDING_DEBIT_ITEM_COLLECT_VIEW_ALL = 'BUILDING_DEBIT_ITEM_COLLECT_VIEW_ALL' // dostęp do widoczności pobranych obciążeń w dostępnych obciążeniach
// export const BUILDING_DEBIT_ITEM_COLLECT_ADD = 'BUILDING_DEBIT_ITEM_COLLECT_ADD' // dostęp do dodawania pobranych obciążeń w dostępnych obciążeniach
//
// BUILDING_AGREEMENT_FILE
// export const BUILDING_AGREEMENT_FILE_VIEW_ALL = 'BUILDING_AGREEMENT_FILE_VIEW_ALL' // dostęp do widoczności dokumentów z umów w dostępnych umowach
// export const BUILDING_AGREEMENT_FILE_ADD = 'BUILDING_AGREEMENT_FILE_ADD' // dostęp do dodawania dokumentów z umów w dostępnych umowach
// export const BUILDING_AGREEMENT_FILE_DELETE = 'BUILDING_AGREEMENT_FILE_DELETE' // dostęp do usuwania dokumentów z umów w dostępnych umowach
//
// BUILDING_AGREEMENT_PROTOCOL
// export const BUILDING_AGREEMENT_PROTOCOL_VIEW_FULL = 'BUILDING_AGREEMENT_PROTOCOL_VIEW_FULL' // dostęp do widoczności wszystkich protokołów z umów w bazie
// export const BUILDING_AGREEMENT_PROTOCOL_VIEW_ALL = 'BUILDING_AGREEMENT_PROTOCOL_VIEW_ALL' // dostęp do widoczności protokołów z umowów w dostępnych umowach
// export const BUILDING_AGREEMENT_PROTOCOL_ADD = 'BUILDING_AGREEMENT_PROTOCOL_ADD' // dostęp do dodawania protokołu z umowy w dostępnych umowach
//
// BUILDING_INVOICE_COST
// export const BUILDING_INVOICE_COST_VIEW_ALL = 'BUILDING_INVOICE_COST_VIEW_ALL' // dostęp do widoczności wszystkich faktur kosztowych w dostępnych budowach
// export const BUILDING_INVOICE_COST_ADD = 'BUILDING_INVOICE_COST_ADD' // dostęp do dodawania nowej faktury kosztowej w dostępnych umowach
// export const BUILDING_INVOICE_COST_EDIT = 'BUILDING_INVOICE_COST_EDIT' // dostęp do edycji dostępnych faktur kosztowych
// export const BUILDING_INVOICE_COST_DELETE = 'BUILDING_INVOICE_COST_DELETE' // dostęp do usuwania dostępnych faktur kosztowych
//
// BUILDING_INVOICE_COST_ITEM
// export const BUILDING_INVOICE_COST_ITEM_VIEW_ALL = 'BUILDING_INVOICE_COST_ITEM_VIEW_ALL' // dostęp do widoczności pozycji faktury kosztowej w dostępnych fakturach kosztowych
// export const BUILDING_INVOICE_COST_ITEM_MODIFY = 'BUILDING_INVOICE_COST_ITEM_MODIFY' // dostęp do dodawania/edycji/usuwania pozycji faktury kosztowej w dostępnych fakturach kosztowych
//
// CONTRACTOR
// export const CONTRACTOR_VIEW_ALL = 'CONTRACTOR_VIEW_ALL' // dostęp do widoczności wszystkich kontrahentów
// export const CONTRACTOR_ADD = 'CONTRACTOR_ADD' // dostęp do dodawania nowych kontrahentów
// export const CONTRACTOR_EDIT = 'CONTRACTOR_EDIT' // dostęp do edycji dostępnych kontrahentów
// export const CONTRACTOR_DELETE = 'CONTRACTOR_DELETE' // dostęp do usuwania dostępnych kontrahentów

// INVESTMENT
// export const INVESTMENT_VIEW_ALL = 'INVESTMENT_VIEW_ALL' // dostęp do widoczności wszystkich inwestycji
// export const INVESTMENT_ADD = 'INVESTMENT_ADD' // dostęp do dodawania nowych inwestycji
// export const INVESTMENT_EDIT = 'INVESTMENT_EDIT' // dostęp do edycji dostępnych inwestycji
// export const INVESTMENT_DELETE = 'INVESTMENT_DELETE' // dostęp do usuwania dostępnych inwestycji

// BUILDING_BUDGET_TEMPLATE
// export const BUILDING_BUDGET_TEMPLATE_VIEW_ALL = 'BUILDING_BUDGET_TEMPLATE_VIEW_ALL' // dostęp do widoczności wszystkich szablonów budżetów
// export const BUILDING_BUDGET_TEMPLATE_MODIFY = 'BUILDING_BUDGET_TEMPLATE_MODIFY' // dostęp do dodawania/edycji/usuwania dostępnych szablonów budżetów

export const CHAT_VIEW_ALL = 'CHAT_VIEW_ALL'
export const CHAT_VIEW_RANK = 'CHAT_VIEW_RANK'
export const APPLICATION_DELETE = 'APPLICATION_DELETE'
export const APPLICATION_VIEW_ASSIGNED_CONTACT_THREAD = 'APPLICATION_VIEW_ASSIGNED_CONTACT_THREAD'

export const CONTACT_ADDON_FIELD_MODIFY = 'CONTACT_ADDON_FIELD_MODIFY'

// DOCUMENT
export const DOCUMENT_VIEW_ALL = 'DOCUMENT_VIEW_ALL' // dostęp do widoczności wszystkich dokumentów i filmów
export const DOCUMENT_VIEW_SHARED = 'DOCUMENT_VIEW_SHARED' // dostęp do widoczności tylko udostępnionych dokumentów i filmów
export const DOCUMENT_MODIFY = 'DOCUMENT_MODIFY' // dostęp do dodawania/edycji/usuwania dokumentów i filmów

// POLLS
export const CONTACT_THREAD_POLL_VIEW_ALL = 'CONTACT_THREAD_POLL_VIEW_ALL' // dostęp do wszystkich ankiet w dostępnych wątkach
export const CONTACT_THREAD_POLL_MODIFY = 'CONTACT_THREAD_POLL_MODIFY' // dostęp do dodawania i edycji dostępnych ankiet w dostępnych wątkach
export const CONTACT_THREAD_POLL_DELETE = 'CONTACT_THREAD_POLL_DELETE' // dostęp do usuwania dostępnych ankiet w dostępnych wątkach

// Shop
export const SHOP_PRODUCT_VIEW_ALL = 'SHOP_PRODUCT_VIEW_ALL' // dostęp do widoczności wszystkich produktów
export const SHOP_PRODUCT_VIEW_GROUP = 'SHOP_PRODUCT_VIEW_GROUP' // dostęp do widoczności produktów w grupach użytkownika
export const SHOP_PRODUCT_MODIFY = 'SHOP_PRODUCT_MODIFY' // dostęp do dodawania/edycji/usuwania produktów

// CATEGORY
export const SHOP_CATEGORY_MODIFY = 'SHOP_CATEGORY_MODIFY' // dostęp do dodawania/edycji/usuwania kategorii produktów

// ATTRIBUTE
export const SHOP_ATTRIBUTE_MODIFY = 'SHOP_ATTRIBUTE_MODIFY' // dostęp do dodawania/edycji/usuwania atrybutów produktów

// OFFER
export const CONTACT_THREAD_OFFER_VIEW_ALL = 'CONTACT_THREAD_OFFER_VIEW_ALL' // dostęp do widoczności wszystkich ofert w dostępnych wątkach
export const CONTACT_THREAD_ASSIGNED_OFFER_VIEW_ALL = 'CONTACT_THREAD_ASSIGNED_OFFER_VIEW_ALL' // dostęp do widoczności wszystkich ofert w przypisanych wątkach
export const CONTACT_THREAD_ASSIGNED_OFFER_VIEW_SELF = 'CONTACT_THREAD_ASSIGNED_OFFER_VIEW_SELF' // dostęp do widoczności tylko własnych ofert w przypisanych wątkach
export const CONTACT_THREAD_OFFER_ADD = 'CONTACT_THREAD_OFFER_ADD' // dostęp do dodawania nowych ofert w dostępych wątakch
export const CONTACT_THREAD_OFFER_EDIT = 'CONTACT_THREAD_OFFER_EDIT' // dostęp do edycji ofert w dostępych wątakch
export const CONTACT_THREAD_CART_DISCOUNT_EDIT = 'CONTACT_THREAD_CART_DISCOUNT_EDIT'
export const CONTACT_THREAD_CART_HIDE_PRICE_MODIFY = 'CONTACT_THREAD_CART_HIDE_PRICE_MODIFY'

// AGREEMENT
export const CONTACT_THREAD_AGREEMENT_VIEW_ALL = 'CONTACT_THREAD_AGREEMENT_VIEW_ALL' // dostęp do widoczności wszystkich umów w dostępnych wątkach
export const CONTACT_THREAD_ASSIGNED_AGREEMENT_VIEW_ALL = 'CONTACT_THREAD_ASSIGNED_AGREEMENT_VIEW_ALL' // dostęp do widoczności wszystkich umów w przypisanych wątkach
export const CONTACT_THREAD_ASSIGNED_AGREEMENT_VIEW_SELF = 'CONTACT_THREAD_ASSIGNED_AGREEMENT_VIEW_SELF' // dostęp do widoczności tylko własnych umów w przypisanych wątkach
export const CONTACT_THREAD_AGREEMENT_ADD = 'CONTACT_THREAD_AGREEMENT_ADD' // dostęp do dodawania nowych umów w dostępych wątakch
export const CONTACT_THREAD_AGREEMENT_EDIT = 'CONTACT_THREAD_AGREEMENT_EDIT' // dostęp do edycji umów w dostępych wątakch
export const CONTACT_THREAD_AGREEMENT_DELETE = 'CONTACT_THREAD_AGREEMENT_DELETE' // dostęp do usuwania umów w dostępych wątakch

// PAYMENT
export const CONTACT_THREAD_PAYMENT_VIEW_ALL = 'CONTACT_THREAD_PAYMENT_VIEW_ALL' // dostęp do widoczności wszystkich umów w dostępnych wątkach
export const CONTACT_THREAD_ASSIGNED_PAYMENT_VIEW_ALL = 'CONTACT_THREAD_ASSIGNED_PAYMENT_VIEW_ALL' // dostęp do widoczności wszystkich umów w przypisanych wątkach
export const CONTACT_THREAD_ASSIGNED_PAYMENT_VIEW_SELF = 'CONTACT_THREAD_ASSIGNED_PAYMENT_VIEW_SELF' // dostęp do widoczności tylko własnych umów w przypisanych wątkach
export const CONTACT_THREAD_PAYMENT_ADD = 'CONTACT_THREAD_PAYMENT_ADD' // dostęp do dodawania nowych umów w dostępych wątakch
export const CONTACT_THREAD_PAYMENT_COLLECT_ADD = 'CONTACT_THREAD_PAYMENT_COLLECT_ADD'
export const CONTACT_THREAD_PAYMENT_DELETE = 'CONTACT_THREAD_PAYMENT_DELETE'
export const APPLICATION_VIEW_ALL = 'APPLICATION_VIEW_ALL'

export const CONTACT_THREAD_ORDER_VIEW_ALL = 'CONTACT_THREAD_ORDER_VIEW_ALL' // dostęp do widoczności wszystkich zamówień w dostępnych wątkach
export const CONTACT_THREAD_ASSIGNED_ORDER_VIEW_ALL = 'CONTACT_THREAD_ASSIGNED_ORDER_VIEW_ALL' // dostęp do widoczności wszystkich zamówień w przypisanych wątkach
export const CONTACT_THREAD_ASSIGNED_ORDER_VIEW_SELF = 'CONTACT_THREAD_ASSIGNED_ORDER_VIEW_SELF' // dostęp do widoczności tylko własnych zamówień w przypisanych wątkach
export const CONTACT_THREAD_ORDER_ADD = 'CONTACT_THREAD_ORDER_ADD' // dostęp do dodawania nowych zamówień w dostępych wątakch
export const CONTACT_THREAD_ORDER_DELETE = 'CONTACT_THREAD_ORDER_DELETE'

export const LOYALTY_PLAN_VIEW_ALL = 'LOYALTY_PLAN_VIEW_ALL' // Widok planów lojalnościowych
export const LOYALTY_PLAN_MODIFY = 'LOYALTY_PLAN_MODIFY' // Edycja planów lojalnościowych
export const LOYALTY_PLAN_POINT_VIEW_ALL = 'LOYALTY_PLAN_POINT_VIEW_ALL' // Widok punktów planów lojalnościowych
export const LOYALTY_PLAN_POINT_VIEW_SELF = 'LOYALTY_PLAN_POINT_VIEW_SELF' // Widok punktów planów lojalnościowych
export const LOYALTY_PLAN_POINT_VIEW_STRUCTURE = 'LOYALTY_PLAN_POINT_VIEW_STRUCTURE' // Widok punktów planów lojalnościowych
export const LOYALTY_PLAN_POINT_MODIFY = 'LOYALTY_PLAN_POINT_MODIFY' // Edycja punktów planów lojalnościowych

export const MARKETING_PLAN_VIEW_ALL = 'MARKETING_PLAN_VIEW_ALL'
export const MARKETING_PLAN_MODIFY = 'MARKETING_PLAN_MODIFY'

export const MARKETING_PLAN_POINT_CART_MODIFY = 'MARKETING_PLAN_POINT_CART_MODIFY'
export const MARKETING_PLAN_POINT_VIEW_ALL = 'MARKETING_PLAN_POINT_VIEW_ALL'
export const MARKETING_PLAN_POINT_VIEW_SELF = 'MARKETING_PLAN_POINT_VIEW_SELF'
export const MARKETING_PLAN_POINT_VIEW_STRUCTURE = 'MARKETING_PLAN_POINT_VIEW_STRUCTURE'
export const MARKETING_PLAN_POINT_MODIFY = 'MARKETING_PLAN_POINT_MODIFY'

// PROJECTS
export const PROJECT_VIEW_ALL = 'PROJECT_VIEW_ALL' // Allows to see all the projects
export const PROJECT_MODIFY = 'PROJECT_MODIFY' // Allows to edit all the projects

export const MARKETING_PLAN_POINT_SETTLEMENT = 'MARKETING_PLAN_POINT_SETTLEMENT'

// export const USER_LOYALTY_PLAN_VIEW_ALL = 'USER_LOYALTY_PLAN_VIEW_ALL'
// export const USER_LOYALTY_PLAN_VIEW_SELF = 'USER_LOYALTY_PLAN_VIEW_SELF'
// export const USER_LOYALTY_PLAN_VIEW_STRUCTURE = 'USER_LOYALTY_PLAN_VIEW_STRUCTURE'
// export const USER_LOYALTY_PLAN_VIEW_STRUCTURE_FIRST = 'USER_LOYALTY_PLAN_VIEW_STRUCTURE_FIRST'

// CLIENT
export const CLIENT_VIEW_ALL = 'CLIENT_VIEW_ALL'
export const CLIENT_EDIT_ALL = 'CLIENT_EDIT_ALL'
export const CLIENT_DELETE_ALL = 'CLIENT_DELETE_ALL'
// CLIENT CHAT
export const CLIENT_CHAT_MOBILE_VIEW_ALL = 'CLIENT_CHAT_MOBILE_VIEW_ALL' // dostęp do widoczności wszystkich wiadomosci mobile
export const CLIENT_CHAT_WHATSAPP_VIEW_ALL = 'CLIENT_CHAT_WHATSAPP_VIEW_ALL'// dostęp do widoczności wszystkich wiadomosci whatsapp
export const CLIENT_CHAT_MESSENGER_VIEW_ALL = 'CLIENT_CHAT_MESSENGER_VIEW_ALL'// dostęp do widoczności wszystkich wiadomosci messenger
export const CLIENT_CHAT_MOBILE_VIEW_ASSIGNED_CONTACT_THREAD = 'CLIENT_CHAT_MOBILE_VIEW_ASSIGNED_CONTACT_THREAD' // dostęp do widoczności wiadomosci mobile, w których użytkownik jest przypsany do wątku
export const CLIENT_CHAT_WHATSAPP_VIEW_ASSIGNED_CONTACT_THREAD = 'CLIENT_CHAT_WHATSAPP_VIEW_ASSIGNED_CONTACT_THREAD' // dostęp do widoczności wiadomosci whatsapp, w których użytkownik jest przypsany do wątku
export const CLIENT_CHAT_MESSENGER_VIEW_ASSIGNED_CONTACT_THREAD = 'CLIENT_CHAT_MESSENGER_VIEW_ASSIGNED_CONTACT_THREAD' // dostęp do widoczności wiadomosci messenger, w których użytkownik jest przypsany do wątku
// NEW
export const LOYALTY_PLAN_POINT_VIEW_STRUCTURE_FIRST = 'LOYALTY_PLAN_POINT_VIEW_STRUCTURE_FIRST'
export const USER_RANKING_MARKETING_VIEW_ALL = 'USER_RANKING_MARKETING_VIEW_ALL'
export const USER_RANKING_LOYALTY_VIEW_ALL = 'USER_RANKING_LOYALTY_VIEW_ALL'

export const CLIENT_CHAT_BROWSER_VIEW_ALL = 'CLIENT_CHAT_BROWSER_VIEW_ALL' // dostęp do widoczności wszystkich wiadomosci browser (widget chat)
export const CLIENT_CHAT_BROWSER_VIEW_ASSIGNED_CONTACT_THREAD = 'CLIENT_CHAT_BROWSER_VIEW_ASSIGNED_CONTACT_THREAD' // dostęp do widoczności wiadomosci browser (widget chat), w których użytkownik jest przypsany do wątku

// RINGOSTAT
export const PHONE_CALLS_USE = 'PHONE_CALLS_USE'
export const PHONE_CALLS_VIEW_ALL = 'PHONE_CALLS_VIEW_ALL'
export const PHONE_CALLS_VIEW_EDIT = 'PHONE_CALLS_VIEW_EDIT'

// Migration
export const MIGRATION_VIEW_ALL = 'MIGRATION_VIEW_ALL' // dostęp do widoczności wszystkich migracji
export const MIGRATION_EXPORT_VIEW_ALL = 'MIGRATION_EXPORT_VIEW_ALL' // dostęp do widoczności wszystkich migracji exportu
export const MIGRATION_IMPORT_VIEW_ALL = 'MIGRATION_IMPORT_VIEW_ALL' // dostęp do widoczności wszystkich migracji importu

export const MIGRATION_EXPORT_USE = 'MIGRATION_EXPORT_USE' // dostęp do wykonywania migracji exportu
export const MIGRATION_IMPORT_USE = 'MIGRATION_IMPORT_USE' // dostęp do wykonywania migracji importu

export const CONTACT_THREAD_GOOGLE_ADS_CLIENT_ACCOUNT_VIEW_ALL = 'CONTACT_THREAD_GOOGLE_ADS_CLIENT_ACCOUNT_VIEW_ALL' // dostęp do widoczności wszystkich kont klientów Google Ads w dostępnych wątkach
export const CONTACT_THREAD_GOOGLE_ADS_CLIENT_ACCOUNT_ADD = 'CONTACT_THREAD_GOOGLE_ADS_CLIENT_ACCOUNT_ADD' // dostęp do dodawania nowych kont klientów Google Ads w dostępych wątakch
export const CONTACT_THREAD_GOOGLE_ADS_CLIENT_ACCOUNT_EDIT = 'CONTACT_THREAD_GOOGLE_ADS_CLIENT_ACCOUNT_EDIT' // dostęp do edytowania kont klientów Google Ads w dostępych wątakch
export const CONTACT_THREAD_GOOGLE_ADS_CLIENT_ACCOUNT_DELETE = 'CONTACT_THREAD_GOOGLE_ADS_CLIENT_ACCOUNT_DELETE' // dostęp do usuwania kont klientów Google Ads w dostępych wątakch

export const CONTACT_THREAD_PAYMENT_SCHEDULE_VIEW_ALL = 'CONTACT_THREAD_PAYMENT_SCHEDULE_VIEW_ALL' // dostęp do widoczności wszystkich harmonogramów płatnośći w dostępnych wątkach
export const CONTACT_THREAD_PAYMENT_SCHEDULE_ADD = 'CONTACT_THREAD_PAYMENT_SCHEDULE_ADD' // dostęp do dodawania nowych harmonogramów płatnośći w dostępych wątakch
export const CONTACT_THREAD_PAYMENT_SCHEDULE_EDIT = 'CONTACT_THREAD_PAYMENT_SCHEDULE_ADD' // dostęp do dodawania nowych harmonogramów płatnośći w dostępych wątakch
export const CONTACT_THREAD_PAYMENT_SCHEDULE_DELETE = 'CONTACT_THREAD_PAYMENT_SCHEDULE_DELETE' // dostęp do usuwania harmonogramów płatnośći w dostępych wątakch

export const getRanksList = () => [
  // Contacts
  {
    section: 'Contacts',
    subsections: [],
    ranks: {
      view: [CONTACT_VIEW_ALL, CONTACT_VIEW_STRUCTURE, CONTACT_VIEW_STRUCTURE_FIRST, CONTACT_VIEW_ASSIGNED],
      modify: [CONTACT_ADD, CONTACT_ADD_IMPORT, CONTACT_EDIT_DETAILS, CONTACT_EDIT_ASSIGNED_USERS, CONTACT_ADDON_FIELD_MODIFY],
      delete: [CONTACT_DELETE],
    },
  },
  {
    section: 'Threads',
    subsections: [
      {
        section: 'Tasks',
        ranks: {
          view: [CONTACT_THREAD_TASK_VIEW_ALL, CONTACT_THREAD_TASK_VIEW_ASSIGNED, CONTACT_THREAD_TASK_VIEW_STRUCTURE],
          modify: [CONTACT_THREAD_TASK_ADD, CONTACT_THREAD_ASSIGNED_TASK_ADD, CONTACT_THREAD_TASK_EDIT_SELF, CONTACT_THREAD_TASK_EDIT, CONTACT_THREAD_TASK_CHANGE_STATUS_ALL, CONTACT_THREAD_TASK_CHANGE_STATUS_ASSIGNED],
          delete: [CONTACT_THREAD_TASK_DELETE],
        },
      },
      {
        section: 'Notes',
        ranks: {
          view: [CONTACT_THREAD_NOTE_VIEW_ALL, CONTACT_THREAD_NOTE_VIEW_SHARED, CONTACT_THREAD_NOTE_VIEW_SELF],
          modify: [CONTACT_THREAD_NOTE_ADD, CONTACT_THREAD_ASSIGNED_NOTE_ADD, CONTACT_THREAD_NOTE_EDIT_SELF],
          delete: [CONTACT_THREAD_NOTE_DELETE_SELF],
        },
      },
      {
        section: 'ranks.sections.threadChecklists',
        subsections: [],
        ranks: {
          view: [CONTACT_THREAD_CHECKLIST_VIEW_ALL],
          modify: [CONTACT_THREAD_CHECKLIST_EDIT],
          delete: [],
        },
      },
      {
        section: 'ranks.sections.threadTimeline',
        subsections: [],
        ranks: {
          view: [CONTACT_THREAD_TIMELINE_VIEW_ALL],
          modify: [],
          delete: [],
        },
      },
      {
        section: 'Emails',
        subsections: [],
        ranks: {
          view: [CONTACT_THREAD_MAIL_VIEW_ALL],
          modify: [CONTACT_THREAD_MAIL_ADD],
          delete: [],
        },
      },
      {
        section: 'Sms',
        subsections: [],
        ranks: {
          view: [CONTACT_THREAD_SMS_VIEW_ALL, CONTACT_THREAD_SMS_VIEW_SHARED, CONTACT_THREAD_SMS_VIEW_SELF],
          modify: [CONTACT_THREAD_SMS_ADD],
          delete: [],
        },
      },
      {
        section: 'Polls',
        subsections: [],
        ranks: {
          view: [CONTACT_THREAD_POLL_VIEW_ALL],
          modify: [CONTACT_THREAD_POLL_MODIFY],
          delete: [CONTACT_THREAD_POLL_DELETE],
        },
        meta: {
          requiredModules: ['pollModule'],
        },
      },
      {
        section: 'Applications',
        subsections: [],
        ranks: {
          view: [{ rank: APPLICATION_VIEW_ALL, information: 'RequiredApplicationRanks' }, APPLICATION_VIEW_ASSIGNED_CONTACT_THREAD],
          modify: [],
          delete: [APPLICATION_DELETE],
        },
        meta: {
          requiredRanks: [CONTACT_THREAD_POLL_VIEW_ALL],
          requiredModules: ['applicationModule'],
        },
      },
    ],
    ranks: {
      view: [CONTACT_THREAD_VIEW_ALL, CONTACT_THREAD_VIEW_STRUCTURE, CONTACT_THREAD_VIEW_STRUCTURE_FIRST, CONTACT_THREAD_VIEW_ASSIGNED],
      modify: [CONTACT_THREAD_ADD, CONTACT_THREAD_EDIT_ALL, CONTACT_THREAD_EDIT_ASSIGNED, CONTACT_THREAD_EDIT_SELF_ASSIGNED],
      delete: [CONTACT_THREAD_DELETE],
    },
    meta: {
      requiredRanks: [CONTACT_VIEW_ALL, CONTACT_VIEW_STRUCTURE, CONTACT_VIEW_ASSIGNED, CONTACT_VIEW_STRUCTURE_FIRST],
    },
  },
  {
    section: 'MobileClients',
    subsections: [],
    ranks: {
      view: [CLIENT_VIEW_ALL],
      modify: [CLIENT_EDIT_ALL],
      delete: [],
    },
    meta: {
      requiredModules: ['clientMobileModule'],
    },
  },
  {
    section: 'ClientChat',
    subsections: [
      {
        section: 'MobileClients',
        ranks: {
          view: [CLIENT_CHAT_MOBILE_VIEW_ALL, CLIENT_CHAT_MOBILE_VIEW_ASSIGNED_CONTACT_THREAD],
          modify: [],
          delete: [],
        },
        meta: {
          requiredModules: ['clientMobileModule'],
        },
      },
      {
        section: 'Whatsapp',
        ranks: {
          view: [CLIENT_CHAT_WHATSAPP_VIEW_ALL, CLIENT_CHAT_WHATSAPP_VIEW_ASSIGNED_CONTACT_THREAD],
          modify: [],
          delete: [],
        },
        meta: {
          requiredModules: ['whatsappModule'],
        },
      },
      {
        section: 'Widget',
        ranks: {
          view: [CLIENT_CHAT_BROWSER_VIEW_ALL, CLIENT_CHAT_BROWSER_VIEW_ASSIGNED_CONTACT_THREAD],
          modify: [],
          delete: [],
        },
        meta: {
          requiredModules: ['clientBrowserModule'],
        },
      },
    ],
    // ranks: {
    //   view: [
    //     CLIENT_CHAT_MOBILE_VIEW_ALL,
    //     CLIENT_CHAT_MOBILE_VIEW_ASSIGNED_CONTACT_THREAD,
    //
    //     CLIENT_CHAT_WHATSAPP_VIEW_ALL,
    //     CLIENT_CHAT_WHATSAPP_VIEW_ASSIGNED_CONTACT_THREAD,
    //
    //     // CLIENT_CHAT_MESSENGER_VIEW_ALL,
    //     // CLIENT_CHAT_MESSENGER_VIEW_ASSIGNED_CONTACT_THREAD,
    //     CLIENT_CHAT_BROWSER_VIEW_ALL,
    //     CLIENT_CHAT_BROWSER_VIEW_ASSIGNED_CONTACT_THREAD,
    //   ],
    //   modify: [CLIENT_EDIT_ALL],
    //   delete: [],
    // },
    meta: {
      requiredModules: ['clientMobileModule', 'whatsappModule', 'clientBrowserModule'],
    },
  },
  // Contacts :: END
  // Threads
  // Threads :: END
  // Users
  {
    section: 'Users',
    subsections: [],
    ranks: {
      view: [USER_VIEW_ALL, USER_VIEW_STRUCTURE],
      modify: [USER_ADD, USER_EDIT, USER_RESET_PASSWORD],
      delete: [],
    },
  },
  {
    section: 'Organizations',
    subsections: [],
    ranks: {
      view: [ORGANIZATION_VIEW_ALL],
      modify: [],
      delete: [ORGANIZATION_DELETE],
    },
  },
  // Offer
  {
    section: 'Offers',
    subsections: [
      {
        section: 'ProductEdit',
        subsections: [],
        ranks: {
          view: [SHOP_PRODUCT_VIEW_ALL, SHOP_PRODUCT_VIEW_GROUP],
          modify: [SHOP_PRODUCT_MODIFY],
          delete: [],
        },
      },
      {
        section: 'Categories',
        subsections: [],
        ranks: {
          view: [],
          modify: [SHOP_CATEGORY_MODIFY],
          delete: [],
        },
      },
      {
        section: 'AttributeList',
        subsections: [],
        ranks: {
          view: [],
          modify: [SHOP_ATTRIBUTE_MODIFY],
          delete: [],
        },
      },
    ],
    ranks: {
      view: [CONTACT_THREAD_OFFER_VIEW_ALL, CONTACT_THREAD_ASSIGNED_OFFER_VIEW_ALL, CONTACT_THREAD_ASSIGNED_OFFER_VIEW_SELF],
      modify: [CONTACT_THREAD_OFFER_ADD, CONTACT_THREAD_OFFER_EDIT, CONTACT_THREAD_CART_DISCOUNT_EDIT, CONTACT_THREAD_CART_HIDE_PRICE_MODIFY],
      delete: [],
    },
    meta: {
      requiredModules: ['offerModule'],
    },
  },
  // Orders
  {
    section: 'Orders',
    subsections: [],
    ranks: {
      view: [CONTACT_THREAD_ORDER_VIEW_ALL, CONTACT_THREAD_ASSIGNED_ORDER_VIEW_ALL, CONTACT_THREAD_ASSIGNED_ORDER_VIEW_SELF],
      modify: [CONTACT_THREAD_ORDER_ADD],
      delete: [CONTACT_THREAD_ORDER_DELETE],
    },
    meta: {
      requiredModules: ['orderModule'],
    },
  },
  // Marketing Points
  {
    section: 'MarketingPlans',
    subsections: [
      {
        section: 'Commissions',
        subsections: [],
        ranks: {
          view: [MARKETING_PLAN_POINT_VIEW_ALL, MARKETING_PLAN_POINT_VIEW_SELF, MARKETING_PLAN_POINT_VIEW_STRUCTURE],
          modify: [MARKETING_PLAN_POINT_MODIFY, MARKETING_PLAN_POINT_CART_MODIFY, MARKETING_PLAN_POINT_SETTLEMENT],
          delete: [],
        },
      },
    ],
    ranks: {
      view: [MARKETING_PLAN_VIEW_ALL],
      modify: [MARKETING_PLAN_MODIFY],
      delete: [],
    },
    meta: {
      requiredModules: ['marketingModule'],
    },
  },
  {
    section: 'Structure',
    ranks: {
      view: [
        USER_STRUCTURE_VIEW_ALL,
        USER_STRUCTURE_VIEW_STRUCTURE,
        USER_STRUCTURE_VIEW_STRUCTURE_FIRST,
      ],
      modify: [],
      delete: [],
    },
    meta: {
      requiredModules: ['orderModule'],
    },
  },
  {
    section: 'Ranking',
    ranks: {
      view: [
        USER_RANKING_MARKETING_VIEW_ALL,
        USER_RANKING_LOYALTY_VIEW_ALL,
      ],
      modify: [],
      delete: [],
    },
    meta: {
      requiredModules: ['marketingModule'],
    },
  },
  // Loyalty Points
  {
    section: 'LoyaltyPlans',
    subsections: [
      {
        section: 'Points',
        subsections: [],
        ranks: {
          view: [
            LOYALTY_PLAN_POINT_VIEW_ALL,
            LOYALTY_PLAN_POINT_VIEW_SELF,
            LOYALTY_PLAN_POINT_VIEW_STRUCTURE,
            LOYALTY_PLAN_POINT_VIEW_STRUCTURE_FIRST,
            // USER_LOYALTY_PLAN_VIEW_ALL,
            // USER_LOYALTY_PLAN_VIEW_SELF,
            // USER_LOYALTY_PLAN_VIEW_STRUCTURE,
            // USER_LOYALTY_PLAN_VIEW_STRUCTURE_FIRST,
          ],
          modify: [LOYALTY_PLAN_POINT_MODIFY],
          delete: [],
        },
      },
    ],
    ranks: {
      view: [LOYALTY_PLAN_VIEW_ALL],
      modify: [LOYALTY_PLAN_MODIFY],
      delete: [],
    },
    meta: {
      requiredModules: ['loyaltyModule'],
    },
  },
  // Agreements
  {
    section: 'AgreementsCount',
    subsections: [
      {
        section: 'contactTabs.Payments',
        subsections: [],
        ranks: {
          view: [CONTACT_THREAD_PAYMENT_VIEW_ALL, CONTACT_THREAD_ASSIGNED_PAYMENT_VIEW_ALL, CONTACT_THREAD_ASSIGNED_PAYMENT_VIEW_SELF],
          modify: [CONTACT_THREAD_PAYMENT_ADD, CONTACT_THREAD_PAYMENT_COLLECT_ADD],
          delete: [CONTACT_THREAD_PAYMENT_DELETE],
        },
      },
    ],
    ranks: {
      view: [CONTACT_THREAD_AGREEMENT_VIEW_ALL, CONTACT_THREAD_ASSIGNED_AGREEMENT_VIEW_ALL, CONTACT_THREAD_ASSIGNED_AGREEMENT_VIEW_SELF],
      modify: [CONTACT_THREAD_AGREEMENT_ADD, CONTACT_THREAD_AGREEMENT_EDIT],
      delete: [CONTACT_THREAD_AGREEMENT_DELETE],
    },
    meta: {
      requiredModules: ['agreementModule'],
    },
  },
  // Users :: END

  // Projects
  {
    section: 'Projects',
    ranks: {
      view: [PROJECT_VIEW_ALL],
      modify: [PROJECT_MODIFY],
      delete: [],
    },
    meta: {
      requiredRanks: [CONTACT_THREAD_VIEW_ALL, USER_VIEW_ALL, USER_VIEW_STRUCTURE],
      requiredModules: ['projectModule'],
    },
  },
  // GoogleAds
  {
    section: 'Google Ads',
    ranks: {
      view: [CONTACT_THREAD_GOOGLE_ADS_CLIENT_ACCOUNT_VIEW_ALL],
      modify: [CONTACT_THREAD_GOOGLE_ADS_CLIENT_ACCOUNT_ADD, CONTACT_THREAD_GOOGLE_ADS_CLIENT_ACCOUNT_EDIT],
      delete: [],
    },
    meta: {
      requiredModules: ['googleAdsModule'],
    },
  },
  // Fakturownia
  {
    section: 'Fakturownia',
    ranks: {
      view: [CONTACT_THREAD_PAYMENT_SCHEDULE_VIEW_ALL],
      modify: [CONTACT_THREAD_PAYMENT_SCHEDULE_ADD, CONTACT_THREAD_PAYMENT_SCHEDULE_EDIT],
      delete: [],
    },
    meta: {
      requiredModules: ['fakturowniaModule'],
    },
  },
  {
    section: 'Complaints',
    ranks: {
      view: [
        CONTACT_THREAD_COMPLAINT_VIEW_ALL,
        CONTACT_THREAD_ASSIGNED_COMPLAINT_VIEW_ALL,
        CONTACT_THREAD_ASSIGNED_COMPLAINT_VIEW_SELF,
      ],
      modify: [
        CONTACT_THREAD_COMPLAINT_ADD,
        CONTACT_THREAD_COMPLAINT_EDIT,
      ],
      delete: [
        CONTACT_THREAD_COMPLAINT_DELETE,
      ],
    },
    meta: {
      requiredModules: ['complaintModule'],
    },
  },

  {
    section: 'News',
    subsections: [],
    ranks: {
      view: [NEWS_POST_VIEW_ALL],
      modify: [NEWS_POST_MODIFY],
      delete: [],
    },
    meta: {
      requiredRanks: [USER_VIEW_ALL],
      requiredModules: ['newsModule'],
    },
  },

  {
    section: 'Ai',
    subsections: [],
    ranks: {
      view: [CHAT_GPT_USE, CHAT_GPT_REPORT_ADD],
    },
    meta: {
      requiredModules: ['chatGptModule'],
    },
  },
  {
    section: 'Integrations',
    subsections: [
      {
        section: 'ImageRecognition',
        subsections: [],
        ranks: {
          view: [IMAGE_RECOGNITION_USE],
        },
        meta: {
          requiredModules: ['imageRecognitionModule'],
        },
      },
      {
        section: 'Ringostat',
        subsections: [],
        ranks: {
          view: [PHONE_CALLS_VIEW_ALL],
          modify: [PHONE_CALLS_USE, PHONE_CALLS_VIEW_EDIT],
        },
        meta: {
          requiredHostnames: ['4b', 'test'],
          requiredModules: ['callModule'],
        },
      },
    ],
    ranks: {},
    meta: {},
  },

  // Global
  {
    section: 'Global',
    subsections: [
      {
        section: 'Chat',
        subsections: [],
        ranks: {
          view: [
            { rank: CHAT_VIEW_ALL, information: 'IndependentChatRank' },
            { rank: CHAT_VIEW_RANK, type: 'select', field: 'chatViewRanks' },
          ],
          modify: [],
          delete: [],
        },
      },
      {
        section: 'Trainings',
        subsections: [],
        ranks: {
          view: [TRAINING_VIEW_ALL],
          modify: [],
          delete: [],
        },
      },
      {
        section: 'Onboarding',
        subsections: [],
        ranks: {
          view: [ONBOARDING_VIEW_ALL],
          modify: [],
          delete: [],
        },
      },
      {
        section: 'Documents',
        subsections: [],
        ranks: {
          view: [DOCUMENT_VIEW_ALL, DOCUMENT_VIEW_SHARED],
          modify: [DOCUMENT_MODIFY],
          delete: [],
        },
      },
      {
        section: 'Settings',
        subsections: [
          {
            section: 'Settings',
            subsections: [],
            ranks: {
              view: [SETTINGS, ACTUALIZATIONS, REPORT_VIEW_ALL],
              modify: [],
              delete: [],
            },
          },
        ],
        ranks: {
          view: [SETTINGS, ACTUALIZATIONS, REPORT_VIEW_ALL],
          modify: [],
          delete: [],
        },
      },
    ],
    ranks: {
      view: [],
      modify: [],
      delete: [],
    },
  },

  {
    section: 'Automations',
    subsections: [],
    ranks: {
      view: [AUTOMATION_VIEW_ALL],
      modify: [AUTOMATION_MODIFY],
      delete: [],
    },
    meta: {
      requiredRanks: [SETTINGS],
      requiredModules: ['automationModule'],
    },
  },
  {
    section: 'Automations',
    subsections: [],
    ranks: {
      view: [MIGRATION_VIEW_ALL, MIGRATION_IMPORT_VIEW_ALL, MIGRATION_EXPORT_VIEW_ALL],
      modify: [MIGRATION_IMPORT_USE, MIGRATION_EXPORT_USE],
      delete: [],
    },
    meta: {
      requiredRanks: [],
      requiredModules: ['migrationModule'],
    },
  },
  // Global :: END
]
