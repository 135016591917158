const vatIdTypeList = [
  {
    country: 'Poland',
    value: 'eu_vat',
    placeholder: 'PL1234567890',
  },
  {
    country: 'Australia',
    value: 'au_abn',
    placeholder: '12345678912',
  },
  {
    country: 'Australia',
    value: 'au_arn',
    placeholder: '123456789123',
  },
  {
    country: 'Austria',
    value: 'eu_vat',
    placeholder: 'ATU12345678',
  },
  {
    country: 'Belgium',
    value: 'eu_vat',
    placeholder: 'BE0123456789',
  },
  {
    country: 'Brazil',
    value: 'br_cnpj',
    placeholder: '01.234.456/5432-10',
  },
  {
    country: 'Brazil',
    value: 'br_cpf',
    placeholder: '123.456.789-87',
  },
  {
    country: 'Bulgaria',
    value: 'bg_uic',
    placeholder: '123456789',
  },
  {
    country: 'Bulgaria',
    value: 'eu_vat',
    placeholder: 'BG0123456789',
  },
  {
    country: 'Canada',
    value: 'ca_bn',
    placeholder: '123456789',
  },
  {
    country: 'Canada',
    value: 'ca_gst_hst',
    placeholder: '123456789RT0002',
  },
  {
    country: 'Canada',
    value: 'ca_pst_bc',
    placeholder: 'PST-1234-5678',
  },
  {
    country: 'Canada',
    value: 'ca_pst_mb',
    placeholder: '123456-7',
  },
  {
    country: 'Canada',
    value: 'ca_pst_sk',
    placeholder: '1234567',
  },
  {
    country: 'Canada',
    value: 'ca_qst',
    placeholder: '1234567890TQ1234',
  },
  {
    country: 'Chile',
    value: 'cl_tin',
    placeholder: '12.345.678-K',
  },
  {
    country: 'Croatia',
    value: 'eu_vat',
    placeholder: 'HR12345678912',
  },
  {
    country: 'Cyprus',
    value: 'eu_vat',
    placeholder: 'CY12345678Z',
  },
  {
    country: 'Czech Republic',
    value: 'eu_vat',
    placeholder: 'CZ1234567890',
  },
  {
    country: 'Denmark',
    value: 'eu_vat',
    placeholder: 'DK12345678',
  },
  {
    country: 'Egypt',
    value: 'eg_tin',
    placeholder: '123456789',
  },
  {
    country: 'Estonia',
    value: 'eu_vat',
    placeholder: 'EE123456789',
  },
  {
    country: 'EU',
    value: 'eu_oss_vat',
    placeholder: 'EU123456789',
  },
  {
    country: 'Finland',
    value: 'eu_vat',
    placeholder: 'FI12345678',
  },
  {
    country: 'France',
    value: 'eu_vat',
    placeholder: 'FRAB123456789',
  },
  {
    country: 'Georgia',
    value: 'ge_vat',
    placeholder: '123456789',
  },
  {
    country: 'Germany',
    value: 'eu_vat',
    placeholder: 'DE123456789',
  },
  {
    country: 'Greece',
    value: 'eu_vat',
    placeholder: 'EL123456789',
  },
  {
    country: 'Hong Kong',
    value: 'hk_br',
    placeholder: '12345678',
  },
  {
    country: 'Hungary',
    value: 'eu_vat',
    placeholder: 'HU12345678912',
  },
  {
    country: 'Hungary',
    value: 'hu_tin',
    placeholder: '12345678-1-23',
  },
  {
    country: 'Iceland',
    value: 'is_vat',
    placeholder: '123456',
  },
  {
    country: 'India',
    value: 'in_gst',
    placeholder: '12ABCDE3456FGZH',
  },
  {
    country: 'Indonesia',
    value: 'id_npwp',
    placeholder: '12.345.678.9-012.345',
  },
  {
    country: 'Ireland',
    value: 'eu_vat',
    placeholder: 'IE1234567AB',
  },
  {
    country: 'Israel',
    value: 'il_vat',
    placeholder: '000012345',
  },
  {
    country: 'Italy',
    value: 'eu_vat',
    placeholder: 'IT12345678912',
  },
  {
    country: 'Japan',
    value: 'jp_cn',
    placeholder: '1234567891234',
  },
  {
    country: 'Japan',
    value: 'jp_rn',
    placeholder: '12345',
  },
  {
    country: 'Japan',
    value: 'jp_trn',
    placeholder: 'T1234567891234',
  },
  {
    country: 'Kenya',
    value: 'ke_pin',
    placeholder: 'P000111111A',
  },
  {
    country: 'Latvia',
    value: 'eu_vat',
    placeholder: 'LV12345678912',
  },
  {
    country: 'Liechtenstein',
    value: 'li_uid',
    placeholder: 'CHE123456789',
  },
  {
    country: 'Lithuania',
    value: 'eu_vat',
    placeholder: 'LT123456789123',
  },
  {
    country: 'Luxembourg',
    value: 'eu_vat',
    placeholder: 'LU12345678',
  },
  {
    country: 'Malaysia',
    value: 'my_frp',
    placeholder: '12345678',
  },
  {
    country: 'Malaysia',
    value: 'my_itn',
    placeholder: 'C 1234567890',
  },
  {
    country: 'Malaysia',
    value: 'my_sst',
    placeholder: 'A12-3456-78912345',
  },
  {
    country: 'Malta',
    value: 'eu_vat',
    placeholder: 'MT12345678',
  },
  {
    country: 'Mexico',
    value: 'mx_rfc',
    placeholder: 'ABC010203AB9',
  },
  {
    country: 'Netherlands',
    value: 'eu_vat',
    placeholder: 'NL123456789B12',
  },
  {
    country: 'New Zealand',
    value: 'nz_gst',
    placeholder: '123456789',
  },
  {
    country: 'Norway',
    value: 'no_vat',
    placeholder: '123456789MVA',
  },
  {
    country: 'Philippines',
    value: 'ph_tin',
    placeholder: '123456789012',
  },
  {
    country: 'Portugal',
    value: 'eu_vat',
    placeholder: 'PT123456789',
  },
  {
    country: 'Romania',
    value: 'eu_vat',
    placeholder: 'RO1234567891',
  },
  {
    country: 'Russia',
    value: 'ru_inn',
    placeholder: '1234567891',
  },
  {
    country: 'Russia',
    value: 'ru_kpp',
    placeholder: '123456789',
  },
  {
    country: 'Saudi Arabia',
    value: 'sa_vat',
    placeholder: '123456789012345',
  },
  {
    country: 'Singapore',
    value: 'sg_gst',
    placeholder: 'M12345678X',
  },
  {
    country: 'Singapore',
    value: 'sg_uen',
    placeholder: '123456789F',
  },
  {
    country: 'Slovakia',
    value: 'eu_vat',
    placeholder: 'SK1234567891',
  },
  {
    country: 'Slovenia',
    value: 'eu_vat',
    placeholder: 'SI12345678',
  },
  {
    country: 'Slovenia',
    value: 'si_tin',
    placeholder: '12345678',
  },
  {
    country: 'South Africa',
    value: 'za_vat',
    placeholder: '4123456789',
  },
  {
    country: 'South Korea',
    value: 'kr_brn',
    placeholder: '123-45-67890',
  },
  {
    country: 'Spain',
    value: 'es_cif',
    placeholder: 'A12345678',
  },
  {
    country: 'Spain',
    value: 'eu_vat',
    placeholder: 'ESA1234567Z',
  },
  {
    country: 'Sweden',
    value: 'eu_vat',
    placeholder: 'SE123456789123',
  },
  {
    country: 'Switzerland',
    value: 'ch_vat',
    placeholder: 'CHE-123.456.789 MWST',
  },
  {
    country: 'Taiwan',
    value: 'tw_vat',
    placeholder: '12345678',
  },
  {
    country: 'Thailand',
    value: 'th_vat',
    placeholder: '1234567891234',
  },
  {
    country: 'Turkey',
    value: 'tr_tin',
    placeholder: '0123456789',
  },
  {
    country: 'Ukraine',
    value: 'ua_vat',
    placeholder: '123456789',
  },
  {
    country: 'United Arab Emirates',
    value: 'ae_trn',
    placeholder: '123456789012345',
  },
  {
    country: 'United Kingdom',
    value: 'eu_vat',
    placeholder: 'XI123456789',
  },
  {
    country: 'United Kingdom',
    value: 'gb_vat',
    placeholder: 'GB123456789',
  },
  {
    country: 'United States',
    value: 'us_ein',
    placeholder: '12-3456789',
  },
]

export default vatIdTypeList
