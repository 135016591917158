/* eslint-disable */
import typeSettings from '@/store/core/settings/type'
import contacts from '@/store/core/contact/contacts'
import users from '@/store/core/users/users'
import profileSettings from '@/store/core/users/profileSettings'
import mailbox from '@/store/core/users/mailbox'
import singleContact from '@/store/core/contact/singleContact'
import contact from '@/store/core/contact/contact'
import ranks from '@/store/core/settings/ranks/ranks'
import funnelMain from '@/store/funnel/funnel'
import uploader from '@/store/core/uploader/uploader'
import importer from '@/store/core/importer/importer'
import templates from '@/store/core/settings/templates/templates'
import globalTasks from '@/store/core/globalTasks/globalTasks'
import dashboard from '@/store/core/dashboard/_OLD_/dashboard'
import notifications from '@/store/core/notifications/notifications'
import groups from '@/store/core/settings/groups/groups'
import system from '@/store/core/settings/system/system'
import emails from '@/store/core/emails/emails'
import timeline from '@/store/core/globalTimeline/globalTimeline'
import searchBar from '@/store/core/searchBar/searchBar'
import chat from '@/store/core/chat/chat'
import documents from '@/store/core/contact/documents/documents'
import globalDocuments from '@/store/core/documents/documents'
import authLogs from '@/store/core/users/authLogs'
import applications from '@/store/core/applications/applications'
import globalCalendar from '@/store/core/gloablCalendar/globalCalendar'
import projects from '@/store/core/projects/projects'
import tasks from '@/store/core/projects/tasks'
import microTasks from '@/store/core/projects/microTasks'
import projectStatuses from '@/store/core/settings/projects/statusProjects'
import taskStatuses from '@/store/core/settings/projects/statusTask'
import projectComments from '@/store/core/projects/comments/projects'
import taskComments from '@/store/core/projects/comments/tasks'
import taskCategories from '@/store/core/settings/projects/categories'
import projectDocuments from '@/store/core/projects/documents'
import projectTags from '@/store/core/settings/projects/tags'
import addonFields from '@/store/core/contact/addonFields'
import addonFieldTypes from '@/store/core/settings/contacts/addonFieldTypes'
import chatWidgets from '@/store/core/settings/chats/widget'
import news from '@/store/core/news/news'
import ai from '@/store/core/ai/ai'
import swModal from '@/store/core/utils/root/swModal'
import regon from '@/store/core/regon/regon'
import globalEmails from '@/store/core/globalEmails/globalEmails'
import campaigns from '@/store/core/campaigns/campaigns'

// eslint-disable-next-line import/prefer-default-export
export const core = {
  typeSettings,
  contacts,
  users,
  profileSettings,
  mailbox,
  singleContact,
  contact,
  ranks,
  funnelMain,
  uploader,
  importer,
  templates,
  globalTasks,
  dashboard,
  notifications,
  groups,
  system,
  emails,
  timeline,
  searchBar,
  chat,
  documents,
  authLogs,
  globalDocuments,
  applications,
  globalCalendar,
  projects,
  tasks,
  microTasks,
  projectStatuses,
  taskStatuses,
  projectComments,
  taskComments,
  taskCategories,
  projectDocuments,
  projectTags,
  addonFields,
  addonFieldTypes,
  chatWidgets,
  news,
  ai,
  regon,
  swModal,
  globalEmails,
  campaigns,
}
