import { isToday } from './utils'

export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

export const title = (value, replacer = ' ') => {
  if (!value) return ''
  const str = value.toString()

  const arr = str.split(replacer)
  const capitalizedArray = []
  arr.forEach(word => {
    const capitalized = word.charAt(0).toUpperCase() + word.slice(1)
    capitalizedArray.push(capitalized)
  })
  return capitalizedArray.join(' ')
}

export const avatarText = value => {
  if (!value) return ''
  const nameArray = value.split(' ')
  return nameArray.map(word => word.charAt(0).toUpperCase()).join('')
}

/**
 * Format and return date in Humanize format
 * Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 * Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
// eslint-disable-next-line no-unused-vars
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }, locale) => {
  let val = value
  // eslint-disable-next-line no-prototype-builtins
  if (!formatting.hasOwnProperty('hour')) {
    // eslint-disable-next-line prefer-destructuring
    val = value.split(' ')[0]
  }

  // eslint-disable-next-line no-unused-vars
  let locales = 'en-US'
  if (locale && locale === 'pl') locales = 'pl-PL'
  if (!value) return value
  let returnValue = ''
  try {
    const isSafari = navigator.userAgent.toLowerCase().search('safari') >= 0 && navigator.userAgent.toLowerCase().search('chrome') < 0
    val = isSafari ? val.replace(/-/g, '/') : val
    if (typeof value === 'string') {
      returnValue = new Intl.DateTimeFormat(locales, formatting).format(new Date(val))
    } else {
      returnValue = new Intl.DateTimeFormat(locales, formatting).format(val)
    }
  } catch (e) {
    if (!returnValue) {
      returnValue = typeof val === 'string' ? val.split('.')[0] : val.toString()
    }
  }

  return returnValue
}

/**
 * Return short human friendly month representation of date
 * Can also convert date to only time if date is of today (Better UX)
 * @param {String} value date to format
 * @param {Boolean} toTimeForCurrentDay Shall convert to time if day is today/current
 */
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true, locale) => {
  let val = value
  // eslint-disable-next-line no-unused-vars
  let locales = 'en-US'
  if (locale && locale === 'pl') locales = 'pl-PL'

  const date = new Date(value)
  // eslint-disable-next-line no-unused-vars
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  const isSafari = navigator.userAgent.toLowerCase().search('safari') >= 0 && navigator.userAgent.toLowerCase().search('chrome') < 0
  val = isSafari ? val.replace(/-/g, '/') : val
  let returnValue = val

  try {
    if (typeof value === 'string') {
      returnValue = new Intl.DateTimeFormat(locales, formatting).format(new Date(val))
    } else {
      returnValue = new Intl.DateTimeFormat(locales, formatting).format(val)
    }
  } catch (e) {
    return returnValue.split('.')[0]
  }

  return returnValue
}

// Strip all the tags from markup and return plain text
export const filterTags = value => value.replace(/<\/?[^>]+(>|$)/g, '')
