var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"title":_vm.$tc('MassContactsUpdate', _vm.contacts.length, { length: _vm.contacts.length }),"hide-footer":"","no-close-on-backdrop":""},on:{"hide":function($event){return _vm.$emit('close-modal', true)}},model:{value:(_vm.isModalOpen),callback:function ($$v) {_vm.isModalOpen=$$v},expression:"isModalOpen"}},[_c('b-alert',{attrs:{"show":"","variant":"primary"}},[_c('div',{staticClass:"alert-body p-1"},[_c('feather-icon',{attrs:{"icon":"AlertCircleIcon","size":"20"}}),_vm._v(" "+_vm._s(_vm.$t('ContactsDataWillBeOverwritten'))+" ")],1)]),_c('b-button',{staticClass:"w-100",attrs:{"size":"sm","variant":"gradient-primary"},on:{"click":function($event){_vm.showPreview = !_vm.showPreview}}},[_c('feather-icon',{attrs:{"icon":!_vm.showPreview ? 'EyeIcon' : 'EyeOffIcon'}}),_vm._v(" "+_vm._s(!_vm.showPreview ? _vm.$t('ShowContacts') : _vm.$t('HideContacts'))+" ")],1),(_vm.showPreview)?_c('b-card',[_c('vue-perfect-scrollbar',{style:({ height: _vm.contacts.length > 3 ? '200px' : 'auto' }),attrs:{"settings":{
        maxScrollbarLength: 60,
        wheelPropagation: false
      }}},[_c('transition-group',{attrs:{"tag":"b-list-group","name":"list"}},_vm._l((_vm.contacts),function(usedUser){return _c('b-list-group-item',{key:("used_user_" + (usedUser.id))},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('b-avatar',{staticClass:"mr-50",attrs:{"text":_vm.usedUserAvatarText(usedUser),"variant":"light-primary"}}),_c('div',{staticClass:"d-flex flex-column"},[_vm._v(" "+_vm._s(usedUser.firstName || '')+" "+_vm._s(usedUser.lastName || '')+" "),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(usedUser.email))])])],1)])])}),1)],1)],1):_vm._e(),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-checkbox',{attrs:{"switch":"","variant":"primary"},model:{value:(_vm.showContactTypes),callback:function ($$v) {_vm.showContactTypes=$$v},expression:"showContactTypes"}},[_vm._v(" "+_vm._s(_vm.$t('Tags'))+" ")]),_c('transition',{attrs:{"name":"fade"}},[(_vm.showContactTypes)?_c('sw-select',[_c('v-select',{attrs:{"multiple":"","options":_vm.contactTypes,"label":"name","reduce":function (tag) { return tag.id; }},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('NoOptions'))+" ")]},proxy:true},{key:"option",fn:function(ref){
      var name = ref.name;
      var color = ref.color;
return [_c('div',{staticClass:"color-dot",staticStyle:{"border":"1px solid white"},style:({ backgroundColor: color })}),_vm._v(" "+_vm._s(name)+" ")]}},{key:"selected-option",fn:function(ref){
      var name = ref.name;
      var color = ref.color;
return [_c('div',{staticClass:"color-dot",staticStyle:{"border":"1px solid white"},style:({ backgroundColor: color })}),_vm._v(" "+_vm._s(name)+" ")]}}],null,false,3842347944),model:{value:(_vm.newContactTypes),callback:function ($$v) {_vm.newContactTypes=$$v},expression:"newContactTypes"}})],1):_vm._e()],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-checkbox',{attrs:{"switch":"","variant":"primary"},model:{value:(_vm.showContactStatuses),callback:function ($$v) {_vm.showContactStatuses=$$v},expression:"showContactStatuses"}},[_vm._v(" "+_vm._s(_vm.$t('contact.contactStatus'))+" ")]),_c('transition',{attrs:{"name":"fade"}},[(_vm.showContactStatuses)?_c('sw-select',[_c('v-select',{attrs:{"options":_vm.contactStatuses,"label":"name","reduce":function (status) { return status.id; }},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('NoOptions'))+" ")]},proxy:true},{key:"option",fn:function(ref){
      var name = ref.name;
      var color = ref.color;
return [_c('div',{staticClass:"color-dot",style:({ backgroundColor: color })}),_vm._v(" "+_vm._s(name)+" ")]}},{key:"selected-option",fn:function(ref){
      var name = ref.name;
      var color = ref.color;
return [_c('div',{staticClass:"color-dot",style:({ backgroundColor: color })}),_vm._v(" "+_vm._s(name)+" ")]}}],null,false,2330741928),model:{value:(_vm.newContactStatus),callback:function ($$v) {_vm.newContactStatus=$$v},expression:"newContactStatus"}})],1):_vm._e()],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-checkbox',{attrs:{"switch":"","variant":"primary"},model:{value:(_vm.showGroups),callback:function ($$v) {_vm.showGroups=$$v},expression:"showGroups"}},[_vm._v(" "+_vm._s(_vm.$t('contact.group'))+" ")]),_c('transition',{attrs:{"name":"fade"}},[(_vm.showGroups)?_c('sw-select',[_c('v-select',{attrs:{"options":_vm.groups,"label":"name","reduce":function (group) { return group.id; },"multiple":""},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('NoOptions'))+" ")]},proxy:true},{key:"option",fn:function(ref){
      var name = ref.name;
      var color = ref.color;
return [_c('div',{staticClass:"color-dot",staticStyle:{"border":"1px solid white"},style:({ backgroundColor: color })}),_vm._v(" "+_vm._s(name)+" ")]}},{key:"selected-option",fn:function(ref){
      var name = ref.name;
      var color = ref.color;
return [_c('div',{staticClass:"color-dot",staticStyle:{"border":"1px solid white"},style:({ backgroundColor: color })}),_vm._v(" "+_vm._s(name)+" ")]}}],null,false,3842347944),model:{value:(_vm.newContactGroups),callback:function ($$v) {_vm.newContactGroups=$$v},expression:"newContactGroups"}})],1):_vm._e()],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-checkbox',{attrs:{"switch":"","variant":"primary"},model:{value:(_vm.showAssignedUsers),callback:function ($$v) {_vm.showAssignedUsers=$$v},expression:"showAssignedUsers"}},[_vm._v(" "+_vm._s(_vm.$t('AssignedUsers'))+" ")]),_c('transition',{attrs:{"name":"fade"}},[(_vm.showAssignedUsers)?_c('sw-select',[_c('v-select',{attrs:{"options":_vm.users,"label":"firstName","filterable":false,"multiple":""},on:{"search":_vm.loadUsers},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
      var search = ref.search;
return [(search.length)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('NoData'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('TypeToSearch'))+" ")])]}},{key:"option",fn:function(ref){
      var firstName = ref.firstName;
      var lastName = ref.lastName;
      var avatar = ref.avatar;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('avatar',{staticClass:"mr-25",attrs:{"user":{ firstName: firstName, lastName: lastName, avatar: avatar }}})],1)]}},{key:"selected-option",fn:function(ref){
      var firstName = ref.firstName;
      var lastName = ref.lastName;
      var avatar = ref.avatar;
return [_c('div',{staticClass:"d-flex"},[_c('avatar',{staticClass:"mr-25",attrs:{"user":{ firstName: firstName, lastName: lastName, avatar: avatar },"text-variant":"text-white"}})],1)]}}],null,false,3138453195),model:{value:(_vm.newContactAssignedUsers),callback:function ($$v) {_vm.newContactAssignedUsers=$$v},expression:"newContactAssignedUsers"}})],1):_vm._e()],1)],1),_c('b-col',[_c('b-button',{attrs:{"variant":"primary","size":"sm","disabled":(!_vm.showGroups && !_vm.showContactStatuses && !_vm.showContactTypes && !_vm.showAssignedUsers) || _vm.loading},on:{"click":_vm.updateProductPrices}},[_vm._v(" "+_vm._s(_vm.$t('Save'))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }