<template>
  <div>
    <div
      class="mb-0 mb-md-50 mb-lg-2 d-flex flex-column flex-md-row align-items-md-center"
      style="row-gap: .5rem"
    >
      <b-button-group class="mt-n1 mb-md-0">
        <b-button
          :variant="type === 'assigned' ? 'primary' : 'light-primary'"
          @click="type = 'assigned'"
        >
          {{ $tc('Assigned1', isAllLangVariant) }}
        </b-button>

        <b-button
          :variant="type === 'all' ? 'primary' : 'light-primary'"
          @click="type = 'all'"
        >
          {{ $tc('All', isAllLangVariant) }}
        </b-button>
      </b-button-group>

      <div class="d-flex align-items-center ml-md-50">
        <sw-select>
          <sw-select-fpr
            :is-empty="timeRange"
            @clear="timeRange = ''"
          >
            <flat-pickr
              v-model="timeRange"
              class="form-control"
              style="min-width: 17rem"
              :config="{ locale: getCalendarLocale($i18n.locale), mode: 'range' }"

              :placeholder="$tc('Costs.DateRange', isAllLangVariant)"
            />
          </sw-select-fpr>
        </sw-select>
      </div>
    </div>

    <!--    Section: Contacts     -->
    <my-section
      v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACTS_ASSIGNED, $viewRanks.SHOW_CONTACTS_DELAYED, $viewRanks.SHOW_CONTACTS_STATUSES]) && (allowAllView([$roles.CONTACT_VIEW_ALL]) || type === 'assigned')"
      :title="$tc('Contacts', isAllLangVariant)"
      icon="UsersIcon"
    >
      <!--    Tile: YourAssignedContacts    -->
      <my-simplte-tile
        v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACTS_ASSIGNED]) && (allowAssignedView([$roles.CONTACT_VIEW_ALL, $roles.CONTACT_VIEW_STRUCTURE, $roles.CONTACT_VIEW_ASSIGNED, $roles.CONTACT_VIEW_STRUCTURE_FIRST,]) || allowAllView([$roles.CONTACT_VIEW_ALL]))"
        :title="$tc('dashboard.tiles.YourAssignedContacts', isAllLangVariant)"
        :tip="$tc('dashboard.tilesTips.YourAssignedContacts', isAllLangVariant)"
        :val="assignedContacts"
        to="contacts"
        :md="6"
        :lg="6"
        :svg-icon="dashboardIcons.contacts(`rgb(${getColor()})`)"
        @redirect="redirect"
      />

      <!--    Tile: Delayed Contacts    -->
      <my-simplte-tile
        v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACTS_DELAYED]) && (allowAssignedView([$roles.CONTACT_VIEW_STRUCTURE_FIRST, $roles.CONTACT_VIEW_ALL, $roles.CONTACT_VIEW_STRUCTURE, $roles.CONTACT_VIEW_ASSIGNED]) || allowAllView([$roles.CONTACT_VIEW_ALL]))"
        :title="$tc('DashboardParts.OurDelayedContacts', isAllLangVariant)"
        :tip="$tc('dashboard.tilesTips.DelayedContacts', isAllLangVariant)"
        :val="delayedContacts"
        to="contacts"
        :md="6"
        :lg="6"
        :svg-icon="dashboardIcons['delayed-contacts'](`rgb(${getColor()})`)"
        @redirect="redirect($event, { showDelayed: true })"
      />

      <b-col
        v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACTS_STATUSES]) && (allowAssignedView([$roles.CONTACT_VIEW_STRUCTURE_FIRST, $roles.CONTACT_VIEW_ALL, $roles.CONTACT_VIEW_STRUCTURE, $roles.CONTACT_VIEW_ASSIGNED]) || allowAllView([$roles.CONTACT_VIEW_ALL]))"
        :sm="12"
      >
        <b-card class="mb-1 mb-md-2">
          <template #header>
            <h5>{{ $tc('ContactStatuses', isAllLangVariant) }}</h5>
          </template>
          <template v-if="contacts !== -1">
            <bar-chart
              v-if="contactsChart.data.labels.length && isContactsChartFilled"
              :height="200"
              :data="contactsChart.data"
              :options="contactsChart.options"
            />
            <div
              v-else
              class="text-center py-1 text-primary font-weight-bold"
            >
              <feather-icon
                size="17"
                icon="XCircleIcon"
              />
              {{ $tc('NoData', isAllLangVariant) }}
            </div>
          </template>
          <b-overlay
            no-wrap
            :show="contacts === -1"
            spinner-variant="primary"
          />
        </b-card>
      </b-col>
    </my-section>

    <!--    Section: Threats     -->
    <my-section
      v-if="checkRequiredViewPermissions([$viewRanks.SHOW_THREADS_ASSIGNED, $viewRanks.SHOW_THREADS_DELAYED, $viewRanks.SHOW_THREADS_STATUSES])"
      :title="$tc('Threads', isAllLangVariant)"
      icon="UsersIcon"
    >
      <b-col
        sm="12"
        class="mb-1 mt-n25 mt-md-0"
      >
        <div class="mb-50 font-weight-bolder">
          {{ $tc('SelectFunnel', isAllLangVariant) }}
        </div>

        <b-button-group class="flex-wrap">
          <b-button
            v-for="(funnel, index) in funnelList"
            :key="`funnel_${funnel.id}`"
            size="sm"
            class="mb-25"
            :variant="displayedFunnelIndex === index ? 'primary' : 'outline-primary'"
            @click="changeFunnel(funnel, index)"
          >
            {{ funnel.name }}
          </b-button>
        </b-button-group>
      </b-col>
      <!--    Tile: YourAssignedThreads    -->
      <my-simplte-tile
        v-if="checkRequiredViewPermissions([$viewRanks.SHOW_THREADS_ASSIGNED]) && (allowAssignedView([$roles.CONTACT_THREAD_VIEW_ALL, $roles.CONTACT_THREAD_VIEW_STRUCTURE, $roles.CONTACT_THREAD_VIEW_STRUCTURE_FIRST, $roles.CONTACT_THREAD_VIEW_ASSIGNED]) || allowAllView([$roles.CONTACT_THREAD_VIEW_ALL]))"
        :title="$tc('dashboard.tiles.YourAssignedThreads', isAllLangVariant)"
        :tip="$tc('dashboard.tilesTips.YourAssignedThreads', isAllLangVariant)"
        :val="assignedThreads"
        to="sales-funnel"
        :md="6"
        :lg="6"
        :svg-icon="dashboardIcons['thread'](`rgb(${getColor()})`)"
        @redirect="redirect($event, { id: funnelList[displayedFunnelIndex].id })"
      />
      <!--    Tile: YourAssignedThreads    -->
      <my-simplte-tile
        v-if="checkRequiredViewPermissions([$viewRanks.SHOW_THREADS_DELAYED]) && (allowAssignedView([$roles.CONTACT_THREAD_VIEW_ALL, $roles.CONTACT_THREAD_VIEW_STRUCTURE, $roles.CONTACT_THREAD_VIEW_STRUCTURE_FIRST, $roles.CONTACT_THREAD_VIEW_ASSIGNED]) || allowAllView([$roles.CONTACT_THREAD_VIEW_ALL]))"
        :title="$tc('DashboardParts.OurDelayedThreads', isAllLangVariant)"
        :tip="$tc('dashboard.tilesTips.DelayedThreads', isAllLangVariant)"
        :val="delayedThreads"
        to="sales-funnel"
        :md="6"
        :lg="6"
        :svg-icon="dashboardIcons['delayed-threads'](`rgb(${getColor()})`)"
        @redirect="redirect($event, {
          id: funnelList[displayedFunnelIndex].id,
          showDelayed: true
        })"
      />
      <b-col
        v-if="checkRequiredViewPermissions([$viewRanks.SHOW_THREADS_STATUSES]) && (allowAssignedView([$roles.CONTACT_THREAD_VIEW_ALL, $roles.CONTACT_THREAD_VIEW_STRUCTURE, $roles.CONTACT_THREAD_VIEW_STRUCTURE_FIRST, $roles.CONTACT_THREAD_VIEW_ASSIGNED]) || allowAllView([$roles.CONTACT_THREAD_VIEW_ALL]))"
        :sm="12"
      >
        <b-card class="mb-1 mb-md-2">
          <template #header>
            <h5>{{ $tc('FunnelsStatuses', isAllLangVariant) }}</h5>
          </template>

          <template v-if="funnels !== -1 || rerenderChart">
            <bar-chart
              v-if="funnelChart.data.labels.length"
              :style="{ height: `${funnelChart.data.labels.length * 40}px` }"
              :data="funnelChart.data"
              :options="funnelChart.options"
            />
            <div
              v-else
              class="text-center py-1 text-primary font-weight-bold"
            >
              <feather-icon
                size="17"
                icon="XCircleIcon"
              />
              {{ $tc('NoData', isAllLangVariant) }}
            </div>
          </template>
          <b-overlay
            no-wrap
            :show="funnels === -1 || !rerenderChart"
            spinner-variant="primary"
          />
        </b-card>
      </b-col>
    </my-section>

    <!--    Section: Your Stats    -->
    <my-section
      v-if="checkRequiredViewPermissions([$viewRanks.SHOW_OFFERS_SENT, $viewRanks.SHOW_OFFERS_ACCEPTED, $viewRanks.SHOW_OFFERS_MESSAGES, $viewRanks.SHOW_AGREEMENTS_SENT, $viewRanks.SHOW_AGREEMENTS_SIGNED]) && checkRequiredModule('offerModule')"
      :title="$tc('dashboard.sections.YourStats', isAllLangVariant)"
      icon="BarChart2Icon"
    >
      <!--    Tile: AllSentencesMade    -->
      <my-tile
        v-if="checkRequiredViewPermissions([$viewRanks.SHOW_OFFERS_SENT]) && (allowAssignedView([[$roles.CONTACT_THREAD_VIEW_ALL, $roles.CONTACT_THREAD_VIEW_ASSIGNED, $roles.CONTACT_THREAD_VIEW_STRUCTURE, $roles.CONTACT_THREAD_VIEW_STRUCTURE_FIRST], [$roles.CONTACT_THREAD_OFFER_VIEW_ALL, $roles.CONTACT_THREAD_ASSIGNED_OFFER_VIEW_ALL, $roles.CONTACT_THREAD_ASSIGNED_OFFER_VIEW_SELF]]) || allowAllView([$roles.CONTACT_THREAD_OFFER_VIEW_ALL]))"
        :title="$tc('dashboard.tiles.OffersSent', isAllLangVariant)"
        :tip="$tc('dashboard.tilesTips.OffersSentToContacts', isAllLangVariant)"
        :val="sentOffers"
        to="offr/offerList"
        :md="6"
        :lg="4"
        :svg-icon="dashboardIcons['offer-send'](`rgb(${getColor()})`)"
        @redirect="redirect($event, { propsOfferMessages: 'sent' })"
      />

      <!--    Tile: AllUnperformedSentences    -->
      <my-tile
        v-if="checkRequiredViewPermissions([$viewRanks.SHOW_OFFERS_ACCEPTED]) && (allowAssignedView([[$roles.CONTACT_THREAD_VIEW_ALL, $roles.CONTACT_THREAD_VIEW_ASSIGNED, $roles.CONTACT_THREAD_VIEW_STRUCTURE], [$roles.CONTACT_THREAD_OFFER_VIEW_ALL, $roles.CONTACT_THREAD_ASSIGNED_OFFER_VIEW_ALL, $roles.CONTACT_THREAD_ASSIGNED_OFFER_VIEW_SELF]]) || allowAllView([$roles.CONTACT_THREAD_OFFER_VIEW_ALL]))"
        :title="$tc('dashboard.tiles.AcceptedOffers', isAllLangVariant)"
        :tip="$tc('dashboard.tilesTips.AcceptedOffers', isAllLangVariant)"
        :val="acceptedOffers"
        to="offr/offerList"
        :md="6"
        :lg="4"
        :svg-icon="dashboardIcons['offer-ok'](`rgb(${getColor()})`)"
        @redirect="redirect($event, { propsOfferStatus: 'ACCEPTED' })"
      />

      <!--    Tile: AllSentencesMade    -->
      <my-tile
        v-if="checkRequiredViewPermissions([$viewRanks.SHOW_OFFERS_MESSAGES]) && (allowAssignedView([[$roles.CONTACT_THREAD_VIEW_ALL, $roles.CONTACT_THREAD_VIEW_ASSIGNED, $roles.CONTACT_THREAD_VIEW_STRUCTURE, $roles.CONTACT_THREAD_VIEW_STRUCTURE_FIRST], [$roles.CONTACT_THREAD_OFFER_VIEW_ALL, $roles.CONTACT_THREAD_ASSIGNED_OFFER_VIEW_ALL, $roles.CONTACT_THREAD_ASSIGNED_OFFER_VIEW_SELF]]) || allowAllView([$roles.CONTACT_THREAD_OFFER_VIEW_ALL]))"
        :title="$tc('dashboard.tiles.OfferMessages', isAllLangVariant)"
        :tip="$tc('dashboard.tilesTips.OfferMessages', isAllLangVariant)"
        :val="offerMessages"
        to="timeline"
        :md="6"
        :lg="4"
        :svg-icon="dashboardIcons['ageements-massages'](`rgb(${getColor()})`)"
        @redirect="redirect($event, { defaultTabs: [
          'contactThreadOfferMessage',
        ] })"
      />

      <!--    Tile: AllUnperformedSentences    -->
      <my-tile
        v-if="checkRequiredViewPermissions([$viewRanks.SHOW_AGREEMENTS_SENT]) && (allowAssignedView([[$roles.CONTACT_THREAD_VIEW_ALL, $roles.CONTACT_THREAD_VIEW_ASSIGNED, $roles.CONTACT_THREAD_VIEW_STRUCTURE_FIRST, $roles.CONTACT_THREAD_VIEW_STRUCTURE], [$roles.CONTACT_THREAD_AGREEMENT_VIEW_ALL, $roles.CONTACT_THREAD_ASSIGNED_AGREEMENT_VIEW_ALL, $roles.CONTACT_THREAD_ASSIGNED_AGREEMENT_VIEW_SELF]]) || allowAllView([$roles.CONTACT_THREAD_AGREEMENT_VIEW_ALL]))"
        :title="$tc('dashboard.tiles.AgreementsSent', isAllLangVariant)"
        :tip="$tc('dashboard.tilesTips.SentAgreements', isAllLangVariant)"
        :val="sentAgreements"
        to="agreements"
        :md="6"
        :lg="4"
        :svg-icon="dashboardIcons['agreement-send'](`rgb(${getColor()})`)"
        @redirect="redirect"
      />

      <!--    Tile: AllSentencesMade    -->
      <my-tile
        v-if="checkRequiredViewPermissions([$viewRanks.SHOW_AGREEMENTS_SIGNED]) && (allowAssignedView([[$roles.CONTACT_THREAD_VIEW_ALL, $roles.CONTACT_THREAD_VIEW_ASSIGNED, $roles.CONTACT_THREAD_VIEW_STRUCTURE_FIRST, $roles.CONTACT_THREAD_VIEW_STRUCTURE], [$roles.CONTACT_THREAD_AGREEMENT_VIEW_ALL, $roles.CONTACT_THREAD_ASSIGNED_AGREEMENT_VIEW_ALL, $roles.CONTACT_THREAD_ASSIGNED_AGREEMENT_VIEW_SELF]]) || allowAllView([$roles.CONTACT_THREAD_AGREEMENT_VIEW_ALL]))"
        :title="$tc('dashboard.tiles.SignedAgreements', isAllLangVariant)"
        :tip="$tc('dashboard.tilesTips.SignedAgreements', isAllLangVariant)"
        :val="signedAgreements"
        to="agreements"
        :md="6"
        :lg="4"
        :svg-icon="dashboardIcons['sign-agreements'](`rgb(${getColor()})`)"
        @redirect="redirect($event, { agreementStatus:
          [
            'ACCEPTED',
            'SIGNED',
            'SIGNING',
            'SIGNED_MANUALLY',
            'SIGNED_ELECTRONICALLY',
          ]
        })"
      />
    </my-section>

    <!--    Section: Your Tasks On The System    -->
    <my-section
      v-if="checkRequiredViewPermissions([[$roles.CONTACT_THREAD_VIEW_ALL, $roles.CONTACT_THREAD_VIEW_ASSIGNED, $roles.CONTACT_THREAD_VIEW_STRUCTURE_FIRST, $roles.CONTACT_THREAD_VIEW_STRUCTURE], [$viewRanks.SHOW_TASKS_DELAYED, $viewRanks.SHOW_TASKS_TODAY, $viewRanks.SHOW_TASKS_IN_THREADS]])"
      :title="$tc('dashboard.sections.YourTasksOnTheSystem', isAllLangVariant)"
      icon="CheckSquareIcon"
    >
      <!--    Tile: AllDelayedQuestsInThreads    -->
      <my-tile
        v-if="checkRequiredViewPermissions([$viewRanks.SHOW_TASKS_DELAYED]) && (allowAssignedView([$roles.CONTACT_THREAD_TASK_VIEW_ALL, $roles.CONTACT_THREAD_TASK_VIEW_ASSIGNED, $roles.CONTACT_THREAD_TASK_VIEW_STRUCTURE]) || allowAllView([$roles.CONTACT_THREAD_TASK_VIEW_ALL]))"
        :title="$tc('dashboard.tiles.AllDelayedQuestsInThreads', isAllLangVariant)"
        :val="allDelayedTasks"
        to="tasks"
        :md="6"
        :lg="4"
        :svg-icon="dashboardIcons['task-delay'](`rgb(${getColor()})`)"
        @redirect="redirect($event, { isToday: false })"
      />

      <!--    Tile: AllTasksForToday    -->
      <my-tile
        v-if="checkRequiredViewPermissions([$viewRanks.SHOW_TASKS_TODAY]) && (allowAssignedView([$roles.CONTACT_THREAD_TASK_VIEW_ALL, $roles.CONTACT_THREAD_TASK_VIEW_ASSIGNED, $roles.CONTACT_THREAD_TASK_VIEW_STRUCTURE]) || allowAllView([$roles.CONTACT_THREAD_TASK_VIEW_ALL]))"
        :title="$tc('dashboard.tiles.AllTasksForToday', isAllLangVariant)"
        :val="allTodayTasks"
        to="tasks"
        :svg-icon="dashboardIcons['task-today'](`rgb(${getColor()})`)"
        :md="6"
        :lg="4"
        @redirect="redirect($event, { isToday: true })"
      />

      <!--    Tile: AllQuestsInThreads    -->
      <my-tile
        v-if="checkRequiredViewPermissions([$viewRanks.SHOW_TASKS_IN_THREADS]) && (allowAssignedView([$roles.CONTACT_THREAD_TASK_VIEW_ALL, $roles.CONTACT_THREAD_TASK_VIEW_STRUCTURE, $roles.CONTACT_THREAD_TASK_VIEW_ASSIGNED]) || allowAllView([$roles.CONTACT_THREAD_TASK_VIEW_ALL]))"
        :title="$tc('dashboard.tiles.AllQuestsInThreads', isAllLangVariant)"
        :val="allTasks"
        to="tasks"
        :md="6"
        :lg="4"
        :svg-icon="dashboardIcons['task-all'](`rgb(${getColor()})`)"
        @redirect="redirect"
      />
    </my-section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axiosIns from '@/libs/axios'
import flatPickr from 'vue-flatpickr-component'
import { GET_FUNNELS } from '@/@constants/mutations'
import { BButtonGroup } from 'bootstrap-vue'
import { CONTACT_VIEW_ALL, CONTACT_VIEW_ASSIGNED, CONTACT_VIEW_STRUCTURE } from '@/helpers/permissions'
import dashboardIcons from '@/@data/dashboardIcons'
import MySection from './components/Section.vue'
import MyTile from './components/Tile.vue'
import MySimplteTile from './components/SimpleTile.vue'
import BarChart from './components/BarChart.vue'

export default {
  components: {
    flatPickr,
    MySection,
    MyTile,
    MySimplteTile,
    BarChart,
    BButtonGroup,
  },
  data: () => ({
    dashboardIcons,
    /* eslint-disable */
    nowDate: `${('0' + new Date().getDate()).slice(-2)}.${('0' + (new Date().getUTCMonth() + 1)).slice(-2)}.${new Date().getUTCFullYear()}`,
    /* eslint-disable */
    nowTime: `${('0' + new Date().getHours()).slice(-2)}:${('0' + new Date().getMinutes()).slice(-2)}`,
    type: 'assigned',
    key: (Math.random() + 1).toString(36).substring(7),

    // Cards - contacts
    newNotAssignedContacts: -1,
    assignedContacts: -1,
    assignedThreads: -1,
    delayedContacts: -1,
    delayedThreads: -1,

    // Cards - statistics
    sentOffers: -1,
    acceptedOffers: -1,
    offerMessages: -1,
    sentAgreements: -1,
    signedAgreements: -1,

    // Cards - tasks
    allDelayedTasks: -1,
    allTodayTasks: -1,
    allTasks: -1,

    funnelChart: {
      data: { labels: [] },
      options: {},
    },

    contactsChart: {
      data: { labels: [] },
      options: {},
    },

    funnelList: [],

    displayedFunnelIndex: 0,
    timeRange: '',
    funnels: -1,
    contacts: -1,
    rerenderChart: true,
  }),
  computed: {
    ...mapGetters({
      user: 'auth/getCurrentUser',
    }),
    isAllLangVariant() {
      return this.type === 'all' ? 2 : 1
    },
    timeRangeArray() {
      return this.timeRange?.dateRange()
    },
    isFunnelChartFilled() {
      return Array.isArray(this.funnels) ? this.funnels[this.displayedFunnelIndex].contactThreadFunnelTypePoints.reduce((a, b) => +a + +b.count, 0) : 0
    },
    isContactsChartFilled() {
      return Array.isArray(this.contacts) ? this.contacts.reduce((a, b) => +a + +b.count, 0) : 0
    },
  },
  created() {
    this.getNow()
    setInterval(() => this.getNow(), 1000)
  },
  watch: {
    timeRange(e) {
      if (e && e.dateRange().length === 2) {
        this.init()
      }

      if (e === '') this.init()
    },
    type() {
      this.setDefault()
      this.init()
    },
  },
  async mounted() {
    const res = await this.$store.dispatch(`funnelMain/${GET_FUNNELS}`)

    if (res) this.funnelList = res

    this.init()
  },
  methods: {
    getColor() {
      return getComputedStyle(document.documentElement).getPropertyValue('--theme-color-primary')
    },
    allowAllView(permissions) {
      return this.type === 'all' && this.checkRequiredPermissions(permissions)
    },
    allowAssignedView(permissions) {
      return this.type === 'assigned' && this.checkRequiredPermissions(permissions)
    },
    redirect(to, additionalParams) {
      this.$router.push({
        name: to,
        params: {
          propsUser: this.type === 'all' ? false : (this.user || null),
          ...additionalParams,
        }
      })
    },
    init() {
      const user = this.$store.getters['auth/getCurrentUser']

      this.$nextTick(() => {
        if (user) {
          const { id } = user

          this.initUser(id)
        }
      })
    },
    changeDate(place, value) {
      this.$set(this, place, value)
    },
    setDefault() {
      // Cards - contacts
      this.newNotAssignedContacts = -1
      this.assignedContacts = -1
      this.assignedThreads = -1
      this.delayedContacts = -1
      this.delayedThreads = -1

      // Cards - statistics
      this.sentOffers = -1
      this.acceptedOffers = -1
      this.offerMessages = -1
      this.sentAgreements = -1
      this.signedAgreements = -1

      // Cards - tasks
      this.allDelayedTasks = -1
      this.allTodayTasks = -1
      this.allTasks = -1

      this.funnels = -1
      this.contacts = -1

      this.funnelChart = {
        data: { labels: [] },
        options: {},
      }

      this.contactsChart = {
        data: { labels: [] },
        options: {},
      }
    },
    async initUser(userId) {
      this.setDefault()

      await this.initContactSection(userId)
      await this.initFunnelChart(userId)
      await this.initContactsChart(userId)
      await this.initThreadSection(userId)
      await this.initStatisticSection(userId)
      await this.initTasksSection(userId)
    },
    /// INIT FUNNEL CHARTS ///
    async initFunnelChart(userId) {
      this.funnels = await this.getFunnelStatuses({}, userId)

      this.$nextTick(() => {
        this.changeFunnel(this.funnels?.find(item => item.id === this.funnelList[0].id), 0)
      })
    },
    async initContactsChart(userId) {
      this.contactsChart = {
        data: { labels: [] },
        options: {},
      }
      const contacts = await this.getContactStatuses({}, userId)
      this.contacts = contacts
      this.$nextTick(() => {
        this.createContactsChart(
            contacts.map(stat => stat.count),
            contacts.map(stat => stat.name),
            contacts.map(stat => stat.color),
        )
      })
    },
    /// CONTACTS SECTION ///
    async initContactSection(userId) {
      if (!this.checkRequiredPermissions([CONTACT_VIEW_ALL, CONTACT_VIEW_STRUCTURE, CONTACT_VIEW_ASSIGNED])) return

      if (this.type === 'all') this.$set(this, 'newNotAssignedContacts', await this.getContacts({ 'isNull_contactAssignedUsers-user': '' }))
      this.$set(this, 'assignedContacts', await this.getContacts({}, userId))
      this.$set(this, 'delayedContacts', await this.getContacts({
        lt_statusDelayedAt: this.getCurrentDate(true)
      }, userId))
    },
    /// DELAYED SECTION ///
    async initThreadSection(userId) {
      if (!this.checkRequiredPermissions([CONTACT_VIEW_ALL, CONTACT_VIEW_STRUCTURE, CONTACT_VIEW_ASSIGNED])) return

      const filters = {}

      const f = this.funnelList[this.displayedFunnelIndex]
      if (f && f.id) {
        filters['eq_contactThreadFunnelProcesses-contactThreadFunnelType'] = f.id
      }

      this.$set(this, 'assignedThreads', await this.getThreads({ ...filters }, userId))
      this.$set(this, 'delayedThreads', await this.getThreads({
        ...filters,
        lt_contactThreadFunnelTypePointDelayedAt: this.getCurrentDate(true),
      }, userId))
    },
    /// STATISTICS SECTION ///
    async initStatisticSection(userId) {
      if (this.checkRequiredModule('offerModule') && this.checkRequiredPermissions([this.$roles.CONTACT_THREAD_OFFER_VIEW_ALL, this.$roles.CONTACT_THREAD_ASSIGNED_OFFER_VIEW_ALL, this.$roles.CONTACT_THREAD_ASSIGNED_OFFER_VIEW_SELF])) {
        this.$set(this, 'sentOffers', await this.getOffers({}, userId))
        this.$set(this, 'acceptedOffers', await this.getOffers({ eq_status: 'ACCEPTED' }, userId))
        this.$set(this, 'offerMessages', await this.getOfferMessages({}, userId))
      }

      if (this.checkRequiredModule('agreementModule')) {
        this.$set(this, 'sentAgreements', await this.getAgreements({}, userId))
        this.$set(this, 'signedAgreements', await this.getAgreements({
          eqArr_status: JSON.stringify([
            'SIGNED',
            'ACCEPTED',
            'SIGNED_MANUALLY',
            'SIGNED_ELECTRONICALLY',
          ])
        }, userId))
      }
    },
    async initTasksSection(userId) {
      this.$set(this, 'allDelayedTasks', await this.getTasks({
        lt_endAt: this.getCurrentDate(true)
      }, userId))
      this.$set(this, 'allTodayTasks', await this.getTasks({
        gte_startAt: `${this.getCurrentDate()} 00:00:00`,
        lt_startAt: `${this.getCurrentDate()} 23:59:59`
      }, userId))
      this.$set(this, 'allTasks', await this.getTasks({}, userId))
    },
    getNow() {
      const date = new Date()
      this.nowDate = `${('0' + date.getDate()).slice(-2)}.${('0' + (date.getUTCMonth() + 1)).slice(-2)}.${date.getUTCFullYear()}`
      this.nowTime = `${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}`
    },
    getCurrentDate(full = false) {
      const date = new Date()
      const month = (date.getMonth() + 1) < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
      const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
      if (full) {
        const hour = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
        const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
        const seconds = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds()
        return `${date.getFullYear()}-${month}-${day} ${hour}:${minutes}:${seconds}`
      }
      return `${date.getFullYear()}-${month}-${day}`
    },
    /// CONTACTS ///
    async getContacts(filters = {}, assignedUserId = null) {
      try {
        const params = { limit: 0, ...filters, fields_load: this.$fields.BASE_ID }
        if (this.type === 'assigned' && assignedUserId) {
          params['eq_contactAssignedUsers-user'] = assignedUserId
          if (!this.allowAssignedView([this.$roles.CONTACT_VIEW_ALL, this.$roles.CONTACT_VIEW_STRUCTURE, this.$roles.CONTACT_VIEW_ASSIGNED, this.$roles.CONTACT_VIEW_STRUCTURE_FIRST,])) return 0
        }

        if (this.type === 'all' && !this.allowAllView([this.$roles.CONTACT_VIEW_ALL])) {
          return 0
        }

        if (Array.isArray(this.timeRangeArray)) {
          if (this.timeRangeArray[0]) params.gte_createdAt = this.timeRangeArray[0]
          if (this.timeRangeArray[1]) params.lte_createdAt = this.timeRangeArray[1]
        }

        const resp = await axiosIns.get('1/contacts', { params })

        return resp.data.data?.totalItemCount || 0
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    /// THREADS ///
    async getThreads(filters = {}, assignedUserId = null) {
      if (!this.checkRequiredPermissions([this.$roles.CONTACT_THREAD_VIEW_ALL, this.$roles.CONTACT_THREAD_VIEW_ASSIGNED, this.$roles.CONTACT_THREAD_VIEW_STRUCTURE_FIRST, this.$roles.CONTACT_THREAD_VIEW_STRUCTURE])) return 0
      try {
        const params = { limit: 0, ...filters, fields_load: this.$fields.BASE_ID }
        if (this.type === 'assigned' && assignedUserId) {
          // params['eq_assignedUsers-id'] = assignedUserId
          if (!this.allowAssignedView([this.$roles.CONTACT_THREAD_VIEW_ALL, this.$roles.CONTACT_THREAD_VIEW_STRUCTURE, this.$roles.CONTACT_THREAD_VIEW_STRUCTURE_FIRST, this.$roles.CONTACT_THREAD_VIEW_ASSIGNED])) return 0
        }

        if (this.type === 'all' && !this.allowAllView([this.$roles.CONTACT_THREAD_VIEW_ALL])) {
          return 0
        }

        if (Array.isArray(this.timeRangeArray)) {
          if (this.timeRangeArray[0]) params.gte_createdAt = this.timeRangeArray[0]
          if (this.timeRangeArray[1]) params.lte_createdAt = this.timeRangeArray[1]
        }

        const resp = await axiosIns.get('1/contacts/threads', { params })

        return resp.data.data?.totalItemCount || 0
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    /// OFFERS ///
    async getOffers(filters = {}, assignedUserId = null) {
      try {
        const params = { limit: 0, ...filters, fields_load: this.$fields.BASE_ID }
        if (this.type === 'assigned' && assignedUserId) {
          params['eq_contactThreadCartBox-contactThread-contact-contactAssignedUsers-user'] = assignedUserId
          if (!this.allowAssignedView([[this.$roles.CONTACT_THREAD_VIEW_ALL, this.$roles.CONTACT_THREAD_VIEW_ASSIGNED, this.$roles.CONTACT_THREAD_VIEW_STRUCTURE, this.$roles.CONTACT_THREAD_VIEW_STRUCTURE_FIRST], [this.$roles.CONTACT_THREAD_OFFER_VIEW_ALL, this.$roles.CONTACT_THREAD_ASSIGNED_OFFER_VIEW_ALL, this.$roles.CONTACT_THREAD_ASSIGNED_OFFER_VIEW_SELF]])) return 0
        }

        if (this.type === 'all' && !this.allowAllView([this.$roles.CONTACT_THREAD_OFFER_VIEW_ALL])) {
          return 0
        }

        if (Array.isArray(this.timeRangeArray)) {
          if (this.timeRangeArray[0]) params.gte_createdAt = this.timeRangeArray[0]
          if (this.timeRangeArray[1]) params.lte_createdAt = this.timeRangeArray[1]
        }

        const resp = await axiosIns.get('1/contacts/threads/carts/offers', { params })

        return resp.data.data?.totalItemCount || 0
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    /// OFFER MESSAGES ///
    async getOfferMessages(filters = {}, assignedUserId = null) {
      try {
        const params = { limit: 0, ...filters, fields_load: this.$fields.BASE_ID }
        if (this.type === 'assigned' && assignedUserId) {
          params['eq_contactThreadCartOffer-contactThreadCartBox-contactThread-contact-contactAssignedUsers-user'] = assignedUserId
          if (!this.allowAssignedView([[this.$roles.CONTACT_THREAD_VIEW_ALL, this.$roles.CONTACT_THREAD_VIEW_ASSIGNED, this.$roles.CONTACT_THREAD_VIEW_STRUCTURE, this.$roles.CONTACT_THREAD_VIEW_STRUCTURE_FIRST], [this.$roles.CONTACT_THREAD_OFFER_VIEW_ALL, this.$roles.CONTACT_THREAD_ASSIGNED_OFFER_VIEW_ALL, this.$roles.CONTACT_THREAD_ASSIGNED_OFFER_VIEW_SELF]])) return 0
        }

        if (this.type === 'all' && !this.allowAllView([this.$roles.CONTACT_THREAD_OFFER_VIEW_ALL])) {
          return 0
        }
        if (Array.isArray(this.timeRangeArray)) {
          if (this.timeRangeArray[0]) params.gte_createdAt = this.timeRangeArray[0]
          if (this.timeRangeArray[1]) params.lte_createdAt = this.timeRangeArray[1]
        }

        const resp = await axiosIns.get('1/contacts/threads/carts/offers/messages', { params })

        return resp.data.data?.totalItemCount || 0
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    /// TASKS ///
    async getTasks(filters = {}, assignedUserId = null) {
      try {
        const params = { limit: 0, ...filters, fields_load: this.$fields.BASE_ID, eq_status: 'O' }
        if (this.type === 'assigned' && assignedUserId) {
          params['eq_contactThread-contact-contactAssignedUsers-user'] = assignedUserId
          if (!this.allowAssignedView([[this.$roles.CONTACT_THREAD_VIEW_ALL, this.$roles.CONTACT_THREAD_VIEW_ASSIGNED, this.$roles.CONTACT_THREAD_VIEW_STRUCTURE_FIRST, this.$roles.CONTACT_THREAD_VIEW_STRUCTURE], [this.$roles.CONTACT_THREAD_TASK_VIEW_ALL, this.$roles.CONTACT_THREAD_TASK_VIEW_ASSIGNED, this.$roles.CONTACT_THREAD_TASK_VIEW_STRUCTURE]])) return 0
        }

        if (this.type === 'all' && !this.allowAllView([this.$roles.CONTACT_THREAD_TASK_VIEW_ALL])) {
          return 0
        }

        if (Array.isArray(this.timeRangeArray)) {
          if (this.timeRangeArray[0]) params.gte_createdAt = this.timeRangeArray[0]
          if (this.timeRangeArray[1]) params.lte_createdAt = this.timeRangeArray[1]
        }

        const resp = await axiosIns.get('1/contacts/threads/tasks', { params })

        return resp.data.data?.totalItemCount || 0
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    /// AGREEMENTS ///
    async getAgreements(filters = {}, assignedUserId = null) {
      try {
        const params = { limit: 0, ...filters, fields_load: this.$fields.BASE_ID }
        if (this.type === 'assigned' && assignedUserId) {
          params['eq_contactThreadCartBox-contactThread-contact-contactAssignedUsers-user'] = assignedUserId
          if (!this.allowAssignedView([[this.$roles.CONTACT_THREAD_VIEW_ALL, this.$roles.CONTACT_THREAD_VIEW_ASSIGNED, this.$roles.CONTACT_THREAD_VIEW_STRUCTURE_FIRST, this.$roles.CONTACT_THREAD_VIEW_STRUCTURE], [this.$roles.CONTACT_THREAD_AGREEMENT_VIEW_ALL, this.$roles.CONTACT_THREAD_ASSIGNED_AGREEMENT_VIEW_ALL, this.$roles.CONTACT_THREAD_ASSIGNED_AGREEMENT_VIEW_SELF]])) return 0
        }

        if (this.type === 'all' && !this.allowAllView([this.$roles.CONTACT_THREAD_AGREEMENT_VIEW_ALL])) {
          return 0
        }

        if (Array.isArray(this.timeRangeArray)) {
          if (this.timeRangeArray[0]) params.gte_createdAt = this.timeRangeArray[0]
          if (this.timeRangeArray[1]) params.lte_createdAt = this.timeRangeArray[1]
        }
        const resp = await axiosIns.get('1/contacts/threads/agreements', { params })

        return resp.data.data?.totalItemCount || 0
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    async getFunnelStatuses(filters = {}, assignedUserId = null) {
      if (!this.checkRequiredPermissions([this.$roles.CONTACT_THREAD_VIEW_ALL, this.$roles.CONTACT_THREAD_VIEW_ASSIGNED, this.$roles.CONTACT_THREAD_VIEW_STRUCTURE_FIRST, this.$roles.CONTACT_THREAD_VIEW_STRUCTURE])) return 0
      try {
        const params = { limit: 0, ...filters, fields_load: this.$fields.BASE_ID }
        if (this.type === 'assigned' && assignedUserId) params['eq_assignedUsers-id'] = assignedUserId
        if (Array.isArray(this.timeRangeArray)) {
          if (this.timeRangeArray[0]) params.gte_createdAt = this.timeRangeArray[0]
          if (this.timeRangeArray[1]) params.lte_createdAt = this.timeRangeArray[1]
        }

        const resp = await axiosIns.get('1/contacts/threads/getStatistics/byFunnelTypePoints', { params })

        return resp.data.data || 0
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    async getContactStatuses(filters = {}, assignedUserId = null) {
      if (!this.checkRequiredPermissions([this.$roles.CONTACT_VIEW_ALL, this.$roles.CONTACT_VIEW_ASSIGNED, this.$roles.CONTACT_VIEW_STRUCTURE_FIRST, this.$roles.CONTACT_VIEW_STRUCTURE])) return 0
      try {
        const params = { limit: 0, ...filters, fields_load: this.$fields.BASE_ID }
        if (this.type === 'assigned' && assignedUserId) params['eq_contactAssignedUsers-user'] = assignedUserId
        if (Array.isArray(this.timeRangeArray)) {
          if (this.timeRangeArray[0]) params.gte_createdAt = this.timeRangeArray[0]
          if (this.timeRangeArray[1]) params.lte_createdAt = this.timeRangeArray[1]
        }

        const resp = await axiosIns.get('1/contacts/getStatistics/byStatuses', { params })

        return resp.data.data || 0
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    changeFunnel(funnel, index) {
      this.displayedFunnelIndex = index

      const funnelIndex = this.funnels.findIndex(item => item.name === funnel.name)
      const { contactThreadFunnelTypePoints = [] } = this.funnels[funnelIndex]

      contactThreadFunnelTypePoints.sort((a, b) => a.position - b.position)

      this.funnelChart = {
        data: { labels: [] },
        options: {},
      }

      this.assignedThreads = -1
      this.delayedThreads = -1

      const user = this.$store.getters['auth/getCurrentUser']
      this.initThreadSection(user.id)

      this.key = (Math.random() + 1).toString(36).substring(7)
      this.$set(this, 'rerenderChart', false)
      this.createFunnelChart(
          contactThreadFunnelTypePoints.map(status => status.count),
          contactThreadFunnelTypePoints.map(status => status.name),
          contactThreadFunnelTypePoints.map(status => `#${status.color.replaceAll('#', '')}`).map(color => (color.length < 4 ? '#efefef' : color)),
      )
    },
    createFunnelChart(series = [53, 16, 31], labels = ['App', 'Service', 'Product'], colors = ['#7367f0', '#968ef1', '#bab6e7']) {
      this.$set(this, 'funnelChart', {
        data: {
          labels,
          datasets: [
            {
              data: series,
              backgroundColor: colors,
              borderColor: 'transparent',
              barThickness: 15,
            },
          ],
        },
        options: {
          elements: {
            rectangle: {
              borderWidth: 2,
              borderSkipped: 'top',
            },
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
          },
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                display: true,
                gridLines: {
                  borderColor: 'transparent',
                  drawTicks: false,
                },
                scaleLabel: {
                  display: true,
                },
                ticks: {
                  min: 0,
                },
              },
            ],
            yAxes: [
              {
                display: true,
                gridLines: {
                  display: false,
                },
                scaleLabel: {
                  display: true,
                },
              },
            ],
          },
        },
      })
      this.$set(this, 'rerenderChart', true)

    },
    createContactsChart(series = [53, 16, 31], labels = ['App', 'Service', 'Product'], colors = ['#7367f0', '#968ef1', '#bab6e7']) {
      this.contactsChart = {
        data: {
          labels,
          datasets: [
            {
              data: series,
              backgroundColor: colors,
              borderColor: 'transparent',
              barThickness: 15,
            },
          ],
        },
        options: {
          elements: {
            rectangle: {
              borderWidth: 2,
              borderSkipped: 'top',
            },
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
          },
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                display: true,
                gridLines: {
                  borderColor: 'transparent',
                  drawTicks: false,
                },
                scaleLabel: {
                  display: true,
                },
                ticks: {
                  min: 0,
                },
              },
            ],
            yAxes: [
              {
                display: true,
                gridLines: {
                  display: false,
                },
                scaleLabel: {
                  display: true,
                },
              },
            ],
          },
        },
      }
    }
  },
}
</script>
