<template>
  <div>
    <!-- Table -->
    <b-card>
      <!-- eslint-disable -->
            <sw-table
                    table-id="d81d4e61ce33475ab586143f3cbfa41e"
                    show-columns
                    show-search
                    :fields.async="fields"
                    :pagination="pagination"
                    @change-pagination="Object.assign(pagination, $event)"
                    @change-search-query="search = $event"
                    @reload-content="loadData"
                    @set-fields="fields = $event"
            >
                <template #table>
                    <!--      Table      -->
                    <b-table
                            :items="items"
                            :fields="tableFields"
                            striped
                            responsive
                            :style="{ minHeight: tableSize(items, 250) }"
                            :busy="loading"
                            show-empty
                            :sort-by.sync="sorter.sortBy"
                            :sort-desc.sync="sorter.sortDesc"
                            :no-local-sorting="true"
                            @sort-changed="Object.assign(sorter, $event); loadData()"
                    >
                        <!--    Head    -->
                        <template #head()="{ label }">
                            {{ $t(`${label}`) }}
                        </template>
                        <template #cell(id)="{ index }">
                            #{{ getOrderNo(index, pagination, sorter) }}
                        </template>

<!--                        <template #cell(status)="{ value }">-->
<!--                            <b-badge :variant="getStatus(value).variant">-->
<!--                                <sw-icon :icon="getStatus(value).icon" />-->
<!--                                {{ $t(value) }}-->
<!--                            </b-badge>-->
<!--                        </template>-->

                        <!--    Other    -->
                        <template #cell()="row">
                            <table-default-cell
                                    :field="row.field"
                                    :value="row.value"
                            />
                        </template>

                        <!--      Empty      -->
                        <template #empty>
                            <empty-content />
                        </template>

                        <!--      Table Busy      -->
                        <template #table-busy>
                            <div class="text-center text-danger my-2">
                                <b-spinner
                                        class="align-middle"
                                        variant="primary"
                                />
                            </div>
                        </template>
                    </b-table>
                </template>
            </sw-table>
        </b-card>
    </div>
</template>

<script>
import { BTable } from 'bootstrap-vue'
import { formatDate } from '@core/utils/filter'
import axiosIns from '@/libs/axios'

export default {
  name: 'List',
  components: {
    BTable,
  },
  props: {},
  data: vm => ({
    loading: false,
    items: [],
    sorter: {
      sortBy: 'id',
      sortDesc: true,
    },
    pagination: {
      perPage: 25,
      currentPage: 1,
      totalRecords: 0,
    },
    search: '',

    callModal: {
      show: false,
      item: null,
      isPreview: false,
    },

    filters: {
      type: 'ALL',
      status: 'ALL',
    },
    fields: [
      {
        key: 'id', label: '#', visible: true, sortable: true,
      },
      {
        key: 'dataType', valueFormatter: vm.formatedDataType, label: 'task.Type', visible: true,
      },
      {
        key: 'status',
        label: 'Status',
        visible: true,
        translate: true,
        tag: 'b-badge',
        valueFormatter: vm.formatedStatus,
        props: {
          variantFormatter: vm.getVariant,
        },
      },
      // { key: 'dataScope', label: 'DataScope', visible: true },
      {
        key: 'type',
        label: 'task.Type',
        visible: true,
        tag: 'b-badge',
        props: {
          variantFormatter: vm.getTypeVariant,
        },
      },
      {
        key: 'files', label: 'File', visible: true, tag: 'file',
      },
      // { key: 'caller', label: 'Caller', visible: true },
      // { key: 'callAt', label: 'CallAt', visible: true },
      // {
      //   key: 'callId',
      //   label: 'CallId',
      //   visible: true,
      //   tag: 'b-badge',
      //   props: { variant: 'light-primary' },
      // },
      // { key: 'contactThread', label: 'ContactThread', visible: true },
      // { key: 'destination', label: 'Destination', visible: true },
      // { key: 'file', label: 'AudioFile', visible: true },
      // { key: 'status', label: 'Status', visible: true },
      // { key: 'note', label: 'Note', visible: true },
      // { key: 'talkTime', label: 'TalkTime', visible: true },
      // { key: 'type', label: 'task.Type', visible: true },
      // { key: 'waitingTime', label: 'WaitingTime', visible: true },
      // // { key: 'action', label: 'Action', visible: true },
    ],
  }),
  computed: {
    tableFields() {
      return this.fields.filter(field => field.visible)
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.loadData()
      },
    },
  },
  async mounted() {
    await this.loadData()
  },
  methods: {
    formatDate,
    async loadData() {
      this.loading = true
      try {
        const params = {
          fields_load: 'MIGRATIONS',
          // fields_info: 1,
          page: this.pagination.currentPage,
          limit: this.pagination.perPage,
        }

        const filters = {}

        if (this.sorter) {
          const sortDir = this.sorter?.sortDesc ? 'desc' : 'asc'
          const sortBy = this.sorter?.sortBy || 'id'
          params.orderBy = `${sortBy}.${sortDir}`
        }

        if (this.search.length) {
          const searchString = this.search.replaceAll(' ', '%')
          params.search = `%${searchString}%`
          params.search_fields = []
        }

        const [resp] = await Promise.all([
          await axiosIns.get('1/migrations', { params: { ...params, ...filters } }),
        ])

        if (resp.data.data.items) {
          this.items = (resp.data?.data?.items || []).map(e => ({ ...e, files: e.dataFile ? [e.dataFile] : [] }))
          this.pagination.totalRecords = resp.data.data.totalItemCount
        }
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.loading = false
      }
    },

    getVariant(value) {
      if (value === 'QUEUE') return 'light-warning'
      if (value === 'IN_PROGRESS') return 'light-info'
      return 'light-success'
    },

    getTypeVariant(value) {
      if (value === 'IMPORT') return 'success'
      if (value === 'EXPORT') return 'primary'
      return 'primary'
    },

    formatedDataType(value) {
      if (value === 'CONTACT') return this.$i18n.t('Contacts')
      if (value === 'CONTACT_THREAD') return this.$i18n.t('Threads')
      if (value === 'CONTACT_THREAD_TASK') return this.$i18n.t('Tasks')
      if (value === 'CONTACT_THREAD_NOTE') return this.$i18n.t('Notes')
      if (value === 'CONTACT_THREAD_CART_ORDER') return this.$i18n.t('Orders')

      return '-'
    },

    formatedStatus(value) {
      if (value === 'QUEUE') return 'InQueue'
      return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
    },

    async deleteItem(itemId, index) {
      const confirmed = await this.showAlert('warning', this.$i18n.t('alerts.AreYouSure'))
      if (!confirmed) return

      try {
        await axiosIns.delete('1/migrations', { data: JSON.stringify([itemId]) })

        this.showToast('success', this.$i18n.t('RemovedSuccessfully'))
        this.$delete(this.items, index)
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    getStatus(status) {
      let toReturn = null
      switch (status) {
        case 'ANSWERED': toReturn = { icon: 'PhoneCallIcon', variant: 'light-success' }; break
        case 'BUSY': toReturn = { icon: 'PhoneOffIcon', variant: 'light-warning' }; break
        case 'NO_ANSWER': toReturn = { icon: 'PhoneMissedIcon', variant: 'light-danger' }; break
        default: toReturn = { icon: '', variant: 'light-secondary' }
      }

      return toReturn
    },
  },
}
</script>

<style lang="scss">
</style>
