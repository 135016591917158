<template>
  <div
    class="d-flex align-items-center flex-wrap"
    style="gap: .34rem"
  >
    <!--    :class="{ 'sticky-top': sticky }"-->
    <div
      class="d-flex align-items-center flex-nowrap"
      style="gap: .5rem"
    >
      <sw-icon icon="fa-align-center" />

      <strong>
        {{ $t('FilterBy') }}:
      </strong>
    </div>

    <template v-for="(filter, i) in filters">
      <!--    START::Refresh    -->
      <f-refresh
        v-if="filter.type === 'refresh'"
        :key="i"
        v-model="filters[i]"
        @click="$emit('refresh')"
      />
      <!--    END::Refresh    -->

      <!--    START::Search    -->
      <f-search
        v-if="filter.type === 'search'"
        :key="i"
        v-model="filters[i]"
        @search="$emit('search', $event)"
        @change="$emit('change', $event)"
      />
      <!--    END::Search    -->

      <!--    START::Radio Select    -->
      <f-radio
        v-if="filter.type === 'radio'"
        :key="i"
        v-model="filters[i]"
        @change="$emit('change', $event); $emit('change-all', { filters, option: filter.active })"
      />
      <!--    END::Radio Select    -->

      <!--    START::Checkbox Select    -->
      <f-checkbox
        v-if="filter.type === 'checkbox'"
        :key="i"
        v-model="filters[i]"
        @change="$emit('change', $event); $emit('change-all', { filters, option: filter.active })"
      />
      <!--    END::Checkbox Select    -->
    </template>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import Refresh from './types/Refresh.vue'
import Search from './types/Search.vue'
import Radio from './types/Radio.vue'
import Checkbox from './types/Checkbox.vue'

export default {
  components: {
    'f-refresh': Refresh,
    'f-search': Search,
    'f-radio': Radio,
    'f-checkbox': Checkbox,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    sticky: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['change-all', 'change', 'search', 'refresh'],
  data: () => ({
    uuid: uuidv4(),
    search: '',
    filters: [],
  }),
  watch: {
    value: {
      deep: true,
      handler(n) { this.filters = JSON.parse(JSON.stringify(n)) },
    },
  },
  async mounted() {
    this.filters = JSON.parse(JSON.stringify(this.value))
  },
}
</script>
