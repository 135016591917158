<template>
  <div>
    <b-tabs>
      <b-tab :title="$t('Accounts')">
        <b-button v-if="checkRequiredPermissions([$roles.CONTACT_THREAD_GOOGLE_ADS_CLIENT_ACCOUNT_ADD])"
                  variant="primary"
                  size="sm"
                  class="mb-50"
                  @click="openGoogleConnectionModal(defaultConnectionItem)"
        >
          {{ $t('ConnectWithGoogleAccount') }}
        </b-button>
        <!-- Content -->
        <!-- eslint-disable -->
            <b-card>
                <sw-table
                        table-id="268111d8194e412354f3dc6ff4b3aafcf"
                        show-columns
                        show-search
                        :fields.async="fields"
                        :pagination="pagination"
                        @change-pagination="Object.assign(pagination, $event)"
                        @change-search-query="search = $event"
                        @reload-content="initData"
                        @set-fields="fields = $event"
                >
                    <template #table>
                        <!--      Table      -->
                        <b-table
                                :items="items"
                                :fields="tableFields"
                                striped
                                responsive
                                :style="{ minHeight: tableSize(items, 250) }"
                                :busy="loading"
                                show-empty
                                :sort-by.sync="sorter.sortBy"
                                :sort-desc.sync="sorter.sortDesc"
                                :no-local-sorting="true"
                                @sort-changed="Object.assign(sorter, $event); initData()"
                        >
                            <!--    Head    -->
                            <template #head()="{ label }">
                                {{ $t(`${label}`) }}
                            </template>
                            <template #cell(id)="{ index }">
                                #{{ getOrderNo(index, pagination, sorter) }}
                            </template>

                            <template #cell(adBudgetLimit)="{ value, item }">
                                {{ value | priceFormat }}{{ item.adBudgetLimitCurrency || 'PLN' }}
                            </template>

                            <template #cell(campaigns)="row">
                                <b-button size="sm"
                                          class="mr-2 btn-icon"
                                          variant="outline-primary"
                                          @click="row.toggleDetails"
                                >
                                    <feather-icon :icon="row.detailsShowing ? 'MinusIcon' : 'PlusIcon'" />
                                </b-button>
                                <!--                  <sw-icon v-else-->
                                <!--                           icon="MinusIcon"-->
                                <!--                  />-->
                            </template>

                            <!-- Subcategory Section -->
                            <template #row-details="{ item }">
                                <GoogleAdsCampaigns :google-ads-account="item" />
                            </template>

                            <template #cell(type)="{ item }">
                              <span v-if="item.contactThreadPaymentSchedule && accountTypes.find(e => e.type === item.contactThreadPaymentSchedule.type)">
                                  {{ $t(accountTypes.find(e => e.type === item.contactThreadPaymentSchedule.type).label) }}
                              </span>
                                              <sw-icon v-else icon="MinusIcon" />
                                          </template>
                                          <template #cell(documentType)="{ item }">
                              <span v-if="item.contactThreadPaymentSchedule && documentTypes.find(e => e.type === item.contactThreadPaymentSchedule.documentType)">
                                  {{ $t(documentTypes.find(e => e.type === item.contactThreadPaymentSchedule.documentType).label) }}
                              </span>
                                              <sw-icon v-else icon="MinusIcon" />
                                          </template>
                                          <template #cell(documentIssuingType)="{ item }">
                              <span v-if="item.contactThreadPaymentSchedule && documentIssuingTypes.find(e => e.type === item.contactThreadPaymentSchedule.documentIssuingType)">
                                  {{ $t(documentIssuingTypes.find(e => e.type === item.contactThreadPaymentSchedule.documentIssuingType).label) }}
                              </span>
                                              <sw-icon v-else icon="MinusIcon" />
                                          </template>
                                          <template #cell(serviceSellDate)="{ item }">
                              <span v-if="item.contactThreadPaymentSchedule && serviceSellDates.find(e => e.type === item.contactThreadPaymentSchedule.serviceSellDate)">
                                  {{ $t(serviceSellDates.find(e => e.type === item.contactThreadPaymentSchedule.serviceSellDate).label) }}
                              </span>
                                              <span v-else>{{ $t('CurrentMonth') }}</span>
                                          </template>

<!--                            <template #cell(status)="{ value }">-->
<!--                                <b-badge :variant="getStatusByValue(value).color" v-if="getStatusByValue(value)">-->
<!--                                    {{ $t(getStatusByValue(value).label) }}-->
<!--                                </b-badge>-->
<!--                            </template>-->
<!--                            <template #cell(topUpMode)="{ value }">-->
<!--                                <b-badge variant="light-primary" v-if="getTopUpModeByValue(value)">-->
<!--                                    {{ $t(getTopUpModeByValue(value)) }}-->
<!--                                </b-badge>-->
<!--                            </template>-->
                            <template #cell(status)="{ value }">
                                <b-badge :variant="getStatusByValue(value).color" v-if="getStatusByValue(value)">
                                    {{ $t(getStatusByValue(value).label) }}
                                </b-badge>
                            </template>

                            <template #cell(contactThread)="{ item }">
        <span v-if="item && item.contactThread">
          <router-link :class="{ 'deleted-text': item.contactThread.contact && item.contactThread.contact.deletedAt }"
                       :to="`/contact/${item.contactThread.contact.id}/thread/${item.contactThread.id}/payments`"
          >
              {{ getUserName(item.contactThread.contact) }} - {{ item.contactThread.name }}
          </router-link>
        </span>
                                <span v-else>
          <feather-icon icon="MinusIcon" />
        </span>
                            </template>

                            <template #cell(paymentDaysCount)="{ item }">
                                <span v-if="item.contactThreadPaymentSchedule">
                                  {{ $t(item.contactThreadPaymentSchedule.paymentDaysCount) }} {{ $tc('Days', item.contactThreadPaymentSchedule.paymentDaysCount) }}
                                </span>
                            </template>
                            <template #cell(maxPaymentsPerMonth)="{ item }">
                                <span v-if="item.contactThreadPaymentSchedule">
                                  {{ $t(item.contactThreadPaymentSchedule.maxPaymentsPerMonth) }}
                                </span>
                            </template>

                            <template #cell(chargeAccountPercentageCondition)="{ value }">
                                <span v-if="value">{{ $t(value) }}%</span>
                                <span v-else>
                      <sw-icon icon="MinusIcon"></sw-icon>
                  </span>
                            </template>

                            <template #cell(monthChargesCount)="{ value }">
                                <span v-if="value">{{ $t(value) }}</span>
                                <span v-else>
                      <sw-icon icon="MinusIcon"></sw-icon>
                  </span>
                            </template>
                            <!--    Other    -->
                            <template #cell()="row">
                                <table-default-cell
                                        :field="row.field"
                                        :value="row.value"
                                />
                            </template>

                            <template #cell(action)="{ item, index }">
                                <b-dropdown
                                        id="dropdown-grouped"
                                        dropleft
                                        no-caret
                                        variant="flat"
                                        v-if="checkRequiredPermissions([$roles.CONTACT_THREAD_GOOGLE_ADS_CLIENT_ACCOUNT_EDIT])"
                                >
                                    <template #button-content>
                                        <feather-icon icon="MoreVerticalIcon" />
                                    </template>
                                    <div>
                                        <b-dropdown-item @click="openChangeStatusModal(item, index)" v-if="item.status === 'ACTIVE'">
                                            <dropdown-item-label icon="XIcon"
                                                                 :label="$t('SuspendAccount')"
                                            />
                                        </b-dropdown-item>
                                        <b-dropdown-item @click="openChangeStatusModal(item, index)" v-if="item.status === 'INACTIVE'">
                                            <dropdown-item-label icon="PlayIcon"
                                                                 :label="$t('ResumeAccount')"
                                            />
                                        </b-dropdown-item>
<!--                                        <b-dropdown-item>-->
<!--                                            <dropdown-item-label icon="ArchiveIcon"-->
<!--                                                                 :label="$t('ArchiveAccount')"-->
<!--                                            />-->
<!--                                        </b-dropdown-item>-->
                                        <b-dropdown-item @click="openGoogleConnectionModal(item)">
                                            <dropdown-item-label icon="EditIcon"
                                                                 :label="$t('EditAccountSettings')"
                                            />
                                        </b-dropdown-item>
                                    </div>
                                </b-dropdown>
                            </template>

                            <!--      Empty      -->
                            <template #empty>
                                <empty-content />
                            </template>

                            <!--      Table Busy      -->
                            <template #table-busy>
                                <div class="text-center text-danger my-2">
                                    <b-spinner
                                            class="align-middle"
                                            variant="primary"
                                    />
                                </div>
                            </template>
                        </b-table>
                    </template>
                </sw-table>
            </b-card>
        </b-tab>
        <b-tab :title="$t('History')">
            <b-card>
                <GoogleAccountLogs :thread="thread" />
            </b-card>
        </b-tab>
    </b-tabs>

    <!-- Add Modal -->
    <b-modal v-model="connectionModal.show"
             :title="$t(connectionModal.item && !connectionModal.item.id ? 'Create' : 'Edit')"
             size="lg"
             hide-footer
    >
      <AddGoogleAdsForm
        v-if="connectionModal.show"
        :edited-item="connectionModal.item"
        @save="saveModalForm"
      />
    </b-modal>

    <!-- Hold Modal -->
    <b-modal v-model="holdModal.show"
             :title="$t(holdModal.item && !holdModal.item.id ? 'ChangeAccountStatus' : 'ChangeAccountStatus')"
             size="lg"
             hide-footer
             @hidden="campaigns = []"
    >
<!--            <b-form-group>-->
<!--                <b-form-checkbox>-->
<!--                    {{ $t('SpecifySuspensionDate') }}-->
<!--                </b-form-checkbox>-->
<!--            </b-form-group>-->

<!--            <sw-select :name="$t('SuspensionDate')">-->
<!--                <sw-select-fpr>-->
<!--                    <flat-pickr class="form-control"-->
<!--                                :config="{ locale: getCalendarLocale($i18n.locale) }"-->
<!--                    />-->
<!--                </sw-select-fpr>-->
<!--            </sw-select>-->

        <sw-info-alert :name="$t('SuspensionPeriodInfo')" />

        <div class="divider divider-left">
            <div class="divider-text text-primary">
                {{ $t(holdModal.item.status === 'ACTIVE' ? 'SuspendedCampaigns' : 'ActiveCampaigns') }}
            </div>
        </div>

        <b-list-group v-if="campaigns && campaigns.length">
            <b-list-group-item v-for="(campaign, index) in campaigns" :key="`camp_${index}`">{{ campaign.name }}</b-list-group-item>
        </b-list-group>

        <div>
            <sw-action-button ref="save-action"
                              label="Save"
                              siz="sm"
                              class="mt-50"
                              variant="primary"
                              @click="changeStatus(holdModal.item, holdModal.item.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE', holdModal.index)"
            />
        </div>
    </b-modal>
  </div>
</template>

<script>

import AddGoogleAdsForm from '@/pages/contact/components/AddGoogleAdsForm.vue'
import { BTable } from 'bootstrap-vue'
import axiosIns from '@/libs/axios'
import GoogleAdsCampaigns from '@/views/core/contact/google/GoogleAdsCampaigns.vue'
import GoogleAccountLogs from '@/pages/settings/integrations/GoogleAccountLogs.vue'

const defaultConnectionItem = {
  id: null,
  name: '',
  topUpMode: 'ONE_TIME',
  adBudgetLimit: '',
  generateProForma: false,
  proFormaExposureDay: '',
  serviceName: '',
  serviceSellDate: '',
  percentageSalary: 0,
  turnOffCampaign: false,
  accountEndDate: '',
  chargeAccountPercentageCondition: '',
  chargeAccountAfterInvoicePaid: false,
  monthChargesCount: 0,
  paymentDaysCount: 0,
  adBudgetLimitCurrency: 'PLN',
}
export default {
  name: 'GoogleAds',
  components: {
    GoogleAccountLogs, GoogleAdsCampaigns, BTable, AddGoogleAdsForm,
  },
  props: {
    contact: {
      default: null,
      required: false,
    },
    thread: {
      default: null,
      required: false,
    },
  },
  data: () => ({
    defaultConnectionItem,
    connectionModal: {
      show: false,
      item: JSON.parse(JSON.stringify(defaultConnectionItem)),
      index: -1,
    },
    holdModal: {
      show: false,
      item: JSON.parse(JSON.stringify(defaultConnectionItem)),
      index: -1,
    },
    loading: false,
    items: [],
    fields: [
      {
        key: 'contactThread', label: 'Thread', visible: true, onlyGlobal: true,
      },
      { key: 'campaigns', label: 'Campaigns', visible: true },
      { key: 'name', label: 'Name', visible: true },
      { key: 'status', label: 'Status', visible: true },
      { key: 'adBudgetLimit', label: 'BudgetValue', visible: true }, // adBudgetLimitCurrency

      // { label: 'ScheduleTypeSelection', key: 'type', visible: true },
      { label: 'NetAmount', key: 'netPrice', visible: true },
      { label: 'GeneratedDocumentType', key: 'documentType', visible: true },
      { label: 'AutomaticVatInvoiceGeneration', key: 'generateVatAfterPaid', visible: true },
      { label: 'IssueFrequency', key: 'documentIssuingType', visible: true },
      { label: 'DocumentsPerMonth', key: 'maxPaymentsPerMonth', visible: true },
      { label: 'SalesDate', key: 'serviceSellDate', visible: true },
      { label: 'PaymentTerm', key: 'paymentDaysCount', visible: true },
      { label: 'EndScheduleDate', key: 'scheduleEndDate', visible: true },

      // { key: 'topUpMode', label: 'AccountTopUpMode', visible: true },
      // { key: 'chargeAccountPercentageCondition', label: 'TopUpCondition', visible: true },
      // { key: 'monthChargesCount', label: 'MonthChargesCount', visible: true },
      // { key: 'accountEndDate', label: 'AccountEndDate', visible: true },
      // { key: 'percentageSalary', label: 'RemunerationPercentage', visible: true },
      // { key: 'paymentDaysCount', label: 'PaymentDueDate', visible: true },

      // { key: 'proFormaExposureDay', label: 'ProFormInvoiceDay', visible: true },
      // { key: 'serviceName', label: 'ServiceName', visible: true },
      // { key: 'serviceSellDate', label: 'DateOfSellService', visible: true },
      // { key: 'turnOffCampaign', label: 'DoNotAutomaticallyTurnOffCampaign', visible: true },
      // { key: 'chargeAccountAfterInvoicePaid', label: 'ChargeAccountAfterInvoicePaidStatus', visible: true },
      // { key: 'generateProForma', label: 'GenerateProFormInvoice', visible: true },
      {
        key: 'action', label: 'Action', visible: true,
      },
    ],
    documentTypes: [
      { type: 'PRO_FORMA', label: 'ProFormaInvoice' },
      { type: 'VAT', label: 'VatInvoice' },
    ],
    documentIssuingTypes: [
      { type: 'MONTHLY', label: 'OncePerMonth' },
      { type: 'DEPENDENCY', label: 'DependsOnGoogleAdsBudgetUsage' },
    ],
    serviceSellDates: [
      { type: 'CURRENT_MONTH', label: 'CurrentMonth' },
    ],
    sorter: {
      sortBy: 'id',
      sortDesc: true,
    },
    pagination: {
      perPage: 25,
      currentPage: 1,
      totalRecords: 0,
    },
    search: '',
    campaigns: [],
  }),
  computed: {
    visibleFields() {
      return this.fields
        .filter(field => !field?.requiredModule || this.checkRequiredModule(field?.requiredModule))
        .filter(field => !field.onlyGlobal || (this.$route.name === 'google-ads' && field.onlyGlobal))
    },
    tableFields() {
      return this.visibleFields.filter(field => field.visible)
    },
  },
  async mounted() {
    defaultConnectionItem.adBudgetLimitCurrency = this.$store.getters['system/getSettings'].defaultCurrency || 'PLN'
    this.defaultConnectionItem.adBudgetLimitCurrency = this.$store.getters['system/getSettings'].defaultCurrency || 'PLN'

    await this.initData()
  },
  methods: {
    getStatusByValue(value) {
      const a = [
        { label: 'SessionActive', v: 'ACTIVE', color: 'light-success' },
        { label: 'SessionInactive', v: 'INACTIVE', color: 'light-danger' },
      ]
      if (a.findWhere('v', value)) return a.findWhere('v', value)
      return null
    },
    getTopUpModeByValue(value) {
      const a = [
        { v: 'ONE_TIME', label: 'OncePerMonth' },
        { v: 'MANY_TIMES', label: 'ManyTimesAMonth' },
      ]
      if (a.findWhere('v', value)) return a.findWhere('v', value).label

      return null
    },
    async saveModalForm() {
      this.$set(this, 'connectionModal', {
        show: false,
        item: JSON.parse(JSON.stringify(defaultConnectionItem)),
        index: -1,
      })

      this.initData()
    },
    openGoogleConnectionModal(item = defaultConnectionItem, index = -1) {
      this.$set(this, 'connectionModal', {
        show: true,
        item: JSON.parse(JSON.stringify(item)),
        index,
      })
    },
    async openChangeStatusModal(item, index = -1) {
      if (item.id) {
        const response = await axiosIns.get(`1/integration/google/connections/googleAdsAccounts/${item.integrationGoogleConnectionGoogleAdsAccount.id}/clients/${item.googleAdsClientId}/campaigns`)
        this.campaigns = response.data.data?.items || []
      }

      this.$set(this, 'holdModal', {
        show: true,
        item: JSON.parse(JSON.stringify(item)),
        index,
      })
    },
    async changeStatus(item, status, index) {
      try {
        await axiosIns.patch('1/contacts/threads/googleAdsClientAccounts', { id: item.id, status })
        this.showToast('success', this.$i18n.t('success.contactUpdated'))

        this.$set(this.items[index], 'status', status)
        this.$set(this, 'holdModal', {
          show: false,
          item: JSON.parse(JSON.stringify(defaultConnectionItem)),
          index: -1,
        })
        this.campaigns = null
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    async initData() {
      try {
        this.loading = true
        const params = {
          fields_load: 'GOOGLE_ADS_CLIENT_ACCOUNT',
          orderBy: 'id.desc',
        }

        if (this.pagination) {
          params.page = this.pagination.currentPage
          params.limit = this.pagination.perPage
        }

        if (this.sorter) {
          const sortDir = this.sorter?.sortDesc ? 'desc' : 'asc'
          const sortBy = this.sorter?.sortBy || 'id'
          params.orderBy = `${sortBy}.${sortDir}`
        }

        // if (this.search.length) {
        //   const searchString = this.search.replaceAll(' ', '%')
        //   params.search = `%${searchString}%`
        //   params.search_fields = []
        // }

        if (this.thread) {
          params.eq_contactThread = this.getObjectId(this.thread)
        }

        const resp = await axiosIns.get('1/contacts/threads/googleAdsClientAccounts', { params })

        this.items = resp.data.data?.items
        this.pagination.totalRecords = resp.data.data?.totalItemCount || 0
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>

</style>
