<template>
  <div>
    <!--    Option: Options    -->
    <b-row class="mx-0">
      <b-col
        sm="12"
        md="6"
        lg="4"
        class="px-1"
      >
        <my-option
          :title="$t('Options')"
          :tip="$t('isProductDescTip')"
        >
          <b-form-group>
            <b-form-checkbox
              v-model="newWebsite.showProductsDescription"
              checked="true"
              name="check-button"
              variant="primary"
              switch
              inline
              class="w-100"
            >
              {{ $tc('isProductDesc', Number(newWebsite.showProductsDescription)) }}
            </b-form-checkbox>
          </b-form-group>
        </my-option>
      </b-col>

      <b-col
        sm="12"
        md="6"
        lg="4"
        class="px-1"
      >
        <my-option
          :title="$t('GalleryVariant')"
          :tip="$t('VariantTip')"
        >
          <sw-select>
            <v-select
              v-model="newWebsite.imageGalleryLayoutType"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              :options="galleryVariantOptions"
              item-value="id"
              :get-option-label="name => $t(`offer.website.GalleryVariants.${name}`)"
            >
              <template #no-options>
                {{ $t('NoOptions') }}
              </template>
            </v-select>
          </sw-select>
        </my-option>
      </b-col>
    </b-row>

    <!--    Option: Title    -->
    <b-row class="mx-0">
      <b-col
        sm="12"
        md="6"
        lg="8"
        class="px-1"
      >
        <my-option
          :title="$t('Title')"
          :tip="$t('MaximumLength') + ': 60'"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="$t('Title')"
            rules="required"
            class="w-100"
          >
            <b-form-group>
              <b-form-input
                id="popover-aboutTitle"
                v-model.trim="newWebsite.productTitle"
                :placeholder="$t('Title')"
                :state="errors.length > 0 ? false:null"
                maxlength="60"
              />
              <small class="text-danger">{{ errors[0] }}</small>

              <!--    Popover    -->
              <b-popover
                target="popover-aboutTitle"
                triggers="focus"
                variant="primary"
              >
                <span>{{ $t('MaximumLength') + ': 60' }}</span>
              </b-popover>
            </b-form-group>
          </validation-provider>
        </my-option>
      </b-col>
    </b-row>

    <!--    Option: Description    -->
    <b-row class="mx-0">
      <b-col
        sm="12"
        md="6"
        lg="8"
        class="px-1"
      >
        <my-option
          :title="$t('task.Description')"
          :tip="$t('MaximumLength') + ': 110'"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="$t('Title')"
            rules="required"
            class="w-100"
          >
            <b-form-group>
              <b-form-textarea
                id="popover-bannerDesc"
                v-model.trim="newWebsite.productDesc"
                :placeholder="$t('Title')"
                :state="errors.length > 0 ? false:null"
                maxlength="110"
              />
              <small class="text-danger">{{ errors[0] }}</small>

              <!--    Popover    -->
              <b-popover
                target="popover-bannerDesc"
                triggers="focus"
                variant="primary"
              >
                <span>{{ $t('MaximumLength') + ': 110' }}</span>
              </b-popover>
            </b-form-group>
          </validation-provider>
        </my-option>
      </b-col>

      <b-col
        sm="12"
        class="px-1"
      >
        <h6>{{ $t('offer.product.presentation') }}</h6>
        <b-alert
          variant="secondary"
          show
        >
          <div class="alert-body">
            <span>
              <feather-icon
                icon="InfoIcon"
                size="18"
              />
              {{ $t('offer.product.PricePresentation') }}
            </span>
          </div>
        </b-alert>

        <b-form-checkbox
          v-model="newWebsite.productPrices"
          checked="true"
          name="check-button"
          variant="primary"
          inline
          class="w-100"
        >
          {{ $t('offer.product.HidePrices') }}
        </b-form-checkbox>

        <b-form-checkbox
          v-model="newWebsite.hideOfferPrice"
          checked="false"
          name="check-button"
          inline
          class="w-100 mt-50"
        >
          {{ $t('offer.product.HideTotalPrice') }}
        </b-form-checkbox>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BFormTextarea, BPopover, VBTooltip } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import myOption from '@/views/offers/website/Option.vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

export default {
  components: {
    BFormTextarea,
    myOption,
    ValidationProvider,
    vSelect,
    BPopover,
  },

  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  props: {
    value: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    newWebsite: { },

    galleryVariantOptions: [
      'VERTICAL',
      'HORIZONTAL',
      'GRID',
    ],
  }),

  mounted() {
    this.newWebsite = this.value

    // eslint-disable-next-line prefer-destructuring
    this.newWebsite.galleryVariant = this.galleryVariantOptions[0].id

    // const { data } = this.$store.dispatch(`websites/${GET_WEBSITE}`, this.oldId)
    // this.galleryVariantOptions = data.items
  },

  methods: {
  },
}
</script>
