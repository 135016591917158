const remove = (items, keys = []) => {
  const itemCp = items

  Object.keys(itemCp).forEach(key => {
    if (keys.includes(key)) return
    // eslint-disable-next-line no-use-before-define
    if (itemCp[key]) payloadKeysCutter(itemCp[key], keys)
  })

  keys.forEach(key => {
    delete itemCp[key]
  })
  return itemCp
}

const payloadKeysCutter = (items, keysToRemove = []) => {
  const defaultKeys = ['deletedAt', 'deletedBy', 'updatedAt', 'updatedBy', 'createdAt', 'createdBy']
  defaultKeys.push(...keysToRemove)

  if (Array.isArray(items) && items.length) {
    return items.map(item => remove(item, defaultKeys))
  }

  if (typeof items === 'object') {
    return remove(items, defaultKeys)
  }

  return null
}

export default payloadKeysCutter
