<template>
  <!-- eslint-disable -->
  <div class="d-flex flex-column">
    <!--    START::Buttons    -->
    <div class="mt-25">
      <b-button
        variant="dark"
        size="sm"
        class="mr-50"
        @click="$router.push({ name: 'loyaltyPlans' })"
      >
        <feather-icon icon="ArrowLeftIcon" />
        {{ $t('Return') }}
      </b-button>

      <b-button
        type="submit"
        variant="primary"
        size="sm"
        @click="saveItem"
      >
        {{ $t('Save') }}
      </b-button>
    </div>

    <!--    END::Buttons    -->
    <validation-observer ref="planForm">
      <!--    START::Info    -->
      <b-card class="mt-2">
        <info
          :is-loading="isLoading"
          :target.async="target"
        />
      </b-card>
      <!--    END::Info    -->

      <!--    START::Punctation    -->
      <b-card>
        <punctation
          :is-loading="isLoading"
          :target.async="target"
        />
      </b-card>
      <!--    END::Punctation    -->
    </validation-observer>

    <div class="mb-2">
      <b-button
        type="submit"
        variant="primary"
        @click="saveItem"
      >
        {{ $t('Save') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import axiosIns from '@/libs/axios'
import payloadKeysCutter from '@/helpers/keysCuter'
import { ValidationObserver } from 'vee-validate'
import info from './components/new/Info.vue'
import punctation from './components/new/Punctation.vue'

const defaultItem = {
  name: '',
  // accuringPointsBasis: '',
  qualifiedStatus: '',
  qualifiedStatusEndDaysAmount: '',
  contractDaysAmount: '',
  typeOfAssignLoyaltyPoints: 'PRODUCT_VALUE',
  typeOfAssignLoyaltyPointsToOwnPurchase: 'PRODUCT_VALUE',
  chargePointsForSales: false,
}

export default {
  components: {
    info,
    punctation,
    ValidationObserver,
  },
  data: () => ({
    isLoading: false,
    target: { ...defaultItem },
  }),
  async mounted() {
    const id = this.$route.params?.loyaltyPlanId || ''
    if (Boolean(id) && id !== 'new') {
      await this.init(id)
    } else {
      Object.assign(this.target, { ...defaultItem })
    }
  },
  methods: {
    async init(id) {
      this.isLoading = true

      try {
        const resp = await axiosIns.get(`1/loyaltyPlans/${id}`, { params: { fields_load: this.$fields.LOYALTY_PLANS } })

        // eslint-disable-next-line
        const item = resp?.data?.data?.item
        if (item) {
          Object.assign(this.target, {
            ...item,
          })
        }
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.isLoading = false
      }
    },
    // eslint-disable-next-line consistent-return
    async saveItem() {
      const isValid = await this.$refs.planForm.validate()
      if (!isValid) return false

      const itemCopy = payloadKeysCutter({ ...this.target }, ['lastExecutedAt'])

      try {
        const resp = itemCopy?.id
          ? await axiosIns.patch('1/loyaltyPlans', itemCopy)
          : await axiosIns.post('1/loyaltyPlans', itemCopy)

        if (resp?.data?.data) {
          await this.$router.push({ name: 'loyaltyPlans' })
          this.showToast('success', this.$i18n.t('success.contactUpdated'))
        }
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
  },
}
</script>
