<template>
  <div>
    <div>
      <b-row>
        <b-col sm="6"
               md="4"
        >
          <b-card :class="{ 'border-primary': filters.type === 'ALL' }"
                  class="border cursor-pointer flex-grow-1"
                  no-body
                  style="min-width: 200px"
                  @click="setFilters('type', 'ALL')"
          >
            <div class="d-flex justify-content-between align-items-center py-1 px-2">
              <div>
                <h3 class="mb-25 font-weight-bolder">
                  {{ stats.type.ALL }}
                </h3>
                <span>{{ $t('All') }}</span>
              </div>
              <b-avatar size="40"
                        variant="light-primary"
              >
                <sw-icon icon="PhoneIcon" />
              </b-avatar>
            </div>
            <b-overlay :show="loading"
                       no-wrap
                       spinner-variant="primary"
            />
          </b-card>
        </b-col>
        <b-col sm="6"
               md="4"
        >
          <b-card :class="{ 'border-primary': filters.type === 'IN' }"
                  class="border cursor-pointer flex-grow-1"
                  no-body
                  style="min-width: 200px"
                  @click="setFilters('type', 'IN')"
          >
            <div class="d-flex justify-content-between align-items-center py-1 px-2">
              <div>
                <h3 class="mb-25 font-weight-bolder">
                  {{ stats.type.IN }}
                </h3>
                <span>{{ $t('IN') }}</span>
              </div>
              <b-avatar size="40"
                        variant="light-primary"
              >
                <sw-icon icon="PhoneIncomingIcon" />
              </b-avatar>
            </div>
            <b-overlay :show="loading"
                       no-wrap
                       spinner-variant="primary"
            />
          </b-card>
        </b-col>

        <b-col sm="6"
               md="4"
        >
          <b-card :class="{ 'border-primary': filters.type === 'OUT' }"
                  class="border cursor-pointer flex-grow-1"
                  no-body
                  style="min-width: 200px"
                  @click="setFilters('type', 'OUT')"
          >
            <div class="d-flex justify-content-between align-items-center py-1 px-2">
              <div>
                <h3 class="mb-25 font-weight-bolder">
                  {{ stats.type.OUT }}
                </h3>
                <span>{{ $t('OUT') }}</span>
              </div>
              <b-avatar size="40"
                        variant="light-primary"
              >
                <sw-icon icon="PhoneOutgoingIcon" />
              </b-avatar>
            </div>
            <b-overlay :show="loading"
                       no-wrap
                       spinner-variant="primary"
            />
          </b-card>
        </b-col>
      </b-row>

      <b-row>
        <b-col sm="12">
          <div class="d-flex flex-wrap">
            <b-badge v-for="[status, value] in Object.entries(stats.status)"
                     :key="status"
                     class="m-25 py-50 flex-grow-1"
                     :variant="filters.status === status ? 'primary' : 'light-primary'"
                     @click="setFilters('status', status)"
            >
              {{ $t(status) }} ({{ value }})
            </b-badge>
          </div>
        </b-col>
      </b-row>

      <!--      <div class="d-flex flex-wrap mb-50">-->
      <!--        <div>{{ $t('task.Type') }}: </div>-->
      <!--        <div>-->
      <!--          <b-badge :variant="filters.type === 'all' ? 'primary' : 'light-primary'"-->
      <!--                   @click="setFilters('type', 'all')"-->
      <!--          >-->
      <!--            {{ $t('All') }}-->
      <!--          </b-badge>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>

    <!--    <div class="d-flex flex-wrap">-->
    <!--      <b-card class="border cursor-pointer flex-grow-1 mr-1"-->
    <!--              style="min-width: 200px"-->
    <!--              @click="setFilters('type', 'IN')"-->
    <!--      >-->
    <!--        <div class="d-flex justify-content-between align-items-center">-->
    <!--          <div>-->
    <!--            <h3 class="mb-25 font-weight-bolder">-->
    <!--              {{ stats.type.IN }}-->
    <!--            </h3>-->
    <!--            <span>{{ $t('IN') }}</span>-->
    <!--          </div>-->
    <!--          <b-avatar size="40"-->
    <!--                    variant="light-primary"-->
    <!--          >-->
    <!--            <sw-icon icon="PhoneIncomingIcon" />-->
    <!--          </b-avatar>-->
    <!--        </div>-->
    <!--        <b-overlay :show="loading"-->
    <!--                   no-wrap-->
    <!--                   spinner-variant="primary"-->
    <!--        />-->
    <!--      </b-card>-->

    <!--      <b-card class="border cursor-pointer flex-grow-1 mr-1"-->
    <!--              style="min-width: 200px"-->
    <!--              @click="setFilters('type', 'OUT')"-->
    <!--      >-->
    <!--        <div class="d-flex justify-content-between align-items-center">-->
    <!--          <div>-->
    <!--            <h3 class="mb-25 font-weight-bolder">-->
    <!--              {{ stats.type.OUT }}-->
    <!--            </h3>-->
    <!--            <span>{{ $t('OUT') }}</span>-->
    <!--          </div>-->
    <!--          <b-avatar size="40"-->
    <!--                    variant="light-primary"-->
    <!--          >-->
    <!--            <sw-icon icon="PhoneOutgoingIcon" />-->
    <!--          </b-avatar>-->
    <!--        </div>-->
    <!--        <b-overlay :show="loading"-->
    <!--                   no-wrap-->
    <!--                   spinner-variant="primary"-->
    <!--        />-->
    <!--      </b-card>-->

    <!--      <b-card class="border cursor-pointer flex-grow-1 mr-1"-->
    <!--              style="min-width: 200px"-->
    <!--              @click="setFilters('status', 'ANSWERED')"-->
    <!--      >-->
    <!--        <div class="d-flex justify-content-between align-items-center">-->
    <!--          <div>-->
    <!--            <h3 class="mb-25 font-weight-bolder">-->
    <!--              {{ stats.status.ANSWERED }}-->
    <!--            </h3>-->
    <!--            <span>{{ $t('ANSWERED') }}</span>-->
    <!--          </div>-->
    <!--          <b-avatar size="40"-->
    <!--                    variant="light-primary"-->
    <!--          >-->
    <!--            <sw-icon icon="PhoneCallIcon" />-->
    <!--          </b-avatar>-->
    <!--        </div>-->
    <!--        <b-overlay :show="loading"-->
    <!--                   no-wrap-->
    <!--                   spinner-variant="primary"-->
    <!--        />-->
    <!--      </b-card>-->

    <!--      <b-card class="border cursor-pointer flex-grow-1 mr-1"-->
    <!--              style="min-width: 200px"-->
    <!--              @click="setFilter('status', 'NO_ANSWER')"-->
    <!--      >-->
    <!--        <div class="d-flex justify-content-between align-items-center">-->
    <!--          <div>-->
    <!--            <h3 class="mb-25 font-weight-bolder">-->
    <!--              {{ stats.status.NO_ANSWER }}-->
    <!--            </h3>-->
    <!--            <span>{{ $t('BUSY') }}</span>-->
    <!--          </div>-->
    <!--          <b-avatar size="40"-->
    <!--                    variant="light-primary"-->
    <!--          >-->
    <!--            <sw-icon icon="PhoneMissedIcon" />-->
    <!--          </b-avatar>-->
    <!--        </div>-->
    <!--        <b-overlay :show="loading"-->
    <!--                   no-wrap-->
    <!--                   spinner-variant="primary"-->
    <!--        />-->
    <!--      </b-card>-->

    <!--      <b-card class="border cursor-pointer flex-grow-1 mr-1"-->
    <!--              style="min-width: 200px"-->
    <!--              @click="setFilter('status', 'BUSY')"-->
    <!--      >-->
    <!--        <div class="d-flex justify-content-between align-items-center">-->
    <!--          <div>-->
    <!--            <h3 class="mb-25 font-weight-bolder">-->
    <!--              {{ stats.status.BUSY }}-->
    <!--            </h3>-->
    <!--            <span>{{ $t('NO_ANSWER') }}</span>-->
    <!--          </div>-->
    <!--          <b-avatar size="40"-->
    <!--                    variant="light-primary"-->
    <!--          >-->
    <!--            <sw-icon icon="PhoneOffIcon" />-->
    <!--          </b-avatar>-->
    <!--        </div>-->
    <!--        <b-overlay :show="loading"-->
    <!--                   no-wrap-->
    <!--                   spinner-variant="primary"-->
    <!--        />-->
    <!--      </b-card>-->
    <!--    </div>-->

    <div v-if="checkRequiredPermissions([$roles.PHONE_CALLS_VIEW_EDIT])"
         class="d-flex mb-50"
    >
      <b-button v-if="!contactThread"
                class="mr-50"
                variant="primary"
                :disabled="!selectedCalls.length"
                size="sm"
                @click="openModal"
      >
        <span>
          {{ $t('AssignToProcess') }}
        </span>
        <span v-if="selectedCalls.length">({{ selectedCalls.length }})</span>
      </b-button>

      <b-button class="mr-50"
                variant="primary"
                :disabled="!selectedCalls.length"
                size="sm"
                @click="noteModal.item = {
                  item: null,
                  note: '',
                }; noteModal.show = true; noteModal.isPreview = false"
      >
        <span>
          {{ $t('AddNote') }}
        </span>
        <span v-if="selectedCalls.length">({{ selectedCalls.length }})</span>
      </b-button>
    </div>
    <!-- Table -->
    <component :is="tag">
      <!-- eslint-disable -->
      <sw-table
        table-id="268111d8194e479ebf3dc6ff4b3aafcf"
        show-columns
        show-search
        :fields.async="fields"
        :pagination="pagination"
        @change-pagination="Object.assign(pagination, $event)"
        @change-search-query="search = $event"
        @reload-content="loadData"
        @set-fields="fields = $event"
      >
        <template #table>
          <!--      Table      -->
          <b-table
            :items="items"
            :fields="tableFields"
            striped
            responsive
            :style="{ minHeight: tableSize(items, 250) }"
            :busy="loading"
            show-empty
            :sort-by.sync="sorter.sortBy"
            :sort-desc.sync="sorter.sortDesc"
            :no-local-sorting="true"
            @sort-changed="Object.assign(sorter, $event); loadData()"
          >
            <!--    Head    -->
            <template #head()="{ label }">
              {{ $t(`${label}`) }}
            </template>
              <template #head(select)>
                  <b-form-checkbox v-model="selectAll"
                                   :disabled="!checkRequiredPermissions([$roles.PHONE_CALLS_VIEW_EDIT])"
                                   @change="selectAllItems"
                                   variant="primary"
                  />
              </template>
            <template #cell(id)="{ index }">
              #{{ getOrderNo(index, pagination, sorter) }}
            </template>

              <template #cell(select)="{ item }">
                  <b-form-checkbox v-model="selectedCalls"
                                   :disabled="!checkRequiredPermissions([$roles.PHONE_CALLS_VIEW_EDIT])"
                                   :value="item.id"
                                   variant="primary"
                  />
              </template>

              <template #cell(caller)="{ value }">
                <div>
                    <span v-if="value && value.phone">{{ value.phone }}</span>
                <sw-icon icon="MinusIcon" v-else />
                </div>
              </template>

              <template #cell(callerUser)="{ value, item }">
                  <div>
                      <avatar :user="value" v-if="value" />
                      <span v-else-if="item.caller && item.caller.phone">
                          <b-button v-if="checkRequiredModule('callModule') && checkRequiredPermissions([$roles.PHONE_CALLS_USE])"
                                    variant="flat-success"
                                    class="btn-icon p-25 rounded"
                                    @click="phoneNumber = item.caller.phone; showCallModal = true"
                          >
                <feather-icon icon="PhoneIcon" />
              </b-button>
                          {{ item.caller.phone }}
                      </span>
                      <b-badge variant="light-primary" v-else-if="item.callerSip">SIP: {{ item.callerSip }}</b-badge>
                      <sw-icon icon="MinusIcon" v-else />
                  </div>
              </template>

              <template #cell(destination)="{ value, index }">
                  <div class="d-flex align-items-center">
                      <span v-if="value && value.phone">
                          <b-button v-if="checkRequiredModule('callModule') && checkRequiredPermissions([$roles.PHONE_CALLS_USE]) && !value.users.length"
                                    variant="flat-success"
                                    class="btn-icon p-25 rounded"
                                    @click="phoneNumber = value.phone; showCallModal = true"
                          >
                <feather-icon icon="PhoneIcon" />
              </b-button>
                          {{ value.phone }}
                      </span>
                      <sw-icon icon="MinusIcon" v-else />

                      <b-badge class="ml-25" variant="primary" :id="`user_${index}`" v-if="value.users && value.users.length">
                          <sw-icon icon="UsersIcon" />
                      </b-badge>

                      <b-badge class="ml-25" variant="success" :id="`contact_${index}`" v-if="value.contacts && value.contacts.length">
                          <sw-icon icon="UsersIcon" />
                      </b-badge>

                      <b-popover triggers="hover" :target="`user_${index}`" :title="$t('Users')">
                          <div
                                  v-for="(user, i) in value.users"
                                  :key="i + '_user_' + index"
                                  class="mb-50"
                          >
                              <avatar
                                      :variant="'light-primary'"
                                      :user="user"
                                      show-name
                              />
                          </div>
                      </b-popover>

                      <b-popover triggers="hover" :target="`contact_${index}`" :title="$t('Contacts')">
                          <div
                                  v-for="(contact, i) in value.contacts.filter(e => !e.deletedAt)"
                                  :key="i + '_contact_' + index"
                                  class="mb-50"
                          >
                              <avatar
                                      :variant="'light-primary'"
                                      :user="contact"
                                      show-name
                              />
                          </div>
                      </b-popover>
                  </div>
              </template>

              <template #cell(file)="{ item, index }">
                  <span v-if="item.file"  class="sw-audio-player">
                      <div class="d-flex align-items-center" v-if="!item.loadedFile">
                          <div class="player-container">
            <div id="player-row" class="player-row d-flex align-items-center">
                          <div class="button-div">
                              <b-button @click="loadAudio(item.file, index)" :disabled="item.fileLoading" variant="gradient-primary" class="btn-icon" size="sm" v-b-tooltip :title="$t('LoadFile')">
                                <feather-icon icon="PlayIcon" />
                              </b-button>
                          </div>
                        <div class="d-flex">
                              <span class="elapsed-time mr-25">00:00</span>
                              <div id="progress-bar" class="progress-bar">
                                  <div class="overlay-container">
                                      <input
                                              id="position"
                                              :value="0"
                                              type="range"
                                              disabled
                                              min="0"
                                              :max="item.talkTime"
                                              class="slider"
                                              name="position"
                                      >
                                  </div>
                              </div>
                              <span class="total-time ml-25">{{ formatSecondsToTime(item.talkTime) }}</span>
                          </div>
            </div>
                          </div>
                      </div>

                      <div v-else class="custom-audio-player">
                          <AudioPlayer :playerid="index" :url="item.loadedFile" />
<!--                          <audio style="height: 20px; color: red" controls autoplay :id="index"><source :src="item.loadedFile" :type="`audio/${item.file.extension}`" />-->
<!--            </audio>-->
                      </div>
                  </span>
                  <sw-icon icon="MinusIcon" v-else />
              </template>

              <template #cell(status)="{ value }">
                  <b-badge :variant="getStatus(value).variant">
                      <sw-icon :icon="getStatus(value).icon" />
                      {{ $t(value) }}
                  </b-badge>
              </template>

              <template #cell(talkTime)="{ value }">
                  {{ formatSecondsToTime(value) }}
              </template>

              <template #cell(type)="{ value }">
                  <b-badge :variant="getType(value).variant">
                      <sw-icon :icon="getType(value).icon" />
                      {{ $t(value) }}
                  </b-badge>
              </template>

              <template #cell(waitingTime)="{ value }">
                  {{ formatSecondsToTime(value) }}
              </template>

              <template #cell(note)="{ item }">
                  <div
                          v-if="item.note"
                          class="text-primary cursor-pointer"
                          @click="noteModal.item = { ...item }; noteModal.show = true; noteModal.isPreview = true"
                  >
                      <feather-icon icon="EyeIcon" />
                      {{ item.note | truncate(50) }}
                  </div>
                  <div v-else-if="checkRequiredPermissions([$roles.PHONE_CALLS_VIEW_EDIT])">
                      <b-button
                              class="btn-icon"
                              icon
                              variant="gradient-primary"
                              size="sm"
                              v-b-tooltip
                              :title="$t('AddNote')"
                              @click="noteModal.item = {
                    ...item,
                    note: '',
                  }; noteModal.show = true; noteModal.isPreview = false; selectedCalls = [item.id]"
                      >
                          <feather-icon icon="PlusIcon" />
                      </b-button>
                  </div>
                  <sw-icon icon="MinusIcon" v-else />
              </template>

              <template #cell(phoneCallTranscriptionSegments)="{ item }">
                  <div v-if="item.phoneCallTranscriptionSegments && item.phoneCallTranscriptionSegments.length">
                      <b-button
                              class="btn-icon"
                              icon
                              variant="gradient-primary"
                              size="sm"
                              v-b-tooltip
                              :title="$t('Show')"
                              @click="selectedTranscriptionItem = item">
                          <feather-icon icon="EyeIcon" />
                      </b-button>
                  </div>
                  <sw-icon icon="MinusIcon" v-else />
              </template>

              <template #cell(contactThread)="{ item }">
                  <b-button

                          v-if="!item.contactThread && checkRequiredPermissions([$roles.PHONE_CALLS_VIEW_EDIT])"
                          variant="gradient-primary"
                          class="btn-icon"
                          size="sm"
                          @click.stop="() => {
                                      selectedCalls = [item.id];
                                      openModal()
                                    }"
                          v-b-tooltip
                          :title="$t('AssignToProcess')"
                  >
                          <feather-icon icon="LinkIcon" />
                  </b-button>
                  <div v-else-if="item.contactThread">
                      <div>
                          <div class="d-flex align-items-center mb-n25">
                              <router-link v-b-tooltip
                                           :title="getUserName(item.contactThread.contact)"
                                           :class="{ 'deleted-text': item.contactThread.contact.deletedAt }"
                                           :to="`/contact/${item.contactThread.contact.id}/thread/${item.contactThread.id}/calls`"
                              >
                                  {{ getUserName(item.contactThread.contact) | truncate(40) }}
                              </router-link>

                              <b-button
                                      variant="flat-success"
                                      :disabled="!!item.contactThread.contact.deletedAt"
                                      :to="`/contact/${item.contactThread.contact.id}/thread/${item.contactThread.id}/tasks`"
                                      target="_blank"
                                      class="btn-icon rounded mr-25"
                                      size="sm"
                              >
                                  <feather-icon
                                          class="text-success"
                                          icon="ExternalLinkIcon"
                                  />
                              </b-button>
                          </div>

                          <small class="text-muted">{{ item.contactThread.name | truncate(24, '...') }}</small>
                      </div>
<!--                  <avatar :user="item.contactThread.contact" v-else-if="item.contactThread" />-->
                  </div>
                  <sw-icon icon="MinusIcon" v-else />
              </template>


<!--            <template #cell(note)="{ value, item }">-->
<!--              <div-->
<!--                v-if="value"-->
<!--                class="text-primary cursor-pointer"-->
<!--                @click="callModal.item = { ...item }; callModal.show = true; callModal.isPreview = true"-->
<!--              >-->
<!--                <feather-icon icon="EyeIcon" />-->
<!--                {{ value | truncate(50) }}-->
<!--              </div>-->
<!--              <div v-else>-->
<!--                <b-button-->
<!--                  class="btn-icon"-->
<!--                  icon-->
<!--                  variant="gradient-primary"-->
<!--                  size="sm"-->
<!--                  @click="callModal.item = {-->
<!--                    ...item,-->
<!--                    note: '',-->
<!--                  }; callModal.show = true; callModal.isPreview = false"-->
<!--                >-->
<!--                  <feather-icon icon="PlusIcon" />-->
<!--                </b-button>-->
<!--              </div>-->
<!--            </template>-->

            <!--    Other    -->
            <template #cell()="row">
              <table-default-cell
                :field="row.field"
                :value="row.value"
              />
            </template>

            <!--      Empty      -->
            <template #empty>
              <empty-content />
            </template>

            <!--      Table Busy      -->
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner
                  class="align-middle"
                  variant="primary"
                />
              </div>
            </template>
          </b-table>
        </template>
      </sw-table>
    </component>

      <b-modal
              v-model="noteModal.show"
              :title="noteModal.isPreview ? $t('Preview') : $t('AddNote')"
              hide-footer
              no-close-on-backdrop
      >
          <div v-if="!noteModal.isPreview">
              <b-form-group :label="$t('Content')">
                  <b-form-textarea v-model="noteModal.note" />
              </b-form-group>
          </div>
          <div v-else-if="noteModal.item">
              <span v-html="noteModal.item.note.replaceAll('\n', '<br>')"></span>
          </div>

          <b-button
                  v-if="!noteModal.isPreview"
                  size="sm"
                  variant="primary"
                  @click="assignNote"
          >
              {{ $t('Save') }}
          </b-button>
      </b-modal>

      <b-modal v-model="showAssignThreadModal"
               :title="$t('AssignToThread')"
               hide-footer
               no-close-on-backdrop
               @hide="clearData"
      >
          <b-form-group>
              <sw-select :name="$t('SelectContacts')">
                  <v-select
                          v-model="selectedThread"
                          :options="threadList"
                          label="name"
                          :filterable="false"
                          @search="loadThreads"
                  >
                      <template #no-options="{ search }">
              <span v-if="search.length">
                {{ $t('NoData') }}
              </span>
                          <span v-else>
                {{ $t('TypeToSearch') }}
              </span>
                      </template>
                      <template #option="{ name, contact }">
                          <div class="d-flex align-items-center">
                              <avatar
                                      :user="contact"
                                      class="mr-25"
                              />  - {{ name }}
                          </div>
                      </template>

                      <template #selected-option="{ name, contact }">
                          <div class="d-flex">
                              <avatar
                                      :user="contact"
                                      class="mr-25"
                              /> – {{ name }}
                          </div>
                      </template>
                  </v-select>
              </sw-select>
          </b-form-group>

          <!--      Button: Save      -->
          <b-button
                  size="sm"
                  variant="primary"
                  :disabled="!selectedThread"
                  @click="assignToThread"
          >
              {{ $t('Save') }}
          </b-button>
      </b-modal>

      <b-modal v-model="showCallModal"
               hide-footer
               @hide="phoneNumber = null"
      >
          <div class="d-flex flex-column align-items-center" v-if="phoneNumber">
              <div class="text-center mb-2">
                  <div class="py-2">
                      <sw-icon size="40"
                               icon="PhoneCallIcon"
                               class="text-primary"
                      />
                  </div>
                  <div>{{ $t('PhoneShort') }}: <strong>{{ phoneNumber | phoneFormat }}</strong></div>
              </div>

              <div>
                  <b-button variant="outline-secondary"
                            class="mr-50"
                            @click="showCallModal = false"
                  >
                      {{ $t('Cancel') }}
                  </b-button>
                  <b-button :disabled="callLoading"
                            variant="primary"
                            @click="makeACall(phoneNumber)"
                  >
                      {{ !callLoading ? $t('Call') : $t('MakingCall') }}
                  </b-button>
              </div>
          </div>
      </b-modal>

      <b-modal :visible="Boolean(selectedTranscriptionItem)"
               hide-footer
               scrollable
               @hide="selectedTranscriptionItem = null"
      >
          <div class="d-flex justify-content-between">
              <div>
                  <b-badge variant="light-primary">{{ $t('Recipient') }}</b-badge>
                  <div></div>
              </div>
              <div>
                  <b-badge variant="light-secondary">{{ $t('Sender') }}</b-badge>
                  <div></div>
              </div>
          </div>
          <hr>
          <div v-if="selectedTranscriptionItem" class="d-flex flex-column">
              <div v-for="(message, index) in selectedTranscriptionItem.phoneCallTranscriptionSegments"
                   :key="`${index}_transcription`"
                   class="d-flex my-25"

                    :class="{ 'align-content-end justify-content-end': message.speaker === 'sender' }"
              >
                  <div :class="{ 'd-flex justify-content-end': message.speaker === 'sender' }">
                      <div class="d-flex flex-column">
                          <div :class="{ 'bg-gradient-secondary': message.speaker === 'sender', 'bg-gradient-primary': message.speaker !== 'sender' }" style="max-width: 300px; width: 100%" class="p-50 rounded" >{{ message.text }}</div>
                          <small class="text-muted mt-25" :class="{ 'd-flex justify-content-end': message.speaker === 'sender' }">{{ formatSecondsToTime(message.start) }} - {{ formatSecondsToTime(message.end) }}</small>
                      </div>
                  </div>
              </div>
          </div>
      </b-modal>

    <!-- Note Modal -->
<!--    <b-modal-->
<!--      v-model="callModal.show"-->
<!--      :title="callModal.isPreview ? $t('Preview') : $t('AddNote')"-->
<!--      hide-footer-->
<!--      no-close-on-backdrop-->
<!--    >-->
<!--      <div v-if="callModal.item && !callModal.isPreview">-->
<!--        <b-form-group :label="$t('Content')">-->
<!--          <b-form-textarea v-model="callModal.item.note" />-->
<!--        </b-form-group>-->
<!--      </div>-->
<!--      <div v-else-if="callModal.item">-->
<!--        <span v-html="callModal.item.note.replaceAll('\n', '<br>')" />-->
<!--      </div>-->

<!--      <b-button-->
<!--        v-if="!callModal.isPreview"-->
<!--        size="sm"-->
<!--        variant="primary"-->
<!--        @click="saveItem"-->
<!--      >-->
<!--        {{ $t('Save') }}-->
<!--      </b-button>-->
<!--    </b-modal>-->
  </div>
</template>

<script>
import {
  BFormTextarea, BTable, BDropdownDivider, BPopover, BModal,
} from 'bootstrap-vue'
import { formatDate } from '@core/utils/filter'
import axiosIns from '@/libs/axios'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { THREADS_SEARCH } from '@/@constants/fields'
import AudioPlayer from '@core/components/@sw/AudioPlayer.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'List',
  components: {
    BModal,
    AudioPlayer,
    vSelect,
    BTable,
    BFormTextarea,
    BDropdownDivider,
    flatPickr,
    BPopover,
  },
  props: {
    tag: {
      default: 'b-card',
      type: String,
    },
    showFilters: {
      default: true,
      type: Boolean,
    },
    contactThread: {
      default: null,
      type: Object,
      required: false,
    },
  },
  data: () => ({
    selectAll: false,
    loading: false,
    showCallModal: false,
    phoneNumber: null,
    callLoading: false,
    selectedTranscriptionItem: null,
    items: [],
    threadList: [],
    statuses: {
      CALLING: 0,
      ANSWERED: 0,
      BUSY: 0,
      NO_ANSWER: 0,
      FAILED: 0,
      REPEATED: 0,
      PROPER: 0,
      NO_FORWARD: 0,
      VOICEMAIL: 0,
      WRONG_EXTENSION: 0,
      NO_EXTENSION: 0,
      CLIENT_NO_ANSWER: 0,
      FAILED_FORBIDDEN_DESTINATION: 0,
    },
    selectedCalls: [],
    sorter: {
      sortBy: 'id',
      sortDesc: true,
    },
    pagination: {
      perPage: 25,
      currentPage: 1,
      totalRecords: 0,
    },
    search: '',

    callModal: {
      show: false,
      item: null,
      isPreview: false,
    },

    showAssignThreadModal: false,
    noteModal: {
      show: false,
      item: null,
      isPreview: false,
    },
    selectedThread: '',

    editModal: {
      show: false,
      item: null,
    },

    filters: {
      type: 'ALL',
      status: 'ALL',
    },
    stats: {
      status: {
        // ANSWERED: 0,
        // BUSY: 0,
        // NO_ANSWER: 0,
        ALL: 0,
        CALLING: 0,
        ANSWERED: 0,
        BUSY: 0,
        NO_ANSWER: 0,
        FAILED: 0,
        REPEATED: 0,
        PROPER: 0,
        NO_FORWARD: 0,
        VOICEMAIL: 0,
        WRONG_EXTENSION: 0,
        NO_EXTENSION: 0,
        CLIENT_NO_ANSWER: 0,
        FAILED_FORBIDDEN_DESTINATION: 0,
      },
      type: {
        ALL: 0,
        IN: 0,
        OUT: 0,
      },
    },
    fields: [
      {
        key: 'select',
        label: 'Select',
        visible: true,
      },
      {
        key: 'id', label: '#', visible: true, sortable: true,
      },
      // { key: 'caller', label: 'Caller', visible: true },
      {
        key: 'phoneCallTranscriptionSegments', label: 'Transcription', visible: true, requiredModule: 'chatGptModule',
      },
      { key: 'callAt', label: 'CallAt', visible: true },
      {
        key: 'callId',
        label: 'CallId',
        visible: true,
        tag: 'b-badge',
        props: { variant: 'light-primary' },
      },
      {
        key: 'callerUser',
        label: 'CallerUser',
        visible: true,
      },
      { key: 'destination', label: 'Destination', visible: true },
      { key: 'contactThread', label: 'ContactThread', visible: true },
      { key: 'file', label: 'AudioFile', visible: true },
      { key: 'status', label: 'Status', visible: true },
      { key: 'note', label: 'Note', visible: true },
      { key: 'talkTime', label: 'TalkTime', visible: true },
      { key: 'type', label: 'task.Type', visible: true },
      { key: 'waitingTime', label: 'WaitingTime', visible: true },
      // { key: 'action', label: 'Action', visible: true },
    ],
  }),
  computed: {
    ...mapGetters({
      currentUser: 'auth/getCurrentUser',
    }),
    visibleFields() {
      return this.fields.filter(field => !field?.requiredModule || this.checkRequiredModule(field?.requiredModule))
    },
    tableFields() {
      return this.visibleFields.filter(field => field.visible)
    },
  },
  watch: {
    selectedCalls: {
      handler() {
        this.selectAll = this.selectedCalls.length === this.items.length
      },
      deep: true,
    },
    filters: {
      deep: true,
      handler(nw, ow) {
        if (nw && ow) this.loadData()
      },
    },
  },
  async mounted() {
    await this.loadData()
  },
  methods: {
    setFilters(field, value) {
      this.$set(this.filters, field, value)
    },
    selectAllItems() {
      this.selectedCalls = this.selectAll ? this.items.map(call => call.id) : []
    },
    formatSecondsToTime(seconds) {
      // Calculate minutes and remaining seconds
      const minutes = Math.floor(seconds / 60)
      const remainingSeconds = seconds % 60

      // Combine minutes and seconds
      // eslint-disable-next-line radix
      return `${minutes.toString().padStart(2, '0')}:${parseInt(remainingSeconds.toString().padStart(2, '0')).toFixed(0)}`
    },
    formatDate,
    async loadThreads(search, isLoading) {
      this.threadList = await this.selectSearch(search, isLoading, THREADS_SEARCH, 'name,contact-firstName,contact-lastName,contact-mailAddress-mail,contact-phoneNumber-phone', '1/contacts/threads')
    },
    async makeACall(phone) {
      this.callLoading = true
      try {
        await axiosIns.post('1/integration/ringostat/outwardCall', { destination: phone.replaceAll(' ', '').replaceAll('+', '') })

        setTimeout(() => {
          this.callLoading = false
          this.showCallModal = false
        }, 1500)
      } catch (err) {
        if (err?.response?.data?.message === 'ITEM_NOT_FOUND') {
          this.showToast('danger', this.$i18n.t('SipNotFound'), err)
        } else {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        }
      }
    },
    openModal() {
      this.showAssignThreadModal = true
      this.selectedThread = null
    },
    openNoteModal() {
      this.noteModal = {
        show: false,
        item: null,
        isPreview: false,
      }
      this.selectedThread = null
    },
    clearData() {
      this.showAssignThreadModal = false
      this.noteModal = {
        show: false,
        item: null,
        isPreview: false,
      }
      this.selectedThread = ''
      this.selectedCalls = []
    },
    async loadData() {
      this.loading = true
      try {
        const params = {
          fields_load: 'RINGOSTAT_CALLS',
          // fields_info: 1,
          page: this.pagination.currentPage,
          limit: this.pagination.perPage,
        }

        const filters = {}
        const statisticsByTypeFilters = {}
        const statisticsByStatusFilters = {}

        if (this.contactThread) {
          filters['eq_contactThread-id'] = this.getObjectId(this.contactThread)
          statisticsByStatusFilters['eq_contactThread-id'] = this.getObjectId(this.contactThread)
          statisticsByTypeFilters['eq_contactThread-id'] = this.getObjectId(this.contactThread)
        }

        if (this.sorter) {
          const sortDir = this.sorter?.sortDesc ? 'desc' : 'asc'
          const sortBy = this.sorter?.sortBy || 'id'
          params.orderBy = `${sortBy}.${sortDir}`
        }

        if (this.search.length) {
          const searchString = `%${this.search.replaceAll(' ', '%')}%`
          const fields = ['callerUser-firstName', 'callerUser-lastName', 'caller-phone'].join(',')
          params.search = searchString
          params.search_fields = fields

          statisticsByStatusFilters.search = searchString
          statisticsByStatusFilters.search_fields = fields

          statisticsByTypeFilters.search = searchString
          statisticsByTypeFilters.search_fields = fields
        }

        if (this.filters.type !== 'ALL') {
          params.eq_type = this.filters.type

          statisticsByStatusFilters.eq_type = this.filters.type
        }

        if (this.filters.status !== 'ALL') {
          params.eq_status = this.filters.status

          statisticsByTypeFilters.eq_status = this.filters.status
        }

        const [resp, respStatsByType, respStatsByStatus] = await Promise.all([
          await axiosIns.get('1/phoneCalls', { params: { ...params, ...filters } }),
          await axiosIns.get('1/phoneCalls/getStatisticsByType', { params: { ...statisticsByTypeFilters } }),
          await axiosIns.get('1/phoneCalls/getStatisticsByStatus', { params: { ...statisticsByStatusFilters } }),
        ])
        //
        // await axiosIns.get('1/phoneCalls', { params: { ...params, ...filters } })
        // await axiosIns.get('1/phoneCalls', { params: { ...params, ...filters } })
        // await axiosIns.get('1/phoneCalls', { params: { ...params, ...filters } })
        // await axiosIns.get('1/phoneCalls', { params: { ...params, ...filters } })
        // await axiosIns.get('1/phoneCalls', { params: { ...params, ...filters } })
        // await axiosIns.get('1/phoneCalls', { params: { ...params, ...filters } })

        if (respStatsByType?.data?.data) {
          this.stats.type = respStatsByType.data.data
        }

        if (respStatsByStatus?.data?.data) {
          this.stats.status = respStatsByStatus.data.data
        }

        if (resp.data.data.items) {
          this.items = (resp.data?.data?.items || []).map(e => ({ ...e, fileLoading: false, loadedFile: false }))
          this.pagination.totalRecords = resp.data.data.totalItemCount
        }
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.loading = false
      }
    },
    // eslint-disable-next-line consistent-return
    async assignToThread() {
      const confirmed = await this.showAlert('warning', this.$i18n.t('alerts.AreYouSure'))
      if (!confirmed || !this.selectedThread) return false

      try {
        const payload = this.selectedCalls.map(id => ({ id, contactThread: this.getObjectId(this.selectedThread) }))
        await axiosIns.patch('1/phoneCalls', payload)
        this.clearData()
        await this.loadData()
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    // eslint-disable-next-line consistent-return
    async assignNote() {
      try {
        const payload = this.selectedCalls.map(id => ({ id, note: this.noteModal.note }))
        await axiosIns.patch('1/phoneCalls', payload)
        this.clearData()
        await this.loadData()
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    async loadAudio(audioFile, index) {
      this.$set(this.items[index], 'fileLoading', true)
      const response = await axiosIns.get(`storage/1/download/${audioFile.token}`)

      const base64Audio = response.data.data.file
      const audioUrl = `data:audio/${audioFile.extension};base64,${base64Audio}`

      this.$set(this.items[index], 'fileLoading', false)
      this.$set(this.items[index], 'loadedFile', audioUrl)
      return audioUrl
    },
    async updateField({ id }, field, value, needConfirm = false) {
      if (needConfirm) {
        const confirmed = await this.showAlert('warning', this.$i18n.t('alerts.AreYouSure'))
        if (!confirmed) return
      }

      try {
        const payload = {
          id,
          [field]: value,
        }
        await axiosIns.patch('1/phoneCalls', payload)
        this.showToast('success', this.$i18n.t('success.contactUpdated'))

        const complaint = this.items.findIndex(e => e.id === payload.id)
        if (complaint > -1) {
          this.$set(this.items[complaint], 'status', payload.status)
        }
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },

    async deleteItem(itemId, index) {
      const confirmed = await this.showAlert('warning', this.$i18n.t('alerts.AreYouSure'))
      if (!confirmed) return

      try {
        await axiosIns.delete('1/phoneCalls', { data: JSON.stringify([itemId]) })

        this.showToast('success', this.$i18n.t('RemovedSuccessfully'))
        this.$delete(this.items, index)
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },

    async saveItem() {
      try {
        await axiosIns({
          url: '1/phoneCalls',
          method: 'PATCH',
          data: { note: this.callModal.item.note, id: this.callModal.item.id },
        })

        const complaint = this.items.findIndex(e => e.id === this.callModal.item.id)
        if (complaint > -1) {
          this.$set(this.items[complaint], 'note', this.callModal.item.note)
        }

        this.callModal = {
          show: false,
          item: null,
          isPreview: false,
        }
        this.showToast('success', this.$i18n.t('success.contactUpdated'))
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.loading = false
      }
    },
    getStatus(status) {
      let toReturn = null
      switch (status) {
        case 'ANSWERED': toReturn = { icon: 'PhoneCallIcon', variant: 'light-success' }; break
        case 'BUSY': toReturn = { icon: 'PhoneOffIcon', variant: 'light-warning' }; break
        case 'NO_ANSWER': toReturn = { icon: 'PhoneMissedIcon', variant: 'light-danger' }; break
        default: toReturn = { icon: '', variant: 'light-secondary' }
      }

      return toReturn
    },
    getType(type) {
      let toReturn = null
      switch (type) {
        case 'IN': toReturn = { icon: 'PhoneIncomingIcon', variant: 'light-success' }; break
        case 'OUT': toReturn = { icon: 'PhoneOutgoingIcon', variant: 'light-warning' }; break
        default: toReturn = { icon: '', variant: 'light-secondary' }
      }

      return toReturn
    },
    openEditModal(item) {
      const editedItem = JSON.parse(JSON.stringify(item))

      this.editModal = {
        show: true,
        item: editedItem,
      }
    },
  },
}
</script>

<style lang="scss">
.custom-audio-player audio::-webkit-media-controls-panel {
    background-color: transparent;
    border-radius: 10px;
  color: red;
}

.custom-audio-player audio::-webkit-media-controls-play-button,
.custom-audio-player audio::-webkit-media-controls-mute-button,
.custom-audio-player audio::-webkit-media-controls-volume-slider,
.custom-audio-player audio::-webkit-media-controls-timeline {
  color: red;
}

.custom-audio-player audio::-webkit-media-controls-enclosure {
    background: transparent;
    padding: 0;
    margin: 0;
    height: 10px;
}

</style>
