<template>
  <div>
    <sw-top-page-bar
      v-if="isMounted"
      v-model="activeTab"
      is-tab-view
      show-text
      :tab-view-list="tabs"
      @input="changeRoute"
    />
    <router-view />

  </div>
</template>

<script>

export default {
  components: {
  },
  data: vm => ({
    activeTab: {
      route: 'loyaltyPlansLoyaltyPlans',
      title: 'YourPlans',
      icon: 'GridIcon',
      requiredViewPermissions: [vm.$viewRanks.SHOW_LOYALTY_PLANS],
    },
    isMounted: false,
    tabList: [
      {
        route: 'loyaltyPlansLoyaltyPlans',
        title: 'YourPlans',
        icon: 'GridIcon',
        requiredViewPermissions: [vm.$viewRanks.SHOW_LOYALTY_PLANS],
      },
      {
        route: 'loyaltyPlansLoyaltyPoints',
        title: 'Points',
        icon: 'HexagonIcon',
      },
    ],
  }),
  computed: {
    tabs() {
      return this.tabList.filter(e => (!e?.requiredViewPermissions?.length || this.checkRequiredViewPermissions(e.requiredViewPermissions)))
    },
  },
  mounted() {
    if (this.$route.name === 'loyaltyPlansLoyaltyPoints' || !this.checkRequiredViewPermissions([this.$viewRanks.SHOW_LOYALTY_PLANS])) {
      this.activeTab = {
        route: 'loyaltyPlansLoyaltyPoints',
        title: 'Points',
        icon: 'HexagonIcon',
      }
    }
    this.isMounted = true
  },
  methods: {
    changeRoute(base) {
      if (this.$route.name !== base.route) {
        this.$router.push({ name: base.route })
      }
      // window.history.replaceState(null, null, `/marketing/${base}`)
    },
  },
}
</script>
