<!--eslint-disable-->

<template>
  <div>
    <h4 class="mb-1">
      {{ $t('BasicInformation') }}
    </h4>
    <b-row style="row-gap: 1rem">
      <!--    START::Name    -->
      <b-col
        md="6"
        lg="4"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="$t('Name')"
          rules="required"
        >
          <label class="mb-50">{{ $t('Name') }}</label>

          <b-form-input
            v-model="target.name"
            :state="errors.length > 0 ? false:null"
            max-length="34"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col>
      <!--    END::Name    -->
      <!--    START::Qualified Status    -->
      <b-col
        md="6"
        lg="4"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="$t('QualifiedStatus')"
          rules="required"
        >
          <sw-select
            class="mb-0"
            :name="$t('QualifiedStatus')"
            :state="errors.length > 0 ? false:null"
          >
            <v-select
              v-model="target.qualifiedStatus"
              :options="qualifiedStatusItems"
              label="label"
              :reduce="i => i.value"
            >
              <template #no-options>
                {{ $t('NoOptions') }}
              </template>
              <template #selected-option="{ label }">
                <span>{{ $tc(label, 2) }}</span>
              </template>
              <template #option="{ label }">
                <span>{{ $tc(label, 2) }}</span>
              </template>
            </v-select>
          </sw-select>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col>

      <b-col
        md="6"
        lg="4"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="$t('QualificationStatusDate')"
          rules="required"
        >
          <sw-select
            class="mb-0"
            :name="$t('QualificationStatusDate')"
            :state="errors.length > 0 ? false:null"
          >
            <v-select
              :value="'CalendarMonth'"
              :options="['CalendarMonth']"
            >
              <template #no-options>
                {{ $t('NoOptions') }}
              </template>
              <template #selected-option>
                <span>{{ $t('CalendarMonth') }}</span>
              </template>
              <template #option>
                <span>{{ $t('CalendarMonth') }}</span>
              </template>
            </v-select>
          </sw-select>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col>

      <!--    END::Qualified Status    -->

      <!--    START::Status Qualification End Date    -->
      <b-col
        md="6"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="$t('SpecifyStatusQualificationEndDate')"
          rules="required"
        >
          <sw-select
            class="mb-0"
            :name="$t('SpecifyStatusQualificationEndDate')"
            :state="errors.length > 0 ? false:null"
          >
            <v-select
              v-model="target.qualifiedStatusEndDaysAmount"
              :options="[-1, ...qualifiedStatusEndDateItems]"
            >
              <template #no-options>
                {{ $t('NoOptions') }}
              </template>
              <template #selected-option="{ label }">
                <span v-if="label !== -1">{{ label }} {{ $t(`DayOfEachMonth`) }}</span>
                <span v-else>{{ $t('LastDayOfMonth') }}</span>
              </template>
              <template #option="{ label }">
                <span v-if="label !== -1">{{ label }} {{ $t(`DayOfEachMonth`) }}</span>
                <span v-else>{{ $t('LastDayOfMonth') }}</span>
              </template>
            </v-select>
          </sw-select>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col>
      <!--    END::Status Qualification End Date    -->

      <!--    START::Date Of Conclusion Of Contract    -->
      <b-col
        md="6"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="$t('DateOfConclusionOfContract')"
          rules="required"
        >
          <sw-select
            class="mb-0"
            :name="$t('DateOfConclusionOfContract')"
            :state="errors.length > 0 ? false:null"
          >
            <v-select
              v-model="target.contractDaysAmount"
              :options="[-1]"
            >
              <template #no-options>
                {{ $t('NoOptions') }}
              </template>
              <template #selected-option="{ label }">
                <span v-if="label !== -1">{{ $t('From') }} {{ label }} {{ $t(`DayOfMonth`) }}</span>
                <span v-else>{{ $t('CalendarMonth') }}</span>
              </template>
              <template #option="{ label }">
                <span v-if="label !== -1">{{ $t('From') }} {{ label }} {{ $t(`DayOfMonth`) }}</span>
                <span v-else>{{ $t('CalendarMonth') }}</span>
              </template>
            </v-select>
          </sw-select>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col>
      <!--    END::Date Of Conclusion Of Contract    -->
    </b-row>

    <b-overlay
      :show="isLoading"
      no-wrap
      spinner-variant="primary"
    />
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'

export default {
  components: {
    ValidationProvider,
    vSelect,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    target: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    qualifiedStatusEndDateItems: Array.from({ length: 28 }, (_, i) => i + 1),
    qualifiedStatusItems: [
      { value: 'NEW', label: 'offer.status.NEW' },
      { value: 'IN_REALIZATION', label: 'offer.status.IN_REALIZATION' },
      // { value: 'SENT', label: 'Sent' },
      // { value: 'DELIVERED', label: 'Delivered' },
      { value: 'REALIZED', label: 'offer.status.REALIZED' },
      { value: 'CANCELLED', label: 'offer.status.CANCELLED' },
    ],
  }),
}
</script>
