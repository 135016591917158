<template>
  <b-row>
    <b-col sm="12"
           class="mt-1 mt-md-0"
    >

      <div class="d-flex align-items-center">
        <b-button size="sm"
                  class="mb-50"
                  variant="primary"
                  @click="editField(JSON.parse(JSON.stringify(defaultTemplate)), -1)"
        >
          <sw-icon icon="PlusIcon"
                   class="mr-25"
          />
          {{ $t('Add') }}
        </b-button>
        <div class="ml-50">
          <vue-select
            v-model="pagination.limit"
            style="min-width: 75px"
            class="per-page-selector d-inline-block mr-50 pb-50 select-size-sm"
            :options="[15, 25, 50]"
            :clearable="false"
            @input="reloadTemplates(pagination.currentPage, $event)"
          />
        </div>
      </div>

      <b-table
        ref="selectableTable"
        :items="templates"
        class="table-wrap-words"
        :fields="fields"
        striped
        responsive
        selectable
        select-mode="multi"
        :style="{ minHeight: tableSize(templates, 150) }"
        :busy="loading"
        show-empty
        @row-selected="onRowSelected"
      >
        <template #head()="{ label }">
          {{ $t(`${label}`) }}
        </template>
        <template #empty>
          <div
            class="text-center py-1 text-primary font-weight-bold"
          >
            <feather-icon
              size="17"
              icon="XCircleIcon"
            />
            {{ $t('NoData') }}
          </div>
        </template>
        <template #head(selected)>
          <b-form-checkbox
            variant="primary"
            :checked="templates.length === selectedTemplates.length"
            @change="changeAllSelection"
          />
        </template>
        <template #cell(selected)="rowSelected">
          <b-form-checkbox
            v-model="selectedTemplates"
            variant="primary"
            :value="rowSelected.item"
            @change="changeSelection(rowSelected.rowSelected, rowSelected.index)"
          />
        </template>
        <template #cell(content)="row">
          <div @click="previewContent = row.item.content; templatePreview = !templatePreview">
            <sw-icon icon="EyeIcon"
                     class="text-primary cursor-pointer mr-25"
            />
            <span :inner-html.prop="row.value | truncate(35, '...')" />
          </div>
        </template>
        <template #cell(action)="row">
          <b-dropdown
            id="dropdown-dropleft"
            dropleft
            no-caret
            variant="flat"
          >
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" />
            </template>

            <b-dropdown-item @click="editField(row.item, row.index)">
              <dropdown-item-label
                icon="EditIcon"
                :label="$t('Edit')"
              />
            </b-dropdown-item>
            <b-dropdown-item @click="removeTemplates(row.item.id, row.index)">
              <dropdown-item-label
                icon="TrashIcon"
                :label="$t('Delete')"
              />
            </b-dropdown-item>
          </b-dropdown>
        </template>
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner
              class="align-middle"
              variant="primary"
            />
          </div>
        </template>
      </b-table>
      <div class="d-flex justify-content-center">
        <div>
          <b-pagination
            v-model="pagination.currentPage"
            :total-rows="rows"
            :per-page="pagination.limit"
            align="center"
            @change="reloadTemplates($event, pagination.limit)"
          />
        </div>
      </div>
    </b-col>

    <transition name="fade">
      <div
        v-if="selectedTemplates.length"
        style="position: fixed; right: 30px; bottom: 20px"
        class="shadow"
      >
        <b-button
          variant="danger"
          class="d-flex align-items-center"
          size="sm"
          @click="removeTemplates"
        >
          <feather-icon icon="TrashIcon" />
          <span class="pr-1 pl-25">{{ $t('Delete') }}</span>
          <b-badge variant="light text-danger">
            {{ selectedTemplates.length }}
          </b-badge>
        </b-button>
      </div>
    </transition>

    <b-modal
      v-model="editedTemplate.show"
      size="lg"
      hide-footer
      :title="$t(editedTemplate.index > -1 ? 'Edit' : 'Create')"
      @hide="closeModal"
    >
      <div v-if="editedTemplate.show">
        <div class="form-label-group">
          <b-form-group :label="$t('Title')">
            <b-form-input
              id="template-name"
              v-model="editedTemplate.item.name"
            />
          </b-form-group>
        </div>
        <div
          class="form-label-group"
        >
          <b-form-group>
            <template #label>
              <div class="d-flex align-items-center">
                {{ $t('Content') }}
                <b-button
                  id="custom-button"
                  variant="success"
                  class="w-auto ml-25"
                  size="sm"
                  @click="$emit('open-modal')"
                >
                  <span class="text-white">
                    <feather-icon
                      icon="CodeIcon"
                      class="mr-25"
                    />
                  </span>

                  <span class="text-white">
                    {{ $t('ShortCodes') }}
                  </span>
                </b-button>
              </div>
            </template>
            <b-form-textarea
              id="template-content"
              ref="sms_content"
              v-model="editedTemplate.item.content"
              :state="editedTemplate.item.content.length <= 500 ? (editedTemplate.item.content.length > 0 ? true : null) : false"
              class="pt-1 char-textarea"
              rows="8"
            />
            <small
              class="textarea-counter-value float-right"
              :class="editedTemplate.item.content.length >= 500 ? 'bg-danger' : ''"
            >
              <span class="char-count">{{ editedTemplate.item.content.length }}</span> / 500
            </small>
          </b-form-group>
        </div>
        <b-button
          size="sm"
          variant="primary"
          :disabled="!editedTemplate.item.content || !editedTemplate.item.name"
          @click="saveTemplate"
        >
          {{ $t('Save') }}
        </b-button>
      </div>
    </b-modal>

    <b-modal
      v-model="templatePreview"
      hide-footer
      :title="$t('Preview')"
      size="md"
    >
      <div style="max-width: 100%; width: 100%; position:relative;">
        <div v-html="previewContent.replaceAll('\n', '<br>')" />
      </div>
    </b-modal>
  </b-row>
</template>

<script>
import VueSelect from 'vue-select'
import {
  BTable, BPagination,
} from 'bootstrap-vue'
import { GET_TEMPLATES, SAVE_TEMPLATE, DELETE_TEMPLATE } from '@/@constants/mutations'
import { mapGetters } from 'vuex'

const defaultTemplate = {
  name: '',
  content: '',
}

export default {
  name: 'Sms',
  components: {
    BTable,
    BPagination,
    VueSelect,
  },
  data: () => ({
    defaultTemplate,
    editedTemplate: {
      show: false,
      item: JSON.parse(JSON.stringify(defaultTemplate)),
      index: -1,
    },
    sorter: {
      sortBy: 'createdAt',
      sortDesc: true,
    },
    pagination: {
      limit: 25,
      currentPage: 1,
    },
    editorOption: {
      placeholder: '',
      modules: {
        toolbar: '#toolbar',
      },
    },
    loading: false,
    selectedTemplates: [],
    fields: [
      {
        key: 'selected',
      },
      {
        key: 'name',
        label: 'Title',
      },
      {
        key: 'content',
        label: 'Template',
      },
      {
        key: 'action',
        label: 'Action',
      },
    ],
    editedTemplateId: null,
    template: {
      content: '',
      name: '',
    },
    previewContent: '',
    templatePreview: false,
  }),
  computed: {
    ...mapGetters({
      shortcodes: 'templates/getShortcodes',
      templates: 'templates/getSmsTemplates',
      rows: 'templates/getSmsTemplatesLength',
      system: 'system/getSettings',
    }),
  },
  mounted() {
    this.reloadTemplates()
  },
  methods: {
    reloadTemplates(page = this.pagination.currentPage, limit = this.pagination.limit) {
      this.loading = true

      this.$store.dispatch(`templates/${GET_TEMPLATES}`, { type: 'sms', page: this.rows < limit ? 1 : page, limit })
        .then(() => {
          setTimeout(() => { this.loading = false }, 500)
        })
        .catch(() => {
          this.loading = false
          this.showToast('error', this.$i18n.t('ProblemOccured'))
        })
    },
    editField(item, index) {
      const payload = {
        index,
        show: true,
        item: {
          content: (item?.content || '').replaceAll('<br>', '\n'),
          name: item?.name,
        },
      }

      this.$set(this, 'editedTemplate', JSON.parse(JSON.stringify(payload)))
    },
    changeSelection(e, index) {
      if (!e) this.$refs.selectableTable.selectRow(index)
      else this.$refs.selectableTable.unselectRow(index)
    },
    changeAllSelection() {
      if (this.templates.length === this.selectedTemplates.length) this.$refs.selectableTable.clearSelected()
      else this.$refs.selectableTable.selectAllRows()
    },
    onRowSelected(items) {
      this.selectedTemplates = items
    },
    insertShortcode(shortcode) {
      const { content } = this.template
      const textarea = this.$refs.content.$refs.input
      const sentenceLength = content.length
      let position = textarea.selectionStart

      if (position === undefined) {
        position = sentenceLength - 1
      }

      const before = content.substr(0, position)
      const after = content.substr(position, sentenceLength)

      this.template.content = before + shortcode.shortcode + after

      this.$nextTick().then(() => {
        textarea.focus()
      })
    },
    saveTemplate() {
      const { editedTemplate } = this
      const content = editedTemplate.item.content.replaceAll('<br>', '\n')
      if (this.templates[this.editedTemplate.index]?.id) editedTemplate.item.id = this.templates[this.editedTemplate.index]?.id
      editedTemplate.item.content = content
      this.$store.dispatch(`templates/${SAVE_TEMPLATE}`, { type: 'sms', template: editedTemplate.item })
        .then(() => {
          if (this.editedTemplate.index > -1) {
            Object.assign(this.templates[this.editedTemplate.index], this.editedTemplate.item)
          } else {
            this.reloadTemplates()
          }

          this.closeModal()
          this.showToast('success', editedTemplate.item.id ? this.$i18n.t('templates.TemplateUpdated') : this.$i18n.t('templates.TemplateSaved'))
        })
        .catch(() => {
          this.showToast('error', this.$i18n.t('ProblemOccured'))
        })
    },
    removeTemplates(id, index) {
      const templatesToRemove = id && index > -1 ? [id] : this.selectedTemplates.map(template => template.id.toString())
      this.$store.dispatch(`templates/${DELETE_TEMPLATE}`, { templatesToRemove, type: 'sms' })
        .then(() => {
          if (id && index > -1) {
            this.$delete(this.templates, index)
          } else this.reloadTemplates()
          this.showToast('success', this.$i18n.t('RemovedSuccessfully'))
        })
        .catch(() => {
          this.showToast('error', this.$i18n.t('ProblemOccured'))
        })
    },
    closeModal() {
      this.$set(this, 'editedTemplate', JSON.parse(JSON.stringify({
        show: false,
        item: JSON.parse(JSON.stringify(defaultTemplate)),
        index: -1,
      })))
    },
  },
}
</script>

<style scoped>

</style>

<!--<template>-->
<!--  <b-row>-->
<!--    <b-col-->
<!--      md="4"-->
<!--      sm="12"-->
<!--    >-->
<!--      <div class="form-label-group">-->
<!--        <b-form-input-->
<!--          id="template-name"-->
<!--          v-model="template.name"-->
<!--          :placeholder="$t('Title')"-->
<!--        />-->
<!--        <label for="template-name">{{ $t('Title') }}</label>-->
<!--      </div>-->
<!--      <div-->
<!--        class="form-label-group"-->
<!--      >-->
<!--        &lt;!&ndash; Preview button &ndash;&gt;-->
<!--        <b-button-->
<!--          variant="primary"-->
<!--          size="sm"-->
<!--          class="preview_swiper mr-50 mb-25"-->
<!--          @click="templatePreview = !templatePreview"-->
<!--        >-->
<!--          <feather-icon :icon="!templatePreview ? 'EyeIcon' : 'EyeOffIcon'" />-->
<!--        </b-button>-->
<!--        &lt;!&ndash; Shortcodes button &ndash;&gt;-->
<!--        <b-button-->
<!--          v-if="!templatePreview"-->
<!--          id="shortcodes_btn"-->
<!--          class="mr-50 mb-25"-->
<!--          variant="primary"-->
<!--          size="sm"-->
<!--          @click="$emit('open-modal')"-->
<!--        >-->
<!--          <feather-icon icon="GridIcon" />-->
<!--        </b-button>-->
<!--        &lt;!&ndash; Content &ndash;&gt;-->
<!--        <div v-if="!templatePreview">-->
<!--          <b-form-textarea-->
<!--            id="template-content"-->
<!--            ref="sms_content"-->
<!--            v-model="template.content"-->
<!--            :state="template.content.length <= 500 ? (template.content.length > 0 ? true : null) : false"-->
<!--            :placeholder="$t('templates.TemplateContent')"-->
<!--            class="pt-1 char-textarea"-->
<!--            rows="8"-->
<!--          />-->
<!--          <small-->
<!--            class="textarea-counter-value float-right"-->
<!--            :class="template.content.length >= 500 ? 'bg-danger' : ''"-->
<!--          >-->
<!--            <span class="char-count">{{ template.content.length }}</span> / 500-->
<!--          </small>-->
<!--        </div>-->
<!--        &lt;!&ndash; Content - Preview &ndash;&gt;-->
<!--        <div-->
<!--          v-else-->
<!--          style="padding: 1rem 1rem 1.2rem; border: 1px solid #d8d6de; border-radius: 0.357rem;"-->
<!--          :inner-html.prop="template.content | shortcodePreview(shortcodes)"-->
<!--        />-->
<!--      </div>-->
<!--      <b-button-->
<!--        size="sm"-->
<!--        class="mt-1"-->
<!--        variant="success"-->
<!--        :disabled="!template.content || !template.name || template.content.length >= 500 || !(template.content.length !== 0)"-->
<!--        @click="saveTemplate"-->
<!--      >-->
<!--        {{ editedTemplateId ? $t('Update') : $t('Save') }}-->
<!--      </b-button>-->
<!--      <b-button-->
<!--        v-if="editedTemplateId"-->
<!--        size="sm"-->
<!--        class="ml-50 mt-1"-->
<!--        @click="editField(null)"-->
<!--      >-->
<!--        {{ $t('Cancel') }}-->
<!--      </b-button>-->
<!--    </b-col>-->

<!--    <b-col-->
<!--      md="8"-->
<!--      sm="12"-->
<!--      class="mt-1 mt-md-0"-->
<!--    >-->
<!--      <b-table-->
<!--        ref="selectableTable"-->
<!--        class="table-wrap-words"-->
<!--        :items="templates"-->
<!--        :fields="fields"-->
<!--        striped-->
<!--        responsive-->
<!--        selectable-->
<!--        select-mode="multi"-->
<!--        :busy="loading"-->
<!--        show-empty-->
<!--        @row-selected="onRowSelected"-->
<!--      >-->
<!--        <template #empty>-->
<!--          <div-->
<!--            class="text-center py-1 text-primary font-weight-bold"-->
<!--          >-->
<!--            <feather-icon-->
<!--              size="17"-->
<!--              icon="XCircleIcon"-->
<!--            />-->
<!--            {{ $t('NoData') }}-->
<!--          </div>-->
<!--        </template>-->
<!--        <template #head()="{ label }">-->
<!--          {{ $t(`${label}`) }}-->
<!--        </template>-->
<!--        <template #head(selected)>-->
<!--          <b-form-checkbox-->
<!--            variant="primary"-->
<!--            :checked="templates.length === selectedTemplates.length"-->
<!--            @change="changeAllSelection"-->
<!--          />-->
<!--        </template>-->
<!--        <template #cell(selected)="rowSelected">-->
<!--          <b-form-checkbox-->
<!--            v-model="selectedTemplates"-->
<!--            variant="primary"-->
<!--            :value="rowSelected.item"-->
<!--            @change="changeSelection(rowSelected.rowSelected, rowSelected.index)"-->
<!--          />-->
<!--        </template>-->
<!--        <template #cell(content)="row">-->
<!--          {{ row.value | truncate(20, '...') }}-->
<!--        </template>-->
<!--        <template #cell(action)="row">-->
<!--          <b-button-->
<!--            size="sm"-->
<!--            class="mr-50"-->
<!--            @click="row.toggleDetails"-->
<!--          >-->
<!--            <feather-icon :icon="!row.detailsShowing ? 'EyeIcon' : 'EyeOffIcon'" />-->
<!--          </b-button>-->
<!--          <b-button-->
<!--            size="sm"-->
<!--            class="mr-50"-->
<!--            @click="editField(row.item)"-->
<!--          >-->
<!--            <feather-icon icon="EditIcon" />-->
<!--          </b-button>-->
<!--        </template>-->
<!--        <template #row-details="row">-->
<!--          <b-card>-->
<!--            <b-row class="mb-2">-->
<!--              <b-col :inner-html.prop="row.item.content | shortcodePreview(shortcodes)" />-->
<!--            </b-row>-->
<!--          </b-card>-->
<!--        </template>-->
<!--        <template #table-busy>-->
<!--          <div class="text-center text-danger my-2">-->
<!--            <b-spinner-->
<!--              class="align-middle"-->
<!--              variant="primary"-->
<!--            />-->
<!--          </div>-->
<!--        </template>-->
<!--      </b-table>-->
<!--      <div class="d-flex justify-content-between">-->
<!--        <div>-->
<!--          <vue-select-->
<!--            v-model="pagination.limit"-->
<!--            style="min-width: 75px"-->
<!--            class="per-page-selector d-inline-block mr-50 pb-50 select-size-sm"-->
<!--            :options="[5, 10, 15, 25]"-->
<!--            :clearable="false"-->
<!--            @input="reloadTemplates(pagination.currentPage, $event)"-->
<!--          />-->
<!--        </div>-->
<!--        <div>-->
<!--          <b-pagination-->
<!--            v-model="pagination.currentPage"-->
<!--            :total-rows="rows"-->
<!--            :per-page="pagination.limit"-->
<!--            align="center"-->
<!--            @change="reloadTemplates($event, pagination.limit)"-->
<!--          />-->
<!--        </div>-->
<!--      </div>-->
<!--    </b-col>-->

<!--    <transition name="fade">-->
<!--      <div-->
<!--        v-if="selectedTemplates.length"-->
<!--        style="position: fixed; right: 30px; bottom: 20px"-->
<!--        class="shadow"-->
<!--      >-->
<!--        <b-button-->
<!--          variant="danger"-->
<!--          class="d-flex align-items-center"-->
<!--          size="sm"-->
<!--          @click="removeTemplates"-->
<!--        >-->
<!--          <feather-icon icon="TrashIcon" />-->
<!--          <span class="pr-1 pl-25">{{ $t('Delete') }}</span>-->
<!--          <b-badge variant="light text-danger">-->
<!--            {{ selectedTemplates.length }}-->
<!--          </b-badge>-->
<!--        </b-button>-->
<!--      </div>-->
<!--    </transition>-->
<!--  </b-row>-->
<!--</template>-->

<!--<script>-->
<!--import VueSelect from 'vue-select'-->
<!--import {-->
<!--  BFormTextarea, BTable, BPagination,-->
<!--} from 'bootstrap-vue'-->
<!--import { GET_TEMPLATES, SAVE_TEMPLATE, DELETE_TEMPLATE } from '@/@constants/mutations'-->
<!--import { mapGetters } from 'vuex'-->

<!--export default {-->
<!--  name: 'Sms',-->
<!--  components: {-->
<!--    BFormTextarea,-->
<!--    BTable,-->
<!--    BPagination,-->
<!--    VueSelect,-->
<!--  },-->
<!--  data: () => ({-->
<!--    sorter: {-->
<!--      sortBy: 'createdAt',-->
<!--      sortDesc: true,-->
<!--    },-->
<!--    pagination: {-->
<!--      limit: 5,-->
<!--      currentPage: 1,-->
<!--    },-->
<!--    loading: false,-->
<!--    selectedTemplates: [],-->
<!--    fields: [-->
<!--      {-->
<!--        key: 'selected',-->
<!--      },-->
<!--      {-->
<!--        key: 'name',-->
<!--        label: 'Title',-->
<!--      },-->
<!--      {-->
<!--        key: 'content',-->
<!--        label: 'Template',-->
<!--      },-->
<!--      {-->
<!--        key: 'action',-->
<!--        label: 'Action',-->
<!--      },-->
<!--    ],-->
<!--    editedTemplateId: null,-->
<!--    template: {-->
<!--      content: '',-->
<!--      name: '',-->
<!--    },-->
<!--    templatePreview: false,-->
<!--  }),-->
<!--  computed: {-->
<!--    ...mapGetters({-->
<!--      shortcodes: 'templates/getShortcodes',-->
<!--      templates: 'templates/getSmsTemplates',-->
<!--      rows: 'templates/getSmsTemplatesLength',-->
<!--    }),-->
<!--  },-->
<!--  mounted() {-->
<!--    this.reloadTemplates()-->
<!--  },-->
<!--  methods: {-->
<!--    reloadTemplates(page = this.pagination.currentPage, limit = this.pagination.limit) {-->
<!--      this.loading = true-->

<!--      this.$store.dispatch(`templates/${GET_TEMPLATES}`, { type: 'sms', page: this.rows < limit ? 1 : page, limit })-->
<!--        .then(() => {-->
<!--          setTimeout(() => { this.loading = false }, 500)-->
<!--        })-->
<!--        .catch(() => {-->
<!--          this.loading = false-->
<!--          this.showToast('error', this.$i18n.t('ProblemOccured'))-->
<!--        })-->
<!--    },-->
<!--    editField(item) {-->
<!--      if (item) {-->
<!--        this.editedTemplateId = item.id-->
<!--        this.template = {-->
<!--          content: item.content.replaceAll('<br>', '\n'),-->
<!--          name: item.name,-->
<!--        }-->
<!--      } else {-->
<!--        this.editedTemplateId = null-->
<!--        this.template = {-->
<!--          content: '',-->
<!--          name: '',-->
<!--        }-->
<!--      }-->
<!--    },-->
<!--    changeSelection(e, index) {-->
<!--      if (!e) this.$refs.selectableTable.selectRow(index)-->
<!--      else this.$refs.selectableTable.unselectRow(index)-->
<!--    },-->
<!--    changeAllSelection() {-->
<!--      if (this.templates.length === this.selectedTemplates.length) this.$refs.selectableTable.clearSelected()-->
<!--      else this.$refs.selectableTable.selectAllRows()-->
<!--    },-->
<!--    onRowSelected(items) {-->
<!--      this.selectedTemplates = items-->
<!--    },-->
<!--    insertShortcode(shortcode) {-->
<!--      const { content } = this.template-->
<!--      const textarea = this.$refs.sms_content.$refs.input-->
<!--      const sentenceLength = content.length-->
<!--      let position = textarea.selectionStart-->

<!--      if (position === undefined) {-->
<!--        position = sentenceLength - 1-->
<!--      }-->

<!--      const before = content.substr(0, position)-->
<!--      const after = content.substr(position, sentenceLength)-->

<!--      this.template.content = before + shortcode.shortcode + after-->

<!--      this.$nextTick().then(() => {-->
<!--        textarea.focus()-->
<!--      })-->
<!--    },-->
<!--    saveTemplate() {-->
<!--      const { template } = this-->
<!--      if (this.editedTemplateId) template.id = this.editedTemplateId-->
<!--      this.$store.dispatch(`templates/${SAVE_TEMPLATE}`, { type: 'sms', template })-->
<!--        .then(() => {-->
<!--          this.editedTemplateId = null-->
<!--          this.template = {-->
<!--            content: '',-->
<!--            name: '',-->
<!--          }-->
<!--          this.reloadTemplates()-->
<!--          this.showToast('success', this.editedTemplateId ? this.$i18n.t('TemplateUpdated') : this.$i18n.t('TemplateAdded'))-->
<!--        })-->
<!--        .catch(() => {-->
<!--          this.showToast('error', this.$i18n.t('ProblemOccured'))-->
<!--        })-->
<!--    },-->
<!--    removeTemplates() {-->
<!--      const templatesToRemove = this.selectedTemplates.map(template => template.id.toString())-->
<!--      this.$store.dispatch(`templates/${DELETE_TEMPLATE}`, { templatesToRemove, type: 'sms' })-->
<!--        .then(() => {-->
<!--          this.reloadTemplates()-->
<!--          this.showToast('success', this.$i18n.t('TemplatesRemoved'))-->
<!--        })-->
<!--        .catch(() => {-->
<!--          this.showToast('error', this.$i18n.t('ProblemOccured'))-->
<!--        })-->
<!--    },-->
<!--  },-->
<!--}-->
<!--</script>-->

<!--<style scoped>-->

<!--</style>-->
