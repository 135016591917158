<template>
  <div class="d-flex flex-column">
    <span>{{ $t('Categories') }}</span>

    <div
      class="d-flex align-items-center my-50"
      style="gap: .5rem"
    >
      <template v-if="categories && categories.length > 0">
        <b-badge
          v-for="(category, i) in categories"
          :key="i"
          v-b-tooltip.hover.v-danger
          :class="{ 'cursor-pointer': isEdit }"
          size="sm"
          :variant="!isEdit ? 'light-primary' : 'light-danger'"
          :disabled="!isEdit"
          :title="!isEdit ? category.name : $t('Delete')"
          @click="!isEdit ? null : $emit('remove', category.id)"
        >
          {{ category.name }}
        </b-badge>
      </template>

      <div
        v-else-if="!isEdit"
        class="text-center py-1 text-primary font-weight-bold"
      >
        <feather-icon
          size="17"
          icon="XCircleIcon"
        />

        {{ $t('NoCategories') }}
      </div>

      <b-button
        v-if="isEdit"
        v-b-tooltip.hover.v-primary
        class="mt-0 px-25"
        size="sm"
        variant="flat-primary"
        :title="$t('Offer.AddCategory')"
        @click="mAddCategory = true"
      >
        {{ $t('Offer.AddCategory') }}

        <feather-icon
          icon="PlusIcon"
          style="top: 0"
        />
      </b-button>
    </div>

    <b-modal
      v-model="mAddCategory"
      :title="$t('Offer.AddCategory')"
      hide-footer
      no-close-on-backdrop
      tabindex="none"
      size="sm"
    >
      <validation-observer
        ref="loginForm"
        v-slot="{invalid}"
      >
        <validation-provider
          name="ChooseCategory"
          rules="required"
        >
          <sw-select :name="$t('ChooseCategory')">
            <v-select
              v-model="category"
              :placeholder="$t('ChooseCategory')"
              :options="categoryList"
              :disabled="!isEdit"
              :filterable="false"
              label="name"
              :reduce="item => ({ id: item.id.toString(), name: item.name })"
              item-value="id"
              item-text="name"
            >
              <template #option="{ name }">
                {{ name | textIsland(28) }}
              </template>

              <template #selected-option="{ name }">
                {{ name | textIsland(28) }}
              </template>

              <template #no-options>
                {{ $t('NoOptions') }}
              </template>
            </v-select>
          </sw-select>
        </validation-provider>

        <b-button
          variant="primary"
          class="w-100"
          size="sm"
          :disabled="invalid"
          @click="addCategory"
        >
          {{ $t('Add') }}
        </b-button>
      </validation-observer>

      <b-overlay
        no-wrap
        :show="isLoading"
        spinner-variant="primary"
      />
    </b-modal>
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { GET_PROJECT_TASK_CATEGORIES } from '@/@constants/mutations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    categories: {
      type: Array,
      required: true,
    },
    isEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    isMeLoading: false,

    mAddCategory: false,

    categoryList: [],
    category: '',
  }),
  watch: {
    mAddCategory(n) {
      if (n) this.loadCategories()
    },
  },
  methods: {
    loadCategories() {
      this.isMeLoading = true

      this.$store.dispatch(`taskCategories/${GET_PROJECT_TASK_CATEGORIES}`)
        .then(res => {
          this.categoryList = res

          const elementsToRemove = new Set(this.categories.map(item => item.id))
          this.categoryList = this.categoryList.filter(item => !elementsToRemove.has(item.id))
        })

        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })

        .finally(() => { this.isMeLoading = false })
    },

    addCategory() {
      const { category } = this

      if (category) {
        this.$emit('new', category)

        this.category = ''

        this.mAddCategory = null
      }
    },
  },
}
</script>
