<template>
  <div class="text-left">
    <div
      class="d-flex flex-wrap justify-content-between align-items-center"
      style="column-gap: .5rem; row-gap: .64rem"
    >
      <!--          v-if="account.user.firstName"-->
      <div>
        <p
          class="text-primary h3 mb-n25"
          :style="account.active ? '' : 'opacity: .5'"
        >
          {{ account.name | textIsland(isMobile ? 24 : 64) }}
          <!--          {{ `${account.user.firstName} ${account.user.lastName}` | textIsland(isMobile ? 24 : 64) }}-->
        </p>
        <div
          class="d-flex flex-wrap align-items-center mt-50"
          style="gap: .5rem"
        >
          <b-badge
            v-if="account && account.user"
            variant="light-primary"
            class="d-flex align-items-center"
            style="gap: .34rem"
          >
            <feather-icon icon="UserIcon" />
            {{ `${account.user.firstName} ${account.user.lastName}` | textIsland(isMobile ? 24 : 64) }}
          </b-badge>

          <b-badge
            v-if="account.imapHost"
            variant="light-primary"
            class="d-flex align-items-center"
            style="gap: .34rem"
          >
            <feather-icon icon="MailIcon" />

            {{ account.imapHost }}
          </b-badge>

          <b-badge
            :variant="account.active ? 'light-success' : 'light-danger'"
            class="d-flex align-items-center"
            style="gap: .34rem"
          >
            {{ $t(account.active ? 'Active2' : 'NotActive2') }}
          </b-badge>
        </div>
      </div>
      <!--        <feather-icon-->
      <!--          v-else-->
      <!--          icon="MinusIcon"-->
      <!--        />-->

      <div
        style="column-gap: .5rem"
      >
        <!--          :disabled="!account.active"-->
        <b-button
          size="sm"
          :variant="account.active ? 'flat-danger' : 'flat-success'"
          @click="account.active ? changeStatus() : openActiveModal()"
        >
          {{ $t(account.active ? 'Deactivate' : 'Activate') }}
        </b-button>

        <b-button
          size="sm"
          variant="flat-warning"
          class="ml-25"
          @click="openModal"
        >
          <sw-icon icon="EditIcon"
                   class="mr-25"
          />
          {{ $t('Edit') }}
        </b-button>

        <!--        <b-button-->
        <!--          size="sm"-->
        <!--          variant="danger"-->
        <!--          @click="deleteAccount()"-->
        <!--        >-->

        <!--          <sw-icon icon="TrashIcon" />-->
        <!--        </b-button>-->
      </div>
    </div>

    <!--    <b-row>-->
    <!--      <b-col-->
    <!--        md="9"-->
    <!--        lg="8"-->
    <!--      >-->
    <!--        <b-form-group :label="$t('gEmail.settings.IncomingMailServer')">-->
    <!--          <b-form-input-->
    <!--            :placeholder="$t('gEmail.settings.IncomingMailServer')"-->
    <!--            :value="account.incomingMailServer.name"-->
    <!--            disabled-->
    <!--          />-->
    <!--        </b-form-group>-->
    <!--      </b-col>-->

    <!--      <b-col-->
    <!--        md="3"-->
    <!--        lg="4"-->
    <!--      >-->
    <!--        <b-form-group :label="$t('Port')">-->
    <!--          <b-form-input-->
    <!--            :placeholder="$t('Port')"-->
    <!--            type="number"-->
    <!--            :value="account.incomingMailServer.port"-->
    <!--            disabled-->
    <!--          />-->
    <!--        </b-form-group>-->
    <!--      </b-col>-->
    <!--    </b-row>-->

    <!--    <b-row class="mb-n1">-->
    <!--      <b-col-->
    <!--        md="9"-->
    <!--        lg="8"-->
    <!--      >-->
    <!--        <b-form-group :label="$t('gEmail.settings.EmergingMailServer')">-->
    <!--          <b-form-input-->
    <!--            :placeholder="$t('gEmail.settings.EmergingMailServer')"-->
    <!--            :value="account.emergingMailServer.name"-->
    <!--            disabled-->
    <!--          />-->
    <!--        </b-form-group>-->
    <!--      </b-col>-->

    <!--      <b-col-->
    <!--        md="3"-->
    <!--        lg="4"-->
    <!--      >-->
    <!--        <b-form-group :label="$t('Port')">-->
    <!--          <b-form-input-->
    <!--            :placeholder="$t('Port')"-->
    <!--            type="number"-->
    <!--            :value="account.emergingMailServer.port"-->
    <!--            disabled-->
    <!--          />-->
    <!--        </b-form-group>-->
    <!--      </b-col>-->
    <!--    </b-row>-->

    <b-modal
      v-model="isSetUserEmailModal"
      :title="$t('Configuration')"
      hide-footer
      tabindex="none"
      size="lg"
    >
      <set-user-email-modal
        :id="account.id"
        @update="item => {
          isSetUserEmailModal = false
          $emit('update', item)
        }"
      />

      <b-overlay
        :show="isLoading"
        no-wrap
        spinner-variant="primary"
      />
    </b-modal>

    <b-modal
      v-model="isActiveModal"
      :title="$t('Activate')"
      hide-footer
      tabindex="none"
      size="sm"
    >
      <validation-observer
        ref="observer"
        v-slot="{ invalid }"
        tag="form"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="$t('gEmail.settings.EmailPassword')"
          rules="required"
        >
          <b-form-group :label="$t('gEmail.settings.EmailPassword')">
            <b-form-input
              v-model="newPass"
              :placeholder="$t('gEmail.settings.EmailPassword')"
              :state="errors.length > 0 ? false:null"
              type="password"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <b-button
          size="sm"
          variant="success"
          :disabled="invalid"
          @click="() => {
            isActiveModal = false
            changeStatus(newPass)
          }"
        >
          {{ $t('Activate') }}
        </b-button>
      </validation-observer>

      <b-overlay
        :show="isLoading"
        no-wrap
        spinner-variant="primary"
      />
    </b-modal>

    <b-overlay
      :show="isLoading"
      no-wrap
      spinner-variant="primary"
    />
  </div>
</template>

<script>
import useVerticalLayout from '@core/layouts/layout-vertical/useVerticalLayout'
import { onUnmounted } from '@vue/composition-api'
import { VBTooltip } from 'bootstrap-vue'
import SetUserEmailModal from '@/pages/user/current/components/globalEmail/components/SetUserEmailModal.vue'
import { UPDATE_USER_MAILBOX } from '@/@constants/mutations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import axiosIns from '@/libs/axios'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    SetUserEmailModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    account: {
      type: Object,
      required: true,
    },
  },
  emits: ['update', 'update-pass'],
  setup() {
    const { isMobile, resizeHandler } = useVerticalLayout()

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    return {
      perfectScrollbarSettings,
      isMobile,
    }
  },
  data: () => ({
    isLoading: false,

    emailAccountList: [],

    isSetUserEmailModal: false,
    isActiveModal: false,

    newPass: '',
  }),
  methods: {
    removeAccount(id) {
      if (id) {
        this.showAlert('warning', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('alerts.WontbeAble'))
      }
    },

    openModal() { this.isSetUserEmailModal = true },
    openActiveModal() { this.isActiveModal = true },

    async deleteAccount() {
      const confirmed = await this.showAlert('warning', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('DeleteMailBoxInfo'))
      if (!confirmed) return

      try {
        await axiosIns.delete('1/users/me/mailboxes', { data: JSON.stringify([this.account.id]) })

        this.showToast('success', this.$i18n.t('RemovedSuccessfully'))
        this.$emit('update')
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    async changeStatus(imapPass = '') {
      this.isLoading = true

      const { id } = this.account

      const payload = {
        id,
        imapPass,
        smtpPass: imapPass,
      }

      await this.$store.dispatch(`mailbox/${UPDATE_USER_MAILBOX}`, payload)

      this.newPass = ''

      this.$emit('update-pass', imapPass.length)

      this.isLoading = false
    },
  },
}
</script>
