<template>
  <div>
    <!--    Buttons    -->
    <div
      class="mb-1 d-flex flex-wrap"
      style="gap: .34rem"
    >
      <!--    Button: Cancel    -->
      <b-button
        variant="warning"
        size="sm"
        @click="$router.go(-1)"
      >
        <feather-icon icon="ArrowLeftIcon" />

        {{ $t('Return') }}
      </b-button>
    </div>

    <b-card>
      <!--     Form     -->
      <b-form>
        <b-row>
          <b-col sm="12"
                 lg="6"
          >
            <b-form-group :label="$t('EnterNameDocument')">
              <b-form-input v-model="editor.name" />
            </b-form-group>
          </b-col>

          <!-- Document Types -->
          <b-col sm="12"
                 lg="6"
          >
            <sw-select :name="$t('Contracts.AgreementType')">
              <v-select
                v-model="editor.agreementType"
                :options="types"
                label="name"
              >
                <template #no-options>
                  {{ $t('NoOptions') }}
                </template>
              </v-select>
            </sw-select>
          </b-col>

          <!-- Polls -->
          <b-col sm="12"
                 lg="4"
          >
            <sw-select
              v-if="checkRequiredModule('pollModule')"
              :name="$t('RelatedPolls')"
            >
              <v-select
                v-model="editor.assignedPolls"
                :options="polls"
                label="name"
                multiple
                @option:deselecting="deselectPoll"
              >
                <template #no-options>
                  {{ $t('NoOptions') }}
                </template>
              </v-select>
            </sw-select>
          </b-col>

          <!-- Signer users -->
          <b-col sm="12"
                 lg="4"
          >
            <sw-select :name="$t('UsersAllowedToSignContract')">
              <assigned-users
                :value="editor.signerUsers"
                @input="changeAssignedUsers"
              />
            </sw-select>
          </b-col>
          <b-col sm="12"
                 lg="4"
          >
            <sw-select
              class="mb-0 pb-0"
              :name="$t('Language')"
            >
              <v-select
                v-model.trim="editor.locale"
                :options="filteredLocales"
                :reduce="e => e.value"
                class="mb-0"
                label="name"
              >
                <template #option="{ label }">
                  {{ $t(label) }}
                </template>
                <template #selected-option="{ label }">
                  {{ $t(label) }}
                </template>
              </v-select>
            </sw-select>
          </b-col>

          <b-col cols="12">
            <b-form-checkbox-group
              stacked
            >
              <b-form-checkbox
                v-model="editor.enableElectronicSignatureTypes"
                value="AUTENTI"
                class="custom-control-primary"
                variant="primary"
              >
                {{ $t('EnableAutentiSignature') }}
              </b-form-checkbox>

              <b-form-checkbox
                v-model="editor.enableElectronicSignatureTypes"
                value="WWW_SIGNATURE"
                class="custom-control-primary"
                variant="primary"
              >
                {{ $t('EnableHandwrittenSignature') }}
              </b-form-checkbox>
            </b-form-checkbox-group>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
    <b-card>
      <b-form>
        <b-row>
          <!-- Header, Footer, Watermark -->
          <b-col sm="12"
                 md="6"
          >
            <b-form-group :label="$t('Header')">
              <b-form-textarea v-model="editor.headerText"
                               class="mr-25 mb-25"
                               rows="5"
              />
            </b-form-group>
            <b-row>
              <b-col sm="12"
                     md="6"
              >
                <b-form-group :label="$t('FontSize') + '  (' + $t('Header') + ')' ">
                  <b-form-input v-model="headerFont" />
                </b-form-group>
              </b-col>
              <b-col sm="12"
                     md="6"
              >
                <b-form-group>
                  <sw-form-input-color
                    :value="headerColor"
                    :label="$t('FontColor') + '  (' + $t('Header') + ')' "
                    height="65px"
                    fluid-x
                    @input="headerColor = $event.hex"
                  />
                </b-form-group>
                <!--                <b-form-group :label="$t('FontColor') + '  (' + $t('Header') + ')' ">-->
                <!--                  <b-form-input-->
                <!--                    v-model="headerColor"-->
                <!--                    type="color"-->
                <!--                    class="color-input"-->
                <!--                  />-->
                <!--                </b-form-group>-->
              </b-col>
            </b-row>
          </b-col>
          <b-col sm="12"
                 md="6"
          >
            <b-form-group :label="$t('Footer')">
              <b-form-textarea v-model="editor.footerText"
                               class="mr-25 mb-25"
                               rows="5"
              />
            </b-form-group>
            <b-row>
              <b-col sm="12"
                     md="6"
              >
                <b-form-group :label="$t('FontSize') + '  (' + $t('Footer') + ')' ">
                  <b-form-input v-model="footerFont" />
                </b-form-group>
              </b-col>
              <b-col sm="12"
                     md="6"
              >
                <b-form-group>
                  <sw-form-input-color
                    :value="footerColor"
                    :label="$t('FontColor') + '  (' + $t('Footer') + ')' "
                    height="65px"
                    fluid-x
                    @input="footerColor = $event.hex"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>

          <b-col sm="12"
                 class="d-flex flex-wrap"
          >
            <div class="mr-2 position-relative">
              <div class="d-flex justify-content-between align-items-center">
                <span>Logo ({{ $t('Header') }})</span>
                <b-button v-if="editor.headerLogo"
                          variant="flat-danger"
                          class="btn-icon"
                          size="sm"
                          @click="editor.headerLogo = ''"
                >
                  <sw-icon icon="TrashIcon" />
                </b-button>
              </div>
              <div class="dragDrop mb-25 rounded mt-25">
                <div>
                  <div v-if="editor.headerLogo"
                       class="position-relative"
                  >
                    <b-img
                      id="img-headerLogo"
                      class="px-0 py-0 center bg-cover"
                      style="object-fit: contain"
                      thumbnail
                      fluid
                      cover
                      :src="editor.headerLogo"
                    />
                  </div>
                  <div v-else
                       class="border d-flex align-items-center justify-content-center flex-column rounded"
                       style="min-width: 225px; min-height: 100px"
                  >
                    <feather-icon icon="UploadIcon"
                                  size="28"
                                  class="mb-50 text-primary"
                    />
                    {{ $t('DragDropFile') }}
                  </div>

                  <b-button
                    v-b-tooltip.hover.v-primary
                    variant="primary"
                    :title="$t('DragDropFile')"
                  >
                    <feather-icon icon="PlusIcon"
                                  size="28"
                    />
                    <input
                      type="file"
                      accept="image/*"
                      @change="onChange($event, 'headerLogo', 1250)"
                    >
                  </b-button>
                </div>
              </div>
            </div>

            <div class="mr-2">
              <div class="d-flex justify-content-between align-items-center">
                <span>Logo ({{ $t('Footer') }})</span>
                <b-button v-if="editor.footerLogo"
                          variant="flat-danger"
                          class="btn-icon"
                          size="sm"
                          @click="editor.footerLogo = ''"
                >
                  <sw-icon icon="TrashIcon" />
                </b-button>
              </div>
              <div class="dragDrop mb-25 rounded mt-25">
                <div>
                  <b-img
                    v-if="editor.footerLogo"
                    id="img-footerLogo"
                    class="px-0 py-0 center bg-cover"
                    style="object-fit: contain"
                    thumbnail
                    fluid
                    :src="editor.footerLogo"
                  />
                  <div v-else
                       class="border d-flex align-items-center justify-content-center flex-column rounded"
                       style="min-width: 225px; min-height: 100px"
                  >
                    <feather-icon icon="UploadIcon"
                                  size="28"
                                  class="mb-50 text-primary"
                    />
                    {{ $t('DragDropFile') }}
                  </div>

                  <b-button
                    v-b-tooltip.hover.v-primary
                    variant="primary"
                    :title="$t('DragDropFile')"
                  >
                    <feather-icon icon="PlusIcon"
                                  size="28"
                    />
                    <input
                      type="file"
                      accept="image/*"
                      @change="onChange($event, 'footerLogo', 1250)"
                    >
                  </b-button>
                </div>
              </div>
            </div>
            <div>
              <div class="d-flex justify-content-between align-items-center">
                <span>{{ $t('Watermark') }} <feather-icon v-b-tooltip
                                                          icon="AlertCircleIcon"
                                                          class="text-primary"
                                                          :title="$t('TransparentBackgroup')"
                /></span>
                <b-button v-if="editor.watermark"
                          variant="flat-danger"
                          class="btn-icon"
                          size="sm"
                          @click="editor.watermark = ''"
                >
                  <sw-icon icon="TrashIcon" />
                </b-button>
              </div>

              <div class="dragDrop mx-auto mt-25 mb-25">
                <div>
                  <b-img
                    v-if="editor.watermark"
                    id="img-watermark"
                    class="px-0 py-0 center bg-cover"
                    style="object-fit: contain"
                    thumbnail
                    fluid
                    :src="editor.watermark"
                  />
                  <div v-else
                       class="border d-flex align-items-center justify-content-center flex-column rounded"
                       style="min-width: 225px; min-height: 100px"
                  >
                    <feather-icon icon="UploadIcon"
                                  size="28"
                                  class="mb-50 text-primary"
                    />
                    {{ $t('DragDropFile') }}
                  </div>

                  <b-button
                    v-b-tooltip.hover.v-primary
                    variant="primary"
                    :title="$t('DragDropFile')"
                  >
                    <feather-icon icon="PlusIcon"
                                  size="28"
                    />
                    <input
                      type="file"
                      accept="image/*"
                      @change="onChange($event, 'watermark', 1250)"
                    >
                  </b-button>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </b-card>

    <!--    <b-card>-->
    <!--      &lt;!&ndash;     Description     &ndash;&gt;-->
    <!--      <b-form-group :label="$t('task.Description')">-->
    <!--        <quill-editor-->
    <!--          ref="editor"-->
    <!--          v-model="editor.content"-->
    <!--          :options="editorOption"-->
    <!--        >-->
    <!--          <div-->
    <!--            id="toolbar"-->
    <!--            slot="toolbar"-->
    <!--          >-->
    <!--            &lt;!&ndash; Add a bold button &ndash;&gt;-->
    <!--            <span class="ql-formats">-->
    <!--              <button class="ql-clean" />-->
    <!--              <button class="ql-bold" />-->
    <!--              <button class="ql-italic" />-->
    <!--              <button class="ql-link" />-->
    <!--            </span>-->
    <!--            <span class="ql-formats">-->
    <!--              <button-->
    <!--                class="ql-script"-->
    <!--                value="sub"-->
    <!--              />-->
    <!--              <button-->
    <!--                class="ql-script"-->
    <!--                value="super"-->
    <!--              />-->
    <!--            </span>-->
    <!--            <span class="ql-formats">-->
    <!--              <button-->
    <!--                class="ql-list"-->
    <!--                value="ordered"-->
    <!--              />-->
    <!--              <button-->
    <!--                class="ql-list"-->
    <!--                value="bullet"-->
    <!--              />-->
    <!--            </span>-->
    <!--            <span class="ql-formats">-->
    <!--              <button-->
    <!--                class="ql-indent"-->
    <!--                value="-1"-->
    <!--              />-->
    <!--              <button-->
    <!--                class="ql-indent"-->
    <!--                value="+1"-->
    <!--              />-->
    <!--            </span>-->
    <!--            <span class="ql-formats">-->
    <!--              <select class="ql-align" />-->
    <!--              <select class="ql-color" />-->
    <!--            </span>-->
    <!--            <span class="ql-formats">-->
    <!--              <select class="ql-size" />-->
    <!--              <select class="ql-header" />-->
    <!--              <select class="ql-font" />-->
    <!--            </span>-->

    <!--            &lt;!&ndash; You can also add your own &ndash;&gt;-->
    <!--            <span class="ql-formats">-->
    <!--              <b-button-->
    <!--                id="custom-button"-->
    <!--                variant="success"-->
    <!--                class="w-auto"-->
    <!--                @click.prevent.stop="isModalOpen = true"-->
    <!--              >-->
    <!--                <span class="text-white">-->
    <!--                  <feather-icon-->
    <!--                    icon="CodeIcon"-->
    <!--                    class="mr-25"-->
    <!--                  />-->
    <!--                </span>-->

    <!--                <span class="text-white">-->
    <!--                  {{ $t('ShortCodes') }}-->
    <!--                </span>-->
    <!--              </b-button>-->
    <!--            </span>-->

    <!--            <span class="ql-formats">-->
    <!--              <button id="insert-table-button">Insert Table</button>-->
    <!--            </span>-->
    <!--          </div>-->
    <!--        </quill-editor>-->
    <!--      </b-form-group>-->
    <!--    </b-card>-->

    <b-card>
      <!--     Description     -->
      <b-form-group :label="$t('task.Description')">
        <div v-if="contentEditor"
             class="editor-container"
        >
          <div class="control-group">
            <div class="button-group">
              <span class="editor-option-group">
                <span class="editor-option"
                      size="sm"
                      @click="contentEditor.chain().focus().unsetAllMarks().run()"
                >
                  <sw-icon size="50"
                           icon="md-formatclear"
                  />
                </span>
              </span>

              <span class="editor-option-group">
                <span class="editor-option"
                      size="sm"
                      :class="{ 'is-active': contentEditor.isActive('bold') }"
                      @click="contentEditor.chain().focus().toggleBold().run()"
                >
                  <sw-icon size="50"
                           icon="bi-type-bold"
                  />
                </span>
                <span class="editor-option"
                      size="sm"
                      :class="{ 'is-active': contentEditor.isActive('italic') }"
                      @click="contentEditor.chain().focus().toggleItalic().run()"
                >
                  <sw-icon size="50"
                           icon="bi-type-italic"
                  />
                </span>
                <span class="editor-option"
                      size="sm"
                      :class="{ 'is-active': contentEditor.isActive('strike') }"
                      @click="contentEditor.chain().focus().toggleStrike().run()"
                >
                  <sw-icon size="50"
                           icon="bi-type-strikethrough"
                  />
                </span>
              </span>

              <span class="editor-option-group">
                <span v-if="!contentEditor.isActive('link')"
                      class="editor-option"
                      @click="setLink"
                >
                  <sw-icon size="50"
                           icon="bi-link"
                  />
                </span>
                <span v-else
                      class="editor-option is-active"
                      @click="contentEditor.chain().focus().unsetLink().run()"
                >
                  <sw-icon size="50"
                           icon="bi-link"
                  />
                </span>
              </span>

              <span class="editor-option-group">
                <span class="editor-option"
                      size="sm"
                      :class="{ 'is-active': contentEditor.isActive('heading', { level: 1 }) }"
                      @click="contentEditor.chain().focus().toggleHeading({ level: 1 }).run()"
                >
                  <sw-icon size="50"
                           icon="bi-type-h1"
                  />
                </span>
                <span class="editor-option"
                      size="sm"
                      :class="{ 'is-active': contentEditor.isActive('heading', { level: 2 }) }"
                      @click="contentEditor.chain().focus().toggleHeading({ level: 2 }).run()"
                >
                  <sw-icon size="50"
                           icon="bi-type-h2"
                  />
                </span>
                <span class="editor-option"
                      size="sm"
                      :class="{ 'is-active': contentEditor.isActive('heading', { level: 3 }) }"
                      @click="contentEditor.chain().focus().toggleHeading({ level: 3 }).run()"
                >
                  <sw-icon size="50"
                           icon="bi-type-h3"
                  />
                </span>
              </span>
              <span class="editor-option-group">
                <span class="editor-option"
                      size="sm"
                      :class="{ 'is-active': contentEditor.isActive('bulletList') }"
                      @click="contentEditor.chain().focus().toggleBulletList().run()"
                >
                  <sw-icon size="50"
                           icon="bi-list-ul"
                  />
                </span>
                <span class="editor-option"
                      size="sm"
                      :class="{ 'is-active': contentEditor.isActive('orderedList') }"
                      @click="contentEditor.chain().focus().toggleOrderedList().run()"
                >
                  <sw-icon size="50"
                           icon="bi-list-ol"
                  />
                </span>
              </span>
              <span class="editor-option-group">
                <span class="editor-option"
                      size="sm"
                      @click="contentEditor.chain().focus().setHorizontalRule().run()"
                >
                  <sw-icon size="50"
                           icon="md-horizontalrule"
                  />
                </span>
              </span>
              <span class="editor-option-group">
                <span class="editor-option"
                      size="sm"
                      :class="{ 'is-active': contentEditor.isActive({ textAlign: 'left' }) }"
                      @click="contentEditor.chain().focus().setTextAlign('left').run()"
                >
                  <sw-icon size="50"
                           icon="bi-text-left"
                  />
                </span>
                <span class="editor-option"
                      size="sm"
                      :class="{ 'is-active': contentEditor.isActive({ textAlign: 'center' }) }"
                      @click="contentEditor.chain().focus().setTextAlign('center').run()"
                >
                  <sw-icon size="50"
                           icon="bi-text-center"
                  />
                </span>
                <span class="editor-option"
                      size="sm"
                      :class="{ 'is-active': contentEditor.isActive({ textAlign: 'right' }) }"
                      @click="contentEditor.chain().focus().setTextAlign('right').run()"
                >
                  <sw-icon size="50"
                           icon="bi-text-right"
                  />
                </span>
                <span class="editor-option"
                      size="sm"
                      :class="{ 'is-active': contentEditor.isActive({ textAlign: 'justify' }) }"
                      @click="contentEditor.chain().focus().setTextAlign('justify').run()"
                >
                  <sw-icon size="50"
                           icon="bi-justify"
                  />
                </span>
              </span>

              <span class="editor-option-group">
                <span class="editor-option"
                      size="sm"
                      @click="$refs.upload_image.click()"
                >
                  <sw-icon size="50"
                           icon="bi-image"
                  />
                </span>

                <input
                  id="img-uploader"
                  ref="upload_image"
                  type="file"
                  accept="image/*"
                  style="display: none"
                  @change="addImage"
                >
              </span>

              <span class="editor-option-group">
                <span id="table-action"
                      class="editor-option"
                      size="sm"
                      @click="contentEditor.chain().focus().setTextAlign('justify').run()"
                >
                  <sw-icon size="50"
                           icon="bi-table"
                  />
                </span>

                <b-popover target="table-action"
                           triggers="hover"
                           custom-class="grid-selection-popover"
                >
                  <div class="text-left">
                    {{ $t('AddTable') }}
                    <GridSelection @selection="contentEditor.chain().focus().insertTable({ rows: $event.rows, cols: $event.cols, withHeaderRow: false }).run()" />
                    <!--                    <b-button variant="flat-primary"-->
                    <!--                              block-->
                    <!--                              size="sm"-->
                    <!--                              :disabled="!contentEditor.can().deleteTable()"-->
                    <!--                              @click="contentEditor.chain().focus().deleteTable().run()"-->
                    <!--                    >-->
                    <!--                      {{ $t('ConnectSplit') }}-->
                    <!--                    </b-button>-->

                    <!--                    <div>-->
                    <!--                      {{ $t('Row') }}-->
                    <!--                      <b-button variant="flat-danger"-->
                    <!--                                block-->
                    <!--                                size="sm"-->
                    <!--                                :disabled="!contentEditor.can().deleteRow()"-->
                    <!--                                @click="contentEditor.chain().focus().deleteRow().run()"-->
                    <!--                      >-->
                    <!--                        <sw-icon icon="TrashIcon" />{{ $t('Delete') }}-->
                    <!--                      </b-button>-->
                    <!--                    </div>-->
                    <!--                    <hr>-->

                    <!--                    <div>-->
                    <!--                      {{ $t('Col') }}-->
                    <!--                      <b-button variant="flat-danger"-->
                    <!--                                block-->
                    <!--                                size="sm"-->
                    <!--                                :disabled="!contentEditor.can().deleteColumn()"-->
                    <!--                                @click="contentEditor.chain().focus().deleteColumn().run()"-->
                    <!--                      >-->
                    <!--                        <sw-icon icon="TrashIcon" />{{ $t('Delete') }}-->
                    <!--                      </b-button>-->
                    <!--                    </div>-->
                    <!--                    <hr>-->

                    <b-button variant="flat-danger"
                              block
                              size="sm"
                              class="mt-25"
                              :disabled="!contentEditor.can().deleteTable()"
                              @click="contentEditor.chain().focus().deleteTable().run()"
                    >
                      <sw-icon icon="TrashIcon" />{{ $t('Delete') }}
                    </b-button>
                  </div>
                </b-popover>

                <!--                <button @click="contentEditor.chain().focus().insertTable({ rows: 2, cols: 2, withHeaderRow: true }).run()">-->
                <!--                  Insert table-->
                <!--                </button>-->
                <!--                <button :disabled="!contentEditor.can().deleteColumn()"-->
                <!--                        @click="contentEditor.chain().focus().deleteColumn().run()"-->
                <!--                >-->
                <!--                  Delete column-->
                <!--                </button>-->
                <!--                <button :disabled="!contentEditor.can().deleteRow()"-->
                <!--                        @click="contentEditor.chain().focus().deleteRow().run()"-->
                <!--                >-->
                <!--                  Delete row-->
                <!--                </button>-->
                <!--                <button :disabled="!contentEditor.can().deleteTable()"-->
                <!--                        @click="contentEditor.chain().focus().deleteTable().run()"-->
                <!--                >-->
                <!--                  Delete table-->
                <!--                </button>-->
                <!--                <button :disabled="!contentEditor.can().mergeOrSplit()"-->
                <!--                        @click="contentEditor.chain().focus().mergeOrSplit().run()"-->
                <!--                >-->
                <!--                  Merge or split-->
                <!--                </button>-->
              </span>

              <span class="editor-option-group">
                <b-button
                  id="custom-button"
                  variant="success"
                  class="w-auto"
                  size="sm"
                  @click.prevent.stop="isModalOpen = true"
                >
                  <span class="text-white">
                    <feather-icon
                      icon="CodeIcon"
                      class="mr-25"
                    />
                  </span>

                  <span class="text-white">
                    {{ $t('ShortCodes') }}
                  </span>
                </b-button>
              </span>

            </div>
          </div>
          <div style="padding: 10px; height: unset">
            <editor-content
              :editor="contentEditor"
            />
          </div>
        </div>
      </b-form-group>
    </b-card>

    <!--     Save Button     -->
    <b-button
      variant="primary"
      :disabled="!editor.name || !editor.signerUsers.length"
      @click="saveContract"
    >
      {{ $t('Save') }}
    </b-button>

    <!--    <transition name="fade">-->
    <!--      <div-->
    <!--        v-if="scrollPos.y.value > 200"-->
    <!--        class="btn position-fixed mr-25"-->
    <!--        style="z-index: 999; bottom: 4.4rem; right: 0; transition-delay: .14s;"-->
    <!--      >-->
    <!--        <b-button-->
    <!--          variant="success"-->
    <!--          class="btn-icon rounded-circle"-->
    <!--          @click.prevent.stop="isModalOpen = true"-->
    <!--        >-->
    <!--          <feather-icon-->
    <!--            icon="CodeIcon"-->
    <!--            size="16"-->
    <!--          />-->
    <!--        </b-button>-->
    <!--      </div>-->
    <!--    </transition>-->

    <!--    Modal: Shortcode     -->
    <b-modal
      :visible="isModalOpen"
      :title="$t('Codes')"

      size="lg"
      hide-footer
      no-close-on-backdrop
      @hide="isModalOpen = false"
    >
      <shortcodes-modal
        :polls="editor.assignedPolls"
        :include-components="['PRODUCT', 'TRANCHE', 'CONTRACTOR', 'PAYMENT_INSTALLMENTS']"
        include-default-codes
        :default-shortcodes="[...signerUserShortcodes]"
        @close-modal="isModalOpen = false"
      />
    </b-modal>

    <b-overlay
      no-wrap
      :show="loading"
      spinner-variant="primary"
    />

    <!--    <div-->
    <!--      class="btn-scroll-to-top"-->
    <!--      :class="{'show': y > 250}"-->
    <!--    >-->
    <!--      <b-button-->
    <!--        variant="primary"-->
    <!--        class="btn-icon"-->
    <!--        @click="scrollToTop"-->
    <!--      >-->
    <!--        <feather-icon-->
    <!--          icon="ArrowUpIcon"-->
    <!--          size="16"-->
    <!--        />-->
    <!--      </b-button>-->
    <!--    </div>-->
  </div>
</template>

<script>
// eslint-disable-next-line max-classes-per-file
import {
  BForm, VBTooltip, BFormTextarea, BPopover,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// eslint-disable-next-line import/no-extraneous-dependencies
import ShortcodesModal from '@/views/modals/ShortcodesModal.vue'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
import {
  CREATE_CONTRACT_TEMPLATE,
  GET_CONTRACT_TEMPLATE,
  GET_POLLS,
  UPDATE_CONTRACT_TEMPLATE,
} from '@/@constants/mutations'
import keysCuter from '@/helpers/keysCuter'
import AssignedUsers from '@/views/components/AssignedUsers.vue'
import { signerUserShortcodes } from '@/@data/shortcodes'
import axiosIns from '@/libs/axios'
import { useWindowScroll } from '@vueuse/core'
import { BFormCheckboxGroup } from 'bootstrap-vue/src/components/form-checkbox'
// import { quillEditor } from 'vue-quill-editor'
import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import StarterKit from '@tiptap/starter-kit'
import Link from '@tiptap/extension-link'
import Image from '@tiptap/extension-image'
import TextAlign from '@tiptap/extension-text-align'

import { Editor, EditorContent } from '@tiptap/vue-2'
import GridSelection from '@/components/excel/GridSelector.vue'

const CustomTableCell = TableCell.extend({
  addAttributes() {
    return {
      // extend the existing attributes …
      ...this.parent?.(),

      // and add a new one …
      backgroundColor: {
        default: null,
        parseHTML: element => element.getAttribute('data-background-color'),
        renderHTML: attributes => ({
          'data-background-color': attributes.backgroundColor,
          style: `background-color: ${attributes.backgroundColor}`,
        }),
      },
    }
  },
})

const ExtendedImage = Image.extend({
  addAttributes() {
    return {
      src: {
        default: '',
      },
      alt: {
        default: undefined,
      },
      title: {
        default: undefined,
      },
      width: {
        default: undefined,
      },
      height: {
        default: undefined,
      },
      style: {
        default: undefined,
      },
    }
  },
})

export default {
  name: 'ContractDetails',
  components: {
    GridSelection,
    BForm,
    EditorContent,
    // quillEditor,
    BPopover,
    ShortcodesModal,
    vSelect,
    BFormTextarea,
    AssignedUsers,
    BFormCheckboxGroup,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data: () => ({

    contentEditor: null,

    signerUserShortcodes,
    loading: false,
    polls: [],
    types: [],

    footerFont: '8',
    footerColor: '#000000',
    headerFont: '8',
    headerColor: '#000000',

    editor: {
      name: '',
      locale: '',

      watermark: '',

      footerText: '',
      footerLogo: '',
      footerStyle: '',

      headerText: '',
      headerLogo: '',
      headerStyle: '',

      assignedPolls: [],
      content: '',
      agreementType: '',
      paymentSchedule: false,
      advancePayment: false,
      signerUsers: [],
      paymentScheduleToPay: false,
    },
    isModalOpen: false,
    editorOption: {
      placeholder: '',
      modules: {
        toolbar: '#toolbar',
      },
    },
    locales: [
      { value: 'pl_pl', label: 'pl_pl' },
      { value: 'en_uk', label: 'en_uk' },
      { value: 'de_de', label: 'de_de' },
      { value: 'ar_dz', label: 'ar_dz', requiredDomain: 'lasland' },
      { value: 'ko_kr', label: 'ko_kr', requiredDomain: 'lasland' },
      { value: 'zh_cn', label: 'zh_cn', requiredDomain: 'lasland' },
    ],
    scrollPos: 0,
  }),
  computed: {
    filteredLocales() {
      return this.locales.filter(locale => !locale.requiredDomain || window.location.href.includes(locale.requiredDomain))
    },
  },
  watch: {
    '$route.params.contractId': {
      deep: true,
      handler() { this.getTemplate() },
    },
    // eslint-disable-next-line
    'editor.paymentScheduleToPay'(newValue) {
      if (!newValue) this.editor.advancePayment = false
    },
  },
  mounted() {
    this.getPolls()
    this.getTypes()
    this.getTemplate()
    this.scrollPos = useWindowScroll()
  },

  beforeUnmount() {
    this.contentEditor.destroy()
  },
  methods: {
    initEditor(content) {
      this.contentEditor = new Editor({
        extensions: [
          StarterKit,
          Table.configure({
            resizable: true,
          }),
          TableRow,
          TableHeader,
          CustomTableCell,
          TextAlign.configure({
            types: ['heading', 'paragraph'],
          }),
          ExtendedImage.configure({
            allowBase64: true,
          }),
          Link.configure({
            openOnClick: false,
            defaultProtocol: 'https',
          }),
        ],
        content,
      })
    },
    async getTypes() {
      try {
        const resp = await axiosIns.get('1/settings/agreementTypes', { params: { fields_load: this.$fields.SETTINGS_AGREEMENTS_TYPES } })
        this.types = resp.data.data?.items || []
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    addImage(event) {
      const file = event.target.files[0]

      if (file) {
        const reader = new FileReader()

        reader.onload = e => {
          const dataURL = e.target.result
          this.contentEditor.chain().focus().setImage({ src: dataURL, width: '100%' }).run()
        }

        reader.readAsDataURL(file)
      }
    },
    getTemplate() {
      if (this.$route.params?.contractId && this.$route.params.contractId !== 'new') {
        this.loading = true
        this.$nextTick(() => {
          this.$store.dispatch(`contractTemplates/${GET_CONTRACT_TEMPLATE}`, this.$route.params.contractId)
            .then(res => {
              this.$set(this, 'editor', res.data.item)

              if (res.data.item.footerStyle) {
                const { footerStyle } = res.data.item
                const stylesArray = footerStyle.split(';')
                stylesArray.forEach(style => {
                  if (style.includes('color')) {
                    const value = style.replaceAll('color:', '').replaceAll('px', '')
                    this.footerColor = value
                  } else if (style.includes('font-size')) {
                    const value = style.replaceAll('font-size:', '').replaceAll('px', '')
                    this.footerFont = value
                  }
                })
              }

              if (res.data.item.headerStyle) {
                const { headerStyle } = res.data.item
                const stylesArray = headerStyle.split(';')
                stylesArray.forEach(style => {
                  if (style.includes('color')) {
                    const value = style.replaceAll('color:', '').replaceAll('px', '')
                    this.headerColor = value
                  } else if (style.includes('font-size')) {
                    const value = style.replaceAll('font-size:', '').replaceAll('px', '')
                    this.headerFont = value
                  }
                })
              }

              this.initEditor(this.editor.content)

              this.loading = false
            })
            .catch(err => {
              this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
              this.loading = false
            })
        })
      } else if (this.$route.params?.contractId === 'new') {
        this.$set(this, 'editor', {
          name: '',
          assignedPolls: [],
          content: '',
          agreementType: '',
          paymentSchedule: false,
          advancePayment: false,
          signerUsers: [],
          watermark: '',
          locale: '',
          footerText: '',
          footerLogo: '',
          headerText: '',
          headerLogo: '',
          paymentScheduleToPay: false,
        })

        this.initEditor(`<p>${this.$i18n.t('Content')}</p><p></p><p></p><p></p>`)
      }
    },
    getPolls() {
      this.$nextTick(() => {
        if (this.checkRequiredModule('pollModule')) {
          this.$store.dispatch(`polls/${GET_POLLS}`, {})
            .then(res => {
              this.polls = res.data.items
            })
            .catch(err => {
              this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            })
        }
      })
    },
    setLink() {
      const previousUrl = this.contentEditor.getAttributes('link').href
      const url = window.prompt('URL', previousUrl)

      // cancelled
      if (url === null) {
        return
      }

      // empty
      if (url === '') {
        this.contentEditor
          .chain()
          .focus()
          .extendMarkRange('link')
          .unsetLink()
          .run()

        return
      }

      // update link
      this.contentEditor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: url })
        .run()
    },
    deselectPoll(e) {
      this.showAlert('error', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('UsedPollShortcodesWillBeDeleted'))
        .then(() => {
          const pollId = e.id
          e.pollFields.sort(((a, b) => a.position - b.position)).forEach(field => {
            this.$set(this.editor, 'content', this.editor.content.replaceAll(`{% poll.${pollId}.${field.id}.name %}`, ''))
          })
        })
        .catch(() => {
          this.editor.assignedPolls.push(e)
        })
    },
    saveContract() {
      this.loading = true
      const payload = { ...this.editor }
      let action = CREATE_CONTRACT_TEMPLATE

      if (this.$route.params?.contractId && this.$route.params?.contractId !== 'new') {
        action = UPDATE_CONTRACT_TEMPLATE
        payload.id = this.$route.params.contractId
      }

      payload.assignedPolls = payload.assignedPolls.map(poll => (typeof poll === 'string' ? poll : poll?.id))
      payload.signerUsers = payload.signerUsers.map(user => (typeof user === 'string' ? user : user?.id))

      payload.agreementType = typeof payload.agreementType === 'string' ? payload.agreementType : payload.agreementType?.id

      payload.footerStyle = []
      payload.headerStyle = []

      if (this.footerColor) payload.footerStyle.push(`color:${this.footerColor}`)
      if (this.footerFont) payload.footerStyle.push(`font-size:${this.footerFont}px;line-height:${this.footerFont}px`)

      if (this.headerColor) payload.headerStyle.push(`color:${this.headerColor}`)
      if (this.headerFont) payload.headerStyle.push(`font-size:${this.headerFont}px;line-height:${this.headerFont}px`)

      payload.footerStyle = payload.footerStyle.join(';')
      payload.headerStyle = payload.headerStyle.join(';')

      payload.content = this.contentEditor.getHTML()

      this.$store.dispatch(`contractTemplates/${action}`, keysCuter(payload))
        .then(res => {
          if (action === CREATE_CONTRACT_TEMPLATE) this.$router.push({ name: 'contracts-generator', params: { contractId: res.data.items[0]?.id } })
          this.showToast('success', this.$i18n.t(`${action === CREATE_CONTRACT_TEMPLATE ? 'ContractTemplateCreated' : 'ContractTemplateUpdated'}`))
          this.loading = false
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          this.loading = false
        })
    },
    changeAssignedUsers(payload) {
      this.editor.signerUsers = payload
    },
    onChange(event, option, maxSize) {
      if (event.target.files[0].size > maxSize * 1000) {
        this.showToast('danger', this.$i18n.tc('MoreThanKB', 1, {
          type: this.$i18n.t('SystemMoreThan.logo'),
          size: maxSize,
        }))
        return
      }
      const fr = new FileReader()
      fr.onload = () => {
        this.$set(this.editor, option, fr.result)
      }
      fr.readAsDataURL(event.target.files[0])
    },
  },
}
</script>

<style lang="scss">
.ql-editor { min-height: 16rem;
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

  td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }

  td:focus {
    outline: 2px solid #007BFF; /* Highlight the cell on focus */
  }
}

.color-input {
  appearance: none !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  background: none !important;
  border: 0 !important;
  border-radius: 5px;
  cursor: pointer !important;
  height: 3rem !important;
  padding: 0 !important;
  margin: -2px 0 !important;
}

/* Basic editor styles */
.tiptap {
  :first-child {
    margin-top: 0;
  }

  /* List styles */
  ul,
  ol {
    padding: 0 1rem;
    margin: 1.25rem 1rem 1.25rem 0.4rem;

    li p {
      margin-top: 0.25em;
      margin-bottom: 0.25em;
    }
  }

  /* Heading styles */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
    margin-top: 2.5rem;
    text-wrap: pretty;
  }

  h1,
  h2 {
    margin-top: 3.5rem;
    margin-bottom: 1.5rem;
  }

  h1 {
    font-size: 1.4rem;
  }

  h2 {
    font-size: 1.2rem;
  }

  h3 {
    font-size: 1.1rem;
  }

  h4,
  h5,
  h6 {
    font-size: 1rem;
  }

  /* Code and preformatted text styles */
  code {
    background-color: var(--purple-light);
    border-radius: 0.4rem;
    color: var(--black);
    font-size: 0.85rem;
    padding: 0.25em 0.3em;
  }

  pre {
    background: var(--black);
    border-radius: 0.5rem;
    color: var(--white);
    font-family: 'JetBrainsMono', monospace;
    margin: 1.5rem 0;
    padding: 0.75rem 1rem;

    code {
      background: none;
      color: inherit;
      font-size: 0.8rem;
      padding: 0;
    }
  }

  blockquote {
    border-left: 3px solid #e3e3e3;
    margin: 1.5rem 0;
    padding-left: 1rem;
  }

  hr {
    border: none;
    border-top: 1px solid #e3e3e3;
    margin: 2rem 0;
  }

  /* Table-specific styling */
  table {
    border-collapse: collapse;
    margin: 0;
    overflow: hidden;
    table-layout: fixed;
    width: 100%;

    td,
    th {
      border: 1px solid #e3e3e3;
      box-sizing: border-box;
      min-width: 1em;
      padding: 6px 8px;
      position: relative;
      vertical-align: top;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      background-color: var(--gray-1);
      font-weight: bold;
      text-align: left;
    }

    .selectedCell:after {
      background: #e3e3e3;
      content: "";
      left: 0; right: 0; top: 0; bottom: 0;
      pointer-events: none;
      position: absolute;
      z-index: 2;
    }

    .column-resize-handle {
      background-color: var(--purple);
      bottom: -2px;
      pointer-events: none;
      position: absolute;
      right: -2px;
      top: 0;
      width: 4px;
    }
  }

  .tableWrapper {
    margin: 1.5rem 0;
    overflow-x: auto;
  }

  &.resize-cursor {
    cursor: ew-resize;
    cursor: col-resize;
  }
}

.editor-option-group {
  padding-right: 10px;
  margin-right: 10px;
}

.editor-option {
  cursor: pointer;
  padding: 3px 5px;
  transition: all .25s;
  &.is-active {
    color: rgb(var(--theme-color-primary));
  }

  &:hover {
    color: rgb(var(--theme-color-primary));
  }
}

.editor-container {
  border: 1px solid #ccc;
  border-radius: 10px;
  .control-group {
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }
}

.ProseMirror {
  outline: none !important;
}

.grid-selection-popover {
  max-width: unset !important;
  & .popover-body {
    width: 200px;
  }
}
</style>
<!--<script>-->
<!--import Table from '@tiptap/extension-table'-->
<!--import TableCell from '@tiptap/extension-table-cell'-->
<!--import TableHeader from '@tiptap/extension-table-header'-->
<!--import TableRow from '@tiptap/extension-table-row'-->
<!--import StarterKit from '@tiptap/starter-kit'-->
<!--import { Editor, EditorContent } from '@tiptap/vue-2'-->

<!--const CustomTableCell = TableCell.extend({-->
<!--  addAttributes() {-->
<!--    return {-->
<!--      // extend the existing attributes …-->
<!--      ...this.parent?.(),-->

<!--      // and add a new one …-->
<!--      backgroundColor: {-->
<!--        default: null,-->
<!--        parseHTML: element => element.getAttribute('data-background-color'),-->
<!--        renderHTML: attributes => ({-->
<!--          'data-background-color': attributes.backgroundColor,-->
<!--          style: `background-color: ${attributes.backgroundColor}`,-->
<!--        }),-->
<!--      },-->
<!--    }-->
<!--  },-->
<!--})-->

<!--export default {-->
<!--  components: {-->
<!--    EditorContent,-->
<!--  },-->

<!--  data() {-->
<!--    return {-->
<!--      editor: null,-->
<!--      tableHTML: '',-->
<!--    }-->
<!--  },-->

<!--  mounted() {-->
<!--    this.editor = new Editor({-->
<!--      extensions: [-->
<!--        StarterKit,-->
<!--        Table.configure({-->
<!--          resizable: true,-->
<!--        }),-->
<!--        TableRow,-->
<!--        TableHeader,-->
<!--        CustomTableCell,-->
<!--      ],-->
<!--      content: '',-->
<!--    })-->
<!--  },-->

<!--  beforeUnmount() {-->
<!--    this.editor.destroy()-->
<!--  },-->
<!--}-->
<!--</script>-->
