// Full Calendar Plugins
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import i18n from '@/libs/i18n'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// eslint-disable-next-line object-curly-newline
import { ref, computed, onMounted } from '@vue/composition-api'
import store from '@/store'
import { GET_GLOBAL_TASKS, OPEN_MODAL } from '@/@constants/mutations'
// import { formatDate } from '@fullcalendar/core'
// import { BPopover } from 'bootstrap-vue'
let dontLoadEvents = true

const openEventModal = async (type, id) => {
  let data = null
  if (type === 'task') data = await store.dispatch(`globalTasks/${GET_GLOBAL_TASKS}`, { filters: { id } })

  if (!(Array.isArray(data?.data?.items) && data?.data?.items.length)) return

  store.commit(`modal/${OPEN_MODAL}`,
    {
      modalType: 'viewModal',
      data: data.data.items[0],
    })
}

// const openListModal = info => {
//   store.commit(`modal/${OPEN_MODAL}`,
//     {
//       modalType: 'calendarModal',
//       modalTitle: formatDate(info.date),
//       data: info.allSegs,
//     })
// }

export default function userCalendar() {
  // Use toast
  const toast = useToast()
  // ------------------------------------------------
  // refCalendar
  // ------------------------------------------------
  const refCalendar = ref(null)

  // ------------------------------------------------
  // calendarApi
  // ------------------------------------------------
  let calendarApi = null
  onMounted(() => {
    calendarApi = refCalendar.value.getApi()

    dontLoadEvents = false
  })

  // ------------------------------------------------
  // calendars
  // ------------------------------------------------
  const calendarsColor = {
    task: 'primary',
    // Business: 'primary',
    // Personal: 'danger',
    // Family: 'warning',
    // ETC: 'info',
  }

  // ------------------------------------------------
  // event
  // ------------------------------------------------
  const blankEvent = {
    title: '',
    start: '',
    end: '',
    allDay: false,
    url: '',
    extendedProps: {
      calendar: '',
      guests: [],
      location: '',
      description: '',
    },
  }
  const event = ref(JSON.parse(JSON.stringify(blankEvent)))
  const clearEventData = () => {
    event.value = JSON.parse(JSON.stringify(blankEvent))
  }

  // *===========================================================================---*
  // *--------- Calendar API Function/Utils --------------------------------------------*
  // Template Future Update: We might move this utils function in its own file
  // *===========================================================================---*

  // ------------------------------------------------
  // (UI) addEventInCalendar
  // ? This is useless because this just add event in gloablCalendar and not in our data
  // * If we try to call it on new event then callback & try to toggle from gloablCalendar we get two events => One from UI and one from data
  // ------------------------------------------------
  // const addEventInCalendar = eventData => {
  //   toast({
  //     component: ToastificationContent,
  //     position: 'bottom-right',
  //     props: {
  //       title: 'Event Added',
  //       icon: 'CheckIcon',
  //       variant: 'success',
  //     },
  //   })
  //   calendarApi.addEvent(eventData)
  // }

  // ------------------------------------------------
  // (UI) updateEventInCalendar
  // ------------------------------------------------
  const updateEventInCalendar = (updatedEventData, propsToUpdate, extendedPropsToUpdate) => {
    toast({
      component: ToastificationContent,
      props: {
        title: 'Event Updated',
        icon: 'CheckIcon',
        variant: 'success',
      },
    })

    const existingEvent = calendarApi.getEventById(updatedEventData.id)

    // --- Set event properties except date related ----- //
    // ? Docs: https://fullcalendar.io/docs/Event-setProp
    // dateRelatedProps => ['start', 'end', 'allDay']
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < propsToUpdate.length; index++) {
      const propName = propsToUpdate[index]
      existingEvent.setProp(propName, updatedEventData[propName])
    }

    // --- Set date related props ----- //
    // ? Docs: https://fullcalendar.io/docs/Event-setDates
    existingEvent.setDates(updatedEventData.start, updatedEventData.end, { allDay: updatedEventData.allDay })

    // --- Set event's extendedProps ----- //
    // ? Docs: https://fullcalendar.io/docs/Event-setExtendedProp
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < extendedPropsToUpdate.length; index++) {
      const propName = extendedPropsToUpdate[index]
      existingEvent.setExtendedProp(propName, updatedEventData.extendedProps[propName])
    }
  }

  // ------------------------------------------------
  // (UI) removeEventInCalendar
  // ------------------------------------------------
  const removeEventInCalendar = eventId => {
    toast({
      component: ToastificationContent,
      props: {
        title: 'Event Removed',
        icon: 'TrashIcon',
        variant: 'danger',
      },
    })
    calendarApi.getEventById(eventId).remove()
  }

  // ------------------------------------------------
  // grabEventDataFromEventApi
  // ? It will return just event data from fullCalendar's EventApi which is not required for event mutations and other tasks
  // ! You need to update below function as per your extendedProps
  // ------------------------------------------------
  const grabEventDataFromEventApi = eventApi => {
    const {
      id,
      title,
      start,
      end,
      // eslint-disable-next-line object-curly-newline
      extendedProps: { calendar, guests, location, description },
      allDay,
    } = eventApi

    return {
      id,
      title,
      start,
      end,
      extendedProps: {
        calendar,
        guests,
        location,
        description,
      },
      allDay,
    }
  }

  // ------------------------------------------------
  // addEvent
  // ------------------------------------------------
  const addEvent = eventData => {
    store.dispatch('calendar/addEvent', { event: eventData }).then(() => {
      // eslint-disable-next-line no-use-before-define
      refetchEvents()
    })
  }

  // ------------------------------------------------
  // updateEvent
  // ------------------------------------------------
  const updateEvent = eventData => {
    store.dispatch('calendar/updateEvent', { event: eventData }).then(response => {
      const updatedEvent = response.data.event

      const propsToUpdate = ['id', 'title', 'url']
      const extendedPropsToUpdate = ['calendar', 'guests', 'location', 'description']

      updateEventInCalendar(updatedEvent, propsToUpdate, extendedPropsToUpdate)
    })
  }

  // ------------------------------------------------
  // removeEvent
  // ------------------------------------------------
  const removeEvent = () => {
    const eventId = event.value.id
    store.dispatch('calendar/removeEvent', { id: eventId }).then(() => {
      removeEventInCalendar(eventId)
    })
  }

  // ------------------------------------------------
  // refetchEvents
  // ------------------------------------------------
  const refetchEvents = () => {
    calendarApi.refetchEvents()
  }

  // ------------------------------------------------
  // selectedCalendars
  // ------------------------------------------------
  // const selectedCalendars = computed(() => store.state.gloablCalendar.selectedCalendars)
  //
  // watch(selectedCalendars, () => {
  //   refetchEvents()
  // })

  // --------------------------------------------------------------------------------------------------
  // AXIOS: fetchEvents
  // * This will be called by fullCalendar to fetch events. Also this can be used to refetch events.
  // --------------------------------------------------------------------------------------------------
  // eslint-disable-next-line consistent-return
  const fetchEvents = (info, successCallback) => {
    // If there's no info => Don't make useless API call
    if (!info || dontLoadEvents) return successCallback([])
    // Fetch Events from API endpoint
    store
      .dispatch('globalCalendar/GET_CALENDAR_TASKS', { info })
      .then(response => {
        successCallback(response)
      })
      .catch(err => {
        if (err.message !== 'Operation canceled due to new request.') {
          toast({
            component: ToastificationContent,
            props: {
              title: i18n.t('errors.SOMETHING_WRONG'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }

  // ------------------------------------------------------------------------
  // calendarOptions
  // * This isn't considered in UI because this is the core of gloablCalendar app
  // ------------------------------------------------------------------------
  const calendarOptions = ref({
    plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
    initialView: 'dayGridMonth',
    headerToolbar: {
      start: 'prev next title',
      end: 'today dayGridMonth,day,listMonth',
    },
    views: {
      dayGridMonth: {
        selectable: true,
      },
      day: {
        type: 'timeGrid',
        slotEventOverlap: false,
        slotDuration: '01:00:00',
        selectable: true,
      },
      listMonth: {
        listDayFormat: {
          month: 'long',
          year: 'numeric',
          day: 'numeric',
          weekday: 'long',
        },
        listDaySideFormat: false,
      },
    },
    events: fetchEvents,

    select: e => {
      store.commit(`modal/${OPEN_MODAL}`, {
        modalType: 'task',
        size: 'lg',
        modalTitle: i18n.t('AddTask'),
        okButtonTitle: i18n.t('AddTask'),
        data: { event: e },
      })
    },

    contentHeight: 300,

    displayEventTime: true,
    displayEventEnd: true,
    /*
      Enable dragging and resizing event
      ? Docs: https://fullcalendar.io/docs/editable
    */
    editable: false,

    /*
      Enable resizing event from start
      ? Docs: https://fullcalendar.io/docs/eventResizableFromStart
    */
    eventResizableFromStart: true,

    /*
      Automatically scroll the scroll-containers during event drag-and-drop and date selecting
      ? Docs: https://fullcalendar.io/docs/dragScroll
    */
    dragScroll: true,

    /*
      Max number of events within a given day
      ? Docs: https://fullcalendar.io/docs/dayMaxEvents
    */
    dayMaxEvents: 6,

    // moreLinkClick: info => openListModal(info),
    // moreLinkClick: info => {
    //   const popover = new BPopover({
    //     propsData: {
    //       title: info.view.title,
    //       html: true,
    //       triggers: ['manual', 'click'],
    //       placement: 'top',
    //       target: info.jsEvent.target,
    //       container: 'body',
    //       boundary: 'viewport',
    //     },
    //   })
    //
    //   const popoverTitle = h('div', { class: 'd-flex align-items-center justify-content-between' },
    //     [
    //       h('p', info.view.title),
    //       h('b-button', {
    //         props: { variant: 'danger', size: 'sm' },
    //         class: 'btn-icon p-25',
    //         on: { click: () => { popover.show = false } },
    //       },
    //       [h('sw-icon', { props: { icon: 'XIcon' } })]),
    //     ])
    //
    //   const popoverContent = h('b-list-group',
    //     { props: { flush: true } },
    //     [
    //       info.hiddenSegs.map(date => h('b-list-group-item',
    //         { class: 'px-75 py-25 text-sm' },
    //         [date.event.title])),
    //     ])
    //
    //   popover.title = popoverTitle
    //   popover.content = popoverContent
    //
    //   // popover.$on('hook:mounted', () => {
    //   //   console.log('SHOW')
    //   //   popover.show = true
    //   // })
    //   popover.$mount()
    //
    //   // popover.$on('hook:mounted', () => {
    //   //   popover.show()
    //   // })
    //
    //   return false
    // },

    /*
      Determines if day names and week names are clickable
      ? Docs: https://fullcalendar.io/docs/navLinks
    */
    navLinks: true,

    eventClassNames({ event: calendarEvent }) {
      // eslint-disable-next-line no-underscore-dangle
      const colorName = calendarsColor[calendarEvent._def.extendedProps.calendar]

      return [
        // Background Color
        `bg-${colorName}`,
      ]
    },
    // eventMouseEnter({ event: hoveredEvent }) {
    //   console.log(event, hoveredEvent)
    // },
    eventClick({ event: clickedEvent }) {
      // * Only grab required field otherwise it goes in infinity loop
      // ! Always grab all fields rendered by form (even if it get `undefined`) otherwise due to Vue3/Composition API you might get: "object is not extensible"
      event.value = grabEventDataFromEventApi(clickedEvent)
      openEventModal(event.value.extendedProps.calendar, event.value.id)
      // eslint-disable-next-line no-use-before-define
      isEventHandlerSidebarActive.value = true
    },

    customButtons: {
      sidebarToggle: {
        // --- This dummy text actual icon rendering is handled using SCSS ----- //
        text: 'sidebar',
        click() {
          // eslint-disable-next-line no-use-before-define
          isCalendarOverlaySidebarActive.value = !isCalendarOverlaySidebarActive.value
        },
      },
    },

    dateClick(info) {
      /*
        ! Vue3 Change
        Using Vue.set isn't working for now so we will try to check reactivity in Vue 3 as it can handle this automatically
        ```
        event.value.start = info.date
        ```
      */
      event.value = JSON.parse(JSON.stringify(Object.assign(event.value, { start: info.date })))
      // eslint-disable-next-line no-use-before-define
      isEventHandlerSidebarActive.value = true
    },

    /*
      Handle event drop (Also include dragged event)
      ? Docs: https://fullcalendar.io/docs/eventDrop
      ? We can use `eventDragStop` but it doesn't return updated event so we have to use `eventDrop` which returns updated event
    */
    eventDrop({ event: droppedEvent }) {
      updateEvent(grabEventDataFromEventApi(droppedEvent))
    },

    /*
      Handle event resize
      ? Docs: https://fullcalendar.io/docs/eventResize
    */
    eventResize({ event: resizedEvent }) {
      updateEvent(grabEventDataFromEventApi(resizedEvent))
    },

    // Get direction from app state (store)
    direction: computed(() => (store.state.appConfig.isRTL ? 'rtl' : 'ltr')),
    rerenderDelay: 350,
  })

  // ------------------------------------------------------------------------

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const isEventHandlerSidebarActive = ref(false)

  const isCalendarOverlaySidebarActive = ref(false)

  return {
    refCalendar,
    isCalendarOverlaySidebarActive,
    calendarOptions,
    event,
    clearEventData,
    addEvent,
    updateEvent,
    removeEvent,
    refetchEvents,
    fetchEvents,

    // ----- UI ----- //
    isEventHandlerSidebarActive,
  }
}
