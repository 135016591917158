import { TEST_REGON } from '@/@constants/mutations'

export default {
  namespaced: true,
  actions: {
    // eslint-disable-next-line no-unused-vars
    [TEST_REGON]: () => new Promise((resolve, reject) => {
    }),
  },
}
