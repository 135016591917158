<template>
  <app-collapse
    class="pb-50 rounded overflow-hidden tree-container"
    :class="{ 'border': deepLevel === 0 }"
  >
    <app-collapse-item
      title="test"
      is-visible
      class="pl-50 pr-0 tree-item"
      arrow-left
    >
      <slot
        v-if="showRoot"
        name="subitems"
        :deep-level="deepLevel"
        :item="rootItem"
      />

      <template #title>
        <slot
          name="root-title"
          :deep-level="deepLevel"
          :item="rootItem"
        >
          {{ rootItem }}
        </slot>
      </template>

      <!-- Substructure Items -->
      <sw-structure
        v-if="showChilds && rootItem[childKey] && maxDeepLevel !== deepLevel"
        :child-key="childKey"
        :root-item="rootItem[childKey]"
        :deep-level="deepLevel + 1"
        :max-deep-level="maxDeepLevel"
      >
        <template #root-title="row">
          <slot
            name="root-title"
            :deep-level="row.deepLevel"
            :item="row.item"
          />
        </template>

        <template #subitems="row">
          <slot
            name="subitems"
            :deep-level="row.deepLevel"
            :item="row.item"
          />
        </template>
      </sw-structure>
    </app-collapse-item>
  </app-collapse>
</template>

<script>
/* eslint-disable */

export default {
  name: 'Structure',
  props: {
    rootItem: {
      required: true,
      default: null,
      type: Object,
    },
    childKey: {
      required: true,
      default: 'user',
      type: String,
    },
    maxDeepLevel: {
      default: null,
      required: false,
      type: Number,
    },
    deepLevel: {
      required: false,
      default: 0,
      type: Number,
    },
    showRoot: {
      required: false,
      default: true,
      type: Boolean,
    },
    showChilds: {
      required: false,
      default: true,
      type: Boolean,
    },
  },
}
</script>

<style scoped>

</style>
