<template>
  <b-card v-if="selectedType && selectedType.key === 'contactThreadTask'" class="border">

    <!-- Task Filters -->
    <b-row v-if="selectedType.key === 'contactThreadTask'">
      <b-col
        md="12"
        class="mb-75"
      >
        <label for="">{{ $t('task.Status') }}</label>
        <div>
          <b-button-group
            class="flex-wrap"
            size="sm"
          >
            <b-button
              :variant="filters.contactThreadTask.status === '' ? 'primary' : 'flat-primary'"
              @click="$emit('assign-filters', { field: 'status', key: 'contactThreadTask', value: '' })"
            >
              {{ $t('All') }}
            </b-button>
              <b-button
              :variant="filters.contactThreadTask.status === 'O' ? 'primary' : 'flat-primary'"
              @click="$emit('assign-filters', { field: 'status', key: 'contactThreadTask', value: 'O' })"
            >
              {{ $t('task.status.O') }}
            </b-button>
            <b-button
              :variant="filters.contactThreadTask.status === 'D' ? 'primary' : 'flat-primary'"
              @click="$emit('assign-filters', { field: 'status', key: 'contactThreadTask', value: 'D' })"
            >
              {{ $t('task.status.D') }}
            </b-button>
            <b-button
              :variant="filters.contactThreadTask.status === 'ND' ? 'primary' : 'flat-primary'"
              @click="$emit('assign-filters', { field: 'status', key: 'contactThreadTask', value: 'ND' })"
            >
              {{ $t('task.status.ND') }}
            </b-button>
          </b-button-group>
        </div>
      </b-col>
      <b-col
        md="12"
        class="mb-75"
      >
        <label for="">{{ $t('TaskTypes') }}</label>

        <b-form-radio-group
          :checked="filters.contactThreadTask.type"
          :options="taskTypes"
          value-field="id"
          text-field="name"
          disabled-field="disabled"
          @change="$emit('assign-filters', { field: 'type', key: 'contactThreadTask', value: $event })"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BButtonGroup, BFormRadioGroup } from 'bootstrap-vue'
import { GET_TASKS } from '@/@constants/mutations'

export default {
  name: 'TimelineAddonFilters',
  components: { BButtonGroup, BFormRadioGroup },
  props: {
    filters: {
      default: {},
      required: true,
    },
    selectedType: {
      default: null,
      required: false,
    },
  },
  emits: ['assign-filters'],
  data: () => ({
    taskTypes: [],
  }),
  mounted() {
    this.loadTaskTypes()
  },
  methods: {
    async loadTaskTypes() {
      const res = await this.$store.dispatch(`typeSettings/${GET_TASKS}`)

      this.taskTypes = [
        {
          id: -1,
          name: this.$i18n.t('All'),
        },
        ...res,
      ]
    },
  },
}
</script>

<style scoped>

</style>
