var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('sw-filters-modern',{staticClass:"mb-50",on:{"change":_vm.resolveFilters,"refresh":_vm.reloadTasks},model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}}),_c(_vm.tag,{tag:"component"},[_c('sw-table',{attrs:{"table-id":"d746341b96a74f7abdd17304dd448c9e","pagination":_vm.pagination,"show-columns":"","fields":_vm.fields},on:{"set-fields":function($event){_vm.fields = $event},"change-pagination":function($event){return Object.assign(_vm.pagination, $event)},"reload-content":_vm.reloadTasks},scopedSlots:_vm._u([{key:"table",fn:function(){return [_c('b-table',{staticClass:"mb-0",attrs:{"striped":"","responsive":"","hover":"","items":_vm.tasks || [],"fields":_vm.fields.filter(function (e) { return e.visible; }),"per-page":_vm.pagination.perPage,"current-page":_vm.pagination.currentPage,"busy":_vm.loading,"show-empty":"","sort-by":_vm.sorter.sortBy,"sort-desc":_vm.sorter.sortDesc,"no-local-sorting":true},on:{"update:sortBy":function($event){return _vm.$set(_vm.sorter, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.sorter, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.sorter, "sortDesc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.sorter, "sortDesc", $event)},"row-clicked":_vm.openViewModal,"sort-changed":function($event){Object.assign(_vm.sorter, $event); _vm.reloadTasks()}},scopedSlots:_vm._u([{key:"head()",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(("" + label)))+" ")]}},{key:"cell(id)",fn:function(ref){
var index = ref.index;
return [_vm._v(" #"+_vm._s(_vm.getOrderNo(index, _vm.pagination, _vm.sorter))+" ")]}},{key:"cell(contactThreadTaskType)",fn:function(data){return [_c('b-badge',{staticStyle:{"padding":"6px 10px"},attrs:{"id":("type_" + (data.index)),"variant":"light-primary"}},[_vm._v(" "+_vm._s(data.value.name)+" "),(data.item.address)?_c('feather-icon',{attrs:{"icon":"NavigationIcon"}}):_vm._e()],1),(data.item.address)?_c('b-tooltip',{attrs:{"target":("type_" + (data.index)),"placement":"top","variant":"dark"}},[_vm._v(" "+_vm._s(data.item.address)+" ")]):_vm._e()]}},{key:"cell(status)",fn:function(data){return [_c('b-badge',{staticStyle:{"padding":"6px 10px"},attrs:{"id":("status_" + (data.index)),"variant":_vm.taskTypeVariant(data.value)}},[_vm._v(" "+_vm._s(_vm.$t(("task.status." + (data.value))))+" ")])]}},{key:"cell(content)",fn:function(data){return [_c('span',{domProps:{"innerHTML":_vm._f("truncate")(data.value,40, '...')}})]}},{key:"cell(priority)",fn:function(ref){
var value = ref.value;
return [(value)?_c('span',[_c('sw-icon',{staticClass:"mr-25",class:[("text-" + (_vm.taskPriority.taskPriorityByValue(value).color))],attrs:{"icon":"FlagIcon"}}),_vm._v(" "+_vm._s(_vm.$t(_vm.taskPriority.taskPriorityByValue(value).label))+" ")],1):_c('feather-icon',{attrs:{"icon":"MinusIcon"}})]}},{key:"cell(files)",fn:function(data){return [(data.value.length)?_c('documents-list',{attrs:{"documents":data.value,"index":data.index}}):_c('feather-icon',{attrs:{"icon":"MinusIcon"}})]}},{key:"cell(sendReminderEmail)",fn:function(ref){
var value = ref.value;
return [_c('feather-icon',{class:value ? 'text-success' : 'text-danger',attrs:{"size":"20","icon":value ? 'CheckSquareIcon' : 'XSquareIcon'}})]}},{key:"cell(sendReminderSms)",fn:function(ref){
var value = ref.value;
return [_c('feather-icon',{class:value ? 'text-success' : 'text-danger',attrs:{"size":"20","icon":value ? 'CheckSquareIcon' : 'XSquareIcon'}})]}},{key:"cell(assignedUser)",fn:function(ref){
var value = ref.value;
return [_c('avatar',{attrs:{"user":value,"to":{ name: 'user', params: { userId: value.id } }}})]}},{key:"cell(createdBy)",fn:function(ref){
var value = ref.value;
return [_c('avatar',{attrs:{"user":value,"to":{ name: 'user', params: { userId: value.id } }}})]}},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[_c('b-spinner',{staticClass:"align-middle",attrs:{"variant":"primary"}})],1)]},proxy:true},{key:"cell(action)",fn:function(data){return [_c('div',{staticClass:"text-center",attrs:{"id":data.id}},[_c('b-dropdown',{staticClass:"dropdown-icon-wrapper",attrs:{"id":"dropdown-grouped","variant":"flat-primary"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"align-middle",attrs:{"icon":"MoreVerticalIcon","size":"20"}})]},proxy:true}],null,true)},[(data.item && data.item.status && data.item.status === 'O')?_c('div',[_c('b-dropdown-item',{on:{"click":function($event){return _vm.changeTaskStatus(data.item.id, 'D', data.index, data.item)}}},[_c('dropdown-item-label',{attrs:{"icon":"CheckIcon","label":_vm.$t('task.status.D')}})],1),_c('b-dropdown-item',{on:{"click":function($event){return _vm.changeTaskStatus(data.item.id, 'ND', data.index, data.item)}}},[_c('dropdown-item-label',{attrs:{"icon":"XIcon","label":_vm.$t('task.status.ND')}})],1),_c('b-dropdown-divider'),(_vm.checkRequiredPermissions([
                    _vm.$roles.CONTACT_THREAD_TASK_EDIT,
                    { role: _vm.$roles.CONTACT_THREAD_TASK_EDIT_SELF, data: data.item, field: 'createdBy', userId: _vm.currentUser.id }
                  ]) && _vm.isTimeLimitReached(data.item.createdAt.date, null, 'contactThreadTaskDeleteTime'))?_c('b-dropdown-item',{on:{"click":function($event){return _vm.editTask(data.item)}}},[_c('dropdown-item-label',{attrs:{"icon":"EditIcon","label":_vm.$t('Edit')}})],1):_vm._e(),(_vm.checkRequiredPermissions([
                    _vm.roles.CONTACT_THREAD_TASK_DELETE ]))?_c('b-dropdown-item',{on:{"click":function($event){return _vm.deleteTask(data.item.id, data.index)}}},[_c('dropdown-item-label',{attrs:{"icon":"TrashIcon","label":_vm.$t('Delete')}})],1):_vm._e()],1):_c('div',[_c('b-dropdown-item',{on:{"click":function($event){return _vm.editTask(data.item, true)}}},[_c('dropdown-item-label',{attrs:{"icon":"RepeatIcon","label":_vm.$t('RepeatTask')}})],1),(data.item.contactThreadTaskType.isReportRequired)?_c('b-dropdown-item',{on:{"click":function($event){_vm.openModal('viewModal', _vm.$t('PreviewReport'), '', data.item.contactThreadTaskReport)}}},[_c('dropdown-item-label',{attrs:{"icon":"ArchiveIcon","label":_vm.$t('ShowReport')}})],1):_vm._e()],1)])],1)]}},{key:"cell()",fn:function(row){return [_c('table-default-cell',{attrs:{"field":row.field,"value":row.value}})]}},{key:"empty",fn:function(){return [_c('empty-content')]},proxy:true}])})]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }