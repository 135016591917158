<template>
  <b-card-text>
    <b-row>
      <!--    Type    -->
      <b-col
        sm="12"
        md="12"
        lg="12"
      >
        <sw-select :name="$t('task.Type')">
          <v-select
            v-model.trim="newOption.type"
            :clearable="false"
            :options="attributeTypes"
            :placeholder="$t('offer.attribute.type')"
            :clear-search-on-blur="() => { return false }"
            :filter="typeSearch"
          >
            <template #option="option">
              {{ $t(`offer.attribute.types.${option.label}`) }}
            </template>
            <template #selected-option="option">
              {{ $t(`offer.attribute.types.${option.label}`) }}
            </template>
            <template #no-options="{ search }">
              <span v-if="search.length">
                {{ $t('NoData') }}
              </span>
              <span v-else>
                {{ $t('TypeToSearch') }}
              </span>
            </template>
          </v-select>
        </sw-select>
      </b-col>

      <!--    Name    -->
      <b-col
        sm="12"
        md="12"
        lg="12"
      >
        <b-form-group :label="$t('Name')">
          <b-form-input
            v-model.trim="newOption.translations[0].name"
            :placeholder="$t('Name')"
          />
        </b-form-group>
      </b-col>
      <!--    Value: Options    -->
      <b-col
        v-if="newOption.type === 'TEXT_ARRAY' || newOption.type === 'TEXT_MULTIPLE_ARRAY' || newOption.type === 'RADIAL'"
        sm="12"
        md="12"
        lg="12"
      >
        <b-form-group :label="$t('Value')">
          <!-- Default Value -->
          <!--                <b-form-input-->
          <!--                  v-if="!['TEXT_ARRAY'].includes(newOption.type)"-->
          <!--                  v-model.trim="newOption.pollFieldOptions"-->
          <!--                  :placeholder="$t('Value')"-->
          <!--                  :state="errors.length > 0 ? false:null"-->
          <!--                  :disabled="!['TEXT_ARRAY'].includes(newOption.type)"-->
          <!--                />-->
          <!-- Tags List -->
          <b-form-tags
            v-model="newOption.pollFieldOptions"
            no-outer-focus
            :placeholder="$t('TagsPlaceholder')"
          >
            <template
              #default="{
                inputAttrs,
                inputHandlers,
                removeTag
              }"
            >
              <b-input-group aria-controls="my-custom-tags-list">
                <input
                  v-bind="inputAttrs"
                  :placeholder="$t('NewValue')"
                  class="form-control"
                  v-on="inputHandlers"
                >
                <b-input-group-append>
                  <b-button
                    variant="primary"
                    :disabled="!inputAttrs.value"
                    @click="addItem(inputAttrs.value); inputAttrs.value = ''"
                  >
                    {{ $t('Add') }}
                  </b-button>
                </b-input-group-append>
              </b-input-group>
              <div
                id="my-custom-tags-list"
                class="d-flex flex-wrap mb-0"
              >
                <!-- Always use the tag value as the :key, not the index! -->
                <!-- Otherwise screen readers will not read the tag
                       additions and removals correctly -->
                <div
                  v-if="newOption.pollFieldOptions && newOption.pollFieldOptions.length"
                  class="d-flex flex-wrap mb-0"
                >
                  <b-card
                    v-for="(tag, index) in newOption.pollFieldOptions"
                    :id="`my-custom-tags-tag_${index}_`"
                    :key="typeof tag === 'string' ? JSON.parse(tag).translations[0].name : tag.translations[0].name"
                    tag="li"
                    class="shadow-none border mt-1 mr-1 mb-0 flex-grow-1"
                    body-class="py-1 pr-2"
                  >
                    <strong>{{
                      typeof tag === 'string' ? JSON.parse(tag).translations[0].name : tag.translations[0].name
                    }}</strong>
                    <b-button
                      variant="link"
                      size="sm"
                      :aria-controls="`my-custom-tags-tag_${index}_`"
                      class="py-0"
                      @click="removeTag(tag)"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </b-card>
                </div>
              </div>
            </template>
          </b-form-tags>
        </b-form-group>
      </b-col>
      <!--    Name    -->
      <b-col
        sm="12"
        md="12"
        lg="12"
      >
        <b-form-group :label="$t('task.Description')">
          <b-form-input
            v-model.trim="newOption.translations[0].description"
            :placeholder="$t('task.Description')"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <div class="d-flex justify-content-end pt-1 mt-1 border-top">
      <b-button
        variant="primary"
        size="sm"
        :disabled="checkRequiredFields"
        @click="saveOption"
      >
        {{ $t('Confirm') }}
      </b-button>
    </div>
  </b-card-text>
</template>

<script>
import {
  BCardText, BFormTags, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import { required } from '@validations'
import { pollTypes } from '@/helpers/offer/attributeTypes'
import vSelect from 'vue-select'

export default {
  components: {
    BCardText,
    vSelect,
    BFormTags,
    BInputGroup,
    BInputGroupAppend,
  },
  props: {
    oldPollOption: {
      type: Object,
      required: false,
    },
  },
  data: () => ({
    newOption: {
      type: '',
      translations: [
        { name: '', locale: 'pl_pl', description: '' },
      ],
      pollFieldOptions: [],
    },
    required,

    attributeTypes: (pollTypes)(),
  }),
  computed: {
    checkRequiredFields() {
      return (
        !this.newOption.translations[0]?.name || !this.newOption.type || this.checkRequiredFieldsByType
      )
    },
    checkRequiredFieldsByType() {
      if (['TEXT_ARRAY', 'TEXT_MULTIPLE_ARRAY', 'RADIAL'].includes(this.newOption.type)) {
        return !this.newOption.pollFieldOptions.length
      }
      return false
    },
  },
  mounted() {
    if (this.oldPollOption && Object.keys(this.oldPollOption).length) {
      this.newOption = this.oldPollOption
    }
  },
  methods: {
    addItem(item) {
      this.newOption.pollFieldOptions.push({
        translations: [
          {
            name: item,
            locale: 'pl_pl',
          },
        ],
      })
    },
    saveOption() {
      this.$emit('saveOption', this.newOption)
      this.$emit('closeModal')
    },
    typeSearch(options, search = '') {
      const items = options.filter(option => this.$i18n.t(`offer.attribute.types.${option}`).toLowerCase().includes(search.toLowerCase()))
      return items || []
    },
  },
}
</script>
