<template>
  <div>
    <app-collapse v-if="checkRequiredPermissions([$roles.SHOP_PRODUCT_VIEW_ALL, $roles.SHOP_PRODUCT_VIEW_GROUP])"
                  class="mb-1"
    >
      <app-collapse-item>
        <template #title>
          <feather-icon icon="ShoppingCartIcon" />
          {{ $t('ProductPointTable') }}
        </template>

        <sw-select :name="$t('SelectLoyaltyPlan')">
          <v-select
            v-model="selectedPlan"
            :options="items"
            label="name"
            :reduce="plan => plan.id"
            @input="loadProducts"
          >
            <template #no-options>
              {{ $t('NoOptions') }}
            </template>
          </v-select>
        </sw-select>

        <sw-table
          table-id="fc79a17638d24dfcaf6773d0bbbc337d"
          :show-columns="false"
          show-search
          :pagination="pagination"
          @change-pagination="Object.assign(pagination, $event)"
          @reload-content="loadProducts"
          @change-search-query="search = $event; loadProducts"
        >
          <template #table>
            <!--      Table      -->
            <b-table
              :items="products"
              :fields="fields"
              striped
              responsive
              :busy="isTableLoading"
              :style="{ minHeight: tableSize(items, 50) }"
              show-empty
            >
              <!--    Head    -->
              <template #head()="{ label }">
                {{ $t(`${label}`) }}
              </template>

              <template #cell(name)="{ item }">
                <div v-if="item.translations && item.translations.length">
                  <router-link
                    v-b-tooltip.hover.left
                    :to="{ name: 'productView', params: { productId: item.id } }"

                    :title="selectedTranslationObject(item).name || '-'"
                  >
                    <span class="doubleLineText">
                      {{ (selectedTranslationObject(item).name || '-') | truncate(60, '...') }}
                    </span>
                  </router-link>
                </div>
                <feather-icon
                  v-else
                  icon="MinusIcon"
                />
              </template>

              <template #cell(reward)="{ item }">
                <b-badge v-if="item.shopProductLoyaltyPlans && item.shopProductLoyaltyPlans.length"
                         class="font-small-2"
                         style="width: 100px"
                         variant="light-primary"
                >
                  {{ item.shopProductLoyaltyPlans[0].pointsReward }}
                </b-badge>

                <feather-icon
                  v-else
                  icon="MinusIcon"
                />
              </template>

              <template #cell(price)="{ item }">
                <b-badge v-if="item.shopProductLoyaltyPlans && item.shopProductLoyaltyPlans.length"
                         class="font-small-2"
                         style="width: 100px"
                         variant="light-primary"
                >
                  {{ item.shopProductLoyaltyPlans[0].pointsPrice }}
                </b-badge>

                <feather-icon
                  v-else
                  icon="MinusIcon"
                />
              </template>

              <!--    Other    -->
              <!--    Other    -->
              <template #cell()="row">
                <table-default-cell
                  :field="row.field"
                  :value="row.value"
                />
              </template>

              <!--      Empty      -->
              <template #empty>
                <empty-content />
              </template>

              <!--      Table Busy      -->
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner
                    class="align-middle"
                    variant="primary"
                  />
                </div>
              </template>
            </b-table>
          </template>
        </sw-table>
      </app-collapse-item>
    </app-collapse>

    <b-alert
      variant="primary"
      show
      class="d-flex align-items-center"
    >
      <div class="alert-body">
        <feather-icon
          class="mr-25"
          icon="InfoIcon"
        />

        <span class="ml-25">
          {{ $t('HereWouWillCreateLoyaltyPlan') }}
        </span>
      </div>
    </b-alert>

    <div class="my-50">
      <b-button
        v-if="checkRequiredPermissions([$roles.LOYALTY_PLAN_MODIFY])"
        variant="primary"
        size="sm"
        :to="{ name: 'newLoyaltyPlan' }"
      >
        <feather-icon icon="PlusIcon" />
        {{ $t('Add') }}
      </b-button>
    </div>

    <list
      :is-loading="isLoading"
      :items="items"
    />
  </div>
</template>

<script>
import axiosIns from '@/libs/axios'
import { BTable, VBTooltip } from 'bootstrap-vue'
import { formatDate } from '@core/utils/filter'
import vSelect from 'vue-select'
import list from './components/list/List.vue'

export default {
  components: {
    vSelect,
    BTable,
    list,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data: () => ({
    isTableLoading: false,
    isLoading: false,
    items: [],

    pagination: {
      perPage: 25,
      currentPage: 1,
      totalRecords: 1,
    },
    search: '',
    selectedPlan: '',

    fields: [
      { key: 'name', label: 'Name', visible: true },
      { key: 'reward', label: 'ProductPointTableRewardPoints', visible: true },
      { key: 'price', label: 'ProductPointTablePricePoints', visible: true },
    ],

    products: [],
  }),
  async mounted() {
    await this.init()
  },
  methods: {
    formatDate,
    async init() {
      this.isLoading = true

      try {
        const resp = await axiosIns.get('1/loyaltyPlans', { params: { fields_load: this.$fields.LOYALTY_PLANS } })

        this.items = resp?.data?.data?.items || []

        if ((window.location.origin.includes('hydropure') || window.location.origin.includes('192.')) && this.items.length) {
          const hydropureProductInPlan = this.items.find(e => e.name.includes('Plan punktowy')) || this.items[0]
          this.selectedPlan = this.getObjectId(hydropureProductInPlan)
        }

        if (this.checkRequiredPermissions([this.$roles.SHOP_PRODUCT_VIEW_ALL, this.$roles.SHOP_PRODUCT_VIEW_GROUP])) {
          await this.loadProducts()
        }
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.isLoading = false
      }
    },
    selectedTranslationObject(listProduct) {
      const selectedLocale = 'pl_pl'

      let localeItem = listProduct.translations.find(({ locale }) => locale === selectedLocale) || { name: '-', description: '' }

      if (!localeItem && listProduct.translations?.length) {
        [localeItem] = listProduct.translations
      }

      return localeItem
    },
    async loadProducts() {
      this.isTableLoading = true
      try {
        const params = {
          fields_load: 'PRODUCT_POINT_TABLE',
          orderBy: 'id.desc',
          page: this.pagination.currentPage,
          limit: this.pagination.perPage,
        }

        if (this.selectedPlan) {
          params['eq_shopProductLoyaltyPlans-loyaltyPlan-id'] = this.getObjectId(this.selectedPlan)
        }

        if (this.search && this.search.length) {
          params.search = `%${this.search}%`
          params.search_fields = 'translations-name'
        }

        const response = await axiosIns.get('1/shops/products', { params })
        this.products = response.data.data?.items || []
        this.pagination.totalRecords = response.data.data?.totalItemCount
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.isTableLoading = false
      }
    },
  },
}
</script>
