<template>
  <div>
    <div
      v-if="!loading"
      class="pt-1"
    >
      <b-overlay
        no-wrap
        :show="loading"
        spinner-variant="primary"
      />

      <b-form
        ref="form"
        class="repeater-form mb-1"
      >
        <!-- Row Loop -->
        <!--          <draggable-->
        <!--            v-model="reasons"-->
        <!--            class="px-50 pb-50"-->
        <!--          >-->
        <b-tabs v-model="tab">
          <b-tab>
            <template #title>
              {{ $t('CloseReason') }} ({{ $t('funnel.status.Success') }})
            </template>

            <div v-if="positive.length">
              <draggable v-model="positive"
                         @end="savePositions"
              >
                <b-card
                  v-for="(reason, index) in positive"
                  :key="`positive_${index}`"
                  no-body
                  class="p-75 mb-0 cursor-pointer"
                >
                  <div class="d-flex flex-column flex-md-row justify-content-md-between align-items-md-center">
                    <p class="h5">
                      <feather-icon
                        icon="MoveIcon"
                        class="text-primary mr-75"
                        size="18"
                      />
                      {{ reason.name }}
                    </p>

                    <b-button-group>
                      <b-button
                        variant="outline-warning"
                        size="sm"
                        @click="editField(reason, reason.index)"
                      >
                        <feather-icon
                          icon="EditIcon"
                          class="mr-25"
                        />
                        <span>{{ $t('Edit') }}</span>
                      </b-button>

                      <b-button
                        variant="outline-danger"
                        size="sm"
                        @click="removeItem(reason.id, reason.index)"
                      >
                        <feather-icon
                          icon="XIcon"
                          class="mr-25"
                        />
                        <span>{{ deleteReasons.includes(reason.id ? reason.id.toString() : reason.id) ? $t('Cancel') : $t('Delete') }}</span>
                      </b-button>
                    </b-button-group>
                  </div>
                </b-card>
              </draggable>
            </div>

            <div
              v-else
              class="text-center py-1 font-weight-bold"
            >
              <feather-icon
                size="17"
                icon="XCircleIcon"
              />
              {{ $t('NoData') }}
            </div>
          </b-tab>

          <b-tab>
            <template #title>
              {{ $t('CloseReason') }} ({{ $t('funnel.status.Failure') }})
            </template>

            <div v-if="negative.length">
              <draggable v-model="negative"
                         @end="savePositions"
              >
                <b-card
                  v-for="(reason, index) in negative"
                  :key="`positive_${index}`"
                  no-body
                  class="p-75 mb-0  cursor-pointer"
                >
                  <div class="d-flex flex-column flex-md-row justify-content-md-between align-items-md-center">
                    <p class="h5">
                      <feather-icon
                        icon="MoveIcon"
                        class="text-primary mr-75"
                        size="18"
                      />
                      {{ reason.name }}
                    </p>

                    <b-button-group>
                      <b-button
                        variant="outline-warning"
                        size="sm"
                        @click="editField(reason, reason.index)"
                      >
                        <feather-icon
                          icon="EditIcon"
                          class="mr-25"
                        />
                        <span>{{ $t('Edit') }}</span>
                      </b-button>

                      <b-button
                        variant="outline-danger"
                        size="sm"
                        @click="removeItem(reason.id, reason.index)"
                      >
                        <feather-icon
                          icon="XIcon"
                          class="mr-25"
                        />
                        <span>{{ deleteReasons.includes(reason.id ? reason.id.toString() : reason.id) ? $t('Cancel') : $t('Delete') }}</span>
                      </b-button>
                    </b-button-group>
                  </div>
                </b-card>
              </draggable>
            </div>
            <div
              v-else
              class="text-center py-1 font-weight-bold"
            >
              <feather-icon
                size="17"
                icon="XCircleIcon"
              />
              {{ $t('NoData') }}
            </div>
          </b-tab>
        </b-tabs>
        <!--          </draggable>-->
      </b-form>

      <b-button
        variant="primary"
        class="mr-1"
        size="sm"
        @click="onSaveForm"
      >
        <span>{{ $t('Save') }}</span>
      </b-button>

      <b-button
        variant="outline-primary"
        size="sm"
        @click="addField"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-25"
        />
        <span>{{ $t('Add') }}</span>
      </b-button>

      <!-- edited modal -->
      <b-modal
        v-model="isFieldEdited"
        hide-footer
        :title="editedFieldIndex > -1 ? $t('Edit') : $t('New')"
        no-close-on-backdrop
      >
        <sw-select :name="$t('task.Type')">
          <v-select
            v-model="editedField.status"
            :reduce="t => t.value"
            :options="[
              { label: $t('funnel.status.Success'), value: 'CLOSED_POSITIVE' },
              { label: $t('funnel.status.Failure'), value: 'CLOSED_NEGATIVE' },
            ]"
          />
        </sw-select>
        <!-- Edited name  -->
        <b-form-group
          :label="$t('Name')"
          label-for="reason_name"
        >
          <b-form-input
            id="reason_name"
            v-model="editedField.name"
            type="text"
          />
        </b-form-group>
        <b-button
          variant="success"
          :disabled="!editedField.name || !editedField.status"
          @click="saveEditedField"
        >
          {{ $t('Confirm') }}
        </b-button>
      </b-modal>
      <!-- edited modal -->
    </div>
    <div
      v-else
      class="d-flex justify-content-center"
    >
      <b-spinner
        variant="primary"
        class="m-5"
      />
    </div>
  </div>
</template>

<script>
import {
  BButtonGroup, BForm, BFormGroup, BModal, BTab, BTabs,
} from 'bootstrap-vue'
import axiosIns from '@/libs/axios'
import vSelect from 'vue-select'
import draggable from 'vuedraggable'

export default {
  name: 'CloseReasons',
  components: {
    BForm,
    BFormGroup,
    vSelect,
    BButtonGroup,
    BModal,
    BTabs,
    BTab,
    draggable,
  },
  data: () => ({
    loading: false,
    areChangesSaved: true,
    isFieldEdited: false,
    editedField: {
      id: null,
      status: 'CLOSED_POSITIVE',
      name: '',
    },
    tab: 0,
    reasons: [],
    editedFieldIndex: 0,
    deleteReasons: [],

    positive: [],
    negative: [],
  }),
  computed: {
    getNegative() {
      return this.reasons.map((a, index) => ({ ...a, index })).filter(t => t.status === 'CLOSED_NEGATIVE')
    },
    getPositive() {
      return this.reasons.map((a, index) => ({ ...a, index })).filter(t => t.status === 'CLOSED_POSITIVE')
    },
  },
  async mounted() {
    await this.init()
  },
  methods: {
    savePositions() {
      this.reasons = [
        ...this.negative,
        ...this.positive,
      ]
    },
    assignToArrays() {
      this.negative = this.reasons.map((a, index) => ({ ...a, index })).filter(t => t.status === 'CLOSED_NEGATIVE')
      this.positive = this.reasons.map((a, index) => ({ ...a, index })).filter(t => t.status === 'CLOSED_POSITIVE')
    },
    async init() {
      this.loading = true
      try {
        const params = { fields_load: this.$fields.CONTACT_THREAD_CLOSE_REASONS, orderBy: 'position.asc' }
        const resp = await axiosIns.get('1/settings/contactThreadStatusReasons', { params })

        if (resp?.data?.data?.items) {
          const items = resp.data?.data?.items || []
          this.reasons = items

          this.assignToArrays()
          // this.reasons.CLOSED_NEGATIVE = items.filter(t => t.status === 'CLOSED_NEGATIVE')
          // this.reasons.CLOSED_POSITIVE = items.filter(t => t.status === 'CLOSED_POSITIVE')
        }
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.loading = false
      }
    },
    removeItem(id, index) {
      this.showAlert('error', this.$i18n.t('alerts.AreYouSure'))
        .then(async () => {
          if (id) await axiosIns.delete('1/settings/contactThreadStatusReasons', { data: JSON.stringify([id]) })
          this.reasons.splice(index, 1)

          this.assignToArrays()
        }).finally(() => {
          this.areChangesSaved = false
        })
    },
    editField(field, index) {
      this.isFieldEdited = true
      this.editedField = { ...field }
      this.editedFieldIndex = index
      this.areChangesSaved = false
    },
    addField() {
      this.isFieldEdited = true
      this.editedField = {
        id: null,
        name: '',
        status: this.tab ? 'CLOSED_NEGATIVE' : 'CLOSED_POSITIVE',
      }
      this.editedFieldIndex = -1
      this.areChangesSaved = false
    },
    saveEditedField() {
      const item = this.editedField
      if (this.editedFieldIndex !== -1) {
        this.$set(this.reasons, this.editedFieldIndex, item)
      } else {
        this.reasons.push(item)
      }

      this.isFieldEdited = false
      this.areChangesSaved = false
      this.editedField = {
        id: null, name: '', status: 'CLOSED_POSITIVE',
      }
      this.editedFieldIndex = 0

      this.assignToArrays()
    },
    async onSaveForm() {
      this.loading = true
      const reasonsPayload = (JSON.parse(JSON.stringify(this.reasons)) || []).map((reason, index) => {
        // eslint-disable-next-line no-underscore-dangle
        const _reason = { name: reason.name, position: index, status: reason.status || 'CLOSED_POSITIVE' }
        if (reason.id) _reason.id = reason.id

        return _reason
      })

      try {
        // if (this.deleteReasons.length) {
        //   await axiosIns.delete('1/settings/contactThreadStatusReasons', { data: JSON.stringify(this.deleteReasons.map(r => this.getObjectId(r))) })
        // }

        await axiosIns.put('1/settings/contactThreadStatusReasons', reasonsPayload)

        this.showToast('success', this.$i18n.t('success.contactUpdated'))
        this.areChangesSaved = true
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        await this.init()
      }
    },
  },

}
</script>

<style lang="scss">
  .popover-tasks {
    max-width: unset !important;
    & .popover-body {
      width: 400px;
    }
  }
</style>
