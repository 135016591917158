<template>
  <b-card>
    <b-tabs
      pills
      lazy
    >
      <b-tab>
        <template #title>
          <span>{{ $t(`Projects`) }}</span>
        </template>

        <list />
      </b-tab>

      <b-tab>
        <template #title>
          <span>{{ $t(`Tasks`) }}</span>
        </template>

        <list is-task />
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import { BTab, BTabs } from 'bootstrap-vue'
import List from './components/status/List.vue'

export default {
  components: {
    List,
    BTabs,
    BTab,
  },
}
</script>
