<template>
  <!-- Error page-->
  <div
    v-if="$route.name === 'system-not-exists'"
    class="misc-wrapper"
  >
    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          {{ $t('ServiceNotExist') }}
        </h2>
        <p class="mb-2">
          {{ $t('GoFeather') }}
        </p>

        <b-button
          variant="primary"
          class="mb-2 btn-sm-block"
          href="https://panel.4b-systems.com"
          target="_blank"
        >
          {{ $t('Go') }}
        </b-button>

        <!-- image -->
        <b-img
          fluid
          :src="imgUrl"
          alt="Error page"
        />
      </div>
    </div>
  </div>
  <div
    v-else
    class="misc-wrapper"
  >
    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          Nie znaleziono strony 🕵🏻‍♀️
        </h2>
        <div>
          <p class="mb-2">
            Wygląda na to, że żadna strona nie istnieje pod tym adresem
          </p>
          <b-button
            variant="primary"
            class="btn-sm-block"
            @click="$router.back()"
          >
            {{ $t('Return') }}
          </b-button>
        </div>
        <!-- image -->
        <b-img
          fluid
          :src="imgUrl"
          alt="Error page"
        />
      </div>
    </div>
  </div>
  <!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BButton, BImg } from 'bootstrap-vue'
import store from '@/store/index'

export default {
  components: {
    BButton,
    BImg,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/error.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
    getLocale() {
      return navigator.language
    },
  },
  mounted() {
    const [lang] = navigator.language.split('_')
    this.$i18n.locale = lang
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
