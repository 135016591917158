<!--eslint-disable-->

<template>
  <div>
    <b-card>
      <sw-table
        table-id="80ccc073e01a4a16bc9b5e0b56aced47"
        :pagination="pagination"
        @change-pagination="Object.assign(pagination, $event)"
        @reload-content="loadReports"
      >
        <template #table>
          <b-table
            striped
            responsive
            hover
            :items="items"
            :fields="fields"
            :per-page="pagination.perPage"
            class="mb-0"
            :busy="isLoading"
            @reload-content="loadReports"
          >
            <template #head()="{ label }">
              {{ $t(`ai.reports.${label}`) }}
            </template>

            <template #cell(totalTokens)="{ value }">
              <b-badge
                size="sm"
                variant="danger"
              >
                <!--                {{ `${value} ${$t('ForPrice')} ${pricePerToken | priceFormat } PLN` }}-->
                {{ `${milionFormatter(value || 0)}` }}
              </b-badge>
            </template>

            <template #cell(status)="{ value }">
              <b-badge
                size="sm"
                :variant="`light-${getStatusVariant(value)}`"
              >
                {{ $t(`ai.status.${value}`) }}
              </b-badge>
            </template>

            <template #cell(content)="{ index }">
              <b-button
                v-b-tooltip.hover.v-primary
                size="sm"
                variant="flat-primary"
                :title="$t('Preview')"
                @click="showPreview(index)"
              >
                <feather-icon
                  icon="EyeIcon"
                  class="mr-25"
                />

                {{ $t('Preview') }}
              </b-button>
            </template>

            <template #cell(reason)="{ value }">
              <b-badge
                v-if="value"
                size="sm"
                variant="primary"
              >
                {{ $t(`ai.reportsTypes.${value}`) }}
              </b-badge>

              <feather-icon
                v-else
                icon="MinusIcon"
              />
            </template>

            <template #cell(source)="{ value }">
              <b-badge
                v-if="value.length"
                size="sm"
                variant="primary"
              >
                {{ $t(`ai.types.${value}`) }}
              </b-badge>

              <feather-icon
                v-else
                icon="MinusIcon"
              />
            </template>

              <template #cell(process)="{ item }">
                  <template v-if="item.contactThread">
                      <router-link :class="{ 'deleted-text': item.contactThread.contact.deletedAt }" :to="`/contact/${item.contactThread.contact.id}/thread/${item.contactThread.id}/emails`">
                          {{ getUserName(item.contactThread.contact) | truncate(30) }} - {{ item.contactThread.name | truncate(30) }}
                      </router-link>
                  </template>

                  <feather-icon
                          v-else
                          icon="MinusIcon"
                  />
              </template>

            <template #cell(status)="{ value }">
              <template v-if="value">
                {{ value }}
              </template>

              <feather-icon
                v-else
                icon="MinusIcon"
              />
            </template>

            <template #cell(createdBy)="{ value }">
              <avatar
                :user="value"
                :to="{ name: 'user', params: { userId: value.id } }"
              />
            </template>

            <template #cell(createdAt)="data">
              {{ data.value.date.split('.')[0] }}
            </template>

            <template #cell(actions)="{ index }">
              <b-button
                v-b-tooltip.hover.v-danger
                size="sm"
                variant="flat-danger"
                :title="$t('Cancel')"
                class="btn-icon"
                @click="cancel(index)"
              >
                <feather-icon
                  icon="TrashIcon"
                  class="mr-25"
                />
              </b-button>
            </template>
          </b-table>
        </template>
      </sw-table>
    </b-card>

    <b-modal
      v-model="isShowPreview"
      :title="$t('Preview')"
      size="xl"
      static
      hide-footer
    >
      <div class="border rounded-lg px-2 py-1">
        <!-- eslint-disable vue/no-v-html -->
        <div
          v-if="previewContent.content"
          v-html="previewContent.content"
        />

        <div
          v-else
          class="text-center py-50 font-weight-bold"
        >
          <feather-icon
            size="17"
            icon="XCircleIcon"
          />

          {{ $t('NoData') }}
        </div>
      </div>

      <h4 class="mt-2 mb-1">
        {{ $t('ReasonForReporting') }}
      </h4>

      <b-alert
        variant="primary"
        show
      >
        <div class="alert-body">
          <template v-if="previewContent.reason">
            {{ $t(`ai.reportsTypes.${previewContent.reason}`) }}
          </template>

          <div
            v-else
            class="text-center py-50 font-weight-bold"
          >
            <feather-icon
              size="17"
              icon="XCircleIcon"
            />

            {{ $t('NoData') }}
          </div>
        </div>
      </b-alert>

      <h4 class="mt-2 mb-1">
        {{ $t('Message') }}
      </h4>

      <b-alert
        variant="primary"
        show
      >
        <div class="alert-body">
          <template v-if="previewContent.message">
            {{ previewContent.message }}
          </template>

          <div
            v-else
            class="text-center py-50 font-weight-bold"
          >
            <feather-icon
              size="17"
              icon="XCircleIcon"
            />

            {{ $t('NoData') }}
          </div>
        </div>
      </b-alert>
    </b-modal>
  </div>
</template>

<script>
import { BTable, VBTooltip } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { GET_OPEN_AI_REPORT_MESSAGE_ALL, REMOVE_OPEN_AI_REPORT_MESSAGE } from '@/@constants/mutations'
import { priceFormat } from '../../../@core/filters/parts/valueFormat'

export default {
  components: {
    BTable,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },

  data: () => ({
    isLoading: false,

    isShowPreview: false,
    previewContent: '',
    sorter: {
      sortBy: 'createdAt',
      sortDesc: true,
    },
    pagination: {
      perPage: 25,
      currentPage: 1,
      totalRecords: 100,
    },

    fields: [
      {
        key: 'content',
        label: 'Message',
      },
      {
        key: 'reason',
        label: 'ReasonForReporting',
      },
      {
        key: 'source',
        label: 'Type',
      },
      // {
      //   key: 'totalTokens',
      //   label: 'Tokens',
      // },
      {
        key: 'process',
        label: 'Process',
      },
      // {
      //   key: 'status',
      //   label: 'Status',
      // },
      {
        key: 'createdBy',
        label: 'ReportedBy',
      },
      {
        key: 'createdAt',
        label: 'CreatedAt',
      },
      {
        key: 'actions',
        label: 'Actions',
      },
    ],

    STATUS_LIST: {
      IN_REVIEW: 'IN_REVIEW',
      ACCEPTED: 'ACCEPTED',
      REJECTED: 'REJECTED',
    },

    TYPE_LIST: {
      EMAIL: 'EMAIL',
      SMS: 'SMS',
    },

    REPORT_TYPE: {
      NO_ANSWER: 'NO_ANSWER',
      PRIVACY_POLICY_VIOLATION: 'PRIVACY_POLICY_VIOLATION',
      OTHER: 'OTHER',
    },

    items: [],

    pricePerToken: 100.00,
  }),

  computed: {
    ...mapGetters({
      currentUser: 'auth/getCurrentUser',
    }),
  },

  mounted() {
    this.ini()
  },

  methods: {
    priceFormat,
    ini() {
      this.loadReports()
    },

    loadReports() {
      this.isLoading = true

      const payload = {
        pagination: this.pagination,
      }

      this.$store.dispatch(`ai/${GET_OPEN_AI_REPORT_MESSAGE_ALL}`, payload)
        .then(res => {
          this.items = res.data.items
          this.pagination.totalRecords = res.data.totalItemCount
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    showPreview(index) {
      const item = this.items[index]

      if (item) {
        this.isShowPreview = true
        this.previewContent = {
          content: item.content,
          message: item.message,
          reason: item.reason,
        }
      }
    },

    getStatusVariant(status) {
      const { STATUS_LIST } = this

      switch (status) {
        case STATUS_LIST.ACCEPTED:
          return 'success'

        case STATUS_LIST.REJECTED:
          return 'danger'

        default:
          return 'primary'
      }
    },

    cancel(index) {
      this.showAlert('warning', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('alerts.WontbeAble'))
        .then(() => this.removeReportBug(index))
    },

    async removeReportBug(index) {
      this.isLoading = true

      const { id } = this.items[index]

      if (id) {
        this.$store.dispatch(`ai/${REMOVE_OPEN_AI_REPORT_MESSAGE}`, id)
          .then(() => {
            this.items.splice(index, 1)

            this.$forceUpdate()
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          })
          .finally(() => {
            this.isLoading = false
          })
      } else {
        this.isLoading = false
      }
    },
  },
}
</script>
