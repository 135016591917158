import Vue from 'vue'
import Vuex from 'vuex'
/* eslint-disable */
// Modules
import { buildings } from '@/store/building'
import { core } from '@/store/core'
import { offering } from '@/store/offer/offer'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import modal from './modals'
import auth from './auth/auth'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    ...core,
    ...buildings,
    ...offering,
    app,
    appConfig,
    verticalMenu,
    modal,
    auth,
  },
  strict: process.env.DEV,
})
