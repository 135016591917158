/* eslint-disable */
export const saveToLocalStorage = (key, payload) => {
  localStorage.setItem(key, typeof payload === 'object' ? JSON.stringify(payload) : payload)
}

export const loadFromLocalStorage = key => {
  const item = localStorage.getItem(key)

  if (item && item !== 'undefined') {
    if (item.includes('{') || item.includes('[')) return JSON.parse(item)
    else if (item) return item
  }

  return false
}

export const removeFromLocalStorage = key => {
  localStorage.removeItem(key)
}
