var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"mx-0 mb-1"},[_c('b-col',{attrs:{"sm":"12","md":"6","lg":"4"}},[_c('my-option',{attrs:{"title":_vm.$t('offer.website.SelectBannerPicture'),"tip":((_vm.$t('PreferredFile')) + ": (400x400)px, PNG"),"my-class":'dragDrop rec'}},[_c('div',[_c('b-img',{staticClass:"px-0 py-0 center",attrs:{"id":"img-aboutBanner","thumbnail":"","fluid":"","src":!!_vm.newWebsite.aboutBanner && _vm.newWebsite.aboutBanner.preview ? ("data:image/webp;base64," + (_vm.newWebsite.aboutBanner.preview)): require('@/assets/icons/user/user.svg')}}),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary",modifiers:{"hover":true,"v-primary":true}}],attrs:{"variant":"primary","title":_vm.$t('DragDropFile')}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}}),_c('input',{attrs:{"type":"file"},on:{"change":function($event){return _vm.onChange($event, 'aboutBanner', 1250)}}})],1)],1)])],1)],1),_c('b-row',{staticClass:"mx-0"},[_c('b-col',{staticClass:"px-1",attrs:{"sm":"12","md":"6","lg":"8"}},[_c('my-option',{attrs:{"title":_vm.$t('Title'),"tip":_vm.$t('MaximumLength') + ': 60'}},[_c('validation-provider',{staticClass:"w-100",attrs:{"name":_vm.$t('Title'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"id":"popover-aboutTitle","placeholder":_vm.$t('Title'),"state":errors.length > 0 ? false:null,"maxlength":"60"},model:{value:(_vm.newWebsite.aboutTitle),callback:function ($$v) {_vm.$set(_vm.newWebsite, "aboutTitle", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"newWebsite.aboutTitle"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_c('b-popover',{attrs:{"target":"popover-aboutTitle","triggers":"focus","variant":"primary"}},[_c('span',[_vm._v(_vm._s(_vm.$t('MaximumLength') + ': 110'))])])],1)]}}])})],1)],1)],1),_c('b-row',{staticClass:"mx-0"},[_c('b-col',{staticClass:"px-1",attrs:{"sm":"12","md":"6","lg":"8"}},[_c('my-option',{attrs:{"title":_vm.$t('task.Description'),"tip":_vm.$t('MaximumLength') + ': 110'}},[_c('validation-provider',{staticClass:"w-100",attrs:{"name":_vm.$t('Title'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-textarea',{attrs:{"id":"popover-aboutDesc","placeholder":_vm.$t('Title'),"state":errors.length > 0 ? false:null,"maxlength":"110"},model:{value:(_vm.newWebsite.aboutDesc),callback:function ($$v) {_vm.$set(_vm.newWebsite, "aboutDesc", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"newWebsite.aboutDesc"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_c('b-popover',{attrs:{"target":"popover-aboutDesc","triggers":"focus","variant":"primary"}},[_c('span',[_vm._v(_vm._s(_vm.$t('MaximumLength') + ': 110'))])])],1)]}}])})],1)],1)],1),_c('b-row',{staticClass:"mx-0"},[_c('b-col',{staticClass:"px-1",attrs:{"sm":"12","md":"6","lg":"8"}},[_c('my-option',{attrs:{"title":_vm.$t('Options'),"tip":_vm.$t('MaximumLength') + ': 75'}},[_c('b-row',{staticClass:"w-100 mx-0 px-0"},[_c('b-col',{staticClass:"d-flex px-0",attrs:{"sm":"12","md":"12","lg":"12"}},[_c('b-form-input',{attrs:{"id":"popover-aboutNewAbout","placeholder":_vm.$t('Title'),"maxlength":"75"},model:{value:(_vm.aboutNewOption),callback:function ($$v) {_vm.aboutNewOption=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"aboutNewOption"}}),_c('b-popover',{attrs:{"target":"popover-aboutNewAbout","triggers":"focus","variant":"primary"}},[_c('span',[_vm._v(_vm._s(_vm.$t('MaximumLength') + ': 75'))])]),_c('b-button',{staticClass:"btn-icon ml-1 offerWebsiteAdd",attrs:{"variant":"primary"},on:{"click":function () {
                  if (_vm.aboutNewOption.length > 0) {
                    _vm.newWebsite.aboutOptions.push(JSON.parse(JSON.stringify(_vm.aboutNewOption)))

                    _vm.aboutNewOption = ''
                  }
                }}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)],1)],1),(_vm.newWebsite.aboutOptions && _vm.newWebsite.aboutOptions.length > 0)?_c('b-row',{staticClass:"w-100 mx-0 px-0"},_vm._l((_vm.newWebsite.aboutOptions),function(item,index){return _c('b-col',{key:index,staticClass:"mt-1 px-0 d-flex",attrs:{"sm":"12","md":"12","lg":"12"}},[_c('b-form-input',{staticClass:"cursor-pointer",attrs:{"value":item,"readonly":""},on:{"click":function($event){_vm.selectedOption = { id: index, opt: JSON.parse(JSON.stringify(item)) }
                _vm.showChangeAboutOptionsModal = true}}}),_c('b-button',{staticClass:"btn-icon ml-1 offerWebsiteAdd",attrs:{"variant":"outline-danger"},on:{"click":function($event){_vm.newWebsite.aboutOptions.splice(index, 1)

                _vm.newWebsite.aboutOptions.push()}}},[_c('feather-icon',{attrs:{"icon":"MinusIcon"}})],1)],1)}),1):_vm._e()],1)],1)],1),_c('b-modal',{attrs:{"title":_vm.$t('offer.website.AboutCompany'),"size":"sm","hide-footer":"","no-close-on-backdrop":""},model:{value:(_vm.showChangeAboutOptionsModal),callback:function ($$v) {_vm.showChangeAboutOptionsModal=$$v},expression:"showChangeAboutOptionsModal"}},[_c('b-form',[_c('validation-provider',{staticClass:"w-100",attrs:{"name":_vm.$t('Title'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Title')}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('Title'),"state":errors.length > 0 ? false:null,"maxlength":"75"},model:{value:(_vm.selectedOption.opt),callback:function ($$v) {_vm.$set(_vm.selectedOption, "opt", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"selectedOption.opt"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('b-button',{staticClass:"ml-25",attrs:{"variant":"success","size":"sm"},on:{"click":function($event){_vm.newWebsite.aboutOptions[_vm.selectedOption.id] = _vm.selectedOption.opt
          _vm.$forceUpdate()

          _vm.showChangeAboutOptionsModal = false}}},[_vm._v(" "+_vm._s(_vm.$t('Save'))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }