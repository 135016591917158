<template>
  <div v-if="item">
    <b-form-group :label="$t('Name')">
      <b-form-input v-model="item.name" />
    </b-form-group>

    <b-row>
      <b-col
        sm="12"
        md="6"
      ><b-form-group label="Youtube">
        <b-input-group class="input-group-merge">
          <b-input-group-prepend :is-text="true">
            <feather-icon icon="YoutubeIcon" />
          </b-input-group-prepend>
          <b-form-input v-model="item.youtubeUrl" />
        </b-input-group>
      </b-form-group>
      </b-col>
      <b-col
        sm="12"
        md="6"
      ><b-form-group :label="$t('EditWebsite')">
        <b-input-group class="input-group-merge">
          <b-input-group-prepend :is-text="true">
            <feather-icon icon="GlobeIcon" />
          </b-input-group-prepend>
          <b-form-input v-model="item.websiteUrl" />
        </b-input-group>
      </b-form-group>
      </b-col>
    </b-row>

    <b-form-group :label="$t('Description')">
      <b-form-textarea v-model="item.description">
        {{ item.description }}
      </b-form-textarea>
    </b-form-group>

    <b-form-group label="Avatar">
      <div class="d-flex align-items-center">
        <drag-drop-upload
          class="flex-grow-1 mr-1"
          :mt="false"
          :show-files="false"
          :disabled="loading"
          @onChangeFiles="setAvatar"
        />

        <b-avatar
          variant="light-primary"
          size="90"
          :src="imagePreview"
        />
      </div>
    </b-form-group>

    <b-button
      variant="primary"
      size="sm"
      :disabled="loading || !item.name"
      @click="saveItem"
    >
      {{ $t('Save') }}
    </b-button>
  </div>
  <div
    v-else
    class="d-flex justify-content-center"
  >
    <b-spinner
      variant="primary"
      class="m-2"
    />
  </div>
</template>

<script>
import axiosIns from '@/libs/axios'
import keysCuter from '@/helpers/keysCuter'
import { BInputGroup, BFormTextarea } from 'bootstrap-vue'
import DragDropUpload from '@/views/components/DragDropUpload.vue'
import { UPLOAD_FILES } from '@/@constants/mutations'

export default {
  name: 'TrainerForm',
  components: {
    DragDropUpload,
    BInputGroup,
    BFormTextarea,
  },
  props: {
    editedItem: {
      default: null,
      required: true,
      type: Object,
    },
  },
  data: () => ({
    item: null,
    loading: false,

    imagePreview: null,
  }),
  computed: {
    isEditMode() {
      return Boolean(this.item?.id)
    },
  },
  async mounted() {
    this.loading = true
    if (this.editedItem?.id) {
      await this.loadItem()
    } else {
      this.item = this.editedItem
    }
    this.loading = false
  },
  methods: {
    setAvatar(event) {
      const fr = new FileReader()
      fr.onload = () => {
        this.imagePreview = fr.result
      }
      fr.readAsDataURL(event[0]);
      [this.item.avatar] = event
    },
    async loadItem() {
      const { id } = this.editedItem

      try {
        const params = {
          fields_load: 'TRAINERS',
          eq_id: id,
        }
        const resp = await axiosIns.get('1/trainingTrainers', { params })
        if (resp.data.data.items) {
          [this.item] = resp.data.data.items

          if (this.item?.avatar) {
            this.imagePreview = `data:image/webp;base64,${this.item.avatar?.preview}` || null
          }
        }
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },

    closeModal(toReturn) {
      this.$emit('close-modal', toReturn)
      this.item = null
      this.addNote = false
      this.orders = []
    },

    async saveItem() {
      this.loading = true
      try {
        let payload = { ...this.item }

        if (payload.avatar) {
          if (!payload.avatar?.token) {
            const filesPayload = {
              uploadedFiles: [payload.avatar],
              type: 'building',
            }
            const { files } = await this.$store.dispatch(`uploader/${UPLOAD_FILES}`, filesPayload)

            // eslint-disable-next-line prefer-destructuring
            payload.avatar = files[0]
          } else {
            payload.avatar = payload.avatar.token
          }
        }

        payload = keysCuter(payload)
        const resp = await axiosIns({
          url: '1/trainingTrainers',
          method: payload.id ? 'PATCH' : 'POST',
          data: payload,
        })

        this.closeModal(resp)
        this.showToast('success', this.$i18n.t('success.contactUpdated'))
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>

</style>
