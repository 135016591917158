import i18n from '@/libs/i18n'

const version = '1'

// eslint-disable-next-line import/prefer-default-export
export const contactMigration = (filters = {}) => ({
  version,
  sheets: [
    {
      name: i18n.t('Contacts'),
      entity: 'Contact',
      columns: [
        { name: 'ID', field: 'id', type: 'numeric' },
        {
          field: 'createdAt', name: i18n.t('CreatedAt'), type: 'string', format: 'Y.m.d',
        },
        { field: 'firstName', name: i18n.t('FirstName'), type: 'string' },
        { field: 'lastName', name: i18n.t('LastName'), type: 'string' },
        { field: 'phone', name: i18n.t('contact.phone'), type: 'string' },
        { field: 'email', name: i18n.t('Email'), type: 'string' },
        { field: 'agreeMarketing', name: i18n.t('contact.agreeMarketing'), type: 'string' },
        { field: 'status', name: i18n.t('task.Status'), type: 'string' },
        { field: 'position', name: i18n.t('ContactPosition'), type: 'string' },
        { field: 'organization.name', name: i18n.t('Organization'), type: 'string' },
        { field: 'organization.vatId', name: i18n.t('contact.vatId'), type: 'string' },
      ],
    },
  ],
  data: [
    {
      parameters: { ...filters },
      sheet: i18n.t('Contacts'),
      children: [],
    },
  ],
})

export const tasksMigration = (filters = {}) => ({
  version,
  sheets: [
    {
      name: i18n.t('Tasks'),
      entity: 'ContactThreadTask',
      columns: [
        {
          name: 'ID', field: 'id', type: 'numeric',
        },
        { field: 'contactThread.contact.fullName', name: i18n.t('FirstNameAndLastName'), type: 'string' },
        { field: 'contactThread.name', name: i18n.t('task.Thread'), type: 'string' },
        { field: 'contactThread.webUrl', name: i18n.t('Link'), type: 'string' },
        { field: 'contactThreadTaskType.name', name: i18n.t('task.Type'), type: 'string' },
        { field: 'status', name: i18n.t('task.Status'), type: 'string' },
        {
          field: 'updatedAt', name: i18n.t('TaskEndedAt'), format: 'Y.m.d', type: 'string',
        },
        { field: 'contactThreadTaskReport.content', name: i18n.t('timeline.Task_report'), type: 'string' },
        { field: 'content', name: i18n.t('task.Description'), type: 'string' },
        { field: 'filesCount', name: i18n.t('task.Files'), type: 'numeric' },
        {
          field: 'startAt', name: i18n.t('task.DateStart'), format: 'Y.m.d', type: 'string',
        },
        {
          field: 'endAt', name: i18n.t('task.DateEnd'), format: 'Y.m.d', type: 'string',
        },
        {
          field: 'createdAt', name: i18n.t('task.Created_Time'), format: 'Y.m.d', type: 'string',
        },
        { field: 'assignedUser.fullName', name: i18n.t('task.AssignedUser'), type: 'string' },
        { field: 'sendReminderEmail', name: i18n.t('task.ReminderEmail'), type: 'bool' },
        { field: 'sendReminderSms', name: i18n.t('task.ReminderSms'), type: 'bool' },
        { field: 'createdBy.fullName', name: i18n.t('task.Principal'), type: 'string' },
      ],
    },
  ],
  data: [
    {
      parameters: { ...filters },
      sheet: i18n.t('Tasks'),
      children: [],
    },
  ],
})

export const noteMigration = (filters = {}) => ({
  version,
  sheets: [
    {
      name: i18n.t('Notes'),
      entity: 'ContactThreadNote',
      columns: [
        { name: 'ID', field: 'id', type: 'numeric' },
        { field: 'contactThread.contact.fullName', name: i18n.t('Contact'), type: 'string' },
        { field: 'contactThread.name', name: i18n.t('task.Thread'), type: 'string' },
        { field: 'contactThread.webUrl', name: i18n.t('Link'), type: 'string' },
        { field: 'contactThreadNoteType.name', name: i18n.t('task.Type'), type: 'string' },
        { field: 'content', name: i18n.t('task.Description'), type: 'string' },
        { field: 'filesCount', name: i18n.t('task.Files'), type: 'string' },
        {
          field: 'createdAt', name: i18n.t('task.Created_Time'), format: 'Y.m.d', type: 'string',
        },
        { field: 'createdBy.fullName', name: i18n.t('CreatedBy'), type: 'string' },
      ],
    },
  ],
  data: [
    {
      parameters: { ...filters },
      sheet: i18n.t('Notes'),
      children: [],
    },
  ],
})

export const orderMigration = (filters = {}) => ({
  version,
  sheets: [
    {
      name: i18n.t('Orders'),
      entity: 'ContactThreadCartOrder',
      columns: [
        { name: 'ID', field: 'id', type: 'numeric' },
        { field: 'number', name: i18n.t('OrderID'), type: 'string' },
        {
          field: 'orderDate', name: i18n.t('OrderDate'), format: 'Y.m.d', type: 'string',
        },
        { field: 'contactThreadCartBox.contactThread.contact.fullName', name: i18n.t('Contact'), type: 'string' },
        { field: 'contactThreadCartBox.contactThread.contact.phone', name: i18n.t('Phone'), type: 'string' },
        { field: 'contactThreadCartBox.contactThread.contact.email', name: i18n.t('Email'), type: 'string' },
        { field: 'contactThreadCartBox.contactThread.name', name: i18n.t('contact.contactThread'), type: 'string' },
        { field: 'contactThreadCartBox.contactThreadCartBoxProducts.translations.nameWithAmount', name: i18n.t('Products'), type: 'string' },
        { field: 'contactThreadCartBoxProductsTotalAmount', name: i18n.t('offer.product.quantity'), type: 'numeric' },
        { field: 'contactThreadCartBox.totalGrossPriceWithDiscount', name: i18n.t('offer.GrossPrice'), type: 'numeric' },
        { field: 'totalSellerDiscount', name: i18n.t('UserDiscount'), type: 'numeric' },
        { field: 'status', name: i18n.t('StatusOfOrder'), type: 'string' },
        { field: 'contactThreadCartBox.paymentType', name: i18n.t('PaymentMethod'), type: 'string' },
        { field: 'seller.fullName', name: i18n.t('Seller'), type: 'string' },
        { field: 'deliveryAddressCountry', name: i18n.t('Country'), type: 'string' },
        { field: 'deliveryAddressCity', name: i18n.t('City'), type: 'string' },
        { field: 'deliveryAddressPostalCode', name: i18n.t('PostalCode'), type: 'string' },
        { field: 'deliveryAddressStreet', name: i18n.t('Street'), type: 'string' },
        { field: 'deliveryAddressNumber', name: i18n.t('Number'), type: 'string' },
      ],
    },
  ],
  data: [
    {
      parameters: { ...filters },
      sheet: i18n.t('Orders'),
      children: [],
    },
  ],
})

export const contactThreadProcessesMigration = (filters = {}) => ({
  version,
  sheets: [
    {
      name: i18n.t('ContactThread'),
      entity: 'ContactThreadFunnelProcess',
      columns: [
        { name: 'ID', field: 'id', type: 'numeric' },
        { field: 'contactThread.contact.fullName', name: i18n.t('Contact'), type: 'string' },
        { field: 'contactThread.contact.phone', name: i18n.t('Phone'), type: 'string' },
        { field: 'contactThread.contact.email', name: i18n.t('E-mail'), type: 'string' },
        { field: 'contactThread.contact.status.name', name: i18n.t('AutomationSection.ContactStatus'), type: 'string' },
        { field: 'contactThreadFunnelType.name', name: i18n.t('AutomationSection.ContactThreadFunnelTypePoint'), type: 'string' },
        { field: 'contactThreadFunnelTypePoint.name', name: i18n.t('AutomationSection.ContactThreadFunnelTypePoint'), type: 'string' },
        { field: 'contactThread.name', name: i18n.t('Thread'), type: 'string' },
        { field: 'contactThread.webUrl', name: i18n.t('Link'), type: 'string' },
        { field: 'status', name: i18n.t('Status'), type: 'string' },
        { field: 'contactThread.contact.organization.name', name: i18n.t('Company'), type: 'string' },
        {
          field: 'statusClosedAt', name: i18n.t('ClosingDate'), type: 'string', format: 'Y.m.d',
        },
        { field: 'contactThreadStatusReason.name', name: i18n.t('Reason'), type: 'string' },
        { field: 'statusNote', name: i18n.t('Note'), type: 'string' },
        { field: 'contactThread.predictedValue', name: i18n.t('CustomerValue'), type: 'numeric' },
        { field: 'contactThread.assignedUsers.fullName', name: i18n.t('AssignedUser'), type: 'string' },
        { field: 'contactThread.contactThreadNotesCount', name: i18n.t('Notes'), type: 'numeric' },
        { field: 'contactThread.contactThreadTasksCount', name: i18n.t('Tasks'), type: 'numeric' },
        {
          field: 'contactThread.createdAt', name: i18n.t('ContactCreatedAt'), type: 'string', format: 'Y.m.d',
        },
      ],
    },
  ],
  data: [
    {
      parameters: { ...filters },
      sheet: i18n.t('ContactThread'),
      children: [],
    },
  ],
})
