<template>
  <div
    class="d-flex flex-column"
    :style="{
      width: fluid || fluidX ? '100%' : width,
      height: fluid || fluidY ? '100%' : height,
    }"
  >
    <div
      class="d-flex align-items-center justify-content-between"
      style="gap: .5rem"
    >
      <div
        class="d-flex align-items-center"
        style="gap: .34rem"
      >
        <label
          v-if="label"
          :for="uid"
          class="text-left"
        >
          {{ label }}
        </label>

        <sw-icon
          v-if="info"
          v-b-tooltip.hover.v-primary
          :title="info"
          icon="InfoIcon"
          class="mt-n25"
        />

        <div
          v-if="$slots.toolbar"
          class="mt-n25"
        >
          <slot name="toolbar" />
        </div>
      </div>

      <b-form-checkbox
        v-if="switchEnabled"
        v-model="isEnabled"
        switch
        size="sm"
        variant="primary"
        class="fix d-flex mr-n25"
      />
    </div>

    <div
      class="swFormInputColor position-relative w-100 h-100"
      :class="!isEnabled && 'disabled'"
    >
      <div
        class="input-container d-flex align-items-center justify-content-center border rounded-lg position-relative overflow-hidden w-100 h-100"
        :style="{ background: hex }"
      >
        <b-form-input
          v-model="hex"
          type="color"
          class="position-absolute w-100 h-100 position-top-0 position-left-0"
          :class="isEnabled ? 'cursor-pointer' : 'cursor-not-allowed'"
          :disabled="!isEnabled"
          style="opacity: 0; z-index: 1"
          @change="loadColor"
        />
      </div>

      <b-button
        v-if="clearable && hex && isEnabled"
        variant="danger"
        size="sm"
        class="position-absolute btn-icon p-25"
        style="top: -.34rem; right: -.64rem; z-index: 2;"
        @click="clearColor"
      >
        <sw-icon icon="RefreshCwIcon" />
      </b-button>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import { VBTooltip } from 'bootstrap-vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    value: {
      type: [String, Object],
      default: '',
    },
    defaultColor: {
      type: [String, Object],
      required: false,
      default: () => ({ hex: '#7367f0', rgb: 'rgb(115, 103, 240)' }),
    },
    width: {
      type: String,
      required: false,
      default: '120px',
    },
    height: {
      type: String,
      required: false,
      default: '120px',
    },
    fluid: {
      type: Boolean,
      required: false,
      default: false,
    },
    fluidX: {
      type: Boolean,
      required: false,
      default: false,
    },
    fluidY: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: [String, Object],
      required: false,
      default: '',
    },
    info: {
      type: [Object, String],
      required: false,
      default: '',
    },
    clearable: {
      type: Boolean,
      required: false,
      default: true,
    },
    enabled: {
      type: Boolean,
      required: false,
      default: true,
    },
    switchEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['input', 'changeEnabled'],
  data: () => ({
    uid: '0',
    hex: '',
    rgb: '',
    isEnabled: true,
  }),
  watch: {
    value(n) {
      const { hex, rgb } = this.format(n)

      this.hex = hex
      this.rgb = rgb
    },
    enabled(n) {
      this.isEnabled = n
    },
    isEnabled(n) {
      this.$emit('change-enabled', n)
    },
  },
  mounted() {
    this.uid = String(uuidv4())

    if (!this.value) this.clearColor()

    if (this.value) {
      const { hex, rgb } = this.format(this.value)

      this.hex = hex
      this.rgb = rgb
    }

    this.isEnabled = this.enabled
  },
  methods: {
    loadColor(color) {
      this.$emit('input', this.format(color))
    },

    clearColor() {
      this.hex = this.defaultColor.hex
      this.rgb = this.defaultColor.rgb
      this.loadColor(this.defaultColor.hex)
    },

    format(color) {
      if (color.hex && color.rgb) return color

      const hex = color.startsWith('#') ? color : this.hexToRgb(color)
      const rgb = color.startsWith('rgb') ? color : this.hexToRgb(color)

      return { hex, rgb }
    },

    hexToRgb(hex) {
      const color = hex.replace(/^#/, '')

      const bigint = parseInt(color, 16)
      // eslint-disable-next-line no-bitwise
      const r = (bigint >> 16) & 255
      // eslint-disable-next-line no-bitwise
      const g = (bigint >> 8) & 255
      // eslint-disable-next-line no-bitwise
      const b = bigint & 255

      return `rgb(${r}, ${g}, ${b})`
    },

    rgbToHex(r, g, b) {
      const componentToHex = c => {
        const hex = c.toString()

        return hex.length === 1 ? `0${hex}` : hex
      }

      return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`
    },
  },
}
</script>
