<template>
  <app-collapse-item
    v-if="!loading && threadList.length && protectGetThreads && protectGetAssignedContacts"
    class="card-body:p-0"
    :is-visible="!loading"
  >
    <template #header>
      <div
        class="d-flex align-items-center"
        style="gap: .34rem"
      >
        <sw-icon
          icon="FilterIcon"
          style="margin-top: -.18rem"
        />

        <h6 class="mb-0">
          {{ $t('ThreadsFromContacts') }}
        </h6>

        <b-spinner
          v-if="loading"
          type="grow"
          small
          class="mx-auto"
          variant="primary"
        />

        <feather-icon
          v-if="
            contact
              && contact.contactThreadAssignedContacts
              && contact.contactThreadAssignedContacts.length
          "
          v-b-tooltip.hover.v-primary.top
          :title="$t('ThisContactIsAssigned')"
          icon="AlertCircleIcon"
          class="text-primary cursor-pointer"
        />
      </div>
    </template>

    <b-list-group
      v-if="!loading"
      class="p-0 pb-50"
      flush
    >
      <b-list-group-item
        v-for="(threadItem, i) in threadList"
        :key="threadItem.id"
        class="d-flex flex-column cursor-pointer"
        style="row-gap: .34rem"
        @click="changeActiveThread(threadItem)"
      >
        <!--    START::Header    -->
        <div
          class="d-flex flex-row"
          style="column-gap: .34rem"
        >
          <!--    START::Title    -->
          <div
            class="d-flex flex-column flex-wrap"
            style="row-gap: .2rem;"
          >
            <span
              v-b-tooltip.hover.right.v-dark
              class="mb-0 cursor-pointer h5 mb-0"
              :title="`${$t('Thread')}: ${threadItem.name}`"
            >
              {{ threadItem.name | truncate(34) }}
            </span>

            <div
              class="d-flex flex-row align-items-center"
              style="gap: .34rem"
            >
              <span
                v-b-tooltip.hover.right.v-dark
                class="h6 font-weight-normal mb-0"
                :title="`${$t('Contact')}: ${getUserName(threadItem.contact)}`"
              >
                {{ getUserName(threadItem.contact) | truncate(34) }}
              </span>
            </div>
          </div>
          <!--    END::Title    -->
        </div>
        <!--    END::Header    -->

        <!--    START::Footer    -->
        <div
          class="d-flex align-items-start justify-content-between"
          style="gap: .34rem"
        >
          <!--    START::Start side    -->
          <div
            class="d-flex align-items-center"
            style="column-gap: .5rem"
          >
            <!--    START::Created At    -->
            <sw-icon
              :id="`ttCreatedAt-${threadItem.id}${i}`"
              icon="CalendarIcon"
              style="margin-top: -.14rem"
              data-html="true"
            />

            <b-tooltip
              :target="`ttCreatedAt-${threadItem.id}${i}`"
              placement="bottom"
              variant="dark"
              triggers="hover"
            >
              {{ $t('ProcessCreatedAt') }}:
              <br>
              {{ threadItem.createdAt.date.split('.')[0] }}
            </b-tooltip>
            <!--    END::Created At    -->
          </div>
          <!--    END::Start side    -->
        </div>
        <!--    END::Footer    -->
      </b-list-group-item>
    </b-list-group>
  </app-collapse-item>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { BTooltip, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  GET_CURRENT_CONTACT_ASSIGNED_THREADS,
} from '@/@constants/mutations'
import { priceFormat } from '@core/filters/parts/valueFormat'

export default {
  components: { BTooltip },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data: () => ({
    loading: false,
    threadList: [],
  }),
  computed: {
    ...mapGetters({
      user: 'auth/getCurrentUser',
      contact: 'contact/contact',
    }),

    protectGetThreads() {
      return this.checkRequiredPermissions([this.$roles.CONTACT_THREAD_VIEW_ALL, this.$roles.CONTACT_THREAD_VIEW_ASSIGNED])
    },
    protectGetAssignedContacts() {
      return this.checkRequiredPermissions([this.$roles.CONTACT_VIEW_ALL, this.$roles.CONTACT_VIEW_ASSIGNED, this.$roles.CONTACT_VIEW_STRUCTURE, this.$roles.CONTACT_VIEW_STRUCTURE_FIRST])
    },
  },
  watch: {
    async contact() { if (this.protectGetThreads && this.protectGetAssignedContacts) await this.loadThreadList() },
  },
  async mounted() {
    if (this.contact && this.protectGetThreads && this.protectGetAssignedContacts) await this.loadThreadList()
  },
  methods: {
    priceFormat,
    ...mapActions({
      getAssignedThreadList: `contact/${GET_CURRENT_CONTACT_ASSIGNED_THREADS}`,
    }),
    ...mapMutations({
      changeActiveThread: 'contact/changeActiveThread',
    }),

    async loadThreadList() {
      this.loading = true

      try {
        this.threadList = await this.getAssignedThreadList()
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }

      this.loading = false
    },
  },
}
</script>
