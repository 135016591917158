<template>
  <div v-if="thread && thread.contactThreadAssignedContacts">
    <app-collapse
      class="mt-50"
      accordion
      :type="$route.name !== 'shop/checkout' ? 'margin' : 'border'"
    >
      <app-collapse-item
        v-for="(assignedContact, index) in thread.contactThreadAssignedContacts"
        :key="`contact_${index}`"
        :title="assignedContact.contact.firstName"
        sub-title
      >
        <template #title>
          <avatar :user="assignedContact.contact" />
        </template>
        <template #subTitle>
          <div class="d-flex flex-wrap justify-content-between w-100 mt-50">
            <div style="width: 100px">
              {{ $t('Part') }}
              <b-badge :variant="'light-primary'">
                {{ assignedContact.part || getMainContactPart || 0 }}%
              </b-badge>
            </div>
            <div>
              {{ $t('Filled') }}
              <b-badge :variant="getFilledFieldsCount[index].total !== getFilledFieldsCount[index].filled ? 'light-danger' : 'light-success'">
                {{ getFilledFieldsCount[index].filled }} / {{ getFilledFieldsCount[index].total }}
              </b-badge>
            </div>
          </div>
        </template>
        <validation-observer :ref="`contacts_validator_${assignedContact.contact.id}`">
          <b-row>
            <!-- CONTACT :: Part -->
            <b-col sm="12">
              <validation-provider
                v-slot="{ errors }"
                :name="$t('ContactPartInAgreement')"
                :rules="rules"
              >
                <b-form-group
                  :label="$t('ContactPartInAgreement')"
                >
                  <b-input-group
                    append="%"
                    class="input-group-merge"
                  >
                    <b-form-input
                      v-if="index !== 0"
                      id="basicInput"
                      v-model="assignedContact.part"
                    />
                    <b-form-input
                      v-else
                      id="basicInput"
                      :value="getMainContactPart"
                      disabled
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- CONTACT :: First name -->
            <b-col
              sm="12"
              md="6"
              lg="4"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('contact.name')"
                :rules="rules"
              >
                <b-form-group :label="$t('contact.name')">
                  <b-form-input
                    id="basicInput"
                    v-model="assignedContact.contact.firstName"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- CONTACT :: Last name -->
            <b-col
              sm="12"
              md="6"
              lg="4"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('contact.lastName')"
                :rules="rules"
              >
                <b-form-group :label="$t('contact.lastName')">
                  <b-form-input
                    id="basicInput"
                    v-model="assignedContact.contact.lastName"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- CONTACT :: Email -->
            <b-col
              sm="12"
              md="6"
              lg="4"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('contact.email')"
                :rules="rules"
              >
                <b-form-group :label="$t('contact.email')">
                  <b-form-input
                    id="basicInput"
                    v-model="assignedContact.contact.email"
                    :state="!Boolean(assignedContact.contact && assignedContact.contact.emailExist)"
                    @blur="checkExistedEmailsNew(thread.contactThreadAssignedContacts)"
                  />
                  <small
                    v-if="Boolean(assignedContact.contact && assignedContact.contact.emailExist)"
                    class="text-danger"
                  >
                    {{ $t('EmailExist') }}
                  </small>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- CONTACT :: Phone -->
            <b-col
              sm="12"
              md="6"
              lg="4"
            >
              <div class="d-flex">
                <sw-select :name="$t('contact.phone')">
                  <v-select
                    v-model="assignedContact.contact.phone.countryCode"
                    :options="countryCodes"
                    label="dial_code"
                    style="min-width: 90px"
                    class="mr-50"
                    :clearable="false"
                  />
                </sw-select>
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('contact.phone')"
                  :rules="rules"
                >
                  <cleave
                    :id="`contact_phone_${assignedContact.id}`"
                    v-model="assignedContact.contact.phone.value"
                    :options="cleaveOptions.number"
                    class="form-control"
                    :class="[
                      !Boolean(assignedContact.contact && assignedContact.contact.phoneExist) ? 'is-valid' : 'is-invalid'
                    ]"
                    @input="checkExistedPhones(thread.contactThreadAssignedContacts)"
                  />
                  <small
                    v-if="Boolean(assignedContact.contact && assignedContact.contact.phoneExist)"
                    class="text-danger"
                  >
                    {{ $t('PhoneExist') }}
                  </small>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
            </b-col>
            <!-- CONTACT :: Born Date -->
            <b-col
              sm="12"
              md="6"
              lg="4"
            >
              <b-form-group :label="$t('contact.birthDate')">
                <flat-pickr
                  id="releaseDate"
                  :value="getBirthDate(assignedContact.contact)"
                  :config="{ locale: getCalendarLocale($i18n.locale) }"
                  class="mr-50 mt-25 form-control"
                  @input="changeField(assignedContact.contact, 'birthDate', $event)"
                />
              </b-form-group>
            </b-col>
            <!-- CONTACT :: ID number -->
            <!--            <b-col-->
            <!--              sm="12"-->
            <!--              md="6"-->
            <!--              lg="4"-->
            <!--            >-->
            <!--              <validation-provider-->
            <!--                #default="{ errors }"-->
            <!--                :name="$t('contact.identityCardNumber')"-->
            <!--                rules="rules"-->
            <!--              >-->
            <!--                <b-form-group :label="$t('contact.identityCardNumber')">-->
            <!--                  <b-form-input-->
            <!--                    id="basicInput"-->
            <!--                    v-model="assignedContact.contact.identityCardNumber"-->
            <!--                  />-->
            <!--                  <small class="text-danger">{{ errors[0] }}</small>-->
            <!--                </b-form-group>-->
            <!--              </validation-provider>-->
            <!--            </b-col>-->
            <!-- CONTACT :: Family Name -->
            <b-col
              sm="12"
              md="6"
              lg="4"
            >
              <b-form-group :label="$t('contact.familyName')">
                <b-form-input
                  id="basicInput"
                  v-model="assignedContact.contact.familyName"
                />
              </b-form-group>
            </b-col>
            <!-- CONTACT :: Civil Status -->
            <b-col
              sm="12"
              md="6"
              lg="4"
            >
              <sw-select :name="$t('contact.maritalStatus')">
                <v-select
                  v-model="assignedContact.contact.maritalStatus"
                  :options="civilStatus"
                >
                  <template #selected-option="{ label }">
                    {{ $t(`contact.${label}`) }}
                  </template>
                  <template #option="{ label }">
                    {{ $t(`contact.${label}`) }}
                  </template>
                </v-select>
              </sw-select>
            </b-col>
            <!-- CONTACT :: Property Status -->
            <b-col
              sm="12"
              md="6"
              lg="4"
            >
              <sw-select :name="$t('contact.financialPosition')">
                <v-select
                  v-model="assignedContact.contact.financialPosition"
                  :options="propertyStatus"
                >
                  <template #selected-option="{ label }">
                    {{ $t(`contact.${label}`) }}
                  </template>
                  <template #option="{ label }">
                    {{ $t(`contact.${label}`) }}
                  </template>
                </v-select>
              </sw-select>
            </b-col>
            <!-- CONTACT :: Addresses / Organization -->
            <b-col
              v-if="assignedContact.contact.isOrganization"
              cols="12"
              class="mb-1"
            >
              <div
                class="d-flex align-items-center flex-wrap"
                style="max-width: 500px"
              >
                <div class="d-flex align-items-center mb-25">
                  {{ $t('Organization') }}
                  <b-button
                    variant="success"
                    class="ml-50"
                    size="sm"
                    @click="addOrganization(false, assignedContact.contact)"
                  >
                    {{ $t('Add') }}
                  </b-button>
                  <b-button
                    v-if="assignedContact.contact.organization"
                    variant="warning"
                    class="ml-50"
                    size="sm"
                    @click="addOrganization(true, assignedContact.contact)"
                  >
                    {{ $t('Edit') }}
                  </b-button>
                </div>
                <sw-select>
                  <v-select
                    v-model="assignedContact.contact.organization"
                    :options="organizations"
                    label="name"
                    class="w-100"
                    :filterable="false"
                    @search="reloadOrganizations"
                    @input="changeOrganization($event, assignedContact.contact)"
                  >
                    <template #no-options="{ search }">
                      <span v-if="search.length">
                        {{ $t('NoData') }}
                      </span>
                      <span v-else>
                        {{ $t('TypeToSearch') }}
                      </span>
                    </template>
                  </v-select>
                </sw-select>
              </div>
            </b-col>
            <!-- Natural Person -->
            <b-col
              v-else
              cols="12"
            >
              <!-- Address -->
              <b-row>
                <b-col>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Country')"
                    :rules="rules"
                  >
                    <b-form-group :label="$t('Country')">
                      <b-form-input
                        id="basicInput"
                        v-model="assignedContact.contact.addressCountry"
                        :placeholder="$t('Country')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('City')"
                    :rules="rules"
                  >
                    <b-form-group :label="$t('City')">
                      <b-form-input
                        id="basicInput"
                        v-model="assignedContact.contact.addressCity"
                        :placeholder="$t('City')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('contact.postalCode')"
                    :rules="rules"
                  >
                    <b-form-group :label="$t('contact.postalCode')">
                      <b-form-input
                        id="basicInput"
                        v-model="assignedContact.contact.addressPostalCode"
                        :placeholder="$t('contact.postalCode')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Street')"
                    :rules="rules"
                  >
                    <b-form-group :label="$t('Street')">
                      <b-form-input
                        id="basicInput"
                        v-model="assignedContact.contact.addressStreet"
                        :placeholder="$t('Street')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('BuildingNumber')"
                    :rules="rules"
                  >
                    <b-form-group :label="$t('BuildingNumber')">
                      <b-form-input
                        id="basicInput"
                        v-model="assignedContact.contact.addressNumber"
                        :placeholder="$t('BuildingNumber')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Pesel')"
                    :rules="rules"
                  >
                    <b-form-group :label="$t('Pesel')">
                      <b-form-input
                        id="basicInput"
                        v-model="assignedContact.contact.personalId"
                        type="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-col>
            <!-- CONTACT :: Additional Documents -->
            <b-col
              sm="12"
            >
              <div>{{ $t('contact.PersonIdentityDocuments') }}
                <feather-icon
                  v-b-tooltip
                  icon="AlertCircleIcon"
                  class="ml-50"
                  :title="$t('OnlyFirstDocument')"
                />
              </div>
              <div v-if="assignedContact.contact.personIdentityDocuments">
                <div
                  v-for="(doc, documentIndex) in assignedContact.contact.personIdentityDocuments"
                  :key="`doc_${index}_${documentIndex}`"
                  class="d-flex flex-lg-nowrap flex-wrap mt-25"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('task.Type')"
                    :rules="rules"
                  >
                    <v-select
                      v-model="doc.type"
                      :options="documentTypes"
                      :placeholder="$t('task.Type')"
                      style="min-width: 200px"
                      class="mr-50 mt-25"
                    >
                      <template #selected-option="{ label }">
                        {{ $t(`contact.${label}`) }}
                      </template>
                      <template #option="{ label }">
                        {{ $t(`contact.${label}`) }}
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="' '"
                    :rules="rules"
                    class="mr-50"
                  >
                    <b-form-input
                      id="basicInput"
                      v-model="doc.number"
                      class="mr-50 mt-25"
                      :placeholder="$t('OrganizationDetails.addressNumber')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('contact.authorityIssuing')"
                    :rules="rules"
                    class="mr-50"
                  >
                    <b-form-input
                      id="basicInput"
                      v-model="doc.authorityIssuing"
                      class="mr-50 mt-25"
                      :placeholder="$t('contact.authorityIssuing')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  <b-form-group class="mr-50">
                    <flat-pickr
                      id="releaseDate"
                      :value="typeof doc.releaseDate === 'object' ? (doc.releaseDate && doc.releaseDate.date ? doc.releaseDate.date : '') : doc.releaseDate"
                      :config="{ locale: getCalendarLocale($i18n.locale) }"
                      class="mr-50 mt-25 form-control"
                      :placeholder="$t('contact.releaseDate')"
                      @input="changeField(doc, 'releaseDate', $event)"
                    />
                  </b-form-group>
                  <b-form-group class="mr-50">
                    <flat-pickr
                      id="expirationDate"
                      :value="typeof doc.expirationDate === 'object' ? (doc.expirationDate && doc.expirationDate.date ? doc.expirationDate.date : '') : doc.expirationDate"
                      :config="{ locale: getCalendarLocale($i18n.locale) }"
                      class="mt-25 form-control"
                      :placeholder="$t('contact.expirationDate')"
                      @input="changeField(doc, 'expirationDate', $event)"
                    />
                  </b-form-group>
                  <b-button
                    variant="flat-danger"
                    class="mr-50 mt-25"
                    @click="$delete(assignedContact.contact.personIdentityDocuments, index)"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </div>
              </div>
              <b-button
                variant="primary"
                size="sm"
                class="mt-50"
                @click="assignedContact.contact.personIdentityDocuments.push({ type: '', number: '', authorityIssuing: '', releaseDate: '', expirationDate: ''})"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                {{ $t('Add') }}
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </app-collapse-item>
    </app-collapse>
  </div>
</template>

<script>
import Cleave from 'vue-cleave-component'

import { countryCodes } from '@/@data/countryCodes'
import { civilStatus, documentTypes, propertyStatus } from '@/@data/contact'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { BAlert, VBTooltip } from 'bootstrap-vue'
import { EDIT_CONTACT, GET_ORGANIZATIONS, OPEN_MODAL } from '@/@constants/mutations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@core/utils/validations/validations'
import axiosIns from '@/libs/axios'
/* eslint-disable */

export default {
  name: 'ContractorRequirements',
  components: {
    Cleave,
    vSelect,
    flatPickr,
    BAlert,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    thread: {
      default: null,
      type: Object,
    },
    selectedAgreement: {
      default: null,
      type: [Object, String, Array],
      required: false,
    }
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data: () => ({
    required,
    documentTypes,
    civilStatus,
    propertyStatus,
    organizations: [],
    countryCodes,
    cleaveOptions: {
      number: {
        delimiter: ' ',
        numeral: true,
        blocks: [3, 3, 3],
      },
    },
  }),

  computed: {
    existedEmails() {
      return [...new Set((this.thread.contactThreadAssignedContacts.filter(contact => contact.emailExist).map(contact => contact.email) || []))]
    },
    existedPhones() {
      return [...new Set((this.thread.contactThreadAssignedContacts.filter(contact => contact.phoneExist).map(contact => contact.phone) || []))]
    },
    isAgreementSelected() {
      return !!this.selectedAgreement
    },
    rules() {
      return this.isAgreementSelected ? 'required' : ''
    },
    getMainContactPart() {
      const contacts = JSON.parse(JSON.stringify(this.thread.contactThreadAssignedContacts))
      contacts.shift()
      return 100 - contacts.reduce((a, { part }) => +a + +part , 0)
    },
    getTotalContactsParts() {
      const contacts = JSON.parse(JSON.stringify(this.thread.contactThreadAssignedContacts))
      return contacts.reduce((a, { part }) => +a + +part , 0)
    },
    getFilledFieldsCount() {
      const arr = []

      const skipFields = ['addressCountry', 'addressStreet', 'addressCity', 'addressPostalCode', 'addressNumber', 'personalId', 'phone', 'organization', 'isOrganization', 'personIdentityDocuments', 'id']
      const person = ['addressCountry', 'addressStreet', 'addressCity', 'addressPostalCode', 'addressNumber', 'personalId']
      this.thread.contactThreadAssignedContacts.forEach(({ contact }) => {
        let total = 0
        let filled = 0

        Object.keys(contact).forEach(field => {
          if (['familyName', 'maritalStatus', 'financialPosition', 'identityCardNumber', 'phoneExist', 'emailExist'].includes(field)) {
            return
          }

          if (!skipFields.includes(field)) {
            total++
            // eslint-disable-next-line no-plusplus
            if (contact[field]) filled++

            if (field === 'email' && contact.emailExist) {
              filled--
            }
          }

          if (contact.isOrganization && field === 'isOrganization') {
            total++
            if (contact.organization) filled++
          } else if (!contact.isOrganization && person.includes(field)) {
            total++
            if (contact[field]) filled++
          }

          if (field === 'phone') {
            total++
            // eslint-disable-next-line no-plusplus
            if (contact[field]?.value) {
              filled++
            }

            if (contact.phoneExist) {
              filled--
            }
          }

          if (field === 'personIdentityDocuments') {
            contact?.personIdentityDocuments.forEach(({ type, number, authorityIssuing, releaseDate, expirationDate }) => {
              total += 5
              if (type) filled++
              if (number) filled++
              if (authorityIssuing) filled++
              if (releaseDate) filled++
              if (expirationDate) filled++
            })
          }
        })
        arr.push({ filled, total })
      })
      return arr
    },
    locale() {
      return this.$i18n.locale
    },
  },
  watch: {
    'thread.contactThreadAssignedContacts': {
      deep: false,
      handler() {
        this.prepareContacts()
      },
    },
  },
  mounted() {
    this.prepareContacts()
  },
  methods: {
    getBirthDate(contact) {
      return typeof contact.birthDate === 'object' ? (contact.birthDate?.date || '') : contact.birthDate
    },
    prepareContacts() {
      this.$nextTick(() => {
        if (!this.thread.contactThreadAssignedContacts.find(ob => ob.contact.id === this.thread.contact.id)) {
          this.thread.contactThreadAssignedContacts.unshift({ contact: this.thread.contact })
        }
        this.thread.contactThreadAssignedContacts.forEach(({ contact }) => {
          const { phone } = contact

          if (typeof phone === 'string') {
            const phoneItem = {
              value: '',
              countryCode: {
                code: 'PL',
                dial_code: '+48 ',
                name: 'Poland',
              },
            }
            contact.phone = phoneItem

            const code = this.countryCodes.find(({ dial_code }) => phone.includes(dial_code))
            if (code) {
              phoneItem.value = phone.replace(code.dial_code, '')
              phoneItem.countryCode = code
            }

            contact.phone = phoneItem
          } else if (phone === null) {
            contact.phone = {
              value: '',
              countryCode: {
                code: 'PL',
                dial_code: '+48 ',
                name: 'Poland',
              },
            }
          }

          // eslint-disable-next-line
          const birthDate = typeof contact.birthDate === 'string' ? contact.birthDate : (contact.birthDate?.date || '')
          this.$set(contact, 'birthDate', birthDate)
          contact.personIdentityDocuments.forEach(doc => {
            // eslint-disable-next-line
            const expirationDate = typeof doc.expirationDate === 'string' ? doc.expirationDate : (doc.expirationDate?.date || '')
            this.$set(doc, 'expirationDate', expirationDate)
            // eslint-disable-next-line
            const releaseDate = typeof doc.releaseDate === 'string' ? doc.releaseDate : (doc.releaseDate?.date || '')
            this.$set(doc, 'releaseDate', releaseDate)
          })
        })

        const c = this.thread.contactThreadAssignedContacts
        Promise.all([this.checkExistedEmailsNew(c), this.checkExistedPhones(c)])
      })
    },
    changeField(item, field, value) {
      this.$set(item, field, value)
    },
    changeOrganization(event, contact) {
      this.$set(contact, 'organization', event)
    },
    addOrganization(isEdited = false, contact) {
      const payload = {
        modalType: 'organization',
        modalTitle: this.$i18n.t('NewOrganization'),
        okButtonTitle: this.$i18n.t('Save'),
      }
      if (isEdited) {
        payload.data = contact.organization
        payload.modalTitle = this.$i18n.t('EditOrganization')
      }
      this.$store.commit(`modal/${OPEN_MODAL}`, payload)
    },
    reloadOrganizations(search, loading) {
      if (search.length) {
        loading(true)
        this.$store.dispatch(`organizations/${GET_ORGANIZATIONS}`, search)
          .then(res => {
            this.organizations = res.data.items
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          })
          .finally(() => {
            loading(false)
          })
      }
    },
    // async validateFields() {
    //   // eslint-disable-next-line
    //   for (const assignedContact of this.thread.contactThreadAssignedContacts) {
    //     // eslint-disable-next-line no-await-in-loop
    //     const isValid = await this.$refs[`contacts_validator_${assignedContact.contact.id}`][0].validate()
    //     if (!isValid) {
    //       this.showToast('error', this.$i18n.t('AllContractorFieldsAreRequired'))
    //       return false
    //     }
    //   }
    //
    //   return true
    // },
    // eslint-disable-next-line consistent-return
    // async save() {
    //   const isValid = await this.validateFields()
    //   if (isValid) {
    //     // eslint-disable-next-line no-return-await
    //     return await this.updateContacts()
    //   }
    //   return false
    // },
    // updateContacts
    save() {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        this.$nextTick(() => {
          const contactsToUpdate = []
          this.thread.contactThreadAssignedContacts.forEach(el => {
            let contact = JSON.parse(JSON.stringify(el.contact))

            if (typeof contact.status !== 'string') {
              contact.status = contact.status?.id
            }

            delete contact.statusUpdatedAt
            if (contact.contactAssignedUsers) contact.contactAssignedUsers = contact.contactAssignedUsers.map(el => el.id)
            if (contact.phoneNumbers) contact.phoneNumbers = contact.phoneNumbers.map(el => el.phone)
            if (contact.mailAddresses) contact.mailAddresses = contact.mailAddresses.map(el => el.mail)
            if (contact.contactTypes) contact.contactTypes = contact.contactTypes.map(el => el.id)
            if (contact.userGroups) contact.userGroups = contact.userGroups.map(el => el.id)

            delete contact.createdAt
            delete contact.createdBy

            const preparedContact = {
              ...contact,
              phoneNumber: contact.phone,
              mailAddress: contact.email,
              // eslint-disable-next-line no-nested-ternary
              organization: contact.organization ? (typeof contact.organization === 'string' ? contact.organization : contact.organization?.id) : null,
              personalId: contact.personalId ? String(contact.personalId) : null,
              birthDate: typeof contact.birthDate === 'object' ? contact.birthDate?.date : contact.birthDate,
              personIdentityDocuments: !contact?.personIdentityDocuments || !contact.personIdentityDocuments.length ? [] : contact.personIdentityDocuments.map(doc => {
                return {
                  ...doc,
                  expirationDate: typeof doc.expirationDate === 'object' ? doc.expirationDate?.date : doc.expirationDate,
                  releaseDate: typeof doc.releaseDate === 'object' ? doc.releaseDate?.date : doc.releaseDate,
                }
              })
            }

            const phone = { ...preparedContact.phone }
            preparedContact.phone = (phone.countryCode?.dial_code || '') + phone.value
            preparedContact.phoneNumber = (phone.countryCode?.dial_code || '') + phone.value

            contactsToUpdate.push(preparedContact)
          })
          const assignedContacts = this.thread.contactThreadAssignedContacts.filter(assignedContact => assignedContact?.id).map(assignedContact => ({
            ...assignedContact,
            contact: typeof assignedContact.contact === 'object' ? assignedContact.contact?.id : assignedContact.contact,
            part: parseFloat(assignedContact?.part ?? 0)
          }))

          Promise.all([this.updateContacts(contactsToUpdate), this.updateAssignedContactsParts(this.thread, assignedContacts)])
              .then(() => {
                resolve(true)
              })
              .catch(err => {
                this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
                reject()
              })
        })
      })
    },
    updateContacts(contacts) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(`contacts/${EDIT_CONTACT}`, contacts)
            .then(() => {
              resolve()
            })
            .catch(err => {
              this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
              reject()
            })
      })
    },
    updateAssignedContactsParts(thread, assignedContacts) {
      return new Promise((resolve, reject) => {
        axiosIns.patch(`1/contacts/${thread.contact.id}/threads`, {
            contact: thread.contact.id,
          contactThreadAssignedContacts: assignedContacts,
          id: thread.id,
        })
            .then(() => {
              resolve()
            })
            .catch(err => {
              reject(err)
            })
      })
    },
    // EXISTED EMAIL AND PHONE
    checkExistedEmailsNew(contacts = [], value = null) {
      const allEmails = contacts.map(({ contact }) => contact.email)
      const duplicateElementa = allEmails.filter((e, i, a) => a.indexOf(e) !== i)

      return new Promise((resolve, reject) => {
        let emails = Array.isArray(contacts) ? contacts.map(({ contact }) => contact.email) : [typeof contacts === 'object' ? contacts.email : contacts]
        emails = emails?.filter(Boolean)
        if (emails.length && !emails[0]) reject()

        axiosIns.post('1/contacts/getExistsByEmails', emails)
            .then(res => {
              const duplicates = [...res.data.data.exists, ...duplicateElementa]
              this.changeExistedEmails(duplicates, contacts)
              resolve()
            })
            .catch(err => {
              this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
              reject()
            })
      })
    },
    changeExistedEmails(duplicates, contacts) {
      contacts.forEach(({ contact }) => {

        for (let d of duplicates) {
          if (typeof d === 'string') {
            if (d === contact.email) {
              this.$set(contact, 'emailExist', true)
              return
            } else {
              this.$set(contact, 'emailExist', false)
            }
          }

          if (typeof d === 'object') {
            if (d?.mail === contact.email && !d?.contacts.find(c => c.id === contact.id)) {
              this.$set(contact, 'emailExist', true)
              return
            } else {
              this.$set(contact, 'emailExist', false)
            }
          }
        }

      })
    },
    // Existed phones
    // eslint-disable-next-line no-unused-vars
    checkExistedPhones(contacts = [], value = null) {
      const allPhones = contacts.map(({contact}) => {
        return typeof contact.phone === 'string' ? String(contact.phone) : String(contact.phone.countryCode.dial_code + contact.phone.value)
      })
      const duplicateElementa = allPhones.filter((e, i, a) => a.indexOf(e) !== i)

      return new Promise((resolve, reject) => {
        const phones = Array.isArray(contacts) ?
            contacts.map(({contact}) => {
              return typeof contact.phone === 'string' ? String(contact.phone) : String(contact.phone.countryCode.dial_code + contact.phone.value)
            }) :
            [typeof contacts === 'object' ? (typeof contacts.phone === 'string' ? String(contacts.phone) : String(contacts.phone.countryCode.dial_code + contacts.phone.value)) : contacts]

        axiosIns.post('1/contacts/getExistsByPhones', phones)
            .then(res => {
              const duplicates = [...res.data.data.exists, ...duplicateElementa]
              this.changeExistedPhones(duplicates, contacts)
              resolve()
            })
            .catch(err => {
              this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
              reject()
            })
      })
    },
    changeExistedPhones(duplicates, contacts) {
      contacts.forEach(({contact}) => {
        const phone = typeof contact.phone === 'string' ? String(contact.phone) : String(contact.phone.countryCode.dial_code + contact.phone.value)

        for (let d of duplicates) {
          if (typeof d === 'string') {
            if (d === phone) {
              this.$set(contact, 'phoneExist', true)
              return
            } else {
              this.$set(contact, 'phoneExist', false)
            }
          }

          if (typeof d === 'object') {
            if (d?.phone === phone && !d?.contacts.find(c => c.id === contact.id)) {
              this.$set(contact, 'phoneExist', true)
              return
            } else {
              this.$set(contact, 'phoneExist', false)
            }
          }
        }
      })
    },
  },
}
</script>

<style scoped>

</style>
