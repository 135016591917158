<template>
  <b-col
    v-if="isApplication"
    class="px-md-50 px-lg-0"
    md="6"
    lg="12"
  >
    <div class="mb-1 align-items-center pb-0 rounded card"
         :class="{ 'bg-light-primary': application.isFromParam }"
    >
      <div class="d-flex flex-wrap justify-content-between p-1 mt-50 w-100 px-lg-3">
        <div
          style="min-width: min(175px, 100%)"
          :style="`width: ${isMobile ? 'max-content' : '175px'}`"
          class="flex-grow-1 mb-2 mb-lg-0"
        >
          <p
            v-if="isMobile"
            class="h5 mb-50"
          >
            {{ $t('Actions') }}:
          </p>

          <div class="d-flex">
            <b-button
              v-if="checkPermissions([$roles.APPLICATION_DELETE])"
              v-b-tooltip.v-danger
              size="sm"
              variant="outline-danger"
              class="text-uppercase btn-icon mr-25"
              :title="$t('Delete')"
              :disabled="!checkPermissions([$roles.APPLICATION_DELETE])"
              @click="$emit('delete-application')"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>

            <div v-if="application.status === 'NEW'">
              <b-button
                v-b-tooltip.v-primary
                size="sm"
                variant="primary"
                class="text-uppercase btn-icon"
                :title="$t('TakeIt')"
                @click="$emit('accept-application', application)"
              >
                <feather-icon icon="ArchiveIcon" />
              </b-button>

              <b-button
                v-b-tooltip.v-danger
                size="sm"
                variant="outline-danger"
                class="text-uppercase btn-icon ml-25"
                :title="$t('Reject')"
                @click="$emit('reject-application')"
              >
                <feather-icon icon="XIcon" />
              </b-button>
            </div>

            <div
              v-else
              class="d-flex flex-wrap"
              style="column-gap: .5rem; row-gap: .5rem;"
            >
              <b-button
                v-if="application.status === 'ACCEPTED'"
                v-b-tooltip
                variant="outline-success"
                size="sm"
                class="d-flex align-items-center"
                :title="application.servedBy ? getUserName(application.servedBy) : ''"
              >
                {{ $t('WasTaken') }}

                <template v-if="application.servedBy">
                  {{ $t('By').toLowerCase() }}

                  <b-avatar
                    size="18"
                    :src="application.servedBy.avatar"
                    class="ml-50"
                  />
                </template>
              </b-button>

              <div v-else>
                <div :id="`rejected_${application.id}`">
                  <b-button
                    v-b-tooltip
                    variant="outline-danger"
                    size="sm"
                    disabled
                    :title="application.contactApplicationStatusReason ? $t('Reason') + ': ' + application.contactApplicationStatusReason.name : ''"
                    class="d-flex align-items-center"
                  >
                    {{ $t('AgreementStatuses.REJECTED') }}

                    <template v-if="application.servedBy">
                      {{ $t('By').toLowerCase() }}

                      <b-avatar
                        size="18"
                        :src="application.servedBy.avatar"
                        class="ml-50"
                      />
                    </template>
                  </b-button>
                </div>

                <b-popover
                  container="application-container"
                  :target="`rejected_${application.id}`"
                  triggers="hover"
                >
                  <b-list-group flush>
                    <b-list-group-item>
                      <span class="font-weight-bolder">{{ $t('AgreementStatuses.REJECTED') }} {{ $t('By').toLowerCase() }}</span>

                      <b-avatar
                        size="18"
                        :src="application.servedBy.avatar"
                        class="ml-50"
                      />
                    </b-list-group-item>
                    <b-list-group-item>
                      <span class="font-weight-bolder">{{ $t('Reason') }}:</span>
                      <span v-if="application.contactApplicationStatusReason">
                        {{ application.contactApplicationStatusReason.name }}
                      </span>
                      <feather-icon
                        v-else
                        icon="MinusIcon"
                      />
                    </b-list-group-item>
                    <b-list-group-item>
                      <span class="font-weight-bolder">{{ $t('Note') }}:</span>
                      <template v-if="application.note">
                        {{ application.note }}
                      </template>
                      <feather-icon
                        v-else
                        icon="MinusIcon"
                      />
                    </b-list-group-item>

                    <b-list-group-item>
                      <span class="font-weight-bolder">{{ $t('RejectionDate') }}:</span>
                      <template v-if="application.servedAt">
                        {{ formatDate(application.servedAt.date, { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' }, $i18n.locale) }}
                      </template>
                      <feather-icon
                        v-else
                        icon="MinusIcon"
                      />
                    </b-list-group-item>
                  </b-list-group>
                </b-popover>
                <!--              {{ application.rejectedBy }}-->
              </div>
            </div>
          </div>
        </div>

        <div
          style="min-width: min(200px, 100%)"
          :style="`width: ${isMobile ? 'max-content' : '200px'}`"
          class="flex-grow-1 mb-2 mb-lg-0"
        >
          <p
            v-if="isMobile"
            class="h5 mb-50"
          >
            {{ $t('ApplicationType') }}:
          </p>
          <div v-if="application.test">
            <b-badge
              variant="light-danger"
              class="mb-25 text-capitalize"
            >
              TEST
            </b-badge>

            <!--            <b-button-->
            <!--              size="sm"-->
            <!--              variant="primary"-->
            <!--              class="text-uppercase btn-icon"-->
            <!--            >-->
            <!--              TEST-->
            <!--            </b-button>-->
          </div>

          <b-badge
            v-if="application.pluginMake"
            variant="light-primary"
            class="mb-25 text-capitalize"
          >
            Make
          </b-badge>
          <b-badge
            v-if="application.pluginZapier"
            variant="light-primary"
            class="mb-25 text-capitalize"
          >
            Zapier
          </b-badge>
          <b-badge
            v-if="application.contactApplicationIntegration"
            variant="light-primary"
            class="mb-25 text-capitalize"
          >
            {{ typeName(application.contactApplicationIntegration.type) }}
          </b-badge>
          <b-badge
            v-if="application.integrationFacebookWebhook"
            variant="light-primary"
            class="mb-25 text-capitalize"
          >
            Facebook
          </b-badge>
          <b-badge
            v-if="application.clientChatMessage"
            variant="light-primary"
            class="mb-25 text-capitalize"
          >
            {{ $t('LeadGenerator') }}
            <span v-if="application.clientChatMessage && application.clientChatMessage.clientChat && application.clientChatMessage.clientChat.browserSession && application.clientChatMessage.clientChat.browserSession.widgetChat">- {{ $t('Widget') }}</span>
            <span v-if="application.clientChatMessage && application.clientChatMessage.clientChat && application.clientChatMessage.clientChat.integrationWhatsAppSubscriptionPhoneNumber">- {{ $t('Whatsapp') }}</span>
            <span v-if="application.clientChatMessage && application.clientChatMessage.clientChat.chatClient">- {{ $t('MobileClients') }}</span>
          </b-badge>

          <div
            v-if="application.integrationFacebookWebhook"
            v-b-tooltip
            class="mb-25"
            :title="application.integrationFacebookWebhook.name"
          >
            {{ application.integrationFacebookWebhook.name | truncate(20) }}
          </div>
          <div v-if="application.clientChatMessage && application.clientChatMessage.clientChat && application.clientChatMessage.clientChat.browserSession && application.clientChatMessage.clientChat.browserSession.widgetChat">
            <span>{{ application.clientChatMessage.clientChat.browserSession.widgetChat.name }}</span>
          </div>
          <div v-if="application.clientChatMessage && application.clientChatMessage.clientChat && application.clientChatMessage.clientChat.integrationWhatsAppSubscriptionPhoneNumber && application.clientChatMessage.clientChat.integrationWhatsAppSubscriptionPhoneNumber.whatsAppPhoneNumberDisplay">
            <span>{{ application.clientChatMessage.clientChat.integrationWhatsAppSubscriptionPhoneNumber.whatsAppPhoneNumberDisplay }}</span>
          </div>
          <div
            v-if="application.contactApplicationIntegration"
            v-b-tooltip
            class="mb-25"
            :title="application.contactApplicationIntegration.name"
          >
            {{ application.contactApplicationIntegration.name | truncate(20) }}
          </div>
          <div
            v-if="application.pluginMake"
            v-b-tooltip
            class="mb-25"
            :title="application.pluginMake.name"
          >
            {{ application.pluginMake.name | truncate(20) }}
          </div>
          <div
            v-if="application.pluginZapier"
            v-b-tooltip
            class="mb-25"
            :title="application.pluginZapier.name"
          >
            {{ application.pluginZapier.name | truncate(20) }}
          </div>

          <div
            v-b-tooltip
            :title="$t('Created')"
          >{{ formatDate(application.createdAt.date, { month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' }, $i18n.locale) }}</div>
          <div
            v-if="application.leadgenCreatedAt"
            v-b-tooltip
            :title="$t('LeadgenCreatedAt')"
          >
            {{ formatDate(application.leadgenCreatedAt.date, { month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' }, $i18n.locale) }}
          </div>
        </div>

        <div
          style="min-width: min(250px, 100%)"
          :style="`width: ${isMobile ? 'max-content' : '250px'}`"
          class="flex-grow-1 mb-2 mb-lg-0"
        >
          <p
            v-if="isMobile"
            class="h5 mb-50"
          >
            {{ $t('BasicInformation') }}:
          </p>

          <div>
            <div>
              {{ getName(application) }}
            </div>

            <div>
              <feather-icon
                v-if="isMailExists"
                :id="`email_exist_button_${key}`"
                icon="AlertCircleIcon"
                size="12"
              />

              <b-popover
                v-if="isMailExists"
                :target="`email_exist_button_${key}`"
                triggers="hover"
              >
                <template #title>
                  <span>{{ $t('TypedEmailExist') }}</span>
                </template>
                <div
                  v-for="existedContact in isMailExists.contacts"
                  :key="`contact_email_${existedContact.id}_${key}`"
                >
                  <router-link :to="{ name: 'contact', params: { contactId: existedContact.id } }">
                    {{ existedContact.firstName }} {{ existedContact.lastName }}
                  </router-link>
                </div>
                <!--                        test@mail-saleswizardapp.com-->
              </b-popover>

              <span
                v-if="application.mailAddress"
                v-b-tooltip
                :title="application.mailAddress.mail"
              >
                {{ application.mailAddress.mail | truncate(25) }}
              </span>

              <feather-icon
                v-else
                icon="MinusIcon"
              />
            </div>
            <div>
              <feather-icon
                v-if="isPhoneExists"
                :id="`phone_exist_button_${key}`"
                icon="AlertCircleIcon"
                size="12"
                class="mr-25"
              />
              <b-popover
                v-if="isPhoneExists"
                :target="`phone_exist_button_${key}`"
                triggers="hover"
              >
                <template #title>
                  <span>{{ $t('TypedPhoneExist') }}</span>
                </template>
                <div
                  v-for="existedContact in isPhoneExists.contacts"
                  :key="`contact_phone_${existedContact.id}_${key}`"
                >
                  <router-link :to="{ name: 'contact', params: { contactId: existedContact.id } }">
                    {{ existedContact.firstName }} {{ existedContact.lastName }}
                  </router-link>
                </div>
                <!--                        test@mail-saleswizardapp.com-->
              </b-popover>
              <span v-if="application.phoneNumber">
                {{ application.phoneNumber.phone }}
              </span>
              <feather-icon
                v-else
                icon="MinusIcon"
              />
            </div>
          </div>
        </div>

        <div
          style="min-width: min(400px, 100%)"
          :style="`width: ${isMobile ? 'max-content' : '400px'}`"
          class="flex-grow-1 mb-0 mb-lg-0"
        >
          <p
            v-if="isMobile"
            class="h5 mb-50"
          >
            {{ $t('SuggestedMaintainer') }}:
          </p>

          <div v-if="suggestedMaintainer.length && !application.contactThread">
            <div
              v-for="(suggest, index) in suggestedMaintainer"
              :key="`suggest_${key}_${index}`"
            >
              <avatar
                :user="suggest"
                show-name
              />
            </div>
          </div>

          <div
            v-else-if="application.contactThread"
            class="d-flex flex-wrap mt-n1"
          >
            <div v-if="application.contactThread.contact"
                 class="mr-1 mt-1"
            >
              {{ $t('contact.assignedUsers') }}

              <avatar-group
                :key="application.id"
                class="mt-50"
                :items="application.contactThread.contact.contactAssignedUsers"
                items-field="user"
              />
            </div>

            <div class="mt-1">
              <p class="mb-25">
                {{ $t('WasTakenBy') }}
              </p>

              <avatar
                :to="{ name: 'contact', params: { contactId: application.contactThread.contact.id, threadId: application.contactThread.id } }"
                force-link
                :user="application.contactThread.contact"
                show-name
              >
                <template #subtitle>
                  {{ application.contactThread.name | truncate(20) }}
                </template>
              </avatar>
            </div>
          </div>

          <feather-icon
            v-else
            icon="MinusIcon"
          />
        </div>
      </div>

      <sw-transition-collapse>
        <div
          v-if="isVisible"
          class="d-flex flex-column w-100 px-lg-3"
        >
          <b-row class="mb-1">
            <b-col
              v-for="(field, index) in application.contactApplicationFields"
              :key="`field_${key}_${index}`"
              lg="6"
            >
              <div class="d-flex flex-wrap justify-content-between px-1 py-50 border-top">
                <span
                  v-if="$te(`ApplicationsFields.${field.type}`)"
                  v-b-tooltip
                  :title="$t(`ApplicationsFields.${field.type}`)"
                >{{ $t(`ApplicationsFields.${field.type}`) | textIsland(45) }}</span>
                <span
                  v-else
                  v-b-tooltip
                  :title="typeName(field.type)"
                >{{ typeName(field.type) | textIsland(45) }}</span>

                <div v-if="field.value || (field.files && field.files.length)">
                  <strong v-if="field.value">{{ field.value }}</strong>
                  <documents-list v-if="field.files && field.files.length"
                                  :documents="field.files"
                  />
                </div>

                <feather-icon
                  v-else
                  icon="MinusIcon"
                />
              </div>
            </b-col>
          </b-row>

          <div
            v-if="application.message"
            class="px-3 px-md-3 px-lg-5"
          >
            <b-alert
              show
              variant="primary"
            >
              <div class="alert-heading">
                {{ $t('Message') }}
              </div>
              <div class="alert-body">
                {{ application.message }}
              </div>
            </b-alert>
          </div>

          <div
            class="px-3 px-md-3 px-lg-5"
          >
            <b-alert
              v-if="application.contactApplicationStatusReason || application.note"
              show
              variant="primary"
            >
              <div class="alert-heading">
                {{ $t('Reason') }}:
                <span v-if="application.contactApplicationStatusReason">
                  {{ application.contactApplicationStatusReason.name }}
                </span>
                <feather-icon
                  v-else
                  icon="MinusIcon"
                />
              </div>

              <div class="alert-body">
                {{ $t('Note') }}:
                <template v-if="application.note">
                  {{ application.note }}
                </template>
                <feather-icon
                  v-else
                  icon="MinusIcon"
                />
              </div>
            </b-alert>
          </div>
        </div>
      </sw-transition-collapse>

      <div
        class="w-100 bg-light-primary rounded-bottom text-center"
        @click="isVisible = !isVisible"
      >
        <feather-icon :icon="!isVisible ? 'ChevronDownIcon' : 'ChevronUpIcon'" />
      </div>
    </div>

  </b-col>
</template>

<script>

import { formatDate } from '@core/utils/filter'
import { BPopover, VBTooltip } from 'bootstrap-vue'
import { onUnmounted, ref } from '@vue/composition-api'
import useVerticalLayout from '@core/layouts/layout-vertical/useVerticalLayout'
import checkRanks from '@/helpers/checkRanks'
import DocumentsList from '@/views/components/DocumentsList.vue'

export default {
  name: 'ApplicationsCard',
  components: {
    DocumentsList,
    BPopover,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    isMailExists: {
      type: [Boolean, Object],
      default: null,
    },
    isPhoneExists: {
      type: [Boolean, Object],
      default: null,
    },
    application: {
      type: [Object],
      default: null,
    },
  },
  setup() {
    const key = Math.random().toString(16).slice(2)

    const isVisible = ref(false)

    const typeName = type => {
      const i = type.replaceAll('_', ' ').toLowerCase()
      return i.charAt(0).toUpperCase() + i.slice(1)
    }

    const { isMobile, resizeHandler } = useVerticalLayout()

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    return {
      key,
      isVisible,
      typeName,
      perfectScrollbarSettings,
      isMobile,
    }
  },
  computed: {
    isApplication() {
      return Boolean(this.application)
    },
    suggestedMaintainer() {
      const contacts = []
      if (this.isMailExists) contacts.push(...this.isMailExists.contacts)
      if (this.isPhoneExists) contacts.push(...this.isPhoneExists.contacts)

      return contacts.removeDuplicates()
    },
  },
  methods: {
    formatDate,
    getName(item) {
      return `${item?.firstName || ''} ${item?.lastName || ''}`
    },
    getStatusColor(status) {
      switch (status) {
        case 'NEW': return 'primary'
        case 'REJECTED': return 'danger'
        case 'DONE': return 'success'
        default: return 'warning'
      }
    },

    checkPermissions(...requiredPermissions) {
      const currentUser = this.$store.getters['auth/getCurrentUser']
      const user = typeof currentUser === 'string' ? JSON.parse(currentUser) : currentUser
      const userPermissions = user.rank.permissions

      return checkRanks(userPermissions, ...requiredPermissions)
    },
  },
}
</script>

<style lang="scss">
#application-container {
  .popover {
    min-width: 400px !important;
    max-width: 400px !important;
    .popover-body {
      padding: 0.65rem 0 !important;
    }
  }
}
</style>
