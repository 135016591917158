/* eslint-disable */
import i18n from '@/libs/i18n'
import { showToast } from '@core/mixins/ui/toast'
// eslint-disable-next-line import/no-cycle
import axiosIns from '@/libs/axios'

const splitToChunks = (array, size = 500) => {
  const result = []
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size))
  }

  return result
}

const uploadRequest = (data, url, method) => new Promise((resolve, reject) => {
  if (method === 'POST') {
    axiosIns.post(url, data)
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
  } else {
    axiosIns.patch(url, data)
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
  }
})

export default {
  namespaced: true,
  state: {
    totalRecords: 0,
    requestCount: 0,
    doneRequests: 0,
    loading: false,
    label: '',
  },
  getters: {
    importerData: state => state,
  },
  mutations: {
    SET_LOADING(state, value) {
      state.loading = value
    },
    SET_TOTAL_RECORDS(state, value) {
      state.totalRecords = value
    },
    SET_LABEL(state, value) {
      state.label = value
    },
    INCRESE_DONE_REQUESTS(state, value) {
      state.doneRequests = value
    },
    SET_REQUESTS_COUNT(state, value = null) {
      if (value !== null) state.requestCount = value
      else state.requestCount++
    },
    CLEAR(state) {
      state.totalRecords = 0
      state.requestCount = 0
      state.doneRequests = 0
      state.loading = false
      state.label = ''
    }
  },
  actions: {
    UPLOAD_IMPORTED_CONTACTS: async ({ commit, state }, { chunkSize = 500, data = [], uploadUrl = '', importerLabel = 'Contacts', method = 'POST' }) => new Promise((resolve, reject) => {
      commit('SET_LABEL', i18n.t(importerLabel))
      commit('SET_TOTAL_RECORDS', data.length || 0)

      const chunks = splitToChunks(data, chunkSize)
      commit('SET_REQUESTS_COUNT', chunks.length || 0)

      commit('SET_LOADING', true)

      if (chunks.length) Promise.all(chunks.map(chunk => uploadRequest(chunk, uploadUrl, method)))
        .then(res => {
          commit('SET_LOADING', false)
          commit('CLEAR', false)
          resolve(res)
        })
        .catch(err => {
          commit('SET_LOADING', false)
          console.error('Importer Error: ', err)
          showToast('error', 'Coś poszło nie tak')
          reject([])
        })
      else {
        commit('CLEAR', false)
        resolve([])
      }

    }),
  },
}
