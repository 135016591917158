export const SET_DEFAULT_STATE = 'SET_DEFAULT_STATE'

export const SET_THREAD_ID = 'SET_THREAD_ID'
export const CREATE_NOTE = 'CREATE_NOTE'
export const CREATE_ORGANIZATION = 'CREATE_ORGANIZATION'
export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION'
export const CREATE_TASK = 'CREATE_TASK'
export const CREATE_REPORT = 'CREATE_REPORT'
export const CREATE_SMS = 'CREATE_SMS'
export const CREATE_MAIL = 'CREATE_MAIL'

export const OPEN_MODAL = 'OPEN_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'
export const RELOAD_CONTENT = 'RELOAD_CONTENT'
// export const HIDE_MODAL = 'HIDE_MODAL'

// Global Tasks
export const GET_GLOBAL_TASKS = 'GET_GLOBAL_TASKS'

// Funnel
export const GET_FUNNEL_POINTS = 'GET_FUNNEL_POINTS'
export const GET_FUNNELS = 'GET_FUNNELS'

// Documents
export const OPEN_FOLDER = 'OPEN_FOLDER'
export const CLOSE_FOLDER = 'CLOSE_FOLDER'

// Auth
export const AUTH_REQUEST = 'AUTH_REQUEST'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_ERROR = 'AUTH_ERROR'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const LOGOUT_SESSION = 'LOGOUT_SESSION'

// Contact OLD
export const GET_USER_CONTACTS = 'GET_USER_CONTACTS'
export const SET_USER = 'SET_USER'
export const GET_TIMELINE = 'GET_TIMELINE'
export const GET_TIMELINE_STATS = 'GET_TIMELINE_STATS'
export const GET_CONTACT_TASKS = 'GET_CONTACT_TASKS'

// Contact
export const LOAD_CURRENT_CONTACT = 'LOAD_CURRENT_CONTACT'
export const CREATE_CONTACT = 'CREATE_CONTACT'
export const EDIT_CONTACT = 'EDIT_CONTACT'
export const REMOVE_CONTACTS = 'REMOVE_CONTACTS'
export const SWITCH_PRIMARY_THREAD = 'SWITCH_PRIMARY_THREAD'
export const REMOVE_THREAD = 'REMOVE_THREAD'
export const CHANGE_STATUS = 'CHANGE_STATUS'
export const GET_THREAD_TASKS = 'GET_THREAD_TASKS'
export const GET_THREAD_TASK = 'GET_THREAD_TASK'
export const GET_CURRENT_THREAD_TASK = 'GET_CURRENT_THREAD_TASK'
export const GET_CURRENT_THREAD_TASKS = 'GET_CURRENT_THREAD_TASKS'
export const GET_CONTACT = 'GET_CONTACT'
export const GET_CONTACT_INFO = 'GET_CONTACT_INFO'
export const GET_THREAD_LIST_ITEM = 'GET_THREAD_LIST_ITEM'
export const GET_THREAD = 'GET_THREAD'
export const GET_THREAD_LIST = 'GET_THREAD_LIST'
export const GET_CONTACT_THREAD_CLOSE_STATUS_REASONS = 'GET_CONTACT_THREAD_CLOSE_STATUS_REASONS'
export const REMOVE_CONTACT = 'REMOVE_CONTACT'
export const REMOVE_CURRENT_CONTACT = 'REMOVE_CURRENT_CONTACT'
export const REMOVE_CURRENT_CONTACT_THREAD = 'REMOVE_CURRENT_CONTACT_THREAD'
export const UPDATE_CONTACT_THREAD_FUNNEL_PROCESS = 'UPDATE_CONTACT_THREAD_FUNNEL_PROCESS'
export const UPDATE_CURRENT_CONTACT_THREAD_FUNNEL_PROCESS = 'UPDATE_CURRENT_CONTACT_THREAD_FUNNEL_PROCESS'
export const GET_CONTACT_THREAD_FUNNEL_PROCESS = 'GET_CONTACT_THREAD_FUNNEL_PROCESS'
export const CONTACT_THREAD_LIST_BASE = 'CONTACT_THREAD_LIST_BASE'
export const GET_CURRENT_CONTACT_THREAD_FUNNEL_PROCESS = 'GET_CURRENT_CONTACT_THREAD_FUNNEL_PROCESS'
export const CHANGE_CURRENT_CONTACT_THREAD_FUNNEL_PROCESS_ACTIVE = 'CHANGE_CURRENT_CONTACT_THREAD_FUNNEL_PROCESS_ACTIVE'
export const CLOSE_CONTACT_THREAD_FUNNEL_PROCESS = 'CLOSE_CONTACT_THREAD_FUNNEL_PROCESS'
export const CLOSE_CURRENT_CONTACT_THREAD_FUNNEL_PROCESS = 'CLOSE_CURRENT_CONTACT_THREAD_FUNNEL_PROCESS'
export const ADD_CONTACT_THREAD_FUNNEL_PROCESS = 'ADD_CONTACT_THREAD_FUNNEL_PROCESS'
export const ADD_CURRENT_CONTACT_THREAD_FUNNEL_PROCESS = 'ADD_CURRENT_CONTACT_THREAD_FUNNEL_PROCESS'
export const GET_CURRENT_CONTACT = 'GET_CURRENT_CONTACT'
export const GET_CURRENT_CONTACT_THREAD_LIST = 'GET_CURRENT_CONTACT_THREAD_LIST'
export const GET_FULL_CURRENT_CONTACT_THREAD_LIST = 'GET_FULL_CURRENT_CONTACT_THREAD_LIST'
export const GET_CONTACT_ASSIGNED_THREADS = 'GET_CONTACT_ASSIGNED_THREADS'
export const GET_CURRENT_CONTACT_ASSIGNED_THREADS = 'GET_CURRENT_CONTACT_ASSIGNED_THREADS'
export const GET_CURRENT_CONTACT_IDENTITY_DOCUMENTS = 'GET_CURRENT_CONTACT_IDENTITY_DOCUMENTS'
export const GET_CONTACT_DELIVERY_ADDRESSES = 'GET_CONTACT_DELIVERY_ADDRESSES'
export const GET_CONTACT_IDENTITY_DOCUMENTS = 'GET_CONTACT_IDENTITY_DOCUMENTS'
export const GET_CURRENT_CONTACT_DELIVERY_ADDRESSES = 'GET_CURRENT_CONTACT_DELIVERY_ADDRESSES'
export const GET_CURRENT_CONTACT_TASKS = 'GET_CURRENT_CONTACT_TASKS'
export const CHANGE_CONTACT_STATUS = 'CHANGE_CONTACT_STATUS'
export const CHANGE_CURRENT_CONTACT_STATUS = 'CHANGE_CURRENT_CONTACT_STATUS'

// Applications
export const GET_APPLICATIONS = 'GET_APPLICATIONS'
export const UPDATE_APPLICATION = 'UPDATE_APPLICATION'
export const TEST_APPLICATION = 'TEST_APPLICATION'

// Single contact
export const GET_SINGLE_CONTACT = 'GET_SINGLE_CONTACT'
export const GET_EDITED_CONTACT = 'GET_EDITED_CONTACT'
export const GET_CONTACT_TIMELINE = 'GET_CONTACT_TIMELINE'
export const GET_CONTACT_CHECKLIST = 'GET_CONTACT_CHECKLIST'
export const TEST_SINGLE_CONTACT = 'TEST_SINGLE_CONTACT'
export const GET_CONTACT_SINGLE_TASK = 'GET_CONTACT_SINGLE_TASK'
export const DELETE_CONTACT_TASK = 'DELETE_CONTACT_TASK'
export const UPDATE_CONTACT_TASK = 'UPDATE_CONTACT_TASK'

export const DELETE_CONTACT_NOTE = 'DELETE_CONTACT_NOTE'

export const GET_CONTACT_DOCUMENTS = 'GET_CONTACT_DOCUMENTS'
export const GET_CONTACT_DOCUMENTS_FOLDER = 'GET_CONTACT_DOCUMENTS_FOLDER'
export const GET_CONTACT_OFFERS = 'GET_CONTACT_OFFERS'
export const UPDATE_CONTACT_THREAD = 'UPDATE_CONTACT_THREAD'
export const DELETE_THREAD = 'DELETE_THREAD'
// Single contact threads
export const CREATE_NEW_THREAD = 'CREATE_NEW_THREAD'
export const GET_CONTACT_THREADS = 'GET_CONTACT_THREADS'
export const GET_CONTACT_FUNNELS = 'GET_CONTACT_FUNNELS'
export const GET_CURRENT_CONTACT_FUNNELS = 'GET_CURRENT_CONTACT_FUNNELS'
export const GET_CONTACT_ADDITIONAL_FIELDS = 'GET_CONTACT_ADDITIONAL_FIELDS'
export const GET_CURRENT_CONTACT_ADDITIONAL_FIELDS = 'GET_CURRENT_CONTACT_ADDITIONAL_FIELDS'
export const REMOVE_CONTACT_ADDITIONAL_FIELD = 'REMOVE_CONTACT_ADDITIONAL_FIELD'
export const REMOVE_CURRENT_CONTACT_ADDITIONAL_FIELD = 'REMOVE_CURRENT_CONTACT_ADDITIONAL_FIELD'
export const GET_CONTACT_FUNNEL_POINTS = 'GET_CONTACT_FUNNEL_POINTS'
export const GET_CURRENT_CONTACT_FUNNEL_POINTS = 'GET_CURRENT_CONTACT_FUNNEL_POINTS'
export const GET_CONTACT_SINGLE_THREAD = 'GET_CONTACT_SINGLE_THREAD'

// Users
export const GET_USERS = 'GET_USERS'
export const GET_SINGLE_USER = 'GET_SINGLE_USER'
export const GET_SELECT_USERS = 'GET_SELECT_USERS'
export const CREATE_USER = 'CREATE_USER'
export const EDIT_USER = 'EDIT_USER'
export const EDIT_USER_LANG = 'EDIT_USER_LANG'
export const REMOVE_USERS = 'REMOVE_USERS'
export const CHANGE_USER_STATUS = 'CHANGE_USER_STATUS'
export const GET_USER_HISTORY = 'GET_USER_HISTORY'

// Emails
export const GET_THREAD_CONVERSATION = 'GET_THREAD_CONVERSATION'
export const GET_THREAD_CONVERSATIONS_FILES = 'GET_THREAD_CONVERSATIONS_FILES'

// Profile Settings
export const CHANGE_USER_PASSWORD = 'CHANGE_USER_PASSWORD'
export const CHANGE_USER_AVATAR = 'CHANGE_USER_AVATAR'

// Settings = Contact statusese
export const SAVE_CONTACT_STATUSES = 'SAVE_CONTACT_STATUSES'
export const GET_CONTACT_STATUSES = 'GET_CONTACT_STATUSES'
export const DELETE_CONTACT_STATUSES = 'DELETE_CONTACT_STATUSES'

export const DELETE_THREAD_TAGS = 'DELETE_THREAD_TAGS'
export const GET_THREAD_TAGS = 'GET_THREAD_TAGS'
export const SAVE_THREAD_TAGS = 'SAVE_THREAD_TAGS'

// Profile Settings Mailbox
export const GET_USER_MAILBOXES = 'GET_USER_MAILBOXES'
export const GET_USER_MAILBOX = 'GET_USER_MAILBOX'
export const ADD_USER_MAILBOX = 'ADD_USER_MAILBOX'
export const UPDATE_USER_MAILBOX = 'UPDATE_USER_MAILBOX'
export const TEST_USER_MAILBOX = 'TEST_USER_MAILBOX'

// Profile Settings Booking
export const ADD_BOOKING = 'ADD_BOOKING'
export const GET_BOOKING_ALL = 'GET_BOOKING_ALL'
export const GET_BOOKING = 'GET_BOOKING'
export const UPDATE_BOOKING = 'UPDATE_BOOKING'
export const DELETE_BOOKING = 'DELETE_BOOKING'

// Settings = Notes
export const GET_NOTES = 'GET_NOTES'
export const DELETE_NOTES = 'DELETE_NOTES'
export const SAVE_NOTES = 'SAVE_NOTES'

// Settings = System
export const SAVE_SETTINGS = 'SAVE_SETTINGS'
export const GET_SETTINGS = 'GET_SETTINGS'
export const GET_PRIVATE_SETTINGS = 'GET_PRIVATE_SETTINGS'

// Settings = Nav
export const UPDATE_NAV = 'UPDATE_NAV'

// Settings = Tasks
export const GET_TASKS = 'GET_TASKS'
export const DELETE_TASKS = 'DELETE_TASKS'
export const SAVE_TASKS = 'SAVE_TASKS'

// Settings
export const RELOAD_SETTINGS = 'RELOAD_SETTINGS'
export const GET_STATUSES = 'GET_STATUSES'
export const GET_CHECKLIST = 'GET_CHECKLIST'

// Settings - delete
export const DELETE_STATUSES = 'DELETE_STATUSES'
export const DELETE_CHECKLIST = 'DELETE_CHECKLIST'

// Settings - templates
export const SAVE_FOOTER = 'SAVE_FOOTER'
export const GET_FOOTER = 'GET_FOOTER'
export const SAVE_OFFER_EMAIL = 'SAVE_OFFER_EMAIL'
export const GET_OFFER_EMAIL = 'GET_OFFER_EMAIL'
export const SAVE_TEMPLATE = 'SAVE_TEMPLATE'
export const DELETE_TEMPLATE = 'DELETE_TEMPLATE'
export const GET_TEMPLATES = 'GET_TEMPLATES'

// Settings - groups
export const GET_GROUPS = 'GET_GROUPS'
export const DELETE_GROUPS = 'DELETE_GROUPS'
export const SAVE_GROUPS = 'SAVE_GROUPS'

// Settings - chats
export const GET_WIDGET_CHAT = 'GET_WIDGET_CHAT'
export const GET_WIDGET_CHATS = 'GET_WIDGET_CHATS'
export const CREATE_WIDGET_CHATS = 'CREATE_WIDGET_CHATS'
export const UPDATE_WIDGET_CHAT = 'UPDATE_WIDGET_CHAT'
export const DELETE_WIDGET_CHAT = 'DELETE_WIDGET_CHAT'

// Uploader
export const UPLOAD_FILES = 'UPLOAD_FILES'
export const DOWNLOAD_FILE = 'DOWNLOAD_FILE'
export const SHOW_UPLOAD_TOAST = 'SHOW_UPLOAD_TOAST'
export const SET_TOAST_ITEM_PROGRESS = 'SET_TOAST_ITEM_PROGRESS'

// Buildings
// Construction
export const GET_CONSTRUCTION = 'GET_CONSTRUCTION'
export const GET_CONSTRUCTIONS = 'GET_CONSTRUCTIONS'
export const CREATE_CONSTRUCTION = 'CREATE_CONSTRUCTION'
export const UPDATE_CONSTRUCTION = 'UPDATE_CONSTRUCTION'
export const DELETE_CONSTRUCTION = 'DELETE_CONSTRUCTION'
export const GET_PROTOCOLS = 'GET_PROTOCOLS'
export const ADD_CONSTRUCTION_PERMITFILE = 'ADD_CONSTRUCTION_PERMITFILE'

// Contractors
export const CREATE_CONTRACTOR = 'CREATE_CONTRACTOR'
export const DELETE_CONTRACTOR = 'DELETE_CONTRACTOR'
export const GET_CONTRACTOR = 'GET_CONTRACTOR'
export const GET_CONTRACTORS = 'GET_CONTRACTORS'
export const UPDATE_CONTRACTOR = 'UPDATE_CONTRACTOR'

// Organizations
export const GET_ORGANIZATIONS = 'GET_ORGANIZATIONS'
export const GET_ORGANIZATIONS_ALL = 'GET_ORGANIZATIONS_ALL'
export const ORGANIZATION_VIEW_ALL = 'ORGANIZATION_VIEW_ALL'
export const GET_ORGANIZATION = 'GET_ORGANIZATION'

// Budget
export const DELETE_BUDGET = 'DELETE_BUDGET'
export const GET_BUDGET = 'GET_BUDGET'
export const GET_BUDGETS = 'GET_BUDGETS'
export const UPDATE_BUDGET = 'UPDATE_BUDGET'
export const CREATE_BUDGET = 'CREATE_BUDGET'
export const CREATE_BUDGET_LINES = 'CREATE_BUDGET_LINES'
export const DELETE_BUDGET_LINES = 'DELETE_BUDGET_LINES'

// Contracts
export const CREATE_CONTRACT = 'CREATE_CONTRACT'
export const GET_CONTRACT = 'GET_CONTRACT'
export const GET_CONTRACTS = 'GET_CONTRACTS'
export const UPDATE_CONTRACT = 'UPDATE_CONTRACT'
export const ADD_CONTRACT_PROTOCOL = 'ADD_CONTRACT_PROTOCOL'
export const GET_CONTRACT_PROTOCOL = 'GET_CONTRACT_PROTOCOL'

// ContractBudgets
export const CREATE_CONTRACT_BUDGET = 'CREATE_CONTRACT_BUDGET'
export const DELETE_CONTRACT_BUDGET = 'DELETE_CONTRACT_BUDGET'
export const GET_CONTRACT_BUDGET = 'GET_CONTRACT_BUDGET'
export const GET_CONTRACT_BUDGETS = 'GET_CONTRACT_BUDGETS'

// ContractDeposits
export const CREATE_CONTRACT_DEPOSIT = 'CREATE_CONTRACT_DEPOSIT'
export const GET_CONTRACT_DEPOSIT = 'GET_CONTRACT_DEPOSIT'
export const DELETE_CONTRACT_DEPOSIT = 'DELETE_CONTRACT_DEPOSIT'
export const GET_CONTRACT_DEPOSITS = 'GET_CONTRACT_DEPOSITS'

// ContractGuarantees
export const CREATE_CONTRACT_GUARANTEE = 'CREATE_CONTRACT_GUARANTEE'
export const GET_CONTRACT_GUARANTEE = 'GET_CONTRACT_GUARANTEE'
export const DELETE_CONTRACT_GUARANTEE = 'DELETE_CONTRACT_GUARANTEE'
export const GET_CONTRACT_GUARANTEES = 'GET_CONTRACT_GUARANTEES'

// ContractLoads
export const CREATE_CONTRACT_LOAD = 'CREATE_CONTRACT_LOAD'
export const GET_CONTRACT_LOAD = 'GET_CONTRACT_LOAD'
export const DELETE_CONTRACT_LOAD = 'DELETE_CONTRACT_LOAD'
export const GET_CONTRACT_LOADS = 'GET_CONTRACT_LOADS'

// ContractDocuments
export const CREATE_CONTRACT_DOCUMENT = 'CREATE_CONTRACT_DOCUMENT'
export const GET_CONTRACT_DOCUMENT = 'GET_CONTRACT_DOCUMENT'
export const GET_CONTRACT_DOCUMENTS = 'GET_CONTRACT_DOCUMENTS'
export const DELETE_CONTRACT_DOCUMENT = 'DELETE_CONTRACT_DOCUMENT'
export const UPDATE_CONTRACT_DOCUMENT = 'UPDATE_CONTRACT_DOCUMENT'

// Costs
export const GET_COST = 'GET_COST'
export const GET_COSTS = 'GET_COSTS'
export const CREATE_COST = 'CREATE_COST'
export const UPDATE_COST = 'UPDATE_COST'
export const CREATE_LINES = 'CREATE_LINES'
export const UPDATE_LINES = 'UPDATE_LINES'
export const GET_LINES = 'GET_LINES'

// Deposits
export const GET_DEBIT = 'GET_DEBIT'
export const GET_DEPOSITS = 'GET_DEPOSITS'
export const GET_GUARANTEE = 'GET_GUARANTEE'

// Collects
export const GET_CONTRACT_COLLECTS = 'GET_CONTRACT_COLLECTS'
export const CREATE_CONTRACT_COLLECT = 'CREATE_CONTRACT_COLLECT'

// Collects Load
export const GET_CONTRACT_COLLECTS_LOAD = 'GET_CONTRACT_COLLECTS_LOAD'
export const CREATE_CONTRACT_COLLECT_LOAD = 'CREATE_CONTRACT_COLLECT_LOAD'

// Website
export const CREATE_WEBSITE = 'CREATE_WEBSITE'
export const GET_WEBSITE = 'GET_WEBSITE'
export const GET_WEBSITES = 'GET_WEBSITES'
export const UPDATE_WEBSITE = 'UPDATE_WEBSITE'

// Folders
export const CREATE_FOLDER = 'CREATE_FOLDER'
export const GET_FOLDER = 'GET_FOLDER'
export const GET_FOLDERS = 'GET_FOLDERS'
export const UPDATE_FOLDER = 'UPDATE_FOLDER'

// Folders
export const CREATE_FILE = 'CREATE_FILE'
export const REMOVE_FILE = 'REMOVE_FILE'
export const GET_FILE = 'GET_FILE'
export const GET_FILES = 'GET_FILES'
export const UPDATE_FILE = 'UPDATE_FILE'
export const REMOVE_FOLDER = 'REMOVE_FOLDER'

// Offer
// Offer - Categories
export const DELETE_CATEGORY = 'DELETE_CATEGORY'
export const SAVE_CATEGORY = 'SAVE_CATEGORY'
export const GET_CATEGORY = 'GET_CATEGORY'

// Offer - Attributes
export const DELETE_ATTRIBUTE = 'DELETE_ATTRIBUTE'
export const SAVE_ATTRIBUTE = 'SAVE_ATTRIBUTE'
export const GET_ATTRIBUTE = 'GET_ATTRIBUTE'

// Offer - Product Picture Type
export const DELETE_PICTURE_TYPE = 'DELETE_PICTURE_TYPE'
export const SAVE_PICTURE_TYPE = 'SAVE_PICTURE_TYPE'
export const GET_PICTURE_TYPE = 'GET_PICTURE_TYPE'

// Offer - Products
export const DELETE_PRODUCT = 'DELETE_PRODUCT'
export const SAVE_PRODUCT = 'SAVE_PRODUCT'
export const GET_PRODUCTS = 'GET_PRODUCTS'
export const GET_PRODUCT_STORAGE = 'GET_PRODUCT_STORAGE'
export const UPDATE_PRODUCT_STORAGE = 'UPDATE_PRODUCT_STORAGE'
export const CREATE_PRODUCT_STORAGE = 'CREATE_PRODUCT_STORAGE'
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT'
export const GET_PRODUCT = 'GET_PRODUCT'
export const GET_PRODUCT_ATTRIBUTES = 'GET_PRODUCT_ATTRIBUTES'
export const UPDATE_PRODUCT_ATTRIBUTES = 'UPDATE_PRODUCT_ATTRIBUTES'
export const DELETE_APPLICATION = 'DELETE_APPLICATION'
export const SAVE_PRODUCT_ATTRIBUTES = 'SAVE_PRODUCT_ATTRIBUTES'
export const DELETE_PRODUCT_ATTRIBUTES = 'DELETE_PRODUCT_ATTRIBUTES'

// Chat
export const GET_CHAT_CONVERSATIONS = 'GET_CHAT_CONVERSATIONS'
export const GET_CHAT_CONVERSATIONS_UNREAD_MESSAGES = 'GET_CHAT_CONVERSATIONS_UNREAD_MESSAGES'
export const CREATE_CHAT_CONVERSATION = 'CREATE_CHAT_CONVERSATION'
export const GET_CHAT_CONVERSATION_MESSAGES = 'GET_CHAT_CONVERSATION_MESSAGES'
export const CREATE_CHAT_CONVERSATION_MESSAGE = 'CREATE_CHAT_CONVERSATION_MESSAGE'

// Cart
export const ADD_PRODUCT_TO_CART = 'ADD_PRODUCT_TO_CART'
export const CHANGE_PRODUCT_AMOUNT = 'CHANGE_PRODUCT_AMOUNT'
export const REMOVE_PRODUCT_FROM_CART = 'REMOVE_PRODUCT_FROM_CART'
export const GET_USER_CART = 'GET_USER_CART'
export const UPDATE_CART = 'UPDATE_CART'
export const UPDATE_CART_PRODUCT = 'UPDATE_CART_PRODUCT'

export const CREATE_THREAD_OFFER = 'CREATE_THREAD_OFFER'
export const GET_THREAD_OFFER = 'GET_THREAD_OFFER'
export const GET_THREAD_ORDERS = 'GET_THREAD_ORDERS'
export const GET_ORDERS = 'GET_ORDERS'
export const GET_THREAD_OFFERS = 'GET_THREAD_OFFERS'
export const GET_THREAD_STATISTICS = 'GET_THREAD_STATISTICS'
export const GET_OFFERS = 'GET_OFFERS'
export const UPDATE_OFFER = 'UPDATE_OFFER'
export const GET_OFFER = 'GET_OFFER'
export const SAVE_OFFER_AGREEMENT = 'SAVE_OFFER_AGREEMENT'
export const SAVE_CONTACT_OFFER_AGREEMENT = 'SAVE_CONTACT_OFFER_AGREEMENT'
export const GET_CONTACT_OFFER_AGREEMENT = 'GET_CONTACT_OFFER_AGREEMENT'
export const DELETE_CONTACT_OFFER_AGREEMENT = 'DELETE_CONTACT_OFFER_AGREEMENT'

// Polls
export const DELETE_POLL = 'DELETE_POLL'
export const GET_POLLS = 'GET_POLLS'
export const CREATE_POLL = 'CREATE_POLL'
export const UPDATE_POLL = 'UPDATE_POLL'

// Product template
export const GET_PRODUCT_TEMPLATE = 'GET_PRODUCT_TEMPLATE'
export const GET_PRODUCT_TEMPLATES = 'GET_PRODUCT_TEMPLATES'
export const SAVE_PRODUCT_TEMPLATE = 'SAVE_PRODUCT_TEMPLATE'
export const UPDATE_PRODUCT_TEMPLATE = 'UPDATE_PRODUCT_TEMPLATE'
export const DELETE_PRODUCT_TEMPLATE = 'DELETE_PRODUCT_TEMPLATE'

export const GET_POLL_OPTIONS = 'GET_POLL_OPTIONS'
export const DELETE_POLL_OPTION = 'DELETE_POLL_OPTION'
export const SAVE_POLL_OPTION = 'SAVE_POLL_OPTION'
export const GET_AGREEMENTS = 'GET_AGREEMENTS'
export const UPDATE_AGREEMENTS = 'UPDATE_AGREEMENTS'
export const ADD_PAYMENT = 'ADD_PAYMENT'
export const GET_PAYMENTS = 'GET_PAYMENTS'
export const GET_PAYMENTS_STATS = 'GET_PAYMENTS_STATS'
export const ADD_PAYMENT_COLLECT = 'ADD_PAYMENT_COLLECT'

// Contract Generator
export const GET_CONTRACTS_TEMPLATES = 'GET_CONTRACTS_TEMPLATES'
export const GET_CONTRACT_TEMPLATE = 'GET_CONTRACT_TEMPLATE'
export const CREATE_CONTRACT_TEMPLATE = 'CREATE_CONTRACT_TEMPLATE'
export const UPDATE_CONTRACT_TEMPLATE = 'UPDATE_CONTRACT_TEMPLATE'
export const DELETE_CONTRACT_TEMPLATE = 'DELETE_CONTRACT_TEMPLATE'

// Projects
export const GET_PROJECTS = 'GET_PROJECTS'
export const GET_PROJECT = 'GET_PROJECT'
export const CREATE_PROJECT = 'CREATE_PROJECT'
export const UPDATE_PROJECT = 'UPDATE_PROJECT'
export const DELETE_PROJECT = 'DELETE_PROJECT'
export const TEST_PROJECT = 'TEST_PROJECT'

// Tasks
export const GET_PROJECT_TASKS = 'GET_PROJECT_TASKS'
export const GET_PROJECT_TASK = 'GET_PROJECT_TASK'
export const GET_PROJECT_TASKS_FROM_PROJECT = 'GET_PROJECT_TASKS_FROM_PROJECT'
export const CREATE_PROJECT_TASK = 'CREATE_PROJECT_TASK'
export const UPDATE_PROJECT_TASK = 'UPDATE_PROJECT_TASK'
export const DELETE_PROJECT_TASK = 'DELETE_PROJECT_TASK'
export const TEST_PROJECT_TASK = 'TEST_PROJECT_TASK'

// Micro Tasks
export const GET_MICRO_TASKS = 'GET_MICRO_TASKS'
export const GET_MICRO_TASK = 'GET_MICRO_TASK'
export const CREATE_MICRO_TASK = 'CREATE_MICRO_TASK'
export const REMOVE_MICRO_TASK = 'REMOVE_MICRO_TASK'
export const UPDATE_MICRO_TASK = 'UPDATE_MICRO_TASK'
export const TEST_MICRO_TASK = 'TEST_MICRO_TASK'

// Settings Projects Statuses
export const GET_PROJECT_STATUSES = 'GET_PROJECT_STATUSES'
export const GET_PROJECT_STATUS = 'GET_PROJECT_STATUS'
export const CREATE_PROJECT_STATUS = 'CREATE_PROJECT_STATUS'
export const UPDATE_PROJECT_STATUS = 'UPDATE_PROJECT_STATUS'
export const DELETE_PROJECT_STATUS = 'DELETE_PROJECT_STATUS'
export const TEST_PROJECT_STATUS = 'TEST_PROJECT_STATUS'

// Settings Task Statuses
export const GET_PROJECT_TASK_STATUSES = 'GET_PROJECT_TASK_STATUSES'
export const GET_PROJECT_TASK_STATUS = 'GET_PROJECT_TASK_STATUS'
export const CREATE_PROJECT_TASK_STATUS = 'CREATE_PROJECT_TASK_STATUS'
export const UPDATE_PROJECT_TASK_STATUS = 'UPDATE_PROJECT_TASK_STATUS'
export const DELETE_PROJECT_TASK_STATUS = 'DELETE_PROJECT_TASK_STATUS'
export const TEST_PROJECT_TASK_STATUS = 'TEST_PROJECT_TASK_STATUS'

// Projects Comments
export const CREATE_PROJECT_COMMENT = 'CREATE_PROJECT_COMMENT'
export const GET_PROJECT_COMMENT = 'GET_PROJECT_COMMENT'
export const GET_PROJECT_COMMENTS = 'GET_PROJECT_COMMENTS'
export const TEST_PROJECT_COMMENT = 'TEST_PROJECT_COMMENT'
export const UPDATE_PROJECT_COMMENT = 'UPDATE_PROJECT_COMMENT'

// Tasks Comments
export const CREATE_PROJECT_TASK_COMMENT = 'CREATE_PROJECT_TASK_COMMENT'
export const GET_PROJECT_TASK_COMMENT = 'GET_PROJECT_TASK_COMMENT'
export const GET_PROJECT_TASK_COMMENTS = 'GET_PROJECT_TASK_COMMENTS'
export const TEST_PROJECT_TASK_COMMENT = 'TEST_PROJECT_TASK_COMMENT'
export const UPDATE_PROJECT_TASK_COMMENT = 'UPDATE_PROJECT_TASK_COMMENT'

// Task Categories
export const CREATE_PROJECT_TASK_CATEGORY = 'CREATE_PROJECT_TASK_CATEGORY'
export const REMOVE_PROJECT_TASK_CATEGORY = 'REMOVE_PROJECT_TASK_CATEGORY'
export const GET_PROJECT_TASK_CATEGORIES = 'GET_PROJECT_TASK_CATEGORIES'
export const GET_PROJECT_TASK_CATEGORY = 'GET_PROJECT_TASK_CATEGORY'
export const TEST_PROJECT_TASK_CATEGORY = 'TEST_PROJECT_TASK_CATEGORY'
export const UPDATE_PROJECT_TASK_CATEGORY = 'UPDATE_PROJECT_TASK_CATEGORY'

// Project Tags
export const CREATE_PROJECT_TAG = 'CREATE_PROJECT_TAG'
export const GET_PROJECT_TAG = 'GET_PROJECT_TAG'
export const GET_PROJECT_TAGS = 'GET_PROJECT_TAGS'
export const REMOVE_PROJECT_TAG = 'REMOVE_PROJECT_TAG'
export const TEST_PROJECT_TAG = 'TEST_PROJECT_TAG'
export const UPDATE_PROJECT_TAG = 'UPDATE_PROJECT_TAG'

// Project Documents
export const CREATE_PROJECT_DOCUMENT = 'CREATE_PROJECT_DOCUMENT'
export const GET_PROJECT_DOCUMENT = 'GET_PROJECT_DOCUMENT'
export const DELETE_PROJECT_DOCUMENT = 'DELETE_PROJECT_DOCUMENT'
export const GET_PROJECT_DOCUMENTS = 'GET_PROJECT_DOCUMENTS'
export const TEST_PROJECT_DOCUMENT = 'TEST_PROJECT_DOCUMENT'
export const UPDATE_PROJECT_DOCUMENT = 'UPDATE_PROJECT_DOCUMENT'
export const GET_MICRO_TASK_FROM_TASK = 'GET_MICRO_TASK_FROM_TASK'

// Contact addon fields types
export const CREATE_CONTACT_ADDON_FIELD = 'CREATE_CONTACT_ADDON_FIELD'
export const DELETE_CONTACT_ADDON_FIELD = 'DELETE_CONTACT_ADDON_FIELD'
export const GET_CONTACT_ADDON_FIELD = 'GET_CONTACT_ADDON_FIELD'
export const GET_CONTACT_ADDON_FIELDS = 'GET_CONTACT_ADDON_FIELDS'
export const TEST_CONTACT_ADDON_FIELD = 'TEST_CONTACT_ADDON_FIELD'
export const UPDATE_CONTACT_ADDON_FIELD = 'UPDATE_CONTACT_ADDON_FIELD'

// Contact addon fields types
export const CREATE_CONTACT_ADDON_FIELD_TYPE = 'CREATE_CONTACT_ADDON_FIELD_TYPE'
export const DELETE_CONTACT_ADDON_FIELD_TYPE = 'DELETE_CONTACT_ADDON_FIELD_TYPE'
export const GET_CONTACT_ADDON_FIELD_TYPE = 'GET_CONTACT_ADDON_FIELD_TYPE'
export const GET_CONTACT_ADDON_FIELD_TYPES = 'GET_CONTACT_ADDON_FIELD_TYPES'
export const TEST_CONTACT_ADDON_FIELD_TYPE = 'TEST_CONTACT_ADDON_FIELD_TYPE'
export const UPDATE_CONTACT_ADDON_FIELD_TYPE = 'UPDATE_CONTACT_ADDON_FIELD_TYPE'

// News
export const GET_NEWS = 'GET_NEWS'
export const GET_NEWS_ALL = 'GET_NEWS_ALL'
export const CREATE_NEWS = 'CREATE_NEWS'
export const REMOVE_NEWS = 'REMOVE_NEWS'
export const UPDATE_NEWS = 'UPDATE_NEWS'
export const TEST_NEWS = 'TEST_NEWS'

// REGON API
export const TEST_REGON = 'TEST_REGON'

// AI
export const GEN_OPENAI_RESPONSE_V3 = 'GEN_OPENAI_RESPONSE_V3'
export const GEN_OPENAI_RESPONSE_V35 = 'GEN_OPENAI_RESPONSE_V35'
export const GET_OPEN_AI_CONFIG_ALL = 'GET_OPEN_AI_CONFIG_ALL'
export const GET_OPEN_AI_CONFIG = 'GET_OPEN_AI_CONFIG'
export const CREATE_OPEN_AI_CONFIG = 'CREATE_OPEN_AI_CONFIG'
export const UPDATE_OPEN_AI_CONFIG = 'UPDATE_OPEN_AI_CONFIG'
export const GET_OPEN_AI_LOG_ALL = 'GET_OPEN_AI_LOG_ALL'
export const CREATE_OPEN_AI_REPORT_MESSAGE = 'CREATE_OPEN_AI_REPORT_MESSAGE'
export const REMOVE_OPEN_AI_REPORT_MESSAGE = 'REMOVE_OPEN_AI_REPORT_MESSAGE'
export const GET_OPEN_AI_REPORT_MESSAGE_ALL = 'GET_OPEN_AI_REPORT_MESSAGE_ALL'
export const TEST_OPEN_AI_CONFIG = 'TEST_OPEN_AI_CONFIG'
export const TEST_OPEN_AI_LOG = 'TEST_OPEN_AI_LOG'
export const TEST_OPEN_AI_REPORTS = 'TEST_OPEN_AI_REPORTS'

// Modals
export const ADD_MODAL = 'ADD_MODAL'
export const ADD_MODALS = 'ADD_MODALS'
export const REMOVE_MODAL = 'REMOVE_MODAL'
export const REMOVE_MODAL_ALL = 'REMOVE_MODAL_ALL'
export const AUTO_MODAL_REMOVE_ALL = 'AUTO_MODAL_REMOVE_ALL'
export const GET_MODAL = 'GET_MODAL'
export const GET_MODAL_ALL = 'GET_MODAL_ALL'
export const SHOW_MODAL = 'SHOW_MODAL'
export const HIDE_MODAL = 'HIDE_MODAL'
export const TOGGLE_MODAL = 'TOGGLE_MODAL'
export const SET_MODAL_DATA = 'SET_MODAL_DATA'
export const LOAD_MODAL_DATA = 'LOAD_MODAL_DATA'
export const SAVE_MODAL_DATA = 'SAVE_MODAL_DATA'
// AI
export const GET_GLOBAL_EMAILS = 'GET_GLOBAL_EMAILS'
export const GET_GLOBAL_EMAIL = 'GET_GLOBAL_EMAIL'
export const SEND_EMAIL = 'SEND_EMAIL'
export const TEST_GLOBAL_EMAILS = 'TEST_GLOBAL_EMAILS'

// Campaigns
export const GET_CAMPAIGNS = 'GET_CAMPAIGNS'
export const ADD_CAMPAIGNS = 'ADD_CAMPAIGNS'
export const UPDATE_CAMPAIGNS = 'UPDATE_CAMPAIGNS'
export const DELETE_CAMPAIGNS = 'DELETE_CAMPAIGNS'
export const TEST_CAMPAIGNS = 'TEST_CAMPAIGNS'
