var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"newFunnelFieldForm",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('Name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Name')}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('Name'),"state":errors.length > 0 ? false:null},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('AmountOfDaysInStatus')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('AmountOfDaysInStatus')}},[_c('b-form-input',{attrs:{"id":"maxDurationStay","placeholder":_vm.$t('AmountOfDaysInStatus'),"type":"number","state":errors.length > 0 ? false:null},model:{value:(_vm.maxDurationStay),callback:function ($$v) {_vm.maxDurationStay=$$v},expression:"maxDurationStay"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('Color')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Color')}},[_c('b-form-input',{attrs:{"id":"color","placeholder":_vm.$t('Color'),"size":"sm","type":"color","state":errors.length > 0 ? false:null},model:{value:(_vm.color),callback:function ($$v) {_vm.color=$$v},expression:"color"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('b-button',{staticClass:"w-100",attrs:{"variant":"primary","disabled":invalid},on:{"click":function($event){return _vm.save()}}},[_vm._v(" "+_vm._s(_vm.$t('Save'))+" ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }