import * as fields from '@/@constants/fields'

const rootBreadcrumb = {
  text: 'Projects',
  active: false,
  to: '/projects',
}

// eslint-disable-next-line import/prefer-default-export
export const projects = [
  {
    path: '/projectsPanel',
    name: 'projectsPanel',
    component: () => import('@/pages/projects/Panel.vue'),
    meta: {
      autorize: true,
      pageTitle: 'ProjectsPanel',
    },
  },
  {
    path: '/projects',
    name: 'projects',
    // component: () => import('@/pages/projects/projects/List.vue'),
    component: () => import('@/pages/projects/Panel.vue'),
    meta: {
      autorize: true,
      pageTitle: 'Projects',
    },
  },
  {
    path: '/projects/new',
    name: 'projects/new',
    component: () => import('@/pages/projects/projects/New.vue'),
    meta: {
      autorize: true,
      pageTitle: 'ProjectNew',
      breadcrumb: [
        rootBreadcrumb,
        {
          active: true,
          text: 'NewProcject',
        },
      ],
    },
  },
  {
    path: '/projects/edit/:id',
    name: 'projects/edit',
    component: () => import('@/pages/projects/projects/New.vue'),
    meta: {
      autorize: true,
      pageTitle: 'ProjectEdit',
      breadcrumb: [
        rootBreadcrumb,
        {
          active: true,
          text: 'Offer.Project',
        },
        // {
        //   active: true,
        //   request: {
        //     to: '/projects/edit/{id}', routeParam: 'id', endpoint: '/projects/{id}', fields: ['name'], fields_load: fields.PROJECTS_SEARCH,
        //   },
        // },
      ],
    },
  },
  {
    path: '/projects/tasks/new',
    name: 'projects/tasks/new',
    component: () => import('@/pages/projects/tasks/New.vue'),
    meta: {
      autorize: true,
      pageTitle: 'TaskNew',
      breadcrumb: [
        rootBreadcrumb,
        {
          text: 'NewTask',
          active: true,
        },
      ],
    },
  },
  {
    path: '/projects/:projectId/tasks/edit/:id',
    name: 'projects/tasks/edit',
    component: () => import('@/pages/projects/tasks/New.vue'),
    meta: {
      autorize: true,
      pageTitle: 'TaskEdit',
      breadcrumb: [
        rootBreadcrumb,
        {
          active: false,
          request: {
            to: '/projects/edit/{projectId}', routeParam: 'projectId', endpoint: '/projects/{projectId}', fields: ['name'], fields_load: fields.PROJECTS_SEARCH,
          },
        },
        {
          active: true,
          text: 'timeline.Task',
        },
        // {
        //   active: true,
        //   request: {
        //     to: '/projects/{projectId}/tasks/edit/{id}', routeParam: 'id', endpoint: '/projects/{projectId}/tasks/{id}', fields: ['name'], fields_load: fields.PROJECT_TASKS_SEARCH,
        //   },
        // },
      ],
    },
  },
]
