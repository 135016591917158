import Vue from 'vue'
import Default from './views/modals/ModalDefault.vue'
import NavMore from './views/modals/root/ModalNavMore.vue'
import SignInQrCode from './views/modals/root/ModalSignInQrCode.vue'
import ReportAI from './views/modals/AI/ReportAIModal.vue'
import ContactMoreInfo from './views/modals/contact/MoreInfoModal.vue'
import NewChatWidget from './views/modals/settings/NewChatWidget.vue'

Vue.component('MDefault', Default)

/*    START::Root    */
Vue.component('MRootNavMore', NavMore)
Vue.component('MSignInQrCode', SignInQrCode)
/*    END::Root    */

/*    START::AI    */
Vue.component('MAiReport', ReportAI)
/*    END::AI    */

/*    START::Contact    */
Vue.component('MContactMoreInfo', ContactMoreInfo)
/*    END::Contact    */

/*    START::Settings    */
Vue.component('MNewChatWidget', NewChatWidget)
/*    END::Settings    */
