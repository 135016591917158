/*
Object Modal:
  – title     - Title of the header.
  – component - Component to load.
  – data      - Data to pass to the Component.
  – visible   - Is modal visible.
  – isLoading - Is loading on.
  – preload   - An asynchronous function that returns an object passed to the data.
  – save      - An asynchronous function that save the data. The data is pass via SAVE_MODAL_DATA.

  – hideFooter  - Hide the Footer.
  – okTitle     - Text on the Button.
  – cancelTitle - Text on the Button.
*/

import {
  ADD_MODAL,
  ADD_MODALS,
  AUTO_MODAL_REMOVE_ALL,
  HIDE_MODAL,
  LOAD_MODAL_DATA,
  REMOVE_MODAL,
  REMOVE_MODAL_ALL,
  SAVE_MODAL_DATA,
  SET_MODAL_DATA,
  SHOW_MODAL,
  TOGGLE_MODAL,
} from '@/@constants/mutations'

const genId = () => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  let id = ''

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 6; i++) {
    const randomIndex = Math.floor(Math.random() * chars.length)
    id += chars.charAt(randomIndex)
  }

  id += Math.floor(Math.random() * 10) // Add a random number at the end

  return id
}

export default {
  namespaced: true,
  state: {
    defaultModal: {
      id: 'ROOT',
      title: 'New Modal',
      component: 'm-default',
      data: null,
      visible: false,
      preload: null,
      save: null,
      isLoading: false,
      autoRemove: true,

      hideFooter: false,
      okTitle: 'Save',
      cancelTitle: 'Cancel',
      size: 'sm',
      onCloseRemove: false,
    },

    modals: [],
  },

  getters: {
    getModal: store => id => store.modals.find(m => m.id === id),
    'getModal.all': store => store.modals,

    'getModal.all.visible': store => store.modals.filter(m => m.visible === true),
    'getModal.all.hidden': store => store.modals.filter(m => m.visible === false),
  },

  mutations: {
    add(state, {
      id, title, component, data, visible, preload, save, autoRemove,

      hideFooter, okTitle, cancelTitle, size, onCloseRemove,
    }) {
      const newModal = {
        id,
        title,
        component,
        data,
        visible,
        preload,
        save,
        autoRemove,

        hideFooter,
        okTitle,
        cancelTitle,
        size,
        onCloseRemove,
      }

      state.modals.push(newModal)
    },

    remove(state, index) { state.modals.splice(index, 1) },
    removeAll(state) { state.modals = [] },

    visible(state, index) { state.modals[index].visible = true },

    hide(state, index) {
      state.modals[index].visible = false
      state.modals[index].data = state.defaultModal.data
    },
    hideAll(state) {
      state.modals.forEach((_, index) => {
        state.modals[index].visible = false
        state.modals[index].data = state.defaultModal.data
      })
    },

    toggle(state, index) { state.modals[index].visible = !state.modals[index].visible },

    loadingStart(state, index) { state.modals[index].isLoading = true },
    loadingStop(state, index) { state.modals[index].isLoading = false },

    clear(state, index) { state.modals[index].data = state.defaultModal.data },
    clearAll(state) { state.modals.forEach((_, index) => { state.modals[index].data = state.defaultModal.data }) },
  },

  actions: {
    [ADD_MODAL]: ({ state, commit, dispatch }, {
      title, component, data, visible, preload, save, autoRemove,

      hideFooter, okTitle, cancelTitle, size, onCloseRemove,
    } = {}) => new Promise(resolve => {
      const { defaultModal } = state
      const id = genId()

      commit('add', {
        id,
        title: title || defaultModal.title,
        component: component || defaultModal.component,
        data: data || defaultModal.data,
        preload: preload || defaultModal.preload,
        save: save || defaultModal.save,
        visible: defaultModal.visible,
        isLoading: defaultModal.isLoading,
        autoRemove,

        hideFooter: hideFooter || defaultModal.hideFooter,
        okTitle: okTitle || defaultModal.okTitle,
        cancelTitle: cancelTitle || defaultModal.cancelTitle,
        size: size || defaultModal.size,
        onCloseRemove: onCloseRemove || defaultModal.onCloseRemove,
      })

      if (visible) dispatch('SHOW_MODAL', id)

      resolve(id)
    }),

    [ADD_MODALS]: ({ state, dispatch }, modals) => new Promise(resolve => {
      if (!modals || !modals.length) throw new TypeError('Enter some modals')

      modals.forEach(m => dispatch('ADD_MODAL', m))
      resolve(state.modals)
    }),

    [REMOVE_MODAL]: ({ state, commit }, id) => new Promise(resolve => {
      const index = state.modals.findIndex(m => m.id === id)

      if (!state.modals[index]) throw new TypeError(`The modal ${id} not found`)

      commit('remove', index)
      resolve()
    }),

    [REMOVE_MODAL_ALL]: ({ commit }) => new Promise(resolve => {
      commit('removeAll')
      resolve()
    }),

    [AUTO_MODAL_REMOVE_ALL]: ({ state, commit }) => new Promise(resolve => {
      state.modals.forEach((m, index) => (m.autoRemove ? commit('remove', index) : false))

      resolve()
    }),

    [SHOW_MODAL]: ({ state, commit, dispatch }, id) => new Promise(resolve => {
      const index = state.modals.findIndex(m => m.id === id)

      if (!state.modals[index]) throw new TypeError(`The modal ${id} not found`)

      dispatch('LOAD_MODAL_DATA', id)

      commit('visible', index)
      resolve()
    }),

    [HIDE_MODAL]: ({ state, commit }, id) => new Promise(resolve => {
      const index = state.modals.findIndex(m => m.id === id)

      if (!state.modals[index]) throw new TypeError(`The modal ${id} not found`)

      commit('hide', index)
      commit('clear', index)
      resolve()
    }),

    [TOGGLE_MODAL]: ({ state, commit }, id) => new Promise(resolve => {
      const index = state.modals.findIndex(m => m.id === id)

      if (!state.modals[index]) throw new TypeError(`The modal ${id} not found`)

      if (state.modals[index].visible) commit('clear', index)

      commit('toggle', index)
      resolve()
    }),

    [SET_MODAL_DATA]: ({ state }, { data, id }) => new Promise(resolve => {
      const index = state.modals.findIndex(m => m.id === id)

      if (!state.modals[index]) throw new TypeError(`The modal ${id} not found`)

      state.modals[index].data = data

      resolve()
    }),

    [LOAD_MODAL_DATA]: ({ state, commit }, id) => new Promise(resolve => {
      const index = state.modals.findIndex(m => m.id === id)
      const modal = state.modals[index]

      if (!state.modals[index]) throw new TypeError(`The modal ${id} not found`)

      if (modal.preload) {
        commit('loadingStart', index)

        modal.preload
          .then(data => {
            modal.data = data

            commit('loadingStop', index)

            resolve(data)
          })
      }
    }),

    [SAVE_MODAL_DATA]: ({ state, commit, dispatch }, id) => new Promise(resolve => {
      const index = state.modals.findIndex(m => m.id === id)
      const modal = state.modals[index]

      if (!state.modals[index]) throw new TypeError(`The modal ${id} not found`)

      if (modal.save) {
        commit('loadingStart', index)

        modal.save(modal.data ? { data: modal.data } : null)
          .then(() => {
            dispatch('HIDE_MODAL', id)

            resolve()
          })
      }
    }),
  },
}
