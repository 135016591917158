<template>
  <div>
    <sw-top-page-bar
      v-if="isMounted"
      v-model="activeTab"
      is-tab-view
      show-text
      :tab-view-list="tabs"
      @input="changeRoute"
    >
    </sw-top-page-bar>
    <router-view />

  </div>
</template>

<script>
/* eslint-disable */
import SwCommissionTable from '@/pages/commissions/List.vue'
import MarketingPlanList from '@/pages/plans/marketing/List.vue'

export default {
  components: {
    MarketingPlanList,
    SwCommissionTable,
  },
  computed: {
    tabs() {
      return this.tabList.filter(e => (!e?.requiredViewPermissions?.length || this.checkRequiredViewPermissions(e.requiredViewPermissions)))
    },
  },
  data: vm => ({
    activePanel: null,
    activeTab: {
        title: 'YourPlans',
        name: 'YourPlans',
        route: 'marketingPlansPlans',
        icon: 'GridIcon',
        requiredViewPermissions: [vm.$viewRanks.SHOW_MARKETING_PLANS],
        component: MarketingPlanList,
    },
    isMounted: false,
    tabList: [
      {
        title: 'YourPlans',
        name: 'YourPlans',
        route: 'marketingPlansPlans',
        icon: 'GridIcon',
        requiredViewPermissions: [vm.$viewRanks.SHOW_MARKETING_PLANS],
        component: MarketingPlanList,
      },
      {
        title: 'Commissions',
        name: 'Commissions',
        route: 'marketingPlansCommissions',
        icon: 'StarIcon',
        component: SwCommissionTable,
      },
    ],
  }),
    mounted() {
      if (this.$route.name === 'marketingPlansCommissions' || !this.checkRequiredViewPermissions([this.$viewRanks.SHOW_MARKETING_PLANS])) {
        this.activeTab = {
            title: 'Commissions',
            name: 'Commissions',
            route: 'marketingPlansCommissions',
            icon: 'StarIcon',
            component: SwCommissionTable,
        }
      }
      this.isMounted = true
    },
    methods: {
        changeRoute(base) {
            if (this.$route.name !== base.route) {
                this.$router.push({ name: base.route })
            }
            // window.history.replaceState(null, null, `/marketing/${base}`)
        },
    },
}
</script>
