var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"linkThreadForm",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('validation-provider',{attrs:{"name":_vm.$t('SelectContacts')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('sw-select',{attrs:{"name":_vm.$t('SelectContacts')}},[_c('v-select',{attrs:{"id":"offer-thread-1","options":_vm.threadList,"label":"name","filterable":false,"selectable":function (thread) { return thread.offersTotal < _vm.system.contactThreadOffersLimit; },"state":errors.length > 0 ? false:null,"placeholder":_vm.$t('SelectContacts')},on:{"search":_vm.loadThreads},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
return [(search.length)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('NoData'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('TypeToSearch'))+" ")])]}},{key:"option",fn:function(ref){
var name = ref.name;
var contact = ref.contact;
var offersTotal = ref.offersTotal;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('avatar',{staticClass:"mr-25",attrs:{"user":contact}}),_vm._v(" - "+_vm._s(name)+" "),(offersTotal >= _vm.system.contactThreadOffersLimit)?_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"ml-50",attrs:{"icon":"AlertCircleIcon","title":_vm.$tc('ThreadOfferCountIsToBig', _vm.system.contactThreadOffersLimit, { limit: _vm.system.contactThreadOffersLimit })}}):_vm._e()],1)]}},{key:"selected-option",fn:function(ref){
var name = ref.name;
var contact = ref.contact;
return [_c('div',{staticClass:"d-flex"},[_c('avatar',{staticClass:"mr-25",attrs:{"user":contact}}),_vm._v(" – "+_vm._s(name)+" ")],1)]}}],null,true),model:{value:(_vm.threads),callback:function ($$v) {_vm.threads=$$v},expression:"threads"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('b-button',{attrs:{"size":"sm","variant":"primary","disabled":invalid || _vm.isLoading},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('Save'))+" ")]),_c('b-overlay',{attrs:{"no-wrap":"","show":_vm.isLoading,"spinner-variant":"primary"}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }