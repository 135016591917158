// eslint-disable-next-line import/no-cycle
import axiosIns from '@/libs/axios'
import * as fields from '@/@constants/fields'
import axios from 'axios'

let source

export default {
  namespaced: true,
  actions: {
    // eslint-disable-next-line no-unused-vars,no-async-promise-executor
    GET_NOTIFICATIONS: ({ commit }, payload) => new Promise(async (resolve, reject) => {
      if (typeof source !== typeof undefined) source.cancel('Operation canceled due to new request.')
      if (!payload?.userId) {
        source.cancel('Operation canceled due to new request.')
        return
      }

      source = axios.CancelToken.source()

      // eslint-disable-next-line no-unused-vars
      const {
        page, limit, userId, all, type, collection, collectionValues,
      } = payload
      let params = {}
      if (page || limit) {
        params = { page, limit }
      }

      params.fields_load = fields.NOTIFICATIONS

      if (!all) {
        params.isNull_readAt = ''
      }

      if (type && type !== 'all') {
        params.eq_type = type
      }

      if (collection) {
        params.collection_field = 'type'
        params.collection_values = JSON.stringify(collectionValues)
      }

      params.orderBy = 'createdAt.desc'

      axiosIns.get(`1/users/${userId}/notifications`, { params, cancelToken: source.token })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    READ_NOTIFICATIONS: ({ commit }, payload) => new Promise((resolve, reject) => {
      const { userId, notifications } = payload
      axiosIns.post(`1/users/${userId}/notifications/read`, notifications)
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
  },
}
