// eslint-disable-next-line import/prefer-default-export
export const complaintStatuses = [
  { variant: 'light-primary', value: 'NEW', label: 'AgreementStatuses.NEW' },
  { variant: 'light-warning', value: 'DURING', label: 'During' },
  { variant: 'light-danger', value: 'REJECTED', label: 'Rejected' },
  { variant: 'light-success', value: 'ACCEPTED', label: 'AgreementStatuses.ACCEPTED' },

]
export const complaintStatusByValue = value => complaintStatuses.find(type => type.value === value)?.label || ''
export const complaintStatusVariantByValue = value => complaintStatuses.find(type => type.value === value)?.variant || 'secondary'
