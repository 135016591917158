<template>
  <div>
    <!--    Product Top    -->
    <div
      class="
        mb-0
        w-100
        d-flex
        column
        align-items-center
        justify-content-center
        position-relative
        product-view
      "
      @mouseenter="loadImage"
    >
      <!--    Product image    -->
      <div class="w-100 position-relative">
        <b-img
          v-if="product.pictures[0] && product.pictures[0].preview"
          :src="product.pictures[0].preview.includes('blob') ? product.pictures[0].preview : `data:image/webp;base64,${product.pictures[0].preview}`"
          style="width: 100%; height: 250px; object-fit: cover"
        />

        <div
          v-else
          style="width: 100%; height: 250px; object-fit: cover"
          class="d-flex justify-content-center align-items-center bg-secondary bg-lighten-4 text-light"
        >
          <feather-icon
            icon="ImageIcon"
            size="40"
          />
        </div>

        <div
          v-if="product.shopCategories && product.shopCategories.length === 2"
          class="ml-50"
          style="position:absolute; bottom: 1rem"
        >
          <b-badge
            variant="primary"
            size="sm"
            class="ml-25 mt-25"
            style="opacity: .9"
          >
            {{ product.shopCategories[0].translations[0].name }}
          </b-badge>
          <b-badge
            variant="primary"
            size="sm"
            class="ml-25 mt-25"
            style="opacity: .9"
          >
            {{ product.shopCategories[1].translations[0].name }}
          </b-badge>
        </div>
      </div>

      <!--    Product badge    -->
      <div
        v-if="showDetails"
        class="d-flex w-100 mt-1 justify-content-between position-absolute productIcon"
      >
        <div>
          <router-link
            v-if="allowEdit"
            class="ml-1"
            :to="{ name: 'productView', params: { productId: product.id } }"
          >
            <feather-icon
              size="18"
              icon="SettingsIcon"
            />
          </router-link>

          <span
            v-if="product.shopProductLoyaltyPlans && product.shopProductLoyaltyPlans.length"
            :id="`product_loyaltyPlan_${product.id}`"
            class="ml-50 text-primary cursor-pointer"
          >
            <feather-icon
              size="19"
              icon="StarIcon"
            />
          </span>

          <span
            v-if="product.shopProductMarketingPlans && product.shopProductMarketingPlans.length"
            :id="`product_marketingPlan_${product.id}`"
            class="ml-50 text-primary cursor-pointer"
          >
            <feather-icon
              size="19"
              icon="LayersIcon"
            />
          </span>

          <b-popover
            v-if="product.shopProductMarketingPlans && product.shopProductMarketingPlans.length"
            :target="`product_marketingPlan_${product.id}`"
            :triggers="['click', 'hover']"
            custom-class="loyaltyPlanPricePopover"
          >
            <div
              v-for="(plan, planIndex) in product.shopProductMarketingPlans"
              :key="`product_${product.id}_plan_popover_${planIndex}`"
            >
              <b-badge
                variant="light-primary"
                class="mb-25"
              >
                {{ plan.marketingPlan.name }}
              </b-badge>
              <div
                v-for="(commission, commissionIndex) in plan.shopProductMarketingPlanCommissions"
                :key="`product_${product.id}_plan_${plan.id}_commission_${commissionIndex}`"
                class="d-flex justify-content-between"
              >
                <div>{{ commission.productAmount }} {{ $t('Pro') }}.</div>
                <strong>{{ commission.value }} {{ $store.getters['system/getSettings'] ? ($store.getters['system/getSettings'].defaultCurrency || 'PLN') : 'PLN' }}</strong>
              </div>
            </div>
          </b-popover>

          <b-popover
            v-if="product.shopProductLoyaltyPlans && product.shopProductLoyaltyPlans.length"
            :target="`product_loyaltyPlan_${product.id}`"
            :triggers="['click', 'hover']"
            custom-class="loyaltyPlanPricePopover"
          >
            <div
              v-for="(plan, planIndex) in product.shopProductLoyaltyPlans"
              :key="`product_${product.id}_plan_popover_${planIndex}`"
            >
              <b-badge
                variant="light-primary"
                class="mb-25"
              >
                {{ plan.loyaltyPlan.name }}
              </b-badge>
              <div class="d-flex justify-content-between">
                <div>{{ $t('PointAwardedForSellingProduct') }}</div>
                <strong>{{ plan.pointsReward }}</strong>
              </div>
              <div class="d-flex justify-content-between">
                <div>{{ $t('PointOfTheProductInPurchase') }}</div>
                <strong>{{ plan.pointsPrice }}</strong>
              </div>
            </div>
          </b-popover>
        </div>

        <div class="d-flex flex-column align-items-end">
          <b-badge
            class="px-50 rounded-left mr-25"
            :variant="Boolean(product.shopProductStoragesSum) ? 'success' : 'danger'"
          >
            {{ Boolean(product.shopProductStoragesSum) ? $t('Available') : $t('Inaccessible') }}
          </b-badge>

          <div
            v-if="product.activeDiscount"
            v-b-tooltip
            class="mt-25 mr-25"
            :title="$t('Offer.Discount')"
          >
            <b-badge variant="primary">
              <feather-icon icon="DollarSignIcon" />
              {{ product.activeDiscount }}
            </b-badge>
          </div>
        </div>
      </div>
    </div>

    <!--    Product    -->
    <div
      v-if="product.translations && product.translations.length"
      class="mt-1 mb-1 mx-1 productBody"
    >
      <h6>
        <router-link
          :to="{ name: 'productView', params: { productId: product.id } }"
          class="font-weight-bolder"
        >
          <span class="doubleLineText">
            {{ ( selectedTranslationObject(product).name ) | truncate(100, '...') }}
          </span>
        </router-link>
      </h6>

      <h5
        v-b-tooltip.hover.left
        class="mt-25"
        :title="$t('Offer.GrossPrice')"
      >
        {{ product.offerPrice * ((product.vat / 100) + 1) || 0 | priceGrossFormat }} {{ $store.getters['system/getSettings'] ? ($store.getters['system/getSettings'].defaultCurrency || 'PLN') : 'PLN' }}
      </h5>

      <div
        class="productDesc font-small-3 text-truncate text-wrap"
        style="max-height: 130px"
      >
        <span :inner-html.prop="selectedTranslationObject(product).description | truncate(250, '...')" />
      </div>
    </div>

    <!--    Button    -->
    <div
      v-if="showDetails && checkRequiredPermissions([$roles.CONTACT_THREAD_OFFER_ADD])"
      v-b-tooltip
      :title="!Boolean(product.shopProductStoragesSum) ? $t('ProductNotAvailableInStorage') : ''"
    >
      <b-button
        v-if="!allowBuy"
        variant="primary"
        block
        squared
        :disabled="!product.active || !Boolean(product.shopProductStoragesSum)"
        @click="$emit('addProductToCart', product)"
      >
        <feather-icon icon="ShoppingCartIcon" />
        <span class="ml-50">{{ $t('AddToOffer') }}</span>
      </b-button>

      <b-button
        v-else
        variant="danger"
        block
        squared
        @click="$emit('remove-cart-product', product.id)"
      >
        <feather-icon icon="TrashIcon" />
        <span class="ml-50">{{ $t('RemoveFromOffer') }}</span>
      </b-button>
    </div>
  </div>
</template>

<script>
import { BImg, BPopover, VBTooltip } from 'bootstrap-vue'
import { DOWNLOAD_FILE } from '@/@constants/mutations'
import { priceGrossFormat } from '@core/filters/parts/valueFormat'

export default {
  components: {
    BImg,
    BPopover,
    // BFormSpinbutton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    selectedLocale: {
      default: 'pl_pl',
      type: String,
    },
    allowEdit: Boolean,
    product: Object,
    allowBuy: Boolean,
    showDetails: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    isImageLoaded: false,
  }),
  watch: {
    allowBuy() {},
  },
  methods: {
    selectedTranslationObject(listProduct) {
      const { selectedLocale } = this

      return listProduct.translations.find(({ locale }) => locale === selectedLocale) || { name: '-', description: '' }
    },
    priceGrossFormat,
    async loadImage() {
      if (!this.isImageLoaded) {
        if (this.product.pictures?.length) {
          const file = this.product.pictures[0]
          this.$store.dispatch(`uploader/${DOWNLOAD_FILE}`, { fileToken: file.token, filename: file.name })
            .then(fileRes => {
              const { data, filename } = fileRes
              // eslint-disable-next-line
              fetch(`data:${data.type};base64,${data.file}`)
                .then(test => test.blob())
                .then(blob => {
                  const blobFile = new File([blob], filename, { type: data.type })
                  // eslint-disable-next-line
                  file.preview = URL.createObjectURL(blobFile)
                  this.$set(this.product.pictures, 0, file)
                })
            })
        }
      }

      this.isImageLoaded = true
    },
  },
}
</script>

<style lang="scss">
#app .product .doubleLineText { width: 100% !important }

.theView,
.cardTable {
  .productIcon {
    top : 0;
    left: 0;
  }

  ///    Product    ///
  .product {
    -webkit-transition: all 250ms;
    -moz-transition: all 250ms;
    transition: all 250ms;

    &:hover {
      box-shadow: 0 14px 28px rgba(0, 0, 0, .14), 0 10px 10px rgba(0, 0, 0, .14);

      -webkit-transform: translateY(-5px);
      -moz-transform: translateY(-5px);
      transform: translateY(-5px);
    }

    ///    Body    ///
    .productBody {
      //min-height: 9rem;

      ///    Title    ///
      .productTitle {
        display: inline-block;

        min-height: 2.5rem;
      }

      ///    Description    ///
      .productDesc {
        letter-spacing: .05rem;
      }
    }
  }
}

.availableBadgeLeft,
.availableBadge {
  font-size: .85rem;
  padding: 2px 10px;
  position: absolute;
  right: 0;
  opacity: .9;
}

.availableBadgeLeft {
  left : 0;
  right: auto;
}

.shopBorder {
  border-left: 1px solid var(--bg-shopItem-lighten-2)
}

.loyaltyPlanPricePopover {
  width: 250px !important;
  max-width: 250px !important;
  .popover-body {
    width: 250px !important;
    max-width: 250px !important;
  }
}
</style>
