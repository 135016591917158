<!--eslint-disable-->

<template>
  <b-table
    :items="items"
    :fields="fields"
    fixed
    show-empty
    :busy="loading"
    :thead-class="!isSubcomponent ? '' : 'd-none'"
    :class="isSubcomponent ? 'b-subtable' : 'b-table-details-no-padding'"
  >
    <template #table-colgroup>
      <col style="width: 8rem">
      <col style="width: 10rem">
      <col style="width: 25rem">
      <col style="width: 17rem">
      <col style="width: 20rem">
      <!-- add as many <col> elements as needed -->
    </template>

    <!--    Table Head    -->
    <template #head()="item">
      <span
        class="text-nowrap"
        style="width:20rem"
      >
        {{ $t(item.field.label) | truncate(34, '...') }}
      </span>
    </template>

    <!-- Group -->
    <template #cell(userGroups)="{ value }">
      <div
        class="color-dot"
        style="border: 1px solid white"
        :style="{ backgroundColor: value.color }"
      />
      {{ value.name }}
    </template>

    <!--    Name    -->
    <template #cell(name)="row">
      <span
        v-if="selectedTranslationObject(row.item).name"
        v-b-tooltip.hover.left
        :title="selectedTranslationObject(row.item).name"
        class="text-primary cursor-pointer"
        @click="$emit('openEditModal', row)"
      >
        <feather-icon
          v-if="isSubcomponent"
          icon="CornerDownRightIcon"
          class="mr-50"
        />
        <span>{{ selectedTranslationObject(row.item).name | truncate(26, '...') }}</span>
      </span>
      <span v-else>-</span>
    </template>

    <!-- Subcategory Details -->
    <template #cell(subcategory)="row">
      <b-button
        :style="{ visibility: !isSubcomponent ? 'visible' : 'hidden' }"
        size="sm"
        class="mr-2 btn-icon"
        variant="outline-primary"
        @click="row.toggleDetails"
      >
        <feather-icon :icon="row.detailsShowing ? 'MinusIcon' : 'PlusIcon'" />
      </b-button>
    </template>

    <!-- Subcategory Section -->
    <template #row-details="row">
      <b-card class="subcomponent-table">
        <category-table
          v-if="!isSubcomponent"
          :items="row.item.children || []"
          is-subcomponent
          :selected-locale="selectedLocale"
          @openEditModal="$emit('openEditModal', $event, row)"
          @deleteItem="$emit('deleteItem', $event, row)"
          @createOffer="$emit('createOffer', $event, row)"
        />

        <b-button
          v-if="checkRequiredPermissions([$roles.SHOP_CATEGORY_MODIFY])"
          size="sm"
          variant="outline-primary"
          class="mb-50 mt-50"
          style="margin-left: 2rem"
          @click="$emit('openEditModal', null, row)"
        >
          {{ $t('Offer.AddSubcategory') }}
        </b-button>
      </b-card>
    </template>
    <template #cell(shopProductsCount)="{ value }">
      <b-badge variant="primary">
        {{ value || 0 }}
      </b-badge>
    </template>
    <!--    Table Action    -->
    <template #cell(action)="row">
      <b-dropdown
        v-if="checkRequiredPermissions([$roles.SHOP_CATEGORY_MODIFY, $roles.CONTACT_THREAD_OFFER_ADD])"
        variant="link"
        class="dropdown-icon-wrapper text-decoration-none"
        no-caret
      >
        <template
          #button-content
          class="sr-only"
        >
          <feather-icon
            icon="MoreVerticalIcon"
            size="16"
            class="align-middle"
          />
        </template>

        <b-dropdown-item
          v-if="checkRequiredPermissions([$roles.SHOP_CATEGORY_MODIFY])"
          @click="$emit('openEditModal', row)"
        >
          {{ $t('Edit') }}
        </b-dropdown-item>
        <!--    Action: Delete    -->
        <b-dropdown-item
          v-if="checkRequiredPermissions([$roles.SHOP_CATEGORY_MODIFY])"
          @click="$emit('deleteItem', row)"
        >
          {{ $t('Delete') }}
        </b-dropdown-item>
        <!--    Action: Create Offer    -->
        <b-dropdown-item
          v-if="checkRequiredPermissions([$roles.CONTACT_THREAD_OFFER_ADD])"
          :disabled="!row.item.id"
          @click="$emit('createOffer', row)"
        >
          {{ $t('CreateProduct') }}
        </b-dropdown-item>
      </b-dropdown>
    </template>
      <!--    Other    -->
      <template #cell()="row">
          <table-default-cell
                  :field="row.field"
                  :value="row.value"
          />
      </template>

      <!--      Empty      -->
      <template #empty>
          <empty-content />
      </template>

      <!--      Table Busy      -->
      <template #table-busy>
          <div class="text-center text-danger my-2">
              <b-spinner
                      class="align-middle"
                      variant="primary"
              />
          </div>
      </template>
  </b-table>
</template>

<script>
import { BTable, VBTooltip } from 'bootstrap-vue'
import { formatDate } from '@core/utils/filter'

export default {
  components: {
    BTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    parent: {
      type: [Object, Number],
      default: -1,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    selectedLocale: {
      default: 'pl_pl',
      type: String,
    },
    items: {
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
      type: Array,
    },
    isSubcomponent: {
      default: false,
      type: Boolean,
    },
  },
  emits: ['openEditModal'],
  data: () => ({
    fields: [
      { key: 'subcategory', label: 'Offer.UnderCategory' },
      { key: 'userGroups', label: 'offer.Group' },
      { key: 'name', label: 'Name' },
      { key: 'shopProductsCount', label: 'offer.NumberOfProducts' },
      { key: 'action', label: 'Action' },
    ],
  }),
  methods: {
    selectedTranslationObject(item) {
      const { selectedLocale } = this

      return item.translations.find(({ locale }) => locale === selectedLocale) || {}
    },
    formatDate,
  },
}
</script>

<style lang="scss">
.doubleLineText {
  width: 9rem !important;

  display: inline-block;

  overflow-wrap: anywhere;

  white-space: normal;
}

.subcomponent-table {
  .card-body {
    background-color: #fcfcfc;
  }
}

</style>
