import * as emailStatuses from '@/@constants/emailStatuses'

const resolveEmailStatusColor = status => {
  if (status === emailStatuses.RECEIVED) return 'primary'
  if (status === emailStatuses.QUEUE) return 'warning'
  if (status === emailStatuses.DELIVERED) return 'success'
  if (status === emailStatuses.FAILED) return 'danger'
  if (status === emailStatuses.OPENED) return 'info'
  return 'primary'
}

export default resolveEmailStatusColor
