var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"mx-0 px-0"},[_c('b-col',{staticClass:"px-1 px-0",attrs:{"sm":"12","md":"6","lg":"8"}},[_c('my-option',{attrs:{"title":_vm.$t('Title'),"tip":_vm.$t('MaximumLength') + ': 40'}},[_c('validation-provider',{staticClass:"w-100",attrs:{"name":_vm.$t('Title'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"id":"popover-opinionTitle","placeholder":_vm.$t('Title'),"state":errors.length > 0 ? false:null,"maxlength":"40"},model:{value:(_vm.newWebsite.opinionTitle),callback:function ($$v) {_vm.$set(_vm.newWebsite, "opinionTitle", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"newWebsite.opinionTitle"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_c('b-popover',{attrs:{"target":"popover-opinionTitle","triggers":"focus","variant":"primary"}},[_c('span',[_vm._v(_vm._s(_vm.$t('MaximumLength') + ': 40'))])])],1)]}}])})],1)],1)],1),_c('b-row',{staticClass:"mx-0"},[_c('b-col',{staticClass:"px-1",attrs:{"sm":"12","md":"6","lg":"8"}},[_c('my-option',{attrs:{"title":_vm.$t('offer.product.description'),"tip":_vm.$t('MaximumLength') + ': 170'}},[_c('validation-provider',{staticClass:"w-100",attrs:{"name":_vm.$t('offer.product.description'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-textarea',{attrs:{"id":"popover-opinionDesc","placeholder":_vm.$t('offer.product.description'),"state":errors.length > 0 ? false:null,"maxlength":"170"},model:{value:(_vm.newWebsite.opinionDesc),callback:function ($$v) {_vm.$set(_vm.newWebsite, "opinionDesc", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"newWebsite.opinionDesc"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_c('b-popover',{attrs:{"target":"popover-opinionDesc","triggers":"focus","variant":"primary"}},[_c('span',[_vm._v(_vm._s(_vm.$t('MaximumLength') + ': 170'))])])],1)]}}])})],1)],1)],1),_c('b-row',{staticClass:"mx-0"},[_c('b-col',{staticClass:"px-1",attrs:{"sm":"12","md":"12","lg":"8"}},[_c('my-option',{attrs:{"title":_vm.$t('offer.website.CustomersOpinion'),"tip":_vm.$t('offer.website.tips.CustomersOpinion')},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('b-button',{staticClass:"ml-50",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.openEditOpinion(-1, { translations: [{ title: '',
                                                           description: '',
                                                           author: '', }], avatar: '' })}}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}}),_vm._v(_vm._s(_vm.$t('Add'))+" ")],1)]},proxy:true}])})],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-row',_vm._l((_vm.newWebsite.opinionOptions),function(item,index){return _c('b-col',{key:index,staticClass:"w-100 mt-25",attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('div',[_vm._v(" "+_vm._s(index + 1)+". "+_vm._s(_vm.$t('Opinion'))+" ")]),_c('div',[_c('b-button',{staticClass:"btn-icon",attrs:{"variant":"flat-warning","size":"sm"},on:{"click":function($event){return _vm.openEditOpinion(index, item)}}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}})],1),_c('b-button',{staticClass:"btn-icon ml-25",attrs:{"variant":"flat-danger","size":"sm"},on:{"click":function($event){_vm.newWebsite.opinionOptions.splice(index, 1)

                  _vm.newWebsite.opinionOptions.push()}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}})],1)],1)]),(!!item.translations.length > 0)?_c('opinion',{attrs:{"item":item,"def-img":_vm.defImg,"translation-nr":0}}):_vm._e(),_c('hr',{staticClass:"mb-0"})],1)}),1)],1)],1),_c('b-modal',{attrs:{"title":_vm.$t('offer.website.CustomersOpinion'),"size":"sm","hide-footer":"","no-close-on-backdrop":""},model:{value:(_vm.showChangeOpinionOptionsModal),callback:function ($$v) {_vm.showChangeOpinionOptionsModal=$$v},expression:"showChangeOpinionOptionsModal"}},[_c('b-form',[_c('b-form-group',{attrs:{"label":_vm.$t('contact.avatar')}},[_c('div',{staticClass:"mx-25 mb-25 dragDrop rec d-flex row align-items-center justify-content-start"},[_c('div',[_c('b-img',{staticClass:"px-0 py-0 center",attrs:{"id":"img-newOption-avatar-modal","thumbnail":"","fluid":"","src":_vm.selectedOption.opt && !!_vm.selectedOption.opt.avatar && !!_vm.selectedOption.opt.avatar.preview ? ("data:image/webp;base64," + (_vm.selectedOption.opt.avatar.preview)): require('@/assets/icons/user/user.svg')}}),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary",modifiers:{"hover":true,"v-primary":true}}],attrs:{"variant":"primary","title":_vm.$t('DragDropFile')}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}}),_c('input',{ref:"newOptionAvatar",attrs:{"type":"file"},on:{"change":function($event){_vm.selectedOption.opt.avatar = _vm.onChange($event, 'newOption-avatar-modal', 1250)}}})],1)],1)])]),_c('validation-provider',{staticClass:"w-100",attrs:{"name":_vm.$t('Question'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Question')}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('Question'),"state":errors.length > 0 ? false:null,"maxlength":"50"},model:{value:(_vm.selectedOption.opt.translations[_vm.selectedOption.translationNr].title),callback:function ($$v) {_vm.$set(_vm.selectedOption.opt.translations[_vm.selectedOption.translationNr], "title", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"selectedOption.opt.translations[selectedOption.translationNr].title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{staticClass:"w-100",attrs:{"name":_vm.$t('Answer'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Answer')}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('Answer'),"state":errors.length > 0 ? false:null,"maxlength":"170"},model:{value:(_vm.selectedOption.opt.translations[_vm.selectedOption.translationNr].description),callback:function ($$v) {_vm.$set(_vm.selectedOption.opt.translations[_vm.selectedOption.translationNr], "description", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"selectedOption.opt.translations[selectedOption.translationNr].description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{staticClass:"w-100",attrs:{"name":_vm.$t('Creator'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Creator')}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('Creator'),"state":errors.length > 0 ? false:null,"maxlength":"50"},model:{value:(_vm.selectedOption.opt.translations[_vm.selectedOption.translationNr].author),callback:function ($$v) {_vm.$set(_vm.selectedOption.opt.translations[_vm.selectedOption.translationNr], "author", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"selectedOption.opt.translations[selectedOption.translationNr].author"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('b-button',{staticClass:"ml-25",attrs:{"variant":"success","size":"sm"},on:{"click":function () {
          if (_vm.selectedOption.id === -1) {
            _vm.newWebsite.opinionOptions.push(_vm.selectedOption.opt)
          } else { _vm.newWebsite.opinionOptions[_vm.selectedOption.id] = _vm.selectedOption.opt }
          // newWebsite.opinionOptions[selectedOption.id] = selectedOption.opt
          _vm.$forceUpdate()

          _vm.showChangeOpinionOptionsModal = false
        }}},[_vm._v(" "+_vm._s(_vm.$t('Save'))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }