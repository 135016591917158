<template>
  <div
    class="w-100 d-flex flex-column"
  >
    <item
      v-for="(item, i) in list"
      :key="i"
      :user="{
        firstName: item.firstName,
        lastName: item.lastName,
        position: i + 1,
        id: item.id,
        avatar: item.avatar,
        userId: item.userId,
        points: item.totalSystemAmount,
        products: item.products,
        converter: item.converter,
      }"
      :type="type"
      :max-sale="largestSell"
      class="border-bottom"
    />
  </div>
</template>

<script>
import Item from './components/UserListItem.vue'

export default {
  components: {
    Item,
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      required: true,
      default: 'marketing',
    },
  },
  computed: {
    largestSell() {
      return Math.max(...this.list.map(e => Number(e.totalSystemAmount) || 0))
    },
  },
}
</script>
