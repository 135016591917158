<template>
  <!-- eslint-disable -->
    <div class="sw-audio-player" id="audio-player-root">
        <!-- Hide the default audio player -->
        <div>
            <audio :id="playerid" ref="player" style="display:none">
                <source :src="url" type="audio/mpeg">
            </audio>
        </div>

        <div class="player-container">
            <div id="player-row" class="player-row d-flex align-items-center">
                <div id="button-div" class="button-div">
                    <b-button v-show="!isPlaying" variant="gradient-primary" class="btn-icon" size="sm" @click="toggleAudio()">
                        <feather-icon icon="PlayIcon" />
                    </b-button>

                    <b-button v-show="isPlaying" variant="gradient-warning" class="btn-icon" size="sm" @click="toggleAudio()">
                        <feather-icon icon="PauseIcon" />
                    </b-button>
                </div>

                <div class="d-flex">
                    <span class="elapsed-time mr-25" v-html="elapsedTime()">00:00</span>
                    <div id="progress-bar" class="progress-bar">
                        <div class="overlay-container">
                            <input
                                    id="position"
                                    v-model="playbackTime"
                                    type="range"
                                    min="0"
                                    :max="audioDuration"
                                    class="slider"
                                    name="position"
                            >

                            <!-- Show loading indicator until audio has been loaded -->
<!--                            <div v-show="!audioLoaded" class="loading-indicator">-->
<!--                                Loading please wait...-->
<!--                            </div>-->

<!--                            <div v-show="audioLoaded" class="time-display">-->
<!--                            </div>-->
                        </div>
                    </div>
                    <span class="total-time ml-25" v-html="totalTime()">00:00</span>
                </div>
            </div>
        </div>

        <!-- outer gray border -->
    </div>
  <!-- white bg -->
</template>

<script>
/* eslint-disable */
// import { mapState } from 'vuex'
import axiosIns from "@/libs/axios";

export default {
  props: ['url', 'playerid', 'time'],
  /**
     * playbackTime = local var that syncs to audio.currentTime
     * audioDuration = duration of audio file in seconds
     * isPlaying = boolean (true if audio is playing)
     *
     * */
  data() {
    return {
      playbackTime: 0,
      audioDuration: 100,
      audioLoaded: false,
      isPlaying: false,
    }
  },
  mounted() {
    // nextTick code will run only after the entire view has been rendered
    this.$nextTick(function () {
      const audio = this.$refs.player
      // Wait for audio to load, then run initSlider() to get audio duration and set the max value of our slider
      // "loademetadata" Event https://www.w3schools.com/tags/av_event_loadedmetadata.asp
      audio.addEventListener(
        'loadedmetadata',
        // eslint-disable-next-line no-unused-vars
        e => {
          this.initSlider()
        },
      )
      // "canplay" HTML Event lets us know audio is ready for play https://www.w3schools.com/tags/av_event_canplay.asp
      audio.addEventListener(
        'canplay',
        // eslint-disable-next-line no-unused-vars
        e => {
          this.audioLoaded = true
        },
      )

      // Wait for audio to begin play, then start playback listener function
      this.$watch('isPlaying', function () {
        if (this.isPlaying) {
          const audio = this.$refs.player
          this.initSlider()
          // console.log("Audio playback started.");
          // prevent starting multiple listeners at the same time
          if (!this.listenerActive) {
            this.listenerActive = true
            // for a more consistent timeupdate, include freqtimeupdate.js and replace both instances of 'timeupdate' with 'freqtimeupdate'
            audio.addEventListener('timeupdate', this.playbackListener)
          }
        }
      })
      // Update current audio position when user drags progress slider
      this.$watch('playbackTime', function () {
        // eslint-disable-next-line no-shadow,no-unused-vars
        const audio2 = this.$refs.player
        const diff = Math.abs(this.playbackTime - this.$refs.player.currentTime)

        // Throttle synchronization to prevent infinite loop between playback listener and this watcher
        if (diff > 0.01) {
          this.$refs.player.currentTime = this.playbackTime
        }
      })

        this.$nextTick(() => {
            audio.play()
            this.isPlaying = true
        })
    })
  },
  methods: {
    async loadAudio(audioFile, index) {
        this.audioLoading = false
        // this.$set(this.items[index], 'fileLoading', true)
        const response = await axiosIns.get(`storage/1/download/${audioFile.token}`)

        const base64Audio = response.data.data.file
        const audioUrl = `data:audio/${audioFile.extension};base64,${base64Audio}`

        // this.$set(this.items[index], 'fileLoading', false)
        // this.$set(this.items[index], 'loadedFile', audioUrl)
        this.audioFile = `data:audio/${audioFile.extension};base64,${base64Audio}`
        this.audioLoading = false
        return audioUrl
    },
    // Set the range slider max value equal to audio duration
    initSlider() {
      const audio = this.$refs.player
      if (audio) {
        this.audioDuration = Math.round(audio.duration)
      }
    },
    // Convert audio current time from seconds to min:sec display
    convertTime(seconds) {
      const format = val => `0${Math.floor(val)}`.slice(-2)
      const hours = seconds / 3600
      const minutes = (seconds % 3600) / 60
      return [minutes, seconds % 60].map(format).join(':')
    },
    // Show the total duration of audio file
    totalTime() {
      const audio = this.$refs.player
      // return this.time
      if (audio) {
        const seconds = audio.duration
        return this.convertTime(seconds)
      }
      return '00:00'
    },
    // Display the audio time elapsed so far
    elapsedTime() {
      const audio = this.$refs.player
      if (audio) {
        const seconds = audio.currentTime
        return this.convertTime(seconds)
      }
      return '00:00'
    },
    // Playback listener function runs every 100ms while audio is playing
    playbackListener(e) {
      const audio = this.$refs.player
      // Sync local 'playbackTime' var to audio.currentTime and update global state
      this.playbackTime = audio.currentTime

      // console.log("update: " + audio.currentTime);
      // Add listeners for audio pause and audio end events
      audio.addEventListener('ended', this.endListener)
      audio.addEventListener('pause', this.pauseListener)
    },
    // Function to run when audio is paused by user
    pauseListener() {
      this.isPlaying = false
      this.listenerActive = false
      this.cleanupListeners()
    },
    // Function to run when audio play reaches the end of file
    endListener() {
      this.isPlaying = false
      this.listenerActive = false
      this.cleanupListeners()
    },
    // Remove listeners after audio play stops
    cleanupListeners() {
      const audio = this.$refs.player
      audio.removeEventListener('timeupdate', this.playbackListener)
      audio.removeEventListener('ended', this.endListener)
      audio.removeEventListener('pause', this.pauseListener)
      // console.log("All cleaned up!");
    },
    toggleAudio() {
      const audio = this.$refs.player
      // var audio = document.getElementById("audio-player");
      if (audio.paused) {
        audio.play()
        this.isPlaying = true
      } else {
        audio.pause()
        this.isPlaying = false
      }
    },
  },
}
</script>

<style>

</style>
