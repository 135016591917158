<template>
  <transition name="fade">
    <feather-icon
      v-if="showHelpTooltips"
      v-b-popover.hover.top.primary="$t(description)"
      :icon="icon"
      :title="$t(title)"
      :class="classes"
      class="mx-25"
    />
  </transition>
</template>

<script>
import { VBPopover } from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  name: 'HelpTooltip',
  directives: {
    'b-popover': VBPopover,
  },
  props: {
    icon: {
      type: String,
      default: 'HelpCircleIcon',
      required: false,
    },
    classes: {
      type: [Array, String],
      required: false,
      default: 'text-primary',
    },
    description: {
      required: true,
      type: String,
      default: '',
    },
    title: {
      required: false,
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      showHelpTooltips: 'app/getShowHelpTooltips',
    }),
  },
}
</script>

<style scoped>

</style>
