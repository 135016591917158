<!-- eslint-disable -->

<template>
  <b-row
    class="mb-2"
    style="row-gap: 2rem"
  >
    <b-col
      md="4"
      lg="3"
      class="d-flex flex-column"
      style="row-gap: 1rem;"
    >
      <b-card
        class="m-0 sticky-to-top"
        no-body
      >
        <b-form-input
          v-model="search"
          :placeholder="`${$t('Search')}…`"
          class="input-search card-body border-0"
          style="padding-top: 2rem; padding-bottom: 2rem;"
        />

        <hr class="m-0">

        <b-list-group flush>
          <b-list-group-item
            v-for="item in searchResult"
            :key="item.title"
            v-model="value"
            class="d-flex flex-row align-items-center cursor-pointer"
            style="column-gap: .75rem"
            active-class="bg-less-primary"
            :class="{ 'bg-tonal-primary text-primary': isPanelActive(item.key || item.title) }"
            @click="changeActivePanel(item.key || item.title)"
          >
            <b-avatar variant="light-primary">
              <feather-icon :icon="item.icon" />
            </b-avatar>

            <div class="d-flex flex-column">
              <h5 class="text-primary mb-25 text-capitalize">
                {{ $t(item.title) }}
              </h5>

              <small
                v-if="item.children && item.children.length"
                class="d-flex flex-wrap text-secondary"
              >
                <template
                  v-for="(subItem, subIndex) in item.children"
                >
                  {{ $t(subItem.title) }}<template v-if="subIndex !== item.children.length - 1">,</template>
                </template>
              </small>

              <small
                v-else-if="item.dsc"
                class="d-flex flex-wrap text-secondary"
              >
                {{ $t(item.dsc) }}
              </small>
            </div>
          </b-list-group-item>

          <b-list-group-item
            v-if="!searchResult.length"
            class="d-flex flex-row align-items-center justify-content-center opacity-disabled py-1 text-primary"
            active-class="bg-less-primary"
          >
            {{ $t('NoData') }}
          </b-list-group-item>
        </b-list-group>
      </b-card>

      <slot name="left" />
    </b-col>

    <b-col
      md="8"
      lg="9"
    >
      <component
        :is="activePanel.component"
        v-if="activePanel && activePanel.component"
      />

      <slot v-else-if="$slots.default" />

      <div
        v-else
        class="p-2 text-center text-primary"
      >
        <feather-icon icon="XCircleIcon" />

        {{ $t('NoData') }}
      </div>
    </b-col>
  </b-row>
</template>

<script>
/* eslint-disable */

export default {
  props: {
    value: {
      type: [Object, null],
      required: false,
      default: null,
    },
    tabs: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  emits: ['input'],

  data: () => ({
    search: '',
    activePanel: null,
    activePanelTab: 0,
  }),

  computed: {
    searchResult() {
      if (!this.search.length) return this.tabs

      return this.tabs.filter(item => (
        this.$t(item.title).toLowerCase().includes(this.search.toLowerCase())
        || (item.children ? item.children.some(subItem => this.$t(subItem.title).toLowerCase().includes(this.search.toLowerCase())) : false)
        || (item.dsc ? this.$t(item.dsc).toLowerCase().includes(this.search.toLowerCase()) : false)
      ))
    },
  },

  watch: {
    tabs(n, o) {
      if (n?.length !== o?.length) {
        const { panel } = this.$route.params

        if (panel) this.changeActivePanel(panel)
      }
    },

    value(n, o) {
      if (n?.key && o?.key && n.key !== o.key) this.changeRouterTab(n.key)
    },
  },

  mounted() {
    this.ini()
  },

  methods: {
    ini() {
      const { panel } = this.$route.params

      if (!panel && this.tabs[0].key) this.changeRouterTab(this.tabs[0].key)

      this.changeActivePanel(panel)
    },

    changeActivePanel(key) {
      this.activePanel = key ? this.tabs.find(item => item[item.key ? 'key' : 'title'] === key) : this.tabs[0]
      this.activePanelTab = 0

      this.$emit('input', this.activePanel)
      this.search = ''
    },

    changeRouterTab(key) {
      this.$router.push(`/settings/${key}`)
    },

    isPanelActive(key) {
      const {
        activePanel,
      } = this

      return activePanel && this.activePanel[this.activePanel.key ? 'key' : 'title'] === key
    },
  },
}
</script>
