<template>
  <div class="d-flex justify-content-end align-items-center">
    <slot />

    <div
      v-if="isEmpty"
      class="cursor-pointer position-absolute mr-75"
      style="opacity: .8"
      @click="$emit('clear')"
    >
      <feather-icon
        icon="XIcon"
        style="stroke-width: .3rem; color: var(--secondary);"
        size="14"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isEmpty: {
      type: null,
      required: false,
      default: false,
    },
  },
  emits: ['clear'],
}
</script>
