var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-text',[_c('b-form',[_c('validation-observer',{attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"12","lg":"12"}},[_c('sw-select',{attrs:{"name":_vm.$t('offer.Create')}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"placeholder":_vm.$t('offer.Create'),"options":_vm.offerTypes},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t('offer.' + label))+" ")]}},{key:"selected-option",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t('offer.' + label))+" ")]}},{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('NoOptions'))+" ")]},proxy:true}]),model:{value:(_vm.newOffer.type),callback:function ($$v) {_vm.$set(_vm.newOffer, "type", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"newOffer.type"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"12","lg":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('offer.Select'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('sw-select',{attrs:{"name":_vm.$t('offer.Select')}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"placeholder":_vm.$t('offer.Select'),"options":_vm.oldOffers,"label":"name"},on:{"search":_vm.loadOldOffers,"option:selected":_vm.selectOldOffer},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(label)+" ")]}},{key:"selected-option",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(label)+" ")]}},{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('NoOptions'))+" ")]},proxy:true}],null,true),model:{value:(_vm.newOffer.selectOld),callback:function ($$v) {_vm.$set(_vm.newOffer, "selectOld", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"newOffer.selectOld"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"12","lg":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Name')}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('Name'),"state":errors.length > 0 ? false:null},model:{value:(_vm.newOffer.name),callback:function ($$v) {_vm.$set(_vm.newOffer, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"newOffer.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1)],1),_c('div',{staticClass:"d-flex justify-content-end pt-1 mt-1 border-top"},[_c('b-button',{attrs:{"size":"sm","variant":"primary"},on:{"click":_vm.pushToServer}},[_vm._v(" "+_vm._s(_vm.$t('Save'))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }