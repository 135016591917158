<script>
import { BEmbed, VBTooltip } from 'bootstrap-vue'

export default {
  name: 'TrainingListCard',
  components: {
    BEmbed,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    item: {
      default: null,
      required: true,
    },
  },
  emites: ['edit', 'remove'],
  computed: {
    avatar() {
      if (this.item?.trainingTrainer?.avatar?.preview) return `data:image/webp;base64,${this.item?.trainingTrainer?.avatar?.preview}`

      return this.item?.trainingTrainer?.staticAvatar
    },
  },
  methods: {
    redirectTo(url) {
      window.open(url, '_blank')
    },
    selectCard() {
      this.$emit('select', this.isSelected ? null : this.item?.id)
    },
  },
}
</script>

<template>
  <b-card
    v-if="item"
    no-body
  >
    <div class="d-flex align-items-center p-75">
      <b-avatar
        class="shadow"
        size="30"
        :src="avatar"
      />
      <div class="ml-1 w-100">
        <div class="d-flex justify-content-between w-100">
          <span>
            <small>{{ item.trainingTrainer.name }}</small>
            <div>
              <span
                v-if="item.url"
                class="text-primary cursor-pointer"
                @click="redirectTo(item.url)"
              >{{ item.name }}</span>
              <span v-else>{{ item.name }}</span>
            </div>
          </span>

          <div>
            <div v-if="checkRequiredPermissions([$roles.FULL_ACCESS])">
              <feather-icon
                v-if="item.id"
                class="text-primary mx-50 cursor-pointer"
                icon="SettingsIcon"
                @click.stop="$emit('edit')"
              />
              <feather-icon
                v-if="item.id"
                class="text-primary mx-50 cursor-pointer"
                icon="TrashIcon"
                @click.stop="$emit('remove')"
              />
            </div>
            <feather-icon
              v-if="!item.id"
              v-b-tooltip
              :title="$t('RecomendedBySaleswizard')"
              class="text-warning mx-50"
              icon="StarIcon"
            />
          </div>
        </div>
      </div>
    </div>
    <b-embed
      type="iframe"
      width="100%"
      height="100%"
      :src="item.url"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
      class="rounded-bottom shadow-lg"
    />
  </b-card>
</template>

<style scoped>

</style>
