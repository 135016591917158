<template>
  <sw-tabs
    lazy
    :tabs="tabs"
    :card="false"
  />
</template>

<script>
// import Sms from './sms/Sms.vue'
import Mail from './mail/Mail.vue'

export default {
  data: () => ({
    activeTab: null,
    tabs: [
      {
        title: 'EmailModule',
        component: Mail,
        icon: 'MailIcon',
      },
      // {
      //   title: 'SmsModule',
      //   component: Sms,
      //   icon: 'MessageSquareIcon',
      // },
    ],
  }),
}
</script>
