<template>
  <v-facebook-login
    ref="FB"
    :key="isLoggedIn"
    :app-id="appId"
    version="v15.0"
    logo-class="d-none"
    class="btn btn-primary"
    :options="{
      oauth: true,
      status : true,
      cookie : true,
      xfbml : true,
      version : 'v15.0'
    }"
    :login-options="{ scope: scopes.join(',') }"
    :fields="['name', 'email']"
    sdk-locale="pl_PL"
    @login="loginProcess"
    @logout="logoutProcess"
    @sdk-init="initProcess"
  >
    <template #login>
      {{ $t('fb.AddConnection') }}

      <feather-icon
        icon="PlusIcon"
        style="top: 0"
      />
    </template>

    <template #logout>
      {{ $t('Logout') }}

      <!--      <feather-icon-->
      <!--        icon="PlusIcon"-->
      <!--        style="top: 0"-->
      <!--      />-->
    </template>
  </v-facebook-login>
</template>

<script>
import VFacebookLogin from 'vue-facebook-login-component'
import moment from 'moment'

export default {
  name: 'FacebookButton',
  components: {
    VFacebookLogin,
  },
  props: {
    isLogged: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    appId: '734418081433968',
    isLoggedIn: false,
    scopes: [
      'pages_read_engagement',
      'public_profile',
      'pages_manage_metadata',
      'pages_show_list',
      'ads_management',
      'pages_manage_ads',
      'leads_retrieval',
    ],

    SDK: null,
  }),
  watch: {
    isLogged(n) {
      this.isLoggedIn = n

      this.$forceUpdate()
    },
  },
  mounted() {
    // Login when session storage is set
    this.$emit('login', this.validateSessionToken())
  },
  methods: {
    manualLogout() {
      if (this.$refs.FB?.$listeners) {
          // eslint-disable-next-line no-unused-expressions
          this.$refs.FB.$listeners?.logout()
      }
    },
    // eslint-disable-next-line consistent-return
    validateSessionToken() {
      const sessionItem = sessionStorage.getItem(`fbssls_${this.appId}`)
      if (!sessionItem) return null

      const expiresAt = moment(JSON.parse(sessionItem).expiresAt)
      const now = moment()

      if (expiresAt.isAfter(now)) {
        return { ...JSON.parse(sessionItem) }
      }

      sessionStorage.removeItem(`fbssls_${this.appId}`)
      this.$forceUpdate()
      return null
    },
    loginProcess(e) {
      this.$emit('login', e)
    },
    logoutProcess(e) {
      this.$emit('logout', e)

      // this.SDK.FB.login()
    },
    initProcess(e) {
      this.$set(this, 'SDK', e)

      this.$emit('init', e)
    },
  },
}
</script>

<style>
.v-facebook-login span {
  white-space: nowrap;
}
</style>
