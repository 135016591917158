<template>
  <div>
    <b-button
      v-if="checkRequiredPermissions([$roles.DOCUMENT_MODIFY])"
      variant="primary"
      size="sm"
      class="mb-50"
      @click="isModalOpen = true"
    >
      <feather-icon
        icon="PlusIcon"
        class="mr-25"
      />
      {{ $t('Add') }}
    </b-button>

    <b-card
      id="docs"
    >
      <sw-select>
        <v-select
          v-model="pagination.perPage"
          :options="[25, 50, 100, 250]"
          :clearable="false"
          class="per-page-selector d-inline-block mb-50 w-100"
          @input="getFolders"
        >
          <template #no-options>
            {{ $t('NoOptions') }}
          </template>
        </v-select>
      </sw-select>

      <b-table
        :items="folders"
        class="table-wrap-words rounded"
        :fields="foldersFields"
        striped
        responsive
        :busy="loading"
        show-empty
      >
        <!--    Head    -->
        <template #head()="{ label }">
          {{ $t(`${label}`) }}
        </template>

        <!--     Name     -->
        <template #cell(name)="data">
          <router-link
            :to="{ name: $route.name, params: { folderId: data.item.id } }"
          >
            <span class="text-primary">{{ data.value === 'Trainings' ? $t('Trainings') : data.value }}</span>
          </router-link>
        </template>

        <!--     Types Of Signature     -->
        <template #cell(typesOfSignature)="{ value }">
          <template v-if="value.length">
            <b-badge
              v-for="type in value"
              :key="type.name"
              variant="primary"
            >
              {{ $t(type.name) }}
            </b-badge>
          </template>

          <feather-icon
            v-else
            icon="MinusIcon"
          />
        </template>

        <!--    CreatedBy    -->
        <template #cell(createdBy)="{ value }">
          <avatar
            :user="value"
            :to="{ name: 'user', params: { userId: value.id } }"
          />
        </template>
        <!--     Share     -->
        <template #cell(sharedRanks)="data">
          <b-button
            v-if="checkRequiredPermissions([$roles.DOCUMENT_MODIFY])"
            size="sm"
            variant="flat-primary"
            @click="
              selectedTarget = { id: data.index, item: folders[data.index] }
              showFolderSharesModal = true
            "
          >
            <feather-icon
              icon="Share2Icon"
            />
          </b-button>
          <feather-icon
            v-else
            icon="MinusIcon"
          />
        </template>

        <!-- Action -->
        <template #cell(action)="{ item, index }">
          <b-dropdown
            v-if="checkRequiredPermissions([$roles.DOCUMENT_MODIFY]) && item.name !== 'Trainings'"
            id="dropdown-dropleft"
            dropleft
            no-caret
            variant="flat"
          >
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" />
            </template>
            <b-dropdown-item @click="editFolder(item, index)">
              {{ $t('Edit') }}
            </b-dropdown-item>
            <b-dropdown-item @click="deleteFolder(item, index)">
              {{ $t('Delete') }}
            </b-dropdown-item>
          </b-dropdown>
          <feather-icon
            v-else
            icon="MinusIcon"
          />
        </template>

        <!--    Other    -->
        <!--    Other    -->
        <template #cell()="row">
          <table-default-cell
            :field="row.field"
            :value="row.value"
          />
        </template>

        <!--      Empty      -->
        <template #empty>
          <empty-content />
        </template>

        <!--      Table Busy      -->
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner
              class="align-middle"
              variant="primary"
            />
          </div>
        </template>
      </b-table>
      <!-- Pagination -->
      <b-pagination
        v-model="pagination.currentPage"
        class="pt-50"
        :total-rows="pagination.totalRecords"
        :per-page="pagination.perPage"
        align="center"
        @change="getFolders"
      />
    </b-card>

    <b-modal
      :visible="isModalOpen"
      title="Folder"
      hide-footer
      no-close-on-backdrop
      @hide="isModalOpen = false; editedFolder = { folder: null, index: -1 }"
    >
      <new-folder-modal
        :edited-folder="editedFolder.folder"
        @save-item="saveItem"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  CREATE_FOLDER, GET_FOLDERS, REMOVE_FOLDER, UPDATE_FOLDER,
} from '@/@constants/mutations'
import { BModal, BTable, VBTooltip } from 'bootstrap-vue'
import NewFolderModal from '@/views/modals/NewDocumentsFolderModal.vue'
import { formatDate } from '@core/utils/filter'
import vSelect from 'vue-select'

export default {
  name: 'FolderList',
  components: {
    BTable,
    BModal,
    NewFolderModal,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data: () => ({
    // Pagination
    sorter: {
      sortBy: 'createdAt',
      sortDesc: true,
    },
    pagination: {
      perPage: 25,
      currentPage: 1,
      totalRecords: 100,
    },
    foldersFields: [
      { key: 'name', label: 'Name', visible: true },
      { key: 'electronicSignatureType', label: 'TypesOfSignature', visible: true },
      { key: 'createdAt', label: 'CreatedAt', visible: true },
      { key: 'updatedAt', label: 'UpdatedAt', visible: true },
      { key: 'createdBy', label: 'CreatedBy', visible: true },
      { key: 'action', label: 'Action', visible: true },
    ],
    folders: [],
    loading: false,
    isModalOpen: false,
    editedFolder: {
      folder: null,
      index: -1,
    },
  }),
  computed: {
    documentsType() {
      if (this.$route.name === 'documents') return 'FILE'

      return 'VIDEO'
    },
  },
  watch: {
    $route: {
      deep: true,
      handler(newValue) {
        this.getFolders()
        this.$store.dispatch('app/UPDATE_TITLE', {
          label: newValue.name === 'documents' ? this.$i18n.t('Documents') : this.$i18n.t('Videos'),
          title: newValue.name === 'documents' ? 'Documents' : 'Videos',
        })
      },
    },
  },
  mounted() {
    this.getFolders()
  },
  methods: {
    formatDate,
    getFolders() {
      this.loading = true

      this.$nextTick(() => {
        this.$store.dispatch(`globalDocuments/${GET_FOLDERS}`, { documentsType: this.documentsType, pagination: this.pagination })
          .then(res => {
            this.pagination.totalRecords = res.data.totalItemCount
            this.folders = res.data.items
            if (this.$route.name === 'videos') {
              // this.folders.unshift({
              //   id: 'trainings',
              //   name: 'Trainings',
              // })
            }
            this.loading = false
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          })
      })
    },
    editFolder(item, index) {
      this.$set(this.editedFolder, 'folder', { ...item })
      this.$set(this.editedFolder, 'index', index)
      this.isModalOpen = true
    },
    saveItem(item) {
      const payload = JSON.parse(JSON.stringify(item))
      let action = CREATE_FOLDER
      if (payload?.id) action = UPDATE_FOLDER

      Object.keys(payload).forEach(key => {
        if (Array.isArray(payload[key])) payload[key] = payload[key].map(value => value.id)
      })

      payload.type = this.documentsType

      this.$nextTick(() => {
        this.$store.dispatch(`globalDocuments/${action}`, payload)
          .then(() => {
            const text = action === CREATE_FOLDER ? this.$i18n.t('success.offerFolderCreated') : this.$i18n.t('success.offerFolderUpdated')
            this.showToast('success', text, this.$i18n.t('success.saved'))

            this.getFolders()
            this.isModalOpen = false
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          })
      })
    },
    deleteFolder(item, index) {
      this.$http.get(`1/documents/folders/${item.id}/files`, { params: { fields_load: this.$fields.BASE_ID, limit: 1 } })
        .then(res => {
          if (res.data.data.items.length) {
            this.showAlert('error', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('alerts.FolderIsNotEmpty'))
              .then(() => {
                this.removeFile(item.id, index)
              })
          } else {
            this.removeFile(item.id, index)
          }
        })
    },
    removeFile(id, index) {
      this.$store.dispatch(`globalDocuments/${REMOVE_FOLDER}`, id)
        .then(() => {
          this.$delete(this.folders, index)
          this.showToast('success', this.$i18n.t('RemovedSuccessfully'))
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
  },
}
</script>

<style scoped>

</style>
