<template>
  <div
    class="d-flex flex-wrap align-items-end"
    style="column-gap: 2rem"
  >
<!--    <user-badge-->
<!--      v-for="(topUser, topUserIndex) in getTopSellers"-->
<!--      :key="`top_user_${topUserIndex}`"-->
<!--      :user="{-->
<!--        position: getPosition( topUserIndex + 1),-->
<!--        ...topUser,-->
<!--      }"-->
<!--    />-->

    <user-badge
      v-if="getTopSellers.length >= 2"
      :user="{
          ...getTopSellers[1],
        position: 2,
      }"
    />

    <user-badge
      v-if="getTopSellers.length >= 1"
      :user="{
          ...getTopSellers[0],
        position: 1,
      }"
    />

    <user-badge
      v-if="getTopSellers.length == 3"
      :user="{
          ...getTopSellers[2],
        position: 3,
      }"
    />
  </div>
</template>

<script>
import UserBadge from './components/UserBadge.vue'

export default {
  components: {
    UserBadge,
  },
  props: {
    topUsers: {
      required: true,
      default: () => [],
    },
  },
  computed: {
    getTopSellers() {
      const sellers = this.topUsers || []
      return sellers
    },
  },
  methods: {
    getPosition(index) {
      if (index === 0) return 1
      if (index === 1) return 1
      if (index === 2) return 3

      return 0
    },
  },
}
</script>
