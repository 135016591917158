<template>
  <div
    ref="shortcode-tabs"
    class="shortcode-modal"
  >
    <sw-select>
      <v-select
        v-model="selectedTab"
        class="mb-50"
        label="title"
        :options="shortcodeTabs"
      >
        <template #selected-option="{ title }">
          <span>{{ $t(`${title}`) }}</span>
        </template>
        <template #option="{ title }">
          <span>{{ $t(`${title}`) }}</span>
        </template>
      </v-select>
    </sw-select>

    <transition-group
      name="slide-fade"
      mode="out-in"
    >
      <b-alert
        v-for="(shortcode, codeIndex) in selectedTab.shortcodes"
        :key="`shortcode_${codeIndex}`"
        v-b-tooltip
        show
        variant="secondary"
        class="mb-25 shortCodesAnim cursor-pointer"
        :title="shortcode.options ? '' : `{% ${shortcode.code} %}`"
      >
        <!-- Options Popover -->
        <b-popover
          v-if="shortcode.options"
          :target="`shortcode_${codeIndex}`"
          placement="top"
          :title="$t(`${shortcode.title}`) | truncate(20)"
          triggers="hover"
        >
          <div>
            <b-badge
              v-for="(option, index) in shortcode.options"
              :key="`option_${codeIndex}_${index}`"
              variant="light-primary"
              class="mr-25 cursor-pointer"
              @click="copyShortcode(shortcode.variants && option === 'Value' ? `{% ${shortcode.code }.${option.toLowerCase()}:${shortcode.variants[0]}:${shortcode.variants[1]} %}` : (option === 'Age' ? `{% ${shortcode.code }.value.${option.toLowerCase()} %}` : `{% ${shortcode.code }.${option.toLowerCase()} %}`))"
            >
              <feather-icon
                class="mr-25"
                icon="CopyIcon"
              />
              {{ $t(`${option}`) }}
            </b-badge>
          </div>
          <!-- Checkbox values -->
          <div
            v-if="shortcode.variants"
            class="mt-50"
          >
            {{ $t('TextValuesInDocument') }}
            <b-form-input
              v-model.trim="shortcode.variants[0]"
              class="mt-25"
              :placeholder="$t('Yes')"
              size="sm"
            />
            <b-form-input
              v-model.trim="shortcode.variants[1]"
              class="mt-25"
              :placeholder="$t('NoNo')"
              size="sm"
            />
          </div>
        </b-popover>
        <div
          :id="`shortcode_${codeIndex}`"
          class="alert-body"
          @click="copyShortcode(`{% ${shortcode.code } %}`, !shortcode.options)"
        >
          <div class="d-flex justify-content-between align-items-center">
            <span class="ml-25">
              <span v-if="shortcode.titlePrefix">{{ $t(`${shortcode.titlePrefix}`) }} - </span>
              <span>{{ $t(`${shortcode.title}`) }}</span>
              <span v-if="shortcode.titleEndfix"> {{ $t(`${shortcode.titleEndfix}`) }} </span>
            </span>
            <feather-icon
              v-if="!shortcode.options"
              class="mr-25 mt-25"
              size="20"
              icon="CopyIcon"
            />
          </div>
        </div>
      </b-alert>
    </transition-group>
    <!--  Footer  -->
    <div class="d-flex justify-content-end pt-1 mt-1 border-top">
      <b-button
        variant="secondary"
        size="sm"
        @click="onModalClose"
      >
        {{ $t('Cancel') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import { BAlert, BPopover, VBTooltip } from 'bootstrap-vue'
import { shortcodes } from '@/@data/shortcodes'
import { CLOSE_MODAL, GET_CONTACT_ADDON_FIELD_TYPES, GET_PRODUCT_TEMPLATES } from '@/@constants/mutations'

export default {
  name: 'ShortcodesModal',
  components: {
    vSelect,
    BAlert,
    BPopover,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    polls: {
      type: Array,
      default: () => [],
    },
    defaultShortcodes: {
      type: Array,
      default: () => [],
    },
    includeDefaultCodes: {
      type: Boolean,
      default: false,
    },
    includeComponents: {
      type: Array,
      default: () => [],
    },
    showSections: {
      default: () => [],
      type: Array,
    },
  },
  data: () => ({
    shortcodeTabs: JSON.parse(JSON.stringify(shortcodes)),
    selectedTab: shortcodes[0],
  }),
  async mounted() {
    if (this.polls.length) {
      this.polls.forEach(poll => {
        const newShortcodeSection = {
          title: poll?.name,
          shortcodes: [
            ...poll.pollFields.sort(((a, b) => a.position - b.position)).map(field => {
              const code = {
                title: field.translations[0].name,
                code: `poll.${poll.id}.${field.id}`,
                options: ['Name', 'Description', 'Value'],
              }
              if (field.type === 'CHECKBOX') {
                code.variants = [this.$i18n.t('Yes'), this.$i18n.t('NoNo')]
              }
              return code
            }),
          ],
        }
        this.shortcodeTabs.push(newShortcodeSection)
      })
    }

    if (this.includeComponents && this.includeComponents.length) {
      this.getComponentsForShortcodes()
    }

    if (this.defaultShortcodes.length && !this.includeDefaultCodes) {
      this.shortcodeTabs = this.defaultShortcodes
      // eslint-disable-next-line prefer-destructuring
      this.selectedTab = this.defaultShortcodes[0]
    } else if (this.defaultShortcodes.length && this.includeDefaultCodes) {
      this.shortcodeTabs.push(...this.defaultShortcodes)
    }

    // Additional fields
    const fields = await this.$store.dispatch(`addonFieldTypes/${GET_CONTACT_ADDON_FIELD_TYPES}`)
    this.shortcodeTabs = this.shortcodeTabs.map(tab => {
      if (tab.title.includes('Contact') && !tab.title.includes('Thread') && !tab.shortcodes.some(s => s.code.includes('additional'))) {
        const typesSample = {
          DIGITAL: '15',
          PERCENTAGE: '10%',
          TEXT: 'Lorem ipsum dolor',
          DATE: '01.04.2021',
          METRIC: '5',
          TIME: '12:00',
          TEXT_ARRAY: 'Lorem',
          TEXT_MULTIPLE_ARRAY: 'Lorem, ipsum',
        }
        tab.shortcodes.push(...fields.map(field => ({
          title: field.name, titlePrefix: 'AdditionalInformation', code: `contact.additionalField.${field.id}`, sample: typesSample[field.type], options: ['Name', 'Value', 'Description', (field.type === 'DATE' ? 'Age' : '')].filter(Boolean),
        })))
      }

      return tab
    })

    if (this.showSections.length) {
      this.shortcodeTabs = this.shortcodeTabs.filter(tab => this.showSections.includes(tab.title))
    }
  },
  computed: {
    ...mapGetters({
      modalConfig: 'modal/getModalState',
    }),
  },
  methods: {
    getComponentsForShortcodes() {
      this.$store.dispatch(`productTemplate/${GET_PRODUCT_TEMPLATES}`, { filters: { type: this.includeComponents } })
        .then(res => {
          this.prepareComponentsShortcodes(res.data.items)
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
    prepareComponentsShortcodes(componentItems) {
      const prepared = componentItems.map(({ name, type, id }) => ({ title: name, code: `component.${id}`, sample: `${type} - ${id}` }))
      this.shortcodeTabs.push({
        title: 'Components',
        shortcodes: prepared,
      })
    },
    onModalClose() {
      this.$store.commit(`modal/${CLOSE_MODAL}`)
      this.$emit('close-modal')
      this.shortcodeTabs = JSON.parse(JSON.stringify(shortcodes))
    },
    copyShortcode(code, copy = true) {
      if (copy) {
        this.$copyText(code, this.$refs['shortcode-tabs']).then(() => {
          this.showToast('primary', this.$i18n.t('CopiedToClipboard'))
          this.onModalClose()
        }, e => {
          this.showToast('success', e)
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-lg {
  .tooltip {
    .tooltip-inner{
      //white-space: nowrap;
      width: 400px !important;
      max-width: 400px !important;
    }
  }
}
</style>
