<template>
  <div>
    <b-button
      variant="primary"
      size="sm"
      class="mb-1"
      @click="onSaveSettings"
    >
      {{ $t('Save') }}
    </b-button>

    <b-card class="position-relative">
      <template #header>
        <h4 class="d-flex align-items-center">
          {{ $t('ImageRecognition') }}

          <b-form-checkbox
            v-model="imageRecognitionPlantNetEnabled"
            class="ml-1"
            checked="true"
            name="check-button"
            variant="primary"
            switch
            inline
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </h4>
      </template>

      <b-row>
        <b-col sm="12">
          <b-form-group :label="$t('APIKey')">
            <b-form-input
              v-model="imageRecognitionPlantNetToken"
              :disabled="!imageRecognitionPlantNetEnabled"
              type="password"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12">
          <sw-select :name="$t('DefaultLanguage')">
            <v-select
              v-model="imageRecognitionPlantNetDefaultLanguage"
              :searchable="false"
              :filtrable="false"
              :clearable="false"
              :disabled="!imageRecognitionPlantNetEnabled"
              :options="['pl', 'en', 'de']"
            >
              <template #no-options>
                {{ $t('NoOptions') }}
              </template>
              <template #option="{ label }">
                {{ $t(`langs.${label}`) }}
              </template>
              <template #selected-option="{ label }">
                {{ $t(`langs.${label}`) }}
              </template>
            </v-select>
          </sw-select>
        </b-col>
      </b-row>

      <b-overlay
        :show="loading"
        no-wrap
        spinner-variant="primary"
      />
    </b-card>

    <b-button
      variant="primary"
      size="sm"
      @click="onSaveSettings"
    >
      {{ $t('Save') }}
    </b-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { GET_PRIVATE_SETTINGS, SAVE_SETTINGS } from '@/@constants/mutations'
import vSelect from 'vue-select'

export default {
  name: 'ImageRecognition',
  components: {
    vSelect,
  },
  data() {
    return {
      loading: false,
      imageRecognitionPlantNetToken: '',
      imageRecognitionPlantNetEnabled: false,
      imageRecognitionPlantNetDefaultLanguage: 'pl',
    }
  },
  computed: {
    ...mapGetters({
      system: 'system/getSettings',
    }),
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.loading = true
      this.$store.dispatch(`system/${GET_PRIVATE_SETTINGS}`)
        .then(res => {
          const token = res.data.item?.imageRecognitionPlantNetTokenFilled ? '*******************' : ''

          this.$set(this, 'imageRecognitionPlantNetToken', res.data.item?.imageRecognitionPlantNetToken || token)
          this.$set(this, 'imageRecognitionPlantNetEnabled', res.data.item?.imageRecognitionPlantNetEnabled || false)
          this.$set(this, 'imageRecognitionPlantNetDefaultLanguage', res.data.item?.imageRecognitionPlantNetDefaultLanguage || 'pl')

          this.loading = false
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          this.loading = false
        })
    },
    onSaveSettings() {
      this.loading = true
      const payload = {
        imageRecognitionPlantNetEnabled: this.imageRecognitionPlantNetEnabled,
        imageRecognitionPlantNetDefaultLanguage: this.imageRecognitionPlantNetDefaultLanguage,
        imageRecognitionPlantNetToken: this.imageRecognitionPlantNetToken,
      }

      if (this.imageRecognitionPlantNetToken.includes('********')) {
        delete payload.imageRecognitionPlantNetToken
      }

      this.$store.dispatch(`system/${SAVE_SETTINGS}`, { privateData: payload })
        .then(() => {
          this.loading = false
          this.showToast('success', this.$i18n.t('ChangesSaved'))
          this.loadData()
        })
        .catch(err => {
          this.loading = false
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
  },
}
</script>

<style scoped>

</style>
