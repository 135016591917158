<template>
  <iframe
    width="100%"
    height="375"
    :src="getUrl"
    title="YouTube video player"
    class="rounded"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    allowfullscreen
  />
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      modalState: ['modal/getModalState'],
    }),
    getUrl() {
      const { modalState } = this

      if (!modalState?.data?.url.length) {
        console.error('The Link is undefined')

        return -1
      }

      return modalState.data.url
    },
  },
}
</script>
