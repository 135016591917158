<template>
  <div>
    <div
      v-if="show"
      class="cursor-pointer"
      @click="show = false"
    >
      <slot name="content" />
    </div>
    <div
      v-else
      class="cursor-pointer"
      @click="show = true"
    >
      <feather-icon
        icon="LockIcon"
        class="mr-25 text-primary"
      />
      {{ $t('Show') }}
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'

export default {
  name: 'HiddenContent',
  setup() {
    const show = ref(false)

    return {
      show,
    }
  },
}
</script>

<style scoped>

</style>
