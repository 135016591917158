<template>
  <div
    class="d-flex flex-wrap align-items-center mt-25"
    style="gap: .5rem"
    :class="small ? 'font-small-1' : 'font-small-2'"
  >
    <template v-if="tags.length">
      <b-badge
        v-for="
          (tag, indexTag) in tags.slice(0, length || tags.length).filter(
            (obj, index) =>
              tags.slice(0, length || tags.length).findIndex((item) => item.name === obj.name) === index
          )
        "
        :key="indexTag"
        v-b-tooltip.hover.v-danger
        :class="isEdit ? 'cursor-pointer' : 'cursor-default'"
        :variant="!isEdit ? 'light-primary' : 'light-danger'"
        :title="!isEdit ? tag : $t('Delete')"
        :disabled="!isEdit"
        @click="isEdit ? deleteTag(tag.id) : null"
      >
        <span class="d-none d-md-inline-block">{{ '#' + tag.name }}</span>
        <span class="d-md-none">{{ '#' + tag.name | textIsland(14, '…') }}</span>
      </b-badge>

      <b-badge
        v-if="tags.length >= 8"
        class="cursor-pointer"
        variant="flat-primary"
        @click="mTags = tags"
      >
        <span
          class="text-primary"
          style="text-decoration: underline; font-size: .7rem"
        >
          {{ $t('More') + '…' }}
        </span>
      </b-badge>
    </template>

    <div
      v-else-if="!isEdit"
      class="text-center text-primary font-weight-bold mt-25"
    >
      <feather-icon
        size="17"
        icon="XCircleIcon"
      />

      {{ $t('NoTags') }}
    </div>

    <b-button
      v-if="isEdit"
      v-b-tooltip.hover.v-primary
      size="sm"
      variant="flat-primary"
      class="p-25"
      :title="$t('AddTag')"
      @click="mAddTag = true"
    >
      {{ $t('AddTag') }}

      <feather-icon icon="PlusIcon" />
    </b-button>

    <b-modal
      v-model="mAddTag"
      :title="$t('AddTag')"
      hide-footer
      no-close-on-backdrop
      tabindex="none"
      size="sm"
    >
      <validation-observer
        ref="loginForm"
        v-slot="{invalid}"
      >
        <validation-provider
          name="ChooseCategory"
          rules="required"
        >
          <sw-select :name="$t('ChooseTag')">
            <v-select
              v-model="tag"
              :placeholder="$t('ChooseTag')"
              :options="tagList"
              :disabled="!isEdit"
              :filterable="false"
              label="name"
              :reduce="item => ({ id: item.id.toString(), name: item.name })"
              item-value="id"
              item-text="name"
            >
              <template #option="{ name }">
                {{ name | textIsland(28) }}
              </template>

              <template #selected-option="{ name }">
                {{ name | textIsland(28) }}
              </template>

              <template #no-options>
                {{ $t('NoOptions') }}
              </template>
            </v-select>
          </sw-select>
        </validation-provider>

        <b-button
          variant="primary"
          class="w-100"
          size="sm"
          :disabled="invalid"
          @click="addTag()"
        >
          {{ $t('Add') }}
        </b-button>
      </validation-observer>

      <b-overlay
        no-wrap
        :show="isLoading || isMeLoading"
        spinner-variant="primary"
      />
    </b-modal>
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { CLOSE_MODAL, GET_PROJECT_TAGS } from '@/@constants/mutations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    tags: {
      type: Array,
      required: true,
    },
    isTask: {
      type: Boolean,
      required: false,
      default: false,
    },
    projectId: {
      type: [Number, null],
      required: false,
      default: null,
    },
    targetId: {
      type: [Number, null],
      required: false,
      default: null,
    },
    small: {
      type: Boolean,
      required: false,
      default: true,
    },
    isEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
    length: {
      type: Number,
      required: false,
      default: null,
    },
  },
  emits: ['new', 'remove'],
  data: () => ({
    isMeLoading: false,

    mAddTag: false,

    tagList: [],
    tag: '',
  }),
  watch: {
    mAddTag(n) {
      if (n) this.loadTags()
    },
  },
  methods: {
    loadTags() {
      this.isMeLoading = true

      this.$store.dispatch(`projectTags/${GET_PROJECT_TAGS}`)
        .then(res => {
          this.tagList = res

          const elementsToRemove = new Set(this.tags.map(item => item.id))
          this.tagList = this.tagList.filter(item => !elementsToRemove.has(item.id))
        })

        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })

        .finally(() => { this.isMeLoading = false })
    },

    deleteTag(id) { this.$emit('remove', id) },

    addTag() {
      const { tag } = this

      if (tag) {
        this.$emit('new', tag)

        this.tag = ''

        this.mAddTag = null
      }
    },

    closeModal() {
      this.$store.commit(`modal/${CLOSE_MODAL}`)
    },
  },
}
</script>
