// eslint-disable-next-line import/no-cycle
import axiosIns from '@/libs/axios'
import { DELETE_GROUPS, GET_GROUPS, SAVE_GROUPS } from '@/@constants/mutations'
import * as fields from '@/@constants/fields'

export default {
  namespaced: true,
  actions: {
    [GET_GROUPS]: () => new Promise((resolve, reject) => {
      axiosIns.get('1/users/groups', { params: { fields_load: fields.USERS_GROUPS } })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [DELETE_GROUPS]: ({ commit }, groupsToDelete) => new Promise((resolve, reject) => {
      axiosIns.delete('1/users/groups', { data: JSON.stringify(groupsToDelete) })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [SAVE_GROUPS]: ({ commit }, groups) => new Promise((resolve, reject) => {
      axiosIns.put('1/users/groups', groups)
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
  },
}
