<template>
  <b-card
    variant="primary"
    class="cardOverlay"
  >
    <b-card-header
      class="p-0 mb-1 d-flex align-items-center justify-content-between"
      style="row-gap: .34rem"
    >
      <div
        class="mb-25 d-flex flex-wrap align-items-center justify-content-md-start"
        style="row-gap: .34rem"
      >
        <b-card-title
          class="mb-0 d-flex align-items-center"
          style="gap: .64rem; word-break: break-word"
        >
          <feather-icon
            icon="StarIcon"
            size="20"
            class="text-primary"
          />

          {{ item.name | textIsland(isMobile ? 24 : 64) }}
        </b-card-title>

        <span class="mb-auto ml-50 d-flex align-items-center">
          <feather-icon
            icon="CalendarIcon"
            class="mr-25"
          />

          {{ item.createdAt.date.split(' ')[0] }}

          <feather-icon
            icon="ClockIcon"
            class="mx-25"
          />

          {{ item.createdAt.date.split(' ')[1].split('.')[0] }}
        </span>
      </div>

      <div>
<!--        <span-->
<!--          v-b-tooltip-->
<!--          :title="isCalculationDisabled(item) ? $t('DisabledCalculateButton') : ''"-->
<!--        >-->
<!--          <b-button-->
<!--            size="sm"-->
<!--            variant="primary"-->
<!--            :disabled="isCalculationDisabled(item)"-->
<!--            class="mr-25"-->
<!--            @click="calculate(item)"-->
<!--          >-->
<!--            {{ $t('CalculatePoints') }}-->
<!--          </b-button>-->
<!--        </span>-->

        <b-button
          v-if="checkRequiredPermissions([$roles.LOYALTY_PLAN_MODIFY])"
          size="sm"
          variant="primary"
          :to="{ name: 'newLoyaltyPlan', params: { loyaltyPlanId: item.id } }"
        >
          <feather-icon icon="EditIcon" />
          {{ $t('Edit') }}
        </b-button>
      </div>
    </b-card-header>

    <hr class="w-100 d-none d-lg-block">

    <b-row
      class="mt-2"
      :style="`row-gap: ${isMobile ? '1rem' : '1.5rem'}`"
    >
      <b-col
        md="6"
        class="d-flex align-items-center"
      >
        <b-avatar
          variant="light-primary"
          class="mr-75"
        >
          <feather-icon
            icon="UsersIcon"
            size="16"
          />
        </b-avatar>

        <div class="d-flex flex-column">
          <h4 class="mb-0 text-primary">
            {{ item.usersCount || 0 }}
          </h4>

          <p class="text-dark mb-0">
            {{ $t('AssignedUsers1') }}
          </p>
        </div>
      </b-col>

      <hr class="w-100 m-0 d-md-none">

      <b-col
        md="6"
        class="d-flex align-items-center"
      >
        <b-avatar
          variant="light-primary"
          class="mr-75"
        >
          <feather-icon
            icon="BoxIcon"
            size="16"
          />
        </b-avatar>

        <div class="d-flex flex-column">
          <h4 class="mb-0 text-primary">
            {{ item.shopProductsCount }}
          </h4>

          <p class="text-dark mb-0">
            {{ $t('AssignedProducts1') }}
          </p>
        </div>
      </b-col>

      <hr class="w-100 m-0 d-md-none">

      <b-col
        md="6"
        class="d-flex align-items-center"
      >
        <b-avatar
          variant="light-primary"
          class="mr-75"
        >
          <feather-icon
            icon="HexagonIcon"
            size="16"
          />
        </b-avatar>

        <div class="d-flex flex-column">
          <h4 class="mb-0 text-primary">
            {{ $te(`offer.status.${item.qualifiedStatus}`) ? $tc(`offer.status.${item.qualifiedStatus}`, 2) : '-' }}
          </h4>

          <p class="text-dark mb-0">
            {{ $t('QualifiedStatus') }}
          </p>
        </div>
      </b-col>

      <hr class="w-100 m-0 d-md-none">

      <b-col
        md="6"
        class="d-flex align-items-center"
      >
        <b-avatar
          variant="light-primary"
          class="mr-75"
        >
          <feather-icon
            icon="ClockIcon"
            size="16"
          />
        </b-avatar>

        <div class="d-flex flex-column">
          <h4 class="mb-0 text-primary">
            <span
              class="d-flex align-items-center"
              style="column-gap: .34rem"
            >
              <span class="d-flex align-items-center mr-25">
                <feather-icon
                  icon="CalendarIcon"
                  class="mr-25"
                />

                {{ item.createdAt.date.split(' ')[0] }}
              </span>

              <span class="d-flex align-items-center mr-25">
                <feather-icon
                  icon="ClockIcon"
                  class="mr-25"
                />

                {{ item.createdAt.date.split(' ')[1].split('.')[0] }}
              </span>
            </span>
          </h4>

          <p class="text-dark mb-0">
            {{ $t('CreatedAt') }}
          </p>
        </div>
      </b-col>

      <hr class="w-100 m-0 d-md-none">

      <b-col
        md="6"
        class="d-flex align-items-center"
      >
        <b-avatar
          variant="light-primary"
          class="mr-75"
          :src="item.createdBy.avatar"
        />

        <div class="d-flex flex-column">
          <h4 class="mb-0 text-primary">
            {{ item.createdBy.firstName }} {{ item.createdBy.lastName }}
          </h4>

          <p class="text-dark mb-0">
            {{ $t('CreatedBy') }}
          </p>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCardTitle, VBTooltip } from 'bootstrap-vue'
import useVerticalLayout from '@core/layouts/layout-vertical/useVerticalLayout'
import { onUnmounted } from '@vue/composition-api'
import moment from 'moment'
import axiosIns from '@/libs/axios'

export default {
  components: {
    BCardTitle,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { isMobile, resizeHandler } = useVerticalLayout()

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    return {
      perfectScrollbarSettings,
      isMobile,
    }
  },
  methods: {
    isCalculationDisabled(plan) {
      const days = plan?.qualifiedStatusEndDaysAmount || 15
      const planLastDate = plan?.lastExecutedAt?.date || null

      const today = new Date().getDate() // if 1 allow

      if (today <= days) {
        if (planLastDate) {
          const now = moment(new Date(), 'YYYY-MM-DD hh:mm:ss')
          const executed = moment(planLastDate, 'YYYY-MM-DD hh:mm:ss')

          if (executed.month() === now.month() && executed.isSameOrBefore(now)) {
            return true
          }
        }

        return false
      }

      return true
    },
    async calculate(plan = {}) {
      try {
        const { id } = plan
        if (!id) return

        await axiosIns.post(`1/loyaltyPlans/${id}/calculate`)
        if (this.plan?.lastExecutedAt) {
          this.plan.lastExecutedAt = moment().format('YYYY-MM-DD hh:mm:ss')
        }

        this.showToast('success', this.$i18n.t('success.contactUpdated'))
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
  },
}
</script>

<style lang="sass">
.cardOverlay
  overflow: hidden

.overlayEdit
  position: absolute

  opacity: 0

  transition: opacity .1s ease-in-out

.card:hover .overlayEdit
  opacity: .9
</style>
