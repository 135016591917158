var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"p-1",attrs:{"no-body":""}},[_c('b-form-group',{attrs:{"label":_vm.$t('assignOrder')}},[_c('v-select',{attrs:{"id":"offer-thread-1","options":_vm.threads,"label":"name","filterable":false,"selectable":function (thread) { return thread.offersTotal < _vm.system.contactThreadOffersLimit; }},on:{"search":_vm.getThreads},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
return [(search.length)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('NoData'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('TypeToSearch'))+" ")])]}},{key:"option",fn:function(ref){
var name = ref.name;
var contact = ref.contact;
var offersTotal = ref.offersTotal;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('avatar',{staticClass:"mr-25",attrs:{"user":contact}}),_vm._v(" - "+_vm._s(name)+" "),(offersTotal >= _vm.system.contactThreadOffersLimit)?_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"ml-50",attrs:{"icon":"AlertCircleIcon","title":_vm.$tc('ThreadOfferCountIsToBig', _vm.system.contactThreadOffersLimit, { limit: _vm.system.contactThreadOffersLimit })}}):_vm._e()],1)]}},{key:"selected-option",fn:function(ref){
var name = ref.name;
var contact = ref.contact;
return [_c('div',{staticClass:"d-flex"},[_c('avatar',{staticClass:"mr-25",attrs:{"user":contact}}),_vm._v(" - "+_vm._s(name)+" ")],1)]}}]),model:{value:(_vm.thread),callback:function ($$v) {_vm.thread=$$v},expression:"thread"}})],1)],1),_c('b-row',{staticClass:"d-flex"},[_c('b-col',{staticClass:"position-relative",attrs:{"sm":"12","md":"12","lg":"8"}},[_c('new-contract-modal',{key:_vm.contactKey,ref:"contract-modal",staticClass:"position-relative",attrs:{"total-gross":_vm.totalGross,"payment-schedule":_vm.cartProducts[0].shopProduct.shopCategories[1].paymentSchedule || null,"thread":_vm.cart.contactThread,"cart":_vm.cart.id,"hide-footer":"","tag":"b-card","show-top":false}})],1),_c('b-col',{staticClass:"pl-lg-25 pl-md-1",attrs:{"sm":"12","md":"12","lg":"4"}},[_c('b-card',[_c('b-button',{staticClass:"mb-50 mr-50 w-100",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$emit('nextStep')}}},[_vm._v(" "+_vm._s(_vm.$t('Next'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }