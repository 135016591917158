<template>
  <b-card-text>
    <b-form>
      <validation-observer tag="form">
        <!--     Name     -->
        <b-row>
          <b-col
            sm="12"
            md="12"
            lg="12"
          >
            <validation-provider
              v-slot="{ errors }"
              :name="$t('Name')"
              rules="required"
            >
              <b-form-group :label="$t('Name')">
                <b-form-input
                  v-model.trim="newSubcategory.name"
                  :placeholder="$t('Name')"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <!--     Category     -->
        <b-row>
          <b-col
            sm="12"
            md="12"
            lg="12"
          >
            <validation-provider
              v-slot="{ errors }"
              :name="$t('offer.product.category')"
              rules="required"
            >
              <sw-select :name="$t('offer.product.category')">
                <v-select
                  v-model.trim="newSubcategory.category"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :placeholder="$t('offer.product.category')"
                  :options="categories"
                  label="name"
                  @search="loadCategories"
                  @option:selected="selectCategory"
                >
                  <template #option="{ label }">
                    {{ label }}
                  </template>
                  <template #selected-option="{ label }">
                    {{ label }}
                  </template>
                  <template #no-options>
                    {{ $t('NoOptions') }}
                  </template>
                </v-select>
              </sw-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>
      </validation-observer>
    </b-form>

    <div class="d-flex justify-content-end pt-1 mt-1 border-top">
      <b-button
        size="sm"
        variant="primary"
        @click="pushToServer"
      >
        {{ $t('Save') }}
      </b-button>
    </div>
  </b-card-text>
</template>

<script>
import { BCardText, BForm } from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  components: {
    BForm,
    BCardText,
    ValidationObserver,
    ValidationProvider,
    vSelect,
  },
  props: {
    oldSubcategory: {
      type: Object,
      required: false,
    },
  },
  data: () => ({
    required,

    newSubcategory: {
      id: '',
      name: '',
      category: '',
    },

    categories: [],
  }),
  created() {
    if (this.oldSubcategory) {
      this.newSubcategory.id = this.oldSubcategory.id || ''
      this.newSubcategory.name = this.oldSubcategory.name || ''
      this.newSubcategory.category = this.oldSubcategory.category || ''
    }
  },
  methods: {
    pushToServer() {},

    loadCategories() {},
    selectCategory() {},
  },
}
</script>
