<template>
  <div>
    <div
      class="d-flex flex-column"
      style="gap: .5rem"
    >
      <h3
        class="text-primary"
        :style="!form.name && 'opacity: .5'"
      >
        {{ form.name || $t('calculators.New') }}
      </h3>

      <!--    START:Toolbar Top    -->
      <div
        class="d-flex mb-1"
        style="column-gap: .5rem"
      >
        <b-button
          variant="warning"
          size="sm"
          @click="$router.push('/calculator')"
        >
          <feather-icon icon="ArrowLeftIcon" />

          {{ $t('Return') }}
        </b-button>

        <b-button
          :disabled="!form.name || !isSectionsValid || loading"
          variant="success"
          size="sm"
        >
          {{ $t('Save') }}
        </b-button>
      </div>
      <!--    END:Toolbar Top    -->

      <!--    START:Calculator Name    -->
      <b-form-group :label="$t('Name')">
        <validation-provider
          v-slot="{ errors }"
          :name="$t('Name')"
          rules="required"
        >
          <b-form-input
            v-model="form.name"
            :label="$t('Name')"
            :placeholder="$t('calculators.New')"
          />

          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <!--    END:Calculator Name    -->

      <h4 class="mb-0">
        {{ $t('Sections') }}
      </h4>

      <!--    START:Sections    -->
      <draggable
        v-if="form.sections.length"
        :list="form.sections"
        tag="app-collapse"
        group="sections"
        class="list-group list-group-flat collapse-draggable mt-75"
      >
        <app-collapse-item
          v-for="(section, i) in form.sections"
          :key="`section-${section.id}`"
          :title="section.name || `${$t('Section')} ${i + 1}`"
        >
          <!--    START:Header    -->
          <template #header>
            <b-form-group
              class="flex-grow-1 mb-0"
            >
              <validation-provider
                :name="$t('Name')"
                rules="required"
              >
                <b-input-group>
                  <b-form-input
                    v-model="section.name"
                    :label="$t('Name')"
                    :placeholder="`${$t('Section')} ${i + 1}`"
                  />

                  <b-input-group-append>
                    <b-button
                      variant="danger"
                      size="sm"
                      class="btn-icon px-1"
                      @click.prevent.stop="
                        section.options.length
                          ? showAlert('warning', $i18n.t('alerts.AreYouSure'), $i18n.t('alerts.WontbeAble'))
                            .then(() => form.sections.splice(i, 1))
                            .catch(() => {})
                          : form.sections.splice(i, 1)
                      "
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </validation-provider>
            </b-form-group>
          </template>
          <!--    END:Header    -->

          <!--    START:Fields    -->
          <draggable
            v-if="section.options.length"
            :list="section.options"
            tag="ul"
            :group="`section-${section.id}-fields`"
            class="list-group list-group-flat mt-n25"
          >
            <b-list-group-item
              v-for="(option, j) in section.options"
              :key="`section-${section.id}-field-${option.id}`"
              tag="li"
              class="cursor-move"
            >
              <component
                :is="option.type"
                @isValid="section.isValid = $event; sectionsValidation()"
                @rm="section.options.splice(j, 1)"
              />
            </b-list-group-item>
          </draggable>
          <!--    END:Fields    -->

          <!--    START:No Section Options    -->
          <empty-content v-else />
          <!--    END:No Section Options    -->

          <!--    START:Add Field    -->
          <div class="container mt-1">
            <sw-select>
              <v-select
                v-model="section.sectionType"
                :options="sectionTypes"
                label="name"
                :get-option-label="type => $t(`calculators.sectionTypes.${type}`)"
                :placeholder="$t('SelectFieldType')"
              >
                <template #no-options="{ search }">
                  <span v-if="search.length">
                    {{ $t('NoData') }}
                  </span>

                  <span v-else>
                    {{ $t('TypeToSearch') }}
                  </span>
                </template>
              </v-select>

              <template #append>
                <b-button
                  variant="success"
                  class="btn-icon"
                  :disabled="!section.sectionType"
                  @click="section.options.push({ id: section.options.length, type: section.sectionType }); section.sectionType = ''"
                >
                  {{ $t('AddField') }}

                  <feather-icon icon="PlusIcon" />
                </b-button>
              </template>
            </sw-select>
          </div>
          <!--    END:Add Field    -->
        </app-collapse-item>
      </draggable>
      <!--    END:Sections    -->

      <!--    START:No Sections    -->
      <empty-content v-else />
      <!--    END:No Sections    -->

      <!--    START:Add New Section    -->
      <div
        class="w-100 px-3 py-2 border-primary text-primary rounded-lg d-flex align-items-center justify-content-center cursor-pointer mt-50"
        style="gap: .34rem"
        @click="form.sections.push(JSON.parse(JSON.stringify({
          id: form.sections.length,
          ...emptySection
        })))"
      >
        <feather-icon icon="PlusIcon" />

        {{ $t('AddNewSection') }}
      </div>
      <!--    END:Add New Section    -->

      <!--    START:Summary    -->
      <sum class="mt-2" />
      <!--    END:Summary    -->

      <!--    START:Toolbar Bottom    -->
      <div
        class="d-flex pb-2"
        style="column-gap: .5rem"
      >
        <b-button
          variant="warning"
          @click="$router.push('/calculator')"
        >
          <feather-icon icon="ArrowLeftIcon" />

          {{ $t('Return') }}
        </b-button>

        <b-button
          :disabled="!form.name || !isSectionsValid || loading"
          variant="success"
        >
          {{ $t('Save') }}
        </b-button>
      </div>
      <!--    END:Toolbar Bottom    -->
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { BInputGroupAppend } from 'bootstrap-vue'
import draggable from 'vuedraggable'
import NoContent from '@/pages/calculator/NoContent.vue'

import SingleSelectionList from './sections/SingleSelectionList.vue'
import UnitOfMeasurementOrWeight from './sections/UnitOfMeasurementOrWeight.vue'
import ValueInCurrency from './sections/ValueInCurrency.vue'
import Range from './sections/Range.vue'
import Info from './sections/Info.vue'
import Products from './sections/Products.vue'
import RangeWithQuantity from './sections/RangeWithQuantity.vue'
import ValueDiscount from './sections/ValueDiscount.vue'
import PercentageDiscount from './sections/PercentageDiscount.vue'
import UserDiscount from './sections/UserDiscount.vue'

import Sum from './Sum.vue'

export default {
  components: {
    NoContent,
    BInputGroupAppend,
    ValidationObserver,
    ValidationProvider,
    draggable,
    vSelect,

    SingleSelectionList,
    UnitOfMeasurementOrWeight,
    ValueInCurrency,
    Range,
    Info,
    Products,
    RangeWithQuantity,
    ValueDiscount,
    PercentageDiscount,
    UserDiscount,

    Sum,
  },

  data: () => ({
    loading: false,

    form: {
      name: '',
      sections: [],
    },

    emptySection: {
      name: '',
      options: [],
    },

    sectionType: '',
    sectionTypes: [
      'SingleSelectionList',
      'UnitOfMeasurementOrWeight',
      'ValueInCurrency',
      'Range',
      'Info',
      'Products',
      'RangeWithQuantity',
      'ValueDiscount',
      'PercentageDiscount',
      'UserDiscount',
    ],

    isSectionsValid: false,
  }),

  methods: {
    sectionsValidation() {
      this.isSectionsValid = this.form.sections.every(section => section.isValid)
    },
  },
}
</script>
