<template>
  <b-row>
    <b-col
      md="6"
      sm="12"
    >
      <div>
        <quill-editor
          ref="editor"
          v-model="content"
          style="min-height: 150px"
          :options="editorOption"
        >
          <div
            id="toolbar"
            slot="toolbar"
            class="pl-2"
          >
            <!-- Add a bold button -->
            <div>
              <button class="ql-bold">
                Bold
              </button>
              <button class="ql-italic">
                Italic
              </button>
              <button class="ql-link">
                Link
              </button>
            </div>

            <!-- Add font size dropdown -->
            <!--            <select class="ql-size">-->
            <!--              <option value="small" />-->
            <!--              &lt;!&ndash; Note a missing, thus falsy value, is used to reset to default &ndash;&gt;-->
            <!--              <option selected />-->
            <!--              <option value="large" />-->
            <!--              <option value="huge" />-->
            <!--            </select>-->

            <!--            <select class="ql-font">-->
            <!--              <option selected="selected" />-->
            <!--              <option value="serif" />-->
            <!--              <option value="monospace" />-->
            <!--            </select>-->

            <!-- You can also add your own -->
            <b-button
              id="custom-button"
              variant="success"
              class="w-auto"
              @click="$emit('open-modal')"
            >
              <span class="text-white">
                <feather-icon
                  icon="CodeIcon"
                  class="mr-25"
                />
              </span>

              <span class="text-white">
                {{ $t('ShortCodes') }}
              </span>
            </b-button>
          </div>
        </quill-editor>
        <b-button
          size="sm"
          class="mt-1"
          variant="success"
          :disabled="!content"
          @click="save"
        >
          {{ $t('Save') }}
        </b-button>
      </div>
    </b-col>
    <b-col
      md="6"
      sm="12"
    >
      <h4 class="pt-1 pt-md-0">
        {{ $t('Preview') }}
      </h4>
      <div :inner-html.prop="content | shortcodePreview(shortcodes)" />
    </b-col>

    <transition name="fade">
      <div
        v-if="scrollPos.y.value > 200"
        class="btn position-fixed mr-25"
        style="z-index: 999; bottom: 4.4rem; right: 0; transition-delay: .14s;"
      >
        <b-button
          variant="success"
          class="btn-icon rounded-circle"
          @click="$emit('open-modal')"
        >
          <feather-icon
            icon="CodeIcon"
            size="16"
          />
        </b-button>
      </div>
    </transition>
  </b-row>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'
import { mapGetters } from 'vuex'
import { GET_FOOTER, SAVE_FOOTER } from '@/@constants/mutations'
import { useWindowScroll } from '@vueuse/core'

export default {
  components: {
    quillEditor,
  },
  data: () => ({
    scrollPos: 0,
    selectPosition: 0,
    editorOption: {
      modules: {
        toolbar: '#toolbar',
      },
    },
    defaultContent: '<p>Pozdrawiam {%loggedUser.lastName%} {%loggedUser.firstName%},</p><p>Telefon: {%loggedUser.phone%}, Email: {%email%}</p>',
    content: '',
  }),
  computed: {
    ...mapGetters({
      shortcodes: 'templates/getShortcodes',
    }),
  },
  mounted() {
    this.$store.dispatch(`templates/${GET_FOOTER}`)
      .then(res => {
        if (res.data.item.emailFooter) this.content = res.data.item.emailFooter
        else this.content = this.defaultContent
      })
      .catch(err => {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      })

    this.scrollPos = useWindowScroll()
  },
  methods: {
    onShow() {
      const range = this.$refs.editor.quill.getSelection()
      if (range && range.index) this.selectPosition = range.index
    },
    onHidden() {
      this.selectPosition = 0
    },
    // eslint-disable-next-line no-unused-vars
    onAddShortcode(shortcode) {
      this.$refs.editor.quill.clipboard.dangerouslyPasteHTML(this.selectPosition, shortcode)
    },
    save() {
      this.$store.dispatch(`templates/${SAVE_FOOTER}`, this.content)
        .then(() => {
          this.showToast('success', this.$i18n.t('templates.TemplateUpdated'))
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
  },
}
</script>

<style lang="scss">
  .ql-container {
    min-height: 200px;
  }
</style>
