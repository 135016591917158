<template>
  <div>
    <div
      v-if="!loading"
      class="pt-1"
    >
      <div>
        <b-form
          ref="form"
          class="repeater-form"
        >

          <!-- Row Loop -->
          <draggable v-model="threadTags">
            <b-row
              v-for="(status, index) in threadTags"
              :id="`status_${index}`"
              :key="`status_${index}`"
              ref="row"
              class="cursor-pointer"
            >

              <!-- Item Name -->
              <b-col md="4">
                <div class="d-flex align-items-center">
                  <feather-icon
                    icon="MoveIcon"
                    class="text-primary mr-75"
                    size="18"
                  />
                  <b-form-group
                    :label="$t('Name')"
                    label-for="status-name"
                    class="w-100 flex-grow-1"
                  >
                    <b-form-input
                      id="status-name"
                      v-model="status.name"
                      type="text"
                      size="sm"
                    />
                  </b-form-group>
                </div>
              </b-col>

              <!-- Profession -->
              <b-col
                lg="2"
                md="1"
              >
                <b-form-group
                  :label="$t('Color')"
                  label-for="color"
                >
                  <b-form-input
                    id="color"
                    v-model="status.color"
                    type="color"
                    size="sm"
                  />
                </b-form-group>
              </b-col>
              <!-- Profession -->
              <b-col
                lg="2"
                md="1"
              >
                <b-form-group>
                  <template #label>
                    {{ $t('CanbanView') }} <sw-icon v-b-tooltip
                                                    icon="HelpCircleIcon"
                                                    :title="$t('ShowOnCanbanView')"
                    />
                  </template>
                  <b-form-checkbox
                    v-model="status.showInContactThreadFunnel"
                    vairnat="primary"
                  />
                </b-form-group>
              </b-col>

              <!-- Remove Button -->
              <b-col
                lg="2"
                md="3"
                class="mb-50"
              >
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="mt-0 mt-md-2"
                  size="sm"
                  @click="removeItem(status.id, index)"
                >
                  <feather-icon
                    icon="XIcon"
                    class="mr-25"
                  />
                  <span>{{ deleteThreadTags.includes(status.id ? status.id.toString() : status.id) ? $t('Cancel') : $t('Delete') }}</span>
                </b-button>
              </b-col>
              <b-col cols="12">
                <hr>
              </b-col>
            </b-row>
          </draggable>

        </b-form>
      </div>
      <b-button
        variant="success"
        class="mr-1"
        size="sm"
        @click="onSaveForm"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-25"
        />
        <span>{{ $t('Save') }}</span>
      </b-button>
      <b-button
        variant="primary"
        size="sm"
        @click="addField"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-25"
        />
        <span>{{ $t('Add') }}</span>
      </b-button>
    </div>
    <div
      v-else
      class="d-flex justify-content-center"
    >
      <b-spinner
        variant="primary"
        class="m-5"
      />
    </div>
  </div>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BRow, BCol, BButton, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'
import { DELETE_THREAD_TAGS, GET_THREAD_TAGS, SAVE_THREAD_TAGS } from '@/@constants/mutations'
import draggable from 'vuedraggable'

export default {
  name: 'ThreadTags',
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    draggable,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data: () => ({
    loading: false,
    threadTags: [],
    areChangesSaved: true,
  }),
  computed: {
    ...mapGetters({
      deleteThreadTags: 'typeSettings/getDeleteThreadTags',
    }),
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.loading = true
      this.$store.dispatch(`typeSettings/${GET_THREAD_TAGS}`)
        .then(res => {
          this.threadTags = res.data.items
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
        .finally(() => {
          this.loading = false
        })
    },
    addField() {
      const fieldData = {
        id: null, name: '', color: '#7367f0', showInContactThreadFunnel: true,
      }
      this.threadTags.push(fieldData)
      this.areChangesSaved = false
    },
    removeItem(id, index) {
      if (id) this.$store.commit('typeSettings/ADD_DELETE_THREAD_TAGS', id)
      else this.threadTags.splice(index, 1)
      this.areChangesSaved = false
    },
    onInputChange(index, field, event) {
      this.threadTags[index][field] = event.target.value
      this.areChangesSaved = false
    },
    onSaveForm() {
      this.loading = true
      this.areChangesSaved = true
      const statuses = []
      this.threadTags.forEach((status, index) => {
        const {
          color, id, name, showInContactThreadFunnel,
        } = status
        if (!this.deleteThreadTags.includes(id ? id.toString() : id)) {
          if (id && name) {
            statuses.push({
              // eslint-disable-next-line radix
              color, id: id.toString(), name, position: index + 1, showInContactThreadFunnel,
            })
            // eslint-disable-next-line radix
          } else if (name) {
            statuses.push({
              color, name, position: index + 1, showInContactThreadFunnel,
            })
          }
        }
      })

      if (this.deleteThreadTags.length) {
        this.$store.dispatch(`typeSettings/${DELETE_THREAD_TAGS}`, this.deleteThreadTags)
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          })
      }

      this.$store.dispatch(`typeSettings/${SAVE_THREAD_TAGS}`, statuses)
        .then(() => {
          this.init()
          this.showToast('success', this.$i18n.t('success.contactUpdated'))
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },

}
</script>

<style scoped>

</style>
