// eslint-disable-next-line import/no-cycle
import axiosIns from '@/libs/axios'
import * as fields from '@/@constants/fields'

export default {
  namespaced: true,
  state: {
    ranksList: [],
  },
  getters: {
    getRanksList: state => state.ranksList,
  },
  mutations: {
    GET_RANKS_LIST(state, payload) {
      state.ranksList = payload
    },
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    GET_RANKS_LIST: ({ commit }) => new Promise((resolve, reject) => {
      axiosIns.get('1/ranks', { params: { fields_load: fields.RANKS } })
        .then(res => {
          commit('GET_RANKS_LIST', res.data.data.items)
          resolve(res.data.data.items)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    GET_RANK: ({ commit }, rankId) => new Promise((resolve, reject) => {
      axiosIns.get(`1/ranks/${rankId}`, { params: { fields_load: fields.RANKS } })
        .then(res => {
          resolve(res.data.data.item)
        })
        .catch(err => {
          reject(err)
        })
    }),
    UPDATE_RANK: ({ dispatch }, rank) => new Promise((resolve, reject) => {
      if (rank.id) {
        axiosIns.patch('1/ranks', rank)
          .then(res => {
            dispatch('GET_RANKS_LIST')
            resolve(res.data)
          })
          .catch(err => {
            reject(err)
          })
      } else {
        axiosIns.post('1/ranks', rank)
          .then(res => {
            dispatch('GET_RANKS_LIST')
            resolve(res.data)
          })
          .catch(err => {
            reject(err)
          })
      }
    }),
    DELETE_RANK: ({ dispatch }, rankId) => new Promise((resolve, reject) => {
      axiosIns.delete('1/ranks', { data: [rankId] })
        .then(() => {
          dispatch('GET_RANKS_LIST')
          resolve()
        })
        .catch(err => {
          reject(err)
        })
    }),
  },
}
