// eslint-disable-next-line import/prefer-default-export
export const shortcutTypes = [
  {
    label: 'CreateContact',
    key: 'CONTACT_FORM',
    fields: [
      { key: 'FIRST_NAME', label: 'contact.firstName' },
      { key: 'LAST_NAME', label: 'contact.lastName' },
      { key: 'PHONE', label: 'contact.phone' },
      { key: 'EMAIL', label: 'contact.email' },
      { key: 'IDENTITY_DOCUMENT_TYPE', label: 'task.Type' },
      { key: 'IDENTITY_DOCUMENT_NUMBER', label: 'OrganizationDetails.addressNumber' },
      { key: 'IDENTITY_DOCUMENT_AUTHORITY_ISSUING', label: 'contact.authorityIssuing' },
      { key: 'IDENTITY_DOCUMENT_RELEASE_DATE', label: 'contact.releaseDate' },
      { key: 'IDENTITY_DOCUMENT_EXPIRATION_DATE', label: 'contact.expirationDate' },
    ],
  },
  {
    label: 'AdditionalFields',
    key: 'CONTACT_ADDITIONAL_FIELDS',
    fields: [],
    request: {
      params: { fields_load: 'CONTACT_ADDON_FIELD_TYPES' },
      url: '1/settings/contactAddonFieldsTypes',
      key: 'id',
      label: 'name',
    },
    requiredTypes: ['CONTACT_FORM'],
  },
  {
    label: 'CreateThread',
    key: 'THREAD_FORM',
    fields: [
      { key: 'NAME', label: 'Name' },
    ],
    requiredTypes: ['CONTACT_FORM'],
  },
  {
    label: 'CreateContracts',
    key: 'GENERATE_AGREEMENT',
    fields: [],
    requiredTypes: ['CONTACT_FORM', 'THREAD_FORM'],
  },
  {
    label: 'AddNote',
    key: 'GENERATE_NOTE',
    fields: [
      { key: 'TYPE', allowDefaultValue: true, label: 'task.Type' },
      { key: 'CONTENT', label: 'Content' },
      { key: 'SHARE_NOTE', label: 'ShareNote' },
    ],
    requiredTypes: ['CONTACT_FORM', 'THREAD_FORM'],
  },
  {
    label: 'AddPoll',
    key: 'GENERATE_POLL',
    request: {
      params: { fields_load: 'SETTINGS_POLLS' },
      url: '1/settings/polls',
      key: 'id',
      label: 'label',
      isTranslation: true,
      fieldsField: 'pollFields',
    },
    fields: [],
    requiredTypes: ['CONTACT_FORM', 'THREAD_FORM'],
  },
]
export const shortcutTypeByValue = value => shortcutTypes.find(type => type.key === value) || null
export const shortcutTypeFieldByValueAndType = (value, type) => shortcutTypeByValue(type).fields?.find(f => f.key === value)
