<template>
  <div>
    <b-alert
      variant="primary"
      show
      class="d-flex align-items-center"
    >
      <div class="alert-body">
        <feather-icon
          class="mr-25"
          icon="InfoIcon"
        />

        <span class="ml-25">
          {{ $t('ThisIsWhereYouWillCreateMarketingPlan') }}
        </span>
      </div>
    </b-alert>

    <b-alert
      variant="primary"
      show
      class="d-flex align-items-center"
    >
      <div class="alert-body">
        <feather-icon
          class="mr-25"
          icon="InfoIcon"
        />

        <span class="ml-25">
          {{ $t('MarketingPlanChangeInfoBox') }}
        </span>
      </div>
    </b-alert>

    <b-button
      v-if="checkRequiredPermissions([$roles.MARKETING_PLAN_MODIFY])"
      variant="primary"
      class="my-50"
      size="sm"
      :to="{ name: 'newMarketingPlan' }"
    >
      <feather-icon icon="PlusIcon" />
      {{ $t('Add') }}
    </b-button>

    <list
      :is-loading="isLoading"
      :items="items"
    />
  </div>
</template>

<script>
import axiosIns from '@/libs/axios'
import list from './components/list/List.vue'

export default {
  components: {
    list,
  },
  data: () => ({
    isLoading: false,
    items: [],
  }),
  async mounted() {
    await this.init()
  },
  methods: {
    async init() {
      this.isLoading = true

      try {
        const resp = await axiosIns.get('1/marketingPlans', { params: { fields_load: this.$fields.MARKETING_PLANS } })

        this.items = resp?.data?.data?.items || []
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
