var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"form",staticClass:"d-flex flex-column",staticStyle:{"row-gap":"1.34rem"}},[_c('div',{staticClass:"d-flex flex-column flex-md-row align-md-items-center justify-md-content-between",staticStyle:{"column-gap":"1.5rem"}},[_c('b-row',{staticClass:"d-flex flex-grow-1",staticStyle:{"row-gap":".34rem"}},[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('EnterFieldName'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"label":_vm.$t('EnterFieldName'),"placeholder":_vm.$t('EnterFieldName')},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('b-input-group-append',[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary",modifiers:{"hover":true,"v-primary":true}}],staticClass:"btn-icon px-75",staticStyle:{"cursor":"default !important"},attrs:{"variant":"outline-primary","title":_vm.$t('calculators.sectionTypes.SingleSelectionList')}},[_c('sw-icon',{attrs:{"icon":"InfoIcon"}})],1)],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('AddHint')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"label":_vm.$t('AddHint'),"placeholder":_vm.$t('AddHint')},model:{value:(_vm.form.hint),callback:function ($$v) {_vm.$set(_vm.form, "hint", $$v)},expression:"form.hint"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"d-none d-md-flex align-items-md-start mt-md-50",staticStyle:{"row-gap":".5rem"}},[_c('b-form-checkbox',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-success",modifiers:{"hover":true,"v-success":true}}],staticClass:"custom-control-success",attrs:{"checked":"true","title":_vm.$t('FieldHiddenVisible'),"name":"show","switch":""},model:{value:(_vm.form.show),callback:function ($$v) {_vm.$set(_vm.form, "show", $$v)},expression:"form.show"}},[_c('span',{staticClass:"switch-icon-left"},[_c('feather-icon',{attrs:{"icon":"EyeIcon"}})],1),_c('span',{staticClass:"switch-icon-right"},[_c('feather-icon',{attrs:{"icon":"EyeOffIcon"}})],1)]),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-danger",modifiers:{"hover":true,"v-danger":true}}],staticClass:"btn-icon mt-md-n25",attrs:{"variant":"flat-danger","size":"sm","title":_vm.$t('Delete')},on:{"click":function($event){return _vm.$emit('rm')}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}})],1)],1)],1),_c('div',[_c('h5',[_vm._v(" "+_vm._s(_vm.$t('EnterAlgorithmForCalculatingDisplayedValue'))+" ")]),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('sw-select',{attrs:{"name":_vm.$t('SpecifyTypeOfFieldUsed'),"rules":"required","classes":"mb-0 w-100","clearable":false}},[_c('v-select',{attrs:{"options":_vm.types,"label":"name","placeholder":_vm.$t('SpecifyTypeOfFieldUsed')},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
return [(search.length)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('NoData'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('TypeToSearch'))+" ")])]}}]),model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('sw-select',{attrs:{"name":_vm.$t('SelectValue'),"rules":"required","classes":"mb-0 w-100"}},[_c('v-select',{attrs:{"options":_vm.values,"label":"name","placeholder":_vm.$t('SelectValue'),"clearable":false},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
return [(search.length)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('NoData'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('TypeToSearch'))+" ")])]}}]),model:{value:(_vm.form.value),callback:function ($$v) {_vm.$set(_vm.form, "value", $$v)},expression:"form.value"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('sw-select',{attrs:{"name":_vm.$t('SelectAction'),"rules":"required","classes":"mb-0 w-100"}},[_c('v-select',{attrs:{"label":"name","options":_vm.actions,"get-option-label":function (name) { return _vm.$t(("calculators.actions." + name)); },"placeholder":_vm.$t('SelectAction'),"clearable":false},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
return [(search.length)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('NoData'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('TypeToSearch'))+" ")])]}}]),model:{value:(_vm.form.action),callback:function ($$v) {_vm.$set(_vm.form, "action", $$v)},expression:"form.action"}})],1)],1)],1)],1),_c('b-form-group',{staticClass:"mb-0",attrs:{"label":_vm.$t('Options')},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('h5',{staticClass:"mb-25"},[_vm._v(" "+_vm._s(_vm.$t('Options'))+" ")])]},proxy:true}])},[_c('b-form-checkbox',{staticClass:"custom-control-primary",model:{value:(_vm.form.required),callback:function ($$v) {_vm.$set(_vm.form, "required", $$v)},expression:"form.required"}},[_vm._v(" "+_vm._s(_vm.$t('MandatoryChoice'))+" ")])],1),_c('div',{staticClass:"d-flex align-items-center justify-content-end mt-n1 d-md-none",staticStyle:{"row-gap":".5rem"}},[_c('b-form-checkbox',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-success",modifiers:{"hover":true,"v-success":true}}],staticClass:"custom-control-success",attrs:{"checked":"true","title":_vm.$t('FieldHiddenVisible'),"name":"show","switch":""},model:{value:(_vm.form.show),callback:function ($$v) {_vm.$set(_vm.form, "show", $$v)},expression:"form.show"}},[_c('span',{staticClass:"switch-icon-left"},[_c('feather-icon',{attrs:{"icon":"EyeIcon"}})],1),_c('span',{staticClass:"switch-icon-right"},[_c('feather-icon',{attrs:{"icon":"EyeOffIcon"}})],1)]),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-danger",modifiers:{"hover":true,"v-danger":true}}],staticClass:"btn-icon",attrs:{"variant":"flat-danger","size":"sm","title":_vm.$t('Delete')},on:{"click":function($event){return _vm.$emit('rm')}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }