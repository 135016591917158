<template>
  <div>
    <!--    <b-button @click="$emit('edit', item)">-->
    <!--      {{ $t('Edit') }}-->
    <!--    </b-button>-->
    <!--    {{ item }}-->
    <div class="d-flex justify-content-between align-items-start">
      <h6>
        {{ $t(automationActionTypeByValue(item.type)) }}
      </h6>
      <div
        v-if="showActions"
        class="d-flex"
      >
        <div
          class="text-warning cursor-pointer mr-1"
          @click="$emit('edit', { ...item, index })"
        >
          <feather-icon icon="EditIcon" />
        </div>

        <div
          class="text-danger cursor-pointer"
          @click="$emit('delete')"
        >
          <feather-icon icon="TrashIcon" />
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-between w-100">
      <div class="w-100">
        <!-- Type 1 -->
        <div class="w-100">
          <div class="d-flex justify-content-between w-100">
            <div class="w-100">
              <!-- Map contacts statuses -->
              <div v-if="item.type === CHANGE_CONTACT_STATUS">
                <b-badge
                  class="mr-25"
                  variant="light-primary"
                >
                  {{ item.contactStatus.name }}
                </b-badge>
              </div>
              <div
                v-else-if="[SEND_EMAIL, SEND_SMS].includes(item.type)"
              >
                <avatar-group
                  :items="item.users"
                />
                <div class="mt-50">
                  <b-form-checkbox
                    disabled
                    variant="primary"
                    :checked="item.sendToContact"
                  >
                    {{ $t('SendToContact') }}
                  </b-form-checkbox>
                </div>

                <div
                  v-if="item.subject"
                  class="font-weight-bolder mt-50"
                >
                  {{ item.subject }}
                </div>

                <div
                  v-b-tooltip
                  class="mt-50"
                  :inner-html.prop="item.content | htmlFormat | truncate(125)"
                  :title="getContent"
                />

                <documents-list
                  v-if="(item.files && item.files.length) || (item.imageFiles && item.imageFiles.length)"
                  :documents="[...(item.files || []), ...(item.imageFiles || [])]"
                  class="mt-50"
                />
              </div>
              <!-- Map thread statuses -->
              <div v-else-if="item.type === CHANGE_CONTACT_THREAD_FUNNEL_TYPE_POINT">
                <b-badge
                  class="mr-25"
                  variant="light-secondary"
                >
                  {{ item.contactThreadFunnelTypePoint.name }}
                </b-badge>
              </div>
              <div v-else-if="[ASSIGN_USER_CONTACT, ASSIGN_USER_CONTACT_THREAD].includes(item.type)">
                <avatar-group
                  :items="item.users"
                />
              </div>
              <!-- Create contact action -->
              <div
                v-else-if="[CREATE_CONTACT_WITH_CONTACT_THREAD].includes(item.type)"
              >
                <div class="d-flex flex-wrap mb-50">
                  <b-badge
                    v-b-tooltip
                    :title="$t('Status')"
                    class="mr-25 mb-25"
                    variant="light-secondary"
                  >
                    <span v-if="item.automationProcedureActionContact.status">
                      {{ item.automationProcedureActionContact.status.name }}
                    </span>
                    <feather-icon
                      v-else
                      icon="MinusIcon"
                    />
                  </b-badge>
                  |
                  <div
                    v-b-tooltip
                    :title="$t('ContactTag')"
                  >
                    <div
                      v-if="item.automationProcedureActionContact.contactTypes && item.automationProcedureActionContact.contactTypes.length"
                      class="d-flex flex-wrap"
                    >
                      <b-badge
                        v-for="(tag, valueIndex) in item.automationProcedureActionContact.contactTypes"
                        :key="`action_contact_tag_item_${index}_${valueIndex}`"
                        class="mx-25"
                        variant="light-secondary"
                      >
                        {{ tag.name }}
                      </b-badge>
                    </div>
                    <b-badge
                      v-else
                      class="mx-25"
                      variant="light-secondary"
                    >
                      <feather-icon icon="MinusIcon" />
                    </b-badge>
                  </div>
                  |
                  <div
                    v-b-tooltip
                    :title="$t('offer.Group')"
                  >
                    <div
                      v-if="item.automationProcedureActionContact.userGroups && item.automationProcedureActionContact.userGroups.length"
                      class="d-flex flex-wrap"
                    >
                      <b-badge
                        v-for="(group, valueIndex) in item.automationProcedureActionContact.userGroups"
                        :key="`action_contact_group_item_${index}_${valueIndex}`"
                        class="mx-25"
                        variant="light-secondary"
                      >
                        {{ group.name }}
                      </b-badge>
                    </div>
                    <b-badge
                      v-else
                      class="mx-25"
                      variant="light-secondary"
                    >
                      <feather-icon icon="MinusIcon" />
                    </b-badge>
                  </div>
                </div>
                <div
                  v-if="item.automationProcedureActionContact.note"
                  class="mb-25"
                >
                  {{ item.automationProcedureActionContact.note }}
                </div>
                <div class="divider text-primary">
                  <div class="divider-text">
                    {{ $t('Thread') }}
                  </div>
                </div>
                <avatar-group
                  v-if="item.automationProcedureActionContact && item.automationProcedureActionContact.assignedUsers"
                  class="mb-25"
                  :items="item.automationProcedureActionContact.assignedUsers"
                />

                <div
                  v-if="item.automationProcedureActionContactThread.name"
                  class="mb-25"
                >
                  {{ item.automationProcedureActionContactThread.name }}
                </div>
                <div class="d-flex flex-wrap mb-25">
                  <b-badge
                    v-b-tooltip
                    :title="$t('AutomationSection.ContactThreadFunnelTypePoint')"
                    class="mr-25"
                    variant="light-secondary"
                  >
                    <span v-if="item.automationProcedureActionContactThread && item.automationProcedureActionContactThread.contactThreadFunnelTypePoint">
                      {{ item.automationProcedureActionContactThread.contactThreadFunnelTypePoint.name }}
                    </span>
                    <feather-icon
                      v-else
                      icon="MinusIcon"
                    />
                  </b-badge>
                  |
                  <div
                    v-b-tooltip
                    :title="$t('app.elements.Checklist')"
                  >
                    <div
                      v-if="item.automationProcedureActionContactThread.contactThreadChecklistTypes && item.automationProcedureActionContactThread.contactThreadChecklistTypes.length"
                      class="d-flex flex-wrap"
                    >
                      <b-badge
                        v-for="(checklist, valueIndex) in item.automationProcedureActionContactThread.contactThreadChecklistTypes"
                        :key="`action_contact_checklist_item_${index}_${valueIndex}`"
                        class="mx-25"
                        variant="light-secondary"
                      >
                        {{ checklist.name }}
                      </b-badge>
                    </div>
                    <b-badge
                      v-else
                      class="mx-25"
                      variant="light-secondary"
                    >
                      <feather-icon icon="MinusIcon" />
                    </b-badge>
                  </div>
                </div>
                <div>
                  <div
                    v-if="!isNested"
                    class="d-flex flex-wrap justify-content-center w-100"
                  >
                    <b-button
                      block
                      pill
                      variant="outline-success"
                      size="sm"
                      class="d-flex justify-content-center align-items-center my-50 mb-75"
                      @click="$emit('add-nested-action')"
                    >
                      <feather-icon
                        icon="PlusIcon"
                        size="20"
                        class="mr-25"
                      />
                      {{ $t('AddNestedAction') }}
                    </b-button>
                  </div>

                  <!-- Nested actions -->
                  <app-timeline
                    v-if="item.automationProcedureActionContactThread.additionalAutomationProcedureActions && item.automationProcedureActionContactThread.additionalAutomationProcedureActions.length"
                    class="mt-75 ml-0"
                  >
                    <app-timeline-item
                      v-for="(threadAction, threadIndex) in item.automationProcedureActionContactThread.additionalAutomationProcedureActions"
                      :key="`contact_action_${index}_${threadIndex}`"
                      variant="primary"
                    >
                      <b-card
                        no-body
                        class="p-50"
                      >
                        <automation-details-action
                          is-nested
                          :show-actions="showActions"
                          :index="threadIndex"
                          :item="threadAction"
                          @delete="deleteAction(threadIndex, index, threadAction)"
                          @edit="$emit('edit', { ...threadAction, index: { index: threadIndex, parentIndex: index } })"
                        />
                      </b-card>
                    </app-timeline-item>
                  </app-timeline>
                </div>
              </div>
              <!-- Task -->
              <div v-else-if="[CREATE_CONTACT_THREAD_TASK].includes(item.type)">
                <b-badge
                  v-if="item.automationProcedureActionContactThreadTask.contactThreadTaskType"
                  class="mb-25"
                  variant="light-primary"
                >
                  {{ item.automationProcedureActionContactThreadTask.contactThreadTaskType.name }}
                </b-badge>
                <avatar
                  v-if="item.automationProcedureActionContactThreadTask.assignedUser"
                  class="mb-25"
                  :user="item.automationProcedureActionContactThreadTask.assignedUser"
                />
                <b-badge v-else-if="item.automationProcedureActionContactThreadTask.assignedLeadUser"
                         variant="primary"
                >
                  {{ $t('PrimaryUser') }}
                </b-badge>
                <div
                  v-if="item.automationProcedureActionContactThreadTask"
                  v-b-tooltip
                  class="mb-25"
                  :title="$t('TaskTerm')"
                >
                  <feather-icon
                    icon="ClockIcon"
                    class="text-primary"
                  />
                  {{ item.automationProcedureActionContactThreadTask.daysAfter || 0 }}
                  {{ $tc('Days', item.automationProcedureActionContactThreadTask.daysAfter || 0) }}
                  {{ $t('At') }}
                  {{ getTimeValue(item.automationProcedureActionContactThreadTask) || '-' }}
                </div>
                <div
                  v-if="item.content"
                  v-b-tooltip
                  class="mb-25"
                  :title="$t('Description')"
                >
                  <feather-icon
                    icon="MessageCircleIcon"
                    class="text-primary"
                  />
                  {{ item.content | truncate(100) }}
                </div>
                <div
                  v-if="item.automationProcedureActionContactThreadTask.address"
                  v-b-tooltip
                  class="mb-25"
                  :title="$t('Address')"
                >
                  <feather-icon
                    icon="MapPinIcon"
                    class="text-primary"
                  />
                  {{ item.automationProcedureActionContactThreadTask.address }}
                </div>
              </div>
              <!-- Checklist -->
              <div
                v-else-if="[CREATE_CONTACT_THREAD_CHECKLIST].includes(item.type)"
                class="d-flex flex-wrap"
              >
                <div
                  v-if="item.automationProcedureActionContactThreadChecklistTypes"
                  class="d-flex flex-wrap"
                >
                  <b-badge
                    v-for="(checklist, valueIndex) in item.automationProcedureActionContactThreadChecklistTypes"
                    :key="`action_checklist_item_${index}_${valueIndex}`"
                    class="mr-25"
                    variant="light-primary"
                  >
                    {{ checklist.name }}
                  </b-badge>
                </div>
              </div>
              <!-- Note -->
              <div
                v-else-if="[CREATE_CONTACT_THREAD_NOTE].includes(item.type)"
                class="d-flex flex-wrap"
              >
                <b-badge
                  v-if="item.automationProcedureActionContactThreadNote.contactThreadNoteType"
                  class="mb-25"
                  variant="light-primary"
                >
                  {{ item.automationProcedureActionContactThreadNote.contactThreadNoteType.name }}
                </b-badge>
                <div
                  v-if="item.content"
                  v-b-tooltip
                  class="mb-25"
                  :title="$t('Description')"
                >
                  <feather-icon
                    icon="MessageCircleIcon"
                    class="text-primary"
                  />
                  {{ item.content || item.content | truncate(100) }}
                </div>
                <div
                  v-if="item.automationProcedureActionContactThreadNote"
                  class="d-flex flex-wrap"
                >
                  <b-badge
                    v-for="(rank, valueIndex) in item.automationProcedureActionContactThreadNote.sharedRanks"
                    :key="`action_note_item_${index}_${valueIndex}`"
                    class="mr-25"
                    variant="light-primary"
                  >
                    {{ rank.name }}
                  </b-badge>
                </div>
              </div>
              <!-- Map other values -->
              <div v-else>
                <b-badge
                  v-for="(value, valueIndex) in item.values"
                  :key="`action_item_${index}_${valueIndex}`"
                  class="mr-25"
                  variant="light-primary"
                >
                  <!--                    Constants values need to check for label-->
                  {{ value }}
                </b-badge>
              </div>
            </div>
          </div>
        </div>
        <!-- Type 2 -->
      </div>
    </div>
  </div>
</template>

<script>
import {
  ASSIGN_USER_CONTACT,
  ASSIGN_USER_CONTACT_THREAD,
  automationActionTypeByValue,
  CHANGE_CONTACT_STATUS,
  CHANGE_CONTACT_THREAD_FUNNEL_TYPE_POINT,
  CREATE_CONTACT_THREAD_NOTE,
  CREATE_CONTACT_THREAD_CHECKLIST,
  CREATE_CONTACT_THREAD_TASK,
  CREATE_CONTACT_WITH_CONTACT_THREAD,
  SEND_EMAIL,
  SEND_SMS,
} from '@/@types/automationsTypes'
import { VBTooltip } from 'bootstrap-vue'
import { formatDate } from '@core/utils/filter'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import axiosIns from '@/libs/axios'
import DocumentsList from '@/views/components/DocumentsList.vue'

export default {
  name: 'AutomationDetailsAction',
  components: { DocumentsList, AppTimeline, AppTimelineItem },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    item: {
      default: null,
      required: true,
    },
    index: {
      default: null,
      required: true,
    },
    showActions: {
      default: true,
      required: false,
      type: Boolean,
    },
    isNested: {
      default: false,
      required: false,
      type: Boolean,
    },
  },
  data: () => ({
    CREATE_CONTACT_WITH_CONTACT_THREAD,
    SEND_SMS,
    SEND_EMAIL,
    CREATE_CONTACT_THREAD_TASK,
    CHANGE_CONTACT_STATUS,
    CHANGE_CONTACT_THREAD_FUNNEL_TYPE_POINT,
    ASSIGN_USER_CONTACT,
    ASSIGN_USER_CONTACT_THREAD,
    CREATE_CONTACT_THREAD_NOTE,
    CREATE_CONTACT_THREAD_CHECKLIST,
  }),
  computed: {
    getContent() {
      const d = document.createElement('div')
      d.innerHTML = `<div>${this.item.content}</div>`

      return d.innerText
    },
  },
  methods: {
    getTimeValue(task) {
      if (task.time?.date) {
        const t = task.time.date.split(' ')[1].split(':')[0]
        const d = task.time.date.split(' ')[1].split(':')[1]
        return `${t}:${d}`
      }

      return task.time
    },
    async deleteAction(index, parentIndex, { id = null }) {
      if (id) {
        // request
        this.showAlert('error', this.$i18n.t('alerts.AreYouSure'))
          .then(async () => {
            try {
              await axiosIns.delete('1/automations/procedures/actions', { data: JSON.stringify([id]) })
              this.$delete(this.item.automationProcedureActionContactThread.additionalAutomationProcedureActions, index)
            } catch (err) {
              this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            }
          })
      } else {
        this.$delete(this.item.automationProcedureActionContactThread.additionalAutomationProcedureActions, index)
      }
    },
    formatDate,
    automationActionTypeByValue,
    getStartAtDate(startAt) {
      return typeof startAt === 'string' ? startAt : startAt?.date
    },
    getEndAtDate(endAt, startAt) {
      if (!endAt) return this.getStartAtDate(startAt)

      return typeof endAt === 'string' ? endAt : endAt?.date
    },
  },
}
</script>

<style scoped>

</style>
