import { PROJECTS_STATUSES } from '@/@constants/fields'
// eslint-disable-next-line import/no-cycle
import axiosIns from '@/libs/axios'
import {
  CREATE_PROJECT_STATUS,
  DELETE_PROJECT_STATUS,
  GET_PROJECT_STATUS,
  GET_PROJECT_STATUSES,
  TEST_PROJECT_STATUS,
  UPDATE_PROJECT_STATUS,
} from '@/@constants/mutations'
import moment from 'moment'

const getDate = date => date.dateRange()

export default {
  namespaced: true,
  actions: {
    [GET_PROJECT_STATUSES]: (_, filters) => new Promise((resolve, reject) => {
      const params = {}
      params.fields_load = PROJECTS_STATUSES

      if (filters?.createdAt) {
        if (filters?.createdAt?.project) {
          const value = getDate(filters.createdAt.project)

          // eslint-disable-next-line prefer-destructuring
          if (value[0]) params.gte_createdAt = value[0]
          // eslint-disable-next-line prefer-destructuring
          if (value[1]) params.lte_createdAt = value[1]
        }
      }

      if (filters?.completionAt) {
        if (filters?.completionAt?.project) {
          const value = getDate(filters.completionAt.project)

          // eslint-disable-next-line prefer-destructuring
          if (value[0]) params['gte_projects-endAt'] = value[0]
          // eslint-disable-next-line prefer-destructuring
          if (value[1]) params['lte_projects-endAt'] = value[1]
        }
      }

      if (filters?.tags.length) {
        const tags = filters.tags.map(tag => (typeof tag === 'string' ? tag : tag.id))

        params['eqArr_projects-projectTasks-projectTaskTag-id'] = JSON.stringify(tags)
      }

      if (filters?.onDeadline) {
        params['gte_projects-endAt'] = moment(new Date()).format('YYYY-MM-DD HH:mm')
        params['lte_projects-endAt'] = moment(new Date()).add(1, 'days').format('YYYY-MM-DD HH:mm')
      }

      if (filters?.contactThreads.length) params['eqArr_projects-contactThreads-id'] = JSON.stringify(filters.contactThreads.map(contact => contact.id))

      if (filters?.assignedUsers.length) params['eqArr_projects-assignedUsers-id'] = JSON.stringify(filters.assignedUsers.map(contact => contact.id))

      if (filters?.expired) params['lt_projects-endAt'] = moment(new Date()).format('YYYY-MM-DD HH:mm')

      axiosIns.get('1/settings/projectStatuses', { params })
        .then(res => {
          let list = res.data.data.items

          list = list.sort((a, b) => {
            if (a.position === null) { return 1 }

            if (b.position === null) { return -1 }

            return a.position - b.position
          })

          list.forEach(status => {
            if (filters?.tags.length) {
              const tags = filters.tags.map(tag => (typeof tag === 'string' ? tag : tag.id))

              // eslint-disable-next-line
              status.projects = status.projects.filter(p => (p?.projectTasksTags ? p.projectTasksTags.filter(t => tags.includes(t.id)).length : false))
            }

            if (filters?.onDeadline) {
              // eslint-disable-next-line
              status.projects = status.projects.filter(p => (p?.endAt?.date ? moment(p.endAt.date).isBetween(moment(moment(new Date()).format('YYYY-MM-DD HH:mm')), moment(moment(new Date()).add(1, 'days').format('YYYY-MM-DD HH:mm'))) : false))
            }

            if (filters?.contactThreads.length) {
              const threadList = filters.contactThreads.map(contact => contact.id)

              // eslint-disable-next-line
              status.projects = status.projects.filter(p => (p?.contactThreads ? p.contactThreads.filter(t => threadList.includes(t.id)).length : false))
            }

            if (filters?.assignedUsers.length) {
              const userList = filters.assignedUsers.map(contact => contact.id)

              // eslint-disable-next-line
              status.projects = status.projects.filter(p => (p?.assignedUsers ? p.assignedUsers.filter(t => userList.includes(t.id)).length : false))
            }

            if (filters?.createdAt) {
              if (filters?.createdAt?.project) {
                const value = getDate(filters.createdAt.project)

                // eslint-disable-next-line prefer-destructuring
                if (value[0]) params.gte_createdAt = value[0]
                // eslint-disable-next-line prefer-destructuring
                if (value[1]) params.lte_createdAt = value[1]

                // eslint-disable-next-line
                status.projects = status.projects.filter(p => moment(p.createdAt.date).isBetween(moment(value[0]), moment(value[1])))
              }
            }

            if (filters?.completionAt) {
              if (filters?.completionAt?.project) {
                const value = getDate(filters.completionAt.project)

                // eslint-disable-next-line
                status.projects = status.projects.filter(p => (p?.endAt?.date ? moment(p.endAt.date).isBetween(moment(value[0]), moment(value[1])) : false))
              }
            }

            if (filters?.expired) {
              // eslint-disable-next-line
              status.projects = status.projects.filter(p => (p?.endAt?.date ? moment(p.endAt.date).isBefore(moment(new Date())) : false))
            }

            // eslint-disable-next-line
            status.projects = status.projects.sort((a, b) => {
              if (a.position === null) { return 1 }

              if (b.position === null) { return -1 }

              return a.position - b.position
            })
          })

          resolve(list)
        })
        .catch(err => reject(err))
    }),

    [GET_PROJECT_STATUS]: (_, status) => new Promise((resolve, reject) => {
      const params = {}
      params.fields_load = PROJECTS_STATUSES

      axiosIns.get(`1/settings/projectStatuses/${status}`, { params })
        .then(res => resolve(res.data.data.item))
        .catch(err => reject(err))
    }),

    [CREATE_PROJECT_STATUS]: (_, payload) => new Promise((resolve, reject) => {
      axiosIns.post('1/settings/projectStatuses', payload)
        .then(res => resolve(res.data.data.items[0].id))
        .catch(err => reject(err))
    }),

    [UPDATE_PROJECT_STATUS]: (_, payload) => new Promise((resolve, reject) => {
      axiosIns.patch('1/settings/projectStatuses', payload)
        .then(res => resolve(res.data.data.items[0].id))
        .catch(err => reject(err))
    }),

    [DELETE_PROJECT_STATUS]: (_, id) => new Promise((resolve, reject) => {
      axiosIns.delete('1/settings/projectStatuses', { data: [id] })
        .then(() => { resolve() })
        .catch(err => { reject(err) })
    }),

    [TEST_PROJECT_STATUS]: (_, level = 0) => new Promise((resolve, reject) => {
      const params = {}
      params.fields_info = level

      axiosIns.get('1/settings/projectStatuses', { params })
        .then(res => resolve(res.data.data))
        .catch(err => reject(err))
    }),
  },
}
