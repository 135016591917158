<template>
  <div v-if="shown">
    <b-card
      v-if="!showFloatButton"
      no-body
      class="position-fixed mb-0 w-100"
      style="bottom: 0; left: 0; z-index: 9999"
    >
      <b-card-body class="d-flex align-items-center justify-content-center bg-light-primary py-75 py-md-50">
        <div
          class="d-flex flex-row flex-wrap align-items-center justify-content-center"
          style="column-gap: 1rem; row-gap: .34rem"
        >
          <strong>
            {{ $t('InstallApp') }}
          </strong>

          <div
            class="d-flex flex-row flex-wrap align-items-center justify-content-center"
            style="column-gap: .5rem; row-gap: .5rem"
          >
            <b-button
              variant="success"
              size="sm"
              @click="installPWA"
            >
              {{ $t('Install') }}
            </b-button>

            <b-button
              variant="outline-secondary"
              size="sm"
              @click="dismissPrompt"
            >
              {{ $t('NoThanks') }}
            </b-button>
          </div>
        </div>
      </b-card-body>
    </b-card>

    <b-button
      v-else
      variant="success"
      class="btn-icon position-fixed"
      style="bottom: 4.5rem; right: 1.7rem; z-index: 9999"
      @click="installPWA"
    >
      <slot name="icon">
        <feather-icon
          icon="SmartphoneIcon"
          size="16"
        />
      </slot>
    </b-button>
  </div>
</template>

<script>
import { loadFromLocalStorage, saveToLocalStorage } from '@/helpers/localStorage'

export default {
  data: () => ({
    shown: false,
    showFloatButton: false,
  }),

  beforeMount() {
    window.addEventListener('beforeinstallprompt', e => {
      e.preventDefault()
      this.installEvent = e
      this.shown = true
      this.showFloatButton = loadFromLocalStorage('showMobileAppBar')
    })
  },

  methods: {
    dismissPrompt() {
      saveToLocalStorage('showMobileAppBar', false)
      this.showFloatButton = true
    },

    installPWA() {
      this.installEvent.prompt()
      // eslint-disable-next-line no-unused-vars
      this.installEvent.userChoice.then(choice => {
        this.dismissPrompt()

        // if (choice.outcome === 'accepted') { } else { }
      })
    },
  },
}
</script>
