// eslint-disable-next-line import/no-cycle
import axiosIns from '@/libs/axios'
import { PROJECTS } from '@/@constants/fields'
import {
  CREATE_PROJECT,
  DELETE_PROJECT,
  GET_PROJECT,
  GET_PROJECTS,
  TEST_PROJECT,
  UPDATE_PROJECT,
} from '@/@constants/mutations'
import moment from 'moment/moment'

const getDate = date => date.dateRange()

export default {
  namespaced: true,
  state: { view: 'extensible' },
  getters: {
    getView: state => state.view,
  },
  mutations: {
    setView(state, payload) { state.view = payload },
  },
  actions: {
    [GET_PROJECTS]: (_, filters) => new Promise((resolve, reject) => {
      const params = {}
      params.fields_load = PROJECTS

      if (filters) {
        if (filters?.status) params['eq_projectStatus-id'] = filters.status

        if (filters?.createdAt) {
          if (filters?.createdAt?.project) {
            const value = getDate(filters.createdAt.project)

            // eslint-disable-next-line prefer-destructuring
            if (value[0]) params.gte_createdAt = value[0]
            // eslint-disable-next-line prefer-destructuring
            params.lte_createdAt = value[1] || moment(value[0]).add(1, 'day').format('YYYY-MM-DD')
          }
        }

        if (filters?.completionAt) {
          if (filters?.completionAt?.project) {
            const value = getDate(filters.completionAt.project)

            // eslint-disable-next-line prefer-destructuring
            if (value[0]) params.gte_endAt = value[0]
            // eslint-disable-next-line prefer-destructuring
            params.lte_endAt = value[1] || moment(value[0]).add(1, 'day').format('YYYY-MM-DD')
          }
        }

        if (filters?.tags?.length) {
          const tags = filters.tags.map(tag => (typeof tag === 'string' ? tag : tag.id))

          params['eqArr_projectTasks-projectTaskTag-id'] = JSON.stringify(tags)
        }

        if (filters?.onDeadline) {
          params.gte_endAt = moment(new Date()).format('YYYY-MM-DD HH:mm')
          params.lte_endAt = moment(new Date()).add(1, 'days').format('YYYY-MM-DD HH:mm')
        }

        if (filters?.contactThreads?.length) params['eqArr_contactThreads-id'] = JSON.stringify(filters.contactThreads.map(contact => contact.id))

        if (filters?.assignedUsers?.length) params['eqArr_assignedUsers-id'] = JSON.stringify(filters.assignedUsers.map(contact => contact.id))

        if (filters?.expired) params.lt_endAt = moment(new Date()).format('YYYY-MM-DD HH:mm')
      }

      axiosIns.get('1/projects', { params })
        .then(res => resolve(res.data.data.items))
        .catch(err => reject(err))
    }),

    [GET_PROJECT]: (_, projectId) => new Promise((resolve, reject) => {
      const params = {}
      params.fields_load = PROJECTS

      axiosIns.get(`1/projects/${projectId}`, { params })
        .then(res => resolve(res.data.data.item))
        .catch(err => reject(err))
    }),

    [CREATE_PROJECT]: (_, payload) => new Promise((resolve, reject) => {
      axiosIns.post('1/projects', payload)
        .then(res => resolve(res.data.data.items[0].id))
        .catch(err => reject(err))
    }),

    [UPDATE_PROJECT]: (_, payload) => new Promise((resolve, reject) => {
      axiosIns.patch('1/projects', payload)
        .then(res => resolve(res.data.data.items[0].id))
        .catch(err => reject(err))
    }),

    [DELETE_PROJECT]: (_, id) => new Promise((resolve, reject) => {
      axiosIns.delete('1/projects', { data: [id] })
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    }),

    [TEST_PROJECT]: (_, level = 0) => new Promise((resolve, reject) => {
      const params = {}
      params.fields_info = level

      axiosIns.get('1/projects', { params })
        .then(res => resolve(res.data.data))
        .catch(err => reject(err))
    }),
  },
}
