<template>
  <div
    v-if="post"
    class="d-flex flex-column align-items-start"
    :class="{ 'flex-lg-row': !preview }"
    style="row-gap: 2rem; column-gap: 2rem;"
  >
    <component
      :is="preview ? 'div' : 'b-card'"
      no-body
      class="w-100"
      :class="{ 'min-vh-100': !post.id, 'd-flex flex-column': preview }"
    >
      <div
        v-if="!preview"
        class="p-2 d-flex flex-wrap flex-row justify-content-between"
        :class="{ 'p-2': !preview }"
      >
        <b-button
          size="sm"
          variant="flat-primary"
          :to="{ name: 'newsList' }"
        >
          <feather-icon icon="ArrowLeftIcon" />

          {{ $t('Return') }}
        </b-button>

        <b-button
          v-if="checkRequiredPermissions([$roles.NEWS_POST_MODIFY])"
          size="sm"
          variant="flat-primary"
          :to="{ name: 'newsEdit', params: { id: post.id } }"
        >
          {{ $t('Edit') }}

          <feather-icon
            icon="SettingsIcon"
            class="spinner ml-25"
          />
        </b-button>
      </div>

      <component
        :is="preview ? 'div' : 'b-card-body'"
        class="pt-0 pb-3 d-flex flex-column position-relative"
        style="row-gap: 2rem"
      >
        <top-info
          :subject="post.subject"
          :author="post.author"
          :created-at="post.createdAt"
          :status="post.status"
          class="px-md-3 px-lg-5"
        />

        <banner
          class="w-100"
          :post="post"
          :is-preview="Boolean(preview)"
        />

        <div
          class="d-flex flex-column px-md-3 px-lg-5 news-content"
          style="row-gap: 1.5rem"
          v-html="post.content"
        />

        <bottom-info
          :author="post.author"
          :status="post.status"
          :created-at="{ date: '05.05.2023' }"
          class="px-md-3 px-lg-5"
        />
      </component>
    </component>

    <b-card
      v-if="!preview"
      class="more-posts"
    >
      <latest-posts :author="post.author" />
    </b-card>
  </div>
</template>

<script>
import Banner from '@/pages/news/components/Banner.vue'
import { mapGetters } from 'vuex'
import TopInfo from '@/pages/news/components/TopInfo.vue'
import BottomInfo from '@/pages/news/components/BottomInfo.vue'
import LatestPosts from '@/pages/news/components/LatestPosts.vue'
import { DOWNLOAD_FILE, GET_NEWS } from '@/@constants/mutations'
import router from '@/router'

export default {
  components: {
    LatestPosts,
    TopInfo,
    BottomInfo,
    Banner,
  },

  data: () => ({
    isLoading: false,

    post: null,
  }),

  computed: {
    ...mapGetters({
      currentUser: 'auth/getCurrentUser',
    }),
  },

  watch: {
    $route: {
      immediate: true,
      handler(newRoute, oldRoute) {
        if (!oldRoute || newRoute.params.id !== oldRoute.params.id) this.ini(Number(newRoute.params.id))
      },
    },
  },

  mounted() {
    const id = Number(this.$route.params?.id) || null

    this.ini(id)
  },

  props: {
    preview: {
      type: Object,
      required: false,
      default: null,
    },
  },

  methods: {
    async ini(id) {
      this.isLoading = true

      if (!this.preview) {
        if (id) {
          await this.loadPost(id)
        } else {
          await router.push({ name: '/newsList' })

          this.isLoading = false
        }
      } else {
        await this.loadPreview()
      }
    },

    async loadPost(id) {
      this.isLoading = true

      await this.$store.dispatch(`news/${GET_NEWS}`, { id })
        .then(res => {
          this.post = res

          this.downloadPicture()
        })
        .catch(() => {
          router.push({ name: '/newsList' })
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    async loadPreview() {
      this.post = this.preview
    },

    async downloadPicture() {
      const { post } = this

      if (post.picture) {
        this.$store.dispatch(`uploader/${DOWNLOAD_FILE}`, { fileToken: post.picture.token, filename: post.picture.name })
          .then(fileRes => {
            const { data } = fileRes

            this.post.picture = data.file
          })
      }
    },
  },
}
</script>

<style lang="css">
.news-content img,
.news-content .ql-video {
  border-radius: 12px;
}

.news-content .ql-video { min-height: 340px }

.news-content .ql-align-center { text-align: center; }
.news-content .ql-align-right { text-align: end; }

.more-posts {
  width: 100%;

  top: -2rem;
}

@media only screen and (min-width: 992px) {
  .more-posts {
    max-width: 350px;

    top: 7.5rem;

    position: sticky;
  }
}
</style>
