<template>
  <div>
    <div
      class="d-flex justify-content-between mb-50"
      style="column-gap: .5rem"
    >
      <div
        class="d-flex"
        style="column-gap: .5rem"
      >
        <b-button-group
          v-if="isTabView && tabViewList.length"
          size="sm"
        >
          <b-button
            v-for="tab in tabViewList"
            :key="tab.title"
            v-b-tooltip.hover.v-primary
            :title="$t(tab.title)"
            variant="outline-primary"
            :class="{ 'active-funnel-view': activeTab && activeTab.title === tab.title }"
            @click="changeActiveTab(tab)"
          >
            <sw-icon :icon="tab.icon ? tab.icon : 'StarIcon'" />
            <span class="pl-25" v-if="showText">{{ $t(tab.title) }}</span>
          </b-button>
        </b-button-group>

        <slot name="left" />
      </div>

      <div
        class="d-flex"
        style="column-gap: .5rem"
      >
        <slot name="right" />
      </div>
    </div>

    <transition-group
      v-if="$slots.default && activeTab"
      name="fade"
    >
      <slot />
    </transition-group>
  </div>
</template>

<script>
import { BButtonGroup, VBTooltip } from 'bootstrap-vue'

export default {
  components: { BButtonGroup },

  directives: {
    'b-tooltip': VBTooltip,
  },

  props: {
    value: {
      type: [Object, null],
      required: false,
      default: null,
    },
    isBtnAdd: {
      type: Boolean,
      required: false,
      default: false,
    },
    showText: {
      type: Boolean,
      required: false,
      default: false,
    },
    isTabView: {
      type: Boolean,
      required: false,
      default: false,
    },
    tabViewList: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  emits: ['add', 'input'],

  data: () => ({
    activeTab: null,
  }),

  mounted() {
    this.ini()
  },

  methods: {
    ini() {
      if (this.tabViewList.length) this.changeActiveTab(this.value || this.tabViewList[0])
    },

    changeActiveTab(tab) {
      this.activeTab = tab

      this.$emit('input', tab)
    },
  },
}
</script>
