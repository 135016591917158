<template>
  <div v-if="!filter.hide">
    <b-button
      :id="uuid"
      variant="flat-primary"
      size="sm"
      class="p-25"
      :disabled="filter.pending"
    >
      <b-spinner
        v-if="filter.pending"
        type="grow"
        small
        class="mx-auto"
        variant="primary"
      />

      <template v-else-if="filter.active">
        {{ $t(filter.active.label) }}
      </template>

      <sw-icon
        v-else
        icon="MinusIcon"
      />
    </b-button>

    <b-popover
      :disabled="filter.pending"
      :target="uuid"
      triggers="focus"
      custom-class="p-0 mt-n75 shadow-none"
      placement="bottom"
    >
      <b-form-radio-group
        v-if="filter.options && filter.options.length"
        v-model="filter.active"
        class="d-flex flex-column py-50 max-h-24 overflow-auto"
        @change="$emit('change', { filter, option: $event })"
      >
        <label
          v-for="(option, j) in filter.options"
          :key="j"
          :for="`${uuid}${j}`"
          class="px-2 py-75 mb-0 cursor-pointer radio-group-cursor-pointer :hover-primary"
        >
          <b-form-radio
            :id="`${uuid}${j}`"
            :value="option"
            class="d-flex align-items-center justify-content-between"
          >
            {{ option.label }}
          </b-form-radio>
        </label>
      </b-form-radio-group>

      <div
        v-else
        class="px-2 py-75"
      >
        {{ $t('NoOptions') }}
      </div>
    </b-popover>
  </div>
</template>

<script lang="ts">
import { BFormRadioGroup, BPopover } from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'

export default {
  components: { BPopover, BFormRadioGroup },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  emits: ['change'],
  data: () => ({
    uuid: uuidv4(),
    filter: { },
  }),
  watch: {
    value: {
      deep: true,
      handler(n) { if (n) this.filter = JSON.parse(JSON.stringify(n)) },
    },
  },
  async mounted() {
    if (this.value) this.filter = JSON.parse(JSON.stringify(this.value))
  },
}
</script>
