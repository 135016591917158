<script>
import { HorizontalBar } from 'vue-chartjs'

export default {
  extends: HorizontalBar,
  props: {
    data: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default: null,
    },
  },
  watch: {
    data: {
      deep: true,
      handler(newData, oldData) {
        if (!Object.is(newData, oldData)) {
          this.renderChart(this.data, this.options)
        }
      },
    },
  },
  mounted() {
    this.renderChart(this.data, this.options)
  },
}
</script>
