<template>
  <validation-observer
    ref="newDocumentForm"
    v-slot="{ invalid }"
    tag="form"
    class="d-flex flex-column"
  >
    <!--      Name      -->
    <validation-provider
      v-slot="{ errors }"
      rules="required"
      :name="$t('Name')"
    >
      <b-form-group :label="$t('Name')">
        <b-form-input
          v-model="name"
          :placeholder="$t('Name')"
          :state="errors.length > 0 ? false:null"
        />

        <small class="text-danger">{{ errors[0] }}</small>
      </b-form-group>
    </validation-provider>

    <!--      Project      -->
    <validation-provider
      v-slot="{ errors }"
      rules="required"
      :name="$t('Offer.Project')"
    >
      <b-form-group>
        <sw-select :name="$t('Offer.Project')">
          <v-select
            v-model="project"
            :options="projectList"
            :placeholder="$t('Offer.Project')"
            :state="errors.length > 0 ? false:null"
            :filterable="false"
            label="name"
            :reduce="item => item.id.toString()"
            item-value="id"
            item-text="name"
          >
            <template #no-options>
              {{ $t('NoOptions') }}
            </template>
          </v-select>
        </sw-select>

        <small class="text-danger">{{ errors[0] }}</small>
      </b-form-group>
    </validation-provider>

    <!--      Task      -->
    <validation-provider
      v-slot="{ errors }"
      :name="$t('timeline.Task')"
    >
      <b-form-group>
        <sw-select :name="$t('timeline.Task')">
          <v-select
            v-model="projectTask"
            :options="taskList"
            :placeholder="$t('timeline.Task')"
            :state="errors.length > 0 ? false:null"
            :filterable="false"
            label="name"
            :reduce="item => item.id.toString()"
            item-value="id"
            item-text="name"
          >
            <template #no-options>
              {{ $t('NoOptions') }}
            </template>
          </v-select>
        </sw-select>

        <small class="text-danger">{{ errors[0] }}</small>
      </b-form-group>
    </validation-provider>

    <!--      Micro Task      -->
    <validation-provider
      v-slot="{ errors }"
      :name="$t('MicroTask')"
    >
      <b-form-group>
        <sw-select :name="$t('MicroTask')">
          <v-select
            v-model="projectTaskMicroTask"
            :options="microTaskList"
            :placeholder="$t('MicroTask')"
            :state="errors.length > 0 ? false:null"
            :filterable="false"
            label="name"
            :reduce="item => item.id.toString()"
            item-value="id"
            item-text="name"
          >
            <template #no-options>
              {{ $t('NoOptions') }}
            </template>
          </v-select>
        </sw-select>

        <small class="text-danger">{{ errors[0] }}</small>
      </b-form-group>
    </validation-provider>

    <!--      Documents      -->
    <validation-provider
      v-slot="{ errors }"
      :name="$t('Description')"
    >
      <b-form-group :label="$t('Description')">
        <textarea
          v-model="description"
          class="form-control"
          type="text"
          :placeholder="$t('Description')"
          :state="errors.length > 0 ? false:null"
        />

        <small class="text-danger">{{ errors[0] }}</small>
      </b-form-group>
    </validation-provider>

    <!--      Files      -->
    <validation-provider
      v-slot="{ errors }"
      :name="$t('Files')"
    >
      <b-form-group :label="$t('Files')">
        <drag-drop-upload
          :disabled="isLoading"
          class="mt-n1"
          multiple
          :state="errors.length > 0 ? false:null"
          @onChangeFiles="changeFiles"
        />

        <small class="text-danger">{{ errors[0] }}</small>
      </b-form-group>
    </validation-provider>

    <b-button
      variant="primary"
      size="sm"
      class="w-100 mt-1"
      :disabled="invalid || !files.length"
      @click="save()"
    >
      {{ $t('Add') }}
    </b-button>
  </validation-observer>
</template>

<script>
import { mapGetters } from 'vuex'
import DragDropUpload from '@/views/components/DragDropUpload.vue'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  CLOSE_MODAL,
  CREATE_PROJECT_DOCUMENT,
  GET_MICRO_TASK_FROM_TASK,
  GET_PROJECT_TASKS_FROM_PROJECT,
  GET_PROJECTS,
  RELOAD_CONTENT,
  UPDATE_PROJECT_DOCUMENT,
  UPLOAD_FILES,
} from '@/@constants/mutations'

export default {
  components: {
    DragDropUpload,
    vSelect,
    ValidationObserver,
    ValidationProvider,
  },
  data: () => ({
    isLoading: false,

    projectList: [],
    taskList: [],
    microTaskList: [],

    name: '',
    description: '',
    project: null,
    projectTask: null,
    projectTaskMicroTask: null,
    files: [],
  }),
  computed: {
    ...mapGetters({
      modalConfig: 'modal/getModalState',
    }),
  },
  watch: {
    project(n) {
      this.loadTasks(n)
    },
    projectTask(n) {
      this.loadMicroTasks(n)
    },
  },
  mounted() {
    this.ini()
  },
  methods: {
    ini() {
      this.loadProjects()

      this.loadDocuments()

      if (this.modalConfig.data?.document?.id) this.loadStatus(this.modalConfig.data.document)
    },

    loadDocuments() {
      this.isLoading = true

      const { data } = this.modalConfig

      this.project = data.projectId.toString() || ''
      this.projectTask = data.taskId.toString() || ''
      this.projectTaskMicroTask = data.microTaskId.toString() || ''

      this.isLoading = false
    },

    loadStatus(document) {
      if (document) {
        const {
          id,
          name,
          description,
          project,
          projectTask,
          projectTaskMicroTask,
          files,
        } = document

        this.name = name
        this.description = description
        this.project = project
        this.projectTask = projectTask
        this.projectTaskMicroTask = projectTaskMicroTask
        this.files = files

        this.id = id || null
        this.name = name || ''
      }
    },

    loadProjects() {
      this.isLoading = true

      this.$store.dispatch(`projects/${GET_PROJECTS}`)
        .then(res => {
          this.projectList = res

          if (!res.find(el => el.id === this.project)) this.project = ''
        })

        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err.response.data.message}`))
        })

        .finally(() => { this.isLoading = false })
    },

    loadTasks(project) {
      if (project) {
        this.isLoading = true

        this.$store.dispatch(`tasks/${GET_PROJECT_TASKS_FROM_PROJECT}`, project)
          .then(res => {
            this.taskList = res

            if (!res.find(el => el.id === this.projectTask)) this.projectTask = ''
          })

          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err.response.data.message}`))
          })

          .finally(() => { this.isLoading = false })
      } else {
        this.taskList = []
        this.projectTask = ''
      }
    },

    loadMicroTasks(task) {
      const { project } = this

      if (project && task) {
        this.isLoading = true

        this.$store.dispatch(`microTasks/${GET_MICRO_TASK_FROM_TASK}`, { project, task })
          .then(res => {
            this.microTaskList = res

            if (!res.find(el => el.id === this.projectTaskMicroTask)) this.projectTaskMicroTask = ''
          })

          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err.response.data.message}`))
          })

          .finally(() => { this.isLoading = false })
      } else {
        this.microTaskList = []
        this.projectTaskMicroTask = ''
      }
    },

    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.newDocumentForm.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },

    async save() {
      if (await this.validationForm()) {
        const payload = await this.getPayload()

        if (payload) this.send(payload)
      }
    },

    async getPayload() {
      const {
        id,
        name,
        description,
        project,
        projectTask,
        projectTaskMicroTask,
        files,
      } = this

      if (files.length) {
        const payload = {
          name,
          description,
          project,
          projectTask,
          projectTaskMicroTask,
        }

        if (id) payload.id = this.id

        const fileList = files.filter(file => file?.token)

        const filePayload = { uploadedFiles: files.filter(file => !file?.token) }
        const newFiles = await this.$store.dispatch(`uploader/${UPLOAD_FILES}`, filePayload)

        fileList.push(...newFiles.files)

        payload.files = fileList

        return payload
      }

      return null
    },

    send(payload) {
      const action = payload?.id ? UPDATE_PROJECT_DOCUMENT : CREATE_PROJECT_DOCUMENT

      this.$store.dispatch(`projectDocuments/${action}`, payload)
        .then(res => {
          this.showToast('success', this.$i18n.t(payload?.id ? 'DocumentUpdated' : 'DocumentAdded'))

          this.$store.commit(`modal/${RELOAD_CONTENT}`, { id: res, ...payload })

          this.$nextTick(() => this.close())
        })

        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })

        .finally(() => { this.isLoading = false })
    },

    changeFiles(files) { this.files.push(...files) },

    close() { this.$store.commit(`modal/${CLOSE_MODAL}`) },
  },
}
</script>
