<template>
  <b-alert
    :show="show"
    :variant="variant"
  >
    <div
      class="alert-body d-flex flex-row"
      style="column-gap: .5rem"
    >
      <div style="margin-top: .1rem">
        <slot name="icon">
          <feather-icon
            :icon="`${icon}Icon`"
            size="18"
          />
        </slot>
      </div>

      <p
        v-if="!$slots.default"
        class="p-0 m-0"
      >
        {{ name }}
      </p>

      <slot />
    </div>
  </b-alert>
</template>

<script>
export default {
  props: {
    name: {
      type: [String, Object],
      required: false,
      default: '–',
    },
    icon: {
      type: String,
      required: false,
      default: 'Info',
    },
    variant: {
      type: String,
      required: false,
      default: 'primary',
    },
    show: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
}
</script>
