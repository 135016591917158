<template>
  <b-card>
    <div>
      <!--      <b-form-checkbox-->
      <!--        v-model="settings.enabledModules[moduleConst]"-->
      <!--        variant="primary"-->
      <!--        :value="true"-->
      <!--        :unchecked-value="false"-->
      <!--        :disabled="loading"-->
      <!--        @change="changeOverride"-->
      <!--      />-->
      <h4>
        {{ moduleLabel }}
      </h4>
      <!--      <b-form-checkbox-->
      <!--        v-model="settings[moduleKey]"-->
      <!--        switch-->
      <!--        variant="primary"-->
      <!--        :disabled="loading || !settings.enabledModules[moduleConst]"-->
      <!--        @change="toggleModule"-->
      <!--      />-->

      <b-button-group>
        <b-button size="sm"
                  :variant="settings[moduleKey] === null ? 'primary' : 'outline-primary'"
                  @click="changeOverride(null)"
        >
          {{ $t('ByPlan') }}
        </b-button>
        <b-button v-b-tooltip
                  size="sm"
                  :variant="settings[moduleKey] === false ? 'primary' : 'outline-primary'"
                  :title="!isInPlan ? 'Plan' : ''"
                  @click="changeOverride(false)"
        >
          <sw-icon v-if="!isInPlan"
                   icon="CircleIcon"
          />
          {{ $t('TurnedOff') }}
        </b-button>
        <b-button v-b-tooltip
                  size="sm"
                  :variant="settings[moduleKey] === true ? 'primary' : 'outline-primary'"
                  :title="isInPlan ? 'Plan' : ''"
                  @click="changeOverride(true)"
        >
          <sw-icon v-if="isInPlan"
                   icon="CircleIcon"
          />
          {{ $t('TurnedOn') }}
        </b-button>
      </b-button-group>
    </div>
  </b-card>
</template>

<script>
import axiosIns from '@/libs/axios'
import { mapGetters } from 'vuex'
import { BButtonGroup, VBTooltip } from 'bootstrap-vue'
import { GET_PRIVATE_SETTINGS } from '@/@constants/mutations'

export default {
  name: 'Item',
  components: {
    BButtonGroup,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    moduleKey: {
      required: true,
      default: '',
      type: String,
    },
    moduleLabel: {
      required: true,
      default: '',
      type: String,
    },
    moduleConst: {
      required: true,
      default: '',
      type: String,
    },
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapGetters({
      settings: 'system/getPrivateSettings',
      settings2: 'system/getSettings',
    }),
    isInPlan() {
      return Boolean(this.settings?.planModules?.includes(this.moduleKey))
    },
  },
  async mounted() {
    await this.$store.dispatch(`system/${GET_PRIVATE_SETTINGS}`)
  },
  methods: {
    async changeOverride(value) {
      this.settings2.enabledModules[this.moduleConst] = Boolean(value === null ? this.settings?.planModules?.includes(this.moduleKey) : value)
      this.settings[this.moduleKey] = value
      this.toggleModule(value)
    },
    async toggleModule(event) {
      this.loading = true

      try {
        await axiosIns.patch('1/settings/global', { [this.moduleKey]: event })

        this.showToast('success', this.$i18n.t('success.contactUpdated'))
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>

</style>
